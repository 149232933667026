// @ngInject
const HandlingFeesApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('handlingFee', {
      params: { organizationId },
    }),

  getById: (handlingFeeId) =>
    ResourcesService.getById('handlingFee', handlingFeeId, {
      params: { organizationId: false },
    }),

  newHandlingFee: (handlingFee, organizationId) =>
    ResourcesService.create('handlingFee', handlingFee, {
      params: { organizationId },
    }),

  updateHandlingFee: (data, handlingFeeId) =>
    ResourcesService.update('handlingFee', handlingFeeId, data, {
      params: { organizationId: false },
      updatedFields: ['name', 'value', 'displayOrder', 'archived'],
    }),
});

angular
  .module('resources.handlingFees', [])
  .factory('HandlingFeesApiService', HandlingFeesApiService);
