import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';
import { FileBrowserContent } from './FileBrowserContent';
import { FileLink } from '@texas/api/endpoints/filesApi';

export type FileBrowserMode = 'Edit' | 'Select';

export interface FileBrowserprops extends SharedDisclosureProps {
  rootFolderId: number;
  mode: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  extensionFilter?: string[];
  rejectExtensionFilter?: string[];
}

export function FileBrowserModal({
  rootFolderId,
  mode,
  onSelect,
  extensionFilter,
  rejectExtensionFilter,
  isOpen,
  onClose,
}: FileBrowserprops) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('fileBrowser.selectFile')}</ModalHeader>
        <ModalCloseButton />
        <FileBrowserContent
          rootFolderId={rootFolderId}
          mode={mode}
          onSelect={onSelect}
          extensionFilter={extensionFilter}
          rejectExtensionFilter={rejectExtensionFilter}
        />
      </ModalContent>
    </Modal>
  );
}
