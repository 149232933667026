// This is is somehow causing prettier to cast a format error here (SyntaxError: Expression expected)
// inside the function call. But the function does not accept any arguments...
// prettier-ignore does not work, so we have to ignore the whole file.

import { createContextValue } from "@texas/utils/contextState";
import { ProductApprovalStateData } from "./types";
import { useWizardStateStore } from "./wizard/hooks/useWizardState";

export const [ProductApprovalWizardStateProvider, useProdutApprovalWizardState] =
  createContextValue<ReturnType<typeof useWizardStateStore<ProductApprovalStateData>>>();
