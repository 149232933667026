import axios, { AxiosError } from 'axios';
import { SignalDispatcher } from 'ste-signals';
import {
  HttpClientResponse,
  Method,
  RequestOptions,
  RequestOptionsWithBody,
} from './types';
import { baseApiUrl, timeout } from './constants';

export function createHttpClient(baseUrl?: string) {
  const onUnauthorizedEvent = new SignalDispatcher();
  const axiosClient = axios.create({ timeout: timeout });

  axiosClient.interceptors.response.use(undefined, errorResponseHandler);

  function errorResponseHandler(error: AxiosError<unknown>) {
    if (error.response && error.response.status === 401) {
      onUnauthorizedEvent.dispatch();
    }

    return Promise.reject(error);
  }

  async function request<T>(
    method: Method,
    url: string,
    options?: RequestOptionsWithBody,
  ): Promise<HttpClientResponse<T>> {
    try {
      const response = await axiosClient.request({
        url,
        method,
        baseURL: baseUrl,
        ...options,
        paramsSerializer: { indexes: null },
      });
      return {
        hasError: false,
        status: response.status,
        data: response.data as unknown as T,
        headers: response.headers,
      };
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const status = error.response?.status ?? 0;
      const data: any = error.response?.data ?? {};

      if (!data.message) {
        return {
          hasError: true,
          error: {
            status: status,
            message: 'Unexpected error',
            errors: {},
          },
        };
      }

      return {
        hasError: true,
        error: {
          status: status,
          message: data.message,
          errors: data.errors,
        },
      };
    }
  }

  return {
    get: <T = unknown>(url: string, options?: RequestOptions) =>
      request<T>('GET', url, options),
    put: <T = unknown>(url: string, options?: RequestOptionsWithBody) =>
      request<T>('PUT', url, options),
    post: <T = unknown>(url: string, options?: RequestOptionsWithBody) =>
      request<T>('POST', url, options),
    delete: <T = unknown>(url: string, options?: RequestOptions) =>
      request<T>('DELETE', url, options),
    onUnauthorized: {
      subscribe: (fn: () => void) => onUnauthorizedEvent.subscribe(fn),
      unsubscribe: (fn: () => void) => onUnauthorizedEvent.unsubscribe(fn),
    },
  };
}

export const httpClient = createHttpClient(baseApiUrl);
