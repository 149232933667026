// @ngInject
const MatrixGroupDialogService = (
  $q,
  $mdDialog,
  $rootScope,
  OrganizationsApiService,
  MatrixGroupsApiService,
) => ({
  showDialog: ({
    organization = $rootScope.currentUser.organization || null,
    matrixGroup = null,
    allowedToEdit = false,
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'MatrixGroupDialogController as vm',
      templateUrl:
        'services/dialogs/matrixGroupDialog/matrixGroupDialog.tpl.html',
      locals: {
        organization,
        allowedToEdit,
      },
      resolve: {
        matrixGroup: () =>
          !matrixGroup
            ? $q.resolve(null)
            : MatrixGroupsApiService.getById(matrixGroup.id),
        matrixNodes: () =>
          OrganizationsApiService.loadMatrixNodes(organization.id),
      },
    }),
});

class MatrixGroupDialogController {
  constructor(
    $filter,
    $mdDialog,
    HelperService,
    MatrixGroupsApiService,
    matrixNodeService,
    toasterService,
    matrixGroup,
    organization,
    matrixNodes,
    allowedToEdit,
  ) {
    'ngInject';

    this.$filter = $filter;
    this.$mdDialog = $mdDialog;
    this.HelperService = HelperService;
    this.MatrixGroupsApiService = MatrixGroupsApiService;
    this.toasterService = toasterService;
    this.origMatrixGroup = matrixGroup;
    this.organization = organization;
    this.matrixNodeService = matrixNodeService;
    this.matrixNodes = _.clone(matrixNodes);
    this.allowedToEdit = allowedToEdit;

    this.validationErrors = [];
    this.editMode = matrixGroup !== null;

    if (!this.editMode) {
      matrixGroup = {
        matrixNodeId: matrixNodes.length ? matrixNodes[0].id : null,
      };
    }
    this.matrixNode = _.find(this.matrixNodes, {
      id: matrixGroup.matrixNodeId,
    });

    this.matrixGroup = _.extend({}, matrixGroup);
    this.matrixGroup.matrixNodeValues = _.map(
      _.filter(
        _.get(this.matrixNode, 'matrixNodeValues', []),
        (value) =>
          !value.archived &&
          !!_.find(this.matrixGroup.matrixNodeValues, { id: value.id }),
      ),
      (value) => {
        const clone = _.extend({}, value);
        const groupValue = _.find(this.matrixGroup.matrixNodeValues, {
          id: value.id,
        });
        if (groupValue && groupValue.name) {
          clone.name = groupValue.name;
        }
        return clone;
      },
    );
  }

  loadNodeValues(query) {
    query = (query || '').toLowerCase();
    return this.$filter('filter')(
      _.get(this.matrixNode, 'matrixNodeValues', []),
      (value) =>
        !value.archived &&
        (value.name.toLowerCase().indexOf(query) !== -1 ||
          value.identifier.toLowerCase().indexOf(query) !== -1) &&
        !_.find(this.matrixGroup.matrixNodeValues, { id: value.id }),
    );
  }

  valueSelected(value) {
    this.selectedValue = null;
    this.valueSearchText = null;
    if (
      value &&
      value.matrixNodeId === this.matrixGroup.matrixNodeId &&
      !_.find(this.matrixGroup.matrixNodeValues, { id: value.id })
    ) {
      this.matrixGroup.matrixNodeValues.push(_.extend({}, value));
    }
  }

  removeValue(value) {
    _.remove(this.matrixGroup.matrixNodeValues, value);
  }

  nodeChanged() {
    if (
      this.matrixNode &&
      this.matrixNode.id &&
      this.matrixGroup.matrixNodeId !== this.matrixNode.id
    ) {
      this.matrixGroup.matrixNodeId = this.matrixNode.id;
      this.matrixGroup.matrixNodeValues = [];
    }
  }

  validateInput(value) {
    this.matrixNodeService.validateInput(
      _.get(this.matrixNode, 'matrixNodeValues', []),
      value,
    );
  }

  keyDown($event, value) {
    this.matrixNodeService.keyDown($event, this.matrixNode, value);
  }

  save() {
    this.validationErrors = [];

    if (this.editMode) {
      const changed = this.HelperService.getChangedData(
        this.origMatrixGroup,
        this.matrixGroup,
        ['name', 'displayOrder'],
      );
      changed.matrixNodeValues = _.map(
        this.matrixGroup.matrixNodeValues,
        (value) => {
          const val = { id: value.id };
          const originalValue = _.find(
            _.get(this.matrixNode, 'matrixNodeValues', []),
            { id: val.id },
          );
          if (value.name && value.name !== originalValue.name) {
            val.name = value.name;
          }
          return val;
        },
      );

      // If changes are found, update them
      if (!_.isEmpty(changed)) {
        this.$mdDialog.startLoadingSpinner();
        this.MatrixGroupsApiService.updateMatrixGroup(
          changed,
          this.origMatrixGroup.id,
        )
          .then(
            () => {
              this.toasterService.success();
              this.$mdDialog.hide();
            },
            (error) => (this.validationErrors = error.errors),
          )
          .finally(() => this.$mdDialog.stopLoadingSpinner());
      } else {
        this.$mdDialog.hide();
      }
    } else {
      this.$mdDialog.startLoadingSpinner();
      this.MatrixGroupsApiService.newMatrixGroup(
        this.matrixGroup,
        this.organization.id,
      )
        .then(
          (newGroup) => {
            this.toasterService.success();
            this.$mdDialog.hide(newGroup);
          },
          (error) => (this.validationErrors = error.errors),
        )
        .finally(() => this.$mdDialog.stopLoadingSpinner());
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  remove() {
    this.$mdDialog.startLoadingSpinner();
    this.MatrixGroupsApiService.removeMatrixGroup(this.matrixGroup.id)
      .then(
        () => {
          this.toasterService.success();
          this.$mdDialog.hide();
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.$mdDialog.stopLoadingSpinner());
  }
}

angular
  .module('services.dialogs.matrixGroupDialog', [])
  .service('MatrixGroupDialogService', MatrixGroupDialogService)
  .controller('MatrixGroupDialogController', MatrixGroupDialogController);
