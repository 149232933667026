import { AutoUpdateErrorType } from '@texas/api/endpoints/autoUpdateTypes';
import { useTranslation } from 'react-i18next';

export function ErrorTitle({ errorType }: { errorType: AutoUpdateErrorType }) {
  const { t } = useTranslation();
  if (errorType === AutoUpdateErrorType.OldValueMismatch) {
    return <>{t('autoUpdate.conflict')}</>;
  }

  return <>{t('autoUpdate.autosaveFailed')}</>;
}
