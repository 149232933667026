import { Box } from '@chakra-ui/react';

export function AutoGrowTextareaContainer({
  children,
  value,
}: {
  children: React.ReactNode;
  value: string;
}) {
  return (
    <Box
      data-replicated-value={value}
      __css={{
        display: 'grid',
        '::after': {
          content: 'attr(data-replicated-value) " "',
          whiteSpace: 'pre-wrap',
          visibility: 'hidden',
          gridArea: '1 / 1 / 2 / 2',
          overflowWrap: 'anywhere',
          py: '0.5rem',
          paddingInline: '1rem',
        },
        '> textarea': {
          resize: 'none',
          overflow: 'hidden',
          gridArea: '1 / 1 / 2 / 2',
        },
      }}
    >
      {children}
    </Box>
  );
}
