import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = config.definePartsStyle({
  track: {
    _focusVisible: { boxShadow: '0 0 0 3px rgba(198, 80, 44, 0.6)' },
    _checked: {
      bg: 'texas.burntSienna.500',
      _focusVisible: { boxShadow: '0 0 0 3px rgba(198, 80, 44, 0.6)' },
    },
  },
});

export default config.defineMultiStyleConfig({ baseStyle });
