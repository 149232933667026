// @ngInject
const UserDialogService = (
  $mdDialog,
  BranchesApiService,
  CountriesApiService,
) => ({
  showDialog(data) {
    data = data || {};
    data.user = data.user || null;
    data.organizationId =
      data.organizationId || data.user.organizationId || null;
    data.viewMode = data.viewMode || false;

    const options = {
      clickOutsideToClose: true,
      locals: {
        organizationId: data.organizationId,
        user: data.user,
        isEditingSelf: data.isEditingSelf,
        createNew: data.user === null,
      },
      resolve: {
        branches: () =>
          BranchesApiService.getAllByOrganizationId(data.organizationId),
        countries: () =>
          data.user?.branchId
            ? CountriesApiService.getAllByBranchId(data.user.branchId)
            : undefined,
      },
    };

    if (data.viewMode) {
      options.controller = 'UserViewDialogController as vm';
      options.templateUrl =
        'services/dialogs/userDialog/userDialogView.tpl.html';
    } else {
      options.controller = 'UserEditDialogController as vm';
      options.templateUrl =
        'services/dialogs/userDialog/userDialogEdit.tpl.html';
    }

    return $mdDialog.show(options);
  },
});

class UserViewDialogController {
  constructor($mdDialog, user) {
    'ngInject';

    this.user = user;
    this._$mdDialog = $mdDialog;
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

class UserEditDialogController {
  constructor(
    $q,
    $mdDialog,
    HelperService,
    AuthService,
    OrganizationsApiService,
    CountriesApiService,
    UsersApiService,
    loadSpinnerService,
    toasterService,
    user,
    isEditingSelf,
    createNew,
    organizationId,
    branches,
    countries,
  ) {
    'ngInject';

    this.validationErrors = [];
    this.editMode = user !== null;
    this.isEditingSelf = isEditingSelf;
    this.createNew = createNew;
    this.branches = branches;
    this.countries = countries;
    this.isSysAdmin = AuthService.hasAnyClaim(['system_administrator']);
    this.allowedToEditUser = AuthService.hasAnyClaim([
      'system_administrator',
      'organization_administrator',
    ]);

    if (!this.editMode) {
      user = {
        emailNotifications: true,
        branchId: branches[0].id,
      };
      if (this.isSysAdmin) {
        OrganizationsApiService.getAll().then((organizations) => {
          this.organizations = organizations;
        });
      } else {
        user.organizationId = organizationId;
      }
    }

    if (this.editMode) {
      user.canEdit =
        _.intersection(_.map(user.claims, 'value'), [
          'system_administrator',
          'organization_administrator',
          'company_administrator',
        ]).length > 0;
      user.organizationAdministrator = !!_.find(
        user.claims,
        (u) => u.value === 'organization_administrator',
      );
      user.agent = !!_.find(user.claims, (u) => u.value === 'agent');
    } else {
      user.canEdit = true;
    }

    this.user = angular.extend({}, user);
    this._$mdDialog = $mdDialog;
    this._$q = $q;
    this._HelperService = HelperService;
    this._UsersApiService = UsersApiService;
    this._toasterService = toasterService;
    this._user = user;
    this.CountriesApiService = CountriesApiService;
    this.HelperService = HelperService;
    this.loadSpinnerService = loadSpinnerService;

    this.setBranch(user.branchId);
    this.setCountry(user.countryId);
  }

  tabChanged(e) {
    const tab = e.currentTarget.dataset.tab;
    const group = e.currentTarget.dataset.tabGroup;
    document
      .querySelectorAll(`[data-tab-content-group="${group}"]`)
      .forEach((e) => e.classList.add('tab-hide'));
    document
      .querySelector(
        `[data-tab-content-group="${group}"][data-tab-content="${tab}"]`,
      )
      .classList.remove('tab-hide');
  }

  onOrgAdminCheck() {
    if (this.user.organizationAdministrator === true) {
      this.user.canEdit = true;
      this.user.agent = false;
    }
  }
  onAgentCheck() {
    if (this.user.agent === true) {
      this.user.canEdit = false;
      this.user.organizationAdministrator = false;
    }
  }

  setBranch(branchId) {
    if (branchId) {
      this.user.branch = _.find(this.branches, {
        id: branchId,
      });
      this.onBranchChanged(branchId);
    }
  }
  setCountry(countryId) {
    if (countryId) {
      this.user.country = _.find(this.countries, {
        id: countryId,
      });
    }
  }

  filterBranches(query) {
    return _.orderBy(
      _.filter(this.branches, (a) =>
        this.HelperService.containsText(a, { name: query }),
      ),
      'name',
    );
  }
  filterCountries(query) {
    return _.orderBy(
      _.filter(this.countries, (a) =>
        this.HelperService.containsText(a, { name: query }),
      ),
      'name',
    );
  }

  onBranchChanged(branchId) {
    if (branchId === undefined) {
      this.branchId = [];
      this.user.branchId = 0;
      this.user.countryId = 0;
    } else {
      this.user.branchId = branchId;
      this.user.countryId = 0;
      this.user.country = undefined;
      this.loadSpinnerService.start('mainSpinner');
      this.CountriesApiService.getAllByBranchId(branchId)
        .then((countries) => (this.countries = countries))
        .finally(() => this.loadSpinnerService.stop('mainSpinner'));
    }
  }
  onCountryChanged(country) {
    if (country === undefined) {
      this.user.countryId = 0;
    } else {
      this.user.countryId = country.id;
    }
  }

  deleteUser() {
    this._$mdDialog.startLoadingSpinner();
    this._UsersApiService
      .deleteUser(this._user.id)
      .then(
        () => {
          this._toasterService.success();
          this._$mdDialog.hide();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  save() {
    const promises = [];
    this.validationErrors = [];

    if (this.editMode) {
      this._$mdDialog.startLoadingSpinner();
      // Get any changes in user info
      const changedUserInfoData = this._HelperService.getChangedData(
        this._user,
        this.user,
        [
          'username',
          'firstName',
          'lastName',
          'email',
          'emailNotifications',
          'branchId',
          'countryId',
          'canEdit',
          'organizationAdministrator',
          'title',
          'phone',
          'mobile',
          'agent',
        ],
      );

      // If changes are found, update them
      if (!_.isEmpty(changedUserInfoData)) {
        const contactInfoDfd = this._$q.defer();

        this._UsersApiService
          .updateContactInfo(changedUserInfoData, this._user.id)
          .then(
            () => {
              contactInfoDfd.resolve();
            },
            (error) => {
              contactInfoDfd.reject(error);
            },
          );

        promises.push(contactInfoDfd.promise);
      }

      // Update password if it has been entered
      if (this.user.password) {
        const passwordDfd = this._$q.defer();
        // Make sure the repeated password is the same
        if (this.user.password !== this.user.passwordRepeat) {
          passwordDfd.reject({
            errors: [{ message: "The repeated password doesn't match" }],
          });
        } else {
          // Send updated data to api
          this._UsersApiService
            .updatePassword(this.user.password, this._user.id)
            .then(
              () => {
                passwordDfd.resolve();
              },
              (error) => {
                passwordDfd.reject(error);
              },
            );
        }
        promises.push(passwordDfd.promise);
      }

      this._$q
        .all(promises)
        .then(
          () => {
            this._toasterService.success();
            this._$mdDialog.hide();
          },
          (error) => {
            this.validationErrors = error.errors;
          },
        )
        .finally(() => {
          this._$mdDialog.stopLoadingSpinner();
        });
    } else {
      if (this.user.password !== this.user.passwordRepeat) {
        this.validationErrors = [
          { message: "The repeated password doesn't match" },
        ];
      } else {
        this._$mdDialog.startLoadingSpinner();
        this._UsersApiService
          .newUser(this.user)
          .then(
            (newUser) => {
              this._toasterService.success();
              this._$mdDialog.hide(newUser);
            },
            (error) => {
              this.validationErrors = error.errors;
            },
          )
          .finally(() => {
            this._$mdDialog.stopLoadingSpinner();
          });
      }
    }
  }

  archive() {
    this._UsersApiService
      .archiveUser(this._user.id)
      .then(
        () => {
          this._toasterService.success();
          this._$mdDialog.hide();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.userDialog', [])
  .service('UserDialogService', UserDialogService)
  .controller('UserEditDialogController', UserEditDialogController)
  .controller('UserViewDialogController', UserViewDialogController);
