import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { TexasDrawerHeader } from '@texas/components/shared/drawer/TexasDrawerHeader';
import { TexasDrawerSubHeader } from '@texas/components/shared/drawer/TexasDrawerSubHeader';
import {
  Scrollable,
  ScrollableContainer,
} from '@texas/components/shared/scrollable/Scrollable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllSuppliers } from './AllSuppliers';
import { RecommendedSuppliers } from './RecommendedSuppliers';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';

export function AddSupplier({
  branchId,
  branchName,
  variantId,
  onAdd,
}: {
  branchId: number;
  branchName: string;
  variantId: number;
  onAdd?: (data: VariantBranchSupplier) => void;
}) {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <ScrollableContainer>
      <TexasDrawerHeader
        title={t('suppliers.addSupplier')}
        subTitle={branchName}
      />
      <Tabs onChange={(index) => setTabIndex(index)}>
        <TexasDrawerSubHeader style={{ padding: 0, paddingX: 0 }}>
          <TabList>
            <Tab>{t('suppliers.recommended')}</Tab>
            <Tab>{t('suppliers.all')}</Tab>
          </TabList>
        </TexasDrawerSubHeader>
      </Tabs>
      {tabIndex === 0 && (
        <Scrollable>
          <RecommendedSuppliers
            onAdd={onAdd}
            variantId={variantId}
            branchId={branchId}
          />
        </Scrollable>
      )}
      {tabIndex === 1 && (
        <Scrollable>
          <AllSuppliers
            onAdd={onAdd}
            variantId={variantId}
            branchId={branchId}
          />
        </Scrollable>
      )}
    </ScrollableContainer>
  );
}
