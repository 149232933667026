import { httpClient } from '@texas/api/httpClient/httpClient';

export interface Material {
  id: number;
  name: string;
  code: string;
  description: string;
  displayOrder: number;
  articleTextField: string;
  archived?: string;
  combinationOf: MaterialList[];
}

interface MaterialList {
  name: string;
  id: number;
}

export interface SearchQuery {
  sortBy: string;
  sortDesc: boolean;
  searchTerm: string;
  includeArchived: boolean;
}

export interface CreateMaterialRequest {
  name: string;
  description: string;
  displayOrder: number;
  articleTextField: string;
  childMaterialIds: number[] | null | undefined;
}

export type UpdateMaterialRequest = CreateMaterialRequest;

export const materialsApi = {
  getAll: () => httpClient.get<Material[]>('metadata/materials/all'),
  get: (id: number) => httpClient.get<Material>(`metadata/materials/${id}`),
  search: (params: SearchQuery) =>
    httpClient.get<Material[]>(`metadata/materials/search`, {
      params,
    }),
  create: (request: CreateMaterialRequest) =>
    httpClient.post<Material>(`metadata/materials`, {
      data: request,
    }),
  update: (id: number, request: UpdateMaterialRequest) =>
    httpClient.put<Material>(`metadata/materials/${id}`, {
      data: request,
    }),
  archive: (id: number) => httpClient.put(`metadata/materials/${id}/archive`),
  restore: (id: number) => httpClient.put(`metadata/materials/${id}/restore`),
};
