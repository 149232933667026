// @ngInject
const autofocus = ($timeout) => ({
  restrict: 'A',

  link: function link(scope, element) {
    $timeout(() => {
      element[0].focus();
    });
  },
});

angular.module('directives.autofocus', []).directive('autofocus', autofocus);
