const uploadsMenu = () => ({
  templateUrl: 'directives/uploadsMenu/uploadsMenu.tpl.html',
  restrict: 'EA',
  scope: {},
  controller: 'UploadsMenusController',
  controllerAs: 'vm',
});

class UploadsMenusController {
  constructor(UploaderService, HelperService) {
    'ngInject';

    this.UploaderService = UploaderService;
    this.HelperService = HelperService;

    this.files = UploaderService.files;
    this.progress = () => UploaderService.getProgress();
    this.activeUpload = () => UploaderService.active;
    this.showDownloadMenu = false;
    this.showDownloads = false;
    this.UploaderService.on('fileAdded', () => this.setActive());
    this.UploaderService.on('filesCompleted', () =>
      this.minimizeDownloadMenu(),
    );
  }

  setActive() {
    this.showDownloadMenu = true;
    this.showDownloads = true;
  }

  closeDownloadMenu() {
    this.showDownloadMenu = false;
    this.UploaderService.removeFinished();
  }

  minimizeDownloadMenu() {
    this.showDownloads = false;
  }
}

angular
  .module('directives.uploadsMenu', [])
  .directive('uploadsMenu', uploadsMenu)
  .controller('UploadsMenusController', UploadsMenusController);
