import {
  Flex,
  Button,
  Tag,
  VStack,
  HStack,
  Divider,
  Box,
  Text,
} from '@chakra-ui/react';
import { Branch } from '@texas/api/endpoints/branchesApi';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import {
  useTexasDrawer,
  TexasDrawer,
} from '@texas/components/shared/drawer/TexasDrawer';
import { TexasDrawerContent } from '@texas/components/shared/drawer/TexasDrawerContent';
import { TexasDrawerHeader } from '@texas/components/shared/drawer/TexasDrawerHeader';
import { TexasDrawerSubHeader } from '@texas/components/shared/drawer/TexasDrawerSubHeader';
import { useRegexSearch } from '@texas/components/shared/hooks/useRegexSearch';
import {
  ScrollableContainer,
  Scrollable,
} from '@texas/components/shared/scrollable/Scrollable';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { useTranslation } from 'react-i18next';

export interface SelectCurrencyDrawerProps {
  onCurrencyClick: (currency: CustomerCurrency) => void;
  currencies: CustomerCurrency[];
  mainSalesCurrency?: SalesCurrency;
  branch?: Branch;
  disabled?: boolean;
}

export function SelectCurrencyDrawer(props: SelectCurrencyDrawerProps) {
  const { t } = useTranslation();
  const drawer = useTexasDrawer(ModalState.Closed);
  const { search, setSearch, regexSearch } = useRegexSearch();

  return (
    <Box>
      <TexasDrawer ref={drawer.ref} modal={drawer}>
        <TexasDrawerContent>
          <ScrollableContainer>
            <TexasDrawerHeader
              title={t('variant.sales.addSalesCurrency')}
              subTitle={props.branch?.name}
            />
            <TexasDrawerSubHeader
              title={t('variant.sales.salesCurrencies')}
              subTitle="PR = Purchase rate / SR = Selling rate"
            />

            <Box p={2} w="100%">
              <SearchFilterInput
                value={search}
                onChange={(searchTerm) => setSearch(searchTerm)}
                debounceDelay={0}
                placeholder={t('variant.searchCurrency')}
              />
            </Box>
            <Scrollable>
              <Box p={2}>
                {props.currencies
                  .filter((c) => c.name.toLowerCase().match(regexSearch))
                  .map((c) => {
                    return (
                      <Button
                        display="flex"
                        key={c.id}
                        variant="unstyled"
                        w="100%"
                        pl={0}
                        height="fit-content"
                        alignItems="start"
                        onClick={() => props.onCurrencyClick(c)}
                        _hover={{
                          color: 'texas.sand.100',
                          pl: '2em',
                        }}
                        _light={{ _hover: { color: 'texas.burntSienna.500' } }}
                        transition="color 100ms ease, padding-left 200ms ease"
                        paddingBottom={2}
                      >
                        <Flex align="center" w="100%">
                          <Tag mr={2}>{c.code}</Tag>
                          <VStack align="start" spacing={1} w="100%">
                            <Text variant="main">{c.name}</Text>
                            <HStack>
                              <Text variant="small">
                                {`${c.buyingRate} ${props.mainSalesCurrency?.currencyCode} PR`}
                              </Text>
                              <Text
                                variant="small"
                                color={
                                  c.sellingRateModified ? 'white' : undefined
                                }
                              >
                                {`${c.sellingRate} ${props.mainSalesCurrency?.currencyCode} SR`}
                              </Text>
                              {c.sellingRateModified && (
                                <Text variant="small">
                                  {c.garpSellingRate} garp SR
                                </Text>
                              )}
                            </HStack>
                            {c.sellingRateModified && (
                              <Text variant="small" color="texas.warning.100">
                                {t('variant.sales.modifiedOnCustomer')}
                              </Text>
                            )}
                            <Divider pt={1} />
                          </VStack>
                        </Flex>
                      </Button>
                    );
                  })}
              </Box>
            </Scrollable>
          </ScrollableContainer>
        </TexasDrawerContent>
      </TexasDrawer>
      <Button
        variant="texas-light"
        size="sm"
        onClick={() => drawer.setModalState(ModalState.Open)}
        disabled={props.disabled}
      >
        {t('variant.addCurrency')}
      </Button>
    </Box>
  );
}
