import { ServerError } from '@texas/types';
import { Branch } from './branchesApi';
import { User } from './userApi';
import { httpClient } from '../httpClient/httpClient';

export interface Session {
  currentUser: User;
  branches: Branch[];
}

export enum SessionStatus {
  Fetching,
  Active,
  Error,
  Inactive,
}

export type SessionState =
  | {
      status: SessionStatus.Fetching;
    }
  | {
      status: SessionStatus.Inactive;
    }
  | {
      status: SessionStatus.Active;
      session: Session;
    }
  | {
      status: SessionStatus.Error;
      error: ServerError;
    };

export const sessionApi = {
  getSession: () => httpClient.get<Session>('session'),
};
