import { Flex } from '@chakra-ui/react';
import { RejectModal } from './RejectModal';
import { AcceptModal } from './AcceptModal';

export function SelectState({
  id,
  branchId,
  variantId,
  onChanged,
}: {
  id: number;
  branchId: number;
  variantId: number;
  onChanged: (id?: number) => void;
}) {
  return (
    <Flex gap={1}>
      <RejectModal productApprovalId={id} onReject={() => onChanged(id)} />
      <AcceptModal
        productApprovalId={id}
        branchId={branchId}
        variantId={variantId}
        onAccept={() => onChanged(id)}
      />
    </Flex>
  );
}
