import { Flex, FormLabel, Input, Button, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { defaultWidgetColor, defaultWidgetLightColor } from '../types';

export function SelectColor({
  onChange,
  selectedColor,
}: {
  onChange: (hex: string | null) => void;
  selectedColor: string | null;
}) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Flex>
      <FormLabel
        boxSize={8}
        bg={
          selectedColor ??
          (colorMode === 'dark' ? defaultWidgetColor : defaultWidgetLightColor)
        }
        borderRadius="full"
        border="1px solid white"
        _light={{
          borderColor: 'texas.bg.800',
        }}
      >
        <Input
          visibility="hidden"
          onChange={(e) => onChange(e.target.value)}
          type="color"
        />
      </FormLabel>

      <Button onClick={() => onChange(null)} variant="unstyled" size="sm">
        {t('autoUpdate.reset')}
      </Button>
    </Flex>
  );
}
