angular.module('directives.modifiedHint', []).directive('modifiedHint', () => ({
  template:
    '<div class="modified-hint">' +
    '<div ng-show="showHint">Modified since last sync</div>' +
    '</div>',
  restrict: 'E',
  replace: true,
  scope: {
    showHint: '=',
  },
}));
