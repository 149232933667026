import {
  Button,
  PopoverTrigger,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { SharedDisclosureProps } from '../types';

interface VerifyPopoverProps extends SharedDisclosureProps {
  trigger: React.ReactNode;
  headerTitle: string;
  children: React.ReactNode;
  secondaryButtonTitle: string;
  primaryButtonTitle: string;

  onPrimaryClick: () => void;

  onSuccessTitle: string;
  onFailureTitle: string;
}

export function VerifyPopover(props: VerifyPopoverProps) {
  const cancelRef = useRef(null);

  return (
    <Popover
      initialFocusRef={cancelRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <PopoverTrigger>{props.trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold">{props.headerTitle}</PopoverHeader>
        <PopoverBody>{props.children}</PopoverBody>
        <PopoverFooter>
          <Button ref={cancelRef} onClick={props.onClose}>
            {props.secondaryButtonTitle}
          </Button>
          <Button onClick={props.onPrimaryClick} ml={3}>
            {props.primaryButtonTitle}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
