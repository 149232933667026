class BranchDialogService {
  constructor(
    $mdDialog,
    CurrenciesApiService,
    VatCodesApiService,
    ResourcesService,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.CurrenciesApiService = CurrenciesApiService;
    this.VatCodesApiService = VatCodesApiService;
    this.ResourcesService = ResourcesService;
  }

  showDialog(data) {
    data = data || {};
    data.organizationId = data.organizationId || null;
    data.branch = data.branch || null;

    const options = {
      clickOutsideToClose: true,
      controller: 'BranchDialogController as vm',
      templateUrl: 'services/dialogs/branchDialog/branchDialog.tpl.html',
      locals: {
        organizationId: data.organizationId,
        branch: data.branch,
        allowedToEdit: data.allowedToEdit,
      },
    };

    if (data.branch) {
      options.resolve = {
        currencies: () => {
          return this.CurrenciesApiService.getAllByBranchId(data.branch.id);
        },
        vatCodes: () => {
          return this.VatCodesApiService.getAllByBranchId(data.branch.id);
        },
        relations: () => {
          return this.ResourcesService.loadRelations('branch', data.branch, [
            'defaultCurrency',
            'defaultVatCode',
          ]);
        },
      };
    } else {
      options.locals.currencies = [];
      options.locals.vatCodes = [];
    }

    return this.$mdDialog.show(options);
  }
}

class BranchDialogController {
  constructor(
    $q,
    $filter,
    $mdDialog,
    HelperService,
    BranchesApiService,
    toasterService,
    branch,
    organizationId,
    currencies,
    vatCodes,
    allowedToEdit,
  ) {
    'ngInject';

    this.$q = $q;
    this.$filter = $filter;
    this.$mdDialog = $mdDialog;
    this.HelperService = HelperService;
    this.BranchesApiService = BranchesApiService;
    this.toasterService = toasterService;
    this.originalBranch = branch;
    this.organizationId = organizationId;
    this.allowedToEdit = allowedToEdit;

    this.validationErrors = [];
    this.branch = _.extend({}, this.originalBranch) || {};
    this.currencies = _.filter(currencies, { archived: false });
    this.vatCodes = _.filter(vatCodes, { archived: false });

    this.setDefaultCurrency(this.branch.defaultCurrencyId);
    this.setDefaultVatCode(this.branch.defaultVatCodeId);
  }

  save() {
    this.validationErrors = [];
    // Get any changes in branch info
    const changedBranchData = this.HelperService.getChangedData(
      this.originalBranch,
      this.branch,
      ['name', 'displayOrder', 'defaultCurrencyId', 'defaultVatCodeId'],
    );

    // If changes are found, update them
    if (!_.isEmpty(changedBranchData)) {
      this.$mdDialog.startLoadingSpinner();
      this.BranchesApiService.updateBranch(
        changedBranchData,
        this.originalBranch.id,
      )
        .then(
          () => {
            this.toasterService.success();
            this.$mdDialog.hide();
          },
          (error) => {
            this.validationErrors = error.errors;
          },
        )
        .finally(() => {
          this.$mdDialog.stopLoadingSpinner();
        });
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setDefaultCurrency(defaultCurrencyId) {
    if (defaultCurrencyId) {
      this.branch.defaultCurrencyId = defaultCurrencyId;
      this.branch.defaultCurrency = _.find(this.getCurrentCurrencies(), {
        id: defaultCurrencyId,
      });
    } else {
      this.branch.defaultCurrencyId = 0;
      this.branch.defaultCurrency = null;
    }
  }

  setDefaultVatCode(defaultVatCodeId) {
    if (defaultVatCodeId) {
      this.branch.defaultVatCodeId = defaultVatCodeId;
      this.branch.defaultVatCode = _.find(this.getCurrentVatCodes(), {
        id: defaultVatCodeId,
      });
    } else {
      this.branch.defaultVatCodeId = 0;
      this.branch.defaultVatCode = null;
    }
  }

  getCurrentCurrencies() {
    const currencies = _.clone(this.currencies);
    if (
      this.originalBranch.currency &&
      !_.some(currencies, { id: this.originalBranch.currency.id })
    ) {
      currencies.push(this.originalBranch.currency);
    }
    return currencies;
  }

  getCurrentVatCodes() {
    const vatCodes = _.clone(this.vatCodes);
    if (
      this.originalBranch.vatCode &&
      !_.some(vatCodes, { id: this.originalBranch.vatCode.id })
    ) {
      vatCodes.push(this.originalBranch.vatCode);
    }
    return vatCodes;
  }

  filterCurrencies(query) {
    return _.orderBy(
      _.filter(this.getCurrentCurrencies(), (c) =>
        this.HelperService.containsText(c, { name: query, code: query }),
      ),
      'name',
    );
  }

  filterVatCodes(query) {
    return _.orderBy(
      _.filter(this.getCurrentVatCodes(), (v) =>
        this.HelperService.containsText(v, { name: query, identifier: query }),
      ),
      ['displayOrder', 'name'],
    );
  }
}

angular
  .module('services.dialogs.branchDialog', [])
  .service('BranchDialogService', BranchDialogService)
  .controller('BranchDialogController', BranchDialogController);
