const link = (scope, element) => {
  const dom = element[0];
  if (scope.focusIf) {
    scope.$applyAsync(() => {
      dom.focus();
    });
  }
};

const focusIf = () => ({
  restrict: 'A',
  scope: {
    focusIf: '=',
  },
  link,
});

angular.module('directives.focusIf', []).directive('focusIf', focusIf);
