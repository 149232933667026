import { useMemo, useState } from 'react';

export function useRegexSearch() {
  const [search, setSearch] = useState('');
  const regexSearch = useMemo(
    () => RegExp(`.*${search.toLowerCase()}.*`),
    [search],
  );

  return { search, setSearch, regexSearch };
}
