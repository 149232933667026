// @ngInject
const UsersApiService = (DS, DSHttpAdapter, $q, ResourcesService) => ({
  getSelf() {
    const deferred = $q.defer();

    DSHttpAdapter.GET(`${DS.defaults.basePath}users/me`).then(
      (response) => {
        const user = DS.inject('user', response.data);
        if (user.organizationId) {
          DS.loadRelations('user', user, ['organization']).then(
            (user) => {
              deferred.resolve(user);
            },
            (error) => {
              deferred.reject(ResourcesService.handleError(error));
            },
          );
        } else {
          deferred.resolve(user);
        }
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getById(userId) {
    const deferred = $q.defer();

    DS.find('user', userId, { params: { organizationId: false } }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getByIds(userIds) {
    const deferred = $q.defer();
    const promises = [];
    const tempUserList = [];

    angular.forEach(userIds, (id) => {
      const dfd = $q.defer();
      promises.push(dfd.promise);

      DS.find('user', id, { params: { organizationId: false } }).then(
        (tempUser) => {
          tempUserList.push(tempUser);
          dfd.resolve();
        },
        (error) => {
          dfd.reject(error);
        },
      );
    });

    $q.all(promises).then(
      () => {
        deferred.resolve(tempUserList);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAll() {
    const deferred = $q.defer();

    DS.findAll('user', null, {
      bypassCache: true,
      params: { organizationId: false },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAllByOrganizationId(organizationId) {
    const deferred = $q.defer();

    DS.findAll('user', null, {
      useFilter: true,
      params: { organizationId },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  newUser(user) {
    const deferred = $q.defer();

    DS.create('user', user, { params: { organizationId: false } }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAllFromCache() {
    return DS.getAll('user');
  },
  updateContactInfo(data, userId) {
    const deferred = $q.defer();

    DS.update('user', userId, data, {
      cacheResponse: false,
      params: { organizationId: false },
    }).then(
      (response) => {
        const user = DS.get('user', userId);
        user.username = response.username;
        user.firstName = response.firstName;
        user.lastName = response.lastName;
        user.email = response.email;
        user.emailNotifications = response.emailNotifications;
        user.branchId = response.branchId;
        user.countryId = response.countryId;
        user.claims = response.claims;
        user.title = response.title;
        user.phone = response.phone;
        user.mobile = response.mobile;
        user.canEdit =
          _.intersection(user.claims, [
            'system_administrator',
            'company_administrator',
          ]).length > 0;
        user.archived = response.archived;
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  updatePassword(password, userId) {
    const deferred = $q.defer();

    DSHttpAdapter.PUT(`${DS.defaults.basePath}users/${userId}/password`, {
      password,
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  archiveUser: (userId) =>
    ResourcesService.update(
      'user',
      userId,
      { archived: true },
      {
        params: { organizationId: false },
        updatedFields: ['archived'],
      },
    ),
  deleteUser(userId) {
    const deferred = $q.defer();

    DS.destroy('user', userId, { params: { organizationId: false } }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
});

angular
  .module('resources.users', [])
  .factory('UsersApiService', UsersApiService);
