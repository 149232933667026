// @ngInject
const ExportArticleExcelDialogService = ($mdDialog, BranchesApiService) => ({
  showDialog: ({
    selectedArticles = null,
    selectedTemplate = null,
    organization = null,
    hideBranchIds = false,
    branchIds = [],
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ExportArticleExcelDialogController as vm',
      templateUrl:
        'services/dialogs/exportArticleExcelDialog/exportArticleExcelDialog.tpl.html',
      locals: {
        selectedArticles,
        selectedTemplate,
        hideBranchIds,
        branchIds,
      },
      resolve: {
        branches: () =>
          BranchesApiService.getAllByOrganizationId(organization.id),
      },
    }),
});

class ExportArticleExcelDialogController {
  constructor(
    $mdDialog,
    selectedArticles,
    selectedTemplate,
    hideBranchIds,
    branchIds,
    ExcelApiService,
    branches,
    $scope,
    $rootScope,
  ) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.validationErrors = [];
    this.ExcelApiService = ExcelApiService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.selectedArticles = selectedArticles;
    this.selectedTemplate = selectedTemplate;
    this.hideBranchIds = hideBranchIds;
    const articleBranchIds = _.uniq(
      _.flatMap(selectedArticles, (a) =>
        _.map(a.branches, (b) => (b.id !== undefined ? b.id : b)),
      ),
    );
    this.branches = _.filter(branches, (b) =>
      _.includes(branchIds.length === 0 ? articleBranchIds : branchIds, b.id),
    );
    this.exportTypes = [
      { id: 1, selected: false, name: 'Purchase price excl. fees' },
      { id: 2, selected: false, name: 'Purchase price inc. fees' },
      { id: 3, selected: false, name: 'Sales price' },
    ];

    this.headers = [
      { index: 1, name: 'TEXTS 1-7' },
      { index: 2, name: 'TEXTS 8-15' },
      { index: 3, name: 'TECHNICAL' },
      { index: 4, name: 'SUPPLIER' },
      { index: 5, name: 'NOTE PURCHASE' },
      { index: 6, name: 'NOTE SALES' },
      { index: 7, name: 'TIERS' },
      { index: 8, name: 'UNIT' },
      { index: 9, name: 'READY SAMPLING DATE' },
      { index: 10, name: 'STATUS' },
    ];
    this.priceHeaders = [
      { index: 1, name: 'MARGIN' },
      { index: 2, name: 'SAMPLE CHARGE' },
      { index: 3, name: 'SUR CHARGE BELOW MPQ' },
      { index: 4, name: 'MOLD CHARGE' },
      { index: 5, name: 'MPQ' },
    ];
    this.template = [
      { id: 1, text: 'All' },
      { id: 2, text: 'Purchase' },
      { id: 3, text: 'Sales' },
      { id: 4, text: 'Article list' },
    ];

    this.export = {
      name: null,
      exportTypes: [],
      branches: [...this.branches],
      headers: [],
      priceHeaders: [],
      articles: this.selectedArticles,
      mainSupplier: true,
      mainVariant: true,
      mainCurrency: true,
      includePictures: true,
      includeMatrix: true,
      template: null,
    };

    if (selectedTemplate != null) {
      this.export.template = selectedTemplate;
    }

    this.export.headers = _.clone(this.headers);
    this.export.priceHeaders = _.clone(this.priceHeaders);
    this.setTemplate();
    this.setupWatcher();
  }

  setupWatcher() {
    this.$scope.$watch(
      () => this.export.mainSupplier,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue === false) {
            this.export.headers = _.filter(
              this.headers,
              (headers) =>
                _.some(
                  this.export.headers,
                  (exportHeaders) => exportHeaders.index === headers.index,
                ) || headers.index === 4,
            );
          } else {
            this.export.headers = _.filter(
              this.export.headers,
              (header) => header.index !== 4,
            );
          }
        }
      },
    );
  }

  save() {
    this.validationErrors = [];
    _.forEach(this.exportTypes, (type) => {
      if (type.selected) {
        this.export.exportTypes.push(type);
      }
    });

    if (this.export.exportTypes.length === 0) {
      this.export.exportTypes.push({ id: 4 });
    }

    this.getExcelForSelected();
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  getExcelForSelected() {
    const data = {
      name: this.export.name,
      customerIds: _.chain(this.selectedArticles)
        .filter((s) => s.customerId)
        .map((s) => s.customerId)
        .value(),
      types: _.chain(this.export.exportTypes)
        .filter((e) => e.id)
        .map((e) => e.id)
        .value(),
      articleIds: _.chain(this.export.articles)
        .filter((a) => (a.id !== undefined ? a.id : a.articleId))
        .map((a) => (a.id !== undefined ? a.id : a.articleId))
        .value(),
      branchIds: _.chain(this.export.branches)
        .filter((b) => b.id)
        .map((b) => b.id)
        .value(),
      mainSupplier: this.export.mainSupplier,
      mainCurrency: this.export.mainCurrency,
      mainVariant: this.export.mainVariant,
      includePictures: this.export.includePictures,
      includeMatrix: this.export.includeMatrix,
      headerIndexes: _.chain(this.export.headers)
        .filter((h) => h.index)
        .map((h) => h.index)
        .value(),
      priceHeaderIndexes: _.chain(this.export.priceHeaders)
        .filter((h) => h.index)
        .map((h) => h.index)
        .value(),
    };

    this.$rootScope.$broadcast('activateExportMenu');

    this.ExcelApiService.generateExcel(data, 'articles')
      .then((response) => {
        window.location.href = this.ExcelApiService.getExcelUrl(response);
      })
      .finally(() => {
        this.$rootScope.$broadcast('deactivateExportMenu');
      });
    this.$mdDialog.hide();
  }

  setTemplate() {
    if (this.export.template === 1) {
      _.forEach(this.exportTypes, (type) => {
        type.selected = true;
      });
      this.export.mainSupplier = false;
      this.export.mainVariant = false;
      this.export.mainCurrency = false;
      this.export.includePictures = true;
      this.export.includeMatrix = true;
      this.export.name = 'Price enquiry';
      this.export.priceHeaders = _.clone(this.priceHeaders);
      this.export.headers = _.clone(this.headers);
    } else if (this.export.template === 2) {
      _.find(this.exportTypes, (type) => {
        if (type.id <= 2) {
          type.selected = true;
        } else {
          type.selected = false;
        }
      });
      this.export.mainSupplier = true;
      this.export.mainVariant = true;
      this.export.mainCurrency = true;
      this.export.includePictures = true;
      this.export.includeMatrix = true;
      this.export.name = 'Purchase price enquiry';
      this.export.headers = _.filter(this.headers, (header) => {
        return header.index !== 6;
      });
      this.export.priceHeaders = _.filter(this.priceHeaders, (priceHeader) => {
        return priceHeader.index !== 1;
      });
    } else if (this.export.template === 3) {
      _.find(this.exportTypes, (type) => {
        if (type.id <= 2) {
          type.selected = false;
        } else if (type.id === 3) {
          type.selected = true;
        }
      });
      this.export.mainSupplier = true;
      this.export.mainVariant = true;
      this.export.mainCurrency = true;
      this.export.includePictures = true;
      this.export.includeMatrix = true;
      this.export.name = 'Sales price enquiry';
      this.export.headers = _.filter(this.headers, (header) => {
        return header.index !== 2 && header.index !== 4 && header.index !== 5;
      });
      this.export.priceHeaders = _.filter(this.priceHeaders, (priceHeader) => {
        return priceHeader.index !== 1;
      });
    } else if (this.export.template === 4) {
      _.forEach(this.exportTypes, (type) => {
        type.selected = false;
      });
      this.export.mainSupplier = true;
      this.export.mainVariant = true;
      this.export.mainCurrency = true;
      this.export.includePictures = true;
      this.export.includeMatrix = true;
      this.export.name = 'Article list';
      this.export.headers = _.filter(this.headers, (header) => {
        return !(header.index >= 4 && header.index <= 7);
      });
      this.export.priceHeaders = [];
    }
  }
}

angular
  .module('services.dialogs.exportArticleExcelDialog', [])
  .service('ExportArticleExcelDialogService', ExportArticleExcelDialogService)
  .controller(
    'ExportArticleExcelDialogController',
    ExportArticleExcelDialogController,
  );
