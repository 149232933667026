import { Project, projectApi } from '@texas/api/endpoints/projectsApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface ArchiveProjectDialogProps extends SharedDisclosureProps {
  onArchive: (project: Project) => void;
  project: Project;
}

export function ArchiveProjectDialog(props: ArchiveProjectDialogProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('project.archiveProject', { name: props.project.name })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={projectApi.archiveProject}
      args={[props.project.id]}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPerformed={props.onArchive}
      onSuccessTitle={t('general.successfullyArchived')}
      onFailureTitle={t('general.archiveFailed')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
