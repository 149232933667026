import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { createContextValue } from '@texas/utils/contextState';
import { useState } from 'react';
import { dragAndDropEvents } from './events';

export function useDragAndDrop() {
  const [draggingData, setDraggingData] = useState<any | null>(null);

  function handleDragStart(event: DragStartEvent) {
    setDraggingData(event.active.data.current);
  }

  function handleDragEnd(event: DragEndEvent) {
    setDraggingData(null);
    dragAndDropEvents.onDrop.dispatch(event);
  }

  return { draggingData, handleDragStart, handleDragEnd };
}

export const [DragAndDropProvider, useDragAndDropState] =
  createContextValue<ReturnType<typeof useDragAndDrop>>();
