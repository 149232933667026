import { Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Accept, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';
import { FileRejections } from './FileRejections';

export function DropZone({
  accept,
  multiple = false,
  onUpload,
  validator,
}: {
  accept?: Accept;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  validator?: <T extends File>(
    file: T,
  ) => FileError | readonly FileError[] | null;
}) {
  const { t } = useTranslation();
  const [mouseInZone, setMouseInZone] = useState<boolean>(false);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>();

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleDrop,
      useFsAccessApi: false,
      accept,
      multiple,
      validator: validator,
    });

  function handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    setFileRejections(fileRejections);

    if (acceptedFiles.length) {
      onUpload(acceptedFiles);
    }
  }

  return (
    <Flex
      w="100%"
      h="100%"
      bg="inherit"
      border="2px"
      textAlign="center"
      borderColor={
        isDragReject ? 'red.300' : isDragActive ? 'gray.200' : 'gray.500'
      }
      _light={{
        borderColor: isDragReject
          ? 'red.500'
          : isDragActive
          ? 'gray.500'
          : 'gray.200',
      }}
      _hover={{
        cursor: 'pointer',
        borderColor: isDragReject ? 'red.300' : 'gray.200',
        _light: { borderColor: isDragReject ? 'red.500' : 'gray.500' },
      }}
      borderStyle="dashed"
      borderRadius={8}
      onMouseEnter={() => setMouseInZone(true)}
      onMouseLeave={() => setMouseInZone(false)}
      {...getRootProps()}
    >
      <Flex
        p={8}
        justifyContent="center"
        alignItems="center"
        direction="column"
        flexGrow={1}
      >
        {isDragReject && (
          <Icons.Cancel
            boxSize={7}
            color="red.300"
            _light={{ color: 'red.500' }}
          />
        )}
        {isDragActive && !isDragReject && (
          <Icons.UploadBoxOutline
            boxSize={7}
            color="gray.200"
            _light={{ color: 'gray.400' }}
          />
        )}
        {!isDragActive && (
          <Icons.FilePlusOutline
            boxSize={7}
            color="gray.200"
            _light={{ color: 'gray.400' }}
          />
        )}
        <Text ml={2} mt={2} variant="sub">
          {isDragReject
            ? t('fileBrowser.notValid')
            : isDragActive
            ? t('fileBrowser.dropToUpload')
            : mouseInZone
            ? t('fileBrowser.clickToUpload')
            : t('fileBrowser.dropFileToUpload')}
        </Text>
        {accept && (
          <Text variant="small" mt={2}>
            {t('fileBrowser.fileFormat', {
              formats: Object.keys(accept)
                .map((exts) => {
                  const index = exts.indexOf('/');
                  return exts.slice(index + 1);
                })
                .join(', '),
            })}
          </Text>
        )}
        <FileRejections fileRejections={fileRejections} />
        <input multiple={multiple} {...getInputProps()} />
      </Flex>
    </Flex>
  );
}
