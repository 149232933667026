import { ClaimType } from '@texas/api/endpoints/userApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { hasClaims } from '@texas/utils/helpers/claimHelpers';

interface ClaimRestrictProps {
  allow: ClaimType[];
  children: React.ReactNode;
}

export function ClaimRestrict(props: ClaimRestrictProps) {
  const session = useActiveSession();
  if (
    !hasClaims(
      session.currentUser.claims.map((c) => c.id),
      props.allow,
    )
  ) {
    return null;
  }

  return <>{props.children}</>;
}
