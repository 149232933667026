// @ngInject
const CurrencyDialogService = ($mdDialog) => ({
  showDialog: ({
    branch = null,
    currency = null,
    allowedToEdit = false,
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'CurrencyDialogController as vm',
      templateUrl: 'services/dialogs/currencyDialog/currencyDialog.tpl.html',
      locals: {
        branch,
        currency,
        allowedToEdit,
      },
    }),
});

class CurrencyDialogController {
  constructor(
    $q,
    $mdDialog,
    HelperService,
    CurrenciesApiService,
    BranchesApiService,
    toasterService,
    currency,
    branch,
    allowedToEdit,
  ) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.HelperService = HelperService;
    this.CurrenciesApiService = CurrenciesApiService;
    this.BranchesApiService = BranchesApiService;
    this.toasterService = toasterService;
    this.originalCurrency = currency;
    this.branch = branch;
    this.allowedToEdit = allowedToEdit;

    this.validationErrors = [];
    this.editMode = currency !== null;

    if (!this.editMode) {
      currency = {
        buyingRate: 1,
        sellingRate: 1,
      };
    }

    this.currency = angular.copy(currency);
    this.isDefaultCurrency = branch.defaultCurrencyId === currency.id;
  }

  save() {
    const promises = [];
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();

    if (this.editMode) {
      const changedCurrencyData = this.HelperService.getChangedData(
        this.originalCurrency,
        this.currency,
        ['name', 'code', 'buyingRate', 'sellingRate', 'archived'],
      );

      if (!_.isEmpty(changedCurrencyData)) {
        promises.push(
          this.CurrenciesApiService.updateCurrency(
            changedCurrencyData,
            this.currency.id,
          ),
        );
      }
    } else {
      promises.push(
        this.CurrenciesApiService.newCurrency(
          this.currency,
          this.branch.id,
        ).then(
          (newCurrency) => {
            if (this.isDefaultCurrency) {
              return this.setDefaultCurrency(newCurrency.id);
            }
          },
          (error) => this.$q.reject(error),
        ),
      );
    }
    this.$q
      .all(promises)
      .then(
        () => {
          this.toasterService.success();
          this.$mdDialog.hide();
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.$mdDialog.stopLoadingSpinner());
  }

  setDefaultCurrency(currencyId) {
    return this.BranchesApiService.updateBranch(
      { defaultCurrencyId: currencyId },
      this.branch.id,
    );
  }

  archive() {
    this.currency.archived = !this.currency.archived;
    this.save();
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.currencyDialog', [])
  .service('CurrencyDialogService', CurrencyDialogService)
  .controller('CurrencyDialogController', CurrencyDialogController);
