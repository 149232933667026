import { useToast } from '@chakra-ui/react';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ServerError } from '@texas/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compositionGroupEvents } from '../shared/events';
import { reactEvents } from '@bridge/reactEvents';

interface Props {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

interface CompositionLocationData {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

export function useVersionOverview({
  cellX,
  cellY,
  variantId,
  supplierId,
  branchId,
}: Props) {
  const {
    refetch,
    data: versions,
    loading: versionsLoading,
  } = useApiResource(compositionGroupApi.overview);
  const toast = useToast();
  const { t } = useTranslation();
  const locationData = useRef<CompositionLocationData>({
    variantId,
    cellX,
    cellY,
    supplierId,
    branchId,
  });

  useEffect(() => {
    locationData.current = {
      variantId,
      cellX,
      cellY,
      supplierId,
      branchId,
    };
  }, [variantId, cellX, cellY, supplierId, branchId]);

  const refetchVersions = useCallback(() => {
    refetch({ variantId, branchId, supplierId, cellX, cellY });
  }, [branchId, cellX, cellY, refetch, supplierId, variantId]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    return reactEvents.productApprovalsCreated.sub(() => {
      refetchVersions();
    });
  }, [refetchVersions]);

  useEffect(() => {
    return compositionGroupEvents.optionArchivedOrRestored.sub(() => {
      refetchVersions();
    });
  }, [refetchVersions]);

  useEffect(() => {
    return compositionGroupEvents.compositionFileUpdated.sub(() => {
      refetchVersions();
    });
  }, [refetchVersions]);

  const createGroupRequest = async (basedOnId: number | undefined) => {
    setIsCreating(true);
    await request(
      compositionGroupApi.addGroup,
      [{ variantId, cellX, cellY, supplierId, branchId, basedOnId }],
      (_) => {
        refetchVersions();
        compositionGroupEvents.compositionGroupsChanged.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
    setIsCreating(false);
  };
  const copyCompositionGroupRequest = async (
    fromId: number,
    basedOnId: number | undefined,
    replaceId: number | undefined,
  ) => {
    setIsCreating(true);
    await request(
      compositionGroupApi.copyGroup,
      [
        fromId,
        {
          ...locationData.current,
          replaceCompositionGroupId: replaceId,
          basedOnId,
        },
      ],
      (_) => {
        refetchVersions();
        compositionGroupEvents.compositionGroupsChanged.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.copyFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
    setIsCreating(false);
  };
  const setBasedOnRequest = async (id: number, basedOnId: number) => {
    await request(
      compositionGroupApi.setBasedOn,
      [id, basedOnId],
      (_) => {
        refetchVersions();
      },
      (error: ServerError) => {
        toast({
          title: t('general.actionFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const archiveGroupRequest = async (id: number) => {
    await request(
      compositionGroupApi.archive,
      [id],
      (_) => {
        refetchVersions();
        compositionGroupEvents.compositionGroupsChanged.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  useEffect(() => {
    refetch({ variantId, cellX, cellY, supplierId, branchId });
  }, [branchId, cellX, cellY, refetch, supplierId, variantId]);

  const productApprovalsCount = (state: ProductApprovalState, id: number) => {
    return (
      versions
        ?.find((v) => v.id === id)
        ?.productApprovals?.filter(
          (x) => convertToEnum(ProductApprovalState, x.state) == state,
        ).length ?? 0
    );
  };

  const anyProductApproval = (id: number) =>
    (versions?.find((v) => v.id === id)?.productApprovals?.length ?? 0) > 0;

  return {
    versions,
    versionsLoading,
    createGroupRequest,
    copyCompositionGroupRequest,
    productApprovalsCount,
    setBasedOnRequest,
    locationData,
    anyProductApproval,
    archiveGroupRequest,
    refetchVersions,
    isCreating,
  };
}
