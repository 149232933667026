// @ngInject
const uploadInstance = () => ({
  restrict: 'A',
  scope: {
    instanceName: '@uploadInstance',
    finalizeUrl: '=',
    replace: '@',
    keepBoth: '@',
    single: '@',
  },
  controller: 'uploadInstanceController',
});

class UploadInstanceController {
  constructor($scope, $attrs, $parse, UploaderService) {
    'ngInject';
    this.instanceName = $scope.instanceName;
    this.finalizeUrl = $scope.finalizeUrl;
    this.replace = $scope.replace;
    this.keepBoth = $scope.keepBoth;
    this.single = $scope.single;

    const options = {};
    options.finalizeUrl = this.finalizeUrl || false;
    options.replace = this.replace || false;
    options.keepBoth = this.keepBoth || false;
    options.single = this.single || false;

    this.instance = UploaderService.instance(this.instanceName, options);
  }
}

angular
  .module('directives.uploadInstance', [
    'uploadButton',
    'uploadArea',
    'uploadPreventDrop',
    'uploadImagePaste',
  ])
  .controller('uploadInstanceController', UploadInstanceController)
  .directive('uploadInstance', uploadInstance);
