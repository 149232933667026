import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { FileLink, filesApi } from '@texas/api/endpoints/filesApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { clientEndpoints } from '@texas/clientEndpoints';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { DocumentDropzone } from '@texas/components/shared/dropzone/DocumentDropzone';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { notImageValidator } from '@texas/components/shared/dropzone/shared';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { FileUpload } from '@texas/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface CompositionFileProps extends SharedDisclosureProps {
  fileId?: number;
  onRemoveFileOpen: () => void;
  onFileBrowserOpen: () => void;
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  uploadFailed: boolean;
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
}

export function DocumentFileModal({
  fileId,
  onRemoveFileOpen,
  onFileBrowserOpen,
  onUpload,
  isUploading,
  uploadFailed,
  fileUploads,
  abortFileUpload,
  onClose,
  isOpen,
}: CompositionFileProps) {
  const { t } = useTranslation();

  const {
    data: file,
    loading,
    set,
    refetch,
    error,
  } = useApiResource(filesApi.getFileLink);

  useEffect(() => {
    if (fileId) refetch(fileId);
    else set(null);
  }, [fileId, refetch, set]);

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontWeight="bold">
            {t('composition.files.layout')}
          </ModalHeader>
          <ModalBody pb={6}>
            {loading && <Spinner />}
            {error && <ErrorDetails error={error} />}
            {file && (
              <Flex direction="column" w="100%" gap={4}>
                <Flex minW="50%" maxW="fit-content">
                  <DocumentDropzone
                    document={file}
                    allowEdit={true}
                    onFileBrowserOpen={onFileBrowserOpen}
                    onRemovefileOpen={onRemoveFileOpen}
                    onUpload={(files) => onUpload(files)}
                    isUploading={isUploading}
                    uploadFailed={uploadFailed}
                    fileUploads={fileUploads}
                    abortFileUpload={abortFileUpload}
                  />
                </Flex>
                {file.extension === '.pdf' && <PreviewPdf file={file} />}
              </Flex>
            )}
            {!file && !loading && (
              <Flex direction="column" gap={4}>
                <Text fontStyle="italic" pl={2}>
                  {t('composition.files.noLayout')}
                </Text>
                <FileDropzone
                  isUploading={isUploading}
                  uploadFailed={uploadFailed}
                  fileUploads={fileUploads}
                  abortFileUpload={abortFileUpload}
                  onUpload={(files) => onUpload(files)}
                  onFileBrowserOpen={onFileBrowserOpen}
                  validator={notImageValidator}
                />
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function PreviewPdf({ file }: { file: FileLink }) {
  const {
    data: documentSrc,
    loading,
    refetch,
    error,
  } = useApiResource(filesApi.previewPdf);

  useEffect(() => {
    refetch(file.identifier);
  }, [file.identifier, refetch]);

  return (
    <>
      {loading && <Spinner />}
      {error && <ErrorDetails error={error} />}
      {documentSrc && !error && (
        <iframe
          title="Preview document"
          id={file.identifier}
          height="600"
          src={clientEndpoints.previewPdf(file.identifier)}
        />
      )}
    </>
  );
}
