import {
  Modal,
  ModalContent,
  ModalOverlay,
  GridItem,
  Flex,
  Text,
  Box,
  Spinner,
  ModalBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { createContext, RefObject, useEffect } from 'react';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionGroupView } from '../shared/group/CompositionGroupView';
import { VersionItem } from './VersionItem';
import { NewVersion } from './NewVersion';
import { useVersionOverview } from './useVersionOverview';
import { CompositionGroupOverview } from '@texas/api/endpoints/compositionGroupApi';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { compositionGroupEvents } from '../shared/events';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';
import { PreviousCompositionGroupsModal } from '../shared/group/PreviousCompositionGroupsModal';
import { CompositionLocationData } from '../shared/hooks/useCompositionGroup';

interface Props {
  variantId: number;
  supplierId: number;
  branchId: number;
  supplierName: string;
  cellX?: number;
  cellY?: number;
  col: number;
  options: CompositionGroupOverview[];
  gridRef: RefObject<HTMLDivElement>;
}

export const VersionsContext = createContext<ReturnType<
  typeof useVersionOverview
> | null>(null);

export function VersionsGridOverview({
  variantId,
  options,
  cellX,
  cellY,
  supplierId,
  supplierName,
  branchId,
  gridRef,
  col,
}: Props) {
  const useVersions = useVersionOverview({
    cellX,
    cellY,
    variantId,
    supplierId,
    branchId,
  });

  const { versions, versionsLoading } = useVersions;
  const { t } = useTranslation();
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();
  const {
    value: previousGroupsLocationValue,
    isOpen: isShowPreviousOpen,
    onClose: onShowPreviousClose,
    onOpen: onShowPreviousOpen,
  } = useValueDisclosure<CompositionLocationData>();

  useEffect(() => {
    return compositionGroupEvents.versionArchivedOrRestored.sub(
      (supplierId) => {
        onClose();
        if (useVersions.locationData.current.supplierId === supplierId) {
          useVersions.refetchVersions();
        }
      },
    );
  }, [useVersions, onClose]);

  return (
    <VersionsContext.Provider value={useVersions}>
      <GridItem
        as={Flex}
        flexDir="column"
        colStart={col}
        alignItems="center"
        rowStart={1}
        rowEnd={2 + options.length}
        pb={4}
      >
        <Box
          w={0.5}
          flexGrow={1}
          transition="background 200ms ease"
          bg={`linear-gradient(180deg, var(--supplier-${supplierId}-bg, #4B4B4B), transparent)`}
          _light={{
            bg: `linear-gradient(180deg, var(--supplier-${supplierId}-bg, #c7c7c7), transparent)`,
          }}
        />
      </GridItem>
      <GridItem colStart={col} rowStart={1}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={2}
          bg="texas.bg.900"
          _light={{ bg: 'gray.100' }}
          borderRadius="md"
          border={`var(--supplier-${supplierId}-border, 1px solid transparent)`}
          transition="border 100ms ease"
        >
          <Text fontSize="sm" fontWeight="bold">
            {supplierName}
          </Text>
          {versionsLoading && <Spinner size="sm" />}
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              size="sm"
              icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
            />
            <MenuList>
              <MenuItem
                onClick={() =>
                  onShowPreviousOpen({
                    variantId,
                    cellX,
                    cellY,
                    branchId,
                    supplierId,
                  })
                }
                icon={<Icons.Archive boxSize={defaultIconSize} />}
              >
                {t('composition.showArchivedVersions')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </GridItem>
      {options.map((o, i) => {
        return (
          <GridItem key={o.id} py={4} px={2} colStart={col} rowStart={i + 2}>
            <Flex flexDir="column" gap={1} justifyContent="center" h="full">
              {versions
                ?.filter((x) => x.basedOnGroupId === o.id)
                .map((x) => {
                  return (
                    <VersionItem
                      key={x.id}
                      gridRef={gridRef}
                      options={options}
                      basedOnOption={o}
                      group={x}
                      onClick={() => onOpen(x.id)}
                    />
                  );
                })}
              <NewVersion gridRef={gridRef} option={o} />
            </Flex>
          </GridItem>
        );
      })}
      <GridItem py={4} px={2} colStart={col} rowStart={options.length + 2}>
        <Flex flexDir="column" gap={1}>
          {versions
            ?.filter((x) => !options.some((o) => x.basedOnGroupId === o.id))
            .map((x) => {
              return (
                <VersionItem
                  key={x.id}
                  gridRef={gridRef}
                  options={options}
                  group={x}
                  onClick={() => onOpen(x.id)}
                />
              );
            })}
        </Flex>
      </GridItem>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="auto" maxW="fit-content">
          <ModalBody>{value && <InnerModal id={value} />}</ModalBody>
        </ModalContent>
      </Modal>
      {previousGroupsLocationValue && (
        <PreviousCompositionGroupsModal
          locationData={previousGroupsLocationValue}
          isOpen={isShowPreviousOpen}
          onClose={onShowPreviousClose}
        />
      )}
    </VersionsContext.Provider>
  );
}

function InnerModal({ id }: { id: number }) {
  const { refetchVersions } = useActiveContext(VersionsContext);

  useEffect(() => {
    return compositionGroupEvents.compositionGroupsChanged.sub(refetchVersions);
  }, [refetchVersions]);

  return (
    <>
      <CompositionGroupView
        componentSettings={{ expandedDefault: true }}
        variantId={0}
        compositionGroupId={id}
      />
    </>
  );
}
