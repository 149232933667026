import { ReactElement } from 'react';
import { SignalDispatcher } from 'ste-signals';

export interface ReactElementWithContainer {
  element: ReactElement;
  container: HTMLElement;
}

let elements: readonly ReactElementWithContainer[] = [];
const updateSignal = new SignalDispatcher();

export function getElements() {
  return elements;
}

export function addElement(element: ReactElementWithContainer) {
  if (elements.some((e) => e.container === element.container)) {
    throw new Error('Container already has an element');
  }

  elements = [...elements, element];
  updateSignal.dispatch();
}

export function removeElement(element: ReactElementWithContainer) {
  elements = elements.filter((e) => e !== element);
  updateSignal.dispatch();
}

export function subscribe(cb: () => void) {
  return updateSignal.subscribe(cb);
}
