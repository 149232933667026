import { reactEvents } from '../../../../../bridge/reactEvents';
import { fileEvents } from '../../../../../react/components/shared/dropzone/events';

// @ngInject
const MatrixCellDialogService = ($mdDialog) => ({
  showDialog: ({
    displaySustainabilityForm = false,
    matrixValue = null,
    label = 'cell',
    variant = null,
    cellInformation = null,
    fieldInformation = null,
    branchId = null,
    setRowOrCol = null,
    sharedToBranches = null,
    textDiffers = null,
    nodeDiffers = null,
    clearField = null,
    clearNode = null,
    article = null,
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'MatrixCellDialogController as vm',
      templateUrl:
        'services/dialogs/matrixCellDialog/matrixCellDialog.tpl.html',
      locals: {
        displaySustainabilityForm,
        matrixValue,
        label,
        variant,
        cellInformation,
        fieldInformation,
        branchId,
        setRowOrCol,
        sharedToBranches,
        textDiffers,
        nodeDiffers,
        clearField,
        clearNode,
        article,
      },
    }),

  showHeaderDialog: ({
    displaySustainabilityForm = false,
    matrixValue = null,
    label = 'header',
    variant = null,
    cellInformation = null,
    fieldInformation = null,
    branchId = null,
    setRowOrCol = null,
    sharedToBranches = null,
    textDiffers = null,
    nodeDiffers = null,
    clearField = null,
    clearNode = null,
    article = null,
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'MatrixCellDialogController as vm',
      templateUrl:
        'services/dialogs/matrixCellDialog/matrixHeaderDialog.tpl.html',
      locals: {
        displaySustainabilityForm,
        matrixValue,
        label,
        variant,
        cellInformation,
        fieldInformation,
        branchId,
        setRowOrCol,
        sharedToBranches,
        textDiffers,
        nodeDiffers,
        clearField,
        clearNode,
        article,
      },
    }),
});

class MatrixCellDialogController {
  constructor(
    $scope,
    $q,
    $mdDialog,
    ArticlesApiService,
    loadSpinnerService,
    UploaderService,
    AuthService,
    FilesApiService,
    displaySustainabilityForm,
    matrixValue,
    variant,
    HelperService,
    OrganizationsApiService,
    label,
    cellInformation,
    fieldInformation,
    branchId,
    setRowOrCol,
    sharedToBranches,
    textDiffers,
    nodeDiffers,
    clearField,
    clearNode,
    article,
  ) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.AuthService = AuthService;
    this.ArticlesApiService = ArticlesApiService;
    this.HelperService = HelperService;
    this.OrganizationsApiService = OrganizationsApiService;
    this.FilesApiService = FilesApiService;
    this.displaySustainabilityForm = displaySustainabilityForm;
    this.matrixValue = matrixValue;
    this.loadSpinnerService = loadSpinnerService;
    this.variant = variant;
    this.label = label;
    this.cellInformation = cellInformation;
    this.fieldInformation = fieldInformation;
    this.setRowOrCol = setRowOrCol;
    this.sharedToBranches = sharedToBranches;
    this.textDiffers = textDiffers;
    this.nodeDiffers = nodeDiffers;
    this.clearField = clearField;
    this.clearNode = clearNode;
    this.article = article;
    const x = matrixValue.matrixNodeValueXId;
    const y = matrixValue.matrixNodeValueYId;
    this.branchId = branchId;
    this.currentUser = {};
    this.validationErrors = [];
    this.materials = [];
    this.techniques = [];
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);
    this.photoInstance = UploaderService.instance('cellDialogPhotoInstance', {
      finalizeUrl:
        `variants/${variant.id}/photo` +
        `?matrixNodeValueXId=${x}&matrixNodeValueYId=${y}`,
      onDone: (success, file) => this.photoUploadDone(success, file),
      keepBoth: true,
      single: true,
      force: true,
      disabled: !this.currentUserCanEdit,
      validate: (file) => this.validateImage(file),
    });
    this.locked = false;

    this.lockedStateChanged = reactEvents.lockedStateChanged.subscribe(
      (lockedState) => {
        this.locked = lockedState.locked;
        $scope.$apply();
      },
    );

    this.photoChangedEvent = fileEvents.fileChanged.subscribe((file) => {
      this.matrixValue.photoId = file.id;
      this.matrixValue.photo = file;
    });

    this.photoDeletedEvent = fileEvents.fileDeleted.subscribe(() => {
      this.matrixValue.photoId = 0;
      this.matrixValue.photo = null;
    });

    this.loadSpinnerService.start('mainSpinner');
    this.AuthService.getUser()
      .then((user) => {
        this.currentUser = user;
        this.$q.all([this.loadTechniques(), this.loadMaterials()]).then(() => {
          if (this.fieldInformation) {
            this.fieldInformation.technique = _.find(this.techniques, {
              id: this.fieldInformation.techniqueId,
            });
            this.fieldInformation.material = _.find(this.materials, {
              id: this.fieldInformation.materialId,
            });
          }
          if (this.cellInformation) {
            this.cellInformation.technique = _.find(this.techniques, {
              id: this.cellInformation.techniqueId,
            });
            this.cellInformation.material = _.find(this.materials, {
              id: this.cellInformation.materialId,
            });
          }
        });
      })
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));

    $scope.$watch(
      () => this.photoInstance.active,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            loadSpinnerService.start('matrixCellPhotoSpinner');
          } else {
            loadSpinnerService.stop('matrixCellPhotoSpinner', {
              force: true,
            });
          }
        }
      },
    );

    $scope.$on('$destroy', () => {
      this.lockedStateChanged();
      this.photoChangedEvent();
      this.photoDeletedEvent();
    });
  }

  close() {
    if (this.setRowOrCol) {
      this.setRowOrCol(this.fieldInformation);
    }
    if (this.sharedToBranches && this.branchId === null) {
      this.sharedToBranches();
    }
    this.$mdDialog.cancel();
  }

  setFieldTechnique(techniqueId) {
    if (techniqueId) {
      this.fieldInformation.techniqueId = techniqueId;
      this.fieldInformation.technique = _.find(this.techniques, {
        id: techniqueId,
      });
    } else {
      this.fieldInformation.techniqueId = 0;
      this.fieldInformation.technique = null;
    }
  }

  setFieldMaterial(materialId) {
    if (materialId) {
      this.fieldInformation.materialId = materialId;
      this.fieldInformation.material = _.find(this.materials, {
        id: materialId,
      });
    } else {
      this.fieldInformation.materialId = 0;
      this.fieldInformation.material = null;
    }
  }

  setTechnique(techniqueId) {
    if (techniqueId) {
      this.cellInformation.techniqueId = techniqueId;
      this.cellInformation.technique = _.find(this.techniques, {
        id: techniqueId,
      });
    } else {
      this.cellInformation.techniqueId = 0;
      this.cellInformation.technique = null;
    }
  }

  setMaterial(materialId) {
    if (materialId) {
      this.cellInformation.materialId = materialId;
      this.cellInformation.material = _.find(this.materials, {
        id: materialId,
      });
    } else {
      this.cellInformation.materialId = 0;
      this.cellInformation.material = null;
    }
  }

  loadTechniques() {
    this.techniques.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.OrganizationsApiService.loadTechniques(
      this.currentUser.organizationId,
    )
      .then((techniques) => (this.techniques = techniques))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadMaterials() {
    this.materials.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.OrganizationsApiService.loadMaterials(
      this.currentUser.organizationId,
    )
      .then((materials) => (this.materials = materials))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  filterTechniques(query) {
    return _.orderBy(
      _.filter(this.techniques, (t) =>
        this.HelperService.containsText(t, { code: query, value: query }),
      ),
      'code',
    );
  }

  filterMaterials(query) {
    return _.orderBy(
      _.filter(this.materials, (m) =>
        this.HelperService.containsText(m, { code: query, value: query }),
      ),
      'code',
    );
  }
}

angular
  .module('services.dialogs.matrixCellDialog', [])
  .service('MatrixCellDialogService', MatrixCellDialogService)
  .controller('MatrixCellDialogController', MatrixCellDialogController);
