// @ngInject
const MatrixNodesApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('matrixNode', {
      params: { organizationId },
    }),

  getById: (matrixNodeId) =>
    ResourcesService.getById('matrixNode', matrixNodeId, {
      params: { organizationId: false },
    }),

  newMatrixNode: (matrixNode, organizationId) =>
    ResourcesService.create('matrixNode', matrixNode, {
      params: { organizationId },
    }),

  updateMatrixNode: (data, matrixNodeId) =>
    ResourcesService.update('matrixNode', matrixNodeId, data, {
      params: { organizationId: false },
      updatedFields: [
        'name',
        'displayOrder',
        'archived',
        'valueMinLength',
        'valueMaxLength',
        'matrixNodeValues',
      ],
    }),
});

angular
  .module('resources.matrixNodes', [])
  .factory('MatrixNodesApiService', MatrixNodesApiService);
