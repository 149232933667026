import {
  Flex,
  Box,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useDisclosure,
  Spinner,
  ModalBody,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OptionItem } from './OptionItem';
import { CompositionGroupOverview } from '@texas/api/endpoints/compositionGroupApi';
import { createContext, useEffect } from 'react';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionGroupView } from '../shared/group/CompositionGroupView';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { useVersionOverview } from '../versionOverview/useVersionOverview';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { ConfirmCopy } from '../shared/group/ConfirmCopy';
import { compositionGroupEvents } from '../shared/events';
import { PreviousCompositionGroupsModal } from '../shared/group/PreviousCompositionGroupsModal';
import { CompositionLocationData } from '../shared/hooks/useCompositionGroup';

interface Props {
  variantId: number;
  label?: string;
  cellX?: number;
  cellY?: number;
  baseOptions?: CompositionGroupOverview[];
  onLoaded: (options: CompositionGroupOverview[]) => void;
}

export const OptionsContext = createContext<ReturnType<
  typeof useVersionOverview
> | null>(null);

export function OptionsOverview({
  variantId,
  cellX,
  cellY,
  label,
  baseOptions,
  onLoaded,
}: Props) {
  const options = useVersionOverview({
    variantId,
    cellX,
    cellY,
  });
  const { t } = useTranslation();
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();

  useEffect(() => {
    if (!options.versions) return;
    onLoaded(options.versions);
  }, [options.versions, onLoaded]);

  useEffect(() => {
    return compositionGroupEvents.optionArchivedOrRestored.sub(() => {
      onClose();
    });
  }, [onClose, options]);

  useEffect(() => {
    if (!cellX || !cellY) return;
    return compositionGroupEvents.compositionCellOptionCopied.sub(
      ({ cellX: x, cellY: y }) => {
        if (cellX !== x && cellY !== y) return;
        options.refetchVersions();
      },
    );
  }, [cellX, cellY, options]);

  return (
    <OptionsContext.Provider value={options}>
      <Flex flexDir="column" gap={2}>
        <Header
          baseOptions={baseOptions}
          forCells={!!(cellX && cellY)}
          label={label}
          variantId={variantId}
          cellX={cellX}
          cellY={cellY}
        />
        {options.versions?.length === 0 && (
          <Text variant="sub">{t('composition.noOptions')}</Text>
        )}
        {options.versions?.map((x) => (
          <OptionItem key={x.id} group={x} onClick={() => onOpen(x.id)} />
        ))}
      </Flex>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="auto" maxW="fit-content">
          <ModalBody>{value && <InnerModal id={value} />}</ModalBody>
        </ModalContent>
      </Modal>
    </OptionsContext.Provider>
  );
}

function InnerModal({ id }: { id: number }) {
  return (
    <>
      <CompositionGroupView
        variantId={0}
        compositionGroupId={id}
        componentSettings={{ expandedDefault: true }}
      />
    </>
  );
}

function Header({
  forCells,
  baseOptions,
  label,
  variantId,
  cellX,
  cellY,
}: {
  forCells: boolean;
  baseOptions?: CompositionGroupOverview[];
  label?: string;
  variantId: number;
  cellX?: number;
  cellY?: number;
}) {
  const { t } = useTranslation();
  const {
    createGroupRequest,
    versionsLoading,
    copyCompositionGroupRequest,
    versions,
  } = useActiveContext(OptionsContext);
  const {
    isOpen: isNewGroupOpen,
    onClose: onNewGroupClose,
    onOpen: onNewGroupOpen,
  } = useDisclosure();
  const { isOpen, onClose, onOpen, value } = useValueDisclosure<{
    version: number;
    id: number;
    title: string;
  }>();
  const {
    value: previousGroupsLocationValue,
    isOpen: isShowPreviousOpen,
    onClose: onShowPreviousClose,
    onOpen: onShowPreviousOpen,
  } = useValueDisclosure<CompositionLocationData>();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={2}
      bg="texas.bg.900"
      _light={{ bg: 'gray.100' }}
      borderRadius="md"
    >
      <Box>
        <Text fontSize="sm" fontWeight="bold">
          {forCells
            ? t('composition.cellOptions')
            : t('composition.variantOptions')}
        </Text>
        {label && (
          <Text fontSize="sm" color="gray.400">
            {label}
          </Text>
        )}
      </Box>

      {versionsLoading && <Spinner size="sm" />}
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          size="sm"
          icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
        />
        <MenuList>
          <MenuItem
            icon={<Icons.Plus boxSize={defaultIconSize} />}
            onClick={() => {
              if (versions?.length === 0 && !versionsLoading) {
                createGroupRequest(undefined);
                return;
              }

              onNewGroupOpen();
            }}
          >
            {t('composition.newOption')}
          </MenuItem>
          {baseOptions?.map((x) => (
            <MenuItem
              key={x.id}
              icon={<Icons.ContentCopy boxSize={defaultIconSize} />}
              onClick={() =>
                onOpen({
                  version: x.version,
                  id: x.id,
                  title: t('composition.copyOptionNr', { nr: x.version }),
                })
              }
            >
              {t('composition.copyFromOption', { nr: x.version })}
            </MenuItem>
          ))}

          <MenuItem
            onClick={() => onShowPreviousOpen({ variantId, cellX, cellY })}
            icon={<Icons.Archive boxSize={defaultIconSize} />}
          >
            {t('composition.showArchivedOptions')}
          </MenuItem>
        </MenuList>
      </Menu>
      <VerifyDialogWithFunction
        headerTitle={t('composition.createNewGroup')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={() => createGroupRequest(undefined)}
        isOpen={isNewGroupOpen}
        onClose={onNewGroupClose}
      >
        {t('composition.newOptionConfirm')}
      </VerifyDialogWithFunction>
      {value && (
        <ConfirmCopy
          title={value.title}
          isOpen={isOpen}
          onClose={onClose}
          compositionGroupId={value.id}
          onConfirm={(id) => {
            onClose();
            copyCompositionGroupRequest(id, value.id, undefined);
          }}
        />
      )}
      {previousGroupsLocationValue && (
        <PreviousCompositionGroupsModal
          locationData={previousGroupsLocationValue}
          isOpen={isShowPreviousOpen}
          onClose={onShowPreviousClose}
        />
      )}
    </Flex>
  );
}
