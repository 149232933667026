import { IconButton } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { Icons } from '../Icons';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';

interface TexasDrawerCloseButtonProps {
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

export function TexasDrawerCloseButton(props: TexasDrawerCloseButtonProps) {
  return (
    <IconButton
      variant="texas-light"
      pos="absolute"
      right="1em"
      top="1em"
      aria-label="Close drawer"
      icon={<Icons.Close boxSize={5} />}
      onClick={() => props.setModalState(ModalState.Closed)}
    />
  );
}
