// @ngInject
const SuppliersApiService = ($q, ResourcesService) => ({
  getAllByOrganizationId(organizationId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.organizationId = organizationId;
    return ResourcesService.getAll('supplier', options);
  },

  getAllByBranchId(branchId, options) {
    options = options || {};
    options.endpoint = `/branches/${branchId}/suppliers`;
    return ResourcesService.getAll('supplier', options);
  },

  getById(supplierId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.organizationId = false;
    return ResourcesService.getById('supplier', supplierId, options);
  },

  newSupplier(supplier, organizationId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.organizationId = organizationId;
    return ResourcesService.create('supplier', supplier, options);
  },

  updateSupplier(data, supplierId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.organizationId = false;
    options.updatedFields = [
      'name',
      'email',
      'archived',
      'branchData',
      'termsOfPayment',
      'dmixIdentifier',
    ];
    return ResourcesService.update('supplier', supplierId, data, options);
  },
});

angular
  .module('resources.suppliers', [])
  .factory('SuppliersApiService', SuppliersApiService);
