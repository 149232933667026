import { createColumnHelper } from '@tanstack/react-table';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { TFunction } from 'i18next';
import { Box, Image, Link } from '@chakra-ui/react';
import placeholderImg from '@texas/assets/placeholder-50.png';
import {
  SearchVariantItem,
  ArticleState,
  ArticleProcess,
} from '@texas/api/endpoints/searchApi';
import { Icons } from '../shared/Icons';
import { StateIndicator } from '../shared/indicators/StateIndicator';
import { StepsIndicator } from '../shared/indicators/StepsIndicator';
import { clientEndpoints } from '@texas/clientEndpoints';

export const TableColumns = (t: TFunction) => {
  const columnHelper = createColumnHelper<SearchVariantItem>();
  const columns = [
    columnHelper.accessor('imageIdentifier', {
      cell: (info) => (
        <>
          <Box data-group={true} position="relative">
            <Box position="absolute" width="100%" zIndex="1" height="100%" />
            <Image
              position="relative"
              zIndex="1"
              _groupHover={{
                transform: info.getValue()
                  ? 'scale(4) translateX(calc(100% / 2))'
                  : undefined,
              }}
              css={{
                transition: info.getValue()
                  ? 'transform 200ms ease'
                  : undefined,
              }}
              boxSize="12"
              src={
                info.getValue()
                  ? clientEndpoints.previewImage(info.getValue(), 256)
                  : undefined
              }
              fallbackSrc={placeholderImg}
              objectFit="contain"
            />
          </Box>
        </>
      ),
      header: t('general.photo'),
      enableSorting: false,
    }),
    columnHelper.accessor('mainVariant', {
      cell: (info) => (info.getValue() ? <Icons.Checkmark /> : ''),
      header: t('general.main'),
      enableSorting: false,
    }),
    columnHelper.accessor('customerName', {
      cell: (info) => (
        <Link href={`#/customers/${info.row.original.customerId}`}>
          {info.getValue()} - {info.row.original.customerIdentifier}
        </Link>
      ),
      header: t('general.brand'),
      enableSorting: false,
    }),
    columnHelper.accessor('concept', {
      cell: (info) => (
        <Link href={`#/concepts/${info.row.original.conceptId}`}>
          {info.getValue()}
        </Link>
      ),
      header: t('general.concept'),
      enableSorting: false,
    }),
    columnHelper.accessor('itemCode', {
      cell: (info) => (
        <Link href={`#/articles/${info.row.original.articleId}`}>
          {info.getValue()}
        </Link>
      ),
      header: t('general.itemCode'),
    }),
    columnHelper.accessor('variantName', {
      cell: (info) => (
        <Link
          href={`#/articles/${info.row.original.articleId}/variants/${info.row.original.variantId}/general/garp`}
        >
          {info.getValue()}
        </Link>
      ),
      header: t('variant.variant'),
      enableSorting: true,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.description'),
      enableSorting: false,
    }),
    columnHelper.accessor('color', {
      cell: (info) => info.getValue(),
      header: t('general.color'),
      enableSorting: false,
    }),
    columnHelper.accessor('size', {
      cell: (info) => info.getValue(),
      header: t('general.size'),
      enableSorting: false,
    }),
    columnHelper.accessor('productGroup', {
      cell: (info) => info.getValue(),
      header: t('general.productGroup'),
      enableSorting: true,
    }),
    columnHelper.accessor('material', {
      cell: (info) => info.getValue(),
      header: t('general.material'),
      enableSorting: false,
    }),
    columnHelper.accessor('created', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.created'),
      enableSorting: true,
    }),
    columnHelper.accessor('sampleReadyDate', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.sampleReadyDate'),
      enableSorting: true,
    }),
    columnHelper.accessor('branch', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      enableSorting: false,
    }),
    columnHelper.accessor('state', {
      cell: (info) => (
        <StateIndicator
          state={
            ArticleState[
              info.getValue() as unknown as keyof typeof ArticleState
            ]
          }
        />
      ),
      header: t('general.state'),
      enableSorting: false,
    }),
    columnHelper.accessor('articleProcess', {
      cell: (info) =>
        ArticleState[
          info.row.original.state as unknown as keyof typeof ArticleState
        ] == ArticleState.Cancelled ? null : (
          <StepsIndicator
            currentStep={
              ArticleProcess[
                info.getValue() as unknown as keyof typeof ArticleProcess
              ]
            }
          />
        ),
      enableSorting: false,
      header: t('general.articleProcess'),
    }),
  ];

  return columns;
};
