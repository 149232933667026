import { GridItem, Badge, Card, CardBody, Heading } from '@chakra-ui/react';
import { fadeInScaleAnimation } from '../../../resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';

export interface BrandCardProps {
  name: string;
  id: number;
  nrOfArticles: number;
}

export function BrandCard(props: BrandCardProps) {
  const { t } = useTranslation();
  return (
    <GridItem>
      <Card variant="brand" animation={fadeInScaleAnimation()}>
        <CardBody>
          <AngularNavLink
            display="flex"
            flexDir="column"
            h="full"
            textDecoration="none"
            to="customer"
            navobj={{ customerId: props.id }}
            gap={2}
          >
            <Heading fontSize="lg">{props.name}</Heading>
            <Badge mt="auto" w="fit-content">
              {props.nrOfArticles} {t('general.articles')}
            </Badge>
          </AngularNavLink>
        </CardBody>
      </Card>
    </GridItem>
  );
}
