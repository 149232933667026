import { httpClient } from '../httpClient/httpClient';

export interface SlimUser {
  firstName: string;
  lastName: string;
  id: number;
}
export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  branchId: number;
  claims: Claims[];
}

export interface Claims {
  id: number;
  name: string;
}

export enum ClaimType {
  Guest = 0,
  SystemAdministrator = 1,
  CompanyAdministrator = 2,
  SalesRepresentative = 3,
  ProductDeveloper = 4,
  Viewer = 5,
  OrganizationAdministrator = 7,
  Agent = 8,
}

export const userApi = {
  getMe: () => httpClient.get<User>('users/me'),
  getUser: () => httpClient.get<User[]>('users'),
};
