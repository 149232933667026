const garpArticleText = () => ({
  templateUrl: 'directives/garp/articleText.tpl.html',
  restrict: 'EA',
  replace: true,
  scope: {
    readOnly: '=',
    readOnlyHint: '=',
    articleText: '=',
    isDisabled: '=',
    showHint: '=',
    useModifiedHint: '=',
    useDisabledHint: '=',
    isHidden: '=',
    onTextChange: '&',
    onClearText: '&',
    onArticleSet: '&',
    articleSetValue: '=',
  },
  controller: 'ArticleTextController',
  controllerAs: 'vm',
});

const LABELS = [
  'Extra Description',
  'Colour',
  'Material',
  'Size',
  'Other Customer info',
  'Other Customer info',
  'Other Customer info',
  'Supplier Item Code',
  'Supplier Colour Code',
  'Supplier Material',
  'Supplier Size',
  'Other Supplier info',
  'Other Supplier info',
  'Other Supplier info',
  'Wep/Product line',
];

class ArticleTextController {
  getLabelFor(articleText) {
    if (!articleText || articleText.index < 1 || articleText.index > 15) {
      return '';
    }

    return LABELS[articleText.index - 1];
  }
}

angular
  .module('directives.garp.articleText', [])
  .controller('ArticleTextController', ArticleTextController)
  .directive('garpArticleText', garpArticleText);
