import {
  Table,
  Thead,
  Tr,
  Td,
  VStack,
  HStack,
  Tbody,
  Tfoot,
  Divider,
  Text,
  Tooltip,
  Badge,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import { TierPrice } from '@texas/api/endpoints/tierPriceApi';
import { Icons } from '@texas/components/shared/Icons';
import useFormat from '@texas/hooks/useFormat';
import { SalesTierRow } from './SalesTierRow';

export interface SalesTiersProps {
  purchaseInformation: PurchaseInformation;
  tierPrices: TierPrice[];
  nodeGroups: ArticleNodeGroup;
  salesCurrency: SalesCurrency;
  selectedCurrency: CustomerCurrency;
  sellingExchangeRate: number;
  readyForSync: boolean;
}

const purchaseInfoOpacity = 0.7;

export function SalesTiers(props: SalesTiersProps) {
  const { t } = useTranslation();
  const { numberFormat } = useFormat();
  return (
    <Table variant="salesTiers" marginY={4}>
      <Thead paddingBottom={2} borderBottom="none">
        <Tr>
          <Td w={6} />
          <Td w={24} opacity={purchaseInfoOpacity}>
            <VStack spacing={0} alignItems="start">
              <Text>{t('variant.sales.tiers')}</Text>
              <Badge colorScheme="orange" fontSize={11}>
                {t('variant.sales.moq').toUpperCase()}:{' '}
                {numberFormat(props.purchaseInformation.moq)}
              </Badge>
            </VStack>
          </Td>
          {nodeGroupIsPopulated(props.nodeGroups) ? (
            <Td />
          ) : (
            <>
              <Td w={32} opacity={purchaseInfoOpacity}>
                {purchaseInfoContainsFees(props.purchaseInformation) ? (
                  <>
                    <Tooltip
                      label={
                        <DisplayPurchaseFees
                          purchaseInfo={props.purchaseInformation}
                        />
                      }
                    >
                      <HStack spacing={1}>
                        <Text>{t('variant.sales.costPrice')}</Text>
                        <Icons.PercentCircle />
                      </HStack>
                    </Tooltip>
                  </>
                ) : (
                  <Text>{t('variant.sales.costPrice')}</Text>
                )}
              </Td>
              <Td>
                <VStack alignItems="start" spacing={0}>
                  <Text>{t('variant.sales.salesPrice')}</Text>
                </VStack>
              </Td>
              <Td w={32}>{t('variant.sales.margin')}</Td>
              <Td w={32}>{t('variant.sales.multiplier')}</Td>
            </>
          )}
        </Tr>
      </Thead>

      <Tbody fontSize={14}>
        {props.tierPrices.map((t) => {
          return (
            <SalesTierRow
              salesCurrency={props.salesCurrency}
              nodeGroups={props.nodeGroups}
              key={t.quantity}
              purchaseInformation={props.purchaseInformation}
              tierPrice={t}
              selectedCurrency={props.selectedCurrency}
              sellingExchangeRate={props.sellingExchangeRate}
              readyForSync={props.readyForSync}
            />
          );
        })}
      </Tbody>

      {!nodeGroupIsPopulated(props.nodeGroups) && (
        <Tfoot>
          <Tr>
            <Td colSpan={3} opacity={purchaseInfoOpacity}>
              <HStack>
                <Text whiteSpace="nowrap">
                  {t('variant.purchaseInformation').toUpperCase()}
                </Text>
                <Divider />
              </HStack>
            </Td>
            <Td colSpan={4}>
              <HStack>
                <Text whiteSpace="nowrap">
                  {' '}
                  {t('variant.salesInformation').toUpperCase()}
                </Text>
                <Divider />
              </HStack>
            </Td>
          </Tr>
        </Tfoot>
      )}
    </Table>
  );
}

function DisplayPurchaseFees({
  purchaseInfo,
}: {
  purchaseInfo: PurchaseInformation;
}) {
  const { t } = useTranslation();
  return (
    <VStack spacing={1}>
      {purchaseInfo.freightCostId && purchaseInfo.freightCostValue > 0 && (
        <Text>
          {t('variant.handlingFee')}: {purchaseInfo.handlingFeeValue}%
        </Text>
      )}
      {purchaseInfo.handlingFeeId && purchaseInfo.handlingFeeValue > 0 && (
        <Text>
          {t('variant.freightCost')}: {purchaseInfo.freightCostValue}%
        </Text>
      )}
    </VStack>
  );
}

function purchaseInfoContainsFees(purchaseInfo: PurchaseInformation): boolean {
  return purchaseInfo.freightCostId !== 0 || purchaseInfo.handlingFeeId !== 0;
}
