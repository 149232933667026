import { Flex, Text, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ClaimType } from '@texas/api/endpoints/userApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { hasClaims } from '@texas/utils/helpers/claimHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ArtworkDocument } from './ArtworkDocument';
import { ArtworkImage } from './ArtworkImage';
import { Photo } from './Photo';

export function GeneralFiles({ variantId }: { variantId: number }) {
  const { t } = useTranslation();

  const {
    data: variantFilesInfo,
    refetch: refetchVariantFilesInfo,
    loading: loadingVariantFilesInfo,
    set: setVariantFilesInfo,
    error,
  } = useApiResource(variantsApi.getVariantFilesInfo);

  useEffect(() => {
    refetchVariantFilesInfo(variantId);
  }, [refetchVariantFilesInfo, variantId]);

  const session = useActiveSession();
  const allowEdit = hasClaims(
    session.currentUser.claims.map((c) => c.id),
    [
      ClaimType.Agent,
      ClaimType.CompanyAdministrator,
      ClaimType.SystemAdministrator,
    ],
  );

  return (
    <>
      {loadingVariantFilesInfo && (
        <Flex gap={4}>
          <Text>{t('general.loading')}</Text>
          <Spinner />
        </Flex>
      )}
      {variantFilesInfo && (
        <Grid templateRows="1fr auto" templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem>
            <ArtworkImage
              variantId={variantId}
              allowEdit={allowEdit}
              variantFilesInfo={variantFilesInfo}
              setVariantFilesInfo={setVariantFilesInfo}
            />
          </GridItem>
          <GridItem>
            <Photo
              variantId={variantId}
              allowEdit={allowEdit}
              variantFilesInfo={variantFilesInfo}
              setVariantFilesInfo={setVariantFilesInfo}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <ArtworkDocument
              variantId={variantId}
              allowEdit={allowEdit}
              variantFilesInfo={variantFilesInfo}
              setVariantFilesInfo={setVariantFilesInfo}
            />
          </GridItem>
        </Grid>
      )}
      {!variantFilesInfo && <ErrorLabel text={error?.message} />}
    </>
  );
}
