// @ngInject
const PriceHistoryDialogService = ($mdDialog, ArticlesApiService) => ({
  showDialog(displayName, tierPrice, col, supplierOrCurrencyId) {
    const options = {
      clickOutsideToClose: true,
      templateUrl:
        'services/dialogs/priceHistoryDialog/priceHistoryDialog.tpl.html',
      controller: 'PriceHistoryDialogController as vm',
      locals: {
        displayName,
        isMatrix: () => {
          return !!col;
        },
      },
    };
    if (col) {
      options.resolve = {
        data: () => {
          return ArticlesApiService.getMatrixCellHistory(tierPrice.id).then(
            (data) =>
              _.filter(data, {
                matrixNodeValueXId: col.matrixNodeValueXId,
                matrixNodeValueYId: col.matrixNodeValueYId,
                supplierId: supplierOrCurrencyId,
              }),
          );
        },
      };
    } else {
      options.resolve = {
        data: () => {
          return ArticlesApiService.getTierPriceHistory(tierPrice.id).then(
            (data) =>
              _.filter(data, {
                supplierId: supplierOrCurrencyId,
              }),
          );
        },
      };
    }
    return $mdDialog.show(options);
  },
});

class PriceHistoryDialogController {
  constructor($mdDialog, displayName, data, isMatrix) {
    'ngInject';

    this._$mdDialog = $mdDialog;
    this.displayName = displayName;
    this.data = data;
    this.isMatrix = isMatrix;
    this.hasMatrix = data.some(
      (d) => d.matrixNodeValueX != null && d.matrixNodeValueY != null,
    );

    this.limitOptions = [20, 40, 60];

    this.options = {
      boundaryLinks: true,
      limitSelect: true,
      pageSelector: true,
    };

    this.query = {
      order: 'updatedOn',
      limit: 20,
      page: 1,
    };
  }

  getName(historyReason) {
    switch (historyReason) {
      case 'CellPriceChanged':
        return 'Cell price changed';
      case 'TierPriceChanged':
        return 'Tier price changed';
      case 'PriceDeleted':
        return 'Fixed price deleted';
      case 'Fixed':
        return 'New fixed price';
      case 'MarginAndMultiplier':
        return 'New margin/multiplier';
      case 'MassUpdateTierPriceChanged':
        return 'Mass-update: tier price changed';
      case 'MassUpdateCellPriceChanged':
        return 'Mass-update: cell price changed';
      case 'MassUpdateFixed':
        return 'Mass-update: new fixed price';
      case 'MassUpdateMarginAndMultiplier':
        return 'Mass-update: new margin/multiplier';
      case 'MassUpdateDeleted':
        return 'Mass-update: fixed price deleted';
      case 'MassUpdateCellPriceDeleted':
        return 'Mass-update: cell price deleted';
      case 'PriceCopied':
        return 'Price copied from another branch';
      case 'OriginalPrice':
        return 'Original price';
      default:
        return '';
    }
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.priceHistoryDialog', [])
  .service('PriceHistoryDialogService', PriceHistoryDialogService)
  .controller('PriceHistoryDialogController', PriceHistoryDialogController);
