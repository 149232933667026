import { Text } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ProductApproval,
  ProductApprovalState,
} from '@texas/api/endpoints/productApprovalsApi';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { Box, Flex } from '@chakra-ui/react';
import { SelectState } from './SelectState';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export const productApprovalColumns = (
  isDarkMode: boolean,
  t: TFunction,
  onStateChange: (id?: number) => void,
) => {
  const columnHelper = createColumnHelper<ProductApproval>();
  const columns = [
    columnHelper.accessor('formatNumber', {
      cell: (info) => info.getValue(),
      header: t('productApproval.number'),
      enableSorting: true,
    }),
    columnHelper.accessor('branchName', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      enableSorting: true,
    }),
    columnHelper.accessor('supplierName', {
      cell: (info) => <Text whiteSpace="normal">{info.getValue()}</Text>,
      header: t('suppliers.supplier'),
      enableSorting: true,
    }),
    columnHelper.accessor('variantName', {
      cell: (info) => (
        <Flex direction="column">
          <Box>{info.getValue()}</Box>
          {info.row.original.matrixNodeXId && info.row.original.matrixNodeYId && (
            <>
              <Box color="gray.200">{info.row.original.matrixNodeXValue}</Box>
              <Box color="gray.200">{info.row.original.matrixNodeYValue}</Box>
            </>
          )}
        </Flex>
      ),
      header: t('variant.variant'),
      enableSorting: true,
    }),
    columnHelper.accessor('created', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.created'),
      enableSorting: true,
    }),
    columnHelper.accessor('state', {
      cell: (info) => (
        <Flex gap={2} alignItems="center" justifyContent="space-between">
          <Text
            w="16"
            color={stateColor(
              convertToEnum(ProductApprovalState, info.getValue()),
              isDarkMode,
            )}
          >
            {info.getValue()}
          </Text>
          {convertToEnum(ProductApprovalState, info.row.original.state) ===
            ProductApprovalState.Pending && (
            <SelectState
              onChanged={onStateChange}
              id={info.row.original.id}
              branchId={info.row.original.branchId}
              variantId={info.row.original.variantId}
            />
          )}
        </Flex>
      ),
      header: t('general.state'),
      enableSorting: true,
    }),
  ];

  return columns;
};

function stateColor(state: ProductApprovalState, isDarkMode: boolean) {
  switch (state) {
    case ProductApprovalState.Pending:
      return isDarkMode ? 'gray.300' : 'gray.500';
    case ProductApprovalState.Accepted:
      return 'inherit';
    case ProductApprovalState.Rejected:
      return isDarkMode ? 'red.300' : 'red.500';
  }
}
