// @ngInject
const articleFilter = () => ({
  templateUrl: 'directives/articleFilter/articleFilter.tpl.html',
  restrict: 'EA',
  scope: {},
  bindToController: {
    placeholder: '=',
    checkboxesChecked: '=',
    items: '=',
    searchFilter: '&',
    selectedItems: '=',
    includeAllCheckbox: '@',
    includeAssignedToMeCheckbox: '@',
    onFilterChange: '&',
  },
  controller: 'ArticleFilterController',
  controllerAs: 'vm',
});

class ArticleFilterController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.inputText = '';
    this.searchText = '';
    this.initializeCheckboxValue();
    this.setupWatchers();
  }

  setupWatchers() {
    this.$scope.$watch(
      () => this.items,
      (newValue, oldValue) => {
        if (newValue && newValue !== oldValue && newValue.length < 1) {
          this.isActive = false;
        } else if (newValue && newValue !== oldValue && newValue.length > 1) {
          const all = _.find(this.items, { name: 'All', isAll: true });
          if (
            _.every(_.tail(this.items), { isChecked: this.checkboxesChecked })
          ) {
            this.isActive = false;
            all.isChecked = this.checkboxesChecked;
          } else {
            this.isActive = true;
            all.isChecked = false;
            if (_.every(_.tail(this.items), { isChecked: true })) {
              all.isChecked = true;
            }
          }
        }
      },
    );
  }

  addCheckAllCheckbox(isChecked) {
    this.items.unshift({
      isChecked,
      name: 'All',
      isAll: true,
    });
  }

  addAssignedToMeCheckbox(isChecked) {
    this.items.unshift({
      isChecked,
      name: 'Assigned to me',
      isDefaultValue: true,
    });
  }

  initializeCheckboxValue() {
    const value = this.checkboxesChecked ? !!this.checkboxesChecked : false;
    this.onFilterChange({ checkedItems: this.selectedItems });

    if (
      this.includeAssignedToMeCheckbox &&
      !_.find(this.items, { name: 'Assigned to me', isDefaultValue: true }) &&
      this.items.length > 0
    ) {
      this.addAssignedToMeCheckbox(value);
    }

    if (
      this.includeAllCheckbox &&
      !_.find(this.items, { name: 'All', isAll: true }) &&
      this.items.length > 0
    ) {
      this.addCheckAllCheckbox(value);
    }

    this.selectedItems = [];

    _.each(this.items, (item) => {
      item.isChecked = value;
      if (item.isChecked && this.selectedItems && !item.isAll) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems = _.reject(
          this.selectedItems,
          (i) => i.isChecked === false,
        );
      }
    });
  }

  setSelectedItem(item) {
    if (item && this.selectedItems && item.name === 'All') {
      this.setAllCheckboxValues(item.isChecked);
    } else if (_.isBoolean(item.isChecked) && this.selectedItems) {
      if (!item.isChecked) {
        this.selectedItems = _.reject(
          this.selectedItems,
          (i) => i.isChecked === false,
        );
      } else {
        this.selectedItems.push(item);
      }

      const all = _.find(this.items, { name: 'All', isAll: true });

      if (_.every(_.tail(this.items), { isChecked: this.checkboxesChecked })) {
        this.isActive = false;
        all.isChecked = this.checkboxesChecked;
      } else {
        this.isActive = true;
        all.isChecked = false;
        if (_.every(_.tail(this.items), { isChecked: true })) {
          all.isChecked = true;
        }
      }
    }
    this.onFilterChange({ checkedItems: this.selectedItems });
  }

  setAllCheckboxValues(checkedValue) {
    const value =
      checkedValue !== undefined ? checkedValue : this.checkboxesChecked;

    if (!value) {
      if (!this.checkboxesChecked) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
      this.selectedItems.length = 0;
    } else {
      if (!this.checkboxesChecked) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
      _.each(this.items, (i) => this.selectedItems.push(i));
    }
    _.each(this.items, (item) => {
      item.isChecked = value;
    });
    this.onFilterChange({ checkedItems: this.selectedItems });
  }

  resetTablePosition(item) {
    this.setSelectedItem(item);
    this.inputText = null;
    this.searchText = '';
    this.query.page = 1;
  }
}

const orderArticleFilter = () => {
  return function (items) {
    if (!items) {
      return;
    }
    items.sort((a, b) => {
      if (a.name === 'All' || (a.isAll && b.name !== 'All')) {
        return -1;
      }

      const nameorder = a.name === b.name ? 0 : a.name < b.name ? -1 : 1;

      if ((a.isChecked && b.isChecked) || (!a.isChecked && !b.isChecked)) {
        return nameorder;
      } else if (a.isChecked) {
        return -1;
      } else {
        return 1;
      }
    });
    return items;
  };
};

angular
  .module('directives.articleFilter', [])
  .controller('ArticleFilterController', ArticleFilterController)
  .directive('articleFilter', articleFilter)
  .filter('orderArticleFilter', orderArticleFilter);
