const saveMenu = () => ({
  templateUrl: 'directives/saveMenu/saveMenu.tpl.html',
  restrict: 'E',
  replace: true,
  scope: {},
  controller: 'SaveMenuController',
  controllerAs: 'vm',
});

class SaveMenuController {
  constructor($window, $element, $scope, $animate, $rootScope, SavingService) {
    'ngInject';

    this.window = $window;
    this.element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$animate = $animate;
    this.top = this.element[0].getBoundingClientRect().top;
    this.stickyClass = 'sticky-auto';
    this.save = SavingService.save;
    this.discard = SavingService.discard;
    this.hasChanges = SavingService.hasChanges;

    this.setupEventListners();
  }

  setStickyMenu() {
    const stickyClass = this.stickyClass;
    if (
      !this.element.hasClass(stickyClass) &&
      this.window.pageYOffset > this.top
    ) {
      this.element.addClass(stickyClass);
    } else if (
      this.element.hasClass(stickyClass) &&
      this.window.pageYOffset <= this.top
    ) {
      this.element.removeClass(stickyClass);
    }
  }

  setupEventListners() {
    this.notifyEvent = this.$rootScope.$on('alertSaveMenu', () => this.shake());
    this.$scope.$on('$destroy', () => this.notifyEvent());
    angular.element(this.window).bind('scroll', () => this.setStickyMenu());
  }

  shake() {
    const contentElement = this.element.children(':first');
    this.$animate
      .addClass(contentElement, 'shake')
      .then(() => this.$animate.removeClass(contentElement, 'shake'));
  }
}

angular
  .module('directives.saveMenu', [])
  .controller('SaveMenuController', SaveMenuController)
  .directive('saveMenu', saveMenu);
