import { useCallback } from 'react';

export const useFormat = () => {
  const numberFormat = useCallback((decimal: number) => {
    return decimal.toLocaleString(undefined, { maximumSignificantDigits: 5 });
  }, []);

  const formatWeight = useCallback((weight: number) => {
    const gram = weight * 1000;

    if (gram > 100) {
      return `${weight} kg`;
    }

    return `${gram} g`;
  }, []);

  const formatName = (firstName: string, lastName: string) =>
    `${firstName} ${lastName}`;

  return { numberFormat, formatWeight, formatName };
};

export default useFormat;
