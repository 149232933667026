import {
  TreatmentListItem,
  treatmentsApi,
} from '@texas/api/endpoints/metadata/treatmentsApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface ArchiveTreatmentProps extends SharedDisclosureProps {
  onArchive: () => void;
  treatment: TreatmentListItem;
}

export function ArchiveTreatment({
  isOpen,
  treatment,
  onArchive,
  onClose,
}: ArchiveTreatmentProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('configuration.archive', {
        name: treatment.name,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={treatmentsApi.archive}
      args={[treatment.id]}
      isOpen={isOpen}
      onClose={onClose}
      onPerformed={onArchive}
      onSuccessTitle={t('general.successfullyArchived')}
      onFailureTitle={t('general.archiveFailed')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
