// @ngInject
const ConfigItemDialogService = ($mdDialog) => ({
  showDialog: (options = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ConfigItemDialogController as vm',
      templateUrl:
        'services/dialogs/configItemDialog/configItemDialog.tpl.html',
      locals: Object.assign(
        {
          configItem: null,
          name: true,
          code: false,
          codeLength: 5,
          identifier: false,
          displayOrder: false,
          value: false,
          checkedByDefault: false,
          archivable: true,
          allowedToEdit: true,
        },
        options,
      ),
    }),
});

class ConfigItemDialogController {
  constructor(
    $mdDialog,
    toasterService,
    configItem,
    onSave,
    identifier,
    displayOrder,
    value,
    checkedByDefault,
    archivable,
    allowedToEdit,
    name,
    code,
    codeLength,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.toasterService = toasterService;

    this.validationErrors = [];
    this.onSave = onSave;
    this.identifier = identifier;
    this.displayOrder = displayOrder;
    this.value = value;
    this.name = name;
    this.code = code;
    this.codeLength = codeLength;
    this.checkedByDefault = checkedByDefault;
    this.archivable = archivable;
    this.allowedToEdit = allowedToEdit;
    this.editMode = configItem !== null;

    if (!this.editMode) {
      configItem = {};
    }
    this.configItem = _.extend({}, configItem);
  }

  save() {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();
    this.onSave(this.configItem)
      .then(
        () => {
          this.toasterService.success();
          this.$mdDialog.hide();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.$mdDialog.stopLoadingSpinner();
      });
  }

  archive() {
    this.configItem.archived = !this.configItem.archived;
    this.save();
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.configItemDialog', [])
  .service('ConfigItemDialogService', ConfigItemDialogService)
  .controller('ConfigItemDialogController', ConfigItemDialogController);
