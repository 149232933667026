import { httpClient } from '../httpClient/httpClient';

export interface VatCode {
  id: number;
  branchId: number;
  identifier: string;
  name: string;
  displayOrder: number;
  value: number;
  archived: boolean;
}

export const vatCodesApi = {
  getAllVatCodes: () => httpClient.get<VatCode[]>(`vatcodes`),
};
