import { angularEvents } from '../../../bridge/angularEvents';

// @ngInject
const AuthApiService = (DS, DSHttpAdapter, $q, ResourcesService) => ({
  login(email, password) {
    const deferred = $q.defer();

    const data = {
      email,
      password,
    };

    DSHttpAdapter.POST(`${DS.defaults.basePath}auth`, data).then(
      () => {
        deferred.resolve();
        angularEvents.currentUserFetched.dispatch();
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  logout() {
    const deferred = $q.defer();

    DSHttpAdapter.DEL(`${DS.defaults.basePath}auth`).then(
      () => {
        deferred.resolve();
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
});

angular.module('resources.auth', []).factory('AuthApiService', AuthApiService);
