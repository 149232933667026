// @ngInject
const TechnicalOptionsApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('technicalOption', {
      params: { organizationId },
    }),

  getById: (technicalOptionId) =>
    ResourcesService.getById('technicalOption', technicalOptionId, {
      params: { organizationId: false },
    }),

  newTechnicalOption: (technicalOption, organizationId) =>
    ResourcesService.create('technicalOption', technicalOption, {
      params: { organizationId },
    }),

  updateTechnicalOption: (data, technicalOptionId) =>
    ResourcesService.update('technicalOption', technicalOptionId, data, {
      params: { organizationId: false },
      updatedFields: ['name', 'displayOrder', 'checkedByDefault', 'archived'],
    }),
});

angular
  .module('resources.technicalOptions', [])
  .factory('TechnicalOptionsApiService', TechnicalOptionsApiService);
