import {
  Box,
  Button,
  Flex,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { Container } from '@texas/components/shared/Container';
import {
  DimensionGroupListItem,
  dimensionGroupsApi,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { dimensionGroupTableColumns } from './Columns';
import { ArchiveDimensionGroup } from './ArchiveDimensionGroup';
import { CreateDimensionGroup } from './CreateDimensionGroup';
import { EditDimensionGroup } from './EditDimensionGroup';
import {
  SearchQuery,
  defaultSearchQuery,
} from '@texas/api/endpoints/metadata/types';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { TexasSwitch } from '@texas/components/shared/form/TexasSwitch';

export function DimensionGroups() {
  const {
    onOpen: onCreateOpen,
    onClose: onCreateClose,
    isOpen: isCreateOpen,
  } = useDisclosure();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<DimensionGroupListItem>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<number>();

  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: dimensionGroups,
    refetch,
    error,
    loading,
  } = useApiResource(dimensionGroupsApi.search);

  const { request: restoreDimensionGroupRequest } = useApiRequest(
    dimensionGroupsApi.restore,
  );

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    ...defaultSearchQuery,
    sortBy: 'name',
  });

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restoreDimensionGroupRequest,
        [id],
        () => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(searchQuery);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [restoreDimensionGroupRequest, toast, t, refetch, searchQuery],
  );

  const columns = useMemo(() => {
    return dimensionGroupTableColumns(
      t,
      onArchiveOpen,
      performRestore,
      onEditOpen,
    );
  }, [t, onArchiveOpen, performRestore, onEditOpen]);

  return (
    <Container title={t('configuration.dimensionGroups')}>
      {archiveValue && (
        <ArchiveDimensionGroup
          dimensionGroup={archiveValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchive={() => {
            refetch(searchQuery);
          }}
        />
      )}

      <CreateDimensionGroup
        onClose={onCreateClose}
        isOpen={isCreateOpen}
        onCreated={() => {
          refetch(searchQuery);
          onCreateClose();
        }}
      />

      <EditDimensionGroup
        dimensionGroupId={editValue}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onUpdated={() => {
          refetch(searchQuery);
        }}
      />
      <VStack w="100%" alignItems="start" p={4}>
        <Flex justify="space-between" w="100%">
          <Flex direction="column" gap={4}>
            <Flex gap={4} align="flex-end">
              <Box height="fit-content" mt="auto" minWidth={300}>
                <SearchFilterInput
                  value={searchQuery.searchTerm}
                  onChange={(s) =>
                    setSearchQuery({ ...searchQuery, searchTerm: s })
                  }
                />
              </Box>
            </Flex>
            <TexasSwitch
              checked={searchQuery.includeArchived}
              label={t('general.includeArchived')}
              onChange={(checked) =>
                setSearchQuery({
                  ...searchQuery,
                  includeArchived: checked,
                })
              }
            />
          </Flex>
          <Button variant="texas-solid" onClick={onCreateOpen}>
            {t('general.addNew')}
          </Button>
        </Flex>

        <DataTableContainer
          w="100%"
          datatable={{
            data: dimensionGroups ?? [],
            sorted: {
              onSortedChange: ({ key, desc }) => {
                setSearchQuery({ ...searchQuery, sortBy: key, sortDesc: desc });
              },
              key: searchQuery.sortBy,
              desc: searchQuery.sortDesc,
            },
            isLoading: loading,
            columns: columns,
            variant: 'configuration',
          }}
          error={error}
        />
      </VStack>
    </Container>
  );
}
