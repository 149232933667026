// @ngInject
const contextMenu = (
  $document,
  $interpolate,
  $compile,
  contextMenuService,
  $templateCache,
) => ({
  restrict: 'EA',
  link(scope, element, attributes) {
    if (!contextMenuService.eventBound) {
      $document[0].addEventListener('click', () => {
        contextMenuService.cancelAll();
        scope.$apply();
      });

      contextMenuService.eventBound = true;
    }

    const closeMenu = () => {
      if (scope.menu) {
        scope.menuScope.$destroy();
        scope.menu.remove();
        scope.menu = null;
      }
    };

    scope.$on('context-menu/close', closeMenu);

    const render = (event) => {
      if ('preventDefault' in event) {
        contextMenuService.cancelAll();
        event.stopPropagation();
        event.preventDefault();
      } else {
        if (!scope.menu) {
          return;
        }
      }

      const templateHtml = $templateCache.get(attributes.contextMenu);
      const menuScope = scope.$new(false);
      const menu = $compile(templateHtml)(menuScope);
      element.append(menu);
      menuScope.$applyAsync();
      menu.css({
        position: 'fixed',
        top: 0,
        left: 0,
        transform: $interpolate('translate({{x}}px, {{y}}px)')({
          x: event.clientX,
          y: event.clientY,
        }),
      });
      scope.menu = menu;
      scope.menuScope = menuScope;
      scope.closeMenu = closeMenu;
      scope.menu.bind('click', closeMenu);
    };

    element.bind(attributes.contextEvent || 'contextmenu', render);
  },
});

// @ngInject
const contextMenuService = ($rootScope) => ({
  cancelAll() {
    $rootScope.$broadcast('context-menu/close');
  },
  eventBound: false,
});

angular
  .module('directives.contextMenu', [])
  .factory('contextMenuService', contextMenuService)
  .directive('contextMenu', contextMenu);
