import { useCallback, useState } from 'react';
import { ServerError } from '../../types';
import { HttpClientResponse } from '../httpClient/types';

export type ApiRequestType<A extends any[], R> = (
  ...args: A
) => Promise<HttpClientResponse<R>>;

export interface ApiRequest<A extends any[], R> {
  request: ApiRequestType<A, R>;
  loading: boolean;
  iteration: number;
  error: ServerError | null;
}

// Used for keeping track of the most recent request
let iteration = 0;

export const useApiRequest = <A extends any[], R>(
  apiRequest: ApiRequestType<A, R>,
): ApiRequest<A, R> => {
  const [requestsInFlight, setRequestsInFlight] = useState<symbol[]>([]);
  const [error, setError] = useState<ServerError | null>(null);

  const request = useCallback(
    async (...args: A) => {
      iteration++;
      const currentRequestSymbol = Symbol();
      setRequestsInFlight((s) => [...s, currentRequestSymbol]);

      return apiRequest(...args)
        .then((r) => {
          if (r.hasError) {
            setError(r.error);
          } else {
            setError(null);
          }

          return r;
        })
        .finally(() => {
          setRequestsInFlight((s) =>
            s.filter((v) => v !== currentRequestSymbol),
          );
        });
    },
    [apiRequest],
  );

  return {
    request,
    loading: requestsInFlight.length > 0,
    error,
    iteration,
  };
};
