// @ngInject
const entityBadge = ($state) => ({
  templateUrl: 'directives/entityBadge/entityBadge.tpl.html',
  restrict: 'E',
  replace: true,
  scope: {
    article: '=',
    concept: '=',
    customer: '=',
    variant: '=',
    branch: '=',
  },
  link(scope) {
    scope.activeEntityName = '';
    scope.displayText = '';
    scope.breadcrumbs = null;

    if (scope.article) {
      scope.activeEntityName = 'article';
      scope.breadcrumbs = scope.customer.name;
      if (scope.concept) {
        scope.breadcrumbs += ` / ${scope.concept.name}`;
      }
      const articleText = scope.article.itemCode;
      scope.displayText = scope.variant
        ? `${articleText} - ${scope.variant.name}`
        : articleText;
    } else if (scope.concept) {
      scope.activeEntityName = 'concept';
      scope.breadcrumbs = scope.customer.name;
      scope.displayText = scope.concept.name;
    } else if (scope.customer) {
      scope.activeEntityName = 'customer';
      scope.displayText = scope.customer.name;
    }

    scope.go = () =>
      $state.go(scope.activeEntityName, {
        [`${scope.activeEntityName}Id`]: scope[scope.activeEntityName].id,
      });
  },
});

angular
  .module('directives.entityBadge', [])
  .directive('entityBadge', entityBadge);
