import { diff } from 'deep-object-diff';
import { FieldValues } from 'react-hook-form';
import { autoUpdateEvents } from '../../components/shared/autoUpdate/AutoUpdateEvents';
import { CommandHandler, Command, CommandBus } from '../commandHandler';
import { commandTypes } from '../commandTypes';
import {
  AutoUpdateCommand,
  AutoUpdateRequestType,
  CommandCallbackArgs,
  AutoUpdateRequestState,
  AutoUpdateRequestWithDataType,
} from './types';
import {
  AutoUpdateErrorType,
  AutoUpdateFieldType,
  AutoUpdateFieldState,
} from '@texas/api/endpoints/autoUpdateTypes';

interface AutoUpdateTimeout {
  submitTimeout: NodeJS.Timeout;
  id: string;
}

const propertyChangedTimeout = 1000;

class AutoUpdateCommandHandler<
  TRequest extends FieldValues,
  TDto extends TRequest,
> implements CommandHandler<Command<AutoUpdateCommand<TRequest, TDto>>>
{
  commandType = commandTypes.autoUpdate;
  timeoutSubmits: AutoUpdateTimeout[] = [];

  execute(command: Command<AutoUpdateCommand<TRequest, TDto>>) {
    const payload = command.payload;

    const foundTimeoutSubmit = this.timeoutSubmits.find(
      (d) => d.id === payload.formId,
    );
    if (foundTimeoutSubmit) {
      clearTimeout(foundTimeoutSubmit.submitTimeout);
      foundTimeoutSubmit.submitTimeout = this.CreateNewTimeout(payload);
      return;
    }

    this.timeoutSubmits.push({
      submitTimeout: this.CreateNewTimeout(payload),
      id: payload.formId,
    });
  }

  CreateNewTimeout(event: AutoUpdateCommand<TRequest, TDto>) {
    return setTimeout(() => {
      this.OnSubmit(
        event.id,
        event.initialFormValues,
        event.unmodifiedServerValues,
        event.formValues,
        event.forceUpdate,
        event.apiRequest,
        event.stateUpdate,
        event.additionalData,
      ).then((data) => {
        if (!data) return;
        if (!document.getElementById(event.formId)) {
          if (!data.response.hasError) {
            autoUpdateEvents.formNotFound.dispatch(data.response.data.fields);
          } else {
            const message = data.response.error.message;
            autoUpdateEvents.formNotFound.dispatch(
              data.modifiedRows.map((m) => ({
                ...m,
                hasError: true,
                error: {
                  message,
                  type: AutoUpdateErrorType.InternalServerError,
                },
              })),
            );
          }
        }
      });
    }, propertyChangedTimeout);
  }

  async OnSubmit<TRequest extends object, TDto extends TRequest, TData = any>(
    id: number,
    initialFormValues: TRequest,
    unmodifiedServerValues: TRequest,
    formValues: TRequest,
    forceUpdate: boolean,
    apiRequest:
      | AutoUpdateRequestType<TRequest, TDto>
      | AutoUpdateRequestWithDataType<TRequest, TDto, TData>,
    stateUpdate: (args: CommandCallbackArgs<TDto>) => void,
    additionalData?: TData,
  ) {
    formValues = setUndefinedValuesToNull(formValues);
    unmodifiedServerValues = setUndefinedValuesToNull(unmodifiedServerValues);
    initialFormValues = setUndefinedValuesToNull(initialFormValues);
    const diffValues = forceUpdate
      ? diffWithArray<TRequest>(unmodifiedServerValues, formValues)
      : diffWithArray<TRequest>(initialFormValues, formValues);

    const diffObjects = Object.keys(diffValues);
    if (diffObjects.length === 0) return;

    // Add these rows as updating
    const modifiedRows: AutoUpdateFieldType[] =
      diffObjects.map<AutoUpdateFieldType>((d) => {
        return {
          hasError: false,
          fieldName: d,
          fieldValue: undefined,
          fieldState: AutoUpdateFieldState.None,
          loading: true,
          iteration: apiRequest.iteration,
        };
      });
    stateUpdate({
      rows: modifiedRows,
      state: AutoUpdateRequestState.Pending,
    });

    const response = await apiRequest.request(id, {
      forceUpdate: forceUpdate,
      oldData: initialFormValues,
      newData: diffValues,
      changedFields: Object.keys(diffValues),
      additionalData: additionalData,
    });

    if (!response.hasError) {
      const failedValues = diffWithArray<TRequest>(
        response.data.value,
        formValues,
      );
      stateUpdate({
        rows: response.data.fields,
        response: {
          values: { ...response.data.value, ...failedValues },
          unmodifiedServerValues: response.data.value,
        },
        state: AutoUpdateRequestState.Success,
      });
    } else {
      stateUpdate({
        rows: modifiedRows.map((m) => ({
          ...m,
          hasError: true,
          error: {
            message: response.error.message,
            type: AutoUpdateErrorType.InternalServerError,
          },
          loading: false,
        })),
        state: AutoUpdateRequestState.Failed,
      });
    }

    return { response, modifiedRows };
  }
}

function setUndefinedValuesToNull(obj: any) {
  const temp = structuredClone(obj);
  Object.entries(obj).map(([key, value]) => {
    if (value === undefined) {
      temp[key] = null;
    }
  });

  return temp;
}

export const autoUpdateBus = new CommandBus([new AutoUpdateCommandHandler()]);

function diffWithArray<T>(obj1: any, obj2: any): T {
  const arrays = Object.keys(obj1).filter((k) => Array.isArray(obj1[k]));
  const res = diff(obj1, obj2) as any;
  Object.keys(res)
    .filter((k) => arrays.includes(k))
    .forEach((k) => (res[k] = obj2[k]));
  return res;
}
