const breadcrumbs = () => ({
  templateUrl: 'directives/breadcrumbs/breadcrumbs.tpl.html',
  restrict: 'E',
  scope: {},
  bindToController: {
    customerId: '=',
    conceptId: '=',
    articleId: '=',
  },
  controller: 'BreadcrumbsController',
  controllerAs: 'vm',
});

class BreadcrumbsController {
  constructor(
    CustomersApiService,
    ConceptsApiService,
    ArticlesApiService,
    $state,
    $scope,
  ) {
    'ngInject';

    this.$state = $state;

    if (this.customerId) {
      CustomersApiService.getById(this.customerId, true).then(
        (customer) => (this.customer = customer),
      );
    }

    if (this.conceptId) {
      ConceptsApiService.getById(this.conceptId, true).then(
        (concept) => (this.concept = concept),
      );
    }

    if (this.articleId) {
      ArticlesApiService.getById(this.articleId).then(
        (article) => (this.article = article),
      );
    }

    $scope.$watch('vm.conceptId', (newValue, oldValue) => {
      if (newValue === oldValue) {
        return;
      }

      if (!newValue) {
        this.concept = null;
      } else {
        ConceptsApiService.getById(newValue, true).then(
          (concept) => (this.concept = concept),
        );
      }
    });

    $scope.$watch('vm.articleId', (newValue, oldValue) => {
      if (newValue === oldValue) {
        return;
      }

      if (!newValue) {
        this.article = null;
      } else {
        ArticlesApiService.getById(newValue).then(
          (article) => (this.article = article),
        );
      }
    });
  }

  goToCustomer(customer) {
    this.$state.go('customer', { customerId: customer.id });
  }

  goToConcepts(concepts) {
    this.$state.go('concept.articles', { conceptId: concepts.id });
  }
}

angular
  .module('directives.breadcrumbs', [])
  .controller('BreadcrumbsController', BreadcrumbsController)
  .directive('breadcrumbs', breadcrumbs);
