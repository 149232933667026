// @ngInject
const checkbox = () => ({
  restrict: 'E',
  require: 'ngModel',
  replace: true,

  scope: {
    isDisabled: '=',
  },

  template: '<span><input type="checkbox" ng-disabled="isDisabled"></span>',

  link: function linkFn(scope, element, attrs, ctrl) {
    const input = element.find('input');
    if (attrs.id) {
      input.attr('id', attrs.id);
      element.removeAttr('id');
    }

    ctrl.$render = () => {
      input[0].checked = ctrl.$viewValue;
    };

    input.bind('change', () => {
      ctrl.$setViewValue(this.checked);
      ctrl.$render();
    });
  },
});

angular.module('directives.checkbox', []).directive('checkbox', checkbox);
