import { httpClient } from '../httpClient/httpClient';
import {
  AutoUpdateRequestWithData,
  AutoUpdateResponse,
} from './autoUpdateTypes';

export interface TierPrice {
  id: number;
  branchId: number;
  quantity: number;
  decimalsInPrice: number;
  isMain: boolean;
  basePrices: BasePrice[];
  salesPrices: SalesPrice[];
}

export interface BasePrice {
  supplierId: number;
  price: number;
}

export interface SalesPrice {
  id: number;
  salesCurrencyId: number;
  tierPriceId: number;
  price: number;
  priceWithMargin: number;
  margin: number;
  isReady: boolean;
}

export interface MassUpdateSalesCellsPricesResponse {
  salesPrice: SalesPrice | null;
  priceCells: SalesCellPrice[];
}

export interface SalesCellPrice {
  id: number;
  tierPriceId: number;
  salesCurrencyId: number;
  matrixNodeValueXId: number;
  matrixNodeValueYId: number;
  price: number;
}

export interface PurchaseCellPrice {
  matrixNodeValueXId: number;
  matrixNodeValueYId: number;
  price: number;
  tierPriceId: number;
  supplierId: number;
  id: number;
}

export interface AutoUpdateSalesCellPrice {
  price?: number;
}
export interface AutoUpdateSalesCellPriceAdditionalData {
  salesCurrencyId: number;
  tierPriceId: number;
  matrixNodeValueXId: number;
  matrixNodeValueYId: number;
}

export interface AutoUpdateSalesPrice {
  price?: number;
}
export const EMPTY_AUTO_UPDATE_SALES_PRICE_OBJECT: AutoUpdateSalesPrice = {
  price: 0,
};
export interface AutoUpdateSalesPriceAdditionalData {
  salesCurrencyId: number;
  tierPriceId: number;
}

export interface MassUpdateCellPricesRequest {
  priceCells: SalesCell[];
}
export interface SalesCell {
  matrixNodeValueXId: number;
  matrixNodeValueYId: number;
  price?: number;
}

export interface MassDeleteCellPricesRequest {
  priceCellIds: number[];
}

export const tierPriceApi = {
  getTierPricesFromVariant: (variantId: number) =>
    httpClient.get<TierPrice[]>(`tierprices/variant/${variantId}`),
  getSalesCellPrices: (tierPriceId: number, salesCurrencyId: number) =>
    httpClient.get<SalesCellPrice[]>(
      `tierprices/${tierPriceId}/${salesCurrencyId}/salesvalues`,
    ),
  getPurchaseCellPrices: (tierPriceId: number) =>
    httpClient.get<PurchaseCellPrice[]>(
      `tierprices/${tierPriceId}/purchasevalues`,
    ),
  setAsMain: (tierPriceId: number) =>
    httpClient.put(`tierprices/${tierPriceId}/main`),
  deleteTier: (tierPriceId: number) =>
    httpClient.delete<number>(`tierprices/${tierPriceId}`),
  autoUpdateSalesPrice: (
    salesPriceId: number,
    request: AutoUpdateRequestWithData<
      AutoUpdateSalesPrice,
      AutoUpdateSalesPriceAdditionalData
    >,
  ) =>
    httpClient.post<AutoUpdateResponse<SalesPrice>>(
      `tierprices/auto/${salesPriceId}`,
      { data: request },
    ),
  autoUpdateSalesCellPrice: (
    _: number,
    request: AutoUpdateRequestWithData<
      AutoUpdateSalesCellPrice,
      AutoUpdateSalesCellPriceAdditionalData
    >,
  ) =>
    httpClient.post<AutoUpdateResponse<SalesCellPrice>>(
      `tierprices/auto/cell/sales`,
      { data: request },
    ),
  massUpdateCellsPrices: (
    tierPriceId: number,
    salesCurrencyId: number,
    request: MassUpdateCellPricesRequest,
  ) =>
    httpClient.post<MassUpdateSalesCellsPricesResponse>(
      `tierprices/${tierPriceId}/${salesCurrencyId}/cells`,
      { data: request },
    ),
  massDeleteCellsPrices: (
    tierPriceId: number,
    salesCurrencyId: number,
    request: MassDeleteCellPricesRequest,
  ) =>
    httpClient.post<SalesCellPrice[]>(
      `tierprices/${tierPriceId}/${salesCurrencyId}/cells`,
      { data: request },
    ),
};
