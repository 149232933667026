// @ngInject
const currentUserResolve = (AuthService) => AuthService.getUser();

// @ngInject
const currentOrganizationResolve = (OrganizationsApiService, currentUser) =>
  OrganizationsApiService.getById(currentUser.organization.id);

// @ngInject
const branchesResolve = (BranchesApiService, currentUser) =>
  BranchesApiService.getAllByOrganizationId(currentUser.organization.id);

// @ngInject
const rootRedirect = ($q) => $q.when({ state: 'customers', params: {} });

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('app', {
    url: '',
    templateUrl: 'app.tpl.html',
    resolve: {
      currentUser: currentUserResolve,
      currentOrganization: currentOrganizationResolve,
      branches: branchesResolve,
    },
    redirectTo: rootRedirect,
    data: { pageTitle: 'Dashboard' },
  });
};

angular
  .module('app', [
    'app.vendor',
    'app.core',
    'app.main',
    'app.bridgeEvents',
    'app.bridgeComponents',
  ])
  .config(config);
