import { Spinner, Flex, Fade, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function LoadingSessionView() {
  const { t } = useTranslation();
  return (
    <Fade in={true}>
      <Flex position="fixed" color="gray.100" inset="0" zIndex="overlay">
        <Flex
          alignItems="center"
          justifyContent="center"
          gap={4}
          bg="rgba(0, 0, 0, 0.6)"
          height="fit-content"
          padding="16"
          margin="auto"
          width="full"
        >
          <Text fontWeight="bold" fontSize="xl">
            {t('session.loadingTexas')}
          </Text>
          <Spinner thickness="3px" />
        </Flex>
      </Flex>
    </Fade>
  );
}
