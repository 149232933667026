import { useToast } from '@chakra-ui/react';
import {
  FileLink,
  filesApi,
  UpdateFileResponse,
} from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useTranslation } from 'react-i18next';

interface RestoreFileProps extends SharedDisclosureProps {
  file: FileLink;
  onRestored: (f: UpdateFileResponse) => void;
}

export function RestoreFile({
  file,
  isOpen,
  onClose,
  onRestored,
}: RestoreFileProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request: restoreRequest } = useApiRequest(filesApi.restoreFile);

  const onRestore = async () => {
    await request(
      restoreRequest,
      [file.id],
      (response) => {
        toast({
          title: t('general.restored'),
          status: 'success',
          isClosable: true,
        });
        onClose();
        onRestored(response);
      },
      (error: ServerError) => {
        toast({
          title: t('general.restoreFailed'),
          description: <ErrorsList errors={error.errors} />,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  return (
    <VerifyDialogWithFunction
      headerTitle={t('fileBrowser.restoreFile')}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      isOpen={isOpen}
      onClose={onClose}
      toPerform={async () => {
        await onRestore();
      }}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithFunction>
  );
}
