export const cottonTcx = {
  colors: [
    {
      code: '17-3938 TCX',
      name: 'Very Peri',
      hex: '6667AB',
      rgb: {
        r: 102,
        g: 103,
        b: 171,
      },
      lab: {
        l: 45.75,
        a: 12.21,
        b: -36.75,
      },
      cmyk: null,
    },
    {
      code: '11-4001 TCX',
      name: 'Brilliant White',
      hex: 'EDF1FF',
      rgb: {
        r: 237,
        g: 241,
        b: 255,
      },
      lab: {
        l: 95.77,
        a: 3.34,
        b: -17.23,
      },
      cmyk: null,
    },
    {
      code: '11-0601 TCX',
      name: 'Bright White',
      hex: 'F4F9FF',
      rgb: {
        r: 244,
        g: 249,
        b: 255,
      },
      lab: {
        l: 98.14,
        a: 2.17,
        b: -14.73,
      },
      cmyk: null,
    },
    {
      code: '11-0700 TCX',
      name: 'Lucent White',
      hex: 'F4F7FF',
      rgb: {
        r: 244,
        g: 247,
        b: 255,
      },
      lab: {
        l: 97.76,
        a: 3.11,
        b: -16.16,
      },
      cmyk: null,
    },
    {
      code: '11-4800 TCX',
      name: 'Blanc de Blanc',
      hex: 'E7E9E7',
      rgb: {
        r: 231,
        g: 233,
        b: 231,
      },
      lab: {
        l: 92.08,
        a: -0.72,
        b: 0.29,
      },
      cmyk: null,
    },
    {
      code: '11-4201 TCX',
      name: 'Cloud Dancer',
      hex: 'F0EEE9',
      rgb: {
        r: 240,
        g: 238,
        b: 233,
      },
      lab: {
        l: 94.14,
        a: 0.42,
        b: 2.51,
      },
      cmyk: null,
    },
    {
      code: '11-0608 TCX',
      name: 'Coconut Milk',
      hex: 'F1EEE6',
      rgb: {
        r: 241,
        g: 238,
        b: 230,
      },
      lab: {
        l: 94.12,
        a: 0.11,
        b: 4.14,
      },
      cmyk: null,
    },
    {
      code: '11-4801 TCX',
      name: 'Tofu',
      hex: 'E8E4DA',
      rgb: {
        r: 232,
        g: 228,
        b: 218,
      },
      lab: {
        l: 90.7,
        a: 0.42,
        b: 5.53,
      },
      cmyk: null,
    },
    {
      code: '11-0607 TCX',
      name: 'Sugar Swizzle',
      hex: 'F4EEE8',
      rgb: {
        r: 244,
        g: 238,
        b: 232,
      },
      lab: {
        l: 94.5,
        a: 1.32,
        b: 3.92,
      },
      cmyk: null,
    },
    {
      code: '11-1001 TCX',
      name: 'White Alyssum',
      hex: 'EFEBE7',
      rgb: {
        r: 239,
        g: 235,
        b: 231,
      },
      lab: {
        l: 93.3,
        a: 1.09,
        b: 2.4,
      },
      cmyk: null,
    },
    {
      code: '11-0602 TCX',
      name: 'Snow White',
      hex: 'F2F0EB',
      rgb: {
        r: 242,
        g: 240,
        b: 235,
      },
      lab: {
        l: 94.93,
        a: 0.1,
        b: 2.68,
      },
      cmyk: null,
    },
    {
      code: '11-4202 TCX',
      name: 'Star White',
      hex: 'EFEFE8',
      rgb: {
        r: 239,
        g: 239,
        b: 232,
      },
      lab: {
        l: 94.3,
        a: -0.59,
        b: 3.05,
      },
      cmyk: null,
    },
    {
      code: '11-4300 TCX',
      name: 'Marshmallow',
      hex: 'F0EEE4',
      rgb: {
        r: 240,
        g: 238,
        b: 228,
      },
      lab: {
        l: 94.11,
        a: -0.6,
        b: 4.8,
      },
      cmyk: null,
    },
    {
      code: '11-4302 TCX',
      name: 'Cannoli Cream',
      hex: 'F1F0E2',
      rgb: {
        r: 241,
        g: 240,
        b: 226,
      },
      lab: {
        l: 94.58,
        a: -1.24,
        b: 6.68,
      },
      cmyk: null,
    },
    {
      code: '11-0103 TCX',
      name: 'Egret',
      hex: 'F3ECE0',
      rgb: {
        r: 243,
        g: 236,
        b: 224,
      },
      lab: {
        l: 93.74,
        a: 1.19,
        b: 6.78,
      },
      cmyk: null,
    },
    {
      code: '11-0104 TCX',
      name: 'Vanilla Ice',
      hex: 'F0EADA',
      rgb: {
        r: 240,
        g: 234,
        b: 218,
      },
      lab: {
        l: 92.93,
        a: 0.14,
        b: 8.57,
      },
      cmyk: null,
    },
    {
      code: '11-0606 TCX',
      name: 'Pristine',
      hex: 'F2E8DA',
      rgb: {
        r: 242,
        g: 232,
        b: 218,
      },
      lab: {
        l: 92.61,
        a: 2.35,
        b: 7.96,
      },
      cmyk: null,
    },
    {
      code: '11-0701 TCX',
      name: 'Whisper White',
      hex: 'EDE6DB',
      rgb: {
        r: 237,
        g: 230,
        b: 219,
      },
      lab: {
        l: 91.74,
        a: 1.66,
        b: 6.08,
      },
      cmyk: null,
    },
    {
      code: '12-0601 TCX',
      name: 'Eggnog',
      hex: 'ECE1D3',
      rgb: {
        r: 236,
        g: 225,
        b: 211,
      },
      lab: {
        l: 90.22,
        a: 2.46,
        b: 8.32,
      },
      cmyk: null,
    },
    {
      code: '11-0604 TCX',
      name: 'Gardenia',
      hex: 'F1E9DF',
      rgb: {
        r: 241,
        g: 233,
        b: 223,
      },
      lab: {
        l: 92.65,
        a: 1.93,
        b: 5.68,
      },
      cmyk: null,
    },
    {
      code: '11-0605 TCX',
      name: 'Jet Stream',
      hex: 'EDE6DE',
      rgb: {
        r: 237,
        g: 230,
        b: 222,
      },
      lab: {
        l: 91.77,
        a: 1.51,
        b: 4.84,
      },
      cmyk: null,
    },
    {
      code: '13-0905 TCX',
      name: 'Birch',
      hex: 'DDD5C7',
      rgb: {
        r: 221,
        g: 213,
        b: 199,
      },
      lab: {
        l: 85.66,
        a: 1.51,
        b: 8.14,
      },
      cmyk: null,
    },
    {
      code: '11-0105 TCX',
      name: 'Antique White',
      hex: 'EEE5D3',
      rgb: {
        r: 238,
        g: 229,
        b: 211,
      },
      lab: {
        l: 91.32,
        a: 1.63,
        b: 9.86,
      },
      cmyk: null,
    },
    {
      code: '12-0703 TCX',
      name: 'Seedpearl',
      hex: 'E6DAC4',
      rgb: {
        r: 230,
        g: 218,
        b: 196,
      },
      lab: {
        l: 87.66,
        a: 1.83,
        b: 12.45,
      },
      cmyk: null,
    },
    {
      code: '12-0000 TCX',
      name: 'White Swan',
      hex: 'E4D7C5',
      rgb: {
        r: 228,
        g: 215,
        b: 197,
      },
      lab: {
        l: 86.66,
        a: 3.13,
        b: 10.42,
      },
      cmyk: null,
    },
    {
      code: '12-0304 TCX',
      name: 'Whitecap Gray',
      hex: 'E0D5C6',
      rgb: {
        r: 224,
        g: 213,
        b: 198,
      },
      lab: {
        l: 85.76,
        a: 2.64,
        b: 8.83,
      },
      cmyk: null,
    },
    {
      code: '13-0908 TCX',
      name: 'Parchment',
      hex: 'DFD1BE',
      rgb: {
        r: 223,
        g: 209,
        b: 190,
      },
      lab: {
        l: 84.62,
        a: 3.53,
        b: 11.29,
      },
      cmyk: null,
    },
    {
      code: '13-1006 TCX',
      name: 'Crème Brûlée',
      hex: 'DBCCB5',
      rgb: {
        r: 219,
        g: 204,
        b: 181,
      },
      lab: {
        l: 82.84,
        a: 3.61,
        b: 13.58,
      },
      cmyk: null,
    },
    {
      code: '12-0709 TCX',
      name: 'Macadamia',
      hex: 'E4CFB6',
      rgb: {
        r: 228,
        g: 207,
        b: 182,
      },
      lab: {
        l: 84.45,
        a: 4.98,
        b: 15.39,
      },
      cmyk: null,
    },
    {
      code: '11-0110 TCX',
      name: 'Buttercream',
      hex: 'EFE1CE',
      rgb: {
        r: 239,
        g: 225,
        b: 206,
      },
      lab: {
        l: 90.31,
        a: 3.21,
        b: 11.18,
      },
      cmyk: null,
    },
    {
      code: '11-0907 TCX',
      name: 'Pearled Ivory ',
      hex: 'F0DFCC',
      rgb: {
        r: 240,
        g: 223,
        b: 204,
      },
      lab: {
        l: 90.03,
        a: 4.31,
        b: 11.69,
      },
      cmyk: null,
    },
    {
      code: '12-0704 TCX',
      name: 'White Smoke',
      hex: 'EDDCC9',
      rgb: {
        r: 237,
        g: 220,
        b: 201,
      },
      lab: {
        l: 88.81,
        a: 4.5,
        b: 11.75,
      },
      cmyk: null,
    },
    {
      code: '11-0809 TCX',
      name: 'Ecru',
      hex: 'F3DFCA',
      rgb: {
        r: 243,
        g: 223,
        b: 202,
      },
      lab: {
        l: 90.19,
        a: 4.93,
        b: 13.05,
      },
      cmyk: null,
    },
    {
      code: '12-0710 TCX',
      name: 'Shortbread',
      hex: 'EFDCC3',
      rgb: {
        r: 239,
        g: 220,
        b: 195,
      },
      lab: {
        l: 88.97,
        a: 4.34,
        b: 15.08,
      },
      cmyk: null,
    },
    {
      code: '12-0605 TCX',
      name: 'Angora',
      hex: 'DFD1BB',
      rgb: {
        r: 223,
        g: 209,
        b: 187,
      },
      lab: {
        l: 84.76,
        a: 2.84,
        b: 12.74,
      },
      cmyk: null,
    },
    {
      code: '13-1008 TCX',
      name: 'Bleached Sand',
      hex: 'DACCB4',
      rgb: {
        r: 218,
        g: 204,
        b: 180,
      },
      lab: {
        l: 82.95,
        a: 2.63,
        b: 13.99,
      },
      cmyk: null,
    },
    {
      code: '13-1104 TCX',
      name: 'Sheer Bliss',
      hex: 'DBCABA',
      rgb: {
        r: 219,
        g: 202,
        b: 186,
      },
      lab: {
        l: 82.62,
        a: 4.61,
        b: 10.39,
      },
      cmyk: null,
    },
    {
      code: '12-1404 TCX',
      name: 'Pink Tint',
      hex: 'DBCBBE',
      rgb: {
        r: 219,
        g: 203,
        b: 190,
      },
      lab: {
        l: 82.95,
        a: 4.59,
        b: 8.79,
      },
      cmyk: null,
    },
    {
      code: '13-1107 TCX',
      name: 'Whisper Pink',
      hex: 'DBCBBE',
      rgb: {
        r: 219,
        g: 203,
        b: 190,
      },
      lab: {
        l: 82.89,
        a: 4.51,
        b: 9.04,
      },
      cmyk: null,
    },
    {
      code: '13-1106 TCX',
      name: 'Sand Dollar',
      hex: 'DECDBE',
      rgb: {
        r: 222,
        g: 205,
        b: 190,
      },
      lab: {
        l: 83.7,
        a: 4.98,
        b: 10.07,
      },
      cmyk: null,
    },
    {
      code: '13-1308 TCX',
      name: 'Brazilian Sand',
      hex: 'DBCAB8',
      rgb: {
        r: 219,
        g: 202,
        b: 184,
      },
      lab: {
        l: 82.49,
        a: 4.25,
        b: 11.59,
      },
      cmyk: null,
    },
    {
      code: '12-1403 TCX',
      name: 'Tapioca',
      hex: 'DCCDBC',
      rgb: {
        r: 220,
        g: 205,
        b: 188,
      },
      lab: {
        l: 83.27,
        a: 4.08,
        b: 10.35,
      },
      cmyk: null,
    },
    {
      code: '13-0907 TCX',
      name: 'Sandshell',
      hex: 'D8CCBB',
      rgb: {
        r: 216,
        g: 204,
        b: 187,
      },
      lab: {
        l: 82.62,
        a: 3.02,
        b: 9.59,
      },
      cmyk: null,
    },
    {
      code: '13-0404 TCX',
      name: 'Ancient Scroll',
      hex: 'CBC3B7',
      rgb: {
        r: 203,
        g: 195,
        b: 183,
      },
      lab: {
        l: 79.19,
        a: 2.19,
        b: 6.71,
      },
      cmyk: null,
    },
    {
      code: '13-0401 TCX',
      name: 'Oatmeal',
      hex: 'CBC3B4',
      rgb: {
        r: 203,
        g: 195,
        b: 180,
      },
      lab: {
        l: 79.14,
        a: 1.95,
        b: 8.22,
      },
      cmyk: null,
    },
    {
      code: '13-0003 TCX',
      name: 'Oat Milk',
      hex: 'D3C9BE',
      rgb: {
        r: 211,
        g: 201,
        b: 190,
      },
      lab: {
        l: 81.49,
        a: 2.91,
        b: 6.76,
      },
      cmyk: null,
    },
    {
      code: '13-0400 TCX',
      name: 'French Oak',
      hex: 'CBC1B3',
      rgb: {
        r: 203,
        g: 193,
        b: 179,
      },
      lab: {
        l: 78.65,
        a: 2.77,
        b: 8.12,
      },
      cmyk: null,
    },
    {
      code: '14-0002 TCX',
      name: 'Pumice Stone',
      hex: 'CAC2B9',
      rgb: {
        r: 202,
        g: 194,
        b: 185,
      },
      lab: {
        l: 78.89,
        a: 2.18,
        b: 5.7,
      },
      cmyk: null,
    },
    {
      code: '13-5304 TCX',
      name: 'Rainy Day',
      hex: 'CFC8BD',
      rgb: {
        r: 207,
        g: 200,
        b: 189,
      },
      lab: {
        l: 80.85,
        a: 1.72,
        b: 6.2,
      },
      cmyk: null,
    },
    {
      code: '13-0000 TCX',
      name: 'Moonbeam',
      hex: 'CDC6BD',
      rgb: {
        r: 205,
        g: 198,
        b: 189,
      },
      lab: {
        l: 80.29,
        a: 1.76,
        b: 5.26,
      },
      cmyk: null,
    },
    {
      code: '12-0105 TCX',
      name: 'Bone White',
      hex: 'D6D1C0',
      rgb: {
        r: 214,
        g: 209,
        b: 192,
      },
      lab: {
        l: 83.83,
        a: 0.48,
        b: 9.03,
      },
      cmyk: null,
    },
    {
      code: '13-0607 TCX',
      name: 'Fog',
      hex: 'D0C5B1',
      rgb: {
        r: 208,
        g: 197,
        b: 177,
      },
      lab: {
        l: 79.99,
        a: 2.13,
        b: 11.36,
      },
      cmyk: null,
    },
    {
      code: '14-1107 TCX',
      name: 'Oyster Gray',
      hex: 'CBC1AE',
      rgb: {
        r: 203,
        g: 193,
        b: 174,
      },
      lab: {
        l: 78.5,
        a: 2.22,
        b: 10.68,
      },
      cmyk: null,
    },
    {
      code: '14-1106 TCX',
      name: 'Peyote',
      hex: 'C5BBAE',
      rgb: {
        r: 197,
        g: 187,
        b: 174,
      },
      lab: {
        l: 76.52,
        a: 2.24,
        b: 8.04,
      },
      cmyk: null,
    },
    {
      code: '15-1305 TCX',
      name: 'Feather Gray',
      hex: 'B8AD9E',
      rgb: {
        r: 184,
        g: 173,
        b: 158,
      },
      lab: {
        l: 71.43,
        a: 2.69,
        b: 9,
      },
      cmyk: null,
    },
    {
      code: '15-1306 TCX',
      name: 'Oxford Tan',
      hex: 'B8A99A',
      rgb: {
        r: 184,
        g: 169,
        b: 154,
      },
      lab: {
        l: 70.38,
        a: 4.15,
        b: 10.03,
      },
      cmyk: null,
    },
    {
      code: '15-1304 TCX',
      name: 'Humus',
      hex: 'B7A793',
      rgb: {
        r: 183,
        g: 167,
        b: 147,
      },
      lab: {
        l: 69.59,
        a: 4.16,
        b: 12.63,
      },
      cmyk: null,
    },
    {
      code: '12-4302 TCX',
      name: 'Vaporous Gray',
      hex: 'DFDDD7',
      rgb: {
        r: 223,
        g: 221,
        b: 215,
      },
      lab: {
        l: 88.25,
        a: 0.14,
        b: 3.06,
      },
      cmyk: null,
    },
    {
      code: '12-4300 TCX',
      name: 'White Onyx',
      hex: 'D7D6CE',
      rgb: {
        r: 215,
        g: 214,
        b: 206,
      },
      lab: {
        l: 85.56,
        a: -0.22,
        b: 4.08,
      },
      cmyk: null,
    },
    {
      code: '13-4403 TCX',
      name: 'Silver Birch',
      hex: 'D2CFC4',
      rgb: {
        r: 210,
        g: 207,
        b: 196,
      },
      lab: {
        l: 83.07,
        a: -0.04,
        b: 5.55,
      },
      cmyk: null,
    },
    {
      code: '12-4301 TCX',
      name: 'Almond Milk',
      hex: 'D6CFBE',
      rgb: {
        r: 214,
        g: 207,
        b: 190,
      },
      lab: {
        l: 83.38,
        a: 0.92,
        b: 9.19,
      },
      cmyk: null,
    },
    {
      code: '14-0105 TCX',
      name: 'Overcast',
      hex: 'C3BDAB',
      rgb: {
        r: 195,
        g: 189,
        b: 171,
      },
      lab: {
        l: 76.8,
        a: 0.32,
        b: 9.97,
      },
      cmyk: null,
    },
    {
      code: '14-6305 TCX',
      name: 'Pelican',
      hex: 'C1BCAC',
      rgb: {
        r: 193,
        g: 188,
        b: 172,
      },
      lab: {
        l: 76.24,
        a: 0.52,
        b: 8.41,
      },
      cmyk: null,
    },
    {
      code: '16-1105 TCX',
      name: 'Plaza Taupe',
      hex: 'AEA393',
      rgb: {
        r: 174,
        g: 163,
        b: 147,
      },
      lab: {
        l: 67.77,
        a: 2.36,
        b: 10.05,
      },
      cmyk: null,
    },
    {
      code: '12-5201 TCX',
      name: 'Icicle',
      hex: 'DBDDD1',
      rgb: {
        r: 219,
        g: 221,
        b: 209,
      },
      lab: {
        l: 87.6,
        a: -2.11,
        b: 5.42,
      },
      cmyk: null,
    },
    {
      code: '12-0404 TCX',
      name: 'Light Gray',
      hex: 'DAD8C9',
      rgb: {
        r: 218,
        g: 216,
        b: 201,
      },
      lab: {
        l: 86.15,
        a: -1.04,
        b: 7.59,
      },
      cmyk: null,
    },
    {
      code: '12-6204 TCX',
      name: 'Silver Green',
      hex: 'D7D7C7',
      rgb: {
        r: 215,
        g: 215,
        b: 199,
      },
      lab: {
        l: 85.67,
        a: -1.72,
        b: 8.01,
      },
      cmyk: null,
    },
    {
      code: '14-6308 TCX',
      name: 'Alfalfa',
      hex: 'B7B59F',
      rgb: {
        r: 183,
        g: 181,
        b: 159,
      },
      lab: {
        l: 73.49,
        a: -1.9,
        b: 11.65,
      },
      cmyk: null,
    },
    {
      code: '15-6410 TCX',
      name: 'Moss Gray',
      hex: 'AFAB97',
      rgb: {
        r: 175,
        g: 171,
        b: 151,
      },
      lab: {
        l: 69.95,
        a: -0.79,
        b: 10.68,
      },
      cmyk: null,
    },
    {
      code: '15-6307 TCX',
      name: 'Agate Gray',
      hex: 'B3B1A1',
      rgb: {
        r: 179,
        g: 177,
        b: 161,
      },
      lab: {
        l: 72.02,
        a: -0.51,
        b: 8.12,
      },
      cmyk: null,
    },
    {
      code: '14-6408 TCX',
      name: 'Abbey Stone',
      hex: 'ABA798',
      rgb: {
        r: 171,
        g: 167,
        b: 152,
      },
      lab: {
        l: 68.51,
        a: -0.14,
        b: 8.26,
      },
      cmyk: null,
    },
    {
      code: '12-5202 TCX',
      name: 'Turtledove',
      hex: 'DED7C8',
      rgb: {
        r: 222,
        g: 215,
        b: 200,
      },
      lab: {
        l: 86.36,
        a: 0.88,
        b: 8.18,
      },
      cmyk: null,
    },
    {
      code: '12-0104 TCX',
      name: 'White Asparagus',
      hex: 'E1DBC9',
      rgb: {
        r: 225,
        g: 219,
        b: 201,
      },
      lab: {
        l: 87.6,
        a: -0.19,
        b: 9.58,
      },
      cmyk: null,
    },
    {
      code: '12-0110 TCX',
      name: 'Pistachio Shell',
      hex: 'D7D0BC',
      rgb: {
        r: 215,
        g: 208,
        b: 188,
      },
      lab: {
        l: 83.71,
        a: 0.55,
        b: 11.02,
      },
      cmyk: null,
    },
    {
      code: '14-0108 TCX',
      name: 'Castle Wall',
      hex: 'C9C0AB',
      rgb: {
        r: 201,
        g: 192,
        b: 171,
      },
      lab: {
        l: 78.14,
        a: 1.06,
        b: 11.75,
      },
      cmyk: null,
    },
    {
      code: '14-0210 TCX',
      name: 'Tidal Foam',
      hex: 'BFB9A3',
      rgb: {
        r: 191,
        g: 185,
        b: 163,
      },
      lab: {
        l: 75.17,
        a: 0.29,
        b: 11.76,
      },
      cmyk: null,
    },
    {
      code: '15-0513 TCX',
      name: 'Eucalyptus',
      hex: 'B1A992',
      rgb: {
        r: 177,
        g: 169,
        b: 146,
      },
      lab: {
        l: 69.58,
        a: 0.32,
        b: 12.9,
      },
      cmyk: null,
    },
    {
      code: '15-0309 TCX',
      name: 'Spray Green',
      hex: 'AEA692',
      rgb: {
        r: 174,
        g: 166,
        b: 146,
      },
      lab: {
        l: 68.58,
        a: 0.74,
        b: 11.83,
      },
      cmyk: null,
    },
    {
      code: '12-0311 TCX',
      name: 'Asparagus Green',
      hex: 'D2CDB4',
      rgb: {
        r: 210,
        g: 205,
        b: 180,
      },
      lab: {
        l: 82.35,
        a: -0.54,
        b: 12.74,
      },
      cmyk: null,
    },
    {
      code: '13-1007 TCX',
      name: 'Oyster White',
      hex: 'D2CAAF',
      rgb: {
        r: 210,
        g: 202,
        b: 175,
      },
      lab: {
        l: 81.48,
        a: 0.17,
        b: 14.44,
      },
      cmyk: null,
    },
    {
      code: '13-0611 TCX',
      name: 'Moth',
      hex: 'D2CBAF',
      rgb: {
        r: 210,
        g: 203,
        b: 175,
      },
      lab: {
        l: 81.74,
        a: -0.12,
        b: 14.65,
      },
      cmyk: null,
    },
    {
      code: '14-0615 TCX',
      name: 'Green Haze',
      hex: 'CAC4A4',
      rgb: {
        r: 202,
        g: 196,
        b: 164,
      },
      lab: {
        l: 78.85,
        a: -0.54,
        b: 16.36,
      },
      cmyk: null,
    },
    {
      code: '15-1115 TCX',
      name: 'Fields of Rye',
      hex: 'B7A990',
      rgb: {
        r: 183,
        g: 169,
        b: 144,
      },
      lab: {
        l: 69.93,
        a: 2.91,
        b: 14.42,
      },
      cmyk: null,
    },
    {
      code: '17-1107 TCX',
      name: 'Seneca Rock',
      hex: '9A927F',
      rgb: {
        r: 154,
        g: 146,
        b: 127,
      },
      lab: {
        l: 60.82,
        a: 1.35,
        b: 10.92,
      },
      cmyk: null,
    },
    {
      code: '17-0510 TCX',
      name: 'Silver Sage',
      hex: '938B78',
      rgb: {
        r: 147,
        g: 139,
        b: 120,
      },
      lab: {
        l: 58.34,
        a: 0.9,
        b: 11.59,
      },
      cmyk: null,
    },
    {
      code: '13-0513 TCX',
      name: 'Frozen Dew',
      hex: 'D8CFB2',
      rgb: {
        r: 216,
        g: 207,
        b: 178,
      },
      lab: {
        l: 83.35,
        a: 0.97,
        b: 15.42,
      },
      cmyk: null,
    },
    {
      code: '13-0711 TCX',
      name: 'Putty',
      hex: 'D4CAB0',
      rgb: {
        r: 212,
        g: 202,
        b: 176,
      },
      lab: {
        l: 81.73,
        a: 0.69,
        b: 14.43,
      },
      cmyk: null,
    },
    {
      code: '13-1105 TCX',
      name: 'Brown Rice',
      hex: 'C8BCA5',
      rgb: {
        r: 200,
        g: 188,
        b: 165,
      },
      lab: {
        l: 76.81,
        a: 2.07,
        b: 13.38,
      },
      cmyk: null,
    },
    {
      code: '16-1119 TCX',
      name: 'Lemon Pepper',
      hex: 'A1947D',
      rgb: {
        r: 161,
        g: 148,
        b: 125,
      },
      lab: {
        l: 62.11,
        a: 2.65,
        b: 13.99,
      },
      cmyk: null,
    },
    {
      code: '17-1108 TCX',
      name: 'Winter Twig',
      hex: '958A7A',
      rgb: {
        r: 149,
        g: 138,
        b: 122,
      },
      lab: {
        l: 58.3,
        a: 2.61,
        b: 10.11,
      },
      cmyk: null,
    },
    {
      code: '17-1113 TCX',
      name: 'Coriander',
      hex: '948772',
      rgb: {
        r: 148,
        g: 135,
        b: 114,
      },
      lab: {
        l: 57.04,
        a: 2.86,
        b: 13.15,
      },
      cmyk: null,
    },
    {
      code: '17-1118 TCX',
      name: 'Lead Gray',
      hex: '8A7963',
      rgb: {
        r: 138,
        g: 121,
        b: 99,
      },
      lab: {
        l: 52.18,
        a: 5.28,
        b: 14.46,
      },
      cmyk: null,
    },
    {
      code: '15-1116 TCX',
      name: 'Safari',
      hex: 'BBAA91',
      rgb: {
        r: 187,
        g: 170,
        b: 145,
      },
      lab: {
        l: 70.6,
        a: 4.02,
        b: 15.28,
      },
      cmyk: null,
    },
    {
      code: '16-1010 TCX',
      name: 'Incense',
      hex: 'B09A7D',
      rgb: {
        r: 176,
        g: 154,
        b: 125,
      },
      lab: {
        l: 65.23,
        a: 5.35,
        b: 18.41,
      },
      cmyk: null,
    },
    {
      code: '15-1114 TCX',
      name: 'Travertine',
      hex: 'AE997D',
      rgb: {
        r: 174,
        g: 153,
        b: 125,
      },
      lab: {
        l: 64.74,
        a: 5.37,
        b: 17.73,
      },
      cmyk: null,
    },
    {
      code: '16-1315 TCX',
      name: 'Cornstalk',
      hex: 'A9947A',
      rgb: {
        r: 169,
        g: 148,
        b: 122,
      },
      lab: {
        l: 62.88,
        a: 5.69,
        b: 16.96,
      },
      cmyk: null,
    },
    {
      code: '17-1022 TCX',
      name: 'Kelp',
      hex: '998467',
      rgb: {
        r: 153,
        g: 132,
        b: 103,
      },
      lab: {
        l: 56.67,
        a: 5.64,
        b: 18.97,
      },
      cmyk: null,
    },
    {
      code: '17-1115 TCX',
      name: 'Petrified Oak',
      hex: '8D7961',
      rgb: {
        r: 141,
        g: 121,
        b: 97,
      },
      lab: {
        l: 52.52,
        a: 5.94,
        b: 16.56,
      },
      cmyk: null,
    },
    {
      code: '17-1314 TCX',
      name: 'Sepia Tint',
      hex: '8A7661',
      rgb: {
        r: 138,
        g: 118,
        b: 97,
      },
      lab: {
        l: 51.12,
        a: 6.27,
        b: 14.73,
      },
      cmyk: null,
    },
    {
      code: '14-1108 TCX',
      name: 'Wood Ash',
      hex: 'D7CAB0',
      rgb: {
        r: 215,
        g: 202,
        b: 176,
      },
      lab: {
        l: 81.88,
        a: 2.55,
        b: 14.4,
      },
      cmyk: null,
    },
    {
      code: '15-1218 TCX',
      name: 'Semolina',
      hex: 'CEB899',
      rgb: {
        r: 206,
        g: 184,
        b: 153,
      },
      lab: {
        l: 76.24,
        a: 5.2,
        b: 19.02,
      },
      cmyk: null,
    },
    {
      code: '14-1112 TCX',
      name: 'Pebble',
      hex: 'CAB698',
      rgb: {
        r: 202,
        g: 182,
        b: 152,
      },
      lab: {
        l: 75.26,
        a: 4.98,
        b: 17.97,
      },
      cmyk: null,
    },
    {
      code: '14-1208 TCX',
      name: 'Irish Cream',
      hex: 'C0AC93',
      rgb: {
        r: 192,
        g: 172,
        b: 147,
      },
      lab: {
        l: 71.79,
        a: 5.15,
        b: 15.83,
      },
      cmyk: null,
    },
    {
      code: '16-1213 TCX',
      name: 'Savannah Tan',
      hex: 'A69483',
      rgb: {
        r: 166,
        g: 148,
        b: 131,
      },
      lab: {
        l: 62.68,
        a: 5.44,
        b: 11.78,
      },
      cmyk: null,
    },
    {
      code: '17-1112 TCX',
      name: 'Weathered Teak',
      hex: '9B8F7F',
      rgb: {
        r: 155,
        g: 143,
        b: 127,
      },
      lab: {
        l: 60.09,
        a: 3.66,
        b: 10.26,
      },
      cmyk: null,
    },
    {
      code: '17-1312 TCX',
      name: 'Silver Mink',
      hex: '9F8E7C',
      rgb: {
        r: 159,
        g: 142,
        b: 124,
      },
      lab: {
        l: 60.13,
        a: 4.98,
        b: 12.21,
      },
      cmyk: null,
    },
    {
      code: '14-1014 TCX',
      name: 'Gravel',
      hex: 'CBBFA2',
      rgb: {
        r: 203,
        g: 191,
        b: 162,
      },
      lab: {
        l: 77.86,
        a: 1.71,
        b: 16.42,
      },
      cmyk: null,
    },
    {
      code: '15-1217 TCX',
      name: 'Mojave Desert',
      hex: 'C7B595',
      rgb: {
        r: 199,
        g: 181,
        b: 149,
      },
      lab: {
        l: 74.65,
        a: 3.62,
        b: 18.76,
      },
      cmyk: null,
    },
    {
      code: '15-1216 TCX',
      name: 'Pale Khaki',
      hex: 'BFAF92',
      rgb: {
        r: 191,
        g: 175,
        b: 146,
      },
      lab: {
        l: 72.43,
        a: 3.1,
        b: 17.02,
      },
      cmyk: null,
    },
    {
      code: '15-1307 TCX',
      name: 'White Pepper',
      hex: 'B6A893',
      rgb: {
        r: 182,
        g: 168,
        b: 147,
      },
      lab: {
        l: 69.67,
        a: 3.44,
        b: 12.74,
      },
      cmyk: null,
    },
    {
      code: '16-1101 TCX',
      name: 'Trench Coat',
      hex: 'AA9D89',
      rgb: {
        r: 170,
        g: 157,
        b: 137,
      },
      lab: {
        l: 65.58,
        a: 2.61,
        b: 12.71,
      },
      cmyk: null,
    },
    {
      code: '16-1104 TCX',
      name: 'Crockery',
      hex: 'A49887',
      rgb: {
        r: 164,
        g: 152,
        b: 135,
      },
      lab: {
        l: 63.5,
        a: 3.44,
        b: 10.47,
      },
      cmyk: null,
    },
    {
      code: '17-1109 TCX',
      name: 'Chinchilla',
      hex: '9C8E7B',
      rgb: {
        r: 156,
        g: 142,
        b: 123,
      },
      lab: {
        l: 60.08,
        a: 3.74,
        b: 12.21,
      },
      cmyk: null,
    },
    {
      code: '14-1110 TCX',
      name: 'Boulder',
      hex: 'D1BE9B',
      rgb: {
        r: 209,
        g: 190,
        b: 155,
      },
      lab: {
        l: 77.99,
        a: 3.59,
        b: 20.41,
      },
      cmyk: null,
    },
    {
      code: '14-1116 TCX',
      name: 'Almond Buff',
      hex: 'CCB390',
      rgb: {
        r: 204,
        g: 179,
        b: 144,
      },
      lab: {
        l: 74.63,
        a: 6.15,
        b: 21.55,
      },
      cmyk: null,
    },
    {
      code: '15-1119 TCX',
      name: 'Taos Taupe',
      hex: 'BFA77F',
      rgb: {
        r: 191,
        g: 167,
        b: 127,
      },
      lab: {
        l: 69.92,
        a: 5.86,
        b: 23.87,
      },
      cmyk: null,
    },
    {
      code: '16-0924 TCX',
      name: 'Croissant',
      hex: 'C4AB86',
      rgb: {
        r: 196,
        g: 171,
        b: 134,
      },
      lab: {
        l: 71.49,
        a: 6.25,
        b: 22.38,
      },
      cmyk: null,
    },
    {
      code: '16-0920 TCX',
      name: 'Curds & Whey',
      hex: 'BCA483',
      rgb: {
        r: 188,
        g: 164,
        b: 131,
      },
      lab: {
        l: 69.01,
        a: 6.19,
        b: 20.62,
      },
      cmyk: null,
    },
    {
      code: '16-1120 TCX',
      name: 'Starfish',
      hex: 'B09A77',
      rgb: {
        r: 176,
        g: 154,
        b: 119,
      },
      lab: {
        l: 65.16,
        a: 5.14,
        b: 21.65,
      },
      cmyk: null,
    },
    {
      code: '16-1324 TCX',
      name: 'Lark',
      hex: 'B89B72',
      rgb: {
        r: 184,
        g: 155,
        b: 114,
      },
      lab: {
        l: 66.1,
        a: 7.16,
        b: 26.14,
      },
      cmyk: null,
    },
    {
      code: '13-1009 TCX',
      name: 'Biscotti',
      hex: 'DAC7AB',
      rgb: {
        r: 218,
        g: 199,
        b: 171,
      },
      lab: {
        l: 81.34,
        a: 4.36,
        b: 16.69,
      },
      cmyk: null,
    },
    {
      code: '13-1016 TCX',
      name: 'Wheat',
      hex: 'DEC5A5',
      rgb: {
        r: 222,
        g: 197,
        b: 165,
      },
      lab: {
        l: 81.08,
        a: 6.17,
        b: 19.54,
      },
      cmyk: null,
    },
    {
      code: '13-1011 TCX',
      name: 'Ivory Cream',
      hex: 'DAC0A7',
      rgb: {
        r: 218,
        g: 192,
        b: 167,
      },
      lab: {
        l: 79.68,
        a: 6.92,
        b: 16.32,
      },
      cmyk: null,
    },
    {
      code: '14-1118 TCX',
      name: 'Beige',
      hex: 'D5BA98',
      rgb: {
        r: 213,
        g: 186,
        b: 152,
      },
      lab: {
        l: 77.39,
        a: 7.07,
        b: 21.03,
      },
      cmyk: null,
    },
    {
      code: '15-1214 TCX',
      name: 'Warm Sand',
      hex: 'C5AE91',
      rgb: {
        r: 197,
        g: 174,
        b: 145,
      },
      lab: {
        l: 72.74,
        a: 5.71,
        b: 18.44,
      },
      cmyk: null,
    },
    {
      code: '15-1020 TCX',
      name: 'Ginger Root',
      hex: 'C0A68B',
      rgb: {
        r: 192,
        g: 166,
        b: 139,
      },
      lab: {
        l: 70.03,
        a: 7.55,
        b: 17.87,
      },
      cmyk: null,
    },
    {
      code: '17-1320 TCX',
      name: 'Tannin',
      hex: 'A68A6D',
      rgb: {
        r: 166,
        g: 138,
        b: 109,
      },
      lab: {
        l: 59.71,
        a: 8.32,
        b: 19.81,
      },
      cmyk: null,
    },
    {
      code: '12-0811 TCX',
      name: 'Dawn',
      hex: 'EBD2B7',
      rgb: {
        r: 235,
        g: 210,
        b: 183,
      },
      lab: {
        l: 85.94,
        a: 6.45,
        b: 16.82,
      },
      cmyk: null,
    },
    {
      code: '13-1010 TCX',
      name: 'Gray Sand',
      hex: 'E5CCAF',
      rgb: {
        r: 229,
        g: 204,
        b: 175,
      },
      lab: {
        l: 83.62,
        a: 6.73,
        b: 17.57,
      },
      cmyk: null,
    },
    {
      code: '14-1120 TCX',
      name: 'Apricot Illusion',
      hex: 'E2C4A6',
      rgb: {
        r: 226,
        g: 196,
        b: 166,
      },
      lab: {
        l: 81.49,
        a: 8.24,
        b: 19.76,
      },
      cmyk: null,
    },
    {
      code: '13-1013 TCX',
      name: 'Appleblossom',
      hex: 'DDBCA0',
      rgb: {
        r: 221,
        g: 188,
        b: 160,
      },
      lab: {
        l: 78.92,
        a: 9.68,
        b: 19.47,
      },
      cmyk: null,
    },
    {
      code: '14-1119 TCX',
      name: 'Winter Wheat',
      hex: 'DFC09F',
      rgb: {
        r: 223,
        g: 192,
        b: 159,
      },
      lab: {
        l: 80.03,
        a: 8.39,
        b: 21.67,
      },
      cmyk: null,
    },
    {
      code: '13-1015 TCX',
      name: 'Honey Peach',
      hex: 'DCBD9E',
      rgb: {
        r: 220,
        g: 189,
        b: 158,
      },
      lab: {
        l: 78.99,
        a: 8.69,
        b: 20.3,
      },
      cmyk: null,
    },
    {
      code: '13-1014 TCX',
      name: 'Mellow Buff',
      hex: 'D8B998',
      rgb: {
        r: 216,
        g: 185,
        b: 152,
      },
      lab: {
        l: 77.39,
        a: 9.01,
        b: 21.34,
      },
      cmyk: null,
    },
    {
      code: '12-1009 TCX',
      name: 'Vanilla Cream',
      hex: 'F5D8C6',
      rgb: {
        r: 245,
        g: 216,
        b: 198,
      },
      lab: {
        l: 88.52,
        a: 9.52,
        b: 12.88,
      },
      cmyk: null,
    },
    {
      code: '12-1006 TCX',
      name: 'Mother of Pearl',
      hex: 'E9D4C3',
      rgb: {
        r: 233,
        g: 212,
        b: 195,
      },
      lab: {
        l: 86.46,
        a: 5.93,
        b: 11.43,
      },
      cmyk: null,
    },
    {
      code: '12-1008 TCX',
      name: 'Linen',
      hex: 'EDD2C0',
      rgb: {
        r: 237,
        g: 210,
        b: 192,
      },
      lab: {
        l: 86.26,
        a: 8.96,
        b: 12.64,
      },
      cmyk: null,
    },
    {
      code: '12-0912 TCX',
      name: 'Tender Peach',
      hex: 'F8D5B8',
      rgb: {
        r: 248,
        g: 213,
        b: 184,
      },
      lab: {
        l: 87.83,
        a: 10.27,
        b: 19.11,
      },
      cmyk: null,
    },
    {
      code: '12-0911 TCX',
      name: 'Peach Taffy',
      hex: 'F2D3BC',
      rgb: {
        r: 242,
        g: 211,
        b: 188,
      },
      lab: {
        l: 86.95,
        a: 9.43,
        b: 16.14,
      },
      cmyk: null,
    },
    {
      code: '12-1011 TCX',
      name: 'Peach Purée',
      hex: 'EFCFBA',
      rgb: {
        r: 239,
        g: 207,
        b: 186,
      },
      lab: {
        l: 85.68,
        a: 10.03,
        b: 15.16,
      },
      cmyk: null,
    },
    {
      code: '12-0913 TCX',
      name: 'Alesan',
      hex: 'F1CEB3',
      rgb: {
        r: 241,
        g: 206,
        b: 179,
      },
      lab: {
        l: 85.52,
        a: 10.5,
        b: 18.44,
      },
      cmyk: null,
    },
    {
      code: '12-1007 TCX',
      name: 'Pastel Rose Tan',
      hex: 'E9D1BF',
      rgb: {
        r: 233,
        g: 209,
        b: 191,
      },
      lab: {
        l: 85.63,
        a: 6.72,
        b: 12.16,
      },
      cmyk: null,
    },
    {
      code: '12-0807 TCX',
      name: 'Sun Kiss',
      hex: 'EBD1BB',
      rgb: {
        r: 235,
        g: 209,
        b: 187,
      },
      lab: {
        l: 85.83,
        a: 7.11,
        b: 14.59,
      },
      cmyk: null,
    },
    {
      code: '12-1005 TCX',
      name: 'Novelle Peach',
      hex: 'E7CFBD',
      rgb: {
        r: 231,
        g: 207,
        b: 189,
      },
      lab: {
        l: 85.01,
        a: 6.96,
        b: 12.53,
      },
      cmyk: null,
    },
    {
      code: '14-1210 TCX',
      name: 'Shifting Sand',
      hex: 'D8C0AD',
      rgb: {
        r: 216,
        g: 192,
        b: 173,
      },
      lab: {
        l: 79.53,
        a: 7.74,
        b: 12.6,
      },
      cmyk: null,
    },
    {
      code: '14-1217 TCX',
      name: 'Amberlight',
      hex: 'E2BEA2',
      rgb: {
        r: 226,
        g: 190,
        b: 162,
      },
      lab: {
        l: 79.72,
        a: 11.24,
        b: 19.25,
      },
      cmyk: null,
    },
    {
      code: '14-1213 TCX',
      name: 'Toasted Almond',
      hex: 'D2B49C',
      rgb: {
        r: 210,
        g: 180,
        b: 156,
      },
      lab: {
        l: 75.67,
        a: 9.5,
        b: 16.47,
      },
      cmyk: null,
    },
    {
      code: '14-1315 TCX',
      name: 'Hazelnut',
      hex: 'CFB095',
      rgb: {
        r: 207,
        g: 176,
        b: 149,
      },
      lab: {
        l: 74.4,
        a: 9.31,
        b: 18.51,
      },
      cmyk: null,
    },
    {
      code: '14-1209 TCX',
      name: 'Smoke Gray',
      hex: 'CEBBA8',
      rgb: {
        r: 206,
        g: 187,
        b: 168,
      },
      lab: {
        l: 77.14,
        a: 5.62,
        b: 12.14,
      },
      cmyk: null,
    },
    {
      code: '14-0708 TCX',
      name: 'Cement',
      hex: 'C4B6A6',
      rgb: {
        r: 196,
        g: 182,
        b: 166,
      },
      lab: {
        l: 75.05,
        a: 4.19,
        b: 9.7,
      },
      cmyk: null,
    },
    {
      code: '14-1212 TCX',
      name: 'Frappé',
      hex: 'D1B7A0',
      rgb: {
        r: 209,
        g: 183,
        b: 160,
      },
      lab: {
        l: 76.29,
        a: 7.74,
        b: 15.44,
      },
      cmyk: null,
    },
    {
      code: '15-1215 TCX',
      name: 'Sesame',
      hex: 'BAA38B',
      rgb: {
        r: 186,
        g: 163,
        b: 139,
      },
      lab: {
        l: 68.62,
        a: 6.75,
        b: 15.73,
      },
      cmyk: null,
    },
    {
      code: '16-1212 TCX',
      name: 'Nomad',
      hex: 'B49F89',
      rgb: {
        r: 180,
        g: 159,
        b: 137,
      },
      lab: {
        l: 66.99,
        a: 6.38,
        b: 14.24,
      },
      cmyk: null,
    },
    {
      code: '15-1314 TCX',
      name: 'Cuban Sand',
      hex: 'C1A68D',
      rgb: {
        r: 193,
        g: 166,
        b: 141,
      },
      lab: {
        l: 70.12,
        a: 8.27,
        b: 16.76,
      },
      cmyk: null,
    },
    {
      code: '15-1213 TCX',
      name: 'Candied Ginger',
      hex: 'BFA387',
      rgb: {
        r: 191,
        g: 163,
        b: 135,
      },
      lab: {
        l: 69.07,
        a: 9.59,
        b: 17.9,
      },
      cmyk: null,
    },
    {
      code: '15-1309 TCX',
      name: 'Moonlight',
      hex: 'C5B1A0',
      rgb: {
        r: 197,
        g: 177,
        b: 160,
      },
      lab: {
        l: 73.69,
        a: 6.27,
        b: 11.56,
      },
      cmyk: null,
    },
    {
      code: '16-1210 TCX',
      name: 'Light Taupe',
      hex: 'B19D8D',
      rgb: {
        r: 177,
        g: 157,
        b: 141,
      },
      lab: {
        l: 66.25,
        a: 7.1,
        b: 10.67,
      },
      cmyk: null,
    },
    {
      code: '15-1308 TCX',
      name: 'Doeskin',
      hex: 'BDAB9B',
      rgb: {
        r: 189,
        g: 171,
        b: 155,
      },
      lab: {
        l: 71.27,
        a: 5.67,
        b: 10.92,
      },
      cmyk: null,
    },
    {
      code: '16-1310 TCX',
      name: 'Natural',
      hex: 'AA907D',
      rgb: {
        r: 170,
        g: 144,
        b: 125,
      },
      lab: {
        l: 62.06,
        a: 8.68,
        b: 14.01,
      },
      cmyk: null,
    },
    {
      code: '16-1318 TCX',
      name: 'Warm Taupe',
      hex: 'AF9483',
      rgb: {
        r: 175,
        g: 148,
        b: 131,
      },
      lab: {
        l: 63.52,
        a: 9.17,
        b: 13.17,
      },
      cmyk: null,
    },
    {
      code: '17-1319 TCX',
      name: 'Amphora',
      hex: '9F8672',
      rgb: {
        r: 159,
        g: 134,
        b: 114,
      },
      lab: {
        l: 57.86,
        a: 8.54,
        b: 14.4,
      },
      cmyk: null,
    },
    {
      code: '17-1316 TCX',
      name: 'Portabella',
      hex: '937B6A',
      rgb: {
        r: 147,
        g: 123,
        b: 106,
      },
      lab: {
        l: 53.65,
        a: 8.38,
        b: 13.39,
      },
      cmyk: null,
    },
    {
      code: '16-1412 TCX',
      name: 'Stucco',
      hex: 'A58D7F',
      rgb: {
        r: 165,
        g: 141,
        b: 127,
      },
      lab: {
        l: 60.87,
        a: 8.23,
        b: 11.4,
      },
      cmyk: null,
    },
    {
      code: '16-1415 TCX',
      name: 'Almondine',
      hex: 'A78C7B',
      rgb: {
        r: 167,
        g: 140,
        b: 123,
      },
      lab: {
        l: 60.55,
        a: 9.49,
        b: 13.07,
      },
      cmyk: null,
    },
    {
      code: '16-1414 TCX',
      name: 'Chanterelle',
      hex: 'A28776',
      rgb: {
        r: 162,
        g: 135,
        b: 118,
      },
      lab: {
        l: 58.65,
        a: 9.27,
        b: 13.56,
      },
      cmyk: null,
    },
    {
      code: '17-1418 TCX',
      name: 'Ginger Snap',
      hex: '977D70',
      rgb: {
        r: 151,
        g: 125,
        b: 112,
      },
      lab: {
        l: 54.77,
        a: 9.84,
        b: 11.34,
      },
      cmyk: null,
    },
    {
      code: '17-1321 TCX',
      name: 'Woodsmoke',
      hex: '947764',
      rgb: {
        r: 148,
        g: 119,
        b: 100,
      },
      lab: {
        l: 52.58,
        a: 10.37,
        b: 14.81,
      },
      cmyk: null,
    },
    {
      code: '17-1417 TCX',
      name: 'Beaver Fur',
      hex: '997867',
      rgb: {
        r: 153,
        g: 120,
        b: 103,
      },
      lab: {
        l: 53.54,
        a: 12.22,
        b: 14.53,
      },
      cmyk: null,
    },
    {
      code: '18-1321 TCX',
      name: 'Brownie',
      hex: '8F7265',
      rgb: {
        r: 143,
        g: 114,
        b: 101,
      },
      lab: {
        l: 50.82,
        a: 11.5,
        b: 12.01,
      },
      cmyk: null,
    },
    {
      code: '13-0403 TCX',
      name: 'Gray Morn',
      hex: 'CABEB5',
      rgb: {
        r: 202,
        g: 190,
        b: 181,
      },
      lab: {
        l: 77.94,
        a: 4.23,
        b: 5.81,
      },
      cmyk: null,
    },
    {
      code: '14-1305 TCX',
      name: 'Mushroom',
      hex: 'BDACA3',
      rgb: {
        r: 189,
        g: 172,
        b: 163,
      },
      lab: {
        l: 71.68,
        a: 5.73,
        b: 7.17,
      },
      cmyk: null,
    },
    {
      code: '16-0806 TCX',
      name: 'Goat',
      hex: 'A89A91',
      rgb: {
        r: 168,
        g: 154,
        b: 145,
      },
      lab: {
        l: 64.6,
        a: 5.64,
        b: 6.22,
      },
      cmyk: null,
    },
    {
      code: '15-1506 TCX',
      name: 'Etherea',
      hex: 'A5958F',
      rgb: {
        r: 165,
        g: 149,
        b: 143,
      },
      lab: {
        l: 63.07,
        a: 6.1,
        b: 5.18,
      },
      cmyk: null,
    },
    {
      code: '17-0807 TCX',
      name: 'Mountain Trail',
      hex: '897569',
      rgb: {
        r: 137,
        g: 117,
        b: 105,
      },
      lab: {
        l: 50.97,
        a: 7.44,
        b: 9.81,
      },
      cmyk: null,
    },
    {
      code: '17-1412 TCX',
      name: 'Leafless Tree',
      hex: '837067',
      rgb: {
        r: 131,
        g: 112,
        b: 103,
      },
      lab: {
        l: 48.98,
        a: 7.26,
        b: 8.06,
      },
      cmyk: null,
    },
    {
      code: '18-1312 TCX',
      name: 'Deep Taupe',
      hex: '7B6660',
      rgb: {
        r: 123,
        g: 102,
        b: 96,
      },
      lab: {
        l: 45.16,
        a: 8.75,
        b: 6.63,
      },
      cmyk: null,
    },
    {
      code: '12-1406 TCX',
      name: 'Almond Peach',
      hex: 'D8C8BD',
      rgb: {
        r: 216,
        g: 200,
        b: 189,
      },
      lab: {
        l: 81.71,
        a: 4.91,
        b: 7.64,
      },
      cmyk: null,
    },
    {
      code: '13-3801 TCX',
      name: 'Crystal Gray',
      hex: 'D7CBC4',
      rgb: {
        r: 215,
        g: 203,
        b: 196,
      },
      lab: {
        l: 82.63,
        a: 3.68,
        b: 4.97,
      },
      cmyk: null,
    },
    {
      code: '16-1211 TCX',
      name: 'Mocha Meringue',
      hex: '9F8D81',
      rgb: {
        r: 159,
        g: 141,
        b: 129,
      },
      lab: {
        l: 60.03,
        a: 6.21,
        b: 8.72,
      },
      cmyk: null,
    },
    {
      code: '17-0808 TCX',
      name: 'Taupe Gray',
      hex: '8E7C71',
      rgb: {
        r: 142,
        g: 124,
        b: 113,
      },
      lab: {
        l: 53.7,
        a: 6.29,
        b: 9.26,
      },
      cmyk: null,
    },
    {
      code: '17-1506 TCX',
      name: 'Cinder',
      hex: '8A7E78',
      rgb: {
        r: 138,
        g: 126,
        b: 120,
      },
      lab: {
        l: 53.94,
        a: 4.61,
        b: 5.02,
      },
      cmyk: null,
    },
    {
      code: '17-1212 TCX',
      name: 'Fungi',
      hex: '8F8177',
      rgb: {
        r: 143,
        g: 129,
        b: 119,
      },
      lab: {
        l: 55.16,
        a: 5.02,
        b: 7.36,
      },
      cmyk: null,
    },
    {
      code: '17-1409 TCX',
      name: 'Brown Lentil',
      hex: '7E695F',
      rgb: {
        r: 126,
        g: 105,
        b: 95,
      },
      lab: {
        l: 46.49,
        a: 7.86,
        b: 9.01,
      },
      cmyk: null,
    },
    {
      code: '15-4504 TCX',
      name: 'Nacreous Clouds',
      hex: 'B9B1AB',
      rgb: {
        r: 185,
        g: 177,
        b: 171,
      },
      lab: {
        l: 72.87,
        a: 2.76,
        b: 4.29,
      },
      cmyk: null,
    },
    {
      code: '16-1406 TCX',
      name: 'Atmosphere',
      hex: 'A89C94',
      rgb: {
        r: 168,
        g: 156,
        b: 148,
      },
      lab: {
        l: 65.2,
        a: 3.94,
        b: 5.71,
      },
      cmyk: null,
    },
    {
      code: '16-3800 TCX',
      name: 'Satellite',
      hex: '978D89',
      rgb: {
        r: 151,
        g: 141,
        b: 137,
      },
      lab: {
        l: 59.54,
        a: 3.63,
        b: 3.6,
      },
      cmyk: null,
    },
    {
      code: '17-1210 TCX',
      name: 'Moon Rock',
      hex: '958B84',
      rgb: {
        r: 149,
        g: 139,
        b: 132,
      },
      lab: {
        l: 58.54,
        a: 3.9,
        b: 5,
      },
      cmyk: null,
    },
    {
      code: '18-1210 TCX',
      name: 'Driftwood',
      hex: '847A75',
      rgb: {
        r: 132,
        g: 122,
        b: 117,
      },
      lab: {
        l: 52.06,
        a: 4.19,
        b: 4.28,
      },
      cmyk: null,
    },
    {
      code: '17-1410 TCX',
      name: 'Pine Bark',
      hex: '847064',
      rgb: {
        r: 132,
        g: 112,
        b: 100,
      },
      lab: {
        l: 49.03,
        a: 7.35,
        b: 9.8,
      },
      cmyk: null,
    },
    {
      code: '18-1306 TCX',
      name: 'Iron',
      hex: '736460',
      rgb: {
        r: 115,
        g: 100,
        b: 96,
      },
      lab: {
        l: 43.71,
        a: 6.36,
        b: 4.47,
      },
      cmyk: null,
    },
    {
      code: '16-1102 TCX',
      name: 'Island Fossil',
      hex: 'B4AA9C',
      rgb: {
        r: 180,
        g: 170,
        b: 156,
      },
      lab: {
        l: 70.19,
        a: 2.45,
        b: 8.52,
      },
      cmyk: null,
    },
    {
      code: '16-1106 TCX',
      name: 'Tuffet',
      hex: 'A59788',
      rgb: {
        r: 165,
        g: 151,
        b: 136,
      },
      lab: {
        l: 63.39,
        a: 5.07,
        b: 9.53,
      },
      cmyk: null,
    },
    {
      code: '17-1009 TCX',
      name: 'Dune',
      hex: '998978',
      rgb: {
        r: 153,
        g: 137,
        b: 120,
      },
      lab: {
        l: 58.23,
        a: 5.23,
        b: 11.19,
      },
      cmyk: null,
    },
    {
      code: '17-1311 TCX',
      name: 'Desert Taupe',
      hex: '8D7E71',
      rgb: {
        r: 141,
        g: 126,
        b: 113,
      },
      lab: {
        l: 54.09,
        a: 5,
        b: 9.25,
      },
      cmyk: null,
    },
    {
      code: '18-1017 TCX',
      name: 'Caribou',
      hex: '816D5E',
      rgb: {
        r: 129,
        g: 109,
        b: 94,
      },
      lab: {
        l: 47.83,
        a: 6.63,
        b: 11.51,
      },
      cmyk: null,
    },
    {
      code: '18-1015 TCX',
      name: 'Shitake',
      hex: '746253',
      rgb: {
        r: 116,
        g: 98,
        b: 83,
      },
      lab: {
        l: 43.11,
        a: 6.13,
        b: 11.3,
      },
      cmyk: null,
    },
    {
      code: '19-0916 TCX',
      name: 'Rain Drum',
      hex: '5F4C40',
      rgb: {
        r: 95,
        g: 76,
        b: 64,
      },
      lab: {
        l: 34.2,
        a: 7.31,
        b: 10.57,
      },
      cmyk: null,
    },
    {
      code: '16-1407 TCX',
      name: 'Cobblestone',
      hex: 'A89A8E',
      rgb: {
        r: 168,
        g: 154,
        b: 142,
      },
      lab: {
        l: 64.77,
        a: 4.33,
        b: 8.63,
      },
      cmyk: null,
    },
    {
      code: '16-1109 TCX',
      name: 'Greige',
      hex: '928475',
      rgb: {
        r: 146,
        g: 132,
        b: 117,
      },
      lab: {
        l: 56.11,
        a: 3.88,
        b: 10.19,
      },
      cmyk: null,
    },
    {
      code: '17-0909 TCX',
      name: 'Fossil',
      hex: '806F63',
      rgb: {
        r: 128,
        g: 111,
        b: 99,
      },
      lab: {
        l: 48.47,
        a: 5.92,
        b: 9.93,
      },
      cmyk: null,
    },
    {
      code: '18-1016 TCX',
      name: 'Cub',
      hex: '6E5C4B',
      rgb: {
        r: 110,
        g: 92,
        b: 75,
      },
      lab: {
        l: 40.54,
        a: 6.23,
        b: 12.58,
      },
      cmyk: null,
    },
    {
      code: '19-0809 TCX',
      name: 'Chocolate Chip',
      hex: '685A4E',
      rgb: {
        r: 104,
        g: 90,
        b: 78,
      },
      lab: {
        l: 39.4,
        a: 5.55,
        b: 8.95,
      },
      cmyk: null,
    },
    {
      code: '19-0808 TCX',
      name: 'Morel',
      hex: '685C53',
      rgb: {
        r: 104,
        g: 92,
        b: 83,
      },
      lab: {
        l: 40.22,
        a: 4.24,
        b: 6.87,
      },
      cmyk: null,
    },
    {
      code: '18-1304 TCX',
      name: 'Falcon',
      hex: '6D625B',
      rgb: {
        r: 109,
        g: 98,
        b: 91,
      },
      lab: {
        l: 42.58,
        a: 4.61,
        b: 5.43,
      },
      cmyk: null,
    },
    {
      code: '16-0906 TCX',
      name: 'Simply Taupe',
      hex: 'AD9F93',
      rgb: {
        r: 173,
        g: 159,
        b: 147,
      },
      lab: {
        l: 66.67,
        a: 4.17,
        b: 8.6,
      },
      cmyk: null,
    },
    {
      code: '17-1105 TCX',
      name: 'Roasted Cashew',
      hex: '928679',
      rgb: {
        r: 146,
        g: 134,
        b: 121,
      },
      lab: {
        l: 56.69,
        a: 3.62,
        b: 8.54,
      },
      cmyk: null,
    },
    {
      code: '17-1310 TCX',
      name: 'Timber Wolf',
      hex: '8D8070',
      rgb: {
        r: 141,
        g: 128,
        b: 112,
      },
      lab: {
        l: 54.44,
        a: 3.82,
        b: 10.68,
      },
      cmyk: null,
    },
    {
      code: '18-0513 TCX',
      name: 'Bungee Cord',
      hex: '696156',
      rgb: {
        r: 105,
        g: 97,
        b: 86,
      },
      lab: {
        l: 41.6,
        a: 2.4,
        b: 7.27,
      },
      cmyk: null,
    },
    {
      code: '19-0822 TCX',
      name: 'Tarmac',
      hex: '5A5348',
      rgb: {
        r: 90,
        g: 83,
        b: 72,
      },
      lab: {
        l: 35.75,
        a: 2.2,
        b: 7.57,
      },
      cmyk: null,
    },
    {
      code: '19-0810 TCX',
      name: 'Major Brown',
      hex: '5B5149',
      rgb: {
        r: 91,
        g: 81,
        b: 73,
      },
      lab: {
        l: 35.22,
        a: 3.83,
        b: 6.33,
      },
      cmyk: null,
    },
    {
      code: '19-0823 TCX',
      name: 'Crocodile',
      hex: '5D5348',
      rgb: {
        r: 93,
        g: 83,
        b: 72,
      },
      lab: {
        l: 36.21,
        a: 3.48,
        b: 8,
      },
      cmyk: null,
    },
    {
      code: '16-1103 TCX',
      name: 'Pure Cashmere',
      hex: 'AEA396',
      rgb: {
        r: 174,
        g: 163,
        b: 150,
      },
      lab: {
        l: 67.83,
        a: 2.78,
        b: 8.38,
      },
      cmyk: null,
    },
    {
      code: '16-0205 TCX',
      name: 'Vintage Khaki',
      hex: '9A9186',
      rgb: {
        r: 154,
        g: 145,
        b: 134,
      },
      lab: {
        l: 60.74,
        a: 2.05,
        b: 7.3,
      },
      cmyk: null,
    },
    {
      code: '18-1110 TCX',
      name: 'Brindle',
      hex: '82776B',
      rgb: {
        r: 130,
        g: 119,
        b: 107,
      },
      lab: {
        l: 50.92,
        a: 3.18,
        b: 8.21,
      },
      cmyk: null,
    },
    {
      code: '18-1108 TCX',
      name: 'Fallen Rock',
      hex: '817669',
      rgb: {
        r: 129,
        g: 118,
        b: 105,
      },
      lab: {
        l: 50.38,
        a: 3.27,
        b: 8.66,
      },
      cmyk: null,
    },
    {
      code: '19-0820 TCX',
      name: 'Canteen',
      hex: '5E5347',
      rgb: {
        r: 94,
        g: 83,
        b: 71,
      },
      lab: {
        l: 36.18,
        a: 3.7,
        b: 8.93,
      },
      cmyk: null,
    },
    {
      code: '18-1112 TCX',
      name: 'Walnut',
      hex: '786A5F',
      rgb: {
        r: 120,
        g: 106,
        b: 95,
      },
      lab: {
        l: 46.07,
        a: 4.53,
        b: 8.56,
      },
      cmyk: null,
    },
    {
      code: '18-0615 TCX',
      name: 'Stone Gray',
      hex: '685E4F',
      rgb: {
        r: 104,
        g: 94,
        b: 79,
      },
      lab: {
        l: 40.61,
        a: 3.37,
        b: 10.02,
      },
      cmyk: null,
    },
    {
      code: '14-4500 TCX',
      name: 'Moonstruck',
      hex: 'C2BEB6',
      rgb: {
        r: 194,
        g: 190,
        b: 182,
      },
      lab: {
        l: 77.12,
        a: 0.63,
        b: 4.46,
      },
      cmyk: null,
    },
    {
      code: '15-4502 TCX',
      name: 'Silver Cloud',
      hex: 'BEB7B0',
      rgb: {
        r: 190,
        g: 183,
        b: 176,
      },
      lab: {
        l: 74.93,
        a: 2.08,
        b: 4.75,
      },
      cmyk: null,
    },
    {
      code: '14-4501 TCX',
      name: 'Silver Lining',
      hex: 'BDB6AB',
      rgb: {
        r: 189,
        g: 182,
        b: 171,
      },
      lab: {
        l: 74.41,
        a: 1.83,
        b: 6.2,
      },
      cmyk: null,
    },
    {
      code: '14-0000 TCX',
      name: 'Silver Gray',
      hex: 'C1B7B0',
      rgb: {
        r: 193,
        g: 183,
        b: 176,
      },
      lab: {
        l: 75.21,
        a: 2.73,
        b: 5.14,
      },
      cmyk: null,
    },
    {
      code: '15-4503 TCX',
      name: 'Chateau Gray',
      hex: 'BBB1A8',
      rgb: {
        r: 187,
        g: 177,
        b: 168,
      },
      lab: {
        l: 72.91,
        a: 2.87,
        b: 5.77,
      },
      cmyk: null,
    },
    {
      code: '16-1305 TCX',
      name: 'String',
      hex: 'AA9F96',
      rgb: {
        r: 170,
        g: 159,
        b: 150,
      },
      lab: {
        l: 66.38,
        a: 3.16,
        b: 6.5,
      },
      cmyk: null,
    },
    {
      code: '16-1107 TCX',
      name: 'Aluminum',
      hex: '9F9586',
      rgb: {
        r: 159,
        g: 149,
        b: 134,
      },
      lab: {
        l: 62.39,
        a: 2.16,
        b: 9.17,
      },
      cmyk: null,
    },
    {
      code: '13-0002 TCX',
      name: 'White Sand',
      hex: 'DBD5D1',
      rgb: {
        r: 219,
        g: 213,
        b: 209,
      },
      lab: {
        l: 85.79,
        a: 1.99,
        b: 2.88,
      },
      cmyk: null,
    },
    {
      code: '14-4002 TCX',
      name: 'Wind Chime',
      hex: 'CAC5C2',
      rgb: {
        r: 202,
        g: 197,
        b: 194,
      },
      lab: {
        l: 79.91,
        a: 1.81,
        b: 2.47,
      },
      cmyk: null,
    },
    {
      code: '15-0703 TCX',
      name: 'Ashes of Roses',
      hex: 'B5ACAB',
      rgb: {
        r: 181,
        g: 172,
        b: 171,
      },
      lab: {
        l: 71.23,
        a: 3.19,
        b: 1.79,
      },
      cmyk: null,
    },
    {
      code: '15-3800 TCX',
      name: 'Porpoise',
      hex: 'A7A19E',
      rgb: {
        r: 167,
        g: 161,
        b: 158,
      },
      lab: {
        l: 66.67,
        a: 2.53,
        b: 2.33,
      },
      cmyk: null,
    },
    {
      code: '16-3801 TCX',
      name: 'Opal Gray',
      hex: 'A49E9E',
      rgb: {
        r: 164,
        g: 158,
        b: 158,
      },
      lab: {
        l: 65.65,
        a: 2.47,
        b: 1.1,
      },
      cmyk: null,
    },
    {
      code: '16-3802 TCX',
      name: 'Ash',
      hex: 'A09998',
      rgb: {
        r: 160,
        g: 153,
        b: 152,
      },
      lab: {
        l: 63.88,
        a: 2.68,
        b: 1.68,
      },
      cmyk: null,
    },
    {
      code: '17-1508 TCX',
      name: 'Silent Storm',
      hex: '7C7977',
      rgb: {
        r: 124,
        g: 121,
        b: 119,
      },
      lab: {
        l: 51,
        a: 1.19,
        b: 1.55,
      },
      cmyk: null,
    },
    {
      code: '15-0000 TCX',
      name: 'Dove',
      hex: 'B3ADA7',
      rgb: {
        r: 179,
        g: 173,
        b: 167,
      },
      lab: {
        l: 71.02,
        a: 2.02,
        b: 3.9,
      },
      cmyk: null,
    },
    {
      code: '16-5803 TCX',
      name: 'Flint Gray',
      hex: 'A09C98',
      rgb: {
        r: 160,
        g: 156,
        b: 152,
      },
      lab: {
        l: 64.56,
        a: 1.82,
        b: 2.31,
      },
      cmyk: null,
    },
    {
      code: '17-0000 TCX',
      name: 'Frost Gray',
      hex: '848283',
      rgb: {
        r: 132,
        g: 130,
        b: 131,
      },
      lab: {
        l: 54.51,
        a: 1.43,
        b: -0.45,
      },
      cmyk: null,
    },
    {
      code: '17-1502 TCX',
      name: 'Cloudburst',
      hex: '837F7F',
      rgb: {
        r: 131,
        g: 127,
        b: 127,
      },
      lab: {
        l: 53.5,
        a: 2.31,
        b: 0.76,
      },
      cmyk: null,
    },
    {
      code: '17-1500 TCX',
      name: 'Steeple Gray',
      hex: '827E7C',
      rgb: {
        r: 130,
        g: 126,
        b: 124,
      },
      lab: {
        l: 53.1,
        a: 1.58,
        b: 1.79,
      },
      cmyk: null,
    },
    {
      code: '18-0403 TCX',
      name: 'Dark Gull Gray',
      hex: '625D5D',
      rgb: {
        r: 98,
        g: 93,
        b: 93,
      },
      lab: {
        l: 39.99,
        a: 2.72,
        b: 1.03,
      },
      cmyk: null,
    },
    {
      code: '18-0601 TCX',
      name: 'Charcoal Gray',
      hex: '6C6868',
      rgb: {
        r: 108,
        g: 104,
        b: 104,
      },
      lab: {
        l: 44.4,
        a: 1.92,
        b: 0.32,
      },
      cmyk: null,
    },
    {
      code: '14-4203 TCX',
      name: 'Vapor Blue',
      hex: 'BEBDBD',
      rgb: {
        r: 190,
        g: 189,
        b: 189,
      },
      lab: {
        l: 76.7,
        a: 0.61,
        b: 0.29,
      },
      cmyk: null,
    },
    {
      code: '16-4703 TCX',
      name: 'Ghost Gray',
      hex: '9C9B98',
      rgb: {
        r: 156,
        g: 155,
        b: 152,
      },
      lab: {
        l: 64.09,
        a: 0.35,
        b: 1.74,
      },
      cmyk: null,
    },
    {
      code: '16-4402 TCX',
      name: 'Drizzle',
      hex: 'A09E9C',
      rgb: {
        r: 160,
        g: 158,
        b: 156,
      },
      lab: {
        l: 65.4,
        a: 0.82,
        b: 1.44,
      },
      cmyk: null,
    },
    {
      code: '16-4400 TCX',
      name: 'Mourning Dove',
      hex: '95908B',
      rgb: {
        r: 149,
        g: 144,
        b: 139,
      },
      lab: {
        l: 60.01,
        a: 1.81,
        b: 2.81,
      },
      cmyk: null,
    },
    {
      code: '17-0207 TCX',
      name: 'Rock Ridge',
      hex: '918C86',
      rgb: {
        r: 145,
        g: 140,
        b: 134,
      },
      lab: {
        l: 58.49,
        a: 2.58,
        b: 3.21,
      },
      cmyk: null,
    },
    {
      code: '17-0205 TCX',
      name: 'Elephant Skin',
      hex: '8F8982',
      rgb: {
        r: 143,
        g: 137,
        b: 130,
      },
      lab: {
        l: 57.58,
        a: 1.53,
        b: 4.81,
      },
      cmyk: null,
    },
    {
      code: '17-5102 TCX',
      name: 'Griffin',
      hex: '8D8F8F',
      rgb: {
        r: 141,
        g: 143,
        b: 143,
      },
      lab: {
        l: 59.12,
        a: -0.11,
        b: -0.55,
      },
      cmyk: null,
    },
    {
      code: '12-4306 TCX',
      name: 'Barely Blue',
      hex: 'DDE0DF',
      rgb: {
        r: 221,
        g: 224,
        b: 223,
      },
      lab: {
        l: 88.94,
        a: -0.71,
        b: 0,
      },
      cmyk: null,
    },
    {
      code: '13-4303 TCX',
      name: 'Dawn Blue',
      hex: 'CACCCB',
      rgb: {
        r: 202,
        g: 204,
        b: 203,
      },
      lab: {
        l: 81.93,
        a: -0.85,
        b: 0.23,
      },
      cmyk: null,
    },
    {
      code: '15-4703 TCX',
      name: 'Mirage Gray',
      hex: 'ABAFAE',
      rgb: {
        r: 171,
        g: 175,
        b: 174,
      },
      lab: {
        l: 71.04,
        a: -1.19,
        b: -0.32,
      },
      cmyk: null,
    },
    {
      code: '16-4702 TCX',
      name: 'Limestone',
      hex: '989A98',
      rgb: {
        r: 152,
        g: 154,
        b: 152,
      },
      lab: {
        l: 63.38,
        a: -0.57,
        b: 0.48,
      },
      cmyk: null,
    },
    {
      code: '18-4105 TCX',
      name: 'Moon Mist',
      hex: '80817D',
      rgb: {
        r: 128,
        g: 129,
        b: 125,
      },
      lab: {
        l: 53.83,
        a: -0.6,
        b: 2.2,
      },
      cmyk: null,
    },
    {
      code: '17-1501 TCX',
      name: 'Wild Dove',
      hex: '8B8C89',
      rgb: {
        r: 139,
        g: 140,
        b: 137,
      },
      lab: {
        l: 58.04,
        a: -0.26,
        b: 1.06,
      },
      cmyk: null,
    },
    {
      code: '18-0510 TCX',
      name: 'Castor Gray',
      hex: '646762',
      rgb: {
        r: 100,
        g: 103,
        b: 98,
      },
      lab: {
        l: 43.28,
        a: -1.42,
        b: 2.19,
      },
      cmyk: null,
    },
    {
      code: '12-4705 TCX',
      name: 'Blue Blush',
      hex: 'D6DBD9',
      rgb: {
        r: 214,
        g: 219,
        b: 217,
      },
      lab: {
        l: 87.03,
        a: -1.83,
        b: 0.05,
      },
      cmyk: null,
    },
    {
      code: '13-4305 TCX',
      name: 'Foggy Dew',
      hex: 'D1D5D0',
      rgb: {
        r: 209,
        g: 213,
        b: 208,
      },
      lab: {
        l: 84.75,
        a: -1.29,
        b: 1.76,
      },
      cmyk: null,
    },
    {
      code: '14-4804 TCX',
      name: 'Blue Fox',
      hex: 'B9BCB6',
      rgb: {
        r: 185,
        g: 188,
        b: 182,
      },
      lab: {
        l: 75.8,
        a: -1.74,
        b: 2.53,
      },
      cmyk: null,
    },
    {
      code: '15-4003 TCX',
      name: 'Storm Gray',
      hex: 'B5BAB6',
      rgb: {
        r: 181,
        g: 186,
        b: 182,
      },
      lab: {
        l: 74.96,
        a: -1.71,
        b: 1,
      },
      cmyk: null,
    },
    {
      code: '17-4402 TCX',
      name: 'Neutral Gray',
      hex: '8E918F',
      rgb: {
        r: 142,
        g: 145,
        b: 143,
      },
      lab: {
        l: 59.75,
        a: -0.92,
        b: 0.21,
      },
      cmyk: null,
    },
    {
      code: '18-5105 TCX',
      name: 'Sedona Sage',
      hex: '686D6C',
      rgb: {
        r: 104,
        g: 109,
        b: 108,
      },
      lab: {
        l: 45.54,
        a: -1.71,
        b: -0.23,
      },
      cmyk: null,
    },
    {
      code: '18-0306 TCX',
      name: 'Gunmetal',
      hex: '5C5D5B',
      rgb: {
        r: 92,
        g: 93,
        b: 91,
      },
      lab: {
        l: 39.3,
        a: 0.22,
        b: 0.87,
      },
      cmyk: null,
    },
    {
      code: '11-4601 TCX',
      name: 'Bit of Blue',
      hex: 'E2EAEB',
      rgb: {
        r: 226,
        g: 234,
        b: 235,
      },
      lab: {
        l: 92.06,
        a: -2.42,
        b: -1.59,
      },
      cmyk: null,
    },
    {
      code: '13-4201 TCX',
      name: 'Oyster Mushroom',
      hex: 'C4C6C8',
      rgb: {
        r: 196,
        g: 198,
        b: 200,
      },
      lab: {
        l: 79.81,
        a: -0.48,
        b: -1.29,
      },
      cmyk: null,
    },
    {
      code: '15-4101 TCX',
      name: 'High-rise',
      hex: 'AEB2B5',
      rgb: {
        r: 174,
        g: 178,
        b: 181,
      },
      lab: {
        l: 72.18,
        a: -0.7,
        b: -2.23,
      },
      cmyk: null,
    },
    {
      code: '15-4305 TCX',
      name: 'Quarry',
      hex: '98A0A5',
      rgb: {
        r: 152,
        g: 160,
        b: 165,
      },
      lab: {
        l: 65.15,
        a: -1.78,
        b: -3.83,
      },
      cmyk: null,
    },
    {
      code: '17-4405 TCX',
      name: 'Monument',
      hex: '84898C',
      rgb: {
        r: 132,
        g: 137,
        b: 140,
      },
      lab: {
        l: 56.61,
        a: -0.78,
        b: -2.67,
      },
      cmyk: null,
    },
    {
      code: '17-3914 TCX',
      name: 'Sharkskin',
      hex: '848587',
      rgb: {
        r: 132,
        g: 133,
        b: 135,
      },
      lab: {
        l: 55.41,
        a: 0.56,
        b: -1.57,
      },
      cmyk: null,
    },
    {
      code: '18-0202 TCX',
      name: 'Lava Smoke',
      hex: '5E6064',
      rgb: {
        r: 94,
        g: 96,
        b: 100,
      },
      lab: {
        l: 40.74,
        a: 0.46,
        b: -2.59,
      },
      cmyk: null,
    },
    {
      code: '13-4108 TCX',
      name: 'Nimbus Cloud',
      hex: 'D5D5D8',
      rgb: {
        r: 213,
        g: 213,
        b: 216,
      },
      lab: {
        l: 85.24,
        a: 0.56,
        b: -1.6,
      },
      cmyk: null,
    },
    {
      code: '14-4104 TCX',
      name: 'Northern Droplet',
      hex: 'BABEBF',
      rgb: {
        r: 186,
        g: 190,
        b: 191,
      },
      lab: {
        l: 76.79,
        a: -1.28,
        b: -0.69,
      },
      cmyk: null,
    },
    {
      code: '17-5104 TCX',
      name: 'Ultimate Gray',
      hex: '939597',
      rgb: {
        r: 147,
        g: 149,
        b: 151,
      },
      lab: {
        l: 61.7,
        a: 0.03,
        b: -1.47,
      },
      cmyk: null,
    },
    {
      code: '16-3917 TCX',
      name: 'Chiseled Stone',
      hex: '8D8E92',
      rgb: {
        r: 141,
        g: 142,
        b: 146,
      },
      lab: {
        l: 58.99,
        a: 0.8,
        b: -2.43,
      },
      cmyk: null,
    },
    {
      code: '15-4304 TCX',
      name: 'Weathervane',
      hex: '8B9198',
      rgb: {
        r: 139,
        g: 145,
        b: 152,
      },
      lab: {
        l: 59.71,
        a: -0.24,
        b: -5.06,
      },
      cmyk: null,
    },
    {
      code: '18-4017 TCX',
      name: 'Night Owl',
      hex: '79797F',
      rgb: {
        r: 121,
        g: 121,
        b: 127,
      },
      lab: {
        l: 51.01,
        a: 1.56,
        b: -3.11,
      },
      cmyk: null,
    },
    {
      code: '18-4004 TCX',
      name: 'Poppy Seed',
      hex: '626367',
      rgb: {
        r: 98,
        g: 99,
        b: 103,
      },
      lab: {
        l: 42.03,
        a: 0.79,
        b: -2.67,
      },
      cmyk: null,
    },
    {
      code: '14-4201 TCX',
      name: 'Lunar Rock',
      hex: 'C5C5C5',
      rgb: {
        r: 197,
        g: 197,
        b: 197,
      },
      lab: {
        l: 79.66,
        a: 0.26,
        b: 0.05,
      },
      cmyk: null,
    },
    {
      code: '14-4102 TCX',
      name: 'Glacier Gray',
      hex: 'C5C6C7',
      rgb: {
        r: 197,
        g: 198,
        b: 199,
      },
      lab: {
        l: 79.9,
        a: -0.24,
        b: -0.72,
      },
      cmyk: null,
    },
    {
      code: '13-4104 TCX',
      name: 'Antarctica',
      hex: 'C6C6C7',
      rgb: {
        r: 198,
        g: 198,
        b: 199,
      },
      lab: {
        l: 79.85,
        a: 0.66,
        b: -0.53,
      },
      cmyk: null,
    },
    {
      code: '14-4103 TCX',
      name: 'Gray Violet',
      hex: 'BBBCBC',
      rgb: {
        r: 187,
        g: 188,
        b: 188,
      },
      lab: {
        l: 76.16,
        a: 0.01,
        b: -0.47,
      },
      cmyk: null,
    },
    {
      code: '14-4107 TCX',
      name: 'Quiet Gray',
      hex: 'BABBBD',
      rgb: {
        r: 186,
        g: 187,
        b: 189,
      },
      lab: {
        l: 75.85,
        a: 0.25,
        b: -1.41,
      },
      cmyk: null,
    },
    {
      code: '14-4105 TCX',
      name: 'Micro Chip',
      hex: 'BABCC0',
      rgb: {
        r: 186,
        g: 188,
        b: 192,
      },
      lab: {
        l: 76.21,
        a: 0.33,
        b: -2.52,
      },
      cmyk: null,
    },
    {
      code: '14-4202 TCX',
      name: 'Harbor Mist',
      hex: 'B0B2B5',
      rgb: {
        r: 176,
        g: 178,
        b: 181,
      },
      lab: {
        l: 72.43,
        a: -0.15,
        b: -1.9,
      },
      cmyk: null,
    },
    {
      code: '16-3850 TCX',
      name: 'Silver Sconce',
      hex: 'A19FA5',
      rgb: {
        r: 161,
        g: 159,
        b: 165,
      },
      lab: {
        l: 65.78,
        a: 2.04,
        b: -2.75,
      },
      cmyk: null,
    },
    {
      code: '16-5102 TCX',
      name: 'Formal Gray',
      hex: '979799',
      rgb: {
        r: 151,
        g: 151,
        b: 153,
      },
      lab: {
        l: 62.49,
        a: 0.94,
        b: -1.13,
      },
      cmyk: null,
    },
    {
      code: '16-3915 TCX',
      name: 'Alloy',
      hex: '98979A',
      rgb: {
        r: 152,
        g: 151,
        b: 154,
      },
      lab: {
        l: 62.7,
        a: 0.74,
        b: -1.37,
      },
      cmyk: null,
    },
    {
      code: '17-3913 TCX',
      name: 'Skyrocket',
      hex: '86868C',
      rgb: {
        r: 134,
        g: 134,
        b: 140,
      },
      lab: {
        l: 56.09,
        a: 1.23,
        b: -3.17,
      },
      cmyk: null,
    },
    {
      code: '17-3911 TCX',
      name: 'Silver Filigree',
      hex: '7F7C81',
      rgb: {
        r: 127,
        g: 124,
        b: 129,
      },
      lab: {
        l: 52.52,
        a: 2.05,
        b: -1.84,
      },
      cmyk: null,
    },
    {
      code: '18-4006 TCX',
      name: 'Quiet Shade',
      hex: '66676D',
      rgb: {
        r: 102,
        g: 103,
        b: 109,
      },
      lab: {
        l: 43.77,
        a: 0.8,
        b: -3.41,
      },
      cmyk: null,
    },
    {
      code: '18-3907 TCX',
      name: 'Tornado',
      hex: '5E5B60',
      rgb: {
        r: 94,
        g: 91,
        b: 96,
      },
      lab: {
        l: 39.25,
        a: 2.41,
        b: -2.26,
      },
      cmyk: null,
    },
    {
      code: '16-5101 TCX',
      name: 'Wet Weather',
      hex: '929090',
      rgb: {
        r: 146,
        g: 144,
        b: 144,
      },
      lab: {
        l: 59.93,
        a: 1.79,
        b: -0.25,
      },
      cmyk: null,
    },
    {
      code: '17-4014 TCX',
      name: 'Titanium',
      hex: '807D7F',
      rgb: {
        r: 128,
        g: 125,
        b: 127,
      },
      lab: {
        l: 52.82,
        a: 2.18,
        b: -0.83,
      },
      cmyk: null,
    },
    {
      code: '18-5102 TCX',
      name: 'Brushed Nickel',
      hex: '73706F',
      rgb: {
        r: 115,
        g: 112,
        b: 111,
      },
      lab: {
        l: 47.48,
        a: 1.99,
        b: 0.77,
      },
      cmyk: null,
    },
    {
      code: '17-4013 TCX',
      name: 'Gray Quill',
      hex: '7B797B',
      rgb: {
        r: 123,
        g: 121,
        b: 123,
      },
      lab: {
        l: 50.98,
        a: 1.85,
        b: -0.76,
      },
      cmyk: null,
    },
    {
      code: '18-4005 TCX',
      name: 'Steel Gray',
      hex: '726F70',
      rgb: {
        r: 114,
        g: 111,
        b: 112,
      },
      lab: {
        l: 47.21,
        a: 2,
        b: -0.43,
      },
      cmyk: null,
    },
    {
      code: '18-4016 TCX',
      name: 'December Sky',
      hex: '767275',
      rgb: {
        r: 118,
        g: 114,
        b: 117,
      },
      lab: {
        l: 48.59,
        a: 2.22,
        b: -0.97,
      },
      cmyk: null,
    },
    {
      code: '18-4010 TCX',
      name: 'City Skyline',
      hex: '6F6C70',
      rgb: {
        r: 111,
        g: 108,
        b: 112,
      },
      lab: {
        l: 46.02,
        a: 2.31,
        b: -1.94,
      },
      cmyk: null,
    },
    {
      code: '16-3803 TCX',
      name: 'Gull Gray',
      hex: 'A49C9F',
      rgb: {
        r: 164,
        g: 156,
        b: 159,
      },
      lab: {
        l: 65.09,
        a: 4.27,
        b: -0.75,
      },
      cmyk: null,
    },
    {
      code: '17-2601 TCX',
      name: 'Zinc',
      hex: '92898A',
      rgb: {
        r: 146,
        g: 137,
        b: 138,
      },
      lab: {
        l: 57.91,
        a: 4.29,
        b: 0.71,
      },
      cmyk: null,
    },
    {
      code: '17-3802 TCX',
      name: 'Gull',
      hex: '918C8F',
      rgb: {
        r: 145,
        g: 140,
        b: 143,
      },
      lab: {
        l: 58.69,
        a: 2.87,
        b: -1.1,
      },
      cmyk: null,
    },
    {
      code: '17-1503 TCX',
      name: 'Storm Front',
      hex: '787376',
      rgb: {
        r: 120,
        g: 115,
        b: 118,
      },
      lab: {
        l: 49.1,
        a: 2.97,
        b: -0.9,
      },
      cmyk: null,
    },
    {
      code: '18-3908 TCX',
      name: 'Volcanic Glass',
      hex: '615C61',
      rgb: {
        r: 97,
        g: 92,
        b: 97,
      },
      lab: {
        l: 39.77,
        a: 3.26,
        b: -1.72,
      },
      cmyk: null,
    },
    {
      code: '19-3803 TCX',
      name: 'Plum Kitten',
      hex: '625B5C',
      rgb: {
        r: 98,
        g: 91,
        b: 92,
      },
      lab: {
        l: 39.41,
        a: 3.55,
        b: 0.49,
      },
      cmyk: null,
    },
    {
      code: '18-3906 TCX',
      name: 'Boulevard',
      hex: '575358',
      rgb: {
        r: 87,
        g: 83,
        b: 88,
      },
      lab: {
        l: 35.94,
        a: 3.39,
        b: -2.62,
      },
      cmyk: null,
    },
    {
      code: '16-0000 TCX',
      name: 'Paloma',
      hex: '9F9C99',
      rgb: {
        r: 159,
        g: 156,
        b: 153,
      },
      lab: {
        l: 64.55,
        a: 1.51,
        b: 1.95,
      },
      cmyk: null,
    },
    {
      code: '17-4016 TCX',
      name: 'Gray Flannel',
      hex: '858282',
      rgb: {
        r: 133,
        g: 130,
        b: 130,
      },
      lab: {
        l: 54.53,
        a: 1.81,
        b: 0.07,
      },
      cmyk: null,
    },
    {
      code: '18-4015 TCX',
      name: 'Mockingbird',
      hex: '77777A',
      rgb: {
        r: 119,
        g: 119,
        b: 122,
      },
      lab: {
        l: 50.14,
        a: 1.02,
        b: -1.56,
      },
      cmyk: null,
    },
    {
      code: '18-0503 TCX',
      name: 'Gargoyle',
      hex: '686767',
      rgb: {
        r: 104,
        g: 103,
        b: 103,
      },
      lab: {
        l: 43.65,
        a: 0.72,
        b: 0.07,
      },
      cmyk: null,
    },
    {
      code: '18-5203 TCX',
      name: 'Pewter',
      hex: '666564',
      rgb: {
        r: 102,
        g: 101,
        b: 100,
      },
      lab: {
        l: 42.88,
        a: 0.89,
        b: 0.5,
      },
      cmyk: null,
    },
    {
      code: '18-5206 TCX',
      name: 'Thunderstorm',
      hex: '5B585A',
      rgb: {
        r: 91,
        g: 88,
        b: 90,
      },
      lab: {
        l: 37.68,
        a: 1.99,
        b: -0.83,
      },
      cmyk: null,
    },
    {
      code: '19-3906 TCX',
      name: 'Dark Shadow',
      hex: '4A4B4D',
      rgb: {
        r: 74,
        g: 75,
        b: 77,
      },
      lab: {
        l: 31.95,
        a: 0.13,
        b: -1.23,
      },
      cmyk: null,
    },
    {
      code: '19-4405 TCX',
      name: 'Forest River',
      hex: '33393B',
      rgb: {
        r: 51,
        g: 57,
        b: 59,
      },
      lab: {
        l: 23.39,
        a: -2.25,
        b: -2.31,
      },
      cmyk: null,
    },
    {
      code: '19-5002 TCX',
      name: 'Woodland Gray',
      hex: '383D3F',
      rgb: {
        r: 56,
        g: 61,
        b: 63,
      },
      lab: {
        l: 25.19,
        a: -1.24,
        b: -2.58,
      },
      cmyk: null,
    },
    {
      code: '19-5005 TCX',
      name: 'Unexplored',
      hex: '3B3D3C',
      rgb: {
        r: 59,
        g: 61,
        b: 60,
      },
      lab: {
        l: 25.63,
        a: -1,
        b: 0.06,
      },
      cmyk: null,
    },
    {
      code: '19-4406 TCX',
      name: 'Black Sand',
      hex: '3C3E40',
      rgb: {
        r: 60,
        g: 62,
        b: 64,
      },
      lab: {
        l: 25.99,
        a: 0.22,
        b: -1.78,
      },
      cmyk: null,
    },
    {
      code: '19-1100 TCX',
      name: 'Bristol Black',
      hex: '3B3739',
      rgb: {
        r: 59,
        g: 55,
        b: 57,
      },
      lab: {
        l: 23.77,
        a: 2.71,
        b: -0.54,
      },
      cmyk: null,
    },
    {
      code: '19-3912 TCX',
      name: 'Volcanic Ash',
      hex: '484849',
      rgb: {
        r: 72,
        g: 72,
        b: 73,
      },
      lab: {
        l: 30.67,
        a: 0.16,
        b: -0.7,
      },
      cmyk: null,
    },
    {
      code: '19-0205 TCX',
      name: 'Black Oyster',
      hex: '414143',
      rgb: {
        r: 65,
        g: 65,
        b: 67,
      },
      lab: {
        l: 27.57,
        a: 0.71,
        b: -1.46,
      },
      cmyk: null,
    },
    {
      code: '18-0000 TCX',
      name: 'Smoked Pearl',
      hex: '656466',
      rgb: {
        r: 101,
        g: 100,
        b: 102,
      },
      lab: {
        l: 42.64,
        a: 0.92,
        b: -0.89,
      },
      cmyk: null,
    },
    {
      code: '18-0201 TCX',
      name: 'Castlerock',
      hex: '5F5E62',
      rgb: {
        r: 95,
        g: 94,
        b: 98,
      },
      lab: {
        l: 40.21,
        a: 1.29,
        b: -2,
      },
      cmyk: null,
    },
    {
      code: '18-5204 TCX',
      name: 'Granite Gray',
      hex: '615F5F',
      rgb: {
        r: 97,
        g: 95,
        b: 95,
      },
      lab: {
        l: 40.51,
        a: 1.53,
        b: -0.02,
      },
      cmyk: null,
    },
    {
      code: '18-5210 TCX',
      name: 'Eiffel Tower',
      hex: '5C5658',
      rgb: {
        r: 92,
        g: 86,
        b: 88,
      },
      lab: {
        l: 37.33,
        a: 2.86,
        b: -0.28,
      },
      cmyk: null,
    },
    {
      code: '19-3910 TCX',
      name: 'Iron Gate',
      hex: '4E5055',
      rgb: {
        r: 78,
        g: 80,
        b: 85,
      },
      lab: {
        l: 34.02,
        a: 0.28,
        b: -2.95,
      },
      cmyk: null,
    },
    {
      code: '19-0201 TCX',
      name: 'Asphalt',
      hex: '444447',
      rgb: {
        r: 68,
        g: 68,
        b: 71,
      },
      lab: {
        l: 28.8,
        a: 1.04,
        b: -2.2,
      },
      cmyk: null,
    },
    {
      code: '19-3901 TCX',
      name: 'Magnet',
      hex: '4D4B4F',
      rgb: {
        r: 77,
        g: 75,
        b: 79,
      },
      lab: {
        l: 32.34,
        a: 1.87,
        b: -2.14,
      },
      cmyk: null,
    },
    {
      code: '19-3905 TCX',
      name: 'Rabbit',
      hex: '5F575D',
      rgb: {
        r: 95,
        g: 87,
        b: 93,
      },
      lab: {
        l: 37.94,
        a: 4.72,
        b: -1.86,
      },
      cmyk: null,
    },
    {
      code: '18-3905 TCX',
      name: 'Excalibur',
      hex: '676168',
      rgb: {
        r: 103,
        g: 97,
        b: 104,
      },
      lab: {
        l: 41.78,
        a: 3.43,
        b: -2.93,
      },
      cmyk: null,
    },
    {
      code: '19-3903 TCX',
      name: 'Shale',
      hex: '4A3F41',
      rgb: {
        r: 74,
        g: 63,
        b: 65,
      },
      lab: {
        l: 27.94,
        a: 6.31,
        b: 0.48,
      },
      cmyk: null,
    },
    {
      code: '19-3900 TCX',
      name: 'Pavement',
      hex: '534D50',
      rgb: {
        r: 83,
        g: 77,
        b: 80,
      },
      lab: {
        l: 33.47,
        a: 3.4,
        b: -0.79,
      },
      cmyk: null,
    },
    {
      code: '19-3908 TCX',
      name: 'Nine Iron',
      hex: '46434A',
      rgb: {
        r: 70,
        g: 67,
        b: 74,
      },
      lab: {
        l: 28.83,
        a: 3.26,
        b: -4.03,
      },
      cmyk: null,
    },
    {
      code: '19-4104 TCX',
      name: 'Ebony',
      hex: '41424A',
      rgb: {
        r: 65,
        g: 66,
        b: 74,
      },
      lab: {
        l: 28.09,
        a: 1.37,
        b: -5.11,
      },
      cmyk: null,
    },
    {
      code: '19-3918 TCX',
      name: 'Periscope',
      hex: '46444C',
      rgb: {
        r: 70,
        g: 68,
        b: 76,
      },
      lab: {
        l: 29.22,
        a: 2.84,
        b: -4.66,
      },
      cmyk: null,
    },
    {
      code: '19-0203 TCX',
      name: 'Gray Pinstripe',
      hex: '4A494D',
      rgb: {
        r: 74,
        g: 73,
        b: 77,
      },
      lab: {
        l: 31.21,
        a: 1.28,
        b: -2.34,
      },
      cmyk: null,
    },
    {
      code: '19-3907 TCX',
      name: 'Forged Iron',
      hex: '48464A',
      rgb: {
        r: 72,
        g: 70,
        b: 74,
      },
      lab: {
        l: 30.04,
        a: 1.88,
        b: -2.13,
      },
      cmyk: null,
    },
    {
      code: '19-3917 TCX',
      name: 'Blackened Pearl',
      hex: '4E4B51',
      rgb: {
        r: 78,
        g: 75,
        b: 81,
      },
      lab: {
        l: 32.41,
        a: 2.36,
        b: -2.87,
      },
      cmyk: null,
    },
    {
      code: '19-3902 TCX',
      name: 'Obsidian',
      hex: '3B363C',
      rgb: {
        r: 59,
        g: 54,
        b: 60,
      },
      lab: {
        l: 23.48,
        a: 3.88,
        b: -2.45,
      },
      cmyk: null,
    },
    {
      code: '19-4205 TCX',
      name: 'Phantom',
      hex: '39373B',
      rgb: {
        r: 57,
        g: 55,
        b: 59,
      },
      lab: {
        l: 23.25,
        a: 2.14,
        b: -2.13,
      },
      cmyk: null,
    },
    {
      code: '19-4007 TCX',
      name: 'Anthracite',
      hex: '28282D',
      rgb: {
        r: 40,
        g: 40,
        b: 45,
      },
      lab: {
        l: 16.39,
        a: 1.24,
        b: -3.38,
      },
      cmyk: null,
    },
    {
      code: '19-0303 TCX',
      name: 'Jet Black',
      hex: '2D2C2F',
      rgb: {
        r: 45,
        g: 44,
        b: 47,
      },
      lab: {
        l: 18.36,
        a: 1.2,
        b: -1.85,
      },
      cmyk: null,
    },
    {
      code: '19-3911 TCX',
      name: 'Black Beauty',
      hex: '27272A',
      rgb: {
        r: 39,
        g: 39,
        b: 42,
      },
      lab: {
        l: 15.64,
        a: 0.89,
        b: -2.4,
      },
      cmyk: null,
    },
    {
      code: '19-4003 TCX',
      name: 'Black Onyx',
      hex: '2C282C',
      rgb: {
        r: 44,
        g: 40,
        b: 44,
      },
      lab: {
        l: 16.71,
        a: 3.1,
        b: -1.77,
      },
      cmyk: null,
    },
    {
      code: '19-4004 TCX',
      name: 'Tap Shoe',
      hex: '2A2B2D',
      rgb: {
        r: 42,
        g: 43,
        b: 45,
      },
      lab: {
        l: 17.41,
        a: 0.43,
        b: -1.32,
      },
      cmyk: null,
    },
    {
      code: '19-4005 TCX',
      name: 'Stretch Limo',
      hex: '2B2C30',
      rgb: {
        r: 43,
        g: 44,
        b: 48,
      },
      lab: {
        l: 18.12,
        a: 0.43,
        b: -2.33,
      },
      cmyk: null,
    },
    {
      code: '19-4006 TCX',
      name: 'Caviar',
      hex: '2A2A2D',
      rgb: {
        r: 42,
        g: 42,
        b: 45,
      },
      lab: {
        l: 17.1,
        a: 0.65,
        b: -2.19,
      },
      cmyk: null,
    },
    {
      code: '19-4008 TCX',
      name: 'Meteorite',
      hex: '2C2929',
      rgb: {
        r: 44,
        g: 41,
        b: 41,
      },
      lab: {
        l: 16.76,
        a: 1.87,
        b: 0.15,
      },
      cmyk: null,
    },
    {
      code: '19-4203 TCX',
      name: 'Moonless Night',
      hex: '2F2D30',
      rgb: {
        r: 47,
        g: 45,
        b: 48,
      },
      lab: {
        l: 18.88,
        a: 1.84,
        b: -1.26,
      },
      cmyk: null,
    },
    {
      code: '19-0405 TCX',
      name: 'Beluga',
      hex: '4A4843',
      rgb: {
        r: 74,
        g: 72,
        b: 67,
      },
      lab: {
        l: 30.58,
        a: 0.76,
        b: 3.26,
      },
      cmyk: null,
    },
    {
      code: '19-0608 TCX',
      name: 'Black Olive',
      hex: '48413B',
      rgb: {
        r: 72,
        g: 65,
        b: 59,
      },
      lab: {
        l: 28.28,
        a: 2.29,
        b: 5.01,
      },
      cmyk: null,
    },
    {
      code: '19-0000 TCX',
      name: 'Raven',
      hex: '413E3D',
      rgb: {
        r: 65,
        g: 62,
        b: 61,
      },
      lab: {
        l: 26.64,
        a: 1.25,
        b: 1.48,
      },
      cmyk: null,
    },
    {
      code: '19-0506 TCX',
      name: 'Black Ink',
      hex: '45413C',
      rgb: {
        r: 69,
        g: 65,
        b: 60,
      },
      lab: {
        l: 27.84,
        a: 1.24,
        b: 3.63,
      },
      cmyk: null,
    },
    {
      code: '19-0508 TCX',
      name: 'Peat',
      hex: '3B3A36',
      rgb: {
        r: 59,
        g: 58,
        b: 54,
      },
      lab: {
        l: 24.4,
        a: 0.37,
        b: 2.55,
      },
      cmyk: null,
    },
    {
      code: '19-4305 TCX',
      name: 'Pirate Black',
      hex: '373838',
      rgb: {
        r: 55,
        g: 56,
        b: 56,
      },
      lab: {
        l: 23.28,
        a: -0.03,
        b: -0.14,
      },
      cmyk: null,
    },
    {
      code: '19-5708 TCX',
      name: 'Jet Set',
      hex: '272C2A',
      rgb: {
        r: 39,
        g: 44,
        b: 42,
      },
      lab: {
        l: 17.29,
        a: -2.43,
        b: 0.39,
      },
      cmyk: null,
    },
    {
      code: '19-0812 TCX',
      name: 'Turkish Coffee',
      hex: '483F39',
      rgb: {
        r: 72,
        g: 63,
        b: 57,
      },
      lab: {
        l: 27.52,
        a: 3.95,
        b: 5.03,
      },
      cmyk: null,
    },
    {
      code: '19-0814 TCX',
      name: 'Slate Black',
      hex: '4B3D33',
      rgb: {
        r: 75,
        g: 61,
        b: 51,
      },
      lab: {
        l: 27.18,
        a: 5.53,
        b: 9.03,
      },
      cmyk: null,
    },
    {
      code: '19-0614 TCX',
      name: 'Wren',
      hex: '4B4139',
      rgb: {
        r: 75,
        g: 65,
        b: 57,
      },
      lab: {
        l: 28.5,
        a: 3.31,
        b: 6.57,
      },
      cmyk: null,
    },
    {
      code: '19-0712 TCX',
      name: 'Demitasse',
      hex: '40332B',
      rgb: {
        r: 64,
        g: 51,
        b: 43,
      },
      lab: {
        l: 22.8,
        a: 5.42,
        b: 7.77,
      },
      cmyk: null,
    },
    {
      code: '19-0910 TCX',
      name: 'Mulch',
      hex: '433937',
      rgb: {
        r: 67,
        g: 57,
        b: 55,
      },
      lab: {
        l: 25.21,
        a: 4.66,
        b: 3.29,
      },
      cmyk: null,
    },
    {
      code: '19-1102 TCX',
      name: 'Licorice',
      hex: '3B3536',
      rgb: {
        r: 59,
        g: 53,
        b: 54,
      },
      lab: {
        l: 22.71,
        a: 3.44,
        b: 0.41,
      },
      cmyk: null,
    },
    {
      code: '19-1103 TCX',
      name: 'Espresso',
      hex: '363031',
      rgb: {
        r: 54,
        g: 48,
        b: 49,
      },
      lab: {
        l: 20.7,
        a: 3.44,
        b: 0.86,
      },
      cmyk: null,
    },
    {
      code: '19-1015 TCX',
      name: 'Bracken',
      hex: '4F3F3B',
      rgb: {
        r: 79,
        g: 63,
        b: 59,
      },
      lab: {
        l: 28.44,
        a: 7.13,
        b: 5.44,
      },
      cmyk: null,
    },
    {
      code: '19-0912 TCX',
      name: 'Chocolate Brown',
      hex: '4E403B',
      rgb: {
        r: 78,
        g: 64,
        b: 59,
      },
      lab: {
        l: 28.56,
        a: 6.22,
        b: 5.53,
      },
      cmyk: null,
    },
    {
      code: '19-0840 TCX',
      name: 'Delicioso',
      hex: '3F352F',
      rgb: {
        r: 63,
        g: 53,
        b: 47,
      },
      lab: {
        l: 23.12,
        a: 4.38,
        b: 5.61,
      },
      cmyk: null,
    },
    {
      code: '19-1016 TCX',
      name: 'Java',
      hex: '433331',
      rgb: {
        r: 67,
        g: 51,
        b: 49,
      },
      lab: {
        l: 23.23,
        a: 8.11,
        b: 4.17,
      },
      cmyk: null,
    },
    {
      code: '19-0915 TCX',
      name: 'Coffee Bean',
      hex: '40312F',
      rgb: {
        r: 64,
        g: 49,
        b: 47,
      },
      lab: {
        l: 22.21,
        a: 7.19,
        b: 4.6,
      },
      cmyk: null,
    },
    {
      code: '19-1106 TCX',
      name: 'Molé',
      hex: '392D2B',
      rgb: {
        r: 57,
        g: 45,
        b: 43,
      },
      lab: {
        l: 19.76,
        a: 6.13,
        b: 3.4,
      },
      cmyk: null,
    },
    {
      code: '19-1101 TCX',
      name: 'After Dark',
      hex: '3D3535',
      rgb: {
        r: 61,
        g: 53,
        b: 53,
      },
      lab: {
        l: 22.94,
        a: 4.2,
        b: 1.28,
      },
      cmyk: null,
    },
    {
      code: '19-1213 TCX',
      name: 'Shopping Bag',
      hex: '5A4743',
      rgb: {
        r: 90,
        g: 71,
        b: 67,
      },
      lab: {
        l: 32.22,
        a: 8.75,
        b: 5.83,
      },
      cmyk: null,
    },
    {
      code: '19-1012 TCX',
      name: 'French Roast',
      hex: '58423F',
      rgb: {
        r: 88,
        g: 66,
        b: 63,
      },
      lab: {
        l: 30.41,
        a: 10.47,
        b: 5.81,
      },
      cmyk: null,
    },
    {
      code: '19-1314 TCX',
      name: 'Seal Brown',
      hex: '493B39',
      rgb: {
        r: 73,
        g: 59,
        b: 57,
      },
      lab: {
        l: 26.3,
        a: 7.02,
        b: 3.87,
      },
      cmyk: null,
    },
    {
      code: '19-1109 TCX',
      name: 'Chocolate Torte',
      hex: '392E2D',
      rgb: {
        r: 57,
        g: 46,
        b: 45,
      },
      lab: {
        l: 20.33,
        a: 5.3,
        b: 2.57,
      },
      cmyk: null,
    },
    {
      code: '19-1111 TCX',
      name: 'Black Coffee',
      hex: '3C302F',
      rgb: {
        r: 60,
        g: 48,
        b: 47,
      },
      lab: {
        l: 21.23,
        a: 5.7,
        b: 2.96,
      },
      cmyk: null,
    },
    {
      code: '19-1018 TCX',
      name: 'Ganache',
      hex: '34292A',
      rgb: {
        r: 52,
        g: 41,
        b: 42,
      },
      lab: {
        l: 18.06,
        a: 6.15,
        b: 1.96,
      },
      cmyk: null,
    },
    {
      code: '19-3909 TCX',
      name: 'Black Bean',
      hex: '2E272A',
      rgb: {
        r: 46,
        g: 39,
        b: 42,
      },
      lab: {
        l: 16.7,
        a: 4.16,
        b: -0.52,
      },
      cmyk: null,
    },
    {
      code: '19-1118 TCX',
      name: 'Chestnut',
      hex: '584039',
      rgb: {
        r: 88,
        g: 64,
        b: 57,
      },
      lab: {
        l: 29.93,
        a: 10.31,
        b: 9.07,
      },
      cmyk: null,
    },
    {
      code: '19-1234 TCX',
      name: 'Rocky Road',
      hex: '5B3F36',
      rgb: {
        r: 91,
        g: 63,
        b: 54,
      },
      lab: {
        l: 29.88,
        a: 12.26,
        b: 10.67,
      },
      cmyk: null,
    },
    {
      code: '19-1420 TCX',
      name: 'Deep Mahogany',
      hex: '553B39',
      rgb: {
        r: 85,
        g: 59,
        b: 57,
      },
      lab: {
        l: 28.09,
        a: 12.83,
        b: 6.51,
      },
      cmyk: null,
    },
    {
      code: '19-1518 TCX',
      name: 'Puce',
      hex: '503938',
      rgb: {
        r: 80,
        g: 57,
        b: 56,
      },
      lab: {
        l: 26.68,
        a: 11.42,
        b: 4.74,
      },
      cmyk: null,
    },
    {
      code: '19-1215 TCX',
      name: 'Shaved Chocolate',
      hex: '553C36',
      rgb: {
        r: 85,
        g: 60,
        b: 54,
      },
      lab: {
        l: 28.1,
        a: 11.19,
        b: 8.71,
      },
      cmyk: null,
    },
    {
      code: '19-1419 TCX',
      name: 'Chicory Coffee',
      hex: '4B342F',
      rgb: {
        r: 75,
        g: 52,
        b: 47,
      },
      lab: {
        l: 24.49,
        a: 10.34,
        b: 7.94,
      },
      cmyk: null,
    },
    {
      code: '19-1110 TCX',
      name: 'Chocolate Plum',
      hex: '3D2D2E',
      rgb: {
        r: 61,
        g: 45,
        b: 46,
      },
      lab: {
        l: 20.55,
        a: 8.02,
        b: 2.37,
      },
      cmyk: null,
    },
    {
      code: '19-1224 TCX',
      name: 'Fondue Fudge',
      hex: '5D4236',
      rgb: {
        r: 93,
        g: 66,
        b: 54,
      },
      lab: {
        l: 31.1,
        a: 11.28,
        b: 12.47,
      },
      cmyk: null,
    },
    {
      code: '19-1233 TCX',
      name: 'Tiramisu',
      hex: '644235',
      rgb: {
        r: 100,
        g: 66,
        b: 53,
      },
      lab: {
        l: 32.02,
        a: 14.63,
        b: 14.6,
      },
      cmyk: null,
    },
    {
      code: '19-1214 TCX',
      name: 'Chocolate Lab',
      hex: '5C3E35',
      rgb: {
        r: 92,
        g: 62,
        b: 53,
      },
      lab: {
        l: 29.94,
        a: 13.23,
        b: 11.46,
      },
      cmyk: null,
    },
    {
      code: '19-1235 TCX',
      name: 'Brunette',
      hex: '664238',
      rgb: {
        r: 102,
        g: 66,
        b: 56,
      },
      lab: {
        l: 32.24,
        a: 16.09,
        b: 13.28,
      },
      cmyk: null,
    },
    {
      code: '19-1218 TCX',
      name: 'Potting Soil',
      hex: '54392D',
      rgb: {
        r: 84,
        g: 57,
        b: 45,
      },
      lab: {
        l: 27.09,
        a: 12.37,
        b: 12.63,
      },
      cmyk: null,
    },
    {
      code: '19-1432 TCX',
      name: 'Chocolate Fondant',
      hex: '56352D',
      rgb: {
        r: 86,
        g: 53,
        b: 45,
      },
      lab: {
        l: 26.54,
        a: 15.06,
        b: 12.15,
      },
      cmyk: null,
    },
    {
      code: '19-1322 TCX',
      name: 'Brown Stone',
      hex: '593C39',
      rgb: {
        r: 89,
        g: 60,
        b: 57,
      },
      lab: {
        l: 28.81,
        a: 13.81,
        b: 7.91,
      },
      cmyk: null,
    },
    {
      code: '19-1217 TCX',
      name: 'Mustang',
      hex: '684B40',
      rgb: {
        r: 104,
        g: 75,
        b: 64,
      },
      lab: {
        l: 35.13,
        a: 12.25,
        b: 12.3,
      },
      cmyk: null,
    },
    {
      code: '19-1430 TCX',
      name: 'Mink',
      hex: '734B42',
      rgb: {
        r: 115,
        g: 75,
        b: 66,
      },
      lab: {
        l: 36.7,
        a: 17.21,
        b: 13.16,
      },
      cmyk: null,
    },
    {
      code: '19-1228 TCX',
      name: 'Root Beer',
      hex: '714A41',
      rgb: {
        r: 113,
        g: 74,
        b: 65,
      },
      lab: {
        l: 36.07,
        a: 16.95,
        b: 13.04,
      },
      cmyk: null,
    },
    {
      code: '19-1220 TCX',
      name: 'Cappuccino',
      hex: '633F33',
      rgb: {
        r: 99,
        g: 63,
        b: 51,
      },
      lab: {
        l: 30.9,
        a: 16.06,
        b: 14.22,
      },
      cmyk: null,
    },
    {
      code: '19-1431 TCX',
      name: 'Fudgesickle',
      hex: '63403A',
      rgb: {
        r: 99,
        g: 64,
        b: 58,
      },
      lab: {
        l: 31.28,
        a: 16.02,
        b: 10.79,
      },
      cmyk: null,
    },
    {
      code: '19-1321 TCX',
      name: 'Rum Raisin',
      hex: '583432',
      rgb: {
        r: 88,
        g: 52,
        b: 50,
      },
      lab: {
        l: 26.41,
        a: 17.66,
        b: 8.7,
      },
      cmyk: null,
    },
    {
      code: '19-1317 TCX',
      name: 'Bitter Chocolate',
      hex: '503130',
      rgb: {
        r: 80,
        g: 49,
        b: 48,
      },
      lab: {
        l: 24.42,
        a: 15.55,
        b: 7.27,
      },
      cmyk: null,
    },
    {
      code: '19-1436 TCX',
      name: 'Cinnamon',
      hex: '6B4139',
      rgb: {
        r: 107,
        g: 65,
        b: 57,
      },
      lab: {
        l: 32.91,
        a: 19.13,
        b: 13.62,
      },
      cmyk: null,
    },
    {
      code: '19-1320 TCX',
      name: 'Sable',
      hex: '6E3F3C',
      rgb: {
        r: 110,
        g: 63,
        b: 60,
      },
      lab: {
        l: 33.01,
        a: 21.9,
        b: 12.25,
      },
      cmyk: null,
    },
    {
      code: '19-1334 TCX',
      name: 'Henna',
      hex: '7C423C',
      rgb: {
        r: 124,
        g: 66,
        b: 60,
      },
      lab: {
        l: 35.9,
        a: 26.63,
        b: 16.4,
      },
      cmyk: null,
    },
    {
      code: '19-1429 TCX',
      name: 'Smoked Paprika',
      hex: '6F372C',
      rgb: {
        r: 111,
        g: 55,
        b: 44,
      },
      lab: {
        l: 30.8,
        a: 25.54,
        b: 19.11,
      },
      cmyk: null,
    },
    {
      code: '19-1337 TCX',
      name: 'Fired Brick',
      hex: '6A2E2A',
      rgb: {
        r: 106,
        g: 46,
        b: 42,
      },
      lab: {
        l: 28.15,
        a: 28.55,
        b: 16.64,
      },
      cmyk: null,
    },
    {
      code: '19-1331 TCX',
      name: 'Madder Brown',
      hex: '6B3231',
      rgb: {
        r: 107,
        g: 50,
        b: 49,
      },
      lab: {
        l: 29.24,
        a: 26.49,
        b: 14.12,
      },
      cmyk: null,
    },
    {
      code: '19-1325 TCX',
      name: 'Hot Chocolate',
      hex: '683B39',
      rgb: {
        r: 104,
        g: 59,
        b: 57,
      },
      lab: {
        l: 31.05,
        a: 21.28,
        b: 10.95,
      },
      cmyk: null,
    },
    {
      code: '18-1336 TCX',
      name: 'Copper Brown',
      hex: '9A6051',
      rgb: {
        r: 154,
        g: 96,
        b: 81,
      },
      lab: {
        l: 47.36,
        a: 24.15,
        b: 19.43,
      },
      cmyk: null,
    },
    {
      code: '18-1433 TCX',
      name: 'Chutney',
      hex: '98594B',
      rgb: {
        r: 152,
        g: 89,
        b: 75,
      },
      lab: {
        l: 45.53,
        a: 26.72,
        b: 20.32,
      },
      cmyk: null,
    },
    {
      code: '18-1425 TCX',
      name: 'Mahogany',
      hex: '824D46',
      rgb: {
        r: 130,
        g: 77,
        b: 70,
      },
      lab: {
        l: 39.49,
        a: 23.45,
        b: 14.83,
      },
      cmyk: null,
    },
    {
      code: '18-1541 TCX',
      name: 'Brandy Brown',
      hex: '73362A',
      rgb: {
        r: 115,
        g: 54,
        b: 42,
      },
      lab: {
        l: 31.48,
        a: 27.54,
        b: 21.44,
      },
      cmyk: null,
    },
    {
      code: '19-1338 TCX',
      name: 'Russet Brown',
      hex: '743332',
      rgb: {
        r: 116,
        g: 51,
        b: 50,
      },
      lab: {
        l: 31.09,
        a: 30.57,
        b: 16.27,
      },
      cmyk: null,
    },
    {
      code: '18-1325 TCX',
      name: 'Spiced Apple',
      hex: '793A38',
      rgb: {
        r: 121,
        g: 58,
        b: 56,
      },
      lab: {
        l: 33.44,
        a: 28.57,
        b: 15.76,
      },
      cmyk: null,
    },
    {
      code: '19-1435 TCX',
      name: 'Cherry Mahogany',
      hex: '66362C',
      rgb: {
        r: 102,
        g: 54,
        b: 44,
      },
      lab: {
        l: 29.1,
        a: 22.26,
        b: 17.09,
      },
      cmyk: null,
    },
    {
      code: '18-1432 TCX',
      name: 'Redwood Burl',
      hex: '9B574B',
      rgb: {
        r: 155,
        g: 87,
        b: 75,
      },
      lab: {
        l: 45.5,
        a: 28.83,
        b: 20.52,
      },
      cmyk: null,
    },
    {
      code: '18-1243 TCX',
      name: 'Imperial Topaz',
      hex: '875341',
      rgb: {
        r: 135,
        g: 83,
        b: 65,
      },
      lab: {
        l: 41.31,
        a: 22.12,
        b: 20.28,
      },
      cmyk: null,
    },
    {
      code: '18-1341 TCX',
      name: 'Maple Syrup',
      hex: '884D40',
      rgb: {
        r: 136,
        g: 77,
        b: 64,
      },
      lab: {
        l: 40.28,
        a: 25.42,
        b: 19.77,
      },
      cmyk: null,
    },
    {
      code: '18-1323 TCX',
      name: 'Brandy Snifter',
      hex: '7E3F3A',
      rgb: {
        r: 126,
        g: 63,
        b: 58,
      },
      lab: {
        l: 35.41,
        a: 28.42,
        b: 16.75,
      },
      cmyk: null,
    },
    {
      code: '19-1541 TCX',
      name: 'Roasted Russet',
      hex: '7A3B31',
      rgb: {
        r: 122,
        g: 59,
        b: 49,
      },
      lab: {
        l: 33.77,
        a: 28.49,
        b: 19.97,
      },
      cmyk: null,
    },
    {
      code: '19-1428 TCX',
      name: 'Brown Out',
      hex: '714439',
      rgb: {
        r: 113,
        g: 68,
        b: 57,
      },
      lab: {
        l: 34.49,
        a: 20.12,
        b: 15.86,
      },
      cmyk: null,
    },
    {
      code: '19-1242 TCX',
      name: 'Cambridge Brown',
      hex: '6F4433',
      rgb: {
        r: 111,
        g: 68,
        b: 51,
      },
      lab: {
        l: 34.19,
        a: 18.91,
        b: 18.9,
      },
      cmyk: null,
    },
    {
      code: '18-1346 TCX',
      name: 'Bruschetta',
      hex: 'A75949',
      rgb: {
        r: 167,
        g: 89,
        b: 73,
      },
      lab: {
        l: 47.74,
        a: 32.89,
        b: 25.05,
      },
      cmyk: null,
    },
    {
      code: '18-1350 TCX',
      name: 'Burnt Brick',
      hex: 'A14D3A',
      rgb: {
        r: 161,
        g: 77,
        b: 58,
      },
      lab: {
        l: 44.15,
        a: 34.94,
        b: 29.65,
      },
      cmyk: null,
    },
    {
      code: '18-1438 TCX',
      name: 'Marsala',
      hex: '964F4C',
      rgb: {
        r: 150,
        g: 79,
        b: 76,
      },
      lab: {
        l: 43.2,
        a: 31.09,
        b: 17,
      },
      cmyk: null,
    },
    {
      code: '18-1531 TCX',
      name: 'Barn Red',
      hex: '8F423B',
      rgb: {
        r: 143,
        g: 66,
        b: 59,
      },
      lab: {
        l: 39,
        a: 34.1,
        b: 21.32,
      },
      cmyk: null,
    },
    {
      code: '18-1442 TCX',
      name: 'Red Ochre',
      hex: '913832',
      rgb: {
        r: 145,
        g: 56,
        b: 50,
      },
      lab: {
        l: 37.26,
        a: 40.41,
        b: 25.13,
      },
      cmyk: null,
    },
    {
      code: '18-1440 TCX',
      name: 'Chili Oil',
      hex: '8F3D37',
      rgb: {
        r: 143,
        g: 61,
        b: 55,
      },
      lab: {
        l: 37.75,
        a: 36.29,
        b: 22.88,
      },
      cmyk: null,
    },
    {
      code: '19-1540 TCX',
      name: 'Burnt Henna',
      hex: '7E392F',
      rgb: {
        r: 126,
        g: 57,
        b: 47,
      },
      lab: {
        l: 34.06,
        a: 31.36,
        b: 21.91,
      },
      cmyk: null,
    },
    {
      code: '18-1443 TCX',
      name: 'Redwood',
      hex: 'A6594B',
      rgb: {
        r: 166,
        g: 89,
        b: 75,
      },
      lab: {
        l: 47.68,
        a: 32.44,
        b: 23.67,
      },
      cmyk: null,
    },
    {
      code: '18-1434 TCX',
      name: 'Etruscan Red',
      hex: 'A2574B',
      rgb: {
        r: 162,
        g: 87,
        b: 75,
      },
      lab: {
        l: 46.44,
        a: 31.77,
        b: 22.23,
      },
      cmyk: null,
    },
    {
      code: '18-1441 TCX',
      name: 'Baked Clay',
      hex: '9C5642',
      rgb: {
        r: 156,
        g: 86,
        b: 66,
      },
      lab: {
        l: 45.22,
        a: 28.79,
        b: 26.06,
      },
      cmyk: null,
    },
    {
      code: '18-1343 TCX',
      name: 'Auburn',
      hex: 'A15843',
      rgb: {
        r: 161,
        g: 88,
        b: 67,
      },
      lab: {
        l: 46.43,
        a: 30.5,
        b: 26.61,
      },
      cmyk: null,
    },
    {
      code: '19-1245 TCX',
      name: 'Arabian Spice',
      hex: '884332',
      rgb: {
        r: 136,
        g: 67,
        b: 50,
      },
      lab: {
        l: 37.78,
        a: 30.24,
        b: 25.16,
      },
      cmyk: null,
    },
    {
      code: '19-1333 TCX',
      name: 'Sequoia',
      hex: '804839',
      rgb: {
        r: 128,
        g: 72,
        b: 57,
      },
      lab: {
        l: 37.82,
        a: 24.08,
        b: 20.74,
      },
      cmyk: null,
    },
    {
      code: '19-1230 TCX',
      name: 'Friar Brown',
      hex: '6E493A',
      rgb: {
        r: 110,
        g: 73,
        b: 58,
      },
      lab: {
        l: 35.19,
        a: 16.28,
        b: 15.91,
      },
      cmyk: null,
    },
    {
      code: '18-1239 TCX',
      name: 'Sierra',
      hex: '995C41',
      rgb: {
        r: 153,
        g: 92,
        b: 65,
      },
      lab: {
        l: 46.16,
        a: 24.35,
        b: 27.26,
      },
      cmyk: null,
    },
    {
      code: '18-1140 TCX',
      name: 'Mocha Bisque',
      hex: '8C543B',
      rgb: {
        r: 140,
        g: 84,
        b: 59,
      },
      lab: {
        l: 42.4,
        a: 22.72,
        b: 25.8,
      },
      cmyk: null,
    },
    {
      code: '17-1142 TCX',
      name: 'Argan Oil',
      hex: '8B5A3F',
      rgb: {
        r: 139,
        g: 90,
        b: 63,
      },
      lab: {
        l: 43.7,
        a: 19.79,
        b: 24.93,
      },
      cmyk: null,
    },
    {
      code: '18-1242 TCX',
      name: 'Brown Patina',
      hex: '834F3D',
      rgb: {
        r: 131,
        g: 79,
        b: 61,
      },
      lab: {
        l: 39.71,
        a: 22.43,
        b: 20.43,
      },
      cmyk: null,
    },
    {
      code: '18-1230 TCX',
      name: 'Coconut Shell',
      hex: '874E3C',
      rgb: {
        r: 135,
        g: 78,
        b: 60,
      },
      lab: {
        l: 40.29,
        a: 23.92,
        b: 22.04,
      },
      cmyk: null,
    },
    {
      code: '18-1238 TCX',
      name: 'Rustic Brown',
      hex: '855141',
      rgb: {
        r: 133,
        g: 81,
        b: 65,
      },
      lab: {
        l: 40.64,
        a: 22.33,
        b: 19.4,
      },
      cmyk: null,
    },
    {
      code: '19-1241 TCX',
      name: 'Tortoise Shell',
      hex: '754734',
      rgb: {
        r: 117,
        g: 71,
        b: 52,
      },
      lab: {
        l: 35.64,
        a: 19.83,
        b: 20.54,
      },
      cmyk: null,
    },
    {
      code: '17-1422 TCX',
      name: 'Raw Umber',
      hex: '92705F',
      rgb: {
        r: 146,
        g: 112,
        b: 95,
      },
      lab: {
        l: 50.49,
        a: 12.85,
        b: 15.02,
      },
      cmyk: null,
    },
    {
      code: '18-1320 TCX',
      name: 'Clove',
      hex: '876155',
      rgb: {
        r: 135,
        g: 97,
        b: 85,
      },
      lab: {
        l: 45.31,
        a: 15.85,
        b: 13.85,
      },
      cmyk: null,
    },
    {
      code: '18-1229 TCX',
      name: 'Carob Brown',
      hex: '855C4B',
      rgb: {
        r: 133,
        g: 92,
        b: 75,
      },
      lab: {
        l: 43.67,
        a: 16.75,
        b: 17.22,
      },
      cmyk: null,
    },
    {
      code: '18-1235 TCX',
      name: 'Russet',
      hex: '905F50',
      rgb: {
        r: 144,
        g: 95,
        b: 80,
      },
      lab: {
        l: 45.8,
        a: 19.98,
        b: 17.7,
      },
      cmyk: null,
    },
    {
      code: '18-1421 TCX',
      name: 'Baltic Amber',
      hex: '8B645A',
      rgb: {
        r: 139,
        g: 100,
        b: 90,
      },
      lab: {
        l: 46.64,
        a: 15.9,
        b: 12.77,
      },
      cmyk: null,
    },
    {
      code: '18-1314 TCX',
      name: 'Acorn',
      hex: '7E5E52',
      rgb: {
        r: 126,
        g: 94,
        b: 82,
      },
      lab: {
        l: 43.35,
        a: 12.88,
        b: 12.68,
      },
      cmyk: null,
    },
    {
      code: '18-1326 TCX',
      name: 'Nutmeg',
      hex: '7E5C54',
      rgb: {
        r: 126,
        g: 92,
        b: 84,
      },
      lab: {
        l: 42.83,
        a: 14.54,
        b: 10.71,
      },
      cmyk: null,
    },
    {
      code: '18-1124 TCX',
      name: 'Partridge',
      hex: '725440',
      rgb: {
        r: 114,
        g: 84,
        b: 64,
      },
      lab: {
        l: 38.66,
        a: 11.75,
        b: 16.82,
      },
      cmyk: null,
    },
    {
      code: '18-1033 TCX',
      name: 'Dachshund',
      hex: '704F37',
      rgb: {
        r: 112,
        g: 79,
        b: 55,
      },
      lab: {
        l: 37.05,
        a: 12.97,
        b: 19.92,
      },
      cmyk: null,
    },
    {
      code: '18-1031 TCX',
      name: 'Toffee',
      hex: '755139',
      rgb: {
        r: 117,
        g: 81,
        b: 57,
      },
      lab: {
        l: 38.37,
        a: 13.89,
        b: 20.97,
      },
      cmyk: null,
    },
    {
      code: '18-1027 TCX',
      name: 'Bison',
      hex: '6E4F3A',
      rgb: {
        r: 110,
        g: 79,
        b: 58,
      },
      lab: {
        l: 36.77,
        a: 12.41,
        b: 17.78,
      },
      cmyk: null,
    },
    {
      code: '18-1222 TCX',
      name: 'Cocoa Brown',
      hex: '6C5042',
      rgb: {
        r: 108,
        g: 80,
        b: 66,
      },
      lab: {
        l: 37,
        a: 11.8,
        b: 13.1,
      },
      cmyk: null,
    },
    {
      code: '19-1121 TCX',
      name: 'Pinecone',
      hex: '62473B',
      rgb: {
        r: 98,
        g: 71,
        b: 59,
      },
      lab: {
        l: 33.11,
        a: 11.55,
        b: 11.87,
      },
      cmyk: null,
    },
    {
      code: '19-1116 TCX',
      name: 'Carafe',
      hex: '5D473A',
      rgb: {
        r: 93,
        g: 71,
        b: 58,
      },
      lab: {
        l: 32.4,
        a: 9.43,
        b: 11.62,
      },
      cmyk: null,
    },
    {
      code: '18-1019 TCX',
      name: 'Coca Mocha',
      hex: '856A57',
      rgb: {
        r: 133,
        g: 106,
        b: 87,
      },
      lab: {
        l: 47.35,
        a: 10.1,
        b: 15.21,
      },
      cmyk: null,
    },
    {
      code: '18-1028 TCX',
      name: 'Emperador',
      hex: '694833',
      rgb: {
        r: 105,
        g: 72,
        b: 51,
      },
      lab: {
        l: 34.22,
        a: 13.54,
        b: 18.84,
      },
      cmyk: null,
    },
    {
      code: '18-1307 TCX',
      name: 'Coffee Quartz',
      hex: '695650',
      rgb: {
        r: 105,
        g: 86,
        b: 80,
      },
      lab: {
        l: 38.52,
        a: 7.79,
        b: 6.69,
      },
      cmyk: null,
    },
    {
      code: '19-1119 TCX',
      name: 'Cocoa',
      hex: '594536',
      rgb: {
        r: 89,
        g: 69,
        b: 54,
      },
      lab: {
        l: 31.18,
        a: 7.82,
        b: 12.46,
      },
      cmyk: null,
    },
    {
      code: '19-1223 TCX',
      name: 'Downtown Brown',
      hex: '5E3F32',
      rgb: {
        r: 94,
        g: 63,
        b: 50,
      },
      lab: {
        l: 30.29,
        a: 14.1,
        b: 14.16,
      },
      cmyk: null,
    },
    {
      code: '19-1216 TCX',
      name: 'Chocolate Martini',
      hex: '56443F',
      rgb: {
        r: 86,
        g: 68,
        b: 63,
      },
      lab: {
        l: 30.86,
        a: 8.62,
        b: 6.15,
      },
      cmyk: null,
    },
    {
      code: '19-0913 TCX',
      name: 'Hot Fudge',
      hex: '493A35',
      rgb: {
        r: 73,
        g: 58,
        b: 53,
      },
      lab: {
        l: 25.97,
        a: 7.35,
        b: 5.57,
      },
      cmyk: null,
    },
    {
      code: '17-1322 TCX',
      name: 'Burro',
      hex: '9E7E67',
      rgb: {
        r: 158,
        g: 126,
        b: 103,
      },
      lab: {
        l: 55.8,
        a: 11.05,
        b: 18.11,
      },
      cmyk: null,
    },
    {
      code: '17-1044 TCX',
      name: 'Chipmunk',
      hex: '976F4D',
      rgb: {
        r: 151,
        g: 111,
        b: 77,
      },
      lab: {
        l: 50.09,
        a: 11.55,
        b: 25.24,
      },
      cmyk: null,
    },
    {
      code: '17-1327 TCX',
      name: 'Tobacco Brown',
      hex: '9A7352',
      rgb: {
        r: 154,
        g: 115,
        b: 82,
      },
      lab: {
        l: 52.03,
        a: 13.33,
        b: 24.49,
      },
      cmyk: null,
    },
    {
      code: '18-1030 TCX',
      name: 'Thrush',
      hex: '936B4F',
      rgb: {
        r: 147,
        g: 107,
        b: 79,
      },
      lab: {
        l: 49.15,
        a: 15.34,
        b: 22.39,
      },
      cmyk: null,
    },
    {
      code: '18-1024 TCX',
      name: 'Nuthatch',
      hex: '815B3C',
      rgb: {
        r: 129,
        g: 91,
        b: 60,
      },
      lab: {
        l: 42.46,
        a: 13.95,
        b: 24.54,
      },
      cmyk: null,
    },
    {
      code: '18-1137 TCX',
      name: 'Rawhide',
      hex: '865E49',
      rgb: {
        r: 134,
        g: 94,
        b: 73,
      },
      lab: {
        l: 44.22,
        a: 16.1,
        b: 19.34,
      },
      cmyk: null,
    },
    {
      code: '19-1227 TCX',
      name: 'Sorrel Horse',
      hex: '6B4331',
      rgb: {
        r: 107,
        g: 67,
        b: 49,
      },
      lab: {
        l: 33.28,
        a: 17.04,
        b: 18.98,
      },
      cmyk: null,
    },
    {
      code: '17-1324 TCX',
      name: 'Cinnamon Swirl',
      hex: '9C7961',
      rgb: {
        r: 156,
        g: 121,
        b: 97,
      },
      lab: {
        l: 54.13,
        a: 12.46,
        b: 19.06,
      },
      cmyk: null,
    },
    {
      code: '17-1318 TCX',
      name: 'Affogat',
      hex: 'A28774',
      rgb: {
        r: 162,
        g: 135,
        b: 116,
      },
      lab: {
        l: 58.67,
        a: 9.33,
        b: 14.2,
      },
      cmyk: null,
    },
    {
      code: '17-1317 TCX',
      name: 'Sinopia Fresco',
      hex: '917567',
      rgb: {
        r: 145,
        g: 117,
        b: 103,
      },
      lab: {
        l: 51.91,
        a: 10.71,
        b: 12.17,
      },
      cmyk: null,
    },
    {
      code: '18-1324 TCX',
      name: 'Chocolate Malt',
      hex: '907162',
      rgb: {
        r: 144,
        g: 113,
        b: 98,
      },
      lab: {
        l: 50.58,
        a: 12.06,
        b: 13.07,
      },
      cmyk: null,
    },
    {
      code: '18-1125 TCX',
      name: 'Pecan Pie',
      hex: '7B5947',
      rgb: {
        r: 123,
        g: 89,
        b: 71,
      },
      lab: {
        l: 41.24,
        a: 13.73,
        b: 15.97,
      },
      cmyk: null,
    },
    {
      code: '18-1130 TCX',
      name: 'Cacao Nibs',
      hex: '7A5747',
      rgb: {
        r: 122,
        g: 87,
        b: 71,
      },
      lab: {
        l: 40.81,
        a: 13.6,
        b: 15.79,
      },
      cmyk: null,
    },
    {
      code: '18-1232 TCX',
      name: 'Soft Silt',
      hex: '6D4D3E',
      rgb: {
        r: 109,
        g: 77,
        b: 62,
      },
      lab: {
        l: 36.3,
        a: 13.5,
        b: 14.87,
      },
      cmyk: null,
    },
    {
      code: '17-1223 TCX',
      name: 'Praline',
      hex: 'AD8B75',
      rgb: {
        r: 173,
        g: 139,
        b: 117,
      },
      lab: {
        l: 60.88,
        a: 11.6,
        b: 17.07,
      },
      cmyk: null,
    },
    {
      code: '16-1323 TCX',
      name: 'Macaroon',
      hex: 'B48B71',
      rgb: {
        r: 180,
        g: 139,
        b: 113,
      },
      lab: {
        l: 61.47,
        a: 14.34,
        b: 20.3,
      },
      cmyk: null,
    },
    {
      code: '17-1225 TCX',
      name: 'Tawny Birch',
      hex: 'AE856C',
      rgb: {
        r: 174,
        g: 133,
        b: 108,
      },
      lab: {
        l: 59.46,
        a: 14.7,
        b: 20.13,
      },
      cmyk: null,
    },
    {
      code: '17-1226 TCX',
      name: 'Tawny Brown',
      hex: 'AB856F',
      rgb: {
        r: 171,
        g: 133,
        b: 111,
      },
      lab: {
        l: 58.95,
        a: 13.87,
        b: 18.19,
      },
      cmyk: null,
    },
    {
      code: '17-1328 TCX',
      name: 'Tanzine',
      hex: 'AD8567',
      rgb: {
        r: 173,
        g: 133,
        b: 103,
      },
      lab: {
        l: 59.1,
        a: 13.74,
        b: 22.44,
      },
      cmyk: null,
    },
    {
      code: '17-1221 TCX',
      name: 'Macchiato',
      hex: 'AC8266',
      rgb: {
        r: 172,
        g: 130,
        b: 102,
      },
      lab: {
        l: 58.25,
        a: 14.8,
        b: 21.8,
      },
      cmyk: null,
    },
    {
      code: '17-1330 TCX',
      name: 'Lion',
      hex: 'A0714F',
      rgb: {
        r: 160,
        g: 113,
        b: 79,
      },
      lab: {
        l: 52.33,
        a: 17.42,
        b: 26.76,
      },
      cmyk: null,
    },
    {
      code: '16-1317 TCX',
      name: 'Brush',
      hex: 'B99984',
      rgb: {
        r: 185,
        g: 153,
        b: 132,
      },
      lab: {
        l: 66,
        a: 10.7,
        b: 15.8,
      },
      cmyk: null,
    },
    {
      code: '16-1320 TCX',
      name: 'Nougat',
      hex: 'B69885',
      rgb: {
        r: 182,
        g: 152,
        b: 133,
      },
      lab: {
        l: 65.38,
        a: 11,
        b: 14.18,
      },
      cmyk: null,
    },
    {
      code: '16-1221 TCX',
      name: 'Roebuck',
      hex: 'B09080',
      rgb: {
        r: 176,
        g: 144,
        b: 128,
      },
      lab: {
        l: 62.76,
        a: 11.5,
        b: 13.85,
      },
      cmyk: null,
    },
    {
      code: '17-1224 TCX',
      name: 'Camel',
      hex: 'B0846A',
      rgb: {
        r: 176,
        g: 132,
        b: 106,
      },
      lab: {
        l: 59.23,
        a: 15.91,
        b: 20.96,
      },
      cmyk: null,
    },
    {
      code: '17-1227 TCX',
      name: 'Café au Lait',
      hex: 'AE8774',
      rgb: {
        r: 174,
        g: 135,
        b: 116,
      },
      lab: {
        l: 59.91,
        a: 14.85,
        b: 16.37,
      },
      cmyk: null,
    },
    {
      code: '17-1230 TCX',
      name: 'Mocha Mousse',
      hex: 'A47864',
      rgb: {
        r: 164,
        g: 120,
        b: 100,
      },
      lab: {
        l: 54.79,
        a: 17.05,
        b: 18.56,
      },
      cmyk: null,
    },
    {
      code: '18-1422 TCX',
      name: 'Myristica',
      hex: '846157',
      rgb: {
        r: 132,
        g: 97,
        b: 87,
      },
      lab: {
        l: 44.82,
        a: 14.54,
        b: 11.8,
      },
      cmyk: null,
    },
    {
      code: '13-1108 TCX',
      name: 'Cream Tan',
      hex: 'E4C7B7',
      rgb: {
        r: 228,
        g: 199,
        b: 183,
      },
      lab: {
        l: 82.61,
        a: 8.9,
        b: 12.04,
      },
      cmyk: null,
    },
    {
      code: '14-1314 TCX',
      name: 'Spanish Villa',
      hex: 'DFBAA9',
      rgb: {
        r: 223,
        g: 186,
        b: 169,
      },
      lab: {
        l: 78.71,
        a: 12.56,
        b: 13.98,
      },
      cmyk: null,
    },
    {
      code: '15-1317 TCX',
      name: 'Sirocco',
      hex: 'C39D88',
      rgb: {
        r: 195,
        g: 157,
        b: 136,
      },
      lab: {
        l: 68.16,
        a: 12.91,
        b: 16.97,
      },
      cmyk: null,
    },
    {
      code: '15-1316 TCX',
      name: 'Maple Sugar',
      hex: 'C9A38D',
      rgb: {
        r: 201,
        g: 163,
        b: 141,
      },
      lab: {
        l: 70.24,
        a: 12.78,
        b: 17.19,
      },
      cmyk: null,
    },
    {
      code: '16-1219 TCX',
      name: 'Tuscany',
      hex: 'BE9785',
      rgb: {
        r: 190,
        g: 151,
        b: 133,
      },
      lab: {
        l: 65.98,
        a: 13.94,
        b: 15.41,
      },
      cmyk: null,
    },
    {
      code: '16-1220 TCX',
      name: 'Café Crème',
      hex: 'C79685',
      rgb: {
        r: 199,
        g: 150,
        b: 133,
      },
      lab: {
        l: 66.86,
        a: 18.46,
        b: 16.89,
      },
      cmyk: null,
    },
    {
      code: '16-1422 TCX',
      name: 'Cork',
      hex: 'BA8671',
      rgb: {
        r: 186,
        g: 134,
        b: 113,
      },
      lab: {
        l: 61.2,
        a: 19.18,
        b: 19.87,
      },
      cmyk: null,
    },
    {
      code: '13-1405 TCX',
      name: 'Shell',
      hex: 'E1CFC6',
      rgb: {
        r: 225,
        g: 207,
        b: 198,
      },
      lab: {
        l: 84.7,
        a: 5.95,
        b: 7.02,
      },
      cmyk: null,
    },
    {
      code: '12-1206 TCX',
      name: 'Silver Peony',
      hex: 'E7CFC7',
      rgb: {
        r: 231,
        g: 207,
        b: 199,
      },
      lab: {
        l: 85.13,
        a: 8.35,
        b: 7.19,
      },
      cmyk: null,
    },
    {
      code: '14-1309 TCX',
      name: 'Peach Whip',
      hex: 'DBBEB7',
      rgb: {
        r: 219,
        g: 190,
        b: 183,
      },
      lab: {
        l: 79.56,
        a: 10.31,
        b: 7.76,
      },
      cmyk: null,
    },
    {
      code: '14-1310 TCX',
      name: 'Cameo Rose',
      hex: 'D7B8AB',
      rgb: {
        r: 215,
        g: 184,
        b: 171,
      },
      lab: {
        l: 77.48,
        a: 10.63,
        b: 11.05,
      },
      cmyk: null,
    },
    {
      code: '14-1307 TCX',
      name: 'Rose Dust',
      hex: 'CDB2A5',
      rgb: {
        r: 205,
        g: 178,
        b: 165,
      },
      lab: {
        l: 74.73,
        a: 9.37,
        b: 10.34,
      },
      cmyk: null,
    },
    {
      code: '15-1315 TCX',
      name: 'Rugby Tan',
      hex: 'C2A594',
      rgb: {
        r: 194,
        g: 165,
        b: 148,
      },
      lab: {
        l: 70.17,
        a: 10.17,
        b: 13.06,
      },
      cmyk: null,
    },
    {
      code: '15-1511 TCX',
      name: 'Mahogany Rose',
      hex: 'C5A193',
      rgb: {
        r: 197,
        g: 161,
        b: 147,
      },
      lab: {
        l: 69.56,
        a: 13.03,
        b: 13.05,
      },
      cmyk: null,
    },
    {
      code: '11-1006 TCX',
      name: 'Slightly Pink',
      hex: 'EDE4DF',
      rgb: {
        r: 237,
        g: 228,
        b: 223,
      },
      lab: {
        l: 91.2,
        a: 3.13,
        b: 3.77,
      },
      cmyk: null,
    },
    {
      code: '11-1007 TCX',
      name: 'Coconut Cream',
      hex: 'EEE0DC',
      rgb: {
        r: 238,
        g: 224,
        b: 220,
      },
      lab: {
        l: 90.27,
        a: 4.88,
        b: 3.58,
      },
      cmyk: null,
    },
    {
      code: '11-2309 TCX',
      name: 'Petal Pink',
      hex: 'F2E2E0',
      rgb: {
        r: 242,
        g: 226,
        b: 224,
      },
      lab: {
        l: 91.29,
        a: 5.6,
        b: 3.23,
      },
      cmyk: null,
    },
    {
      code: '12-2103 TCX',
      name: 'Almost Mauve',
      hex: 'E7DCD9',
      rgb: {
        r: 231,
        g: 220,
        b: 217,
      },
      lab: {
        l: 88.58,
        a: 4.12,
        b: 2.77,
      },
      cmyk: null,
    },
    {
      code: '11-0603 TCX',
      name: 'Pastel Parchment',
      hex: 'E5D9D3',
      rgb: {
        r: 229,
        g: 217,
        b: 211,
      },
      lab: {
        l: 87.67,
        a: 3.71,
        b: 4.58,
      },
      cmyk: null,
    },
    {
      code: '12-1105 TCX',
      name: 'Salsa Rosa',
      hex: 'F3DBD3',
      rgb: {
        r: 243,
        g: 219,
        b: 211,
      },
      lab: {
        l: 89.42,
        a: 7.99,
        b: 7.55,
      },
      cmyk: null,
    },
    {
      code: '11-1305 TCX',
      name: 'Angel Wing',
      hex: 'F3DFD7',
      rgb: {
        r: 243,
        g: 223,
        b: 215,
      },
      lab: {
        l: 90.36,
        a: 6.76,
        b: 6.68,
      },
      cmyk: null,
    },
    {
      code: '11-1302 TCX',
      name: 'Sea Salt',
      hex: 'F1E6DF',
      rgb: {
        r: 241,
        g: 230,
        b: 223,
      },
      lab: {
        l: 92.2,
        a: 3,
        b: 5.27,
      },
      cmyk: null,
    },
    {
      code: '12-1106 TCX',
      name: 'Sheer Pink',
      hex: 'F6E5DB',
      rgb: {
        r: 246,
        g: 229,
        b: 219,
      },
      lab: {
        l: 92.18,
        a: 5.17,
        b: 7.09,
      },
      cmyk: null,
    },
    {
      code: '11-1306 TCX',
      name: 'Cream Pink',
      hex: 'F6E4D9',
      rgb: {
        r: 246,
        g: 228,
        b: 217,
      },
      lab: {
        l: 91.86,
        a: 5.35,
        b: 7.72,
      },
      cmyk: null,
    },
    {
      code: '11-1005 TCX',
      name: 'Bridal Blush',
      hex: 'EEE2DD',
      rgb: {
        r: 238,
        g: 226,
        b: 221,
      },
      lab: {
        l: 90.78,
        a: 3.82,
        b: 3.8,
      },
      cmyk: null,
    },
    {
      code: '12-1108 TCX',
      name: 'Dew',
      hex: 'EEDED1',
      rgb: {
        r: 238,
        g: 222,
        b: 209,
      },
      lab: {
        l: 89.57,
        a: 4.66,
        b: 8.29,
      },
      cmyk: null,
    },
    {
      code: '12-2901 TCX',
      name: 'Morganite',
      hex: 'E0CDC6',
      rgb: {
        r: 224,
        g: 205,
        b: 198,
      },
      lab: {
        l: 84,
        a: 6.36,
        b: 6.03,
      },
      cmyk: null,
    },
    {
      code: '13-1504 TCX',
      name: 'Peach Blush',
      hex: 'E4CCC6',
      rgb: {
        r: 228,
        g: 204,
        b: 198,
      },
      lab: {
        l: 84.21,
        a: 8.44,
        b: 6.84,
      },
      cmyk: null,
    },
    {
      code: '11-1404 TCX',
      name: 'Powder Puff',
      hex: 'F3E0D6',
      rgb: {
        r: 243,
        g: 224,
        b: 214,
      },
      lab: {
        l: 90.73,
        a: 5.8,
        b: 7.51,
      },
      cmyk: null,
    },
    {
      code: '12-1107 TCX',
      name: 'Peach Dust',
      hex: 'F0D8CC',
      rgb: {
        r: 240,
        g: 216,
        b: 204,
      },
      lab: {
        l: 88.26,
        a: 7.55,
        b: 9.46,
      },
      cmyk: null,
    },
    {
      code: '12-1209 TCX',
      name: 'Soft Pink',
      hex: 'F2D8CD',
      rgb: {
        r: 242,
        g: 216,
        b: 205,
      },
      lab: {
        l: 88.44,
        a: 8.7,
        b: 9.36,
      },
      cmyk: null,
    },
    {
      code: '12-1110 TCX',
      name: 'Crème de Pêche',
      hex: 'F6D7C7',
      rgb: {
        r: 246,
        g: 215,
        b: 199,
      },
      lab: {
        l: 88.31,
        a: 10.03,
        b: 12.07,
      },
      cmyk: null,
    },
    {
      code: '12-1010 TCX',
      name: 'Scallop Shell',
      hex: 'FBD8C9',
      rgb: {
        r: 251,
        g: 216,
        b: 201,
      },
      lab: {
        l: 89.28,
        a: 11.03,
        b: 12.8,
      },
      cmyk: null,
    },
    {
      code: '13-1404 TCX',
      name: 'Pale Dogwood',
      hex: 'EDCDC2',
      rgb: {
        r: 237,
        g: 205,
        b: 194,
      },
      lab: {
        l: 85.07,
        a: 10.78,
        b: 9.89,
      },
      cmyk: null,
    },
    {
      code: '12-1207 TCX',
      name: 'Pearl Blush',
      hex: 'F4CEC5',
      rgb: {
        r: 244,
        g: 206,
        b: 197,
      },
      lab: {
        l: 86.01,
        a: 13.57,
        b: 10.14,
      },
      cmyk: null,
    },
    {
      code: '13-1406 TCX',
      name: 'Cloud Pink',
      hex: 'F5D1C8',
      rgb: {
        r: 245,
        g: 209,
        b: 200,
      },
      lab: {
        l: 86.86,
        a: 12.82,
        b: 9.78,
      },
      cmyk: null,
    },
    {
      code: '13-1407 TCX',
      name: 'Creole Pink',
      hex: 'F7D5CC',
      rgb: {
        r: 247,
        g: 213,
        b: 204,
      },
      lab: {
        l: 88.19,
        a: 11.94,
        b: 9.32,
      },
      cmyk: null,
    },
    {
      code: '13-1208 TCX',
      name: 'Peachy Keen',
      hex: 'E3BEB4',
      rgb: {
        r: 227,
        g: 190,
        b: 180,
      },
      lab: {
        l: 80.19,
        a: 13.22,
        b: 10.64,
      },
      cmyk: null,
    },
    {
      code: '14-1312 TCX',
      name: 'Peach Powder',
      hex: 'E4BFB3',
      rgb: {
        r: 228,
        g: 191,
        b: 179,
      },
      lab: {
        l: 80.67,
        a: 12.96,
        b: 11.84,
      },
      cmyk: null,
    },
    {
      code: '14-1311 TCX',
      name: 'Evening Sand',
      hex: 'DDB6AB',
      rgb: {
        r: 221,
        g: 182,
        b: 171,
      },
      lab: {
        l: 77.65,
        a: 13.5,
        b: 11.45,
      },
      cmyk: null,
    },
    {
      code: '14-1313 TCX',
      name: 'Rose Cloud',
      hex: 'DBB0A2',
      rgb: {
        r: 219,
        g: 176,
        b: 162,
      },
      lab: {
        l: 75.91,
        a: 15.56,
        b: 14.03,
      },
      cmyk: null,
    },
    {
      code: '14-1316 TCX',
      name: 'Dusty Pink',
      hex: 'DEAA9B',
      rgb: {
        r: 222,
        g: 170,
        b: 155,
      },
      lab: {
        l: 74.68,
        a: 19.14,
        b: 16.25,
      },
      cmyk: null,
    },
    {
      code: '13-1109 TCX',
      name: 'Bisque',
      hex: 'EDCAB5',
      rgb: {
        r: 237,
        g: 202,
        b: 181,
      },
      lab: {
        l: 84.21,
        a: 11.43,
        b: 15.49,
      },
      cmyk: null,
    },
    {
      code: '13-1114 TCX',
      name: 'Bellini',
      hex: 'F4C9B1',
      rgb: {
        r: 244,
        g: 201,
        b: 177,
      },
      lab: {
        l: 84.59,
        a: 14,
        b: 18.65,
      },
      cmyk: null,
    },
    {
      code: '15-1319 TCX',
      name: 'Almost Apricot',
      hex: 'E5B39B',
      rgb: {
        r: 229,
        g: 179,
        b: 155,
      },
      lab: {
        l: 77.54,
        a: 17.28,
        b: 20.06,
      },
      cmyk: null,
    },
    {
      code: '14-1220 TCX',
      name: 'Peach Nougat',
      hex: 'E6AF91',
      rgb: {
        r: 230,
        g: 175,
        b: 145,
      },
      lab: {
        l: 76.39,
        a: 19.38,
        b: 24.29,
      },
      cmyk: null,
    },
    {
      code: '15-1318 TCX',
      name: 'Pink Sand',
      hex: 'DFB19B',
      rgb: {
        r: 223,
        g: 177,
        b: 155,
      },
      lab: {
        l: 76.24,
        a: 16.31,
        b: 18.21,
      },
      cmyk: null,
    },
    {
      code: '15-1322 TCX',
      name: 'Dusty Coral',
      hex: 'D29B83',
      rgb: {
        r: 210,
        g: 155,
        b: 131,
      },
      lab: {
        l: 69.31,
        a: 19.57,
        b: 21.67,
      },
      cmyk: null,
    },
    {
      code: '16-1330 TCX',
      name: 'Muted Clay',
      hex: 'D29380',
      rgb: {
        r: 210,
        g: 147,
        b: 128,
      },
      lab: {
        l: 67.41,
        a: 23.79,
        b: 21.04,
      },
      cmyk: null,
    },
    {
      code: '12-0813 TCX',
      name: 'Autumn Blonde',
      hex: 'EECFAD',
      rgb: {
        r: 238,
        g: 207,
        b: 173,
      },
      lab: {
        l: 85.41,
        a: 8.07,
        b: 21.34,
      },
      cmyk: null,
    },
    {
      code: '13-1024 TCX',
      name: 'Buff',
      hex: 'EBC396',
      rgb: {
        r: 235,
        g: 195,
        b: 150,
      },
      lab: {
        l: 81.76,
        a: 10.78,
        b: 28.67,
      },
      cmyk: null,
    },
    {
      code: '13-1018 TCX',
      name: 'Desert Dust',
      hex: 'E3BC8E',
      rgb: {
        r: 227,
        g: 188,
        b: 142,
      },
      lab: {
        l: 79.25,
        a: 10.51,
        b: 29.25,
      },
      cmyk: null,
    },
    {
      code: '12-0921 TCX',
      name: 'Golden Straw',
      hex: 'E6BD8F',
      rgb: {
        r: 230,
        g: 189,
        b: 143,
      },
      lab: {
        l: 79.68,
        a: 11.54,
        b: 29.33,
      },
      cmyk: null,
    },
    {
      code: '14-1127 TCX',
      name: 'Desert Mist',
      hex: 'E0B589',
      rgb: {
        r: 224,
        g: 181,
        b: 137,
      },
      lab: {
        l: 77.04,
        a: 12.91,
        b: 29.07,
      },
      cmyk: null,
    },
    {
      code: '14-1122 TCX',
      name: 'Sheepskin',
      hex: 'DAB58F',
      rgb: {
        r: 218,
        g: 181,
        b: 143,
      },
      lab: {
        l: 76.56,
        a: 10.81,
        b: 25.1,
      },
      cmyk: null,
    },
    {
      code: '16-1333 TCX',
      name: 'Doe',
      hex: 'B98E68',
      rgb: {
        r: 185,
        g: 142,
        b: 104,
      },
      lab: {
        l: 62.88,
        a: 14.61,
        b: 26.9,
      },
      cmyk: null,
    },
    {
      code: '15-1125 TCX',
      name: 'Porcini',
      hex: 'CCA580',
      rgb: {
        r: 204,
        g: 165,
        b: 128,
      },
      lab: {
        l: 70.97,
        a: 11.98,
        b: 25.19,
      },
      cmyk: null,
    },
    {
      code: '15-1220 TCX',
      name: 'Lattè',
      hex: 'C5A582',
      rgb: {
        r: 197,
        g: 165,
        b: 130,
      },
      lab: {
        l: 70.19,
        a: 9.39,
        b: 22.95,
      },
      cmyk: null,
    },
    {
      code: '15-1231 TCX',
      name: 'Clay',
      hex: 'D2A172',
      rgb: {
        r: 210,
        g: 161,
        b: 114,
      },
      lab: {
        l: 70.41,
        a: 15.64,
        b: 32.23,
      },
      cmyk: null,
    },
    {
      code: '16-1341 TCX',
      name: 'Butterum',
      hex: 'C68F65',
      rgb: {
        r: 198,
        g: 143,
        b: 101,
      },
      lab: {
        l: 64.51,
        a: 18.42,
        b: 31.78,
      },
      cmyk: null,
    },
    {
      code: '15-1425 TCX',
      name: 'Fenugreek',
      hex: 'C1926C',
      rgb: {
        r: 193,
        g: 146,
        b: 108,
      },
      lab: {
        l: 64.64,
        a: 15.88,
        b: 27.48,
      },
      cmyk: null,
    },
    {
      code: '15-1430 TCX',
      name: 'Pastry Shell',
      hex: 'BD8D67',
      rgb: {
        r: 189,
        g: 141,
        b: 103,
      },
      lab: {
        l: 63.02,
        a: 16.47,
        b: 28.27,
      },
      cmyk: null,
    },
    {
      code: '16-1438 TCX',
      name: 'Meerkat',
      hex: 'A47045',
      rgb: {
        r: 164,
        g: 112,
        b: 69,
      },
      lab: {
        l: 52.41,
        a: 19.25,
        b: 32.73,
      },
      cmyk: null,
    },
    {
      code: '15-1225 TCX',
      name: 'Sand',
      hex: 'CCA67F',
      rgb: {
        r: 204,
        g: 166,
        b: 127,
      },
      lab: {
        l: 71.18,
        a: 11.3,
        b: 26.12,
      },
      cmyk: null,
    },
    {
      code: '16-1331 TCX',
      name: 'Toast',
      hex: 'CA9978',
      rgb: {
        r: 202,
        g: 153,
        b: 120,
      },
      lab: {
        l: 67.77,
        a: 16.97,
        b: 25.18,
      },
      cmyk: null,
    },
    {
      code: '16-1336 TCX',
      name: 'Biscuit',
      hex: 'B4835B',
      rgb: {
        r: 180,
        g: 131,
        b: 91,
      },
      lab: {
        l: 59.45,
        a: 16.62,
        b: 29.56,
      },
      cmyk: null,
    },
    {
      code: '16-1235 TCX',
      name: 'Sandstorm',
      hex: 'BD8B69',
      rgb: {
        r: 189,
        g: 139,
        b: 105,
      },
      lab: {
        l: 62.57,
        a: 17.78,
        b: 26.61,
      },
      cmyk: null,
    },
    {
      code: '16-1432 TCX',
      name: 'Almond',
      hex: 'A7754D',
      rgb: {
        r: 167,
        g: 117,
        b: 77,
      },
      lab: {
        l: 54.27,
        a: 18,
        b: 30.68,
      },
      cmyk: null,
    },
    {
      code: '17-1134 TCX',
      name: 'Brown Sugar',
      hex: 'A17249',
      rgb: {
        r: 161,
        g: 114,
        b: 73,
      },
      lab: {
        l: 52.62,
        a: 16.43,
        b: 30.7,
      },
      cmyk: null,
    },
    {
      code: '17-1137 TCX',
      name: 'Cashew',
      hex: 'A57149',
      rgb: {
        r: 165,
        g: 113,
        b: 73,
      },
      lab: {
        l: 52.73,
        a: 18.91,
        b: 31.08,
      },
      cmyk: null,
    },
    {
      code: '17-1336 TCX',
      name: 'Bran',
      hex: 'A66E4A',
      rgb: {
        r: 166,
        g: 110,
        b: 74,
      },
      lab: {
        l: 52.34,
        a: 21.17,
        b: 30.03,
      },
      cmyk: null,
    },
    {
      code: '17-1340 TCX',
      name: 'Adobe',
      hex: 'A3623B',
      rgb: {
        r: 163,
        g: 98,
        b: 59,
      },
      lab: {
        l: 48.89,
        a: 25.45,
        b: 34.21,
      },
      cmyk: null,
    },
    {
      code: '18-1154 TCX',
      name: 'Glazed Ginger',
      hex: '91552B',
      rgb: {
        r: 145,
        g: 85,
        b: 43,
      },
      lab: {
        l: 43.23,
        a: 23.4,
        b: 35.52,
      },
      cmyk: null,
    },
    {
      code: '17-1052 TCX',
      name: 'Roasted Pecan',
      hex: '945A2C',
      rgb: {
        r: 148,
        g: 90,
        b: 44,
      },
      lab: {
        l: 44.65,
        a: 22.75,
        b: 36.87,
      },
      cmyk: null,
    },
    {
      code: '18-1155 TCX',
      name: 'Sugar Almond',
      hex: '94562A',
      rgb: {
        r: 148,
        g: 86,
        b: 42,
      },
      lab: {
        l: 43.68,
        a: 24.83,
        b: 36.91,
      },
      cmyk: null,
    },
    {
      code: '18-1142 TCX',
      name: 'Leather Brown',
      hex: '97572B',
      rgb: {
        r: 151,
        g: 87,
        b: 43,
      },
      lab: {
        l: 44.47,
        a: 25.69,
        b: 37.43,
      },
      cmyk: null,
    },
    {
      code: '18-1148 TCX',
      name: 'Caramel Café',
      hex: '864C24',
      rgb: {
        r: 134,
        g: 76,
        b: 36,
      },
      lab: {
        l: 39.48,
        a: 23.23,
        b: 35.14,
      },
      cmyk: null,
    },
    {
      code: '15-1327 TCX',
      name: 'Peach Bloom',
      hex: 'D99B7C',
      rgb: {
        r: 217,
        g: 155,
        b: 124,
      },
      lab: {
        l: 69.81,
        a: 22.53,
        b: 26.59,
      },
      cmyk: null,
    },
    {
      code: '16-1327 TCX',
      name: 'Toasted Nut',
      hex: 'C08768',
      rgb: {
        r: 192,
        g: 135,
        b: 104,
      },
      lab: {
        l: 62.14,
        a: 20.01,
        b: 26.87,
      },
      cmyk: null,
    },
    {
      code: '16-1332 TCX',
      name: 'Pheasant',
      hex: 'C68463',
      rgb: {
        r: 198,
        g: 132,
        b: 99,
      },
      lab: {
        l: 61.9,
        a: 24.77,
        b: 29.38,
      },
      cmyk: null,
    },
    {
      code: '17-1430 TCX',
      name: 'Pecan Brown',
      hex: 'A36E51',
      rgb: {
        r: 163,
        g: 110,
        b: 81,
      },
      lab: {
        l: 51.89,
        a: 20.52,
        b: 25.7,
      },
      cmyk: null,
    },
    {
      code: '16-1429 TCX',
      name: 'Sunburn',
      hex: 'B37256',
      rgb: {
        r: 179,
        g: 114,
        b: 86,
      },
      lab: {
        l: 55.11,
        a: 25.52,
        b: 27.23,
      },
      cmyk: null,
    },
    {
      code: '17-1147 TCX',
      name: 'Amber Brown',
      hex: 'A76646',
      rgb: {
        r: 167,
        g: 102,
        b: 70,
      },
      lab: {
        l: 50.44,
        a: 25.38,
        b: 30.36,
      },
      cmyk: null,
    },
    {
      code: '17-1143 TCX',
      name: 'Hazel',
      hex: 'AE7250',
      rgb: {
        r: 174,
        g: 114,
        b: 80,
      },
      lab: {
        l: 54.45,
        a: 22.61,
        b: 29.57,
      },
      cmyk: null,
    },
    {
      code: '16-1328 TCX',
      name: 'Sandstone',
      hex: 'C48A69',
      rgb: {
        r: 196,
        g: 138,
        b: 105,
      },
      lab: {
        l: 63.3,
        a: 20.82,
        b: 27.43,
      },
      cmyk: null,
    },
    {
      code: '16-1439 TCX',
      name: 'Caramel',
      hex: 'C37C54',
      rgb: {
        r: 195,
        g: 124,
        b: 84,
      },
      lab: {
        l: 59.66,
        a: 26.26,
        b: 34.65,
      },
      cmyk: null,
    },
    {
      code: '17-1436 TCX',
      name: 'Raw Sienna',
      hex: 'B9714F',
      rgb: {
        r: 185,
        g: 113,
        b: 79,
      },
      lab: {
        l: 55.79,
        a: 27.86,
        b: 32.46,
      },
      cmyk: null,
    },
    {
      code: '17-1347 TCX',
      name: 'Autumn Leaf',
      hex: 'B5684C',
      rgb: {
        r: 181,
        g: 104,
        b: 76,
      },
      lab: {
        l: 53.16,
        a: 30.73,
        b: 30.44,
      },
      cmyk: null,
    },
    {
      code: '18-1250 TCX',
      name: 'Bombay Brown',
      hex: '9F5130',
      rgb: {
        r: 159,
        g: 81,
        b: 48,
      },
      lab: {
        l: 44.53,
        a: 32.2,
        b: 35.18,
      },
      cmyk: null,
    },
    {
      code: '17-1342 TCX',
      name: 'Autumnal',
      hex: 'A15325',
      rgb: {
        r: 161,
        g: 83,
        b: 37,
      },
      lab: {
        l: 45.21,
        a: 30.79,
        b: 42.06,
      },
      cmyk: null,
    },
    {
      code: '18-1246 TCX',
      name: 'Umber',
      hex: '944A20',
      rgb: {
        r: 148,
        g: 74,
        b: 32,
      },
      lab: {
        l: 41.12,
        a: 30.69,
        b: 39.87,
      },
      cmyk: null,
    },
    {
      code: '18-1450 TCX',
      name: 'Mecca Orange',
      hex: 'BD5745',
      rgb: {
        r: 189,
        g: 87,
        b: 69,
      },
      lab: {
        l: 50.87,
        a: 43.96,
        b: 31.73,
      },
      cmyk: null,
    },
    {
      code: '18-1451 TCX',
      name: 'Autumn Glaze',
      hex: 'B3573F',
      rgb: {
        r: 179,
        g: 87,
        b: 63,
      },
      lab: {
        l: 49.14,
        a: 37.37,
        b: 33.35,
      },
      cmyk: null,
    },
    {
      code: '18-1340 TCX',
      name: "Potter's Clay",
      hex: '9E4624',
      rgb: {
        r: 158,
        g: 70,
        b: 36,
      },
      lab: {
        l: 42.1,
        a: 36.12,
        b: 39.33,
      },
      cmyk: null,
    },
    {
      code: '18-1345 TCX',
      name: 'Cinnamon Stick',
      hex: '9C4722',
      rgb: {
        r: 156,
        g: 71,
        b: 34,
      },
      lab: {
        l: 41.87,
        a: 34.97,
        b: 39.66,
      },
      cmyk: null,
    },
    {
      code: '18-1355 TCX',
      name: 'Rooibos Tea',
      hex: 'A23C26',
      rgb: {
        r: 162,
        g: 60,
        b: 38,
      },
      lab: {
        l: 41.05,
        a: 44.25,
        b: 36.92,
      },
      cmyk: null,
    },
    {
      code: '19-1250 TCX',
      name: 'Picante',
      hex: '8D3F2D',
      rgb: {
        r: 141,
        g: 63,
        b: 45,
      },
      lab: {
        l: 37.87,
        a: 34.32,
        b: 28.55,
      },
      cmyk: null,
    },
    {
      code: '18-1244 TCX',
      name: 'Ginger Bread',
      hex: '8C4A2F',
      rgb: {
        r: 140,
        g: 74,
        b: 47,
      },
      lab: {
        l: 39.96,
        a: 27.64,
        b: 29.54,
      },
      cmyk: null,
    },
    {
      code: '17-1540 TCX',
      name: 'Apricot Brandy',
      hex: 'C26A5A',
      rgb: {
        r: 194,
        g: 106,
        b: 90,
      },
      lab: {
        l: 55.55,
        a: 36.73,
        b: 26.21,
      },
      cmyk: null,
    },
    {
      code: '17-1532 TCX',
      name: 'Aragon',
      hex: 'B06454',
      rgb: {
        r: 176,
        g: 100,
        b: 84,
      },
      lab: {
        l: 51.58,
        a: 31.57,
        b: 23.62,
      },
      cmyk: null,
    },
    {
      code: '18-1535 TCX',
      name: 'Ginger Spice',
      hex: 'B65D4B',
      rgb: {
        r: 182,
        g: 93,
        b: 75,
      },
      lab: {
        l: 51.08,
        a: 37.01,
        b: 28.65,
      },
      cmyk: null,
    },
    {
      code: '18-1444 TCX',
      name: 'Tandoori Spice',
      hex: '9F4440',
      rgb: {
        r: 159,
        g: 68,
        b: 64,
      },
      lab: {
        l: 42.14,
        a: 40.23,
        b: 23.34,
      },
      cmyk: null,
    },
    {
      code: '18-1540 TCX',
      name: 'Cinnabar',
      hex: '9C453B',
      rgb: {
        r: 156,
        g: 69,
        b: 59,
      },
      lab: {
        l: 41.71,
        a: 36.97,
        b: 26.14,
      },
      cmyk: null,
    },
    {
      code: '18-1547 TCX',
      name: 'Bossa Nova',
      hex: '973B36',
      rgb: {
        r: 151,
        g: 59,
        b: 54,
      },
      lab: {
        l: 38.84,
        a: 41,
        b: 24.48,
      },
      cmyk: null,
    },
    {
      code: '18-1449 TCX',
      name: 'Ketchup',
      hex: '9A382D',
      rgb: {
        r: 154,
        g: 56,
        b: 45,
      },
      lab: {
        l: 38.77,
        a: 42.84,
        b: 30.34,
      },
      cmyk: null,
    },
    {
      code: '16-1520 TCX',
      name: 'Lobster Bisque',
      hex: 'DD9289',
      rgb: {
        r: 221,
        g: 146,
        b: 137,
      },
      lab: {
        l: 68.58,
        a: 29.62,
        b: 17.85,
      },
      cmyk: null,
    },
    {
      code: '16-1329 TCX',
      name: 'Coral Haze',
      hex: 'E38E84',
      rgb: {
        r: 227,
        g: 142,
        b: 132,
      },
      lab: {
        l: 68.61,
        a: 34.17,
        b: 20.38,
      },
      cmyk: null,
    },
    {
      code: '16-1431 TCX',
      name: 'Canyon Clay',
      hex: 'CE8477',
      rgb: {
        r: 206,
        g: 132,
        b: 119,
      },
      lab: {
        l: 63.37,
        a: 29.43,
        b: 20.48,
      },
      cmyk: null,
    },
    {
      code: '16-1624 TCX',
      name: 'Lantana',
      hex: 'DA7E7A',
      rgb: {
        r: 218,
        g: 126,
        b: 122,
      },
      lab: {
        l: 63.7,
        a: 37.4,
        b: 19.34,
      },
      cmyk: null,
    },
    {
      code: '18-1629 TCX',
      name: 'Faded Rose',
      hex: 'BF6464',
      rgb: {
        r: 191,
        g: 100,
        b: 100,
      },
      lab: {
        l: 54.03,
        a: 38.9,
        b: 18.48,
      },
      cmyk: null,
    },
    {
      code: '18-1630 TCX',
      name: 'Dusty Cedar',
      hex: 'AD5D5D',
      rgb: {
        r: 173,
        g: 93,
        b: 93,
      },
      lab: {
        l: 49.89,
        a: 34.41,
        b: 16.2,
      },
      cmyk: null,
    },
    {
      code: '18-1648 TCX',
      name: 'Baked Apple',
      hex: 'B34646',
      rgb: {
        r: 179,
        g: 70,
        b: 70,
      },
      lab: {
        l: 46.13,
        a: 46.81,
        b: 25.67,
      },
      cmyk: null,
    },
    {
      code: '14-1318 TCX',
      name: 'Coral Pink',
      hex: 'E8A798',
      rgb: {
        r: 232,
        g: 167,
        b: 152,
      },
      lab: {
        l: 75.07,
        a: 25.02,
        b: 18.42,
      },
      cmyk: null,
    },
    {
      code: '15-1523 TCX',
      name: 'Shrimp',
      hex: 'E29A86',
      rgb: {
        r: 226,
        g: 154,
        b: 134,
      },
      lab: {
        l: 70.9,
        a: 27.24,
        b: 22.79,
      },
      cmyk: null,
    },
    {
      code: '17-1341 TCX',
      name: 'Tawny Orange',
      hex: 'D3806F',
      rgb: {
        r: 211,
        g: 128,
        b: 111,
      },
      lab: {
        l: 62.9,
        a: 34.08,
        b: 23.95,
      },
      cmyk: null,
    },
    {
      code: '16-1532 TCX',
      name: 'Crabapple',
      hex: 'D77E70',
      rgb: {
        r: 215,
        g: 126,
        b: 112,
      },
      lab: {
        l: 63.25,
        a: 35.35,
        b: 24.5,
      },
      cmyk: null,
    },
    {
      code: '16-1526 TCX',
      name: 'Terra Cotta',
      hex: 'D38377',
      rgb: {
        r: 211,
        g: 131,
        b: 119,
      },
      lab: {
        l: 63.76,
        a: 32,
        b: 20.92,
      },
      cmyk: null,
    },
    {
      code: '17-1544 TCX',
      name: 'Burnt Sienna',
      hex: 'C65D52',
      rgb: {
        r: 198,
        g: 93,
        b: 82,
      },
      lab: {
        l: 53.75,
        a: 43.65,
        b: 28.62,
      },
      cmyk: null,
    },
    {
      code: '18-1536 TCX',
      name: 'Hot Sauce',
      hex: 'AB4F41',
      rgb: {
        r: 171,
        g: 79,
        b: 65,
      },
      lab: {
        l: 46.32,
        a: 38.6,
        b: 28.44,
      },
      cmyk: null,
    },
    {
      code: '15-1333 TCX',
      name: 'Canyon Sunset',
      hex: 'E1927A',
      rgb: {
        r: 225,
        g: 146,
        b: 122,
      },
      lab: {
        l: 69.03,
        a: 31.01,
        b: 26.34,
      },
      cmyk: null,
    },
    {
      code: '16-1340 TCX',
      name: 'Brandied Melon',
      hex: 'CE7B5B',
      rgb: {
        r: 206,
        g: 123,
        b: 91,
      },
      lab: {
        l: 60.86,
        a: 32.35,
        b: 32.69,
      },
      cmyk: null,
    },
    {
      code: '16-1435 TCX',
      name: 'Carnelian',
      hex: 'CE785D',
      rgb: {
        r: 206,
        g: 120,
        b: 93,
      },
      lab: {
        l: 60.41,
        a: 33.7,
        b: 31,
      },
      cmyk: null,
    },
    {
      code: '15-1429 TCX',
      name: 'Dusted Clay',
      hex: 'CC7457',
      rgb: {
        r: 204,
        g: 116,
        b: 87,
      },
      lab: {
        l: 59.15,
        a: 35.4,
        b: 32.54,
      },
      cmyk: null,
    },
    {
      code: '16-1441 TCX',
      name: 'Arabesque',
      hex: 'D16F52',
      rgb: {
        r: 209,
        g: 111,
        b: 82,
      },
      lab: {
        l: 58.89,
        a: 39.17,
        b: 35.62,
      },
      cmyk: null,
    },
    {
      code: '16-1440 TCX',
      name: 'Langoustino',
      hex: 'CA6C56',
      rgb: {
        r: 202,
        g: 108,
        b: 86,
      },
      lab: {
        l: 57.07,
        a: 38.13,
        b: 30.75,
      },
      cmyk: null,
    },
    {
      code: '17-1446 TCX',
      name: 'Mango',
      hex: 'B75E41',
      rgb: {
        r: 183,
        g: 94,
        b: 65,
      },
      lab: {
        l: 51.22,
        a: 35.48,
        b: 35.35,
      },
      cmyk: null,
    },
    {
      code: '15-1520 TCX',
      name: 'Blooming Dahlia',
      hex: 'EC9688',
      rgb: {
        r: 236,
        g: 150,
        b: 136,
      },
      lab: {
        l: 71.42,
        a: 33.47,
        b: 22.62,
      },
      cmyk: null,
    },
    {
      code: '16-1529 TCX',
      name: 'Burnt Coral',
      hex: 'E9897E',
      rgb: {
        r: 233,
        g: 137,
        b: 126,
      },
      lab: {
        l: 68.03,
        a: 38.44,
        b: 23.61,
      },
      cmyk: null,
    },
    {
      code: '16-1345 TCX',
      name: 'Sun Baked',
      hex: 'D38063',
      rgb: {
        r: 211,
        g: 128,
        b: 99,
      },
      lab: {
        l: 62.8,
        a: 32.37,
        b: 30.55,
      },
      cmyk: null,
    },
    {
      code: '17-1345 TCX',
      name: 'Spice Route',
      hex: 'BA5B3F',
      rgb: {
        r: 186,
        g: 91,
        b: 63,
      },
      lab: {
        l: 51.07,
        a: 39.2,
        b: 35.3,
      },
      cmyk: null,
    },
    {
      code: '17-1444 TCX',
      name: 'Ginger',
      hex: 'C96551',
      rgb: {
        r: 201,
        g: 101,
        b: 81,
      },
      lab: {
        l: 55.51,
        a: 40.94,
        b: 31.71,
      },
      cmyk: null,
    },
    {
      code: '16-1450 TCX',
      name: 'Flamingo',
      hex: 'DF7253',
      rgb: {
        r: 223,
        g: 114,
        b: 83,
      },
      lab: {
        l: 61.51,
        a: 42.72,
        b: 38.65,
      },
      cmyk: null,
    },
    {
      code: '18-1354 TCX',
      name: 'Burnt Ochre',
      hex: 'BB4F35',
      rgb: {
        r: 187,
        g: 79,
        b: 53,
      },
      lab: {
        l: 49.04,
        a: 45.21,
        b: 38.69,
      },
      cmyk: null,
    },
    {
      code: '15-1433 TCX',
      name: 'Papaya Punch',
      hex: 'FCA289',
      rgb: {
        r: 252,
        g: 162,
        b: 137,
      },
      lab: {
        l: 75.96,
        a: 33.21,
        b: 28.45,
      },
      cmyk: null,
    },
    {
      code: '14-1228 TCX',
      name: 'Peach Nectar',
      hex: 'FFB59B',
      rgb: {
        r: 255,
        g: 181,
        b: 155,
      },
      lab: {
        l: 81.9,
        a: 29.02,
        b: 27.59,
      },
      cmyk: null,
    },
    {
      code: '15-1331 TCX',
      name: 'Coral Reef',
      hex: 'FAA181',
      rgb: {
        r: 250,
        g: 161,
        b: 129,
      },
      lab: {
        l: 75.43,
        a: 33.05,
        b: 31.95,
      },
      cmyk: null,
    },
    {
      code: '15-1530 TCX',
      name: 'Peach Pink',
      hex: 'FA9A85',
      rgb: {
        r: 250,
        g: 154,
        b: 133,
      },
      lab: {
        l: 74.06,
        a: 36.77,
        b: 28.14,
      },
      cmyk: null,
    },
    {
      code: '15-1340 TCX',
      name: 'Cadmium Orange',
      hex: 'F99471',
      rgb: {
        r: 249,
        g: 148,
        b: 113,
      },
      lab: {
        l: 72.41,
        a: 38.41,
        b: 36.18,
      },
      cmyk: null,
    },
    {
      code: '15-1239 TCX',
      name: 'Cantaloupe',
      hex: 'FFA177',
      rgb: {
        r: 255,
        g: 161,
        b: 119,
      },
      lab: {
        l: 77.13,
        a: 36.66,
        b: 40.04,
      },
      cmyk: null,
    },
    {
      code: '16-1442 TCX',
      name: 'Melon',
      hex: 'FE8863',
      rgb: {
        r: 254,
        g: 136,
        b: 99,
      },
      lab: {
        l: 70.55,
        a: 45.07,
        b: 41.66,
      },
      cmyk: null,
    },
    {
      code: '12-0915 TCX',
      name: 'Pale Peach',
      hex: 'FED1BD',
      rgb: {
        r: 254,
        g: 209,
        b: 189,
      },
      lab: {
        l: 87.76,
        a: 14.32,
        b: 17,
      },
      cmyk: null,
    },
    {
      code: '12-0917 TCX',
      name: 'Bleached Apricot',
      hex: 'FCCAAC',
      rgb: {
        r: 252,
        g: 202,
        b: 172,
      },
      lab: {
        l: 85.66,
        a: 16.09,
        b: 22.52,
      },
      cmyk: null,
    },
    {
      code: '14-1219 TCX',
      name: 'Peach Parfait',
      hex: 'F8BFA8',
      rgb: {
        r: 248,
        g: 191,
        b: 168,
      },
      lab: {
        l: 82.35,
        a: 19.86,
        b: 20.41,
      },
      cmyk: null,
    },
    {
      code: '13-1021 TCX',
      name: 'Prairie Sunset',
      hex: 'FFBB9E',
      rgb: {
        r: 255,
        g: 187,
        b: 158,
      },
      lab: {
        l: 82.33,
        a: 24.27,
        b: 25.8,
      },
      cmyk: null,
    },
    {
      code: '14-1323 TCX',
      name: 'Salmon',
      hex: 'FAAA94',
      rgb: {
        r: 250,
        g: 170,
        b: 148,
      },
      lab: {
        l: 77.57,
        a: 29.73,
        b: 24.48,
      },
      cmyk: null,
    },
    {
      code: '14-1227 TCX',
      name: 'Peach',
      hex: 'F2A987',
      rgb: {
        r: 242,
        g: 169,
        b: 135,
      },
      lab: {
        l: 76.3,
        a: 26.1,
        b: 29.23,
      },
      cmyk: null,
    },
    {
      code: '15-1334 TCX',
      name: 'Shell Coral',
      hex: 'EA9575',
      rgb: {
        r: 234,
        g: 149,
        b: 117,
      },
      lab: {
        l: 70.81,
        a: 31.9,
        b: 31.76,
      },
      cmyk: null,
    },
    {
      code: '13-1023 TCX',
      name: 'Peach Fuzz',
      hex: 'FFBE98',
      rgb: {
        r: 255,
        g: 190,
        b: 152,
      },
      lab: {
        l: 83.16,
        a: 22.4,
        b: 30.15,
      },
      cmyk: null,
    },
    {
      code: '14-1225 TCX',
      name: 'Beach Sand',
      hex: 'FBB995',
      rgb: {
        r: 251,
        g: 185,
        b: 149,
      },
      lab: {
        l: 81.26,
        a: 22.5,
        b: 28.67,
      },
      cmyk: null,
    },
    {
      code: '14-1230 TCX',
      name: 'Apricot Wash',
      hex: 'FBAC82',
      rgb: {
        r: 251,
        g: 172,
        b: 130,
      },
      lab: {
        l: 78,
        a: 27.38,
        b: 34.46,
      },
      cmyk: null,
    },
    {
      code: '14-1231 TCX',
      name: 'Peach Cobbler',
      hex: 'FFB181',
      rgb: {
        r: 255,
        g: 177,
        b: 129,
      },
      lab: {
        l: 80.14,
        a: 28.16,
        b: 38.11,
      },
      cmyk: null,
    },
    {
      code: '14-1241 TCX',
      name: 'Orange Chiffon',
      hex: 'FAAB7E',
      rgb: {
        r: 250,
        g: 171,
        b: 126,
      },
      lab: {
        l: 77.6,
        a: 27.44,
        b: 36.34,
      },
      cmyk: null,
    },
    {
      code: '14-1135 TCX',
      name: 'Salmon Buff',
      hex: 'FEAA7B',
      rgb: {
        r: 254,
        g: 170,
        b: 123,
      },
      lab: {
        l: 77.78,
        a: 29.62,
        b: 37.91,
      },
      cmyk: null,
    },
    {
      code: '14-1139 TCX',
      name: 'Pumpkin',
      hex: 'F5A26F',
      rgb: {
        r: 245,
        g: 162,
        b: 111,
      },
      lab: {
        l: 74.78,
        a: 29.64,
        b: 40.43,
      },
      cmyk: null,
    },
    {
      code: '13-1026 TCX',
      name: 'Creampuff',
      hex: 'FFCDA8',
      rgb: {
        r: 255,
        g: 205,
        b: 168,
      },
      lab: {
        l: 86.78,
        a: 16.85,
        b: 26.49,
      },
      cmyk: null,
    },
    {
      code: '13-1020 TCX',
      name: 'Apricot Ice',
      hex: 'FBBE99',
      rgb: {
        r: 251,
        g: 190,
        b: 153,
      },
      lab: {
        l: 82.44,
        a: 20.14,
        b: 28.15,
      },
      cmyk: null,
    },
    {
      code: '13-1022 TCX',
      name: 'Caramel Cream',
      hex: 'F4BA94',
      rgb: {
        r: 244,
        g: 186,
        b: 148,
      },
      lab: {
        l: 80.51,
        a: 19.35,
        b: 28.2,
      },
      cmyk: null,
    },
    {
      code: '13-1125 TCX',
      name: 'Peach Quartz',
      hex: 'F5B895',
      rgb: {
        r: 245,
        g: 184,
        b: 149,
      },
      lab: {
        l: 80.42,
        a: 20.77,
        b: 27.4,
      },
      cmyk: null,
    },
    {
      code: '14-1224 TCX',
      name: 'Coral Sands',
      hex: 'EDAA86',
      rgb: {
        r: 237,
        g: 170,
        b: 134,
      },
      lab: {
        l: 76.01,
        a: 23.49,
        b: 29.32,
      },
      cmyk: null,
    },
    {
      code: '16-1338 TCX',
      name: 'Copper Tan',
      hex: 'DE8E65',
      rgb: {
        r: 222,
        g: 142,
        b: 101,
      },
      lab: {
        l: 67.44,
        a: 29.13,
        b: 36.08,
      },
      cmyk: null,
    },
    {
      code: '16-1337 TCX',
      name: 'Coral Gold',
      hex: 'D27D56',
      rgb: {
        r: 210,
        g: 125,
        b: 86,
      },
      lab: {
        l: 62,
        a: 32.53,
        b: 36.63,
      },
      cmyk: null,
    },
    {
      code: '13-1017 TCX',
      name: 'Almond Cream',
      hex: 'F4C29F',
      rgb: {
        r: 244,
        g: 194,
        b: 159,
      },
      lab: {
        l: 82.54,
        a: 16.53,
        b: 24.97,
      },
      cmyk: null,
    },
    {
      code: '13-1019 TCX',
      name: 'Cream Blush',
      hex: 'F8C19A',
      rgb: {
        r: 248,
        g: 193,
        b: 154,
      },
      lab: {
        l: 82.66,
        a: 17.66,
        b: 28.11,
      },
      cmyk: null,
    },
    {
      code: '14-1133 TCX',
      name: 'Apricot Nectar',
      hex: 'ECAA79',
      rgb: {
        r: 236,
        g: 170,
        b: 121,
      },
      lab: {
        l: 75.71,
        a: 21.91,
        b: 36.08,
      },
      cmyk: null,
    },
    {
      code: '15-1234 TCX',
      name: 'Gold Earth',
      hex: 'DD9C6B',
      rgb: {
        r: 221,
        g: 156,
        b: 107,
      },
      lab: {
        l: 70.45,
        a: 22.17,
        b: 36.19,
      },
      cmyk: null,
    },
    {
      code: '15-1237 TCX',
      name: 'Apricot Tan',
      hex: 'DD9760',
      rgb: {
        r: 221,
        g: 151,
        b: 96,
      },
      lab: {
        l: 69.15,
        a: 24.28,
        b: 40.21,
      },
      cmyk: null,
    },
    {
      code: '16-1346 TCX',
      name: 'Golden Ochre',
      hex: 'C77943',
      rgb: {
        r: 199,
        g: 121,
        b: 67,
      },
      lab: {
        l: 59.34,
        a: 29.32,
        b: 43.27,
      },
      cmyk: null,
    },
    {
      code: '16-1443 TCX',
      name: 'Apricot Buff',
      hex: 'CD7E4D',
      rgb: {
        r: 205,
        g: 126,
        b: 77,
      },
      lab: {
        l: 61.39,
        a: 29.67,
        b: 40.71,
      },
      cmyk: null,
    },
    {
      code: '16-1347 TCX',
      name: 'Peach Caramel',
      hex: 'C6733D',
      rgb: {
        r: 198,
        g: 115,
        b: 61,
      },
      lab: {
        l: 57.87,
        a: 31.59,
        b: 44.48,
      },
      cmyk: null,
    },
    {
      code: '16-1348 TCX',
      name: 'Tomato Cream',
      hex: 'C57644',
      rgb: {
        r: 197,
        g: 118,
        b: 68,
      },
      lab: {
        l: 58.44,
        a: 30.2,
        b: 41.53,
      },
      cmyk: null,
    },
    {
      code: '18-1248 TCX',
      name: 'Rust',
      hex: 'B55A30',
      rgb: {
        r: 181,
        g: 90,
        b: 48,
      },
      lab: {
        l: 49.9,
        a: 35.71,
        b: 42.47,
      },
      cmyk: null,
    },
    {
      code: '16-1448 TCX',
      name: 'Burnt Orange',
      hex: 'C86733',
      rgb: {
        r: 200,
        g: 103,
        b: 51,
      },
      lab: {
        l: 55.57,
        a: 37.8,
        b: 47.75,
      },
      cmyk: null,
    },
    {
      code: '16-1449 TCX',
      name: 'Gold Flame',
      hex: 'B45422',
      rgb: {
        r: 180,
        g: 84,
        b: 34,
      },
      lab: {
        l: 48.4,
        a: 38.45,
        b: 47.58,
      },
      cmyk: null,
    },
    {
      code: '17-1353 TCX',
      name: 'Apricot Orange',
      hex: 'C96B3C',
      rgb: {
        r: 201,
        g: 107,
        b: 60,
      },
      lab: {
        l: 56.44,
        a: 36.77,
        b: 43.94,
      },
      cmyk: null,
    },
    {
      code: '16-1260 TCX',
      name: 'Harvest Pumpkin',
      hex: 'D56231',
      rgb: {
        r: 213,
        g: 98,
        b: 49,
      },
      lab: {
        l: 56.71,
        a: 46.35,
        b: 50.05,
      },
      cmyk: null,
    },
    {
      code: '15-1242 TCX',
      name: 'Muskmelon',
      hex: 'EC935E',
      rgb: {
        r: 236,
        g: 147,
        b: 94,
      },
      lab: {
        l: 70.17,
        a: 32.17,
        b: 43.21,
      },
      cmyk: null,
    },
    {
      code: '16-1350 TCX',
      name: 'Amberglow',
      hex: 'DC793E',
      rgb: {
        r: 220,
        g: 121,
        b: 62,
      },
      lab: {
        l: 62.18,
        a: 37.64,
        b: 49.7,
      },
      cmyk: null,
    },
    {
      code: '16-1253 TCX',
      name: 'Orange Ochre',
      hex: 'DC793A',
      rgb: {
        r: 220,
        g: 121,
        b: 58,
      },
      lab: {
        l: 62.14,
        a: 37.58,
        b: 51.82,
      },
      cmyk: null,
    },
    {
      code: '16-1454 TCX',
      name: 'Jaffa Orange',
      hex: 'D86E39',
      rgb: {
        r: 216,
        g: 110,
        b: 57,
      },
      lab: {
        l: 59.43,
        a: 42.36,
        b: 49.24,
      },
      cmyk: null,
    },
    {
      code: '17-1145 TCX',
      name: 'Autumn Maple',
      hex: 'C46316',
      rgb: {
        r: 196,
        g: 99,
        b: 22,
      },
      lab: {
        l: 54.07,
        a: 38.08,
        b: 57.82,
      },
      cmyk: null,
    },
    {
      code: '17-1140 TCX',
      name: 'Marmalade',
      hex: 'C16512',
      rgb: {
        r: 193,
        g: 101,
        b: 18,
      },
      lab: {
        l: 53.97,
        a: 34.72,
        b: 58.78,
      },
      cmyk: null,
    },
    {
      code: '18-1249 TCX',
      name: 'Hawaiian Sunset',
      hex: 'BB5C14',
      rgb: {
        r: 187,
        g: 92,
        b: 20,
      },
      lab: {
        l: 51.04,
        a: 37.29,
        b: 55.75,
      },
      cmyk: null,
    },
    {
      code: '15-1245 TCX',
      name: 'Mock Orange',
      hex: 'FFA368',
      rgb: {
        r: 255,
        g: 163,
        b: 104,
      },
      lab: {
        l: 76.98,
        a: 34.2,
        b: 47.28,
      },
      cmyk: null,
    },
    {
      code: '15-1243 TCX',
      name: 'Papaya',
      hex: 'FFA266',
      rgb: {
        r: 255,
        g: 162,
        b: 102,
      },
      lab: {
        l: 77.21,
        a: 36.03,
        b: 48.59,
      },
      cmyk: null,
    },
    {
      code: '15-1247 TCX',
      name: 'Tangerine',
      hex: 'F88F58',
      rgb: {
        r: 248,
        g: 143,
        b: 88,
      },
      lab: {
        l: 71.03,
        a: 38.94,
        b: 47.72,
      },
      cmyk: null,
    },
    {
      code: '16-1257 TCX',
      name: 'Sun Orange',
      hex: 'F48037',
      rgb: {
        r: 244,
        g: 128,
        b: 55,
      },
      lab: {
        l: 67.22,
        a: 43.43,
        b: 59.11,
      },
      cmyk: null,
    },
    {
      code: '15-1150 TCX',
      name: 'Dark Cheddar',
      hex: 'E08119',
      rgb: {
        r: 224,
        g: 129,
        b: 25,
      },
      lab: {
        l: 64.27,
        a: 33.75,
        b: 66.3,
      },
      cmyk: null,
    },
    {
      code: '16-1164 TCX',
      name: 'Orange Pepper',
      hex: 'DF7500',
      rgb: {
        r: 223,
        g: 117,
        b: 0,
      },
      lab: {
        l: 61.71,
        a: 39.03,
        b: 71.4,
      },
      cmyk: null,
    },
    {
      code: '16-1255 TCX',
      name: 'Russet Orange',
      hex: 'E47127',
      rgb: {
        r: 228,
        g: 113,
        b: 39,
      },
      lab: {
        l: 61.68,
        a: 44.66,
        b: 60.05,
      },
      cmyk: null,
    },
    {
      code: '15-1160 TCX',
      name: 'Blazing Orange',
      hex: 'FFA74F',
      rgb: {
        r: 255,
        g: 167,
        b: 79,
      },
      lab: {
        l: 78.78,
        a: 34.62,
        b: 61.55,
      },
      cmyk: null,
    },
    {
      code: '15-1153 TCX',
      name: 'Apricot',
      hex: 'F19035',
      rgb: {
        r: 241,
        g: 144,
        b: 53,
      },
      lab: {
        l: 69.84,
        a: 34.42,
        b: 62.07,
      },
      cmyk: null,
    },
    {
      code: '15-1151 TCX',
      name: 'Iceland Poppy',
      hex: 'F4973B',
      rgb: {
        r: 244,
        g: 151,
        b: 59,
      },
      lab: {
        l: 71.8,
        a: 32.48,
        b: 61.89,
      },
      cmyk: null,
    },
    {
      code: '15-1157 TCX',
      name: 'Flame Orange',
      hex: 'FB8B23',
      rgb: {
        r: 251,
        g: 139,
        b: 35,
      },
      lab: {
        l: 70.23,
        a: 40.68,
        b: 69.79,
      },
      cmyk: null,
    },
    {
      code: '15-1164 TCX',
      name: 'Bright Marigold',
      hex: 'FF8D00',
      rgb: {
        r: 255,
        g: 141,
        b: 0,
      },
      lab: {
        l: 72.44,
        a: 43.06,
        b: 85.9,
      },
      cmyk: null,
    },
    {
      code: '15-1262 TCX',
      name: 'Carrot Curl',
      hex: 'FF8D19',
      rgb: {
        r: 255,
        g: 141,
        b: 25,
      },
      lab: {
        l: 72.66,
        a: 43.91,
        b: 75.83,
      },
      cmyk: null,
    },
    {
      code: '15-1263 TCX',
      name: 'Autumn Glory',
      hex: 'FF8813',
      rgb: {
        r: 255,
        g: 136,
        b: 19,
      },
      lab: {
        l: 71.6,
        a: 46.25,
        b: 76.32,
      },
      cmyk: null,
    },
    {
      code: '15-1264 TCX',
      name: 'Turmeric',
      hex: 'FF850F',
      rgb: {
        r: 255,
        g: 133,
        b: 15,
      },
      lab: {
        l: 71.47,
        a: 48.09,
        b: 77.37,
      },
      cmyk: null,
    },
    {
      code: '15-1335 TCX',
      name: 'Tangelo',
      hex: 'FF7E04',
      rgb: {
        r: 255,
        g: 126,
        b: 4,
      },
      lab: {
        l: 70.37,
        a: 51.33,
        b: 78.52,
      },
      cmyk: null,
    },
    {
      code: '17-1350 TCX',
      name: 'Oriole',
      hex: 'FF7913',
      rgb: {
        r: 255,
        g: 121,
        b: 19,
      },
      lab: {
        l: 68.33,
        a: 51.45,
        b: 73.86,
      },
      cmyk: null,
    },
    {
      code: '16-1364 TCX',
      name: 'Vibrant Orange',
      hex: 'FF7420',
      rgb: {
        r: 255,
        g: 116,
        b: 32,
      },
      lab: {
        l: 67.82,
        a: 54.55,
        b: 70.84,
      },
      cmyk: null,
    },
    {
      code: '16-1356 TCX',
      name: 'Persimmon Orange',
      hex: 'F47327',
      rgb: {
        r: 244,
        g: 115,
        b: 39,
      },
      lab: {
        l: 64.66,
        a: 49.55,
        b: 63.55,
      },
      cmyk: null,
    },
    {
      code: '16-1358 TCX',
      name: 'Orange Tiger',
      hex: 'F96815',
      rgb: {
        r: 249,
        g: 104,
        b: 21,
      },
      lab: {
        l: 63.56,
        a: 55.12,
        b: 69.94,
      },
      cmyk: null,
    },
    {
      code: '17-1349 TCX',
      name: 'Exuberance',
      hex: 'E86800',
      rgb: {
        r: 232,
        g: 104,
        b: 0,
      },
      lab: {
        l: 60.65,
        a: 48.38,
        b: 72.54,
      },
      cmyk: null,
    },
    {
      code: '16-1343 TCX',
      name: 'Autumn Sunset',
      hex: 'F38554',
      rgb: {
        r: 243,
        g: 133,
        b: 84,
      },
      lab: {
        l: 68.28,
        a: 42.01,
        b: 46.78,
      },
      cmyk: null,
    },
    {
      code: '16-1344 TCX',
      name: 'Dusty Orange',
      hex: 'E27A53',
      rgb: {
        r: 226,
        g: 122,
        b: 83,
      },
      lab: {
        l: 63.68,
        a: 40.98,
        b: 40.83,
      },
      cmyk: null,
    },
    {
      code: '16-1359 TCX',
      name: 'Orange Peel',
      hex: 'FA7A35',
      rgb: {
        r: 250,
        g: 122,
        b: 53,
      },
      lab: {
        l: 67,
        a: 48.61,
        b: 60.39,
      },
      cmyk: null,
    },
    {
      code: '17-1360 TCX',
      name: 'Celosia Orange',
      hex: 'E8703A',
      rgb: {
        r: 232,
        g: 112,
        b: 58,
      },
      lab: {
        l: 62.31,
        a: 45.91,
        b: 52.72,
      },
      cmyk: null,
    },
    {
      code: '16-1459 TCX',
      name: 'Mandarin Orange',
      hex: 'EC6A37',
      rgb: {
        r: 236,
        g: 106,
        b: 55,
      },
      lab: {
        l: 61.88,
        a: 51.41,
        b: 54.11,
      },
      cmyk: null,
    },
    {
      code: '16-1462 TCX',
      name: 'Golden Poppy',
      hex: 'F56733',
      rgb: {
        r: 245,
        g: 103,
        b: 51,
      },
      lab: {
        l: 62.97,
        a: 55.27,
        b: 57.8,
      },
      cmyk: null,
    },
    {
      code: '16-1453 TCX',
      name: 'Exotic Orange',
      hex: 'FA6632',
      rgb: {
        r: 250,
        g: 102,
        b: 50,
      },
      lab: {
        l: 63.49,
        a: 56.45,
        b: 59.65,
      },
      cmyk: null,
    },
    {
      code: '16-1357 TCX',
      name: 'Bird of Paradise',
      hex: 'FF8C55',
      rgb: {
        r: 255,
        g: 140,
        b: 85,
      },
      lab: {
        l: 72.46,
        a: 44.81,
        b: 52.2,
      },
      cmyk: null,
    },
    {
      code: '16-1360 TCX',
      name: 'Nectarine',
      hex: 'FF8656',
      rgb: {
        r: 255,
        g: 134,
        b: 86,
      },
      lab: {
        l: 71.52,
        a: 47.95,
        b: 50.71,
      },
      cmyk: null,
    },
    {
      code: '16-1349 TCX',
      name: 'Coral Rose',
      hex: 'F3774D',
      rgb: {
        r: 243,
        g: 119,
        b: 77,
      },
      lab: {
        l: 65.48,
        a: 48.07,
        b: 47.4,
      },
      cmyk: null,
    },
    {
      code: '16-1361 TCX',
      name: 'Carrot',
      hex: 'FD6F3B',
      rgb: {
        r: 253,
        g: 111,
        b: 59,
      },
      lab: {
        l: 65.65,
        a: 54.42,
        b: 57.55,
      },
      cmyk: null,
    },
    {
      code: '17-1361 TCX',
      name: 'Scarlet Ibis',
      hex: 'F45720',
      rgb: {
        r: 244,
        g: 87,
        b: 32,
      },
      lab: {
        l: 60.11,
        a: 61.28,
        b: 63.13,
      },
      cmyk: null,
    },
    {
      code: '16-1363 TCX',
      name: "Puffin's Bill",
      hex: 'E95C20',
      rgb: {
        r: 233,
        g: 92,
        b: 32,
      },
      lab: {
        l: 58.99,
        a: 55.12,
        b: 61.27,
      },
      cmyk: null,
    },
    {
      code: '16-1460 TCX',
      name: 'Dragon Fire',
      hex: 'FD652D',
      rgb: {
        r: 253,
        g: 101,
        b: 45,
      },
      lab: {
        l: 63.83,
        a: 58.29,
        b: 61.78,
      },
      cmyk: null,
    },
    {
      code: '16-1362 TCX',
      name: 'Vermillion Orange',
      hex: 'F9633B',
      rgb: {
        r: 249,
        g: 99,
        b: 59,
      },
      lab: {
        l: 62.83,
        a: 58.18,
        b: 54.78,
      },
      cmyk: null,
    },
    {
      code: '16-1452 TCX',
      name: 'Firecracker',
      hex: 'F36944',
      rgb: {
        r: 243,
        g: 105,
        b: 68,
      },
      lab: {
        l: 63.06,
        a: 54.36,
        b: 49.22,
      },
      cmyk: null,
    },
    {
      code: '17-1452 TCX',
      name: 'Koi',
      hex: 'D15837',
      rgb: {
        r: 209,
        g: 88,
        b: 55,
      },
      lab: {
        l: 54.35,
        a: 48.45,
        b: 44.95,
      },
      cmyk: null,
    },
    {
      code: '17-1462 TCX',
      name: 'Flame',
      hex: 'F2552C',
      rgb: {
        r: 242,
        g: 85,
        b: 44,
      },
      lab: {
        l: 59.56,
        a: 61.05,
        b: 57.92,
      },
      cmyk: null,
    },
    {
      code: '17-1461 TCX',
      name: 'Orangeade',
      hex: 'E2552D',
      rgb: {
        r: 226,
        g: 85,
        b: 45,
      },
      lab: {
        l: 56.68,
        a: 55.55,
        b: 53.66,
      },
      cmyk: null,
    },
    {
      code: '17-1464 TCX',
      name: 'Red Orange',
      hex: 'F05627',
      rgb: {
        r: 240,
        g: 86,
        b: 39,
      },
      lab: {
        l: 59.31,
        a: 59.98,
        b: 59.55,
      },
      cmyk: null,
    },
    {
      code: '17-1463 TCX',
      name: 'Tangerine Tango',
      hex: 'DD4124',
      rgb: {
        r: 221,
        g: 65,
        b: 36,
      },
      lab: {
        l: 52.91,
        a: 61.7,
        b: 53.9,
      },
      cmyk: null,
    },
    {
      code: '17-1456 TCX',
      name: 'Tigerlily',
      hex: 'E2583E',
      rgb: {
        r: 226,
        g: 88,
        b: 62,
      },
      lab: {
        l: 57.25,
        a: 55.46,
        b: 45.29,
      },
      cmyk: null,
    },
    {
      code: '17-1450 TCX',
      name: 'Summer Fig',
      hex: 'BE4B3B',
      rgb: {
        r: 190,
        g: 75,
        b: 59,
      },
      lab: {
        l: 48.95,
        a: 48.02,
        b: 35.79,
      },
      cmyk: null,
    },
    {
      code: '18-1448 TCX',
      name: 'Chili',
      hex: 'BE5141',
      rgb: {
        r: 190,
        g: 81,
        b: 65,
      },
      lab: {
        l: 49.84,
        a: 45.61,
        b: 33.55,
      },
      cmyk: null,
    },
    {
      code: '18-1447 TCX',
      name: 'Orange Rust',
      hex: 'C25A3C',
      rgb: {
        r: 194,
        g: 90,
        b: 60,
      },
      lab: {
        l: 52.19,
        a: 42.09,
        b: 39.54,
      },
      cmyk: null,
    },
    {
      code: '17-1553 TCX',
      name: 'Paprika',
      hex: 'CE4D42',
      rgb: {
        r: 206,
        g: 77,
        b: 66,
      },
      lab: {
        l: 52.13,
        a: 53.31,
        b: 36.24,
      },
      cmyk: null,
    },
    {
      code: '18-1454 TCX',
      name: 'Red Clay',
      hex: 'C2452D',
      rgb: {
        r: 194,
        g: 69,
        b: 45,
      },
      lab: {
        l: 48.34,
        a: 51.16,
        b: 43.34,
      },
      cmyk: null,
    },
    {
      code: '17-1449 TCX',
      name: 'Pureed Pumpkin',
      hex: 'C34121',
      rgb: {
        r: 195,
        g: 65,
        b: 33,
      },
      lab: {
        l: 47.93,
        a: 53.19,
        b: 49.16,
      },
      cmyk: null,
    },
    {
      code: '18-1445 TCX',
      name: 'Spicy Orange',
      hex: 'D73D26',
      rgb: {
        r: 215,
        g: 61,
        b: 38,
      },
      lab: {
        l: 51.3,
        a: 62.1,
        b: 51.13,
      },
      cmyk: null,
    },
    {
      code: '17-1558 TCX',
      name: 'Grenadine',
      hex: 'DF3F32',
      rgb: {
        r: 223,
        g: 63,
        b: 50,
      },
      lab: {
        l: 53.14,
        a: 63.86,
        b: 47.73,
      },
      cmyk: null,
    },
    {
      code: '18-1561 TCX',
      name: 'Orange.com',
      hex: 'DA321C',
      rgb: {
        r: 218,
        g: 50,
        b: 28,
      },
      lab: {
        l: 50.55,
        a: 65.96,
        b: 55.48,
      },
      cmyk: null,
    },
    {
      code: '17-1563 TCX',
      name: 'Cherry Tomato',
      hex: 'EB3C27',
      rgb: {
        r: 235,
        g: 60,
        b: 39,
      },
      lab: {
        l: 55.15,
        a: 67.88,
        b: 56.08,
      },
      cmyk: null,
    },
    {
      code: '17-1564 TCX',
      name: 'Fiesta',
      hex: 'DD4132',
      rgb: {
        r: 221,
        g: 65,
        b: 50,
      },
      lab: {
        l: 52.97,
        a: 62.32,
        b: 47.26,
      },
      cmyk: null,
    },
    {
      code: '18-1564 TCX',
      name: 'Poinciana',
      hex: 'CA3422',
      rgb: {
        r: 202,
        g: 52,
        b: 34,
      },
      lab: {
        l: 47.61,
        a: 60.01,
        b: 49.05,
      },
      cmyk: null,
    },
    {
      code: '18-1550 TCX',
      name: 'Aurora Red',
      hex: 'B93A32',
      rgb: {
        r: 185,
        g: 58,
        b: 50,
      },
      lab: {
        l: 45.22,
        a: 52.77,
        b: 37.14,
      },
      cmyk: null,
    },
    {
      code: '16-1544 TCX',
      name: 'Persimmon',
      hex: 'F67866',
      rgb: {
        r: 246,
        g: 120,
        b: 102,
      },
      lab: {
        l: 66.43,
        a: 49.91,
        b: 35.4,
      },
      cmyk: null,
    },
    {
      code: '16-1541 TCX',
      name: 'Camellia',
      hex: 'F6745F',
      rgb: {
        r: 246,
        g: 116,
        b: 95,
      },
      lab: {
        l: 65.56,
        a: 51.39,
        b: 38.15,
      },
      cmyk: null,
    },
    {
      code: '17-1547 TCX',
      name: 'Emberglow',
      hex: 'EA6759',
      rgb: {
        r: 234,
        g: 103,
        b: 89,
      },
      lab: {
        l: 61.32,
        a: 53.14,
        b: 35.42,
      },
      cmyk: null,
    },
    {
      code: '16-1451 TCX',
      name: 'Nasturtium',
      hex: 'FE6347',
      rgb: {
        r: 254,
        g: 99,
        b: 71,
      },
      lab: {
        l: 64.02,
        a: 60.14,
        b: 49.9,
      },
      cmyk: null,
    },
    {
      code: '17-1562 TCX',
      name: 'Mandarin Red',
      hex: 'E74A33',
      rgb: {
        r: 231,
        g: 74,
        b: 51,
      },
      lab: {
        l: 56.18,
        a: 62.35,
        b: 50.91,
      },
      cmyk: null,
    },
    {
      code: '18-1664 TCX',
      name: 'Fiery Red',
      hex: 'D01C1F',
      rgb: {
        r: 208,
        g: 28,
        b: 31,
      },
      lab: {
        l: 46.83,
        a: 68.62,
        b: 50.71,
      },
      cmyk: null,
    },
    {
      code: '18-1549 TCX',
      name: 'Valiant Poppy',
      hex: 'BD332D',
      rgb: {
        r: 189,
        g: 51,
        b: 45,
      },
      lab: {
        l: 45,
        a: 57.28,
        b: 39.7,
      },
      cmyk: null,
    },
    {
      code: '16-1543 TCX',
      name: 'Fusion Coral',
      hex: 'FF8576',
      rgb: {
        r: 255,
        g: 133,
        b: 118,
      },
      lab: {
        l: 71.37,
        a: 49.08,
        b: 33.54,
      },
      cmyk: null,
    },
    {
      code: '16-1548 TCX',
      name: 'Peach Echo',
      hex: 'F8786B',
      rgb: {
        r: 248,
        g: 120,
        b: 107,
      },
      lab: {
        l: 66.84,
        a: 50.32,
        b: 32.94,
      },
      cmyk: null,
    },
    {
      code: '16-1539 TCX',
      name: 'Coral',
      hex: 'ED7464',
      rgb: {
        r: 237,
        g: 116,
        b: 100,
      },
      lab: {
        l: 64.37,
        a: 48.56,
        b: 33.31,
      },
      cmyk: null,
    },
    {
      code: '16-1542 TCX',
      name: 'Fresh Salmon',
      hex: 'FF7F6A',
      rgb: {
        r: 255,
        g: 127,
        b: 106,
      },
      lab: {
        l: 69.98,
        a: 51.47,
        b: 38.46,
      },
      cmyk: null,
    },
    {
      code: '16-1545 TCX',
      name: 'Coral Quartz',
      hex: 'F77565',
      rgb: {
        r: 247,
        g: 117,
        b: 101,
      },
      lab: {
        l: 65.94,
        a: 51.51,
        b: 35.32,
      },
      cmyk: null,
    },
    {
      code: '16-1546 TCX',
      name: 'Living Coral',
      hex: 'FF6F61',
      rgb: {
        r: 255,
        g: 111,
        b: 97,
      },
      lab: {
        l: 66.46,
        a: 56.84,
        b: 38.93,
      },
      cmyk: null,
    },
    {
      code: '17-1656 TCX',
      name: 'Hot Coral',
      hex: 'F35B54',
      rgb: {
        r: 243,
        g: 91,
        b: 84,
      },
      lab: {
        l: 60.94,
        a: 60.17,
        b: 39.21,
      },
      cmyk: null,
    },
    {
      code: '15-1435 TCX',
      name: 'Desert Flower',
      hex: 'FF9687',
      rgb: {
        r: 255,
        g: 150,
        b: 135,
      },
      lab: {
        l: 74.74,
        a: 41.94,
        b: 28.22,
      },
      cmyk: null,
    },
    {
      code: '16-1641 TCX',
      name: 'Georgia Peach',
      hex: 'F97272',
      rgb: {
        r: 249,
        g: 114,
        b: 114,
      },
      lab: {
        l: 66,
        a: 54.13,
        b: 28.45,
      },
      cmyk: null,
    },
    {
      code: '16-1640 TCX',
      name: 'Sugar Coral',
      hex: 'F56C73',
      rgb: {
        r: 245,
        g: 108,
        b: 115,
      },
      lab: {
        l: 64.41,
        a: 55.12,
        b: 25.6,
      },
      cmyk: null,
    },
    {
      code: '17-1647 TCX',
      name: 'Dubarry',
      hex: 'F25F66',
      rgb: {
        r: 242,
        g: 95,
        b: 102,
      },
      lab: {
        l: 61.68,
        a: 58.93,
        b: 29.78,
      },
      cmyk: null,
    },
    {
      code: '17-1643 TCX',
      name: 'Porcelain Rose',
      hex: 'EA6B6A',
      rgb: {
        r: 234,
        g: 107,
        b: 106,
      },
      lab: {
        l: 62.21,
        a: 51.88,
        b: 27.36,
      },
      cmyk: null,
    },
    {
      code: '17-1644 TCX',
      name: 'Spiced Coral',
      hex: 'D75C5D',
      rgb: {
        r: 215,
        g: 92,
        b: 93,
      },
      lab: {
        l: 56.44,
        a: 51.31,
        b: 26.52,
      },
      cmyk: null,
    },
    {
      code: '17-1545 TCX',
      name: 'Cranberry',
      hex: 'BB4A4D',
      rgb: {
        r: 187,
        g: 74,
        b: 77,
      },
      lab: {
        l: 48.41,
        a: 48.37,
        b: 24.12,
      },
      cmyk: null,
    },
    {
      code: '16-1632 TCX',
      name: 'Shell Pink',
      hex: 'F88180',
      rgb: {
        r: 248,
        g: 129,
        b: 128,
      },
      lab: {
        l: 68.68,
        a: 47.42,
        b: 23.76,
      },
      cmyk: null,
    },
    {
      code: '18-1649 TCX',
      name: 'Deep Sea Coral',
      hex: 'D9615B',
      rgb: {
        r: 217,
        g: 97,
        b: 91,
      },
      lab: {
        l: 57.47,
        a: 48.45,
        b: 29.37,
      },
      cmyk: null,
    },
    {
      code: '17-1635 TCX',
      name: 'Rose of Sharon',
      hex: 'DC5B62',
      rgb: {
        r: 220,
        g: 91,
        b: 98,
      },
      lab: {
        l: 57.2,
        a: 53.86,
        b: 25.16,
      },
      cmyk: null,
    },
    {
      code: '18-1651 TCX',
      name: 'Cayenne',
      hex: 'E04951',
      rgb: {
        r: 224,
        g: 73,
        b: 81,
      },
      lab: {
        l: 54.95,
        a: 61.15,
        b: 32.71,
      },
      cmyk: null,
    },
    {
      code: '17-1654 TCX',
      name: 'Poinsettia',
      hex: 'CB3441',
      rgb: {
        r: 203,
        g: 52,
        b: 65,
      },
      lab: {
        l: 48.18,
        a: 61.69,
        b: 32.55,
      },
      cmyk: null,
    },
    {
      code: '17-1641 TCX',
      name: 'Chrysanthemum',
      hex: 'BE454F',
      rgb: {
        r: 190,
        g: 69,
        b: 79,
      },
      lab: {
        l: 48.09,
        a: 51.36,
        b: 23.34,
      },
      cmyk: null,
    },
    {
      code: '18-1643 TCX',
      name: 'Cardinal',
      hex: 'AD3E48',
      rgb: {
        r: 173,
        g: 62,
        b: 72,
      },
      lab: {
        l: 43.85,
        a: 48.5,
        b: 20.84,
      },
      cmyk: null,
    },
    {
      code: '18-1661 TCX',
      name: 'Tomato Puree',
      hex: 'C53346',
      rgb: {
        r: 197,
        g: 51,
        b: 70,
      },
      lab: {
        l: 47.02,
        a: 60.45,
        b: 27.93,
      },
      cmyk: null,
    },
    {
      code: '17-1663 TCX',
      name: 'Bittersweet',
      hex: 'D93744',
      rgb: {
        r: 217,
        g: 55,
        b: 68,
      },
      lab: {
        l: 51.27,
        a: 64.77,
        b: 35.34,
      },
      cmyk: null,
    },
    {
      code: '18-1652 TCX',
      name: 'Rococco Red',
      hex: 'BB363F',
      rgb: {
        r: 187,
        g: 54,
        b: 63,
      },
      lab: {
        l: 45.15,
        a: 55.22,
        b: 29.11,
      },
      cmyk: null,
    },
    {
      code: '18-1660 TCX',
      name: 'Tomato',
      hex: 'CE2939',
      rgb: {
        r: 206,
        g: 41,
        b: 57,
      },
      lab: {
        l: 47.6,
        a: 65.42,
        b: 36.86,
      },
      cmyk: null,
    },
    {
      code: '18-1762 TCX',
      name: 'Hibiscus',
      hex: 'DE3848',
      rgb: {
        r: 222,
        g: 56,
        b: 72,
      },
      lab: {
        l: 52.3,
        a: 66.11,
        b: 34.43,
      },
      cmyk: null,
    },
    {
      code: '19-1760 TCX',
      name: 'Scarlet',
      hex: 'BC2C3D',
      rgb: {
        r: 188,
        g: 44,
        b: 61,
      },
      lab: {
        l: 44.13,
        a: 59.65,
        b: 29.3,
      },
      cmyk: null,
    },
    {
      code: '18-1764 TCX',
      name: 'Lollipop',
      hex: 'CC1C3B',
      rgb: {
        r: 204,
        g: 28,
        b: 59,
      },
      lab: {
        l: 46.18,
        a: 67.93,
        b: 33.82,
      },
      cmyk: null,
    },
    {
      code: '18-1763 TCX',
      name: 'High Risk Red',
      hex: 'C71F2D',
      rgb: {
        r: 199,
        g: 31,
        b: 45,
      },
      lab: {
        l: 45.26,
        a: 65.65,
        b: 40.75,
      },
      cmyk: null,
    },
    {
      code: '18-1555 TCX',
      name: 'Molten Lava',
      hex: 'B5332E',
      rgb: {
        r: 181,
        g: 51,
        b: 46,
      },
      lab: {
        l: 43.59,
        a: 54.27,
        b: 36.93,
      },
      cmyk: null,
    },
    {
      code: '18-1657 TCX',
      name: 'Salsa',
      hex: 'AA182B',
      rgb: {
        r: 170,
        g: 24,
        b: 43,
      },
      lab: {
        l: 38.49,
        a: 59.44,
        b: 32.5,
      },
      cmyk: null,
    },
    {
      code: '18-1551 TCX',
      name: 'Aura Orange',
      hex: 'B4272B',
      rgb: {
        r: 180,
        g: 39,
        b: 43,
      },
      lab: {
        l: 41.97,
        a: 58.36,
        b: 37.16,
      },
      cmyk: null,
    },
    {
      code: '18-1659 TCX',
      name: 'Goji Berry',
      hex: 'B91428',
      rgb: {
        r: 185,
        g: 20,
        b: 40,
      },
      lab: {
        l: 41.53,
        a: 64.5,
        b: 38.52,
      },
      cmyk: null,
    },
    {
      code: '18-1658 TCX',
      name: 'Pompeian Red',
      hex: 'A42A2E',
      rgb: {
        r: 164,
        g: 42,
        b: 46,
      },
      lab: {
        l: 38.78,
        a: 52.08,
        b: 30.65,
      },
      cmyk: null,
    },
    {
      code: '19-1758 TCX',
      name: 'Haute Red',
      hex: 'A11729',
      rgb: {
        r: 161,
        g: 23,
        b: 41,
      },
      lab: {
        l: 36.49,
        a: 57,
        b: 30.53,
      },
      cmyk: null,
    },
    {
      code: '18-1559 TCX',
      name: 'Red Alert',
      hex: 'D0342C',
      rgb: {
        r: 208,
        g: 52,
        b: 44,
      },
      lab: {
        l: 48.82,
        a: 63.33,
        b: 44.87,
      },
      cmyk: null,
    },
    {
      code: '18-1563 TCX',
      name: 'Rubicondo',
      hex: 'BB3339',
      rgb: {
        r: 187,
        g: 51,
        b: 57,
      },
      lab: {
        l: 44.77,
        a: 56.79,
        b: 31.93,
      },
      cmyk: null,
    },
    {
      code: '18-1453 TCX',
      name: 'Fire Whirl',
      hex: 'A73730',
      rgb: {
        r: 167,
        g: 55,
        b: 48,
      },
      lab: {
        l: 41.14,
        a: 48.32,
        b: 31.64,
      },
      cmyk: null,
    },
    {
      code: '18-1553 TCX',
      name: 'Adrenaline Rush',
      hex: 'B31A23',
      rgb: {
        r: 179,
        g: 26,
        b: 35,
      },
      lab: {
        l: 40.41,
        a: 61.21,
        b: 39.94,
      },
      cmyk: null,
    },
    {
      code: '19-1756 TCX',
      name: 'Urban Red',
      hex: 'B01F32',
      rgb: {
        r: 176,
        g: 31,
        b: 50,
      },
      lab: {
        l: 40.4,
        a: 59.7,
        b: 30.66,
      },
      cmyk: null,
    },
    {
      code: '18-1552 TCX',
      name: 'Lava Falls',
      hex: '9A2B2E',
      rgb: {
        r: 154,
        g: 43,
        b: 46,
      },
      lab: {
        l: 37.06,
        a: 48.31,
        b: 27.66,
      },
      cmyk: null,
    },
    {
      code: '19-1755 TCX',
      name: 'Equestrian Red',
      hex: '9E1528',
      rgb: {
        r: 158,
        g: 21,
        b: 40,
      },
      lab: {
        l: 35.49,
        a: 56.28,
        b: 30.23,
      },
      cmyk: null,
    },
    {
      code: '18-1662 TCX',
      name: 'Flame Scarlet',
      hex: 'CD212A',
      rgb: {
        r: 205,
        g: 33,
        b: 42,
      },
      lab: {
        l: 46.65,
        a: 66.98,
        b: 44.36,
      },
      cmyk: null,
    },
    {
      code: '17-1664 TCX',
      name: 'Poppy Red',
      hex: 'DC343B',
      rgb: {
        r: 220,
        g: 52,
        b: 59,
      },
      lab: {
        l: 51.47,
        a: 66.39,
        b: 41,
      },
      cmyk: null,
    },
    {
      code: '19-1763 TCX',
      name: 'Racing Red',
      hex: 'BD162C',
      rgb: {
        r: 189,
        g: 22,
        b: 44,
      },
      lab: {
        l: 42.48,
        a: 64.87,
        b: 37.82,
      },
      cmyk: null,
    },
    {
      code: '18-1663 TCX',
      name: 'Chinese Red',
      hex: 'BE132D',
      rgb: {
        r: 190,
        g: 19,
        b: 45,
      },
      lab: {
        l: 42.5,
        a: 65.45,
        b: 37.2,
      },
      cmyk: null,
    },
    {
      code: '19-1664 TCX',
      name: 'True Red',
      hex: 'BF1932',
      rgb: {
        r: 191,
        g: 25,
        b: 50,
      },
      lab: {
        l: 43.18,
        a: 65.02,
        b: 35.26,
      },
      cmyk: null,
    },
    {
      code: '19-1663 TCX',
      name: 'Ribbon Red',
      hex: 'B92636',
      rgb: {
        r: 185,
        g: 38,
        b: 54,
      },
      lab: {
        l: 42.91,
        a: 60.28,
        b: 31.75,
      },
      cmyk: null,
    },
    {
      code: '19-1662 TCX',
      name: 'Samba',
      hex: 'A2242F',
      rgb: {
        r: 162,
        g: 36,
        b: 47,
      },
      lab: {
        l: 37.87,
        a: 53.81,
        b: 28.46,
      },
      cmyk: null,
    },
    {
      code: '18-1653 TCX',
      name: 'Toreador',
      hex: 'B71233',
      rgb: {
        r: 183,
        g: 18,
        b: 51,
      },
      lab: {
        l: 40.98,
        a: 63.93,
        b: 31.59,
      },
      cmyk: null,
    },
    {
      code: '18-1761 TCX',
      name: 'Ski Patrol',
      hex: 'BB1237',
      rgb: {
        r: 187,
        g: 18,
        b: 55,
      },
      lab: {
        l: 41.97,
        a: 65.01,
        b: 30.73,
      },
      cmyk: null,
    },
    {
      code: '19-1764 TCX',
      name: 'Lipstick Red',
      hex: 'B31B38',
      rgb: {
        r: 179,
        g: 27,
        b: 56,
      },
      lab: {
        l: 40.77,
        a: 61.5,
        b: 28.02,
      },
      cmyk: null,
    },
    {
      code: '18-1654 TCX',
      name: 'Lychee',
      hex: 'BA0E32',
      rgb: {
        r: 186,
        g: 14,
        b: 50,
      },
      lab: {
        l: 41.53,
        a: 65.68,
        b: 32.84,
      },
      cmyk: null,
    },
    {
      code: '19-1762 TCX',
      name: 'Crimson',
      hex: 'AE0F36',
      rgb: {
        r: 174,
        g: 15,
        b: 54,
      },
      lab: {
        l: 38.87,
        a: 62.15,
        b: 26.52,
      },
      cmyk: null,
    },
    {
      code: '18-1759 TCX',
      name: 'Jalapeño Red',
      hex: 'B2123C',
      rgb: {
        r: 178,
        g: 18,
        b: 60,
      },
      lab: {
        l: 40.1,
        a: 63.24,
        b: 24.22,
      },
      cmyk: null,
    },
    {
      code: '19-1862 TCX',
      name: 'Jester Red',
      hex: '9E1030',
      rgb: {
        r: 158,
        g: 16,
        b: 48,
      },
      lab: {
        l: 35.46,
        a: 58.06,
        b: 24.94,
      },
      cmyk: null,
    },
    {
      code: '18-1655 TCX',
      name: 'Mars Red',
      hex: 'BC2732',
      rgb: {
        r: 188,
        g: 39,
        b: 50,
      },
      lab: {
        l: 43.67,
        a: 60.65,
        b: 35.62,
      },
      cmyk: null,
    },
    {
      code: '19-1757 TCX',
      name: 'Barbados Cherry',
      hex: 'AA0A27',
      rgb: {
        r: 170,
        g: 10,
        b: 39,
      },
      lab: {
        l: 37.65,
        a: 61.7,
        b: 33.86,
      },
      cmyk: null,
    },
    {
      code: '19-1559 TCX',
      name: 'Scarlet Sage',
      hex: '9D202F',
      rgb: {
        r: 157,
        g: 32,
        b: 47,
      },
      lab: {
        l: 36.34,
        a: 53.5,
        b: 26.29,
      },
      cmyk: null,
    },
    {
      code: '19-1761 TCX',
      name: 'Tango Red',
      hex: 'AC0C2D',
      rgb: {
        r: 172,
        g: 12,
        b: 45,
      },
      lab: {
        l: 38.18,
        a: 61.87,
        b: 31.29,
      },
      cmyk: null,
    },
    {
      code: '19-1759 TCX',
      name: 'American Beauty',
      hex: 'A73340',
      rgb: {
        r: 167,
        g: 51,
        b: 64,
      },
      lab: {
        l: 41.06,
        a: 49.78,
        b: 22.89,
      },
      cmyk: null,
    },
    {
      code: '19-1863 TCX',
      name: 'Scooter',
      hex: '941E32',
      rgb: {
        r: 148,
        g: 30,
        b: 50,
      },
      lab: {
        l: 34.42,
        a: 51.24,
        b: 21.84,
      },
      cmyk: null,
    },
    {
      code: '19-1557 TCX',
      name: 'Chili Pepper',
      hex: '9B1B30',
      rgb: {
        r: 155,
        g: 27,
        b: 48,
      },
      lab: {
        l: 35.57,
        a: 55.14,
        b: 24.85,
      },
      cmyk: null,
    },
    {
      code: '19-1554 TCX',
      name: 'Savvy Red',
      hex: '951528',
      rgb: {
        r: 149,
        g: 21,
        b: 40,
      },
      lab: {
        l: 33.63,
        a: 53.74,
        b: 27.66,
      },
      cmyk: null,
    },
    {
      code: '19-1657 TCX',
      name: 'Karanda Red',
      hex: '8C2A37',
      rgb: {
        r: 140,
        g: 42,
        b: 55,
      },
      lab: {
        l: 34.14,
        a: 44.67,
        b: 17.59,
      },
      cmyk: null,
    },
    {
      code: '19-1875 TCX',
      name: 'Emboldened',
      hex: '8E1F2C',
      rgb: {
        r: 142,
        g: 31,
        b: 44,
      },
      lab: {
        l: 33.11,
        a: 48.89,
        b: 23.82,
      },
      cmyk: null,
    },
    {
      code: '19-1558 TCX',
      name: 'Scarlet Smile',
      hex: '9F2436',
      rgb: {
        r: 159,
        g: 36,
        b: 54,
      },
      lab: {
        l: 37.36,
        a: 53.71,
        b: 23.51,
      },
      cmyk: null,
    },
    {
      code: '19-1537 TCX',
      name: 'Winery',
      hex: '7E212A',
      rgb: {
        r: 126,
        g: 33,
        b: 42,
      },
      lab: {
        l: 29.81,
        a: 42.54,
        b: 20.17,
      },
      cmyk: null,
    },
    {
      code: '19-1653 TCX',
      name: 'Rhythmic Red',
      hex: '8C2230',
      rgb: {
        r: 140,
        g: 34,
        b: 48,
      },
      lab: {
        l: 32.93,
        a: 47.8,
        b: 20.81,
      },
      cmyk: null,
    },
    {
      code: '19-1527 TCX',
      name: 'New Maroon',
      hex: '6B3339',
      rgb: {
        r: 107,
        g: 51,
        b: 57,
      },
      lab: {
        l: 29.59,
        a: 27.18,
        b: 8.99,
      },
      cmyk: null,
    },
    {
      code: '19-1650 TCX',
      name: 'Biking Red',
      hex: '77202E',
      rgb: {
        r: 119,
        g: 32,
        b: 46,
      },
      lab: {
        l: 28.36,
        a: 40.71,
        b: 15.33,
      },
      cmyk: null,
    },
    {
      code: '19-1652 TCX',
      name: 'Rhubarb',
      hex: '77202F',
      rgb: {
        r: 119,
        g: 32,
        b: 47,
      },
      lab: {
        l: 28.38,
        a: 40.98,
        b: 14.96,
      },
      cmyk: null,
    },
    {
      code: '19-1534 TCX',
      name: 'Merlot',
      hex: '73272D',
      rgb: {
        r: 115,
        g: 39,
        b: 45,
      },
      lab: {
        l: 28.45,
        a: 36.09,
        b: 16.17,
      },
      cmyk: null,
    },
    {
      code: '19-1555 TCX',
      name: 'Red Dahlia',
      hex: '7D2027',
      rgb: {
        r: 125,
        g: 32,
        b: 39,
      },
      lab: {
        l: 29.43,
        a: 42.77,
        b: 21.34,
      },
      cmyk: null,
    },
    {
      code: '19-1531 TCX',
      name: 'Sun-Dried Tomato',
      hex: '752329',
      rgb: {
        r: 117,
        g: 35,
        b: 41,
      },
      lab: {
        l: 28.27,
        a: 38.39,
        b: 18.23,
      },
      cmyk: null,
    },
    {
      code: '19-1535 TCX',
      name: 'Syrah',
      hex: '6A282C',
      rgb: {
        r: 106,
        g: 40,
        b: 44,
      },
      lab: {
        l: 26.95,
        a: 32.12,
        b: 14.05,
      },
      cmyk: null,
    },
    {
      code: '19-1327 TCX',
      name: 'Andorra',
      hex: '603535',
      rgb: {
        r: 96,
        g: 53,
        b: 53,
      },
      lab: {
        l: 28.11,
        a: 20.68,
        b: 9.35,
      },
      cmyk: null,
    },
    {
      code: '19-1629 TCX',
      name: 'Ruby Wine',
      hex: '77333B',
      rgb: {
        r: 119,
        g: 51,
        b: 59,
      },
      lab: {
        l: 31.65,
        a: 32.7,
        b: 11.15,
      },
      cmyk: null,
    },
    {
      code: '19-1536 TCX',
      name: 'Red Pear',
      hex: '7C3539',
      rgb: {
        r: 124,
        g: 53,
        b: 57,
      },
      lab: {
        l: 33,
        a: 32.44,
        b: 14.44,
      },
      cmyk: null,
    },
    {
      code: '19-1934 TCX',
      name: 'Tibetan Red',
      hex: '782A39',
      rgb: {
        r: 120,
        g: 42,
        b: 57,
      },
      lab: {
        l: 30.34,
        a: 37.43,
        b: 10.9,
      },
      cmyk: null,
    },
    {
      code: '19-1726 TCX',
      name: 'Cordovan',
      hex: '702F3B',
      rgb: {
        r: 112,
        g: 47,
        b: 59,
      },
      lab: {
        l: 29.69,
        a: 31.35,
        b: 8.06,
      },
      cmyk: null,
    },
    {
      code: '19-1724 TCX',
      name: 'Cabernet',
      hex: '64242E',
      rgb: {
        r: 100,
        g: 36,
        b: 46,
      },
      lab: {
        l: 25.22,
        a: 31.75,
        b: 10.35,
      },
      cmyk: null,
    },
    {
      code: '19-1525 TCX',
      name: 'Port',
      hex: '663336',
      rgb: {
        r: 102,
        g: 51,
        b: 54,
      },
      lab: {
        l: 28.6,
        a: 24.64,
        b: 9.55,
      },
      cmyk: null,
    },
    {
      code: '19-1521 TCX',
      name: 'Red Mahogany',
      hex: '60373D',
      rgb: {
        r: 96,
        g: 55,
        b: 61,
      },
      lab: {
        l: 28.72,
        a: 20.58,
        b: 4.64,
      },
      cmyk: null,
    },
    {
      code: '19-1533 TCX',
      name: 'Cowhide',
      hex: '884344',
      rgb: {
        r: 136,
        g: 67,
        b: 68,
      },
      lab: {
        l: 38.17,
        a: 31.24,
        b: 14.72,
      },
      cmyk: null,
    },
    {
      code: '19-1530 TCX',
      name: 'Burnt Russet',
      hex: '7E3940',
      rgb: {
        r: 126,
        g: 57,
        b: 64,
      },
      lab: {
        l: 34.29,
        a: 32.28,
        b: 11.51,
      },
      cmyk: null,
    },
    {
      code: '19-1524 TCX',
      name: 'Oxblood Red',
      hex: '70393F',
      rgb: {
        r: 112,
        g: 57,
        b: 63,
      },
      lab: {
        l: 31.93,
        a: 26.86,
        b: 8.45,
      },
      cmyk: null,
    },
    {
      code: '19-1930 TCX',
      name: 'Pomegranate',
      hex: '6C2831',
      rgb: {
        r: 108,
        g: 40,
        b: 49,
      },
      lab: {
        l: 27.31,
        a: 32.8,
        b: 11.7,
      },
      cmyk: null,
    },
    {
      code: '19-1522 TCX',
      name: 'Zinfandel',
      hex: '5D2935',
      rgb: {
        r: 93,
        g: 41,
        b: 53,
      },
      lab: {
        l: 24.88,
        a: 25.73,
        b: 5.31,
      },
      cmyk: null,
    },
    {
      code: '19-1725 TCX',
      name: 'Tawny Port',
      hex: '5C2C35',
      rgb: {
        r: 92,
        g: 44,
        b: 53,
      },
      lab: {
        l: 25.39,
        a: 24.67,
        b: 5.47,
      },
      cmyk: null,
    },
    {
      code: '19-1526 TCX',
      name: 'Chocolate Truffle',
      hex: '612E35',
      rgb: {
        r: 97,
        g: 46,
        b: 53,
      },
      lab: {
        l: 26.63,
        a: 25.48,
        b: 7.32,
      },
      cmyk: null,
    },
    {
      code: '18-1631 TCX',
      name: 'Earth Red',
      hex: '95424E',
      rgb: {
        r: 149,
        g: 66,
        b: 78,
      },
      lab: {
        l: 40.33,
        a: 37.25,
        b: 11.89,
      },
      cmyk: null,
    },
    {
      code: '19-1543 TCX',
      name: 'Brick Red',
      hex: '8C373E',
      rgb: {
        r: 140,
        g: 55,
        b: 62,
      },
      lab: {
        l: 36.49,
        a: 38.79,
        b: 16.55,
      },
      cmyk: null,
    },
    {
      code: '19-1532 TCX',
      name: 'Rosewood',
      hex: '813639',
      rgb: {
        r: 129,
        g: 54,
        b: 57,
      },
      lab: {
        l: 34.03,
        a: 34.76,
        b: 15.57,
      },
      cmyk: null,
    },
    {
      code: '19-1656 TCX',
      name: 'Rio Red',
      hex: '8A2232',
      rgb: {
        r: 138,
        g: 34,
        b: 50,
      },
      lab: {
        l: 32.58,
        a: 46.38,
        b: 19.21,
      },
      cmyk: null,
    },
    {
      code: '19-1655 TCX',
      name: 'Garnet',
      hex: '953640',
      rgb: {
        r: 149,
        g: 54,
        b: 64,
      },
      lab: {
        l: 38.04,
        a: 42.5,
        b: 17.69,
      },
      cmyk: null,
    },
    {
      code: '19-1840 TCX',
      name: 'Deep Claret',
      hex: '973443',
      rgb: {
        r: 151,
        g: 52,
        b: 67,
      },
      lab: {
        l: 38.01,
        a: 44.42,
        b: 15.93,
      },
      cmyk: null,
    },
    {
      code: '19-1940 TCX',
      name: 'Rumba Red',
      hex: '7C2439',
      rgb: {
        r: 124,
        g: 36,
        b: 57,
      },
      lab: {
        l: 30.11,
        a: 40.99,
        b: 11.07,
      },
      cmyk: null,
    },
    {
      code: '16-1617 TCX',
      name: 'Mauveglow',
      hex: 'D18489',
      rgb: {
        r: 209,
        g: 132,
        b: 137,
      },
      lab: {
        l: 63.93,
        a: 31.91,
        b: 11.37,
      },
      cmyk: null,
    },
    {
      code: '17-1927 TCX',
      name: 'Desert Rose',
      hex: 'CF6977',
      rgb: {
        r: 207,
        g: 105,
        b: 119,
      },
      lab: {
        l: 57.87,
        a: 43.73,
        b: 13.2,
      },
      cmyk: null,
    },
    {
      code: '17-1929 TCX',
      name: 'Rapture Rose',
      hex: 'D16277',
      rgb: {
        r: 209,
        g: 98,
        b: 119,
      },
      lab: {
        l: 57.02,
        a: 48.34,
        b: 11.99,
      },
      cmyk: null,
    },
    {
      code: '18-1634 TCX',
      name: 'Baroque Rose',
      hex: 'B35A66',
      rgb: {
        r: 179,
        g: 90,
        b: 102,
      },
      lab: {
        l: 50.2,
        a: 38.9,
        b: 11.67,
      },
      cmyk: null,
    },
    {
      code: '18-1635 TCX',
      name: 'Slate Rose',
      hex: 'B45865',
      rgb: {
        r: 180,
        g: 88,
        b: 101,
      },
      lab: {
        l: 50.09,
        a: 40.4,
        b: 12.17,
      },
      cmyk: null,
    },
    {
      code: '17-1633 TCX',
      name: 'Holly Berry',
      hex: 'B44E5D',
      rgb: {
        r: 180,
        g: 78,
        b: 93,
      },
      lab: {
        l: 47.96,
        a: 44.5,
        b: 14.47,
      },
      cmyk: null,
    },
    {
      code: '18-1633 TCX',
      name: 'Garnet Rose',
      hex: 'AC4B55',
      rgb: {
        r: 172,
        g: 75,
        b: 85,
      },
      lab: {
        l: 46.02,
        a: 42.4,
        b: 16.34,
      },
      cmyk: null,
    },
    {
      code: '14-1911 TCX',
      name: 'Candy Pink',
      hex: 'F5B0BD',
      rgb: {
        r: 245,
        g: 176,
        b: 189,
      },
      lab: {
        l: 79.29,
        a: 28.25,
        b: 4.93,
      },
      cmyk: null,
    },
    {
      code: '15-1816 TCX',
      name: 'Peony',
      hex: 'ED9CA8',
      rgb: {
        r: 237,
        g: 156,
        b: 168,
      },
      lab: {
        l: 73.48,
        a: 33.54,
        b: 8.07,
      },
      cmyk: null,
    },
    {
      code: '14-1714 TCX',
      name: 'Quartz Pink',
      hex: 'EFA6AA',
      rgb: {
        r: 239,
        g: 166,
        b: 170,
      },
      lab: {
        l: 76.01,
        a: 29.3,
        b: 10.58,
      },
      cmyk: null,
    },
    {
      code: '15-1717 TCX',
      name: 'Pink Icing',
      hex: 'EEA0A6',
      rgb: {
        r: 238,
        g: 160,
        b: 166,
      },
      lab: {
        l: 74.32,
        a: 31.78,
        b: 10.08,
      },
      cmyk: null,
    },
    {
      code: '15-1821 TCX',
      name: 'Flamingo Pink',
      hex: 'F7969E',
      rgb: {
        r: 247,
        g: 150,
        b: 158,
      },
      lab: {
        l: 73.3,
        a: 39.31,
        b: 13.25,
      },
      cmyk: null,
    },
    {
      code: '15-1624 TCX',
      name: 'Conch Shell',
      hex: 'FC8F9B',
      rgb: {
        r: 252,
        g: 143,
        b: 155,
      },
      lab: {
        l: 72.48,
        a: 43.79,
        b: 14.64,
      },
      cmyk: null,
    },
    {
      code: '15-1626 TCX',
      name: 'Salmon Rose',
      hex: 'FF8D94',
      rgb: {
        r: 255,
        g: 141,
        b: 148,
      },
      lab: {
        l: 72.35,
        a: 45.31,
        b: 18.14,
      },
      cmyk: null,
    },
    {
      code: '15-1611 TCX',
      name: 'Bridal Rose',
      hex: 'D69FA2',
      rgb: {
        r: 214,
        g: 159,
        b: 162,
      },
      lab: {
        l: 71.28,
        a: 22.26,
        b: 7.67,
      },
      cmyk: null,
    },
    {
      code: '15-1614 TCX',
      name: 'Rose Elegance',
      hex: 'D1969A',
      rgb: {
        r: 209,
        g: 150,
        b: 154,
      },
      lab: {
        l: 68.39,
        a: 24.4,
        b: 7.75,
      },
      cmyk: null,
    },
    {
      code: '16-1610 TCX',
      name: 'Brandied Apricot',
      hex: 'CA848A',
      rgb: {
        r: 202,
        g: 132,
        b: 138,
      },
      lab: {
        l: 63.1,
        a: 29.54,
        b: 9.47,
      },
      cmyk: null,
    },
    {
      code: '16-1720 TCX',
      name: 'Strawberry Ice',
      hex: 'E78B90',
      rgb: {
        r: 231,
        g: 139,
        b: 144,
      },
      lab: {
        l: 68.66,
        a: 37.7,
        b: 14.33,
      },
      cmyk: null,
    },
    {
      code: '16-1626 TCX',
      name: 'Peach Blossom',
      hex: 'DE8286',
      rgb: {
        r: 222,
        g: 130,
        b: 134,
      },
      lab: {
        l: 65.31,
        a: 37.72,
        b: 15.3,
      },
      cmyk: null,
    },
    {
      code: '16-1620 TCX',
      name: 'Tea Rose',
      hex: 'DC7178',
      rgb: {
        r: 220,
        g: 113,
        b: 120,
      },
      lab: {
        l: 61.42,
        a: 44.44,
        b: 17.85,
      },
      cmyk: null,
    },
    {
      code: '17-1537 TCX',
      name: 'Mineral Red',
      hex: 'B35457',
      rgb: {
        r: 179,
        g: 84,
        b: 87,
      },
      lab: {
        l: 48.81,
        a: 41.03,
        b: 19.06,
      },
      cmyk: null,
    },
    {
      code: '15-1415 TCX',
      name: 'Coral Cloud',
      hex: 'E2A9A1',
      rgb: {
        r: 226,
        g: 169,
        b: 161,
      },
      lab: {
        l: 75.01,
        a: 21.47,
        b: 13.59,
      },
      cmyk: null,
    },
    {
      code: '16-1511 TCX',
      name: 'Rose Tan',
      hex: 'D19C97',
      rgb: {
        r: 209,
        g: 156,
        b: 151,
      },
      lab: {
        l: 69.9,
        a: 20.61,
        b: 11.28,
      },
      cmyk: null,
    },
    {
      code: '17-1718 TCX',
      name: 'Dusty Rose',
      hex: 'BA797D',
      rgb: {
        r: 186,
        g: 121,
        b: 125,
      },
      lab: {
        l: 58.26,
        a: 27.43,
        b: 9.62,
      },
      cmyk: null,
    },
    {
      code: '17-1514 TCX',
      name: 'Ash Rose',
      hex: 'B5817D',
      rgb: {
        r: 181,
        g: 129,
        b: 125,
      },
      lab: {
        l: 59.48,
        a: 21.24,
        b: 10.77,
      },
      cmyk: null,
    },
    {
      code: '17-1424 TCX',
      name: 'Brick Dust',
      hex: 'B07069',
      rgb: {
        r: 176,
        g: 112,
        b: 105,
      },
      lab: {
        l: 54.54,
        a: 27.17,
        b: 15.68,
      },
      cmyk: null,
    },
    {
      code: '17-1518 TCX',
      name: 'Old Rose',
      hex: 'B47B77',
      rgb: {
        r: 180,
        g: 123,
        b: 119,
      },
      lab: {
        l: 57.99,
        a: 23.55,
        b: 12.41,
      },
      cmyk: null,
    },
    {
      code: '17-1520 TCX',
      name: 'Canyon Rose',
      hex: 'AF6C67',
      rgb: {
        r: 175,
        g: 108,
        b: 103,
      },
      lab: {
        l: 53.64,
        a: 28.11,
        b: 15.46,
      },
      cmyk: null,
    },
    {
      code: '14-1506 TCX',
      name: 'Rose Smoke',
      hex: 'D3B4AD',
      rgb: {
        r: 211,
        g: 180,
        b: 173,
      },
      lab: {
        l: 76.24,
        a: 11.38,
        b: 8.4,
      },
      cmyk: null,
    },
    {
      code: '14-1803 TCX',
      name: 'Sepia Rose',
      hex: 'D5BBB6',
      rgb: {
        r: 213,
        g: 187,
        b: 182,
      },
      lab: {
        l: 78.06,
        a: 9.71,
        b: 6.08,
      },
      cmyk: null,
    },
    {
      code: '15-1512 TCX',
      name: 'Misty Rose',
      hex: 'CAA39A',
      rgb: {
        r: 202,
        g: 163,
        b: 154,
      },
      lab: {
        l: 70.63,
        a: 14.61,
        b: 10.73,
      },
      cmyk: null,
    },
    {
      code: '15-1516 TCX',
      name: 'Peach Beige',
      hex: 'D3A297',
      rgb: {
        r: 211,
        g: 162,
        b: 151,
      },
      lab: {
        l: 71.28,
        a: 18.65,
        b: 13.52,
      },
      cmyk: null,
    },
    {
      code: '16-1516 TCX',
      name: 'Cameo Brown',
      hex: 'C08A80',
      rgb: {
        r: 192,
        g: 138,
        b: 128,
      },
      lab: {
        l: 63.12,
        a: 21.35,
        b: 14.57,
      },
      cmyk: null,
    },
    {
      code: '18-1435 TCX',
      name: 'Withered Rose',
      hex: 'A26666',
      rgb: {
        r: 162,
        g: 102,
        b: 102,
      },
      lab: {
        l: 50.49,
        a: 25.73,
        b: 11.65,
      },
      cmyk: null,
    },
    {
      code: '18-1426 TCX',
      name: 'Apple Butter',
      hex: '844B4D',
      rgb: {
        r: 132,
        g: 75,
        b: 77,
      },
      lab: {
        l: 39.44,
        a: 25.84,
        b: 10.97,
      },
      cmyk: null,
    },
    {
      code: '15-1515 TCX',
      name: 'Mellow Rose',
      hex: 'D9A6A1',
      rgb: {
        r: 217,
        g: 166,
        b: 161,
      },
      lab: {
        l: 73.04,
        a: 19.9,
        b: 10.86,
      },
      cmyk: null,
    },
    {
      code: '16-1518 TCX',
      name: 'Rosette',
      hex: 'CE8F8B',
      rgb: {
        r: 206,
        g: 143,
        b: 139,
      },
      lab: {
        l: 65.99,
        a: 25.3,
        b: 12.8,
      },
      cmyk: null,
    },
    {
      code: '16-1522 TCX',
      name: 'Rose Dawn',
      hex: 'C2877B',
      rgb: {
        r: 194,
        g: 135,
        b: 123,
      },
      lab: {
        l: 62.47,
        a: 23.18,
        b: 16.59,
      },
      cmyk: null,
    },
    {
      code: '17-1524 TCX',
      name: 'Desert Sand',
      hex: 'BD7B74',
      rgb: {
        r: 189,
        g: 123,
        b: 116,
      },
      lab: {
        l: 59.05,
        a: 26.96,
        b: 16.08,
      },
      cmyk: null,
    },
    {
      code: '18-1436 TCX',
      name: 'Light Mahogany',
      hex: 'AD6D68',
      rgb: {
        r: 173,
        g: 109,
        b: 104,
      },
      lab: {
        l: 53.54,
        a: 26.81,
        b: 14.83,
      },
      cmyk: null,
    },
    {
      code: '17-1525 TCX',
      name: 'Cedar Wood',
      hex: 'A1665B',
      rgb: {
        r: 161,
        g: 102,
        b: 91,
      },
      lab: {
        l: 49.92,
        a: 24.74,
        b: 17.32,
      },
      cmyk: null,
    },
    {
      code: '17-1516 TCX',
      name: 'Burlwood',
      hex: '9B716B',
      rgb: {
        r: 155,
        g: 113,
        b: 107,
      },
      lab: {
        l: 52.01,
        a: 17.31,
        b: 10.54,
      },
      cmyk: null,
    },
    {
      code: '14-1418 TCX',
      name: 'Peach Melba',
      hex: 'FBBDAF',
      rgb: {
        r: 251,
        g: 189,
        b: 175,
      },
      lab: {
        l: 82.43,
        a: 22.61,
        b: 16.59,
      },
      cmyk: null,
    },
    {
      code: '13-1318 TCX',
      name: 'Tropical Peach',
      hex: 'FFC4B2',
      rgb: {
        r: 255,
        g: 196,
        b: 178,
      },
      lab: {
        l: 84.93,
        a: 22.09,
        b: 18.69,
      },
      cmyk: null,
    },
    {
      code: '14-1419 TCX',
      name: 'Peach Pearl',
      hex: 'FFB2A5',
      rgb: {
        r: 255,
        g: 178,
        b: 165,
      },
      lab: {
        l: 80.48,
        a: 28.47,
        b: 19.41,
      },
      cmyk: null,
    },
    {
      code: '14-1420 TCX',
      name: 'Apricot Blush',
      hex: 'FEAEA5',
      rgb: {
        r: 254,
        g: 174,
        b: 165,
      },
      lab: {
        l: 79.4,
        a: 30.07,
        b: 18.07,
      },
      cmyk: null,
    },
    {
      code: '14-1324 TCX',
      name: 'Peach Bud',
      hex: 'FDB2A8',
      rgb: {
        r: 253,
        g: 178,
        b: 168,
      },
      lab: {
        l: 80.19,
        a: 28.08,
        b: 17.69,
      },
      cmyk: null,
    },
    {
      code: '15-1423 TCX',
      name: 'Peach Amber',
      hex: 'FB9F93',
      rgb: {
        r: 251,
        g: 159,
        b: 147,
      },
      lab: {
        l: 75.39,
        a: 35.63,
        b: 22.09,
      },
      cmyk: null,
    },
    {
      code: '15-1621 TCX',
      name: 'Candlelight Peach',
      hex: 'F8A39D',
      rgb: {
        r: 248,
        g: 163,
        b: 157,
      },
      lab: {
        l: 76.03,
        a: 32.76,
        b: 17.53,
      },
      cmyk: null,
    },
    {
      code: '13-1513 TCX',
      name: 'Gossamer Pink',
      hex: 'FAC8C3',
      rgb: {
        r: 250,
        g: 200,
        b: 195,
      },
      lab: {
        l: 85.26,
        a: 18.75,
        b: 9.95,
      },
      cmyk: null,
    },
    {
      code: '13-1310 TCX',
      name: 'English Rose',
      hex: 'F4C6C3',
      rgb: {
        r: 244,
        g: 198,
        b: 195,
      },
      lab: {
        l: 84.19,
        a: 17.6,
        b: 8.5,
      },
      cmyk: null,
    },
    {
      code: '13-1510 TCX',
      name: 'Impatiens Pink',
      hex: 'FFC4BC',
      rgb: {
        r: 255,
        g: 196,
        b: 188,
      },
      lab: {
        l: 84.67,
        a: 21.42,
        b: 13.13,
      },
      cmyk: null,
    },
    {
      code: '14-1511 TCX',
      name: 'Powder Pink',
      hex: 'ECB2B3',
      rgb: {
        r: 236,
        g: 178,
        b: 179,
      },
      lab: {
        l: 78.53,
        a: 23.03,
        b: 8.81,
      },
      cmyk: null,
    },
    {
      code: '14-1513 TCX',
      name: 'Blossom',
      hex: 'F2B2AE',
      rgb: {
        r: 242,
        g: 178,
        b: 174,
      },
      lab: {
        l: 79.04,
        a: 24.52,
        b: 12.22,
      },
      cmyk: null,
    },
    {
      code: '14-1521 TCX',
      name: "Peaches N' Cream",
      hex: 'F4A6A3',
      rgb: {
        r: 244,
        g: 166,
        b: 163,
      },
      lab: {
        l: 76.36,
        a: 30.71,
        b: 14.99,
      },
      cmyk: null,
    },
    {
      code: '16-1434 TCX',
      name: 'Coral Almond',
      hex: 'E29D93',
      rgb: {
        r: 226,
        g: 157,
        b: 147,
      },
      lab: {
        l: 71.81,
        a: 26.87,
        b: 16.47,
      },
      cmyk: null,
    },
    {
      code: '13-2005 TCX',
      name: 'Strawberry Cream',
      hex: 'F4C3C4',
      rgb: {
        r: 244,
        g: 195,
        b: 196,
      },
      lab: {
        l: 83.6,
        a: 18.65,
        b: 7.32,
      },
      cmyk: null,
    },
    {
      code: '13-2003 TCX',
      name: 'Blushing Rose',
      hex: 'F7C0BF',
      rgb: {
        r: 247,
        g: 192,
        b: 191,
      },
      lab: {
        l: 83.1,
        a: 21.05,
        b: 8.93,
      },
      cmyk: null,
    },
    {
      code: '12-1708 TCX',
      name: 'Crystal Rose',
      hex: 'FDC3C6',
      rgb: {
        r: 253,
        g: 195,
        b: 198,
      },
      lab: {
        l: 84.49,
        a: 22.12,
        b: 7.73,
      },
      cmyk: null,
    },
    {
      code: '14-1910 TCX',
      name: 'Tickled Pink',
      hex: 'F7B9C2',
      rgb: {
        r: 247,
        g: 185,
        b: 194,
      },
      lab: {
        l: 81.48,
        a: 25.35,
        b: 5.18,
      },
      cmyk: null,
    },
    {
      code: '13-1716 TCX',
      name: 'Pale Rosette',
      hex: 'FFBBB9',
      rgb: {
        r: 255,
        g: 187,
        b: 185,
      },
      lab: {
        l: 83.51,
        a: 28.07,
        b: 13.52,
      },
      cmyk: null,
    },
    {
      code: '16-1615 TCX',
      name: 'Flamingo Plume',
      hex: 'DE838D',
      rgb: {
        r: 222,
        g: 131,
        b: 141,
      },
      lab: {
        l: 65.64,
        a: 38.57,
        b: 11.34,
      },
      cmyk: null,
    },
    {
      code: '15-1919 TCX',
      name: 'Dianthus',
      hex: 'FC97A5',
      rgb: {
        r: 252,
        g: 151,
        b: 165,
      },
      lab: {
        l: 74.23,
        a: 40.79,
        b: 11.35,
      },
      cmyk: null,
    },
    {
      code: '13-1501 TCX',
      name: 'Mangano Calcite',
      hex: 'F5D4CD',
      rgb: {
        r: 245,
        g: 212,
        b: 205,
      },
      lab: {
        l: 87.68,
        a: 11.95,
        b: 7.92,
      },
      cmyk: null,
    },
    {
      code: '12-1212 TCX',
      name: 'Veiled Rose',
      hex: 'F8CDC9',
      rgb: {
        r: 248,
        g: 205,
        b: 201,
      },
      lab: {
        l: 86.3,
        a: 15.96,
        b: 8.32,
      },
      cmyk: null,
    },
    {
      code: '13-1408 TCX',
      name: 'Chintz Rose',
      hex: 'EEC4BE',
      rgb: {
        r: 238,
        g: 196,
        b: 190,
      },
      lab: {
        l: 83.09,
        a: 15.7,
        b: 9.62,
      },
      cmyk: null,
    },
    {
      code: '13-1409 TCX',
      name: 'Seashell Pink',
      hex: 'F7C8C2',
      rgb: {
        r: 247,
        g: 200,
        b: 194,
      },
      lab: {
        l: 84.99,
        a: 17.13,
        b: 10.07,
      },
      cmyk: null,
    },
    {
      code: '13-1511 TCX',
      name: 'Pink Salt',
      hex: 'F8CDC7',
      rgb: {
        r: 248,
        g: 205,
        b: 199,
      },
      lab: {
        l: 86.29,
        a: 15.55,
        b: 9.31,
      },
      cmyk: null,
    },
    {
      code: '13-1520 TCX',
      name: 'Rose Quartz',
      hex: 'F7CACA',
      rgb: {
        r: 247,
        g: 202,
        b: 202,
      },
      lab: {
        l: 85.61,
        a: 17.04,
        b: 6.99,
      },
      cmyk: null,
    },
    {
      code: '15-1712 TCX',
      name: 'Murex Shell',
      hex: 'F8A3A4',
      rgb: {
        r: 248,
        g: 163,
        b: 164,
      },
      lab: {
        l: 76.2,
        a: 33.79,
        b: 14.18,
      },
      cmyk: null,
    },
    {
      code: '12-1303 TCX',
      name: 'Carinaria',
      hex: 'F4DDDF',
      rgb: {
        r: 244,
        g: 221,
        b: 223,
      },
      lab: {
        l: 90.28,
        a: 8.87,
        b: 2.17,
      },
      cmyk: null,
    },
    {
      code: '12-1304 TCX',
      name: 'Pearl',
      hex: 'F9DBD8',
      rgb: {
        r: 249,
        g: 219,
        b: 216,
      },
      lab: {
        l: 89.98,
        a: 10.54,
        b: 5.87,
      },
      cmyk: null,
    },
    {
      code: '12-1706 TCX',
      name: 'Pink Dogwood',
      hex: 'F7D1D1',
      rgb: {
        r: 247,
        g: 209,
        b: 209,
      },
      lab: {
        l: 87.4,
        a: 14.33,
        b: 5.68,
      },
      cmyk: null,
    },
    {
      code: '13-2004 TCX',
      name: 'Potpourri',
      hex: 'E7C9CA',
      rgb: {
        r: 231,
        g: 201,
        b: 202,
      },
      lab: {
        l: 83.68,
        a: 11.89,
        b: 3.82,
      },
      cmyk: null,
    },
    {
      code: '14-1907 TCX',
      name: 'Peachskin',
      hex: 'DFB8B6',
      rgb: {
        r: 223,
        g: 184,
        b: 182,
      },
      lab: {
        l: 78.42,
        a: 15.24,
        b: 7.04,
      },
      cmyk: null,
    },
    {
      code: '14-1508 TCX',
      name: 'Silver Pink',
      hex: 'DCB1AF',
      rgb: {
        r: 220,
        g: 177,
        b: 175,
      },
      lab: {
        l: 76.24,
        a: 16.98,
        b: 7.58,
      },
      cmyk: null,
    },
    {
      code: '14-1904 TCX',
      name: 'Pink Dolphin',
      hex: 'E8AFB6',
      rgb: {
        r: 232,
        g: 175,
        b: 182,
      },
      lab: {
        l: 77.4,
        a: 23.11,
        b: 5.37,
      },
      cmyk: null,
    },
    {
      code: '11-2409 TCX',
      name: 'Delicacy',
      hex: 'F5E3E2',
      rgb: {
        r: 245,
        g: 227,
        b: 226,
      },
      lab: {
        l: 91.78,
        a: 6.71,
        b: 2.77,
      },
      cmyk: null,
    },
    {
      code: '12-1305 TCX',
      name: 'Heavenly Pink',
      hex: 'F4DEDE',
      rgb: {
        r: 244,
        g: 222,
        b: 222,
      },
      lab: {
        l: 90.51,
        a: 7.94,
        b: 3.2,
      },
      cmyk: null,
    },
    {
      code: '11-1408 TCX',
      name: 'Rosewater',
      hex: 'F6DBD8',
      rgb: {
        r: 246,
        g: 219,
        b: 216,
      },
      lab: {
        l: 89.68,
        a: 9.89,
        b: 5.14,
      },
      cmyk: null,
    },
    {
      code: '12-2102 TCX',
      name: 'Mauve Morn',
      hex: 'ECD7D6',
      rgb: {
        r: 236,
        g: 215,
        b: 214,
      },
      lab: {
        l: 87.67,
        a: 7.96,
        b: 3.05,
      },
      cmyk: null,
    },
    {
      code: '12-1605 TCX',
      name: 'Crystal Pink',
      hex: 'EDD0CE',
      rgb: {
        r: 237,
        g: 208,
        b: 206,
      },
      lab: {
        l: 86,
        a: 11.25,
        b: 5.16,
      },
      cmyk: null,
    },
    {
      code: '14-1905 TCX',
      name: 'Lotus',
      hex: 'E2C1C0',
      rgb: {
        r: 226,
        g: 193,
        b: 192,
      },
      lab: {
        l: 81.07,
        a: 12.79,
        b: 5.48,
      },
      cmyk: null,
    },
    {
      code: '13-2007 TCX',
      name: 'Veiled Pink',
      hex: 'E6C4C5',
      rgb: {
        r: 230,
        g: 196,
        b: 197,
      },
      lab: {
        l: 82.32,
        a: 13.12,
        b: 4.25,
      },
      cmyk: null,
    },
    {
      code: '12-1310 TCX',
      name: 'Icy Pink',
      hex: 'FBD3D9',
      rgb: {
        r: 251,
        g: 211,
        b: 217,
      },
      lab: {
        l: 88.27,
        a: 15.69,
        b: 2.72,
      },
      cmyk: null,
    },
    {
      code: '12-1813 TCX',
      name: "Mary's Rose",
      hex: 'F8D1D4',
      rgb: {
        r: 248,
        g: 209,
        b: 212,
      },
      lab: {
        l: 87.55,
        a: 14.81,
        b: 4.12,
      },
      cmyk: null,
    },
    {
      code: '12-2902 TCX',
      name: 'Mauve Chalk',
      hex: 'E5D0CF',
      rgb: {
        r: 229,
        g: 208,
        b: 207,
      },
      lab: {
        l: 85.21,
        a: 8.28,
        b: 2.94,
      },
      cmyk: null,
    },
    {
      code: '12-2904 TCX',
      name: 'Primrose Pink',
      hex: 'EED4D9',
      rgb: {
        r: 238,
        g: 212,
        b: 217,
      },
      lab: {
        l: 87.4,
        a: 10.57,
        b: 1.1,
      },
      cmyk: null,
    },
    {
      code: '13-2803 TCX',
      name: 'Pale Lilac',
      hex: 'E1C6CC',
      rgb: {
        r: 225,
        g: 198,
        b: 204,
      },
      lab: {
        l: 82.56,
        a: 10.78,
        b: 1.05,
      },
      cmyk: null,
    },
    {
      code: '13-1904 TCX',
      name: 'Chalk Pink',
      hex: 'E6C5CA',
      rgb: {
        r: 230,
        g: 197,
        b: 202,
      },
      lab: {
        l: 82.52,
        a: 13.41,
        b: 2.4,
      },
      cmyk: null,
    },
    {
      code: '12-1808 TCX',
      name: 'Pinkesque',
      hex: 'F7C9CF',
      rgb: {
        r: 247,
        g: 201,
        b: 207,
      },
      lab: {
        l: 85.51,
        a: 18.04,
        b: 4.02,
      },
      cmyk: null,
    },
    {
      code: '12-2807 TCX',
      name: 'Tender Touch',
      hex: 'F3D4DF',
      rgb: {
        r: 243,
        g: 212,
        b: 223,
      },
      lab: {
        l: 88,
        a: 13.02,
        b: -0.61,
      },
      cmyk: null,
    },
    {
      code: '13-2808 TCX',
      name: 'Ballet Slipper',
      hex: 'ECCED5',
      rgb: {
        r: 236,
        g: 206,
        b: 213,
      },
      lab: {
        l: 85.73,
        a: 11.74,
        b: 0.87,
      },
      cmyk: null,
    },
    {
      code: '13-2802 TCX',
      name: 'Fairy Tale',
      hex: 'F2C1D1',
      rgb: {
        r: 242,
        g: 193,
        b: 209,
      },
      lab: {
        l: 83.11,
        a: 20.32,
        b: -0.45,
      },
      cmyk: null,
    },
    {
      code: '13-1906 TCX',
      name: 'Rose Shadow',
      hex: 'F8C1CC',
      rgb: {
        r: 248,
        g: 193,
        b: 204,
      },
      lab: {
        l: 83.66,
        a: 21.86,
        b: 3.07,
      },
      cmyk: null,
    },
    {
      code: '13-2006 TCX',
      name: 'Almond Blossom',
      hex: 'F5BEC7',
      rgb: {
        r: 245,
        g: 190,
        b: 199,
      },
      lab: {
        l: 82.46,
        a: 22.21,
        b: 3.78,
      },
      cmyk: null,
    },
    {
      code: '13-2010 TCX',
      name: 'Orchid Pink',
      hex: 'F3BBCA',
      rgb: {
        r: 243,
        g: 187,
        b: 202,
      },
      lab: {
        l: 81.75,
        a: 23.05,
        b: 1.59,
      },
      cmyk: null,
    },
    {
      code: '14-1909 TCX',
      name: 'Coral Blush',
      hex: 'E6B2B8',
      rgb: {
        r: 230,
        g: 178,
        b: 184,
      },
      lab: {
        l: 77.8,
        a: 21.6,
        b: 5.2,
      },
      cmyk: null,
    },
    {
      code: '11-2511 TCX',
      name: 'Shrinking Violet',
      hex: 'F4E1E6',
      rgb: {
        r: 244,
        g: 225,
        b: 230,
      },
      lab: {
        l: 91.35,
        a: 7.77,
        b: 0.43,
      },
      cmyk: null,
    },
    {
      code: '12-2906 TCX',
      name: 'Barely Pink',
      hex: 'F8D7DD',
      rgb: {
        r: 248,
        g: 215,
        b: 221,
      },
      lab: {
        l: 88.99,
        a: 13.24,
        b: 1.49,
      },
      cmyk: null,
    },
    {
      code: '13-2807 TCX',
      name: 'Ballerina',
      hex: 'F2CFDC',
      rgb: {
        r: 242,
        g: 207,
        b: 220,
      },
      lab: {
        l: 86.71,
        a: 14.59,
        b: -1.07,
      },
      cmyk: null,
    },
    {
      code: '13-2801 TCX',
      name: 'Pink-a-boo',
      hex: 'F7CDDB',
      rgb: {
        r: 247,
        g: 205,
        b: 219,
      },
      lab: {
        l: 86.58,
        a: 17.75,
        b: -0.47,
      },
      cmyk: null,
    },
    {
      code: '14-2304 TCX',
      name: 'Bleached Mauve',
      hex: 'D5ADB8',
      rgb: {
        r: 213,
        g: 173,
        b: 184,
      },
      lab: {
        l: 74.86,
        a: 17,
        b: 0.78,
      },
      cmyk: null,
    },
    {
      code: '13-2804 TCX',
      name: 'Parfait Pink',
      hex: 'E9C3CF',
      rgb: {
        r: 233,
        g: 195,
        b: 207,
      },
      lab: {
        l: 82.66,
        a: 16.35,
        b: -0.27,
      },
      cmyk: null,
    },
    {
      code: '13-2008 TCX',
      name: 'Romance Rose',
      hex: 'E8B4C2',
      rgb: {
        r: 232,
        g: 180,
        b: 194,
      },
      lab: {
        l: 78.83,
        a: 21.11,
        b: 1.48,
      },
      cmyk: null,
    },
    {
      code: '12-2907 TCX',
      name: 'Pink Marshmallow',
      hex: 'F8E0E8',
      rgb: {
        r: 248,
        g: 224,
        b: 232,
      },
      lab: {
        l: 91.51,
        a: 9.73,
        b: -0.32,
      },
      cmyk: null,
    },
    {
      code: '11-2509 TCX',
      name: 'Calcite',
      hex: 'EBDBE1',
      rgb: {
        r: 235,
        g: 219,
        b: 225,
      },
      lab: {
        l: 89,
        a: 6.36,
        b: -0.56,
      },
      cmyk: null,
    },
    {
      code: '12-2905 TCX',
      name: 'Cradle Pink',
      hex: 'EDD0DD',
      rgb: {
        r: 237,
        g: 208,
        b: 221,
      },
      lab: {
        l: 86.47,
        a: 12.4,
        b: -2.04,
      },
      cmyk: null,
    },
    {
      code: '13-3406 TCX',
      name: 'Orchid Ice',
      hex: 'E0D0DB',
      rgb: {
        r: 224,
        g: 208,
        b: 219,
      },
      lab: {
        l: 85.19,
        a: 7.24,
        b: -3.25,
      },
      cmyk: null,
    },
    {
      code: '12-2903 TCX',
      name: 'Light Lilac',
      hex: 'DEC6D3',
      rgb: {
        r: 222,
        g: 198,
        b: 211,
      },
      lab: {
        l: 82.34,
        a: 10.87,
        b: -3.13,
      },
      cmyk: null,
    },
    {
      code: '13-3405 TCX',
      name: 'Lilac Snow',
      hex: 'E0C7D7',
      rgb: {
        r: 224,
        g: 199,
        b: 215,
      },
      lab: {
        l: 82.77,
        a: 11.8,
        b: -4.55,
      },
      cmyk: null,
    },
    {
      code: '14-3203 TCX',
      name: 'Corsage',
      hex: 'DBB4C8',
      rgb: {
        r: 219,
        g: 180,
        b: 200,
      },
      lab: {
        l: 77.57,
        a: 17.35,
        b: -3.81,
      },
      cmyk: null,
    },
    {
      code: '12-2908 TCX',
      name: 'Festival Bloom',
      hex: 'F5D6E6',
      rgb: {
        r: 245,
        g: 214,
        b: 230,
      },
      lab: {
        l: 88.89,
        a: 13.49,
        b: -3.34,
      },
      cmyk: null,
    },
    {
      code: '13-2806 TCX',
      name: 'Pink Lady',
      hex: 'EFC1D6',
      rgb: {
        r: 239,
        g: 193,
        b: 214,
      },
      lab: {
        l: 82.89,
        a: 20.12,
        b: -3.56,
      },
      cmyk: null,
    },
    {
      code: '13-2805 TCX',
      name: 'Roseate Spoonbill',
      hex: 'E6BCCD',
      rgb: {
        r: 230,
        g: 188,
        b: 205,
      },
      lab: {
        l: 80.54,
        a: 18.3,
        b: -2.5,
      },
      cmyk: null,
    },
    {
      code: '13-2809 TCX',
      name: 'Sweet Dreams',
      hex: 'EFBED4',
      rgb: {
        r: 239,
        g: 190,
        b: 212,
      },
      lab: {
        l: 82.08,
        a: 21.48,
        b: -3.34,
      },
      cmyk: null,
    },
    {
      code: '14-2710 TCX',
      name: 'Lilac Sachet',
      hex: 'E9ADCA',
      rgb: {
        r: 233,
        g: 173,
        b: 202,
      },
      lab: {
        l: 77.54,
        a: 26.59,
        b: -4.86,
      },
      cmyk: null,
    },
    {
      code: '14-2808 TCX',
      name: 'Sweet Lilac',
      hex: 'E8B5CE',
      rgb: {
        r: 232,
        g: 181,
        b: 206,
      },
      lab: {
        l: 79.31,
        a: 22.48,
        b: -4.28,
      },
      cmyk: null,
    },
    {
      code: '14-3205 TCX',
      name: 'Pirouette',
      hex: 'EDBEDC',
      rgb: {
        r: 237,
        g: 190,
        b: 220,
      },
      lab: {
        l: 82.15,
        a: 21.14,
        b: -7.55,
      },
      cmyk: null,
    },
    {
      code: '13-2800 TCX',
      name: 'Pink Tulle',
      hex: 'F0C9E1',
      rgb: {
        r: 240,
        g: 201,
        b: 225,
      },
      lab: {
        l: 85.16,
        a: 17.19,
        b: -5.9,
      },
      cmyk: null,
    },
    {
      code: '13-3207 TCX',
      name: 'Cherry Blossom',
      hex: 'F7CFE1',
      rgb: {
        r: 247,
        g: 207,
        b: 225,
      },
      lab: {
        l: 87.21,
        a: 17.21,
        b: -2.73,
      },
      cmyk: null,
    },
    {
      code: '14-2806 TCX',
      name: 'Nosegay',
      hex: 'F0BDD5',
      rgb: {
        r: 240,
        g: 189,
        b: 213,
      },
      lab: {
        l: 82.16,
        a: 22.11,
        b: -3.77,
      },
      cmyk: null,
    },
    {
      code: '15-2218 TCX',
      name: 'Pink Frosting',
      hex: 'F1A9CB',
      rgb: {
        r: 241,
        g: 169,
        b: 203,
      },
      lab: {
        l: 77.52,
        a: 31.32,
        b: -5.36,
      },
      cmyk: null,
    },
    {
      code: '15-2213 TCX',
      name: 'Bonbon',
      hex: 'F1A6CC',
      rgb: {
        r: 241,
        g: 166,
        b: 204,
      },
      lab: {
        l: 76.79,
        a: 33.31,
        b: -6.55,
      },
      cmyk: null,
    },
    {
      code: '16-2118 TCX',
      name: 'Pink Power',
      hex: 'D56793',
      rgb: {
        r: 213,
        g: 103,
        b: 147,
      },
      lab: {
        l: 59.17,
        a: 48.93,
        b: -0.88,
      },
      cmyk: null,
    },
    {
      code: '17-2519 TCX',
      name: 'Gin Fizz',
      hex: 'CB5B8A',
      rgb: {
        r: 203,
        g: 91,
        b: 138,
      },
      lab: {
        l: 55.19,
        a: 50.31,
        b: -1.54,
      },
      cmyk: null,
    },
    {
      code: '15-1915 TCX',
      name: 'Flowering Ginger',
      hex: 'EB7C96',
      rgb: {
        r: 235,
        g: 124,
        b: 150,
      },
      lab: {
        l: 66.19,
        a: 46.87,
        b: 8.03,
      },
      cmyk: null,
    },
    {
      code: '15-1922 TCX',
      name: 'Geranium Pink',
      hex: 'F6909D',
      rgb: {
        r: 246,
        g: 144,
        b: 157,
      },
      lab: {
        l: 72.03,
        a: 41.57,
        b: 12.28,
      },
      cmyk: null,
    },
    {
      code: '16-1731 TCX',
      name: 'Strawberry Pink',
      hex: 'F57F8E',
      rgb: {
        r: 245,
        g: 127,
        b: 142,
      },
      lab: {
        l: 68.21,
        a: 48.35,
        b: 15.76,
      },
      cmyk: null,
    },
    {
      code: '17-1928 TCX',
      name: 'Bubblegum',
      hex: 'E9738D',
      rgb: {
        r: 233,
        g: 115,
        b: 141,
      },
      lab: {
        l: 64.24,
        a: 49.83,
        b: 10.25,
      },
      cmyk: null,
    },
    {
      code: '17-1930 TCX',
      name: 'Camellia Rose',
      hex: 'EB6081',
      rgb: {
        r: 235,
        g: 96,
        b: 129,
      },
      lab: {
        l: 61.13,
        a: 57.96,
        b: 12.89,
      },
      cmyk: null,
    },
    {
      code: '17-1926 TCX',
      name: 'Fruit Dove',
      hex: 'CF5C78',
      rgb: {
        r: 207,
        g: 92,
        b: 120,
      },
      lab: {
        l: 55.53,
        a: 49.84,
        b: 9.05,
      },
      cmyk: null,
    },
    {
      code: '17-1739 TCX',
      name: 'Rethink Pink',
      hex: 'C63C5F',
      rgb: {
        r: 198,
        g: 60,
        b: 95,
      },
      lab: {
        l: 48.75,
        a: 59.27,
        b: 14.98,
      },
      cmyk: null,
    },
    {
      code: '16-1723 TCX',
      name: 'Confetti',
      hex: 'E6798E',
      rgb: {
        r: 230,
        g: 121,
        b: 142,
      },
      lab: {
        l: 64.88,
        a: 45.65,
        b: 10.68,
      },
      cmyk: null,
    },
    {
      code: '16-1735 TCX',
      name: 'Pink Lemonade',
      hex: 'EE6D8A',
      rgb: {
        r: 238,
        g: 109,
        b: 138,
      },
      lab: {
        l: 63.83,
        a: 54.33,
        b: 11.62,
      },
      cmyk: null,
    },
    {
      code: '17-1741 TCX',
      name: 'Party Punch',
      hex: 'C54966',
      rgb: {
        r: 197,
        g: 73,
        b: 102,
      },
      lab: {
        l: 50.42,
        a: 53.2,
        b: 13.07,
      },
      cmyk: null,
    },
    {
      code: '17-1744 TCX',
      name: 'Calypso Coral',
      hex: 'EE5C6C',
      rgb: {
        r: 238,
        g: 92,
        b: 108,
      },
      lab: {
        l: 60.57,
        a: 59.27,
        b: 24.47,
      },
      cmyk: null,
    },
    {
      code: '17-1640 TCX',
      name: 'Winterberry',
      hex: 'BE394F',
      rgb: {
        r: 190,
        g: 57,
        b: 79,
      },
      lab: {
        l: 46.45,
        a: 56.76,
        b: 21.01,
      },
      cmyk: null,
    },
    {
      code: '18-1665 TCX',
      name: 'Watermelon',
      hex: 'C63A4F',
      rgb: {
        r: 198,
        g: 58,
        b: 79,
      },
      lab: {
        l: 48.13,
        a: 58.61,
        b: 23.48,
      },
      cmyk: null,
    },
    {
      code: '18-1750 TCX',
      name: 'Viva Magenta',
      hex: 'BB2649',
      rgb: {
        r: 187,
        g: 38,
        b: 73,
      },
      lab: {
        l: 43.6,
        a: 61.25,
        b: 20.98,
      },
      cmyk: null,
    },
    {
      code: '15-1822 TCX',
      name: 'Plumeria',
      hex: 'FB90A2',
      rgb: {
        r: 251,
        g: 144,
        b: 162,
      },
      lab: {
        l: 72.73,
        a: 43.78,
        b: 10.74,
      },
      cmyk: null,
    },
    {
      code: '17-1746 TCX',
      name: 'Coral Paradise',
      hex: 'F05E73',
      rgb: {
        r: 240,
        g: 94,
        b: 115,
      },
      lab: {
        l: 61.33,
        a: 59.3,
        b: 21.49,
      },
      cmyk: null,
    },
    {
      code: '17-1736 TCX',
      name: 'Sun Kissed Coral',
      hex: 'EA6676',
      rgb: {
        r: 234,
        g: 102,
        b: 118,
      },
      lab: {
        l: 61.67,
        a: 54.5,
        b: 20.22,
      },
      cmyk: null,
    },
    {
      code: '17-1755 TCX',
      name: 'Paradise Pink',
      hex: 'E4455E',
      rgb: {
        r: 228,
        g: 69,
        b: 94,
      },
      lab: {
        l: 55.26,
        a: 64.67,
        b: 25.34,
      },
      cmyk: null,
    },
    {
      code: '17-1753 TCX',
      name: 'Geranium',
      hex: 'DA3D58',
      rgb: {
        r: 218,
        g: 61,
        b: 88,
      },
      lab: {
        l: 52.48,
        a: 64.41,
        b: 25.23,
      },
      cmyk: null,
    },
    {
      code: '18-1755 TCX',
      name: 'Rouge Red',
      hex: 'E24666',
      rgb: {
        r: 226,
        g: 70,
        b: 102,
      },
      lab: {
        l: 55.35,
        a: 63.94,
        b: 20.5,
      },
      cmyk: null,
    },
    {
      code: '18-1756 TCX',
      name: 'Teaberry',
      hex: 'DC3855',
      rgb: {
        r: 220,
        g: 56,
        b: 85,
      },
      lab: {
        l: 52.17,
        a: 66.19,
        b: 26.48,
      },
      cmyk: null,
    },
    {
      code: '18-2120 TCX',
      name: 'Honeysuckle',
      hex: 'D94F70',
      rgb: {
        r: 217,
        g: 79,
        b: 112,
      },
      lab: {
        l: 55.08,
        a: 58.07,
        b: 13.85,
      },
      cmyk: null,
    },
    {
      code: '17-1740 TCX',
      name: 'Claret Red',
      hex: 'C84B61',
      rgb: {
        r: 200,
        g: 75,
        b: 97,
      },
      lab: {
        l: 51.19,
        a: 52.49,
        b: 17.14,
      },
      cmyk: null,
    },
    {
      code: '18-1741 TCX',
      name: 'Raspberry Wine',
      hex: 'B63753',
      rgb: {
        r: 182,
        g: 55,
        b: 83,
      },
      lab: {
        l: 44.83,
        a: 53.97,
        b: 16.9,
      },
      cmyk: null,
    },
    {
      code: '18-1852 TCX',
      name: 'Rose Red',
      hex: 'C82151',
      rgb: {
        r: 200,
        g: 33,
        b: 81,
      },
      lab: {
        l: 46.06,
        a: 66.66,
        b: 20.19,
      },
      cmyk: null,
    },
    {
      code: '18-1760 TCX',
      name: 'Barberry',
      hex: 'BF1945',
      rgb: {
        r: 191,
        g: 25,
        b: 69,
      },
      lab: {
        l: 43.48,
        a: 65.34,
        b: 23.72,
      },
      cmyk: null,
    },
    {
      code: '17-1842 TCX',
      name: 'Azalea',
      hex: 'D42E5B',
      rgb: {
        r: 212,
        g: 46,
        b: 91,
      },
      lab: {
        l: 49.8,
        a: 67.02,
        b: 19.56,
      },
      cmyk: null,
    },
    {
      code: '18-1856 TCX',
      name: 'Virtual Pink',
      hex: 'C6174E',
      rgb: {
        r: 198,
        g: 23,
        b: 78,
      },
      lab: {
        l: 44.92,
        a: 67.84,
        b: 20.54,
      },
      cmyk: null,
    },
    {
      code: '18-1754 TCX',
      name: 'Raspberry',
      hex: 'D32E5E',
      rgb: {
        r: 211,
        g: 46,
        b: 94,
      },
      lab: {
        l: 49.66,
        a: 66.95,
        b: 17.68,
      },
      cmyk: null,
    },
    {
      code: '18-1945 TCX',
      name: 'Bright Rose',
      hex: 'C51959',
      rgb: {
        r: 197,
        g: 25,
        b: 89,
      },
      lab: {
        l: 45,
        a: 67.7,
        b: 13.65,
      },
      cmyk: null,
    },
    {
      code: '18-1951 TCX',
      name: 'Love Potion',
      hex: 'C01553',
      rgb: {
        r: 192,
        g: 21,
        b: 83,
      },
      lab: {
        l: 43.73,
        a: 67.08,
        b: 15.46,
      },
      cmyk: null,
    },
    {
      code: '18-1950 TCX',
      name: 'Jazzy',
      hex: 'B61C50',
      rgb: {
        r: 182,
        g: 28,
        b: 80,
      },
      lab: {
        l: 41.95,
        a: 62.96,
        b: 14.53,
      },
      cmyk: null,
    },
    {
      code: '19-1955 TCX',
      name: 'Cerise',
      hex: 'A41247',
      rgb: {
        r: 164,
        g: 18,
        b: 71,
      },
      lab: {
        l: 37.25,
        a: 59.65,
        b: 13.01,
      },
      cmyk: null,
    },
    {
      code: '19-1860 TCX',
      name: 'Persian Red',
      hex: 'A21441',
      rgb: {
        r: 162,
        g: 20,
        b: 65,
      },
      lab: {
        l: 36.77,
        a: 58.71,
        b: 16.08,
      },
      cmyk: null,
    },
    {
      code: '19-1850 TCX',
      name: 'Red Bud',
      hex: '962D49',
      rgb: {
        r: 150,
        g: 45,
        b: 73,
      },
      lab: {
        l: 36.86,
        a: 46.89,
        b: 10.95,
      },
      cmyk: null,
    },
    {
      code: '17-1937 TCX',
      name: 'Hot Pink',
      hex: 'E55982',
      rgb: {
        r: 229,
        g: 89,
        b: 130,
      },
      lab: {
        l: 59.12,
        a: 59.2,
        b: 9.42,
      },
      cmyk: null,
    },
    {
      code: '18-2043 TCX',
      name: 'Raspberry Sorbet',
      hex: 'D2386C',
      rgb: {
        r: 210,
        g: 56,
        b: 108,
      },
      lab: {
        l: 50.78,
        a: 64.02,
        b: 10.4,
      },
      cmyk: null,
    },
    {
      code: '19-2039 TCX',
      name: 'Granita',
      hex: 'A52350',
      rgb: {
        r: 165,
        g: 35,
        b: 80,
      },
      lab: {
        l: 38.9,
        a: 55.81,
        b: 9.32,
      },
      cmyk: null,
    },
    {
      code: '19-2041 TCX',
      name: 'Cherries Jubilee',
      hex: 'A22452',
      rgb: {
        r: 162,
        g: 36,
        b: 82,
      },
      lab: {
        l: 38.39,
        a: 54.65,
        b: 7.61,
      },
      cmyk: null,
    },
    {
      code: '19-2045 TCX',
      name: 'Vivacious',
      hex: 'A32857',
      rgb: {
        r: 163,
        g: 40,
        b: 87,
      },
      lab: {
        l: 39.1,
        a: 54.03,
        b: 5.37,
      },
      cmyk: null,
    },
    {
      code: '19-2047 TCX',
      name: 'Sangria',
      hex: '982551',
      rgb: {
        r: 152,
        g: 37,
        b: 81,
      },
      lab: {
        l: 36.58,
        a: 51.3,
        b: 5.12,
      },
      cmyk: null,
    },
    {
      code: '19-2033 TCX',
      name: 'Anemone',
      hex: '842C48',
      rgb: {
        r: 132,
        g: 44,
        b: 72,
      },
      lab: {
        l: 33.28,
        a: 40.96,
        b: 5.8,
      },
      cmyk: null,
    },
    {
      code: '15-1920 TCX',
      name: 'Morning Glory',
      hex: 'EC809E',
      rgb: {
        r: 236,
        g: 128,
        b: 158,
      },
      lab: {
        l: 67.53,
        a: 45.85,
        b: 5.5,
      },
      cmyk: null,
    },
    {
      code: '16-2126 TCX',
      name: 'Azalea Pink',
      hex: 'E96A97',
      rgb: {
        r: 233,
        g: 106,
        b: 151,
      },
      lab: {
        l: 62.78,
        a: 54.26,
        b: 2.75,
      },
      cmyk: null,
    },
    {
      code: '17-2127 TCX',
      name: 'Shocking Pink',
      hex: 'DE5B8C',
      rgb: {
        r: 222,
        g: 91,
        b: 140,
      },
      lab: {
        l: 58.25,
        a: 56.62,
        b: 2.03,
      },
      cmyk: null,
    },
    {
      code: '17-2230 TCX',
      name: 'Carmine Rose',
      hex: 'E35B8F',
      rgb: {
        r: 227,
        g: 91,
        b: 143,
      },
      lab: {
        l: 59.19,
        a: 58.69,
        b: 1.7,
      },
      cmyk: null,
    },
    {
      code: '17-2033 TCX',
      name: 'Fandango Pink',
      hex: 'E04F80',
      rgb: {
        r: 224,
        g: 79,
        b: 128,
      },
      lab: {
        l: 56.61,
        a: 61.64,
        b: 7.05,
      },
      cmyk: null,
    },
    {
      code: '18-2133 TCX',
      name: 'Pink Flambé',
      hex: 'D3507A',
      rgb: {
        r: 211,
        g: 80,
        b: 122,
      },
      lab: {
        l: 54.32,
        a: 56.24,
        b: 7.12,
      },
      cmyk: null,
    },
    {
      code: '17-1831 TCX',
      name: 'Carmine',
      hex: 'BC4869',
      rgb: {
        r: 188,
        g: 72,
        b: 105,
      },
      lab: {
        l: 48.7,
        a: 50.67,
        b: 8.66,
      },
      cmyk: null,
    },
    {
      code: '14-2311 TCX',
      name: 'Prism Pink',
      hex: 'F0A1BF',
      rgb: {
        r: 240,
        g: 161,
        b: 191,
      },
      lab: {
        l: 75.39,
        a: 34.09,
        b: -1.59,
      },
      cmyk: null,
    },
    {
      code: '15-2216 TCX',
      name: 'Sachet Pink',
      hex: 'F18AAD',
      rgb: {
        r: 241,
        g: 138,
        b: 173,
      },
      lab: {
        l: 70.46,
        a: 43.93,
        b: 1.04,
      },
      cmyk: null,
    },
    {
      code: '15-2217 TCX',
      name: 'Aurora Pink',
      hex: 'E881A6',
      rgb: {
        r: 232,
        g: 129,
        b: 166,
      },
      lab: {
        l: 67.18,
        a: 44.76,
        b: 0.42,
      },
      cmyk: null,
    },
    {
      code: '16-2124 TCX',
      name: 'Pink Carnation',
      hex: 'ED7A9E',
      rgb: {
        r: 237,
        g: 122,
        b: 158,
      },
      lab: {
        l: 66.47,
        a: 48.93,
        b: 3.91,
      },
      cmyk: null,
    },
    {
      code: '16-2120 TCX',
      name: 'Wild Orchid',
      hex: 'D979A2',
      rgb: {
        r: 217,
        g: 121,
        b: 162,
      },
      lab: {
        l: 63.45,
        a: 42.34,
        b: -3.17,
      },
      cmyk: null,
    },
    {
      code: '17-2120 TCX',
      name: 'Chateau Rose',
      hex: 'D2738F',
      rgb: {
        r: 210,
        g: 115,
        b: 143,
      },
      lab: {
        l: 60.72,
        a: 41.09,
        b: 3.67,
      },
      cmyk: null,
    },
    {
      code: '17-2520 TCX',
      name: 'Ibis Rose',
      hex: 'CA628F',
      rgb: {
        r: 202,
        g: 98,
        b: 143,
      },
      lab: {
        l: 56.32,
        a: 46.28,
        b: -2.61,
      },
      cmyk: null,
    },
    {
      code: '15-2215 TCX',
      name: 'Begonia Pink',
      hex: 'EC9ABE',
      rgb: {
        r: 236,
        g: 154,
        b: 190,
      },
      lab: {
        l: 73.44,
        a: 36.24,
        b: -3.95,
      },
      cmyk: null,
    },
    {
      code: '15-2214 TCX',
      name: 'Rosebloom',
      hex: 'E290B2',
      rgb: {
        r: 226,
        g: 144,
        b: 178,
      },
      lab: {
        l: 69.69,
        a: 36.03,
        b: -2.69,
      },
      cmyk: null,
    },
    {
      code: '15-2718 TCX',
      name: 'Fuchsia Pink',
      hex: 'DF88B7',
      rgb: {
        r: 223,
        g: 136,
        b: 183,
      },
      lab: {
        l: 67.71,
        a: 39.62,
        b: -8.85,
      },
      cmyk: null,
    },
    {
      code: '17-2623 TCX',
      name: 'Fiji Flower',
      hex: 'CA669D',
      rgb: {
        r: 202,
        g: 102,
        b: 157,
      },
      lab: {
        l: 57.6,
        a: 46.05,
        b: -9.41,
      },
      cmyk: null,
    },
    {
      code: '17-2227 TCX',
      name: 'Lilac Rose',
      hex: 'BD4275',
      rgb: {
        r: 189,
        g: 66,
        b: 117,
      },
      lab: {
        l: 48.25,
        a: 54.67,
        b: 0.26,
      },
      cmyk: null,
    },
    {
      code: '18-2333 TCX',
      name: 'Raspberry Rose',
      hex: 'CC4385',
      rgb: {
        r: 204,
        g: 67,
        b: 133,
      },
      lab: {
        l: 51.66,
        a: 60.34,
        b: -3.88,
      },
      cmyk: null,
    },
    {
      code: '17-2031 TCX',
      name: 'Fuchsia Rose',
      hex: 'C74375',
      rgb: {
        r: 199,
        g: 67,
        b: 117,
      },
      lab: {
        l: 50.33,
        a: 57.13,
        b: 4.27,
      },
      cmyk: null,
    },
    {
      code: '16-2122 TCX',
      name: 'Pink Cosmos',
      hex: 'F07BA5',
      rgb: {
        r: 240,
        g: 123,
        b: 165,
      },
      lab: {
        l: 67.16,
        a: 49.87,
        b: 1.31,
      },
      cmyk: null,
    },
    {
      code: '17-2235 TCX',
      name: 'Sangria Sunset',
      hex: 'E96393',
      rgb: {
        r: 233,
        g: 99,
        b: 147,
      },
      lab: {
        l: 61.74,
        a: 57.13,
        b: 3.63,
      },
      cmyk: null,
    },
    {
      code: '18-2330 TCX',
      name: 'Fuchsia Fedora',
      hex: 'CA4286',
      rgb: {
        r: 202,
        g: 66,
        b: 134,
      },
      lab: {
        l: 51.22,
        a: 59.65,
        b: -5.09,
      },
      cmyk: null,
    },
    {
      code: '17-2034 TCX',
      name: 'Pink Yarrow',
      hex: 'CE3375',
      rgb: {
        r: 206,
        g: 51,
        b: 117,
      },
      lab: {
        l: 49.63,
        a: 65.38,
        b: 2.88,
      },
      cmyk: null,
    },
    {
      code: '17-2036 TCX',
      name: 'Magenta',
      hex: 'D23C77',
      rgb: {
        r: 210,
        g: 60,
        b: 119,
      },
      lab: {
        l: 51.47,
        a: 63.76,
        b: 4.45,
      },
      cmyk: null,
    },
    {
      code: '18-2143 TCX',
      name: 'Beetroot Purple',
      hex: 'CF2D71',
      rgb: {
        r: 207,
        g: 45,
        b: 113,
      },
      lab: {
        l: 49.14,
        a: 67.01,
        b: 4.82,
      },
      cmyk: null,
    },
    {
      code: '18-2042 TCX',
      name: 'Innuendo',
      hex: 'C43362',
      rgb: {
        r: 196,
        g: 51,
        b: 98,
      },
      lab: {
        l: 47.3,
        a: 61.28,
        b: 11.17,
      },
      cmyk: null,
    },
    {
      code: '17-2619 TCX',
      name: 'Strawberry Moon',
      hex: 'D96BA1',
      rgb: {
        r: 217,
        g: 107,
        b: 161,
      },
      lab: {
        l: 60.65,
        a: 49.49,
        b: -6.74,
      },
      cmyk: null,
    },
    {
      code: '17-2625 TCX',
      name: 'Super Pink',
      hex: 'CE6BA4',
      rgb: {
        r: 206,
        g: 107,
        b: 164,
      },
      lab: {
        l: 59.06,
        a: 45.83,
        b: -10.99,
      },
      cmyk: null,
    },
    {
      code: '17-2521 TCX',
      name: 'Cone Flower',
      hex: 'C35989',
      rgb: {
        r: 195,
        g: 89,
        b: 137,
      },
      lab: {
        l: 53.49,
        a: 47.81,
        b: -3.93,
      },
      cmyk: null,
    },
    {
      code: '18-2436 TCX',
      name: 'Fuchsia Purple',
      hex: 'D4367A',
      rgb: {
        r: 212,
        g: 54,
        b: 122,
      },
      lab: {
        l: 51.16,
        a: 66.34,
        b: 2.54,
      },
      cmyk: null,
    },
    {
      code: '18-2140 TCX',
      name: 'Cabaret',
      hex: 'CB3373',
      rgb: {
        r: 203,
        g: 51,
        b: 115,
      },
      lab: {
        l: 48.92,
        a: 64.28,
        b: 3.29,
      },
      cmyk: null,
    },
    {
      code: '18-2045 TCX',
      name: 'Pink Peacock',
      hex: 'C62368',
      rgb: {
        r: 198,
        g: 35,
        b: 104,
      },
      lab: {
        l: 46.27,
        a: 66.67,
        b: 6.18,
      },
      cmyk: null,
    },
    {
      code: '18-2336 TCX',
      name: 'Very Berry',
      hex: 'B73275',
      rgb: {
        r: 183,
        g: 50,
        b: 117,
      },
      lab: {
        l: 45.01,
        a: 58.38,
        b: -4.04,
      },
      cmyk: null,
    },
    {
      code: '17-2627 TCX',
      name: 'Phlox Pink',
      hex: 'CE5E9A',
      rgb: {
        r: 206,
        g: 94,
        b: 154,
      },
      lab: {
        l: 56.61,
        a: 50.75,
        b: -8.97,
      },
      cmyk: null,
    },
    {
      code: '17-2624 TCX',
      name: 'Rose Violet',
      hex: 'C0428A',
      rgb: {
        r: 192,
        g: 66,
        b: 138,
      },
      lab: {
        l: 49.37,
        a: 56.95,
        b: -10.05,
      },
      cmyk: null,
    },
    {
      code: '18-2615 TCX',
      name: 'Rosé Sorbet',
      hex: 'A3497C',
      rgb: {
        r: 163,
        g: 73,
        b: 124,
      },
      lab: {
        l: 45.03,
        a: 43.16,
        b: -8.81,
      },
      cmyk: null,
    },
    {
      code: '18-2328 TCX',
      name: 'Fuchsia Red',
      hex: 'AB3475',
      rgb: {
        r: 171,
        g: 52,
        b: 117,
      },
      lab: {
        l: 42.92,
        a: 53.94,
        b: -7.32,
      },
      cmyk: null,
    },
    {
      code: '18-2326 TCX',
      name: 'Cactus Flower',
      hex: 'A83E6C',
      rgb: {
        r: 168,
        g: 62,
        b: 108,
      },
      lab: {
        l: 43.78,
        a: 48.15,
        b: -1.02,
      },
      cmyk: null,
    },
    {
      code: '19-2434 TCX',
      name: 'Festival Fuchsia',
      hex: '9E2C6A',
      rgb: {
        r: 158,
        g: 44,
        b: 106,
      },
      lab: {
        l: 39.16,
        a: 52.27,
        b: -6.88,
      },
      cmyk: null,
    },
    {
      code: '18-1722 TCX',
      name: 'Raspberry Coulis',
      hex: '833A5B',
      rgb: {
        r: 131,
        g: 58,
        b: 91,
      },
      lab: {
        l: 36.04,
        a: 36.27,
        b: -2.83,
      },
      cmyk: null,
    },
    {
      code: '18-2525 TCX',
      name: 'Magenta Haze',
      hex: '9D446E',
      rgb: {
        r: 157,
        g: 68,
        b: 110,
      },
      lab: {
        l: 42.89,
        a: 41.6,
        b: -3.76,
      },
      cmyk: null,
    },
    {
      code: '18-2527 TCX',
      name: 'Baton Rouge',
      hex: '973C6C',
      rgb: {
        r: 151,
        g: 60,
        b: 108,
      },
      lab: {
        l: 40.3,
        a: 42.94,
        b: -6.05,
      },
      cmyk: null,
    },
    {
      code: '19-2431 TCX',
      name: 'Boysenberry',
      hex: '85325C',
      rgb: {
        r: 133,
        g: 50,
        b: 92,
      },
      lab: {
        l: 35,
        a: 39.79,
        b: -4.62,
      },
      cmyk: null,
    },
    {
      code: '19-2432 TCX',
      name: 'Raspberry Radiance',
      hex: '802A50',
      rgb: {
        r: 128,
        g: 42,
        b: 80,
      },
      lab: {
        l: 32.36,
        a: 41.2,
        b: -0.85,
      },
      cmyk: null,
    },
    {
      code: '19-2430 TCX',
      name: 'Purple Potion',
      hex: '692746',
      rgb: {
        r: 105,
        g: 39,
        b: 70,
      },
      lab: {
        l: 27.18,
        a: 32.88,
        b: -2.17,
      },
      cmyk: null,
    },
    {
      code: '19-2428 TCX',
      name: 'Magenta Purple',
      hex: '6C244C',
      rgb: {
        r: 108,
        g: 36,
        b: 76,
      },
      lab: {
        l: 27.54,
        a: 36.66,
        b: -5.64,
      },
      cmyk: null,
    },
    {
      code: '19-2429 TCX',
      name: 'Plum Caspia',
      hex: '61234A',
      rgb: {
        r: 97,
        g: 35,
        b: 74,
      },
      lab: {
        l: 25.18,
        a: 32.94,
        b: -8.61,
      },
      cmyk: null,
    },
    {
      code: '17-2617 TCX',
      name: 'Dahlia Mauve',
      hex: 'A64F82',
      rgb: {
        r: 166,
        g: 79,
        b: 130,
      },
      lab: {
        l: 46.88,
        a: 41.47,
        b: -9.79,
      },
      cmyk: null,
    },
    {
      code: '18-3027 TCX',
      name: 'Purple Orchid',
      hex: 'AD4D8C',
      rgb: {
        r: 173,
        g: 77,
        b: 140,
      },
      lab: {
        l: 47.92,
        a: 46.24,
        b: -14.28,
      },
      cmyk: null,
    },
    {
      code: '18-3339 TCX',
      name: 'Vivid Viola',
      hex: '993C7C',
      rgb: {
        r: 153,
        g: 60,
        b: 124,
      },
      lab: {
        l: 41.19,
        a: 45.1,
        b: -14.87,
      },
      cmyk: null,
    },
    {
      code: '19-2630 TCX',
      name: 'Wild Aster',
      hex: '92316F',
      rgb: {
        r: 146,
        g: 49,
        b: 111,
      },
      lab: {
        l: 37.8,
        a: 46.75,
        b: -12.05,
      },
      cmyk: null,
    },
    {
      code: '18-3022 TCX',
      name: 'Deep Orchid',
      hex: '903F75',
      rgb: {
        r: 144,
        g: 63,
        b: 117,
      },
      lab: {
        l: 39.91,
        a: 40.22,
        b: -13.09,
      },
      cmyk: null,
    },
    {
      code: '18-2320 TCX',
      name: 'Clover',
      hex: '8A3371',
      rgb: {
        r: 138,
        g: 51,
        b: 113,
      },
      lab: {
        l: 36.84,
        a: 43.35,
        b: -14.75,
      },
      cmyk: null,
    },
    {
      code: '18-2929 TCX',
      name: 'Purple Wine',
      hex: '8C3573',
      rgb: {
        r: 140,
        g: 53,
        b: 115,
      },
      lab: {
        l: 37.48,
        a: 43.36,
        b: -15.53,
      },
      cmyk: null,
    },
    {
      code: '18-3224 TCX',
      name: 'Radiant Orchid',
      hex: 'AD5E99',
      rgb: {
        r: 173,
        g: 94,
        b: 153,
      },
      lab: {
        l: 51.6,
        a: 39.03,
        b: -16.62,
      },
      cmyk: null,
    },
    {
      code: '18-3331 TCX',
      name: 'Hyacinth Violet',
      hex: '8D4687',
      rgb: {
        r: 141,
        g: 70,
        b: 135,
      },
      lab: {
        l: 41.56,
        a: 37.46,
        b: -21.47,
      },
      cmyk: null,
    },
    {
      code: '18-3324 TCX',
      name: 'Dahlia',
      hex: '853F84',
      rgb: {
        r: 133,
        g: 63,
        b: 132,
      },
      lab: {
        l: 38.69,
        a: 37.82,
        b: -23.78,
      },
      cmyk: null,
    },
    {
      code: '19-2924 TCX',
      name: 'Hollyhock',
      hex: '823270',
      rgb: {
        r: 130,
        g: 50,
        b: 112,
      },
      lab: {
        l: 35.11,
        a: 41.05,
        b: -16.91,
      },
      cmyk: null,
    },
    {
      code: '19-3138 TCX',
      name: 'Byzantium',
      hex: '853B7B',
      rgb: {
        r: 133,
        g: 59,
        b: 123,
      },
      lab: {
        l: 37.54,
        a: 38.78,
        b: -20.41,
      },
      cmyk: null,
    },
    {
      code: '19-2820 TCX',
      name: 'Phlox',
      hex: '692D5D',
      rgb: {
        r: 105,
        g: 45,
        b: 93,
      },
      lab: {
        l: 29.13,
        a: 32.78,
        b: -14.95,
      },
      cmyk: null,
    },
    {
      code: '19-3022 TCX',
      name: 'Gloxinia',
      hex: '632E5A',
      rgb: {
        r: 99,
        g: 46,
        b: 90,
      },
      lab: {
        l: 28.18,
        a: 29.24,
        b: -14.46,
      },
      cmyk: null,
    },
    {
      code: '17-3014 TCX',
      name: 'Mulberry',
      hex: 'A76C97',
      rgb: {
        r: 167,
        g: 108,
        b: 151,
      },
      lab: {
        l: 53.74,
        a: 28.85,
        b: -12.28,
      },
      cmyk: null,
    },
    {
      code: '17-3323 TCX',
      name: 'Iris Orchid',
      hex: 'A767A2',
      rgb: {
        r: 167,
        g: 103,
        b: 162,
      },
      lab: {
        l: 52.93,
        a: 33.38,
        b: -19.66,
      },
      cmyk: null,
    },
    {
      code: '18-3120 TCX',
      name: 'Willowherb',
      hex: '8F4584',
      rgb: {
        r: 143,
        g: 69,
        b: 132,
      },
      lab: {
        l: 41.33,
        a: 38.3,
        b: -19.6,
      },
      cmyk: null,
    },
    {
      code: '18-3025 TCX',
      name: 'Striking Purple',
      hex: '944E87',
      rgb: {
        r: 148,
        g: 78,
        b: 135,
      },
      lab: {
        l: 44.06,
        a: 36.56,
        b: -17.65,
      },
      cmyk: null,
    },
    {
      code: '19-3336 TCX',
      name: 'Sparkling Grape',
      hex: '763374',
      rgb: {
        r: 118,
        g: 51,
        b: 116,
      },
      lab: {
        l: 33.39,
        a: 36.89,
        b: -22.8,
      },
      cmyk: null,
    },
    {
      code: '19-3230 TCX',
      name: 'Grape Juice',
      hex: '682961',
      rgb: {
        r: 104,
        g: 41,
        b: 97,
      },
      lab: {
        l: 28.31,
        a: 34.67,
        b: -18.8,
      },
      cmyk: null,
    },
    {
      code: '18-3340 TCX',
      name: 'Charisma',
      hex: '642B60',
      rgb: {
        r: 100,
        g: 43,
        b: 96,
      },
      lab: {
        l: 27.96,
        a: 31.77,
        b: -18.39,
      },
      cmyk: null,
    },
    {
      code: '16-3115 TCX',
      name: 'Crocus',
      hex: 'C67FAE',
      rgb: {
        r: 198,
        g: 127,
        b: 174,
      },
      lab: {
        l: 62.45,
        a: 33.97,
        b: -12.03,
      },
      cmyk: null,
    },
    {
      code: '17-3020 TCX',
      name: 'Spring Crocus',
      hex: 'BA69A1',
      rgb: {
        r: 186,
        g: 105,
        b: 161,
      },
      lab: {
        l: 55.88,
        a: 39.18,
        b: -14.53,
      },
      cmyk: null,
    },
    {
      code: '17-3240 TCX',
      name: 'Bodacious',
      hex: 'B76CA4',
      rgb: {
        r: 183,
        g: 108,
        b: 164,
      },
      lab: {
        l: 56.03,
        a: 36.92,
        b: -15.72,
      },
      cmyk: null,
    },
    {
      code: '17-3023 TCX',
      name: 'Rosebud',
      hex: 'B65F9A',
      rgb: {
        r: 182,
        g: 95,
        b: 154,
      },
      lab: {
        l: 53.06,
        a: 42.27,
        b: -14.7,
      },
      cmyk: null,
    },
    {
      code: '18-3230 TCX',
      name: 'Meadow Mauve',
      hex: 'A9568C',
      rgb: {
        r: 169,
        g: 86,
        b: 140,
      },
      lab: {
        l: 48.84,
        a: 40.91,
        b: -12.74,
      },
      cmyk: null,
    },
    {
      code: '18-3015 TCX',
      name: 'Amethyst',
      hex: '864D75',
      rgb: {
        r: 134,
        g: 77,
        b: 117,
      },
      lab: {
        l: 41.01,
        a: 29.88,
        b: -11.62,
      },
      cmyk: null,
    },
    {
      code: '18-3014 TCX',
      name: 'Grape Kiss',
      hex: '7B4468',
      rgb: {
        r: 123,
        g: 68,
        b: 104,
      },
      lab: {
        l: 37.24,
        a: 28.73,
        b: -9.64,
      },
      cmyk: null,
    },
    {
      code: '15-3216 TCX',
      name: 'Phalaenopsis',
      hex: 'D39FC8',
      rgb: {
        r: 211,
        g: 159,
        b: 200,
      },
      lab: {
        l: 71.76,
        a: 25.33,
        b: -12.47,
      },
      cmyk: null,
    },
    {
      code: '16-3320 TCX',
      name: 'Violet',
      hex: 'C180B5',
      rgb: {
        r: 193,
        g: 128,
        b: 181,
      },
      lab: {
        l: 62.12,
        a: 32.73,
        b: -16.67,
      },
      cmyk: null,
    },
    {
      code: '16-3321 TCX',
      name: 'First Bloom',
      hex: 'B977AE',
      rgb: {
        r: 185,
        g: 119,
        b: 174,
      },
      lab: {
        l: 59.01,
        a: 33.19,
        b: -17.13,
      },
      cmyk: null,
    },
    {
      code: '17-3310 TCX',
      name: 'Pale Pansy',
      hex: 'B380AA',
      rgb: {
        r: 179,
        g: 128,
        b: 170,
      },
      lab: {
        l: 60.05,
        a: 26.2,
        b: -13.62,
      },
      cmyk: null,
    },
    {
      code: '17-3320 TCX',
      name: 'Liatris',
      hex: 'A8639C',
      rgb: {
        r: 168,
        g: 99,
        b: 156,
      },
      lab: {
        l: 51.85,
        a: 34.81,
        b: -17.56,
      },
      cmyk: null,
    },
    {
      code: '18-3223 TCX',
      name: 'Cattleya Orchid',
      hex: '9C4C8D',
      rgb: {
        r: 156,
        g: 76,
        b: 141,
      },
      lab: {
        l: 45.18,
        a: 40.21,
        b: -18.89,
      },
      cmyk: null,
    },
    {
      code: '18-3024 TCX',
      name: 'Beautyberry',
      hex: '88447B',
      rgb: {
        r: 136,
        g: 68,
        b: 123,
      },
      lab: {
        l: 39.8,
        a: 35.83,
        b: -17.05,
      },
      cmyk: null,
    },
    {
      code: '15-3412 TCX',
      name: 'Orchid Bouquet',
      hex: 'D1ACCE',
      rgb: {
        r: 209,
        g: 172,
        b: 206,
      },
      lab: {
        l: 74.71,
        a: 18.54,
        b: -11.44,
      },
      cmyk: null,
    },
    {
      code: '16-3416 TCX',
      name: 'Violet Tulle',
      hex: 'C193C0',
      rgb: {
        r: 193,
        g: 147,
        b: 192,
      },
      lab: {
        l: 66.74,
        a: 23.78,
        b: -15.67,
      },
      cmyk: null,
    },
    {
      code: '15-3214 TCX',
      name: 'Orchid',
      hex: 'D198C5',
      rgb: {
        r: 209,
        g: 152,
        b: 197,
      },
      lab: {
        l: 70,
        a: 27.61,
        b: -13.52,
      },
      cmyk: null,
    },
    {
      code: '14-3209 TCX',
      name: 'Pastel Lavender',
      hex: 'D8A1C4',
      rgb: {
        r: 216,
        g: 161,
        b: 196,
      },
      lab: {
        l: 72.68,
        a: 25.61,
        b: -8.98,
      },
      cmyk: null,
    },
    {
      code: '15-2913 TCX',
      name: 'Lilac Chiffon',
      hex: 'DE9BC4',
      rgb: {
        r: 222,
        g: 155,
        b: 196,
      },
      lab: {
        l: 71.99,
        a: 30.9,
        b: -9.51,
      },
      cmyk: null,
    },
    {
      code: '16-3118 TCX',
      name: 'Cyclamen',
      hex: 'D687BA',
      rgb: {
        r: 214,
        g: 135,
        b: 186,
      },
      lab: {
        l: 66.6,
        a: 37.03,
        b: -12.4,
      },
      cmyk: null,
    },
    {
      code: '16-3116 TCX',
      name: 'Opera Mauve',
      hex: 'CA80B1',
      rgb: {
        r: 202,
        g: 128,
        b: 177,
      },
      lab: {
        l: 63.19,
        a: 35.35,
        b: -12.15,
      },
      cmyk: null,
    },
    {
      code: '14-3207 TCX',
      name: 'Pink Lavender',
      hex: 'D9AFCA',
      rgb: {
        r: 217,
        g: 175,
        b: 202,
      },
      lab: {
        l: 76.11,
        a: 19.56,
        b: -7.22,
      },
      cmyk: null,
    },
    {
      code: '16-2111 TCX',
      name: 'Mauve Orchid',
      hex: 'B58299',
      rgb: {
        r: 181,
        g: 130,
        b: 153,
      },
      lab: {
        l: 60.37,
        a: 23.41,
        b: -3.43,
      },
      cmyk: null,
    },
    {
      code: '16-2614 TCX',
      name: 'Moonlite Mauve',
      hex: 'D28FB0',
      rgb: {
        r: 210,
        g: 143,
        b: 176,
      },
      lab: {
        l: 67.45,
        a: 29.91,
        b: -5.36,
      },
      cmyk: null,
    },
    {
      code: '15-2210 TCX',
      name: 'Orchid Smoke',
      hex: 'D294AA',
      rgb: {
        r: 210,
        g: 148,
        b: 170,
      },
      lab: {
        l: 68.29,
        a: 26.97,
        b: -0.76,
      },
      cmyk: null,
    },
    {
      code: '16-2215 TCX',
      name: 'Cashmere Rose',
      hex: 'CE879F',
      rgb: {
        r: 206,
        g: 135,
        b: 159,
      },
      lab: {
        l: 64.72,
        a: 30.85,
        b: 0.13,
      },
      cmyk: null,
    },
    {
      code: '15-1912 TCX',
      name: 'Sea Pink',
      hex: 'DE98AB',
      rgb: {
        r: 222,
        g: 152,
        b: 171,
      },
      lab: {
        l: 70.67,
        a: 30.01,
        b: 1.96,
      },
      cmyk: null,
    },
    {
      code: '16-1715 TCX',
      name: 'Wild Rose',
      hex: 'CE8498',
      rgb: {
        r: 206,
        g: 132,
        b: 152,
      },
      lab: {
        l: 63.98,
        a: 31.9,
        b: 2.6,
      },
      cmyk: null,
    },
    {
      code: '14-2305 TCX',
      name: 'Pink Nectar',
      hex: 'D8AAB7',
      rgb: {
        r: 216,
        g: 170,
        b: 183,
      },
      lab: {
        l: 74.38,
        a: 19.57,
        b: 0.73,
      },
      cmyk: null,
    },
    {
      code: '14-2307 TCX',
      name: 'Cameo Pink',
      hex: 'DBA9B7',
      rgb: {
        r: 219,
        g: 169,
        b: 183,
      },
      lab: {
        l: 74.63,
        a: 21.66,
        b: 0.71,
      },
      cmyk: null,
    },
    {
      code: '15-1906 TCX',
      name: 'Zephyr',
      hex: 'C89FA5',
      rgb: {
        r: 200,
        g: 159,
        b: 165,
      },
      lab: {
        l: 69.76,
        a: 16.66,
        b: 3.29,
      },
      cmyk: null,
    },
    {
      code: '16-1712 TCX',
      name: 'Polignac',
      hex: 'C28799',
      rgb: {
        r: 194,
        g: 135,
        b: 153,
      },
      lab: {
        l: 63.18,
        a: 25.49,
        b: 0.64,
      },
      cmyk: null,
    },
    {
      code: '16-1710 TCX',
      name: 'Foxglove',
      hex: 'B98391',
      rgb: {
        r: 185,
        g: 131,
        b: 145,
      },
      lab: {
        l: 60.95,
        a: 23.82,
        b: 2.11,
      },
      cmyk: null,
    },
    {
      code: '16-1708 TCX',
      name: 'Lilas',
      hex: 'B88995',
      rgb: {
        r: 184,
        g: 137,
        b: 149,
      },
      lab: {
        l: 62.38,
        a: 20.08,
        b: 1.63,
      },
      cmyk: null,
    },
    {
      code: '17-1610 TCX',
      name: 'Dusky Orchid',
      hex: '9A7182',
      rgb: {
        r: 154,
        g: 113,
        b: 130,
      },
      lab: {
        l: 52.61,
        a: 19.19,
        b: -1.77,
      },
      cmyk: null,
    },
    {
      code: '17-1609 TCX',
      name: 'Mesa Rose',
      hex: 'A76E7A',
      rgb: {
        r: 167,
        g: 110,
        b: 122,
      },
      lab: {
        l: 53.23,
        a: 25.68,
        b: 3.75,
      },
      cmyk: null,
    },
    {
      code: '17-1614 TCX',
      name: 'Deco Rose',
      hex: '985F68',
      rgb: {
        r: 152,
        g: 95,
        b: 104,
      },
      lab: {
        l: 47.35,
        a: 25.58,
        b: 5.8,
      },
      cmyk: null,
    },
    {
      code: '17-1522 TCX',
      name: 'Mauvewood',
      hex: 'A75D67',
      rgb: {
        r: 167,
        g: 93,
        b: 103,
      },
      lab: {
        l: 49.1,
        a: 32.21,
        b: 9.99,
      },
      cmyk: null,
    },
    {
      code: '18-1718 TCX',
      name: 'Hawthorn Rose',
      hex: '884C5E',
      rgb: {
        r: 136,
        g: 76,
        b: 94,
      },
      lab: {
        l: 40.67,
        a: 28.15,
        b: 2.28,
      },
      cmyk: null,
    },
    {
      code: '18-1725 TCX',
      name: 'Dry Rose',
      hex: '8C4759',
      rgb: {
        r: 140,
        g: 71,
        b: 89,
      },
      lab: {
        l: 40.05,
        a: 31.94,
        b: 4.25,
      },
      cmyk: null,
    },
    {
      code: '18-1619 TCX',
      name: 'Maroon',
      hex: '844655',
      rgb: {
        r: 132,
        g: 70,
        b: 85,
      },
      lab: {
        l: 38.44,
        a: 29.09,
        b: 4.24,
      },
      cmyk: null,
    },
    {
      code: '18-1420 TCX',
      name: 'Wild Ginger',
      hex: '7C4C53',
      rgb: {
        r: 124,
        g: 76,
        b: 83,
      },
      lab: {
        l: 38.63,
        a: 22.23,
        b: 5.56,
      },
      cmyk: null,
    },
    {
      code: '17-1623 TCX',
      name: 'Rose Wine',
      hex: 'A4596D',
      rgb: {
        r: 164,
        g: 89,
        b: 109,
      },
      lab: {
        l: 47.83,
        a: 33.93,
        b: 4.14,
      },
      cmyk: null,
    },
    {
      code: '17-1608 TCX',
      name: 'Heather Rose',
      hex: 'AD6D7F',
      rgb: {
        r: 173,
        g: 109,
        b: 127,
      },
      lab: {
        l: 54.07,
        a: 28.49,
        b: 2.23,
      },
      cmyk: null,
    },
    {
      code: '17-1723 TCX',
      name: 'Malaga',
      hex: '9F5069',
      rgb: {
        r: 159,
        g: 80,
        b: 105,
      },
      lab: {
        l: 45.23,
        a: 35.99,
        b: 2.72,
      },
      cmyk: null,
    },
    {
      code: '18-2027 TCX',
      name: 'Beaujolais',
      hex: '80304C',
      rgb: {
        r: 128,
        g: 48,
        b: 76,
      },
      lab: {
        l: 33.27,
        a: 38.28,
        b: 2.9,
      },
      cmyk: null,
    },
    {
      code: '19-2030 TCX',
      name: 'Beet Red',
      hex: '7A1F3D',
      rgb: {
        r: 122,
        g: 31,
        b: 61,
      },
      lab: {
        l: 29.19,
        a: 42.97,
        b: 6.6,
      },
      cmyk: null,
    },
    {
      code: '19-2025 TCX',
      name: 'Red Plum',
      hex: '7C2946',
      rgb: {
        r: 124,
        g: 41,
        b: 70,
      },
      lab: {
        l: 31.17,
        a: 39.82,
        b: 3.51,
      },
      cmyk: null,
    },
    {
      code: '19-2024 TCX',
      name: 'Rhododendron',
      hex: '722B3F',
      rgb: {
        r: 114,
        g: 43,
        b: 63,
      },
      lab: {
        l: 29.41,
        a: 34.88,
        b: 5.26,
      },
      cmyk: null,
    },
    {
      code: '15-1607 TCX',
      name: 'Pale Mauve',
      hex: 'C6A4A4',
      rgb: {
        r: 198,
        g: 164,
        b: 164,
      },
      lab: {
        l: 70.77,
        a: 13.08,
        b: 5.2,
      },
      cmyk: null,
    },
    {
      code: '17-1512 TCX',
      name: 'Nostalgia Rose',
      hex: 'A4777E',
      rgb: {
        r: 164,
        g: 119,
        b: 126,
      },
      lab: {
        l: 55.15,
        a: 19.27,
        b: 3.92,
      },
      cmyk: null,
    },
    {
      code: '17-1511 TCX',
      name: 'Wistful Mauve',
      hex: '946C74',
      rgb: {
        r: 148,
        g: 108,
        b: 116,
      },
      lab: {
        l: 50.27,
        a: 17.96,
        b: 2.47,
      },
      cmyk: null,
    },
    {
      code: '18-1613 TCX',
      name: 'Renaissance Rose',
      hex: '865560',
      rgb: {
        r: 134,
        g: 85,
        b: 96,
      },
      lab: {
        l: 42.46,
        a: 22.92,
        b: 3.2,
      },
      cmyk: null,
    },
    {
      code: '18-1418 TCX',
      name: 'Crushed Berry',
      hex: '804F5A',
      rgb: {
        r: 128,
        g: 79,
        b: 90,
      },
      lab: {
        l: 39.96,
        a: 22.91,
        b: 3.42,
      },
      cmyk: null,
    },
    {
      code: '18-1616 TCX',
      name: 'Roan Rouge',
      hex: '885157',
      rgb: {
        r: 136,
        g: 81,
        b: 87,
      },
      lab: {
        l: 41.62,
        a: 25.19,
        b: 7.5,
      },
      cmyk: null,
    },
    {
      code: '18-1614 TCX',
      name: 'Nocturne',
      hex: '7B4B56',
      rgb: {
        r: 123,
        g: 75,
        b: 86,
      },
      lab: {
        l: 38.26,
        a: 22.26,
        b: 3.37,
      },
      cmyk: null,
    },
    {
      code: '15-1905 TCX',
      name: 'Burnished Lilac',
      hex: 'C5AEB1',
      rgb: {
        r: 197,
        g: 174,
        b: 177,
      },
      lab: {
        l: 73.29,
        a: 9.82,
        b: 1.97,
      },
      cmyk: null,
    },
    {
      code: '15-2705 TCX',
      name: 'Keepsake Lilac',
      hex: 'C0A5AE',
      rgb: {
        r: 192,
        g: 165,
        b: 174,
      },
      lab: {
        l: 70.53,
        a: 11.93,
        b: -0.72,
      },
      cmyk: null,
    },
    {
      code: '15-2205 TCX',
      name: 'Dawn Pink',
      hex: 'BFA3AF',
      rgb: {
        r: 191,
        g: 163,
        b: 175,
      },
      lab: {
        l: 69.91,
        a: 12.82,
        b: -1.69,
      },
      cmyk: null,
    },
    {
      code: '16-3205 TCX',
      name: 'Mauve Shadows',
      hex: 'B598A3',
      rgb: {
        r: 181,
        g: 152,
        b: 163,
      },
      lab: {
        l: 65.77,
        a: 13.2,
        b: -1.29,
      },
      cmyk: null,
    },
    {
      code: '17-1605 TCX',
      name: 'Elderberry',
      hex: '9D848E',
      rgb: {
        r: 157,
        g: 132,
        b: 142,
      },
      lab: {
        l: 57.95,
        a: 11.89,
        b: -1.46,
      },
      cmyk: null,
    },
    {
      code: '17-2411 TCX',
      name: 'Toadstool',
      hex: '998089',
      rgb: {
        r: 153,
        g: 128,
        b: 137,
      },
      lab: {
        l: 56.41,
        a: 11.22,
        b: -0.42,
      },
      cmyk: null,
    },
    {
      code: '18-2109 TCX',
      name: 'Grape Shake',
      hex: '896971',
      rgb: {
        r: 137,
        g: 105,
        b: 113,
      },
      lab: {
        l: 47.99,
        a: 14.64,
        b: 0.72,
      },
      cmyk: null,
    },
    {
      code: '13-3804 TCX',
      name: 'Gray Lilac',
      hex: 'D4CACD',
      rgb: {
        r: 212,
        g: 202,
        b: 205,
      },
      lab: {
        l: 82.24,
        a: 4.27,
        b: 0.06,
      },
      cmyk: null,
    },
    {
      code: '15-2706 TCX',
      name: 'Violet Ice',
      hex: 'C2ACB1',
      rgb: {
        r: 194,
        g: 172,
        b: 177,
      },
      lab: {
        l: 72.52,
        a: 9.68,
        b: 0.33,
      },
      cmyk: null,
    },
    {
      code: '16-1707 TCX',
      name: 'Deauville Mauve',
      hex: 'AF9293',
      rgb: {
        r: 175,
        g: 146,
        b: 147,
      },
      lab: {
        l: 63.43,
        a: 11.71,
        b: 3.74,
      },
      cmyk: null,
    },
    {
      code: '16-1806 TCX',
      name: 'Woodrose',
      hex: 'AE8C8E',
      rgb: {
        r: 174,
        g: 140,
        b: 142,
      },
      lab: {
        l: 61.84,
        a: 13.65,
        b: 4.48,
      },
      cmyk: null,
    },
    {
      code: '18-1807 TCX',
      name: 'Twilight Mauve',
      hex: '8B6F70',
      rgb: {
        r: 139,
        g: 111,
        b: 112,
      },
      lab: {
        l: 49.79,
        a: 11.91,
        b: 4.07,
      },
      cmyk: null,
    },
    {
      code: '18-1612 TCX',
      name: 'Rose Taupe',
      hex: '806062',
      rgb: {
        r: 128,
        g: 96,
        b: 98,
      },
      lab: {
        l: 44.26,
        a: 14.47,
        b: 4.35,
      },
      cmyk: null,
    },
    {
      code: '18-1512 TCX',
      name: 'Rose Brown',
      hex: '80565B',
      rgb: {
        r: 128,
        g: 86,
        b: 91,
      },
      lab: {
        l: 41.71,
        a: 19.39,
        b: 4.94,
      },
      cmyk: null,
    },
    {
      code: '14-3803 TCX',
      name: 'Hushed Violet',
      hex: 'D1C0BF',
      rgb: {
        r: 209,
        g: 192,
        b: 191,
      },
      lab: {
        l: 79.08,
        a: 7,
        b: 2.54,
      },
      cmyk: null,
    },
    {
      code: '16-1509 TCX',
      name: 'Shadow Gray',
      hex: 'BBA5A0',
      rgb: {
        r: 187,
        g: 165,
        b: 160,
      },
      lab: {
        l: 69.6,
        a: 8.76,
        b: 5.49,
      },
      cmyk: null,
    },
    {
      code: '16-1508 TCX',
      name: 'Adobe Rose',
      hex: 'BA9F99',
      rgb: {
        r: 186,
        g: 159,
        b: 153,
      },
      lab: {
        l: 67.89,
        a: 10.66,
        b: 6.95,
      },
      cmyk: null,
    },
    {
      code: '16-1510 TCX',
      name: 'Fawn',
      hex: 'AE9490',
      rgb: {
        r: 174,
        g: 148,
        b: 144,
      },
      lab: {
        l: 63.71,
        a: 10.03,
        b: 6.28,
      },
      cmyk: null,
    },
    {
      code: '17-1510 TCX',
      name: 'Antler',
      hex: '957A76',
      rgb: {
        r: 149,
        g: 122,
        b: 118,
      },
      lab: {
        l: 53.88,
        a: 10.72,
        b: 6.66,
      },
      cmyk: null,
    },
    {
      code: '18-1415 TCX',
      name: 'Marron',
      hex: '6E4C4B',
      rgb: {
        r: 110,
        g: 76,
        b: 75,
      },
      lab: {
        l: 36.53,
        a: 15.71,
        b: 7.44,
      },
      cmyk: null,
    },
    {
      code: '18-1409 TCX',
      name: 'Peppercorn',
      hex: '6C5656',
      rgb: {
        r: 108,
        g: 86,
        b: 86,
      },
      lab: {
        l: 39.1,
        a: 9.83,
        b: 3.88,
      },
      cmyk: null,
    },
    {
      code: '13-3803 TCX',
      name: 'Lilac Ash',
      hex: 'D7CDCD',
      rgb: {
        r: 215,
        g: 205,
        b: 205,
      },
      lab: {
        l: 83.25,
        a: 3.81,
        b: 1.46,
      },
      cmyk: null,
    },
    {
      code: '16-1703 TCX',
      name: 'Sphinx',
      hex: 'AB9895',
      rgb: {
        r: 171,
        g: 152,
        b: 149,
      },
      lab: {
        l: 64.67,
        a: 7.29,
        b: 4.5,
      },
      cmyk: null,
    },
    {
      code: '16-1506 TCX',
      name: 'Bark',
      hex: 'A99592',
      rgb: {
        r: 169,
        g: 149,
        b: 146,
      },
      lab: {
        l: 63.56,
        a: 7.85,
        b: 4.65,
      },
      cmyk: null,
    },
    {
      code: '19-1620 TCX',
      name: 'Huckleberry',
      hex: '5B4349',
      rgb: {
        r: 91,
        g: 67,
        b: 73,
      },
      lab: {
        l: 31.33,
        a: 12.31,
        b: 0.65,
      },
      cmyk: null,
    },
    {
      code: '18-1404 TCX',
      name: 'Sparrow',
      hex: '69595C',
      rgb: {
        r: 105,
        g: 89,
        b: 92,
      },
      lab: {
        l: 39.52,
        a: 7.94,
        b: 1.05,
      },
      cmyk: null,
    },
    {
      code: '18-1506 TCX',
      name: 'Plum Truffle',
      hex: '675758',
      rgb: {
        r: 103,
        g: 87,
        b: 88,
      },
      lab: {
        l: 38.75,
        a: 7.83,
        b: 2.3,
      },
      cmyk: null,
    },
    {
      code: '19-1606 TCX',
      name: 'Raisin',
      hex: '524144',
      rgb: {
        r: 82,
        g: 65,
        b: 68,
      },
      lab: {
        l: 29.62,
        a: 8.56,
        b: 1.38,
      },
      cmyk: null,
    },
    {
      code: '16-1606 TCX',
      name: 'Purple Dove',
      hex: '99888D',
      rgb: {
        r: 153,
        g: 136,
        b: 141,
      },
      lab: {
        l: 58.44,
        a: 7.78,
        b: 0.05,
      },
      cmyk: null,
    },
    {
      code: '17-1708 TCX',
      name: 'Moonscape',
      hex: '73606A',
      rgb: {
        r: 115,
        g: 96,
        b: 106,
      },
      lab: {
        l: 42.93,
        a: 9.73,
        b: -2.47,
      },
      cmyk: null,
    },
    {
      code: '18-1705 TCX',
      name: 'Arctic Dusk',
      hex: '735B6A',
      rgb: {
        r: 115,
        g: 91,
        b: 106,
      },
      lab: {
        l: 41.85,
        a: 12.65,
        b: -4.33,
      },
      cmyk: null,
    },
    {
      code: '18-1405 TCX',
      name: 'Flint',
      hex: '705861',
      rgb: {
        r: 112,
        g: 88,
        b: 97,
      },
      lab: {
        l: 40.25,
        a: 12.03,
        b: -0.87,
      },
      cmyk: null,
    },
    {
      code: '18-1708 TCX',
      name: 'Ephemera',
      hex: '705A66',
      rgb: {
        r: 112,
        g: 90,
        b: 102,
      },
      lab: {
        l: 40.91,
        a: 11.3,
        b: -2.95,
      },
      cmyk: null,
    },
    {
      code: '18-1706 TCX',
      name: 'Black Plum',
      hex: '6D5765',
      rgb: {
        r: 109,
        g: 87,
        b: 101,
      },
      lab: {
        l: 39.69,
        a: 11.58,
        b: -4.28,
      },
      cmyk: null,
    },
    {
      code: '19-3316 TCX',
      name: 'Plum Perfect',
      hex: '473442',
      rgb: {
        r: 71,
        g: 52,
        b: 66,
      },
      lab: {
        l: 24.55,
        a: 11.24,
        b: -4.6,
      },
      cmyk: null,
    },
    {
      code: '18-1411 TCX',
      name: 'Plum Wine',
      hex: '674550',
      rgb: {
        r: 103,
        g: 69,
        b: 80,
      },
      lab: {
        l: 33.89,
        a: 16.76,
        b: 0.09,
      },
      cmyk: null,
    },
    {
      code: '19-2311 TCX',
      name: 'Eggplant',
      hex: '623F4C',
      rgb: {
        r: 98,
        g: 63,
        b: 76,
      },
      lab: {
        l: 31.48,
        a: 17.67,
        b: -0.44,
      },
      cmyk: null,
    },
    {
      code: '19-1621 TCX',
      name: 'Catawba Grape',
      hex: '5D3C43',
      rgb: {
        r: 93,
        g: 60,
        b: 67,
      },
      lab: {
        l: 29.64,
        a: 17.05,
        b: 2.39,
      },
      cmyk: null,
    },
    {
      code: '19-1624 TCX',
      name: 'Sassafras',
      hex: '54353B',
      rgb: {
        r: 84,
        g: 53,
        b: 59,
      },
      lab: {
        l: 26.39,
        a: 15.79,
        b: 2.49,
      },
      cmyk: null,
    },
    {
      code: '19-1625 TCX',
      name: 'Decadent Chocolate',
      hex: '513235',
      rgb: {
        r: 81,
        g: 50,
        b: 53,
      },
      lab: {
        l: 24.98,
        a: 15.89,
        b: 4.67,
      },
      cmyk: null,
    },
    {
      code: '19-1623 TCX',
      name: 'Vineyard Wine',
      hex: '58363D',
      rgb: {
        r: 88,
        g: 54,
        b: 61,
      },
      lab: {
        l: 27.18,
        a: 17.1,
        b: 2.66,
      },
      cmyk: null,
    },
    {
      code: '19-1619 TCX',
      name: 'Fudge',
      hex: '493338',
      rgb: {
        r: 73,
        g: 51,
        b: 56,
      },
      lab: {
        l: 24.1,
        a: 11.79,
        b: 1.28,
      },
      cmyk: null,
    },
    {
      code: '19-1716 TCX',
      name: 'Mauve Wine',
      hex: '5B3644',
      rgb: {
        r: 91,
        g: 54,
        b: 68,
      },
      lab: {
        l: 27.88,
        a: 19.77,
        b: -1.04,
      },
      cmyk: null,
    },
    {
      code: '19-1718 TCX',
      name: 'Fig',
      hex: '532E3B',
      rgb: {
        r: 83,
        g: 46,
        b: 59,
      },
      lab: {
        l: 24.31,
        a: 19.55,
        b: 0.09,
      },
      cmyk: null,
    },
    {
      code: '19-1627 TCX',
      name: 'Port Royale',
      hex: '512B33',
      rgb: {
        r: 81,
        g: 43,
        b: 51,
      },
      lab: {
        l: 23.17,
        a: 19.26,
        b: 3.5,
      },
      cmyk: null,
    },
    {
      code: '19-1617 TCX',
      name: 'Burgundy',
      hex: '64313E',
      rgb: {
        r: 100,
        g: 49,
        b: 62,
      },
      lab: {
        l: 28.08,
        a: 25.59,
        b: 3.77,
      },
      cmyk: null,
    },
    {
      code: '19-1528 TCX',
      name: 'Windsor Wine',
      hex: '582B36',
      rgb: {
        r: 88,
        g: 43,
        b: 54,
      },
      lab: {
        l: 24.61,
        a: 22.89,
        b: 3.55,
      },
      cmyk: null,
    },
    {
      code: '19-1529 TCX',
      name: 'Maroon Banner',
      hex: '5B2B38',
      rgb: {
        r: 91,
        g: 43,
        b: 56,
      },
      lab: {
        l: 25.1,
        a: 25.17,
        b: 3,
      },
      cmyk: null,
    },
    {
      code: '19-2118 TCX',
      name: 'Winetasting',
      hex: '492A34',
      rgb: {
        r: 73,
        g: 42,
        b: 52,
      },
      lab: {
        l: 21.57,
        a: 16.78,
        b: 0.22,
      },
      cmyk: null,
    },
    {
      code: '18-1709 TCX',
      name: 'Tulipwood',
      hex: '805466',
      rgb: {
        r: 128,
        g: 84,
        b: 102,
      },
      lab: {
        l: 41.46,
        a: 22.12,
        b: -2.01,
      },
      cmyk: null,
    },
    {
      code: '18-1710 TCX',
      name: 'Grape Nectar',
      hex: '8D5C74',
      rgb: {
        r: 141,
        g: 92,
        b: 116,
      },
      lab: {
        l: 45.53,
        a: 24.25,
        b: -4.09,
      },
      cmyk: null,
    },
    {
      code: '19-2014 TCX',
      name: 'Prune',
      hex: '603749',
      rgb: {
        r: 96,
        g: 55,
        b: 73,
      },
      lab: {
        l: 29.14,
        a: 21.25,
        b: -2.03,
      },
      cmyk: null,
    },
    {
      code: '19-2315 TCX',
      name: 'Grape Wine',
      hex: '5A2F43',
      rgb: {
        r: 90,
        g: 47,
        b: 67,
      },
      lab: {
        l: 26.23,
        a: 23.06,
        b: -2.28,
      },
      cmyk: null,
    },
    {
      code: '19-1608 TCX',
      name: 'Prune Purple',
      hex: '5C3A4D',
      rgb: {
        r: 92,
        g: 58,
        b: 77,
      },
      lab: {
        l: 29.37,
        a: 18.49,
        b: -4.27,
      },
      cmyk: null,
    },
    {
      code: '19-2514 TCX',
      name: 'Italian Plum',
      hex: '533146',
      rgb: {
        r: 83,
        g: 49,
        b: 70,
      },
      lab: {
        l: 25.47,
        a: 18.8,
        b: -5.74,
      },
      cmyk: null,
    },
    {
      code: '19-2520 TCX',
      name: 'Potent Purple',
      hex: '462639',
      rgb: {
        r: 70,
        g: 38,
        b: 57,
      },
      lab: {
        l: 20.3,
        a: 18.46,
        b: -4.49,
      },
      cmyk: null,
    },
    {
      code: '17-1710 TCX',
      name: 'Bordeaux',
      hex: '96637B',
      rgb: {
        r: 150,
        g: 99,
        b: 123,
      },
      lab: {
        l: 48.5,
        a: 24.48,
        b: -3.86,
      },
      cmyk: null,
    },
    {
      code: '17-1612 TCX',
      name: 'Mellow Mauve',
      hex: '996378',
      rgb: {
        r: 153,
        g: 99,
        b: 120,
      },
      lab: {
        l: 48.92,
        a: 25.37,
        b: -1.21,
      },
      cmyk: null,
    },
    {
      code: '17-1818 TCX',
      name: 'Red Violet',
      hex: 'A35776',
      rgb: {
        r: 163,
        g: 87,
        b: 118,
      },
      lab: {
        l: 47.7,
        a: 36.29,
        b: -1.95,
      },
      cmyk: null,
    },
    {
      code: '18-1720 TCX',
      name: 'Violet Quartz',
      hex: '8B4963',
      rgb: {
        r: 139,
        g: 73,
        b: 99,
      },
      lab: {
        l: 40.42,
        a: 31.52,
        b: -0.84,
      },
      cmyk: null,
    },
    {
      code: '18-1716 TCX',
      name: 'Damson',
      hex: '854C65',
      rgb: {
        r: 133,
        g: 76,
        b: 101,
      },
      lab: {
        l: 40.42,
        a: 28.42,
        b: -2.54,
      },
      cmyk: null,
    },
    {
      code: '19-2410 TCX',
      name: 'Amaranth',
      hex: '6F3C56',
      rgb: {
        r: 111,
        g: 60,
        b: 86,
      },
      lab: {
        l: 33.06,
        a: 25.74,
        b: -4.03,
      },
      cmyk: null,
    },
    {
      code: '19-2312 TCX',
      name: 'Crushed Violets',
      hex: '643A4C',
      rgb: {
        r: 100,
        g: 58,
        b: 76,
      },
      lab: {
        l: 30.6,
        a: 21.71,
        b: -1.89,
      },
      cmyk: null,
    },
    {
      code: '14-3204 TCX',
      name: 'Fragrant Lilac',
      hex: 'CFADBE',
      rgb: {
        r: 207,
        g: 173,
        b: 190,
      },
      lab: {
        l: 74.32,
        a: 15.13,
        b: -3.39,
      },
      cmyk: null,
    },
    {
      code: '15-3207 TCX',
      name: 'Mauve Mist',
      hex: 'C49DB4',
      rgb: {
        r: 196,
        g: 157,
        b: 180,
      },
      lab: {
        l: 69.19,
        a: 18.39,
        b: -5.6,
      },
      cmyk: null,
    },
    {
      code: '16-2107 TCX',
      name: 'Orchid Haze',
      hex: 'B0879B',
      rgb: {
        r: 176,
        g: 135,
        b: 155,
      },
      lab: {
        l: 61.15,
        a: 18.87,
        b: -3.28,
      },
      cmyk: null,
    },
    {
      code: '18-3011 TCX',
      name: 'Argyle Purple',
      hex: '895C79',
      rgb: {
        r: 137,
        g: 92,
        b: 121,
      },
      lab: {
        l: 45.12,
        a: 22.9,
        b: -7.79,
      },
      cmyk: null,
    },
    {
      code: '18-3211 TCX',
      name: 'Grapeade',
      hex: '85677B',
      rgb: {
        r: 133,
        g: 103,
        b: 123,
      },
      lab: {
        l: 47.4,
        a: 15.42,
        b: -6.05,
      },
      cmyk: null,
    },
    {
      code: '18-3012 TCX',
      name: 'Purple Gumdrop',
      hex: '7A596F',
      rgb: {
        r: 122,
        g: 89,
        b: 111,
      },
      lab: {
        l: 42.03,
        a: 17.23,
        b: -6.49,
      },
      cmyk: null,
    },
    {
      code: '18-3013 TCX',
      name: 'Berry Conserve',
      hex: '765269',
      rgb: {
        r: 118,
        g: 82,
        b: 105,
      },
      lab: {
        l: 39.84,
        a: 18.46,
        b: -6.28,
      },
      cmyk: null,
    },
    {
      code: '16-3307 TCX',
      name: 'Lavender Mist',
      hex: 'AE90A7',
      rgb: {
        r: 174,
        g: 144,
        b: 167,
      },
      lab: {
        l: 63.39,
        a: 14.82,
        b: -7.3,
      },
      cmyk: null,
    },
    {
      code: '17-3410 TCX',
      name: 'Valerian',
      hex: '9F7A93',
      rgb: {
        r: 159,
        g: 122,
        b: 147,
      },
      lab: {
        l: 55.86,
        a: 17.9,
        b: -6.9,
      },
      cmyk: null,
    },
    {
      code: '18-3220 TCX',
      name: 'Very Grape',
      hex: '927288',
      rgb: {
        r: 146,
        g: 114,
        b: 136,
      },
      lab: {
        l: 52.05,
        a: 15.97,
        b: -6.41,
      },
      cmyk: null,
    },
    {
      code: '18-3418 TCX',
      name: 'Chinese Violet',
      hex: '835E81',
      rgb: {
        r: 131,
        g: 94,
        b: 129,
      },
      lab: {
        l: 45.14,
        a: 19.79,
        b: -12.62,
      },
      cmyk: null,
    },
    {
      code: '18-3218 TCX',
      name: 'Concord Grape',
      hex: '7C5379',
      rgb: {
        r: 124,
        g: 83,
        b: 121,
      },
      lab: {
        l: 41.2,
        a: 22.4,
        b: -13.7,
      },
      cmyk: null,
    },
    {
      code: '19-3424 TCX',
      name: 'Sunset Purple',
      hex: '6F456E',
      rgb: {
        r: 111,
        g: 69,
        b: 110,
      },
      lab: {
        l: 35.8,
        a: 24.5,
        b: -15.16,
      },
      cmyk: null,
    },
    {
      code: '18-3415 TCX',
      name: 'Grape Jam',
      hex: '725671',
      rgb: {
        r: 114,
        g: 86,
        b: 113,
      },
      lab: {
        l: 40.51,
        a: 15.56,
        b: -10.01,
      },
      cmyk: null,
    },
    {
      code: '18-3522 TCX',
      name: 'Crushed Grape',
      hex: '7A547F',
      rgb: {
        r: 122,
        g: 84,
        b: 127,
      },
      lab: {
        l: 41.58,
        a: 21.51,
        b: -16.59,
      },
      cmyk: null,
    },
    {
      code: '19-3325 TCX',
      name: 'Wood Violet',
      hex: '75406A',
      rgb: {
        r: 117,
        g: 64,
        b: 106,
      },
      lab: {
        l: 35.34,
        a: 29.05,
        b: -13.7,
      },
      cmyk: null,
    },
    {
      code: '19-3223 TCX',
      name: 'Purple Passion',
      hex: '683D62',
      rgb: {
        r: 104,
        g: 61,
        b: 98,
      },
      lab: {
        l: 32.55,
        a: 24.67,
        b: -12.6,
      },
      cmyk: null,
    },
    {
      code: '19-2524 TCX',
      name: 'Dark Purple',
      hex: '592147',
      rgb: {
        r: 89,
        g: 33,
        b: 71,
      },
      lab: {
        l: 23.13,
        a: 30.14,
        b: -9.59,
      },
      cmyk: null,
    },
    {
      code: '19-2420 TCX',
      name: 'Pickled Beet',
      hex: '4D243D',
      rgb: {
        r: 77,
        g: 36,
        b: 61,
      },
      lab: {
        l: 21.13,
        a: 23.17,
        b: -6.09,
      },
      cmyk: null,
    },
    {
      code: '19-2620 TCX',
      name: 'Winter Bloom',
      hex: '47253C',
      rgb: {
        r: 71,
        g: 37,
        b: 60,
      },
      lab: {
        l: 20.31,
        a: 19.62,
        b: -6.53,
      },
      cmyk: null,
    },
    {
      code: '19-2816 TCX',
      name: 'Blackberry Wine',
      hex: '4E3246',
      rgb: {
        r: 78,
        g: 50,
        b: 70,
      },
      lab: {
        l: 25.05,
        a: 15.98,
        b: -6.36,
      },
      cmyk: null,
    },
    {
      code: '14-3206 TCX',
      name: 'Winsome Orchid',
      hex: 'D4B9CB',
      rgb: {
        r: 212,
        g: 185,
        b: 203,
      },
      lab: {
        l: 78.07,
        a: 12.5,
        b: -5.26,
      },
      cmyk: null,
    },
    {
      code: '16-3110 TCX',
      name: 'Smoky Grape',
      hex: 'B88AAC',
      rgb: {
        r: 184,
        g: 138,
        b: 172,
      },
      lab: {
        l: 63.08,
        a: 22.83,
        b: -10.42,
      },
      cmyk: null,
    },
    {
      code: '17-3313 TCX',
      name: 'Dusty Lavender',
      hex: 'A1759C',
      rgb: {
        r: 161,
        g: 117,
        b: 156,
      },
      lab: {
        l: 55.13,
        a: 22.61,
        b: -12.81,
      },
      cmyk: null,
    },
    {
      code: '16-3310 TCX',
      name: 'Lavender Herb',
      hex: 'B18EAA',
      rgb: {
        r: 177,
        g: 142,
        b: 170,
      },
      lab: {
        l: 63.3,
        a: 17.13,
        b: -8.99,
      },
      cmyk: null,
    },
    {
      code: '17-3520 TCX',
      name: 'Diffused Orchid',
      hex: '997AA3',
      rgb: {
        r: 153,
        g: 122,
        b: 163,
      },
      lab: {
        l: 55.56,
        a: 18.07,
        b: -16.45,
      },
      cmyk: null,
    },
    {
      code: '17-3612 TCX',
      name: 'Orchid Mist',
      hex: '917798',
      rgb: {
        r: 145,
        g: 119,
        b: 152,
      },
      lab: {
        l: 53.74,
        a: 14.98,
        b: -13.26,
      },
      cmyk: null,
    },
    {
      code: '18-3530 TCX',
      name: 'Lavender Crystal',
      hex: '936B99',
      rgb: {
        r: 147,
        g: 107,
        b: 153,
      },
      lab: {
        l: 50.86,
        a: 22.67,
        b: -17.85,
      },
      cmyk: null,
    },
    {
      code: '15-3520 TCX',
      name: 'Crocus Petal',
      hex: 'B99BC5',
      rgb: {
        r: 185,
        g: 155,
        b: 197,
      },
      lab: {
        l: 68.1,
        a: 17.14,
        b: -16.67,
      },
      cmyk: null,
    },
    {
      code: '16-3521 TCX',
      name: 'Lupine',
      hex: 'BE9CC1',
      rgb: {
        r: 190,
        g: 156,
        b: 193,
      },
      lab: {
        l: 68.56,
        a: 18.16,
        b: -13.64,
      },
      cmyk: null,
    },
    {
      code: '16-3617 TCX',
      name: 'Sheer Lilac',
      hex: 'B793C0',
      rgb: {
        r: 183,
        g: 147,
        b: 192,
      },
      lab: {
        l: 65.64,
        a: 20.4,
        b: -17.52,
      },
      cmyk: null,
    },
    {
      code: '16-3520 TCX',
      name: 'African Violet',
      hex: 'B085B7',
      rgb: {
        r: 176,
        g: 133,
        b: 183,
      },
      lab: {
        l: 61.32,
        a: 23.49,
        b: -19.24,
      },
      cmyk: null,
    },
    {
      code: '17-3617 TCX',
      name: 'English Lavender',
      hex: '9D7BB0',
      rgb: {
        r: 157,
        g: 123,
        b: 176,
      },
      lab: {
        l: 56.58,
        a: 21.12,
        b: -22.42,
      },
      cmyk: null,
    },
    {
      code: '17-3640 TCX',
      name: 'Fairy Wren',
      hex: '957AB0',
      rgb: {
        r: 149,
        g: 122,
        b: 176,
      },
      lab: {
        l: 55.4,
        a: 18.43,
        b: -24.1,
      },
      cmyk: null,
    },
    {
      code: '18-3628 TCX',
      name: 'Bellflower',
      hex: '9469A2',
      rgb: {
        r: 148,
        g: 105,
        b: 162,
      },
      lab: {
        l: 50.99,
        a: 25.13,
        b: -22.98,
      },
      cmyk: null,
    },
    {
      code: '13-3802 TCX',
      name: 'Orchid Tint',
      hex: 'DBD2DB',
      rgb: {
        r: 219,
        g: 210,
        b: 219,
      },
      lab: {
        l: 85.24,
        a: 4.45,
        b: -3.09,
      },
      cmyk: null,
    },
    {
      code: '13-3820 TCX',
      name: 'Lavender Fog',
      hex: 'D2C4D6',
      rgb: {
        r: 210,
        g: 196,
        b: 214,
      },
      lab: {
        l: 80.89,
        a: 7.34,
        b: -6.79,
      },
      cmyk: null,
    },
    {
      code: '13-3805 TCX',
      name: 'Orchid Hush',
      hex: 'CEC3D2',
      rgb: {
        r: 206,
        g: 195,
        b: 210,
      },
      lab: {
        l: 80.18,
        a: 5.99,
        b: -5.62,
      },
      cmyk: null,
    },
    {
      code: '15-3507 TCX',
      name: 'Lavender Frost',
      hex: 'BDABBE',
      rgb: {
        r: 189,
        g: 171,
        b: 190,
      },
      lab: {
        l: 72.06,
        a: 9.4,
        b: -7.1,
      },
      cmyk: null,
    },
    {
      code: '15-3508 TCX',
      name: 'Fair Orchid',
      hex: 'C0AAC0',
      rgb: {
        r: 192,
        g: 170,
        b: 192,
      },
      lab: {
        l: 72.16,
        a: 11.48,
        b: -8.06,
      },
      cmyk: null,
    },
    {
      code: '16-3525 TCX',
      name: 'Regal Orchid',
      hex: 'A98BAF',
      rgb: {
        r: 169,
        g: 139,
        b: 175,
      },
      lab: {
        l: 61.88,
        a: 17.07,
        b: -14.11,
      },
      cmyk: null,
    },
    {
      code: '16-3817 TCX',
      name: 'Rhapsody',
      hex: '9F86AA',
      rgb: {
        r: 159,
        g: 134,
        b: 170,
      },
      lab: {
        l: 59.49,
        a: 14.87,
        b: -14.67,
      },
      cmyk: null,
    },
    {
      code: '14-3612 TCX',
      name: 'Orchid Bloom',
      hex: 'C5AECF',
      rgb: {
        r: 197,
        g: 174,
        b: 207,
      },
      lab: {
        l: 73.9,
        a: 13.34,
        b: -13.26,
      },
      cmyk: null,
    },
    {
      code: '15-3620 TCX',
      name: 'Lavendula',
      hex: 'BCA4CB',
      rgb: {
        r: 188,
        g: 164,
        b: 203,
      },
      lab: {
        l: 70.75,
        a: 14.21,
        b: -16.24,
      },
      cmyk: null,
    },
    {
      code: '15-3720 TCX',
      name: 'Lilac Breeze',
      hex: 'B4A0C9',
      rgb: {
        r: 180,
        g: 160,
        b: 201,
      },
      lab: {
        l: 68.84,
        a: 13.02,
        b: -18.04,
      },
      cmyk: null,
    },
    {
      code: '15-3716 TCX',
      name: 'Purple Rose',
      hex: 'B09FCA',
      rgb: {
        r: 176,
        g: 159,
        b: 202,
      },
      lab: {
        l: 68.31,
        a: 12.41,
        b: -19.21,
      },
      cmyk: null,
    },
    {
      code: '16-3815 TCX',
      name: 'Viola',
      hex: 'A692BA',
      rgb: {
        r: 166,
        g: 146,
        b: 186,
      },
      lab: {
        l: 63.38,
        a: 13.76,
        b: -17.84,
      },
      cmyk: null,
    },
    {
      code: '16-3720 TCX',
      name: 'Sand Verbena',
      hex: '9F90C1',
      rgb: {
        r: 159,
        g: 144,
        b: 193,
      },
      lab: {
        l: 62.71,
        a: 12.67,
        b: -22.74,
      },
      cmyk: null,
    },
    {
      code: '17-3735 TCX',
      name: 'Sunlit Allium',
      hex: '9787B8',
      rgb: {
        r: 151,
        g: 135,
        b: 184,
      },
      lab: {
        l: 59.39,
        a: 13.1,
        b: -22.85,
      },
      cmyk: null,
    },
    {
      code: '17-3619 TCX',
      name: 'Hyacinth',
      hex: '936CA7',
      rgb: {
        r: 147,
        g: 108,
        b: 167,
      },
      lab: {
        l: 51.7,
        a: 23.97,
        b: -24.84,
      },
      cmyk: null,
    },
    {
      code: '17-3628 TCX',
      name: 'Amethyst Orchid',
      hex: '926AA6',
      rgb: {
        r: 146,
        g: 106,
        b: 166,
      },
      lab: {
        l: 51.03,
        a: 24.68,
        b: -25.14,
      },
      cmyk: null,
    },
    {
      code: '18-3533 TCX',
      name: 'Dewberry',
      hex: '8B5897',
      rgb: {
        r: 139,
        g: 88,
        b: 151,
      },
      lab: {
        l: 45.47,
        a: 29.78,
        b: -24.99,
      },
      cmyk: null,
    },
    {
      code: '19-3438 TCX',
      name: 'Bright Violet',
      hex: '794384',
      rgb: {
        r: 121,
        g: 67,
        b: 132,
      },
      lab: {
        l: 37.57,
        a: 31.54,
        b: -25.7,
      },
      cmyk: null,
    },
    {
      code: '18-3634 TCX',
      name: 'Chive Blossom',
      hex: '7E5E9A',
      rgb: {
        r: 126,
        g: 94,
        b: 154,
      },
      lab: {
        l: 45.22,
        a: 21.75,
        b: -27.1,
      },
      cmyk: null,
    },
    {
      code: '19-3526 TCX',
      name: 'Meadow Violet',
      hex: '764F82',
      rgb: {
        r: 118,
        g: 79,
        b: 130,
      },
      lab: {
        l: 39.86,
        a: 24.09,
        b: -21.42,
      },
      cmyk: null,
    },
    {
      code: '19-3425 TCX',
      name: 'Spiced Plum',
      hex: '6E4874',
      rgb: {
        r: 110,
        g: 72,
        b: 116,
      },
      lab: {
        l: 36.58,
        a: 22.69,
        b: -17.69,
      },
      cmyk: null,
    },
    {
      code: '18-3633 TCX',
      name: 'Deep Lavender',
      hex: '775496',
      rgb: {
        r: 119,
        g: 84,
        b: 150,
      },
      lab: {
        l: 41.87,
        a: 24.68,
        b: -30.14,
      },
      cmyk: null,
    },
    {
      code: '18-3531 TCX',
      name: 'Royal Lilac',
      hex: '774D8E',
      rgb: {
        r: 119,
        g: 77,
        b: 142,
      },
      lab: {
        l: 40.13,
        a: 27.12,
        b: -28.43,
      },
      cmyk: null,
    },
    {
      code: '19-3542 TCX',
      name: 'Pansy',
      hex: '653D7C',
      rgb: {
        r: 101,
        g: 61,
        b: 124,
      },
      lab: {
        l: 33.16,
        a: 26.5,
        b: -28.19,
      },
      cmyk: null,
    },
    {
      code: '19-3536 TCX',
      name: 'Amaranth Purple',
      hex: '6A397B',
      rgb: {
        r: 106,
        g: 57,
        b: 123,
      },
      lab: {
        l: 33.09,
        a: 30.29,
        b: -27.41,
      },
      cmyk: null,
    },
    {
      code: '19-3540 TCX',
      name: 'Purple Magic',
      hex: '663271',
      rgb: {
        r: 102,
        g: 50,
        b: 113,
      },
      lab: {
        l: 30.53,
        a: 31.22,
        b: -25.59,
      },
      cmyk: null,
    },
    {
      code: '19-3528 TCX',
      name: 'Imperial Purple',
      hex: '542B5D',
      rgb: {
        r: 84,
        g: 43,
        b: 93,
      },
      lab: {
        l: 25.38,
        a: 25.56,
        b: -20.9,
      },
      cmyk: null,
    },
    {
      code: '19-3220 TCX',
      name: 'Plum',
      hex: '5A315D',
      rgb: {
        r: 90,
        g: 49,
        b: 93,
      },
      lab: {
        l: 27.51,
        a: 25.05,
        b: -17.64,
      },
      cmyk: null,
    },
    {
      code: '18-3635 TCX',
      name: 'Picasso Lily',
      hex: '644878',
      rgb: {
        r: 100,
        g: 72,
        b: 120,
      },
      lab: {
        l: 35.54,
        a: 19.2,
        b: -22.18,
      },
      cmyk: null,
    },
    {
      code: '18-3520 TCX',
      name: 'Purple Heart',
      hex: '745587',
      rgb: {
        r: 116,
        g: 85,
        b: 135,
      },
      lab: {
        l: 41.26,
        a: 20.82,
        b: -22.37,
      },
      cmyk: null,
    },
    {
      code: '18-3615 TCX',
      name: 'Imperial Palace',
      hex: '604E7A',
      rgb: {
        r: 96,
        g: 78,
        b: 122,
      },
      lab: {
        l: 36.75,
        a: 14.42,
        b: -21.37,
      },
      cmyk: null,
    },
    {
      code: '18-3518 TCX',
      name: 'Patrician Purple',
      hex: '6C4E79',
      rgb: {
        r: 108,
        g: 78,
        b: 121,
      },
      lab: {
        l: 38.09,
        a: 19.67,
        b: -18.89,
      },
      cmyk: null,
    },
    {
      code: '18-3521 TCX',
      name: 'Plum Jam',
      hex: '634177',
      rgb: {
        r: 99,
        g: 65,
        b: 119,
      },
      lab: {
        l: 33.48,
        a: 22.69,
        b: -24.53,
      },
      cmyk: null,
    },
    {
      code: '18-3540 TCX',
      name: 'Purple Sapphire',
      hex: '6F4785',
      rgb: {
        r: 111,
        g: 71,
        b: 133,
      },
      lab: {
        l: 37.27,
        a: 25.97,
        b: -27.27,
      },
      cmyk: null,
    },
    {
      code: '19-3514 TCX',
      name: 'Majesty',
      hex: '593761',
      rgb: {
        r: 89,
        g: 55,
        b: 97,
      },
      lab: {
        l: 28.93,
        a: 20.91,
        b: -17.73,
      },
      cmyk: null,
    },
    {
      code: '19-3518 TCX',
      name: 'Grape Royale',
      hex: '4F2D54',
      rgb: {
        r: 79,
        g: 45,
        b: 84,
      },
      lab: {
        l: 24.62,
        a: 21.53,
        b: -16.48,
      },
      cmyk: null,
    },
    {
      code: '19-3632 TCX',
      name: 'Petunia',
      hex: '4F3466',
      rgb: {
        r: 79,
        g: 52,
        b: 102,
      },
      lab: {
        l: 27.1,
        a: 19.93,
        b: -23.99,
      },
      cmyk: null,
    },
    {
      code: '19-3628 TCX',
      name: 'Acai',
      hex: '46295A',
      rgb: {
        r: 70,
        g: 41,
        b: 90,
      },
      lab: {
        l: 22.44,
        a: 21.38,
        b: -23.61,
      },
      cmyk: null,
    },
    {
      code: '19-3640 TCX',
      name: 'Crown Jewel',
      hex: '482D54',
      rgb: {
        r: 72,
        g: 45,
        b: 84,
      },
      lab: {
        l: 23.48,
        a: 18.12,
        b: -17.74,
      },
      cmyk: null,
    },
    {
      code: '19-3218 TCX',
      name: 'Plum Purple',
      hex: '51304E',
      rgb: {
        r: 81,
        g: 48,
        b: 78,
      },
      lab: {
        l: 25.34,
        a: 19.72,
        b: -10.91,
      },
      cmyk: null,
    },
    {
      code: '18-3410 TCX',
      name: 'Vintage Violet',
      hex: '634F62',
      rgb: {
        r: 99,
        g: 79,
        b: 98,
      },
      lab: {
        l: 36.44,
        a: 11.32,
        b: -7.27,
      },
      cmyk: null,
    },
    {
      code: '19-3217 TCX',
      name: 'Shadow Purple',
      hex: '4E334E',
      rgb: {
        r: 78,
        g: 51,
        b: 78,
      },
      lab: {
        l: 25.64,
        a: 16.24,
        b: -10.73,
      },
      cmyk: null,
    },
    {
      code: '18-3513 TCX',
      name: 'Grape Compote',
      hex: '6B5876',
      rgb: {
        r: 107,
        g: 88,
        b: 118,
      },
      lab: {
        l: 40.18,
        a: 13.05,
        b: -13.65,
      },
      cmyk: null,
    },
    {
      code: '19-2814 TCX',
      name: 'Wineberry',
      hex: '5A395B',
      rgb: {
        r: 90,
        g: 57,
        b: 91,
      },
      lab: {
        l: 29.19,
        a: 20.26,
        b: -13.54,
      },
      cmyk: null,
    },
    {
      code: '19-3323 TCX',
      name: 'Deep Purple',
      hex: '50314C',
      rgb: {
        r: 80,
        g: 49,
        b: 76,
      },
      lab: {
        l: 25.4,
        a: 18.63,
        b: -9.72,
      },
      cmyk: null,
    },
    {
      code: '19-2009 TCX',
      name: 'Hortensia',
      hex: '553B50',
      rgb: {
        r: 85,
        g: 59,
        b: 80,
      },
      lab: {
        l: 28.8,
        a: 14.61,
        b: -7.38,
      },
      cmyk: null,
    },
    {
      code: '19-3714 TCX',
      name: 'Navy Cosmos',
      hex: '503B53',
      rgb: {
        r: 80,
        g: 59,
        b: 83,
      },
      lab: {
        l: 28.05,
        a: 13.5,
        b: -10.61,
      },
      cmyk: null,
    },
    {
      code: '19-3519 TCX',
      name: 'Purple Pennant',
      hex: '432C47',
      rgb: {
        r: 67,
        g: 44,
        b: 71,
      },
      lab: {
        l: 21.93,
        a: 15.04,
        b: -12.21,
      },
      cmyk: null,
    },
    {
      code: '19-3520 TCX',
      name: 'Blackberry Cordial',
      hex: '402A47',
      rgb: {
        r: 64,
        g: 42,
        b: 71,
      },
      lab: {
        l: 20.9,
        a: 14.72,
        b: -13.8,
      },
      cmyk: null,
    },
    {
      code: '18-3714 TCX',
      name: 'Mulled Grape',
      hex: '675A74',
      rgb: {
        r: 103,
        g: 90,
        b: 116,
      },
      lab: {
        l: 40.38,
        a: 9.68,
        b: -12.27,
      },
      cmyk: null,
    },
    {
      code: '18-3715 TCX',
      name: 'Montana Grape',
      hex: '6C5971',
      rgb: {
        r: 108,
        g: 89,
        b: 113,
      },
      lab: {
        l: 40.64,
        a: 11.78,
        b: -10.4,
      },
      cmyk: null,
    },
    {
      code: '18-3620 TCX',
      name: 'Mystical',
      hex: '5F4E72',
      rgb: {
        r: 95,
        g: 78,
        b: 114,
      },
      lab: {
        l: 36.05,
        a: 13.6,
        b: -17.63,
      },
      cmyk: null,
    },
    {
      code: '19-3622 TCX',
      name: 'Loganberry',
      hex: '5A4769',
      rgb: {
        r: 90,
        g: 71,
        b: 105,
      },
      lab: {
        l: 33.49,
        a: 14.42,
        b: -16.51,
      },
      cmyk: null,
    },
    {
      code: '19-3620 TCX',
      name: 'Purple Reign',
      hex: '56456B',
      rgb: {
        r: 86,
        g: 69,
        b: 107,
      },
      lab: {
        l: 32.45,
        a: 13.86,
        b: -19.21,
      },
      cmyk: null,
    },
    {
      code: '19-3215 TCX',
      name: 'Indigo',
      hex: '4C3957',
      rgb: {
        r: 76,
        g: 57,
        b: 87,
      },
      lab: {
        l: 27.23,
        a: 13.82,
        b: -14.43,
      },
      cmyk: null,
    },
    {
      code: '19-3619 TCX',
      name: 'Sweet Grape',
      hex: '4B3B4F',
      rgb: {
        r: 75,
        g: 59,
        b: 79,
      },
      lab: {
        l: 27.44,
        a: 10.11,
        b: -8.74,
      },
      cmyk: null,
    },
    {
      code: '19-3722 TCX',
      name: 'Mulberry Purple',
      hex: '493C61',
      rgb: {
        r: 73,
        g: 60,
        b: 97,
      },
      lab: {
        l: 28.25,
        a: 12.61,
        b: -19.65,
      },
      cmyk: null,
    },
    {
      code: '19-3716 TCX',
      name: 'Purple Plumeria',
      hex: '473854',
      rgb: {
        r: 71,
        g: 56,
        b: 84,
      },
      lab: {
        l: 26.34,
        a: 11.37,
        b: -13.73,
      },
      cmyk: null,
    },
    {
      code: '19-3720 TCX',
      name: 'Gothic Grape',
      hex: '473951',
      rgb: {
        r: 71,
        g: 57,
        b: 81,
      },
      lab: {
        l: 26.27,
        a: 10.66,
        b: -12.03,
      },
      cmyk: null,
    },
    {
      code: '19-3617 TCX',
      name: 'Mysterioso',
      hex: '46394B',
      rgb: {
        r: 70,
        g: 57,
        b: 75,
      },
      lab: {
        l: 25.91,
        a: 9.1,
        b: -8.91,
      },
      cmyk: null,
    },
    {
      code: '19-3712 TCX',
      name: 'Nightshade',
      hex: '433748',
      rgb: {
        r: 67,
        g: 55,
        b: 72,
      },
      lab: {
        l: 25.19,
        a: 8.38,
        b: -8.11,
      },
      cmyk: null,
    },
    {
      code: '19-3725 TCX',
      name: 'Purple Velvet',
      hex: '41354D',
      rgb: {
        r: 65,
        g: 53,
        b: 77,
      },
      lab: {
        l: 24.36,
        a: 10.16,
        b: -12.86,
      },
      cmyk: null,
    },
    {
      code: '19-3728 TCX',
      name: 'Grape',
      hex: '433455',
      rgb: {
        r: 67,
        g: 52,
        b: 85,
      },
      lab: {
        l: 24.76,
        a: 12.37,
        b: -16.67,
      },
      cmyk: null,
    },
    {
      code: '18-3834 TCX',
      name: 'Veronica',
      hex: '6D6695',
      rgb: {
        r: 109,
        g: 102,
        b: 149,
      },
      lab: {
        l: 45.32,
        a: 11.26,
        b: -24.58,
      },
      cmyk: null,
    },
    {
      code: '18-3840 TCX',
      name: 'Purple Opulence',
      hex: '60569A',
      rgb: {
        r: 96,
        g: 86,
        b: 154,
      },
      lab: {
        l: 40.06,
        a: 16.6,
        b: -36.11,
      },
      cmyk: null,
    },
    {
      code: '19-3850 TCX',
      name: 'Liberty',
      hex: '4E4489',
      rgb: {
        r: 78,
        g: 68,
        b: 137,
      },
      lab: {
        l: 32.63,
        a: 17.26,
        b: -37.2,
      },
      cmyk: null,
    },
    {
      code: '19-3847 TCX',
      name: 'Deep Blue',
      hex: '44377B',
      rgb: {
        r: 68,
        g: 55,
        b: 123,
      },
      lab: {
        l: 27.61,
        a: 18.59,
        b: -36.94,
      },
      cmyk: null,
    },
    {
      code: '19-3737 TCX',
      name: 'Heliotrope',
      hex: '4F3872',
      rgb: {
        r: 79,
        g: 56,
        b: 114,
      },
      lab: {
        l: 28.67,
        a: 20.3,
        b: -29.53,
      },
      cmyk: null,
    },
    {
      code: '19-3750 TCX',
      name: 'Violet Indigo',
      hex: '3E295C',
      rgb: {
        r: 62,
        g: 41,
        b: 92,
      },
      lab: {
        l: 21.48,
        a: 18.35,
        b: -26.76,
      },
      cmyk: null,
    },
    {
      code: '19-3731 TCX',
      name: 'Parachute Purple',
      hex: '392852',
      rgb: {
        r: 57,
        g: 40,
        b: 82,
      },
      lab: {
        l: 19.97,
        a: 14.88,
        b: -22.17,
      },
      cmyk: null,
    },
    {
      code: '17-3834 TCX',
      name: 'Dahlia Purple',
      hex: '7E6EAC',
      rgb: {
        r: 126,
        g: 110,
        b: 172,
      },
      lab: {
        l: 50.13,
        a: 16.42,
        b: -30.48,
      },
      cmyk: null,
    },
    {
      code: '18-3737 TCX',
      name: 'Passion Flower',
      hex: '6D5698',
      rgb: {
        r: 109,
        g: 86,
        b: 152,
      },
      lab: {
        l: 41.41,
        a: 20.28,
        b: -32.33,
      },
      cmyk: null,
    },
    {
      code: '18-3839 TCX',
      name: 'Purple Corallites',
      hex: '5B4F8F',
      rgb: {
        r: 91,
        g: 79,
        b: 143,
      },
      lab: {
        l: 37.26,
        a: 15.92,
        b: -33.5,
      },
      cmyk: null,
    },
    {
      code: '18-3838 TCX',
      name: 'Ultra Violet',
      hex: '5F4B8B',
      rgb: {
        r: 95,
        g: 75,
        b: 139,
      },
      lab: {
        l: 36.47,
        a: 19.51,
        b: -32.68,
      },
      cmyk: null,
    },
    {
      code: '19-3748 TCX',
      name: 'Prism Violet',
      hex: '543D7D',
      rgb: {
        r: 84,
        g: 61,
        b: 125,
      },
      lab: {
        l: 31.01,
        a: 20.52,
        b: -32.29,
      },
      cmyk: null,
    },
    {
      code: '19-3642 TCX',
      name: 'Royal Purple',
      hex: '603F83',
      rgb: {
        r: 96,
        g: 63,
        b: 131,
      },
      lab: {
        l: 33.21,
        a: 24.86,
        b: -32.29,
      },
      cmyk: null,
    },
    {
      code: '19-3638 TCX',
      name: 'Tillandsia Purple',
      hex: '563474',
      rgb: {
        r: 86,
        g: 52,
        b: 116,
      },
      lab: {
        l: 28.51,
        a: 25.15,
        b: -30.64,
      },
      cmyk: null,
    },
    {
      code: '14-3812 TCX',
      name: 'Pastel Lilac',
      hex: 'BCAFCF',
      rgb: {
        r: 188,
        g: 175,
        b: 207,
      },
      lab: {
        l: 73.37,
        a: 9.42,
        b: -14.29,
      },
      cmyk: null,
    },
    {
      code: '15-3817 TCX',
      name: 'Lavender',
      hex: 'AFA4CE',
      rgb: {
        r: 175,
        g: 164,
        b: 206,
      },
      lab: {
        l: 69.48,
        a: 10.28,
        b: -20.03,
      },
      cmyk: null,
    },
    {
      code: '16-3823 TCX',
      name: 'Violet Tulip',
      hex: '9E91C3',
      rgb: {
        r: 158,
        g: 145,
        b: 195,
      },
      lab: {
        l: 62.77,
        a: 12.51,
        b: -23.67,
      },
      cmyk: null,
    },
    {
      code: '17-3725 TCX',
      name: 'Bougainvillea',
      hex: '9883B9',
      rgb: {
        r: 152,
        g: 131,
        b: 185,
      },
      lab: {
        l: 58.51,
        a: 16.04,
        b: -24.75,
      },
      cmyk: null,
    },
    {
      code: '17-3730 TCX',
      name: 'Paisley Purple',
      hex: '8B79B1',
      rgb: {
        r: 139,
        g: 121,
        b: 177,
      },
      lab: {
        l: 54.22,
        a: 15.77,
        b: -26.75,
      },
      cmyk: null,
    },
    {
      code: '17-3826 TCX',
      name: 'Aster Purple',
      hex: '7D74A8',
      rgb: {
        r: 125,
        g: 116,
        b: 168,
      },
      lab: {
        l: 51.31,
        a: 11.76,
        b: -26.19,
      },
      cmyk: null,
    },
    {
      code: '19-3730 TCX',
      name: 'Gentian Violet',
      hex: '544275',
      rgb: {
        r: 84,
        g: 66,
        b: 117,
      },
      lab: {
        l: 31.95,
        a: 16.38,
        b: -26.06,
      },
      cmyk: null,
    },
    {
      code: '14-3710 TCX',
      name: 'Orchid Petal',
      hex: 'BFB4CB',
      rgb: {
        r: 191,
        g: 180,
        b: 203,
      },
      lab: {
        l: 74.88,
        a: 7.45,
        b: -9.9,
      },
      cmyk: null,
    },
    {
      code: '16-3810 TCX',
      name: 'Wisteria',
      hex: 'A198AF',
      rgb: {
        r: 161,
        g: 152,
        b: 175,
      },
      lab: {
        l: 64.1,
        a: 6.93,
        b: -10.58,
      },
      cmyk: null,
    },
    {
      code: '16-3812 TCX',
      name: 'Heirloom Lilac',
      hex: '9D96B2',
      rgb: {
        r: 157,
        g: 150,
        b: 178,
      },
      lab: {
        l: 63.53,
        a: 6.65,
        b: -13.45,
      },
      cmyk: null,
    },
    {
      code: '17-3817 TCX',
      name: 'Daybreak',
      hex: '8981A0',
      rgb: {
        r: 137,
        g: 129,
        b: 160,
      },
      lab: {
        l: 55.45,
        a: 8.04,
        b: -15.32,
      },
      cmyk: null,
    },
    {
      code: '17-3812 TCX',
      name: 'Dusk',
      hex: '897F98',
      rgb: {
        r: 137,
        g: 127,
        b: 152,
      },
      lab: {
        l: 54.88,
        a: 7.65,
        b: -11.53,
      },
      cmyk: null,
    },
    {
      code: '17-3615 TCX',
      name: 'Chalk Violet',
      hex: '8F7DA5',
      rgb: {
        r: 143,
        g: 125,
        b: 165,
      },
      lab: {
        l: 55.32,
        a: 13.11,
        b: -18.63,
      },
      cmyk: null,
    },
    {
      code: '18-3718 TCX',
      name: 'Purple Haze',
      hex: '817396',
      rgb: {
        r: 129,
        g: 115,
        b: 150,
      },
      lab: {
        l: 50.73,
        a: 11.1,
        b: -16.87,
      },
      cmyk: null,
    },
    {
      code: '15-3807 TCX',
      name: 'Misty Lilac',
      hex: 'BCB4C4',
      rgb: {
        r: 188,
        g: 180,
        b: 196,
      },
      lab: {
        l: 74.5,
        a: 5.08,
        b: -6.8,
      },
      cmyk: null,
    },
    {
      code: '14-3907 TCX',
      name: 'Thistle',
      hex: 'B9B3C5',
      rgb: {
        r: 185,
        g: 179,
        b: 197,
      },
      lab: {
        l: 73.96,
        a: 4.84,
        b: -8.16,
      },
      cmyk: null,
    },
    {
      code: '14-3904 TCX',
      name: 'Evening Haze',
      hex: 'BDB8C7',
      rgb: {
        r: 189,
        g: 184,
        b: 199,
      },
      lab: {
        l: 75.66,
        a: 3.68,
        b: -6.68,
      },
      cmyk: null,
    },
    {
      code: '17-3910 TCX',
      name: 'Lavender Gray',
      hex: '9890A2',
      rgb: {
        r: 152,
        g: 144,
        b: 162,
      },
      lab: {
        l: 60.94,
        a: 6.41,
        b: -8.59,
      },
      cmyk: null,
    },
    {
      code: '17-3810 TCX',
      name: 'Purple Ash',
      hex: '8F8395',
      rgb: {
        r: 143,
        g: 131,
        b: 149,
      },
      lab: {
        l: 56.46,
        a: 7.38,
        b: -7.72,
      },
      cmyk: null,
    },
    {
      code: '18-3710 TCX',
      name: 'Gray Ridge',
      hex: '847986',
      rgb: {
        r: 132,
        g: 121,
        b: 134,
      },
      lab: {
        l: 52.29,
        a: 6.67,
        b: -5.26,
      },
      cmyk: null,
    },
    {
      code: '18-3712 TCX',
      name: 'Purple Sage',
      hex: '76697E',
      rgb: {
        r: 118,
        g: 105,
        b: 126,
      },
      lab: {
        l: 46.27,
        a: 8.73,
        b: -9.53,
      },
      cmyk: null,
    },
    {
      code: '14-3903 TCX',
      name: 'Lilac Marble',
      hex: 'C3BABF',
      rgb: {
        r: 195,
        g: 186,
        b: 191,
      },
      lab: {
        l: 76.41,
        a: 4.08,
        b: -1.12,
      },
      cmyk: null,
    },
    {
      code: '15-3802 TCX',
      name: 'Cloud Gray',
      hex: 'B7A9AC',
      rgb: {
        r: 183,
        g: 169,
        b: 172,
      },
      lab: {
        l: 70.66,
        a: 6.02,
        b: 0.56,
      },
      cmyk: null,
    },
    {
      code: '14-3906 TCX',
      name: 'Raindrops',
      hex: 'B2ABB4',
      rgb: {
        r: 178,
        g: 171,
        b: 180,
      },
      lab: {
        l: 70.81,
        a: 4.06,
        b: -3.38,
      },
      cmyk: null,
    },
    {
      code: '14-3805 TCX',
      name: 'Iris',
      hex: 'BAAFBC',
      rgb: {
        r: 186,
        g: 175,
        b: 188,
      },
      lab: {
        l: 72.74,
        a: 6.31,
        b: -4.86,
      },
      cmyk: null,
    },
    {
      code: '17-3808 TCX',
      name: 'Nirvana',
      hex: 'A2919B',
      rgb: {
        r: 162,
        g: 145,
        b: 155,
      },
      lab: {
        l: 62.06,
        a: 8.43,
        b: -2.55,
      },
      cmyk: null,
    },
    {
      code: '16-3304 TCX',
      name: 'Sea Fog',
      hex: 'A5929D',
      rgb: {
        r: 165,
        g: 146,
        b: 157,
      },
      lab: {
        l: 62.56,
        a: 9.68,
        b: -2.77,
      },
      cmyk: null,
    },
    {
      code: '17-1505 TCX',
      name: 'Quail',
      hex: '98868B',
      rgb: {
        r: 152,
        g: 134,
        b: 139,
      },
      lab: {
        l: 57.82,
        a: 8.28,
        b: -0.03,
      },
      cmyk: null,
    },
    {
      code: '13-4105 TCX',
      name: 'Lilac Hint',
      hex: 'D0D0DA',
      rgb: {
        r: 208,
        g: 208,
        b: 218,
      },
      lab: {
        l: 83.75,
        a: 1.63,
        b: -5.04,
      },
      cmyk: null,
    },
    {
      code: '14-3905 TCX',
      name: 'Lavender Blue',
      hex: 'C5C0D0',
      rgb: {
        r: 197,
        g: 192,
        b: 208,
      },
      lab: {
        l: 78.6,
        a: 4.12,
        b: -7.27,
      },
      cmyk: null,
    },
    {
      code: '16-3911 TCX',
      name: 'Lavender Aura',
      hex: '9F99AA',
      rgb: {
        r: 159,
        g: 153,
        b: 170,
      },
      lab: {
        l: 64.04,
        a: 5.12,
        b: -8.18,
      },
      cmyk: null,
    },
    {
      code: '16-3905 TCX',
      name: 'Lilac Gray',
      hex: '9896A4',
      rgb: {
        r: 152,
        g: 150,
        b: 164,
      },
      lab: {
        l: 62.7,
        a: 2.95,
        b: -6.92,
      },
      cmyk: null,
    },
    {
      code: '16-3907 TCX',
      name: 'Dapple Gray',
      hex: '9C9BA7',
      rgb: {
        r: 156,
        g: 155,
        b: 167,
      },
      lab: {
        l: 64.44,
        a: 2.54,
        b: -6.16,
      },
      cmyk: null,
    },
    {
      code: '17-3906 TCX',
      name: 'Minimal Gray',
      hex: '948D99',
      rgb: {
        r: 148,
        g: 141,
        b: 153,
      },
      lab: {
        l: 59.52,
        a: 5.08,
        b: -5.65,
      },
      cmyk: null,
    },
    {
      code: '18-1703 TCX',
      name: 'Shark',
      hex: '6D636B',
      rgb: {
        r: 109,
        g: 99,
        b: 107,
      },
      lab: {
        l: 43.28,
        a: 5.27,
        b: -2.65,
      },
      cmyk: null,
    },
    {
      code: '15-3910 TCX',
      name: 'Languid Lavender',
      hex: 'A2A1BA',
      rgb: {
        r: 162,
        g: 161,
        b: 186,
      },
      lab: {
        l: 66.94,
        a: 4.35,
        b: -12.57,
      },
      cmyk: null,
    },
    {
      code: '15-3908 TCX',
      name: 'Icelandic Blue',
      hex: 'AAADC2',
      rgb: {
        r: 170,
        g: 173,
        b: 194,
      },
      lab: {
        l: 71.02,
        a: 1.82,
        b: -11.14,
      },
      cmyk: null,
    },
    {
      code: '15-3912 TCX',
      name: 'Aleutian',
      hex: '9A9EB3',
      rgb: {
        r: 154,
        g: 158,
        b: 179,
      },
      lab: {
        l: 65.33,
        a: 1.85,
        b: -11.78,
      },
      cmyk: null,
    },
    {
      code: '17-3933 TCX',
      name: 'Silver Bullet',
      hex: '81839A',
      rgb: {
        r: 129,
        g: 131,
        b: 154,
      },
      lab: {
        l: 55.27,
        a: 3.15,
        b: -12.41,
      },
      cmyk: null,
    },
    {
      code: '17-3907 TCX',
      name: 'Quicksilver',
      hex: '7E7D88',
      rgb: {
        r: 126,
        g: 125,
        b: 136,
      },
      lab: {
        l: 52.77,
        a: 2.68,
        b: -6.15,
      },
      cmyk: null,
    },
    {
      code: '18-3933 TCX',
      name: 'Blue Granite',
      hex: '717388',
      rgb: {
        r: 113,
        g: 115,
        b: 136,
      },
      lab: {
        l: 48.64,
        a: 3.24,
        b: -11.98,
      },
      cmyk: null,
    },
    {
      code: '18-3812 TCX',
      name: 'Cadet',
      hex: '6A6378',
      rgb: {
        r: 106,
        g: 99,
        b: 120,
      },
      lab: {
        l: 43.19,
        a: 6.75,
        b: -10.88,
      },
      cmyk: null,
    },
    {
      code: '14-3911 TCX',
      name: 'Purple Heather',
      hex: 'BAB8D3',
      rgb: {
        r: 186,
        g: 184,
        b: 211,
      },
      lab: {
        l: 75.76,
        a: 4.66,
        b: -13.11,
      },
      cmyk: null,
    },
    {
      code: '15-3909 TCX',
      name: 'Cosmic Sky',
      hex: 'AAAAC4',
      rgb: {
        r: 170,
        g: 170,
        b: 196,
      },
      lab: {
        l: 70.32,
        a: 3.48,
        b: -12.94,
      },
      cmyk: null,
    },
    {
      code: '16-3930 TCX',
      name: 'Thistle Down',
      hex: '9499BB',
      rgb: {
        r: 148,
        g: 153,
        b: 187,
      },
      lab: {
        l: 63.54,
        a: 3.72,
        b: -18.71,
      },
      cmyk: null,
    },
    {
      code: '17-3925 TCX',
      name: 'Persian Violet',
      hex: '8C8EB2',
      rgb: {
        r: 140,
        g: 142,
        b: 178,
      },
      lab: {
        l: 59.73,
        a: 5.36,
        b: -19.02,
      },
      cmyk: null,
    },
    {
      code: '18-3820 TCX',
      name: 'Twilight Purple',
      hex: '66648B',
      rgb: {
        r: 102,
        g: 100,
        b: 139,
      },
      lab: {
        l: 43.94,
        a: 7.68,
        b: -21.1,
      },
      cmyk: null,
    },
    {
      code: '18-3817 TCX',
      name: 'Heron',
      hex: '62617E',
      rgb: {
        r: 98,
        g: 97,
        b: 126,
      },
      lab: {
        l: 42.09,
        a: 5.6,
        b: -16.22,
      },
      cmyk: null,
    },
    {
      code: '19-3842 TCX',
      name: 'Deep Wisteria',
      hex: '453F6F',
      rgb: {
        r: 69,
        g: 63,
        b: 111,
      },
      lab: {
        l: 29.02,
        a: 11.99,
        b: -26.99,
      },
      cmyk: null,
    },
    {
      code: '16-3931 TCX',
      name: 'Sweet Lavender',
      hex: '9A9BC1',
      rgb: {
        r: 154,
        g: 155,
        b: 193,
      },
      lab: {
        l: 64.83,
        a: 5.79,
        b: -20,
      },
      cmyk: null,
    },
    {
      code: '17-3924 TCX',
      name: 'Lavender Violet',
      hex: '767BA5',
      rgb: {
        r: 118,
        g: 123,
        b: 165,
      },
      lab: {
        l: 52.49,
        a: 5.51,
        b: -22.92,
      },
      cmyk: null,
    },
    {
      code: '18-3930 TCX',
      name: 'Bleached Denim',
      hex: '646F9B',
      rgb: {
        r: 100,
        g: 111,
        b: 155,
      },
      lab: {
        l: 47.13,
        a: 4.08,
        b: -25.47,
      },
      cmyk: null,
    },
    {
      code: '18-3833 TCX',
      name: 'Dusted Peri',
      hex: '696BA0',
      rgb: {
        r: 105,
        g: 107,
        b: 160,
      },
      lab: {
        l: 46.64,
        a: 8.7,
        b: -28.87,
      },
      cmyk: null,
    },
    {
      code: '18-3828 TCX',
      name: 'Corsican Blue',
      hex: '646093',
      rgb: {
        r: 100,
        g: 96,
        b: 147,
      },
      lab: {
        l: 42.71,
        a: 11.17,
        b: -27.86,
      },
      cmyk: null,
    },
    {
      code: '19-3947 TCX',
      name: 'Orient Blue',
      hex: '48457A',
      rgb: {
        r: 72,
        g: 69,
        b: 122,
      },
      lab: {
        l: 31.71,
        a: 11.45,
        b: -30.08,
      },
      cmyk: null,
    },
    {
      code: '18-3963 TCX',
      name: 'Spectrum Blue',
      hex: '3D3C7C',
      rgb: {
        r: 61,
        g: 60,
        b: 124,
      },
      lab: {
        l: 28.17,
        a: 14.09,
        b: -36.8,
      },
      cmyk: null,
    },
    {
      code: '17-3929 TCX',
      name: 'Pale Iris',
      hex: '8896C6',
      rgb: {
        r: 136,
        g: 150,
        b: 198,
      },
      lab: {
        l: 62.2,
        a: 2.65,
        b: -26.46,
      },
      cmyk: null,
    },
    {
      code: '17-3932 TCX',
      name: 'Deep Periwinkle',
      hex: '7C83BC',
      rgb: {
        r: 124,
        g: 131,
        b: 188,
      },
      lab: {
        l: 55.96,
        a: 6.89,
        b: -30.58,
      },
      cmyk: null,
    },
    {
      code: '17-3940 TCX',
      name: 'Iolite',
      hex: '707BB4',
      rgb: {
        r: 112,
        g: 123,
        b: 180,
      },
      lab: {
        l: 52.53,
        a: 5.76,
        b: -31.52,
      },
      cmyk: null,
    },
    {
      code: '18-3944 TCX',
      name: 'Violet Storm',
      hex: '5C619D',
      rgb: {
        r: 92,
        g: 97,
        b: 157,
      },
      lab: {
        l: 42.68,
        a: 9.57,
        b: -33.65,
      },
      cmyk: null,
    },
    {
      code: '18-3950 TCX',
      name: 'Iris Bloom',
      hex: '5C609F',
      rgb: {
        r: 92,
        g: 96,
        b: 159,
      },
      lab: {
        l: 42.62,
        a: 9.63,
        b: -34.58,
      },
      cmyk: null,
    },
    {
      code: '18-3943 TCX',
      name: 'Blue Iris',
      hex: '5A5B9F',
      rgb: {
        r: 90,
        g: 91,
        b: 159,
      },
      lab: {
        l: 41.03,
        a: 12.88,
        b: -37.4,
      },
      cmyk: null,
    },
    {
      code: '19-3832 TCX',
      name: 'Navy Blue',
      hex: '403F6F',
      rgb: {
        r: 64,
        g: 63,
        b: 111,
      },
      lab: {
        l: 28.69,
        a: 10.09,
        b: -27.58,
      },
      cmyk: null,
    },
    {
      code: '16-3925 TCX',
      name: 'Easter Egg',
      hex: '919BC9',
      rgb: {
        r: 145,
        g: 155,
        b: 201,
      },
      lab: {
        l: 64.44,
        a: 3.87,
        b: -25.14,
      },
      cmyk: null,
    },
    {
      code: '17-3930 TCX',
      name: 'Jacaranda',
      hex: '848DC5',
      rgb: {
        r: 132,
        g: 141,
        b: 197,
      },
      lab: {
        l: 59.58,
        a: 6.02,
        b: -30.1,
      },
      cmyk: null,
    },
    {
      code: '17-3934 TCX',
      name: 'Persian Jewel',
      hex: '6E81BE',
      rgb: {
        r: 110,
        g: 129,
        b: 190,
      },
      lab: {
        l: 54.19,
        a: 4.49,
        b: -34.74,
      },
      cmyk: null,
    },
    {
      code: '18-3935 TCX',
      name: 'Wedgewood',
      hex: '6479B3',
      rgb: {
        r: 100,
        g: 121,
        b: 179,
      },
      lab: {
        l: 50.89,
        a: 3.43,
        b: -33.48,
      },
      cmyk: null,
    },
    {
      code: '18-3946 TCX',
      name: 'Baja Blue',
      hex: '5F6EAE',
      rgb: {
        r: 95,
        g: 110,
        b: 174,
      },
      lab: {
        l: 47.19,
        a: 7.19,
        b: -36.75,
      },
      cmyk: null,
    },
    {
      code: '19-3955 TCX',
      name: 'Royal Blue',
      hex: '3E428B',
      rgb: {
        r: 62,
        g: 66,
        b: 139,
      },
      lab: {
        l: 30.8,
        a: 14.4,
        b: -41.94,
      },
      cmyk: null,
    },
    {
      code: '19-3951 TCX',
      name: 'Clematis Blue',
      hex: '373B7C',
      rgb: {
        r: 55,
        g: 59,
        b: 124,
      },
      lab: {
        l: 27.44,
        a: 12.39,
        b: -37.84,
      },
      cmyk: null,
    },
    {
      code: '17-4037 TCX',
      name: 'Ultramarine',
      hex: '5B7EBD',
      rgb: {
        r: 91,
        g: 126,
        b: 189,
      },
      lab: {
        l: 52.1,
        a: 0.47,
        b: -37.64,
      },
      cmyk: null,
    },
    {
      code: '18-3945 TCX',
      name: 'Amparo Blue',
      hex: '4A5FA5',
      rgb: {
        r: 74,
        g: 95,
        b: 165,
      },
      lab: {
        l: 41.19,
        a: 7.12,
        b: -40.86,
      },
      cmyk: null,
    },
    {
      code: '18-3949 TCX',
      name: 'Dazzling Blue',
      hex: '3950A0',
      rgb: {
        r: 57,
        g: 80,
        b: 160,
      },
      lab: {
        l: 35.52,
        a: 10.04,
        b: -46.82,
      },
      cmyk: null,
    },
    {
      code: '19-3950 TCX',
      name: 'Deep Ultramarine',
      hex: '384883',
      rgb: {
        r: 56,
        g: 72,
        b: 131,
      },
      lab: {
        l: 31.6,
        a: 7.3,
        b: -35.6,
      },
      cmyk: null,
    },
    {
      code: '19-3954 TCX',
      name: 'Bluing',
      hex: '333E83',
      rgb: {
        r: 51,
        g: 62,
        b: 131,
      },
      lab: {
        l: 28.28,
        a: 12.36,
        b: -41.27,
      },
      cmyk: null,
    },
    {
      code: '19-3952 TCX',
      name: 'Surf the Web',
      hex: '203C7F',
      rgb: {
        r: 32,
        g: 60,
        b: 127,
      },
      lab: {
        l: 26.24,
        a: 7.84,
        b: -41.85,
      },
      cmyk: null,
    },
    {
      code: '19-3864 TCX',
      name: 'Mazarine Blue',
      hex: '273C76',
      rgb: {
        r: 39,
        g: 60,
        b: 118,
      },
      lab: {
        l: 26.15,
        a: 6.81,
        b: -36.23,
      },
      cmyk: null,
    },
    {
      code: '19-3964 TCX',
      name: 'Blue Quartz',
      hex: '274374',
      rgb: {
        r: 39,
        g: 67,
        b: 116,
      },
      lab: {
        l: 28.16,
        a: 1.37,
        b: -31.66,
      },
      cmyk: null,
    },
    {
      code: '19-4055 TCX',
      name: 'Galaxy Blue',
      hex: '2A4C7D',
      rgb: {
        r: 42,
        g: 76,
        b: 125,
      },
      lab: {
        l: 31.45,
        a: -0.66,
        b: -32.09,
      },
      cmyk: null,
    },
    {
      code: '19-4041 TCX',
      name: 'Déja Vu Blue',
      hex: '2E5283',
      rgb: {
        r: 46,
        g: 82,
        b: 131,
      },
      lab: {
        l: 33.9,
        a: -0.71,
        b: -32.31,
      },
      cmyk: null,
    },
    {
      code: '19-4057 TCX',
      name: 'True Blue',
      hex: '1F4477',
      rgb: {
        r: 31,
        g: 68,
        b: 119,
      },
      lab: {
        l: 28.21,
        a: 0.25,
        b: -33.71,
      },
      cmyk: null,
    },
    {
      code: '19-4044 TCX',
      name: 'Limoges',
      hex: '243F6C',
      rgb: {
        r: 36,
        g: 63,
        b: 108,
      },
      lab: {
        l: 26.14,
        a: 1.38,
        b: -30.26,
      },
      cmyk: null,
    },
    {
      code: '19-4029 TCX',
      name: 'Navy Peony',
      hex: '223A5E',
      rgb: {
        r: 34,
        g: 58,
        b: 94,
      },
      lab: {
        l: 23.9,
        a: -0.44,
        b: -24.64,
      },
      cmyk: null,
    },
    {
      code: '19-3953 TCX',
      name: 'Sodalite Blue',
      hex: '253668',
      rgb: {
        r: 37,
        g: 54,
        b: 104,
      },
      lab: {
        l: 23.29,
        a: 5.44,
        b: -31.74,
      },
      cmyk: null,
    },
    {
      code: '19-4045 TCX',
      name: 'Lapis Blue',
      hex: '004C8E',
      rgb: {
        r: 0,
        g: 76,
        b: 142,
      },
      lab: {
        l: 30.78,
        a: -1.11,
        b: -43.76,
      },
      cmyk: null,
    },
    {
      code: '19-4150 TCX',
      name: 'Princess Blue',
      hex: '00539C',
      rgb: {
        r: 0,
        g: 83,
        b: 156,
      },
      lab: {
        l: 33.62,
        a: -0.95,
        b: -48.14,
      },
      cmyk: null,
    },
    {
      code: '18-4148 TCX',
      name: 'Victoria Blue',
      hex: '09589D',
      rgb: {
        r: 9,
        g: 88,
        b: 157,
      },
      lab: {
        l: 35.97,
        a: -1.87,
        b: -44.71,
      },
      cmyk: null,
    },
    {
      code: '19-4049 TCX',
      name: 'Snorkel Blue',
      hex: '034F84',
      rgb: {
        r: 3,
        g: 79,
        b: 132,
      },
      lab: {
        l: 31.64,
        a: -5.7,
        b: -36.36,
      },
      cmyk: null,
    },
    {
      code: '19-4050 TCX',
      name: 'Nautical Blue',
      hex: '1B5091',
      rgb: {
        r: 27,
        g: 80,
        b: 145,
      },
      lab: {
        l: 33.07,
        a: 1.18,
        b: -42.09,
      },
      cmyk: null,
    },
    {
      code: '19-4053 TCX',
      name: 'Turkish Sea',
      hex: '1A5190',
      rgb: {
        r: 26,
        g: 81,
        b: 144,
      },
      lab: {
        l: 33.41,
        a: 0.15,
        b: -41.25,
      },
      cmyk: null,
    },
    {
      code: '19-4056 TCX',
      name: 'Blue Lolite',
      hex: '1A4C8B',
      rgb: {
        r: 26,
        g: 76,
        b: 139,
      },
      lab: {
        l: 31.49,
        a: 0.99,
        b: -40.81,
      },
      cmyk: null,
    },
    {
      code: '18-4039 TCX',
      name: 'Regatta',
      hex: '497AB7',
      rgb: {
        r: 73,
        g: 122,
        b: 183,
      },
      lab: {
        l: 49.78,
        a: -3.17,
        b: -38.42,
      },
      cmyk: null,
    },
    {
      code: '18-4043 TCX',
      name: 'Palace Blue',
      hex: '356CB0',
      rgb: {
        r: 53,
        g: 108,
        b: 176,
      },
      lab: {
        l: 44.3,
        a: -1.92,
        b: -42.52,
      },
      cmyk: null,
    },
    {
      code: '18-4143 TCX',
      name: 'Super Sonic',
      hex: '3073B7',
      rgb: {
        r: 48,
        g: 115,
        b: 183,
      },
      lab: {
        l: 46.3,
        a: -4.15,
        b: -43.53,
      },
      cmyk: null,
    },
    {
      code: '18-4051 TCX',
      name: 'Strong Blue',
      hex: '1F5DA0',
      rgb: {
        r: 31,
        g: 93,
        b: 160,
      },
      lab: {
        l: 38.12,
        a: -1.38,
        b: -43.3,
      },
      cmyk: null,
    },
    {
      code: '18-4048 TCX',
      name: 'Nebulas Blue',
      hex: '2E62A3',
      rgb: {
        r: 46,
        g: 98,
        b: 163,
      },
      lab: {
        l: 40.4,
        a: -1.67,
        b: -41.29,
      },
      cmyk: null,
    },
    {
      code: '19-4058 TCX',
      name: 'Beaucoup Blue',
      hex: '32508E',
      rgb: {
        r: 50,
        g: 80,
        b: 142,
      },
      lab: {
        l: 34.1,
        a: 4,
        b: -38.36,
      },
      cmyk: null,
    },
    {
      code: '19-4054 TCX',
      name: 'Nouvean Navy',
      hex: '3A5589',
      rgb: {
        r: 58,
        g: 85,
        b: 137,
      },
      lab: {
        l: 35.87,
        a: 1.71,
        b: -32.76,
      },
      cmyk: null,
    },
    {
      code: '18-4141 TCX',
      name: 'Campanula',
      hex: '3272AF',
      rgb: {
        r: 50,
        g: 114,
        b: 175,
      },
      lab: {
        l: 45.95,
        a: -5.81,
        b: -39.51,
      },
      cmyk: null,
    },
    {
      code: '18-4250 TCX',
      name: 'Indigo Bunting',
      hex: '006CA9',
      rgb: {
        r: 0,
        g: 108,
        b: 169,
      },
      lab: {
        l: 41.94,
        a: -13.76,
        b: -42.72,
      },
      cmyk: null,
    },
    {
      code: '18-4244 TCX',
      name: 'Directoire Blue',
      hex: '0061A3',
      rgb: {
        r: 0,
        g: 97,
        b: 163,
      },
      lab: {
        l: 38.02,
        a: -9.33,
        b: -45.61,
      },
      cmyk: null,
    },
    {
      code: '18-4045 TCX',
      name: 'Daphne',
      hex: '0F5F9A',
      rgb: {
        r: 15,
        g: 95,
        b: 154,
      },
      lab: {
        l: 38.15,
        a: -6.08,
        b: -39.83,
      },
      cmyk: null,
    },
    {
      code: '19-4151 TCX',
      name: 'Skydiver',
      hex: '00589B',
      rgb: {
        r: 0,
        g: 88,
        b: 155,
      },
      lab: {
        l: 34.73,
        a: -5.63,
        b: -46.04,
      },
      cmyk: null,
    },
    {
      code: '19-4048 TCX',
      name: 'Baleine Blue',
      hex: '165288',
      rgb: {
        r: 22,
        g: 82,
        b: 136,
      },
      lab: {
        l: 33.1,
        a: -4.23,
        b: -36.38,
      },
      cmyk: null,
    },
    {
      code: '19-4052 TCX',
      name: 'Classic Blue',
      hex: '0F4C81',
      rgb: {
        r: 15,
        g: 76,
        b: 129,
      },
      lab: {
        l: 30.6,
        a: -3.72,
        b: -36.1,
      },
      cmyk: null,
    },
    {
      code: '16-4132 TCX',
      name: 'Little Boy Blue',
      hex: '6EA1D4',
      rgb: {
        r: 110,
        g: 161,
        b: 212,
      },
      lab: {
        l: 63.92,
        a: -7.45,
        b: -32.46,
      },
      cmyk: null,
    },
    {
      code: '17-4139 TCX',
      name: 'Azure Blue',
      hex: '4D91C6',
      rgb: {
        r: 77,
        g: 145,
        b: 198,
      },
      lab: {
        l: 57.1,
        a: -10.55,
        b: -35.5,
      },
      cmyk: null,
    },
    {
      code: '17-4245 TCX',
      name: 'Ibiza Blue',
      hex: '007CB8',
      rgb: {
        r: 0,
        g: 124,
        b: 184,
      },
      lab: {
        l: 48.16,
        a: -14.47,
        b: -41.26,
      },
      cmyk: null,
    },
    {
      code: '18-4252 TCX',
      name: 'Blue Aster',
      hex: '0077B3',
      rgb: {
        r: 0,
        g: 119,
        b: 179,
      },
      lab: {
        l: 45.68,
        a: -16.57,
        b: -42.76,
      },
      cmyk: null,
    },
    {
      code: '18-4247 TCX',
      name: 'Brilliant Blue',
      hex: '0075B3',
      rgb: {
        r: 0,
        g: 117,
        b: 179,
      },
      lab: {
        l: 45.12,
        a: -14.36,
        b: -43.5,
      },
      cmyk: null,
    },
    {
      code: '18-4140 TCX',
      name: 'French Blue',
      hex: '0072B5',
      rgb: {
        r: 0,
        g: 114,
        b: 181,
      },
      lab: {
        l: 45.02,
        a: -9.03,
        b: -44.67,
      },
      cmyk: null,
    },
    {
      code: '19-4245 TCX',
      name: 'Imperial Blue',
      hex: '005A92',
      rgb: {
        r: 0,
        g: 90,
        b: 146,
      },
      lab: {
        l: 34.87,
        a: -10.84,
        b: -40.46,
      },
      cmyk: null,
    },
    {
      code: '14-4320 TCX',
      name: 'Baltic Sea',
      hex: '7AB6D9',
      rgb: {
        r: 122,
        g: 182,
        b: 217,
      },
      lab: {
        l: 70.58,
        a: -13.88,
        b: -24.68,
      },
      cmyk: null,
    },
    {
      code: '15-4323 TCX',
      name: 'Ethereal Blue',
      hex: '5CA6CE',
      rgb: {
        r: 92,
        g: 166,
        b: 206,
      },
      lab: {
        l: 64.08,
        a: -15.67,
        b: -29.24,
      },
      cmyk: null,
    },
    {
      code: '16-4134 TCX',
      name: 'Bonnie Blue',
      hex: '539CCC',
      rgb: {
        r: 83,
        g: 156,
        b: 204,
      },
      lab: {
        l: 60.87,
        a: -13.75,
        b: -32.69,
      },
      cmyk: null,
    },
    {
      code: '17-4435 TCX',
      name: 'Malibu Blue',
      hex: '008CC1',
      rgb: {
        r: 0,
        g: 140,
        b: 193,
      },
      lab: {
        l: 53.43,
        a: -18.69,
        b: -38.33,
      },
      cmyk: null,
    },
    {
      code: '17-4129 TCX',
      name: 'Wave Ride',
      hex: '2D80A7',
      rgb: {
        r: 45,
        g: 128,
        b: 167,
      },
      lab: {
        l: 49.7,
        a: -16.74,
        b: -29.37,
      },
      cmyk: null,
    },
    {
      code: '18-4032 TCX',
      name: 'Deep Water',
      hex: '266691',
      rgb: {
        r: 38,
        g: 102,
        b: 145,
      },
      lab: {
        l: 40.38,
        a: -9.09,
        b: -30.74,
      },
      cmyk: null,
    },
    {
      code: '18-4037 TCX',
      name: 'Sea of Belize',
      hex: '296C97',
      rgb: {
        r: 41,
        g: 108,
        b: 151,
      },
      lab: {
        l: 42.78,
        a: -10.71,
        b: -30.95,
      },
      cmyk: null,
    },
    {
      code: '14-4122 TCX',
      name: 'Airy Blue',
      hex: '93B7D5',
      rgb: {
        r: 147,
        g: 183,
        b: 213,
      },
      lab: {
        l: 72.33,
        a: -7.53,
        b: -19.93,
      },
      cmyk: null,
    },
    {
      code: '15-4225 TCX',
      name: 'Alaskan Blue',
      hex: '6DA9D2',
      rgb: {
        r: 109,
        g: 169,
        b: 210,
      },
      lab: {
        l: 66.18,
        a: -12.4,
        b: -28.1,
      },
      cmyk: null,
    },
    {
      code: '17-4032 TCX',
      name: 'Lichen Blue',
      hex: '5D89B4',
      rgb: {
        r: 93,
        g: 137,
        b: 180,
      },
      lab: {
        l: 55.15,
        a: -7.43,
        b: -27.54,
      },
      cmyk: null,
    },
    {
      code: '18-4036 TCX',
      name: 'Parisian Blue',
      hex: '4F7CA4',
      rgb: {
        r: 79,
        g: 124,
        b: 164,
      },
      lab: {
        l: 49.75,
        a: -7.76,
        b: -26.9,
      },
      cmyk: null,
    },
    {
      code: '17-4033 TCX',
      name: 'Pacific Coast',
      hex: '5580AD',
      rgb: {
        r: 85,
        g: 128,
        b: 173,
      },
      lab: {
        l: 51.68,
        a: -6.45,
        b: -28.84,
      },
      cmyk: null,
    },
    {
      code: '17-4029 TCX',
      name: 'Quiet Harbor',
      hex: '5B789A',
      rgb: {
        r: 91,
        g: 120,
        b: 154,
      },
      lab: {
        l: 49.06,
        a: -4.65,
        b: -22.34,
      },
      cmyk: null,
    },
    {
      code: '18-4029 TCX',
      name: 'Federal Blue',
      hex: '43628B',
      rgb: {
        r: 67,
        g: 98,
        b: 139,
      },
      lab: {
        l: 40.46,
        a: -2.79,
        b: -26.72,
      },
      cmyk: null,
    },
    {
      code: '15-4020 TCX',
      name: 'Cerulean',
      hex: '9BB7D4',
      rgb: {
        r: 155,
        g: 183,
        b: 212,
      },
      lab: {
        l: 72.98,
        a: -5.17,
        b: -18.24,
      },
      cmyk: null,
    },
    {
      code: '16-4121 TCX',
      name: 'Blissful Blue ',
      hex: '7198BB',
      rgb: {
        r: 113,
        g: 152,
        b: 187,
      },
      lab: {
        l: 60.68,
        a: -6.92,
        b: -23.44,
      },
      cmyk: null,
    },
    {
      code: '16-4021 TCX',
      name: 'Allure',
      hex: '7291B4',
      rgb: {
        r: 114,
        g: 145,
        b: 180,
      },
      lab: {
        l: 58.54,
        a: -4.63,
        b: -22.29,
      },
      cmyk: null,
    },
    {
      code: '18-4035 TCX',
      name: 'Cyaneus',
      hex: '557EA2',
      rgb: {
        r: 85,
        g: 126,
        b: 162,
      },
      lab: {
        l: 50.73,
        a: -7.75,
        b: -24.13,
      },
      cmyk: null,
    },
    {
      code: '18-4027 TCX',
      name: 'Moonlight Blue',
      hex: '516886',
      rgb: {
        r: 81,
        g: 104,
        b: 134,
      },
      lab: {
        l: 42.79,
        a: -2,
        b: -20.58,
      },
      cmyk: null,
    },
    {
      code: '18-3929 TCX',
      name: 'Blue Horizon',
      hex: '4E6582',
      rgb: {
        r: 78,
        g: 101,
        b: 130,
      },
      lab: {
        l: 41.71,
        a: -3.35,
        b: -19.47,
      },
      cmyk: null,
    },
    {
      code: '19-4026 TCX',
      name: 'Ensign Blue',
      hex: '384C67',
      rgb: {
        r: 56,
        g: 76,
        b: 103,
      },
      lab: {
        l: 31.52,
        a: -1.79,
        b: -18.7,
      },
      cmyk: null,
    },
    {
      code: '16-4018 TCX',
      name: 'Ashleigh Blue',
      hex: '6B86AA',
      rgb: {
        r: 107,
        g: 134,
        b: 170,
      },
      lab: {
        l: 54.77,
        a: -3.72,
        b: -22.51,
      },
      cmyk: null,
    },
    {
      code: '17-4027 TCX',
      name: 'Riviera',
      hex: '5979A2',
      rgb: {
        r: 89,
        g: 121,
        b: 162,
      },
      lab: {
        l: 49.42,
        a: -3.73,
        b: -26.18,
      },
      cmyk: null,
    },
    {
      code: '17-4028 TCX',
      name: 'Riverside ',
      hex: '4C6A93',
      rgb: {
        r: 76,
        g: 106,
        b: 147,
      },
      lab: {
        l: 43.71,
        a: -2.76,
        b: -26.07,
      },
      cmyk: null,
    },
    {
      code: '18-4034 TCX',
      name: 'Vallarta Blue',
      hex: '30658E',
      rgb: {
        r: 48,
        g: 101,
        b: 142,
      },
      lab: {
        l: 40.29,
        a: -8.4,
        b: -28.99,
      },
      cmyk: null,
    },
    {
      code: '18-4041 TCX',
      name: 'Star Sapphire',
      hex: '386192',
      rgb: {
        r: 56,
        g: 97,
        b: 146,
      },
      lab: {
        l: 39.71,
        a: -3.38,
        b: -32.06,
      },
      cmyk: null,
    },
    {
      code: '19-4039 TCX',
      name: 'Delft',
      hex: '3D5E8C',
      rgb: {
        r: 61,
        g: 94,
        b: 140,
      },
      lab: {
        l: 38.84,
        a: -2.07,
        b: -29.47,
      },
      cmyk: null,
    },
    {
      code: '19-4037 TCX',
      name: 'Bright Cobalt',
      hex: '395D8D',
      rgb: {
        r: 57,
        g: 93,
        b: 141,
      },
      lab: {
        l: 38.19,
        a: -1.59,
        b: -31.44,
      },
      cmyk: null,
    },
    {
      code: '15-3932 TCX',
      name: 'Bel Air Blue',
      hex: '819AC1',
      rgb: {
        r: 129,
        g: 154,
        b: 193,
      },
      lab: {
        l: 62.54,
        a: -2.34,
        b: -23.64,
      },
      cmyk: null,
    },
    {
      code: '18-4033 TCX',
      name: 'Blue Jasper',
      hex: '527EA9',
      rgb: {
        r: 82,
        g: 126,
        b: 169,
      },
      lab: {
        l: 50.83,
        a: -6.31,
        b: -28.52,
      },
      cmyk: null,
    },
    {
      code: '18-4022 TCX',
      name: 'High Tide',
      hex: '345E8B',
      rgb: {
        r: 52,
        g: 94,
        b: 139,
      },
      lab: {
        l: 38.35,
        a: -4.13,
        b: -30.16,
      },
      cmyk: null,
    },
    {
      code: '19-4042 TCX',
      name: 'Set Sail',
      hex: '284972',
      rgb: {
        r: 40,
        g: 73,
        b: 114,
      },
      lab: {
        l: 29.79,
        a: -1.12,
        b: -28.2,
      },
      cmyk: null,
    },
    {
      code: '19-4035 TCX',
      name: 'Dark Blue',
      hex: '305679',
      rgb: {
        r: 48,
        g: 86,
        b: 121,
      },
      lab: {
        l: 34.71,
        a: -5.27,
        b: -24.71,
      },
      cmyk: null,
    },
    {
      code: '19-4033 TCX',
      name: 'Poseidon',
      hex: '133955',
      rgb: {
        r: 19,
        g: 57,
        b: 85,
      },
      lab: {
        l: 22.35,
        a: -6.18,
        b: -21.36,
      },
      cmyk: null,
    },
    {
      code: '19-4117 TCX',
      name: 'Big Dipper',
      hex: '354253',
      rgb: {
        r: 53,
        g: 66,
        b: 83,
      },
      lab: {
        l: 27.24,
        a: -1.7,
        b: -12.54,
      },
      cmyk: null,
    },
    {
      code: '16-4031 TCX',
      name: 'Cornflower Blue',
      hex: '7391C8',
      rgb: {
        r: 115,
        g: 145,
        b: 200,
      },
      lab: {
        l: 59.48,
        a: -0.53,
        b: -32.52,
      },
      cmyk: null,
    },
    {
      code: '16-4032 TCX',
      name: 'Provence',
      hex: '658DC6',
      rgb: {
        r: 101,
        g: 141,
        b: 198,
      },
      lab: {
        l: 57.24,
        a: -2.39,
        b: -34.78,
      },
      cmyk: null,
    },
    {
      code: '17-4041 TCX',
      name: 'Marina',
      hex: '5085C3',
      rgb: {
        r: 80,
        g: 133,
        b: 195,
      },
      lab: {
        l: 53.59,
        a: -4.09,
        b: -39.16,
      },
      cmyk: null,
    },
    {
      code: '16-4033 TCX',
      name: 'Granada Sky',
      hex: '5D81BC',
      rgb: {
        r: 93,
        g: 129,
        b: 188,
      },
      lab: {
        l: 53.15,
        a: -1.72,
        b: -35.08,
      },
      cmyk: null,
    },
    {
      code: '17-3936 TCX',
      name: 'Blue Bonnet',
      hex: '6384B8',
      rgb: {
        r: 99,
        g: 132,
        b: 184,
      },
      lab: {
        l: 54.03,
        a: -1.1,
        b: -31.96,
      },
      cmyk: null,
    },
    {
      code: '18-3937 TCX',
      name: 'Blue Yonder',
      hex: '5A77A8',
      rgb: {
        r: 90,
        g: 119,
        b: 168,
      },
      lab: {
        l: 49.1,
        a: -1.09,
        b: -29.87,
      },
      cmyk: null,
    },
    {
      code: '18-3928 TCX',
      name: 'Dutch Blue',
      hex: '4A638D',
      rgb: {
        r: 74,
        g: 99,
        b: 141,
      },
      lab: {
        l: 41.26,
        a: -0.86,
        b: -26.3,
      },
      cmyk: null,
    },
    {
      code: '16-3923 TCX',
      name: 'Baby Lavender',
      hex: 'A2A9D1',
      rgb: {
        r: 162,
        g: 169,
        b: 209,
      },
      lab: {
        l: 69.73,
        a: 3.39,
        b: -20.98,
      },
      cmyk: null,
    },
    {
      code: '16-3921 TCX',
      name: 'Blue Heron',
      hex: '96A3C7',
      rgb: {
        r: 150,
        g: 163,
        b: 199,
      },
      lab: {
        l: 66.97,
        a: 0.69,
        b: -19.85,
      },
      cmyk: null,
    },
    {
      code: '15-3919 TCX',
      name: 'Serenity',
      hex: '93A9D1',
      rgb: {
        r: 147,
        g: 169,
        b: 209,
      },
      lab: {
        l: 68.45,
        a: -1.73,
        b: -23.31,
      },
      cmyk: null,
    },
    {
      code: '15-3930 TCX',
      name: 'Vista Blue',
      hex: '80A0D4',
      rgb: {
        r: 128,
        g: 160,
        b: 212,
      },
      lab: {
        l: 64.71,
        a: -1.59,
        b: -30.85,
      },
      cmyk: null,
    },
    {
      code: '16-3920 TCX',
      name: 'Lavender Lustre',
      hex: '8C9CC0',
      rgb: {
        r: 140,
        g: 156,
        b: 192,
      },
      lab: {
        l: 63.94,
        a: 0.2,
        b: -20.99,
      },
      cmyk: null,
    },
    {
      code: '16-4030 TCX',
      name: 'Hydrangea',
      hex: '849BCC',
      rgb: {
        r: 132,
        g: 155,
        b: 204,
      },
      lab: {
        l: 63.41,
        a: 0.49,
        b: -28.23,
      },
      cmyk: null,
    },
    {
      code: '16-3929 TCX',
      name: 'Grapemist',
      hex: '8398CA',
      rgb: {
        r: 131,
        g: 152,
        b: 202,
      },
      lab: {
        l: 62.42,
        a: 1.17,
        b: -28.58,
      },
      cmyk: null,
    },
    {
      code: '15-4031 TCX',
      name: 'Windsurfer',
      hex: 'A3BBE0',
      rgb: {
        r: 163,
        g: 187,
        b: 224,
      },
      lab: {
        l: 75,
        a: -2.28,
        b: -21.77,
      },
      cmyk: null,
    },
    {
      code: '15-3917 TCX',
      name: 'Frozen Fjord',
      hex: '89A5CB',
      rgb: {
        r: 137,
        g: 165,
        b: 203,
      },
      lab: {
        l: 66.64,
        a: -3.95,
        b: -22.81,
      },
      cmyk: null,
    },
    {
      code: '15-3920 TCX',
      name: 'Placid Blue',
      hex: '8CACD3',
      rgb: {
        r: 140,
        g: 172,
        b: 211,
      },
      lab: {
        l: 68.99,
        a: -4.8,
        b: -23.58,
      },
      cmyk: null,
    },
    {
      code: '15-3922 TCX',
      name: 'Open Air',
      hex: '94B2DF',
      rgb: {
        r: 148,
        g: 178,
        b: 223,
      },
      lab: {
        l: 71.45,
        a: -2.99,
        b: -26.59,
      },
      cmyk: null,
    },
    {
      code: '16-4020 TCX',
      name: 'Della Robbia Blue',
      hex: '7A9DCB',
      rgb: {
        r: 122,
        g: 157,
        b: 203,
      },
      lab: {
        l: 63.38,
        a: -4.02,
        b: -27.97,
      },
      cmyk: null,
    },
    {
      code: '16-4029 TCX',
      name: 'Azurine',
      hex: '7291C1',
      rgb: {
        r: 114,
        g: 145,
        b: 193,
      },
      lab: {
        l: 59.14,
        a: -1.94,
        b: -29.05,
      },
      cmyk: null,
    },
    {
      code: '18-3936 TCX',
      name: 'Ebb and Flow',
      hex: '5E79A8',
      rgb: {
        r: 94,
        g: 121,
        b: 168,
      },
      lab: {
        l: 50.04,
        a: -0.66,
        b: -28.81,
      },
      cmyk: null,
    },
    {
      code: '13-4202 TCX',
      name: 'Ice Water',
      hex: 'BFD5EB',
      rgb: {
        r: 191,
        g: 213,
        b: 235,
      },
      lab: {
        l: 83.96,
        a: -4.11,
        b: -14.06,
      },
      cmyk: null,
    },
    {
      code: '14-4005 TCX',
      name: 'Nantucket Breeze',
      hex: 'B7D1EA',
      rgb: {
        r: 183,
        g: 209,
        b: 234,
      },
      lab: {
        l: 82.34,
        a: -4.86,
        b: -15.97,
      },
      cmyk: null,
    },
    {
      code: '14-4124 TCX',
      name: 'Dutch Canal',
      hex: '96BAE0',
      rgb: {
        r: 150,
        g: 186,
        b: 224,
      },
      lab: {
        l: 73.76,
        a: -5.97,
        b: -23.72,
      },
      cmyk: null,
    },
    {
      code: '14-4214 TCX',
      name: 'Powder Blue',
      hex: '96B3D2',
      rgb: {
        r: 150,
        g: 179,
        b: 210,
      },
      lab: {
        l: 71.32,
        a: -4.89,
        b: -19.69,
      },
      cmyk: null,
    },
    {
      code: '14-4121 TCX',
      name: 'Blue Bell',
      hex: '93B4D7',
      rgb: {
        r: 147,
        g: 180,
        b: 215,
      },
      lab: {
        l: 71.51,
        a: -5.46,
        b: -22.28,
      },
      cmyk: null,
    },
    {
      code: '17-4140 TCX',
      name: 'All Aboard',
      hex: '5C97CB',
      rgb: {
        r: 92,
        g: 151,
        b: 203,
      },
      lab: {
        l: 59.78,
        a: -9,
        b: -33.91,
      },
      cmyk: null,
    },
    {
      code: '17-4030 TCX',
      name: 'Silver Lake Blue',
      hex: '618BB9',
      rgb: {
        r: 97,
        g: 139,
        b: 185,
      },
      lab: {
        l: 56.08,
        a: -5.58,
        b: -29.35,
      },
      cmyk: null,
    },
    {
      code: '13-4306 TCX',
      name: 'Ice Melt',
      hex: 'D3E4F1',
      rgb: {
        r: 211,
        g: 228,
        b: 241,
      },
      lab: {
        l: 89.43,
        a: -3.75,
        b: -8.96,
      },
      cmyk: null,
    },
    {
      code: '13-4302 TCX',
      name: 'Skywriting',
      hex: 'C5D1DD',
      rgb: {
        r: 197,
        g: 209,
        b: 221,
      },
      lab: {
        l: 83.22,
        a: -2.34,
        b: -7.49,
      },
      cmyk: null,
    },
    {
      code: '14-4112 TCX',
      name: 'Skyway',
      hex: 'ADBED3',
      rgb: {
        r: 173,
        g: 190,
        b: 211,
      },
      lab: {
        l: 76.06,
        a: -2.86,
        b: -12.75,
      },
      cmyk: null,
    },
    {
      code: '14-4115 TCX',
      name: 'Cashmere Blue',
      hex: 'A5B8D0',
      rgb: {
        r: 165,
        g: 184,
        b: 208,
      },
      lab: {
        l: 73.87,
        a: -3.32,
        b: -14.41,
      },
      cmyk: null,
    },
    {
      code: '15-4030 TCX',
      name: 'Chambray Blue',
      hex: '9EB4D3',
      rgb: {
        r: 158,
        g: 180,
        b: 211,
      },
      lab: {
        l: 72.43,
        a: -3.02,
        b: -18.41,
      },
      cmyk: null,
    },
    {
      code: '16-3922 TCX',
      name: 'Brunnera Blue',
      hex: '9BA9CA',
      rgb: {
        r: 155,
        g: 169,
        b: 202,
      },
      lab: {
        l: 69.08,
        a: -0.06,
        b: -18.43,
      },
      cmyk: null,
    },
    {
      code: '16-4022 TCX',
      name: 'Endless Sky',
      hex: '7E95B5',
      rgb: {
        r: 126,
        g: 149,
        b: 181,
      },
      lab: {
        l: 60.6,
        a: -3.05,
        b: -19.52,
      },
      cmyk: null,
    },
    {
      code: '13-3920 TCX',
      name: 'Halogen Blue',
      hex: 'BDC6DC',
      rgb: {
        r: 189,
        g: 198,
        b: 220,
      },
      lab: {
        l: 79.56,
        a: 0.02,
        b: -12.04,
      },
      cmyk: null,
    },
    {
      code: '14-4110 TCX',
      name: 'Heather',
      hex: 'B7C0D6',
      rgb: {
        r: 183,
        g: 192,
        b: 214,
      },
      lab: {
        l: 77.55,
        a: -0.21,
        b: -12.06,
      },
      cmyk: null,
    },
    {
      code: '14-3949 TCX',
      name: 'Xenon Blue',
      hex: 'B7C0D7',
      rgb: {
        r: 183,
        g: 192,
        b: 215,
      },
      lab: {
        l: 77.45,
        a: 0.06,
        b: -12.56,
      },
      cmyk: null,
    },
    {
      code: '15-3915 TCX',
      name: 'Kentucky Blue',
      hex: 'A5B3CC',
      rgb: {
        r: 165,
        g: 179,
        b: 204,
      },
      lab: {
        l: 72.53,
        a: -1.41,
        b: -14.45,
      },
      cmyk: null,
    },
    {
      code: '14-3912 TCX',
      name: 'Zen Blue',
      hex: 'A0AABF',
      rgb: {
        r: 160,
        g: 170,
        b: 191,
      },
      lab: {
        l: 69.12,
        a: -0.37,
        b: -12.14,
      },
      cmyk: null,
    },
    {
      code: '16-4016 TCX',
      name: 'Soft Chambray',
      hex: '91A5BE',
      rgb: {
        r: 145,
        g: 165,
        b: 190,
      },
      lab: {
        l: 66.73,
        a: -3.33,
        b: -15.29,
      },
      cmyk: null,
    },
    {
      code: '15-4009 TCX',
      name: 'Blue Blizzard',
      hex: '939EB3',
      rgb: {
        r: 147,
        g: 158,
        b: 179,
      },
      lab: {
        l: 64.8,
        a: -0.92,
        b: -12.49,
      },
      cmyk: null,
    },
    {
      code: '12-4108 TCX',
      name: 'Ancient Water',
      hex: 'CCD6E6',
      rgb: {
        r: 204,
        g: 214,
        b: 230,
      },
      lab: {
        l: 85.18,
        a: -0.9,
        b: -9.41,
      },
      cmyk: null,
    },
    {
      code: '13-4110 TCX',
      name: 'Arctic Ice',
      hex: 'BFC7D6',
      rgb: {
        r: 191,
        g: 199,
        b: 214,
      },
      lab: {
        l: 79.88,
        a: -0.37,
        b: -8.63,
      },
      cmyk: null,
    },
    {
      code: '14-4211 TCX',
      name: 'Niagara Mist',
      hex: 'ABB9C9',
      rgb: {
        r: 171,
        g: 185,
        b: 201,
      },
      lab: {
        l: 74.39,
        a: -2.87,
        b: -9.92,
      },
      cmyk: null,
    },
    {
      code: '16-4023 TCX',
      name: 'Rain Washed',
      hex: '8192AB',
      rgb: {
        r: 129,
        g: 146,
        b: 171,
      },
      lab: {
        l: 59.87,
        a: -2.52,
        b: -15.02,
      },
      cmyk: null,
    },
    {
      code: '17-3912 TCX',
      name: 'Wild Wind',
      hex: '657088',
      rgb: {
        r: 101,
        g: 112,
        b: 136,
      },
      lab: {
        l: 46.85,
        a: 0.32,
        b: -14.96,
      },
      cmyk: null,
    },
    {
      code: '17-3926 TCX',
      name: 'Troposphere',
      hex: '76849B',
      rgb: {
        r: 118,
        g: 132,
        b: 155,
      },
      lab: {
        l: 54.52,
        a: -1.52,
        b: -14.22,
      },
      cmyk: null,
    },
    {
      code: '18-3919 TCX',
      name: 'Bluefin',
      hex: '536679',
      rgb: {
        r: 83,
        g: 102,
        b: 121,
      },
      lab: {
        l: 41.99,
        a: -3.81,
        b: -13.62,
      },
      cmyk: null,
    },
    {
      code: '16-3919 TCX',
      name: 'Eventide',
      hex: '959FB7',
      rgb: {
        r: 149,
        g: 159,
        b: 183,
      },
      lab: {
        l: 65.11,
        a: 0.34,
        b: -14.08,
      },
      cmyk: null,
    },
    {
      code: '16-4019 TCX',
      name: 'Forever Blue',
      hex: '899BB8',
      rgb: {
        r: 137,
        g: 155,
        b: 184,
      },
      lab: {
        l: 63.22,
        a: -1.91,
        b: -17.62,
      },
      cmyk: null,
    },
    {
      code: '17-4015 TCX',
      name: 'Infinity',
      hex: '6E7E99',
      rgb: {
        r: 110,
        g: 126,
        b: 153,
      },
      lab: {
        l: 52.09,
        a: -1.15,
        b: -16.71,
      },
      cmyk: null,
    },
    {
      code: '17-3915 TCX',
      name: 'Tempest',
      hex: '79839B',
      rgb: {
        r: 121,
        g: 131,
        b: 155,
      },
      lab: {
        l: 54.55,
        a: 0.47,
        b: -14.31,
      },
      cmyk: null,
    },
    {
      code: '17-3917 TCX',
      name: 'Stonewash',
      hex: '74809A',
      rgb: {
        r: 116,
        g: 128,
        b: 154,
      },
      lab: {
        l: 53.24,
        a: -0.2,
        b: -15.7,
      },
      cmyk: null,
    },
    {
      code: '17-3918 TCX',
      name: 'Country Blue',
      hex: '717F9B',
      rgb: {
        r: 113,
        g: 127,
        b: 155,
      },
      lab: {
        l: 52.88,
        a: -0.51,
        b: -16.74,
      },
      cmyk: null,
    },
    {
      code: '19-3929 TCX',
      name: 'Vintage Indigo',
      hex: '4A556B',
      rgb: {
        r: 74,
        g: 85,
        b: 107,
      },
      lab: {
        l: 35.94,
        a: -0.22,
        b: -14.11,
      },
      cmyk: null,
    },
    {
      code: '18-3916 TCX',
      name: 'Flint Stone',
      hex: '677283',
      rgb: {
        r: 103,
        g: 114,
        b: 131,
      },
      lab: {
        l: 47.54,
        a: -1.11,
        b: -10.95,
      },
      cmyk: null,
    },
    {
      code: '18-3910 TCX',
      name: 'Folkstone Gray',
      hex: '626879',
      rgb: {
        r: 98,
        g: 104,
        b: 121,
      },
      lab: {
        l: 43.85,
        a: 0.98,
        b: -10.37,
      },
      cmyk: null,
    },
    {
      code: '18-3912 TCX',
      name: 'Grisaille',
      hex: '585E6F',
      rgb: {
        r: 88,
        g: 94,
        b: 111,
      },
      lab: {
        l: 39.83,
        a: 0.97,
        b: -10.63,
      },
      cmyk: null,
    },
    {
      code: '19-4121 TCX',
      name: 'Blue Wing Teal',
      hex: '2C4053',
      rgb: {
        r: 44,
        g: 64,
        b: 83,
      },
      lab: {
        l: 25.81,
        a: -3.69,
        b: -14.54,
      },
      cmyk: null,
    },
    {
      code: '19-4028 TCX',
      name: 'Insignia Blue',
      hex: '303E55',
      rgb: {
        r: 48,
        g: 62,
        b: 85,
      },
      lab: {
        l: 25.73,
        a: -0.9,
        b: -16.01,
      },
      cmyk: null,
    },
    {
      code: '19-4118 TCX',
      name: 'Dark Denim',
      hex: '36465E',
      rgb: {
        r: 54,
        g: 70,
        b: 94,
      },
      lab: {
        l: 29,
        a: -1.46,
        b: -16.44,
      },
      cmyk: null,
    },
    {
      code: '19-4122 TCX',
      name: 'Moonlit Ocean',
      hex: '2A3B4E',
      rgb: {
        r: 42,
        g: 59,
        b: 78,
      },
      lab: {
        l: 24.09,
        a: -3.01,
        b: -13.84,
      },
      cmyk: null,
    },
    {
      code: '17-3919 TCX',
      name: 'Purple Impression',
      hex: '858FB1',
      rgb: {
        r: 133,
        g: 143,
        b: 177,
      },
      lab: {
        l: 59.52,
        a: 1.82,
        b: -18.99,
      },
      cmyk: null,
    },
    {
      code: '17-3923 TCX',
      name: 'Colony Blue',
      hex: '65769A',
      rgb: {
        r: 101,
        g: 118,
        b: 154,
      },
      lab: {
        l: 49.24,
        a: -0.06,
        b: -21.53,
      },
      cmyk: null,
    },
    {
      code: '18-3921 TCX',
      name: 'Bijou Blue',
      hex: '4E5E7F',
      rgb: {
        r: 78,
        g: 94,
        b: 127,
      },
      lab: {
        l: 39.61,
        a: 0.38,
        b: -20.99,
      },
      cmyk: null,
    },
    {
      code: '19-4030 TCX',
      name: 'True Navy',
      hex: '3F5277',
      rgb: {
        r: 63,
        g: 82,
        b: 119,
      },
      lab: {
        l: 34.52,
        a: 0.27,
        b: -23.71,
      },
      cmyk: null,
    },
    {
      code: '19-3926 TCX',
      name: 'Crown Blue',
      hex: '464B65',
      rgb: {
        r: 70,
        g: 75,
        b: 101,
      },
      lab: {
        l: 32.3,
        a: 2.95,
        b: -15.51,
      },
      cmyk: null,
    },
    {
      code: '19-4031 TCX',
      name: 'Sargasso Sea',
      hex: '36445A',
      rgb: {
        r: 54,
        g: 68,
        b: 90,
      },
      lab: {
        l: 28.13,
        a: -0.95,
        b: -15.54,
      },
      cmyk: null,
    },
    {
      code: '19-4024 TCX',
      name: 'Dress Blues',
      hex: '2A3244',
      rgb: {
        r: 42,
        g: 50,
        b: 68,
      },
      lab: {
        l: 20.73,
        a: 0.45,
        b: -12.43,
      },
      cmyk: null,
    },
    {
      code: '17-3920 TCX',
      name: 'English Manor',
      hex: '7181A4',
      rgb: {
        r: 113,
        g: 129,
        b: 164,
      },
      lab: {
        l: 53.64,
        a: -0.07,
        b: -20.44,
      },
      cmyk: null,
    },
    {
      code: '18-3920 TCX',
      name: 'Coastal Fjord',
      hex: '505D7E',
      rgb: {
        r: 80,
        g: 93,
        b: 126,
      },
      lab: {
        l: 39.31,
        a: 0.97,
        b: -20.49,
      },
      cmyk: null,
    },
    {
      code: '18-3917 TCX',
      name: 'Gray Blue',
      hex: '4E597B',
      rgb: {
        r: 78,
        g: 89,
        b: 123,
      },
      lab: {
        l: 37.75,
        a: 2.18,
        b: -20.83,
      },
      cmyk: null,
    },
    {
      code: '19-3928 TCX',
      name: 'Blue Indigo',
      hex: '49516D',
      rgb: {
        r: 73,
        g: 81,
        b: 109,
      },
      lab: {
        l: 34.68,
        a: 2.29,
        b: -17.09,
      },
      cmyk: null,
    },
    {
      code: '19-3919 TCX',
      name: 'Nightshadow Blue',
      hex: '4E5368',
      rgb: {
        r: 78,
        g: 83,
        b: 104,
      },
      lab: {
        l: 35.32,
        a: 2.22,
        b: -13.23,
      },
      cmyk: null,
    },
    {
      code: '19-3915 TCX',
      name: 'Graystone',
      hex: '4D495B',
      rgb: {
        r: 77,
        g: 73,
        b: 91,
      },
      lab: {
        l: 32,
        a: 4.82,
        b: -10.01,
      },
      cmyk: null,
    },
    {
      code: '19-3815 TCX',
      name: 'Evening Blue',
      hex: '2A293E',
      rgb: {
        r: 42,
        g: 41,
        b: 62,
      },
      lab: {
        l: 17.36,
        a: 5.13,
        b: -13.63,
      },
      cmyk: null,
    },
    {
      code: '17-3922 TCX',
      name: 'Blue Ice',
      hex: '70789B',
      rgb: {
        r: 112,
        g: 120,
        b: 155,
      },
      lab: {
        l: 50.62,
        a: 3.75,
        b: -20.67,
      },
      cmyk: null,
    },
    {
      code: '18-3927 TCX',
      name: 'Velvet Morning',
      hex: '60688D',
      rgb: {
        r: 96,
        g: 104,
        b: 141,
      },
      lab: {
        l: 44.36,
        a: 3.85,
        b: -21.4,
      },
      cmyk: null,
    },
    {
      code: '18-3932 TCX',
      name: 'Marlin',
      hex: '515B87',
      rgb: {
        r: 81,
        g: 91,
        b: 135,
      },
      lab: {
        l: 39.25,
        a: 4.87,
        b: -25.83,
      },
      cmyk: null,
    },
    {
      code: '19-3936 TCX',
      name: 'Skipper Blue',
      hex: '484A72',
      rgb: {
        r: 72,
        g: 74,
        b: 114,
      },
      lab: {
        l: 32.57,
        a: 7.31,
        b: -23.27,
      },
      cmyk: null,
    },
    {
      code: '19-3839 TCX',
      name: 'Blue Ribbon',
      hex: '3A395F',
      rgb: {
        r: 58,
        g: 57,
        b: 95,
      },
      lab: {
        l: 25.54,
        a: 8.2,
        b: -22.2,
      },
      cmyk: null,
    },
    {
      code: '19-3830 TCX',
      name: 'Astral Aura',
      hex: '373151',
      rgb: {
        r: 55,
        g: 49,
        b: 81,
      },
      lab: {
        l: 22.04,
        a: 9.22,
        b: -18.67,
      },
      cmyk: null,
    },
    {
      code: '19-3810 TCX',
      name: 'Eclipse',
      hex: '353148',
      rgb: {
        r: 53,
        g: 49,
        b: 72,
      },
      lab: {
        l: 21.43,
        a: 6.63,
        b: -13.58,
      },
      cmyk: null,
    },
    {
      code: '19-3934 TCX',
      name: 'Oceana',
      hex: '3C4862',
      rgb: {
        r: 60,
        g: 72,
        b: 98,
      },
      lab: {
        l: 30.22,
        a: 0.88,
        b: -17.34,
      },
      cmyk: null,
    },
    {
      code: '19-3932 TCX',
      name: 'Naval Academy',
      hex: '2E344A',
      rgb: {
        r: 46,
        g: 52,
        b: 74,
      },
      lab: {
        l: 21.75,
        a: 2.09,
        b: -14.61,
      },
      cmyk: null,
    },
    {
      code: '19-3943 TCX',
      name: 'Bellwether Blue',
      hex: '23305B',
      rgb: {
        r: 35,
        g: 48,
        b: 91,
      },
      lab: {
        l: 20.54,
        a: 5.03,
        b: -27.63,
      },
      cmyk: null,
    },
    {
      code: '19-3851 TCX',
      name: 'Midnight Sail',
      hex: '323455',
      rgb: {
        r: 50,
        g: 52,
        b: 85,
      },
      lab: {
        l: 22.88,
        a: 6.33,
        b: -20.37,
      },
      cmyk: null,
    },
    {
      code: '19-3942 TCX',
      name: 'Beacon Blue',
      hex: '252A50',
      rgb: {
        r: 37,
        g: 42,
        b: 80,
      },
      lab: {
        l: 18.3,
        a: 6.35,
        b: -24.15,
      },
      cmyk: null,
    },
    {
      code: '19-3937 TCX',
      name: 'Ocean Cavern',
      hex: '252A48',
      rgb: {
        r: 37,
        g: 42,
        b: 72,
      },
      lab: {
        l: 17.63,
        a: 5.07,
        b: -20.15,
      },
      cmyk: null,
    },
    {
      code: '19-3838 TCX',
      name: 'Rhodonite',
      hex: '302C4D',
      rgb: {
        r: 48,
        g: 44,
        b: 77,
      },
      lab: {
        l: 19.71,
        a: 8.1,
        b: -19.89,
      },
      cmyk: null,
    },
    {
      code: '19-3935 TCX',
      name: 'Deep Cobalt',
      hex: '404466',
      rgb: {
        r: 64,
        g: 68,
        b: 102,
      },
      lab: {
        l: 29.76,
        a: 5.05,
        b: -20.46,
      },
      cmyk: null,
    },
    {
      code: '19-4027 TCX',
      name: 'Estate Blue',
      hex: '233658',
      rgb: {
        r: 35,
        g: 54,
        b: 88,
      },
      lab: {
        l: 22.13,
        a: 0.57,
        b: -23.18,
      },
      cmyk: null,
    },
    {
      code: '19-3938 TCX',
      name: 'Twilight Blue',
      hex: '313D64',
      rgb: {
        r: 49,
        g: 61,
        b: 100,
      },
      lab: {
        l: 26.08,
        a: 4.59,
        b: -25.45,
      },
      cmyk: null,
    },
    {
      code: '19-3939 TCX',
      name: 'Blueprint',
      hex: '2D3359',
      rgb: {
        r: 45,
        g: 51,
        b: 89,
      },
      lab: {
        l: 22.17,
        a: 5.57,
        b: -23.74,
      },
      cmyk: null,
    },
    {
      code: '19-3940 TCX',
      name: 'Blue Depths',
      hex: '263056',
      rgb: {
        r: 38,
        g: 48,
        b: 86,
      },
      lab: {
        l: 20.52,
        a: 4.37,
        b: -24.32,
      },
      cmyk: null,
    },
    {
      code: '19-3933 TCX',
      name: 'Medieval Blue',
      hex: '2A304E',
      rgb: {
        r: 42,
        g: 48,
        b: 78,
      },
      lab: {
        l: 20.39,
        a: 3.53,
        b: -19.15,
      },
      cmyk: null,
    },
    {
      code: '19-3925 TCX',
      name: 'Patriot Blue',
      hex: '363756',
      rgb: {
        r: 54,
        g: 55,
        b: 86,
      },
      lab: {
        l: 24.28,
        a: 5.73,
        b: -18.65,
      },
      cmyk: null,
    },
    {
      code: '19-4111 TCX',
      name: 'Pageant Blue',
      hex: '1F2C43',
      rgb: {
        r: 31,
        g: 44,
        b: 67,
      },
      lab: {
        l: 17.67,
        a: 0.31,
        b: -16.59,
      },
      cmyk: null,
    },
    {
      code: '19-4109 TCX',
      name: 'After Midnight',
      hex: '30343F',
      rgb: {
        r: 48,
        g: 52,
        b: 63,
      },
      lab: {
        l: 21.64,
        a: 0.86,
        b: -7.51,
      },
      cmyk: null,
    },
    {
      code: '19-4018 TCX',
      name: 'Seaborne',
      hex: '2E2E3B',
      rgb: {
        r: 46,
        g: 46,
        b: 59,
      },
      lab: {
        l: 19.47,
        a: 2.5,
        b: -8.26,
      },
      cmyk: null,
    },
    {
      code: '19-3812 TCX',
      name: 'Baritone Blue',
      hex: '272836',
      rgb: {
        r: 39,
        g: 40,
        b: 54,
      },
      lab: {
        l: 16.39,
        a: 2.88,
        b: -9.65,
      },
      cmyk: null,
    },
    {
      code: '19-4017 TCX',
      name: 'Spellbound',
      hex: '333B4C',
      rgb: {
        r: 51,
        g: 59,
        b: 76,
      },
      lab: {
        l: 24.65,
        a: -0.2,
        b: -11.64,
      },
      cmyk: null,
    },
    {
      code: '19-3811 TCX',
      name: 'Inkling',
      hex: '2F2C3F',
      rgb: {
        r: 47,
        g: 44,
        b: 63,
      },
      lab: {
        l: 19.1,
        a: 4.94,
        b: -11.12,
      },
      cmyk: null,
    },
    {
      code: '19-4105 TCX',
      name: 'Polar Night',
      hex: '343540',
      rgb: {
        r: 52,
        g: 53,
        b: 64,
      },
      lab: {
        l: 22.24,
        a: 1.92,
        b: -7.32,
      },
      cmyk: null,
    },
    {
      code: '19-3921 TCX',
      name: 'Black Iris',
      hex: '2B3042',
      rgb: {
        r: 43,
        g: 48,
        b: 66,
      },
      lab: {
        l: 20.03,
        a: 1.54,
        b: -12.26,
      },
      cmyk: null,
    },
    {
      code: '19-3831 TCX',
      name: 'Maritime Blue',
      hex: '27293D',
      rgb: {
        r: 39,
        g: 41,
        b: 61,
      },
      lab: {
        l: 17.17,
        a: 3.3,
        b: -12.95,
      },
      cmyk: null,
    },
    {
      code: '19-4025 TCX',
      name: 'Mood Indigo',
      hex: '353A4C',
      rgb: {
        r: 53,
        g: 58,
        b: 76,
      },
      lab: {
        l: 24.52,
        a: 1.33,
        b: -11.48,
      },
      cmyk: null,
    },
    {
      code: '19-3920 TCX',
      name: 'Peacoat',
      hex: '2B2E43',
      rgb: {
        r: 43,
        g: 46,
        b: 67,
      },
      lab: {
        l: 19.25,
        a: 3.3,
        b: -13.51,
      },
      cmyk: null,
    },
    {
      code: '19-4010 TCX',
      name: 'Total Eclipse',
      hex: '2D313D',
      rgb: {
        r: 45,
        g: 49,
        b: 61,
      },
      lab: {
        l: 20.12,
        a: 1.3,
        b: -8.5,
      },
      cmyk: null,
    },
    {
      code: '19-4022 TCX',
      name: 'Parisian Night',
      hex: '323441',
      rgb: {
        r: 50,
        g: 52,
        b: 65,
      },
      lab: {
        l: 22.09,
        a: 1.7,
        b: -8.04,
      },
      cmyk: null,
    },
    {
      code: '19-4020 TCX',
      name: 'Dark Sapphire',
      hex: '262B37',
      rgb: {
        r: 38,
        g: 43,
        b: 55,
      },
      lab: {
        l: 17.49,
        a: 0.35,
        b: -8.41,
      },
      cmyk: null,
    },
    {
      code: '19-3930 TCX',
      name: 'Odyssey Gray',
      hex: '444552',
      rgb: {
        r: 68,
        g: 69,
        b: 82,
      },
      lab: {
        l: 29.41,
        a: 2.23,
        b: -8.47,
      },
      cmyk: null,
    },
    {
      code: '19-4009 TCX',
      name: 'Outer Space',
      hex: '2F3441',
      rgb: {
        r: 47,
        g: 52,
        b: 65,
      },
      lab: {
        l: 21.66,
        a: 0.63,
        b: -8.65,
      },
      cmyk: null,
    },
    {
      code: '19-3927 TCX',
      name: 'Graphite',
      hex: '3B3B48',
      rgb: {
        r: 59,
        g: 59,
        b: 72,
      },
      lab: {
        l: 25.38,
        a: 2.63,
        b: -7.8,
      },
      cmyk: null,
    },
    {
      code: '19-4023 TCX',
      name: 'Blue Nights',
      hex: '363B48',
      rgb: {
        r: 54,
        g: 59,
        b: 72,
      },
      lab: {
        l: 24.73,
        a: 0.93,
        b: -8.88,
      },
      cmyk: null,
    },
    {
      code: '19-3923 TCX',
      name: 'Navy Blazer',
      hex: '282D3C',
      rgb: {
        r: 40,
        g: 45,
        b: 60,
      },
      lab: {
        l: 18.43,
        a: 1.16,
        b: -10.33,
      },
      cmyk: null,
    },
    {
      code: '19-3922 TCX',
      name: 'Sky Captain',
      hex: '262934',
      rgb: {
        r: 38,
        g: 41,
        b: 52,
      },
      lab: {
        l: 16.76,
        a: 0.52,
        b: -7.23,
      },
      cmyk: null,
    },
    {
      code: '19-3713 TCX',
      name: 'Deep Well',
      hex: '2D2A33',
      rgb: {
        r: 45,
        g: 42,
        b: 51,
      },
      lab: {
        l: 17.74,
        a: 3.05,
        b: -5.51,
      },
      cmyk: null,
    },
    {
      code: '19-4014 TCX',
      name: 'Ombre Blue',
      hex: '434854',
      rgb: {
        r: 67,
        g: 72,
        b: 84,
      },
      lab: {
        l: 30.35,
        a: 0.92,
        b: -8.09,
      },
      cmyk: null,
    },
    {
      code: '19-4019 TCX',
      name: 'India Ink',
      hex: '3C3F4A',
      rgb: {
        r: 60,
        g: 63,
        b: 74,
      },
      lab: {
        l: 26.72,
        a: 0.87,
        b: -6.83,
      },
      cmyk: null,
    },
    {
      code: '19-4013 TCX',
      name: 'Dark Navy',
      hex: '2E2F36',
      rgb: {
        r: 46,
        g: 47,
        b: 54,
      },
      lab: {
        l: 19.47,
        a: 1.1,
        b: -4.62,
      },
      cmyk: null,
    },
    {
      code: '19-4015 TCX',
      name: 'Blue Graphite',
      hex: '323137',
      rgb: {
        r: 50,
        g: 49,
        b: 55,
      },
      lab: {
        l: 20.74,
        a: 1.97,
        b: -3.45,
      },
      cmyk: null,
    },
    {
      code: '19-4011 TCX',
      name: 'Salute',
      hex: '282B34',
      rgb: {
        r: 40,
        g: 43,
        b: 52,
      },
      lab: {
        l: 17.37,
        a: 0.64,
        b: -6.37,
      },
      cmyk: null,
    },
    {
      code: '19-4218 TCX',
      name: 'Vulcan',
      hex: '2E3036',
      rgb: {
        r: 46,
        g: 48,
        b: 54,
      },
      lab: {
        l: 19.78,
        a: 0.76,
        b: -4.43,
      },
      cmyk: null,
    },
    {
      code: '19-3924 TCX',
      name: 'Night Sky',
      hex: '2A2A35',
      rgb: {
        r: 42,
        g: 42,
        b: 53,
      },
      lab: {
        l: 17.46,
        a: 2.07,
        b: -7.26,
      },
      cmyk: null,
    },
    {
      code: '19-4908 TCX',
      name: 'Magical Forest',
      hex: '2E3C41',
      rgb: {
        r: 46,
        g: 60,
        b: 65,
      },
      lab: {
        l: 24.24,
        a: -4.69,
        b: -5.05,
      },
      cmyk: null,
    },
    {
      code: '19-4112 TCX',
      name: 'Stratified Sea',
      hex: '313F4A',
      rgb: {
        r: 49,
        g: 63,
        b: 74,
      },
      lab: {
        l: 25.64,
        a: -3.05,
        b: -9.38,
      },
      cmyk: null,
    },
    {
      code: '19-4108 TCX',
      name: 'Sea Storm',
      hex: '323C46',
      rgb: {
        r: 50,
        g: 60,
        b: 70,
      },
      lab: {
        l: 24.5,
        a: -1.37,
        b: -7.79,
      },
      cmyk: null,
    },
    {
      code: '19-3944 TCX',
      name: 'Overture',
      hex: '343646',
      rgb: {
        r: 52,
        g: 54,
        b: 70,
      },
      lab: {
        l: 22.98,
        a: 2.11,
        b: -9.96,
      },
      cmyk: null,
    },
    {
      code: '19-4016 TCX',
      name: 'Inkwell',
      hex: '363945',
      rgb: {
        r: 54,
        g: 57,
        b: 69,
      },
      lab: {
        l: 23.96,
        a: 1.64,
        b: -8.39,
      },
      cmyk: null,
    },
    {
      code: '19-4051 TCX',
      name: 'Collegiate Blue ',
      hex: '323841',
      rgb: {
        r: 50,
        g: 56,
        b: 65,
      },
      lab: {
        l: 23.31,
        a: -0.47,
        b: -6.5,
      },
      cmyk: null,
    },
    {
      code: '19-3916 TCX',
      name: 'Charcoal Art',
      hex: '38373D',
      rgb: {
        r: 56,
        g: 55,
        b: 61,
      },
      lab: {
        l: 23.27,
        a: 1.59,
        b: -3.73,
      },
      cmyk: null,
    },
    {
      code: '16-3916 TCX',
      name: 'Sleet',
      hex: '92949B',
      rgb: {
        r: 146,
        g: 148,
        b: 155,
      },
      lab: {
        l: 61.42,
        a: 0.68,
        b: -4.1,
      },
      cmyk: null,
    },
    {
      code: '15-4307 TCX',
      name: 'Tradewinds',
      hex: '7F8792',
      rgb: {
        r: 127,
        g: 135,
        b: 146,
      },
      lab: {
        l: 56.01,
        a: -1.19,
        b: -7.09,
      },
      cmyk: null,
    },
    {
      code: '18-4214 TCX',
      name: 'Stormy Weather',
      hex: '58646D',
      rgb: {
        r: 88,
        g: 100,
        b: 109,
      },
      lab: {
        l: 41.48,
        a: -2.41,
        b: -6.96,
      },
      cmyk: null,
    },
    {
      code: '19-4215 TCX',
      name: 'Turbulence',
      hex: '4E545B',
      rgb: {
        r: 78,
        g: 84,
        b: 91,
      },
      lab: {
        l: 35.19,
        a: -0.7,
        b: -5.44,
      },
      cmyk: null,
    },
    {
      code: '19-4110 TCX',
      name: 'Midnight Navy',
      hex: '34414E',
      rgb: {
        r: 52,
        g: 65,
        b: 78,
      },
      lab: {
        l: 26.65,
        a: -2.49,
        b: -9.88,
      },
      cmyk: null,
    },
    {
      code: '19-4021 TCX',
      name: 'Blueberry',
      hex: '2C333E',
      rgb: {
        r: 44,
        g: 51,
        b: 62,
      },
      lab: {
        l: 20.98,
        a: -0.86,
        b: -7.64,
      },
      cmyk: null,
    },
    {
      code: '19-4012 TCX',
      name: 'Carbon',
      hex: '272F38',
      rgb: {
        r: 39,
        g: 47,
        b: 56,
      },
      lab: {
        l: 18.72,
        a: -1.16,
        b: -7,
      },
      cmyk: null,
    },
    {
      code: '17-4020 TCX',
      name: 'Blue Shadow',
      hex: '67829A',
      rgb: {
        r: 103,
        g: 130,
        b: 154,
      },
      lab: {
        l: 52.77,
        a: -5.5,
        b: -16.69,
      },
      cmyk: null,
    },
    {
      code: '19-4227 TCX',
      name: 'Indian Teal',
      hex: '3C586B',
      rgb: {
        r: 60,
        g: 88,
        b: 107,
      },
      lab: {
        l: 35.53,
        a: -6.15,
        b: -15.06,
      },
      cmyk: null,
    },
    {
      code: '19-4318 TCX',
      name: 'Mallard Blue',
      hex: '3A5C6E',
      rgb: {
        r: 58,
        g: 92,
        b: 110,
      },
      lab: {
        l: 36.98,
        a: -8.98,
        b: -14.61,
      },
      cmyk: null,
    },
    {
      code: '18-3918 TCX',
      name: 'China Blue',
      hex: '546477',
      rgb: {
        r: 84,
        g: 100,
        b: 119,
      },
      lab: {
        l: 41.51,
        a: -2.25,
        b: -13.32,
      },
      cmyk: null,
    },
    {
      code: '19-4229 TCX',
      name: 'Orion Blue',
      hex: '3E4F5C',
      rgb: {
        r: 62,
        g: 79,
        b: 92,
      },
      lab: {
        l: 32.52,
        a: -3.87,
        b: -10.26,
      },
      cmyk: null,
    },
    {
      code: '19-4220 TCX',
      name: 'Dark Slate',
      hex: '46515A',
      rgb: {
        r: 70,
        g: 81,
        b: 90,
      },
      lab: {
        l: 33.7,
        a: -2.81,
        b: -6.72,
      },
      cmyk: null,
    },
    {
      code: '19-4316 TCX',
      name: 'Stargazer',
      hex: '39505C',
      rgb: {
        r: 57,
        g: 80,
        b: 92,
      },
      lab: {
        l: 32.31,
        a: -6.41,
        b: -10.58,
      },
      cmyk: null,
    },
    {
      code: '17-4019 TCX',
      name: 'Mountain Spring',
      hex: '7A92A5',
      rgb: {
        r: 122,
        g: 146,
        b: 165,
      },
      lab: {
        l: 58.99,
        a: -5.17,
        b: -13.52,
      },
      cmyk: null,
    },
    {
      code: '17-4018 TCX',
      name: 'Windward Blue',
      hex: '708497',
      rgb: {
        r: 112,
        g: 132,
        b: 151,
      },
      lab: {
        l: 54.02,
        a: -4.19,
        b: -12.87,
      },
      cmyk: null,
    },
    {
      code: '18-4221 TCX',
      name: 'Spring Lake',
      hex: '667E91',
      rgb: {
        r: 102,
        g: 126,
        b: 145,
      },
      lab: {
        l: 51.26,
        a: -5.48,
        b: -13.83,
      },
      cmyk: null,
    },
    {
      code: '18-4218 TCX',
      name: 'Blue Fusion',
      hex: '496275',
      rgb: {
        r: 73,
        g: 98,
        b: 117,
      },
      lab: {
        l: 39.94,
        a: -4.79,
        b: -14.68,
      },
      cmyk: null,
    },
    {
      code: '18-3923 TCX',
      name: 'Oceanview',
      hex: '4F677A',
      rgb: {
        r: 79,
        g: 103,
        b: 122,
      },
      lab: {
        l: 42.07,
        a: -5.77,
        b: -13.71,
      },
      cmyk: null,
    },
    {
      code: '19-4129 TCX',
      name: 'Key Largo',
      hex: '34465A',
      rgb: {
        r: 52,
        g: 70,
        b: 90,
      },
      lab: {
        l: 28.88,
        a: -3.09,
        b: -14.2,
      },
      cmyk: null,
    },
    {
      code: '19-4128 TCX',
      name: 'Titan',
      hex: '263B51',
      rgb: {
        r: 38,
        g: 59,
        b: 81,
      },
      lab: {
        l: 23.77,
        a: -3.06,
        b: -16.39,
      },
      cmyk: null,
    },
    {
      code: '18-4220 TCX',
      name: 'Provincial Blue',
      hex: '5C798E',
      rgb: {
        r: 92,
        g: 121,
        b: 142,
      },
      lab: {
        l: 48.95,
        a: -6.4,
        b: -15.58,
      },
      cmyk: null,
    },
    {
      code: '18-4217 TCX',
      name: 'Bluestone',
      hex: '587284',
      rgb: {
        r: 88,
        g: 114,
        b: 132,
      },
      lab: {
        l: 46.28,
        a: -6.33,
        b: -13.59,
      },
      cmyk: null,
    },
    {
      code: '18-4320 TCX',
      name: 'Aegean Blue',
      hex: '4E6E81',
      rgb: {
        r: 78,
        g: 110,
        b: 129,
      },
      lab: {
        l: 44.48,
        a: -7.89,
        b: -14.97,
      },
      cmyk: null,
    },
    {
      code: '18-3922 TCX',
      name: 'Coronet Blue',
      hex: '59728E',
      rgb: {
        r: 89,
        g: 114,
        b: 142,
      },
      lab: {
        l: 46.85,
        a: -3.78,
        b: -18.77,
      },
      cmyk: null,
    },
    {
      code: '18-4020 TCX',
      name: "Captain's Blue",
      hex: '557088',
      rgb: {
        r: 85,
        g: 112,
        b: 136,
      },
      lab: {
        l: 45.63,
        a: -5,
        b: -17.33,
      },
      cmyk: null,
    },
    {
      code: '18-4025 TCX',
      name: 'Copen Blue',
      hex: '516B84',
      rgb: {
        r: 81,
        g: 107,
        b: 132,
      },
      lab: {
        l: 43.73,
        a: -4.77,
        b: -17.63,
      },
      cmyk: null,
    },
    {
      code: '18-4026 TCX',
      name: 'Stellar',
      hex: '46647E',
      rgb: {
        r: 70,
        g: 100,
        b: 126,
      },
      lab: {
        l: 40.62,
        a: -5.88,
        b: -18.52,
      },
      cmyk: null,
    },
    {
      code: '18-4215 TCX',
      name: 'Blue Mirage',
      hex: '5C6D7C',
      rgb: {
        r: 92,
        g: 109,
        b: 124,
      },
      lab: {
        l: 44.89,
        a: -3.31,
        b: -10.91,
      },
      cmyk: null,
    },
    {
      code: '18-4028 TCX',
      name: 'Bering Sea',
      hex: '4B5B6E',
      rgb: {
        r: 75,
        g: 91,
        b: 110,
      },
      lab: {
        l: 37.78,
        a: -2.79,
        b: -13.2,
      },
      cmyk: null,
    },
    {
      code: '19-4126 TCX',
      name: 'Deep Dive',
      hex: '29495C',
      rgb: {
        r: 41,
        g: 73,
        b: 92,
      },
      lab: {
        l: 29.09,
        a: -7.47,
        b: -15.6,
      },
      cmyk: null,
    },
    {
      code: '19-4038 TCX',
      name: 'Gibraltar Sea',
      hex: '133951',
      rgb: {
        r: 19,
        g: 57,
        b: 81,
      },
      lab: {
        l: 21.91,
        a: -6.77,
        b: -19.38,
      },
      cmyk: null,
    },
    {
      code: '19-4120 TCX',
      name: 'Blue Opal',
      hex: '103B58',
      rgb: {
        r: 16,
        g: 59,
        b: 88,
      },
      lab: {
        l: 23.05,
        a: -6.75,
        b: -22.23,
      },
      cmyk: null,
    },
    {
      code: '19-4034 TCX',
      name: 'Sailor Blue ',
      hex: '0F3B53',
      rgb: {
        r: 15,
        g: 59,
        b: 83,
      },
      lab: {
        l: 22.64,
        a: -8.01,
        b: -19.99,
      },
      cmyk: null,
    },
    {
      code: '19-4125 TCX',
      name: 'Majolica Blue',
      hex: '284357',
      rgb: {
        r: 40,
        g: 67,
        b: 87,
      },
      lab: {
        l: 26.83,
        a: -6.01,
        b: -15.69,
      },
      cmyk: null,
    },
    {
      code: '17-4023 TCX',
      name: 'Blue Heaven',
      hex: '5B7E98',
      rgb: {
        r: 91,
        g: 126,
        b: 152,
      },
      lab: {
        l: 50.62,
        a: -7.79,
        b: -18.45,
      },
      cmyk: null,
    },
    {
      code: '18-4222 TCX',
      name: 'Bluesteel',
      hex: '36637C',
      rgb: {
        r: 54,
        g: 99,
        b: 124,
      },
      lab: {
        l: 39.23,
        a: -9.99,
        b: -20.06,
      },
      cmyk: null,
    },
    {
      code: '18-4018 TCX',
      name: 'Real Teal',
      hex: '405D73',
      rgb: {
        r: 64,
        g: 93,
        b: 115,
      },
      lab: {
        l: 37.81,
        a: -6.05,
        b: -16.39,
      },
      cmyk: null,
    },
    {
      code: '19-4127 TCX',
      name: 'Midnight',
      hex: '325B74',
      rgb: {
        r: 50,
        g: 91,
        b: 116,
      },
      lab: {
        l: 36.22,
        a: -8.7,
        b: -19.86,
      },
      cmyk: null,
    },
    {
      code: '18-4023 TCX',
      name: 'Blue Ashes',
      hex: '3B5F78',
      rgb: {
        r: 59,
        g: 95,
        b: 120,
      },
      lab: {
        l: 38.21,
        a: -7.84,
        b: -18.71,
      },
      cmyk: null,
    },
    {
      code: '19-4234 TCX',
      name: 'Ink Blue',
      hex: '0B5369',
      rgb: {
        r: 11,
        g: 83,
        b: 105,
      },
      lab: {
        l: 31.68,
        a: -14.95,
        b: -19.97,
      },
      cmyk: null,
    },
    {
      code: '19-4241 TCX',
      name: 'Moroccan Blue',
      hex: '104E67',
      rgb: {
        r: 16,
        g: 78,
        b: 103,
      },
      lab: {
        l: 30.09,
        a: -12.32,
        b: -21.26,
      },
      cmyk: null,
    },
    {
      code: '14-4106 TCX',
      name: 'Gray Dawn',
      hex: 'BBC1CC',
      rgb: {
        r: 187,
        g: 193,
        b: 204,
      },
      lab: {
        l: 77.9,
        a: -0.91,
        b: -6.06,
      },
      cmyk: null,
    },
    {
      code: '14-4206 TCX',
      name: 'Pearl Blue',
      hex: 'B0B7BE',
      rgb: {
        r: 176,
        g: 183,
        b: 190,
      },
      lab: {
        l: 74.07,
        a: -1.34,
        b: -4.55,
      },
      cmyk: null,
    },
    {
      code: '14-4210 TCX',
      name: 'Celestial Blue',
      hex: 'A3B4C4',
      rgb: {
        r: 163,
        g: 180,
        b: 196,
      },
      lab: {
        l: 72.38,
        a: -3.52,
        b: -10.2,
      },
      cmyk: null,
    },
    {
      code: '15-4008 TCX',
      name: 'Blue Fog',
      hex: '9BABBB',
      rgb: {
        r: 155,
        g: 171,
        b: 187,
      },
      lab: {
        l: 68.92,
        a: -2.93,
        b: -10.77,
      },
      cmyk: null,
    },
    {
      code: '16-4010 TCX',
      name: 'Dusty Blue',
      hex: '8C9DAD',
      rgb: {
        r: 140,
        g: 157,
        b: 173,
      },
      lab: {
        l: 63.68,
        a: -3.43,
        b: -10.83,
      },
      cmyk: null,
    },
    {
      code: '16-4013 TCX',
      name: 'Ashley Blue',
      hex: '8699AB',
      rgb: {
        r: 134,
        g: 153,
        b: 171,
      },
      lab: {
        l: 61.97,
        a: -3.73,
        b: -12,
      },
      cmyk: null,
    },
    {
      code: '17-4021 TCX',
      name: 'Faded Denim',
      hex: '798EA4',
      rgb: {
        r: 121,
        g: 142,
        b: 164,
      },
      lab: {
        l: 57.95,
        a: -3.7,
        b: -14.55,
      },
      cmyk: null,
    },
    {
      code: '12-4303 TCX',
      name: 'Country Air',
      hex: 'CBD9E7',
      rgb: {
        r: 203,
        g: 217,
        b: 231,
      },
      lab: {
        l: 85.95,
        a: -3.03,
        b: -8.43,
      },
      cmyk: null,
    },
    {
      code: '12-4202 TCX',
      name: 'Delicate Blue',
      hex: 'C2DAE8',
      rgb: {
        r: 194,
        g: 218,
        b: 232,
      },
      lab: {
        l: 85.6,
        a: -6.16,
        b: -10.08,
      },
      cmyk: null,
    },
    {
      code: '14-4215 TCX',
      name: 'Skyride',
      hex: 'AFC3D3',
      rgb: {
        r: 175,
        g: 195,
        b: 211,
      },
      lab: {
        l: 77.41,
        a: -4.51,
        b: -10.84,
      },
      cmyk: null,
    },
    {
      code: '14-4316 TCX',
      name: 'Summer Song',
      hex: 'A1C3DA',
      rgb: {
        r: 161,
        g: 195,
        b: 218,
      },
      lab: {
        l: 76.53,
        a: -8.28,
        b: -16.07,
      },
      cmyk: null,
    },
    {
      code: '14-4123 TCX',
      name: 'Clear Sky',
      hex: '9BBCD8',
      rgb: {
        r: 155,
        g: 188,
        b: 216,
      },
      lab: {
        l: 74.48,
        a: -6.62,
        b: -18.54,
      },
      cmyk: null,
    },
    {
      code: '14-4209 TCX',
      name: 'Subdued Blue',
      hex: 'A4B4C6',
      rgb: {
        r: 164,
        g: 180,
        b: 198,
      },
      lab: {
        l: 72.43,
        a: -2.47,
        b: -11.57,
      },
      cmyk: null,
    },
    {
      code: '16-4118 TCX',
      name: 'Glacier Lake',
      hex: '81A0BA',
      rgb: {
        r: 129,
        g: 160,
        b: 186,
      },
      lab: {
        l: 64.02,
        a: -6.45,
        b: -17.35,
      },
      cmyk: null,
    },
    {
      code: '13-4111 TCX',
      name: 'Plein Air',
      hex: 'BFCAD6',
      rgb: {
        r: 191,
        g: 202,
        b: 214,
      },
      lab: {
        l: 80.77,
        a: -2.07,
        b: -7.5,
      },
      cmyk: null,
    },
    {
      code: '13-4200 TCX',
      name: 'Omphalodes',
      hex: 'B5CEDF',
      rgb: {
        r: 181,
        g: 206,
        b: 223,
      },
      lab: {
        l: 81.04,
        a: -6.1,
        b: -12.07,
      },
      cmyk: null,
    },
    {
      code: '14-4317 TCX',
      name: 'Cool Blue',
      hex: 'A5C5D9',
      rgb: {
        r: 165,
        g: 197,
        b: 217,
      },
      lab: {
        l: 77.38,
        a: -8.06,
        b: -14.15,
      },
      cmyk: null,
    },
    {
      code: '14-4313 TCX',
      name: 'Aquamarine',
      hex: '9DC3D4',
      rgb: {
        r: 157,
        g: 195,
        b: 212,
      },
      lab: {
        l: 76.14,
        a: -9.75,
        b: -13.72,
      },
      cmyk: null,
    },
    {
      code: '15-4005 TCX',
      name: 'Dream Blue',
      hex: 'A0BCD0',
      rgb: {
        r: 160,
        g: 188,
        b: 208,
      },
      lab: {
        l: 74.59,
        a: -6.74,
        b: -13.91,
      },
      cmyk: null,
    },
    {
      code: '14-4318 TCX',
      name: 'Sky Blue',
      hex: '8ABAD3',
      rgb: {
        r: 138,
        g: 186,
        b: 211,
      },
      lab: {
        l: 72.46,
        a: -11.83,
        b: -18.6,
      },
      cmyk: null,
    },
    {
      code: '15-4319 TCX',
      name: 'Air Blue',
      hex: '77ACC7',
      rgb: {
        r: 119,
        g: 172,
        b: 199,
      },
      lab: {
        l: 67.04,
        a: -12.76,
        b: -20.87,
      },
      cmyk: null,
    },
    {
      code: '15-4105 TCX',
      name: 'Angel Falls',
      hex: 'A3BDD3',
      rgb: {
        r: 163,
        g: 189,
        b: 211,
      },
      lab: {
        l: 75.12,
        a: -5.65,
        b: -14.45,
      },
      cmyk: null,
    },
    {
      code: '16-4120 TCX',
      name: 'Dusk Blue',
      hex: '7BA0C0',
      rgb: {
        r: 123,
        g: 160,
        b: 192,
      },
      lab: {
        l: 63.81,
        a: -7.51,
        b: -21.1,
      },
      cmyk: null,
    },
    {
      code: '16-4127 TCX',
      name: 'Heritage Blue',
      hex: '5D96BC',
      rgb: {
        r: 93,
        g: 150,
        b: 188,
      },
      lab: {
        l: 59.05,
        a: -11.45,
        b: -26.52,
      },
      cmyk: null,
    },
    {
      code: '17-4123 TCX',
      name: 'Niagara',
      hex: '5587A4',
      rgb: {
        r: 85,
        g: 135,
        b: 164,
      },
      lab: {
        l: 53.21,
        a: -10.98,
        b: -21.95,
      },
      cmyk: null,
    },
    {
      code: '17-4131 TCX',
      name: 'Cendre Blue',
      hex: '3F7FA5',
      rgb: {
        r: 63,
        g: 127,
        b: 165,
      },
      lab: {
        l: 49.9,
        a: -13.19,
        b: -27.59,
      },
      cmyk: null,
    },
    {
      code: '18-4232 TCX',
      name: 'Faience',
      hex: '2A6A8B',
      rgb: {
        r: 42,
        g: 106,
        b: 139,
      },
      lab: {
        l: 41.67,
        a: -13.2,
        b: -25.01,
      },
      cmyk: null,
    },
    {
      code: '18-4231 TCX',
      name: 'Blue Sapphire',
      hex: '0D597D',
      rgb: {
        r: 13,
        g: 89,
        b: 125,
      },
      lab: {
        l: 34.61,
        a: -10.97,
        b: -28.26,
      },
      cmyk: null,
    },
    {
      code: '17-4336 TCX',
      name: 'Blithe',
      hex: '0084BD',
      rgb: {
        r: 0,
        g: 132,
        b: 189,
      },
      lab: {
        l: 50.99,
        a: -16.17,
        b: -40.3,
      },
      cmyk: null,
    },
    {
      code: '17-4433 TCX',
      name: 'Dresden Blue',
      hex: '0086BB',
      rgb: {
        r: 0,
        g: 134,
        b: 187,
      },
      lab: {
        l: 50.6,
        a: -21.7,
        b: -39.88,
      },
      cmyk: null,
    },
    {
      code: '18-4334 TCX',
      name: 'Mediterranian Blue',
      hex: '1578A7',
      rgb: {
        r: 21,
        g: 120,
        b: 167,
      },
      lab: {
        l: 46.51,
        a: -14.45,
        b: -34.67,
      },
      cmyk: null,
    },
    {
      code: '18-4330 TCX',
      name: 'Swedish Blue',
      hex: '007EB1',
      rgb: {
        r: 0,
        g: 126,
        b: 177,
      },
      lab: {
        l: 48.56,
        a: -16.86,
        b: -36.97,
      },
      cmyk: null,
    },
    {
      code: '18-4440 TCX',
      name: 'Cloisonné',
      hex: '0075AF',
      rgb: {
        r: 0,
        g: 117,
        b: 175,
      },
      lab: {
        l: 44.29,
        a: -18.68,
        b: -42.67,
      },
      cmyk: null,
    },
    {
      code: '17-4247 TCX',
      name: 'Diva Blue',
      hex: '007BB2',
      rgb: {
        r: 0,
        g: 123,
        b: 178,
      },
      lab: {
        l: 46.31,
        a: -21.7,
        b: -41.59,
      },
      cmyk: null,
    },
    {
      code: '18-4434 TCX',
      name: 'Mykonos Blue',
      hex: '005780',
      rgb: {
        r: 0,
        g: 87,
        b: 128,
      },
      lab: {
        l: 33.18,
        a: -16.07,
        b: -31.82,
      },
      cmyk: null,
    },
    {
      code: '15-4427 TCX',
      name: 'Norse Blue',
      hex: '4CA5C7',
      rgb: {
        r: 76,
        g: 165,
        b: 199,
      },
      lab: {
        l: 62.93,
        a: -20.35,
        b: -27.03,
      },
      cmyk: null,
    },
    {
      code: '17-4540 TCX',
      name: 'Hawaiian Ocean',
      hex: '008DB9',
      rgb: {
        r: 0,
        g: 141,
        b: 185,
      },
      lab: {
        l: 51.81,
        a: -31.18,
        b: -37.13,
      },
      cmyk: null,
    },
    {
      code: '17-4432 TCX',
      name: 'Vivid Blue',
      hex: '0088B0',
      rgb: {
        r: 0,
        g: 136,
        b: 176,
      },
      lab: {
        l: 50.36,
        a: -28.46,
        b: -33.84,
      },
      cmyk: null,
    },
    {
      code: '17-4440 TCX',
      name: 'Blue Danube',
      hex: '0087B6',
      rgb: {
        r: 0,
        g: 135,
        b: 182,
      },
      lab: {
        l: 49.86,
        a: -29.46,
        b: -38.44,
      },
      cmyk: null,
    },
    {
      code: '18-4535 TCX',
      name: 'Blue Jewel',
      hex: '0079A7',
      rgb: {
        r: 0,
        g: 121,
        b: 167,
      },
      lab: {
        l: 44.46,
        a: -29.06,
        b: -38.21,
      },
      cmyk: null,
    },
    {
      code: '18-4538 TCX',
      name: 'Hawaiian Surf',
      hex: '0079A8',
      rgb: {
        r: 0,
        g: 121,
        b: 168,
      },
      lab: {
        l: 44.55,
        a: -27.83,
        b: -38.2,
      },
      cmyk: null,
    },
    {
      code: '18-4537 TCX',
      name: 'Methyl Blue',
      hex: '0074A8',
      rgb: {
        r: 0,
        g: 116,
        b: 168,
      },
      lab: {
        l: 42.88,
        a: -24.42,
        b: -40.97,
      },
      cmyk: null,
    },
    {
      code: '14-4514 TCX',
      name: 'Atomizer',
      hex: '9AD4EA',
      rgb: {
        r: 154,
        g: 212,
        b: 234,
      },
      lab: {
        l: 81.31,
        a: -16.09,
        b: -17.54,
      },
      cmyk: null,
    },
    {
      code: '16-4520 TCX',
      name: 'Splish Splash',
      hex: '69BBDD',
      rgb: {
        r: 105,
        g: 187,
        b: 221,
      },
      lab: {
        l: 71.39,
        a: -19.9,
        b: -26.08,
      },
      cmyk: null,
    },
    {
      code: '15-4421 TCX',
      name: 'Blue Grotto',
      hex: '5DACCE',
      rgb: {
        r: 93,
        g: 172,
        b: 206,
      },
      lab: {
        l: 66.04,
        a: -18.69,
        b: -26.39,
      },
      cmyk: null,
    },
    {
      code: '15-4428 TCX',
      name: 'Crystal Seas',
      hex: '5EB0CF',
      rgb: {
        r: 94,
        g: 176,
        b: 207,
      },
      lab: {
        l: 67.14,
        a: -19.97,
        b: -24.89,
      },
      cmyk: null,
    },
    {
      code: '16-4526 TCX',
      name: 'Swim Cap',
      hex: '38A4D0',
      rgb: {
        r: 56,
        g: 164,
        b: 208,
      },
      lab: {
        l: 62.39,
        a: -21.68,
        b: -32.79,
      },
      cmyk: null,
    },
    {
      code: '16-4530 TCX',
      name: 'Aquarius',
      hex: '3CADD4',
      rgb: {
        r: 60,
        g: 173,
        b: 212,
      },
      lab: {
        l: 65.18,
        a: -23.97,
        b: -30.88,
      },
      cmyk: null,
    },
    {
      code: '16-4529 TCX',
      name: 'Cyan Blue',
      hex: '15A3C7',
      rgb: {
        r: 21,
        g: 163,
        b: 199,
      },
      lab: {
        l: 60.99,
        a: -26.63,
        b: -30.41,
      },
      cmyk: null,
    },
    {
      code: '13-4307 TCX',
      name: 'Tropical Breeze',
      hex: '88CDE7',
      rgb: {
        r: 136,
        g: 205,
        b: 231,
      },
      lab: {
        l: 78.25,
        a: -18.4,
        b: -21.02,
      },
      cmyk: null,
    },
    {
      code: '14-4310 TCX',
      name: 'Blue Topaz',
      hex: '78BDD4',
      rgb: {
        r: 120,
        g: 189,
        b: 212,
      },
      lab: {
        l: 72.2,
        a: -18.69,
        b: -19.64,
      },
      cmyk: null,
    },
    {
      code: '14-4522 TCX',
      name: 'Bachelor Button',
      hex: '4ABBD5',
      rgb: {
        r: 74,
        g: 187,
        b: 213,
      },
      lab: {
        l: 69.85,
        a: -27.96,
        b: -24.33,
      },
      cmyk: null,
    },
    {
      code: '15-4720 TCX',
      name: 'River Blue',
      hex: '38AECD',
      rgb: {
        r: 56,
        g: 174,
        b: 205,
      },
      lab: {
        l: 65.35,
        a: -26.84,
        b: -26.99,
      },
      cmyk: null,
    },
    {
      code: '16-4535 TCX',
      name: 'Blue Atoll',
      hex: '00B1D2',
      rgb: {
        r: 0,
        g: 177,
        b: 210,
      },
      lab: {
        l: 65.3,
        a: -32.99,
        b: -29.8,
      },
      cmyk: null,
    },
    {
      code: '16-4427 TCX',
      name: 'Horizon Blue',
      hex: '289DBE',
      rgb: {
        r: 40,
        g: 157,
        b: 190,
      },
      lab: {
        l: 59.12,
        a: -24.44,
        b: -28.28,
      },
      cmyk: null,
    },
    {
      code: '17-4427 TCX',
      name: 'Bluejay',
      hex: '167EA0',
      rgb: {
        r: 22,
        g: 126,
        b: 160,
      },
      lab: {
        l: 48.12,
        a: -19.3,
        b: -28.16,
      },
      cmyk: null,
    },
    {
      code: '13-4410 TCX',
      name: 'Cooling Spray',
      hex: 'C3E1EE',
      rgb: {
        r: 195,
        g: 225,
        b: 238,
      },
      lab: {
        l: 87.32,
        a: -8.43,
        b: -10.34,
      },
      cmyk: null,
    },
    {
      code: '13-4401 TCX',
      name: 'Quiet Tide',
      hex: 'C0E3F0',
      rgb: {
        r: 192,
        g: 227,
        b: 240,
      },
      lab: {
        l: 87.81,
        a: -9.98,
        b: -10.96,
      },
      cmyk: null,
    },
    {
      code: '12-4401 TCX',
      name: 'Spun Sugar',
      hex: 'B4DCEA',
      rgb: {
        r: 180,
        g: 220,
        b: 234,
      },
      lab: {
        l: 84.98,
        a: -11.39,
        b: -12,
      },
      cmyk: null,
    },
    {
      code: '14-4311 TCX',
      name: 'Corydalis Blue',
      hex: 'A9CADA',
      rgb: {
        r: 169,
        g: 202,
        b: 218,
      },
      lab: {
        l: 79.23,
        a: -9.25,
        b: -12.18,
      },
      cmyk: null,
    },
    {
      code: '13-4411 TCX',
      name: 'Crystal Blue',
      hex: 'A1C8DB',
      rgb: {
        r: 161,
        g: 200,
        b: 219,
      },
      lab: {
        l: 78.16,
        a: -10.32,
        b: -14.48,
      },
      cmyk: null,
    },
    {
      code: '14-4516 TCX',
      name: 'Petit four',
      hex: '87C2D4',
      rgb: {
        r: 135,
        g: 194,
        b: 212,
      },
      lab: {
        l: 74.5,
        a: -16.74,
        b: -16.26,
      },
      cmyk: null,
    },
    {
      code: '16-4421 TCX',
      name: 'Blue Mist',
      hex: '5BACC3',
      rgb: {
        r: 91,
        g: 172,
        b: 195,
      },
      lab: {
        l: 65.39,
        a: -21.62,
        b: -20.82,
      },
      cmyk: null,
    },
    {
      code: '18-4430 TCX',
      name: 'Fjord Blue',
      hex: '007291',
      rgb: {
        r: 0,
        g: 114,
        b: 145,
      },
      lab: {
        l: 43.07,
        a: -21.97,
        b: -26.92,
      },
      cmyk: null,
    },
    {
      code: '18-4528 TCX',
      name: 'Mosaic Blue',
      hex: '00758F',
      rgb: {
        r: 0,
        g: 117,
        b: 143,
      },
      lab: {
        l: 43.94,
        a: -22.52,
        b: -24.77,
      },
      cmyk: null,
    },
    {
      code: '18-4432 TCX',
      name: 'Turkish Tile',
      hex: '00698B',
      rgb: {
        r: 0,
        g: 105,
        b: 139,
      },
      lab: {
        l: 39.56,
        a: -21.43,
        b: -28.98,
      },
      cmyk: null,
    },
    {
      code: '18-4530 TCX',
      name: 'Celestial',
      hex: '006380',
      rgb: {
        r: 0,
        g: 99,
        b: 128,
      },
      lab: {
        l: 37.68,
        a: -16.91,
        b: -25.51,
      },
      cmyk: null,
    },
    {
      code: '18-4225 TCX',
      name: 'Saxony Blue',
      hex: '1F6680',
      rgb: {
        r: 31,
        g: 102,
        b: 128,
      },
      lab: {
        l: 39.56,
        a: -15.85,
        b: -21.98,
      },
      cmyk: null,
    },
    {
      code: '19-4342 TCX',
      name: 'Seaport',
      hex: '005E7D',
      rgb: {
        r: 0,
        g: 94,
        b: 125,
      },
      lab: {
        l: 35.82,
        a: -16.97,
        b: -26.09,
      },
      cmyk: null,
    },
    {
      code: '19-4329 TCX',
      name: 'Corsair',
      hex: '19576C',
      rgb: {
        r: 25,
        g: 87,
        b: 108,
      },
      lab: {
        l: 33.47,
        a: -13.29,
        b: -19.58,
      },
      cmyk: null,
    },
    {
      code: '17-4328 TCX',
      name: 'Blue Moon',
      hex: '3686A0',
      rgb: {
        r: 54,
        g: 134,
        b: 160,
      },
      lab: {
        l: 51.57,
        a: -19.63,
        b: -22.34,
      },
      cmyk: null,
    },
    {
      code: '17-4429 TCX',
      name: 'Navagio Bay',
      hex: '3284A1',
      rgb: {
        r: 50,
        g: 132,
        b: 161,
      },
      lab: {
        l: 50.67,
        a: -18.49,
        b: -24.2,
      },
      cmyk: null,
    },
    {
      code: '18-4630 TCX',
      name: 'Tahitian Tide',
      hex: '006C7F',
      rgb: {
        r: 0,
        g: 108,
        b: 127,
      },
      lab: {
        l: 39.78,
        a: -26.42,
        b: -21.58,
      },
      cmyk: null,
    },
    {
      code: '19-4536 TCX',
      name: 'Crystal Teal',
      hex: '00637C',
      rgb: {
        r: 0,
        g: 99,
        b: 124,
      },
      lab: {
        l: 36.77,
        a: -24.2,
        b: -24.49,
      },
      cmyk: null,
    },
    {
      code: '19-4540 TCX',
      name: 'Deep Lagoon',
      hex: '005366',
      rgb: {
        r: 0,
        g: 83,
        b: 102,
      },
      lab: {
        l: 30.03,
        a: -23.29,
        b: -21.1,
      },
      cmyk: null,
    },
    {
      code: '19-4535 TCX',
      name: 'Ocean Depths',
      hex: '006175',
      rgb: {
        r: 0,
        g: 97,
        b: 117,
      },
      lab: {
        l: 35.79,
        a: -24.31,
        b: -21.74,
      },
      cmyk: null,
    },
    {
      code: '19-4340 TCX',
      name: 'Lyons Blue',
      hex: '015871',
      rgb: {
        r: 1,
        g: 88,
        b: 113,
      },
      lab: {
        l: 33.58,
        a: -15.06,
        b: -22.29,
      },
      cmyk: null,
    },
    {
      code: '16-4525 TCX',
      name: 'Maui Blue',
      hex: '52A2B4',
      rgb: {
        r: 82,
        g: 162,
        b: 180,
      },
      lab: {
        l: 61.74,
        a: -22.39,
        b: -18.3,
      },
      cmyk: null,
    },
    {
      code: '17-4728 TCX',
      name: 'Algiers Blue',
      hex: '00859C',
      rgb: {
        r: 0,
        g: 133,
        b: 156,
      },
      lab: {
        l: 49.34,
        a: -29.88,
        b: -23.68,
      },
      cmyk: null,
    },
    {
      code: '17-4530 TCX',
      name: 'Barrier Reef',
      hex: '0084A1',
      rgb: {
        r: 0,
        g: 132,
        b: 161,
      },
      lab: {
        l: 49.46,
        a: -26.7,
        b: -26.57,
      },
      cmyk: null,
    },
    {
      code: '17-4730 TCX',
      name: 'Caneel Bay',
      hex: '00849F',
      rgb: {
        r: 0,
        g: 132,
        b: 159,
      },
      lab: {
        l: 48.94,
        a: -29,
        b: -26.66,
      },
      cmyk: null,
    },
    {
      code: '18-4525 TCX',
      name: 'Caribbean Sea',
      hex: '00819D',
      rgb: {
        r: 0,
        g: 129,
        b: 157,
      },
      lab: {
        l: 47.07,
        a: -34.15,
        b: -28,
      },
      cmyk: null,
    },
    {
      code: '17-4735 TCX',
      name: 'Capri Breeze',
      hex: '008799',
      rgb: {
        r: 0,
        g: 135,
        b: 153,
      },
      lab: {
        l: 48.5,
        a: -40.25,
        b: -23.86,
      },
      cmyk: null,
    },
    {
      code: '18-4733 TCX',
      name: 'Enamel Blue',
      hex: '007A8E',
      rgb: {
        r: 0,
        g: 122,
        b: 142,
      },
      lab: {
        l: 44.18,
        a: -34.2,
        b: -23.57,
      },
      cmyk: null,
    },
    {
      code: '12-4608 TCX',
      name: 'Clearwater',
      hex: 'AAD5DB',
      rgb: {
        r: 170,
        g: 213,
        b: 219,
      },
      lab: {
        l: 82.04,
        a: -13.68,
        b: -8.7,
      },
      cmyk: null,
    },
    {
      code: '14-4816 TCX',
      name: 'Blue Radiance',
      hex: '58C9D4',
      rgb: {
        r: 88,
        g: 201,
        b: 212,
      },
      lab: {
        l: 74.37,
        a: -31.78,
        b: -16.81,
      },
      cmyk: null,
    },
    {
      code: '15-4722 TCX',
      name: 'Capri',
      hex: '44BBCA',
      rgb: {
        r: 68,
        g: 187,
        b: 202,
      },
      lab: {
        l: 69.23,
        a: -31.27,
        b: -19.3,
      },
      cmyk: null,
    },
    {
      code: '15-4825 TCX',
      name: 'Blue Curacao',
      hex: '33BECC',
      rgb: {
        r: 51,
        g: 190,
        b: 204,
      },
      lab: {
        l: 69.74,
        a: -34.41,
        b: -19.95,
      },
      cmyk: null,
    },
    {
      code: '16-4725 TCX',
      name: 'Scuba Blue',
      hex: '00ABC0',
      rgb: {
        r: 0,
        g: 171,
        b: 192,
      },
      lab: {
        l: 61.84,
        a: -39.52,
        b: -25.6,
      },
      cmyk: null,
    },
    {
      code: '16-4834 TCX',
      name: 'Bluebird',
      hex: '009DAE',
      rgb: {
        r: 0,
        g: 157,
        b: 174,
      },
      lab: {
        l: 56.15,
        a: -43.23,
        b: -24,
      },
      cmyk: null,
    },
    {
      code: '16-4728 TCX',
      name: 'Peacock Blue',
      hex: '00A0B0',
      rgb: {
        r: 0,
        g: 160,
        b: 176,
      },
      lab: {
        l: 58.27,
        a: -36.72,
        b: -21.81,
      },
      cmyk: null,
    },
    {
      code: '13-4409 TCX',
      name: 'Blue Glow',
      hex: 'B2D4DD',
      rgb: {
        r: 178,
        g: 212,
        b: 221,
      },
      lab: {
        l: 82.33,
        a: -10.13,
        b: -9.12,
      },
      cmyk: null,
    },
    {
      code: '14-4510 TCX',
      name: 'Aquatic',
      hex: '9AC1CC',
      rgb: {
        r: 154,
        g: 193,
        b: 204,
      },
      lab: {
        l: 75.19,
        a: -11.83,
        b: -10.78,
      },
      cmyk: null,
    },
    {
      code: '14-4315 TCX',
      name: 'Sea Angel',
      hex: '98BFCB',
      rgb: {
        r: 152,
        g: 191,
        b: 203,
      },
      lab: {
        l: 74.63,
        a: -11.72,
        b: -10.84,
      },
      cmyk: null,
    },
    {
      code: '13-5410 TCX',
      name: 'Iced Aqua',
      hex: 'ACD3DC',
      rgb: {
        r: 172,
        g: 211,
        b: 220,
      },
      lab: {
        l: 81.73,
        a: -12.28,
        b: -9.41,
      },
      cmyk: null,
    },
    {
      code: '14-4508 TCX',
      name: 'Stratosphere',
      hex: '9EC1CC',
      rgb: {
        r: 158,
        g: 193,
        b: 204,
      },
      lab: {
        l: 75.65,
        a: -10.14,
        b: -10.14,
      },
      cmyk: null,
    },
    {
      code: '14-4512 TCX',
      name: 'Porcelain Blue',
      hex: '95C0CB',
      rgb: {
        r: 149,
        g: 192,
        b: 203,
      },
      lab: {
        l: 74.56,
        a: -12.36,
        b: -11.4,
      },
      cmyk: null,
    },
    {
      code: '15-4415 TCX',
      name: 'Milky Blue',
      hex: '72A8BA',
      rgb: {
        r: 114,
        g: 168,
        b: 186,
      },
      lab: {
        l: 65.42,
        a: -15.62,
        b: -15.71,
      },
      cmyk: null,
    },
    {
      code: '13-4304 TCX',
      name: 'Ballad Blue',
      hex: 'C0CEDA',
      rgb: {
        r: 192,
        g: 206,
        b: 218,
      },
      lab: {
        l: 81.85,
        a: -3.11,
        b: -7.73,
      },
      cmyk: null,
    },
    {
      code: '13-4308 TCX',
      name: 'Baby Blue',
      hex: 'B5C7D3',
      rgb: {
        r: 181,
        g: 199,
        b: 211,
      },
      lab: {
        l: 78.98,
        a: -4.03,
        b: -8.82,
      },
      cmyk: null,
    },
    {
      code: '15-4312 TCX',
      name: 'Forget-Me-Not',
      hex: '8FADBD',
      rgb: {
        r: 143,
        g: 173,
        b: 189,
      },
      lab: {
        l: 68.68,
        a: -7.74,
        b: -12.27,
      },
      cmyk: null,
    },
    {
      code: '16-4519 TCX',
      name: 'Delphinium Blue',
      hex: '6198AE',
      rgb: {
        r: 97,
        g: 152,
        b: 174,
      },
      lab: {
        l: 59.48,
        a: -15.25,
        b: -18.19,
      },
      cmyk: null,
    },
    {
      code: '17-4320 TCX',
      name: 'Adriatic Blue',
      hex: '5C899B',
      rgb: {
        r: 92,
        g: 137,
        b: 155,
      },
      lab: {
        l: 54.19,
        a: -12.18,
        b: -15.51,
      },
      cmyk: null,
    },
    {
      code: '17-4421 TCX',
      name: 'Larkspur',
      hex: '3C7D90',
      rgb: {
        r: 60,
        g: 125,
        b: 144,
      },
      lab: {
        l: 48.25,
        a: -16.15,
        b: -18.42,
      },
      cmyk: null,
    },
    {
      code: '17-4716 TCX',
      name: 'Storm Blue',
      hex: '47788A',
      rgb: {
        r: 71,
        g: 120,
        b: 138,
      },
      lab: {
        l: 47.22,
        a: -13.1,
        b: -15.87,
      },
      cmyk: null,
    },
    {
      code: '16-4109 TCX',
      name: 'Arona',
      hex: '879BA3',
      rgb: {
        r: 135,
        g: 155,
        b: 163,
      },
      lab: {
        l: 62.59,
        a: -5.87,
        b: -7.06,
      },
      cmyk: null,
    },
    {
      code: '17-4412 TCX',
      name: 'Smoke Blue',
      hex: '6D8994',
      rgb: {
        r: 109,
        g: 137,
        b: 148,
      },
      lab: {
        l: 55.01,
        a: -7.8,
        b: -10.11,
      },
      cmyk: null,
    },
    {
      code: '17-4111 TCX',
      name: 'Citadel',
      hex: '748995',
      rgb: {
        r: 116,
        g: 137,
        b: 149,
      },
      lab: {
        l: 55.57,
        a: -5.6,
        b: -9.64,
      },
      cmyk: null,
    },
    {
      code: '18-4417 TCX',
      name: 'Tapestry',
      hex: '436573',
      rgb: {
        r: 67,
        g: 101,
        b: 115,
      },
      lab: {
        l: 40.22,
        a: -8.51,
        b: -13.28,
      },
      cmyk: null,
    },
    {
      code: '19-4526 TCX',
      name: 'Blue Coral',
      hex: '1C5366',
      rgb: {
        r: 28,
        g: 83,
        b: 102,
      },
      lab: {
        l: 32.25,
        a: -13.05,
        b: -17.34,
      },
      cmyk: null,
    },
    {
      code: '19-4324 TCX',
      name: 'Legion Blue',
      hex: '1F495B',
      rgb: {
        r: 31,
        g: 73,
        b: 91,
      },
      lab: {
        l: 28.4,
        a: -10.43,
        b: -15.73,
      },
      cmyk: null,
    },
    {
      code: '19-4326 TCX',
      name: 'Reflecting Pond',
      hex: '203E4A',
      rgb: {
        r: 32,
        g: 62,
        b: 74,
      },
      lab: {
        l: 23.97,
        a: -8.25,
        b: -11.89,
      },
      cmyk: null,
    },
    {
      code: '13-4404 TCX',
      name: 'Ice Flow',
      hex: 'C6D2D2',
      rgb: {
        r: 198,
        g: 210,
        b: 210,
      },
      lab: {
        l: 83.11,
        a: -3.96,
        b: -1.97,
      },
      cmyk: null,
    },
    {
      code: '13-4103 TCX',
      name: 'Illusion Blue',
      hex: 'C9D3DC',
      rgb: {
        r: 201,
        g: 211,
        b: 220,
      },
      lab: {
        l: 83.95,
        a: -2.31,
        b: -5.67,
      },
      cmyk: null,
    },
    {
      code: '14-4306 TCX',
      name: 'Cloud Blue',
      hex: 'A2B6B9',
      rgb: {
        r: 162,
        g: 182,
        b: 185,
      },
      lab: {
        l: 72.34,
        a: -6.17,
        b: -4.5,
      },
      cmyk: null,
    },
    {
      code: '14-4506 TCX',
      name: 'Ether',
      hex: '9EB6B8',
      rgb: {
        r: 158,
        g: 182,
        b: 184,
      },
      lab: {
        l: 72.01,
        a: -7.85,
        b: -4.57,
      },
      cmyk: null,
    },
    {
      code: '16-4411 TCX',
      name: 'Tourmaline',
      hex: '86A1A9',
      rgb: {
        r: 134,
        g: 161,
        b: 169,
      },
      lab: {
        l: 64.16,
        a: -8.21,
        b: -7.93,
      },
      cmyk: null,
    },
    {
      code: '16-4114 TCX',
      name: 'Stone Blue',
      hex: '829CA5',
      rgb: {
        r: 130,
        g: 156,
        b: 165,
      },
      lab: {
        l: 62.35,
        a: -7.82,
        b: -8.54,
      },
      cmyk: null,
    },
    {
      code: '16-4414 TCX',
      name: 'Cameo Blue',
      hex: '769DA6',
      rgb: {
        r: 118,
        g: 157,
        b: 166,
      },
      lab: {
        l: 61.81,
        a: -12.01,
        b: -9.96,
      },
      cmyk: null,
    },
    {
      code: '14-4307 TCX',
      name: 'Winter Sky',
      hex: 'A9C0CB',
      rgb: {
        r: 169,
        g: 192,
        b: 203,
      },
      lab: {
        l: 76.08,
        a: -6.02,
        b: -8.72,
      },
      cmyk: null,
    },
    {
      code: '15-4309 TCX',
      name: 'Sterling Blue',
      hex: 'A2B9C2',
      rgb: {
        r: 162,
        g: 185,
        b: 194,
      },
      lab: {
        l: 73.59,
        a: -6.66,
        b: -7.53,
      },
      cmyk: null,
    },
    {
      code: '16-4612 TCX',
      name: 'Reef Waters',
      hex: '6F9FA9',
      rgb: {
        r: 111,
        g: 159,
        b: 169,
      },
      lab: {
        l: 62.04,
        a: -14.51,
        b: -11.65,
      },
      cmyk: null,
    },
    {
      code: '16-4610 TCX',
      name: 'Stillwater',
      hex: '70A4B0',
      rgb: {
        r: 112,
        g: 164,
        b: 176,
      },
      lab: {
        l: 63.8,
        a: -15.94,
        b: -12.54,
      },
      cmyk: null,
    },
    {
      code: '15-4717 TCX',
      name: 'Aqua',
      hex: '65A1AD',
      rgb: {
        r: 101,
        g: 161,
        b: 173,
      },
      lab: {
        l: 62.1,
        a: -18.28,
        b: -13.65,
      },
      cmyk: null,
    },
    {
      code: '16-5121 TCX',
      name: 'Meadowbrook',
      hex: '60A0A3',
      rgb: {
        r: 96,
        g: 160,
        b: 163,
      },
      lab: {
        l: 61.22,
        a: -21.31,
        b: -9.2,
      },
      cmyk: null,
    },
    {
      code: '16-4719 TCX',
      name: 'Porcelain',
      hex: '5D9CA4',
      rgb: {
        r: 93,
        g: 156,
        b: 164,
      },
      lab: {
        l: 60.07,
        a: -19.8,
        b: -11.8,
      },
      cmyk: null,
    },
    {
      code: '12-4609 TCX',
      name: 'Starlight Blue',
      hex: 'B5CED4',
      rgb: {
        r: 181,
        g: 206,
        b: 212,
      },
      lab: {
        l: 80.85,
        a: -7.47,
        b: -6.35,
      },
      cmyk: null,
    },
    {
      code: '13-4809 TCX',
      name: 'Plume',
      hex: 'A5CFD5',
      rgb: {
        r: 165,
        g: 207,
        b: 213,
      },
      lab: {
        l: 80.09,
        a: -13.58,
        b: -8.54,
      },
      cmyk: null,
    },
    {
      code: '15-4712 TCX',
      name: 'Marine Blue',
      hex: '76AFB6',
      rgb: {
        r: 118,
        g: 175,
        b: 182,
      },
      lab: {
        l: 67.36,
        a: -17.62,
        b: -10.89,
      },
      cmyk: null,
    },
    {
      code: '15-4714 TCX',
      name: 'Aquarelle',
      hex: '61AAB1',
      rgb: {
        r: 97,
        g: 170,
        b: 177,
      },
      lab: {
        l: 64.61,
        a: -22.77,
        b: -12.5,
      },
      cmyk: null,
    },
    {
      code: '15-4715 TCX',
      name: 'Aqua Sea',
      hex: '6BAAAE',
      rgb: {
        r: 107,
        g: 170,
        b: 174,
      },
      lab: {
        l: 65,
        a: -19.62,
        b: -10.17,
      },
      cmyk: null,
    },
    {
      code: '15-5209 TCX',
      name: 'Aqua Haze',
      hex: '87B9BB',
      rgb: {
        r: 135,
        g: 185,
        b: 187,
      },
      lab: {
        l: 71.53,
        a: -16.78,
        b: -7.54,
      },
      cmyk: null,
    },
    {
      code: '15-5210 TCX',
      name: 'Nile Blue',
      hex: '76A7AB',
      rgb: {
        r: 118,
        g: 167,
        b: 171,
      },
      lab: {
        l: 64.91,
        a: -17.1,
        b: -7.82,
      },
      cmyk: null,
    },
    {
      code: '12-4611 TCX',
      name: 'Saltwater Slide',
      hex: 'CBE9F2',
      rgb: {
        r: 203,
        g: 233,
        b: 242,
      },
      lab: {
        l: 90.15,
        a: -9.08,
        b: -8.12,
      },
      cmyk: null,
    },
    {
      code: '13-5411 TCX',
      name: 'Aqua-esque',
      hex: 'A3CCD6',
      rgb: {
        r: 163,
        g: 204,
        b: 214,
      },
      lab: {
        l: 79.16,
        a: -12.74,
        b: -10.22,
      },
      cmyk: null,
    },
    {
      code: '14-4515 TCX',
      name: 'Blue Elixir',
      hex: '8DCCDD',
      rgb: {
        r: 141,
        g: 204,
        b: 221,
      },
      lab: {
        l: 77.88,
        a: -18.19,
        b: -16.18,
      },
      cmyk: null,
    },
    {
      code: '14-4513 TCX',
      name: 'Cabana Blue',
      hex: '76B5C7',
      rgb: {
        r: 118,
        g: 181,
        b: 199,
      },
      lab: {
        l: 69.74,
        a: -18.06,
        b: -16.71,
      },
      cmyk: null,
    },
    {
      code: '14-4511 TCX',
      name: 'Gulf Stream',
      hex: '89C3D0',
      rgb: {
        r: 137,
        g: 195,
        b: 208,
      },
      lab: {
        l: 74.77,
        a: -17.76,
        b: -13.72,
      },
      cmyk: null,
    },
    {
      code: '15-4710 TCX',
      name: 'Barbados Beach',
      hex: '63B7C5',
      rgb: {
        r: 99,
        g: 183,
        b: 197,
      },
      lab: {
        l: 69.25,
        a: -24.17,
        b: -16.56,
      },
      cmyk: null,
    },
    {
      code: '15-4716 TCX',
      name: 'Ipanema',
      hex: '62BBC5',
      rgb: {
        r: 98,
        g: 187,
        b: 197,
      },
      lab: {
        l: 70.16,
        a: -26.06,
        b: -15.16,
      },
      cmyk: null,
    },
    {
      code: '14-4620 TCX',
      name: 'Island Paradise',
      hex: '96DFE4',
      rgb: {
        r: 150,
        g: 223,
        b: 228,
      },
      lab: {
        l: 83.71,
        a: -23.09,
        b: -11.16,
      },
      cmyk: null,
    },
    {
      code: '13-4720 TCX',
      name: 'Tanager Turquoise',
      hex: '91DDE8',
      rgb: {
        r: 145,
        g: 221,
        b: 232,
      },
      lab: {
        l: 83.01,
        a: -22.92,
        b: -14.26,
      },
      cmyk: null,
    },
    {
      code: '14-4812 TCX',
      name: 'Aqua Splash',
      hex: '85CED1',
      rgb: {
        r: 133,
        g: 206,
        b: 209,
      },
      lab: {
        l: 77.8,
        a: -24.08,
        b: -9.62,
      },
      cmyk: null,
    },
    {
      code: '13-4810 TCX',
      name: 'Limpet Shell',
      hex: '98DDDF',
      rgb: {
        r: 152,
        g: 221,
        b: 223,
      },
      lab: {
        l: 83.24,
        a: -22.36,
        b: -9.16,
      },
      cmyk: null,
    },
    {
      code: '14-4710 TCX',
      name: 'Tibetan Stone',
      hex: '83C2C7',
      rgb: {
        r: 131,
        g: 194,
        b: 199,
      },
      lab: {
        l: 74.06,
        a: -20.05,
        b: -10.34,
      },
      cmyk: null,
    },
    {
      code: '14-4814 TCX',
      name: 'Angel Blue',
      hex: '83C5CD',
      rgb: {
        r: 131,
        g: 197,
        b: 205,
      },
      lab: {
        l: 75.07,
        a: -20.45,
        b: -12.06,
      },
      cmyk: null,
    },
    {
      code: '14-4615 TCX',
      name: 'Antigua Sand',
      hex: '84C3CE',
      rgb: {
        r: 132,
        g: 195,
        b: 206,
      },
      lab: {
        l: 74.45,
        a: -18.99,
        b: -13.08,
      },
      cmyk: null,
    },
    {
      code: '14-4618 TCX',
      name: 'Waterspout',
      hex: '96D8DE',
      rgb: {
        r: 150,
        g: 216,
        b: 222,
      },
      lab: {
        l: 81.82,
        a: -21.17,
        b: -10.8,
      },
      cmyk: null,
    },
    {
      code: '14-4818 TCX',
      name: 'Amazonite',
      hex: '77BABE',
      rgb: {
        r: 119,
        g: 186,
        b: 190,
      },
      lab: {
        l: 70.75,
        a: -21.49,
        b: -10.17,
      },
      cmyk: null,
    },
    {
      code: '14-4815 TCX',
      name: 'Leisure Time',
      hex: '7FBFC5',
      rgb: {
        r: 127,
        g: 191,
        b: 197,
      },
      lab: {
        l: 72.81,
        a: -20.27,
        b: -10.84,
      },
      cmyk: null,
    },
    {
      code: '15-4711 TCX',
      name: 'Coastal Shade',
      hex: '6AA8AF',
      rgb: {
        r: 106,
        g: 168,
        b: 175,
      },
      lab: {
        l: 64.4,
        a: -19.93,
        b: -11.06,
      },
      cmyk: null,
    },
    {
      code: '15-4713 TCX',
      name: 'Sea Jet',
      hex: '54AFBC',
      rgb: {
        r: 84,
        g: 175,
        b: 188,
      },
      lab: {
        l: 65.97,
        a: -26.03,
        b: -16.49,
      },
      cmyk: null,
    },
    {
      code: '17-4725 TCX',
      name: 'Tahitian Teal',
      hex: '00899B',
      rgb: {
        r: 0,
        g: 137,
        b: 155,
      },
      lab: {
        l: 50.57,
        a: -31.24,
        b: -21.61,
      },
      cmyk: null,
    },
    {
      code: '18-4631 TCX',
      name: 'Exotic Plume',
      hex: '006E7F',
      rgb: {
        r: 0,
        g: 110,
        b: 127,
      },
      lab: {
        l: 40.62,
        a: -27.96,
        b: -20.44,
      },
      cmyk: null,
    },
    {
      code: '17-4928 TCX',
      name: 'Lake Blue',
      hex: '008C96',
      rgb: {
        r: 0,
        g: 140,
        b: 150,
      },
      lab: {
        l: 51.08,
        a: -37.01,
        b: -18.1,
      },
      cmyk: null,
    },
    {
      code: '18-4735 TCX',
      name: 'Tile Blue',
      hex: '008491',
      rgb: {
        r: 0,
        g: 132,
        b: 145,
      },
      lab: {
        l: 47.31,
        a: -41.52,
        b: -21.01,
      },
      cmyk: null,
    },
    {
      code: '18-4726 TCX',
      name: 'Biscay Bay',
      hex: '097988',
      rgb: {
        r: 9,
        g: 121,
        b: 136,
      },
      lab: {
        l: 45.64,
        a: -25.04,
        b: -17.75,
      },
      cmyk: null,
    },
    {
      code: '17-4724 TCX',
      name: 'Pagoda Blue',
      hex: '1B7F8E',
      rgb: {
        r: 27,
        g: 127,
        b: 142,
      },
      lab: {
        l: 47.95,
        a: -24.78,
        b: -17.61,
      },
      cmyk: null,
    },
    {
      code: '18-4728 TCX',
      name: 'Harbor Blue',
      hex: '00656E',
      rgb: {
        r: 0,
        g: 101,
        b: 110,
      },
      lab: {
        l: 37.47,
        a: -26.81,
        b: -14.14,
      },
      cmyk: null,
    },
    {
      code: '19-4524 TCX',
      name: 'Shaded Spruce',
      hex: '00585E',
      rgb: {
        r: 0,
        g: 88,
        b: 94,
      },
      lab: {
        l: 32.77,
        a: -21.36,
        b: -11.73,
      },
      cmyk: null,
    },
    {
      code: '19-4914 TCX',
      name: 'Deep Teal',
      hex: '19454B',
      rgb: {
        r: 25,
        g: 69,
        b: 75,
      },
      lab: {
        l: 26.28,
        a: -14.06,
        b: -8.9,
      },
      cmyk: null,
    },
    {
      code: '18-5610 TCX',
      name: 'Brittany Blue',
      hex: '4D7E86',
      rgb: {
        r: 77,
        g: 126,
        b: 134,
      },
      lab: {
        l: 49.22,
        a: -16.28,
        b: -10.36,
      },
      cmyk: null,
    },
    {
      code: '18-4718 TCX',
      name: 'Hydro',
      hex: '426972',
      rgb: {
        r: 66,
        g: 105,
        b: 114,
      },
      lab: {
        l: 41.45,
        a: -11.89,
        b: -10.28,
      },
      cmyk: null,
    },
    {
      code: '19-4826 TCX',
      name: 'Dragonfly',
      hex: '2A5C6A',
      rgb: {
        r: 42,
        g: 92,
        b: 106,
      },
      lab: {
        l: 35.96,
        a: -13.65,
        b: -14.06,
      },
      cmyk: null,
    },
    {
      code: '18-4522 TCX',
      name: 'Colonial Blue',
      hex: '2E6471',
      rgb: {
        r: 46,
        g: 100,
        b: 113,
      },
      lab: {
        l: 38.89,
        a: -15.34,
        b: -13.95,
      },
      cmyk: null,
    },
    {
      code: '19-4820 TCX',
      name: 'Balsam',
      hex: '33565E',
      rgb: {
        r: 51,
        g: 86,
        b: 94,
      },
      lab: {
        l: 34.05,
        a: -11.39,
        b: -9.05,
      },
      cmyk: null,
    },
    {
      code: '19-4517 TCX',
      name: 'Mediterranea',
      hex: '32575D',
      rgb: {
        r: 50,
        g: 87,
        b: 93,
      },
      lab: {
        l: 34.15,
        a: -12.12,
        b: -8.26,
      },
      cmyk: null,
    },
    {
      code: '19-4726 TCX',
      name: 'Atlantic Deep',
      hex: '274E55',
      rgb: {
        r: 39,
        g: 78,
        b: 85,
      },
      lab: {
        l: 30.32,
        a: -12.35,
        b: -9.15,
      },
      cmyk: null,
    },
    {
      code: '12-4604 TCX',
      name: 'Skylight',
      hex: 'C8E0E0',
      rgb: {
        r: 200,
        g: 224,
        b: 224,
      },
      lab: {
        l: 87.35,
        a: -8.25,
        b: -3.36,
      },
      cmyk: null,
    },
    {
      code: '12-4607 TCX',
      name: 'Pastel Blue',
      hex: 'BCD3D5',
      rgb: {
        r: 188,
        g: 211,
        b: 213,
      },
      lab: {
        l: 82.77,
        a: -7.23,
        b: -4.42,
      },
      cmyk: null,
    },
    {
      code: '14-4810 TCX',
      name: 'Canal Blue',
      hex: '9CC2C5',
      rgb: {
        r: 156,
        g: 194,
        b: 197,
      },
      lab: {
        l: 75.49,
        a: -12.61,
        b: -6.78,
      },
      cmyk: null,
    },
    {
      code: '16-5114 TCX',
      name: 'Dusty Turquoise',
      hex: '649B9E',
      rgb: {
        r: 100,
        g: 155,
        b: 158,
      },
      lab: {
        l: 59.91,
        a: -17.57,
        b: -8.96,
      },
      cmyk: null,
    },
    {
      code: '17-4818 TCX',
      name: 'Bristol Blue',
      hex: '568F91',
      rgb: {
        r: 86,
        g: 143,
        b: 145,
      },
      lab: {
        l: 55.26,
        a: -18.41,
        b: -8.64,
      },
      cmyk: null,
    },
    {
      code: '17-5117 TCX',
      name: 'Green-Blue Slate',
      hex: '358082',
      rgb: {
        r: 53,
        g: 128,
        b: 130,
      },
      lab: {
        l: 48.81,
        a: -24.31,
        b: -9.38,
      },
      cmyk: null,
    },
    {
      code: '17-4919 TCX',
      name: 'Teal',
      hex: '478589',
      rgb: {
        r: 71,
        g: 133,
        b: 137,
      },
      lab: {
        l: 51.12,
        a: -19.03,
        b: -9.94,
      },
      cmyk: null,
    },
    {
      code: '13-5306 TCX',
      name: 'Icy Morn',
      hex: 'B0D3D1',
      rgb: {
        r: 176,
        g: 211,
        b: 209,
      },
      lab: {
        l: 81.68,
        a: -12.06,
        b: -3.74,
      },
      cmyk: null,
    },
    {
      code: '13-4909 TCX',
      name: 'Blue Light',
      hex: 'ACDFDD',
      rgb: {
        r: 172,
        g: 223,
        b: 221,
      },
      lab: {
        l: 85.07,
        a: -17.57,
        b: -5.49,
      },
      cmyk: null,
    },
    {
      code: '13-4910 TCX',
      name: 'Blue Tint',
      hex: '9FD9D7',
      rgb: {
        r: 159,
        g: 217,
        b: 215,
      },
      lab: {
        l: 82.36,
        a: -19.72,
        b: -6.14,
      },
      cmyk: null,
    },
    {
      code: '13-5313 TCX',
      name: 'Aruba Blue',
      hex: '81D7D3',
      rgb: {
        r: 129,
        g: 215,
        b: 211,
      },
      lab: {
        l: 79.96,
        a: -28,
        b: -8.17,
      },
      cmyk: null,
    },
    {
      code: '14-4811 TCX',
      name: 'Aqua Sky',
      hex: '7BC4C4',
      rgb: {
        r: 123,
        g: 196,
        b: 196,
      },
      lab: {
        l: 74.04,
        a: -23.98,
        b: -8.6,
      },
      cmyk: null,
    },
    {
      code: '15-5217 TCX',
      name: 'Blue Turquoise',
      hex: '53B0AE',
      rgb: {
        r: 83,
        g: 176,
        b: 174,
      },
      lab: {
        l: 65.83,
        a: -29.32,
        b: -9.35,
      },
      cmyk: null,
    },
    {
      code: '16-5123 TCX',
      name: 'Baltic',
      hex: '279D9F',
      rgb: {
        r: 39,
        g: 157,
        b: 159,
      },
      lab: {
        l: 58.27,
        a: -32.08,
        b: -12.31,
      },
      cmyk: null,
    },
    {
      code: '11-4607 TCX',
      name: 'Diaphonous',
      hex: 'E7F4F2',
      rgb: {
        r: 231,
        g: 244,
        b: 242,
      },
      lab: {
        l: 95.05,
        a: -4.64,
        b: -0.86,
      },
      cmyk: null,
    },
    {
      code: '11-4606 TCX',
      name: 'Ice Castle',
      hex: 'DAF2F1',
      rgb: {
        r: 218,
        g: 242,
        b: 241,
      },
      lab: {
        l: 93.63,
        a: -9.01,
        b: -2.46,
      },
      cmyk: null,
    },
    {
      code: '12-5208 TCX',
      name: 'Mint Julep',
      hex: 'D1EFED',
      rgb: {
        r: 209,
        g: 239,
        b: 237,
      },
      lab: {
        l: 92.1,
        a: -10.84,
        b: -3.02,
      },
      cmyk: null,
    },
    {
      code: '12-5207 TCX',
      name: 'Salt Air',
      hex: 'C9EDED',
      rgb: {
        r: 201,
        g: 237,
        b: 237,
      },
      lab: {
        l: 91.08,
        a: -12.63,
        b: -4.68,
      },
      cmyk: null,
    },
    {
      code: '13-4908 TCX',
      name: 'Spa Retreat',
      hex: 'B4E8EC',
      rgb: {
        r: 180,
        g: 232,
        b: 236,
      },
      lab: {
        l: 88.21,
        a: -17.13,
        b: -8.29,
      },
      cmyk: null,
    },
    {
      code: '14-4715 TCX',
      name: 'Water Ballet',
      hex: '9FDFE4',
      rgb: {
        r: 159,
        g: 223,
        b: 228,
      },
      lab: {
        l: 84.4,
        a: -20.75,
        b: -9.93,
      },
      cmyk: null,
    },
    {
      code: '12-5302 TCX',
      name: 'Cooling Oasis',
      hex: 'BAD7D9',
      rgb: {
        r: 186,
        g: 215,
        b: 217,
      },
      lab: {
        l: 83.64,
        a: -9.26,
        b: -5.35,
      },
      cmyk: null,
    },
    {
      code: '12-5206 TCX',
      name: 'Blue Glass',
      hex: 'C7E3E1',
      rgb: {
        r: 199,
        g: 227,
        b: 225,
      },
      lab: {
        l: 87.8,
        a: -9.93,
        b: -2.81,
      },
      cmyk: null,
    },
    {
      code: '12-5209 TCX',
      name: 'Soothing Sea',
      hex: 'C3E9E4',
      rgb: {
        r: 195,
        g: 233,
        b: 228,
      },
      lab: {
        l: 89.2,
        a: -13.62,
        b: -2.39,
      },
      cmyk: null,
    },
    {
      code: '12-5808 TCX',
      name: 'Honeydew',
      hex: 'BAE1D3',
      rgb: {
        r: 186,
        g: 225,
        b: 211,
      },
      lab: {
        l: 86.26,
        a: -15.59,
        b: 1.91,
      },
      cmyk: null,
    },
    {
      code: '12-5409 TCX',
      name: 'Fair Aqua',
      hex: 'B8E2DC',
      rgb: {
        r: 184,
        g: 226,
        b: 220,
      },
      lab: {
        l: 86.63,
        a: -15.41,
        b: -2.36,
      },
      cmyk: null,
    },
    {
      code: '12-5410 TCX',
      name: 'Bleached Aqua',
      hex: 'BCE3DF',
      rgb: {
        r: 188,
        g: 227,
        b: 223,
      },
      lab: {
        l: 87.04,
        a: -13.84,
        b: -3.39,
      },
      cmyk: null,
    },
    {
      code: '14-4809 TCX',
      name: 'Eggshell Blue',
      hex: 'A3CCC9',
      rgb: {
        r: 163,
        g: 204,
        b: 201,
      },
      lab: {
        l: 78.75,
        a: -14.24,
        b: -3.99,
      },
      cmyk: null,
    },
    {
      code: '13-5309 TCX',
      name: 'Pastel Turquoise',
      hex: '99C5C4',
      rgb: {
        r: 153,
        g: 197,
        b: 196,
      },
      lab: {
        l: 76.06,
        a: -14.6,
        b: -5.54,
      },
      cmyk: null,
    },
    {
      code: '11-4604 TCX',
      name: 'Billowing Sail',
      hex: 'D8E7E7',
      rgb: {
        r: 216,
        g: 231,
        b: 231,
      },
      lab: {
        l: 90.46,
        a: -5.22,
        b: -1.84,
      },
      cmyk: null,
    },
    {
      code: '12-4305 TCX',
      name: 'Spa Blue',
      hex: 'D3DEDF',
      rgb: {
        r: 211,
        g: 222,
        b: 223,
      },
      lab: {
        l: 87.67,
        a: -3.58,
        b: -2.12,
      },
      cmyk: null,
    },
    {
      code: '12-4805 TCX',
      name: 'Wan Blue',
      hex: 'CBDCDF',
      rgb: {
        r: 203,
        g: 220,
        b: 223,
      },
      lab: {
        l: 86.34,
        a: -4.96,
        b: -3.95,
      },
      cmyk: null,
    },
    {
      code: '12-4610 TCX',
      name: 'Whispering Blue',
      hex: 'C9DCDC',
      rgb: {
        r: 201,
        g: 220,
        b: 220,
      },
      lab: {
        l: 86.3,
        a: -6.41,
        b: -2.62,
      },
      cmyk: null,
    },
    {
      code: '13-4804 TCX',
      name: 'Pale Blue',
      hex: 'C4D6D3',
      rgb: {
        r: 196,
        g: 214,
        b: 211,
      },
      lab: {
        l: 84.08,
        a: -6.34,
        b: -1.01,
      },
      cmyk: null,
    },
    {
      code: '13-4405 TCX',
      name: 'Misty Blue',
      hex: 'BFCDCC',
      rgb: {
        r: 191,
        g: 205,
        b: 204,
      },
      lab: {
        l: 81.26,
        a: -4.74,
        b: -1.62,
      },
      cmyk: null,
    },
    {
      code: '14-4504 TCX',
      name: 'Sky Gray',
      hex: 'BCC8C6',
      rgb: {
        r: 188,
        g: 200,
        b: 198,
      },
      lab: {
        l: 79.52,
        a: -4.04,
        b: -0.69,
      },
      cmyk: null,
    },
    {
      code: '11-4805 TCX',
      name: 'Hint of Mint',
      hex: 'D8EBE6',
      rgb: {
        r: 216,
        g: 235,
        b: 230,
      },
      lab: {
        l: 91.37,
        a: -7.39,
        b: -0.18,
      },
      cmyk: null,
    },
    {
      code: '12-5508 TCX',
      name: 'Hushed Green',
      hex: 'D8E9E5',
      rgb: {
        r: 216,
        g: 233,
        b: 229,
      },
      lab: {
        l: 90.9,
        a: -6.62,
        b: -0.27,
      },
      cmyk: null,
    },
    {
      code: '12-5204 TCX',
      name: 'Morning Mist',
      hex: 'CFDFDB',
      rgb: {
        r: 207,
        g: 223,
        b: 219,
      },
      lab: {
        l: 87.39,
        a: -5.73,
        b: -0.62,
      },
      cmyk: null,
    },
    {
      code: '12-5303 TCX',
      name: 'Sprout Green',
      hex: 'CBD7D2',
      rgb: {
        r: 203,
        g: 215,
        b: 210,
      },
      lab: {
        l: 84.98,
        a: -5.05,
        b: 0.81,
      },
      cmyk: null,
    },
    {
      code: '12-4806 TCX',
      name: 'Chalk Blue',
      hex: 'CCDAD7',
      rgb: {
        r: 204,
        g: 218,
        b: 215,
      },
      lab: {
        l: 85.77,
        a: -4.99,
        b: -0.73,
      },
      cmyk: null,
    },
    {
      code: '14-4807 TCX',
      name: 'Surf Spray',
      hex: 'B4C8C2',
      rgb: {
        r: 180,
        g: 200,
        b: 194,
      },
      lab: {
        l: 78.88,
        a: -7.69,
        b: 0.32,
      },
      cmyk: null,
    },
    {
      code: '14-4908 TCX',
      name: 'Harbor Gray',
      hex: 'A8C0BB',
      rgb: {
        r: 168,
        g: 192,
        b: 187,
      },
      lab: {
        l: 75.71,
        a: -8.58,
        b: -1.01,
      },
      cmyk: null,
    },
    {
      code: '15-4707 TCX',
      name: 'Blue Haze',
      hex: 'A5BCBB',
      rgb: {
        r: 165,
        g: 188,
        b: 187,
      },
      lab: {
        l: 74.42,
        a: -8.38,
        b: -2.59,
      },
      cmyk: null,
    },
    {
      code: '15-4706 TCX',
      name: 'Gray Mist',
      hex: '99AEAE',
      rgb: {
        r: 153,
        g: 174,
        b: 174,
      },
      lab: {
        l: 69.39,
        a: -7.35,
        b: -3.06,
      },
      cmyk: null,
    },
    {
      code: '16-5106 TCX',
      name: 'Blue Surf',
      hex: '90A8A4',
      rgb: {
        r: 144,
        g: 168,
        b: 164,
      },
      lab: {
        l: 66.74,
        a: -9.03,
        b: -1.21,
      },
      cmyk: null,
    },
    {
      code: '15-5207 TCX',
      name: 'Aquifer',
      hex: '89ACAC',
      rgb: {
        r: 137,
        g: 172,
        b: 172,
      },
      lab: {
        l: 67.45,
        a: -11.73,
        b: -4.9,
      },
      cmyk: null,
    },
    {
      code: '16-4712 TCX',
      name: 'Mineral Blue',
      hex: '6D9192',
      rgb: {
        r: 109,
        g: 145,
        b: 146,
      },
      lab: {
        l: 57.29,
        a: -12.41,
        b: -5.24,
      },
      cmyk: null,
    },
    {
      code: '17-5110 TCX',
      name: 'Trellis',
      hex: '6A8988',
      rgb: {
        r: 106,
        g: 137,
        b: 136,
      },
      lab: {
        l: 54.46,
        a: -11.23,
        b: -3.91,
      },
      cmyk: null,
    },
    {
      code: '17-4911 TCX',
      name: 'Arctic',
      hex: '648589',
      rgb: {
        r: 100,
        g: 133,
        b: 137,
      },
      lab: {
        l: 52.88,
        a: -10.94,
        b: -6.71,
      },
      cmyk: null,
    },
    {
      code: '16-4408 TCX',
      name: 'Slate',
      hex: '8C9FA1',
      rgb: {
        r: 140,
        g: 159,
        b: 161,
      },
      lab: {
        l: 63.83,
        a: -6.04,
        b: -4.1,
      },
      cmyk: null,
    },
    {
      code: '16-4706 TCX',
      name: 'Silver Blue',
      hex: '8A9A9A',
      rgb: {
        r: 138,
        g: 154,
        b: 154,
      },
      lab: {
        l: 62.2,
        a: -5.53,
        b: -2.43,
      },
      cmyk: null,
    },
    {
      code: '16-4404 TCX',
      name: 'Abyss',
      hex: '8F9E9D',
      rgb: {
        r: 143,
        g: 158,
        b: 157,
      },
      lab: {
        l: 63.78,
        a: -5.36,
        b: -1.52,
      },
      cmyk: null,
    },
    {
      code: '17-4408 TCX',
      name: 'Lead',
      hex: '7A898F',
      rgb: {
        r: 122,
        g: 137,
        b: 143,
      },
      lab: {
        l: 55.92,
        a: -4.66,
        b: -5.21,
      },
      cmyk: null,
    },
    {
      code: '18-4711 TCX',
      name: 'Stormy Sea',
      hex: '6E8082',
      rgb: {
        r: 110,
        g: 128,
        b: 130,
      },
      lab: {
        l: 52,
        a: -6.25,
        b: -3.8,
      },
      cmyk: null,
    },
    {
      code: '18-4510 TCX',
      name: 'Trooper',
      hex: '697A7E',
      rgb: {
        r: 105,
        g: 122,
        b: 126,
      },
      lab: {
        l: 49.97,
        a: -5.62,
        b: -4.71,
      },
      cmyk: null,
    },
    {
      code: '18-4011 TCX',
      name: 'Goblin Blue',
      hex: '5F7278',
      rgb: {
        r: 95,
        g: 114,
        b: 120,
      },
      lab: {
        l: 46.47,
        a: -6.2,
        b: -6.11,
      },
      cmyk: null,
    },
    {
      code: '16-5304 TCX',
      name: 'Jadeite',
      hex: '95A69F',
      rgb: {
        r: 149,
        g: 166,
        b: 159,
      },
      lab: {
        l: 66.45,
        a: -7.04,
        b: 1.21,
      },
      cmyk: null,
    },
    {
      code: '16-5804 TCX',
      name: 'Slate Gray',
      hex: '8A9691',
      rgb: {
        r: 138,
        g: 150,
        b: 145,
      },
      lab: {
        l: 60.88,
        a: -5.1,
        b: 1.07,
      },
      cmyk: null,
    },
    {
      code: '17-5107 TCX',
      name: 'Chinois Green',
      hex: '7C8C87',
      rgb: {
        r: 124,
        g: 140,
        b: 135,
      },
      lab: {
        l: 56.91,
        a: -6.75,
        b: 0.73,
      },
      cmyk: null,
    },
    {
      code: '16-5806 TCX',
      name: 'Green Milieu',
      hex: '8A9992',
      rgb: {
        r: 138,
        g: 153,
        b: 146,
      },
      lab: {
        l: 61.88,
        a: -6.45,
        b: 1.72,
      },
      cmyk: null,
    },
    {
      code: '18-5606 TCX',
      name: 'Balsam Green',
      hex: '576664',
      rgb: {
        r: 87,
        g: 102,
        b: 100,
      },
      lab: {
        l: 41.75,
        a: -5.63,
        b: -0.97,
      },
      cmyk: null,
    },
    {
      code: '18-4612 TCX',
      name: 'North Atlantic',
      hex: '546D70',
      rgb: {
        r: 84,
        g: 109,
        b: 112,
      },
      lab: {
        l: 43.83,
        a: -8.14,
        b: -5.9,
      },
      cmyk: null,
    },
    {
      code: '18-5112 TCX',
      name: 'Sea Pine',
      hex: '4C6969',
      rgb: {
        r: 76,
        g: 105,
        b: 105,
      },
      lab: {
        l: 41.87,
        a: -10.9,
        b: -3.99,
      },
      cmyk: null,
    },
    {
      code: '18-5612 TCX',
      name: 'Sagebrush Green',
      hex: '567572',
      rgb: {
        r: 86,
        g: 117,
        b: 114,
      },
      lab: {
        l: 46.65,
        a: -11.73,
        b: -2.64,
      },
      cmyk: null,
    },
    {
      code: '18-5410 TCX',
      name: 'Silver Pine',
      hex: '4E6866',
      rgb: {
        r: 78,
        g: 104,
        b: 102,
      },
      lab: {
        l: 41.81,
        a: -9.98,
        b: -2.77,
      },
      cmyk: null,
    },
    {
      code: '18-5308 TCX',
      name: 'Blue Spruce',
      hex: '486B67',
      rgb: {
        r: 72,
        g: 107,
        b: 103,
      },
      lab: {
        l: 42.32,
        a: -13.77,
        b: -2.28,
      },
      cmyk: null,
    },
    {
      code: '19-4818 TCX',
      name: 'Mallard Green',
      hex: '405E5C',
      rgb: {
        r: 64,
        g: 94,
        b: 92,
      },
      lab: {
        l: 37.34,
        a: -11.58,
        b: -3.2,
      },
      cmyk: null,
    },
    {
      code: '19-5408 TCX',
      name: 'Bistro Green',
      hex: '395551',
      rgb: {
        r: 57,
        g: 85,
        b: 81,
      },
      lab: {
        l: 33.49,
        a: -11.36,
        b: -1.65,
      },
      cmyk: null,
    },
    {
      code: '19-5030 TCX',
      name: 'Sea Moss',
      hex: '264445',
      rgb: {
        r: 38,
        g: 68,
        b: 69,
      },
      lab: {
        l: 26.44,
        a: -11.26,
        b: -4.84,
      },
      cmyk: null,
    },
    {
      code: '19-5320 TCX',
      name: 'Ponderosa Pine',
      hex: '203B3D',
      rgb: {
        r: 32,
        g: 59,
        b: 61,
      },
      lab: {
        l: 22.72,
        a: -9.55,
        b: -5.05,
      },
      cmyk: null,
    },
    {
      code: '19-5226 TCX',
      name: 'Everglade',
      hex: '005B5D',
      rgb: {
        r: 0,
        g: 91,
        b: 93,
      },
      lab: {
        l: 32.87,
        a: -29.29,
        b: -11,
      },
      cmyk: null,
    },
    {
      code: '19-4922 TCX',
      name: 'Teal Green',
      hex: '006361',
      rgb: {
        r: 0,
        g: 99,
        b: 97,
      },
      lab: {
        l: 36.61,
        a: -26.27,
        b: -7.96,
      },
      cmyk: null,
    },
    {
      code: '19-4916 TCX',
      name: 'Pacific',
      hex: '1F595C',
      rgb: {
        r: 31,
        g: 89,
        b: 92,
      },
      lab: {
        l: 33.9,
        a: -17.44,
        b: -9.03,
      },
      cmyk: null,
    },
    {
      code: '19-5413 TCX',
      name: 'Jasper',
      hex: '335959',
      rgb: {
        r: 51,
        g: 89,
        b: 89,
      },
      lab: {
        l: 34.8,
        a: -13.35,
        b: -5.3,
      },
      cmyk: null,
    },
    {
      code: '19-5217 TCX',
      name: 'Storm',
      hex: '035453',
      rgb: {
        r: 3,
        g: 84,
        b: 83,
      },
      lab: {
        l: 31.22,
        a: -22.24,
        b: -7,
      },
      cmyk: null,
    },
    {
      code: '18-5315 TCX',
      name: 'Bayberry',
      hex: '255958',
      rgb: {
        r: 37,
        g: 89,
        b: 88,
      },
      lab: {
        l: 33.92,
        a: -17.17,
        b: -6.42,
      },
      cmyk: null,
    },
    {
      code: '19-5414 TCX',
      name: 'June Bug',
      hex: '264A48',
      rgb: {
        r: 38,
        g: 74,
        b: 72,
      },
      lab: {
        l: 28.37,
        a: -13.88,
        b: -3.79,
      },
      cmyk: null,
    },
    {
      code: '18-4835 TCX',
      name: 'Alexandrite',
      hex: '00666C',
      rgb: {
        r: 0,
        g: 102,
        b: 108,
      },
      lab: {
        l: 37.8,
        a: -26.66,
        b: -12.95,
      },
      cmyk: null,
    },
    {
      code: '18-4833 TCX',
      name: 'Kayaking',
      hex: '00656A',
      rgb: {
        r: 0,
        g: 101,
        b: 106,
      },
      lab: {
        l: 36.55,
        a: -30.89,
        b: -14.1,
      },
      cmyk: null,
    },
    {
      code: '19-4918 TCX',
      name: 'Spruced-up',
      hex: '075055',
      rgb: {
        r: 7,
        g: 80,
        b: 85,
      },
      lab: {
        l: 29.97,
        a: -18.38,
        b: -10.65,
      },
      cmyk: null,
    },
    {
      code: '19-4534 TCX',
      name: 'Chesapeake Bay',
      hex: '16525A',
      rgb: {
        r: 22,
        g: 82,
        b: 90,
      },
      lab: {
        l: 30.98,
        a: -15.93,
        b: -12.14,
      },
      cmyk: null,
    },
    {
      code: '19-4727 TCX',
      name: 'Green Heron',
      hex: '27535A',
      rgb: {
        r: 39,
        g: 83,
        b: 90,
      },
      lab: {
        l: 32.12,
        a: -13.5,
        b: -9.99,
      },
      cmyk: null,
    },
    {
      code: '19-4523 TCX',
      name: 'Gulf Coast',
      hex: '0A4F5C',
      rgb: {
        r: 10,
        g: 79,
        b: 92,
      },
      lab: {
        l: 29.71,
        a: -15.28,
        b: -15.35,
      },
      cmyk: null,
    },
    {
      code: '19-5025 TCX',
      name: 'Dark Sea',
      hex: '1D3F43',
      rgb: {
        r: 29,
        g: 63,
        b: 67,
      },
      lab: {
        l: 24.08,
        a: -11.15,
        b: -7.45,
      },
      cmyk: null,
    },
    {
      code: '16-5112 TCX',
      name: 'Canton',
      hex: '6DA29E',
      rgb: {
        r: 109,
        g: 162,
        b: 158,
      },
      lab: {
        l: 62.63,
        a: -18.34,
        b: -4.75,
      },
      cmyk: null,
    },
    {
      code: '16-5412 TCX',
      name: 'Agate Green',
      hex: '599F99',
      rgb: {
        r: 89,
        g: 159,
        b: 153,
      },
      lab: {
        l: 60.37,
        a: -23.64,
        b: -5.42,
      },
      cmyk: null,
    },
    {
      code: '16-5119 TCX',
      name: 'Sea Blue',
      hex: '549F98',
      rgb: {
        r: 84,
        g: 159,
        b: 152,
      },
      lab: {
        l: 60.11,
        a: -26.88,
        b: -4.69,
      },
      cmyk: null,
    },
    {
      code: '17-5122 TCX',
      name: 'Latigo Bay',
      hex: '379190',
      rgb: {
        r: 55,
        g: 145,
        b: 144,
      },
      lab: {
        l: 54.55,
        a: -28.45,
        b: -8.71,
      },
      cmyk: null,
    },
    {
      code: '17-5421 TCX',
      name: 'Porcelain Green',
      hex: '108780',
      rgb: {
        r: 16,
        g: 135,
        b: 128,
      },
      lab: {
        l: 49.91,
        a: -32.34,
        b: -6.96,
      },
      cmyk: null,
    },
    {
      code: '18-5121 TCX',
      name: 'Bayou',
      hex: '20706F',
      rgb: {
        r: 32,
        g: 112,
        b: 111,
      },
      lab: {
        l: 42.21,
        a: -25.02,
        b: -7.93,
      },
      cmyk: null,
    },
    {
      code: '18-5115 TCX',
      name: 'North Sea',
      hex: '316C6B',
      rgb: {
        r: 49,
        g: 108,
        b: 107,
      },
      lab: {
        l: 41.34,
        a: -19.83,
        b: -6.56,
      },
      cmyk: null,
    },
    {
      code: '16-5127 TCX',
      name: 'Ceramic',
      hex: '00AAA9',
      rgb: {
        r: 0,
        g: 170,
        b: 169,
      },
      lab: {
        l: 61.04,
        a: -43.66,
        b: -13.82,
      },
      cmyk: null,
    },
    {
      code: '17-5126 TCX',
      name: 'Viridian Green',
      hex: '009499',
      rgb: {
        r: 0,
        g: 148,
        b: 153,
      },
      lab: {
        l: 53.91,
        a: -38.84,
        b: -15.24,
      },
      cmyk: null,
    },
    {
      code: '18-4930 TCX',
      name: 'Tropical Green',
      hex: '008786',
      rgb: {
        r: 0,
        g: 135,
        b: 134,
      },
      lab: {
        l: 47.88,
        a: -46.89,
        b: -13.76,
      },
      cmyk: null,
    },
    {
      code: '17-5025 TCX',
      name: 'Navigate',
      hex: '008583',
      rgb: {
        r: 0,
        g: 133,
        b: 131,
      },
      lab: {
        l: 48.23,
        a: -39.4,
        b: -11.57,
      },
      cmyk: null,
    },
    {
      code: '17-5029 TCX',
      name: 'Deep Peacock Blue',
      hex: '008381',
      rgb: {
        r: 0,
        g: 131,
        b: 129,
      },
      lab: {
        l: 46.59,
        a: -44.19,
        b: -13.08,
      },
      cmyk: null,
    },
    {
      code: '17-5034 TCX',
      name: 'Lapis',
      hex: '008684',
      rgb: {
        r: 0,
        g: 134,
        b: 132,
      },
      lab: {
        l: 47.66,
        a: -45.78,
        b: -12.78,
      },
      cmyk: null,
    },
    {
      code: '18-4834 TCX',
      name: 'Deep Lake',
      hex: '00656B',
      rgb: {
        r: 0,
        g: 101,
        b: 107,
      },
      lab: {
        l: 37.06,
        a: -29.56,
        b: -13.61,
      },
      cmyk: null,
    },
    {
      code: '15-5218 TCX',
      name: 'Pool Blue',
      hex: '67BCB3',
      rgb: {
        r: 103,
        g: 188,
        b: 179,
      },
      lab: {
        l: 70.18,
        a: -28.89,
        b: -5.25,
      },
      cmyk: null,
    },
    {
      code: '15-5519 TCX',
      name: 'Turquoise',
      hex: '45B5AA',
      rgb: {
        r: 69,
        g: 181,
        b: 170,
      },
      lab: {
        l: 66.69,
        a: -35.38,
        b: -5.83,
      },
      cmyk: null,
    },
    {
      code: '15-5516 TCX',
      name: 'Waterfall',
      hex: '3AB0A2',
      rgb: {
        r: 58,
        g: 176,
        b: 162,
      },
      lab: {
        l: 64.59,
        a: -36.74,
        b: -4.72,
      },
      cmyk: null,
    },
    {
      code: '16-5418 TCX',
      name: 'Lagoon',
      hex: '4D9E9A',
      rgb: {
        r: 77,
        g: 158,
        b: 154,
      },
      lab: {
        l: 59.64,
        a: -26.83,
        b: -6.89,
      },
      cmyk: null,
    },
    {
      code: '16-5422 TCX',
      name: 'Bright Aqua',
      hex: '30A299',
      rgb: {
        r: 48,
        g: 162,
        b: 153,
      },
      lab: {
        l: 59.86,
        a: -34.55,
        b: -6.57,
      },
      cmyk: null,
    },
    {
      code: '18-5128 TCX',
      name: 'Blue Grass',
      hex: '007C7A',
      rgb: {
        r: 0,
        g: 124,
        b: 122,
      },
      lab: {
        l: 44.56,
        a: -41.04,
        b: -11.58,
      },
      cmyk: null,
    },
    {
      code: '18-4936 TCX',
      name: 'Fanfare',
      hex: '006D70',
      rgb: {
        r: 0,
        g: 109,
        b: 112,
      },
      lab: {
        l: 39.01,
        a: -37.43,
        b: -14.15,
      },
      cmyk: null,
    },
    {
      code: '15-5425 TCX',
      name: 'Atlantis',
      hex: '00AF9F',
      rgb: {
        r: 0,
        g: 175,
        b: 159,
      },
      lab: {
        l: 63.05,
        a: -44.67,
        b: -5.47,
      },
      cmyk: null,
    },
    {
      code: '16-5425 TCX',
      name: 'Pool Green',
      hex: '00AF9D',
      rgb: {
        r: 0,
        g: 175,
        b: 157,
      },
      lab: {
        l: 63.11,
        a: -45.12,
        b: -4.43,
      },
      cmyk: null,
    },
    {
      code: '17-5335 TCX',
      name: 'Spectra Green',
      hex: '009B8C',
      rgb: {
        r: 0,
        g: 155,
        b: 140,
      },
      lab: {
        l: 55.02,
        a: -49.88,
        b: -7.06,
      },
      cmyk: null,
    },
    {
      code: '17-5330 TCX',
      name: 'Dynasty Green',
      hex: '008E80',
      rgb: {
        r: 0,
        g: 142,
        b: 128,
      },
      lab: {
        l: 50.23,
        a: -50.07,
        b: -6.96,
      },
      cmyk: null,
    },
    {
      code: '17-5130 TCX',
      name: 'Columbia',
      hex: '009288',
      rgb: {
        r: 0,
        g: 146,
        b: 136,
      },
      lab: {
        l: 51.6,
        a: -48.29,
        b: -9.56,
      },
      cmyk: null,
    },
    {
      code: '17-5024 TCX',
      name: 'Teal Blue',
      hex: '007F7B',
      rgb: {
        r: 0,
        g: 127,
        b: 123,
      },
      lab: {
        l: 46.29,
        a: -35.29,
        b: -9.71,
      },
      cmyk: null,
    },
    {
      code: '18-5025 TCX',
      name: 'Quetzal Green',
      hex: '006866',
      rgb: {
        r: 0,
        g: 104,
        b: 102,
      },
      lab: {
        l: 37.49,
        a: -33.83,
        b: -10.16,
      },
      cmyk: null,
    },
    {
      code: '14-5416 TCX',
      name: 'Bermuda',
      hex: '60C8B3',
      rgb: {
        r: 96,
        g: 200,
        b: 179,
      },
      lab: {
        l: 73.64,
        a: -35.95,
        b: -0.59,
      },
      cmyk: null,
    },
    {
      code: '14-5721 TCX',
      name: 'Electric Green',
      hex: '4BC3A7',
      rgb: {
        r: 75,
        g: 195,
        b: 167,
      },
      lab: {
        l: 70.86,
        a: -40.22,
        b: 1.55,
      },
      cmyk: null,
    },
    {
      code: '15-5421 TCX',
      name: 'Aqua Green',
      hex: '00B89F',
      rgb: {
        r: 0,
        g: 184,
        b: 159,
      },
      lab: {
        l: 66.21,
        a: -45.65,
        b: -1.1,
      },
      cmyk: null,
    },
    {
      code: '16-5427 TCX',
      name: 'Billiard',
      hex: '00AA92',
      rgb: {
        r: 0,
        g: 170,
        b: 146,
      },
      lab: {
        l: 61,
        a: -46.01,
        b: -1.23,
      },
      cmyk: null,
    },
    {
      code: '16-5431 TCX',
      name: 'Peacock Green',
      hex: '00A78B',
      rgb: {
        r: 0,
        g: 167,
        b: 139,
      },
      lab: {
        l: 59.39,
        a: -51.22,
        b: 0.2,
      },
      cmyk: null,
    },
    {
      code: '16-5533 TCX',
      name: 'Arcadia',
      hex: '00A28A',
      rgb: {
        r: 0,
        g: 162,
        b: 138,
      },
      lab: {
        l: 57.95,
        a: -46.43,
        b: -1.54,
      },
      cmyk: null,
    },
    {
      code: '18-5020 TCX',
      name: 'Parasailing',
      hex: '00736C',
      rgb: {
        r: 0,
        g: 115,
        b: 108,
      },
      lab: {
        l: 41.21,
        a: -38.3,
        b: -8.4,
      },
      cmyk: null,
    },
    {
      code: '13-6009 TCX',
      name: 'Brook Green',
      hex: 'AFDDCC',
      rgb: {
        r: 175,
        g: 221,
        b: 204,
      },
      lab: {
        l: 84.1,
        a: -17.97,
        b: 2.34,
      },
      cmyk: null,
    },
    {
      code: '13-5714 TCX',
      name: 'Cabbage',
      hex: '87D7BE',
      rgb: {
        r: 135,
        g: 215,
        b: 190,
      },
      lab: {
        l: 79.73,
        a: -29.97,
        b: 2.67,
      },
      cmyk: null,
    },
    {
      code: '14-5714 TCX',
      name: 'Beveled Glass',
      hex: '7ACCB8',
      rgb: {
        r: 122,
        g: 204,
        b: 184,
      },
      lab: {
        l: 75.96,
        a: -30.31,
        b: 0.68,
      },
      cmyk: null,
    },
    {
      code: '14-5718 TCX',
      name: 'Opal',
      hex: '77CFB7',
      rgb: {
        r: 119,
        g: 207,
        b: 183,
      },
      lab: {
        l: 76.6,
        a: -32.03,
        b: 1.62,
      },
      cmyk: null,
    },
    {
      code: '15-5819 TCX',
      name: 'Spearmint',
      hex: '64BFA4',
      rgb: {
        r: 100,
        g: 191,
        b: 164,
      },
      lab: {
        l: 70.61,
        a: -33.81,
        b: 3.5,
      },
      cmyk: null,
    },
    {
      code: '15-5718 TCX',
      name: 'Biscay Green',
      hex: '56C6A9',
      rgb: {
        r: 86,
        g: 198,
        b: 169,
      },
      lab: {
        l: 72.27,
        a: -39.08,
        b: 2.97,
      },
      cmyk: null,
    },
    {
      code: '15-5728 TCX',
      name: 'Mint Leaf',
      hex: '00B694',
      rgb: {
        r: 0,
        g: 182,
        b: 148,
      },
      lab: {
        l: 65.25,
        a: -47.67,
        b: 3.83,
      },
      cmyk: null,
    },
    {
      code: '12-5407 TCX',
      name: 'Aqua Glass',
      hex: 'D2E8DF',
      rgb: {
        r: 210,
        g: 232,
        b: 223,
      },
      lab: {
        l: 90.05,
        a: -8.86,
        b: 1.09,
      },
      cmyk: null,
    },
    {
      code: '12-5507 TCX',
      name: 'Bay',
      hex: 'BAE5D6',
      rgb: {
        r: 186,
        g: 229,
        b: 214,
      },
      lab: {
        l: 87.41,
        a: -16.85,
        b: 1.73,
      },
      cmyk: null,
    },
    {
      code: '13-5409 TCX',
      name: 'Yucca',
      hex: 'A1D7C9',
      rgb: {
        r: 161,
        g: 215,
        b: 201,
      },
      lab: {
        l: 81.75,
        a: -20.36,
        b: 0.14,
      },
      cmyk: null,
    },
    {
      code: '13-5412 TCX',
      name: 'Beach Glass',
      hex: '96DFCE',
      rgb: {
        r: 150,
        g: 223,
        b: 206,
      },
      lab: {
        l: 83.13,
        a: -26.53,
        b: -0.82,
      },
      cmyk: null,
    },
    {
      code: '13-5414 TCX',
      name: 'Ice Green',
      hex: '87D8C3',
      rgb: {
        r: 135,
        g: 216,
        b: 195,
      },
      lab: {
        l: 80.13,
        a: -29.62,
        b: 0.93,
      },
      cmyk: null,
    },
    {
      code: '14-5420 TCX',
      name: 'Cockatoo',
      hex: '58C8B6',
      rgb: {
        r: 88,
        g: 200,
        b: 182,
      },
      lab: {
        l: 73.16,
        a: -36.82,
        b: -2.78,
      },
      cmyk: null,
    },
    {
      code: '15-5416 TCX',
      name: 'Florida Keys',
      hex: '57BEAB',
      rgb: {
        r: 87,
        g: 190,
        b: 171,
      },
      lab: {
        l: 70.01,
        a: -35.26,
        b: -1.3,
      },
      cmyk: null,
    },
    {
      code: '12-5406 TCX',
      name: 'Opal Blue',
      hex: 'C3DDD6',
      rgb: {
        r: 195,
        g: 221,
        b: 214,
      },
      lab: {
        l: 85.76,
        a: -9.76,
        b: -0.49,
      },
      cmyk: null,
    },
    {
      code: '12-5408 TCX',
      name: 'Moonlight Jade',
      hex: 'C7E5DF',
      rgb: {
        r: 199,
        g: 229,
        b: 223,
      },
      lab: {
        l: 88.5,
        a: -11.55,
        b: -0.78,
      },
      cmyk: null,
    },
    {
      code: '12-5505 TCX',
      name: 'Glacier',
      hex: 'C3DBD4',
      rgb: {
        r: 195,
        g: 219,
        b: 212,
      },
      lab: {
        l: 85.25,
        a: -9.44,
        b: 0.37,
      },
      cmyk: null,
    },
    {
      code: '14-5711 TCX',
      name: 'Ocean Wave',
      hex: '8EC5B6',
      rgb: {
        r: 142,
        g: 197,
        b: 182,
      },
      lab: {
        l: 75.05,
        a: -20.47,
        b: 0.46,
      },
      cmyk: null,
    },
    {
      code: '14-5413 TCX',
      name: 'Holiday',
      hex: '81C3B4',
      rgb: {
        r: 129,
        g: 195,
        b: 180,
      },
      lab: {
        l: 73.73,
        a: -24.44,
        b: -0.51,
      },
      cmyk: null,
    },
    {
      code: '14-5713 TCX',
      name: 'Cascade',
      hex: '76C1B2',
      rgb: {
        r: 118,
        g: 193,
        b: 178,
      },
      lab: {
        l: 72.55,
        a: -27.17,
        b: -1.31,
      },
      cmyk: null,
    },
    {
      code: '15-5711 TCX',
      name: 'Dusty Jade Green',
      hex: '7BB5A3',
      rgb: {
        r: 123,
        g: 181,
        b: 163,
      },
      lab: {
        l: 68.85,
        a: -22.5,
        b: 1.82,
      },
      cmyk: null,
    },
    {
      code: '16-5109 TCX',
      name: 'Wasabi',
      hex: '73A89E',
      rgb: {
        r: 115,
        g: 168,
        b: 158,
      },
      lab: {
        l: 64.64,
        a: -20.52,
        b: -1.52,
      },
      cmyk: null,
    },
    {
      code: '16-5515 TCX',
      name: 'Beryl Green',
      hex: '619187',
      rgb: {
        r: 97,
        g: 145,
        b: 135,
      },
      lab: {
        l: 56.37,
        a: -19.45,
        b: -0.77,
      },
      cmyk: null,
    },
    {
      code: '17-5111 TCX',
      name: 'Oil Blue',
      hex: '658C88',
      rgb: {
        r: 101,
        g: 140,
        b: 136,
      },
      lab: {
        l: 54.99,
        a: -14.04,
        b: -3.49,
      },
      cmyk: null,
    },
    {
      code: '17-5513 TCX',
      name: 'Deep Sea',
      hex: '4F7C74',
      rgb: {
        r: 79,
        g: 124,
        b: 116,
      },
      lab: {
        l: 48.31,
        a: -16.65,
        b: -2.06,
      },
      cmyk: null,
    },
    {
      code: '18-5618 TCX',
      name: 'Deep Jungle',
      hex: '36716F',
      rgb: {
        r: 54,
        g: 113,
        b: 111,
      },
      lab: {
        l: 43.54,
        a: -21.49,
        b: -5.34,
      },
      cmyk: null,
    },
    {
      code: '18-5619 TCX',
      name: 'Tidepool',
      hex: '0B6F69',
      rgb: {
        r: 11,
        g: 111,
        b: 105,
      },
      lab: {
        l: 41.33,
        a: -28.83,
        b: -5.38,
      },
      cmyk: null,
    },
    {
      code: '18-5620 TCX',
      name: 'Ivy',
      hex: '226C63',
      rgb: {
        r: 34,
        g: 108,
        b: 99,
      },
      lab: {
        l: 40.49,
        a: -26.06,
        b: -2.81,
      },
      cmyk: null,
    },
    {
      code: '16-5919 TCX',
      name: 'Crème de Menthe',
      hex: '70A38D',
      rgb: {
        r: 112,
        g: 163,
        b: 141,
      },
      lab: {
        l: 62.64,
        a: -22.29,
        b: 4.97,
      },
      cmyk: null,
    },
    {
      code: '16-5815 TCX',
      name: 'Feldspar',
      hex: '729B8B',
      rgb: {
        r: 114,
        g: 155,
        b: 139,
      },
      lab: {
        l: 60.38,
        a: -16.36,
        b: 2.86,
      },
      cmyk: null,
    },
    {
      code: '18-5622 TCX',
      name: 'Frosty Spruce',
      hex: '578270',
      rgb: {
        r: 87,
        g: 130,
        b: 112,
      },
      lab: {
        l: 50.58,
        a: -17.66,
        b: 3.73,
      },
      cmyk: null,
    },
    {
      code: '17-5722 TCX',
      name: 'Bottle Green',
      hex: '437D6D',
      rgb: {
        r: 67,
        g: 125,
        b: 109,
      },
      lab: {
        l: 47.62,
        a: -22.52,
        b: 1.37,
      },
      cmyk: null,
    },
    {
      code: '18-5418 TCX',
      name: 'Antique Green',
      hex: '2A675C',
      rgb: {
        r: 42,
        g: 103,
        b: 92,
      },
      lab: {
        l: 38.92,
        a: -21.55,
        b: -1.49,
      },
      cmyk: null,
    },
    {
      code: '18-5725 TCX',
      name: 'Galapagos Green',
      hex: '29685F',
      rgb: {
        r: 41,
        g: 104,
        b: 95,
      },
      lab: {
        l: 39.39,
        a: -23.44,
        b: -2.11,
      },
      cmyk: null,
    },
    {
      code: '19-5230 TCX',
      name: 'Forest Biome',
      hex: '194B46',
      rgb: {
        r: 25,
        g: 75,
        b: 70,
      },
      lab: {
        l: 28.12,
        a: -18.54,
        b: -2.78,
      },
      cmyk: null,
    },
    {
      code: '16-5917 TCX',
      name: 'Malachite Green',
      hex: '709A89',
      rgb: {
        r: 112,
        g: 154,
        b: 137,
      },
      lab: {
        l: 60.01,
        a: -17,
        b: 3.15,
      },
      cmyk: null,
    },
    {
      code: '18-5621 TCX',
      name: 'Fir',
      hex: '3B725F',
      rgb: {
        r: 59,
        g: 114,
        b: 95,
      },
      lab: {
        l: 43.48,
        a: -21.81,
        b: 3.42,
      },
      cmyk: null,
    },
    {
      code: '19-5420 TCX',
      name: 'Evergreen',
      hex: '12574A',
      rgb: {
        r: 18,
        g: 87,
        b: 74,
      },
      lab: {
        l: 32.39,
        a: -23.95,
        b: -0.05,
      },
      cmyk: null,
    },
    {
      code: '18-5616 TCX',
      name: 'Posy Green',
      hex: '325B51',
      rgb: {
        r: 50,
        g: 91,
        b: 81,
      },
      lab: {
        l: 35.26,
        a: -16.48,
        b: 0.32,
      },
      cmyk: null,
    },
    {
      code: '19-5511 TCX',
      name: 'Hunter Green',
      hex: '335749',
      rgb: {
        r: 51,
        g: 87,
        b: 73,
      },
      lab: {
        l: 33.62,
        a: -15.39,
        b: 2.73,
      },
      cmyk: null,
    },
    {
      code: '18-5718 TCX',
      name: 'Smoke Pine',
      hex: '3E6257',
      rgb: {
        r: 62,
        g: 98,
        b: 87,
      },
      lab: {
        l: 38.44,
        a: -15.07,
        b: 1.47,
      },
      cmyk: null,
    },
    {
      code: '19-5232 TCX',
      name: 'Rain Forest',
      hex: '16463F',
      rgb: {
        r: 22,
        g: 70,
        b: 63,
      },
      lab: {
        l: 26.05,
        a: -17.46,
        b: -2.05,
      },
      cmyk: null,
    },
    {
      code: '16-5721 TCX',
      name: 'Marine Green',
      hex: '40A48E',
      rgb: {
        r: 64,
        g: 164,
        b: 142,
      },
      lab: {
        l: 60.48,
        a: -34.24,
        b: 0.58,
      },
      cmyk: null,
    },
    {
      code: '17-5430 TCX',
      name: 'Alhambra',
      hex: '008778',
      rgb: {
        r: 0,
        g: 135,
        b: 120,
      },
      lab: {
        l: 49.66,
        a: -36.52,
        b: -2.41,
      },
      cmyk: null,
    },
    {
      code: '17-5734 TCX',
      name: 'Viridis',
      hex: '00846B',
      rgb: {
        r: 0,
        g: 132,
        b: 107,
      },
      lab: {
        l: 48.02,
        a: -40.39,
        b: 2.87,
      },
      cmyk: null,
    },
    {
      code: '17-5528 TCX',
      name: 'Greenlake',
      hex: '007D69',
      rgb: {
        r: 0,
        g: 125,
        b: 105,
      },
      lab: {
        l: 45.47,
        a: -38.16,
        b: 0.42,
      },
      cmyk: null,
    },
    {
      code: '18-5624 TCX',
      name: 'Shady Glade',
      hex: '006E5B',
      rgb: {
        r: 0,
        g: 110,
        b: 91,
      },
      lab: {
        l: 40.06,
        a: -32.98,
        b: 0.35,
      },
      cmyk: null,
    },
    {
      code: '18-5424 TCX',
      name: 'Cadmium Green',
      hex: '00675B',
      rgb: {
        r: 0,
        g: 103,
        b: 91,
      },
      lab: {
        l: 37.18,
        a: -33.71,
        b: -3.49,
      },
      cmyk: null,
    },
    {
      code: '18-5322 TCX',
      name: 'Alpine Green',
      hex: '005F56',
      rgb: {
        r: 0,
        g: 95,
        b: 86,
      },
      lab: {
        l: 35.04,
        a: -27.7,
        b: -3.28,
      },
      cmyk: null,
    },
    {
      code: '16-5426 TCX',
      name: 'Water Garden',
      hex: '1BA491',
      rgb: {
        r: 27,
        g: 164,
        b: 145,
      },
      lab: {
        l: 59.87,
        a: -39.52,
        b: -2.21,
      },
      cmyk: null,
    },
    {
      code: '17-5440 TCX',
      name: 'Ocean Floor',
      hex: '00847A',
      rgb: {
        r: 0,
        g: 132,
        b: 122,
      },
      lab: {
        l: 48.21,
        a: -37.74,
        b: -5.86,
      },
      cmyk: null,
    },
    {
      code: '17-5730 TCX',
      name: 'Slushy',
      hex: '008A7A',
      rgb: {
        r: 0,
        g: 138,
        b: 122,
      },
      lab: {
        l: 49.95,
        a: -40.37,
        b: -3.86,
      },
      cmyk: null,
    },
    {
      code: '17-5527 TCX',
      name: 'Sporting Green',
      hex: '007667',
      rgb: {
        r: 0,
        g: 118,
        b: 103,
      },
      lab: {
        l: 42.34,
        a: -39.22,
        b: -4.05,
      },
      cmyk: null,
    },
    {
      code: '18-5016 TCX',
      name: 'Proud Peacock',
      hex: '006D65',
      rgb: {
        r: 0,
        g: 109,
        b: 101,
      },
      lab: {
        l: 39.04,
        a: -36.72,
        b: -7.07,
      },
      cmyk: null,
    },
    {
      code: '18-5425 TCX',
      name: 'Bear Grass',
      hex: '00695B',
      rgb: {
        r: 0,
        g: 105,
        b: 91,
      },
      lab: {
        l: 38.39,
        a: -32.86,
        b: -1.86,
      },
      cmyk: null,
    },
    {
      code: '19-5421 TCX',
      name: 'Aventurine',
      hex: '005348',
      rgb: {
        r: 0,
        g: 83,
        b: 72,
      },
      lab: {
        l: 29.85,
        a: -28.68,
        b: -2.38,
      },
      cmyk: null,
    },
    {
      code: '16-5421 TCX',
      name: 'Sea Green',
      hex: '159C88',
      rgb: {
        r: 21,
        g: 156,
        b: 136,
      },
      lab: {
        l: 57.09,
        a: -38.85,
        b: -1.32,
      },
      cmyk: null,
    },
    {
      code: '17-5633 TCX',
      name: 'Deep Green',
      hex: '009276',
      rgb: {
        r: 0,
        g: 146,
        b: 118,
      },
      lab: {
        l: 51.9,
        a: -49.85,
        b: 1.04,
      },
      cmyk: null,
    },
    {
      code: '17-5638 TCX',
      name: 'Vivid Green',
      hex: '009E82',
      rgb: {
        r: 0,
        g: 158,
        b: 130,
      },
      lab: {
        l: 56.26,
        a: -50.46,
        b: 0.75,
      },
      cmyk: null,
    },
    {
      code: '17-5641 TCX',
      name: 'Emerald',
      hex: '009473',
      rgb: {
        r: 0,
        g: 148,
        b: 115,
      },
      lab: {
        l: 52.5,
        a: -50.74,
        b: 3.75,
      },
      cmyk: null,
    },
    {
      code: '18-5841 TCX',
      name: 'Pepper Green',
      hex: '007D60',
      rgb: {
        r: 0,
        g: 125,
        b: 96,
      },
      lab: {
        l: 44.06,
        a: -49.19,
        b: 2.74,
      },
      cmyk: null,
    },
    {
      code: '17-5735 TCX',
      name: 'Parakeet',
      hex: '008C69',
      rgb: {
        r: 0,
        g: 140,
        b: 105,
      },
      lab: {
        l: 48.98,
        a: -53.7,
        b: 4.58,
      },
      cmyk: null,
    },
    {
      code: '18-5642 TCX',
      name: 'Golf Green',
      hex: '008763',
      rgb: {
        r: 0,
        g: 135,
        b: 99,
      },
      lab: {
        l: 47.2,
        a: -53.18,
        b: 5.19,
      },
      cmyk: null,
    },
    {
      code: '16-5932 TCX',
      name: 'Holly Green',
      hex: '0F9D76',
      rgb: {
        r: 15,
        g: 157,
        b: 118,
      },
      lab: {
        l: 56.95,
        a: -43.23,
        b: 8.49,
      },
      cmyk: null,
    },
    {
      code: '17-5936 TCX',
      name: 'Simply Green',
      hex: '009B74',
      rgb: {
        r: 0,
        g: 155,
        b: 116,
      },
      lab: {
        l: 55.53,
        a: -47.71,
        b: 7.24,
      },
      cmyk: null,
    },
    {
      code: '16-5938 TCX',
      name: 'Mint',
      hex: '00A170',
      rgb: {
        r: 0,
        g: 161,
        b: 112,
      },
      lab: {
        l: 56.84,
        a: -54.63,
        b: 11.54,
      },
      cmyk: null,
    },
    {
      code: '17-5937 TCX',
      name: 'Deep Mint',
      hex: '009E6D',
      rgb: {
        r: 0,
        g: 158,
        b: 109,
      },
      lab: {
        l: 55.88,
        a: -54.1,
        b: 11.88,
      },
      cmyk: null,
    },
    {
      code: '18-5633 TCX',
      name: 'Bosphorus',
      hex: '007558',
      rgb: {
        r: 0,
        g: 117,
        b: 88,
      },
      lab: {
        l: 42.26,
        a: -39.74,
        b: 4.98,
      },
      cmyk: null,
    },
    {
      code: '18-5845 TCX',
      name: 'Lush Meadow',
      hex: '006E52',
      rgb: {
        r: 0,
        g: 110,
        b: 82,
      },
      lab: {
        l: 38.96,
        a: -42.8,
        b: 3.9,
      },
      cmyk: null,
    },
    {
      code: '18-5338 TCX',
      name: 'Ultramarine Green',
      hex: '006B54',
      rgb: {
        r: 0,
        g: 107,
        b: 84,
      },
      lab: {
        l: 37.96,
        a: -41.04,
        b: 1.56,
      },
      cmyk: null,
    },
    {
      code: '14-6017 TCX',
      name: 'Neptune Green',
      hex: '7FBB9E',
      rgb: {
        r: 127,
        g: 187,
        b: 158,
      },
      lab: {
        l: 70.9,
        a: -26.12,
        b: 7.7,
      },
      cmyk: null,
    },
    {
      code: '16-6030 TCX',
      name: 'Katydid',
      hex: '66BC91',
      rgb: {
        r: 102,
        g: 188,
        b: 145,
      },
      lab: {
        l: 69.43,
        a: -34.76,
        b: 11.78,
      },
      cmyk: null,
    },
    {
      code: '14-6330 TCX',
      name: 'Spring Bud',
      hex: '6BCD9C',
      rgb: {
        r: 107,
        g: 205,
        b: 156,
      },
      lab: {
        l: 74.95,
        a: -38.75,
        b: 13.48,
      },
      cmyk: null,
    },
    {
      code: '15-6123 TCX',
      name: 'Jade Cream',
      hex: '60B892',
      rgb: {
        r: 96,
        g: 184,
        b: 146,
      },
      lab: {
        l: 67.94,
        a: -34.4,
        b: 9.16,
      },
      cmyk: null,
    },
    {
      code: '16-5930 TCX',
      name: 'Ming Green',
      hex: '3AA278',
      rgb: {
        r: 58,
        g: 162,
        b: 120,
      },
      lab: {
        l: 59.24,
        a: -38.85,
        b: 10.99,
      },
      cmyk: null,
    },
    {
      code: '16-5942 TCX',
      name: 'Blarney',
      hex: '00A776',
      rgb: {
        r: 0,
        g: 167,
        b: 118,
      },
      lab: {
        l: 59.51,
        a: -51.95,
        b: 11.73,
      },
      cmyk: null,
    },
    {
      code: '16-5825 TCX',
      name: 'Gumdrop Green',
      hex: '2FA785',
      rgb: {
        r: 47,
        g: 167,
        b: 133,
      },
      lab: {
        l: 60.94,
        a: -40.71,
        b: 6.01,
      },
      cmyk: null,
    },
    {
      code: '15-5812 TCX',
      name: 'Lichen',
      hex: '9BC2B1',
      rgb: {
        r: 155,
        g: 194,
        b: 177,
      },
      lab: {
        l: 74.99,
        a: -15.67,
        b: 3.08,
      },
      cmyk: null,
    },
    {
      code: '13-5907 TCX',
      name: 'Gossamer Green',
      hex: 'B2CFBE',
      rgb: {
        r: 178,
        g: 207,
        b: 190,
      },
      lab: {
        l: 80.4,
        a: -11.97,
        b: 4.36,
      },
      cmyk: null,
    },
    {
      code: '13-5911 TCX',
      name: "Bird's Egg Green",
      hex: 'AACCB8',
      rgb: {
        r: 170,
        g: 204,
        b: 184,
      },
      lab: {
        l: 78.76,
        a: -14.13,
        b: 4.74,
      },
      cmyk: null,
    },
    {
      code: '13-6110 TCX',
      name: 'Mist Green',
      hex: 'AACEBC',
      rgb: {
        r: 170,
        g: 206,
        b: 188,
      },
      lab: {
        l: 79.43,
        a: -14.58,
        b: 4.08,
      },
      cmyk: null,
    },
    {
      code: '14-6011 TCX',
      name: 'Grayed Jade',
      hex: '9BBEA9',
      rgb: {
        r: 155,
        g: 190,
        b: 169,
      },
      lab: {
        l: 73.75,
        a: -14.81,
        b: 5.78,
      },
      cmyk: null,
    },
    {
      code: '16-5820 TCX',
      name: 'Green Spruce',
      hex: '589F7E',
      rgb: {
        r: 88,
        g: 159,
        b: 126,
      },
      lab: {
        l: 59.61,
        a: -28.97,
        b: 8.62,
      },
      cmyk: null,
    },
    {
      code: '16-5924 TCX',
      name: 'Winter Green',
      hex: '4F9E81',
      rgb: {
        r: 79,
        g: 158,
        b: 129,
      },
      lab: {
        l: 59.19,
        a: -32.36,
        b: 6.71,
      },
      cmyk: null,
    },
    {
      code: '11-0304 TCX',
      name: 'Water Lily',
      hex: 'DDE3D5',
      rgb: {
        r: 221,
        g: 227,
        b: 213,
      },
      lab: {
        l: 89.33,
        a: -3.54,
        b: 5.76,
      },
      cmyk: null,
    },
    {
      code: '12-6208 TCX',
      name: 'Phantom Green',
      hex: 'DCE4D7',
      rgb: {
        r: 220,
        g: 228,
        b: 215,
      },
      lab: {
        l: 89.73,
        a: -4.32,
        b: 5.19,
      },
      cmyk: null,
    },
    {
      code: '12-6206 TCX',
      name: 'Fairest Jade',
      hex: 'D8E3D7',
      rgb: {
        r: 216,
        g: 227,
        b: 215,
      },
      lab: {
        l: 89.14,
        a: -5.76,
        b: 4.62,
      },
      cmyk: null,
    },
    {
      code: '12-5504 TCX',
      name: 'Clearly Aqua',
      hex: 'CEE1D4',
      rgb: {
        r: 206,
        g: 225,
        b: 212,
      },
      lab: {
        l: 87.7,
        a: -7.74,
        b: 3.49,
      },
      cmyk: null,
    },
    {
      code: '13-6008 TCX',
      name: 'Misty Jade',
      hex: 'BCD9C8',
      rgb: {
        r: 188,
        g: 217,
        b: 200,
      },
      lab: {
        l: 83.97,
        a: -12.05,
        b: 3.98,
      },
      cmyk: null,
    },
    {
      code: '12-5506 TCX',
      name: 'Dusty Aqua',
      hex: 'C0DCCD',
      rgb: {
        r: 192,
        g: 220,
        b: 205,
      },
      lab: {
        l: 85.23,
        a: -12.02,
        b: 3.74,
      },
      cmyk: null,
    },
    {
      code: '14-6312 TCX',
      name: 'Cameo Green',
      hex: 'AAC0AD',
      rgb: {
        r: 170,
        g: 192,
        b: 173,
      },
      lab: {
        l: 75.58,
        a: -9.92,
        b: 6.3,
      },
      cmyk: null,
    },
    {
      code: '11-4303 TCX',
      name: 'Mystic Blue',
      hex: 'E1E3DE',
      rgb: {
        r: 225,
        g: 227,
        b: 222,
      },
      lab: {
        l: 90.08,
        a: -1.04,
        b: 1.97,
      },
      cmyk: null,
    },
    {
      code: '12-4304 TCX',
      name: 'Bluewash',
      hex: 'E2E6E0',
      rgb: {
        r: 226,
        g: 230,
        b: 224,
      },
      lab: {
        l: 90.91,
        a: -1.84,
        b: 2.11,
      },
      cmyk: null,
    },
    {
      code: '11-4301 TCX',
      name: 'Lily White',
      hex: 'E2E2DA',
      rgb: {
        r: 226,
        g: 226,
        b: 218,
      },
      lab: {
        l: 89.59,
        a: -0.75,
        b: 3.5,
      },
      cmyk: null,
    },
    {
      code: '11-4802 TCX',
      name: 'Summer Shower',
      hex: 'E5EBE3',
      rgb: {
        r: 229,
        g: 235,
        b: 227,
      },
      lab: {
        l: 92.36,
        a: -2.77,
        b: 2.82,
      },
      cmyk: null,
    },
    {
      code: '11-4804 TCX',
      name: 'Lightest Sky',
      hex: 'E4EADF',
      rgb: {
        r: 228,
        g: 234,
        b: 223,
      },
      lab: {
        l: 91.89,
        a: -3.31,
        b: 4.39,
      },
      cmyk: null,
    },
    {
      code: '12-5203 TCX',
      name: 'Murmur',
      hex: 'D2D8D2',
      rgb: {
        r: 210,
        g: 216,
        b: 210,
      },
      lab: {
        l: 85.8,
        a: -2.7,
        b: 2.1,
      },
      cmyk: null,
    },
    {
      code: '12-5603 TCX',
      name: 'Zephyr Blue',
      hex: 'D3D9D1',
      rgb: {
        r: 211,
        g: 217,
        b: 209,
      },
      lab: {
        l: 85.98,
        a: -2.92,
        b: 2.74,
      },
      cmyk: null,
    },
    {
      code: '12-5404 TCX',
      name: 'Whisper Green',
      hex: 'E0E6D6',
      rgb: {
        r: 224,
        g: 230,
        b: 214,
      },
      lab: {
        l: 90.46,
        a: -3.77,
        b: 6.64,
      },
      cmyk: null,
    },
    {
      code: '12-6205 TCX',
      name: 'Milky Green',
      hex: 'CFDBD1',
      rgb: {
        r: 207,
        g: 219,
        b: 209,
      },
      lab: {
        l: 86.19,
        a: -4.9,
        b: 2.86,
      },
      cmyk: null,
    },
    {
      code: '13-0107 TCX',
      name: 'Dewkist',
      hex: 'C4D1C2',
      rgb: {
        r: 196,
        g: 209,
        b: 194,
      },
      lab: {
        l: 82.41,
        a: -6.18,
        b: 5.25,
      },
      cmyk: null,
    },
    {
      code: '13-6106 TCX',
      name: 'Green Tint',
      hex: 'C5CCC0',
      rgb: {
        r: 197,
        g: 204,
        b: 192,
      },
      lab: {
        l: 81.18,
        a: -3.18,
        b: 4.65,
      },
      cmyk: null,
    },
    {
      code: '13-6107 TCX',
      name: 'Green Lily',
      hex: 'C1CEC1',
      rgb: {
        r: 193,
        g: 206,
        b: 193,
      },
      lab: {
        l: 81.24,
        a: -5.72,
        b: 4.21,
      },
      cmyk: null,
    },
    {
      code: '13-6108 TCX',
      name: 'Celadon',
      hex: 'B8CCBA',
      rgb: {
        r: 184,
        g: 204,
        b: 186,
      },
      lab: {
        l: 80.06,
        a: -9.04,
        b: 5.91,
      },
      cmyk: null,
    },
    {
      code: '14-6008 TCX',
      name: 'Subtle Green',
      hex: 'B5CBBB',
      rgb: {
        r: 181,
        g: 203,
        b: 187,
      },
      lab: {
        l: 79.52,
        a: -9.07,
        b: 4.71,
      },
      cmyk: null,
    },
    {
      code: '14-4505 TCX',
      name: 'Smoke',
      hex: 'BFC8C3',
      rgb: {
        r: 191,
        g: 200,
        b: 195,
      },
      lab: {
        l: 79.68,
        a: -3.89,
        b: 1.37,
      },
      cmyk: null,
    },
    {
      code: '13-5305 TCX',
      name: 'Pale Aqua',
      hex: 'C1CCC2',
      rgb: {
        r: 193,
        g: 204,
        b: 194,
      },
      lab: {
        l: 80.92,
        a: -5.2,
        b: 3.58,
      },
      cmyk: null,
    },
    {
      code: '14-5707 TCX',
      name: 'Aqua Foam',
      hex: 'ADC3B4',
      rgb: {
        r: 173,
        g: 195,
        b: 180,
      },
      lab: {
        l: 76.61,
        a: -9.3,
        b: 4.33,
      },
      cmyk: null,
    },
    {
      code: '14-5706 TCX',
      name: 'Silt Green',
      hex: 'A9BDB1',
      rgb: {
        r: 169,
        g: 189,
        b: 177,
      },
      lab: {
        l: 74.74,
        a: -8.79,
        b: 3.47,
      },
      cmyk: null,
    },
    {
      code: '15-5706 TCX',
      name: 'Frosty Green',
      hex: 'A3B5A6',
      rgb: {
        r: 163,
        g: 181,
        b: 166,
      },
      lab: {
        l: 71.94,
        a: -8.34,
        b: 5.14,
      },
      cmyk: null,
    },
    {
      code: '16-5907 TCX',
      name: 'Granite Green',
      hex: '86A293',
      rgb: {
        r: 134,
        g: 162,
        b: 147,
      },
      lab: {
        l: 63.91,
        a: -11.69,
        b: 3.32,
      },
      cmyk: null,
    },
    {
      code: '16-5810 TCX',
      name: 'Green Bay',
      hex: '7E9285',
      rgb: {
        r: 126,
        g: 146,
        b: 133,
      },
      lab: {
        l: 58.68,
        a: -9.06,
        b: 4.15,
      },
      cmyk: null,
    },
    {
      code: '12-5403 TCX',
      name: 'Blue Flower',
      hex: 'D0D9D4',
      rgb: {
        r: 208,
        g: 217,
        b: 212,
      },
      lab: {
        l: 85.74,
        a: -3.4,
        b: 0.9,
      },
      cmyk: null,
    },
    {
      code: '14-4502 TCX',
      name: 'Mercury',
      hex: 'BAC2BA',
      rgb: {
        r: 186,
        g: 194,
        b: 186,
      },
      lab: {
        l: 77.53,
        a: -3.56,
        b: 2.94,
      },
      cmyk: null,
    },
    {
      code: '14-4503 TCX',
      name: 'Metal',
      hex: 'BABFBC',
      rgb: {
        r: 186,
        g: 191,
        b: 188,
      },
      lab: {
        l: 76.88,
        a: -2,
        b: 0.95,
      },
      cmyk: null,
    },
    {
      code: '15-4702 TCX',
      name: 'Puritan Gray',
      hex: 'A8B0AE',
      rgb: {
        r: 168,
        g: 176,
        b: 174,
      },
      lab: {
        l: 71.18,
        a: -3.15,
        b: -0.27,
      },
      cmyk: null,
    },
    {
      code: '15-4704 TCX',
      name: 'Pigeon',
      hex: 'A9AFAA',
      rgb: {
        r: 169,
        g: 175,
        b: 170,
      },
      lab: {
        l: 70.86,
        a: -2.62,
        b: 1.65,
      },
      cmyk: null,
    },
    {
      code: '15-5205 TCX',
      name: 'Aqua Gray',
      hex: 'A5B2AA',
      rgb: {
        r: 165,
        g: 178,
        b: 170,
      },
      lab: {
        l: 71.28,
        a: -5.31,
        b: 2.18,
      },
      cmyk: null,
    },
    {
      code: '16-5808 TCX',
      name: 'Iceberg Green',
      hex: '8C9C92',
      rgb: {
        r: 140,
        g: 156,
        b: 146,
      },
      lab: {
        l: 62.7,
        a: -6.43,
        b: 2.35,
      },
      cmyk: null,
    },
    {
      code: '16-5807 TCX',
      name: 'Lily Pad',
      hex: '818F84',
      rgb: {
        r: 129,
        g: 143,
        b: 132,
      },
      lab: {
        l: 57.78,
        a: -6.47,
        b: 3.51,
      },
      cmyk: null,
    },
    {
      code: '17-6009 TCX',
      name: 'Laurel Wreath',
      hex: '616F65',
      rgb: {
        r: 97,
        g: 111,
        b: 101,
      },
      lab: {
        l: 45.29,
        a: -6.93,
        b: 3.31,
      },
      cmyk: null,
    },
    {
      code: '18-5611 TCX',
      name: 'Dark Forest',
      hex: '556962',
      rgb: {
        r: 85,
        g: 105,
        b: 98,
      },
      lab: {
        l: 42.54,
        a: -8.82,
        b: 0.87,
      },
      cmyk: null,
    },
    {
      code: '18-6011 TCX',
      name: 'Duck Green',
      hex: '53665C',
      rgb: {
        r: 83,
        g: 102,
        b: 92,
      },
      lab: {
        l: 41.12,
        a: -7.62,
        b: 2.25,
      },
      cmyk: null,
    },
    {
      code: '18-5913 TCX',
      name: 'Garden Topiary',
      hex: '3F524B',
      rgb: {
        r: 63,
        g: 82,
        b: 75,
      },
      lab: {
        l: 33.07,
        a: -8.81,
        b: 1.12,
      },
      cmyk: null,
    },
    {
      code: '19-5411 TCX',
      name: 'Trekking Green',
      hex: '355048',
      rgb: {
        r: 53,
        g: 80,
        b: 72,
      },
      lab: {
        l: 31.5,
        a: -11.64,
        b: 0.91,
      },
      cmyk: null,
    },
    {
      code: '19-5220 TCX',
      name: 'Botanical Garden',
      hex: '12403C',
      rgb: {
        r: 18,
        g: 64,
        b: 60,
      },
      lab: {
        l: 23.77,
        a: -17.16,
        b: -2.9,
      },
      cmyk: null,
    },
    {
      code: '19-5004 TCX',
      name: 'Urban Chic',
      hex: '474E4D',
      rgb: {
        r: 71,
        g: 78,
        b: 77,
      },
      lab: {
        l: 32.37,
        a: -2.81,
        b: -0.75,
      },
      cmyk: null,
    },
    {
      code: '19-4906 TCX',
      name: 'Green Gables',
      hex: '324241',
      rgb: {
        r: 50,
        g: 66,
        b: 65,
      },
      lab: {
        l: 26.3,
        a: -6.39,
        b: -2.1,
      },
      cmyk: null,
    },
    {
      code: '19-5212 TCX',
      name: 'Darkest Spruce',
      hex: '303D3C',
      rgb: {
        r: 48,
        g: 61,
        b: 60,
      },
      lab: {
        l: 24.62,
        a: -5.29,
        b: -1.35,
      },
      cmyk: null,
    },
    {
      code: '19-5406 TCX',
      name: 'Pine Grove',
      hex: '223631',
      rgb: {
        r: 34,
        g: 54,
        b: 49,
      },
      lab: {
        l: 20.67,
        a: -9.56,
        b: 0.25,
      },
      cmyk: null,
    },
    {
      code: '19-5918 TCX',
      name: 'Mountain View',
      hex: '2E3D30',
      rgb: {
        r: 46,
        g: 61,
        b: 48,
      },
      lab: {
        l: 23.94,
        a: -8.22,
        b: 5.42,
      },
      cmyk: null,
    },
    {
      code: '19-6110 TCX',
      name: 'Deep Forest',
      hex: '37413A',
      rgb: {
        r: 55,
        g: 65,
        b: 58,
      },
      lab: {
        l: 26.55,
        a: -5.21,
        b: 2.69,
      },
      cmyk: null,
    },
    {
      code: '19-5350 TCX',
      name: 'Scarab',
      hex: '24312D',
      rgb: {
        r: 36,
        g: 49,
        b: 45,
      },
      lab: {
        l: 18.82,
        a: -5.82,
        b: 0.03,
      },
      cmyk: null,
    },
    {
      code: '17-6212 TCX',
      name: 'Sea Spray',
      hex: '727E6F',
      rgb: {
        r: 114,
        g: 126,
        b: 111,
      },
      lab: {
        l: 51.2,
        a: -5.67,
        b: 5.62,
      },
      cmyk: null,
    },
    {
      code: '18-5806 TCX',
      name: 'Agave Green',
      hex: '6B7169',
      rgb: {
        r: 107,
        g: 113,
        b: 105,
      },
      lab: {
        l: 46.75,
        a: -3.54,
        b: 3.57,
      },
      cmyk: null,
    },
    {
      code: '19-0309 TCX',
      name: 'Thyme',
      hex: '50574C',
      rgb: {
        r: 80,
        g: 87,
        b: 76,
      },
      lab: {
        l: 36.19,
        a: -4.59,
        b: 5.69,
      },
      cmyk: null,
    },
    {
      code: '19-5621 TCX',
      name: 'Cilantro',
      hex: '43544B',
      rgb: {
        r: 67,
        g: 84,
        b: 75,
      },
      lab: {
        l: 33.95,
        a: -8.11,
        b: 2.81,
      },
      cmyk: null,
    },
    {
      code: '19-5914 TCX',
      name: 'Jungle Green',
      hex: '3C4E47',
      rgb: {
        r: 60,
        g: 78,
        b: 71,
      },
      lab: {
        l: 31.25,
        a: -8.11,
        b: 1.43,
      },
      cmyk: null,
    },
    {
      code: '19-5920 TCX',
      name: 'Pineneedle',
      hex: '344D41',
      rgb: {
        r: 52,
        g: 77,
        b: 65,
      },
      lab: {
        l: 30.11,
        a: -11.65,
        b: 2.94,
      },
      cmyk: null,
    },
    {
      code: '19-5917 TCX',
      name: 'Sycamore',
      hex: '35463D',
      rgb: {
        r: 53,
        g: 70,
        b: 61,
      },
      lab: {
        l: 27.82,
        a: -8.34,
        b: 2.99,
      },
      cmyk: null,
    },
    {
      code: '18-0515 TCX',
      name: 'Dusty Olive',
      hex: '646356',
      rgb: {
        r: 100,
        g: 99,
        b: 86,
      },
      lab: {
        l: 41.82,
        a: -0.85,
        b: 7.33,
      },
      cmyk: null,
    },
    {
      code: '19-0312 TCX',
      name: 'Beetle',
      hex: '56584C',
      rgb: {
        r: 86,
        g: 88,
        b: 76,
      },
      lab: {
        l: 36.89,
        a: -2.52,
        b: 6.85,
      },
      cmyk: null,
    },
    {
      code: '19-0307 TCX',
      name: 'Climbing Ivy',
      hex: '444940',
      rgb: {
        r: 68,
        g: 73,
        b: 64,
      },
      lab: {
        l: 30.27,
        a: -3.13,
        b: 4.49,
      },
      cmyk: null,
    },
    {
      code: '19-0417 TCX',
      name: 'Kombu Green',
      hex: '3A4132',
      rgb: {
        r: 58,
        g: 65,
        b: 50,
      },
      lab: {
        l: 26.51,
        a: -5.11,
        b: 8.05,
      },
      cmyk: null,
    },
    {
      code: '19-0315 TCX',
      name: 'Black Forest',
      hex: '424F3C',
      rgb: {
        r: 66,
        g: 79,
        b: 60,
      },
      lab: {
        l: 31.87,
        a: -8.25,
        b: 9.21,
      },
      cmyk: null,
    },
    {
      code: '19-0415 TCX',
      name: 'Duffel Bag',
      hex: '394034',
      rgb: {
        r: 57,
        g: 64,
        b: 52,
      },
      lab: {
        l: 26.12,
        a: -4.64,
        b: 6.59,
      },
      cmyk: null,
    },
    {
      code: '19-0509 TCX',
      name: 'Rosin',
      hex: '36362D',
      rgb: {
        r: 54,
        g: 54,
        b: 45,
      },
      lab: {
        l: 22.47,
        a: -0.78,
        b: 5.91,
      },
      cmyk: null,
    },
    {
      code: '17-0610 TCX',
      name: 'Laurel Oak',
      hex: '918C7E',
      rgb: {
        r: 145,
        g: 140,
        b: 126,
      },
      lab: {
        l: 58.56,
        a: 0.32,
        b: 8.58,
      },
      cmyk: null,
    },
    {
      code: '17-0613 TCX',
      name: 'Vetiver',
      hex: '807D6F',
      rgb: {
        r: 128,
        g: 125,
        b: 111,
      },
      lab: {
        l: 52.29,
        a: -0.38,
        b: 7.97,
      },
      cmyk: null,
    },
    {
      code: '18-0312 TCX',
      name: 'Deep Lichen Green',
      hex: '6E6E5C',
      rgb: {
        r: 110,
        g: 110,
        b: 92,
      },
      lab: {
        l: 46.02,
        a: -2.17,
        b: 9.89,
      },
      cmyk: null,
    },
    {
      code: '18-0517 TCX',
      name: 'Tea Leaf',
      hex: '616054',
      rgb: {
        r: 97,
        g: 96,
        b: 84,
      },
      lab: {
        l: 40.5,
        a: -1.4,
        b: 7.24,
      },
      cmyk: null,
    },
    {
      code: '18-0514 TCX',
      name: 'Mulled Basil',
      hex: '696B62',
      rgb: {
        r: 105,
        g: 107,
        b: 98,
      },
      lab: {
        l: 44.66,
        a: -1.92,
        b: 4.73,
      },
      cmyk: null,
    },
    {
      code: '19-0404 TCX',
      name: 'Kambaba',
      hex: '4C4D44',
      rgb: {
        r: 76,
        g: 77,
        b: 68,
      },
      lab: {
        l: 32.28,
        a: -1.55,
        b: 5,
      },
      cmyk: null,
    },
    {
      code: '19-0406 TCX',
      name: 'Chimera',
      hex: '4D4C47',
      rgb: {
        r: 77,
        g: 76,
        b: 71,
      },
      lab: {
        l: 32.12,
        a: -0.07,
        b: 2.82,
      },
      cmyk: null,
    },
    {
      code: '15-6304 TCX',
      name: 'Pussywillow Gray',
      hex: 'AEACA1',
      rgb: {
        r: 174,
        g: 172,
        b: 161,
      },
      lab: {
        l: 70.2,
        a: -0.16,
        b: 5.57,
      },
      cmyk: null,
    },
    {
      code: '16-0207 TCX',
      name: 'London Fog',
      hex: 'A29E92',
      rgb: {
        r: 162,
        g: 158,
        b: 146,
      },
      lab: {
        l: 65.17,
        a: 0.42,
        b: 6.63,
      },
      cmyk: null,
    },
    {
      code: '15-4306 TCX',
      name: 'Belgian Block',
      hex: 'A3A9A6',
      rgb: {
        r: 163,
        g: 169,
        b: 166,
      },
      lab: {
        l: 68.66,
        a: -2.35,
        b: 0.5,
      },
      cmyk: null,
    },
    {
      code: '16-5904 TCX',
      name: 'Wrought Iron',
      hex: '999E98',
      rgb: {
        r: 153,
        g: 158,
        b: 152,
      },
      lab: {
        l: 64.53,
        a: -2.42,
        b: 2.15,
      },
      cmyk: null,
    },
    {
      code: '17-0209 TCX',
      name: 'Forest Fog',
      hex: '909189',
      rgb: {
        r: 144,
        g: 145,
        b: 137,
      },
      lab: {
        l: 59.87,
        a: -1.46,
        b: 4.01,
      },
      cmyk: null,
    },
    {
      code: '17-0208 TCX',
      name: 'Dried Sage',
      hex: '8C8A7F',
      rgb: {
        r: 140,
        g: 138,
        b: 127,
      },
      lab: {
        l: 57.55,
        a: -0.82,
        b: 6.26,
      },
      cmyk: null,
    },
    {
      code: '18-0516 TCX',
      name: 'Smokey Olive',
      hex: '6A685D',
      rgb: {
        r: 106,
        g: 104,
        b: 93,
      },
      lab: {
        l: 43.86,
        a: -0.15,
        b: 6.19,
      },
      cmyk: null,
    },
    {
      code: '12-5703 TCX',
      name: 'Frosted Mint',
      hex: 'CDD4CB',
      rgb: {
        r: 205,
        g: 212,
        b: 203,
      },
      lab: {
        l: 84.16,
        a: -3.49,
        b: 3.76,
      },
      cmyk: null,
    },
    {
      code: '13-6105 TCX',
      name: 'Celadon Tint',
      hex: 'CBCEBE',
      rgb: {
        r: 203,
        g: 206,
        b: 190,
      },
      lab: {
        l: 82.05,
        a: -2.63,
        b: 7.25,
      },
      cmyk: null,
    },
    {
      code: '15-5704 TCX',
      name: 'Mineral Gray',
      hex: 'B2B6AC',
      rgb: {
        r: 178,
        g: 182,
        b: 172,
      },
      lab: {
        l: 73.46,
        a: -2.52,
        b: 4.48,
      },
      cmyk: null,
    },
    {
      code: '16-0110 TCX',
      name: 'Desert Sage',
      hex: 'A7AE9E',
      rgb: {
        r: 167,
        g: 174,
        b: 158,
      },
      lab: {
        l: 70.02,
        a: -4.18,
        b: 7.07,
      },
      cmyk: null,
    },
    {
      code: '16-6008 TCX',
      name: 'Seagrass',
      hex: '959889',
      rgb: {
        r: 149,
        g: 152,
        b: 137,
      },
      lab: {
        l: 62.3,
        a: -3.22,
        b: 7.65,
      },
      cmyk: null,
    },
    {
      code: '16-0213 TCX',
      name: 'Tea',
      hex: '999B85',
      rgb: {
        r: 153,
        g: 155,
        b: 133,
      },
      lab: {
        l: 63.27,
        a: -3.18,
        b: 10.73,
      },
      cmyk: null,
    },
    {
      code: '17-6206 TCX',
      name: 'Shadow',
      hex: '888D82',
      rgb: {
        r: 136,
        g: 141,
        b: 130,
      },
      lab: {
        l: 57.98,
        a: -3.02,
        b: 5.28,
      },
      cmyk: null,
    },
    {
      code: '13-0210 TCX',
      name: 'Fog Green',
      hex: 'C2CBB4',
      rgb: {
        r: 194,
        g: 203,
        b: 180,
      },
      lab: {
        l: 80.56,
        a: -5.43,
        b: 9.88,
      },
      cmyk: null,
    },
    {
      code: '13-0212 TCX',
      name: 'Tender Greens',
      hex: 'C5CFB6',
      rgb: {
        r: 197,
        g: 207,
        b: 182,
      },
      lab: {
        l: 81.87,
        a: -6.07,
        b: 10.78,
      },
      cmyk: null,
    },
    {
      code: '14-0114 TCX',
      name: 'Celadon Green',
      hex: 'B5C2A5',
      rgb: {
        r: 181,
        g: 194,
        b: 165,
      },
      lab: {
        l: 76.58,
        a: -7.41,
        b: 12.19,
      },
      cmyk: null,
    },
    {
      code: '15-6313 TCX',
      name: 'Laurel Green',
      hex: 'ADBBA1',
      rgb: {
        r: 173,
        g: 187,
        b: 161,
      },
      lab: {
        l: 74.21,
        a: -7.67,
        b: 10.82,
      },
      cmyk: null,
    },
    {
      code: '15-6310 TCX',
      name: 'Swamp',
      hex: 'A8B197',
      rgb: {
        r: 168,
        g: 177,
        b: 151,
      },
      lab: {
        l: 70.83,
        a: -6.09,
        b: 11.91,
      },
      cmyk: null,
    },
    {
      code: '15-6414 TCX',
      name: 'Reseda',
      hex: 'A1AD92',
      rgb: {
        r: 161,
        g: 173,
        b: 146,
      },
      lab: {
        l: 69.19,
        a: -7.2,
        b: 11.96,
      },
      cmyk: null,
    },
    {
      code: '17-6323 TCX',
      name: 'Hedge Green',
      hex: '768A75',
      rgb: {
        r: 118,
        g: 138,
        b: 117,
      },
      lab: {
        l: 55.11,
        a: -9.09,
        b: 7.48,
      },
      cmyk: null,
    },
    {
      code: '14-6007 TCX',
      name: 'Sea Foam',
      hex: 'B7C2B2',
      rgb: {
        r: 183,
        g: 194,
        b: 178,
      },
      lab: {
        l: 77.12,
        a: -5.88,
        b: 6.63,
      },
      cmyk: null,
    },
    {
      code: '15-6315 TCX',
      name: 'Smoke Green',
      hex: 'A8BBA2',
      rgb: {
        r: 168,
        g: 187,
        b: 162,
      },
      lab: {
        l: 73.73,
        a: -9.59,
        b: 9.56,
      },
      cmyk: null,
    },
    {
      code: '16-0220 TCX',
      name: 'Mistletoe',
      hex: '8AA282',
      rgb: {
        r: 138,
        g: 162,
        b: 130,
      },
      lab: {
        l: 63.91,
        a: -12.31,
        b: 12.96,
      },
      cmyk: null,
    },
    {
      code: '16-0224 TCX',
      name: 'Green Eyes',
      hex: '7D946D',
      rgb: {
        r: 125,
        g: 148,
        b: 109,
      },
      lab: {
        l: 58.69,
        a: -13.03,
        b: 17.07,
      },
      cmyk: null,
    },
    {
      code: '17-0119 TCX',
      name: 'Turf Green',
      hex: '6F8C69',
      rgb: {
        r: 111,
        g: 140,
        b: 105,
      },
      lab: {
        l: 55.21,
        a: -14.96,
        b: 14.29,
      },
      cmyk: null,
    },
    {
      code: '17-0220 TCX',
      name: 'Watercress',
      hex: '748C69',
      rgb: {
        r: 116,
        g: 140,
        b: 105,
      },
      lab: {
        l: 55.38,
        a: -12.95,
        b: 14.84,
      },
      cmyk: null,
    },
    {
      code: '18-0121 TCX',
      name: 'Elm Green',
      hex: '547053',
      rgb: {
        r: 84,
        g: 112,
        b: 83,
      },
      lab: {
        l: 44.14,
        a: -14.12,
        b: 11.44,
      },
      cmyk: null,
    },
    {
      code: '13-6006 TCX',
      name: 'Almost Aqua',
      hex: 'CAD3C1',
      rgb: {
        r: 202,
        g: 211,
        b: 193,
      },
      lab: {
        l: 83.52,
        a: -5.5,
        b: 7.79,
      },
      cmyk: null,
    },
    {
      code: '13-6208 TCX',
      name: 'Bok Choy',
      hex: 'BCCAB3',
      rgb: {
        r: 188,
        g: 202,
        b: 179,
      },
      lab: {
        l: 79.67,
        a: -7.91,
        b: 9.58,
      },
      cmyk: null,
    },
    {
      code: '16-6318 TCX',
      name: 'Mineral Green',
      hex: '7A9B77',
      rgb: {
        r: 122,
        g: 155,
        b: 119,
      },
      lab: {
        l: 60.47,
        a: -16.26,
        b: 13.56,
      },
      cmyk: null,
    },
    {
      code: '18-6216 TCX',
      name: 'Comfrey',
      hex: '5B7961',
      rgb: {
        r: 91,
        g: 121,
        b: 97,
      },
      lab: {
        l: 47.79,
        a: -14.9,
        b: 8.88,
      },
      cmyk: null,
    },
    {
      code: '17-5912 TCX',
      name: 'Dark Ivy',
      hex: '5B7763',
      rgb: {
        r: 91,
        g: 119,
        b: 99,
      },
      lab: {
        l: 47.33,
        a: -13.47,
        b: 6.97,
      },
      cmyk: null,
    },
    {
      code: '18-6114 TCX',
      name: 'Myrtle',
      hex: '506B58',
      rgb: {
        r: 80,
        g: 107,
        b: 88,
      },
      lab: {
        l: 42.48,
        a: -13.25,
        b: 6.55,
      },
      cmyk: null,
    },
    {
      code: '18-6018 TCX',
      name: 'Foliage Green',
      hex: '3E6F58',
      rgb: {
        r: 62,
        g: 111,
        b: 88,
      },
      lab: {
        l: 42.64,
        a: -20.52,
        b: 6.17,
      },
      cmyk: null,
    },
    {
      code: '13-6007 TCX',
      name: 'Spray',
      hex: 'BED3BB',
      rgb: {
        r: 190,
        g: 211,
        b: 187,
      },
      lab: {
        l: 82.33,
        a: -11.26,
        b: 9.24,
      },
      cmyk: null,
    },
    {
      code: '13-0116 TCX',
      name: 'Pastel Green',
      hex: 'B4D3B2',
      rgb: {
        r: 180,
        g: 211,
        b: 178,
      },
      lab: {
        l: 81.4,
        a: -14.9,
        b: 11.88,
      },
      cmyk: null,
    },
    {
      code: '14-6316 TCX',
      name: 'Sprucestone',
      hex: '9FC09C',
      rgb: {
        r: 159,
        g: 192,
        b: 156,
      },
      lab: {
        l: 74.37,
        a: -15.9,
        b: 13.52,
      },
      cmyk: null,
    },
    {
      code: '14-6324 TCX',
      name: 'Peapod',
      hex: '82B185',
      rgb: {
        r: 130,
        g: 177,
        b: 133,
      },
      lab: {
        l: 67.72,
        a: -24.07,
        b: 16.87,
      },
      cmyk: null,
    },
    {
      code: '14-6327 TCX',
      name: 'Zephyr Green',
      hex: '7CB083',
      rgb: {
        r: 124,
        g: 176,
        b: 131,
      },
      lab: {
        l: 67.07,
        a: -26.04,
        b: 16.61,
      },
      cmyk: null,
    },
    {
      code: '16-6324 TCX',
      name: 'Jadesheen',
      hex: '77A276',
      rgb: {
        r: 119,
        g: 162,
        b: 118,
      },
      lab: {
        l: 62.38,
        a: -21.14,
        b: 17.03,
      },
      cmyk: null,
    },
    {
      code: '15-6432 TCX',
      name: 'Shamrock',
      hex: '6FA26B',
      rgb: {
        r: 111,
        g: 162,
        b: 107,
      },
      lab: {
        l: 61.74,
        a: -27.11,
        b: 22.34,
      },
      cmyk: null,
    },
    {
      code: '15-6114 TCX',
      name: 'Hemlock',
      hex: '97C1A1',
      rgb: {
        r: 151,
        g: 193,
        b: 161,
      },
      lab: {
        l: 74.07,
        a: -18.3,
        b: 10.25,
      },
      cmyk: null,
    },
    {
      code: '14-6319 TCX',
      name: 'Meadow',
      hex: '8BBA94',
      rgb: {
        r: 139,
        g: 186,
        b: 148,
      },
      lab: {
        l: 71.15,
        a: -20.95,
        b: 12.72,
      },
      cmyk: null,
    },
    {
      code: '15-6120 TCX',
      name: 'Ming',
      hex: '7CB08A',
      rgb: {
        r: 124,
        g: 176,
        b: 138,
      },
      lab: {
        l: 67.07,
        a: -24.85,
        b: 13.13,
      },
      cmyk: null,
    },
    {
      code: '15-6322 TCX',
      name: 'Light Grass Green',
      hex: '7CB68E',
      rgb: {
        r: 124,
        g: 182,
        b: 142,
      },
      lab: {
        l: 68.79,
        a: -26.44,
        b: 13.31,
      },
      cmyk: null,
    },
    {
      code: '14-6329 TCX',
      name: 'Absinthe Green',
      hex: '76B583',
      rgb: {
        r: 118,
        g: 181,
        b: 131,
      },
      lab: {
        l: 68.05,
        a: -29.91,
        b: 17.86,
      },
      cmyk: null,
    },
    {
      code: '16-6329 TCX',
      name: 'Peppermint',
      hex: '699E6D',
      rgb: {
        r: 105,
        g: 158,
        b: 109,
      },
      lab: {
        l: 60.08,
        a: -26.97,
        b: 19.3,
      },
      cmyk: null,
    },
    {
      code: '18-0110 TCX',
      name: 'English Ivy',
      hex: '61845B',
      rgb: {
        r: 97,
        g: 132,
        b: 91,
      },
      lab: {
        l: 51.32,
        a: -17.62,
        b: 16.55,
      },
      cmyk: null,
    },
    {
      code: '14-0127 TCX',
      name: 'Greengage',
      hex: '8BC28C',
      rgb: {
        r: 139,
        g: 194,
        b: 140,
      },
      lab: {
        l: 73.33,
        a: -26.42,
        b: 20.18,
      },
      cmyk: null,
    },
    {
      code: '16-6127 TCX',
      name: 'Greenbriar',
      hex: '4B9B69',
      rgb: {
        r: 75,
        g: 155,
        b: 105,
      },
      lab: {
        l: 57.43,
        a: -34.16,
        b: 16.59,
      },
      cmyk: null,
    },
    {
      code: '18-6022 TCX',
      name: 'Leprechaun',
      hex: '378661',
      rgb: {
        r: 55,
        g: 134,
        b: 97,
      },
      lab: {
        l: 49.7,
        a: -31.77,
        b: 10.46,
      },
      cmyk: null,
    },
    {
      code: '17-6030 TCX',
      name: 'Jelly Bean',
      hex: '008658',
      rgb: {
        r: 0,
        g: 134,
        b: 88,
      },
      lab: {
        l: 48.09,
        a: -44.37,
        b: 12.98,
      },
      cmyk: null,
    },
    {
      code: '18-6030 TCX',
      name: 'Jolly Green',
      hex: '007844',
      rgb: {
        r: 0,
        g: 120,
        b: 68,
      },
      lab: {
        l: 42.4,
        a: -48.99,
        b: 16.76,
      },
      cmyk: null,
    },
    {
      code: '19-6026 TCX',
      name: 'Verdant Green',
      hex: '12674A',
      rgb: {
        r: 18,
        g: 103,
        b: 74,
      },
      lab: {
        l: 37.9,
        a: -30.56,
        b: 7.74,
      },
      cmyk: null,
    },
    {
      code: '18-6024 TCX',
      name: 'Amazon',
      hex: '207349',
      rgb: {
        r: 32,
        g: 115,
        b: 73,
      },
      lab: {
        l: 42.26,
        a: -33.19,
        b: 13.83,
      },
      cmyk: null,
    },
    {
      code: '17-6154 TCX',
      name: 'Green Bee',
      hex: '008C4E',
      rgb: {
        r: 0,
        g: 140,
        b: 78,
      },
      lab: {
        l: 50.39,
        a: -44.92,
        b: 21.31,
      },
      cmyk: null,
    },
    {
      code: '18-6028 TCX',
      name: 'Rolling Hills',
      hex: '227949',
      rgb: {
        r: 34,
        g: 121,
        b: 73,
      },
      lab: {
        l: 44.34,
        a: -35.22,
        b: 17.02,
      },
      cmyk: null,
    },
    {
      code: '18-6031 TCX',
      name: 'First Tee',
      hex: '00723B',
      rgb: {
        r: 0,
        g: 114,
        b: 59,
      },
      lab: {
        l: 40.45,
        a: -46.97,
        b: 19.36,
      },
      cmyk: null,
    },
    {
      code: '18-6033 TCX',
      name: 'Green Tambourine',
      hex: '007449',
      rgb: {
        r: 0,
        g: 116,
        b: 73,
      },
      lab: {
        l: 41.03,
        a: -45.59,
        b: 11.76,
      },
      cmyk: null,
    },
    {
      code: '18-6026 TCX',
      name: 'Abundant Green',
      hex: '006339',
      rgb: {
        r: 0,
        g: 99,
        b: 57,
      },
      lab: {
        l: 35.97,
        a: -36.31,
        b: 15.38,
      },
      cmyk: null,
    },
    {
      code: '19-6350 TCX',
      name: 'Formal Garden',
      hex: '1F5129',
      rgb: {
        r: 31,
        g: 81,
        b: 41,
      },
      lab: {
        l: 30.03,
        a: -25.9,
        b: 17.57,
      },
      cmyk: null,
    },
    {
      code: '19-6027 TCX',
      name: 'Green Jacket',
      hex: '005D43',
      rgb: {
        r: 0,
        g: 93,
        b: 67,
      },
      lab: {
        l: 33.69,
        a: -32.63,
        b: 6.64,
      },
      cmyk: null,
    },
    {
      code: '14-0121 TCX',
      name: 'Nile Green',
      hex: 'A7C796',
      rgb: {
        r: 167,
        g: 199,
        b: 150,
      },
      lab: {
        l: 76.58,
        a: -16.91,
        b: 19.6,
      },
      cmyk: null,
    },
    {
      code: '13-0221 TCX',
      name: 'Pistachio Green',
      hex: 'A9D39E',
      rgb: {
        r: 169,
        g: 211,
        b: 158,
      },
      lab: {
        l: 80.24,
        a: -21.15,
        b: 20.47,
      },
      cmyk: null,
    },
    {
      code: '13-0117 TCX',
      name: 'Green Ash',
      hex: 'A0DAA9',
      rgb: {
        r: 160,
        g: 218,
        b: 169,
      },
      lab: {
        l: 81.79,
        a: -26.56,
        b: 16.78,
      },
      cmyk: null,
    },
    {
      code: '14-6340 TCX',
      name: 'Spring Bouquet',
      hex: '6DCE87',
      rgb: {
        r: 109,
        g: 206,
        b: 135,
      },
      lab: {
        l: 74.91,
        a: -41.65,
        b: 24.38,
      },
      cmyk: null,
    },
    {
      code: '15-6340 TCX',
      name: 'Irish Green',
      hex: '45BE76',
      rgb: {
        r: 69,
        g: 190,
        b: 118,
      },
      lab: {
        l: 68.43,
        a: -47.72,
        b: 24.12,
      },
      cmyk: null,
    },
    {
      code: '16-6240 TCX',
      name: 'Island Green',
      hex: '2CAE66',
      rgb: {
        r: 44,
        g: 174,
        b: 102,
      },
      lab: {
        l: 62.58,
        a: -48.86,
        b: 24.68,
      },
      cmyk: null,
    },
    {
      code: '15-5534 TCX',
      name: 'Bright Green',
      hex: '009B5C',
      rgb: {
        r: 0,
        g: 155,
        b: 92,
      },
      lab: {
        l: 54.76,
        a: -53.84,
        b: 19.35,
      },
      cmyk: null,
    },
    {
      code: '12-0225 TCX',
      name: 'Patina Green',
      hex: 'B9EAB3',
      rgb: {
        r: 185,
        g: 234,
        b: 179,
      },
      lab: {
        l: 87.95,
        a: -23.8,
        b: 20.24,
      },
      cmyk: null,
    },
    {
      code: '14-0156 TCX',
      name: 'Summer Green',
      hex: '7FD37F',
      rgb: {
        r: 127,
        g: 211,
        b: 127,
      },
      lab: {
        l: 77.08,
        a: -39.38,
        b: 31.55,
      },
      cmyk: null,
    },
    {
      code: '16-6444 TCX',
      name: 'Poison Green',
      hex: '4DB560',
      rgb: {
        r: 77,
        g: 181,
        b: 96,
      },
      lab: {
        l: 65.63,
        a: -45.76,
        b: 31.91,
      },
      cmyk: null,
    },
    {
      code: '16-6340 TCX',
      name: 'Classic Green',
      hex: '39A845',
      rgb: {
        r: 57,
        g: 168,
        b: 69,
      },
      lab: {
        l: 60.41,
        a: -48.35,
        b: 38.59,
      },
      cmyk: null,
    },
    {
      code: '16-6339 TCX',
      name: 'Vibrant Green',
      hex: '55A860',
      rgb: {
        r: 85,
        g: 168,
        b: 96,
      },
      lab: {
        l: 61.81,
        a: -38.12,
        b: 27.26,
      },
      cmyk: null,
    },
    {
      code: '16-6138 TCX',
      name: 'Kelly Green',
      hex: '349C5E',
      rgb: {
        r: 52,
        g: 156,
        b: 94,
      },
      lab: {
        l: 56.83,
        a: -42.1,
        b: 21.62,
      },
      cmyk: null,
    },
    {
      code: '17-6153 TCX',
      name: 'Fern Green',
      hex: '008C45',
      rgb: {
        r: 0,
        g: 140,
        b: 69,
      },
      lab: {
        l: 49.96,
        a: -48.66,
        b: 25.87,
      },
      cmyk: null,
    },
    {
      code: '13-0220 TCX',
      name: 'Paradise Green',
      hex: 'B2E79F',
      rgb: {
        r: 178,
        g: 231,
        b: 159,
      },
      lab: {
        l: 86.32,
        a: -27.17,
        b: 28.23,
      },
      cmyk: null,
    },
    {
      code: '15-0146 TCX',
      name: 'Green Flash',
      hex: '78C753',
      rgb: {
        r: 120,
        g: 199,
        b: 83,
      },
      lab: {
        l: 73,
        a: -41.2,
        b: 47.09,
      },
      cmyk: null,
    },
    {
      code: '15-0545 TCX',
      name: 'Jasmine Green',
      hex: '7FC844',
      rgb: {
        r: 127,
        g: 200,
        b: 68,
      },
      lab: {
        l: 73.47,
        a: -40.2,
        b: 54.14,
      },
      cmyk: null,
    },
    {
      code: '16-0237 TCX',
      name: 'Foliage',
      hex: '75A14F',
      rgb: {
        r: 117,
        g: 161,
        b: 79,
      },
      lab: {
        l: 61.35,
        a: -25.57,
        b: 35.64,
      },
      cmyk: null,
    },
    {
      code: '17-0145 TCX',
      name: 'Online Lime',
      hex: '44883C',
      rgb: {
        r: 68,
        g: 136,
        b: 60,
      },
      lab: {
        l: 50.54,
        a: -35.64,
        b: 32.51,
      },
      cmyk: null,
    },
    {
      code: '18-0135 TCX',
      name: 'Treetop',
      hex: '476A30',
      rgb: {
        r: 71,
        g: 106,
        b: 48,
      },
      lab: {
        l: 40.82,
        a: -23.01,
        b: 27.77,
      },
      cmyk: null,
    },
    {
      code: '19-0230 TCX',
      name: 'Garden Green',
      hex: '495E35',
      rgb: {
        r: 73,
        g: 94,
        b: 53,
      },
      lab: {
        l: 37.34,
        a: -13.97,
        b: 20.48,
      },
      cmyk: null,
    },
    {
      code: '14-0232 TCX',
      name: 'Jade Lime',
      hex: 'A1CA7B',
      rgb: {
        r: 161,
        g: 202,
        b: 123,
      },
      lab: {
        l: 76.74,
        a: -24.59,
        b: 33.69,
      },
      cmyk: null,
    },
    {
      code: '14-0244 TCX',
      name: 'Bright Lime Green',
      hex: '97BC62',
      rgb: {
        r: 151,
        g: 188,
        b: 98,
      },
      lab: {
        l: 71.77,
        a: -23.16,
        b: 39.33,
      },
      cmyk: null,
    },
    {
      code: '15-0343 TCX',
      name: 'Greenery',
      hex: '88B04B',
      rgb: {
        r: 136,
        g: 176,
        b: 75,
      },
      lab: {
        l: 67.14,
        a: -26.69,
        b: 44.97,
      },
      cmyk: null,
    },
    {
      code: '17-0235 TCX',
      name: 'Piquant Green',
      hex: '769358',
      rgb: {
        r: 118,
        g: 147,
        b: 88,
      },
      lab: {
        l: 57.33,
        a: -17.47,
        b: 26.53,
      },
      cmyk: null,
    },
    {
      code: '18-0119 TCX',
      name: 'Willow Bough',
      hex: '59754D',
      rgb: {
        r: 89,
        g: 117,
        b: 77,
      },
      lab: {
        l: 46.15,
        a: -17.55,
        b: 18.55,
      },
      cmyk: null,
    },
    {
      code: '18-0107 TCX',
      name: 'Kale',
      hex: '5A7247',
      rgb: {
        r: 90,
        g: 114,
        b: 71,
      },
      lab: {
        l: 45.14,
        a: -14.71,
        b: 19.98,
      },
      cmyk: null,
    },
    {
      code: '18-0330 TCX',
      name: 'Twist of Lime',
      hex: '4F632D',
      rgb: {
        r: 79,
        g: 99,
        b: 45,
      },
      lab: {
        l: 39.23,
        a: -14.5,
        b: 27.03,
      },
      cmyk: null,
    },
    {
      code: '17-0232 TCX',
      name: 'Salted Lime',
      hex: '60843C',
      rgb: {
        r: 96,
        g: 132,
        b: 60,
      },
      lab: {
        l: 51.14,
        a: -23.79,
        b: 33.83,
      },
      cmyk: null,
    },
    {
      code: '17-0340 TCX',
      name: 'Kelp Forest',
      hex: '728839',
      rgb: {
        r: 114,
        g: 136,
        b: 57,
      },
      lab: {
        l: 53.65,
        a: -16.88,
        b: 37.97,
      },
      cmyk: null,
    },
    {
      code: '17-0345 TCX',
      name: 'Minced Herb',
      hex: '6B8542',
      rgb: {
        r: 107,
        g: 133,
        b: 66,
      },
      lab: {
        l: 52.22,
        a: -17.9,
        b: 31.48,
      },
      cmyk: null,
    },
    {
      code: '18-0230 TCX',
      name: 'Banana Palm',
      hex: '4F6C35',
      rgb: {
        r: 79,
        g: 108,
        b: 53,
      },
      lab: {
        l: 42.1,
        a: -18.95,
        b: 26.26,
      },
      cmyk: null,
    },
    {
      code: '18-0226 TCX',
      name: 'Courtyard',
      hex: '3E5E32',
      rgb: {
        r: 62,
        g: 94,
        b: 50,
      },
      lab: {
        l: 36.44,
        a: -20.14,
        b: 21.27,
      },
      cmyk: null,
    },
    {
      code: '19-0231 TCX',
      name: 'Forest Elf',
      hex: '395228',
      rgb: {
        r: 57,
        g: 82,
        b: 40,
      },
      lab: {
        l: 31.87,
        a: -17.37,
        b: 21.19,
      },
      cmyk: null,
    },
    {
      code: '19-0220 TCX',
      name: 'Douglas Fir',
      hex: '36492E',
      rgb: {
        r: 54,
        g: 73,
        b: 46,
      },
      lab: {
        l: 28.68,
        a: -12.49,
        b: 13.61,
      },
      cmyk: null,
    },
    {
      code: '13-0331 TCX',
      name: 'Sap Green',
      hex: 'AFCB80',
      rgb: {
        r: 175,
        g: 203,
        b: 128,
      },
      lab: {
        l: 77.97,
        a: -18.18,
        b: 32.61,
      },
      cmyk: null,
    },
    {
      code: '15-0341 TCX',
      name: 'Parrot Green',
      hex: '8DB051',
      rgb: {
        r: 141,
        g: 176,
        b: 81,
      },
      lab: {
        l: 67.32,
        a: -22.73,
        b: 42.06,
      },
      cmyk: null,
    },
    {
      code: '16-0235 TCX',
      name: 'Kiwi',
      hex: '7AAB55',
      rgb: {
        r: 122,
        g: 171,
        b: 85,
      },
      lab: {
        l: 64.58,
        a: -27.6,
        b: 36.83,
      },
      cmyk: null,
    },
    {
      code: '17-0133 TCX',
      name: 'Fluorite Green',
      hex: '699158',
      rgb: {
        r: 105,
        g: 145,
        b: 88,
      },
      lab: {
        l: 55.92,
        a: -21.67,
        b: 24.12,
      },
      cmyk: null,
    },
    {
      code: '17-0230 TCX',
      name: 'Forest Green',
      hex: '6B8D53',
      rgb: {
        r: 107,
        g: 141,
        b: 83,
      },
      lab: {
        l: 54.81,
        a: -19.76,
        b: 25.59,
      },
      cmyk: null,
    },
    {
      code: '18-0130 TCX',
      name: 'Cactus',
      hex: '53713D',
      rgb: {
        r: 83,
        g: 113,
        b: 61,
      },
      lab: {
        l: 44.02,
        a: -17.68,
        b: 23.7,
      },
      cmyk: null,
    },
    {
      code: '18-0125 TCX',
      name: 'Artichoke Green',
      hex: '4B6D41',
      rgb: {
        r: 75,
        g: 109,
        b: 65,
      },
      lab: {
        l: 42.28,
        a: -18.65,
        b: 19.13,
      },
      cmyk: null,
    },
    {
      code: '14-0123 TCX',
      name: 'Arcadian Green',
      hex: 'A3C893',
      rgb: {
        r: 163,
        g: 200,
        b: 147,
      },
      lab: {
        l: 76.59,
        a: -19.01,
        b: 21.3,
      },
      cmyk: null,
    },
    {
      code: '15-6437 TCX',
      name: 'Grass Green',
      hex: '7BB369',
      rgb: {
        r: 123,
        g: 179,
        b: 105,
      },
      lab: {
        l: 67.37,
        a: -29.23,
        b: 30.18,
      },
      cmyk: null,
    },
    {
      code: '15-6442 TCX',
      name: 'Bud Green',
      hex: '79B465',
      rgb: {
        r: 121,
        g: 180,
        b: 101,
      },
      lab: {
        l: 67.6,
        a: -30.88,
        b: 32.33,
      },
      cmyk: null,
    },
    {
      code: '15-6428 TCX',
      name: 'Green Tea',
      hex: '86A96F',
      rgb: {
        r: 134,
        g: 169,
        b: 111,
      },
      lab: {
        l: 65.14,
        a: -19.07,
        b: 24.59,
      },
      cmyk: null,
    },
    {
      code: '16-0123 TCX',
      name: 'Tendril',
      hex: '89A06B',
      rgb: {
        r: 137,
        g: 160,
        b: 107,
      },
      lab: {
        l: 62.98,
        a: -15.08,
        b: 24.36,
      },
      cmyk: null,
    },
    {
      code: '17-0215 TCX',
      name: 'Aspen Green',
      hex: '7E9B75',
      rgb: {
        r: 126,
        g: 155,
        b: 117,
      },
      lab: {
        l: 60.68,
        a: -14.91,
        b: 15.2,
      },
      cmyk: null,
    },
    {
      code: '16-0228 TCX',
      name: 'Jade Green',
      hex: '759465',
      rgb: {
        r: 117,
        g: 148,
        b: 101,
      },
      lab: {
        l: 57.64,
        a: -16.67,
        b: 19.74,
      },
      cmyk: null,
    },
    {
      code: '13-0324 TCX',
      name: 'Lettuce Green',
      hex: 'BED38E',
      rgb: {
        r: 190,
        g: 211,
        b: 142,
      },
      lab: {
        l: 81.45,
        a: -14.67,
        b: 30.61,
      },
      cmyk: null,
    },
    {
      code: '14-0226 TCX',
      name: 'Opaline Green',
      hex: 'A3C57D',
      rgb: {
        r: 163,
        g: 197,
        b: 125,
      },
      lab: {
        l: 75.32,
        a: -20.15,
        b: 30.63,
      },
      cmyk: null,
    },
    {
      code: '16-0233 TCX',
      name: 'Meadow Green',
      hex: '739957',
      rgb: {
        r: 115,
        g: 153,
        b: 87,
      },
      lab: {
        l: 58.9,
        a: -21.58,
        b: 28.52,
      },
      cmyk: null,
    },
    {
      code: '17-6229 TCX',
      name: 'Medium Green',
      hex: '3C824E',
      rgb: {
        r: 60,
        g: 130,
        b: 78,
      },
      lab: {
        l: 48.51,
        a: -33.5,
        b: 20.45,
      },
      cmyk: null,
    },
    {
      code: '18-6330 TCX',
      name: 'Juniper',
      hex: '3D7245',
      rgb: {
        r: 61,
        g: 114,
        b: 69,
      },
      lab: {
        l: 43.02,
        a: -25.66,
        b: 17.96,
      },
      cmyk: null,
    },
    {
      code: '18-6320 TCX',
      name: 'Fairway',
      hex: '477050',
      rgb: {
        r: 71,
        g: 112,
        b: 80,
      },
      lab: {
        l: 43.24,
        a: -19.42,
        b: 12.11,
      },
      cmyk: null,
    },
    {
      code: '17-6333 TCX',
      name: 'Mint Green',
      hex: '487D49',
      rgb: {
        r: 72,
        g: 125,
        b: 73,
      },
      lab: {
        l: 47.32,
        a: -28.54,
        b: 22.13,
      },
      cmyk: null,
    },
    {
      code: '13-0215 TCX',
      name: 'Reed',
      hex: 'C3D3A8',
      rgb: {
        r: 195,
        g: 211,
        b: 168,
      },
      lab: {
        l: 82.51,
        a: -10.27,
        b: 18.67,
      },
      cmyk: null,
    },
    {
      code: '12-0317 TCX',
      name: 'Gleam',
      hex: 'BFD1AD',
      rgb: {
        r: 191,
        g: 209,
        b: 173,
      },
      lab: {
        l: 81.56,
        a: -10.2,
        b: 14.88,
      },
      cmyk: null,
    },
    {
      code: '14-0115 TCX',
      name: 'Foam Green',
      hex: 'B4C79C',
      rgb: {
        r: 180,
        g: 199,
        b: 156,
      },
      lab: {
        l: 77.81,
        a: -11.56,
        b: 18.61,
      },
      cmyk: null,
    },
    {
      code: '15-6423 TCX',
      name: 'Forest Shade',
      hex: '91AC80',
      rgb: {
        r: 145,
        g: 172,
        b: 128,
      },
      lab: {
        l: 67.35,
        a: -15.01,
        b: 18.56,
      },
      cmyk: null,
    },
    {
      code: '16-6116 TCX',
      name: 'Shale Green',
      hex: '739072',
      rgb: {
        r: 115,
        g: 144,
        b: 114,
      },
      lab: {
        l: 56.77,
        a: -14.66,
        b: 11.79,
      },
      cmyk: null,
    },
    {
      code: '17-6319 TCX',
      name: 'Kashmir',
      hex: '6F8D6A',
      rgb: {
        r: 111,
        g: 141,
        b: 106,
      },
      lab: {
        l: 55.44,
        a: -15.43,
        b: 14.1,
      },
      cmyk: null,
    },
    {
      code: '17-6219 TCX',
      name: 'Deep Grass Green',
      hex: '558367',
      rgb: {
        r: 85,
        g: 131,
        b: 103,
      },
      lab: {
        l: 50.42,
        a: -19.97,
        b: 8.47,
      },
      cmyk: null,
    },
    {
      code: '16-6216 TCX',
      name: 'Basil',
      hex: '879F84',
      rgb: {
        r: 135,
        g: 159,
        b: 132,
      },
      lab: {
        l: 62.86,
        a: -11.69,
        b: 10.32,
      },
      cmyk: null,
    },
    {
      code: '17-0210 TCX',
      name: 'Loden Frost',
      hex: '788F74',
      rgb: {
        r: 120,
        g: 143,
        b: 116,
      },
      lab: {
        l: 56.77,
        a: -12.02,
        b: 11.08,
      },
      cmyk: null,
    },
    {
      code: '18-0108 TCX',
      name: 'Dill',
      hex: '647755',
      rgb: {
        r: 100,
        g: 119,
        b: 85,
      },
      lab: {
        l: 47.71,
        a: -12.72,
        b: 15.92,
      },
      cmyk: null,
    },
    {
      code: '18-0117 TCX',
      name: 'Vineyard Green',
      hex: '5F7355',
      rgb: {
        r: 95,
        g: 115,
        b: 85,
      },
      lab: {
        l: 45.97,
        a: -10.91,
        b: 12.8,
      },
      cmyk: null,
    },
    {
      code: '19-6311 TCX',
      name: 'Greener Pastures',
      hex: '37503D',
      rgb: {
        r: 55,
        g: 80,
        b: 61,
      },
      lab: {
        l: 31.39,
        a: -13.19,
        b: 7.77,
      },
      cmyk: null,
    },
    {
      code: '19-6050 TCX',
      name: 'Eden',
      hex: '264E36',
      rgb: {
        r: 38,
        g: 78,
        b: 54,
      },
      lab: {
        l: 29.35,
        a: -18.88,
        b: 8.66,
      },
      cmyk: null,
    },
    {
      code: '19-5513 TCX',
      name: 'Dark Green',
      hex: '324F40',
      rgb: {
        r: 50,
        g: 79,
        b: 64,
      },
      lab: {
        l: 30.6,
        a: -14.09,
        b: 4.57,
      },
      cmyk: null,
    },
    {
      code: '12-0106 TCX',
      name: 'Meadow Mist',
      hex: 'D3DEC4',
      rgb: {
        r: 211,
        g: 222,
        b: 196,
      },
      lab: {
        l: 86.99,
        a: -6.21,
        b: 10.89,
      },
      cmyk: null,
    },
    {
      code: '12-0109 TCX',
      name: 'Ambrosia',
      hex: 'D2E7CA',
      rgb: {
        r: 210,
        g: 231,
        b: 202,
      },
      lab: {
        l: 89.38,
        a: -11.56,
        b: 11.2,
      },
      cmyk: null,
    },
    {
      code: '13-0111 TCX',
      name: 'Seacrest',
      hex: 'BFD1B3',
      rgb: {
        r: 191,
        g: 209,
        b: 179,
      },
      lab: {
        l: 81.88,
        a: -9.9,
        b: 12.19,
      },
      cmyk: null,
    },
    {
      code: '15-6317 TCX',
      name: 'Quiet Green',
      hex: '9EBC97',
      rgb: {
        r: 158,
        g: 188,
        b: 151,
      },
      lab: {
        l: 72.95,
        a: -15.04,
        b: 14.38,
      },
      cmyk: null,
    },
    {
      code: '15-6316 TCX',
      name: 'Fair Green',
      hex: '92AF88',
      rgb: {
        r: 146,
        g: 175,
        b: 136,
      },
      lab: {
        l: 68.18,
        a: -14.69,
        b: 15.21,
      },
      cmyk: null,
    },
    {
      code: '17-0123 TCX',
      name: 'Stone Green',
      hex: '658E67',
      rgb: {
        r: 101,
        g: 142,
        b: 103,
      },
      lab: {
        l: 54.98,
        a: -20.13,
        b: 15.12,
      },
      cmyk: null,
    },
    {
      code: '17-5923 TCX',
      name: 'Pine Green',
      hex: '3A795E',
      rgb: {
        r: 58,
        g: 121,
        b: 94,
      },
      lab: {
        l: 45.68,
        a: -27.45,
        b: 7.45,
      },
      cmyk: null,
    },
    {
      code: '12-0313 TCX',
      name: 'Seafoam Green',
      hex: 'CBD5B1',
      rgb: {
        r: 203,
        g: 213,
        b: 177,
      },
      lab: {
        l: 83.77,
        a: -7.25,
        b: 15.83,
      },
      cmyk: null,
    },
    {
      code: '12-0312 TCX',
      name: 'Lime Cream',
      hex: 'D7E8BC',
      rgb: {
        r: 215,
        g: 232,
        b: 188,
      },
      lab: {
        l: 89.64,
        a: -10.46,
        b: 18.59,
      },
      cmyk: null,
    },
    {
      code: '13-0319 TCX',
      name: 'Shadow Lime',
      hex: 'CFE09D',
      rgb: {
        r: 207,
        g: 224,
        b: 157,
      },
      lab: {
        l: 86.45,
        a: -12.92,
        b: 30.08,
      },
      cmyk: null,
    },
    {
      code: '12-0322 TCX',
      name: 'Butterfly',
      hex: 'CADEA5',
      rgb: {
        r: 202,
        g: 222,
        b: 165,
      },
      lab: {
        l: 85.86,
        a: -13.24,
        b: 24.98,
      },
      cmyk: null,
    },
    {
      code: '13-0317 TCX',
      name: 'Lily Green',
      hex: 'C5CF98',
      rgb: {
        r: 197,
        g: 207,
        b: 152,
      },
      lab: {
        l: 81.09,
        a: -9.13,
        b: 25.36,
      },
      cmyk: null,
    },
    {
      code: '14-0223 TCX',
      name: 'Nile',
      hex: 'B4BB85',
      rgb: {
        r: 180,
        g: 187,
        b: 133,
      },
      lab: {
        l: 74.18,
        a: -7.77,
        b: 25.48,
      },
      cmyk: null,
    },
    {
      code: '15-0531 TCX',
      name: 'Sweet Pea',
      hex: 'A3A969',
      rgb: {
        r: 163,
        g: 169,
        b: 105,
      },
      lab: {
        l: 67.47,
        a: -8.06,
        b: 30.9,
      },
      cmyk: null,
    },
    {
      code: '12-6207 TCX',
      name: 'Frost',
      hex: 'DDE2D6',
      rgb: {
        r: 221,
        g: 226,
        b: 214,
      },
      lab: {
        l: 89.31,
        a: -2.86,
        b: 5.02,
      },
      cmyk: null,
    },
    {
      code: '12-0315 TCX',
      name: 'White Jade',
      hex: 'D4DBB2',
      rgb: {
        r: 212,
        g: 219,
        b: 178,
      },
      lab: {
        l: 85.98,
        a: -6.73,
        b: 18.96,
      },
      cmyk: null,
    },
    {
      code: '14-0217 TCX',
      name: 'Seedling',
      hex: 'C0CBA1',
      rgb: {
        r: 192,
        g: 203,
        b: 161,
      },
      lab: {
        l: 79.94,
        a: -8.68,
        b: 19.48,
      },
      cmyk: null,
    },
    {
      code: '14-0116 TCX',
      name: 'Margarita',
      hex: 'B5C38E',
      rgb: {
        r: 181,
        g: 195,
        b: 142,
      },
      lab: {
        l: 76.63,
        a: -11.48,
        b: 24.54,
      },
      cmyk: null,
    },
    {
      code: '15-0628 TCX',
      name: 'Leek Green',
      hex: 'B7B179',
      rgb: {
        r: 183,
        g: 177,
        b: 121,
      },
      lab: {
        l: 71.76,
        a: -2.92,
        b: 28.51,
      },
      cmyk: null,
    },
    {
      code: '15-0525 TCX',
      name: 'Weeping Willow',
      hex: 'B3B17B',
      rgb: {
        r: 179,
        g: 177,
        b: 123,
      },
      lab: {
        l: 71.41,
        a: -4.36,
        b: 26.98,
      },
      cmyk: null,
    },
    {
      code: '16-0430 TCX',
      name: 'Fern',
      hex: '9AA067',
      rgb: {
        r: 154,
        g: 160,
        b: 103,
      },
      lab: {
        l: 64.38,
        a: -8.66,
        b: 28.59,
      },
      cmyk: null,
    },
    {
      code: '12-0435 TCX',
      name: 'Daiquiri Green',
      hex: 'C9D77E',
      rgb: {
        r: 201,
        g: 215,
        b: 126,
      },
      lab: {
        l: 83.25,
        a: -13.47,
        b: 41.03,
      },
      cmyk: null,
    },
    {
      code: '13-0442 TCX',
      name: 'Green Glow',
      hex: 'B0C965',
      rgb: {
        r: 176,
        g: 201,
        b: 101,
      },
      lab: {
        l: 77.24,
        a: -19.19,
        b: 44.98,
      },
      cmyk: null,
    },
    {
      code: '14-0445 TCX',
      name: 'Bright Chartreuse',
      hex: 'B5BF50',
      rgb: {
        r: 181,
        g: 191,
        b: 80,
      },
      lab: {
        l: 74.69,
        a: -13.65,
        b: 52.18,
      },
      cmyk: null,
    },
    {
      code: '16-0435 TCX',
      name: 'Dark Citron',
      hex: 'A0AC4F',
      rgb: {
        r: 160,
        g: 172,
        b: 79,
      },
      lab: {
        l: 67.79,
        a: -13.43,
        b: 44.6,
      },
      cmyk: null,
    },
    {
      code: '15-0332 TCX',
      name: 'Leaf Green',
      hex: '9FAF6C',
      rgb: {
        r: 159,
        g: 175,
        b: 108,
      },
      lab: {
        l: 68.85,
        a: -13.04,
        b: 31.35,
      },
      cmyk: null,
    },
    {
      code: '15-0336 TCX',
      name: 'Herbal Garden',
      hex: '9CAD60',
      rgb: {
        r: 156,
        g: 173,
        b: 96,
      },
      lab: {
        l: 67.93,
        a: -14.5,
        b: 36.13,
      },
      cmyk: null,
    },
    {
      code: '16-0439 TCX',
      name: 'Spinach Green',
      hex: '909B4C',
      rgb: {
        r: 144,
        g: 155,
        b: 76,
      },
      lab: {
        l: 61.64,
        a: -11.53,
        b: 38.27,
      },
      cmyk: null,
    },
    {
      code: '13-0535 TCX',
      name: 'Sharp Green',
      hex: 'C6E67A',
      rgb: {
        r: 198,
        g: 230,
        b: 122,
      },
      lab: {
        l: 86.95,
        a: -22.77,
        b: 47.37,
      },
      cmyk: null,
    },
    {
      code: '13-0443 TCX',
      name: 'Love Bird',
      hex: 'C1DB3C',
      rgb: {
        r: 193,
        g: 219,
        b: 60,
      },
      lab: {
        l: 83.25,
        a: -23.09,
        b: 68.24,
      },
      cmyk: null,
    },
    {
      code: '14-0340 TCX',
      name: 'Acid Lime',
      hex: 'BBDF32',
      rgb: {
        r: 187,
        g: 223,
        b: 50,
      },
      lab: {
        l: 83.79,
        a: -27.92,
        b: 71.54,
      },
      cmyk: null,
    },
    {
      code: '13-0550 TCX',
      name: 'Lime Punch',
      hex: 'C0D725',
      rgb: {
        r: 192,
        g: 215,
        b: 37,
      },
      lab: {
        l: 81.91,
        a: -22.95,
        b: 73.73,
      },
      cmyk: null,
    },
    {
      code: '14-0446 TCX',
      name: 'Tender Shoots',
      hex: 'B5CC39',
      rgb: {
        r: 181,
        g: 204,
        b: 57,
      },
      lab: {
        l: 78.27,
        a: -21.07,
        b: 64.36,
      },
      cmyk: null,
    },
    {
      code: '16-0230 TCX',
      name: 'Macaw Green',
      hex: '9BB53E',
      rgb: {
        r: 155,
        g: 181,
        b: 62,
      },
      lab: {
        l: 69.81,
        a: -20.53,
        b: 53.69,
      },
      cmyk: null,
    },
    {
      code: '17-0336 TCX',
      name: 'Peridot',
      hex: '819448',
      rgb: {
        r: 129,
        g: 148,
        b: 72,
      },
      lab: {
        l: 58.5,
        a: -15.58,
        b: 36.37,
      },
      cmyk: null,
    },
    {
      code: '13-0643 TCX',
      name: 'Yellow Plum',
      hex: 'D5D859',
      rgb: {
        r: 213,
        g: 216,
        b: 89,
      },
      lab: {
        l: 84.14,
        a: -12.64,
        b: 59.58,
      },
      cmyk: null,
    },
    {
      code: '15-0549 TCX',
      name: 'Fragile Sprout',
      hex: 'B9BD2B',
      rgb: {
        r: 185,
        g: 189,
        b: 43,
      },
      lab: {
        l: 74.53,
        a: -14.16,
        b: 66.17,
      },
      cmyk: null,
    },
    {
      code: '14-0443 TCX',
      name: 'Kiwi Colada',
      hex: 'BCCA25',
      rgb: {
        r: 188,
        g: 202,
        b: 37,
      },
      lab: {
        l: 78.16,
        a: -19.05,
        b: 70.69,
      },
      cmyk: null,
    },
    {
      code: '14-0442 TCX',
      name: 'White Grape',
      hex: 'A6BE47',
      rgb: {
        r: 166,
        g: 190,
        b: 71,
      },
      lab: {
        l: 73.33,
        a: -21.54,
        b: 54.46,
      },
      cmyk: null,
    },
    {
      code: '16-0229 TCX',
      name: 'Titanite',
      hex: '8FAB3A',
      rgb: {
        r: 143,
        g: 171,
        b: 58,
      },
      lab: {
        l: 66.11,
        a: -21.05,
        b: 51.18,
      },
      cmyk: null,
    },
    {
      code: '17-0332 TCX',
      name: 'Spindle Tree',
      hex: '6D8F3E',
      rgb: {
        r: 109,
        g: 143,
        b: 62,
      },
      lab: {
        l: 55.43,
        a: -23.9,
        b: 37.8,
      },
      cmyk: null,
    },
    {
      code: '18-0323 TCX',
      name: 'Campsite',
      hex: '627C4C',
      rgb: {
        r: 98,
        g: 124,
        b: 76,
      },
      lab: {
        l: 48.77,
        a: -16.34,
        b: 22.31,
      },
      cmyk: null,
    },
    {
      code: '12-0741 TCX',
      name: 'Sunny Lime',
      hex: 'DFEF87',
      rgb: {
        r: 223,
        g: 239,
        b: 135,
      },
      lab: {
        l: 91.35,
        a: -15.93,
        b: 46.86,
      },
      cmyk: null,
    },
    {
      code: '12-0530 TCX',
      name: 'Charlock',
      hex: 'E5E790',
      rgb: {
        r: 229,
        g: 231,
        b: 144,
      },
      lab: {
        l: 90.06,
        a: -9.29,
        b: 41.35,
      },
      cmyk: null,
    },
    {
      code: '13-0645 TCX',
      name: 'Limeade',
      hex: 'D3D95F',
      rgb: {
        r: 211,
        g: 217,
        b: 95,
      },
      lab: {
        l: 84.46,
        a: -13.02,
        b: 57.01,
      },
      cmyk: null,
    },
    {
      code: '13-0650 TCX',
      name: 'Sulphur Spring',
      hex: 'D5D717',
      rgb: {
        r: 213,
        g: 215,
        b: 23,
      },
      lab: {
        l: 83.65,
        a: -14.46,
        b: 78.81,
      },
      cmyk: null,
    },
    {
      code: '13-0651 TCX',
      name: 'Evening Primrose',
      hex: 'CDDC20',
      rgb: {
        r: 205,
        g: 220,
        b: 32,
      },
      lab: {
        l: 84.28,
        a: -19.02,
        b: 76.8,
      },
      cmyk: null,
    },
    {
      code: '13-0540 TCX',
      name: 'Wild Lime',
      hex: 'C3D363',
      rgb: {
        r: 195,
        g: 211,
        b: 99,
      },
      lab: {
        l: 81.53,
        a: -16.38,
        b: 51.52,
      },
      cmyk: null,
    },
    {
      code: '14-0452 TCX',
      name: 'Lime Green',
      hex: '9FC131',
      rgb: {
        r: 159,
        g: 193,
        b: 49,
      },
      lab: {
        l: 73.31,
        a: -26.12,
        b: 61.86,
      },
      cmyk: null,
    },
    {
      code: '11-0710 TCX',
      name: 'Tender Yellow',
      hex: 'EDEEB7',
      rgb: {
        r: 237,
        g: 238,
        b: 183,
      },
      lab: {
        l: 92.86,
        a: -5.61,
        b: 25.89,
      },
      cmyk: null,
    },
    {
      code: '11-0618 TCX',
      name: 'Wax Yellow',
      hex: 'EDE9AD',
      rgb: {
        r: 237,
        g: 233,
        b: 173,
      },
      lab: {
        l: 91.66,
        a: -4.4,
        b: 29.15,
      },
      cmyk: null,
    },
    {
      code: '12-0426 TCX',
      name: 'Mellow Green',
      hex: 'D5D593',
      rgb: {
        r: 213,
        g: 213,
        b: 147,
      },
      lab: {
        l: 84.12,
        a: -6.65,
        b: 32.17,
      },
      cmyk: null,
    },
    {
      code: '12-0525 TCX',
      name: 'Luminary Green',
      hex: 'E3EAA5',
      rgb: {
        r: 227,
        g: 234,
        b: 165,
      },
      lab: {
        l: 90.99,
        a: -9.57,
        b: 32.28,
      },
      cmyk: null,
    },
    {
      code: '12-0520 TCX',
      name: 'Pale Lime Yellow',
      hex: 'DFE69F',
      rgb: {
        r: 223,
        g: 230,
        b: 159,
      },
      lab: {
        l: 89.41,
        a: -9.52,
        b: 33.2,
      },
      cmyk: null,
    },
    {
      code: '13-0530 TCX',
      name: 'Lime Sherbet',
      hex: 'CDD78A',
      rgb: {
        r: 205,
        g: 215,
        b: 138,
      },
      lab: {
        l: 83.78,
        a: -11.1,
        b: 35.78,
      },
      cmyk: null,
    },
    {
      code: '13-0532 TCX',
      name: 'Celery Green',
      hex: 'C5CC7B',
      rgb: {
        r: 197,
        g: 204,
        b: 123,
      },
      lab: {
        l: 79.98,
        a: -10.31,
        b: 38.3,
      },
      cmyk: null,
    },
    {
      code: '11-4803 TCX',
      name: 'Ice',
      hex: 'E0E4D9',
      rgb: {
        r: 224,
        g: 228,
        b: 217,
      },
      lab: {
        l: 90.04,
        a: -2.71,
        b: 4.71,
      },
      cmyk: null,
    },
    {
      code: '12-0108 TCX',
      name: 'Canary Green',
      hex: 'D6DFC9',
      rgb: {
        r: 214,
        g: 223,
        b: 201,
      },
      lab: {
        l: 87.48,
        a: -5.57,
        b: 9.06,
      },
      cmyk: null,
    },
    {
      code: '11-0410 TCX',
      name: 'Sylvan Green',
      hex: 'E7EACB',
      rgb: {
        r: 231,
        g: 234,
        b: 203,
      },
      lab: {
        l: 91.92,
        a: -4.23,
        b: 14.39,
      },
      cmyk: null,
    },
    {
      code: '12-0607 TCX',
      name: 'Green Essence',
      hex: 'E9EAC8',
      rgb: {
        r: 233,
        g: 234,
        b: 200,
      },
      lab: {
        l: 91.79,
        a: -3.82,
        b: 15.98,
      },
      cmyk: null,
    },
    {
      code: '12-0521 TCX',
      name: 'Young Wheat',
      hex: 'E1E3A9',
      rgb: {
        r: 225,
        g: 227,
        b: 169,
      },
      lab: {
        l: 89.04,
        a: -6.65,
        b: 27.78,
      },
      cmyk: null,
    },
    {
      code: '12-0524 TCX',
      name: 'Citron',
      hex: 'DFDE9B',
      rgb: {
        r: 223,
        g: 222,
        b: 155,
      },
      lab: {
        l: 87.27,
        a: -6.53,
        b: 32.3,
      },
      cmyk: null,
    },
    {
      code: '11-0623 TCX',
      name: 'Yellow Pear',
      hex: 'EDEA9B',
      rgb: {
        r: 237,
        g: 234,
        b: 155,
      },
      lab: {
        l: 91.53,
        a: -6.53,
        b: 37.75,
      },
      cmyk: null,
    },
    {
      code: '11-0205 TCX',
      name: 'Glass Green',
      hex: 'ECEAD0',
      rgb: {
        r: 236,
        g: 234,
        b: 208,
      },
      lab: {
        l: 92.21,
        a: -1.86,
        b: 12.46,
      },
      cmyk: null,
    },
    {
      code: '13-0614 TCX',
      name: 'Garden Glade',
      hex: 'DCD8A8',
      rgb: {
        r: 220,
        g: 216,
        b: 168,
      },
      lab: {
        l: 85.87,
        a: -3.74,
        b: 24.12,
      },
      cmyk: null,
    },
    {
      code: '12-0418 TCX',
      name: 'Hay',
      hex: 'D3CCA3',
      rgb: {
        r: 211,
        g: 204,
        b: 163,
      },
      lab: {
        l: 81.99,
        a: -2.33,
        b: 21.56,
      },
      cmyk: null,
    },
    {
      code: '13-0720 TCX',
      name: 'Custard',
      hex: 'E5D68E',
      rgb: {
        r: 229,
        g: 214,
        b: 142,
      },
      lab: {
        l: 85.78,
        a: -1.85,
        b: 37.53,
      },
      cmyk: null,
    },
    {
      code: '13-0333 TCX',
      name: 'Lima Bean',
      hex: 'E1D590',
      rgb: {
        r: 225,
        g: 213,
        b: 144,
      },
      lab: {
        l: 85.24,
        a: -2.81,
        b: 35.69,
      },
      cmyk: null,
    },
    {
      code: '14-0827 TCX',
      name: 'Dusky Citron',
      hex: 'E3CC81',
      rgb: {
        r: 227,
        g: 204,
        b: 129,
      },
      lab: {
        l: 83.03,
        a: 1.37,
        b: 40.36,
      },
      cmyk: null,
    },
    {
      code: '13-0739 TCX',
      name: 'Cream Gold',
      hex: 'DFC160',
      rgb: {
        r: 223,
        g: 193,
        b: 96,
      },
      lab: {
        l: 79.48,
        a: 3.2,
        b: 52,
      },
      cmyk: null,
    },
    {
      code: '11-0609 TCX',
      name: 'Ethereal Green',
      hex: 'F1ECCA',
      rgb: {
        r: 241,
        g: 236,
        b: 202,
      },
      lab: {
        l: 93.11,
        a: -1.68,
        b: 16.97,
      },
      cmyk: null,
    },
    {
      code: '11-0617 TCX',
      name: 'Transparent Yellow',
      hex: 'F4ECC2',
      rgb: {
        r: 244,
        g: 236,
        b: 194,
      },
      lab: {
        l: 93.22,
        a: -1.35,
        b: 21.17,
      },
      cmyk: null,
    },
    {
      code: '13-0624 TCX',
      name: 'Golden Mist',
      hex: 'D5CD94',
      rgb: {
        r: 213,
        g: 205,
        b: 148,
      },
      lab: {
        l: 81.97,
        a: -2.07,
        b: 28.63,
      },
      cmyk: null,
    },
    {
      code: '12-0619 TCX',
      name: 'Dusty Yellow',
      hex: 'D4CC9A',
      rgb: {
        r: 212,
        g: 204,
        b: 154,
      },
      lab: {
        l: 81.74,
        a: -2.13,
        b: 25.58,
      },
      cmyk: null,
    },
    {
      code: '14-0627 TCX',
      name: 'Shadow Green',
      hex: 'CFC486',
      rgb: {
        r: 207,
        g: 196,
        b: 134,
      },
      lab: {
        l: 79.12,
        a: -2.64,
        b: 32.4,
      },
      cmyk: null,
    },
    {
      code: '14-0826 TCX',
      name: 'Pampas',
      hex: 'CFBB7B',
      rgb: {
        r: 207,
        g: 187,
        b: 123,
      },
      lab: {
        l: 76.57,
        a: 1.39,
        b: 35.08,
      },
      cmyk: null,
    },
    {
      code: '16-0737 TCX',
      name: 'Burnished Gold',
      hex: 'AA9855',
      rgb: {
        r: 170,
        g: 152,
        b: 85,
      },
      lab: {
        l: 63.4,
        a: 1.82,
        b: 36.38,
      },
      cmyk: null,
    },
    {
      code: '13-0633 TCX',
      name: 'Chardonnay',
      hex: 'E7DF99',
      rgb: {
        r: 231,
        g: 223,
        b: 153,
      },
      lab: {
        l: 88.27,
        a: -3.98,
        b: 35.02,
      },
      cmyk: null,
    },
    {
      code: '14-0636 TCX',
      name: 'Muted Lime',
      hex: 'D1C87C',
      rgb: {
        r: 209,
        g: 200,
        b: 124,
      },
      lab: {
        l: 80.06,
        a: -3.17,
        b: 37.99,
      },
      cmyk: null,
    },
    {
      code: '13-0632 TCX',
      name: 'Endive',
      hex: 'D2CC81',
      rgb: {
        r: 210,
        g: 204,
        b: 129,
      },
      lab: {
        l: 81.36,
        a: -4.49,
        b: 37.52,
      },
      cmyk: null,
    },
    {
      code: '15-0636 TCX',
      name: 'Golden Green',
      hex: 'BDB369',
      rgb: {
        r: 189,
        g: 179,
        b: 105,
      },
      lab: {
        l: 72.49,
        a: -2.21,
        b: 38.1,
      },
      cmyk: null,
    },
    {
      code: '16-0632 TCX',
      name: 'Willow',
      hex: '9A8B4F',
      rgb: {
        r: 154,
        g: 139,
        b: 79,
      },
      lab: {
        l: 58.27,
        a: 0.82,
        b: 33.1,
      },
      cmyk: null,
    },
    {
      code: '17-0636 TCX',
      name: 'Green Moss',
      hex: '857946',
      rgb: {
        r: 133,
        g: 121,
        b: 70,
      },
      lab: {
        l: 51.01,
        a: 0.33,
        b: 29.39,
      },
      cmyk: null,
    },
    {
      code: '18-0627 TCX',
      name: 'Fir Green',
      hex: '67592A',
      rgb: {
        r: 103,
        g: 89,
        b: 42,
      },
      lab: {
        l: 38.62,
        a: 1.48,
        b: 29.13,
      },
      cmyk: null,
    },
    {
      code: '12-0626 TCX',
      name: 'Lemon Grass',
      hex: 'DCD494',
      rgb: {
        r: 220,
        g: 212,
        b: 148,
      },
      lab: {
        l: 84.37,
        a: -2.42,
        b: 32.19,
      },
      cmyk: null,
    },
    {
      code: '12-0633 TCX',
      name: 'Canary Yellow',
      hex: 'DFD87E',
      rgb: {
        r: 223,
        g: 216,
        b: 126,
      },
      lab: {
        l: 85.42,
        a: -5.49,
        b: 44.22,
      },
      cmyk: null,
    },
    {
      code: '14-0647 TCX',
      name: 'Celery',
      hex: 'CEC153',
      rgb: {
        r: 206,
        g: 193,
        b: 83,
      },
      lab: {
        l: 77.46,
        a: -3.55,
        b: 54.55,
      },
      cmyk: null,
    },
    {
      code: '15-0646 TCX',
      name: 'Warm Olive',
      hex: 'C7B63C',
      rgb: {
        r: 199,
        g: 182,
        b: 60,
      },
      lab: {
        l: 73.99,
        a: -3.28,
        b: 60.51,
      },
      cmyk: null,
    },
    {
      code: '15-0643 TCX',
      name: 'Cress Green',
      hex: 'BCA949',
      rgb: {
        r: 188,
        g: 169,
        b: 73,
      },
      lab: {
        l: 69.67,
        a: -0.46,
        b: 50.29,
      },
      cmyk: null,
    },
    {
      code: '16-0840 TCX',
      name: 'Antique Moss',
      hex: 'B9A023',
      rgb: {
        r: 185,
        g: 160,
        b: 35,
      },
      lab: {
        l: 66.92,
        a: 0.91,
        b: 62.39,
      },
      cmyk: null,
    },
    {
      code: '16-0639 TCX',
      name: 'Golden Olive',
      hex: 'AF9841',
      rgb: {
        r: 175,
        g: 152,
        b: 65,
      },
      lab: {
        l: 63.9,
        a: 1.37,
        b: 47.57,
      },
      cmyk: null,
    },
    {
      code: '15-0533 TCX',
      name: 'Linden Green',
      hex: 'C4BF71',
      rgb: {
        r: 196,
        g: 191,
        b: 113,
      },
      lab: {
        l: 76.58,
        a: -6.02,
        b: 39.68,
      },
      cmyk: null,
    },
    {
      code: '15-0543 TCX',
      name: 'Apple Green',
      hex: 'B5B644',
      rgb: {
        r: 181,
        g: 182,
        b: 68,
      },
      lab: {
        l: 72.28,
        a: -9.58,
        b: 54.69,
      },
      cmyk: null,
    },
    {
      code: '15-0544 TCX',
      name: 'Grenoble Green',
      hex: 'A2A524',
      rgb: {
        r: 162,
        g: 165,
        b: 36,
      },
      lab: {
        l: 65.85,
        a: -12.99,
        b: 60.32,
      },
      cmyk: null,
    },
    {
      code: '16-0540 TCX',
      name: 'Oasis',
      hex: 'A3A04D',
      rgb: {
        r: 163,
        g: 160,
        b: 77,
      },
      lab: {
        l: 64.87,
        a: -6.19,
        b: 41.77,
      },
      cmyk: null,
    },
    {
      code: '16-0543 TCX',
      name: 'Golden Lime',
      hex: '9A9739',
      rgb: {
        r: 154,
        g: 151,
        b: 57,
      },
      lab: {
        l: 61.45,
        a: -7.1,
        b: 47.64,
      },
      cmyk: null,
    },
    {
      code: '16-0545 TCX',
      name: 'Split Pea',
      hex: '9C9A40',
      rgb: {
        r: 156,
        g: 154,
        b: 64,
      },
      lab: {
        l: 62.43,
        a: -7.21,
        b: 45.45,
      },
      cmyk: null,
    },
    {
      code: '16-0550 TCX',
      name: 'Lentil Sprout',
      hex: 'ACA54E',
      rgb: {
        r: 172,
        g: 165,
        b: 78,
      },
      lab: {
        l: 66.96,
        a: -5.16,
        b: 44.73,
      },
      cmyk: null,
    },
    {
      code: '15-0548 TCX',
      name: 'Citronelle',
      hex: 'B8AF23',
      rgb: {
        r: 184,
        g: 175,
        b: 35,
      },
      lab: {
        l: 70.57,
        a: -7.09,
        b: 65.15,
      },
      cmyk: null,
    },
    {
      code: '15-0542 TCX',
      name: 'Pear Liqueur',
      hex: 'A29C1F',
      rgb: {
        r: 162,
        g: 156,
        b: 31,
      },
      lab: {
        l: 63.49,
        a: -8.5,
        b: 59.88,
      },
      cmyk: null,
    },
    {
      code: '16-0541 TCX',
      name: 'Green Envy',
      hex: 'A19436',
      rgb: {
        r: 161,
        g: 148,
        b: 54,
      },
      lab: {
        l: 61.35,
        a: -3.28,
        b: 49.71,
      },
      cmyk: null,
    },
    {
      code: '16-0640 TCX',
      name: 'Avocado Oil',
      hex: '9B892F',
      rgb: {
        r: 155,
        g: 137,
        b: 47,
      },
      lab: {
        l: 57.75,
        a: -0.43,
        b: 48.68,
      },
      cmyk: null,
    },
    {
      code: '16-0544 TCX',
      name: 'Camping Gear',
      hex: '97913A',
      rgb: {
        r: 151,
        g: 145,
        b: 58,
      },
      lab: {
        l: 59.59,
        a: -6.17,
        b: 45.7,
      },
      cmyk: null,
    },
    {
      code: '16-0634 TCX',
      name: 'Evergreen Sprig',
      hex: '8D8646',
      rgb: {
        r: 141,
        g: 134,
        b: 70,
      },
      lab: {
        l: 55.53,
        a: -4.86,
        b: 35.25,
      },
      cmyk: null,
    },
    {
      code: '18-0537 TCX',
      name: 'Golden Cypress',
      hex: '7D7F2E',
      rgb: {
        r: 125,
        g: 127,
        b: 46,
      },
      lab: {
        l: 51.95,
        a: -10.13,
        b: 42.66,
      },
      cmyk: null,
    },
    {
      code: '15-0538 TCX',
      name: 'Green Oasis',
      hex: 'B0B454',
      rgb: {
        r: 176,
        g: 180,
        b: 84,
      },
      lab: {
        l: 71.49,
        a: -10.22,
        b: 46.58,
      },
      cmyk: null,
    },
    {
      code: '16-0436 TCX',
      name: 'Pickled Pepper',
      hex: '9EA450',
      rgb: {
        r: 158,
        g: 164,
        b: 80,
      },
      lab: {
        l: 65.54,
        a: -9.67,
        b: 41.05,
      },
      cmyk: null,
    },
    {
      code: '16-0532 TCX',
      name: 'Moss',
      hex: 'A09D59',
      rgb: {
        r: 160,
        g: 157,
        b: 89,
      },
      lab: {
        l: 63.67,
        a: -4.94,
        b: 34.61,
      },
      cmyk: null,
    },
    {
      code: '18-0540 TCX',
      name: 'Lima Bean Green',
      hex: '828B33',
      rgb: {
        r: 130,
        g: 139,
        b: 51,
      },
      lab: {
        l: 55.7,
        a: -12.09,
        b: 43.69,
      },
      cmyk: null,
    },
    {
      code: '18-0530 TCX',
      name: 'Going Green',
      hex: '7C823F',
      rgb: {
        r: 124,
        g: 130,
        b: 63,
      },
      lab: {
        l: 52.71,
        a: -8.6,
        b: 34.22,
      },
      cmyk: null,
    },
    {
      code: '17-0530 TCX',
      name: 'Guacamole',
      hex: '797B3B',
      rgb: {
        r: 121,
        g: 123,
        b: 59,
      },
      lab: {
        l: 50.44,
        a: -7.9,
        b: 34.17,
      },
      cmyk: null,
    },
    {
      code: '18-0538 TCX',
      name: 'Woodbine',
      hex: '7B7F32',
      rgb: {
        r: 123,
        g: 127,
        b: 50,
      },
      lab: {
        l: 51.53,
        a: -8.97,
        b: 39.53,
      },
      cmyk: null,
    },
    {
      code: '13-0522 TCX',
      name: 'Pale Green',
      hex: 'CBCE91',
      rgb: {
        r: 203,
        g: 206,
        b: 145,
      },
      lab: {
        l: 81.38,
        a: -6.95,
        b: 29.31,
      },
      cmyk: null,
    },
    {
      code: '14-0434 TCX',
      name: 'Green Banana',
      hex: 'BABC72',
      rgb: {
        r: 186,
        g: 188,
        b: 114,
      },
      lab: {
        l: 74.79,
        a: -7.72,
        b: 36.1,
      },
      cmyk: null,
    },
    {
      code: '15-0535 TCX',
      name: 'Palm',
      hex: 'AFAF5E',
      rgb: {
        r: 175,
        g: 175,
        b: 94,
      },
      lab: {
        l: 70.11,
        a: -7.3,
        b: 40.09,
      },
      cmyk: null,
    },
    {
      code: '16-0531 TCX',
      name: 'Perfect Pear',
      hex: '919047',
      rgb: {
        r: 145,
        g: 144,
        b: 71,
      },
      lab: {
        l: 58.62,
        a: -6.58,
        b: 38.04,
      },
      cmyk: null,
    },
    {
      code: '17-0529 TCX',
      name: 'Cardamom Seed',
      hex: '757331',
      rgb: {
        r: 117,
        g: 115,
        b: 49,
      },
      lab: {
        l: 47.64,
        a: -5.63,
        b: 35.16,
      },
      cmyk: null,
    },
    {
      code: '18-0428 TCX',
      name: 'Peat Moss',
      hex: '6D6B3A',
      rgb: {
        r: 109,
        g: 107,
        b: 58,
      },
      lab: {
        l: 44.57,
        a: -5.16,
        b: 27.39,
      },
      cmyk: null,
    },
    {
      code: '18-0529 TCX',
      name: 'Sphagnum',
      hex: '62633D',
      rgb: {
        r: 98,
        g: 99,
        b: 61,
      },
      lab: {
        l: 40.98,
        a: -3.19,
        b: 20.59,
      },
      cmyk: null,
    },
    {
      code: '14-0425 TCX',
      name: 'Beechnut',
      hex: 'C2C18D',
      rgb: {
        r: 194,
        g: 193,
        b: 141,
      },
      lab: {
        l: 77.28,
        a: -5.59,
        b: 26.16,
      },
      cmyk: null,
    },
    {
      code: '15-0326 TCX',
      name: 'Tarragon',
      hex: 'A4AE77',
      rgb: {
        r: 164,
        g: 174,
        b: 119,
      },
      lab: {
        l: 69.21,
        a: -9.11,
        b: 26.08,
      },
      cmyk: null,
    },
    {
      code: '17-0324 TCX',
      name: 'Epsom',
      hex: '849161',
      rgb: {
        r: 132,
        g: 145,
        b: 97,
      },
      lab: {
        l: 58.04,
        a: -9.62,
        b: 22.63,
      },
      cmyk: null,
    },
    {
      code: '17-0330 TCX',
      name: 'Turtle Green',
      hex: '81894E',
      rgb: {
        r: 129,
        g: 137,
        b: 78,
      },
      lab: {
        l: 55.2,
        a: -9.45,
        b: 29.93,
      },
      cmyk: null,
    },
    {
      code: '18-0324 TCX',
      name: 'Calliste Green',
      hex: '757A4E',
      rgb: {
        r: 117,
        g: 122,
        b: 78,
      },
      lab: {
        l: 49.9,
        a: -7.23,
        b: 23.17,
      },
      cmyk: null,
    },
    {
      code: '18-0332 TCX',
      name: 'Grasshopper',
      hex: '77824A',
      rgb: {
        r: 119,
        g: 130,
        b: 74,
      },
      lab: {
        l: 52.43,
        a: -12.06,
        b: 28.97,
      },
      cmyk: null,
    },
    {
      code: '18-0435 TCX',
      name: 'Calla Green ',
      hex: '6A6F34',
      rgb: {
        r: 106,
        g: 111,
        b: 52,
      },
      lab: {
        l: 45.39,
        a: -8.76,
        b: 31.7,
      },
      cmyk: null,
    },
    {
      code: '13-0608 TCX',
      name: 'Aloe Wash',
      hex: 'D0D3B7',
      rgb: {
        r: 208,
        g: 211,
        b: 183,
      },
      lab: {
        l: 83.65,
        a: -4.06,
        b: 13.43,
      },
      cmyk: null,
    },
    {
      code: '14-0216 TCX',
      name: 'Lint',
      hex: 'B6BA99',
      rgb: {
        r: 182,
        g: 186,
        b: 153,
      },
      lab: {
        l: 74.39,
        a: -4.35,
        b: 15.22,
      },
      cmyk: null,
    },
    {
      code: '16-0421 TCX',
      name: 'Sage',
      hex: '91946E',
      rgb: {
        r: 145,
        g: 148,
        b: 110,
      },
      lab: {
        l: 60.22,
        a: -5.21,
        b: 19.52,
      },
      cmyk: null,
    },
    {
      code: '17-0525 TCX',
      name: 'Mosstone',
      hex: '858961',
      rgb: {
        r: 133,
        g: 137,
        b: 97,
      },
      lab: {
        l: 55.97,
        a: -5.64,
        b: 20.33,
      },
      cmyk: null,
    },
    {
      code: '18-0525 TCX',
      name: 'Iguana',
      hex: '818455',
      rgb: {
        r: 129,
        g: 132,
        b: 85,
      },
      lab: {
        l: 53.95,
        a: -6.78,
        b: 24.63,
      },
      cmyk: null,
    },
    {
      code: '18-0228 TCX',
      name: 'Pesto',
      hex: '595F34',
      rgb: {
        r: 89,
        g: 95,
        b: 52,
      },
      lab: {
        l: 38.94,
        a: -7.9,
        b: 23.6,
      },
      cmyk: null,
    },
    {
      code: '18-0328 TCX',
      name: 'Cedar Green',
      hex: '5E6737',
      rgb: {
        r: 94,
        g: 103,
        b: 55,
      },
      lab: {
        l: 41.9,
        a: -9.24,
        b: 25.69,
      },
      cmyk: null,
    },
    {
      code: '15-0523 TCX',
      name: 'Winter Pear',
      hex: 'B0B487',
      rgb: {
        r: 176,
        g: 180,
        b: 135,
      },
      lab: {
        l: 72.11,
        a: -6.48,
        b: 22.21,
      },
      cmyk: null,
    },
    {
      code: '16-0526 TCX',
      name: 'Cedar',
      hex: '928E64',
      rgb: {
        r: 146,
        g: 142,
        b: 100,
      },
      lab: {
        l: 58.53,
        a: -2.15,
        b: 22.37,
      },
      cmyk: null,
    },
    {
      code: '17-0535 TCX',
      name: 'Green Olive',
      hex: '8D8B55',
      rgb: {
        r: 141,
        g: 139,
        b: 85,
      },
      lab: {
        l: 56.98,
        a: -4.89,
        b: 29.16,
      },
      cmyk: null,
    },
    {
      code: '18-0430 TCX',
      name: 'Avocado',
      hex: '676232',
      rgb: {
        r: 103,
        g: 98,
        b: 50,
      },
      lab: {
        l: 41.09,
        a: -2.84,
        b: 27.89,
      },
      cmyk: null,
    },
    {
      code: '18-0426 TCX',
      name: 'Capulet Olive',
      hex: '656344',
      rgb: {
        r: 101,
        g: 99,
        b: 68,
      },
      lab: {
        l: 41.63,
        a: -3.19,
        b: 18.18,
      },
      cmyk: null,
    },
    {
      code: '18-0220 TCX',
      name: 'Mayfly',
      hex: '666740',
      rgb: {
        r: 102,
        g: 103,
        b: 64,
      },
      lab: {
        l: 42.63,
        a: -5.09,
        b: 21.66,
      },
      cmyk: null,
    },
    {
      code: '18-0527 TCX',
      name: 'Olive Branch',
      hex: '6A6A45',
      rgb: {
        r: 106,
        g: 106,
        b: 69,
      },
      lab: {
        l: 44.14,
        a: -3.03,
        b: 20.2,
      },
      cmyk: null,
    },
    {
      code: '17-0115 TCX',
      name: 'Oil Green',
      hex: '80856D',
      rgb: {
        r: 128,
        g: 133,
        b: 109,
      },
      lab: {
        l: 54.65,
        a: -4.43,
        b: 11.88,
      },
      cmyk: null,
    },
    {
      code: '18-0316 TCX',
      name: 'Olivine',
      hex: '666B54',
      rgb: {
        r: 102,
        g: 107,
        b: 84,
      },
      lab: {
        l: 44.26,
        a: -4.72,
        b: 12.23,
      },
      cmyk: null,
    },
    {
      code: '18-0317 TCX',
      name: 'Bronze Green',
      hex: '525F48',
      rgb: {
        r: 82,
        g: 95,
        b: 72,
      },
      lab: {
        l: 38.55,
        a: -7.92,
        b: 10.8,
      },
      cmyk: null,
    },
    {
      code: '19-0323 TCX',
      name: 'Chive',
      hex: '4B5335',
      rgb: {
        r: 75,
        g: 83,
        b: 53,
      },
      lab: {
        l: 33.91,
        a: -7.59,
        b: 16.27,
      },
      cmyk: null,
    },
    {
      code: '18-0322 TCX',
      name: 'Cypress',
      hex: '545A3E',
      rgb: {
        r: 84,
        g: 90,
        b: 62,
      },
      lab: {
        l: 36.99,
        a: -5.9,
        b: 15.01,
      },
      cmyk: null,
    },
    {
      code: '18-0523 TCX',
      name: 'Winter Moss',
      hex: '5B5A41',
      rgb: {
        r: 91,
        g: 90,
        b: 65,
      },
      lab: {
        l: 37.69,
        a: -2.47,
        b: 14.42,
      },
      cmyk: null,
    },
    {
      code: '19-0419 TCX',
      name: 'Rifle Green',
      hex: '424832',
      rgb: {
        r: 66,
        g: 72,
        b: 50,
      },
      lab: {
        l: 29.36,
        a: -5.33,
        b: 11.66,
      },
      cmyk: null,
    },
    {
      code: '18-0422 TCX',
      name: 'Loden Green',
      hex: '6E7153',
      rgb: {
        r: 110,
        g: 113,
        b: 83,
      },
      lab: {
        l: 46.86,
        a: -4.87,
        b: 16.11,
      },
      cmyk: null,
    },
    {
      code: '18-0420 TCX',
      name: 'Four Leaf Clover',
      hex: '616652',
      rgb: {
        r: 97,
        g: 102,
        b: 82,
      },
      lab: {
        l: 42.17,
        a: -4.51,
        b: 10.57,
      },
      cmyk: null,
    },
    {
      code: '18-0521 TCX',
      name: 'Burnt Olive',
      hex: '646049',
      rgb: {
        r: 100,
        g: 96,
        b: 73,
      },
      lab: {
        l: 40.65,
        a: -0.75,
        b: 13.69,
      },
      cmyk: null,
    },
    {
      code: '19-0512 TCX',
      name: 'Ivy Green',
      hex: '585442',
      rgb: {
        r: 88,
        g: 84,
        b: 66,
      },
      lab: {
        l: 35.69,
        a: -0.27,
        b: 10.75,
      },
      cmyk: null,
    },
    {
      code: '19-0510 TCX',
      name: 'Kalamata',
      hex: '5F5C4C',
      rgb: {
        r: 95,
        g: 92,
        b: 76,
      },
      lab: {
        l: 38.93,
        a: 0,
        b: 9.28,
      },
      cmyk: null,
    },
    {
      code: '19-0515 TCX',
      name: 'Olive Night',
      hex: '535040',
      rgb: {
        r: 83,
        g: 80,
        b: 64,
      },
      lab: {
        l: 33.99,
        a: -0.57,
        b: 9.54,
      },
      cmyk: null,
    },
    {
      code: '19-0413 TCX',
      name: 'Deep Depths',
      hex: '46483D',
      rgb: {
        r: 70,
        g: 72,
        b: 61,
      },
      lab: {
        l: 30.23,
        a: -2.07,
        b: 6.52,
      },
      cmyk: null,
    },
    {
      code: '18-0617 TCX',
      name: 'Covert Green',
      hex: '80765F',
      rgb: {
        r: 128,
        g: 118,
        b: 95,
      },
      lab: {
        l: 50.07,
        a: 1.8,
        b: 14.05,
      },
      cmyk: null,
    },
    {
      code: '17-0517 TCX',
      name: 'Dusky Green',
      hex: '746C57',
      rgb: {
        r: 116,
        g: 108,
        b: 87,
      },
      lab: {
        l: 45.95,
        a: 1.08,
        b: 12.86,
      },
      cmyk: null,
    },
    {
      code: '19-0622 TCX',
      name: 'Military Olive',
      hex: '63563B',
      rgb: {
        r: 99,
        g: 86,
        b: 59,
      },
      lab: {
        l: 37.32,
        a: 2.92,
        b: 17.9,
      },
      cmyk: null,
    },
    {
      code: '19-0516 TCX',
      name: 'Dark Olive',
      hex: '574D35',
      rgb: {
        r: 87,
        g: 77,
        b: 53,
      },
      lab: {
        l: 33.33,
        a: 1.7,
        b: 16.2,
      },
      cmyk: null,
    },
    {
      code: '19-0403 TCX',
      name: 'Sea Turtle',
      hex: '5E574A',
      rgb: {
        r: 94,
        g: 87,
        b: 74,
      },
      lab: {
        l: 37.59,
        a: 1.72,
        b: 9.07,
      },
      cmyk: null,
    },
    {
      code: '19-0511 TCX',
      name: 'Grape Leaf',
      hex: '545244',
      rgb: {
        r: 84,
        g: 82,
        b: 68,
      },
      lab: {
        l: 34.62,
        a: -0.27,
        b: 7.99,
      },
      cmyk: null,
    },
    {
      code: '19-0414 TCX',
      name: 'Forest Night',
      hex: '434237',
      rgb: {
        r: 67,
        g: 66,
        b: 55,
      },
      lab: {
        l: 27.88,
        a: -1.23,
        b: 7.17,
      },
      cmyk: null,
    },
    {
      code: '16-0518 TCX',
      name: 'Gray Green',
      hex: 'A49A79',
      rgb: {
        r: 164,
        g: 154,
        b: 121,
      },
      lab: {
        l: 63.8,
        a: 0.51,
        b: 18.26,
      },
      cmyk: null,
    },
    {
      code: '17-0625 TCX',
      name: 'Boa',
      hex: '8E855F',
      rgb: {
        r: 142,
        g: 133,
        b: 95,
      },
      lab: {
        l: 55.59,
        a: -0.62,
        b: 21.82,
      },
      cmyk: null,
    },
    {
      code: '17-0627 TCX',
      name: 'Dried Herb',
      hex: '847A59',
      rgb: {
        r: 132,
        g: 122,
        b: 89,
      },
      lab: {
        l: 51.61,
        a: 0.06,
        b: 19.81,
      },
      cmyk: null,
    },
    {
      code: '18-0622 TCX',
      name: 'Olive Drab',
      hex: '756D47',
      rgb: {
        r: 117,
        g: 109,
        b: 71,
      },
      lab: {
        l: 45.99,
        a: -0.26,
        b: 22.28,
      },
      cmyk: null,
    },
    {
      code: '18-0825 TCX',
      name: 'Nutria',
      hex: '75663E',
      rgb: {
        r: 117,
        g: 102,
        b: 62,
      },
      lab: {
        l: 43.91,
        a: 2.33,
        b: 24.89,
      },
      cmyk: null,
    },
    {
      code: '18-0629 TCX',
      name: 'Lizard',
      hex: '71643E',
      rgb: {
        r: 113,
        g: 100,
        b: 62,
      },
      lab: {
        l: 43.11,
        a: 1.12,
        b: 23.54,
      },
      cmyk: null,
    },
    {
      code: '18-0625 TCX',
      name: 'Martini Olive',
      hex: '726A4E',
      rgb: {
        r: 114,
        g: 106,
        b: 78,
      },
      lab: {
        l: 45.07,
        a: 0.08,
        b: 16.89,
      },
      cmyk: null,
    },
    {
      code: '14-0418 TCX',
      name: 'Bog',
      hex: 'BAB696',
      rgb: {
        r: 186,
        g: 182,
        b: 150,
      },
      lab: {
        l: 73.84,
        a: -1.51,
        b: 16.52,
      },
      cmyk: null,
    },
    {
      code: '15-0318 TCX',
      name: 'Sage Green',
      hex: 'B2AC88',
      rgb: {
        r: 178,
        g: 172,
        b: 136,
      },
      lab: {
        l: 70.05,
        a: -1.43,
        b: 18.95,
      },
      cmyk: null,
    },
    {
      code: '16-0713 TCX',
      name: 'Slate Green',
      hex: 'A0987C',
      rgb: {
        r: 160,
        g: 152,
        b: 124,
      },
      lab: {
        l: 62.98,
        a: 0.33,
        b: 15.77,
      },
      cmyk: null,
    },
    {
      code: '17-0619 TCX',
      name: 'Overland Trek',
      hex: '898270',
      rgb: {
        r: 137,
        g: 130,
        b: 112,
      },
      lab: {
        l: 54.55,
        a: 0.79,
        b: 10.68,
      },
      cmyk: null,
    },
    {
      code: '16-0613 TCX',
      name: 'Elm',
      hex: 'A39F86',
      rgb: {
        r: 163,
        g: 159,
        b: 134,
      },
      lab: {
        l: 65.4,
        a: -1.08,
        b: 13.58,
      },
      cmyk: null,
    },
    {
      code: '17-0620 TCX',
      name: 'Aloe',
      hex: '817A60',
      rgb: {
        r: 129,
        g: 122,
        b: 96,
      },
      lab: {
        l: 51.39,
        a: -0.37,
        b: 15.33,
      },
      cmyk: null,
    },
    {
      code: '17-0618 TCX',
      name: 'Mermaid',
      hex: '817A65',
      rgb: {
        r: 129,
        g: 122,
        b: 101,
      },
      lab: {
        l: 51.57,
        a: 0.28,
        b: 12.81,
      },
      cmyk: null,
    },
    {
      code: '15-0522 TCX',
      name: 'Pale Olive Green',
      hex: 'B5AD88',
      rgb: {
        r: 181,
        g: 173,
        b: 136,
      },
      lab: {
        l: 70.61,
        a: -0.69,
        b: 19.74,
      },
      cmyk: null,
    },
    {
      code: '16-1110 TCX',
      name: 'Olive Gray',
      hex: 'A6997A',
      rgb: {
        r: 166,
        g: 153,
        b: 122,
      },
      lab: {
        l: 63.72,
        a: 2.11,
        b: 17.94,
      },
      cmyk: null,
    },
    {
      code: '16-1108 TCX',
      name: 'Twill',
      hex: 'A79B82',
      rgb: {
        r: 167,
        g: 155,
        b: 130,
      },
      lab: {
        l: 64.7,
        a: 2.27,
        b: 14.8,
      },
      cmyk: null,
    },
    {
      code: '16-1118 TCX',
      name: 'Sponge',
      hex: 'A49775',
      rgb: {
        r: 164,
        g: 151,
        b: 117,
      },
      lab: {
        l: 63.07,
        a: 1.68,
        b: 19.76,
      },
      cmyk: null,
    },
    {
      code: '17-0630 TCX',
      name: 'Tree House',
      hex: '998D76',
      rgb: {
        r: 153,
        g: 141,
        b: 118,
      },
      lab: {
        l: 59.13,
        a: 2.49,
        b: 14.02,
      },
      cmyk: null,
    },
    {
      code: '17-1019 TCX',
      name: 'Elmwood',
      hex: '8C7C61',
      rgb: {
        r: 140,
        g: 124,
        b: 97,
      },
      lab: {
        l: 52.92,
        a: 3.84,
        b: 17.12,
      },
      cmyk: null,
    },
    {
      code: '18-0724 TCX',
      name: 'Gothic Olive',
      hex: '7C6E4E',
      rgb: {
        r: 124,
        g: 110,
        b: 78,
      },
      lab: {
        l: 47.25,
        a: 2.98,
        b: 19.71,
      },
      cmyk: null,
    },
    {
      code: '13-0715 TCX',
      name: 'Sea Mist',
      hex: 'D8C9A3',
      rgb: {
        r: 216,
        g: 201,
        b: 163,
      },
      lab: {
        l: 81.49,
        a: 1.7,
        b: 21.08,
      },
      cmyk: null,
    },
    {
      code: '13-0613 TCX',
      name: 'Chino Green',
      hex: 'D9CAA5',
      rgb: {
        r: 217,
        g: 202,
        b: 165,
      },
      lab: {
        l: 81.87,
        a: 1.81,
        b: 20.45,
      },
      cmyk: null,
    },
    {
      code: '13-0919 TCX',
      name: 'Soybean',
      hex: 'D2C29D',
      rgb: {
        r: 210,
        g: 194,
        b: 157,
      },
      lab: {
        l: 79.23,
        a: 2.25,
        b: 20.76,
      },
      cmyk: null,
    },
    {
      code: '13-0915 TCX',
      name: 'Reed Yellow',
      hex: 'DCC99E',
      rgb: {
        r: 220,
        g: 201,
        b: 158,
      },
      lab: {
        l: 81.88,
        a: 2.51,
        b: 24.2,
      },
      cmyk: null,
    },
    {
      code: '14-1113 TCX',
      name: 'Marzipan',
      hex: 'D8C09D',
      rgb: {
        r: 216,
        g: 192,
        b: 157,
      },
      lab: {
        l: 79.32,
        a: 4.92,
        b: 21.15,
      },
      cmyk: null,
    },
    {
      code: '14-0925 TCX',
      name: 'Parsnip',
      hex: 'D6C69A',
      rgb: {
        r: 214,
        g: 198,
        b: 154,
      },
      lab: {
        l: 80.47,
        a: 1.39,
        b: 24.37,
      },
      cmyk: null,
    },
    {
      code: '14-1026 TCX',
      name: 'Moonstone',
      hex: 'CCB584',
      rgb: {
        r: 204,
        g: 181,
        b: 132,
      },
      lab: {
        l: 75.05,
        a: 3.69,
        b: 28.32,
      },
      cmyk: null,
    },
    {
      code: '14-0626 TCX',
      name: 'Dried Moss',
      hex: 'CCB97E',
      rgb: {
        r: 204,
        g: 185,
        b: 126,
      },
      lab: {
        l: 75.92,
        a: 1.27,
        b: 32.55,
      },
      cmyk: null,
    },
    {
      code: '14-1025 TCX',
      name: 'Cocoon',
      hex: 'C9B27C',
      rgb: {
        r: 201,
        g: 178,
        b: 124,
      },
      lab: {
        l: 73.81,
        a: 3.91,
        b: 31.03,
      },
      cmyk: null,
    },
    {
      code: '14-0721 TCX',
      name: 'Hemp',
      hex: 'C0AD7C',
      rgb: {
        r: 192,
        g: 173,
        b: 124,
      },
      lab: {
        l: 71.62,
        a: 2.61,
        b: 27.73,
      },
      cmyk: null,
    },
    {
      code: '15-0719 TCX',
      name: 'Silver Fern',
      hex: 'BBAA7E',
      rgb: {
        r: 187,
        g: 170,
        b: 126,
      },
      lab: {
        l: 70.28,
        a: 2.65,
        b: 25.07,
      },
      cmyk: null,
    },
    {
      code: '16-0726 TCX',
      name: 'Khaki',
      hex: 'A39264',
      rgb: {
        r: 163,
        g: 146,
        b: 100,
      },
      lab: {
        l: 61.23,
        a: 2.4,
        b: 26.49,
      },
      cmyk: null,
    },
    {
      code: '17-0929 TCX',
      name: 'Fennel Seed',
      hex: '998456',
      rgb: {
        r: 153,
        g: 132,
        b: 86,
      },
      lab: {
        l: 56.43,
        a: 3.82,
        b: 27.85,
      },
      cmyk: null,
    },
    {
      code: '18-0820 TCX',
      name: 'Capers',
      hex: '6A5E4B',
      rgb: {
        r: 106,
        g: 94,
        b: 75,
      },
      lab: {
        l: 40.76,
        a: 2.93,
        b: 12.85,
      },
      cmyk: null,
    },
    {
      code: '16-1326 TCX',
      name: 'Prairie Sand',
      hex: 'B59A6A',
      rgb: {
        r: 181,
        g: 154,
        b: 106,
      },
      lab: {
        l: 65.41,
        a: 6.56,
        b: 29.35,
      },
      cmyk: null,
    },
    {
      code: '16-1126 TCX',
      name: 'Antelope',
      hex: 'B19664',
      rgb: {
        r: 177,
        g: 150,
        b: 100,
      },
      lab: {
        l: 63.79,
        a: 6.28,
        b: 30.26,
      },
      cmyk: null,
    },
    {
      code: '16-1133 TCX',
      name: 'Mustard Gold',
      hex: 'B08E51',
      rgb: {
        r: 176,
        g: 142,
        b: 81,
      },
      lab: {
        l: 61.61,
        a: 8.66,
        b: 37.74,
      },
      cmyk: null,
    },
    {
      code: '17-1028 TCX',
      name: 'Antique Bronze',
      hex: '907954',
      rgb: {
        r: 144,
        g: 121,
        b: 84,
      },
      lab: {
        l: 52.61,
        a: 5.89,
        b: 23.74,
      },
      cmyk: null,
    },
    {
      code: '17-0935 TCX',
      name: 'Dull Gold',
      hex: '8A6F48',
      rgb: {
        r: 138,
        g: 111,
        b: 72,
      },
      lab: {
        l: 49.09,
        a: 7.74,
        b: 26.08,
      },
      cmyk: null,
    },
    {
      code: '18-0830 TCX',
      name: 'Butternut',
      hex: '7B643F',
      rgb: {
        r: 123,
        g: 100,
        b: 63,
      },
      lab: {
        l: 44.08,
        a: 6.26,
        b: 24.81,
      },
      cmyk: null,
    },
    {
      code: '19-0617 TCX',
      name: 'Teak',
      hex: '655341',
      rgb: {
        r: 101,
        g: 83,
        b: 65,
      },
      lab: {
        l: 37.02,
        a: 5.99,
        b: 13.54,
      },
      cmyk: null,
    },
    {
      code: '16-0730 TCX',
      name: 'Antique Gold',
      hex: 'B59E5F',
      rgb: {
        r: 181,
        g: 158,
        b: 95,
      },
      lab: {
        l: 66.17,
        a: 3.49,
        b: 35.67,
      },
      cmyk: null,
    },
    {
      code: '17-0836 TCX',
      name: 'Ecru Olive',
      hex: '927B3C',
      rgb: {
        r: 146,
        g: 123,
        b: 60,
      },
      lab: {
        l: 53.03,
        a: 4.25,
        b: 37.26,
      },
      cmyk: null,
    },
    {
      code: '17-0840 TCX',
      name: 'Amber Green',
      hex: '9A803A',
      rgb: {
        r: 154,
        g: 128,
        b: 58,
      },
      lab: {
        l: 55.28,
        a: 4.67,
        b: 41.43,
      },
      cmyk: null,
    },
    {
      code: '17-0843 TCX',
      name: 'Bronze Mist',
      hex: '9C7E41',
      rgb: {
        r: 156,
        g: 126,
        b: 65,
      },
      lab: {
        l: 55.04,
        a: 7.23,
        b: 37.53,
      },
      cmyk: null,
    },
    {
      code: '19-1034 TCX',
      name: 'Breen',
      hex: '795D34',
      rgb: {
        r: 121,
        g: 93,
        b: 52,
      },
      lab: {
        l: 41.88,
        a: 8.26,
        b: 28.02,
      },
      cmyk: null,
    },
    {
      code: '18-0920 TCX',
      name: 'Kangaroo',
      hex: '725E43',
      rgb: {
        r: 114,
        g: 94,
        b: 67,
      },
      lab: {
        l: 41.48,
        a: 6.45,
        b: 18.65,
      },
      cmyk: null,
    },
    {
      code: '19-0618 TCX',
      name: 'Beech',
      hex: '5B4F3B',
      rgb: {
        r: 91,
        g: 79,
        b: 59,
      },
      lab: {
        l: 34.48,
        a: 3.76,
        b: 13.69,
      },
      cmyk: null,
    },
    {
      code: '15-0730 TCX',
      name: 'Southern Moss',
      hex: 'BCA66A',
      rgb: {
        r: 188,
        g: 166,
        b: 106,
      },
      lab: {
        l: 69.03,
        a: 3.22,
        b: 33.73,
      },
      cmyk: null,
    },
    {
      code: '15-0732 TCX',
      name: 'Olivenite',
      hex: 'C1A65C',
      rgb: {
        r: 193,
        g: 166,
        b: 92,
      },
      lab: {
        l: 69.4,
        a: 4.14,
        b: 41.61,
      },
      cmyk: null,
    },
    {
      code: '16-0742 TCX',
      name: 'Green Sulphur',
      hex: 'AE8E2C',
      rgb: {
        r: 174,
        g: 142,
        b: 44,
      },
      lab: {
        l: 60.83,
        a: 5.47,
        b: 53.93,
      },
      cmyk: null,
    },
    {
      code: '17-0839 TCX',
      name: 'Golden Palm',
      hex: 'AA8805',
      rgb: {
        r: 170,
        g: 136,
        b: 5,
      },
      lab: {
        l: 58.87,
        a: 5.81,
        b: 62.48,
      },
      cmyk: null,
    },
    {
      code: '16-0847 TCX',
      name: 'Olive Oil',
      hex: 'A98B2D',
      rgb: {
        r: 169,
        g: 139,
        b: 45,
      },
      lab: {
        l: 59.63,
        a: 4.8,
        b: 52.24,
      },
      cmyk: null,
    },
    {
      code: '18-0835 TCX',
      name: 'Dried Tobacco',
      hex: '997B38',
      rgb: {
        r: 153,
        g: 123,
        b: 56,
      },
      lab: {
        l: 53.76,
        a: 6.81,
        b: 40.51,
      },
      cmyk: null,
    },
    {
      code: '18-0832 TCX',
      name: 'Plantation',
      hex: '7A6332',
      rgb: {
        r: 122,
        g: 99,
        b: 50,
      },
      lab: {
        l: 43.78,
        a: 5.27,
        b: 31.69,
      },
      cmyk: null,
    },
    {
      code: '13-0725 TCX',
      name: 'Raffia',
      hex: 'DAC483',
      rgb: {
        r: 218,
        g: 196,
        b: 131,
      },
      lab: {
        l: 79.99,
        a: 2.39,
        b: 35.33,
      },
      cmyk: null,
    },
    {
      code: '15-0743 TCX',
      name: 'Oil Yellow',
      hex: 'C4A647',
      rgb: {
        r: 196,
        g: 166,
        b: 71,
      },
      lab: {
        l: 69.57,
        a: 3.85,
        b: 52.05,
      },
      cmyk: null,
    },
    {
      code: '14-0740 TCX',
      name: 'Bamboo',
      hex: 'D2B04C',
      rgb: {
        r: 210,
        g: 176,
        b: 76,
      },
      lab: {
        l: 73.68,
        a: 5.49,
        b: 54.46,
      },
      cmyk: null,
    },
    {
      code: '15-0850 TCX',
      name: 'Ceylon Yellow',
      hex: 'D4AE40',
      rgb: {
        r: 212,
        g: 174,
        b: 64,
      },
      lab: {
        l: 73.26,
        a: 6.27,
        b: 59.49,
      },
      cmyk: null,
    },
    {
      code: '15-0751 TCX',
      name: 'Lemon Curry',
      hex: 'CDA323',
      rgb: {
        r: 205,
        g: 163,
        b: 35,
      },
      lab: {
        l: 69.74,
        a: 7.95,
        b: 65.75,
      },
      cmyk: null,
    },
    {
      code: '16-0946 TCX',
      name: 'Honey',
      hex: 'BA9238',
      rgb: {
        r: 186,
        g: 146,
        b: 56,
      },
      lab: {
        l: 63.34,
        a: 9.33,
        b: 51.84,
      },
      cmyk: null,
    },
    {
      code: '18-0840 TCX',
      name: 'Tapenade',
      hex: '805D24',
      rgb: {
        r: 128,
        g: 93,
        b: 36,
      },
      lab: {
        l: 42.72,
        a: 10.73,
        b: 37.81,
      },
      cmyk: null,
    },
    {
      code: '14-0837 TCX',
      name: 'Misted Yellow',
      hex: 'DAB965',
      rgb: {
        r: 218,
        g: 185,
        b: 101,
      },
      lab: {
        l: 77.11,
        a: 5.17,
        b: 46.88,
      },
      cmyk: null,
    },
    {
      code: '14-0952 TCX',
      name: 'Spicy Mustard',
      hex: 'D8AE48',
      rgb: {
        r: 216,
        g: 174,
        b: 72,
      },
      lab: {
        l: 73.9,
        a: 8.52,
        b: 56.7,
      },
      cmyk: null,
    },
    {
      code: '16-0954 TCX',
      name: 'Arrowwood',
      hex: 'BC8D1F',
      rgb: {
        r: 188,
        g: 141,
        b: 31,
      },
      lab: {
        l: 62.27,
        a: 11.8,
        b: 60.3,
      },
      cmyk: null,
    },
    {
      code: '16-0952 TCX',
      name: 'Nugget Gold',
      hex: 'C89721',
      rgb: {
        r: 200,
        g: 151,
        b: 33,
      },
      lab: {
        l: 66.22,
        a: 12.06,
        b: 63.76,
      },
      cmyk: null,
    },
    {
      code: '15-0948 TCX',
      name: 'Golden Spice',
      hex: 'C6973F',
      rgb: {
        r: 198,
        g: 151,
        b: 63,
      },
      lab: {
        l: 66.25,
        a: 12.32,
        b: 52.42,
      },
      cmyk: null,
    },
    {
      code: '16-0953 TCX',
      name: 'Tawny Olive',
      hex: 'C4962C',
      rgb: {
        r: 196,
        g: 150,
        b: 44,
      },
      lab: {
        l: 65.5,
        a: 11.52,
        b: 59.16,
      },
      cmyk: null,
    },
    {
      code: '17-0949 TCX',
      name: 'Chai Tea',
      hex: 'B28330',
      rgb: {
        r: 178,
        g: 131,
        b: 48,
      },
      lab: {
        l: 58.77,
        a: 13.14,
        b: 50.57,
      },
      cmyk: null,
    },
    {
      code: '14-1031 TCX',
      name: 'Rattan',
      hex: 'D1B272',
      rgb: {
        r: 209,
        g: 178,
        b: 114,
      },
      lab: {
        l: 74.37,
        a: 6.12,
        b: 37.18,
      },
      cmyk: null,
    },
    {
      code: '14-1036 TCX',
      name: 'Ochre',
      hex: 'D6AF66',
      rgb: {
        r: 214,
        g: 175,
        b: 102,
      },
      lab: {
        l: 74.13,
        a: 9.02,
        b: 42.67,
      },
      cmyk: null,
    },
    {
      code: '15-1132 TCX',
      name: 'Fall Leaf',
      hex: 'C9A86A',
      rgb: {
        r: 201,
        g: 168,
        b: 106,
      },
      lab: {
        l: 71.18,
        a: 6.93,
        b: 37.12,
      },
      cmyk: null,
    },
    {
      code: '15-0942 TCX',
      name: 'Sauterne',
      hex: 'C5A253',
      rgb: {
        r: 197,
        g: 162,
        b: 83,
      },
      lab: {
        l: 68.97,
        a: 7.12,
        b: 45.7,
      },
      cmyk: null,
    },
    {
      code: '16-0945 TCX',
      name: 'Tinsel',
      hex: 'C3964D',
      rgb: {
        r: 195,
        g: 150,
        b: 77,
      },
      lab: {
        l: 65.68,
        a: 12.15,
        b: 44.68,
      },
      cmyk: null,
    },
    {
      code: '16-0948 TCX',
      name: 'Harvest Gold',
      hex: 'B68A3A',
      rgb: {
        r: 182,
        g: 138,
        b: 58,
      },
      lab: {
        l: 60.97,
        a: 11.71,
        b: 48.57,
      },
      cmyk: null,
    },
    {
      code: '18-0939 TCX',
      name: 'Cumin',
      hex: '927240',
      rgb: {
        r: 146,
        g: 114,
        b: 64,
      },
      lab: {
        l: 50.56,
        a: 8.98,
        b: 32.85,
      },
      cmyk: null,
    },
    {
      code: '15-1142 TCX',
      name: 'Honey Gold',
      hex: 'D1A054',
      rgb: {
        r: 209,
        g: 160,
        b: 84,
      },
      lab: {
        l: 69.7,
        a: 13.89,
        b: 46.6,
      },
      cmyk: null,
    },
    {
      code: '16-0947 TCX',
      name: 'Bright Gold',
      hex: 'CF9F52',
      rgb: {
        r: 207,
        g: 159,
        b: 82,
      },
      lab: {
        l: 69.27,
        a: 12.89,
        b: 47.03,
      },
      cmyk: null,
    },
    {
      code: '16-0950 TCX',
      name: 'Narcissus',
      hex: 'C39449',
      rgb: {
        r: 195,
        g: 148,
        b: 73,
      },
      lab: {
        l: 65.17,
        a: 13.3,
        b: 46.42,
      },
      cmyk: null,
    },
    {
      code: '16-1139 TCX',
      name: 'Amber Gold',
      hex: 'C19552',
      rgb: {
        r: 193,
        g: 149,
        b: 82,
      },
      lab: {
        l: 65.16,
        a: 12.36,
        b: 41.77,
      },
      cmyk: null,
    },
    {
      code: '15-1046 TCX',
      name: 'Mineral Yellow',
      hex: 'D39C43',
      rgb: {
        r: 211,
        g: 156,
        b: 67,
      },
      lab: {
        l: 68.86,
        a: 15.35,
        b: 53.71,
      },
      cmyk: null,
    },
    {
      code: '15-0960 TCX',
      name: 'Mango Mojito',
      hex: 'D69D30',
      rgb: {
        r: 214,
        g: 157,
        b: 48,
      },
      lab: {
        l: 69.32,
        a: 15.59,
        b: 62.17,
      },
      cmyk: null,
    },
    {
      code: '15-0953 TCX',
      name: 'Golden Yellow',
      hex: 'CB8E16',
      rgb: {
        r: 203,
        g: 142,
        b: 22,
      },
      lab: {
        l: 64.43,
        a: 17.79,
        b: 65.61,
      },
      cmyk: null,
    },
    {
      code: '16-0940 TCX',
      name: 'Taffy',
      hex: 'C39B6A',
      rgb: {
        r: 195,
        g: 155,
        b: 106,
      },
      lab: {
        l: 67.18,
        a: 11.6,
        b: 32.05,
      },
      cmyk: null,
    },
    {
      code: '17-1047 TCX',
      name: 'Honey Mustard',
      hex: 'B68F52',
      rgb: {
        r: 182,
        g: 143,
        b: 82,
      },
      lab: {
        l: 62.35,
        a: 11.26,
        b: 37.88,
      },
      cmyk: null,
    },
    {
      code: '17-1129 TCX',
      name: 'Wood Thrush',
      hex: 'A47D43',
      rgb: {
        r: 164,
        g: 125,
        b: 67,
      },
      lab: {
        l: 55.72,
        a: 11.75,
        b: 37.34,
      },
      cmyk: null,
    },
    {
      code: '18-0940 TCX',
      name: 'Golden Brown',
      hex: '91672F',
      rgb: {
        r: 145,
        g: 103,
        b: 47,
      },
      lab: {
        l: 47.5,
        a: 14.41,
        b: 38.1,
      },
      cmyk: null,
    },
    {
      code: '18-0937 TCX',
      name: 'Bronze Brown',
      hex: '825E2F',
      rgb: {
        r: 130,
        g: 94,
        b: 47,
      },
      lab: {
        l: 43.4,
        a: 12.04,
        b: 32.89,
      },
      cmyk: null,
    },
    {
      code: '18-0933 TCX',
      name: 'Rubber',
      hex: '815B37',
      rgb: {
        r: 129,
        g: 91,
        b: 55,
      },
      lab: {
        l: 42.61,
        a: 13.74,
        b: 27.62,
      },
      cmyk: null,
    },
    {
      code: '18-1048 TCX',
      name: "Monk's Robe",
      hex: '704822',
      rgb: {
        r: 112,
        g: 72,
        b: 34,
      },
      lab: {
        l: 35.08,
        a: 14.93,
        b: 30.08,
      },
      cmyk: null,
    },
    {
      code: '17-1036 TCX',
      name: 'Bistre',
      hex: '98754A',
      rgb: {
        r: 152,
        g: 117,
        b: 74,
      },
      lab: {
        l: 52.3,
        a: 10.75,
        b: 29.57,
      },
      cmyk: null,
    },
    {
      code: '18-1022 TCX',
      name: 'Ermine',
      hex: '836B4F',
      rgb: {
        r: 131,
        g: 107,
        b: 79,
      },
      lab: {
        l: 47.19,
        a: 7.51,
        b: 19.56,
      },
      cmyk: null,
    },
    {
      code: '18-1018 TCX',
      name: 'Otter',
      hex: '7F674F',
      rgb: {
        r: 127,
        g: 103,
        b: 79,
      },
      lab: {
        l: 45.7,
        a: 7.91,
        b: 17.72,
      },
      cmyk: null,
    },
    {
      code: '18-0930 TCX',
      name: 'Coffee Liqueúr',
      hex: '6A513B',
      rgb: {
        r: 106,
        g: 81,
        b: 59,
      },
      lab: {
        l: 36.73,
        a: 9.17,
        b: 17.43,
      },
      cmyk: null,
    },
    {
      code: '18-0928 TCX',
      name: 'Sepia',
      hex: '6C543E',
      rgb: {
        r: 108,
        g: 84,
        b: 62,
      },
      lab: {
        l: 38,
        a: 8.33,
        b: 16.97,
      },
      cmyk: null,
    },
    {
      code: '19-0815 TCX',
      name: 'Desert Palm',
      hex: '5A4632',
      rgb: {
        r: 90,
        g: 70,
        b: 50,
      },
      lab: {
        l: 31.56,
        a: 7.25,
        b: 15.25,
      },
      cmyk: null,
    },
    {
      code: '19-1020 TCX',
      name: 'Dark Earth',
      hex: '5C4939',
      rgb: {
        r: 92,
        g: 73,
        b: 57,
      },
      lab: {
        l: 32.83,
        a: 7.43,
        b: 12.99,
      },
      cmyk: null,
    },
    {
      code: '16-1334 TCX',
      name: 'Tan',
      hex: 'B69574',
      rgb: {
        r: 182,
        g: 149,
        b: 116,
      },
      lab: {
        l: 64.31,
        a: 10.76,
        b: 22.33,
      },
      cmyk: null,
    },
    {
      code: '17-1325 TCX',
      name: 'Cartouche',
      hex: 'A88968',
      rgb: {
        r: 168,
        g: 137,
        b: 104,
      },
      lab: {
        l: 59.57,
        a: 9.18,
        b: 22.51,
      },
      cmyk: null,
    },
    {
      code: '17-1038 TCX',
      name: "Tiger's Eye",
      hex: '977C61',
      rgb: {
        r: 151,
        g: 124,
        b: 97,
      },
      lab: {
        l: 54.2,
        a: 8.4,
        b: 18.85,
      },
      cmyk: null,
    },
    {
      code: '18-1014 TCX',
      name: 'Malt Ball',
      hex: '816852',
      rgb: {
        r: 129,
        g: 104,
        b: 82,
      },
      lab: {
        l: 46.16,
        a: 8.41,
        b: 16.51,
      },
      cmyk: null,
    },
    {
      code: '18-1020 TCX',
      name: 'Cocoa Crème',
      hex: '876D58',
      rgb: {
        r: 135,
        g: 109,
        b: 88,
      },
      lab: {
        l: 48.41,
        a: 8.97,
        b: 15.91,
      },
      cmyk: null,
    },
    {
      code: '18-1029 TCX',
      name: 'Toasted Coconut',
      hex: '8B6A4F',
      rgb: {
        r: 139,
        g: 106,
        b: 79,
      },
      lab: {
        l: 47.94,
        a: 11.32,
        b: 21.1,
      },
      cmyk: null,
    },
    {
      code: '18-1025 TCX',
      name: 'Foxtrot',
      hex: '8A6749',
      rgb: {
        r: 138,
        g: 103,
        b: 73,
      },
      lab: {
        l: 47.05,
        a: 12.46,
        b: 22.95,
      },
      cmyk: null,
    },
    {
      code: '14-1038 TCX',
      name: 'New Wheat',
      hex: 'D7B57F',
      rgb: {
        r: 215,
        g: 181,
        b: 127,
      },
      lab: {
        l: 75.95,
        a: 8.51,
        b: 32.31,
      },
      cmyk: null,
    },
    {
      code: '16-0928 TCX',
      name: 'Curry',
      hex: 'BE9E6F',
      rgb: {
        r: 190,
        g: 158,
        b: 111,
      },
      lab: {
        l: 67.42,
        a: 8.13,
        b: 29.58,
      },
      cmyk: null,
    },
    {
      code: '15-1040 TCX',
      name: 'Iced Coffee',
      hex: 'B18F6A',
      rgb: {
        r: 177,
        g: 143,
        b: 106,
      },
      lab: {
        l: 62.28,
        a: 10.42,
        b: 24.98,
      },
      cmyk: null,
    },
    {
      code: '17-1045 TCX',
      name: 'Apple Cinnamon',
      hex: 'B0885B',
      rgb: {
        r: 176,
        g: 136,
        b: 91,
      },
      lab: {
        l: 60.23,
        a: 12.44,
        b: 31.09,
      },
      cmyk: null,
    },
    {
      code: '17-1128 TCX',
      name: 'Bone Brown',
      hex: '9D7446',
      rgb: {
        r: 157,
        g: 116,
        b: 70,
      },
      lab: {
        l: 52.57,
        a: 13.58,
        b: 32.12,
      },
      cmyk: null,
    },
    {
      code: '17-1125 TCX',
      name: 'Dijon',
      hex: '97754C',
      rgb: {
        r: 151,
        g: 117,
        b: 76,
      },
      lab: {
        l: 52.32,
        a: 10.54,
        b: 28.44,
      },
      cmyk: null,
    },
    {
      code: '17-0942 TCX',
      name: 'Medal Bronze',
      hex: '977547',
      rgb: {
        r: 151,
        g: 117,
        b: 71,
      },
      lab: {
        l: 52.06,
        a: 10.96,
        b: 30.88,
      },
      cmyk: null,
    },
    {
      code: '13-1031 TCX',
      name: 'Apricot Sherbet',
      hex: 'FACD9E',
      rgb: {
        r: 250,
        g: 205,
        b: 158,
      },
      lab: {
        l: 85.78,
        a: 12.46,
        b: 30.14,
      },
      cmyk: null,
    },
    {
      code: '13-1030 TCX',
      name: 'Sunburst',
      hex: 'F6C289',
      rgb: {
        r: 246,
        g: 194,
        b: 137,
      },
      lab: {
        l: 82.51,
        a: 14.76,
        b: 36.66,
      },
      cmyk: null,
    },
    {
      code: '16-1144 TCX',
      name: 'Oak Buff',
      hex: 'CF9C63',
      rgb: {
        r: 207,
        g: 156,
        b: 99,
      },
      lab: {
        l: 68.82,
        a: 15.67,
        b: 38.11,
      },
      cmyk: null,
    },
    {
      code: '16-1143 TCX',
      name: 'Honey Yellow',
      hex: 'CA9456',
      rgb: {
        r: 202,
        g: 148,
        b: 86,
      },
      lab: {
        l: 66.18,
        a: 16.92,
        b: 41.17,
      },
      cmyk: null,
    },
    {
      code: '17-1040 TCX',
      name: 'Spruce Yellow',
      hex: 'BE8A4A',
      rgb: {
        r: 190,
        g: 138,
        b: 74,
      },
      lab: {
        l: 62.04,
        a: 17.16,
        b: 41.92,
      },
      cmyk: null,
    },
    {
      code: '17-1048 TCX',
      name: 'Inca Gold',
      hex: 'BB7A2C',
      rgb: {
        r: 187,
        g: 122,
        b: 44,
      },
      lab: {
        l: 57.65,
        a: 22.41,
        b: 51.81,
      },
      cmyk: null,
    },
    {
      code: '18-0935 TCX',
      name: 'Buckthorn Brown',
      hex: 'A76F1F',
      rgb: {
        r: 167,
        g: 111,
        b: 31,
      },
      lab: {
        l: 52.27,
        a: 18.78,
        b: 51.14,
      },
      cmyk: null,
    },
    {
      code: '13-1027 TCX',
      name: 'Apricot Cream',
      hex: 'F1BD89',
      rgb: {
        r: 241,
        g: 189,
        b: 137,
      },
      lab: {
        l: 80.77,
        a: 15.07,
        b: 34.35,
      },
      cmyk: null,
    },
    {
      code: '14-1128 TCX',
      name: 'Buff Orange',
      hex: 'FFBB7C',
      rgb: {
        r: 255,
        g: 187,
        b: 124,
      },
      lab: {
        l: 81.74,
        a: 20.91,
        b: 42.82,
      },
      cmyk: null,
    },
    {
      code: '15-1145 TCX',
      name: 'Chamois',
      hex: 'F7B26A',
      rgb: {
        r: 247,
        g: 178,
        b: 106,
      },
      lab: {
        l: 78.58,
        a: 20.99,
        b: 47.54,
      },
      cmyk: null,
    },
    {
      code: '14-1051 TCX',
      name: 'Warm Apricot',
      hex: 'FFB765',
      rgb: {
        r: 255,
        g: 183,
        b: 101,
      },
      lab: {
        l: 80.92,
        a: 22.52,
        b: 53.09,
      },
      cmyk: null,
    },
    {
      code: '16-1142 TCX',
      name: 'Golden Nugget',
      hex: 'DB9B59',
      rgb: {
        r: 219,
        g: 155,
        b: 89,
      },
      lab: {
        l: 69.92,
        a: 20.87,
        b: 44.56,
      },
      cmyk: null,
    },
    {
      code: '16-1342 TCX',
      name: 'Buckskin',
      hex: 'D18E54',
      rgb: {
        r: 209,
        g: 142,
        b: 84,
      },
      lab: {
        l: 65.39,
        a: 23.12,
        b: 41.73,
      },
      cmyk: null,
    },
    {
      code: '16-1148 TCX',
      name: 'Nugget',
      hex: 'CF8948',
      rgb: {
        r: 207,
        g: 137,
        b: 72,
      },
      lab: {
        l: 63.88,
        a: 24.6,
        b: 46.16,
      },
      cmyk: null,
    },
    {
      code: '16-1149 TCX',
      name: 'Desert Sun',
      hex: 'C87629',
      rgb: {
        r: 200,
        g: 118,
        b: 41,
      },
      lab: {
        l: 58.52,
        a: 30.05,
        b: 54.84,
      },
      cmyk: null,
    },
    {
      code: '17-1046 TCX',
      name: 'Golden Oak',
      hex: 'BE752C',
      rgb: {
        r: 190,
        g: 117,
        b: 44,
      },
      lab: {
        l: 57.1,
        a: 26.37,
        b: 51.18,
      },
      cmyk: null,
    },
    {
      code: '18-1160 TCX',
      name: 'Sudan Brown',
      hex: 'AC6C29',
      rgb: {
        r: 172,
        g: 108,
        b: 41,
      },
      lab: {
        l: 52.29,
        a: 23.56,
        b: 47.14,
      },
      cmyk: null,
    },
    {
      code: '18-1050 TCX',
      name: 'Honey Ginger',
      hex: 'A96318',
      rgb: {
        r: 169,
        g: 99,
        b: 24,
      },
      lab: {
        l: 49.7,
        a: 26.15,
        b: 51.77,
      },
      cmyk: null,
    },
    {
      code: '18-1049 TCX',
      name: 'Thai Curry',
      hex: 'AC681A',
      rgb: {
        r: 172,
        g: 104,
        b: 26,
      },
      lab: {
        l: 51.4,
        a: 24.8,
        b: 52.57,
      },
      cmyk: null,
    },
    {
      code: '18-1163 TCX',
      name: 'Pumpkin Spice',
      hex: 'A05C17',
      rgb: {
        r: 160,
        g: 92,
        b: 23,
      },
      lab: {
        l: 46.94,
        a: 25.78,
        b: 49.3,
      },
      cmyk: null,
    },
    {
      code: '18-0950 TCX',
      name: 'Cathay Spice',
      hex: '99642C',
      rgb: {
        r: 153,
        g: 100,
        b: 44,
      },
      lab: {
        l: 47.85,
        a: 19.42,
        b: 40.34,
      },
      cmyk: null,
    },
    {
      code: '13-0945 TCX',
      name: 'Pale Marigold',
      hex: 'FFC66E',
      rgb: {
        r: 255,
        g: 198,
        b: 110,
      },
      lab: {
        l: 84.98,
        a: 17.81,
        b: 53.6,
      },
      cmyk: null,
    },
    {
      code: '14-1050 TCX',
      name: 'Marigold',
      hex: 'FDAC53',
      rgb: {
        r: 253,
        g: 172,
        b: 83,
      },
      lab: {
        l: 77.8,
        a: 26.09,
        b: 57.66,
      },
      cmyk: null,
    },
    {
      code: '15-1058 TCX',
      name: 'Radiant Yellow',
      hex: 'FC9E21',
      rgb: {
        r: 252,
        g: 158,
        b: 33,
      },
      lab: {
        l: 74.43,
        a: 31.04,
        b: 73.5,
      },
      cmyk: null,
    },
    {
      code: '15-1147 TCX',
      name: 'Butterscotch',
      hex: 'E19640',
      rgb: {
        r: 225,
        g: 150,
        b: 64,
      },
      lab: {
        l: 69.05,
        a: 24.97,
        b: 55.71,
      },
      cmyk: null,
    },
    {
      code: '15-1054 TCX',
      name: 'Cadmium Yellow',
      hex: 'EE9626',
      rgb: {
        r: 238,
        g: 150,
        b: 38,
      },
      lab: {
        l: 70.83,
        a: 29.88,
        b: 67.92,
      },
      cmyk: null,
    },
    {
      code: '16-1140 TCX',
      name: 'Yam',
      hex: 'D0893F',
      rgb: {
        r: 208,
        g: 137,
        b: 63,
      },
      lab: {
        l: 64.11,
        a: 23.76,
        b: 50.66,
      },
      cmyk: null,
    },
    {
      code: '16-1150 TCX',
      name: 'Topaz',
      hex: 'D08344',
      rgb: {
        r: 208,
        g: 131,
        b: 68,
      },
      lab: {
        l: 62.69,
        a: 27.53,
        b: 46.78,
      },
      cmyk: null,
    },
    {
      code: '13-0942 TCX',
      name: 'Amber Yellow',
      hex: 'FAB75A',
      rgb: {
        r: 250,
        g: 183,
        b: 90,
      },
      lab: {
        l: 80.01,
        a: 19.49,
        b: 56.62,
      },
      cmyk: null,
    },
    {
      code: '13-0947 TCX',
      name: 'Banana',
      hex: 'FCB953',
      rgb: {
        r: 252,
        g: 185,
        b: 83,
      },
      lab: {
        l: 80.44,
        a: 19.19,
        b: 60.24,
      },
      cmyk: null,
    },
    {
      code: '15-0955 TCX',
      name: 'Old Gold',
      hex: 'ECA825',
      rgb: {
        r: 236,
        g: 168,
        b: 37,
      },
      lab: {
        l: 74.56,
        a: 20.43,
        b: 70.41,
      },
      cmyk: null,
    },
    {
      code: '14-0955 TCX',
      name: 'Citrus',
      hex: 'F9AC2F',
      rgb: {
        r: 249,
        g: 172,
        b: 47,
      },
      lab: {
        l: 77.01,
        a: 23.41,
        b: 70.54,
      },
      cmyk: null,
    },
    {
      code: '15-1062 TCX',
      name: 'Gold Fusion',
      hex: 'FFB000',
      rgb: {
        r: 255,
        g: 176,
        b: 0,
      },
      lab: {
        l: 79.29,
        a: 25.26,
        b: 86.63,
      },
      cmyk: null,
    },
    {
      code: '14-1064 TCX',
      name: 'Saffron',
      hex: 'FFA500',
      rgb: {
        r: 255,
        g: 165,
        b: 0,
      },
      lab: {
        l: 76.86,
        a: 30.34,
        b: 84.95,
      },
      cmyk: null,
    },
    {
      code: '14-1159 TCX',
      name: 'Zinnia',
      hex: 'FFA00D',
      rgb: {
        r: 255,
        g: 160,
        b: 13,
      },
      lab: {
        l: 76.94,
        a: 36.24,
        b: 80.18,
      },
      cmyk: null,
    },
    {
      code: '14-0936 TCX',
      name: 'Sahara Sun',
      hex: 'DFC08A',
      rgb: {
        r: 223,
        g: 192,
        b: 138,
      },
      lab: {
        l: 79.64,
        a: 6.98,
        b: 31.5,
      },
      cmyk: null,
    },
    {
      code: '13-1025 TCX',
      name: 'Impala',
      hex: 'F8CE97',
      rgb: {
        r: 248,
        g: 206,
        b: 151,
      },
      lab: {
        l: 85.73,
        a: 10.86,
        b: 33.46,
      },
      cmyk: null,
    },
    {
      code: '13-0935 TCX',
      name: 'Flax',
      hex: 'FFC87D',
      rgb: {
        r: 255,
        g: 200,
        b: 125,
      },
      lab: {
        l: 84.77,
        a: 15.09,
        b: 45.76,
      },
      cmyk: null,
    },
    {
      code: '14-1045 TCX',
      name: 'Amber',
      hex: 'EFAD55',
      rgb: {
        r: 239,
        g: 173,
        b: 85,
      },
      lab: {
        l: 76.31,
        a: 19.5,
        b: 54.64,
      },
      cmyk: null,
    },
    {
      code: '15-1049 TCX',
      name: "Artisan's Gold",
      hex: 'F2AB46',
      rgb: {
        r: 242,
        g: 171,
        b: 70,
      },
      lab: {
        l: 76.1,
        a: 20.84,
        b: 61.22,
      },
      cmyk: null,
    },
    {
      code: '14-0941 TCX',
      name: 'Beeswax',
      hex: 'EBA851',
      rgb: {
        r: 235,
        g: 168,
        b: 81,
      },
      lab: {
        l: 74.65,
        a: 20.38,
        b: 54.28,
      },
      cmyk: null,
    },
    {
      code: '15-1045 TCX',
      name: 'Autumn Blaze',
      hex: 'D9932F',
      rgb: {
        r: 217,
        g: 147,
        b: 47,
      },
      lab: {
        l: 67.39,
        a: 22.36,
        b: 60.95,
      },
      cmyk: null,
    },
    {
      code: '13-0939 TCX',
      name: 'Golden Cream',
      hex: 'F7BF68',
      rgb: {
        r: 247,
        g: 191,
        b: 104,
      },
      lab: {
        l: 81.65,
        a: 14.31,
        b: 51.74,
      },
      cmyk: null,
    },
    {
      code: '13-0946 TCX',
      name: 'Jurassic Gold',
      hex: 'E7AA56',
      rgb: {
        r: 231,
        g: 170,
        b: 86,
      },
      lab: {
        l: 74.8,
        a: 17.82,
        b: 51.95,
      },
      cmyk: null,
    },
    {
      code: '14-1052 TCX',
      name: 'Kumquat',
      hex: 'FCAB4D',
      rgb: {
        r: 252,
        g: 171,
        b: 77,
      },
      lab: {
        l: 77.27,
        a: 26,
        b: 59.87,
      },
      cmyk: null,
    },
    {
      code: '16-1054 TCX',
      name: 'Sunflower',
      hex: 'D39237',
      rgb: {
        r: 211,
        g: 146,
        b: 55,
      },
      lab: {
        l: 66.36,
        a: 20.59,
        b: 56.32,
      },
      cmyk: null,
    },
    {
      code: '15-1050 TCX',
      name: 'Golden Glow',
      hex: 'D99938',
      rgb: {
        r: 217,
        g: 153,
        b: 56,
      },
      lab: {
        l: 68.88,
        a: 19.24,
        b: 58.64,
      },
      cmyk: null,
    },
    {
      code: '14-1041 TCX',
      name: 'Golden Apricot',
      hex: 'DDA758',
      rgb: {
        r: 221,
        g: 167,
        b: 88,
      },
      lab: {
        l: 72.8,
        a: 15.17,
        b: 48.45,
      },
      cmyk: null,
    },
    {
      code: '15-1051 TCX',
      name: 'Golden Orange',
      hex: 'D7942E',
      rgb: {
        r: 215,
        g: 148,
        b: 46,
      },
      lab: {
        l: 67.43,
        a: 20.75,
        b: 61.21,
      },
      cmyk: null,
    },
    {
      code: '13-0941 TCX',
      name: 'Banana Cream',
      hex: 'FFCF73',
      rgb: {
        r: 255,
        g: 207,
        b: 115,
      },
      lab: {
        l: 86.73,
        a: 12.04,
        b: 52.92,
      },
      cmyk: null,
    },
    {
      code: '13-0932 TCX',
      name: 'Cornsilk',
      hex: 'EDC373',
      rgb: {
        r: 237,
        g: 195,
        b: 115,
      },
      lab: {
        l: 81.45,
        a: 9.15,
        b: 45.93,
      },
      cmyk: null,
    },
    {
      code: '13-0940 TCX',
      name: 'Sunset Gold',
      hex: 'F7C46C',
      rgb: {
        r: 247,
        g: 196,
        b: 108,
      },
      lab: {
        l: 82.68,
        a: 12.21,
        b: 51.32,
      },
      cmyk: null,
    },
    {
      code: '14-0851 TCX',
      name: 'Samoan Sun',
      hex: 'FBC85F',
      rgb: {
        r: 251,
        g: 200,
        b: 95,
      },
      lab: {
        l: 83.97,
        a: 11.74,
        b: 57.73,
      },
      cmyk: null,
    },
    {
      code: '14-0847 TCX',
      name: 'Buff Yellow',
      hex: 'F1BF70',
      rgb: {
        r: 241,
        g: 191,
        b: 112,
      },
      lab: {
        l: 80.95,
        a: 12.85,
        b: 47,
      },
      cmyk: null,
    },
    {
      code: '14-0850 TCX',
      name: 'Daffodil',
      hex: 'FDC04E',
      rgb: {
        r: 253,
        g: 192,
        b: 78,
      },
      lab: {
        l: 82.26,
        a: 15.81,
        b: 63.88,
      },
      cmyk: null,
    },
    {
      code: '14-0846 TCX',
      name: 'Yolk Yellow',
      hex: 'E2B051',
      rgb: {
        r: 226,
        g: 176,
        b: 81,
      },
      lab: {
        l: 75.48,
        a: 11.99,
        b: 54.99,
      },
      cmyk: null,
    },
    {
      code: '12-0826 TCX',
      name: 'Golden Haze',
      hex: 'FBD897',
      rgb: {
        r: 251,
        g: 216,
        b: 151,
      },
      lab: {
        l: 88.54,
        a: 6.28,
        b: 37.35,
      },
      cmyk: null,
    },
    {
      code: '12-0758 TCX',
      name: 'Yarrow',
      hex: 'FACE6D',
      rgb: {
        r: 250,
        g: 206,
        b: 109,
      },
      lab: {
        l: 85.59,
        a: 9.18,
        b: 53.46,
      },
      cmyk: null,
    },
    {
      code: '14-0848 TCX',
      name: 'Mimosa',
      hex: 'F0C05A',
      rgb: {
        r: 240,
        g: 192,
        b: 90,
      },
      lab: {
        l: 81,
        a: 9.9,
        b: 57.42,
      },
      cmyk: null,
    },
    {
      code: '13-0859 TCX',
      name: 'Lemon Chrome',
      hex: 'FFC300',
      rgb: {
        r: 255,
        g: 195,
        b: 0,
      },
      lab: {
        l: 84.16,
        a: 17.61,
        b: 84.93,
      },
      cmyk: null,
    },
    {
      code: '14-0957 TCX',
      name: 'Spectra Yellow',
      hex: 'F7B718',
      rgb: {
        r: 247,
        g: 183,
        b: 24,
      },
      lab: {
        l: 79.34,
        a: 16.39,
        b: 77.42,
      },
      cmyk: null,
    },
    {
      code: '13-0759 TCX',
      name: 'Solar Power',
      hex: 'F4BF3A',
      rgb: {
        r: 244,
        g: 191,
        b: 58,
      },
      lab: {
        l: 80.88,
        a: 12.21,
        b: 69.44,
      },
      cmyk: null,
    },
    {
      code: '14-0951 TCX',
      name: 'Golden Rod',
      hex: 'E2A829',
      rgb: {
        r: 226,
        g: 168,
        b: 41,
      },
      lab: {
        l: 73.3,
        a: 14.95,
        b: 68.44,
      },
      cmyk: null,
    },
    {
      code: '13-0840 TCX',
      name: 'Snapdragon',
      hex: 'FED877',
      rgb: {
        r: 254,
        g: 216,
        b: 119,
      },
      lab: {
        l: 88.28,
        a: 6.37,
        b: 52.22,
      },
      cmyk: null,
    },
    {
      code: '13-0851 TCX',
      name: 'Minion YellowTM',
      hex: 'FFD55E',
      rgb: {
        r: 255,
        g: 213,
        b: 94,
      },
      lab: {
        l: 87.91,
        a: 8.25,
        b: 63.19,
      },
      cmyk: null,
    },
    {
      code: '13-0850 TCX',
      name: 'Aspen Gold',
      hex: 'FFD662',
      rgb: {
        r: 255,
        g: 214,
        b: 98,
      },
      lab: {
        l: 88.11,
        a: 7.3,
        b: 61.66,
      },
      cmyk: null,
    },
    {
      code: '13-0849 TCX',
      name: 'Habañero Gold',
      hex: 'FFD551',
      rgb: {
        r: 255,
        g: 213,
        b: 81,
      },
      lab: {
        l: 87.63,
        a: 7.55,
        b: 68.04,
      },
      cmyk: null,
    },
    {
      code: '13-0758 TCX',
      name: 'Dandelion',
      hex: 'FFD02E',
      rgb: {
        r: 255,
        g: 208,
        b: 46,
      },
      lab: {
        l: 86.73,
        a: 9.68,
        b: 79.08,
      },
      cmyk: null,
    },
    {
      code: '13-0752 TCX',
      name: 'Lemon',
      hex: 'F3BF09',
      rgb: {
        r: 243,
        g: 191,
        b: 9,
      },
      lab: {
        l: 80.65,
        a: 10.86,
        b: 80.06,
      },
      cmyk: null,
    },
    {
      code: '14-0852 TCX',
      name: 'Freesia',
      hex: 'F3C12C',
      rgb: {
        r: 243,
        g: 193,
        b: 44,
      },
      lab: {
        l: 81.12,
        a: 11.03,
        b: 73.91,
      },
      cmyk: null,
    },
    {
      code: '13-0754 TCX',
      name: 'Sunstruck',
      hex: 'F6CD4C',
      rgb: {
        r: 246,
        g: 205,
        b: 76,
      },
      lab: {
        l: 84.58,
        a: 6.21,
        b: 66.51,
      },
      cmyk: null,
    },
    {
      code: '14-0849 TCX',
      name: 'Beach Ball',
      hex: 'F3C65F',
      rgb: {
        r: 243,
        g: 198,
        b: 95,
      },
      lab: {
        l: 82.69,
        a: 8.85,
        b: 56.83,
      },
      cmyk: null,
    },
    {
      code: '14-0751 TCX',
      name: 'Daisy Daze',
      hex: 'E9BE21',
      rgb: {
        r: 233,
        g: 190,
        b: 33,
      },
      lab: {
        l: 79.45,
        a: 6.77,
        b: 75.18,
      },
      cmyk: null,
    },
    {
      code: '13-0757 TCX',
      name: 'Calendula',
      hex: 'DFB136',
      rgb: {
        r: 223,
        g: 177,
        b: 54,
      },
      lab: {
        l: 75.15,
        a: 9.53,
        b: 65.32,
      },
      cmyk: null,
    },
    {
      code: '14-0958 TCX',
      name: 'Bumblebee',
      hex: 'E7B200',
      rgb: {
        r: 231,
        g: 178,
        b: 0,
      },
      lab: {
        l: 76.19,
        a: 11.9,
        b: 78.92,
      },
      cmyk: null,
    },
    {
      code: '15-0956 TCX',
      name: 'Daylily',
      hex: 'E9A900',
      rgb: {
        r: 233,
        g: 169,
        b: 0,
      },
      lab: {
        l: 74.34,
        a: 16.99,
        b: 79.2,
      },
      cmyk: null,
    },
    {
      code: '15-0954 TCX',
      name: 'Symphonic Sunset',
      hex: 'D79D00',
      rgb: {
        r: 215,
        g: 157,
        b: 0,
      },
      lab: {
        l: 69.46,
        a: 14.95,
        b: 74.11,
      },
      cmyk: null,
    },
    {
      code: '12-0737 TCX',
      name: 'Goldfinch',
      hex: 'F8DC6C',
      rgb: {
        r: 248,
        g: 220,
        b: 108,
      },
      lab: {
        l: 88.57,
        a: 1.64,
        b: 56.79,
      },
      cmyk: null,
    },
    {
      code: '12-0736 TCX',
      name: 'Lemon Drop',
      hex: 'FDD878',
      rgb: {
        r: 253,
        g: 216,
        b: 120,
      },
      lab: {
        l: 88.34,
        a: 5.64,
        b: 51.68,
      },
      cmyk: null,
    },
    {
      code: '13-0755 TCX',
      name: 'Primrose Yellow',
      hex: 'F6D155',
      rgb: {
        r: 246,
        g: 209,
        b: 85,
      },
      lab: {
        l: 85.48,
        a: 4.54,
        b: 63.6,
      },
      cmyk: null,
    },
    {
      code: '13-0756 TCX',
      name: 'Lemon Zest',
      hex: 'F9D857',
      rgb: {
        r: 249,
        g: 216,
        b: 87,
      },
      lab: {
        l: 87.51,
        a: 2.85,
        b: 65.24,
      },
      cmyk: null,
    },
    {
      code: '14-0754 TCX',
      name: 'Super Lemon',
      hex: 'E4BF45',
      rgb: {
        r: 228,
        g: 191,
        b: 69,
      },
      lab: {
        l: 79.34,
        a: 4.95,
        b: 63.63,
      },
      cmyk: null,
    },
    {
      code: '13-0746 TCX',
      name: 'Maize',
      hex: 'EEC843',
      rgb: {
        r: 238,
        g: 200,
        b: 67,
      },
      lab: {
        l: 82.55,
        a: 6.09,
        b: 67.36,
      },
      cmyk: null,
    },
    {
      code: '14-0755 TCX',
      name: 'Sulphur',
      hex: 'DDB614',
      rgb: {
        r: 221,
        g: 182,
        b: 20,
      },
      lab: {
        l: 76,
        a: 5.47,
        b: 74.7,
      },
      cmyk: null,
    },
    {
      code: '13-0760 TCX',
      name: 'Yellow Balloon',
      hex: 'F0CC4A',
      rgb: {
        r: 240,
        g: 204,
        b: 74,
      },
      lab: {
        l: 83.77,
        a: 3.56,
        b: 66.56,
      },
      cmyk: null,
    },
    {
      code: '13-0749 TCX',
      name: 'Slicker',
      hex: 'F1CD39',
      rgb: {
        r: 241,
        g: 205,
        b: 57,
      },
      lab: {
        l: 83.96,
        a: 3.06,
        b: 72.56,
      },
      cmyk: null,
    },
    {
      code: '13-0751 TCX',
      name: 'High Visibility',
      hex: 'F1C700',
      rgb: {
        r: 241,
        g: 199,
        b: 0,
      },
      lab: {
        l: 82.47,
        a: 5.7,
        b: 85.13,
      },
      cmyk: null,
    },
    {
      code: '14-0750 TCX',
      name: 'Passion Fruit',
      hex: 'DCC13A',
      rgb: {
        r: 220,
        g: 193,
        b: 58,
      },
      lab: {
        l: 78.85,
        a: 1.03,
        b: 66.31,
      },
      cmyk: null,
    },
    {
      code: '14-0757 TCX',
      name: 'Misted Marigold',
      hex: 'E3BD33',
      rgb: {
        r: 227,
        g: 189,
        b: 51,
      },
      lab: {
        l: 78.59,
        a: 4.81,
        b: 69.27,
      },
      cmyk: null,
    },
    {
      code: '14-0758 TCX',
      name: 'Hot Spot',
      hex: 'D7AC00',
      rgb: {
        r: 215,
        g: 172,
        b: 0,
      },
      lab: {
        l: 72.91,
        a: 7.48,
        b: 74.67,
      },
      cmyk: null,
    },
    {
      code: '16-0748 TCX',
      name: 'Gold Flake',
      hex: 'BC9312',
      rgb: {
        r: 188,
        g: 147,
        b: 18,
      },
      lab: {
        l: 63.82,
        a: 8.63,
        b: 64.69,
      },
      cmyk: null,
    },
    {
      code: '12-0738 TCX',
      name: 'Yellow Cream',
      hex: 'EFDC75',
      rgb: {
        r: 239,
        g: 220,
        b: 117,
      },
      lab: {
        l: 87.93,
        a: -2.02,
        b: 52.13,
      },
      cmyk: null,
    },
    {
      code: '13-0644 TCX',
      name: 'Golden Kiwi',
      hex: 'F4DD40',
      rgb: {
        r: 244,
        g: 221,
        b: 64,
      },
      lab: {
        l: 88.2,
        a: -2.77,
        b: 73.68,
      },
      cmyk: null,
    },
    {
      code: '12-0752 TCX',
      name: 'Buttercup',
      hex: 'FAE03C',
      rgb: {
        r: 250,
        g: 224,
        b: 60,
      },
      lab: {
        l: 89.41,
        a: -1.69,
        b: 76.22,
      },
      cmyk: null,
    },
    {
      code: '12-0643 TCX',
      name: 'Blazing Yellow',
      hex: 'FEE715',
      rgb: {
        r: 254,
        g: 231,
        b: 21,
      },
      lab: {
        l: 91.52,
        a: -4.25,
        b: 86.73,
      },
      cmyk: null,
    },
    {
      code: '14-0756 TCX',
      name: 'Empire Yellow',
      hex: 'F7D000',
      rgb: {
        r: 247,
        g: 208,
        b: 0,
      },
      lab: {
        l: 85.05,
        a: 3.15,
        b: 90.32,
      },
      cmyk: null,
    },
    {
      code: '14-0760 TCX',
      name: 'Cyber Yellow',
      hex: 'FFD500',
      rgb: {
        r: 255,
        g: 213,
        b: 0,
      },
      lab: {
        l: 87.73,
        a: 6.15,
        b: 97.79,
      },
      cmyk: null,
    },
    {
      code: '13-0858 TCX',
      name: 'Vibrant Yellow',
      hex: 'FFDA29',
      rgb: {
        r: 255,
        g: 218,
        b: 41,
      },
      lab: {
        l: 88.64,
        a: 3.45,
        b: 81.19,
      },
      cmyk: null,
    },
    {
      code: '12-0735 TCX',
      name: 'Yellowtail',
      hex: 'ECD966',
      rgb: {
        r: 236,
        g: 217,
        b: 102,
      },
      lab: {
        l: 86.72,
        a: -2.52,
        b: 57.32,
      },
      cmyk: null,
    },
    {
      code: '13-0753 TCX',
      name: 'Quince',
      hex: 'E8D11F',
      rgb: {
        r: 232,
        g: 209,
        b: 31,
      },
      lab: {
        l: 83.98,
        a: -3.2,
        b: 78.83,
      },
      cmyk: null,
    },
    {
      code: '13-0647 TCX',
      name: 'Illuminating',
      hex: 'F5DF4D',
      rgb: {
        r: 245,
        g: 223,
        b: 77,
      },
      lab: {
        l: 88.79,
        a: -2.91,
        b: 69.98,
      },
      cmyk: null,
    },
    {
      code: '14-0745 TCX',
      name: 'Incaberry',
      hex: 'E2C207',
      rgb: {
        r: 226,
        g: 194,
        b: 7,
      },
      lab: {
        l: 79.72,
        a: 1.51,
        b: 78.97,
      },
      cmyk: null,
    },
    {
      code: '15-0635 TCX',
      name: 'Snake Eye',
      hex: 'CCB932',
      rgb: {
        r: 204,
        g: 185,
        b: 50,
      },
      lab: {
        l: 75.08,
        a: -2.55,
        b: 65.31,
      },
      cmyk: null,
    },
    {
      code: '15-0647 TCX',
      name: 'Yellow Jasmine',
      hex: 'CEB00D',
      rgb: {
        r: 206,
        g: 176,
        b: 13,
      },
      lab: {
        l: 73.06,
        a: 1.89,
        b: 72.56,
      },
      cmyk: null,
    },
    {
      code: '16-0838 TCX',
      name: 'Mango Mint',
      hex: 'CDB127',
      rgb: {
        r: 205,
        g: 177,
        b: 39,
      },
      lab: {
        l: 73.33,
        a: 0.17,
        b: 67.82,
      },
      cmyk: null,
    },
    {
      code: '12-0740 TCX',
      name: 'Limelight',
      hex: 'F0E87D',
      rgb: {
        r: 240,
        g: 232,
        b: 125,
      },
      lab: {
        l: 91.06,
        a: -6.94,
        b: 51.95,
      },
      cmyk: null,
    },
    {
      code: '12-0742 TCX',
      name: 'Lemon Verbena',
      hex: 'F4E87A',
      rgb: {
        r: 244,
        g: 232,
        b: 122,
      },
      lab: {
        l: 91.25,
        a: -5.01,
        b: 53.41,
      },
      cmyk: null,
    },
    {
      code: '12-0646 TCX',
      name: 'Celandine',
      hex: 'EBDF68',
      rgb: {
        r: 235,
        g: 223,
        b: 104,
      },
      lab: {
        l: 88.27,
        a: -5.59,
        b: 57.87,
      },
      cmyk: null,
    },
    {
      code: '12-0642 TCX',
      name: 'Aurora',
      hex: 'EDDD59',
      rgb: {
        r: 237,
        g: 221,
        b: 89,
      },
      lab: {
        l: 87.6,
        a: -4.25,
        b: 63.63,
      },
      cmyk: null,
    },
    {
      code: '13-0640 TCX',
      name: 'Acacia',
      hex: 'DACD65',
      rgb: {
        r: 218,
        g: 205,
        b: 101,
      },
      lab: {
        l: 81.91,
        a: -3.56,
        b: 51.55,
      },
      cmyk: null,
    },
    {
      code: '13-0648 TCX',
      name: 'Green Sheen',
      hex: 'D9CE52',
      rgb: {
        r: 217,
        g: 206,
        b: 82,
      },
      lab: {
        l: 82.04,
        a: -5.47,
        b: 59.99,
      },
      cmyk: null,
    },
    {
      code: '13-0646 TCX',
      name: 'Meadowlark',
      hex: 'EADA4F',
      rgb: {
        r: 234,
        g: 218,
        b: 79,
      },
      lab: {
        l: 86.59,
        a: -4.45,
        b: 66.38,
      },
      cmyk: null,
    },
    {
      code: '11-0615 TCX',
      name: 'Pear Sorbet',
      hex: 'F3EAC3',
      rgb: {
        r: 243,
        g: 234,
        b: 195,
      },
      lab: {
        l: 92.7,
        a: -0.63,
        b: 20.05,
      },
      cmyk: null,
    },
    {
      code: '11-0616 TCX',
      name: 'Pastel Yellow',
      hex: 'F2E6B1',
      rgb: {
        r: 242,
        g: 230,
        b: 177,
      },
      lab: {
        l: 91.34,
        a: -1.35,
        b: 27.3,
      },
      cmyk: null,
    },
    {
      code: '12-0715 TCX',
      name: 'Double Cream',
      hex: 'F3E0AC',
      rgb: {
        r: 243,
        g: 224,
        b: 172,
      },
      lab: {
        l: 90.03,
        a: 1.46,
        b: 28.12,
      },
      cmyk: null,
    },
    {
      code: '12-0722 TCX',
      name: 'French Vanilla',
      hex: 'EFE1A7',
      rgb: {
        r: 239,
        g: 225,
        b: 167,
      },
      lab: {
        l: 89.85,
        a: -1,
        b: 30.23,
      },
      cmyk: null,
    },
    {
      code: '12-0721 TCX',
      name: 'Lemonade',
      hex: 'F0E79D',
      rgb: {
        r: 240,
        g: 231,
        b: 157,
      },
      lab: {
        l: 91.02,
        a: -3.76,
        b: 36.78,
      },
      cmyk: null,
    },
    {
      code: '11-0622 TCX',
      name: 'Yellow Iris',
      hex: 'EEE78E',
      rgb: {
        r: 238,
        g: 231,
        b: 142,
      },
      lab: {
        l: 90.67,
        a: -5.63,
        b: 43.63,
      },
      cmyk: null,
    },
    {
      code: '11-0620 TCX',
      name: 'Elfin Yellow',
      hex: 'EEEA97',
      rgb: {
        r: 238,
        g: 234,
        b: 151,
      },
      lab: {
        l: 91.64,
        a: -6.77,
        b: 40.07,
      },
      cmyk: null,
    },
    {
      code: '12-0720 TCX',
      name: 'Mellow Yellow',
      hex: 'F0DD9D',
      rgb: {
        r: 240,
        g: 221,
        b: 157,
      },
      lab: {
        l: 88.77,
        a: 0.51,
        b: 34.29,
      },
      cmyk: null,
    },
    {
      code: '12-0824 TCX',
      name: 'Pale Banana',
      hex: 'FAE199',
      rgb: {
        r: 250,
        g: 225,
        b: 153,
      },
      lab: {
        l: 90.41,
        a: 2.28,
        b: 38.39,
      },
      cmyk: null,
    },
    {
      code: '12-0711 TCX',
      name: 'Lemon Meringue',
      hex: 'F6E199',
      rgb: {
        r: 246,
        g: 225,
        b: 153,
      },
      lab: {
        l: 90.17,
        a: 0.82,
        b: 37.43,
      },
      cmyk: null,
    },
    {
      code: '12-0825 TCX',
      name: 'Popcorn',
      hex: 'F8DE8D',
      rgb: {
        r: 248,
        g: 222,
        b: 141,
      },
      lab: {
        l: 89.54,
        a: 1.97,
        b: 42.86,
      },
      cmyk: null,
    },
    {
      code: '12-0718 TCX',
      name: 'Pineapple Slice',
      hex: 'E7D391',
      rgb: {
        r: 231,
        g: 211,
        b: 145,
      },
      lab: {
        l: 85.16,
        a: 2.02,
        b: 34.9,
      },
      cmyk: null,
    },
    {
      code: '12-0729 TCX',
      name: 'Sundress',
      hex: 'EBCF89',
      rgb: {
        r: 235,
        g: 207,
        b: 137,
      },
      lab: {
        l: 84.51,
        a: 4.92,
        b: 38.05,
      },
      cmyk: null,
    },
    {
      code: '12-0727 TCX',
      name: 'Sunshine',
      hex: 'FADE85',
      rgb: {
        r: 250,
        g: 222,
        b: 133,
      },
      lab: {
        l: 89.58,
        a: 2.54,
        b: 47.07,
      },
      cmyk: null,
    },
    {
      code: '11-0510 TCX',
      name: 'Afterglow',
      hex: 'F3E6C9',
      rgb: {
        r: 243,
        g: 230,
        b: 201,
      },
      lab: {
        l: 91.8,
        a: 1.8,
        b: 15.78,
      },
      cmyk: null,
    },
    {
      code: '11-0619 TCX',
      name: 'Flan',
      hex: 'F6E3B4',
      rgb: {
        r: 246,
        g: 227,
        b: 180,
      },
      lab: {
        l: 91.01,
        a: 2.14,
        b: 25.13,
      },
      cmyk: null,
    },
    {
      code: '12-0717 TCX',
      name: 'Anise Flower',
      hex: 'F4E3B5',
      rgb: {
        r: 244,
        g: 227,
        b: 181,
      },
      lab: {
        l: 90.9,
        a: 1.6,
        b: 24.62,
      },
      cmyk: null,
    },
    {
      code: '13-0822 TCX',
      name: 'Sunlight',
      hex: 'EDD59E',
      rgb: {
        r: 237,
        g: 213,
        b: 158,
      },
      lab: {
        l: 86.4,
        a: 3.44,
        b: 30.59,
      },
      cmyk: null,
    },
    {
      code: '13-0917 TCX',
      name: 'Italian Straw',
      hex: 'E7D1A1',
      rgb: {
        r: 231,
        g: 209,
        b: 161,
      },
      lab: {
        l: 84.94,
        a: 3.73,
        b: 26.64,
      },
      cmyk: null,
    },
    {
      code: '13-0922 TCX',
      name: 'Straw',
      hex: 'E0C992',
      rgb: {
        r: 224,
        g: 201,
        b: 146,
      },
      lab: {
        l: 81.96,
        a: 3.24,
        b: 30.42,
      },
      cmyk: null,
    },
    {
      code: '14-0935 TCX',
      name: 'Jojoba',
      hex: 'DABE81',
      rgb: {
        r: 218,
        g: 190,
        b: 129,
      },
      lab: {
        l: 78.55,
        a: 4.99,
        b: 34.89,
      },
      cmyk: null,
    },
    {
      code: '12-0812 TCX',
      name: 'Alabaster Gleam',
      hex: 'F0DEBD',
      rgb: {
        r: 240,
        g: 222,
        b: 189,
      },
      lab: {
        l: 89.32,
        a: 3.02,
        b: 18.64,
      },
      cmyk: null,
    },
    {
      code: '12-0806 TCX',
      name: 'Rutabaga',
      hex: 'EDDCBE',
      rgb: {
        r: 237,
        g: 220,
        b: 190,
      },
      lab: {
        l: 88.65,
        a: 3.73,
        b: 16.68,
      },
      cmyk: null,
    },
    {
      code: '13-0815 TCX',
      name: 'Banana Crepe',
      hex: 'E7D3AD',
      rgb: {
        r: 231,
        g: 211,
        b: 173,
      },
      lab: {
        l: 85.56,
        a: 3.51,
        b: 21.55,
      },
      cmyk: null,
    },
    {
      code: '12-0910 TCX',
      name: "Lamb's Wool",
      hex: 'E5D0B1',
      rgb: {
        r: 229,
        g: 208,
        b: 177,
      },
      lab: {
        l: 84.77,
        a: 4.52,
        b: 18.07,
      },
      cmyk: null,
    },
    {
      code: '13-0814 TCX',
      name: 'Summer Melon',
      hex: 'EAD3AE',
      rgb: {
        r: 234,
        g: 211,
        b: 174,
      },
      lab: {
        l: 85.96,
        a: 4.74,
        b: 21.56,
      },
      cmyk: null,
    },
    {
      code: '12-0714 TCX',
      name: 'Cornhusk',
      hex: 'F3D5AD',
      rgb: {
        r: 243,
        g: 213,
        b: 173,
      },
      lab: {
        l: 87.15,
        a: 7.32,
        b: 23.64,
      },
      cmyk: null,
    },
    {
      code: '12-0822 TCX',
      name: 'Golden Fleece',
      hex: 'F2D1A0',
      rgb: {
        r: 242,
        g: 209,
        b: 160,
      },
      lab: {
        l: 85.94,
        a: 7.65,
        b: 29.25,
      },
      cmyk: null,
    },
    {
      code: '11-0515 TCX',
      name: 'Lemon Icing',
      hex: 'F6EBC8',
      rgb: {
        r: 246,
        g: 235,
        b: 200,
      },
      lab: {
        l: 93.45,
        a: 0.09,
        b: 18.4,
      },
      cmyk: null,
    },
    {
      code: '12-0804 TCX',
      name: 'Cloud Cream',
      hex: 'E6DDC5',
      rgb: {
        r: 230,
        g: 221,
        b: 197,
      },
      lab: {
        l: 88.4,
        a: 0.75,
        b: 13.08,
      },
      cmyk: null,
    },
    {
      code: '12-0713 TCX',
      name: 'Almond Oil',
      hex: 'F4E4C1',
      rgb: {
        r: 244,
        g: 228,
        b: 193,
      },
      lab: {
        l: 91.29,
        a: 2.81,
        b: 18.75,
      },
      cmyk: null,
    },
    {
      code: '12-0815 TCX',
      name: 'Vanilla Custard',
      hex: 'F3E0BE',
      rgb: {
        r: 243,
        g: 224,
        b: 190,
      },
      lab: {
        l: 90.2,
        a: 3.58,
        b: 19.22,
      },
      cmyk: null,
    },
    {
      code: '12-0712 TCX',
      name: 'Vanilla',
      hex: 'F4E1C1',
      rgb: {
        r: 244,
        g: 225,
        b: 193,
      },
      lab: {
        l: 90.64,
        a: 3.64,
        b: 18.52,
      },
      cmyk: null,
    },
    {
      code: '13-0916 TCX',
      name: 'Chamomile',
      hex: 'E8D0A7',
      rgb: {
        r: 232,
        g: 208,
        b: 167,
      },
      lab: {
        l: 84.78,
        a: 5.24,
        b: 23.39,
      },
      cmyk: null,
    },
    {
      code: '12-0817 TCX',
      name: 'Apricot Gelato',
      hex: 'F5D7AF',
      rgb: {
        r: 245,
        g: 215,
        b: 175,
      },
      lab: {
        l: 88,
        a: 7.25,
        b: 24.23,
      },
      cmyk: null,
    },
    {
      code: '11-0108 TCX',
      name: 'Solitary Star',
      hex: 'F3EBD6',
      rgb: {
        r: 243,
        g: 235,
        b: 214,
      },
      lab: {
        l: 93.28,
        a: 0.64,
        b: 10.98,
      },
      cmyk: null,
    },
    {
      code: '11-0202 TCX',
      name: "Baby's Breath",
      hex: 'E9E2D1',
      rgb: {
        r: 233,
        g: 226,
        b: 209,
      },
      lab: {
        l: 90.23,
        a: 0.31,
        b: 9.11,
      },
      cmyk: null,
    },
    {
      code: '11-0507 TCX',
      name: 'Winter White',
      hex: 'F5ECD2',
      rgb: {
        r: 245,
        g: 236,
        b: 210,
      },
      lab: {
        l: 93.56,
        a: 0.41,
        b: 13.58,
      },
      cmyk: null,
    },
    {
      code: '11-0107 TCX',
      name: 'Papyrus ',
      hex: 'F5EDD6',
      rgb: {
        r: 245,
        g: 237,
        b: 214,
      },
      lab: {
        l: 93.9,
        a: 0.47,
        b: 11.97,
      },
      cmyk: null,
    },
    {
      code: '11-0106 TCX',
      name: 'Sweet Corn',
      hex: 'F0EBD7',
      rgb: {
        r: 240,
        g: 235,
        b: 215,
      },
      lab: {
        l: 93.05,
        a: -0.32,
        b: 10.53,
      },
      cmyk: null,
    },
    {
      code: '12-0602 TCX',
      name: 'Arctic Wolf',
      hex: 'E6DECF',
      rgb: {
        r: 230,
        g: 222,
        b: 207,
      },
      lab: {
        l: 88.96,
        a: 0.92,
        b: 8.37,
      },
      cmyk: null,
    },
    {
      code: '12-0908 TCX',
      name: 'Summer Sand',
      hex: 'D6CBBA',
      rgb: {
        r: 214,
        g: 203,
        b: 186,
      },
      lab: {
        l: 82.4,
        a: 1.95,
        b: 9.98,
      },
      cmyk: null,
    },
    {
      code: '13-1012 TCX',
      name: 'Frosted Almond',
      hex: 'D2C2AC',
      rgb: {
        r: 210,
        g: 194,
        b: 172,
      },
      lab: {
        l: 79.36,
        a: 3.67,
        b: 13.06,
      },
      cmyk: null,
    },
    {
      code: '14-1012 TCX',
      name: 'Gilded Beige',
      hex: 'B39F8D',
      rgb: {
        r: 179,
        g: 159,
        b: 141,
      },
      lab: {
        l: 67.15,
        a: 5.32,
        b: 12.81,
      },
      cmyk: null,
    },
    {
      code: '15-0927 TCX',
      name: 'Pale Gold',
      hex: 'BD9865',
      rgb: {
        r: 189,
        g: 152,
        b: 101,
      },
      lab: {
        l: 65.81,
        a: 9.92,
        b: 32.35,
      },
      cmyk: null,
    },
    {
      code: '16-0836 TCX',
      name: 'Rich Gold',
      hex: 'C8B273',
      rgb: {
        r: 200,
        g: 178,
        b: 115,
      },
      lab: {
        l: 73.5,
        a: 2.26,
        b: 35.03,
      },
      cmyk: null,
    },
    {
      code: '16-1325 TCX',
      name: 'Copper',
      hex: 'C47E5A',
      rgb: {
        r: 196,
        g: 126,
        b: 90,
      },
      lab: {
        l: 60.25,
        a: 26.16,
        b: 32.08,
      },
      cmyk: null,
    },
    {
      code: '18-1537 TCX',
      name: 'Copper Coin',
      hex: 'BA6B57',
      rgb: {
        r: 186,
        g: 107,
        b: 87,
      },
      lab: {
        l: 54.6,
        a: 32.14,
        b: 26.76,
      },
      cmyk: null,
    },
    {
      code: '14-5002 TCX',
      name: 'Silver',
      hex: 'A2A2A1',
      rgb: {
        r: 162,
        g: 162,
        b: 161,
      },
      lab: {
        l: 66.68,
        a: -0.28,
        b: 0.71,
      },
      cmyk: null,
    },
  ],
};
