import { Flex, IconButton } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ContactListItem } from '@texas/api/endpoints/contactsApi';
import { Icons } from '@texas/components/shared/Icons';
import { TFunction } from 'i18next';
import { Mode } from './ContactsModal';

export const modalContactsTableColumns = (
  t: TFunction,
  referenceId: number,
  mode: Mode,
  onSelect: (c: ContactListItem) => void,
) => {
  const columnHelper = createColumnHelper<ContactListItem>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.name'),
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: t('general.email'),
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {mode === 'select' ? (
              <IconButton
                variant="texas-light"
                aria-label="Select"
                size="sm"
                onClick={() => onSelect(info.row.original)}
                icon={<Icons.Plus boxSize={5} />}
              />
            ) : (
              <>
                {!info.row.original.customerIds.find(
                  (c) => c === referenceId,
                ) && (
                  <IconButton
                    variant="texas-light"
                    size="sm"
                    textAlign="center"
                    onClick={() => onSelect(info.row.original)}
                    icon={<Icons.Plus boxSize={5} />}
                    aria-label="Add"
                  />
                )}
                {info.row.original.customerIds.find(
                  (c) => c === referenceId,
                ) && <Icons.Checkmark boxSize={5} />}
              </>
            )}
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
