// @ngInject
const CopyArticleDialogService = ($mdDialog) => ({
  showDialog: ({
    customerId = null,
    articleId = null,
    itemCodeMinLength = null,
    itemCodeMaxLength = null,
    conceptId = null,
    includePurchasePrices = true,
    includeSalesPrices = false,
    includeVariants = false,
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'CopyArticleController as vm',
      templateUrl:
        'services/dialogs/copyArticleDialog/copyArticleDialog.tpl.html',
      locals: {
        customerId,
        articleId,
        itemCodeMinLength,
        itemCodeMaxLength,
        conceptId,
        includePurchasePrices,
        includeSalesPrices,
        includeVariants,
      },
    }),
});

class CopyArticleController {
  constructor(
    $mdDialog,
    toasterService,
    ArticlesApiService,
    articleId,
    customerId,
    itemCodeMinLength,
    itemCodeMaxLength,
    conceptId,
    includePurchasePrices,
    includeSalesPrices,
    includeVariants,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.toasterService = toasterService;
    this.ArticlesApiService = ArticlesApiService;
    this.customerId = customerId;
    this.articleId = articleId;
    this.itemCodeMinLength = itemCodeMaxLength >= 13 ? 1 : itemCodeMinLength;
    this.itemCodeMaxLength = itemCodeMaxLength;
    this.articleCopy = {
      basedOnArticleId: articleId,
      conceptId,
      includePurchasePrices,
      includeSalesPrices,
      includeVariants,
    };

    this.validationErrors = [];
  }

  onItemCodeChange() {
    if (this.showImport) {
      this.showImport = false;
    }
  }

  save(force) {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();
    const articleCopy = _.extend({}, this.articleCopy);
    if (force) {
      articleCopy.force = true;
    }
    this.ArticlesApiService.newArticle(articleCopy, this.customerId)
      .then(
        (newArticle) => {
          this.toasterService.success();
          this.$mdDialog.hide(newArticle);
        },
        (error) => {
          this.validationErrors = error.errors;
          if (error.status === 409) {
            this.showImport = true;
          }
        },
      )
      .finally(() => this.$mdDialog.stopLoadingSpinner());
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.copyArticleDialog', [])
  .service('CopyArticleDialogService', CopyArticleDialogService)
  .controller('CopyArticleController', CopyArticleController);
