import { Text, StackProps, Flex } from '@chakra-ui/react';

interface TexasDrawerSubHeaderProps {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  style?: StackProps;
}

export function TexasDrawerSubHeader(props: TexasDrawerSubHeaderProps) {
  return (
    <Flex
      direction="column"
      padding={1}
      paddingLeft={4}
      backgroundColor="gray.700"
      _light={{ backgroundColor: 'gray.50' }}
      alignItems="start"
      spacing={0}
      {...props.style}
    >
      {props.children ? (
        <>{props.children}</>
      ) : (
        <>
          <Text variant="main">{props.title}</Text>
          <Text variant="small">{props.subTitle}</Text>
        </>
      )}
    </Flex>
  );
}
