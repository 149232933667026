import { ClaimType, User } from '@texas/api/endpoints/userApi';

export function hasClaims(claimIds: number[], requiredClaims: ClaimType[]) {
  return requiredClaims.some((rc) => claimIds.includes(rc));
}

export function isOrganizationOrSystemAdmin(user: User) {
  return hasClaims(
    user.claims.map((c) => c.id),
    [ClaimType.OrganizationAdministrator, ClaimType.SystemAdministrator],
  );
}
