import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { Uploaded, FileUpload } from '@texas/types';
import { FileLink, filesApi } from '../endpoints/filesApi';
import { formatBytes } from '@texas/utils/helpers/filesHelper';

export function useFileUploads(
  onUploadComplete?: (file: FileLink) => void,
  maxSize?: number,
) {
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);

  const resetFileUploads = useCallback(() => {
    setFileUploads([]);
  }, [setFileUploads]);

  const uploadedFileRefIds = useMemo(() => {
    const uploadedFileUploads = fileUploads.filter(
      (u): u is Uploaded => u.type === 'uploaded',
    );
    return uploadedFileUploads.map((a) => a.fileRefId);
  }, [fileUploads]);

  async function uploadFiles(files: File[], folderId: number) {
    setFileUploads([]);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (maxSize && file.size > maxSize) {
        const newFileUpload: FileUpload = {
          type: 'failed',
          uuid: nanoid(),
          fileName: file.name,
          error: {
            message: `File size is too large (limit: ${formatBytes(maxSize)})`,
            errors: null,
            status: 413,
          },
        };

        setFileUploads((prev) => [...prev, newFileUpload]);
        continue;
      }

      const { uploadPromise, abortController } = filesApi.uploadToFolder(
        file,
        folderId,
      );
      const newFileUpload: FileUpload = {
        type: 'uploading',
        uuid: nanoid(),
        fileName: file.name,
        abortController: abortController,
      };

      setFileUploads((prev) => [...prev, newFileUpload]);

      const res = await uploadPromise;

      if (!res.hasError) {
        setFileUploads((prev) =>
          prev.map((f) => {
            if (f.uuid === newFileUpload.uuid) {
              return {
                ...f,
                type: 'uploaded',
                fileRefId: res.data.identifier,
                fileName: res.data.originalName,
              };
            }
            return f;
          }),
        );
        if (onUploadComplete) {
          onUploadComplete(res.data);
        }
      } else {
        setFileUploads((prev) =>
          prev.map((f) => {
            if (f.uuid === newFileUpload.uuid) {
              return { ...f, type: 'failed', error: res.error };
            }
            return f;
          }),
        );
      }
    }
  }

  function abortFileUpload(file: FileUpload) {
    if (file.type === 'uploading') {
      file.abortController.abort();
      setFileUploads((prev) => prev.filter((f) => f.uuid !== file.uuid));
    }
  }

  const isUploading = fileUploads.some((f) => f.type === 'uploading');
  const uploadFailed = fileUploads.some((f) => f.type === 'failed');

  return {
    fileUploads,
    uploadedFileRefIds,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
    resetFileUploads,
  } as const;
}
