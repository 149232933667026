// @ngInject
const confirmDialog = ($mdDialog) => ({
  restrict: 'EA',
  scope: {
    action: '&confirmDialog',
    title: '=confirmTitle',
    text: '=confirmText',
    disabled: '=confirmDisabled',
  },
  link(scope, elem) {
    elem.on('click', ($event) => {
      if (scope.disabled) {
        return;
      }
      $event.stopPropagation();
      $mdDialog
        .show(
          $mdDialog
            .confirm()
            .title(scope.title)
            .textContent(scope.text)
            .ariaLabel(scope.title)
            .ok('Yes')
            .cancel('Cancel')
            .targetEvent($event),
        )
        .then(() => scope.action());
    });
  },
});

angular
  .module('directives.confirmDialog', [])
  .directive('confirmDialog', confirmDialog);
