import { TFunction } from 'i18next';
import { WidgetType } from './shared/types';

export type UsedOnBoard = 'Dashboard' | 'Customer' | 'All';

export interface BaseWidgetProps {
  widgetId: string;
  color: string | null;
  customTitle?: string;
}

export interface WidgetConfig {
  label: string;
  description: string;
  type: WidgetType;
  board: UsedOnBoard;
  maximum: number | null;
}

type WidgetConfigs = { readonly [key in WidgetType]: WidgetConfig };

export const getWidgetConfigs = (t: TFunction): WidgetConfigs => {
  return {
    [WidgetType.Brands]: {
      label: t('widgets.brands.yourBrands'),
      description: t('widgets.brands.desc'),
      type: WidgetType.Brands,
      board: 'Dashboard',
      maximum: 1,
    },
    [WidgetType.Articles]: {
      label: t('widgets.articles.articleSearch'),
      description: t('widgets.articles.desc'),
      type: WidgetType.Articles,
      board: 'Dashboard',
      maximum: null,
    },
    [WidgetType.ArticlesCount]: {
      label: t('widgets.articlesCount.title'),
      description: t('widgets.articlesCount.desc'),
      type: WidgetType.ArticlesCount,
      board: 'Dashboard',
      maximum: null,
    },
  };
};
