import { angularEvents } from '../../bridge/angularEvents';

// @ngInject
const userSettingsResolve = ($q) => $q.when({});

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('main', {
    parent: 'app',
    url: '',
    abstract: true,
    views: {
      'app-content': {
        templateUrl: 'main/main.tpl.html',
        controller: 'MainController as vm',
      },
    },
    data: { claims: ['user'] },
    resolve: {
      userSettings: userSettingsResolve,
    },
  });
};

class MainController {
  constructor(
    $rootScope,
    $scope,
    appSettings,
    userSettings,
    currentUser,
    PollerService,
    TasksApiService,
    DS,
    $state,
    AuthService,
  ) {
    'ngInject';

    $rootScope.appSettings = appSettings;
    $rootScope.userSettings = userSettings;
    $rootScope.currentUser = currentUser;
    this.$scope = $scope;
    this.$state = $state;
    this.AuthService = AuthService;
    this.unconfirmedTasks = 0;
    this.DS = DS;
    this.currentUser = currentUser;
    this.poller = PollerService.get(
      () => TasksApiService.getUnconfirmedCountByUserId(currentUser.id),
      ({ count }) => {
        this.unconfirmedTasks = count;
        angularEvents.pollReceived.dispatch(count);
      },
    );
    this.setWatchers();
  }

  setWatchers() {
    this.$scope.$watch(
      () => {
        return this.DS.lastModified('task');
      },
      () => {
        this.poller.poll();
      },
    );
  }
}

angular
  .module('app.main', [
    'main.customers',
    'main.customer',
    'main.concept',
    'main.article',
    'main.suppliers',
    'main.supplier',
    'main.users',
    'main.configuration',
    'main.tasks',
    'main.archive',
    'main.searcharticles',
  ])
  .config(config)
  .controller('MainController', MainController);
