import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = config.definePartsStyle({
  content: { color: 'white', _light: { color: 'texas.bg.900' } },
});

const variants = {
  'above-angular': {
    popper: {
      zIndex: 100,
    },
  },
  responsive: {
    content: {
      width: 'unset',
    },
  },
  'tier-overview': {
    content: {
      bg: 'texas.bg.blueTint.100',
      color: 'white',
      borderColor: 'gray.400',
      _light: { bg: 'white', color: 'black' },
    },
    arrow: {
      bg: 'texas.bg.blueTint.100 !important',
      _light: { bg: 'white !important' },
    },
  },
};

export default config.defineMultiStyleConfig({ baseStyle, variants });
