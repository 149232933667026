import { reactEvents } from '../../../../../bridge/reactEvents';

const articleMaterialTextIndex = 2;

// @ngInject
const garpDataResolve = (variant, GarpApiService) =>
  GarpApiService.getGarpData(variant.id);

// @ngInject
const categoryCodesResolve = (variant, GarpApiService) =>
  GarpApiService.getSharedCategoryCodes(variant.id);

// @ngInject
const techniquesResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadTechniques(currentUser.organization.id);

// @ngInject
const materialsResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadMaterials(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.general.garp', {
    parent: 'variant.general',
    url: '/garp',
    templateUrl: 'main/article/variant/garp/garp.tpl.html',
    controller: 'VariantGarpController as vm',
    resolve: {
      garpData: garpDataResolve,
      categoryCodes: categoryCodesResolve,
      techniques: techniquesResolve,
      materials: materialsResolve,
    },
  });
};

class VariantGarpController {
  constructor(
    $state,
    $q,
    $scope,
    $timeout,
    $filter,
    currentUser,
    loadSpinnerService,
    toasterService,
    HelperService,
    GarpApiService,
    BranchesApiService,
    OrganizationsApiService,
    CountriesApiService,
    TasksApiService,
    variant,
    garpData,
    article,
    categoryCodes,
    SavingService,
    variantOptions,
    materials,
    techniques,
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$filter = $filter;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
    this.HelperService = HelperService;
    this.GarpApiService = GarpApiService;
    this.BranchesApiService = BranchesApiService;
    this.OrganizationsApiService = OrganizationsApiService;
    this.CountriesApiService = CountriesApiService;
    this.TasksApiService = TasksApiService;
    this.currentUser = currentUser;
    this.article = article;
    this.variant = variant;
    this.locked = false;
    this.SavingService = SavingService;
    this.variantOptions = variantOptions;
    this.sharedCategoryCodes = categoryCodes;
    this.$state = $state;
    this.showExtraDetails = false;
    this.hasMatrix =
      !!this.article.matrixNodeXId && !!this.article.matrixNodeYId;

    this.contentTemplate = '';
    this.selectedBranch = null;
    this.selectedBranchData = {};
    this.categoryCodes = [];
    this.materials = [];
    this.techniques = [];
    this.sharedTechniques = techniques;
    this.sharedMaterials = materials;
    this.customsCodes = [];
    this.countries = [];
    this.states = [
      { id: 0, name: 'Registered' },
      { id: 3, name: 'On Hold' },
      { id: 4, name: 'Cancelled' },
      { id: 1, name: 'Accepted' },
      { id: 2, name: 'Rejected' },
    ];
    this.garpData = {};
    this.garpDataCopy = {};
    this.branches = article._branches;
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(),
      onDiscard: () => this.discard(),
      onValidate: () => this.validate(),
    });

    this.shared = {
      articleTexts: [],
    };
    const sharedFields = [
      'unit',
      'code1',
      'code2',
      'code3',
      'code4',
      'code5',
      'code6',
      'quantityPerUnit',
      'weight',
      'decimalsInQuantity',
      'decimalsInPrice',
    ];
    const self = this;
    _.forEach(sharedFields, (field) => {
      Object.defineProperty(this.shared, field, {
        enumerable: true,
        get() {
          return self.fieldDiffers(field)
            ? ''
            : _.chain(self.garpDataCopy.branchData)
                .map((d) => d[field])
                .filter((f) => !_.isNil(f))
                .head()
                .value();
        },
        set(val) {
          _.forEach(self.garpDataCopy.branchData, (d) => {
            d[field] = val;
          });
        },
      });
    });
    Object.defineProperty(this.shared, 'categoryCode', {
      enumerable: true,
      get() {
        return self.categoryCodeDiffers() ? '' : self.findFirstCategoryCode();
      },
      set(val) {
        _.forEach(self.garpDataCopy.branchData, (d) => {
          d.categoryCodeId = val
            ? _.find(val.branches, { branchId: d.branchId }).categoryCodeId
            : 0;
        });
      },
    });
    Object.defineProperty(this.shared, 'material', {
      enumerable: true,
      get() {
        return self.materialDiffers() ? '' : self.findFirstMaterial();
      },
      set(val) {
        _.forEach(self.garpDataCopy.branchData, (d) => {
          d.materialId = val ? val.id : 0;
        });
      },
    });
    Object.defineProperty(this.shared, 'technique', {
      enumerable: true,
      get() {
        return self.techniqueDiffers() ? '' : self.findFirstTechnique();
      },
      set(val) {
        _.forEach(self.garpDataCopy.branchData, (d) => {
          d.techniqueId = val ? val.id : 0;
        });
      },
    });
    for (let i = 1; i <= 15; i++) {
      const text = {
        index: i,
        get languageCode() {
          return self.codeDiffers(i)
            ? ''
            : _.chain(self.garpDataCopy.branchData)
                .map((d) => d.articleTexts[i - 1].languageCode)
                .filter((code) => !!code)
                .head()
                .value();
        },
        set languageCode(val) {
          _.forEach(self.garpDataCopy.branchData, (d) => {
            d.articleTexts[i - 1].languageCode = val;
          });
        },
        get text() {
          return self.textDiffers(i)
            ? ''
            : _.chain(self.garpDataCopy.branchData)
                .map((d) => d.articleTexts[i - 1].text)
                .filter((text) => !!text)
                .head()
                .value();
        },
        set text(val) {
          self.setDefaultSharedLanguageCode(i);
          _.forEach(self.garpDataCopy.branchData, (d) => {
            const text = d.articleTexts[i - 1];
            text.text = val;
          });
        },
      };
      this.shared.articleTexts.splice(
        _.sortedIndexBy(this.shared.articleTexts, text, (t) => t.index),
        0,
        text,
      );
    }

    this.setGarpData(garpData);
    if (variantOptions.selectedBranch) {
      this.selectBranch(variantOptions.selectedBranch);
    } else {
      this.selectShared();
    }
    this.setWatchers();

    this.finalizedEvent = reactEvents.variantFinalized.subscribe((data) => {
      this.setGarpData(data);
    });

    this.lockedStateChanged = reactEvents.lockedStateChanged.subscribe(
      (lockedState) => {
        this.locked = lockedState.locked;
        $scope.$apply();
      },
    );
  }

  findFirstCategoryCode() {
    return _.chain(this.garpDataCopy.branchData)
      .filter((d) => !!d.categoryCodeId)
      .map((d) => this.findCategoryCode(d.branchId, d.categoryCodeId))
      .head()
      .value();
  }

  categoryCodeDiffers() {
    return (
      _.uniqBy(this.garpDataCopy.branchData, (data) => {
        const categoryCode = this.findCategoryCode(
          data.branchId,
          data.categoryCodeId,
        );
        return categoryCode ? categoryCode.name : data.categoryCodeId;
      }).length !== 1
    );
  }

  findCategoryCode(branchId, categoryCodeId) {
    return _.find(this.sharedCategoryCodes, (c) =>
      _.some(c.branches, { branchId, categoryCodeId }),
    );
  }

  findFirstMaterial() {
    return _.chain(this.garpDataCopy.branchData)
      .filter((d) => !!d.materialId)
      .map((d) => this.findMaterial(d.materialId))
      .head()
      .value();
  }

  materialDiffers() {
    return (
      _.uniqBy(this.garpDataCopy.branchData, (data) => {
        const material = this.findMaterial(data.materialId);
        return material ? material.code : data.materialId;
      }).length !== 1
    );
  }

  findMaterial(materialId) {
    return _.find(this.sharedMaterials, (c) => c.id === materialId);
  }

  findFirstTechnique() {
    return _.chain(this.garpDataCopy.branchData)
      .filter((d) => !!d.techniqueId)
      .map((d) => this.findTechnique(d.techniqueId))
      .head()
      .value();
  }

  techniqueDiffers() {
    return (
      _.uniqBy(this.garpDataCopy.branchData, (data) => {
        const technique = this.findTechnique(data.techniqueId);
        return technique ? technique.code : data.techniqueId;
      }).length !== 1
    );
  }

  findTechnique(techniqueId) {
    return _.find(this.sharedTechniques, (c) => c.id === techniqueId);
  }

  fieldDiffers(field) {
    return (
      _.uniqBy(this.garpDataCopy.branchData, (data) => data[field] || '')
        .length !== 1
    );
  }

  textDiffers(index) {
    return (
      _.uniqBy(
        this.garpDataCopy.branchData,
        (data) => data.articleTexts[index - 1].text || '',
      ).length !== 1
    );
  }

  codeDiffers(index) {
    return (
      _.uniqBy(
        this.garpDataCopy.branchData,
        (data) => data.articleTexts[index - 1].languageCode || '',
      ).length !== 1
    );
  }

  clearText(index) {
    if (index < 1 || index > 15) {
      return;
    }

    const text = this.shared.articleTexts[index - 1];
    text.text = '';
    text.languageCode = '';
  }

  clearField(field, value = '') {
    if (!angular.isDefined(this.shared[field])) {
      return;
    }

    if (field === 'categoryCode') {
      this.sharedCategoryCode = null;
    }
    if (field === 'material') {
      this.sharedMaterial = null;
    }
    if (field === 'technique') {
      this.sharedTechnique = null;
    }
    if (field === 'weight') {
      this.shared[field] = null;
      return;
    }

    this.shared[field] = value;
  }

  setGarpData(garpData) {
    _.forEach(garpData.branchData, (branchData) => {
      for (let i = 1; i <= 15; i++) {
        if (!_.some(branchData.articleTexts, { index: i })) {
          const text = { index: i, languageCode: '', text: '' };
          branchData.articleTexts.splice(
            _.sortedIndexBy(branchData.articleTexts, text, (t) => t.index),
            0,
            text,
          );
        }
      }
    });

    _.forEach(garpData.branchData, (branchData) => {
      branchData.startDate = this.getDateInUTCOrDefaultNull(
        branchData.startDate,
      );
      branchData.requestDate = this.getDateInUTCOrDefaultNull(
        branchData.requestDate,
      );
      branchData.endDate = this.getDateInUTCOrDefaultNull(branchData.endDate);
      branchData.requestCompletedDate = this.getDateInUTCOrDefaultNull(
        branchData.requestCompletedDate,
      );
      branchData.sampleSentDate = this.getDateInUTCOrDefaultNull(
        branchData.sampleSentDate,
      );
      branchData.sampleConfirmationDate = this.getDateInUTCOrDefaultNull(
        branchData.sampleConfirmationDate,
      );
      branchData.sampleReadyDate = this.getDateInUTCOrDefaultNull(
        branchData.sampleReadyDate,
      );
    });

    this.garpData = garpData;
    this.garpDataCopy = angular.copy(garpData);

    if (this.selectedBranch) {
      this.selectedBranchData = _.find(this.garpDataCopy.branchData, {
        branchId: this.selectedBranch.id,
      });
      this.setSelectedBranchData();
    }

    if (!this.categoryCodeDiffers()) {
      this.sharedCategoryCode = this.findFirstCategoryCode();
    } else {
      this.sharedCategoryCode = null;
    }

    if (!this.materialDiffers()) {
      this.sharedMaterial = this.findFirstMaterial();
    } else {
      this.sharedMaterial = null;
    }

    if (!this.techniqueDiffers()) {
      this.sharedTechnique = this.findFirstTechnique();
    } else {
      this.sharedTechnique = null;
    }

    if (this.variantGarpForm) {
      this.variantGarpForm.$setPristine(true);
    }
  }

  selectShared() {
    this.contentTemplate = 'main/article/variant/garp/shared.tpl.html';
    this.selectedBranch = null;
  }

  selectBranch(branch) {
    this.contentTemplate = 'main/article/variant/garp/branch.tpl.html';
    this.variantOptions.selectedBranch = branch;
    this.selectedBranch = branch;
    this.selectedBranchData = _.find(this.garpDataCopy.branchData, {
      branchId: branch.id,
    });
    this.setDirtyFields(
      _.find(this.getBranchDataChanges(), {
        branchId: branch.id,
      }),
    );
    this.$q
      .all([
        this.loadCategoryCodes(branch.id),
        this.loadCustomsCodes(branch.id),
        this.loadCountries(branch.id),
        this.loadTechniques(),
        this.loadMaterials(),
      ])
      .then(() => {
        this.setSelectedBranchData();
      });
  }

  setSelectedBranchData() {
    this.setCategoryCode(this.selectedBranchData.categoryCodeId);
    this.setCustomsCode(this.selectedBranchData.customsCodeId);
    this.setCountryOfOrigin(this.selectedBranchData.countryOfOriginId);
    this.setTechnique(this.selectedBranchData.techniqueId);
    this.setMaterial(this.selectedBranchData.materialId);
  }

  setDirtyFields(changedData) {
    this.$timeout(() => {
      if (this.variantGarpForm) {
        this.variantGarpForm.$setPristine();
        _.forEach(changedData, (value, key) => {
          if (key === 'branchId' || key === 'articleTexts') {
            return;
          }
          if (this.variantGarpForm[key]) {
            this.variantGarpForm[key].$setDirty();
          }
        });
      }
    });
  }

  getChangedData() {
    const changedData = {};
    const changedBranchData = this.getBranchDataChanges();
    if (changedBranchData.length) {
      changedData.branchData = changedBranchData;
    }
    return changedData;
  }

  validate() {
    if (!this.variantGarpForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  save(cb) {
    const data = this.getChangedData();
    this.assignBranchDataDates(data);

    this.loadSpinnerService.start('mainSpinner');
    this.GarpApiService.updateGarpData(data, this.variant.id)
      .then(
        (updatedGarpData) => {
          this.setGarpData(updatedGarpData);
          if (cb) {
            cb();
          } else {
            this.toasterService.success();
          }
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  discard() {
    this.validationErrors = [];
    this.setGarpData(this.garpData);
  }

  assignBranchDataDates(data) {
    _.forEach(data.branchData, (bd) => {
      const branchDataCopy = _.find(this.garpDataCopy.branchData, (b) => {
        return b.branchId === bd.branchId;
      });

      if (branchDataCopy) {
        const requestDate = branchDataCopy.requestDate;
        const sampleReadyDate = branchDataCopy.sampleReadyDate;
        const requestCompletedDate = branchDataCopy.requestCompletedDate;
        const sampleSentDate = branchDataCopy.sampleSentDate;
        const sampleConfirmationDate = branchDataCopy.sampleConfirmationDate;

        if (!bd.requestDate) {
          _.assignIn(bd, { requestDate });
        } else {
          bd.requestDate = this.getDateInUTCFromLocalDate(bd.requestDate);
        }
        if (!bd.sampleReadyDate) {
          _.assignIn(bd, { sampleReadyDate });
        } else {
          bd.sampleReadyDate = this.getDateInUTCFromLocalDate(
            bd.sampleReadyDate,
          );
        }
        if (!bd.requestCompletedDate) {
          _.assignIn(bd, { requestCompletedDate });
        } else {
          bd.requestCompletedDate = this.getDateInUTCFromLocalDate(
            bd.requestCompletedDate,
          );
        }
        if (!bd.sampleSentDate) {
          _.assignIn(bd, { sampleSentDate });
        } else {
          bd.sampleSentDate = this.getDateInUTCFromLocalDate(bd.sampleSentDate);
        }
        if (!bd.sampleConfirmationDate) {
          _.assignIn(bd, { sampleConfirmationDate });
        } else {
          bd.sampleConfirmationDate = this.getDateInUTCFromLocalDate(
            bd.sampleConfirmationDate,
          );
        }
      }
    });
  }

  getBranchDataChanges() {
    const dataChanges = [];
    const branchDataFields = [
      'state',
      'toDoTaskId',
      'categoryCodeId',
      'customsCodeId',
      'countryOfOriginId',
      'techniqueId',
      'materialId',
      'unit',
      'code1',
      'code2',
      'code3',
      'code4',
      'code5',
      'code6',
      'quantityPerUnit',
      'weight',
      'decimalsInQuantity',
      'decimalsInPrice',
      'articleTexts',
      'startDate',
      'requestDate',
      'sampleReadyDate',
      'endDate',
      'requestCompletedDate',
      'sampleSentDate',
      'sampleConfirmationDate',
    ];

    _.forEach(this.garpDataCopy.branchData, (branchData) => {
      const existingBranchData = _.find(this.garpData.branchData, {
        branchId: branchData.branchId,
      });
      const changedData = this.HelperService.getChangedData(
        existingBranchData,
        branchData,
        branchDataFields,
      );
      if (!_.isEmpty(changedData)) {
        dataChanges.push(
          angular.extend(
            {},
            {
              branchId: branchData.branchId,
            },
            changedData,
          ),
        );
      }
    });

    return dataChanges;
  }

  loadCategoryCodes(branchId) {
    this.categoryCodes.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.GarpApiService.getCategoryCodes(branchId)
      .then((categoryCodes) => (this.categoryCodes = categoryCodes))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadCustomsCodes(branchId) {
    this.customsCodes.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.GarpApiService.getCustomsCodes(branchId)
      .then((customsCodes) => (this.customsCodes = customsCodes))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadCountries(branchId) {
    this.countries.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.CountriesApiService.getAllByBranchId(branchId)
      .then((countries) => (this.countries = countries))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadTechniques() {
    this.techniques.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.OrganizationsApiService.loadTechniques(
      this.currentUser.organization.id,
    )
      .then((techniques) => (this.techniques = techniques))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadMaterials() {
    this.materials.length = 0;
    this.loadSpinnerService.start('mainSpinner');
    return this.OrganizationsApiService.loadMaterials(
      this.currentUser.organization.id,
    )
      .then((materials) => (this.materials = materials))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadGarpData() {
    this.loadSpinnerService.start('mainSpinner');
    this.GarpApiService.getGarpData(this.variant.id)
      .then((data) => {
        this.setGarpData(data);
        this.selectShared();
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  setCategoryCode(categoryCodeId) {
    if (categoryCodeId) {
      this.selectedBranchData.categoryCodeId = categoryCodeId;
      this.selectedBranchData.categoryCode = _.find(this.categoryCodes, {
        id: categoryCodeId,
      });
    } else {
      this.selectedBranchData.categoryCodeId = 0;
      this.selectedBranchData.categoryCode = null;
    }
  }

  setCustomsCode(customsCodeId) {
    if (customsCodeId) {
      this.selectedBranchData.customsCodeId = customsCodeId;
      this.selectedBranchData.customsCode = _.find(this.customsCodes, {
        id: customsCodeId,
      });
    } else {
      this.selectedBranchData.customsCodeId = 0;
      this.selectedBranchData.customsCode = null;
    }
  }

  setCountryOfOrigin(countryId) {
    if (countryId) {
      this.selectedBranchData.countryOfOriginId = countryId;
      this.selectedBranchData.countryOfOrigin = _.find(this.countries, {
        id: countryId,
      });
    } else {
      this.selectedBranchData.countryOfOriginId = 0;
      this.selectedBranchData.countryOfOrigin = null;
    }
  }

  setTechnique(techniqueId) {
    if (techniqueId) {
      this.selectedBranchData.techniqueId = techniqueId;
      this.selectedBranchData.technique = _.find(this.techniques, {
        id: techniqueId,
      });
    } else {
      this.selectedBranchData.techniqueId = 0;
      this.selectedBranchData.technique = null;
    }
  }

  setMaterial(materialId) {
    if (materialId) {
      this.selectedBranchData.materialId = materialId;
      this.selectedBranchData.material = _.find(this.materials, {
        id: materialId,
      });
    } else {
      this.selectedBranchData.materialId = 0;
      this.selectedBranchData.material = null;
    }
  }

  materialChanged(materialId) {
    if (materialId) {
      const material = _.find(this.materials, {
        id: materialId,
      });

      this.verifyMaterialUpdate(material);
    } else {
      this.selectedBranchData.materialId = 0;
      this.selectedBranchData.material = null;
    }
  }

  articleSetValue(index, shared) {
    if (index === 3) {
      if (shared) {
        if (
          (this.shared.articleTexts[articleMaterialTextIndex].text ?? '') ===
          (this.shared.material?.articleTextField ?? '')
        ) {
          return undefined;
        }

        if (!this.shared.material) {
          return 'Clear material text field';
        }
        return `Set material text field to ${this.shared.material.articleTextField}`;
      }

      if (
        (this.selectedBranchData.articleTexts[articleMaterialTextIndex].text ??
          '') === (this.selectedBranchData.material?.articleTextField ?? '')
      ) {
        return undefined;
      }

      if (!this.selectedBranchData.material) {
        return 'Clear material text field';
      }
      return `Set material text field to ${this.selectedBranchData.material.articleTextField}`;
    }
  }

  setArticleText(index, shared) {
    if (index === 3) {
      if (shared) {
        if (!this.shared.material) {
          this.shared.articleTexts[articleMaterialTextIndex].text = '';
          return;
        }

        this.shared.articleTexts[articleMaterialTextIndex].text =
          this.shared.material.articleTextField;
        return;
      }

      if (!this.selectedBranchData.material) {
        this.selectedBranchData.articleTexts[articleMaterialTextIndex].text =
          '';
        return;
      }

      this.selectedBranchData.articleTexts[articleMaterialTextIndex].text =
        this.selectedBranchData.material.articleTextField;
    }
  }

  filterCategoryCodes(query) {
    return _.orderBy(
      _.filter(this.categoryCodes, (c) =>
        this.HelperService.containsText(c, { name: query, identifier: query }),
      ),
      'name',
    );
  }

  filterSharedCategoryCodes(query) {
    return _.orderBy(
      _.filter(this.sharedCategoryCodes, (c) =>
        this.HelperService.containsText(c, { name: query }),
      ),
      'name',
    );
  }

  filterCustomsCodes(query) {
    return _.orderBy(
      _.filter(this.customsCodes, (c) =>
        this.HelperService.containsText(c, { name: query, identifier: query }),
      ),
      'name',
    );
  }

  filterCountries(query) {
    return _.orderBy(
      _.filter(this.countries, (c) =>
        this.HelperService.containsText(c, { name: query, code: query }),
      ),
      'name',
    );
  }

  filterTechniques(query) {
    return _.orderBy(
      _.filter(this.techniques, (t) =>
        this.HelperService.containsText(t, { code: query, value: query }),
      ),
      'code',
    );
  }

  filterSharedTechniques(query) {
    return _.orderBy(
      _.filter(this.sharedTechniques, (p) =>
        this.HelperService.containsText(p, { code: query, value: query }),
      ),
      'code',
    );
  }

  filterMaterials(query) {
    return _.orderBy(
      _.filter(this.materials, (m) =>
        this.HelperService.containsText(m, { code: query, name: query }),
      ),
      'code',
    );
  }

  filterSharedMaterials(query) {
    return _.orderBy(
      _.filter(this.sharedMaterials, (m) =>
        this.HelperService.containsText(m, { code: query, name: query }),
      ),
      'code',
    );
  }

  goToTask() {
    this.$state.go('article.tasks', {
      articleId: this.article.id,
      toDoTaskId: this.selectedBranchData.toDoTaskId,
    });
  }

  setDefaultLanguageCode(i) {
    if (
      this.selectedBranch !== null &&
      _.isEmpty(this.selectedBranchData.articleTexts[i - 1].languageCode)
    ) {
      if (i >= 8 && i <= 12) {
        this.selectedBranchData.articleTexts[i - 1].languageCode = 'i';
      } else if (i >= 13 && i <= 15) {
        this.selectedBranchData.articleTexts[i - 1].languageCode = '.';
      }
    }
  }

  setDefaultSharedLanguageCode(i) {
    if (_.isEmpty(this.shared.articleTexts[i - 1].languageCode)) {
      if (i <= 4) {
        this.shared.articleTexts[i - 1].languageCode = '*';
      } else if (i >= 5 && i <= 7) {
        this.shared.articleTexts[i - 1].languageCode = '*';
      } else if (i >= 8 && i <= 12) {
        this.shared.articleTexts[i - 1].languageCode = 'i';
      } else if (i >= 13 && i <= 15) {
        this.shared.articleTexts[i - 1].languageCode = '.';
      }
    }
  }

  toggleExtraDetails(value) {
    this.showExtraDetails = value;
  }

  matrixDisabledHint(index) {
    return this.hasMatrix && (index === 2 || index === 4) ? true : false;
  }

  textDisabledOnBranch(index) {
    return index !== 6 && index !== 7 && index !== 3;
  }

  getDateInUTCOrDefaultNull(date) {
    if (date) {
      const d = new Date(date);
      return new Date(
        Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()),
      );
    }
    return null;
  }

  getDateInUTCFromLocalDate(date) {
    if (!date) return null;
    const utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    return new Date(utc);
  }

  setWatchers() {
    this.$scope.$watch(
      () => {
        return this.article.branches;
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.branches = _.clone(this.article._branches);
          const selectedBranch = this.variantOptions.selectedBranch;
          if (
            selectedBranch &&
            !_.find(this.branches, { id: selectedBranch.id })
          ) {
            this.variantOptions.selectedBranch = null;
          }
          if (
            this.selectedBranch &&
            !_.find(this.branches, { id: selectedBranch.id })
          ) {
            this.selectedBranch = null;
          }
          this.loadGarpData();
        }
      },
      true,
    );

    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        if (!hasChanges) {
          this.variantGarpForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
      this.finalizedEvent();
      this.lockedStateChanged();
    });
  }
}

angular
  .module('main.article.variant.general.garp', [])
  .config(config)
  .controller('VariantGarpController', VariantGarpController);
