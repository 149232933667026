// @ngInject
const usersResolve = (currentUser, UsersApiService) =>
  UsersApiService.getAllByOrganizationId(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('users', {
    parent: 'main',
    url: '/users',
    views: {
      'main-content': {
        controller: 'MainUsersController as vm',
        templateUrl: 'main/users/users.tpl.html',
      },
    },
    resolve: {
      users: usersResolve,
    },
    data: { pageTitle: 'Users' },
  });
};

class MainUsersController {
  constructor(
    $scope,
    $filter,
    currentUser,
    DS,
    UserDialogService,
    UsersApiService,
    AuthService,
    users,
  ) {
    'ngInject';

    this._UserDialogService = UserDialogService;
    this._currentUser = currentUser;
    this.originalUsers = users;
    this.users = [];
    this.onlyAdmins = false;
    this.query = { order: 'email', limit: 50, page: 1 };
    this.allowedToEditUser = AuthService.hasAnyClaim([
      'system_administrator',
      'organization_administrator',
    ]);

    this.filterAdmins();

    $scope.$watch(
      () => {
        return DS.lastModified('user');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.originalUsers = _.filter(
            UsersApiService.getAllFromCache(),
            (u) => !u.archived,
          );
          this.filterAdmins();
        }
      },
    );
  }

  filterAdmins() {
    this.resetTablePosition();

    let userList = _.filter(this.originalUsers, (u) => !u.archived);
    if (this.onlyAdmins) {
      userList = _.filter(userList, (user) => {
        return (
          _.intersection(_.map(user.claims, 'value'), [
            'system_administrator',
            'organization_administrator',
          ]).length > 0
        );
      });
    }

    this.users = userList;
  }

  openUser(user) {
    this._UserDialogService.showDialog({
      user,
      isEditingSelf: this._currentUser.id === user.id,
      viewMode: !this.allowedToEditUser,
    });
  }

  newUser() {
    this._UserDialogService.showDialog({
      organizationId: this._currentUser.organization.id,
    });
  }

  resetTablePosition() {
    this.query.page = 1;
  }
}

angular
  .module('main.users', [])
  .config(config)
  .controller('MainUsersController', MainUsersController);
