import { BodyOverride } from '../BodyOverride';
import { FileBrowserContent } from './FileBrowserContent';

export function FileBrowserTab({ rootFolderId }: { rootFolderId: number }) {
  return (
    <BodyOverride>
      <FileBrowserContent rootFolderId={rootFolderId} />
    </BodyOverride>
  );
}
