import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  IconButton,
  Input,
  useToast,
  Text,
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import {
  CreateDimensionGroupRequest,
  DimensionGroup,
  dimensionGroupsApi,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { Icons } from '@texas/components/shared/Icons';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';

export interface CreateDimensionGroupProps extends SharedDisclosureProps {
  onCreated: (dimensionGroup: DimensionGroup) => void;
}

export interface CreateDimensionGroupForm {
  name: string;
  description: string | undefined;
  dimensions: { name: string }[];
}

const defaultValues: CreateDimensionGroupForm = {
  name: '',
  description: '',
  dimensions: [],
};

export function CreateDimensionGroup({
  onCreated,
  isOpen,
  onClose,
}: CreateDimensionGroupProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    request: createRequest,
    error,
    loading,
  } = useApiRequest(dimensionGroupsApi.create);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CreateDimensionGroupForm>({
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dimensions',
    rules: {
      required: { value: true, message: t('configuration.dimensionRequired') },
      minLength: { value: 1, message: t('configuration.dimensionRequired') },
    },
  });

  const onSubmit = async (data: CreateDimensionGroupForm) => {
    const formRequest: CreateDimensionGroupRequest = {
      ...data,
      dimensions: data.dimensions.flatMap((d) => d.name),
    };
    await request(
      createRequest,
      [formRequest],
      (data: DimensionGroup) => {
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        reset(defaultValues);
        onCreated(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  return (
    <Modal
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewDimensionGroup')}
        </ModalHeader>
        {error && <ErrorDetails error={error} />}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <ModalBody>
            <Flex flexDir="column" gap={4}>
              <FormControl isRequired={true} isInvalid={!!errors.name}>
                <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                <Input
                  variant="outline"
                  {...register('name', {
                    required: true,
                  })}
                  placeholder={t('general.name')}
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <TexasFormLabel>{t('general.description')}</TexasFormLabel>
                <Input
                  variant="outline"
                  {...register('description')}
                  placeholder={t('general.description')}
                />
                <ErrorLabel text={errors.description?.message} />
              </FormControl>
              <FormControl
                isRequired={true}
                isInvalid={!!errors.dimensions?.root?.message}
              >
                <TexasFormLabel>{t('configuration.dimensions')}</TexasFormLabel>
                {fields.map((d, i) => {
                  return (
                    <>
                      <Flex mt={2} gap={2}>
                        <Input
                          {...register(`dimensions.${i}.name` as const, {
                            required: true,
                          })}
                          key={d.id}
                          variant="outline"
                          placeholder={t('configuration.dimensionName')}
                        />
                        <IconButton
                          variant="texas-light"
                          size="sm"
                          icon={<Icons.Close />}
                          onClick={() => remove(i)}
                          aria-label={t('general.remove')}
                        />
                      </Flex>
                      <ErrorLabel
                        text={errors.dimensions?.[i]?.name?.message}
                      />
                    </>
                  );
                })}
                <ErrorLabel text={errors.dimensions?.root?.message} />
                <Button
                  variant="texas-light"
                  my={2}
                  size="sm"
                  onClick={() => append({ name: '' })}
                  aria-label={t('general.add')}
                  rightIcon={<Icons.Plus boxSize={5} />}
                >
                  {t('general.add')}
                </Button>
                <Text variant="note" mt={2}>
                  {t('configuration.dimensionGroupNote')}
                </Text>
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <SubmitButton loading={isSubmitting} disabled={loading}>
              {t('general.create')}
            </SubmitButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
