import { reactEvents } from '../../../../../bridge/reactEvents';

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.general', {
    url: '/general',
    templateUrl: 'main/article/variant/general/general.tpl.html',
    controller: 'VariantGeneralController as vm',
  });
};

class VariantGeneralController {
  constructor(
    $scope,
    variant,
    loadSpinnerService,
    ArticlesApiService,
    HelperService,
    AuthService,
    toasterService,
    SavingService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.originalVariant = variant;
    this.loadSpinnerService = loadSpinnerService;
    this.ArticlesApiService = ArticlesApiService;
    this.HelperService = HelperService;
    this.AuthService = AuthService;
    this.toasterService = toasterService;
    this.SavingService = SavingService;

    this.validationErrors = [];
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
      'agent',
    ]);
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validate(),
    });

    this.variantNameChangedEvent = reactEvents.variantNameChanged.subscribe(
      (variant) => {
        this.setVariantName(variant);
      },
    );

    this.setVariantClone();
    this.setWatchers();
  }

  setVariantClone() {
    this.variant = _.extend({}, this.originalVariant);
  }

  getChangedData() {
    return this.HelperService.getChangedData(
      this.originalVariant,
      this.variant,
      ['name', 'description', 'notes'],
    );
  }

  validate() {
    if (!this.variantGeneralForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  save(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateVariant(data, this.variant.id)
      .then(
        () => {
          this.setVariantClone();
          this.toasterService.success();
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  discard() {
    this.validationErrors = [];
    this.setVariantClone();
  }

  archive() {
    this.save({ archived: !this.variant.archived });
  }

  setAsMain() {
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateArticle(
      { mainVariantId: this.variant.id },
      this.variant.articleId,
    )
      .then(
        () => this.toasterService.success(),
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  setVariantName(variant) {
    this.originalVariant.variantName = variant.value;
    this.variant.name = variant.value;
  }

  setWatchers() {
    this.$scope.$watch(
      () => !_.isEmpty(this.getChangedData()),
      (hasChanges) => {
        if (!hasChanges) {
          this.variantGeneralForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
      this.variantNameChangedEvent();
    });
  }
}

angular
  .module('main.article.variant.general', ['main.article.variant.general.garp'])
  .config(config)
  .controller('VariantGeneralController', VariantGeneralController);
