import { ProductGroup } from './productGroupsApi';
import { httpClient } from '../../httpClient/httpClient';
import { ProductGroupItem, SearchQuery } from './types';

export interface Quality {
  id: number;
  name: string;
  code: string;
  productGroups: ProductGroup[];
  archived: string | null;
  inputField: boolean;
  childQualities: ChildQualityListItem[];
}

interface ChildQualityListItem {
  id: number;
  value: string;
}

export interface QualityListItem {
  id: number;
  name: string;
  code: string;
  archived: string | null;
  inputField: boolean;
  productGroups: ProductGroupItem[];
  childQualities: ChildQualityListItem[];
}

export interface UpdateQualityRequest {
  name: string;
  code: string;
  inputField: boolean;
  productGroupIds: number[] | null | undefined;
  childQualityIds: number[] | null | undefined;
}
export interface CreateQualityRequest {
  name: string;
  code: string;
  inputField: boolean;
  productGroupIds: number[] | null | undefined;
  childQualityIds: number[] | null | undefined;
}

export const qualitiesApi = {
  get: (id: number) => httpClient.get<Quality>(`metadata/qualities/${id}`),
  getAll: (params: SearchQuery) =>
    httpClient.get<QualityListItem[]>(`metadata/qualities/all`, {
      params,
    }),
  create: (request: CreateQualityRequest) =>
    httpClient.post<Quality>(`metadata/qualities`, {
      data: request,
    }),
  update: (id: number, request: UpdateQualityRequest) =>
    httpClient.put<Quality>(`metadata/qualities/${id}`, {
      data: request,
    }),
  archive: (id: number) => httpClient.put(`metadata/qualities/${id}/archive`),
  restore: (id: number) => httpClient.put(`metadata/qualities/${id}/restore`),
};
