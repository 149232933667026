// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('supplier.management', {
    url: '/management',
    templateUrl: 'main/supplier/management/management.tpl.html',
    controller: 'SupplierManagementController as vm',
  });
};

class SupplierManagementController {
  constructor(
    $scope,
    $filter,
    $state,
    $q,
    currentOrganization,
    loadSpinnerService,
    UploaderService,
    HelperService,
    AuthService,
    toasterService,
    SuppliersApiService,
    DeliveryTermsApiService,
    ResourcesService,
    CountriesApiService,
    supplier,
    SavingService,
    DeliveryMethodsApiService,
    PaymentTermsApiService,
    CurrenciesApiService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.$filter = $filter;
    this.loadSpinnerService = loadSpinnerService;
    this.HelperService = HelperService;
    this.toasterService = toasterService;
    this.SuppliersApiService = SuppliersApiService;
    this.DeliveryTermsApiService = DeliveryTermsApiService;
    this.DeliveryMethodsApiService = DeliveryMethodsApiService;
    this.PaymentTermsApiService = PaymentTermsApiService;
    this.ResourcesService = ResourcesService;
    this.CountriesApiService = CountriesApiService;
    this.SavingService = SavingService;
    this.CurrenciesApiService = CurrenciesApiService;

    this.selectedBranch = null;
    this.selectedCurrency = null;
    this.originalSupplier = supplier;
    this.currentBranchData = {};
    this.validationErrors = [];
    this.deliveryMethods = [];
    this.deliveryTerms = [];
    this.paymentTerms = [];
    this.countries = [];
    this.currencies = [];
    this.validationErrors = [];
    this.branches = currentOrganization.branches;
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);
    this.emailsUploadInstance = UploaderService.instance(
      'supplierEmailsUploadInstance',
    );
    this.emailsUploadInstance.finalizeUrl = () =>
      `folders/${supplier.emailsFolderId}/files`;
    this.documentsUploadInstance = UploaderService.instance(
      'supplierDocumentsUploadInstance',
    );
    this.documentsUploadInstance.finalizeUrl = () =>
      `folders/${supplier.documentsFolderId}/files`;
    this.samplesUploadInstance = UploaderService.instance(
      'supplierSamplesUploadInstance',
    );
    this.samplesUploadInstance.finalizeUrl = () =>
      `folders/${supplier.samplesFolderId}/files`;
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validateForm(),
    });
    this.setSupplierClone();
    this.selectBranch(this.branches[0]);
    this.setWatchers();
  }

  setSupplierClone() {
    this.supplier = _.extend({}, this.originalSupplier);

    if (this.selectedBranch) {
      this.setCurrentBranchDataClone();
    }
  }

  getChangedData() {
    const fields = ['name', 'dmixIdentifier', 'archived'];

    // Get any changes
    const changedInfo = this.HelperService.getChangedData(
      this.originalSupplier,
      this.supplier,
      fields,
    );

    // Get branch data changes
    const changedBranchData = this.getBranchDataChanges();
    if (changedBranchData.length) {
      changedInfo.branchData = changedBranchData;
    }

    return changedInfo;
  }

  validateForm() {
    if (!this.supplierGeneralForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  save(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.SuppliersApiService.updateSupplier(data, this.originalSupplier.id)
      .then(
        () => {
          this.setSupplierClone();
          this.setCurrentBranchData();
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  discard() {
    this.validationErrors = [];
    this.setSupplierClone();
    this.setCurrentBranchData();
    this.selectedCurrency = null;
  }

  archive() {
    this.save({ archived: !this.supplier.archived });
  }

  getBranchDataChanges() {
    const dataChanges = [];
    const fields = [
      'supplierNumber',
      'paymentTermId',
      'deliveryTermId',
      'deliveryMethodId',
      'address1',
      'address2',
      'city',
      'countryId',
      'buyingRate',
      'useCustomBuyingRate',
      'currencyId',
      'telephone',
      'website',
      'email',
    ];

    const originalBranchData = this.getOriginalBranchData();
    const changedData = this.HelperService.getChangedData(
      originalBranchData,
      this.currentBranchData,
      fields,
    );
    if (!_.isEmpty(changedData)) {
      dataChanges.push(angular.extend({}, originalBranchData, changedData));
    }

    return dataChanges;
  }

  goToFolder(folderId) {
    this.$state.go('supplier.files.id', { folderPath: folderId.toString() });
  }

  setCurrentBranchDataClone() {
    this.currentBranchData = _.extend({}, this.getOriginalBranchData());
  }

  setCurrentBranchData() {
    this.setBranchDataCountry(this.currentBranchData.countryId);
    this.setBranchDataPaymentTerm(this.currentBranchData.paymentTermId);
    this.setBranchDataDeliveryTerm(this.currentBranchData.deliveryTermId);
    this.setBranchDataDeliveryMethod(this.currentBranchData.deliveryMethodId);
    this.setBranchDataCurrency(this.currentBranchData.currencyId);
  }

  selectBranch(branch) {
    if (!branch) {
      return;
    }

    this.selectedBranch = branch;
    this.setCurrentBranchDataClone();
    this.loadBranchData(this.getOriginalBranchData()).then(() => {
      this.setCurrentBranchData();
    });
  }

  loadBranchData(branchData) {
    this.loadSpinnerService.start('mainSpinner');

    return this.$q
      .all([
        this.DeliveryMethodsApiService.getAllByBranchId(branchData.branchId),
        this.DeliveryTermsApiService.getAllByBranchId(branchData.branchId),
        this.PaymentTermsApiService.getAllByBranchId(branchData.branchId),
        this.CountriesApiService.getAllByBranchId(branchData.branchId),
        this.CurrenciesApiService.getAllByBranchId(branchData.branchId),
      ])
      .then((data) => {
        this.deliveryMethods = data[0];
        this.deliveryTerms = data[1];
        this.paymentTerms = data[2];
        this.countries = data[3];
        this.currencies = data[4];
        return this.ResourcesService.loadRelations(
          'supplierBranchData',
          branchData,
          [
            'deliveryMethod',
            'deliveryTerm',
            'paymentTerm',
            'country',
            'currency',
          ],
        );
      })
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  getOriginalBranchData() {
    if (
      !_.some(this.originalSupplier._branchData, {
        branchId: this.selectedBranch.id,
      })
    ) {
      this.originalSupplier.branchData.push({
        branchId: this.selectedBranch.id,
        supplierId: this.originalSupplier.id,
      });
    }

    return _.find(this.originalSupplier._branchData, {
      branchId: this.selectedBranch.id,
    });
  }

  setBranchDataCountry(countryId) {
    if (countryId) {
      this.currentBranchData.countryId = countryId;
      this.currentBranchData.country = _.find(this.getCurrentCountries(), {
        id: countryId,
      });
    } else {
      if (this.getOriginalBranchData().countryId) {
        this.currentBranchData.countryId = 0;
      }
      this.currentBranchData.country = null;
    }
  }

  setBranchDataPaymentTerm(paymentTermId) {
    if (paymentTermId) {
      this.currentBranchData.paymentTermId = paymentTermId;
      this.currentBranchData.paymentTerm = _.find(
        this.getCurrentPaymentTerms(),
        { id: paymentTermId },
      );
    } else {
      if (this.getOriginalBranchData().paymentTermId) {
        this.currentBranchData.paymentTermId = 0;
      }
      this.currentBranchData.paymentTerm = null;
    }
  }

  setBranchDataDeliveryTerm(deliveryTermId) {
    if (deliveryTermId) {
      this.currentBranchData.deliveryTermId = deliveryTermId;
      this.currentBranchData.deliveryTerm = _.find(
        this.getCurrentDeliveryTerms(),
        { id: deliveryTermId },
      );
    } else {
      if (this.getOriginalBranchData().deliveryTermId) {
        this.currentBranchData.deliveryTermId = 0;
      }
      this.currentBranchData.deliveryTerm = null;
    }
  }

  setBranchDataDeliveryMethod(deliveryMethodId) {
    if (deliveryMethodId) {
      this.currentBranchData.deliveryMethodId = deliveryMethodId;
      this.currentBranchData.deliveryMethod = _.find(
        this.getCurrentDeliveryMethods(),
        { id: deliveryMethodId },
      );
    } else {
      if (this.getOriginalBranchData().deliveryMethodId) {
        this.currentBranchData.deliveryMethodId = 0;
      }
      this.currentBranchData.deliveryMethod = null;
    }
  }

  setBranchCustomBuyingRate() {
    this.currentBranchData.useCustomBuyingRate =
      !this.currentBranchData.useCustomBuyingRate;
  }

  setBranchDataCurrency(currencyId) {
    this.selectedCurrency = null;
    if (currencyId) {
      this.currentBranchData.currency = _.find(this.getCurrentCurrencies(), {
        id: currencyId,
      });
      if (this.currentBranchData.currencyId !== currencyId) {
        this.currentBranchData.buyingRate =
          this.currentBranchData.currency.buyingRate;
      }
    } else {
      if (this.getOriginalBranchData().currencyId) {
        this.currentBranchData.currencyId = 0;
      }
      this.currentBranchData.currency = null;
    }
    this.currentBranchData.currencyId = currencyId;
  }

  removeCurrency() {
    this.currentBranchData.currency = null;
    this.currentBranchData.buyingRate = null;
    this.currentBranchData.useCustomBuyingRate = false;
    this.currentBranchData.currencyId = 0;
  }

  getCurrentCountries() {
    const countries = _.filter(this.countries, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.country &&
      !_.some(this.countries, { id: originalBranchData.country.id })
    ) {
      countries.push(originalBranchData.country);
    }
    return countries;
  }

  getCurrentPaymentTerms() {
    const paymentTerms = _.filter(this.paymentTerms, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.paymentTerm &&
      !_.some(this.paymentTerms, { id: originalBranchData.paymentTerm.id })
    ) {
      paymentTerms.push(originalBranchData.paymentTerm);
    }
    return paymentTerms;
  }

  getCurrentDeliveryTerms() {
    const deliveryTerms = _.filter(this.deliveryTerms, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.deliveryTerm &&
      !_.some(this.deliveryTerms, { id: originalBranchData.deliveryTerm.id })
    ) {
      deliveryTerms.push(originalBranchData.deliveryTerm);
    }
    return deliveryTerms;
  }

  getCurrentDeliveryMethods() {
    const deliveryMethods = _.filter(this.deliveryMethods, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.deliveryMethod &&
      !_.some(this.deliveryMethods, {
        id: originalBranchData.deliveryMethod.id,
      })
    ) {
      deliveryMethods.push(originalBranchData.deliveryMethod);
    }
    return deliveryMethods;
  }

  getCurrentCurrencies() {
    const currencies = _.filter(this.currencies, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.currency &&
      !_.some(this.currencies, { id: originalBranchData.currency.id })
    ) {
      currencies.push(originalBranchData.currency);
    }
    return currencies;
  }

  filterCountries(query) {
    return _.orderBy(
      _.filter(this.getCurrentCountries(), (c) =>
        this.HelperService.containsText(c, { name: query, code: query }),
      ),
      'name',
    );
  }

  filterPaymentTerms(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentPaymentTerms(), { name: query }),
      ['displayOrder', 'name'],
    );
  }

  filterDeliveryTerms(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentDeliveryTerms(), { name: query }),
      ['displayOrder', 'name'],
    );
  }

  filterDeliveryMethods(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentDeliveryMethods(), { name: query }),
      ['displayOrder', 'name'],
    );
  }

  filterCurrencies(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentCurrencies(), { name: query }),
      ['displayOrder', 'name'],
    );
  }

  setWatchers() {
    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        if (!hasChanges) {
          this.supplierGeneralForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
    });
  }
}

angular
  .module('main.supplier.management', [])
  .config(config)
  .controller('SupplierManagementController', SupplierManagementController);
