import { httpClient } from '@texas/api/httpClient/httpClient';

export interface Technique {
  id: number;
  code: string;
  value: string;
  description: string;
}

export const techniquesApi = {
  getAll: () => httpClient.get<Technique[]>(`organizations/3/techniques`),
};
