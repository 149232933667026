// @ngInject
const NotificationsApiService = (DS, DSHttpAdapter, $q, ResourcesService) => ({
  notifyAssignedUser: (taskId, senderId, recipientId) =>
    DSHttpAdapter.POST(`${DS.defaults.basePath}notifications/assignedUser/`, {
      taskId,
      senderId,
      recipientId,
    }).then(
      (res) => res,
      (err) => $q.reject(ResourcesService.handleError(err)),
    ),
});

angular
  .module('resources.notifications', [])
  .factory('NotificationsApiService', NotificationsApiService);
