import { PagedItems } from '@texas/types';
import { httpClient } from '../httpClient/httpClient';
import { baseApiUrl } from '../httpClient/constants';

export enum ProductApprovalState {
  Pending = 1,
  Accepted = 2,
  Rejected = 3,
}

export enum ProductApprovalRejectReason {
  Price = 1,
  Quality = 2,
  SampleLeadTime = 3,
  ProductionLeadTime = 4,
  AnswerTime = 5,
  Other = 6,
}

export interface ProductApproval {
  id: number;
  number: string;
  formatNumber: string;
  branchId: number;
  branchName: string;
  supplierId: number;
  supplierName: string;
  variantName: string;
  variantId: number;
  state: ProductApprovalState;
  created: string;
  supplierContactName: string;
  supplierContactId: number;
  customerContactName: string;
  customerContactId: number;
  issuedBy: string;
  matrixNodeXId: number | null;
  matrixNodeXValue: string;
  matrixNodeXName: string;
  matrixNodeYId: number | null;
  matrixNodeYValue: string;
  matrixNodeYName: string;
  imageIdentifier: string | null;
  compositionGroupId: number;
  compositionGroupVersion: number;
  handledDate: string;
  handledBy: string;
  handleReason: ProductApprovalRejectReason[] | null;
  handledNote: string;
}

export interface FilterOptionIds {
  state?: ProductApprovalState;
  branchIds?: number[];
  supplierIds?: number[];
}

export interface ProductApprovalsSearchQuery extends FilterOptionIds {
  articleId: number;
  searchTerm: string;
  sortBy: string;
  sortDesc: boolean;
  page: number;
  pageSize: number;
}

export interface FilterOptionIdsQuery {
  state?: keyof typeof ProductApprovalState;
  branchIds?: number[];
  supplierIds?: number[];
}

export interface CreateProductApprovalData {
  variantId: number;
  supplierId: number;
  branchId: number;
  matrixNodeXId: number | null;
  matrixNodeYId: number | null;
  compositionGroupId: number;
  customerContactId: number;
  supplierContactId: number;
  externalSupplierItemCode: string;
  supplierNote: string;
}

export interface CreateProductApprovalsRequest {
  data: CreateProductApprovalData[];
}

export interface CountProductApprovalsQuery {
  state: ProductApprovalState;
  variantId: number;
  branchId: number;
  supplierId: number;
  matrixNodeXId?: number;
  matrixNodeYId?: number;
  onlyArchived?: boolean;
}

interface Count {
  count: number;
}

export interface RejectPaRequest {
  reasons: ProductApprovalRejectReason[];
  note: string;
}

export interface AcceptPaRequest {
  rejectPendingPas: {
    reasons: ProductApprovalRejectReason[];
    note: string;
    id: number;
  }[];
}

export const productApprovalsApi = {
  get: (id: number) => httpClient.get<ProductApproval>(`productapproval/${id}`),
  getForVariant: (
    variantId: number,
    params?: { state?: ProductApprovalState; branchId?: number },
  ) =>
    httpClient.get<ProductApproval[]>(`productapproval/variant/${variantId}`, {
      params,
    }),
  getAll: (params: ProductApprovalsSearchQuery) =>
    httpClient.get<PagedItems<ProductApproval>>(`productapproval/all`, {
      params,
    }),
  getCount: (params: CountProductApprovalsQuery) =>
    httpClient.get<Count>(`productapproval/count`, {
      params,
    }),
  create: (request: CreateProductApprovalsRequest) =>
    httpClient.post(`productapproval`, { data: request }),
  downloadSupplierProductApproval: (id: number) =>
    `${baseApiUrl}/productapproval/${id}/download/supplier`,
  downloadCustomerProductApproval: (id: number, hideMaterials: boolean) =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${baseApiUrl}/productapproval/${id}/download/customer/${hideMaterials}`,
  rejectPa: (id: number, request: RejectPaRequest) =>
    httpClient.put(`productapproval/${id}/reject`, { data: request }),
  acceptPa: (id: number, request: AcceptPaRequest) =>
    httpClient.put(`productapproval/${id}/accept`, { data: request }),
};
