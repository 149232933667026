// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.paymentTerms', {
    url: '/paymentterms',
    controller: 'PaymentTermsConfigurationController as vm',
    templateUrl: 'main/configuration/paymentTerms/paymentTerms.tpl.html',
    data: { pageTitle: 'Payment terms' },
  });
};

class PaymentTermsConfigurationController {
  constructor(
    $scope,
    DS,
    configurationOptions,
    loadSpinnerService,
    PaymentTermsApiService,
    ConfigItemDialogService,
    currentOrganization,
  ) {
    'ngInject';

    this._ConfigItemDialogService = ConfigItemDialogService;
    this._configurationOptions = configurationOptions;
    this._loadSpinnerService = loadSpinnerService;
    this._PaymentTermsApiService = PaymentTermsApiService;
    this.selectedBranch = {};
    this.branches = currentOrganization.branches;
    this.paymentTerms = [];
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    this.onSelectBranch(configurationOptions.selectedBranch);

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('paymentTerm');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.paymentTerms = DS.filter('paymentTerm', {
            where: {
              branchId: {
                '==': this.selectedBranch.id,
              },
            },
          });
        }
      },
    );
  }

  onSelectBranch(branch) {
    this._configurationOptions.selectedBranch = branch || this.branches[0];
    this.selectedBranch = branch || this.branches[0];
    this.loadPaymentTerms();
  }

  loadPaymentTerms() {
    this._loadSpinnerService.start('mainSpinner');
    this._PaymentTermsApiService
      .getAllByBranchId(this.selectedBranch.id)
      .then((paymentTerms) => {
        this.paymentTerms = paymentTerms;
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }

  openPaymentTerm(paymentTerm) {
    this._ConfigItemDialogService.showDialog({
      configItem: paymentTerm,
      displayOrder: true,
      identifier: true,
      onSave: (paymentTerm) =>
        this._PaymentTermsApiService.updatePaymentTerm(
          _.pick(paymentTerm, [
            'name',
            'identifier',
            'displayOrder',
            'archived',
          ]),
          paymentTerm.id,
        ),
    });
  }

  newPaymentTerm() {
    this._ConfigItemDialogService.showDialog({
      displayOrder: true,
      identifier: true,
      onSave: (paymentTerm) =>
        this._PaymentTermsApiService.newPaymentTerm(
          paymentTerm,
          this.selectedBranch.id,
        ),
    });
  }
}

angular
  .module('configuration.paymentTerms', [])
  .config(config)
  .controller(
    'PaymentTermsConfigurationController',
    PaymentTermsConfigurationController,
  );
