import { Box, Text } from '@chakra-ui/react';

export function BlurTag({
  validText,
  rejectText,
  isValid,
}: {
  validText: string;
  rejectText: string;

  isValid: boolean;
}) {
  return (
    <Box
      position="absolute"
      p={6}
      w="fit-content"
      h="fit-content"
      bg="white"
      borderRadius="full"
      left="50%"
      top="50%"
      transform="translate(-50%,-50%);"
      marginInline="auto"
      textAlign="center"
      boxShadow="lg"
    >
      {isValid ? (
        <Text
          whiteSpace="nowrap"
          variant="header-medium"
          fontSize="1em"
          color="black"
        >
          {validText}
        </Text>
      ) : (
        <Text
          whiteSpace="nowrap"
          variant="header-medium"
          fontSize="1em"
          color="red"
          _light={{ color: 'red' }}
        >
          {rejectText}
        </Text>
      )}
    </Box>
  );
}
