// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.garp', {
    url: '/garp',
    controller: 'GarpConfigurationController as vm',
    templateUrl: 'main/configuration/garp/garp.tpl.html',
    data: { pageTitle: 'Garp' },
  });
};

class GarpConfigurationController {
  constructor(
    loadSpinnerService,
    GarpApiService,
    configurationOptions,
    currentOrganization,
    toasterService,
  ) {
    'ngInject';

    this.loadSpinnerService = loadSpinnerService;
    this.GarpApiService = GarpApiService;
    this.configurationOptions = configurationOptions;
    this.branches = currentOrganization.branches;
    this.toasterService = toasterService;

    this.selectedBranch = {};
    this.validationErrors = [];

    this.selectBranch(configurationOptions.selectedBranch);
  }

  selectBranch(branch) {
    this.configurationOptions.selectedBranch = branch || this.branches[0];
    this.selectedBranch = branch || this.branches[0];
  }

  run(action) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');

    action()
      .then(
        () => this.toasterService.success(),
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  importMetadata() {
    this.run(() => this.GarpApiService.importMetadata(this.selectedBranch.id));
  }

  importMatrixNodes() {
    this.run(() => this.GarpApiService.importMatrixNodes());
  }
}

angular
  .module('configuration.garp', [])
  .config(config)
  .controller('GarpConfigurationController', GarpConfigurationController);
