import {
  FormControl,
  Text,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
  Heading,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import {
  Branch,
  CreateBranchRequest,
  branchesApi,
} from '@texas/api/endpoints/branchesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import { ErrorDetails } from '../../shared/alert/ErrorDetails';
import { SubmitButton } from '../../shared/form/SubmitButton';
import { TexasFormLabel } from '../../shared/form/TexasFormLabel';
import { ModalBadge } from '../../shared/modal/ModalBadge';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';

export interface CreateBranchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBranchCreated: (branch: Branch) => void;
  identifier: string;
}

export function CreateBranchModal(props: CreateBranchModalProps) {
  const schema: Yup.ObjectSchema<CreateBranchRequest> = Yup.object({
    identifier: Yup.string().required().max(100),
    displayOrder: Yup.number().required(),
    name: Yup.string().required().max(100),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateBranchRequest>({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation();
  const toast = useToast();

  const {
    request: createBranchRequest,
    loading,
    error,
  } = useApiRequest(branchesApi.createBranch);

  useEffect(() => {
    setValue('identifier', props.identifier);
  }, [props.identifier, setValue]);

  const onSubmit = async (data: CreateBranchRequest) =>
    await request(
      createBranchRequest,
      [data],
      (data: Branch) => {
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        props.onBranchCreated(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBadge>
          <Text fontWeight="bold">{props.identifier}</Text>
        </ModalBadge>
        <ModalHeader>
          <Heading size="md">{t('branch.createNew')}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {error && <ErrorDetails error={error} />}
            <VStack>
              <FormControl isRequired={true}>
                <TexasFormLabel>{t('branch.branchName')}</TexasFormLabel>
                <Input
                  variant="outline"
                  {...register('name', { required: true })}
                  placeholder={t('general.name')}
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
              <FormControl isRequired={true}>
                <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
                <NumberInput>
                  <NumberInputField
                    {...register('displayOrder', {
                      required: true,
                    })}
                    placeholder="0"
                  />
                </NumberInput>
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <SubmitButton loading={loading}>
              {t('general.create')} {props.identifier}
            </SubmitButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
