import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = config.definePartsStyle({
  item: {
    color: 'white',
    _light: {
      color: 'texas.bg.800',
      _focusWithin: { backgroundColor: 'blackAlpha.50' },
      _hover: { backgroundColor: 'blackAlpha.50' },
    },
  },
  groupTitle: {
    _light: {
      color: 'texas.bg.800',
    },
  },
});

const variants = {
  table: {
    button: {
      background: 'transparent',
      color: 'White',
      _light: {
        color: 'black',
      },
    },
  },
};

export default config.defineMultiStyleConfig({ baseStyle, variants });
