import { Box, Flex, IconButton, Image, Tooltip } from '@chakra-ui/react';
import { Icons } from '@texas/components/shared/Icons';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import placeholderImg from '@texas/assets/placeholder-250.png';
import { useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { acceptedImageFormats } from '@texas/utils/helpers/filesHelper';
import { clientEndpoints } from '@texas/clientEndpoints';
import { useState } from 'react';
import { BlurTag } from '@texas/components/shared/blur/BlurTag';
import { sizeValidator } from './shared';
import { FileUpload } from '@texas/types';
import { FileUploads } from './FileUploads';
import { FileRejections } from './FileRejections';

export function ImageDropzone({
  imageId,
  imageIdentifier,
  imageSrc,
  allowEdit,
  onUpload,
  isUploading,
  uploadFailed,
  fileUploads,
  abortFileUpload,
  onRemovefileOpen,
  onFileBrowserOpen,
  children,
}: {
  imageId: number;
  imageIdentifier: string;
  imageSrc: string;
  allowEdit: boolean;
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  uploadFailed: boolean;
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
  onRemovefileOpen: () => void;
  onFileBrowserOpen: () => void;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const [fileRejections, setFileRejections] = useState<FileRejection[]>();

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      onDrop: handleDrop,
      useFsAccessApi: false,
      noClick: true,
      accept: acceptedImageFormats,
      multiple: false,
      validator: sizeValidator,
    });

  function handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    setFileRejections(fileRejections);

    if (acceptedFiles.length) {
      onUpload(acceptedFiles);
    }
  }

  return (
    <Flex
      animation={fadeInScaleAnimation()}
      direction="column"
      gap={4}
      height="100%"
      {...getRootProps()}
    >
      {(isUploading || uploadFailed) && (
        <FileUploads
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
        />
      )}
      <Flex role="group">
        <Flex position="relative" maxH={imageId ? '300px' : '150px'}>
          <Image
            key={imageId}
            src={imageSrc}
            fallbackSrc={placeholderImg}
            objectFit={imageId ? 'contain' : 'cover'}
            filter={isDragActive ? 'blur(3px)' : 'none'}
          />
          {isDragActive && (
            <BlurTag
              validText={t('fileBrowser.uploadAndReplaceImage')}
              rejectText={t('fileBrowser.notValid')}
              isValid={!isDragReject}
            />
          )}
          {allowEdit && imageIdentifier && (
            <Box
              position="absolute"
              top={-2}
              left={-4}
              opacity={0}
              _groupHover={{ opacity: isDragActive ? 0 : 1 }}
            >
              <Tooltip label={t('general.remove')}>
                <IconButton
                  icon={<Icons.Close boxSize={6} />}
                  aria-label={t('fileBrowser.removeFile')}
                  size="sm"
                  variant="texas-solid"
                  onClick={() => {
                    onRemovefileOpen();
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Flex>
        <Flex
          direction="column"
          gap={1}
          opacity={0}
          _groupHover={{ opacity: isDragActive ? 0 : 1 }}
        >
          {imageIdentifier && (
            <Tooltip label={t('fileBrowser.downloadFile')}>
              <IconButton
                icon={<Icons.Download boxSize={6} />}
                aria-label={t('fileBrowser.downloadFile')}
                size="sm"
                variant="no-bg"
                as="a"
                href={clientEndpoints.downloadFile(imageIdentifier)}
                target="_blank"
                download={true}
              />
            </Tooltip>
          )}
          {allowEdit && (
            <>
              <Tooltip label={t('fileBrowser.chooseFromLibrary')}>
                <IconButton
                  icon={<Icons.OpenInNew boxSize={6} />}
                  aria-label={t('fileBrowser.chooseFromLibrary')}
                  size="sm"
                  variant="no-bg"
                  onClick={() => {
                    onFileBrowserOpen();
                    setFileRejections([]);
                  }}
                />
              </Tooltip>
              <Tooltip label={t('fileBrowser.uploadFile')}>
                <IconButton
                  icon={<Icons.Upload boxSize={6} />}
                  aria-label={t('fileBrowser.uploadFile')}
                  size="sm"
                  variant="no-bg"
                  onClick={open}
                />
              </Tooltip>
            </>
          )}
        </Flex>
        {children}
      </Flex>
      <FileRejections fileRejections={fileRejections} />
      <input multiple={false} {...getInputProps()} />
    </Flex>
  );
}
