import {
  VStack,
  MenuItem,
  useDisclosure,
  Flex,
  IconButton,
  Tooltip,
  Spacer,
} from '@chakra-ui/react';
import { WidgetWrapper } from '../shared/components/WidgetWrapper';
import { useTranslation } from 'react-i18next';
import {
  defaultEmptyFilters,
  useSearchArticles,
} from '../../searchArticles/useSearchArticles';
import { reactEvents } from '../../../../bridge/reactEvents';
import { maxExportArticlesAmount } from '../../searchArticles/SearchArticles';

import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Icons } from '@texas/components/shared/Icons';
import { RowSelectionState } from '@tanstack/react-table';
import { useExportableArticles } from '@texas/components/searchArticles/useExportableArticles';
import { BaseWidgetProps } from '../widgets';
import { SearchArticleOptions } from '../shared/types';
import { ArticlesDataTableContainerFooter } from '@texas/components/shared/dataTable/ArticlesDataTableContainerFooter';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { WidgetType } from '../shared/types';
import { searchApi } from '@texas/api/endpoints/searchApi';
import { emptyPagedItems } from '@texas/utils/helpers/commonHelpers';
import { ActiveFilterModal } from '../shared/components/ArticlesFilterModal';
import { ArticlesCardView } from '@texas/components/shared/views/ArticlesCardView';
import { ArticleSearchView } from '@texas/components/searchArticles/types';
import { PrinterFilter } from '../shared/components/FilterPrinter';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { SearchArticleContext } from './context';

interface Props extends BaseWidgetProps {
  options: SearchArticleOptions;
}

export function ArticlesWidget({
  options,
  customTitle,
  widgetId,
  color,
}: Props) {
  const { t } = useTranslation();

  const search = useSearchArticles({
    defaultFilters: defaultEmptyFilters,
    defaultArticleSearchProps: options.defaultSearchPage,
    defaultPage: options.defaultSearchPage.searchParams.page,
    defaultSearchParams: options.defaultSearchPage.searchParams,
    localStorageKey: options.localStorageKey
      ? `${options.localStorageKey}_${widgetId}`
      : undefined,
    filterOptions: options.filterOptions,
    optOutDefaultOrder: options.optOutDefaultOrdering,
    limit: options.pageSize,
    request: searchApi.searchVariants,
    defaultData: { pagedItems: emptyPagedItems },
  });

  const { articles, searchPage, loading } = search;

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const exportableArticles = useExportableArticles(
    selectedRows,
    articles?.exportArticleItems ?? [],
  );

  const {
    isOpen: isFiltersOpen,
    onClose: onFiltersClosed,
    onOpen: onFiltersOpen,
  } = useDisclosure();

  return (
    <>
      <SearchArticleContext.Provider value={search}>
        <WidgetWrapper
          toolbox={
            <WidgetToolbox options={options} onFiltersOpen={onFiltersOpen} />
          }
          widgetId={widgetId}
          loading={loading}
          settings={options.componentSettings}
          customColor={color}
          type={WidgetType.Articles}
          style="default"
          header={customTitle ?? t('widgets.articles.articles')}
          extendedMenu={
            <>
              <MenuItem
                onClick={() => {
                  reactEvents.excelExportClicked.dispatch({
                    articles: exportableArticles,
                    branchIds: searchPage.searchParams['branchIds'] ?? [],
                    hideBranchIds: false,
                  });
                }}
                isDisabled={
                  Object.keys(selectedRows).length === 0 ||
                  Object.keys(selectedRows).length > maxExportArticlesAmount
                }
                icon={<Icons.FileExport boxSize={5} />}
              >
                {t('general.exportExcel')}
              </MenuItem>
            </>
          }
        >
          <VStack h="100%" w="100%" align="start">
            <InnerWidget
              options={options}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </VStack>
        </WidgetWrapper>
        <ActiveFilterModal
          search={search}
          widgetRenderComponent={
            <InnerWidget
              overrideView="table"
              options={options}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          }
          isOpen={isFiltersOpen}
          onClose={onFiltersClosed}
        />
      </SearchArticleContext.Provider>
    </>
  );
}

function InnerWidget({
  options,
  selectedRows,
  setSelectedRows,
  overrideView,
}: {
  options: SearchArticleOptions;
  selectedRows: RowSelectionState;
  setSelectedRows: Dispatch<SetStateAction<RowSelectionState>>;
  overrideView?: ArticleSearchView;
}) {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return options.columns(t);
  }, [options, t]);

  const search = useActiveContext(SearchArticleContext);
  const {
    setSearchParamsWithRef,
    searchParamsRef,
    searchPage,
    articles,
    loading,
    error,
    view,
  } = search;

  return (
    <>
      <PrinterFilter search={search} />
      <DataTableContainer
        hidden={(overrideView ?? view) !== 'table'}
        p={4}
        w="100%"
        minH={0}
        display="flex"
        flexDir="column"
        error={error}
        css={{ ' th, td': { whiteSpace: 'normal' } }}
        datatable={{
          data: articles?.pagedItems.items ?? [],
          columns: columns,
          isLoading: loading,
          selectionProps: {
            setSelectedRows: setSelectedRows,
            selectedRows: selectedRows,
          },
          rowProps: {
            getRowId: (originalRow, _) => {
              return originalRow.articleId.toString();
            },
          },
          sorted: {
            onSortedChange: ({ key, desc }) => {
              setSearchParamsWithRef({
                ...searchParamsRef.current,
                sortBy: key,
                sortDesc: desc,
                page: options.defaultSearchPage.searchParams.page,
              });
            },
            key: searchPage.searchParams['sortBy'],
            desc: searchPage.searchParams['sortDesc'],
          },
        }}
        pagination={{
          totalItems: articles?.pagedItems.totalItems ?? 0,
          pageSize: options.pageSize,
          currentPage: searchPage.searchParams.page,
          onPageChange: (page) =>
            setSearchParamsWithRef({
              ...searchParamsRef.current,
              page,
            }),
        }}
        footer={
          <ArticlesDataTableContainerFooter
            selectedRows={Object.keys(selectedRows).length}
            onClear={() => setSelectedRows({})}
          />
        }
      />
      <Flex
        hidden={(overrideView ?? view) !== 'card'}
        w="100%"
        direction="column"
        overflowX="auto"
        overflowY="hidden"
        pb={2}
      >
        <ArticlesCardView
          articles={articles}
          searchPage={searchPage}
          searchParamsRef={searchParamsRef}
          setSearchParamsWithRef={setSearchParamsWithRef}
          error={error}
          loading={loading}
        />
      </Flex>
    </>
  );
}

function WidgetToolbox({
  options,
  onFiltersOpen,
}: {
  options: SearchArticleOptions;
  onFiltersOpen: () => void;
}) {
  const { t } = useTranslation();
  const { view, handleViewChange } = useActiveContext(SearchArticleContext);
  return (
    <Flex gap={2}>
      <Tooltip label={t('view.tableView')}>
        <IconButton
          variant={view === 'table' ? 'texas-solid' : 'texas-light'}
          size="sm"
          icon={<Icons.viewTable />}
          aria-label={t('view.tableView')}
          onClick={() => handleViewChange('table')}
        />
      </Tooltip>
      <Tooltip label={t('view.cardView')}>
        <IconButton
          variant={view === 'card' ? 'texas-solid' : 'texas-light'}
          size="sm"
          icon={<Icons.viewCard />}
          aria-label={t('view.cardView')}
          onClick={() => handleViewChange('card')}
        />
      </Tooltip>
      {options.componentSettings?.editFilters !== 'hidden' && (
        <>
          <Spacer />
          <Tooltip label={t('widgets.changeFilters')}>
            <IconButton
              size="sm"
              variant="texas-light"
              icon={<Icons.FilterVariant />}
              aria-label={t('widgets.changeFilters')}
              onClick={() => onFiltersOpen()}
            />
          </Tooltip>
        </>
      )}
    </Flex>
  );
}
