import { FieldValues } from 'react-hook-form';
import { AutoUpdateFormState } from '../../components/shared/autoUpdate/useAutoUpdateForm';
import {
  AutoUpdateRequest,
  AutoUpdateResponse,
  AutoUpdateRequestWithData,
  AutoUpdateFieldType,
} from '@texas/api/endpoints/autoUpdateTypes';
import { ApiRequest } from '@texas/api/hooks/useApiRequest';

export type AutoUpdateRequestType<
  TRequest extends FieldValues,
  TDto,
> = ApiRequest<
  [id: number, request: AutoUpdateRequest<TRequest>],
  AutoUpdateResponse<TDto>
>;
export type AutoUpdateRequestWithDataType<
  TRequest extends FieldValues,
  TDto,
  TData,
> = ApiRequest<
  [id: number, request: AutoUpdateRequestWithData<TRequest, TData>],
  AutoUpdateResponse<TDto>
>;

export enum AutoUpdateRequestState {
  Pending,
  Failed,
  Success,
}

export interface CommandCallbackArgs<TDto> {
  rows?: AutoUpdateFieldType[];
  state: AutoUpdateRequestState;
  response?: AutoUpdateFormState<TDto>;
}

export interface AutoUpdateCommand<
  TRequest extends FieldValues,
  TDto,
  TData = any,
> {
  initialFormValues: TRequest;
  unmodifiedServerValues: TRequest;
  formValues: TRequest;
  forceUpdate: boolean;
  id: number;
  formId: string;
  additionalData?: TData;
  apiRequest:
    | AutoUpdateRequestType<TRequest, TDto>
    | AutoUpdateRequestWithDataType<TRequest, TDto, TData>;
  stateUpdate: (args: CommandCallbackArgs<TDto>) => void;
}
