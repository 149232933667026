// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.suppliers', {
    controller: 'SuppliersController as vm',
    parent: 'variant',
    url: '/suppliers',
    templateUrl: 'main/article/variant/suppliers/suppliers.tpl.html',
    data: { pageTitle: 'Suppliers' },
  });
};

class SuppliersController {
  constructor(variant) {
    'ngInject';

    this.variant = variant;
  }
}

angular
  .module('main.article.variant.suppliers', [])
  .config(config)
  .controller('SuppliersController', SuppliersController);
