import {
  Box,
  Grid,
  GridItem,
  Flex,
  Text,
  Card,
  CardBody,
  Tag,
  TagLabel,
  TagCloseButton,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { Icons } from '@texas/components/shared/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderOverview } from './FolderOverview';
import { FolderListItem } from './FolderListItem';
import { FileBrowserMode } from './FileBrowserModal';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { fileEvents } from '../dropzone/events';
import { DropZone } from '../dropzone/DropZone';

export function FileBrowserContent({
  rootFolderId,
  mode = 'Edit',
  onSelect,
  extensionFilter,
  rejectExtensionFilter,
}: {
  rootFolderId: number;
  mode?: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  extensionFilter?: string[];
  rejectExtensionFilter?: string[];
}) {
  const { t } = useTranslation();
  const [selectedFolderId, setSelectedFolderId] =
    useState<number>(rootFolderId);
  const [breadCrumbFolders, setBreadCrumbFolders] = useState<string[]>([]);

  const onUploadcomplete = useCallback((file: FileLink) => {
    fileEvents.fileUploadComplete.dispatch(file);
  }, []);

  const { fileUploads, uploadFiles, abortFileUpload, resetFileUploads } =
    useFileUploads(onUploadcomplete);

  useEffect(() => {
    resetFileUploads();
  }, [selectedFolderId, resetFileUploads]);

  return (
    <Card variant="dark">
      <CardBody>
        <Grid
          templateAreas={`"nav main"`}
          gridTemplateRows="1fr"
          gridTemplateColumns="min-content auto"
          gap={4}
        >
          <GridItem _light={{ bg: 'white' }} area="nav">
            <Flex direction="column">
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                borderRadius="md"
                gap={2}
                mb={4}
              >
                <Icons.FolderOutline
                  _light={{ color: 'texas.bg.800' }}
                  boxSize={7}
                />
                <Text variant="header-medium" fontSize="2xl">
                  {t('fileBrowser.folders')}
                </Text>
              </Flex>
              <FolderListItem
                level={0}
                folderId={rootFolderId}
                mode={mode}
                selectedFolder={selectedFolderId}
                setSelectedFolder={setSelectedFolderId}
                setBreadCrumbFolders={setBreadCrumbFolders}
              />
            </Flex>
          </GridItem>
          <GridItem
            _light={{ bg: 'white', borderColor: 'gray.50' }}
            area="main"
            pl={6}
            borderLeft="1px solid"
            borderColor="gray.900"
          >
            <Text variant="header-medium" fontSize="2xl" pb={4}>
              {t('fileBrowser.files')}
            </Text>
            {mode === 'Edit' && (
              <Flex>
                <Box w={80} pb={4}>
                  <DropZone
                    multiple={true}
                    onUpload={(files) => uploadFiles(files, selectedFolderId)}
                  />
                </Box>
                <Flex
                  py={4}
                  pl={4}
                  gap={4}
                  alignContent="flex-start"
                  flexWrap="wrap"
                >
                  {fileUploads.map((upload) => {
                    if (upload.type === 'failed')
                      return (
                        <Tooltip label={upload.error?.message}>
                          <Tag
                            key={upload.uuid}
                            variant="lighter"
                            h="fit-content"
                            py={2}
                            height={8}
                          >
                            <TagLabel mr={2}>{upload.fileName}</TagLabel>
                            <Icons.Close
                              boxSize={4}
                              color="red.300"
                              _light={{ color: 'red.500' }}
                            />
                          </Tag>
                        </Tooltip>
                      );

                    return (
                      <Tag
                        key={upload.uuid}
                        variant="lighter"
                        w="fit-content"
                        py={2}
                        height={8}
                      >
                        <TagLabel mr={2}>{upload.fileName}</TagLabel>
                        {upload.type === 'uploading' && (
                          <Flex alignItems="center">
                            <Spinner size="xs" />
                            <TagCloseButton
                              onClick={() => abortFileUpload(upload)}
                            />
                          </Flex>
                        )}
                        {upload.type === 'uploaded' && (
                          <Icons.Checkmark
                            boxSize={4}
                            color="green.300"
                            _light={{ color: 'green.500' }}
                          />
                        )}
                      </Tag>
                    );
                  })}
                </Flex>
              </Flex>
            )}
            {selectedFolderId && (
              <FolderOverview
                id={selectedFolderId}
                mode={mode}
                breadCrumbFolders={breadCrumbFolders}
                onSelect={onSelect}
                extensionFilter={extensionFilter}
                rejectExtensionFilter={rejectExtensionFilter}
              />
            )}
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
}
