// @ngInject
const AuthService = (
  $rootScope,
  $q,
  $state,
  $window,
  UsersApiService,
  LoginDialogService,
  AuthApiService,
) => {
  let _isAuthenticated = false;
  let _isAuthenticating = false;
  let _user = null;

  const isAuthenticated = () => {
    return _isAuthenticated;
  };

  const hasAnyClaim = (claims) => {
    if (!isAuthenticated() || !_user.claims) {
      return false;
    }

    for (let i = 0; i < claims.length; i += 1) {
      if (!_.isEmpty(_.find(_user.claims, { value: claims[i] }))) {
        return true;
      }
    }

    return false;
  };

  const showLogin = () => {
    if (_isAuthenticating) {
      return;
    }

    const loginOptions = {
      user: _user ? { email: _user.email } : null,
    };

    _isAuthenticating = true;
    LoginDialogService.showDialog(loginOptions)
      .then(() => {
        if ($rootScope.toState) {
          $state.go($rootScope.toState.name, $rootScope.toParams, {
            replace: true,
          });
        }
      })
      .finally(() => {
        _isAuthenticating = false;
      });
  };

  const getUser = () => {
    const deferred = $q.defer();

    // If we already have the user, just return it
    if (_user) {
      deferred.resolve(_user);
    } else {
      UsersApiService.getSelf().then(
        (user) => {
          _user = user;
          _isAuthenticated = true;
          deferred.resolve(_user);
        },
        () => {
          deferred.reject();
        },
      );
    }

    return deferred.promise;
  };

  const logout = () => {
    AuthApiService.logout().then(() => {
      // Reload the app
      $window.location.href = '/';
    });
  };

  return {
    isAuthenticated,
    hasAnyClaim,
    getUser,
    showLogin,
    logout,
  };
};

angular.module('security.authService', []).factory('AuthService', AuthService);
