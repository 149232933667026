import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
} from '@chakra-ui/react';
import { ServerError } from '../../../types';
import { ErrorsList } from '../ErrorsList';

export function ErrorDetails({ error }: { error: ServerError | null }) {
  if (!error) {
    return null;
  }

  return (
    <Alert status="error">
      <AlertIcon />
      <Flex flexDir="column">
        <AlertTitle>{error.message}</AlertTitle>
        <AlertDescription>
          <ErrorsList errors={error.errors} />
        </AlertDescription>
      </Flex>
    </Alert>
  );
}
