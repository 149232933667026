// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.countries', {
    url: '/countries',
    controller: 'CountriesConfigurationController as vm',
    templateUrl: 'main/configuration/countries/countries.tpl.html',
    data: { pageTitle: 'Countries' },
  });
};

class CountriesConfigurationController {
  constructor(
    $scope,
    DS,
    CountriesApiService,
    loadSpinnerService,
    CountryDialogService,
    configurationOptions,
    currentOrganization,
  ) {
    'ngInject';

    this._CountryDialogService = CountryDialogService;
    this._CountriesApiService = CountriesApiService;
    this._loadSpinnerService = loadSpinnerService;
    this._configurationOptions = configurationOptions;
    this.countries = [];
    this.selectedBranch = {};
    this.branches = currentOrganization.branches;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'name',
      limit: 50,
      page: 1,
    };

    this.onSelectBranch(configurationOptions.selectedBranch);

    $scope.$watch(
      () => {
        return DS.lastModified('country');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.countries = CountriesApiService.getAllFromCache(
            this.selectedBranch.id,
          );
        }
      },
    );
  }

  onSelectBranch(branch) {
    this._configurationOptions.selectedBranch = branch || this.branches[0];
    this.selectedBranch = branch || this.branches[0];
    this.loadCountries();
  }

  loadCountries() {
    this._loadSpinnerService.start('mainSpinner');
    this._CountriesApiService
      .getAllByBranchId(this.selectedBranch.id)
      .then((countries) => {
        this.countries = countries;
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }

  newCountry() {
    this._CountryDialogService.showDialog({ branch: this.selectedBranch });
  }

  openCountry(country) {
    this._CountryDialogService.showDialog({
      branch: this.selectedBranch,
      country,
    });
  }
}

angular
  .module('configuration.countries', [])
  .config(config)
  .controller(
    'CountriesConfigurationController',
    CountriesConfigurationController,
  );
