import { Box, Flex, Text } from '@chakra-ui/react';
import {
  ProductApproval,
  ProductApprovalState,
} from '@texas/api/endpoints/productApprovalsApi';
import { Icons } from '@texas/components/shared/Icons';
import {
  fadeInScaleAnimation,
  fadeInScalePopAnimation,
} from '@texas/resources/animations/animations';
import { PaMetadataList } from './PaMetadataList';

export function ProductApprovalInfo({
  pa,
  state,
}: {
  pa: ProductApproval;
  state: ProductApprovalState;
}) {
  const isAccepted = state === ProductApprovalState.Accepted;
  return (
    <Box
      animation={fadeInScaleAnimation()}
      borderRadius="md"
      p={4}
      bg={isAccepted ? '#2B281F' : 'texas.bg.800'}
      _light={{
        bg: isAccepted ? '#fffef6' : 'white',
      }}
      border="1px solid"
      borderColor={isAccepted ? 'texas.sand.100' : 'gray.300'}
    >
      <Flex pb={6} justifyContent="space-between">
        <Text fontFamily="Barlow" fontSize="lg" fontWeight="bold">
          {pa.formatNumber}
        </Text>
        {isAccepted ? (
          <Icons.CheckmarkDecagram
            opacity={0}
            sx={{ animationFillMode: 'forwards' }}
            animation={fadeInScalePopAnimation(1000, 300)}
            color="texas.sand.100"
            _light={{ color: 'texas.burntSienna.500' }}
            boxSize={8}
          />
        ) : (
          <Icons.Cancel
            opacity={0}
            sx={{ animationFillMode: 'forwards' }}
            animation={fadeInScalePopAnimation(1000, 300)}
            boxSize={8}
          />
        )}
      </Flex>
      <PaMetadataList isAccepted={isAccepted} pa={pa} />
    </Box>
  );
}
