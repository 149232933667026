// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('article.productApproval', {
    controller: 'ProductApprovalController as vm',
    parent: 'article',
    url: '/product-approval',
    templateUrl: 'main/article/productApproval/productApproval.tpl.html',
    data: { pageTitle: 'Product approval' },
  });
};

class ProductApprovalController {
  constructor(article) {
    'ngInject';

    this.article = article;
  }
}

angular
  .module('main.article.productApproval', [])
  .config(config)
  .controller('ProductApprovalController', ProductApprovalController);
