import { Card } from '@chakra-ui/react';

export function TemplateCard({
  children,
  exists,
}: {
  children: React.ReactNode;
  exists: boolean;
}) {
  return (
    <Card
      variant="composition-template"
      w="300px"
      h="700px"
      overflowY="auto"
      {...(!exists
        ? {
            border: '1px dashed',
            borderColor: 'texas.bg.700',
            opacity: 0.6,
            _light: { borderColor: 'gray.200' },
          }
        : null)}
    >
      {children}
    </Card>
  );
}
