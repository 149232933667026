import { Box } from '@chakra-ui/react';

export function ColorDot({ hex }: { hex: string }) {
  return (
    <Box
      boxSize="4"
      borderRadius="full"
      border="1px solid gray"
      bg={`#${hex}`}
    />
  );
}
