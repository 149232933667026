import { Box, Card, CardBody, Flex, Spinner, Text } from '@chakra-ui/react';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { GeneralInfoForm } from './GeneralInfoForm';
import { GeneralFiles } from './files/GeneralFiles';
import { useTranslation } from 'react-i18next';
import { BodyOverride } from '@texas/components/shared/BodyOverride';

export function General({ id }: { id: number }) {
  const { t } = useTranslation();
  const {
    data: variantInfo,
    refetch: refetchVariantInfo,
    loading: loadingVariantInfo,
    error,
  } = useApiResource(variantsApi.getVariantInfo);

  useEffect(() => {
    refetchVariantInfo(id);
  }, [refetchVariantInfo, id]);

  return (
    <BodyOverride>
      <Card variant="dark">
        <CardBody>
          <Flex w="100%" justifyContent="space-between">
            <Box w="60%">
              <GeneralFiles variantId={id} />
            </Box>
            <Box w="40%" ml={4}>
              {loadingVariantInfo && (
                <Flex gap={4}>
                  <Text>{t('general.loading')}</Text>
                  <Spinner />
                </Flex>
              )}
              {variantInfo && <GeneralInfoForm variantInfo={variantInfo} />}
              {!variantInfo && <ErrorLabel text={error?.message} />}
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </BodyOverride>
  );
}
