import { PagedItems } from '@texas/types';
import { httpClient } from '../httpClient/httpClient';
import { defaultPageSize } from '@texas/components/shared/dataTable/defaultTableOptions';

export interface Contact {
  id: number;
  name: string;
  email: string;
  archived?: string | null;
  customers: Customer[];
}

export interface Customer {
  id: number;
  name: string;
  identifier: string;
  archived: boolean;
}

export interface ContactListItem {
  id: number;
  name: string;
  email: string;
  archived?: string | null;
  customerIds: number[];
}

export interface SearchQuery {
  sortBy: string;
  sortDesc: boolean;
  searchTerm: string;
  page: number;
  pageSize: number;
  includeArchived: boolean;
  customerId: number | null;
  supplierId: number | null;
}

export const defaultSearchQuery: SearchQuery = {
  sortBy: 'name',
  sortDesc: false,
  searchTerm: '',
  page: 1,
  pageSize: defaultPageSize,
  includeArchived: false,
  customerId: null,
  supplierId: null,
};

export enum ContactAddToType {
  Customer = 1,
  Supplier = 2,
}

export interface CreateContactRequest {
  name: string;
  email: string;
  referenceId: number;
  addTo: ContactAddToType;
}

export interface UpdateContactRequest {
  name: string;
  email: string;
}

export interface ConnectContactRequest {
  id: number;
  referenceId: number;
}

export const contactsApi = {
  get: (id: number) => httpClient.get<Contact>(`contacts/${id}`),
  getAll: (params: SearchQuery) =>
    httpClient.get<PagedItems<ContactListItem>>(`contacts/all`, {
      params,
    }),
  create: (request: CreateContactRequest) =>
    httpClient.post<Contact>(`contacts`, {
      data: request,
    }),
  update: (id: number, request: UpdateContactRequest) =>
    httpClient.put<Contact>(`contacts/${id}`, {
      data: request,
    }),
  connectCustomer: (request: ConnectContactRequest) =>
    httpClient.put<Contact>(`contacts/connect/customer`, { data: request }),
  disconnectCustomer: (request: ConnectContactRequest) =>
    httpClient.put<Contact>(`contacts/disconnect/customer`, { data: request }),
  connectSupplier: (request: ConnectContactRequest) =>
    httpClient.put<Contact>(`contacts/connect/supplier`, { data: request }),
  disconnectSupplier: (request: ConnectContactRequest) =>
    httpClient.put<Contact>(`contacts/disconnect/Supplier`, { data: request }),
  archive: (id: number) => httpClient.put(`contacts/${id}/archive`),
  restore: (id: number) => httpClient.put(`contacts/${id}/restore`),
};
