// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.qualities', {
    url: '/qualities',
    templateUrl: 'main/configuration/qualities/qualities.tpl.html',
    data: { pageTitle: 'Qualities' },
  });
};

angular.module('configuration.qualities', []).config(config);
