export interface SearchQuery {
  sortBy: string;
  sortDesc: boolean;
  searchTerm: string;
  includeArchived: boolean;
  preciseIdFiltering: boolean;
  productGroupId: number | null;
}

export const defaultSearchQuery: SearchQuery = {
  sortBy: 'code',
  sortDesc: false,
  searchTerm: '',
  includeArchived: false,
  preciseIdFiltering: true,
  productGroupId: null,
};

export interface ProductGroupItem {
  id: number;
  value: string;
}
