// @ngInject
const noDirty = () => ({
  require: 'ngModel',

  link: function link(scope, element, attrs, ngModelCtrl) {
    ngModelCtrl.$setDirty = angular.noop;
    ngModelCtrl.$setValidity = angular.noop;
  },
});

angular.module('directives.noDirty', []).directive('noDirty', noDirty);
