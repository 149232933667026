import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ArchiveMaterial } from './ArchiveMaterial';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { materialTableColumns } from './Columns';
import { EditMaterial } from './EditMaterial';
import { CreateMaterial } from './CreateMaterial';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { Container } from '@texas/components/shared/Container';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { TexasSwitch } from '@texas/components/shared/form/TexasSwitch';
import {
  Material,
  SearchQuery,
  materialsApi,
} from '@texas/api/endpoints/metadata/materialsApi';

export function Materials() {
  const {
    onOpen: onCreateOpen,
    onClose: onCreateClose,
    isOpen: isCreateOpen,
  } = useDisclosure();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<Material>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<number>();

  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: materials,
    refetch,
    error,
    loading,
  } = useApiResource(materialsApi.search);

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    includeArchived: false,
    searchTerm: '',
    sortBy: 'code',
    sortDesc: false,
  });

  const { request: restoreMaterialRequest } = useApiRequest(
    materialsApi.restore,
  );

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restoreMaterialRequest,
        [id],
        () => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(searchQuery);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [restoreMaterialRequest, toast, t, refetch, searchQuery],
  );

  const mixedColumns = useMemo(() => {
    return materialTableColumns(
      t,
      true,
      onArchiveOpen,
      performRestore,
      onEditOpen,
    );
  }, [t, onArchiveOpen, performRestore, onEditOpen]);
  const columns = useMemo(() => {
    return materialTableColumns(
      t,
      false,
      onArchiveOpen,
      performRestore,
      onEditOpen,
    );
  }, [t, onArchiveOpen, performRestore, onEditOpen]);

  return (
    <Container title={t('general.materials')}>
      {archiveValue && (
        <ArchiveMaterial
          material={archiveValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchive={() => {
            refetch(searchQuery);
          }}
        />
      )}

      <EditMaterial
        materialId={editValue}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onUpdated={(_) => {
          refetch(searchQuery);
        }}
      />

      <CreateMaterial
        onCreated={() => {
          refetch(searchQuery);
          onCreateClose();
        }}
        onClose={onCreateClose}
        isOpen={isCreateOpen}
      />

      <VStack w="100%" alignItems="start" p={4}>
        <Flex justify="space-between" w="100%">
          <Flex direction="column" gap={4}>
            <Flex gap={4} align="flex-end">
              <Box height="fit-content" mt="auto" minWidth={80}>
                <SearchFilterInput
                  value={searchQuery.searchTerm}
                  onChange={(s) =>
                    setSearchQuery({ ...searchQuery, searchTerm: s })
                  }
                />
              </Box>
            </Flex>
            <TexasSwitch
              checked={searchQuery.includeArchived}
              label={t('general.includeArchived')}
              onChange={(checked) =>
                setSearchQuery({
                  ...searchQuery,
                  includeArchived: checked,
                })
              }
            />
          </Flex>
          <Button variant="texas-solid" onClick={onCreateOpen}>
            {t('general.addNew')}
          </Button>
        </Flex>
        <Tabs>
          <TabList>
            <Tab>{t('general.mixedMaterails')}</Tab>
            <Tab>{t('general.materials')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DataTableContainer
                w="100%"
                datatable={{
                  data:
                    materials?.filter((x) => x.combinationOf.length > 0) ?? [],
                  sorted: {
                    onSortedChange: ({ key, desc }) => {
                      setSearchQuery({
                        ...searchQuery,
                        sortBy: key,
                        sortDesc: desc,
                      });
                    },
                    key: searchQuery.sortBy,
                    desc: searchQuery.sortDesc,
                  },
                  isLoading: loading,
                  columns: mixedColumns,
                  variant: 'configuration',
                }}
                error={error}
              />
            </TabPanel>
            <TabPanel>
              <DataTableContainer
                w="100%"
                datatable={{
                  data:
                    materials?.filter((x) => x.combinationOf.length == 0) ?? [],
                  sorted: {
                    onSortedChange: ({ key, desc }) => {
                      setSearchQuery({
                        ...searchQuery,
                        sortBy: key,
                        sortDesc: desc,
                      });
                    },
                    key: searchQuery.sortBy,
                    desc: searchQuery.sortDesc,
                  },
                  isLoading: loading,
                  columns: columns,
                  variant: 'configuration',
                }}
                error={error}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Container>
  );
}
