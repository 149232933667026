const defaultTabSettings = {
  hideBadges: true,
};

class TasksTabsController {
  constructor(
    $state,
    TasksApiService,
    loadSpinnerService,
    toasterService,
    currentUser,
    customer,
    concept,
    article,
    tasksTabSettings,
  ) {
    'ngInject';

    this.TasksApiService = TasksApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
    this.currentUser = currentUser;
    this.customer = customer;
    this.concept = concept;
    this.article = article;
    this.$state = $state;

    this.newTaskValidationErrors = [];
    this.updateTaskValidationErrors = [];
    this.selectedTask = {};
    this.newTask = { title: '' };
    this.tasks = [];
    this.completed = false;
    this.tasksTabSettings = _.merge({}, defaultTabSettings, tasksTabSettings);

    this.loadTasks();
  }

  loadTasks() {
    const options = {};

    this.loadSpinnerService.start('mainSpinner');
    this.getTasks(options)
      .then((tasks) => {
        this.tasks = tasks;
        if (this.$state.params.toDoTaskId !== null) {
          this.selectTask(
            _.find(this.tasks, { id: this.$state.params.toDoTaskId }),
          );
        }
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  selectTask(task) {
    if (!task) {
      this.selectedTask = {};
      return;
    }
    if (task.id === this.selectedTask.id) {
      return;
    }

    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.getById(task.id, {
      includeRelations: ['creator', '_taskUsers.user'],
    })
      .then((task) => {
        this.selectedTask = task;
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  addNewTask() {
    this.newTaskValidationErrors = [];
    if (_.isEmpty(this.newTask.title.trim())) {
      return;
    }
    this.loadSpinnerService.start('mainSpinner');
    this.createNewTask(this.newTask)
      .then(
        (task) => {
          this.toasterService.success();
          this.tasks.unshift(task);
          this.newTask.title = '';
          this.newTaskForm.$setPristine();
          this.selectTask(task);
        },
        (error) => {
          this.newTaskValidationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  deleteTask(task) {
    _.remove(this.tasks, { id: task.id });
    this.selectTask(null);
  }

  assignToMe(task) {
    if (_.some(task.assignedUsers, { userId: this.currentUser.id })) {
      return;
    }
    this.loadSpinnerService.start('mainSpinner');
    const newAssignUsers = _.clone(task.assignedUsers);
    newAssignUsers.push({
      userId: this.currentUser.id,
      user: this.currentUser,
      confirmation: 'Accepted',
    });
    this.TasksApiService.updateTask(task.id, { assignedUsers: newAssignUsers })
      .then(() => {
        this.toasterService.success();
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  createNewTask(data) {
    if (this.article) {
      return this.TasksApiService.newTaskWithArticleId(data, this.article.id);
    } else if (this.concept) {
      return this.TasksApiService.newTaskWithConceptId(data, this.concept.id);
    } else if (this.customer) {
      return this.TasksApiService.newTaskWithCustomerId(data, this.customer.id);
    }
  }

  getTasks(options) {
    if (this.article) {
      return this.TasksApiService.getAllByArticleId(this.article.id, options);
    } else if (this.concept) {
      return this.TasksApiService.getAllByConceptId(this.concept.id, options);
    } else if (this.customer) {
      return this.TasksApiService.getAllByCustomerId(this.customer.id, options);
    }
  }

  toggleDoneStatus(task) {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.updateTask(task.id, { isDone: task.isDone })
      .then(() => {
        this.toasterService.success();
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  filterTasks() {
    return (task) => {
      if (this.completed) {
        return !!task.doneDate;
      } else {
        return !task.doneDate;
      }
    };
  }

  setCompleted(completed) {
    this.completed = completed;
  }
}

angular
  .module('tabs.tasks', [])
  .controller('TasksTabsController', TasksTabsController);
