import { BrandsDto } from '@texas/api/endpoints/customersApi';
import { SignalDispatcher } from 'ste-signals';
import { SimpleEventDispatcher } from 'ste-simple-events';

export const angularEvents = {
  currentUserFetched: new SignalDispatcher(),
  pollReceived: new SimpleEventDispatcher<number>(),
  newBrandCreated: new SimpleEventDispatcher<BrandsDto>(),
  addSupplierClicked: new SimpleEventDispatcher<{
    variantId: number;
    branchId: number;
    branchName: string;
  }>(),
};
