// @ngInject
const ErrorLoggerService = ($injector) => {
  let active = false;
  return {
    init(options) {
      options = options || {};

      Raygun.init(options.apiKey).attach().withTags(['frontend']);
      Raygun.setVersion(options.version);
      active = true;
    },
    logException(exception, cause) {
      const state = $injector.get('$state');
      if (!active) {
        return;
      }

      Raygun.send(exception, { cause, state: state.current });
    },
  };
};

angular
  .module('services.errorLogger', [])
  .service('ErrorLoggerService', ErrorLoggerService);
