import { FieldValueType } from '@texas/api/endpoints/variantsApi';
import { PagedItems } from '../../types';
import queryString from 'query-string';
import splitOnFirst from 'split-on-first';

export const EMPTY_OBJECT = {};
const seperator = '|';

export const emptyPagedItems: PagedItems<any> = {
  items: [],
  totalItems: 0,
};

export const queryStringToObject = <T>() => {
  const split = splitOnFirst(location.hash, '?');

  if (split.length !== 2 || split[1] === undefined) return;

  return queryString.parse(split[1], {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: seperator,
  }) as T;
};

export const objectToQueryString = <T>(obj: T | undefined) => {
  if (!obj) return '';

  return `?${Object.entries(obj)
    .map((k) => {
      const isArr = Array.isArray(k[1]);
      const arrFormat = isArr ? '[]' : '';
      return `${k[0]}${arrFormat}=${
        isArr ? (k[1] as any[]).join(seperator) : (k[1] as any)
      }`;
    })
    .join('&')}`;
};

export function addSpaceBeforeUppercaseLetter(str: string) {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

export function formatValue(value: any, type: FieldValueType) {
  if (!value) return null;

  switch (type) {
    case FieldValueType.Text:
      return value;
    case FieldValueType.Percentage:
      return `${value}%`;
    default:
      return value;
  }
}

export function onlyUnique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

export const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};
