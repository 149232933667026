import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  Input,
  Flex,
  useColorMode,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getWidgetConfigs } from '../../widgets';
import { useRef, useState } from 'react';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { SelectColor } from './SelectColor';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import {
  WidgetType,
  defaultWidgetColor,
  defaultWidgetLightColor,
} from '../types';

interface Props extends SharedDisclosureProps {
  name: string;
  type: WidgetType;
  color: string | null;
  onUpdate: (name: string, color: string) => void;
}

export function EditWidget({
  isOpen,
  onClose,
  name,
  color,
  type,
  onUpdate,
}: Props) {
  const { t } = useTranslation();
  const configs = getWidgetConfigs(t);

  const [widgetName, setWidgetName] = useState(name);
  const [newColor, setNewColor] = useState<string | null>(color);
  const ref = useRef<HTMLInputElement>(null);
  const { colorMode } = useColorMode();

  function updateWidget(name: string, color: string) {
    onClose();
    onUpdate(name, color);
  }

  return (
    <>
      <Modal size="2xl" isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('widgets.update')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={4}>
              <TexasInputWrapper label={t('widgets.name')}>
                <Input
                  ref={ref}
                  value={widgetName}
                  placeholder={configs[type].label}
                  onChange={(e) => setWidgetName(e.target.value)}
                />
              </TexasInputWrapper>
              <FormControl>
                <TexasFormLabel pb={1}>
                  {t('widgets.selectColor')}
                </TexasFormLabel>
                <SelectColor
                  selectedColor={newColor}
                  onChange={(hex) => setNewColor(hex)}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter
            backgroundColor="gray.800"
            _light={{ backgroundColor: 'gray.50' }}
            gap={2}
          >
            <Button onClick={onClose}>{t('general.close')}</Button>
            <Button
              variant="texas-solid"
              onClick={() => {
                updateWidget(
                  widgetName,
                  newColor ??
                    (colorMode === 'dark'
                      ? defaultWidgetColor
                      : defaultWidgetLightColor),
                );
              }}
            >
              {t('general.update')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
