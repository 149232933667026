import FinalizeError from '@texas/api/dto/variant/finalizeErrorTypes';
import { ProjectContainer } from '@texas/components/customer/projects/ProjectContainer';

interface HandleFinalizeErrorContainerProps {
  validation: FinalizeError;
  onResolved?: () => void;
}

export function HandleFinalizeErrorContainer(
  props: HandleFinalizeErrorContainerProps,
) {
  if (props.validation.type === 'ProjectIsMissing') {
    return (
      <ProjectContainer
        finalizeContainer={true}
        popoverPlacement="end-end"
        customerId={props.validation.data.customerId}
        brandName={props.validation.data.customerName}
        keyAccountManagerId={props.validation.data.keyAccountManagerId}
        identifier={props.validation.data.identifier}
        customerArchived={props.validation.data.customerArchived}
        onProjectCreated={props.onResolved}
      />
    );
  }

  return null;
}
