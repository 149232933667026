import { Button, useDisclosure } from '@chakra-ui/react';
import { ProductApprovalModal } from './ProductApprovalModal';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { useTranslation } from 'react-i18next';
import { Icons } from '@texas/components/shared/Icons';
import { FeatureGuard } from '@texas/components/shared/featureGuard/FeatureGuard';

export function CreateProductApprovalButton({
  variantId,
  customerId,
  articleNodeGroups,
}: {
  variantId: number;
  customerId: number;
  articleNodeGroups: ArticleNodeGroup;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  return (
    <FeatureGuard feature="productApproval">
      <Button variant="texas" onClick={onOpen} leftIcon={<Icons.Plus />}>
        {t('productApproval.productApproval')}
      </Button>
      <ProductApprovalModal
        customerId={customerId}
        variantId={variantId}
        articleNodeGroups={articleNodeGroups}
        isOpen={isOpen}
        onClose={onClose}
      />
    </FeatureGuard>
  );
}
