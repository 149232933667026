import { useEffect, useState } from 'react';

function useLocalStorage<T>(
  key: string | undefined,
  defaultValue: T,
  overrideInitialValue?: T,
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (overrideInitialValue) {
      return overrideInitialValue;
    }

    if (!key) return defaultValue;

    const item = window.localStorage.getItem(key);
    return item ? (parseJSON(item) as T) : defaultValue;
  });

  useEffect(() => {
    if (!key) return;
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  return [storedValue, setStoredValue] as const;
}

export { useLocalStorage };

function parseJSON(value: string | null) {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    // eslint-disable-next-line no-console
    console.log('parsing error on', { value });
    return undefined;
  }
}
