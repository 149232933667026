// @ngInject
const UploaderChunkClass = () => {
  return class UploaderChunk {
    constructor(uploaderService, uploaderFile, offset) {
      this.uploaderService = uploaderService;
      this.uploaderFile = uploaderFile;
      this.offset = offset;
      this.retries = 0;
      this.pendingRetry = false;
      this.uploaderFileSize = uploaderFile.size;
      this.loaded = 0; // Bytes transferred from total request size
      this._lastLoaded = 0; // Total amount of bytes from the chunk transferred until the last progress call
      this.total = 0; // Total request size
      this.startByte =
        uploaderFile.offset + this.offset * this.uploaderService.opts.chunkSize;
      this.endByte = Math.min(
        this.uploaderFileSize,
        this.startByte + this.uploaderService.opts.chunkSize,
      );
      this.size = this.endByte - this.startByte;
      this.xhr = null;
    }

    progressHandler(event) {
      if (event.lengthComputable) {
        this.loaded = Math.min(event.loaded, this.size);
        this.total = Math.min(event.total, this.size);
        if (this.loaded > this._lastLoaded) {
          const loadedNow = this.loaded - this._lastLoaded;
          this.uploaderFile.completedBytes += loadedNow;
          this.uploaderService.addCompletedBytes(loadedNow);
        }
        this._lastLoaded = this.loaded;
      }
      this.uploaderFile.chunkEvent('progress');
    }

    doneHandler() {
      const status = this.status();
      const message = this.message();
      if (status === 'success') {
        this.retries = 0;
        this.uploaderFile.completedChunks =
          this.uploaderFile.completedChunks + 1;
        this.uploaderFile.chunkEvent('success', message);
      } else {
        this.uploaderFile.uploadingChunk = false;
        this.abort();
        if (status === 'error') {
          this.retries = 0;
          this.uploaderFile.chunkEvent('error', message);
        } else {
          if (this.retries < this.uploaderService.opts.maxChunkRetries) {
            this.retries = this.retries + 1;
            let retryInterval = this.uploaderService.opts.chunkRetryInterval;
            if (retryInterval !== null) {
              if (this.retries > 1) {
                //increase the wait time between the successive retries
                retryInterval *= this.retries;
              }
              setTimeout(() => {
                this.send();
              }, retryInterval);
            } else {
              this.send();
            }
          } else {
            this.retries = 0;
            //the same as permanent error above
            this.uploaderService.chunkEvent('error', message);
          }
        }
      }
    }

    send() {
      this.uploaderFile.uploadingChunk = this;
      this.loaded = 0;
      this._lastLoaded = 0;
      this.total = 0;
      this.pendingRetry = false;

      const sliceFunctionName = this.uploaderFile.file.slice
        ? 'slice'
        : this.uploaderFile.file.mozSlice
        ? 'mozSlice'
        : this.uploaderFile.file.webkitSlice
        ? 'webkitSlice'
        : 'slice';

      const bytes = this.uploaderFile.file[sliceFunctionName](
        this.startByte,
        this.endByte,
      );

      // Set up request and listen for event
      this.xhr = new XMLHttpRequest();
      this.xhr.upload.addEventListener(
        'progress',
        (event) => this.progressHandler(event),
        false,
      );
      this.xhr.addEventListener('load', () => this.doneHandler(), false);
      this.xhr.addEventListener('error', () => this.doneHandler(), false);

      const data = this.prepareXhrRequest(bytes);
      this.xhr.send(data);
    }

    abort() {
      if (this.xhr) {
        this.xhr.abort();
        if (this.loaded) {
          //subtract the pending load
          this.uploaderFile.completedBytes -= this.loaded;
          this.uploaderService.removeCompletedBytes(this.loaded);
        }
      }
      this.uploaderFile.chunkEvent('abort');
    }

    status() {
      if (this.pendingRetry) {
        // if pending retry then that's effectively the same as actively uploading,
        // there might just be a slight delay before the retry starts
        return 'uploading';
      } else if (!this.xhr) {
        return 'pending';
      } else if (this.xhr.readyState < 4) {
        // Status is really 'OPENED', 'HEADERS_RECEIVED'
        // or 'LOADING' - meaning that stuff is happening
        return 'uploading';
      } else {
        if (
          this.uploaderService.opts.successStatuses.indexOf(this.xhr.status) >
          -1
        ) {
          return 'success';
        } else if (
          this.uploaderService.opts.permanentErrors.indexOf(this.xhr.status) >
            -1 ||
          this.retries >= this.uploaderService.opts.maxChunkRetries
        ) {
          return 'error';
        } else {
          // this should never happen, but we'll reset and queue a retry
          // a likely case for this would be 503 service unavailable
          this.abort();
          return 'pending';
        }
      }
    }

    message() {
      return this.xhr ? this.xhr.responseText : '';
    }

    prepareXhrRequest(blob) {
      // Add data from the query options
      const data = new FormData();
      data.append('chunkNumber', this.offset + 1);
      data.append('file', blob, this.uploaderFile.name);
      this.xhr.open(
        'POST',
        this.uploaderService.opts.target + this.uploaderFile.uniqueIdentifier,
      );
      // Get headers
      const headers = this.uploaderService.opts.headers();
      // Add data from header options
      _.forEach(headers, (v, k) => {
        this.xhr.setRequestHeader(k, v);
      });

      return data;
    }
  };
};

angular
  .module('services.uploader.uploaderChunk', [])
  .factory('UploaderChunk', UploaderChunkClass);
