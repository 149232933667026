import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '../types';
import { useRef } from 'react';

interface Props extends SharedDisclosureProps {
  headerTitle: string;
  children: React.ReactNode;
  secondaryButtonTitle: string;
  primaryButtonTitle: string;
  toPerform: () => void;
}

export function VerifyDialogWithFunction(props: Props) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.headerTitle}
          </AlertDialogHeader>
          <AlertDialogBody>{props.children}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="texas-light"
              ref={cancelRef}
              onClick={props.onClose}
            >
              {props.secondaryButtonTitle}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                props.toPerform();
                props.onClose();
              }}
              ml={3}
            >
              {props.primaryButtonTitle}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
