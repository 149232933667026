import { createContext, useMemo } from 'react';
import { BrandsWidget } from './Brands/BrandsWidget';
import { ArticlesWidget } from './ArticlesList/ArticlesWidget';
import { Box } from '@chakra-ui/react';
import {
  ArticlesCountOptions,
  SearchArticleOptions,
  WidgetOptions,
  WidgetType,
} from './shared/types';
import { ArticlesCountWidget } from './ArticlesCount/ArticlesCountWidget';
import {
  GridLayoutProps,
  WidgetsLayout,
} from './shared/components/WidgetsContainer';
import { useWidgetFactory } from './useWidgetFactory';

export type WidgetOption = {
  [_ in WidgetType]?: WidgetOptions;
};

export interface Widget {
  id: string;
  type: WidgetType;
  layoutIndex: string;
  name: string;
  categoryColor: string | null;
}

export interface WidgetLayout {
  layout: ReactGridLayout.Layouts;
  widgets: Widget[];
  currentLayout?: ReactGridLayout.Layout[];
}

export interface WidgetLayoutProps {
  defaultLayout: WidgetLayout;
  localStorageKey?: string;
  widgetOptions: WidgetOption;
  gridProps?: GridLayoutProps;
}

export const WidgetFactoryContext = createContext<ReturnType<
  typeof useWidgetFactory
> | null>(null);

export const useWidgetLayout = (props: WidgetLayoutProps) => {
  const widgetFactory = useWidgetFactory({
    defaultLayout: props.defaultLayout,
    widgetOptions: props.widgetOptions,
    localStorageKey: props.localStorageKey,
  });

  const { layout, setLayout, addNewWidget } = widgetFactory;

  const widgets = useMemo(() => {
    return (
      <WidgetFactoryContext.Provider value={widgetFactory}>
        <WidgetsLayout
          onLayoutChange={onLayoutChange}
          layout={layout.layout}
          gridProps={props.gridProps}
        >
          {layout.widgets.map((w) => {
            return (
              <Box key={w.layoutIndex}>
                {widgetComponent(
                  w.type,
                  w.id,
                  w.categoryColor,
                  w.name,
                  props.widgetOptions[w.type],
                )}
              </Box>
            );
          })}
        </WidgetsLayout>
      </WidgetFactoryContext.Provider>
    );

    function onLayoutChange(
      currentLayout: ReactGridLayout.Layout[],
      allLayouts: ReactGridLayout.Layouts,
    ) {
      setLayout((s) => ({
        widgets: s.widgets,
        layout: allLayouts,
        currentLayout: currentLayout,
      }));
    }
  }, [
    widgetFactory,
    layout.layout,
    layout.widgets,
    props.gridProps,
    props.widgetOptions,
    setLayout,
  ]);

  return { widgetComponents: widgets, addNewWidget, widgets: layout.widgets };
};

function widgetComponent(
  widgetType: WidgetType,
  id: string,
  customColor: string | null,
  name?: string,
  options?: WidgetOptions,
) {
  switch (widgetType) {
    case WidgetType.Brands:
      return (
        <BrandsWidget
          widgetId={id}
          options={
            options?.widgetType === widgetType ? options.options : undefined
          }
          color={customColor}
          customTitle={options?.options.customWidgetTitle ?? name}
        />
      );
    case WidgetType.Articles:
      return (
        <ArticlesWidget
          color={customColor}
          widgetId={id}
          customTitle={options?.options.customWidgetTitle ?? name}
          options={options?.options as SearchArticleOptions}
        />
      );
    case WidgetType.ArticlesCount: {
      const countOptions = options?.options as ArticlesCountOptions;
      const preConfigTitle =
        countOptions.preConfiguredWidget?.[id]?.widgetTitle;
      return (
        <ArticlesCountWidget
          color={customColor}
          widgetId={id}
          customTitle={
            preConfigTitle ?? options?.options.customWidgetTitle ?? name
          }
          options={countOptions.preConfiguredWidget?.[id] ?? countOptions}
        />
      );
    }
  }
}
