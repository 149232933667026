// @ngInject
const freightCostsResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadFreightCosts(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.freightCosts', {
    url: '/freightcosts',
    controller: 'FreightCostsConfigurationController as vm',
    templateUrl: 'main/configuration/freightCosts/freightCosts.tpl.html',
    data: { pageTitle: 'Delivery terms' },
    resolve: {
      freightCosts: freightCostsResolve,
    },
  });
};

class FreightCostsConfigurationController {
  constructor(
    $scope,
    DS,
    FreightCostsApiService,
    ConfigItemDialogService,
    currentUser,
    freightCosts,
  ) {
    'ngInject';

    this._ConfigItemDialogService = ConfigItemDialogService;
    this._FreightCostsApiService = FreightCostsApiService;
    this._currentUser = currentUser;
    this.freightCosts = freightCosts;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('freightCost');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.freightCosts = currentUser.organization.freightCosts;
        }
      },
    );
  }

  openFreightCost(freightCost) {
    this._ConfigItemDialogService.showDialog({
      configItem: freightCost,
      displayOrder: true,
      value: true,
      onSave: (freightCost) =>
        this._FreightCostsApiService.updateFreightCost(
          _.pick(freightCost, ['name', 'value', 'displayOrder', 'archived']),
          freightCost.id,
        ),
    });
  }

  newFreightCost() {
    this._ConfigItemDialogService.showDialog({
      displayOrder: true,
      value: true,
      onSave: (freightCost) =>
        this._FreightCostsApiService.newFreightCost(
          freightCost,
          this._currentUser.organization.id,
        ),
    });
  }
}

angular
  .module('configuration.freightCosts', [])
  .config(config)
  .controller(
    'FreightCostsConfigurationController',
    FreightCostsConfigurationController,
  );
