// @ngInject
const ArticleTagsApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('articleTag', {
      params: { organizationId },
    }),
  getById: (articleTagId) =>
    ResourcesService.getById('articleTag', articleTagId, {
      params: { organizationId: false },
    }),

  newArticleTag: (articleTag, organizationId) =>
    ResourcesService.create('articleTag', articleTag, {
      params: { organizationId },
    }),
  updateArticleTag: (data, articleTagId) =>
    ResourcesService.update('articleTag', articleTagId, data, {
      params: { organizationId: false },
      updatedFields: ['name'],
    }),
  deleteArticleTag: (articleTagId) =>
    ResourcesService.destroy('articleTag', articleTagId),
});

angular
  .module('resources.articleTags', [])
  .factory('ArticleTagsApiService', ArticleTagsApiService);
