import {
  FormControl,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  ModalBody,
  ModalFooter,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import {
  Material,
  UpdateMaterialRequest,
  materialsApi,
} from '@texas/api/endpoints/metadata/materialsApi';

interface Props extends SharedDisclosureProps {
  onUpdated: (material: Material) => void;
  materialId: number | null;
}

export function EditMaterial({
  onUpdated,
  materialId,
  onClose,
  isOpen,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('general.edit')}</ModalHeader>
        {materialId && (
          <Form
            materialId={materialId}
            onClose={onClose}
            onUpdated={onUpdated}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  materialId,
  onClose,
  onUpdated,
}: {
  materialId: number;
  onClose: () => void;
  onUpdated: (material: Material) => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: updateRequest, error } = useApiRequest(materialsApi.update);

  const {
    data: materials,
    refetch: refetchMaterials,
    loading: materialsLoading,
  } = useApiResource(materialsApi.getAll);

  const { data: material, refetch: refetchMaterial } = useApiResource(
    materialsApi.get,
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<UpdateMaterialRequest>();
  const watchCombinations = watch('childMaterialIds');

  useEffect(() => {
    refetchMaterial(materialId);
    refetchMaterials();
  }, [materialId, refetchMaterial, refetchMaterials]);

  useEffect(() => {
    if (!material) return;
    reset({
      name: material.name,
      description: material.description,
      displayOrder: material.displayOrder,
      articleTextField: material.articleTextField,
      childMaterialIds: material.combinationOf.map((x) => x.id),
    });
  }, [material, reset]);

  const onSubmit = async (data: UpdateMaterialRequest) =>
    await request(
      updateRequest,
      [materialId, data],
      (data: Material) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(data);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <ModalBody>
          <Flex flexDir="column" gap={4}>
            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>{t('general.name')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 35,
                    message: t('errors.maxLength', { count: 35 }),
                  },
                })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.description}>
              <TexasFormLabel>{t('general.description')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('description', {
                  required: true,
                  maxLength: {
                    value: 35,
                    message: t('errors.maxLength', { count: 35 }),
                  },
                })}
                placeholder={t('general.description')}
              />
              <ErrorLabel text={errors.description?.message} />
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={!!errors.articleTextField}
            >
              <TexasFormLabel>
                {t('configuration.articleTextField')}
              </TexasFormLabel>
              <Input
                variant="outline"
                {...register('articleTextField', {
                  required: true,
                  maxLength: {
                    value: 20,
                    message: t('errors.maxLength', { count: 20 }),
                  },
                })}
                placeholder={t('configuration.articleTextField')}
              />
              <ErrorLabel text={errors.articleTextField?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.displayOrder}>
              <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
              <Input
                type="number"
                variant="outline"
                {...register('displayOrder', {
                  required: true,
                  valueAsNumber: true,
                })}
                placeholder={t('branch.displayOrder')}
              />
              <ErrorLabel text={errors.displayOrder?.message} />
            </FormControl>

            <FormControl>
              <TexasFormLabel>
                {t('configuration.combinationOf')}
              </TexasFormLabel>
              <Controller
                name="childMaterialIds"
                control={control}
                render={({ field }) => (
                  <TexasSelect
                    {...field}
                    isLoading={materialsLoading}
                    isClearable={true}
                    value={field.value?.map<ReactSelectOption>((x) => ({
                      label: materials?.find((p) => p.id === x)?.name ?? '',
                      value: x,
                    }))}
                    isMulti={true}
                    onChange={(e) => field.onChange(e.map((x) => x.value))}
                    options={
                      materials?.map<ReactSelectOption>((x) => ({
                        value: x.id,
                        label: x.name,
                      })) ?? []
                    }
                    closeMenuOnSelect={false}
                  />
                )}
              />
              <Text variant="small" pt={1}>
                {t('configuration.mixedDesc')}
              </Text>
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            disabled={watchCombinations?.length == 1}
            loading={isSubmitting}
          >
            {watchCombinations?.length == 0
              ? t('configuration.updateMaterial')
              : t('configuration.updateMixedMaterial')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
