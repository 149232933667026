import {
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { productGroupsApi } from '@texas/api/endpoints/metadata/productGroupsApi';
import {
  Treatment,
  UpdateTreatmentRequest,
  treatmentsApi,
} from '@texas/api/endpoints/metadata/treatmentsApi';
import { ServerError, ReactSelectOption } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

export interface EditTreatmentProps extends SharedDisclosureProps {
  onUpdated: () => void;
  treatmentId: number | null;
}

export function EditTreatment({
  onUpdated,
  treatmentId,
  onClose,
  isOpen,
}: EditTreatmentProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('configuration.editTreatment')}</ModalHeader>
        {treatmentId && (
          <Form
            treatmentId={treatmentId}
            onClose={onClose}
            onUpdated={onUpdated}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  treatmentId,
  onClose,
  onUpdated,
}: {
  treatmentId: number;
  onClose: () => void;
  onUpdated: (treatment: Treatment) => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    request: updateRequest,
    error,
    loading,
  } = useApiRequest(treatmentsApi.update);
  const {
    data: productGroups,
    refetch: refetchProductGroups,
    loading: productGroupsLoading,
  } = useApiResource(productGroupsApi.getProductGroups);
  const { data: treatment, refetch: refetchTreatment } = useApiResource(
    treatmentsApi.get,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<UpdateTreatmentRequest>();

  useEffect(() => {
    refetchTreatment(treatmentId);
    refetchProductGroups();
  }, [reset, refetchProductGroups, refetchTreatment, treatmentId]);

  useEffect(() => {
    reset({
      name: treatment?.name,
      code: treatment?.code,
      description: treatment?.description,
      productGroupIds: treatment?.productGroups.map((x) => x.id),
    });
  }, [reset, treatment]);

  const onSubmit = async (data: UpdateTreatmentRequest) =>
    await request(
      updateRequest,
      [treatment!.id, data],
      (data: Treatment) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(data);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <VStack>
            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>{t('general.name')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 30,
                    message: t('errors.maxLength', { count: 30 }),
                  },
                })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('general.description')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('description')}
                placeholder={t('general.description')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.code}>
              <TexasFormLabel>{t('general.code')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('code', {
                  required: true,
                  maxLength: {
                    value: 6,
                    message: t('errors.maxLength', { count: 6 }),
                  },
                })}
                placeholder={t('general.code')}
              />
              <ErrorLabel text={errors.code?.message} />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('general.productGroups')}</TexasFormLabel>
              <Controller
                name="productGroupIds"
                control={control}
                render={({ field }) => (
                  <TexasSelect
                    {...field}
                    isLoading={productGroupsLoading}
                    isClearable={true}
                    value={field.value?.map<ReactSelectOption>((x) => ({
                      label:
                        productGroups?.find((p) => p.id === x)?.value ?? '',
                      value: x,
                    }))}
                    isMulti={true}
                    onChange={(e) => field.onChange(e.map((x) => x.value))}
                    options={
                      productGroups?.map<ReactSelectOption>((x) => ({
                        value: x.id,
                        label: x.value,
                      })) ?? []
                    }
                    closeMenuOnSelect={false}
                  />
                )}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <SubmitButton loading={loading}>{t('general.update')}</SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
