import { Tooltip } from '@chakra-ui/react';
import { Icons } from '../Icons';
import { ArticleState } from '@texas/api/endpoints/searchApi';

const defaultBoxSize = 7;

export function StateIndicator({
  state,
  boxSize,
}: {
  state: ArticleState;
  boxSize?: number;
}) {
  if (state === ArticleState.Registered) {
    return null;
  }

  return (
    <Tooltip label={ArticleState[state]}>
      {getStateIcon(state, boxSize ?? defaultBoxSize)}
    </Tooltip>
  );
}

function getStateIcon(state: ArticleState, boxSize: number) {
  switch (state) {
    case ArticleState.Accepted:
      return <Icons.CheckmarkDecagram boxSize={boxSize} />;
    case ArticleState.Rejected:
      return <Icons.HandBackLeft boxSize={boxSize} />;
    case ArticleState.OnHold:
      return <Icons.PauseCircle boxSize={boxSize} />;
    case ArticleState.Cancelled:
      return <Icons.CloseCircle boxSize={boxSize} />;
    case ArticleState.Registered:
      return null;
  }
}
