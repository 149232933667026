import { httpClient } from '../httpClient/httpClient';

export interface FreightCost {
  id: number;
  value: number;
}

export interface HandlingFee {
  id: number;
  value: number;
}

export interface PurchaseInformation {
  id: number;
  variantId: number;

  branchId: number;
  isMain: boolean;
  sampleCharge: number;
  surcharge: number;
  mouldCharge: number;
  fixedExchangeRate: number;
  notes: string;
  moq: number;
  supplierId?: number;
  supplierName: string;
  supplierNumber: string;
  freightCostId: number;
  freightCostValue: number;
  handlingFeeId: number;
  handlingFeeValue: number;
}

export interface PurchaseInformationSupplier {
  id: number;
  name: string;
  supplierNumber: string;
}

export const purchaseInformationApi = {
  getPurchaseInformationsFromVariant: (variantId: number) =>
    httpClient.get<PurchaseInformation[]>(
      `purchaseinformations/variant/${variantId}`,
    ),
  getPurchaseInformation: (
    variantId: number,
    branchId: number,
    supplierId: number,
  ) =>
    httpClient.get<PurchaseInformation>(
      `purchaseinformations/${variantId}/${branchId}/${supplierId}`,
    ),
};
