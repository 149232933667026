// @ngInject
const userAvatar = () => ({
  templateUrl: 'directives/userAvatar/userAvatar.tpl.html',
  restrict: 'E',
  replace: true,

  scope: {
    user: '=',
    size: '@',
  },
});

angular.module('directives.userAvatar', []).directive('userAvatar', userAvatar);
