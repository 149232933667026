// @ngInject
const toasterService = (toastr) => ({
  success() {
    toastr.clear();
    toastr.success('<i class="fa fa-check"></i> Success!', null, {
      timeOut: 3000,
      extendedTimeout: 3000,
    });
  },

  error(message) {
    if (message) {
      toastr.clear();
      toastr.error(`<i class="fa fa-warning"></i> ${message}`, null, {
        timeOut: 5000,
        extendedTimeout: 5000,
      });
    }
  },
});

angular
  .module('services.toaster', [])
  .factory('toasterService', toasterService);
