const matrixNodeService = () => {
  return {
    validateInput(nodeValues, value, hasGroupsField = false) {
      if (!value) {
        return;
      }
      if (!value.name) {
        const existing = _.find(
          nodeValues,
          hasGroupsField ? { group: false, id: value.id } : { id: value.id },
        );
        if (existing) {
          value.name = existing.name;
        }
      }
      value.$edit = false;
    },

    keyDown($event, nodeValues, value) {
      if ($event.keyCode === 13) {
        $event.preventDefault();
        value.$edit = false;
        this.validateInput(nodeValues, value);
      }
    },
  };
};

angular
  .module('services.matrixNode', [])
  .factory('matrixNodeService', matrixNodeService);
