import {
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  Box,
  Flex,
} from '@chakra-ui/react';
import { Icons } from '@texas/components/shared/Icons';
import { ReactSelectOptionWithDesc } from '@texas/types';
import { OptionProps, GroupBase, components } from 'react-select';

export function PopoverOptionComponent<IsMulti extends boolean>(
  props: OptionProps<
    ReactSelectOptionWithDesc,
    IsMulti,
    GroupBase<ReactSelectOptionWithDesc>
  >,
) {
  if (!props.data.description || props.data.description.length == 0) {
    return <components.Option {...props} />;
  }

  return (
    <Popover trigger="hover" placement="right">
      <PopoverTrigger>
        <Box>
          <components.Option {...props}>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{props.data.label}</Box>
              <Icons.InformationOutline />
            </Flex>
          </components.Option>
        </Box>
      </PopoverTrigger>
      <Portal {...(props.data.ref ? { containerRef: props.data.ref } : null)}>
        <PopoverContent color="white">
          <PopoverBody>{props.data.description}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
