import { reactEvents } from '@bridge/reactEvents';

// @ngInject
const suppliersResolve = (SuppliersApiService, currentOrganization) =>
  SuppliersApiService.getAllByOrganizationId(currentOrganization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('suppliers', {
    parent: 'main',
    url: '/suppliers',
    views: {
      'main-content': {
        controller: 'SupplierMenuController as vm',
        templateUrl: 'main/suppliers/suppliers.tpl.html',
      },
    },
    data: { pageTitle: 'Supplier' },
    resolve: { suppliers: suppliersResolve },
  });
};

class SupplierMenuController {
  constructor(
    $state,
    $scope,
    SuppliersApiService,
    currentOrganization,
    suppliers,
  ) {
    'ngInject';

    this.$state = $state;
    this.$scope = $scope;
    this.SuppliersApiService = SuppliersApiService;
    this.currentOrganization = currentOrganization;
    this.suppliers = suppliers;

    this.filter = '';
    this.query = { order: 'name', limit: 50, page: 1 };

    this.supplierCreatedEvent = reactEvents.supplierCreated.subscribe(() => {
      this.SuppliersApiService.getAllByOrganizationId(
        this.currentOrganization.id,
      ).then((data) => {
        this.suppliers = data;
      });
    });

    this.$scope.$on('$destroy', () => {
      this.supplierCreatedEvent();
    });
  }

  resetTablePosition() {
    this.query.page = 1;
  }

  goToSupplier(supplier) {
    this.$state.go('supplier', { supplierId: supplier.id });
  }
}

const supplierFilter = () => {
  return function (items, filter) {
    const filtered = [];

    if (!filter) {
      return items;
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].name.toLowerCase().includes(filter.toLowerCase())) {
        filtered.push(items[i]);
        continue;
      }

      for (let j = 0; j < items[i].branchData.length; j++) {
        const supplierNumber = items[i].branchData[j].supplierNumber;
        if (!supplierNumber) {
          break;
        }
        if (supplierNumber.includes(filter)) {
          filtered.push(items[i]);
          break;
        }
      }
    }
    return filtered;
  };
};

angular
  .module('main.suppliers', [])
  .config(config)
  .controller('SupplierMenuController', SupplierMenuController)
  .filter('filterSuppliers', supplierFilter);
