import {
  VStack,
  IconButton,
  Tooltip,
  MenuItem,
  useDisclosure,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { WidgetWrapper } from '../shared/components/WidgetWrapper';
import { useTranslation } from 'react-i18next';
import {
  defaultEmptyFilters,
  useSearchArticles,
} from '../../searchArticles/useSearchArticles';
import { reactEvents } from '../../../../bridge/reactEvents';
import { maxExportArticlesAmount } from '../../searchArticles/SearchArticles';
import { useEffect, useState } from 'react';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { RowSelectionState } from '@tanstack/react-table';
import { useExportableArticles } from '@texas/components/searchArticles/useExportableArticles';
import { BaseWidgetProps } from '../widgets';
import { WidgetOverviewModal } from '../shared/components/ArticlesOverviewModal';
import { ArticlesCountOptions } from '../shared/types';
import { WidgetType } from '../shared/types';
import { ActiveFilterModal } from '../shared/components/ArticlesFilterModal';
import { searchApi } from '@texas/api/endpoints/searchApi';
import {
  blinkAnimation,
  fadeInAnimation,
} from '@texas/resources/animations/animations';
import { PrinterFilter } from '../shared/components/FilterPrinter';
import { SearchArticleCountContext } from './context';
import { useActiveContext } from '@texas/hooks/useActiveContext';

interface Props extends BaseWidgetProps {
  options: ArticlesCountOptions;
}

export function ArticlesCountWidget({
  options,
  customTitle,
  widgetId,
  color,
}: Props) {
  const { t } = useTranslation();

  const search = useSearchArticles({
    defaultFilters: defaultEmptyFilters,
    defaultArticleSearchProps: options.defaultSearchPage,
    defaultPage: options.defaultSearchPage.searchParams.page,
    defaultSearchParams: options.defaultSearchPage.searchParams,
    localStorageKey: options.localStorageKey
      ? `${options.localStorageKey}_${widgetId}`
      : undefined,
    filterOptions: options.filterOptions,
    optOutDefaultOrder: options.optOutDefaultOrdering,
    limit: 0,
    request: searchApi.searchVariantsCount,
    defaultData: { totalCount: 0 },
  });

  const { articles, searchPage, loading } = search;

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const exportableArticles = useExportableArticles(
    selectedRows,
    articles?.exportArticleItems ?? [],
  );

  useEffect(() => {
    if ((articles?.exportArticleItems.length ?? 0) > maxExportArticlesAmount) {
      setSelectedRows({});
      return;
    }
    const rows = articles?.exportArticleItems.reduce<RowSelectionState>(
      (p, c) => {
        p[c.id] = true;
        return p;
      },
      {},
    );
    setSelectedRows(rows ?? {});
  }, [articles?.exportArticleItems]);

  const selectedRowsCount = Object.keys(selectedRows).length;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isFiltersOpen,
    onClose: onFiltersClosed,
    onOpen: onFiltersOpen,
  } = useDisclosure();

  return (
    <>
      <SearchArticleCountContext.Provider value={search}>
        <WidgetWrapper
          widgetId={widgetId}
          loading={loading}
          settings={options.componentSettings}
          customColor={color}
          type={WidgetType.ArticlesCount}
          style="default"
          header={customTitle ?? t('widgets.articles.articles')}
          toolbox={
            <WidgetToolbox options={options} onFiltersOpen={onFiltersOpen} />
          }
          extendedMenu={
            <>
              <MenuItem
                icon={<Icons.OpenInNew boxSize={defaultIconSize} />}
                onClick={onOpen}
              >
                {t('widgets.showResult')}
              </MenuItem>
              <Tooltip
                label={
                  (articles?.exportArticleItems.length ?? 0) === 0 ||
                  (articles?.exportArticleItems.length ?? 0) >
                    maxExportArticlesAmount
                    ? t('widgets.countExportError', {
                        count: articles?.exportArticleItems.length ?? 0,
                        max: maxExportArticlesAmount,
                      })
                    : ''
                }
              >
                <MenuItem
                  onClick={() => {
                    reactEvents.excelExportClicked.dispatch({
                      articles: exportableArticles,
                      branchIds: searchPage.searchParams['branchIds'] ?? [],
                      hideBranchIds: false,
                    });
                  }}
                  isDisabled={
                    selectedRowsCount === 0 ||
                    selectedRowsCount > maxExportArticlesAmount
                  }
                  icon={<Icons.FileExport boxSize={5} />}
                >
                  {t('general.exportExcel')}
                </MenuItem>
              </Tooltip>
            </>
          }
        >
          <VStack h="100%" w="100%" align="start">
            <InnerWidget />
          </VStack>
        </WidgetWrapper>
        <ActiveFilterModal
          search={search}
          widgetRenderComponent={<InnerWidget />}
          isOpen={isFiltersOpen}
          onClose={onFiltersClosed}
        />
        <WidgetOverviewModal
          isOpen={isOpen}
          onClose={onClose}
          filters={search.searchParamsRef.current}
          searchQuery={search.searchParamsRef.current}
          articleSearchProps={search.searchPage}
          columns={options.columns}
        />
      </SearchArticleCountContext.Provider>
    </>
  );
}

function InnerWidget() {
  const search = useActiveContext(SearchArticleCountContext);
  const { articles, loading } = search;
  const { t } = useTranslation();
  return (
    <Box>
      <Flex alignItems="end" gap={2}>
        <Text
          animation={loading ? blinkAnimation() : fadeInAnimation()}
          fontSize="5xl"
        >
          {articles?.totalCount ?? '...'}
        </Text>
        <Text color="gray.300" pb={3}>
          {t('widgets.articles.articles', { count: articles?.totalCount ?? 0 })}
        </Text>
      </Flex>
      <PrinterFilter search={search} />
    </Box>
  );
}

function WidgetToolbox({
  options,
  onFiltersOpen,
}: {
  options: ArticlesCountOptions;
  onFiltersOpen: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex gap={2}>
      {options.componentSettings?.editFilters !== 'hidden' && (
        <>
          <Tooltip label={t('widgets.changeFilters')}>
            <IconButton
              size="sm"
              variant="texas-light"
              icon={<Icons.FilterVariant />}
              aria-label={t('widgets.changeFilters')}
              onClick={() => onFiltersOpen()}
            />
          </Tooltip>
        </>
      )}
    </Flex>
  );
}
