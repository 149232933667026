class UploaderService {
  constructor($rootScope, ENV, UploaderFile, ResourcesService) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.UploaderFile = UploaderFile;

    this.active = false;
    this.files = [];
    this.instances = [];
    this.events = {};
    this.averageSpeed = 0;
    this.completedFiles = 0;
    this.uploadingFiles = 0;
    this.size = 0;
    this.completedBytes = 0;
    this.opts = {
      chunkSize: 20000000,
      progressCallbacksInterval: 600,
      speedSmoothingFactor: 0.1,
      query: {},
      headers: ResourcesService.getRequestHeaders,
      target: `${ENV.apiEndpoint}files/`,
      maxChunkRetries: 3,
      resumeLargerThan: 10485760,
      chunkRetryInterval: null,
      maxSimultaneous: 1,
      permanentErrors: [404, 415, 500, 501],
      successStatuses: [200, 201, 202],
    };
  }

  instance(name, options) {
    if (!name) {
      return false;
    }

    const existingInstance = _.find(this.instances, { name });
    if (existingInstance) {
      if (options && options.force) {
        _.remove(this.instances, existingInstance);
      } else {
        return existingInstance;
      }
    }

    const newInstance = Object.assign(
      {
        name,
        finalizeUrl: false,
        files: [],
        active: false,
        disabled: false,
        done: false,
        replace: false,
        keepBoth: false,
        single: false,
        validate: false,
        onStart: angular.noop,
        onDone: angular.noop,
      },
      options,
    );

    this.instances.push(newInstance);

    return newInstance;
  }

  addFiles(fileList, options) {
    const instance = _.find(this.instances, { name: options.instance });

    if (!instance) {
      return;
    }

    _.forEach(fileList, (file) => {
      if (
        instance.single &&
        (instance.active || _.some(instance.files, { done: false }))
      ) {
        return;
      }

      if (
        _.isFunction(instance.validate) &&
        instance.validate(file) === false
      ) {
        this.$rootScope.$evalAsync();
        return;
      }

      let finalizeUrl;

      if (_.isFunction(instance.finalizeUrl)) {
        finalizeUrl = instance.finalizeUrl();
      } else {
        finalizeUrl = instance.finalizeUrl;
      }

      const f = new this.UploaderFile(this, instance, finalizeUrl, file);
      instance.files.push(f);
      instance.done = false;
      instance.onStart(f);
      this.files.push(f);
      this.size += f.size;
      this.fire('fileAdded', f);
    });

    this.start();
  }

  removeFile(file) {
    let removedUploading = false;
    _.remove(this.files, file);
    _.remove(file.instance.files, file);
    if (file.uploading) {
      if (file.uploadingChunk) {
        file.uploadingChunk.abort();
      }
      removedUploading = true;
      this.onFileError(file);
    }

    if (file.uploadComplete) {
      this.completedFiles = this.completedFiles - 1;
    }

    this.size -= file.size;
    this.completedBytes -= file.completedBytes;
    file = null;

    if (removedUploading) {
      this.uploadNextFile();
    }
  }

  start() {
    this.active = true;
    this.uploadNextFile();
  }

  uploadNextFile() {
    _.forEach(this.files, (file) => {
      if (!file.uploadComplete && !file.error) {
        file.instance.active = true;
        const filtered = _.filter(this.files, { uploading: true });
        if (filtered.length < this.opts.maxSimultaneous) {
          file.start();
        } else {
          return false;
        }
      }
    });
  }

  removeFinished() {
    _.forEach(_.filter(this.files, { done: true }), (file) => {
      this.removeFile(file);
    });
  }

  removeAll() {
    _.forEach(this.files, (file) => {
      this.removeFile(file);
    });
    this.averageSpeed = 0;
    this.uploadingFiles = 0;
  }

  getProgress() {
    if (this.size === 0 && this.completedBytes === 0) {
      return 1;
    }
    return this.completedBytes / this.size;
  }

  timeRemaining() {
    const sizeDelta = this.size - this.completedBytes;
    if (sizeDelta && !this.averageSpeed) {
      return Number.POSITIVE_INFINITY;
    }
    if (!sizeDelta && !this.averageSpeed) {
      return 0;
    }
    return Math.floor(sizeDelta / this.averageSpeed);
  }

  onFileComplete(file, newFileObj) {
    this.uploadingFiles = this.uploadingFiles - 1;
    this.completedFiles = this.completedFiles + 1;

    if (!_.some(this.files, { done: false })) {
      this.active = false;
      this.fire('filesCompleted');
    }

    if (!_.some(file.instance.files, { done: false })) {
      file.instance.active = false;
    }

    if (!_.some(file.instance.files, { done: false })) {
      file.instance.done = true;
      file.instance.onDone(true, newFileObj);
      this.$rootScope.$evalAsync();
    }

    this.uploadNextFile();
  }

  onFileError(file, serverReply) {
    this.uploadingFiles = this.uploadingFiles - 1;

    if (!_.some(this.files, { done: false })) {
      this.active = false;
    }

    if (!_.some(file.instance.files, { done: false })) {
      file.instance.active = false;
    }

    if (!_.some(file.instance.files, { done: false })) {
      file.instance.done = true;
      file.instance.onDone(false, serverReply);
      this.$rootScope.$evalAsync();
    }

    this.uploadNextFile();
  }

  onFileStart() {
    this.uploadingFiles = this.uploadingFiles + 1;
  }

  on(event, callback) {
    if (!Object.prototype.hasOwnProperty.call(this.events, event)) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  }

  off(event, fn) {
    if (Object.prototype.hasOwnProperty.call(this.events, event)) {
      _.remove(this.events[event], fn);
    }
  }

  fire(event, ...args) {
    this.$rootScope.$evalAsync();
    if (Object.prototype.hasOwnProperty.call(this.events, event)) {
      _.forEach(this.events[event], (callback) => {
        callback(...args);
      });
    }
  }

  addCompletedBytes(bytes) {
    this.completedBytes += bytes;
  }

  removeCompletedBytes(bytes) {
    this.completedBytes -= bytes;
  }
}

// @ngInject
const UploaderServiceFactory = (
  $rootScope,
  ENV,
  UploaderFile,
  ResourcesService,
) => new UploaderService($rootScope, ENV, UploaderFile, ResourcesService);

angular
  .module('services.uploader', ['services.uploader.uploaderFile'])
  .service('UploaderService', UploaderServiceFactory);
