// @ngInject
const ExportDialogService = ($mdDialog) => ({
  showDialog(customers) {
    const options = {
      clickOutsideToClose: true,
      locals: { customers },
    };

    options.controller = 'ExportDialogController as vm';
    options.templateUrl = 'services/dialogs/exportDialog/exportDialog.tpl.html';

    return $mdDialog.show(options);
  },
});

class ExportDialogController {
  constructor(
    $mdDialog,
    $rootScope,
    customers,
    GarpApiService,
    toasterService,
    ExcelApiService,
  ) {
    'ngInject';

    this.toasterService = toasterService;
    this.validationErrors = [];
    this.customers = customers;
    this.GarpApiService = GarpApiService;
    this.ExcelApiService = ExcelApiService;
    this.$rootScope = $rootScope;

    this.fromDate = null;
    this.toDate = null;
    this.maxDate = new Date();

    this._$mdDialog = $mdDialog;
  }

  getExcelForSelected() {
    const data = {
      customerIds: _.chain(this.customers)
        .filter((a) => a.id)
        .map((a) => a.id)
        .value(),
      fromDate: this.fromDate,
      toDate: this.toDate,
    };

    this.$rootScope.$broadcast('activateExportMenu');

    this.ExcelApiService.generateExcel(data, 'articles')
      .then((response) => {
        window.location.href = this.ExcelApiService.getExcelUrl(response);
      })
      .finally(() => {
        this.$rootScope.$broadcast('deactivateExportMenu');
      });
    this._$mdDialog.hide();
  }

  save() {
    this.validationErrors = [];
    this.getExcelForSelected();
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.exportDialog', [])
  .service('ExportDialogService', ExportDialogService)
  .controller('ExportDialogController', ExportDialogController);
