// @ngInject
const supplierResolve = ($stateParams, SuppliersApiService) =>
  SuppliersApiService.getById($stateParams.supplierId);

// @ngInject
const scrollTop = ($window) => $window.scrollTo(0, 0);

// @ngInject
const rootFolderIdResolve = (supplier) => supplier.folderId;

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('supplier', {
    parent: 'main',
    url: '/suppliers/{supplierId}',
    views: {
      'main-content': {
        controller: 'SupplierController as vm',
        templateUrl: 'main/supplier/supplier.tpl.html',
      },
    },
    data: { pageTitle: 'Supplier' },
    resolve: { supplier: supplierResolve },
    onEnter: scrollTop,
  });

  // Files tab
  $stateProvider.state('supplier.files', {
    url: '/files',
    abstract: true,
    templateUrl: 'tabs/filesTab/filesTab.tpl.html',
    controller: 'FilesTabsController as vm',
    resolve: { rootFolderId: rootFolderIdResolve },
  });

  $stateProvider.state('supplier.files.id', {
    url: '/{folderPath:nonURIEncoded}',
    params: {
      folderPath: {
        value: null,
        squash: true,
      },
    },
  });
};

class SupplierController {
  constructor($scope, $state, supplier) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.supplier = supplier;
    this.entityTabs = [
      {
        isDefault: true,
        active: this.$state.is('supplier'),
        heading: '<i class="icon icon-settings"></i>',
        tooltip: 'Management',
        route: 'supplier.management',
      },
      {
        heading: '<i class="icon icon-folder"></i>',
        tooltip: 'Files',
        route: 'supplier.files.id',
      },
    ];

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('supplier')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.entityTabs, { isDefault: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module('main.supplier', ['main.supplier.management'])
  .config(config)
  .controller('SupplierController', SupplierController);
