import {
  ProductApprovalState,
  ProductApprovalRejectReason,
} from '@texas/api/endpoints/productApprovalsApi';
import { ReactSelectOption } from '@texas/types';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';
import { TFunction } from 'i18next';

export function getStateOption(state: ProductApprovalState): ReactSelectOption {
  const t = getEnumNamesAndValues(ProductApprovalState);
  const v = t.find(
    (x) => convertToEnum(ProductApprovalState, x.value) === state,
  )!;

  return { label: v.name, value: v.value };
}

export function getStateReasonOption(
  t: TFunction,
  reason: ProductApprovalRejectReason,
) {
  return {
    label: rejectReasonAsFriendlyName(t, reason),
    value: reason,
  };
}

export function rejectReasonAsFriendlyName(
  t: TFunction,
  reason: ProductApprovalRejectReason,
) {
  switch (reason) {
    case ProductApprovalRejectReason.Price:
      return t('productApproval.rejectReason.price');
    case ProductApprovalRejectReason.Quality:
      return t('productApproval.rejectReason.quality');
    case ProductApprovalRejectReason.SampleLeadTime:
      return t('productApproval.rejectReason.sampleLeadTime');
    case ProductApprovalRejectReason.ProductionLeadTime:
      return t('productApproval.rejectReason.productionLeadTime');
    case ProductApprovalRejectReason.AnswerTime:
      return t('productApproval.rejectReason.answerTime');
    case ProductApprovalRejectReason.Other:
      return t('productApproval.rejectReason.other');
  }
}
