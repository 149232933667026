import { httpClient } from '../httpClient/httpClient';
import { ArticleState } from '@texas/api/endpoints/searchApi';

export interface MatrixNodeValue {
  id: number;
  matrixNodeId: number;
  identifier: string;
  name: string | null;
  displayOrder: number;
  archived: boolean;
}

export interface ArticleNodeValue {
  id: number;
  name: string | null;
  matrixNodeValue: MatrixNodeValue;
}

export interface ArticleNodeGroup {
  nodeXValues: ArticleNodeValue[];
  nodeYValues: ArticleNodeValue[];
}

export interface MoveArticle {
  articleId: number;
  itemCode: string;
  brand: string;
  project: string;
  concept: string;
}

export interface UpdateArticle {
  variantId: number;
  name: string;
  itemCode: string;
  branchId: number;
  branchIdentifier: string;
  state: ArticleState;
  categoryCode: string;
}

interface MoveRequest {
  articleIds: number[];
  toCustomerId: number;
  toConceptId: number | null;
}

interface UpdateStateRequest extends UpdateVariants {
  state: ArticleState;
}

interface UpdateCategoryCodeRequest extends UpdateVariants {
  categoryCode: string;
}

interface UpdateVariants {
  variants: UpdateVariant[];
}

export interface UpdateVariant {
  variantId: number;
  branchId: number;
}

export interface IdNameDto {
  id: number;
  name: string;
}

export const articlesApi = {
  moveArticles: (request: MoveRequest) =>
    httpClient.post<MoveArticle[]>('articles/move', { data: request }),
  updateState: (request: UpdateStateRequest) =>
    httpClient.put<UpdateArticle[]>('articles/state', { data: request }),
  updateCategoryCode: (request: UpdateCategoryCodeRequest) =>
    httpClient.put<UpdateArticle[]>('articles/category-code', {
      data: request,
    }),
  getUpdateVariants: (request: UpdateVariants) =>
    httpClient.post<UpdateArticle[]>('articles/update', {
      data: request,
    }),
  getMoveArticles: (articleIds: number[]) =>
    httpClient.get<MoveArticle[]>(`articles/move`, {
      params: { articleIds: articleIds },
    }),
  getArticleBranches: (articleId: number) =>
    httpClient.get<IdNameDto[]>(`articles/${articleId}/branches`),
  getArticleSuppliers: (articleId: number) =>
    httpClient.get<IdNameDto[]>(`articles/${articleId}/suppliers`),
};
