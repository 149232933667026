import { Button } from '@chakra-ui/react';

export function SubmitButton({
  loading,
  children,
  disabled = false,
}: {
  loading: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Button
      isDisabled={disabled || loading}
      isLoading={loading}
      w="100%"
      type="submit"
      variant="texas-solid"
      mt="1em !important"
    >
      {children}
    </Button>
  );
}
