import { Spinner, useTimeout, Box, Fade } from '@chakra-ui/react';
import { useState } from 'react';

export function LoadingOverlay({ delay = 120 }: { delay?: number }) {
  const [visible, setVisible] = useState<boolean>(false);
  useTimeout(() => setVisible(true), delay);

  if (!visible) {
    return null;
  }

  return (
    <Fade in={true}>
      <Box
        position="absolute"
        inset="0"
        zIndex="overlay"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="rgba(0, 0, 0, 0.6)"
        _light={{ bg: 'rgba(0, 0, 0, 0.2)' }}
      >
        <Spinner thickness="3px" color="primary.200" size="lg" />
      </Box>
    </Fade>
  );
}
