import { WidgetLayout } from '../widgets/useWidgetLayout';
import {
  defaultWidgetLayouts,
  widgetSizeConstraints,
} from '../widgets/defaultWidgetLayouts';
import { WidgetType } from '../widgets/shared/types';

export const defaultDashboardWidgetsLayout: WidgetLayout = {
  layout: {
    lg: [
      {
        ...defaultWidgetLayouts.articles.lg,
        ...widgetSizeConstraints.articles,
        i: '0',
      },
      {
        ...defaultWidgetLayouts.brands.lg,
        ...widgetSizeConstraints.brands,
        i: '1',
      },
    ],
  },
  widgets: [
    {
      type: WidgetType.Articles,
      id: '0',
      layoutIndex: '0',
      name: WidgetType.Articles,
      categoryColor: null,
    },
    {
      type: WidgetType.Brands,
      id: '1',
      layoutIndex: '1',
      name: WidgetType.Brands,
      categoryColor: null,
    },
  ],
  currentLayout: [
    {
      ...defaultWidgetLayouts.articles.lg,
      i: '0',
    },
    {
      ...defaultWidgetLayouts.brands.lg,
      i: '1',
    },
  ],
};
