import { angularEvents } from '../../../bridge/angularEvents';

// @ngInject
const CustomersApiService = ($q, DS, UsersApiService, ResourcesService) => ({
  getAllByOrganizationId(organizationId, useCache) {
    const deferred = $q.defer();

    DS.findAll('customer', null, {
      bypassCache: !useCache,
      useFilter: true,
      params: { organizationId },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAllFromCache() {
    return DS.getAll('customer');
  },
  getById(customerId, useCache) {
    const deferred = $q.defer();

    DS.find('customer', customerId, {
      bypassCache: !useCache,
      params: { organizationId: false },
    }).then(
      (customer) => {
        deferred.resolve(customer);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  newCustomer(customer, organizationId) {
    const deferred = $q.defer();

    DS.create('customer', customer, { params: { organizationId } }).then(
      (response) => {
        deferred.resolve(response);
        angularEvents.newBrandCreated.dispatch({
          id: response.id,
          nrOfArticles: 0,
          name: response.name,
        });
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  updateCustomerInfo(data, customerId) {
    const deferred = $q.defer();

    if (data.users) {
      data.users = _.map(data.users, _.partialRight(_.pick, 'id'));
    }

    DS.update('customer', customerId, data, {
      cacheResponse: false,
      params: { organizationId: false },
    }).then(
      (response) => {
        const customer = DS.get('customer', customerId);
        customer.name = response.name;
        customer.identifier = response.identifier;
        customer.officeAddress = response.officeAddress;
        customer.website = response.website;
        customer.email = response.email;
        customer.phone = response.phone;
        customer.notes = response.notes;
        customer.customerType = response.customerType;
        customer.archived = response.archived;
        customer.users = response.users;
        customer.brandOwner = response.brandOwner;
        customer.branchData = response.branchData;
        deferred.resolve(customer);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
});

angular
  .module('resources.customers', [])
  .factory('CustomersApiService', CustomersApiService);
