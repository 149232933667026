const tierPrice = () => ({
  templateUrl: 'directives/tierPrice/tierPrice.tpl.html',
  restrict: 'E',
  scope: {},
  bindToController: {
    variant: '=',
    branches: '=',
    purchaseInfos: '=',
    organizationConfig: '=',
    matrixLabel: '@',
    tierPrice: '=tier',
    supplierId: '=',
    frozenPrices: '=',
    purchaseExchangeRate: '=',
    currentPurchaseExchangeRate: '=',
    displayExchangeRate: '=',
    freightCost: '=',
    handlingFee: '=',
    nodeXValues: '=',
    nodeYValues: '=',
    includeAdditionalCosts: '=',
    editable: '=',
    nodeValuesModified: '=',
    getOriginalTierPrice: '&',
    onUpdateTierPrice: '&',
    onSetMainTierPrice: '&',
    onDeleteTierPrice: '&',
    tierProId: '=',
  },
  controller: 'TierPriceController',
  controllerAs: 'vm',
});

class TierPriceController {
  constructor(
    $scope,
    ArticlesApiService,
    toasterService,
    loadSpinnerService,
    $q,
    HelperService,
    priceMatrixService,
    SavingService,
    PriceHistoryDialogService,
  ) {
    'ngInject';

    this.ArticlesApiService = ArticlesApiService;
    this.toasterService = toasterService;
    this.loadSpinnerService = loadSpinnerService;
    this.$q = $q;
    this.$scope = $scope;
    this.HelperService = HelperService;
    this.priceMatrixService = priceMatrixService;
    this.SavingService = SavingService;
    this.PriceHistoryDialogService = PriceHistoryDialogService;

    this.originalPurchaseCellValues = [];
    this.tierPriceHistory = [];
    this.originalMatrixCellValues = [];
    this.updatedMatrixCellValues = [];
    this.matrixDataIsLoaded = false;
    this.hasPriceHistory = false;
    this.isEdited = false;
    this.gridOptions = {
      enableSorting: false,
      enableColumnMenus: false,
      enableCellEditOnFocus: true,
      showCustomPrice: true,
      flatEntityAccess: true,
      getCustomPrice: (cellPrice) =>
        this.getPurchasePriceWithFeesInDisplayCurrency(cellPrice.price),
      getMatrixCellHistory: (rowName, displayName, col) => {
        const name = `${rowName}, ${displayName}`;
        this.PriceHistoryDialogService.showDialog(
          name,
          this.tierPrice,
          col,
          this.supplierId,
        );
      },
      resetMatrixCellValue: (matrixCell) => {
        priceMatrixService.resetMatrixCellPrice(
          matrixCell,
          this.getMatrixCellPrice(
            matrixCell.matrixNodeValueXId,
            matrixCell.matrixNodeValueYId,
          ),
          this.originalMatrixCellValues,
          this.updatedMatrixCellValues,
          () =>
            this.getMatrixCellPrice(
              matrixCell.matrixNodeValueXId,
              matrixCell.matrixNodeValueYId,
            ),
        );
      },
      onRegisterApi: (gridApi) => {
        gridApi.edit.on.afterCellEdit(
          $scope,
          (rowEntity, colDef, matrixCell) => {
            priceMatrixService.onMatrixCellChange(
              matrixCell,
              this.getMatrixCellPrice(
                matrixCell.matrixNodeValueXId,
                matrixCell.matrixNodeValueYId,
              ),
              this.originalMatrixCellValues,
              this.updatedMatrixCellValues,
            );
          },
        );
      },
      data: [],
    };
    this.hasMatrix = this.nodeXValues.length && this.nodeYValues.length;
    this.cellValuesSaveObj = this.SavingService.registerSave({
      onSave: () => this.saveUpdatedMatrixCellValues(),
      onDiscard: () => this.discardUpdatedMatrixCellValues(),
    });
    this.setupWatchers();
    this.setCurrentBasePrice();
    this.loadTierPriceHistory();
    this.tierPrice.$expanded = false;
    this.focusField = null;
  }

  getTierPriceHistory() {
    const name = `Quantity ${this.tierPrice.quantity}`;
    this.PriceHistoryDialogService.showDialog(
      name,
      this.tierPrice,
      null,
      this.supplierId,
    );
  }

  loadTierPriceHistory() {
    this.loadSpinnerService.start('mainSpinner');
    this.$q
      .when(
        this.ArticlesApiService.getTierPriceHistory(this.tierPrice.id).then(
          (data) => {
            this.tierPriceHistory = data.length;
          },
        ),
      )
      .then(() => {
        this.hasPriceHistory = this.tierPriceHistory > 0;
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  isWithFees() {
    return this.includeAdditionalCosts;
  }

  getPurchasePriceWithFeesInDisplayCurrency(price) {
    if (this.displayExchangeRate) {
      price = this.exchangeRateCalculation(
        price,
        this.currentPurchaseExchangeRate,
        this.displayExchangeRate,
      );
    }

    if (this.isWithFees()) {
      return this.getRoundedPriceWithFees(price);
    } else {
      return this.HelperService.round(price, this.tierPrice.decimalsInPrice);
    }
  }

  getFrozenBasePrice(matrixNodeValueXId, matrixNodeValueYId) {
    if (!_.some(this.frozenPrices, { tierPriceId: this.tierPrice.id })) {
      return null;
    }
    let price;
    if (matrixNodeValueXId && matrixNodeValueYId) {
      price = _.find(this.frozenPrices, {
        tierPriceId: this.tierPrice.id,
        matrixNodeValueXId,
        matrixNodeValueYId,
      });
    }
    return (
      price ||
      _.find(
        this.frozenPrices,
        (price) =>
          price.tierPriceId === this.tierPrice.id &&
          !price.matrixNodeValueXId &&
          !price.matrixNodeValueYId,
      )
    );
  }

  getBasePrice() {
    const frozenBasePrice = this.getFrozenBasePrice();
    return frozenBasePrice
      ? frozenBasePrice.price
      : this.currentBasePrice.price;
  }

  getFreightCost(frozenBasePrice) {
    if (frozenBasePrice) {
      return frozenBasePrice.freightCost;
    }

    return this.freightCost;
  }

  getHandlingFee(frozenBasePrice) {
    if (frozenBasePrice) {
      return frozenBasePrice.handlingFee;
    }

    return this.handlingFee;
  }

  getRoundedPriceWithFees(price) {
    price = this.HelperService.round(price, this.tierPrice.decimalsInPrice);
    const frozenBasePrice = this.getFrozenBasePrice();
    const freightCost = this.getFreightCost(frozenBasePrice);
    if (freightCost) {
      price += (freightCost / 100) * price;
    }
    const handlingFee = this.getHandlingFee(frozenBasePrice);
    if (handlingFee) {
      price += (handlingFee / 100) * price;
    }
    return this.HelperService.round(price, this.tierPrice.decimalsInPrice);
  }

  exchangeRateCalculation(price, baseExchangeRate, targetExchangeRate) {
    if (!_.isNumber(baseExchangeRate) || !_.isNumber(targetExchangeRate)) {
      return 0;
    }
    return (price * baseExchangeRate) / targetExchangeRate;
  }

  refreshGridData() {
    this.addMatrixCellValues(true);
  }

  addMatrixCellValues(keepDirty) {
    _.forEach(this.gridOptions.data, (row) => {
      this.priceMatrixService.addRowCellValues(
        row,
        this.nodeXValues,
        (xId, yId) => this.getMatrixCellPrice(xId, yId),
        this.originalMatrixCellValues,
        keepDirty,
      );
    });
  }

  getMatrixCellPrice() {
    return this.getPurchaseMatrixCellPrice();
  }

  getPurchaseMatrixCellPrice(frozenFirst = false) {
    if (frozenFirst) {
      const frozenBasePrice = this.getFrozenBasePrice();
      if (frozenBasePrice) {
        return frozenBasePrice.price;
      }
    }
    return this.getOriginalBasePrice();
  }

  getCellValue(matrixNodeValueXId, matrixNodeValueYId) {
    return (
      this.getFrozenBasePrice(matrixNodeValueXId, matrixNodeValueYId) ||
      _.find(this.originalPurchaseCellValues, {
        matrixNodeValueXId,
        matrixNodeValueYId,
        supplierId: this.supplierId,
      })
    );
  }

  loadMatrixData() {
    this.loadSpinnerService.start('mainSpinner');
    this.$q
      .when(
        this.ArticlesApiService.getMatrixCellValues(this.tierPrice.id).then(
          (matrixCellValues) => {
            this.originalPurchaseCellValues = matrixCellValues;
          },
        ),
      )
      .then(() => {
        this.setOriginalMatrixCellValues();
        this.setupMatrix();
        this.addMatrixCellValues();
        this.matrixDataIsLoaded = true;
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  setupMatrix() {
    const maxTextWidth = _.max(
      _.map(this.nodeYValues, (y) =>
        y.name ? y.name.length : y.matrixNodeValue.name.length,
      ),
    );

    this.gridOptions.columnDefs = this.priceMatrixService.getColumns({
      matrixLabel: this.matrixLabel,
      nodeXValues: this.nodeXValues,
      enableCellEdit: this.editable,
      width: maxTextWidth * 10 + 150,
    });

    _.forEach(this.gridOptions.columnDefs, (col) => {
      if (['matrixNodeValueId'] in col) {
        const minWidth = col.displayName.length * 15;
        col.minWidth = minWidth > 150 ? minWidth : 150;
      }
    });

    this.gridOptions.data = this.priceMatrixService.getRows(this.nodeYValues);
  }

  onExpandedChange() {
    if (this.tierPrice.$expanded && !this.matrixDataIsLoaded) {
      this.loadMatrixData();
    }
  }

  saveTierPrice() {
    if (this.hasChangedBasePrice() && this.hasMatrix) {
      if (this.currentCellValuesIsEdited()) {
        this.openSaveTierMenu();
      } else {
        this.updateTierPrice(this.tierPrice, true);
      }
    } else {
      this.updateTierPrice(this.tierPrice);
    }
  }

  updateTierPrice(tierPrice, replaceAll) {
    this.onUpdateTierPrice({ tierPrice, replaceAll }).then(() => {
      if (replaceAll) {
        _.remove(this.originalPurchaseCellValues, {
          supplierId: this.supplierId,
        });
      }
      this.resetPrice(replaceAll);
    });
  }

  currentCellValuesIsEdited() {
    const currentCellValues = _.unionWith(
      this.updatedMatrixCellValues,
      this.originalPurchaseCellValues,
      (updated, original) => {
        return (
          updated.matrixNodeValueXId === original.matrixNodeValueXId &&
          updated.matrixNodeValueYId === original.matrixNodeValueYId
        );
      },
    );
    return !_.every(currentCellValues, { _price: null });
  }

  resetPrice(resetCellValues) {
    this.tierPrice.$edit = false;
    this.setCurrentBasePrice();
    if (resetCellValues) {
      this.setOriginalMatrixCellValues();
      this.discardUpdatedMatrixCellValues();
    }
    this.refreshGridData();
  }

  saveUpdatedMatrixCellValues() {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    _.forEach(this.updatedMatrixCellValues, (value) => {
      value.supplierId = this.supplierId;
    });
    this.ArticlesApiService.updateMatrixCellValues(
      this.updatedMatrixCellValues,
      this.tierPrice.id,
      false,
    )
      .then(
        (matrixCellValues) => {
          this.originalPurchaseCellValues = matrixCellValues;
          this.setOriginalMatrixCellValues();
          this.updatedMatrixCellValues = [];
          this.addMatrixCellValues();
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = this.validationErrors.concat(error.errors);
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  discardUpdatedMatrixCellValues() {
    this.updatedMatrixCellValues = [];
    this.addMatrixCellValues();
  }

  hasChangedBasePrice() {
    const originalBasePrices = this.getOriginalTierPrice(
      tierPrice.id,
    ).basePrices;
    const originalCurrentBasePrice = _.find(originalBasePrices, {
      supplierId: this.supplierId,
    });
    return originalCurrentBasePrice
      ? originalCurrentBasePrice.price !== this.currentBasePrice.price
      : this.currentBasePrice.price !== 0;
  }

  setCurrentBasePrice() {
    this.tierPrice.basePrices = this.tierPrice.basePrices || [];
    let basePrice = _.find(this.tierPrice.basePrices, {
      supplierId: this.supplierId,
    });

    if (!basePrice) {
      basePrice = {
        supplierId: this.supplierId,
        price: 0,
        freightCost: this.getFreightCost(undefined),
        handlingFee: this.getHandlingFee(undefined),
      };
      this.tierPrice.basePrices.push(basePrice);
    } else {
      basePrice.freightCost = this.getFreightCost(undefined);
      basePrice.handlingFee = this.getHandlingFee(undefined);
    }

    this.currentBasePrice = basePrice;
  }

  setOriginalMatrixCellValues() {
    const values = this.originalPurchaseCellValues;
    this.originalMatrixCellValues = _.filter(values, {
      supplierId: this.supplierId,
    });
  }

  getOriginalBasePrice() {
    const basePrice = _.find(this.getOriginalTierPrice().basePrices, {
      supplierId: this.supplierId,
    });

    return basePrice ? basePrice.price : 0;
  }

  startEditTierPrice(clickedField) {
    if (this.hasMatrix && !this.matrixDataIsLoaded) {
      this.loadMatrixData();
    }
    if (this.editable) {
      this.setCurrentBasePrice();
      this.tierPrice.$edit = true;
      this.isEdited = true;
    }
    this.focusField = clickedField;
  }

  closeEditTierPrice() {
    angular.extend(this.tierPrice, this.getOriginalTierPrice());
    this.tierPrice.basePrices = _.map(this.tierPrice.basePrices, _.clone);
    this.setCurrentBasePrice();
    this.tierPrice.$edit = false;
  }

  setEditable(editable) {
    this.gridOptions.enableCellEditOnFocus = editable;
    _.forEach(this.gridOptions.columnDefs, (col) => {
      col.enableCellEdit = editable;
    });
  }

  shouldFocus(field) {
    return this.focusField === field;
  }

  isValidPrice(value) {
    return value || value === 0;
  }

  getCellBasePrice(matrixNodeValueXId, matrixNodeValueYId) {
    const cellValue = this.getCellValue(matrixNodeValueXId, matrixNodeValueYId);
    return cellValue ? cellValue.price : this.getBasePrice();
  }

  setupWatchers() {
    this.$scope.$watch(
      () => this.nodeValuesModified,
      (newValue, oldValue) => {
        if (newValue !== oldValue && this.matrixDataIsLoaded) {
          this.loadMatrixData();
          this.hasMatrix = this.nodeXValues.length && this.nodeYValues.length;
        }
      },
    );

    this.$scope.$watch(
      () => this.supplierId,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.resetPrice(true);
        }
      },
    );

    this.$scope.$watch(
      () => this.tierPrice.$expanded,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.onExpandedChange();
        }
      },
    );

    this.$scope.$watch(
      () => this.purchaseInfos,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.filteredPurchaseInfos = _.map(
            _.filter(
              this.purchaseInfos,
              (i) =>
                i.branchId !== this.tierPrice.branchId && i.isMain && i.isReady,
            ),
            (i) => ({
              branchId: i.branchId,
              name: _.find(this.branches, { id: i.branchId }).name,
              tierPrice: _.find(this.variant.tierPrices, {
                branchId: i.branchId,
                isMain: true,
              }),
            }),
          );
        }
      },
    );
    this.$scope.$watch(
      () => this.tierPrice.basePrices,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.setCurrentBasePrice();
        }
      },
    );

    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.updatedMatrixCellValues);
      },
      (hasChanges) => {
        this.cellValuesSaveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.cellValuesSaveObj);
    });

    this.$scope.$watch(
      () => this.editable,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.setEditable(newValue);
        }
      },
    );
  }
}

angular
  .module('directives.tierPrice', [])
  .controller('TierPriceController', TierPriceController)
  .directive('tierPrice', tierPrice);
