import { Flex } from '@chakra-ui/react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { Props } from '@dnd-kit/core/dist/components/DndContext/DndContext';
import { DragAndDropProvider, useDragAndDrop } from './useDragAndDrop';
import { fadeInScalePopAnimation } from '@texas/resources/animations/animations';
import { Icons } from '../Icons';

interface DragAndDropProps {
  children: React.ReactNode;
  dndProps?: Props;
}

export function DragAndDrop({ children, dndProps }: DragAndDropProps) {
  const dnd = useDragAndDrop();

  const { draggingData, handleDragEnd, handleDragStart } = dnd;

  return (
    <DragAndDropProvider value={dnd}>
      <DndContext
        {...dndProps}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        {children}
        <DragOverlay style={{ pointerEvents: 'none' }}>
          {draggingData ? (
            <Flex
              animation={fadeInScalePopAnimation()}
              boxSize={10}
              bg="white"
              boxShadow="base"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              border="1px solid gray.400"
            >
              <Icons.Cube boxSize={6} color="black" />
            </Flex>
          ) : null}
        </DragOverlay>
      </DndContext>
    </DragAndDropProvider>
  );
}
