import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';
import {
  scaleInAnimation,
  stretchAnimation,
} from '../../resources/animations/animations';

interface IconWithBadgeProps {
  tooltipLabel: string;
  icon: React.ReactNode;
  badge?: boolean;
}

export function IconWithBadge(props: IconWithBadgeProps) {
  return (
    <Tooltip label={props.tooltipLabel}>
      <Box position="relative">
        {props.icon}
        {props.badge && (
          <Box
            pos="absolute"
            bottom={0}
            right={0}
            borderRadius="50%"
            backgroundColor="texas.notification.info"
            boxSize={2}
            animation={`${stretchAnimation()},${scaleInAnimation()}`}
          />
        )}
      </Box>
    </Tooltip>
  );
}
