import {
  Alert,
  AlertDescription,
  AlertIcon,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FileRejection } from 'react-dropzone/.';

export function FileRejections({
  fileRejections,
}: {
  fileRejections: FileRejection[] | undefined;
}) {
  return (
    <>
      {fileRejections?.map((rejection) => (
        <Alert
          status="error"
          key={rejection.file.path}
          borderRadius="md"
          mt={2}
        >
          <AlertIcon />
          <Flex flexDir="column" textAlign="left">
            <AlertDescription>
              <Text fontWeight="medium">{rejection.file.name}</Text>
              {rejection.errors.map((e) => (
                <Text key={e.code}>{e.message}</Text>
              ))}
            </AlertDescription>
          </Flex>
        </Alert>
      ))}
    </>
  );
}
