import { useCallback, useEffect, useState } from 'react';
import { angularEvents } from '../../bridge/angularEvents';
import { createContextValue } from '../utils/contextState';
import {
  SessionState,
  SessionStatus,
  sessionApi,
} from '@texas/api/endpoints/sessionApi';

export const useSessionState = () => {
  const [sessionState, setSessionState] = useState<SessionState>({
    status: SessionStatus.Inactive,
  });

  const getSession = useCallback(async () => {
    setSessionState({ status: SessionStatus.Fetching });
    const response = await sessionApi.getSession();

    if (!response.hasError) {
      setSessionState({
        status: SessionStatus.Active,
        session: response.data,
      });
    } else {
      setSessionState({
        status: SessionStatus.Error,
        error: response.error,
      });
    }
  }, []);

  useEffect(() => {
    getSession();
    return angularEvents.currentUserFetched.subscribe(() => {
      getSession();
    });
  }, [getSession]);

  return [sessionState, setSessionState] as const;
};

export const [SessionStateProvider, useSession] =
  createContextValue<SessionState>();

export function useActiveSession() {
  const sessionState = useSession();

  if (sessionState.status !== SessionStatus.Active) {
    throw new Error('No active session');
  }

  return sessionState.session;
}
