// @ngInject
const matrixGroupsResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadMatrixGroups(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.matrixGroups', {
    url: '/matrixgroups',
    controller: 'MatrixGroupsConfigurationController as vm',
    templateUrl: 'main/configuration/matrixGroups/matrixGroups.tpl.html',
    data: { pageTitle: 'Matrix Groups' },
    resolve: {
      matrixGroups: matrixGroupsResolve,
    },
  });
};

class MatrixGroupsConfigurationController {
  constructor(
    $scope,
    currentUser,
    DS,
    MatrixGroupDialogService,
    matrixGroups,
    AuthService,
  ) {
    'ngInject';

    this._MatrixGroupDialogService = MatrixGroupDialogService;
    this._currentUser = currentUser;
    this.allowedToCreateNew = AuthService.hasAnyClaim([
      'system_administrator',
      'organization_administrator',
    ]);
    this.matrixGroups = matrixGroups;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('matrixGroup');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.matrixGroups = currentUser.organization.matrixGroups;
        }
      },
    );
  }

  openMatrixGroup(matrixGroup) {
    this._MatrixGroupDialogService.showDialog({
      organization: this._currentUser.organization,
      matrixGroup,
      allowedToEdit: this.allowedToCreateNew,
    });
  }

  newMatrixGroup() {
    this._MatrixGroupDialogService.showDialog({
      organization: this._currentUser.organization,
      allowedToEdit: this.allowedToCreateNew,
    });
  }
}

angular
  .module('configuration.matrixGroups', [])
  .config(config)
  .controller(
    'MatrixGroupsConfigurationController',
    MatrixGroupsConfigurationController,
  );
