import {
  Flex,
  Button,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CompositionGroupView } from './CompositionGroupView';
import { RefObject } from 'react';

export function ConfirmCopy({
  compositionGroupId,
  onConfirm,
  isOpen,
  title,
  ref,
  onClose,
}: {
  compositionGroupId: number;
  onConfirm: (id: number) => void;
  isOpen: boolean;
  title: string;
  ref?: RefObject<HTMLElement>;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      portalProps={{ containerRef: ref }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent width="auto" maxW="fit-content">
        <ModalBody>
          <Text textAlign="center">{title}</Text>
          <Flex flexDir="column" gap={4}>
            <CompositionGroupView
              variantId={0}
              compositionGroupId={compositionGroupId}
              componentSettings={{
                compositionArchiveRestore: 'hidden',
                compositionEdit: 'hidden',
                compositionGroupDots: 'hidden',
              }}
            />
            <Button onClick={() => onConfirm(compositionGroupId)}>
              {t('composition.copyComposition')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
