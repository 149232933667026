export const droppableZoneStyles = (
  isDragging: boolean,
  validDropZone: boolean,
  isOver: boolean,
  isDark: boolean,
) => {
  return {
    ...(isDragging
      ? validDropZone
        ? {
            opacity: 1,
            cursor: 'copy',
            outline: '1px solid',
            outlineColor: isDark ? 'white' : 'black',
            transform: isOver ? 'scale(1.05)' : null,
          }
        : { opacity: 0.5, cursor: 'no-drop', transform: 'scale(0.9)' }
      : null),
  };
};
