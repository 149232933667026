// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.dimensionGroups', {
    url: '/dimensiongroups',
    templateUrl: 'main/configuration/dimensionGroups/dimensionGroups.tpl.html',
    data: { pageTitle: 'Dimension groups' },
  });
};

angular.module('configuration.dimensionGroups', []).config(config);
