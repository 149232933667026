// @ngInject
const taskForm = () => ({
  templateUrl: 'directives/taskForm/taskForm.tpl.html',
  restrict: 'E',
  scope: {},
  bindToController: {
    task: '=',
    onDelete: '&',
    onSelect: '&',
    onChange: '&',
  },
  controller: 'TaskFormController',
  controllerAs: 'vm',
});

class TaskFormController {
  constructor(
    $scope,
    $filter,
    $mdDialog,
    HelperService,
    toasterService,
    TasksApiService,
    UsersApiService,
    loadSpinnerService,
    AuthService,
    SavingService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$filter = $filter;
    this.$mdDialog = $mdDialog;
    this.HelperService = HelperService;
    this.toasterService = toasterService;
    this.TasksApiService = TasksApiService;
    this.UsersApiService = UsersApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.SavingService = SavingService;

    this.currentUser = {};
    this.users = [];
    this.originalTask = {};
    this.taskCopy = {};
    this.assignedUsers = null;

    AuthService.getUser().then((user) => {
      this.currentUser = user;
      this.loadUsers();
    });
    this.setWatchers();
    this.saveObj = SavingService.registerSave({
      onSave: () => this.updateTask(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validateForm(),
    });
  }

  setWatchers() {
    this.$scope.$watch(
      () => this.task,
      (updatedTask) => {
        if (updatedTask) {
          if (updatedTask.id !== this.originalTask.id) {
            this.setTaskCopy(updatedTask);
          } else {
            if (_.isEmpty(this.getChangedBaseData())) {
              this.originalTask = this.getTaskClone(this.task);
              this.taskCopy = this.getTaskClone(this.task);
            }
          }
        }
      },
    );

    this.$scope.$watch(
      () => this.task.isDone,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.taskCopy.isDone = newValue;
        }
      },
    );

    this.$scope.$watch(
      () => this.task.assignedUsers,
      () => {
        if (!this.hasChangedUsers()) {
          this.assignedUsers = _.clone(this.task._taskUsers);
          this.originalAssignedUsers = _.clone(this.task._taskUsers);
        }
      },
      true,
    );

    this.$scope.$watch(
      () => !_.isEmpty(this.getChangedData()),
      (hasChanges) => {
        if (!hasChanges) {
          this.editTaskForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
    });
  }

  loadUsers() {
    const organizationId = this.currentUser.organization.id;
    this.UsersApiService.getAllByOrganizationId(organizationId).then(
      (response) => (this.users = response),
    );
  }

  getUsers(query) {
    return _.filter(
      this.$filter('filter')(this.users, { uniqueName: query }),
      (user) => !_.find(this.assignedUsers, { userId: user.id }),
    );
  }

  validateForm() {
    if (!this.editTaskForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  getChangedData() {
    const changedData = this.getChangedBaseData();

    if (this.assignedUsers !== null) {
      if (this.hasChangedUsers()) {
        changedData.assignedUsers = this.assignedUsers;
      }
    }

    return changedData;
  }

  getChangedBaseData() {
    const changedData = this.HelperService.getChangedData(
      this.originalTask,
      this.taskCopy,
      ['title', 'description'],
    );

    if (moment(this.taskCopy.dueDate).diff(moment(this.originalTask.dueDate))) {
      changedData.dueDate = this.taskCopy.dueDate;
    }

    return changedData;
  }

  hasChangedUsers() {
    if (!this.originalAssignedUsers) {
      return false;
    }
    return this.HelperService.isArrayModified(
      this.originalAssignedUsers,
      this.assignedUsers,
      ['id'],
    );
  }

  updateTask(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.updateTask(this.taskCopy.id, data)
      .then(
        () => {
          this.setTaskCopy(this.task);
          this.toasterService.success();
          if (this.onChange) {
            this.onChange();
          }
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  selectTask(task) {
    this.onSelect({ task });
  }

  discard() {
    this.validationErrors = [];
    this.setTaskCopy(this.task);
  }

  deleteTask(task) {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.deleteTask(task)
      .then(
        (task) => {
          this.toasterService.success();
          this.onDelete({ task });
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  toggleDoneStatus() {
    this.saveDoneStatus();
  }

  saveDoneStatus() {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.updateTask(this.taskCopy.id, {
      isDone: this.taskCopy.isDone,
    })
      .then(() => this.toasterService.success())
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  setTaskCopy(task) {
    if (this.editTaskForm) {
      this.editTaskForm.$setPristine();
    }
    this.originalTask = this.getTaskClone(task);
    this.taskCopy = this.getTaskClone(task);
    this.assignedUsers = _.clone(this.task._taskUsers);
    this.originalAssignedUsers = _.clone(this.task._taskUsers);
  }

  getTaskClone(task) {
    const taskClone = _.extend({}, task);
    taskClone.dueDate = task.dueDate ? new Date(task.dueDate) : undefined;
    taskClone.creator = task.creator;
    return taskClone;
  }

  assignUser(user) {
    this.selectedUser = null;
    this.userSearchText = null;
    if (user) {
      this.assignedUsers.push({
        userId: user.id,
        confirmation: user.id === this.currentUser.id ? 'Accepted' : 'None',
        user,
      });
    }
  }

  removeAssignedUser(assignedUser) {
    _.remove(this.assignedUsers, { userId: assignedUser.userId });
  }
}

angular
  .module('directives.taskForm', [])
  .controller('TaskFormController', TaskFormController)
  .directive('taskForm', taskForm);
