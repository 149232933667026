export const defaultWidgetLayouts = {
  articles: {
    lg: {
      x: 0,
      y: 3,
      w: 12,
      h: 8,
    },
  },
  brands: {
    lg: {
      x: 0,
      y: 0,
      w: 12,
      h: 2,
    },
  },
  articlesCount: {
    lg: {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
    },
  },
};

export const widgetSizeConstraints = {
  articles: {
    minH: 2,
    minW: 3,
    maxH: 8,
  },
  brands: {
    minH: 2,
    minW: 4,
    maxH: 6,
  },
  articlesCount: {
    minH: 2,
    minW: 3,
    maxH: 8,
  },
};
