import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Flex,
  ModalCloseButton,
  FormControl,
} from '@chakra-ui/react';

import { blinkAnimation } from '@texas/resources/animations/animations';
import { ReactSelectOption, ServerError } from '@texas/types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { request } from '@texas/utils/helpers/httpHelpers';
import { articlesApi, MoveArticle } from '@texas/api/endpoints/articlesApi';
import { customersApi } from '@texas/api/endpoints/customersApi';
import { FilterItem } from '@texas/api/endpoints/searchApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from './Icons';
import { ErrorDetails } from './alert/ErrorDetails';
import { SharedDisclosureProps } from './types';
import { TexasSelect } from './form/TexasSelect';
import { TexasFormLabel } from './form/TexasFormLabel';

export interface MoveArticlesProps extends SharedDisclosureProps {
  onArticlesMoved: () => void;
  articles: number[] | null;
}

export function MoveArticles(props: MoveArticlesProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    request: moveRequest,
    error,
    loading,
  } = useApiRequest(articlesApi.moveArticles);

  const [newBrand, setNewBrand] = useState<FilterItem | null>(null);
  const [newConcept, setNewConcept] = useState<FilterItem | null>(null);

  const {
    data: articles,
    refetch: refetchArticles,
    loading: articlesLoading,
    set: setArticles,
  } = useApiResource(articlesApi.getMoveArticles);

  useEffect(() => {
    setNewBrand(null);
    setNewConcept(null);
    setArticles(null);
    if (!props.articles) return;
    refetchArticles(props.articles);
  }, [props.articles, refetchArticles, setArticles]);

  const {
    data: brands,
    refetch,
    set,
  } = useApiResource(customersApi.filterCustomers);
  const {
    data: concepts,
    refetch: refetchConcepts,
    set: setConcepts,
  } = useApiResource(customersApi.getConcepts);

  const onChangeDebounced = useDebouncedCallback((input: string) => {
    if (input.length === 0) {
      set([]);
      return;
    }
    refetch({ customerName: input, assignedTo: null });
  }, 100);

  useEffect(() => {
    if (!newBrand) {
      setConcepts([]);
      return;
    }
    refetchConcepts(newBrand.value);
  }, [newBrand, refetchConcepts, setConcepts]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('article.moveArticles')}</ModalHeader>
        <ModalBody>
          <ErrorDetails error={error} />
          <Flex dir="column" justify="center">
            {articlesLoading && <Spinner />}
            {!articlesLoading && (
              <TableContainer flexGrow={1}>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>{t('general.itemCode')}</Th>
                      <Th>
                        {t('general.brand')} ({t('general.project')})
                      </Th>
                      <Th>{t('general.concept')}</Th>
                      <Th />
                      <Th fontWeight="bold">{t('general.newBrand')}</Th>
                      <Th fontWeight="bold">{t('general.newConcept')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {articles?.map((x) => {
                      return (
                        <Tr key={x.articleId}>
                          <Td>{x.itemCode}</Td>
                          <Td>
                            {x.brand}{' '}
                            {x.project.length > 0 && <>({x.project})</>}
                          </Td>
                          <Td>{x.concept}</Td>
                          <Td>
                            <Icons.ArrowRight />
                          </Td>
                          <Td
                            animation={blinkAnimation()}
                            color="texas.sand.100"
                            _light={{ color: 'texas.burntSienna.500' }}
                            fontWeight="bold"
                          >
                            {newBrand?.label}
                          </Td>
                          <Td
                            animation={blinkAnimation()}
                            color="texas.sand.100"
                            _light={{ color: 'texas.burntSienna.500' }}
                            fontWeight="bold"
                          >
                            {newConcept?.label}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Flex>
          <Flex my={4} gap={4}>
            <FormControl>
              <TexasFormLabel>{t('general.brand')}</TexasFormLabel>
              <TexasSelect
                onInputChange={(e) => onChangeDebounced(e)}
                onChange={(value) => setNewBrand(value)}
                isClearable={true}
                options={
                  brands?.map<ReactSelectOption>((x) => ({
                    label: `${x.name} ${
                      x.project.length > 0 ? `(${x.project})` : ''
                    }`,
                    value: x.id,
                  })) ?? []
                }
              />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('general.concept')}</TexasFormLabel>
              <TexasSelect
                onChange={(value) => setNewConcept(value)}
                isClearable={true}
                options={
                  concepts?.map<ReactSelectOption>((x) => ({
                    label: x.name,
                    value: x.id,
                  })) ?? []
                }
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter
          backgroundColor="gray.800"
          _light={{ backgroundColor: 'gray.50' }}
        >
          <Button variant="texas-outline-light" onClick={props.onClose}>
            {t('general.close')}
          </Button>
          <Button
            variant="texas-solid"
            ml={3}
            isLoading={loading}
            isDisabled={loading || newBrand === null}
            onClick={async () =>
              await request(
                moveRequest,
                [
                  {
                    articleIds: articles?.map((x) => x.articleId) ?? [],
                    toConceptId: newConcept?.value ?? null,
                    toCustomerId: newBrand?.value ?? -1,
                  },
                ],
                (_: MoveArticle[]) => {
                  toast({
                    title: t('general.updated'),
                    status: 'success',
                    isClosable: true,
                  });
                  props.onArticlesMoved();
                  props.onClose();
                },
                (error: ServerError) => {
                  toast({
                    title: t('general.updateFailed'),
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                  });
                },
              )
            }
          >
            {t('article.moveArticles')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
