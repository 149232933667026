// @ngInject
const handlingFeesResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadHandlingFees(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.handlingFees', {
    url: '/handlingfees',
    controller: 'HandlingFeesConfigurationController as vm',
    templateUrl: 'main/configuration/handlingFees/handlingFees.tpl.html',
    data: { pageTitle: 'Delivery terms' },
    resolve: {
      handlingFees: handlingFeesResolve,
    },
  });
};

class HandlingFeesConfigurationController {
  constructor(
    $scope,
    DS,
    HandlingFeesApiService,
    ConfigItemDialogService,
    currentUser,
    handlingFees,
  ) {
    'ngInject';

    this._ConfigItemDialogService = ConfigItemDialogService;
    this._HandlingFeesApiService = HandlingFeesApiService;
    this._currentUser = currentUser;
    this.handlingFees = handlingFees;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('handlingFee');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.handlingFees = currentUser.organization.handlingFees;
        }
      },
    );
  }

  openHandlingFee(handlingFee) {
    this._ConfigItemDialogService.showDialog({
      configItem: handlingFee,
      displayOrder: true,
      value: true,
      onSave: (handlingFee) =>
        this._HandlingFeesApiService.updateHandlingFee(
          _.pick(handlingFee, ['name', 'value', 'displayOrder', 'archived']),
          handlingFee.id,
        ),
    });
  }

  newHandlingFee() {
    this._ConfigItemDialogService.showDialog({
      displayOrder: true,
      value: true,
      onSave: (handlingFee) =>
        this._HandlingFeesApiService.newHandlingFee(
          handlingFee,
          this._currentUser.organization.id,
        ),
    });
  }
}

angular
  .module('configuration.handlingFees', [])
  .config(config)
  .controller(
    'HandlingFeesConfigurationController',
    HandlingFeesConfigurationController,
  );
