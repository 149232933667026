// @ngInject
const taskItem = () => ({
  templateUrl: 'directives/taskItem/taskItem.tpl.html',
  restrict: 'EA',
  scope: {},
  bindToController: {
    task: '=taskItem',
    hideEntityBadge: '=',
    toggleDoneStatus: '&?',
    assignToMe: '&?',
    confirm: '&?',
    selectTask: '&?',
    removeTask: '&?',
    onChange: '&',
  },
  controller: 'TaskItemController',
  controllerAs: 'vm',
});

class TaskItemController {
  constructor($scope, $mdDialog, AuthService) {
    'ngInject';

    this.$mdDialog = $mdDialog;

    this.currentDate = new Date();
    this.currentUser = {};
    this.isDone = this.task.isDone;

    AuthService.getUser().then((user) => (this.currentUser = user));

    $scope.$watch(
      () => this.task.isDone,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.isDone = newValue;
        }
      },
    );
  }

  hasExpired() {
    if (this.task.dueDate && !this.task.isDone) {
      return Date.parse(this.task.dueDate) < this.currentDate;
    } else {
      return false;
    }
  }

  toggleDoneCheckbox(task) {
    task.isDone = this.isDone;
    this.toggleDoneStatus();
  }

  showAssignUserConfirmation() {
    return this.currentUser
      ? _.some(this.task.assignedUsers, {
          userId: this.currentUser.id,
          confirmation: 'None',
        })
      : false;
  }

  getAssignedUser() {
    return _.find(this.task.assignedUsers, { userId: this.currentUser.id });
  }
}

angular
  .module('directives.taskItem', [])
  .controller('TaskItemController', TaskItemController)
  .directive('taskItem', taskItem);
