import { Box, VStack, Text } from '@chakra-ui/react';

interface TexasDrawerHeaderProps {
  title?: string;
  subTitle?: string;
}

export function TexasDrawerHeader(props: TexasDrawerHeaderProps) {
  return (
    <Box>
      <VStack padding={4} alignItems="start" spacing={0}>
        <Text variant="header">{props.title}</Text>
        <Text variant="sub">{props.subTitle}</Text>
      </VStack>
    </Box>
  );
}
