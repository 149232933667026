import {
  Box,
  Card,
  CardBody,
  Flex,
  IconButton,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { clientEndpoints } from '@texas/clientEndpoints';
import { BlurTag } from '@texas/components/shared/blur/BlurTag';
import { Icons } from '@texas/components/shared/Icons';
import { Locale } from '@texas/i18n/types';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { getExtension } from '@texas/utils/helpers/filesHelper';
import { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '@texas/types';
import { FileUploads } from './FileUploads';
import { FileRejections } from './FileRejections';
import { notImageValidator } from './shared';

export function DocumentDropzone({
  document,
  allowEdit,
  onUpload,
  isUploading,
  uploadFailed,
  fileUploads,
  abortFileUpload,
  onRemovefileOpen,
  onFileBrowserOpen,
}: {
  document: FileLink;
  allowEdit: boolean;
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  uploadFailed: boolean;
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
  onRemovefileOpen: () => void;
  onFileBrowserOpen: () => void;
}) {
  const { t } = useTranslation();
  const [fileRejections, setFileRejections] = useState<FileRejection[]>();

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      onDrop: handleDrop,
      useFsAccessApi: false,
      noClick: true,
      multiple: false,
      validator: notImageValidator,
    });

  function handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    setFileRejections(fileRejections);

    if (acceptedFiles.length) {
      onUpload(acceptedFiles);
    }
  }
  return (
    <Card
      animation={fadeInScaleAnimation()}
      height="100%"
      w="100%"
      boxShadow="none"
    >
      <CardBody
        p={3}
        position="relative"
        bg="gray.700"
        _light={{
          bg: 'white',
          border: '1px solid',
          borderColor: isDragReject
            ? 'red.500'
            : isDragActive
            ? 'gray.500'
            : 'gray.200',
        }}
        borderColor={
          isDragReject ? 'red.300' : isDragActive ? 'gray.200' : 'gray.500'
        }
        {...getRootProps()}
      >
        <Flex
          gap={2}
          role="group"
          alignItems="center"
          filter={isDragActive ? 'blur(3px)' : 'none'}
        >
          <Icons.FileOutline
            boxSize={16}
            color="gray.500"
            _light={{ color: 'gray.100' }}
          />
          <Flex direction="column" flexGrow={1} pr={4}>
            <Text>{document.name}</Text>
            <Flex gap={2} alignItems="center">
              <Tag
                size="sm"
                lineHeight={1}
                textTransform="uppercase"
                h="fit-content"
                w="fit-content"
                variant="lighter"
              >
                {getExtension(document.extension)}
              </Tag>
              <Text variant="small">
                {formatDate(Locale.En, document.created)}
              </Text>
            </Flex>
            <Text variant="sub">{document.creatorName}</Text>
          </Flex>
          <Flex
            direction="column"
            opacity={0}
            _groupHover={{ opacity: isDragActive ? 0 : 1 }}
          >
            <Tooltip label={t('fileBrowser.downloadFile')}>
              <IconButton
                icon={<Icons.Download boxSize={6} />}
                aria-label={t('fileBrowser.downloadFile')}
                size="sm"
                variant="no-bg"
                as="a"
                href={clientEndpoints.downloadFile(document.identifier)}
                target="_blank"
                download={true}
              />
            </Tooltip>
            {allowEdit && (
              <>
                <Tooltip label={t('fileBrowser.chooseFromLibrary')}>
                  <IconButton
                    icon={<Icons.OpenInNew boxSize={6} />}
                    aria-label={t('fileBrowser.chooseFromLibrary')}
                    size="sm"
                    variant="no-bg"
                    onClick={() => {
                      onFileBrowserOpen();
                      setFileRejections([]);
                    }}
                  />
                </Tooltip>
                <Tooltip label={t('fileBrowser.uploadFile')}>
                  <IconButton
                    icon={<Icons.Upload boxSize={6} />}
                    aria-label={t('fileBrowser.uploadFile')}
                    size="sm"
                    variant="no-bg"
                    onClick={open}
                  />
                </Tooltip>
              </>
            )}
          </Flex>
          {allowEdit && (
            <Box
              position="absolute"
              top={-2}
              left={-4}
              opacity={0}
              _groupHover={{ opacity: isDragActive ? 0 : 1 }}
            >
              <Tooltip label={t('general.remove')}>
                <IconButton
                  icon={<Icons.Close boxSize={6} />}
                  aria-label={t('fileBrowser.removeFile')}
                  size="sm"
                  variant="texas-solid"
                  onClick={() => {
                    onRemovefileOpen();
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Flex>
        {(isUploading || uploadFailed) && (
          <FileUploads
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
          />
        )}
        {isDragActive && (
          <BlurTag
            validText={t('fileBrowser.uploadAndReplaceDocument')}
            rejectText={t('fileBrowser.notValid')}
            isValid={!isDragReject}
          />
        )}
        <FileRejections fileRejections={fileRejections} />
        <input multiple={false} {...getInputProps()} />
      </CardBody>
    </Card>
  );
}
