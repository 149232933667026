import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GeneratedProductApproval } from '../../../types';

export function CopyPreviousValue({
  previousPa,
  value,
  onCopy,
}: {
  previousPa: GeneratedProductApproval | null | undefined;
  value: string | undefined;
  onCopy: (previousPa: GeneratedProductApproval) => void;
}) {
  const { t } = useTranslation();
  if (!previousPa || !value) return null;

  return (
    <Button
      justifyContent="start"
      py={2}
      onClick={() => onCopy(previousPa)}
      variant="link"
    >
      {t('general.copyValue', { value: value })}
    </Button>
  );
}
