// @ngInject
const NoteDialogService = ($mdDialog) => ({
  showDialog(data) {
    const options = {
      clickOutsideToClose: true,
      locals: { data },
    };

    options.controller = 'NoteDialogController as vm';
    options.templateUrl = 'services/dialogs/noteDialog/noteDialog.tpl.html';

    return $mdDialog.show(options);
  },
});

class NoteDialogController {
  constructor($mdDialog, data) {
    'ngInject';

    this.validationErrors = [];
    this._$mdDialog = $mdDialog;
    this.notes = data.notes;
    this.disabled = data.disabled;
  }

  save(notes) {
    this._$mdDialog.hide({ notes });
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.noteDialog', [])
  .service('NoteDialogService', NoteDialogService)
  .controller('NoteDialogController', NoteDialogController);
