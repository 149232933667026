// @ngInject
const supplierSelector = () => ({
  templateUrl: 'directives/supplierSelector/supplierSelector.tpl.html',
  restrict: 'EA',
  scope: {},
  bindToController: {
    selectedPurchaseInfo: '=',
    purchaseInfos: '=',
    getSuppliers: '&',
    selectPurchaseInfo: '&',
    newPurchaseInfo: '&?',
    selectedBranchId: '=',
    editable: '=',
  },
  controller: 'SupplierSelectorController',
  controllerAs: 'vm',
});

class SupplierSelectorController {
  constructor(ArticlesApiService, loadSpinnerService, toasterService) {
    'ngInject';

    this.ArticlesApiService = ArticlesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
  }

  isMainSupplier(supplierId) {
    return _.some(this.purchaseInfos, {
      isMain: true,
      supplierId,
      branchId: this.selectedBranchId,
    });
  }

  filterSuppliers() {
    const suppliers = this.getSuppliers();
    return _.orderBy(
      _.filter(suppliers, (supplier) =>
        _.some(this.purchaseInfos, {
          supplierId: supplier.id,
          branchId: this.selectedBranchId,
        }),
      ),
      'name',
    );
  }

  getCurrentSupplierNumber(supplier) {
    if (!supplier) {
      return '';
    }
    const branchData = _.find(supplier.branchData, {
      branchId: this.selectedBranchId,
    });
    return branchData ? branchData.supplierNumber : '';
  }

  setCurrentPurchaseInfoAsMain() {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updatePurchaseInfo(
      { isMain: true },
      this.selectedPurchaseInfo.id,
    )
      .then(
        (updatedPurchaseInfo) => {
          this.selectedPurchaseInfo.isMain = updatedPurchaseInfo.isMain;
          const purchaseInfos = _.filter(this.purchaseInfos, {
            branchId: this.selectedBranchId,
          });
          _.forEach(purchaseInfos, (p) => {
            p.isMain = p.id === this.selectedPurchaseInfo.id;
          });
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }
}

angular
  .module('directives.supplierSelector', [])
  .controller('SupplierSelectorController', SupplierSelectorController)
  .directive('supplierSelector', supplierSelector);
