// @ngInject
const FreightCostsApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('freightCost', {
      params: { organizationId },
    }),

  getById: (freightCostId) =>
    ResourcesService.getById('freightCost', freightCostId, {
      params: { organizationId: false },
    }),

  newFreightCost: (freightCost, organizationId) =>
    ResourcesService.create('freightCost', freightCost, {
      params: { organizationId },
    }),

  updateFreightCost: (data, freightCostId) =>
    ResourcesService.update('freightCost', freightCostId, data, {
      params: { organizationId: false },
      updatedFields: ['name', 'value', 'displayOrder', 'archived'],
    }),
});

angular
  .module('resources.freightCosts', [])
  .factory('FreightCostsApiService', FreightCostsApiService);
