import {
  UserProfile,
  userProfilesApi,
} from '@texas/api/endpoints/userProfilesApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface ArchiveUserProfileDialogProps extends SharedDisclosureProps {
  onArchive: (profile: UserProfile) => void;
  userProfile: UserProfile;
}

export function ArchiveUserProfileDialog(props: ArchiveUserProfileDialogProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('userProfile.archiveUserProfile', {
        name: props.userProfile.name,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={userProfilesApi.archiveUserProfile}
      args={[props.userProfile.id]}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPerformed={props.onArchive}
      onSuccessTitle={t('general.successfullyArchived')}
      onFailureTitle={t('general.archiveFailed')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
