const HelperService = () => ({
  isArrayModified: (original, edited, keys, sortBy = false) => {
    if (
      (!original && !edited) ||
      (!original && edited && !edited.length) ||
      (!edited && original && !original.length)
    ) {
      return false;
    }
    if (
      ((!original || !original.length) && edited && edited.length) ||
      ((!edited || !edited.length) && original && original.length) ||
      original.length !== edited.length
    ) {
      return true;
    }

    if (sortBy) {
      original = _.sortBy(original, sortBy);
      edited = _.sortBy(edited, sortBy);
    }
    return _.some(_.unzip([original, edited]), (pair) => {
      if (!keys || !keys.length) {
        return !_.eq(pair[0], pair[1]);
      }
      return _.some(keys, (key) => !_.eq(pair[0][key], pair[1][key]));
    });
  },

  getChangedData: (original, edited, keys, ignoredKeys = []) => {
    const diff = {};
    const editedClone = _.cloneDeep(edited);
    _.forEach(keys, (key) => {
      if (ignoredKeys.length > 0) {
        _.forEach(editedClone[key], (field) => {
          _.forEach(ignoredKeys, (ignoredKey) => {
            delete field[ignoredKey];
          });
        });
      }

      if (!angular.equals(original[key], editedClone[key])) {
        diff[key] = editedClone[key];
      }
    });
    return diff;
  },

  getCleanFileName: (fileName) =>
    fileName.substring(
      0,
      fileName.lastIndexOf('.') === -1
        ? fileName.length
        : fileName.lastIndexOf('.'),
    ),

  round: (num, decimals) => {
    const n = Math.pow(10, decimals);
    return Math.round((n * num).toFixed(decimals)) / n;
  },

  isValidDecimal: (value) => /^\d+[,.]?(\d+|)$/.test(value), // Matches: 1123, 1123.56 and 1123.

  containsText: (obj, predicate) =>
    _.some(predicate, (searchText, key) => {
      if (_.isEmpty(searchText)) {
        return true;
      }

      if (_.isUndefined(obj[key]) || _.isNull(obj[key])) {
        return false;
      }

      const valueText = obj[key].toString().toLowerCase();

      return _.includes(valueText, searchText.toLowerCase());
    }),

  toHHMMSS: (d) => {
    if (isNaN(d) || !isFinite(d)) {
      return '0';
    }
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    return `${
      (h > 0 ? `${h}h` : '') +
      (m > 0 ? `${(h > 0 && m < 10 ? '0' : '') + m}m` : '0m') +
      (s < 10 ? '0' : '') +
      s
    }s`;
  },
});

angular.module('services.helper', []).service('HelperService', HelperService);
