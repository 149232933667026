import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Text,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import {
  nodeGroupIsPopulated,
  formatNodeGroup,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { useTranslation } from 'react-i18next';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../../../types';
import { TFunction } from 'i18next';
import { Icons } from '@texas/components/shared/Icons';
import { validProductApproval } from './shared';

export function ProductApprovalList({
  selectedKey,
  nodeGroups,
  productApprovals,
  onClick,
  onHandle,
}: {
  selectedKey: string;
  nodeGroups: ArticleNodeGroup | undefined;
  productApprovals: GeneratedProductApproval[];
  onClick: (key: string) => void;
  onHandle: (key: string, state: GeneratedProductApprovalState) => void;
}) {
  const { t } = useTranslation();
  if (productApprovals.length === 0) {
    return (
      <Text color="gray.300" textAlign="center">
        {t('wizard.productApproval.noSuppliers')}
      </Text>
    );
  }
  return (
    <Table variant="select-product-approval">
      <Thead>
        <Tr>
          <Th />
          <Th />
          {nodeGroupIsPopulated(nodeGroups) && <Th />}
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {productApprovals.map((x) => {
          return (
            <Tr
              onClick={() => onClick(x.key)}
              key={x.key}
              backgroundColor={
                selectedKey === x.key ? 'texas.bg.700' : 'transparent'
              }
              _light={{
                backgroundColor:
                  selectedKey === x.key ? 'gray.50' : 'transparent',
              }}
            >
              <Td>
                <Text>{x.supplier.name}</Text>
                {x.compositionGroup && (
                  <Text>
                    {t('composition.versionNr', {
                      nr: x.compositionGroup.version,
                    })}
                  </Text>
                )}
                <Text variant="sub">{validateProductApproval(x, t)}</Text>
              </Td>
              <Td>
                <Text>{x.supplier.branch.identifier}</Text>
              </Td>
              {nodeGroupIsPopulated(nodeGroups) && (
                <Td>
                  {formatNodeGroup(
                    nodeGroups!.nodeXValues[x.cell!.xIndex],
                    nodeGroups!.nodeYValues[x.cell!.yIndex],
                  )}
                </Td>
              )}
              <Td>
                <Flex justifyContent="flex-end">
                  {x.state === GeneratedProductApprovalState.Unhandled && (
                    <>
                      <Tooltip label={t('wizard.productApproval.ignore')}>
                        <IconButton
                          variant="ghost"
                          icon={<Icons.EyeOff />}
                          onClick={(e) => {
                            onHandle(
                              x.key,
                              GeneratedProductApprovalState.Ignored,
                            );
                            e.stopPropagation();
                          }}
                          aria-label={t('wizard.productApproval.ignore')}
                        />
                      </Tooltip>
                      <Tooltip label={t('wizard.productApproval.ready')}>
                        <IconButton
                          variant="ghost"
                          isDisabled={validProductApproval(x) !== null}
                          colorScheme={
                            validProductApproval(x) === null ? 'green' : 'gray'
                          }
                          icon={<Icons.CheckCircle />}
                          onClick={(e) => {
                            onHandle(
                              x.key,
                              GeneratedProductApprovalState.Accepted,
                            );
                            e.stopPropagation();
                          }}
                          aria-label={t('wizard.productApproval.ready')}
                        />
                      </Tooltip>
                    </>
                  )}
                  {(x.state === GeneratedProductApprovalState.Ignored ||
                    x.state === GeneratedProductApprovalState.Accepted) && (
                    <Tooltip label={t('wizard.productApproval.setAsUnhandled')}>
                      <IconButton
                        variant="ghost"
                        icon={<Icons.ArchiveRemove />}
                        onClick={(e) => {
                          onHandle(
                            x.key,
                            GeneratedProductApprovalState.Unhandled,
                          );
                          e.stopPropagation();
                        }}
                        aria-label={t('wizard.productApproval.setAsUnhandled')}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

function validateProductApproval(pa: GeneratedProductApproval, t: TFunction) {
  if (pa.compositionGroup === null)
    return t('wizard.productApproval.noComposition');

  if (pa.customerContact === null || pa.supplierContact === null)
    return t('wizard.productApproval.selectContacts');

  return null;
}
