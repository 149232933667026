import { TFunction } from 'i18next';
import { PagedItems } from '../../types';
import {
  FilterItem,
  SearchFilterQuery,
  FilterOptionIds,
} from '@texas/api/endpoints/searchApi';
import { HttpClientResponse } from '@texas/api/httpClient/types';

export interface BaseFilterProps {
  onRemove?: () => void;
  name: string;
  static: boolean;
}

export interface SelectFilterProps extends BaseFilterProps {
  valueIds: number[];
  onChange: (ids: number[]) => void;
  filteredOptions: number[];
  fetchValues: FetchValuesType;
  fetchOptions: FetchOptionsType;
  defaultIsOpen: boolean;
  minSearchTermLength: number;
  customOptionComponentView: ((value: FilterItem) => JSX.Element) | undefined;
  customValueLabelComponentView:
    | ((value: FilterItem) => JSX.Element)
    | undefined;
}

export interface SwitchFilterProps extends BaseFilterProps {
  onChange: (value: boolean) => void;
  offLabel: string;
  onLabel: string;
  checked: boolean;
}

export type FetchOptionsType = (
  query: SearchFilterQuery,
) => Promise<HttpClientResponse<PagedItems<FilterItem>>>;

export type FetchValuesType = (
  params: number[],
) => Promise<HttpClientResponse<FilterItem[]>>;

export enum FilterOptionType {
  Branches,
  Customers,
  Concepts,
  States,
  ShowAllOptions,
  CategoryCodes,
  IncludeArchived,
  Materials,
  ProductGroups,
  Processes,
  ShowMyBrands,
}

export enum FilterTypeEnum {
  Checkbox,
  Select,
}

export interface BaseFilter {
  optionType: FilterOptionType;
  getName: (t: TFunction) => string;
}

export interface SelectFilter extends BaseFilter {
  queryParamsKey:
    | 'branchIds'
    | 'customerIds'
    | 'conceptIds'
    | 'states'
    | 'categoryCodeIds'
    | 'materialIds'
    | 'productGroupIds'
    | 'processes';
  type: FilterTypeEnum.Select;
  fetchValues: FetchValuesType;
  fetchOptions: FetchOptionsType;
  minSearchTermLength: number;
  customOptionComponentView?: (value: FilterItem) => JSX.Element;
  customValueLabelComponentView?: (value: FilterItem) => JSX.Element;
}

export interface CheckboxFilter extends BaseFilter {
  queryParamsKey: 'showAllVariants' | 'includeArchived' | 'showMyBrands';
  type: FilterTypeEnum.Checkbox;
  getOffLabel: (t: TFunction) => string;
  getOnLabel: (t: TFunction) => string;
  defaultChecked: boolean;
}

export type FilterType = SelectFilter | CheckboxFilter;

export interface VariantsFilterResult extends FilterOptionIds {
  optionType?: FilterOptionType;
  isPopulated: boolean;
}
