angular.module('directives', [
  'directives.disabledHint',
  'directives.branchDisabledHint',
  'directives.modifiedHint',
  'directives.autofocus',
  'directives.branchSelector',
  'directives.checkbox',
  'directives.confirmDialog',
  'directives.fileDownload',
  'directives.loadSpinner',
  'directives.ngEnter',
  'directives.noDirty',
  'directives.smartDecimal',
  'directives.tabviews',
  'directives.templateTabViews',
  'directives.taskItem',
  'directives.uploadInstance',
  'directives.uploadsMenu',
  'directives.excelExportMenu',
  'directives.validationErrors',
  'directives.garp.articleText',
  'directives.contextMenu',
  'directives.texasErrors',
  'directives.taskForm',
  'directives.entityBadge',
  'directives.breadcrumbs',
  'directives.assignedUserChip',
  'directives.assignedUserConfirmation',
  'directives.assignedUserReminder',
  'directives.userAvatar',
  'directives.focusIf',
  'directives.fixFocus',
  'directives.saveMenu',
  'directives.disableWhileEditing',
  'directives.clearSearch',
  'directives.tierPrice',
  'directives.tierPriceList',
  'directives.supplierSelector',
  'directives.customerCurrencies',
  'directives.articleFilter',
]);
