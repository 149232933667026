import { Stack, SlideFade, Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { VerifyButton } from '../shared/verifyButton/VerifyButton';

export interface FilterContainerProps {
  onRemove?: () => void;
  gridColumn: string;
  children: React.ReactNode;
  static: boolean;
  utilityBar?: React.ReactNode;
}

export const FilterContainerElement = (props: FilterContainerProps) => {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      width="100%"
      height="fit-content"
      mt="auto"
      gridColumn={props.gridColumn}
      role="group"
    >
      {(props.onRemove || props.utilityBar) && (
        <HStack
          _groupHover={{ opacity: 1 }}
          opacity={0}
          transition="opacity 200ms ease"
          paddingBottom={2}
        >
          {props.onRemove && !props.static && (
            <VerifyButton
              buttonProps={{
                fontSize: '12px',
                variant: 'link',
                size: 'sm',
                padding: '0px 4px',
              }}
              onVerified={props.onRemove}
              label={`${t('general.remove')} ${t(
                'general.filter',
              ).toLowerCase()}`}
            />
          )}

          {props.utilityBar}
        </HStack>
      )}

      <SlideFade in={true}>
        <Stack
          border="1px solid"
          borderColor="gray.500"
          _hover={{ borderColor: 'gray.400' }}
          _focusWithin={{
            borderColor: 'gray.400',
          }}
          _light={{
            borderColor: 'blackAlpha.300',
            _hover: { borderColor: 'blackAlpha.500' },
            _focusWithin: {
              borderColor: 'blackAlpha.500',
            },
          }}
          borderRadius="md"
        >
          {props.children}
        </Stack>
      </SlideFade>
    </Box>
  );
};
