import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = config.definePartsStyle({
  control: {
    borderColor: 'texas.sand.100',
    _checked: {
      background: 'texas.sand.100',
      borderColor: 'texas.sand.100',
      _hover: {
        background: 'texas.sand.100',
        borderColor: 'texas.sand.100',
      },
    },
    _focusVisible: { boxShadow: '0 0 0 3px rgba(198, 163, 124, 0.6)' },
    _indeterminate: {
      background: 'texas.sand.50',
      borderColor: 'texas.sand.50',
    },
    _light: {
      borderColor: 'texas.burntSienna.500',
      _checked: {
        background: 'texas.burntSienna.500',
        _hover: {
          background: 'texas.burntSienna.500',
          borderColor: 'texas.burntSienna.500',
        },
      },
      _focusVisible: { boxShadow: '0 0 0 3px rgba(198, 80, 44, 0.6)' },
      _indeterminate: {
        background: 'texas.burntSienna.100',
        borderColor: 'texas.burntSienna.100',
      },
    },
  },
  icon: {
    color: 'white',
    backgroundColor: 'texas.sand.100',
    borderColor: 'texas.sand.100',
    _light: {
      color: 'white',
      backgroundColor: 'texas.burntSienna.500',
      borderColor: 'texas.burntSienna.500',
    },
  },
});

export default config.defineMultiStyleConfig({ baseStyle });
