// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('tasks.declined', {
    url: '/declined',
    templateUrl: 'main/tasks/declined/declined.tpl.html',
    controller: 'DeclinedTasksController as vm',
    data: { pageTitle: 'Tasks' },
  });
};

class DeclinedTasksController {
  constructor(
    $scope,
    DS,
    toasterService,
    loadSpinnerService,
    TasksApiService,
    currentUser,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.DS = DS;
    this.toasterService = toasterService;
    this.loadSpinnerService = loadSpinnerService;
    this.TasksApiService = TasksApiService;
    this.currentUser = currentUser;

    this.selectedTask = {};
    this.tasks = [];
    this.tasksCount = 0;
    this.page = 1;
    this.pageSizes = [10, 15, 50, 100];
    this.pageSize = 50;
    this.onPaginate = (page, pageSize) => {
      this.page = page;
      this.pageSize = pageSize;
      this.selectTask(null);
      this.loadTasks();
    };

    this.loadTasks();
  }

  loadTasks() {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.getAllByUserId(this.currentUser.id, {
      declined: true,
      page: this.page,
      limit: this.pageSize,
    })
      .then(({ items, totalItems }) => {
        this.tasks = items;
        this.tasksCount = totalItems;
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  selectTask(task) {
    if (!task) {
      this.selectedTask = {};
      return;
    }
    if (task.id === this.selectedTask.id) {
      return;
    }

    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.getById(task.id, {
      includeRelations: ['creator', '_taskUsers.user'],
    })
      .then((task) => {
        this.selectedTask = task;
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  deleteTask(task) {
    _.remove(this.tasks, { id: task.id });
    this.selectTask(null);
  }

  toggleDoneStatus(task) {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.updateTask(task.id, { isDone: task.isDone })
      .then(() => {
        this.toasterService.success();
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }
}

angular
  .module('main.tasks.declined', [])
  .config(config)
  .controller('DeclinedTasksController', DeclinedTasksController);
