import { Box } from '@chakra-ui/react';

export function TexasDrawerContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box overflowX="auto" flexGrow={1}>
      {children}
    </Box>
  );
}
