// @ngInject
const ArticleVariantDialogService = ($mdDialog, ArticlesApiService) => ({
  showDialog(data) {
    data = data || {};
    data.articleId = data.articleId || null;
    data.articleVariants = data.articleVariants || [];

    return $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ArticleVariantEditDialogController as vm',
      templateUrl: 'services/dialogs/variantDialog/variantDialog.tpl.html',
      locals: { articleId: data.articleId },
      resolve: {
        articleVariants: () =>
          ArticlesApiService.getVariantsByArticleId(data.articleId),
      },
    });
  },
});

class ArticleVariantEditDialogController {
  constructor(
    $filter,
    $mdDialog,
    ArticlesApiService,
    toasterService,
    articleId,
    articleVariants,
  ) {
    'ngInject';

    this.validationErrors = [];
    this.variant = {};
    this.articleVariants = _.filter(articleVariants, { archived: false });
    this._$filter = $filter;
    this._$mdDialog = $mdDialog;
    this._ArticlesApiService = ArticlesApiService;
    this._articleId = articleId;
    this._articleVariants = articleVariants;
    this._toasterService = toasterService;
    this.copyArtwork = true;
    this.copyPurchasePrices = true;
    this.copySalesPrices = false;
  }

  filterVariants(query) {
    return _.orderBy(
      this._$filter('filter')(this._articleVariants, { name: query }),
      'name',
    );
  }

  setBasedOnArticleVariant(variantId) {
    this.variant.BasedOnArticleVariantId = variantId;
  }

  save() {
    this.validationErrors = [];

    if (this.variant.BasedOnArticleVariantId) {
      this.variant.copyArtwork = this.copyArtwork;
      this.variant.copyPurchasePrices = this.copyPurchasePrices;
      this.variant.copySalesPrices = this.copySalesPrices;
    }

    this._$mdDialog.startLoadingSpinner();
    this._ArticlesApiService
      .newVariant(this.variant, this._articleId)
      .then(
        (newVariant) => {
          this._toasterService.success();
          this._$mdDialog.hide(newVariant);
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.variantDialog', [])
  .service('ArticleVariantDialogService', ArticleVariantDialogService)
  .controller(
    'ArticleVariantEditDialogController',
    ArticleVariantEditDialogController,
  );
