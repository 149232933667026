import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Badge,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { Icons } from '@texas/components/shared/Icons';
import { DimensionGroupListItem } from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';

export const dimensionGroupTableColumns = (
  t: TFunction,
  onArchiveOpen: (dimensionGroup: DimensionGroupListItem) => void,
  onRestore: (id: number) => Promise<void>,
  onEditOpen: (dimensionGroupId: number) => void,
) => {
  const columnHelper = createColumnHelper<DimensionGroupListItem>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Flex direction="column">
          <Box>{info.getValue()}</Box>
          <Box color="gray.200" whiteSpace="normal">
            {info.row.original.description}
          </Box>
        </Flex>
      ),
      header: t('general.name'),
    }),
    columnHelper.accessor('dimensions', {
      cell: (info) => (
        <Flex gap={2} flexWrap="wrap">
          {info.getValue().map((v) => (
            <Badge
              key={v}
              color="texas.sand.50"
              _light={{
                backgroundColor: 'blackAlpha.100',
                color: 'texas.burntSienna.500',
              }}
            >
              {v}
            </Badge>
          ))}
        </Flex>
      ),
      header: t('configuration.dimensions'),
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <>
                <Tooltip
                  label={formatDate(Locale.En, info.row.original.archived)}
                >
                  <Icons.Archive boxSize={4} />
                </Tooltip>
              </>
            )}
            <Menu placement="left" variant="table">
              <MenuButton float="right" aria-label={t('general.more')}>
                <Icons.DotsHorizontal boxSize={6} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<Icons.Pencil boxSize={5} />}
                  onClick={() => onEditOpen(info.row.original.id)}
                >
                  {t('general.edit')}
                </MenuItem>
                {info.row.original.archived ? (
                  <MenuItem
                    color="texas.sand.100"
                    icon={<Icons.ArchiveRemove boxSize={5} />}
                    onClick={() => onRestore(info.row.original.id)}
                  >
                    {t('general.restore')}
                  </MenuItem>
                ) : (
                  <MenuItem
                    color="texas.sand.50"
                    icon={<Icons.Archive boxSize={5} />}
                    onClick={() => onArchiveOpen(info.row.original)}
                  >
                    {t('general.archive')}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
