// @ngInject
const templateTabs = () => ({
  restrict: 'EA',
  scope: false,
  replace: true,
  transclude: true,
  controller: 'TemplateTabController',

  template:
    '<div>' +
    '<section class="tab-nav" ng-transclude></section>' +
    '<section ng-include="templateUrl"></section>' +
    '</div>',
});

class TemplateTabController {
  constructor($scope) {
    'ngInject';
    const tabs = ($scope.tabs = []);

    $scope.templateUrl = '';
    this._$scope = $scope;
    this._tabs = tabs;
  }

  selectTab(tab) {
    _.forEach(this._tabs, (tab) => {
      tab.selected = false;
    });
    tab.selected = true;
  }

  setTabTemplate(templateUrl) {
    this._$scope.templateUrl = templateUrl;
  }

  addTab(tab) {
    if (this._tabs.length === 0 || tab.preSelected) {
      this.selectTab(tab);
    }
    this._tabs.push(tab);
  }
}

const templateTab = () => ({
  restrict: 'EA',
  replace: true,
  require: '^templateTabs',

  scope: {
    tabTitle: '@',
    templateUrl: '@',
    preSelected: '@',
  },

  link: function linkFn(scope, element, attrs, tabsetController) {
    tabsetController.addTab(scope);

    scope.select = () => {
      tabsetController.selectTab(scope);
    };

    scope.$watch('selected', () => {
      if (scope.selected) {
        tabsetController.setTabTemplate(scope.templateUrl);
      }
    });
  },

  template:
    '<a href="" ng-class="{active: selected}" ng-click="select()" ng-bind-html="tabTitle"></a>',
});

angular
  .module('directives.templateTabViews', [])
  .directive('templateTabs', templateTabs)
  .controller('TemplateTabController', TemplateTabController)
  .directive('templateTab', templateTab);
