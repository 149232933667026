window.jQuery = window.$ = require('jquery');
window.moment = require('moment/moment');
require('angular/angular');
require('angular-animate/angular-animate');
require('angular-aria/angular-aria');
require('angular-cookies/angular-cookies');
require('angular-sanitize/angular-sanitize');
require('js-data/dist/js-data');
require('js-data-angular/dist/js-data-angular');
require('angular-ui-router/release/angular-ui-router');
require('lodash/lodash');
require('autofill-directive/autofill-directive');
require('angular-elastic/elastic');
require('blueimp-canvas-to-blob/js/canvas-to-blob');
require('raygun4js/dist/raygun');
require('angular-toastr/dist/angular-toastr.tpls');
require('angular-ui-grid/ui-grid');
require('angular-messages/angular-messages');
require('angular-material/modules/js/core/core');
require('angular-material/modules/js/checkbox/checkbox');
require('angular-material/modules/js/virtualRepeat/virtualRepeat');
require('angular-material/modules/js/progressCircular/progressCircular');
require('angular-material/modules/js/progressLinear/progressLinear');
require('angular-material/modules/js/tooltip/tooltip');
require('angular-material/modules/js/button/button');
require('angular-material/modules/js/menu/menu');
require('angular-material/modules/js/backdrop/backdrop');
require('angular-material/modules/js/input/input');
require('angular-material/modules/js/select/select');
require('angular-material/modules/js/chips/chips');
require('angular-material/modules/js/autocomplete/autocomplete');
require('angular-material/modules/js/icon/icon');
require('angular-material/modules/js/dialog/dialog');
require('angular-material/modules/js/content/content');
require('angular-material/modules/js/toolbar/toolbar');
require('angular-material/modules/js/sidenav/sidenav');
require('angular-material/modules/js/radioButton/radioButton');
require('angular-material/modules/js/datepicker/datepicker');
require('angular-material-data-table/dist/md-data-table');
require('ng-scroll-repeat/dist/ng-scroll-repeat');

import './angular/scss/core.scss';
import { renderReactRoot } from './react/Root';

angular.module('constant', []).constant('ENV', {
  version: process.env.APP_VERSION,
  name: process.env.APP_ENVIRONMENT,
  apiEndpoint: process.env.APP_API_ENDPOINT,
  raygunApiKey: process.env.APP_RAYGUN_API_KEY,
});

// Dynamically require so Webpack finds them all.
const components = require.context('.', true, /^.*\.js$/);
components.keys().forEach(components);

// Populate the AngularJS $templateCache
angular.module('app').run([
  '$templateCache',
  ($templateCache) => {
    const templates = require.context('.', true, /^.*\.tpl\.html/);
    templates.keys().forEach((key) => {
      let templatePath = key.slice(2);
      if (templatePath.startsWith('angular/common/')) {
        templatePath = templatePath.slice('angular/common/'.length);
      } else if (templatePath.startsWith('angular/')) {
        templatePath = templatePath.slice('angular/'.length);
      }
      return $templateCache.put(templatePath, templates(key));
    });
  },
]);

renderReactRoot(document.getElementById('react-root'));
