import {
  Button,
  Heading,
  HStack,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBranchModal } from './CreateBranchModal';
import { branchesApi, Branch } from '@texas/api/endpoints/branchesApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { reactEvents } from 'src/bridge/reactEvents';
import { Icons } from '@texas/components/shared/Icons';

export function AvailableBranches() {
  const { t } = useTranslation();
  const {
    data: availableBranches,
    refetch: refetchAvailableBranches,
    set: setAvailableBranches,
    loading,
  } = useApiResource(branchesApi.getAvailableBranchesForCreation);

  useEffect(() => {
    refetchAvailableBranches();
  }, [refetchAvailableBranches]);

  const {
    value: branchValue,
    isOpen,
    onOpen,
    onClose,
  } = useValueDisclosure<string>();

  return (
    <>
      {branchValue && (
        <CreateBranchModal
          isOpen={isOpen}
          onClose={onClose}
          onBranchCreated={function (branch: Branch): void {
            onClose();
            reactEvents.branchCreated.dispatch(branch);
            const set =
              availableBranches?.filter((b) => b !== branch.identifier) ?? [];
            setAvailableBranches(set);
          }}
          identifier={branchValue}
        />
      )}

      <Skeleton isLoaded={!loading}>
        <HStack>
          {availableBranches && (
            <>
              {availableBranches.length === 0 ? (
                <HStack color="gray.400">
                  <Icons.InformationOutline />
                  <Text fontSize={12} textTransform="uppercase">
                    {t('branch.noNewBranchesAvailable')}
                  </Text>
                </HStack>
              ) : (
                <VStack
                  w="100%"
                  alignItems="start"
                  backgroundColor="gray.50"
                  borderRadius={6}
                  padding={2}
                >
                  <Heading size="sm" color="texas.bg.800">
                    {t('branch.newBranchesAvailable')}
                  </Heading>
                  <Text fontSize={12} color="gray.400">
                    {t('branch.clickToCreate')}
                  </Text>
                  <HStack>
                    {availableBranches.map((b) => (
                      <Button
                        size="sm"
                        variant="texas"
                        key={b}
                        onClick={() => onOpen(b)}
                      >
                        {b}
                      </Button>
                    ))}
                  </HStack>
                </VStack>
              )}
            </>
          )}
        </HStack>
      </Skeleton>
    </>
  );
}
