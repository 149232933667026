// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.vatCodes', {
    url: '/vatcodes',
    controller: 'VatCodesConfigurationController as vm',
    templateUrl: 'main/configuration/vatCodes/vatCodes.tpl.html',
    data: { pageTitle: 'VAT codes' },
  });
};

class VatCodesConfigurationController {
  constructor(
    $scope,
    DS,
    configurationOptions,
    loadSpinnerService,
    VatCodesApiService,
    OrganizationsApiService,
    ConfigItemDialogService,
    currentOrganization,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.DS = DS;
    this.configurationOptions = configurationOptions;
    this.loadSpinnerService = loadSpinnerService;
    this.VatCodesApiService = VatCodesApiService;
    this.OrganizationsApiService = OrganizationsApiService;
    this.ConfigItemDialogService = ConfigItemDialogService;
    this.currentOrganization = currentOrganization;

    this.selectedBranch = {};
    this.branches = currentOrganization.branches;
    this.vatCodes = [];
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    this.selectBranch(this.configurationOptions.selectedBranch);
    this.setWatchers();
  }

  selectBranch(branch) {
    this.configurationOptions.selectedBranch = branch || this.branches[0];
    this.selectedBranch = branch || this.branches[0];
    this.loadVatCodes();
  }

  loadVatCodes() {
    this.loadSpinnerService.start('mainSpinner');
    this.VatCodesApiService.getAllByBranchId(this.selectedBranch.id)
      .then((vatCodes) => {
        this.vatCodes = vatCodes;
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  openVatCode(vatCode) {
    this.ConfigItemDialogService.showDialog({
      configItem: vatCode,
      displayOrder: true,
      identifier: true,
      value: true,
      onSave: (vatCode) => {
        return this.VatCodesApiService.updateVatCode(vatCode, vatCode.id);
      },
    });
  }

  newVatCode() {
    this.ConfigItemDialogService.showDialog({
      displayOrder: true,
      identifier: true,
      value: true,
      onSave: (vatCode) => {
        return this.VatCodesApiService.newVatCode(
          vatCode,
          this.selectedBranch.id,
        );
      },
    });
  }

  setWatchers() {
    this.$scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    this.$scope.$watch(
      () => {
        return this.DS.lastModified('vatCode');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.vatCodes = this.DS.filter('vatCode', {
            where: {
              branchId: {
                '==': this.selectedBranch.id,
              },
            },
          });
        }
      },
    );
  }
}

angular
  .module('configuration.vatCodes', [])
  .config(config)
  .controller(
    'VatCodesConfigurationController',
    VatCodesConfigurationController,
  );
