// @ngInject
const uploadArea = (UploaderService) => ({
  restrict: 'EA',
  scope: false,
  require: '^uploadInstance',

  link: function linkFn(scope, element, attrs, uploadInstanceCtrl) {
    const instance = uploadInstanceCtrl.instance;
    const overlay = angular.element("<div class='file-drop-overlay'></div>");

    if (attrs.overlayClass) {
      overlay.addClass(attrs.overlayClass);
    }

    element.append(overlay);

    element.on('dragover', (event) => {
      event.preventDefault();
      overlay.toggleClass('dropping', true);
    });

    element.on('dragenter', (event) => {
      event.preventDefault();
    });

    element.on('dragleave drop', (event) => {
      event.preventDefault();
      overlay.toggleClass('dropping', false);
    });

    element.on('drop', (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (!instance.disabled) {
        event = event.originalEvent || event;
        const dataTransfer = event.dataTransfer;
        UploaderService.addFiles(dataTransfer.files, {
          instance: instance.name,
        });
      }
    });

    scope.$watch(
      () => {
        return instance.disabled;
      },
      (value) => {
        if (value) {
          overlay.toggleClass('file-drop-overlay-disabled', true);
        } else {
          overlay.toggleClass('file-drop-overlay-disabled', false);
        }
      },
    );
  },
});

angular.module('uploadArea', []).directive('uploadArea', uploadArea);
