// @ngInject
const smartDecimal = (HelperService) => ({
  require: 'ngModel',

  link: function linkFn(scope, elm, attrs, ctrl) {
    ctrl.$options = { allowInvalid: true, updateOnDefault: true };
    const parseDecimal = (viewValue) => {
      if (_.isString(viewValue) && viewValue.startsWith('.')) {
        viewValue = parseFloat(viewValue.padStart(viewValue.length + 1, '0'));
      }
      if (_.isString(viewValue) && HelperService.isValidDecimal(viewValue)) {
        return parseFloat(viewValue.replace(',', '.'));
      } else {
        return viewValue;
      }
    };

    ctrl.$parsers.unshift(parseDecimal);

    ctrl.$validators.decimal = (modelValue, viewValue) =>
      HelperService.isValidDecimal(viewValue) || _.isEmpty(viewValue);

    elm.on('blur', () => {
      ctrl.$setViewValue(setDefaultValueIfEmpty(parseDecimal(ctrl.$viewValue)));
      ctrl.$render();
    });

    function setDefaultValueIfEmpty(value) {
      if (value) {
        return value.toString();
      } else if (!value && value !== 0) {
        const defaultValue = parseFloat(attrs.smartDecimal);
        return _.isInteger(defaultValue) ? attrs.smartDecimal : null;
      }
      return value;
    }
  },
});

angular
  .module('directives.smartDecimal', [])
  .directive('smartDecimal', smartDecimal);
