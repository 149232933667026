// @ngInject
const ProductGroupDialogService = ($mdDialog) => ({
  showDialog: (options = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ProductGroupDialogController as vm',
      templateUrl:
        'services/dialogs/productGroupDialog/productGroupDialog.tpl.html',
      locals: Object.assign(
        {
          productGroup: null,
          displayOrder: false,
          checkedByDefault: false,
          archivable: true,
          allowedToEdit: true,
        },
        options,
      ),
    }),
});

class ProductGroupDialogController {
  constructor(
    $mdDialog,
    toasterService,
    productGroup,
    onSave,
    displayOrder,
    checkedByDefault,
    archivable,
    allowedToEdit,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.toasterService = toasterService;

    this.validationErrors = [];
    this.onSave = onSave;
    this.displayOrder = displayOrder;
    this.checkedByDefault = checkedByDefault;
    this.archivable = archivable;
    this.allowedToEdit = allowedToEdit;
    this.editMode = productGroup !== null;

    if (!this.editMode) {
      productGroup = {};
    }
    this.productGroup = _.extend({}, productGroup);
  }

  save() {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();
    this.onSave(this.productGroup)
      .then(
        () => {
          this.toasterService.success();
          this.$mdDialog.hide();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.$mdDialog.stopLoadingSpinner();
      });
  }

  archive() {
    this.productGroup.archived = !this.productGroup.archived;
    this.save();
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.productGroupDialog', [])
  .service('ProductGroupDialogService', ProductGroupDialogService)
  .controller('ProductGroupDialogController', ProductGroupDialogController);
