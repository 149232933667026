import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  SimpleGrid,
  FormControl,
  Input,
  Box,
  Flex,
  Badge,
  useColorMode,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getWidgetConfigs, WidgetConfig } from '../../widgets';
import { useRef, useState } from 'react';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { Widget } from '../../useWidgetLayout';
import { SelectColor } from './SelectColor';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import {
  defaultWidgetColor,
  defaultWidgetLightColor,
  WidgetType,
} from '../types';

interface WidgetData {
  name: string;
  type: WidgetType | null;
  color: string | null;
}

export function AddWidget({
  onAdd,
  currentWidgets,
}: {
  onAdd: (type: WidgetType, name: string, color: string | null) => void;
  currentWidgets: Widget[];
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const configs = getWidgetConfigs(t);
  const [data, setData] = useState<WidgetData>({
    name: '',
    type: null,
    color: null,
  });
  const ref = useRef<HTMLInputElement>(null);

  function addWidget(type: WidgetType, name: string, color: string | null) {
    const widget = configs[type];
    onClose();
    onAdd(widget.type, name, color);
    setData({
      name: '',
      type: null,
      color: null,
    });
  }

  return (
    <>
      <Button w="fit-content" variant="texas-light" onClick={onOpen}>
        {t('widgets.new')}
      </Button>

      <Modal
        size="2xl"
        isCentered={true}
        isOpen={isOpen}
        onClose={() => {
          setData({
            name: '',
            type: null,
            color: null,
          });
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('widgets.add')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex gap={4} direction="column">
              <TexasFormLabel pb={1}>
                {t('widgets.selectWidgetType')}
              </TexasFormLabel>
              <SimpleGrid columns={3} spacing={2}>
                {Object.values(configs).map((x) => {
                  return (
                    <WidgetTemplate
                      current={
                        currentWidgets.filter((c) => c.type === x.type).length
                      }
                      color={data.color}
                      selected={data.type === x.type}
                      key={x.type}
                      config={x}
                      onSelect={() => {
                        setData((d) => ({ ...d, type: x.type }));
                        if (!ref.current) return;
                        ref.current.focus();
                      }}
                    />
                  );
                })}
              </SimpleGrid>
              <Flex gap={4}>
                <TexasInputWrapper label={t('widgets.nameYourWidget')}>
                  <Input
                    ref={ref}
                    placeholder={
                      data.type ? configs[data.type].label : t('widgets.name')
                    }
                    onChange={(e) =>
                      setData((d) => ({ ...d, name: e.target.value }))
                    }
                  />
                </TexasInputWrapper>
                <FormControl>
                  <TexasFormLabel pb={1}>
                    {t('widgets.selectColor')}
                  </TexasFormLabel>
                  <SelectColor
                    selectedColor={data.color}
                    onChange={(hex) => setData((d) => ({ ...d, color: hex }))}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter
            backgroundColor="gray.800"
            _light={{ backgroundColor: 'gray.50' }}
            gap={2}
          >
            <Button onClick={onClose}>{t('general.close')}</Button>
            <Button
              variant="texas-solid"
              isDisabled={!data.type}
              onClick={() => {
                if (!data.type) return;
                addWidget(
                  data.type,
                  data.name.length == 0 ? configs[data.type].label : data.name,
                  data.color,
                );
              }}
            >
              {t('general.add')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function WidgetTemplate({
  config,
  selected,
  onSelect,
  color,
  current,
}: {
  config: WidgetConfig;
  selected: boolean;
  onSelect: () => void;
  color: string | null;
  current: number;
}) {
  const maxReached = config.maximum !== null && current >= config.maximum;
  const { colorMode } = useColorMode();
  return (
    <Button
      onClick={onSelect}
      isDisabled={maxReached}
      h="auto"
      w="auto"
      alignItems="start"
      justifyContent="start"
      textAlign="start"
      whiteSpace="normal"
      borderRadius="base"
      background="texas.bg.700"
      border="1px solid"
      borderColor={selected ? 'white' : 'transparent'}
      _light={{
        background: 'gray.50',
        borderColor: selected ? 'black' : 'transparent',
        _hover: {
          background: 'gray.10',
        },
      }}
      gap={2}
      padding={0}
      overflow="hidden"
    >
      <Box
        bg={
          color ??
          (colorMode === 'dark' ? defaultWidgetColor : defaultWidgetLightColor)
        }
        w={3}
        h="full"
        flexShrink={0}
      />
      <Flex p={4} flexDir="column" gap={2}>
        <Text>{config.label}</Text>
        <Text variant="sub" fontWeight="medium" fontSize="sm">
          {config.description}
        </Text>
        {maxReached && (
          <Badge w="fit-content">Max {config.maximum} widget/s</Badge>
        )}
      </Flex>
    </Button>
  );
}
