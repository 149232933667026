// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration', {
    parent: 'main',
    url: '/configuration',
    views: {
      'main-content': {
        controller: 'MainConfigurationController as vm',
        templateUrl: 'main/configuration/configuration.tpl.html',
      },
    },
    resolve: {
      configurationOptions() {
        return { selectedBranch: null };
      },
    },
    data: { pageTitle: 'Configuration' },
  });
};

class MainConfigurationController {
  constructor($scope, $state, currentOrganization) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;

    this.tabData = [
      {
        heading: 'Article templates',
        route: 'configuration.articleTemplates',
        active: true,
      },
      { heading: 'Article tags', route: 'configuration.articleTags' },
      { heading: 'Branches', route: 'configuration.branches' },
      { heading: 'Countries', route: 'configuration.countries' },
      { heading: 'Currencies', route: 'configuration.currencies' },
      { heading: 'Matrix groups', route: 'configuration.matrixGroups' },
      { heading: 'Matrix nodes', route: 'configuration.matrixNodes' },
      { heading: 'Technical option', route: 'configuration.technicalOptions' },
      { heading: 'Delivery method', route: 'configuration.deliveryMethods' },
      { heading: 'Delivery terms', route: 'configuration.deliveryTerms' },
      { heading: 'Payment terms', route: 'configuration.paymentTerms' },
      { heading: 'Freight costs', route: 'configuration.freightCosts' },
      { heading: 'Handling fees', route: 'configuration.handlingFees' },
      { heading: 'Materials', route: 'configuration.materials' },
      { heading: 'Product Groups', route: 'configuration.productGroups' },
      { heading: 'Techniques', route: 'configuration.techniques' },
      { heading: 'VAT codes', route: 'configuration.vatCodes' },
      { heading: 'Treatments', route: 'configuration.treatments' },
      { heading: 'Dimension groups', route: 'configuration.dimensionGroups' },
      { heading: 'Qualities', route: 'configuration.qualities' },
      {
        heading: currentOrganization.name,
        route: 'configuration.organization',
      },
      { heading: 'Garp', route: 'configuration.garp' },
    ];

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('configuration')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.tabData, { active: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module('main.configuration', [
    'configuration.articleTemplates',
    'configuration.articleTags',
    'configuration.branches',
    'configuration.countries',
    'configuration.matrixGroups',
    'configuration.matrixNodes',
    'configuration.currencies',
    'configuration.garp',
    'configuration.technicalOptions',
    'configuration.deliveryMethods',
    'configuration.deliveryTerms',
    'configuration.paymentTerms',
    'configuration.freightCosts',
    'configuration.handlingFees',
    'configuration.materials',
    'configuration.productGroups',
    'configuration.techniques',
    'configuration.organization',
    'configuration.vatCodes',
    'configuration.treatments',
    'configuration.dimensionGroups',
    'configuration.qualities',
  ])
  .config(config)
  .controller('MainConfigurationController', MainConfigurationController);
