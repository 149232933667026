import { Flex, Button } from '@chakra-ui/react';
import { FileUpload } from '@texas/types';
import { Accept, FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FileUploads } from './FileUploads';
import { DropZone } from './DropZone';

export function FileDropzone({
  accept,
  multiple = false,
  onUpload,
  isUploading,
  uploadFailed,
  fileUploads,
  abortFileUpload,
  onFileBrowserOpen,
  validator,
}: {
  accept?: Accept;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  uploadFailed: boolean;
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
  onFileBrowserOpen: () => void;
  validator?: <T extends File>(
    file: T,
  ) => FileError | readonly FileError[] | null;
}) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={2} flexGrow={1} alignItems="center">
      {(isUploading || uploadFailed) && (
        <FileUploads
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
        />
      )}
      <DropZone
        accept={accept}
        multiple={multiple}
        onUpload={onUpload}
        validator={validator}
      />
      <Button
        variant="texas-link-light"
        textDecoration="underline"
        onClick={onFileBrowserOpen}
        isLoading={isUploading}
      >
        {t('fileBrowser.selectFromLibrary')}
      </Button>
    </Flex>
  );
}
