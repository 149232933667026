import { useActiveSession } from '@texas/hooks/useSession';
import { BetaFeature, hasAccessToFeature } from './features';

export function FeatureGuard({
  feature,
  children,
}: {
  feature: BetaFeature;
  children: React.ReactNode;
}) {
  const session = useActiveSession();

  if (!hasAccessToFeature(session.currentUser.id, feature)) {
    return null;
  }

  return <>{children}</>;
}
