// @ngInject
const CurrenciesApiService = (DS, $q, ResourcesService, DSHttpAdapter) => ({
  getAllByBranchId(branchId) {
    const deferred = $q.defer();

    DS.findAll('currency', null, {
      bypassCache: true,
      params: { branchId, organizationId: false },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAll() {
    return DSHttpAdapter.GET(`${DS.defaults.basePath}currencies/all`).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    );
  },
  getAllFromCache(branchId) {
    return DS.filter('currency', { branchId });
  },
  newCurrency(currency, branchId) {
    const deferred = $q.defer();

    DS.create('currency', currency, {
      params: { branchId, organizationId: false },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  updateCurrency(data, currencyId) {
    const deferred = $q.defer();

    DS.update('currency', currencyId, data, {
      cacheResponse: false,
      params: { branchId: false },
    }).then(
      (response) => {
        const currency = DS.get('currency', currencyId);
        currency.name = response.name;
        currency.code = response.code;
        currency.buyingRate = response.buyingRate;
        currency.sellingRate = response.sellingRate;
        currency.archived = response.archived;
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
});

angular
  .module('resources.currencies', [])
  .factory('CurrenciesApiService', CurrenciesApiService);
