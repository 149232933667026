import { Button, Flex, Text } from '@chakra-ui/react';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { useTranslation } from 'react-i18next';

export function SupplierCell({
  supplier,
  onDisable,
  onEnable,
}: {
  supplier: VariantBranchSupplier;
  onDisable: (supplierId: number, branchId: number) => void;
  onEnable: (supplierId: number, branchId: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex direction="column" grow={1} py={2} align="start">
      <Text
        variant={supplier.disabled ? 'sub' : undefined}
        textDecoration={supplier.disabled ? 'line-through' : undefined}
        px={4}
      >
        {supplier.supplierName}
      </Text>
      {supplier.disabled ? (
        <Button
          mt="auto"
          ml={2}
          size="xs"
          variant="texas-link"
          color="whiteAlpha.600"
          _light={{ color: 'blackAlpha.800' }}
          textDecoration="underline"
          visibility="hidden"
          _groupHover={{ visibility: 'visible' }}
          onClick={() => onEnable(supplier.supplierId, supplier.branchId)}
        >
          {t('general.enable')}
        </Button>
      ) : (
        <Button
          mt="auto"
          ml={2}
          size="xs"
          variant="texas-link-danger"
          visibility="hidden"
          _groupHover={{ visibility: 'visible' }}
          onClick={() => onDisable(supplier.supplierId, supplier.branchId)}
        >
          {t('general.disable')}
        </Button>
      )}
    </Flex>
  );
}
