// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.materials', {
    url: '/materials',
    controller: 'MaterialsConfigurationController as vm',
    templateUrl: 'main/configuration/materials/materials.tpl.html',
    data: { pageTitle: 'Materials' },
  });
};

class MaterialsConfigurationController {
  constructor() {}
}

angular
  .module('configuration.materials', [])
  .config(config)
  .controller(
    'MaterialsConfigurationController',
    MaterialsConfigurationController,
  );
