import { Box, Flex } from '@chakra-ui/react';
import { ReactSelectOptionWithIcon } from '@texas/types';
import { GroupBase, components, SingleValueProps } from 'react-select';

export function IconSingleValueComponent<IsMulti extends boolean>(
  props: SingleValueProps<
    ReactSelectOptionWithIcon,
    IsMulti,
    GroupBase<ReactSelectOptionWithIcon>
  >,
) {
  return (
    <components.SingleValue {...props}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>{props.data.label}</Box>
        {props.data.icon}
      </Flex>
    </components.SingleValue>
  );
}
