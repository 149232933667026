const tierPriceList = () => ({
  templateUrl: 'directives/tierPriceList/tierPriceList.tpl.html',
  restrict: 'E',
  scope: {},
  bindToController: {
    type: '@',
    organizationConfig: '=',
    matrixLabel: '@',
    tiers: '=',
    variant: '=',
    branches: '=',
    purchaseInfos: '=',
    supplierId: '=',
    nodeXValues: '=',
    nodeYValues: '=',
    currentPurchaseCurrency: '<purchaseCurrency',
    salesCurrency: '=',
    frozenPrices: '=',
    currentPurchaseExchangeRate: '<purchaseExchangeRate',
    freightCost: '=',
    handlingFee: '=',
    selectedBranch: '=',
    selectedVariant: '=',
    selectedPurchaseInfo: '=',
    filterCurrencies: '&',
    onAddTierPrice: '&',
    onUpdateTierPrice: '&',
    onDeleteTierPrice: '&',
    onCopyPrices: '&',
    editable: '=',
  },
  controller: 'TierPriceListController',
  controllerAs: 'vm',
});

class TierPriceListController {
  constructor(
    $q,
    $scope,
    toasterService,
    ArticlesApiService,
    loadSpinnerService,
    HelperService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.toasterService = toasterService;
    this.ArticlesApiService = ArticlesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.HelperService = HelperService;

    this.newTierPrice = {};
    this.tierPrices = [];
    this.validationErrors = [];
    this.updateTierPriceList();
    this.setupWatchers();
    this.includeAdditionalCosts =
      this.type === 'purchase' ? true : this.salesCurrency.syncWithoutFees;
    this.currencyRate = this.type === 'purchase' ? 'buyingRate' : 'sellingRate';
    this.displayCurrency =
      this.type === 'purchase'
        ? this.currentPurchaseCurrency
        : this.salesCurrency.currency;
    this.hasMatrix = this.nodeXValues.length && this.nodeYValues.length;
    this.expandAll = false;
    if (this.type === 'purchase') {
      this.setFilteredPurchaseInformations();
    }
  }

  setFilteredPurchaseInformations() {
    this.filteredPurchaseInfos = _.chain(this.purchaseInfos)
      .filter((i) => i.branchId !== this.selectedBranch.id && i.isMain)
      .map((i) => ({
        id: i.id,
        name: _.find(this.branches, { id: i.branchId }).name,
      }))
      .value();
  }

  updateTierPriceList() {
    const frozenPrice = _.find(
      this.frozenPrices,
      (price) =>
        _.some(this.tiers, { id: price.tierPriceId }) &&
        !price.matrixNodeValueXId &&
        !price.matrixNodeValueYId,
    );
    this.purchaseCurrencyCode = frozenPrice
      ? frozenPrice.currencyCode
      : this.currentPurchaseCurrency.code;
    this.purchaseExchangeRate = frozenPrice
      ? frozenPrice.fixedExchangeRate
      : this.currentPurchaseExchangeRate;
    const noFrozenPrices = _.isEmpty(
      _.filter(this.frozenPrices, (p) =>
        _.some(this.tiers, { id: p.tierPriceId }),
      ),
    );
    this.tierPrices = _.chain(this.tiers)
      .filter(
        (t) =>
          noFrozenPrices || _.some(this.frozenPrices, { tierPriceId: t.id }),
      )
      .map((t) => {
        const currentTier = _.find(this.tierPrices, { id: t.id });
        const isExpanded = currentTier ? currentTier.$expanded : false;
        const isEditing = currentTier ? currentTier.$editable : false;
        const newClone = _.extend({}, t);
        newClone.$expanded = isExpanded;
        newClone.$editable = isEditing;
        newClone.basePrices = _.map(t.basePrices, _.clone);
        newClone.salesPrices = _.map(t.salesPrices, _.clone);
        return newClone;
      })
      .value();
  }

  getOriginalTierPrice(tierId) {
    return _.find(this.tiers, { id: tierId });
  }

  setDisplayExchangeRate() {
    const selectedCurrency =
      this.type === 'purchase'
        ? this.currentPurchaseCurrency
        : this.salesCurrency.currency;
    if (selectedCurrency && this.displayCurrency) {
      this.displayExchangeRate =
        selectedCurrency.id !== this.displayCurrency.id
          ? this.displayCurrency[this.currencyRate]
          : null;
    }
  }

  addTierPrice() {
    if (!_.isNumber(this.newTierPrice.quantity)) {
      return;
    }
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    const newTierPriceData = {
      branchId: this.selectedBranch.id,
      quantity: this.newTierPrice.quantity,
    };

    if (this.newTierPrice.price) {
      newTierPriceData.basePrices = [
        { supplierId: this.supplierId || 0, price: this.newTierPrice.price },
      ];
    }

    this.ArticlesApiService.newTierPrice(
      newTierPriceData,
      this.selectedVariant.id,
    )
      .then(
        (newTierPrice) => {
          this.newTierPrice = {};
          this.onAddTierPrice({ tierId: newTierPrice.id });
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  updateTierPrice(tierPrice, replaceAll) {
    const promises = [];
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    const tierDfd = this.$q.defer();
    promises.push(tierDfd.promise);
    const fields = ['quantity', 'decimalsInPrice'];

    const originalTierPrice = this.getOriginalTierPrice(tierPrice.id);
    const data = this.HelperService.getChangedData(
      originalTierPrice,
      tierPrice,
      fields,
    );

    const hasChangedBasePrices = this.HelperService.isArrayModified(
      originalTierPrice.basePrices,
      tierPrice.basePrices,
      ['supplierId', 'price'],
    );
    if (hasChangedBasePrices) {
      data.basePrices = tierPrice.basePrices;
    }

    const salesFields = ['price', 'priceWithMargin', 'margin', 'isReady'];
    const hasChangedSalesPrices = this.HelperService.isArrayModified(
      originalTierPrice.salesPrices,
      tierPrice.salesPrices,
      salesFields,
    );
    if (hasChangedSalesPrices) {
      data.salesPrices = _.chain(tierPrice.salesPrices)
        .map((salesPrice) => ({
          current: salesPrice,
          previous:
            _.find(originalTierPrice.salesPrices, {
              salesCurrencyId: salesPrice.salesCurrencyId,
            }) || {},
        }))
        .map((pair) =>
          _.extend(
            { salesCurrencyId: pair.current.salesCurrencyId },
            this.HelperService.getChangedData(
              pair.previous,
              pair.current,
              salesFields,
            ),
          ),
        )
        .filter(
          (changed) =>
            angular.isDefined(changed.price) ||
            angular.isDefined(changed.priceWithMargin) ||
            angular.isDefined(changed.margin) ||
            angular.isDefined(changed.isReady),
        )
        .value();
    }

    this.ArticlesApiService.updateTierPrice(data, tierPrice.id).then(
      (updatedTierPrice) => {
        angular.extend(
          _.find(this.tierPrices, { id: tierPrice.id }),
          updatedTierPrice,
        );
        _.forEach(this.tierPrices, (t) => {
          t.basePrices = _.map(t.basePrices, _.clone);
          t.salesPrices = _.map(t.salesPrices, _.clone);
        });

        if (replaceAll) {
          promises.push(
            this.ArticlesApiService.deletePurchaseCellValues(
              tierPrice.id,
              this.supplierId,
            ),
          );
        }
        tierDfd.resolve();
      },
      (error) => {
        tierDfd.reject(error);
      },
    );
    const promise = this.$q.all(promises);
    promise
      .then(
        () => {
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
    return promise;
  }

  setMainTierPrice(tierPrice) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    return this.ArticlesApiService.updateTierPrice(
      { isMain: true },
      tierPrice.id,
    )
      .then(
        (updatedTierPrice) => {
          tierPrice.isMain = updatedTierPrice.isMain;
          _.forEach(this.tierPrices, (t) => {
            t.isMain = t.id === tierPrice.id;
          });
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  deleteTierPrice(tier) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.deleteTierPrice(tier.id)
      .then(
        () => {
          this.onDeleteTierPrice({ tierId: tier.id });
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  hasValidCurrencies() {
    return (
      _.isNumber(this.purchaseExchangeRate) &&
      this.displayCurrency &&
      _.isNumber(this.displayCurrency[this.currencyRate])
    );
  }

  toggleExpandAll() {
    this.expandAll = !this.expandAll;
    _.forEach(this.tierPrices, (tierPrice) => {
      tierPrice.$expanded = this.expandAll;
    });
  }

  copyPrices({ id: sourcePurchaseInfoId }) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.copyPrices(
      this.selectedPurchaseInfo.id,
      sourcePurchaseInfoId,
    )
      .then(
        () => {
          this.onCopyPrices();
          this.toasterService.success();
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  collapseAll() {
    _.forEach(this.tierPrices, (tierPrice) => {
      tierPrice.$expanded = false;
    });
  }

  setupWatchers() {
    this.$scope.$watch(
      () => {
        return this.type === 'purchase'
          ? this.currentPurchaseCurrency
          : this.salesCurrency.currency;
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.displayCurrency = newValue;
          this.setDisplayExchangeRate();
        }
      },
    );

    this.$scope.$watch(
      () =>
        this.nodeXValues && this.nodeYValues
          ? this.nodeXValues.concat(this.nodeYValues)
          : false,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.nodeValuesModified = Date.now();
        }
      },
      true,
    );

    this.$scope.$watch(
      () => {
        return this.tiers;
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.updateTierPriceList();
        }
      },
    );

    if (this.type === 'purchase') {
      this.$scope.$watch(
        () => this.purchaseInfos,
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            this.setFilteredPurchaseInformations();
          }
        },
      );
    }

    this.$scope.$watch(
      () => this.editable,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.collapseAll();
        }
      },
    );

    this.$scope.$watch(
      () => this.selectedBranch.id,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.setFilteredPurchaseInformations();
        }
      },
    );
  }
}

angular
  .module('directives.tierPriceList', [])
  .controller('TierPriceListController', TierPriceListController)
  .directive('tierPriceList', tierPriceList);
