// @ngInject
const loadSpinnerService = ($rootScope) => ({
  start(name, options = { force: false }) {
    $rootScope.$applyAsync($rootScope.$emit(`${name}-start`, options));
  },

  stop(name, options = { force: false }) {
    $rootScope.$applyAsync($rootScope.$emit(`${name}-stop`, options));
  },
});

angular
  .module('services.loadSpinner', [])
  .service('loadSpinnerService', loadSpinnerService);
