import {
  Tabs,
  TabPanels,
  TabPanel,
  Flex,
  Text,
  useDisclosure,
  Collapse,
  IconButton,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { evaluateSales } from './shared';
import {
  nodeGroupIsPopulated,
  emptyNodeGroup,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesInformation } from '@texas/api/endpoints/salesInformationApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { SalesInformationForm } from './forms/SalesInformationForm';
import { SalesContext } from './Sales';
import { SalesCurrencyComponent } from './SalesCurrencyComponent';
import { Icons } from '@texas/components/shared/Icons';

export interface SalesInformationBranchProps {
  salesInfo: SalesInformation;
  currencies: CustomerCurrency[];
  purchaseInfo?: PurchaseInformation;
}

export function SalesInformationBranch(props: SalesInformationBranchProps) {
  const salesContext = useContext(SalesContext);
  const session = useActiveSession();
  const mainIndex = props.salesInfo.currencies.findIndex((c) => c.isMain);
  const [tabIndex, setTabIndex] = useState(mainIndex !== -1 ? mainIndex : 0);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const [selectedCurrency, setSelectedCurrency] = useState<
    CustomerCurrency | undefined
  >(
    props.currencies.find(
      (c) => c.id === props.salesInfo.currencies.at(mainIndex)?.currencyId,
    ) ?? props.currencies.at(0),
  );

  const branch = session.branches.find(
    (b) => b.id === props.salesInfo.branchId,
  );

  if (!branch) {
    return <>Branch was not found</>;
  }

  const tierPrices =
    salesContext?.tierPrices.data?.filter((t) => t.branchId === branch.id) ??
    [];

  if (!selectedCurrency) {
    return <>No currency selected</>;
  }

  const saleEvaluations = evaluateSales(
    props.salesInfo,
    tierPrices,
    nodeGroupIsPopulated(salesContext?.articleNodeGroup ?? emptyNodeGroup),
    props.purchaseInfo,
  );

  if (!props.purchaseInfo?.supplierId) {
    return null;
  }

  return (
    <Flex
      direction="column"
      w="100%"
      alignItems="start"
      borderRadius={12}
      backgroundColor="texas.bg.900"
      _light={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'gray.200',
      }}
    >
      <Flex justifyContent="space-between" w="100%" p={4}>
        <Text variant="header">{branch.name}</Text>
        <IconButton
          aria-label="Expand"
          icon={isOpen ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
          onClick={onToggle}
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity={true} style={{ width: '100%' }}>
        <Tabs
          index={tabIndex}
          w="100%"
          onChange={(index) => setTabIndex(index)}
        >
          <SalesInformationForm
            salesEvaluations={saleEvaluations}
            mainPurchaseInformation={props.purchaseInfo}
            salesInformation={props.salesInfo}
            branch={branch}
            currencies={props.currencies}
            onCurrentCurrencyChanged={(id) =>
              setSelectedCurrency(props.currencies.find((c) => c.id === id))
            }
            selectedCurrency={selectedCurrency}
          />
          <TabPanels>
            {props.salesInfo.currencies.map((c) => (
              <TabPanel
                p={0}
                key={c.id}
                borderBottomRadius={12}
                backgroundColor="texas.bg.blueTint.100"
                _light={{ backgroundColor: 'white' }}
              >
                <SalesCurrencyComponent
                  key={c.id}
                  salesInformation={props.salesInfo}
                  salesCurrency={c}
                  purchaseInformation={props.purchaseInfo!}
                  currencies={
                    salesContext?.currencies.data?.filter(
                      (c) => c.branchId === props.salesInfo.branchId,
                    ) ?? []
                  }
                  tierPrices={tierPrices}
                  selectedCurrency={selectedCurrency}
                  sellingExchangeRate={
                    c.currencyCode === selectedCurrency.code
                      ? c.fixedExchangeRate
                      : selectedCurrency.sellingRate
                  }
                  onRemoved={() => setTabIndex(0)}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Collapse>
    </Flex>
  );
}
