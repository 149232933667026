import {
  DeepPartial,
  FieldValues,
  Path,
  Resolver,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { BoxProps } from '@chakra-ui/react';
import {
  AutoUpdateRequest,
  AutoUpdateResponse,
  AutoUpdateRequestWithData,
  AutoUpdateFieldType,
} from '@texas/api/endpoints/autoUpdateTypes';
import { HttpClientResponse } from '@texas/api/httpClient/types';

export interface AutoUpdateResponseCallback<TDto, TRequest> {
  unmodifiedServerData: TDto;
  mappedServerData: TRequest;
}

interface AutoUpdateFormBaseProps<TRequest extends FieldValues, TDto> {
  valueId: number;
  namePrefix: string;
  defaultValue?: DeepPartial<TRequest>;
  fallbackMapObject?: TRequest;
  onResponse?: (data: AutoUpdateResponseCallback<TDto, TRequest>) => void;
  resolver?: Resolver<TRequest, any>;
}

export interface UseAutoUpdateFormProps<TRequest extends FieldValues, TDto>
  extends AutoUpdateFormBaseProps<TRequest, TDto> {
  autoUpdateRequest: (
    id: number,
    request: AutoUpdateRequest<TRequest>,
  ) => Promise<HttpClientResponse<AutoUpdateResponse<TDto>>>;
}

export interface UseAutoUpdateFormWithDataProps<
  TRequest extends FieldValues,
  TDto,
  TData,
> extends AutoUpdateFormBaseProps<TRequest, TDto> {
  autoUpdateRequest: (
    id: number,
    request: AutoUpdateRequestWithData<TRequest, TData>,
  ) => Promise<HttpClientResponse<AutoUpdateResponse<TDto>>>;
  additionalData: TData;
}

export interface BaseAutoUpdateProps<T extends FieldValues> {
  states: AutoUpdateFieldType[];
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  onValueOptionClick: (force: boolean) => void;
}

export interface AutoUpdateWrapperProps<T extends FieldValues> {
  autoUpdateProps: BaseAutoUpdateProps<T>;
  path: Path<T>;
  children: React.ReactNode;
  position?: Position;
  boxProps?: BoxProps;
}

export enum Position {
  Bottom,
  Right,
}

export interface AutoUpdateProps<T extends FieldValues>
  extends BaseAutoUpdateProps<T> {
  path: Path<T>;
  children: React.ReactNode;
  position?: Position;
  boxProps?: BoxProps;
}
