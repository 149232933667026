import { httpClient } from '@texas/api/httpClient/httpClient';

export interface CategoryCode {
  id: number;
  branchId: number;
  identifier: string;
  name: string;
  archived: boolean;
}

export interface CategoryCodeName {
  name: string;
}

export interface SharedCategoryCode {
  name: string;
  branches: BranchCategoryCode[];
}

interface BranchCategoryCode {
  branchId: number;
  CategoryCodeId: number;
}

export const categoryCodesApi = {
  getSharedNames: (branchIds?: number[]) =>
    httpClient.get<CategoryCodeName[]>(
      '/metadata/category-codes/shared/branches/names',
      {
        params: { branchIds: branchIds },
      },
    ),

  // below endpoints exists in garp.js but will be replaced with these when angular is removed
  getSharedVariantCodes: (variantId: number) =>
    httpClient.get<SharedCategoryCode[]>(
      `/metadata/category-codes/shared/variant/${variantId}`,
    ),
  getBranchCodes: (branchId: number) =>
    httpClient.get<CategoryCode[]>(
      `/metadata/category-codes/branch/${branchId}`,
    ),
};
