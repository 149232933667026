// @ngInject
const MaterialDialogService = ($mdDialog) => ({
  showDialog: (options = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'MaterialDialogController as vm',
      templateUrl: 'services/dialogs/materialDialog/materialDialog.tpl.html',
      locals: Object.assign(
        {
          material: null,
          displayOrder: false,
          checkedByDefault: false,
          archivable: true,
          allowedToEdit: true,
        },
        options,
      ),
    }),
});

class MaterialDialogController {
  constructor(
    $mdDialog,
    toasterService,
    material,
    onSave,
    displayOrder,
    checkedByDefault,
    archivable,
    allowedToEdit,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.toasterService = toasterService;

    this.validationErrors = [];
    this.onSave = onSave;
    this.displayOrder = displayOrder;
    this.checkedByDefault = checkedByDefault;
    this.archivable = archivable;
    this.allowedToEdit = allowedToEdit;
    this.editMode = material !== null;

    if (!this.editMode) {
      material = {};
    }
    this.material = _.extend({}, material);
  }

  save() {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();
    this.onSave(this.material)
      .then(
        () => {
          this.toasterService.success();
          this.$mdDialog.hide();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.$mdDialog.stopLoadingSpinner();
      });
  }

  archive() {
    this.material.archived = !this.material.archived;
    this.save();
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.materialDialog', [])
  .service('MaterialDialogService', MaterialDialogService)
  .controller('MaterialDialogController', MaterialDialogController);
