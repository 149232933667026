import { httpClient } from '../httpClient/httpClient';
import { UserProfile } from './userProfilesApi';

export interface Project {
  name: string;
  erpId: string;
  id: number;
  keyAccountManager?: UserProfile;
  archived?: Date;
}

export interface ErpProject {
  name: string;
  number: string;
}

export interface CreateProjectRequest {
  name: string;
  customerId: number;
  userProfileId?: number;
  identifier?: string | null;
}
export interface UpdateProjectRequest {
  name: string;
  userProfileId?: number;
}

export const projectApi = {
  createProject: (request: CreateProjectRequest) =>
    httpClient.post<Project>('projects', { data: request }),
  updateProject: (projectId: number, request: UpdateProjectRequest) =>
    httpClient.put<Project>(`projects/${projectId}`, { data: request }),
  archiveProject: (projectId: number) =>
    httpClient.put<Project>(`projects/${projectId}/archive`),
  restoreProject: (projectId: number) =>
    httpClient.put<Project>(`projects/${projectId}/restore`),
  getProjectFromBrand: (customerId: number) =>
    httpClient.get<Project>(`projects/brand/${customerId}`),
  validateProject: (identifier: string) =>
    httpClient.get<ErpProject>(`projects/validate/${identifier}`),
};
