import {
  VStack,
  Box,
  Text,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  PopoverAnchor,
} from '@chakra-ui/react';
import { shakeAnimation } from '../../../resources/animations/animations';
import { convertToEnum } from '../../../utils/helpers/enumHelpers';
import { AutoUpdateErrorFooter } from './AutoUpdateErrorFooter';
import { ErrorTitle } from './AutoUpdateErrorTitle';
import {
  AutoUpdateErrorType,
  AutoUpdateFieldError,
} from '@texas/api/endpoints/autoUpdateTypes';

export interface AutoUpdateErrorProps {
  row: AutoUpdateFieldError;
  formValue: any;
  onValueOptionClick: (value: any, set: boolean) => void;
}

export function AutoUpateError(props: AutoUpdateErrorProps) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const shakeAnim = shakeAnimation();

  return (
    <>
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverAnchor>
          <Box
            animation={shakeAnim}
            pos="absolute"
            zIndex={99}
            top="100%"
            left="0"
            backgroundColor="texas.danger.100"
            w="100%"
            borderRadius={4}
            onClick={onToggle}
            cursor="pointer"
            transition="width 200ms ease, left 200ms ease"
            _hover={{
              w: 'calc(100% + 0.3em)',
              left: '-0.15em',
            }}
          >
            <VStack
              pos="relative"
              top="100%"
              px={2}
              fontWeight="bold"
              fontSize={12}
            >
              <Text>
                <ErrorTitle
                  errorType={convertToEnum(
                    AutoUpdateErrorType,
                    props.row.error.type,
                  )}
                />
              </Text>
            </VStack>
          </Box>
        </PopoverAnchor>

        <PopoverContent backgroundColor="texas.danger.100">
          <PopoverArrow backgroundColor="texas.danger.100" />
          <PopoverCloseButton />
          <PopoverBody>
            <VStack align="start" fontWeight="bold" fontSize={12} spacing={1}>
              <Box px={2}>
                <Text>{props.row.error.message}</Text>
              </Box>
              <AutoUpdateErrorFooter
                fieldError={props.row}
                fieldValue={props.row.fieldValue}
                formValue={props.formValue}
                onValueOptionClick={(value, force) => {
                  props.onValueOptionClick(value, force);
                  onClose();
                }}
              />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
