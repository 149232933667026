import { Box, Flex } from '@chakra-ui/react';
import { ReactSelectOptionWithIcon } from '@texas/types';
import { OptionProps, GroupBase, components } from 'react-select';

export function IconOptionComponent<IsMulti extends boolean>(
  props: OptionProps<
    ReactSelectOptionWithIcon,
    IsMulti,
    GroupBase<ReactSelectOptionWithIcon>
  >,
) {
  return (
    <components.Option {...props}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>{props.data.label}</Box>
        {props.data.icon}
      </Flex>
    </components.Option>
  );
}
