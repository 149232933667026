// @ngInject
const FilesApiService = (DS, DSHttpAdapter, $q, ResourcesService) => {
  const getFolderById = (folderId) =>
    ResourcesService.getById('folder', folderId, {
      findInverseLinks: false,
      findHasMany: true,
      findHasOne: false,
      findBelongsTo: false,
    });

  return {
    getFolderById,

    getFolderFromCache: (folderId) => DS.get('folder', folderId),

    newFolder: (folder, parent) =>
      DSHttpAdapter.POST(
        `${DS.defaults.basePath}folders/${parent.id}/folders`,
        folder,
      ).then(
        (response) => {
          parent.hasNonArchivedFolders = true;
          parent.hasFolders = true;
          return DS.inject('folder', response.data);
        },
        (error) => $q.reject(ResourcesService.handleError(error)),
      ),

    updateFolderInfo: (data, folderId) =>
      DS.update('folder', folderId, data, { cacheResponse: false }).then(
        (response) => {
          const folder = DS.get('folder', folderId);
          folder.name = response.name;
          folder.updated = response.updated;
          const archivedChanged = folder.archived !== response.archived;
          folder.archived = response.archived;
          return archivedChanged ? getFolderById(folder.parentId) : response;
        },
        (error) => $q.reject(ResourcesService.handleError(error)),
      ),

    getFileIdentifier: (fileName, fileSize, chunkSize) =>
      DSHttpAdapter.POST(`${DS.defaults.basePath}files`, {
        fileName,
        fileSize,
        chunkSize,
      }).then(
        (response) => response.data.identifier,
        (error) => $q.reject(ResourcesService.handleError(error)),
      ),

    updateFileInfo: (data, identifier) =>
      ResourcesService.update('file', identifier, data, {
        updatedFields: ['name', 'updated', 'archived', 'note'],
      }),

    postFinalizeRequest: (uniqueIdentifier, postUrl, options) =>
      DSHttpAdapter.POST(
        DS.defaults.basePath + postUrl,
        Object.assign(
          {},
          {
            identifier: uniqueIdentifier,
          },
          options,
        ),
      ).then(
        (response) => DS.inject('file', response.data),
        (error) => $q.reject(ResourcesService.handleError(error)),
      ),

    getFileUrl: (identifier) => `${DS.defaults.basePath}files/${identifier}`,

    getFilePreviewUrl: (identifier, size) =>
      `${DS.defaults.basePath}files/${identifier}/preview/${size}`,

    clearFolderCache: () => DS.ejectAll('folder'),
  };
};

angular
  .module('resources.files', [])
  .factory('FilesApiService', FilesApiService);
