// @ngInject
const texasDialogDelegate = ($q, $delegate, loadSpinnerService) => {
  const showFn = $delegate.show;
  const defaultSpinnerName = 'modalSpinner';

  $delegate.show = (...args) => {
    const options = args[0]._options ? args[0]._options : args[0];
    if (options.$type) {
      return showFn(...args);
    }

    options.onShowing = () => loadSpinnerService.stop('mainSpinner');
    options.clickOutsideToClose = false;
    options.focusOnOpen = false;
    options.transformTemplate = (template) =>
      `<div class="md-dialog-container"><load-spinner spinner-name="${
        args[0].customSpinnerName
          ? args[0].customSpinnerName
          : defaultSpinnerName
      }"></load-spinner>${template}</div>`;

    loadSpinnerService.start('mainSpinner');

    const deferred = $q.defer();

    showFn(...args).then(
      (response) => {
        deferred.resolve(response);
      },
      (response) => {
        deferred.reject(response);
        loadSpinnerService.stop('mainSpinner');
      },
    );

    return deferred.promise;
  };

  $delegate.startLoadingSpinner = (customSpinnerName) =>
    loadSpinnerService.start(
      customSpinnerName ? customSpinnerName : defaultSpinnerName,
    );

  $delegate.stopLoadingSpinner = (customSpinnerName) =>
    loadSpinnerService.stop(
      customSpinnerName ? customSpinnerName : defaultSpinnerName,
    );

  return $delegate;
};

// @ngInject
const texasDialog = ($provide) =>
  $provide.decorator('$mdDialog', texasDialogDelegate);

angular.module('decorators.texasDialog', []).config(texasDialog);
