import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ServerError } from '../../../../types';
import { useState } from 'react';
import { DateFormat, formatDate } from '../../../../utils/helpers/dateHelper';
import { Locale } from '../../../../i18n/types';
import { useTranslation } from 'react-i18next';
import { ColumnHistoryResponse } from '@texas/api/endpoints/articleHistoryApi';
import { HttpClientResponse } from '@texas/api/httpClient/types';
import { Icons } from '../../Icons';
import { LoadingOverlay } from '../../LoadingOverlay';
import { ErrorDetails } from '../../alert/ErrorDetails';
import { TFunction } from 'i18next';

interface UpdateHistoryProps {
  historyRequestWrapper: () => Promise<
    HttpClientResponse<ColumnHistoryResponse[]>
  >;
  header: string;
}

export function UpdateHistory(props: UpdateHistoryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ServerError | null>(null);
  const [histories, setHistories] = useState<ColumnHistoryResponse[]>([]);

  const { t } = useTranslation();

  return (
    <>
      <IconButton
        variant="ghost"
        size="xs"
        aria-label="History"
        onClick={async () => {
          onOpen();
          await fetchHistories();
        }}
        icon={<Icons.History />}
      />
      <Modal
        scrollBehavior="inside"
        size="xl"
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.header}</ModalHeader>
          <ModalBody>
            <Histories
              histories={histories}
              isLoading={loading}
              error={error}
              t={t}
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>{t('general.close')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

  async function fetchHistories() {
    setLoading(true);
    const response = await props.historyRequestWrapper();

    if (response.hasError) {
      setError(response.error);
      return;
    }

    setHistories(response.data);
    setError(null);
    setLoading(false);
  }
}

function Histories({
  histories,
  isLoading,
  error,
  t,
}: {
  histories: ColumnHistoryResponse[];
  isLoading: boolean;
  error: ServerError | null;
  t: TFunction;
}) {
  return (
    <TableContainer>
      {isLoading && <LoadingOverlay />}
      {error && <ErrorDetails error={error} />}
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead background="texas.bg.800">
          <Tr>
            <Th color="white">Date</Th>
            <Th color="white">Changed by</Th>
            <Th color="white">To</Th>
          </Tr>
        </Thead>
        {histories.length === 0 && (
          <Tbody>
            <Tr>
              <Td
                colSpan={100}
                textAlign="center"
                color="whiteAlpha.600"
                _light={{ color: 'blackAlpha.600' }}
                p={8}
              >
                {t('general.noData')}
              </Td>
            </Tr>
          </Tbody>
        )}
        <Tbody>
          {histories.map((x, i) => (
            <Tr key={i}>
              <Td>{formatDate(Locale.En, x.modified, DateFormat.Datetime)}</Td>
              <Td>{x.modifiedBy}</Td>
              <Td isNumeric={true}>{x.toValue}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
