import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import {
  CreateMaterialRequest,
  materialsApi,
} from '@texas/api/endpoints/metadata/materialsApi';

interface Props extends SharedDisclosureProps {
  onCreated: () => void;
}

const defaultValues: CreateMaterialRequest = {
  name: '',
  description: '',
  articleTextField: '',
  displayOrder: 0,
  childMaterialIds: [],
};

export function CreateMaterial({ onCreated, onClose, isOpen }: Props) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createRequest, error } = useApiRequest(materialsApi.create);

  const {
    data: materials,
    refetch: refetchMaterials,
    loading: materialsLoading,
  } = useApiResource(materialsApi.getAll);

  useEffect(() => {
    if (!isOpen) return;
    refetchMaterials();
  }, [isOpen, refetchMaterials]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateMaterialRequest>({
    defaultValues: defaultValues,
  });
  const watchCombinations = watch('childMaterialIds');

  const onSubmit = async (data: CreateMaterialRequest) =>
    await request(
      createRequest,
      [data],
      () => {
        reset(defaultValues);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        onCreated();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewMaterial')}
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            {error && <ErrorDetails error={error} />}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <VStack>
                <FormControl isRequired={true} isInvalid={!!errors.name}>
                  <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('name', {
                      required: true,
                      maxLength: {
                        value: 35,
                        message: t('errors.maxLength', { count: 35 }),
                      },
                    })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.name?.message} />
                </FormControl>
                <FormControl isRequired={true} isInvalid={!!errors.description}>
                  <TexasFormLabel>{t('general.description')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('description', {
                      required: true,
                      maxLength: {
                        value: 35,
                        message: t('errors.maxLength', { count: 35 }),
                      },
                    })}
                    placeholder={t('general.description')}
                  />
                  <ErrorLabel text={errors.description?.message} />
                </FormControl>
                <FormControl
                  isRequired={true}
                  isInvalid={!!errors.articleTextField}
                >
                  <TexasFormLabel>
                    {t('configuration.articleTextField')}
                  </TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('articleTextField', {
                      required: true,
                      maxLength: {
                        value: 20,
                        message: t('errors.maxLength', { count: 20 }),
                      },
                    })}
                    placeholder={t('configuration.articleTextField')}
                  />
                  <ErrorLabel text={errors.articleTextField?.message} />
                </FormControl>
                <FormControl
                  isRequired={true}
                  isInvalid={!!errors.displayOrder}
                >
                  <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
                  <Input
                    type="number"
                    variant="outline"
                    {...register('displayOrder', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder={t('branch.displayOrder')}
                  />
                  <ErrorLabel text={errors.displayOrder?.message} />
                </FormControl>

                <FormControl>
                  <TexasFormLabel>
                    {t('configuration.combinationOf')}
                  </TexasFormLabel>
                  <Controller
                    name="childMaterialIds"
                    control={control}
                    render={({ field }) => (
                      <TexasSelect
                        {...field}
                        isLoading={materialsLoading}
                        isClearable={true}
                        value={field.value?.map<ReactSelectOption>((x) => ({
                          label: materials?.find((p) => p.id === x)?.name ?? '',
                          value: x,
                        }))}
                        isMulti={true}
                        onChange={(e) => field.onChange(e.map((x) => x.value))}
                        options={
                          materials?.map<ReactSelectOption>((x) => ({
                            value: x.id,
                            label: x.name,
                          })) ?? []
                        }
                        closeMenuOnSelect={false}
                      />
                    )}
                  />
                  <Text variant="small" pt={1}>
                    {t('configuration.mixedDesc')}
                  </Text>
                </FormControl>
                <SubmitButton
                  disabled={watchCombinations?.length == 1}
                  loading={isSubmitting}
                >
                  {watchCombinations?.length == 0
                    ? t('configuration.createMaterial')
                    : t('configuration.createMixedMaterial')}
                </SubmitButton>
              </VStack>
            </form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
