const tabViews = () => ({
  restrict: 'EA',
  scope: {
    tabs: '=data',
  },
  controller: 'TabViewsController as vm',
  templateUrl: 'directives/tabViews/tabViews.tpl.html',
});

class TabViewsController {
  constructor($scope, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;

    this.tabs = $scope.tabs;
    this.setupTabs();
    this.setActiveTab();

    $scope.$on('$stateChangeSuccess', () => this.setActiveTab());
  }

  setupTabs() {
    _.forEach(this.tabs, (tab) => {
      tab.params = tab.params || _.noop;
      tab.hidden = tab.hidden || false;
    });
  }

  go(tab) {
    if (!this.$state.includes(tab.route)) {
      this.$state.go(tab.route, tab.params());
    }
  }

  setActiveTab() {
    _.forEach(this.tabs, (tab) => {
      tab.active = this.$state.includes(tab.route);
    });
  }
}

angular
  .module('directives.tabviews', [])
  .controller('TabViewsController', TabViewsController)
  .directive('tabViews', tabViews);
