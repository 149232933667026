import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { MatrixGrid } from '@texas/components/shared/matrix/MatrixGrid';
import { Button, Checkbox } from '@chakra-ui/react';
import { useEffect } from 'react';
import { formatNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { ProductApprovalCell } from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';
import { useTranslation } from 'react-i18next';

export function SelectCells({
  articleNodeGroup,
  page,
}: {
  articleNodeGroup: ArticleNodeGroup;
  page: string;
}) {
  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      Object.keys(data.selectedCells).filter(
        (key) => data.selectedCells[key].added,
      ).length > 0
    ) {
      setStepIsValid(page, true);
      return;
    }

    setStepIsValid(page, false);
  }, [data.selectedCells, setStepIsValid, page]);

  return (
    <MatrixGrid
      articleNodeGroup={articleNodeGroup}
      TopLeft={
        <>
          <Button
            mx={4}
            width="fit-content"
            size="sm"
            variant="texas-outline-light"
            mb={2}
            onClick={() =>
              setData((d) => ({
                ...d,
                selectedCells: checkAll(articleNodeGroup),
              }))
            }
          >
            {t('general.selectAll')}
          </Button>
        </>
      }
      cell={(x, y, xIndex, yIndex) => {
        return (
          <Checkbox
            isChecked={cellChecked(data.selectedCells[cellFormat(x, y)])}
            onChange={(e) => {
              setData((d) => ({
                ...d,
                selectedCells: {
                  ...d.selectedCells,
                  [cellFormat(x, y)]: {
                    x,
                    y,
                    xIndex,
                    yIndex,
                    added: e.target.checked,
                  },
                },
              }));
            }}
          >
            {formatNodeGroup(
              articleNodeGroup.nodeXValues[xIndex],
              articleNodeGroup.nodeYValues[yIndex],
            )}
          </Checkbox>
        );
      }}
    />
  );
}

function checkAll(nodeGroup: ArticleNodeGroup) {
  const cells: Record<string, ProductApprovalCell> = {};
  for (let x = 0; x < nodeGroup.nodeXValues.length; x++) {
    for (let y = 0; y < nodeGroup.nodeYValues.length; y++) {
      cells[
        cellFormat(
          nodeGroup.nodeXValues[x].matrixNodeValue.id,
          nodeGroup.nodeYValues[y].matrixNodeValue.id,
        )
      ] = {
        x: nodeGroup.nodeXValues[x].matrixNodeValue.id,
        y: nodeGroup.nodeYValues[y].matrixNodeValue.id,
        xIndex: x,
        yIndex: y,
        added: true,
      };
    }
  }
  return cells;
}

function cellChecked(cell: ProductApprovalCell | undefined) {
  return cell?.added ?? false;
}

function cellFormat(x: number, y: number) {
  return `${x}-${y}`;
}
