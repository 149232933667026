// @ngInject
const validationErrors = () => ({
  templateUrl: 'directives/validationErrors/validationErrors.tpl.html',
  restrict: 'E',

  scope: {
    validationErrors: '=errors',
  },
});

angular
  .module('directives.validationErrors', [])
  .directive('validationErrors', validationErrors);
