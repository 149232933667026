import { Box, Flex } from '@chakra-ui/react';

export function Scrollable({ children }: { children: React.ReactNode }) {
  return (
    <Box flex="1 1 auto" overflow="auto">
      {children}
    </Box>
  );
}

export function ScrollableContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex flexDirection="column" maxHeight="100%">
      {children}
    </Flex>
  );
}
