// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('customer.contacts', {
    controller: 'ContactsController as vm',
    url: '/contacts',
    templateUrl: 'main/customer/contacts/contacts.tpl.html',
    data: { pageTitle: 'Contacts' },
  });
};

class ContactsController {
  constructor(customer) {
    'ngInject';

    this.customer = customer;
  }
}

angular
  .module('main.customer.contacts', [])
  .config(config)
  .controller('ContactsController', ContactsController);
