import {
  TexasDrawer,
  useTexasDrawer,
} from '@texas/components/shared/drawer/TexasDrawer';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { useEffect, useState } from 'react';
import { angularEvents } from 'src/bridge/angularEvents';
import { AddSupplier } from './add/AddSupplier';
import { reactEvents } from 'src/bridge/reactEvents';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { purchaseInformationApi } from '@texas/api/endpoints/purchaseInformationApi';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TexasDrawerContent } from '@texas/components/shared/drawer/TexasDrawerContent';

export function AngularAddSupplier() {
  const drawer = useTexasDrawer(ModalState.Closed);
  const { t } = useTranslation();
  const getPurchaseInformation = useApiRequest(
    purchaseInformationApi.getPurchaseInformation,
  );
  const toast = useToast();
  const [addSupplierData, setAddSupplierData] = useState<{
    branchId: number;
    variantId: number;
    branchName: string;
  } | null>(null);
  useEffect(() => {
    return angularEvents.addSupplierClicked.subscribe((data) => {
      setAddSupplierData({
        branchId: data.branchId,
        variantId: data.variantId,
        branchName: data.branchName,
      });
      drawer.setModalState(ModalState.Open);
    });
  }, [drawer]);

  return (
    <TexasDrawer ref={drawer.ref} modal={drawer}>
      <TexasDrawerContent>
        {addSupplierData && (
          <AddSupplier
            variantId={addSupplierData.variantId}
            branchId={addSupplierData.branchId}
            branchName={addSupplierData.branchName}
            onAdd={async (data) => {
              const purchaseInfo = await getPurchaseInformation.request(
                data.variantId,
                data.branchId,
                data.supplierId,
              );
              if (purchaseInfo.hasError) {
                toast({
                  title: t('variant.purchase.errorFetchingPurchaseInfo'),
                  status: 'error',
                  description: purchaseInfo.error.message,
                  isClosable: true,
                });
                return;
              }
              reactEvents.supplierAdded.dispatch(purchaseInfo.data);
            }}
          />
        )}
      </TexasDrawerContent>
    </TexasDrawer>
  );
}
1;
