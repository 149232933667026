// @ngInject
const restrictAccess = (AuthService) => ({
  restrict: 'A',
  scope: false,

  compile: function compileFn(element, attr) {
    let accessDenied = true;

    const attributes = attr.restrictAccess.split(' ');
    angular.forEach(attributes, (claim) => {
      if (AuthService.hasAnyClaim([claim])) {
        accessDenied = false;
      }
    });

    if (accessDenied) {
      element.hide();
    }
  },
});

angular
  .module('security.restrictAccess', [])
  .directive('restrictAccess', restrictAccess);
