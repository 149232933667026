import { Tooltip } from '@chakra-ui/react';
import { Icons } from '../Icons';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';

export function ArchivedTooltip({ archived }: { archived: string }) {
  return (
    <Tooltip label={formatDate(Locale.En, archived)}>
      <Icons.Archive boxSize={4} />
    </Tooltip>
  );
}
