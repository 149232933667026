import { fadeInScaleAnimation } from '@texas/resources/animations/animations';

const gridCellStyle = (disabled = false) => {
  return {
    display: 'flex',
    animation: fadeInScaleAnimation(),
    bg: disabled ? 'texas.bg.900' : 'texas.bg.800',
    _light: { bg: disabled ? 'blackAlpha.100' : 'gray.50' },
    borderRadius: 'md',
    role: 'group',
  };
};

export default gridCellStyle;
