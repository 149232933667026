// @ngInject
const FileReplaceDialogService = ($mdDialog) => ({
  showDialog(data) {
    data = data || {};
    data.file = data.file || null;

    return $mdDialog.show({
      controller: 'FileReplaceDialogController as vm',
      templateUrl:
        'services/dialogs/fileReplaceDialog/fileReplaceDialog.tpl.html',
      locals: { file: data.file },
    });
  },
});

class FileReplaceDialogController {
  constructor($mdDialog, HelperService, FilesApiService, toasterService, file) {
    'ngInject';
    const existsError = {
      message:
        'File already exists, choose a new name or replace the existing file',
    };

    this.validationErrors = [existsError];
    this.fileName = HelperService.getCleanFileName(file.name);
    this._$mdDialog = $mdDialog;
    this._FilesApiService = FilesApiService;
    this._existsError = existsError;
    this._file = file;
    this._toasterService = toasterService;
  }

  rename() {
    this.validationErrors = [];
    this._$mdDialog.startLoadingSpinner();
    this._FilesApiService
      .postFinalizeRequest(
        this._file.uniqueIdentifier,
        this._file.finalizeUrl,
        { fileName: this.fileName },
      )
      .then(
        (response) => {
          this._toasterService.success();
          this._$mdDialog.hide(response);
        },
        (error) => {
          if (error.status === 409) {
            this.validationErrors.push(this._existsError);
          } else {
            this.validationErrors.push(error);
          }
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  replace() {
    this.validationErrors = [];
    this._$mdDialog.startLoadingSpinner();
    this._FilesApiService
      .postFinalizeRequest(
        this._file.uniqueIdentifier,
        this._file.finalizeUrl,
        { replace: true },
      )
      .then(
        (response) => {
          this._toasterService.success();
          this._$mdDialog.hide(response);
        },
        (error) => {
          this.validationErrors.push(error);
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.fileReplaceDialog', [])
  .service('FileReplaceDialogService', FileReplaceDialogService)
  .controller('FileReplaceDialogController', FileReplaceDialogController);
