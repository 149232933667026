import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertStatus,
  Flex,
} from '@chakra-ui/react';

interface AlertDetailsProps {
  title?: string;
  description?: string;
  type?: AlertStatus;
  variant?: 'solid' | 'subtle';
}

export function AlertDetails(props: AlertDetailsProps) {
  return (
    <Alert status={props.type} variant={props.variant}>
      <Flex direction="column">
        <Flex>
          <AlertIcon />
          <AlertTitle>{props.title}</AlertTitle>
        </Flex>
        <AlertDescription>{props.description}</AlertDescription>
      </Flex>
    </Alert>
  );
}
