import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  CreateTreatmentRequest,
  treatmentsApi,
} from '@texas/api/endpoints/metadata/treatmentsApi';
import { productGroupsApi } from '@texas/api/endpoints/metadata/productGroupsApi';
import { ServerError, ReactSelectOption } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

export interface CreateTreatmentProps extends SharedDisclosureProps {
  onCreated: () => void;
}

const defaultValues: CreateTreatmentRequest = {
  productGroupIds: [],
  name: '',
  code: '',
};

export function CreateTreatment({
  onCreated,
  onClose,
  isOpen,
}: CreateTreatmentProps) {
  const toast = useToast();
  const { t } = useTranslation();

  const {
    request: createRequest,
    error,
    loading,
  } = useApiRequest(treatmentsApi.create);

  const {
    data: productGroups,
    refetch: refetchProductGroups,
    loading: productGroupsLoading,
  } = useApiResource(productGroupsApi.getProductGroups);

  useEffect(() => {
    refetchProductGroups();
  }, [refetchProductGroups]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<CreateTreatmentRequest>();

  const onSubmit = async (data: CreateTreatmentRequest) =>
    await request(
      createRequest,
      [data],
      () => {
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        reset(defaultValues);
        onCreated();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewTreatment')}
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            <VStack>
              {error && <ErrorDetails error={error} />}
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <VStack>
                  <FormControl isRequired={true} isInvalid={!!errors.name}>
                    <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                    <Input
                      variant="outline"
                      {...register('name', {
                        required: true,
                        maxLength: {
                          value: 30,
                          message: t('errors.maxLength', { count: 30 }),
                        },
                      })}
                      placeholder={t('general.name')}
                    />
                    <ErrorLabel text={errors.name?.message} />
                  </FormControl>
                  <FormControl>
                    <TexasFormLabel>{t('general.description')}</TexasFormLabel>
                    <Input
                      variant="outline"
                      {...register('description')}
                      placeholder={t('general.description')}
                    />
                    <ErrorLabel text={errors.name?.message} />
                  </FormControl>
                  <FormControl isRequired={true} isInvalid={!!errors.code}>
                    <TexasFormLabel>{t('general.code')}</TexasFormLabel>
                    <Input
                      variant="outline"
                      {...register('code', {
                        required: true,
                        maxLength: {
                          value: 6,
                          message: t('errors.maxLength', { count: 6 }),
                        },
                      })}
                      placeholder={t('general.code')}
                    />
                    <ErrorLabel text={errors.code?.message} />
                  </FormControl>
                  <FormControl>
                    <TexasFormLabel>
                      {t('general.productGroups')}
                    </TexasFormLabel>
                    <Controller
                      name="productGroupIds"
                      control={control}
                      render={({ field }) => (
                        <TexasSelect
                          {...field}
                          isLoading={productGroupsLoading}
                          isClearable={true}
                          value={field.value?.map<ReactSelectOption>((x) => ({
                            label:
                              productGroups?.find((p) => p.id === x)?.value ??
                              '',
                            value: x,
                          }))}
                          isMulti={true}
                          onChange={(e) =>
                            field.onChange(e.map((x) => x.value))
                          }
                          options={
                            productGroups?.map<ReactSelectOption>((x) => ({
                              value: x.id,
                              label: x.value,
                            })) ?? []
                          }
                          closeMenuOnSelect={false}
                        />
                      )}
                    />
                  </FormControl>
                  <SubmitButton loading={loading}>
                    {t('general.create')}
                  </SubmitButton>
                </VStack>
              </form>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
