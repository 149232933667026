import { httpClient } from '../httpClient/httpClient';

export interface ColumnHistoryResponse {
  fromValue: any;
  toValue: any;
  modifiedBy: string;
  modified: string;
}

export const articleHistoryApi = {
  salesPrice: (
    articleId: number,
    tierPriceId: number,
    salesCurrencyId: number,
  ) =>
    httpClient.get<ColumnHistoryResponse[]>(
      `history/article/${articleId}/salesprice/price/${tierPriceId}/${salesCurrencyId}`,
    ),
  salesCurrency: (
    articleId: number,
    articleVariantId: number,
    currencyId: number,
    branchId: number,
  ) =>
    httpClient.get<ColumnHistoryResponse[]>(
      `history/article/${articleId}/salescurrency/fixedexchangerate/${articleVariantId}/${currencyId}/${branchId}`,
    ),
  salesCellPrice: (
    articleId: number,
    tierPriceId: number,
    salesCurrencyId: number,
    matrixNodeX: number,
    matrixNodeY: number,
  ) =>
    httpClient.get<ColumnHistoryResponse[]>(
      `history/article/${articleId}/salescellprices/price/${tierPriceId}/${salesCurrencyId}/${matrixNodeX}/${matrixNodeY}`,
    ),
};
