// @ngInject
const SavingService = ($q, $rootScope) => {
  const currentSaveObjects = [];

  return {
    registerSave(saveObj) {
      currentSaveObjects.push(saveObj);
      saveObj.onValidate = saveObj.onValidate || _.noop;
      return saveObj;
    },
    deregisterSave(saveObj) {
      _.remove(currentSaveObjects, saveObj);
    },
    save() {
      _.forEach(
        _.filter(currentSaveObjects, (obj) => {
          return obj.hasChanges;
        }),
        (saveObject) => {
          if (saveObject.onValidate() === false) {
            return;
          }
          saveObject.onSave();
        },
      );
    },
    discard() {
      _.forEach(
        _.filter(currentSaveObjects, (obj) => {
          return obj.hasChanges;
        }),
        (saveObject) => {
          saveObject.onDiscard();
        },
      );
    },
    hasChanges() {
      return _.some(currentSaveObjects, { hasChanges: true });
    },
    disabled() {
      return _.some(currentSaveObjects, { disabled: true });
    },
    messages() {
      return _.map(
        _.filter(currentSaveObjects, (o) => o.message),
        (o) => o.message,
      );
    },
    notifyUser() {
      $rootScope.$applyAsync($rootScope.$emit('alertSaveMenu'));
    },
  };
};

angular.module('services.saving', []).factory('SavingService', SavingService);
