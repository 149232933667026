import { useState, useCallback } from 'react';
import { StepConfig, StepState } from '../types';

const defaultStepState = {
  isActive: false,
  isValid: false,
  isDisabled: false,
};

export function useStepStates(stepConfigs: StepConfig[]) {
  const [stepStates, setStepStates] = useState(
    stepConfigs.map((c): StepState => {
      return {
        id: c.id,
        ...defaultStepState,
      };
    }),
  );

  const setStepIsActive = useCallback(
    (id: string | null) => {
      if (stepStates.find((s) => s.id === id)?.isDisabled) return;
      setStepStates((s) =>
        s.map((stepState) =>
          stepState.id === id
            ? { ...stepState, isActive: true }
            : {
                ...stepState,
                isActive: false,
              },
        ),
      );
    },
    [stepStates],
  );

  const nextStep = useCallback(() => {
    const activeStepIndex = stepStates.findIndex((s) => s.isActive);
    if (activeStepIndex === -1) {
      throw new Error('No active step found');
    }

    const nextStepId = stepStates.at(activeStepIndex + 1)?.id;

    if (nextStepId) {
      setStepIsActive(nextStepId);
    } else {
      setStepIsActive(null);
    }
  }, [stepStates, setStepIsActive]);

  const resetAllSteps = useCallback(() => {
    setStepStates((s) =>
      s.map((s2, i) =>
        i === 0
          ? { ...s2, ...defaultStepState, isActive: true }
          : { ...s2, ...defaultStepState },
      ),
    );
  }, []);

  const setStepIsValid = useCallback(
    (id: string, isValid: boolean) => {
      setStepStates((s) =>
        s.map((stepState) =>
          stepState.id === id ? { ...stepState, isValid } : stepState,
        ),
      );
    },
    [setStepStates],
  );

  const resetStep = useCallback(
    (id: string) => {
      setStepStates((s) =>
        s.map((stepState) =>
          stepState.id === id
            ? { ...stepState, ...defaultStepState }
            : stepState,
        ),
      );
    },
    [setStepStates],
  );

  return {
    stepStates,
    setStepIsActive,
    setStepIsValid,
    nextStep,
    resetStep,
    resetAllSteps,
  };
}
