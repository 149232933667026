import { Box, Text } from '@chakra-ui/react';

export interface ContainerProps {
  children: React.ReactNode;
  title: string;
  noBorderRadius?: boolean;
}

export function Container(props: ContainerProps) {
  return (
    <Box
      bgColor="texas.bg.900"
      _light={{ backgroundColor: 'white' }}
      borderRadius={props.noBorderRadius ? 0 : 12}
      overflow="hidden"
    >
      <Text variant="header" size="md" px={4} py={2} letterSpacing="wider">
        {props.title.toUpperCase()}
      </Text>
      {props.children}
    </Box>
  );
}
