const excelExportMenu = () => ({
  templateUrl: 'directives/excelExportMenu/excelExportMenu.tpl.html',
  restrict: 'E',
  scope: {},
  controller: 'ExcelExportMenuController',
  controllerAs: 'vm',
});

class ExcelExportMenuController {
  constructor($scope) {
    'ngInject';
    this.showExportMenu = false;
    this.activeExport = false;

    $scope.$on('activateExportMenu', () => this.setActive());
    $scope.$on('deactivateExportMenu', () => this.setInactive());
  }

  setActive() {
    this.showExportMenu = true;
    this.activeExport = true;
  }

  setInactive() {
    this.activeExport = false;
  }

  closeExportMenu() {
    this.showExportMenu = false;
  }
}

angular
  .module('directives.excelExportMenu', [])
  .directive('excelExportMenu', excelExportMenu)
  .controller('ExcelExportMenuController', ExcelExportMenuController);
