import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
  Text,
  IconButton,
  Select as ChakraSelect,
  Badge,
} from '@chakra-ui/react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import {
  CreateProductGroupRequest,
  productGroupsApi,
} from '@texas/api/endpoints/metadata/productGroupsApi';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  Metric,
  dimensionGroupsApi,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { useEffect, useState } from 'react';
import React from 'react';
import { Icons } from '@texas/components/shared/Icons';
import { getEnumNamesAndValues } from '@texas/utils/helpers/enumHelpers';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

export interface CreateProductGroupProps extends SharedDisclosureProps {
  onCreated: () => void;
}

const defaultValues: CreateProductGroupRequest = {
  value: '',
  code: '',
  displayOrder: 0,
  dimensionGroups: [],
};

export function CreateProductGroup({
  onCreated,
  onClose,
  isOpen,
}: CreateProductGroupProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createRequest, error } = useApiRequest(
    productGroupsApi.create,
  );
  const {
    data: dimensionGroups,
    refetch,
    loading,
  } = useApiResource(dimensionGroupsApi.getAll);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateProductGroupRequest>({
    defaultValues: {
      code: '',
      value: '',
      displayOrder: 0,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dimensionGroups',
  });
  const [dimensionGroupIds, setDimensionGroupIds] = useState<number[]>([]);

  const onSubmit = async (data: CreateProductGroupRequest) =>
    await request(
      createRequest,
      [data],
      () => {
        reset(defaultValues);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        onCreated();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewProductGroup')}
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            {error && <ErrorDetails error={error} />}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <VStack>
                <FormControl isRequired={true} isInvalid={!!errors.code}>
                  <TexasFormLabel>{t('general.code')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('code', {
                      required: true,
                      maxLength: {
                        value: 2,
                        message: t('errors.maxLength', { count: 2 }),
                      },
                    })}
                    placeholder={t('general.code')}
                  />
                  <ErrorLabel text={errors.code?.message} />
                </FormControl>
                <FormControl isRequired={true} isInvalid={!!errors.value}>
                  <TexasFormLabel>{t('general.value')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('value', {
                      required: true,
                      maxLength: {
                        value: 100,
                        message: t('errors.maxLength', { count: 100 }),
                      },
                    })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.value?.message} />
                </FormControl>
                <FormControl
                  isRequired={true}
                  isInvalid={!!errors.displayOrder}
                >
                  <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
                  <Input
                    type="number"
                    variant="outline"
                    {...register('displayOrder', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder={t('branch.displayOrder')}
                  />
                  <ErrorLabel text={errors.displayOrder?.message} />
                </FormControl>

                <FormControl>
                  <TexasFormLabel>
                    {t('configuration.dimensionGroups')}
                  </TexasFormLabel>
                  <TexasSelect
                    isLoading={loading}
                    isClearable={false}
                    isMulti={true}
                    value={dimensionGroupIds.map<ReactSelectOption>((x) => ({
                      label:
                        dimensionGroups?.find((p) => p.id === x)?.name ?? '',
                      value: x,
                    }))}
                    onChange={(_, meta) => {
                      if (meta.action === 'select-option') {
                        setDimensionGroupIds((s) => [...s, meta.option?.value]);
                        append({
                          dimensionGroupId: meta.option?.value,
                          metric: Metric.Millimeters,
                        });
                        return;
                      }

                      setDimensionGroupIds((s) =>
                        s.filter((x) => x !== meta.removedValue?.value),
                      );
                      remove(
                        fields.findIndex(
                          (x) => x.id === meta.removedValue?.value,
                        ),
                      );
                    }}
                    options={
                      dimensionGroups?.map<ReactSelectOption>((x) => ({
                        value: x.id,
                        label: x.name,
                      })) ?? []
                    }
                    closeMenuOnSelect={false}
                  />
                  <Box pt={2}>
                    {fields.map((x, i) => {
                      const dimensionGroup = dimensionGroups?.find(
                        (q) => q.id === dimensionGroupIds[i],
                      );
                      return (
                        <React.Fragment key={x.id}>
                          <Flex alignItems="flex-start" gap={2} pt={2}>
                            <Flex w="xl" flexDir="column" gap={1}>
                              <Text>{dimensionGroup?.name}</Text>
                              <Flex gap={2} flexWrap="wrap">
                                {dimensionGroup?.dimensions.map((d) => (
                                  <Badge key={d.id}>{d.name}</Badge>
                                ))}
                              </Flex>
                            </Flex>
                            <FormControl
                              isRequired={true}
                              isInvalid={!!errors.dimensionGroups?.[i]?.metric}
                            >
                              <ChakraSelect
                                fontSize="sm"
                                {...register(
                                  `dimensionGroups.${i}.metric` as const,
                                  {
                                    required: {
                                      value: true,
                                      message: t(
                                        'composition.qualityValueIsRequired',
                                      ),
                                    },
                                    valueAsNumber: true,
                                  },
                                )}
                              >
                                {getEnumNamesAndValues(Metric).map((x) => (
                                  <option key={x.value} value={x.value}>
                                    {x.name}
                                  </option>
                                ))}
                              </ChakraSelect>
                            </FormControl>
                            <IconButton
                              ml="auto"
                              size="sm"
                              variant="ghost"
                              icon={<Icons.Close />}
                              onClick={() => {
                                setDimensionGroupIds((x) =>
                                  x.filter((q) => q !== dimensionGroup?.id),
                                );
                                remove(i);
                              }}
                              aria-label={t('general.remove')}
                            />
                          </Flex>
                          <ErrorLabel
                            text={errors.dimensionGroups?.[i]?.metric?.message}
                          />
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </FormControl>

                <SubmitButton loading={isSubmitting}>
                  {t('general.create')}
                </SubmitButton>
              </VStack>
            </form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
