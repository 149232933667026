// @ngInject
const techniquesResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadTechniques(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.techniques', {
    url: '/techniques',
    controller: 'TechniquesConfigurationController as vm',
    templateUrl: 'main/configuration/techniques/techniques.tpl.html',
    data: { pageTitle: 'Techniques' },
    resolve: {
      techniques: techniquesResolve,
    },
  });
};

class TechniquesConfigurationController {
  constructor(
    $scope,
    DS,
    TechniquesApiService,
    TechniqueDialogService,
    currentUser,
    techniques,
  ) {
    'ngInject';

    this._TechniqueDialogService = TechniqueDialogService;
    this._TechniquesApiService = TechniquesApiService;
    this._currentUser = currentUser;
    this.techniques = techniques;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'code',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('technique');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.techniques = currentUser.organization.techniques;
        }
      },
    );
  }

  openTechnique(technique) {
    this._TechniqueDialogService.showDialog({
      technique,
      displayOrder: true,
      onSave: (technique) =>
        this._TechniquesApiService.updateTechnique(
          _.pick(technique, [
            'code',
            'value',
            'displayOrder',
            'archived',
            'description',
          ]),
          technique.id,
        ),
    });
  }

  newTechnique() {
    this._TechniqueDialogService.showDialog({
      displayOrder: true,
      onSave: (technique) =>
        this._TechniquesApiService.newTechnique(
          technique,
          this._currentUser.organization.id,
        ),
    });
  }
}

angular
  .module('configuration.techniques', [])
  .config(config)
  .controller(
    'TechniquesConfigurationController',
    TechniquesConfigurationController,
  );
