import { Flex, Box, Text } from '@chakra-ui/react';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { PantoneColor } from '../pantonePicker/PantonePicker';

export function ColorBox({
  color,
  colorDescription,
  onRemove,
}: {
  color: PantoneColor;
  colorDescription?: string;
  onRemove?: () => void;
}) {
  return (
    <Box
      animation={fadeInScaleAnimation()}
      bgColor={`#${color.hex}`}
      borderRadius="md"
      overflow="hidden"
      border="1px solid gray"
      {...(onRemove
        ? {
            onClick: () => {
              onRemove();
            },
            _hover: {
              opacity: 0.7,
              cursor: 'pointer',
            },
          }
        : null)}
    >
      <Flex bg="black" ml={12} px={2} py={1} flexDir="column" color="white">
        <Text fontSize="sm" variant="main">
          {color.name}
          {colorDescription && <Text as="span"> ({colorDescription})</Text>}
        </Text>
        <Text fontSize="sm">{color.code}</Text>
      </Flex>
    </Box>
  );
}
