// @ngInject
const MatrixGroupsApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('matrixGroup', {
      params: { organizationId },
    }),

  getById: (matrixGroupId) =>
    ResourcesService.getById('matrixGroup', matrixGroupId, {
      params: { organizationId: false },
    }),

  newMatrixGroup: (matrixGroup, organizationId) =>
    ResourcesService.create('matrixGroup', matrixGroup, {
      params: { organizationId },
    }),

  updateMatrixGroup: (data, matrixGroupId) =>
    ResourcesService.update('matrixGroup', matrixGroupId, data, {
      params: { organizationId: false },
      updatedFields: ['name', 'displayOrder', 'matrixNodeValues'],
    }),

  removeMatrixGroup: (matrixGroupId) =>
    ResourcesService.destroy('matrixGroup', matrixGroupId, {
      params: { organizationId: false },
    }),
});

angular
  .module('resources.matrixGroups', [])
  .factory('MatrixGroupsApiService', MatrixGroupsApiService);
