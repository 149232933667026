export const defaultCustomerWidgetLayouts = {
  articles: {
    lg: {
      x: 0,
      y: 3,
      w: 12,
      h: 10,
      minH: 3,
      minW: 4,
      maxH: 12,
    },
  },
  articlesCount: {
    lg: {
      w: 3,
      h: 2,
      minH: 2,
      minW: 2,
      maxH: 3,
    },
  },
};
