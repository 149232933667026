import { httpClient } from '@texas/api/httpClient/httpClient';
import { ProductGroup } from './productGroupsApi';
import { ProductGroupItem, SearchQuery } from './types';

interface BaseTreatment {
  name: string;
  description?: string;
  code: string;
}

export interface Treatment extends BaseTreatment {
  id: number;
  productGroups: ProductGroup[];
  archived: string | null;
}

export interface TreatmentListItem extends BaseTreatment {
  id: number;
  archived: string | null;
  productGroups: ProductGroupItem[];
}

export interface UpdateTreatmentRequest extends BaseTreatment {
  productGroupIds: number[] | null | undefined;
}
export type CreateTreatmentRequest = UpdateTreatmentRequest;

export const treatmentsApi = {
  get: (id: number) => httpClient.get<Treatment>(`metadata/treatments/${id}`),
  getAll: (params: SearchQuery) =>
    httpClient.get<TreatmentListItem[]>(`metadata/treatments/all`, {
      params,
    }),
  create: (request: CreateTreatmentRequest) =>
    httpClient.post<Treatment>(`metadata/treatments`, {
      data: request,
    }),
  update: (id: number, request: UpdateTreatmentRequest) =>
    httpClient.put<Treatment>(`metadata/treatments/${id}`, {
      data: request,
    }),
  archive: (id: number) => httpClient.put(`metadata/treatments/${id}/archive`),
  restore: (id: number) => httpClient.put(`metadata/treatments/${id}/restore`),
};
