// @ngInject
const angularDataConfig = (DSProvider, ENV) =>
  (DSProvider.defaults.basePath = ENV.apiEndpoint);

// @ngInject
const httpProviderConfig = ($httpProvider) =>
  $httpProvider.interceptors.push('httpInterceptor');

const loggerBlacklist = [0, 401, 400, 422, 409];

// @ngInject
const httpInterceptor = ($injector, $q, ErrorLoggerService) => ({
  responseError(rejection) {
    const { status } = rejection;
    if (status === 401) {
      const AuthService = $injector.get('AuthService');
      AuthService.showLogin();
    }
    if (!_.includes(loggerBlacklist, status)) {
      const error = new Error(`HTTP ${status} response error`);
      ErrorLoggerService.logException(error, { status });
    }
    return $q.reject(rejection);
  },
});

// @ngInject
const exceptionHandler =
  ($delegate, ErrorLoggerService) => (exception, cause) => {
    ErrorLoggerService.logException(exception, cause);
    $delegate(exception, cause);
  };

// @ngInject
const errorLoggerServiceConfig = ($provide) =>
  $provide.decorator('$exceptionHandler', exceptionHandler);

// @ngInject
const urlMatcherConfig = ($urlMatcherFactoryProvider) => {
  $urlMatcherFactoryProvider.strictMode(false);
  $urlMatcherFactoryProvider.type('nonURIEncoded', {
    encode: (item) => item || '',
    decode: (item) => item || '',
    // Item is always valid
    is: () => true,
  });
};

// @ngInject
const toasterConfig = (toastrConfig) =>
  angular.extend(toastrConfig, {
    allowHtml: true,
    closeButton: true,
    closeHtml: '<i class="fa fa-times"></i>',
    maxOpened: 1,
  });

// @ngInject
const mdDateConfig = ($mdDateLocaleProvider) => {
  $mdDateLocaleProvider.parseDate = (dateString) =>
    moment(dateString, 'YYYY-MM-DD').isValid
      ? moment(dateString, 'YYYY-MM-DD').toDate()
      : '';

  $mdDateLocaleProvider.formatDate = (date) =>
    date ? moment(date).format('YYYY-MM-DD') : '';

  $mdDateLocaleProvider.firstDayOfWeek = 1;
};

// @ngInject
const angularMaterial = ($mdThemingProvider) => {
  $mdThemingProvider
    .theme('default')
    .primaryPalette('grey', { default: '900' })
    .accentPalette('blue', { default: '500' });
};

// @ngInject
const disableDebugInfo = ($compileProvider, ENV) => {
  if (ENV.name === 'production') {
    $compileProvider.debugInfoEnabled(false);
  }
};

angular
  .module('core.config', [])
  .factory('httpInterceptor', httpInterceptor)
  .config(httpProviderConfig)
  .config(angularDataConfig)
  .config(urlMatcherConfig)
  .config(errorLoggerServiceConfig)
  .config(toasterConfig)
  .config(mdDateConfig)
  .config(angularMaterial)
  .config(disableDebugInfo);
