import {
  useToast,
  useDisclosure,
  Flex,
  Spinner,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { filesApi, Folder } from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { Icons } from '@texas/components/shared/Icons';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateFolderModal } from './CreateFolderModal';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { EditFolderModal } from './EditFolderModal';
import { FileBrowserMode } from './FileBrowserModal';
import { fileEvents } from '../dropzone/events';

export function FolderListItem({
  folderId,
  level,
  mode,
  selectedFolder,
  setSelectedFolder,
  parentFolders,
  setBreadCrumbFolders,
  refetchParent,
}: {
  folderId: number;
  level: number;
  mode: FileBrowserMode;
  selectedFolder: number;
  setSelectedFolder: Dispatch<SetStateAction<number>>;
  parentFolders?: string[];
  setBreadCrumbFolders: Dispatch<SetStateAction<string[]>>;
  refetchParent?: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const breadCrumbFolders = parentFolders ?? [];
  const {
    data: folder,
    loading,
    refetch,
    set,
  } = useApiResource(filesApi.getFolder);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isNewFolderOpen,
    onClose: onNewFolderClose,
    onOpen: onNewFolderOpen,
  } = useDisclosure();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useDisclosure();
  const {
    value: folderValue,
    isOpen: isEditFolderOpen,
    onClose: onEditFolderClose,
    onOpen: onEditFolderOpen,
  } = useValueDisclosure<Folder>();

  const { request: archiveRequest, loading: archiveLoading } = useApiRequest(
    filesApi.updateFolder,
  );

  const onArchive = async () => {
    if (!folder) return;

    await request(
      archiveRequest,
      [folder.id, { name: folder.name, archived: true }],
      () => {
        toast({
          title: t('general.successfullyArchived'),
          status: 'success',
          isClosable: true,
        });
        onArchiveClose();

        if (refetchParent) refetchParent();
      },
      (error: ServerError) => {
        toast({
          title: t('general.archiveFailed'),
          description: <ErrorsList errors={error.errors} />,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  useEffect(() => {
    refetch(folderId);
  }, [refetch, folderId]);

  return (
    <>
      {loading && <Spinner size="sm" m={2} />}
      {folder && (
        <>
          <Flex direction="column" pl={level}>
            <Flex
              gap={2}
              alignItems="center"
              bg={selectedFolder === folder.id ? 'gray.900' : undefined}
              _light={{
                bg: selectedFolder === folder.id ? 'gray.50' : undefined,
              }}
              borderRadius="40px"
              p={1}
              role="group"
            >
              {folder.hasFolders && (
                <>
                  {isOpen ? (
                    <Icons.ChevronDown onClick={onToggle} boxSize={5} />
                  ) : (
                    <Icons.ChevronUp onClick={onToggle} boxSize={5} />
                  )}
                </>
              )}

              <Text
                onClick={() => {
                  setSelectedFolder(folder.id);
                  setBreadCrumbFolders(breadCrumbFolders);
                }}
                pl={folder.hasFolders ? 0 : 4}
                fontWeight={selectedFolder === folder.id ? 'medium' : 'normal'}
                cursor="pointer"
                whiteSpace="nowrap"
              >
                {folder.name}
              </Text>
              <Spacer />
              {archiveLoading && <Spinner />}
              {!archiveLoading && mode === 'Edit' && (
                <Menu placement="right">
                  <MenuButton
                    float="right"
                    aria-label={t('general.more')}
                    size="sm"
                    as={IconButton}
                    variant="ghost"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                  >
                    <Icons.DotsHorizontal boxSize={4} />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      icon={<Icons.Pencil boxSize={5} />}
                      onClick={() => onEditFolderOpen(folder)}
                      isDisabled={!folder.isEditable}
                    >
                      {t('fileBrowser.editFolder')}
                    </MenuItem>
                    <MenuItem
                      icon={<Icons.Plus boxSize={5} />}
                      onClick={onNewFolderOpen}
                      isDisabled={folder.isReadOnly}
                    >
                      {t('fileBrowser.newFolder')}
                    </MenuItem>
                    <MenuItem
                      color="texas.sand.100"
                      icon={<Icons.Archive boxSize={5} />}
                      onClick={() => onArchiveOpen()}
                      isDisabled={!folder.isEditable}
                    >
                      {t('general.archive')}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>

            {folder.hasFolders &&
              isOpen &&
              folder.folders.map((f) => (
                <FolderListItem
                  key={f.id}
                  folderId={f.id}
                  level={level + 1}
                  mode={mode}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  parentFolders={[...breadCrumbFolders, folder.name]}
                  setBreadCrumbFolders={setBreadCrumbFolders}
                  refetchParent={() => refetch(folderId)}
                />
              ))}
          </Flex>
          {folderValue && (
            <EditFolderModal
              folder={folderValue}
              isOpen={isEditFolderOpen}
              onClose={onEditFolderClose}
              onUpdated={(f) => {
                set({ ...folder, name: f.name });
                fileEvents.folderNameChanged.dispatch({
                  id: folder.id,
                  value: f.name,
                });
              }}
            />
          )}
          <CreateFolderModal
            isOpen={isNewFolderOpen}
            onClose={onNewFolderClose}
            parentId={folderId}
            onCreated={(f) => {
              set({
                ...folder,
                folders: [...folder.folders, f],
                hasFolders: true,
              });
            }}
          />
          <VerifyDialogWithFunction
            headerTitle={t('fileBrowser.archiveFolder')}
            secondaryButtonTitle={t('general.cancel')}
            primaryButtonTitle={t('general.confirm')}
            isOpen={isArchiveOpen}
            onClose={onArchiveClose}
            toPerform={async () => {
              await onArchive();
            }}
          >
            {t('alert.areYouSure')}
          </VerifyDialogWithFunction>
        </>
      )}
    </>
  );
}
