import { Flex, Input, Textarea, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  AutoUpdateWrapper,
  useAutoUpdateForm,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import {
  AutoUpdateInfoRequest,
  VariantInfo,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { hasClaims } from '@texas/utils/helpers/claimHelpers';
import { useActiveSession } from '@texas/hooks/useSession';
import { ClaimType } from '@texas/api/endpoints/userApi';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { reactEvents } from 'src/bridge/reactEvents';
import { AutoUpdateResponseCallback } from '@texas/components/shared/autoUpdate/types';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';

export function GeneralInfoForm({ variantInfo }: { variantInfo: VariantInfo }) {
  const { t } = useTranslation();
  const session = useActiveSession();
  const disabled = !hasClaims(
    session.currentUser.claims.map((c) => c.id),
    [
      ClaimType.CompanyAdministrator,
      ClaimType.OrganizationAdministrator,
      ClaimType.SystemAdministrator,
    ],
  );

  function onResponse(
    data: AutoUpdateResponseCallback<VariantInfo, AutoUpdateInfoRequest>,
  ) {
    reactEvents.variantNameChanged.dispatch({
      id: variantInfo.id,
      value: data.mappedServerData.name,
    });
  }

  const {
    registerAutoUpdate,
    form,
    formState,
    watch,
    triggerSubmit,
    register,
  } = useAutoUpdateForm<AutoUpdateInfoRequest, VariantInfo>({
    defaultValue: {
      name: variantInfo.name,
      character: variantInfo.character,
      notes: variantInfo.notes,
    },
    valueId: variantInfo.id,
    namePrefix: 'variantInfo',
    autoUpdateRequest: variantsApi.autoUpdateInfo,
    onResponse,
  });

  return (
    <form {...form}>
      <Flex direction="column" gap={4}>
        <Text variant="header">{t('general.info')}</Text>
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="name"
          boxProps={{ flexGrow: '1' }}
        >
          <TexasFormLabel>{t('general.name')}</TexasFormLabel>
          <Input
            {...register('name', {
              required: true,
              minLength: {
                value: 1,
                message: t('errors.minLength', { count: 1 }),
              },
              maxLength: {
                value: 100,
                message: t('errors.maxLength', { count: 100 }),
              },
              onBlur: triggerSubmit,
            })}
            disabled={disabled}
            borderBottom="1px"
            borderBottomColor="gray"
            borderRadius={0}
            p={1}
            fontSize="md"
          />
          <ErrorLabel text={formState.errors.name?.message} />
        </AutoUpdateWrapper>
        <TexasInputWrapper
          error={formState.errors.notes}
          label={t('general.notes')}
        >
          <AutoUpdateWrapper
            autoUpdateProps={registerAutoUpdate}
            path="notes"
            boxProps={{ flexGrow: '1' }}
          >
            <AutoGrowTextareaContainer value={watch('notes')}>
              <Textarea
                {...register('notes', {
                  maxLength: {
                    value: 4000,
                    message: t('errors.maxLength', { count: 4000 }),
                  },
                  onBlur: triggerSubmit,
                })}
                disabled={disabled}
                minHeight={40}
                height="auto"
                variant="unstyled"
                padding={1}
                placeholder={t('general.notes')}
                width="100%"
              />
            </AutoGrowTextareaContainer>
          </AutoUpdateWrapper>
        </TexasInputWrapper>
      </Flex>
    </form>
  );
}
