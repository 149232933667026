import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useActiveSession } from '@texas/hooks/useSession';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';

export function useProductApprovals() {
  const { data, setData } = useProdutApprovalWizardState();
  const { currentUser } = useActiveSession();
  const [selectedKey, setSelectedKey] = useState<string>('');
  const previousRelevantPaRef = useRef<GeneratedProductApproval | null>();
  const refKeys = useRef(data.generatedProductApprovalKeys);
  const refPas = useRef(data.generatedProductApprovals);

  const { data: compositions, refetch } = useApiResource(
    compositionGroupApi.searchGroup,
  );

  useEffect(() => {
    refetch({
      variantId: data.variantId,
      branchIds: data.selectedBranches.map((x) => x.id),
      supplierIds: data.selectedSuppliers.map((x) => x.id),
    });
  }, [data.selectedBranches, data.selectedSuppliers, data.variantId, refetch]);

  const generateProductApprovals = useCallback(() => {
    if (!compositions) return;

    const productApprovals: Record<string, GeneratedProductApproval> = {};
    const keys: string[] = [];

    for (let k = 0; k < data.selectedSuppliers.length; k++) {
      const supplier = data.selectedSuppliers[k];

      if (nodeGroupIsPopulated(data.articleNodes)) {
        const cellKeys = Object.keys(data.selectedCells);
        for (let i = 0; i < cellKeys.length; i++) {
          const cell = data.selectedCells[cellKeys[i]];

          if (!cell.added) continue;

          const format = `${supplier.id}-${supplier.branch.id}-${cell.x}-${cell.y}`;

          const supplierCellCompositions = compositions.filter(
            (c) =>
              c.supplierId === supplier.id &&
              c.branchId === supplier.branch.id &&
              c.cellX === cell.x &&
              c.cellY === cell.y,
          );

          if (supplierCellCompositions.length === 0) {
            keys.push(format);
            productApprovals[format] = {
              key: format,
              branch: supplier.branch,
              cell: {
                x: cell.x,
                y: cell.y,
                xIndex: cell.xIndex,
                yIndex: cell.yIndex,
                added: true,
              },
              customerContact: null,
              issuedBy: {
                id: currentUser.id,
                name: `${currentUser.firstName} ${currentUser.lastName}`,
              },
              supplier: supplier,
              supplierContact: null,
              supplierExternalItemCode: '',
              supplierNote: '',
              state: GeneratedProductApprovalState.Ignored,
              compositionGroup: null,
            };
            continue;
          }

          supplierCellCompositions.forEach((comp) => {
            const format = `${supplier.id}-${supplier.branch.id}-${cell.x}-${cell.y}-${comp.version}`;

            if (refKeys.current.includes(format)) {
              keys.push(format);
              productApprovals[format] = {
                ...refPas.current[format],
                compositionGroup: comp,
              };
            } else {
              keys.push(format);
              productApprovals[format] = {
                key: format,
                branch: supplier.branch,
                cell: {
                  x: cell.x,
                  y: cell.y,
                  xIndex: cell.xIndex,
                  yIndex: cell.yIndex,
                  added: true,
                },
                customerContact: null,
                issuedBy: {
                  id: currentUser.id,
                  name: `${currentUser.firstName} ${currentUser.lastName}`,
                },
                supplier: supplier,
                supplierContact: null,
                supplierExternalItemCode: '',
                supplierNote: '',
                state: GeneratedProductApprovalState.Unhandled,
                compositionGroup: comp,
              };
            }
          });
        }

        continue;
      }

      const format = `${supplier.id}-${supplier.branch.id}`;

      const supplierCompositions = compositions.filter(
        (c) =>
          c.supplierId === supplier.id && c.branchId === supplier.branch.id,
      );

      if (supplierCompositions.length === 0) {
        keys.push(format);
        productApprovals[format] = {
          key: format,
          branch: supplier.branch,
          cell: null,
          customerContact: null,
          issuedBy: {
            id: currentUser.id,
            name: `${currentUser.firstName} ${currentUser.lastName}`,
          },
          supplier: supplier,
          supplierContact: null,
          supplierExternalItemCode: '',
          supplierNote: '',
          state: GeneratedProductApprovalState.Ignored,
          compositionGroup: null,
        };
        continue;
      }

      supplierCompositions.forEach((comp) => {
        const format = `${supplier.id}-${supplier.branch.id}-${comp.version}`;

        if (refKeys.current.includes(format)) {
          keys.push(format);
          productApprovals[format] = {
            ...refPas.current[format],
            compositionGroup: comp,
          };
        } else {
          keys.push(format);
          productApprovals[format] = {
            key: format,
            branch: supplier.branch,
            cell: null,
            customerContact: null,
            issuedBy: {
              id: currentUser.id,
              name: `${currentUser.firstName} ${currentUser.lastName}`,
            },
            supplier: supplier,
            supplierContact: null,
            supplierExternalItemCode: '',
            supplierNote: '',
            state: GeneratedProductApprovalState.Unhandled,
            compositionGroup: comp,
          };
        }
      });
    }

    if (keys.length > 0) {
      setSelectedKey(keys[0]);
    }

    refKeys.current = keys;
    refPas.current = productApprovals;
    setData((d) => ({
      ...d,
      generatedProductApprovals: productApprovals,
      generatedProductApprovalKeys: keys,
    }));
  }, [
    compositions,
    setData,
    data.selectedSuppliers,
    data.articleNodes,
    data.selectedCells,
    currentUser.id,
    currentUser.firstName,
    currentUser.lastName,
  ]);

  useEffect(() => {
    generateProductApprovals();
  }, [generateProductApprovals]);

  useEffect(() => {
    refKeys.current = data.generatedProductApprovalKeys;
    refPas.current = data.generatedProductApprovals;
  }, [data.generatedProductApprovalKeys, data.generatedProductApprovals]);

  return {
    selectedKey,
    setSelectedKey,
    previousRelevantPaRef,
  };
}
