import { TabPanel } from '@chakra-ui/react';
import { Step } from './types';
import { StepContent } from './StepContent';

export function StepTabPanel({ step }: { step: Step }) {
  return (
    <TabPanel
      px={0}
      borderBottom={step.last ? 'none' : '1px solid'}
      borderBottomColor="gray.600"
      _light={{ borderBottomColor: 'gray.100' }}
    >
      <StepContent>{step.config.content}</StepContent>
    </TabPanel>
  );
}
