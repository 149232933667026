import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function NewSupplierCell({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <Button variant="texas-outline" borderStyle="dashed" onClick={onClick}>
      {t('suppliers.addSupplier')}
    </Button>
  );
}
