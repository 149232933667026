import { useDroppable } from '@dnd-kit/core';
import { useDragAndDropState } from './useDragAndDrop';
import { useEffect, useMemo } from 'react';
import { dragAndDropEvents } from './events';

export function useCustomDroppable({
  id,
  data,
  condition,
  onDrop,
}: {
  id: string;
  data: any | undefined;
  condition: (data: any | null) => boolean;
  onDrop: (data: any) => void;
}) {
  const { draggingData } = useDragAndDropState();
  const { setNodeRef, isOver } = useDroppable({
    id: id,
    data: data,
  });

  const validDropZone = useMemo(
    () => condition(draggingData),
    [condition, draggingData],
  );

  useEffect(() => {
    return dragAndDropEvents.onDrop.sub((event) => {
      if (event.over?.id !== id || !validDropZone) return;
      onDrop(event.active.data.current);
    });
  }, [id, onDrop, validDropZone]);

  return { setNodeRef, isOver, validDropZone, isDragging: !!draggingData };
}
