import { Box, Flex, Tab, Text } from '@chakra-ui/react';
import { Step } from './types';
import { blinkAnimation } from '@texas/resources/animations/animations';
import { useProdutApprovalWizardState } from '../context';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../types';

export function StepTab({ step, zIndex }: { step: Step; zIndex: number }) {
  const state = step.state;
  const { data } = useProdutApprovalWizardState();

  return (
    <Flex align="center" zIndex={zIndex}>
      <Tab
        bg={state.isValid || state.isActive ? 'gray.600' : 'gray.800'}
        _light={{
          bg: state.isValid || state.isActive ? 'gray.100' : 'gray.50',
        }}
        flexDirection="column"
      >
        <Text
          pl="14px"
          fontWeight="bold"
          whiteSpace="nowrap"
          color={state.isValid || state.isActive ? 'white' : 'gray.300'}
          _hover={{
            color: state.isActive ? 'white' : 'gray.200',
            _light: { color: state.isActive ? 'texas.bg.900' : 'gray.600' },
          }}
          _light={{
            color:
              state.isValid || state.isActive ? 'texas.bg.900' : 'gray.400',
          }}
          animation={
            step.last &&
            !step.state.isActive &&
            data.generatedProductApprovalKeys.length > 0 &&
            allHandled(
              data.generatedProductApprovals,
              data.generatedProductApprovalKeys,
            )
              ? blinkAnimation()
              : 'none'
          }
        >
          {step.config.label}
        </Text>
      </Tab>
      {!step.last && (
        <Box
          borderTop="1rem solid transparent"
          borderBottom="1rem solid transparent"
          borderLeft="1rem solid"
          borderLeftColor={
            state.isValid || state.isActive ? 'gray.600' : 'gray.800'
          }
          _light={{
            borderLeftColor:
              state.isValid || state.isActive ? 'gray.100' : 'gray.50',
          }}
        />
      )}
    </Flex>
  );
}

function allHandled(
  productApprovals: Record<string, GeneratedProductApproval>,
  keys: string[],
) {
  return keys
    .map((k) => productApprovals[k])
    .every((p) => p.state !== GeneratedProductApprovalState.Unhandled);
}
