import { httpClient } from '../httpClient/httpClient';

export interface Branch {
  identifier: string;
  id: number;
  name: string;
  defaultCurrencyId: number;
}

export interface CreateBranchRequest {
  identifier: string;
  name: string;
  displayOrder: number;
}

export const branchesApi = {
  getBranches: () => httpClient.get<Branch[]>(`organizations/3/branches`),
  getAvailableBranchesForCreation: () =>
    httpClient.get<string[]>(`branches/available`),
  createBranch: (request: CreateBranchRequest) =>
    httpClient.post<Branch>(`organizations/3/branches`, { data: request }),
};
