import { SetStateAction, useCallback, useRef, useState } from 'react';
import { ServerError } from '../../types';
import { HttpClientResponse } from '../httpClient/types';

export interface ApiResource<A extends any[], R> {
  data: R | null;
  refetch: (...args: A) => Promise<void>;
  set: (value: SetStateAction<R | null>) => void;
  loading: boolean;
  error: ServerError | null;
}

export function useApiResource<A extends any[], R>(
  apiRequest: (...args: A) => Promise<HttpClientResponse<R>>,
): ApiResource<A, R> {
  const latestRequestSymbol = useRef<symbol>();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<ServerError | null>(null);

  const set = useCallback((value: SetStateAction<R | null>) => {
    setData(value);
    setError(null);
    setLoading(false);
    latestRequestSymbol.current = undefined;
  }, []);

  const refetch = useCallback(
    async (...args: A) => {
      setLoading(true);

      const currentRequestSymbol = Symbol();
      latestRequestSymbol.current = currentRequestSymbol;

      const response = await apiRequest(...args);

      if (currentRequestSymbol !== latestRequestSymbol.current) {
        return;
      }

      if (response.hasError) {
        setError(response.error);
        setData(null);
      } else {
        setError(null);
        setData(response.data);
      }

      setLoading(false);
    },
    [apiRequest],
  );

  return { data, refetch, set, loading, error };
}
