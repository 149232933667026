import { List, ListItem } from '@chakra-ui/react';

interface ErrorItem {
  message: string;
}

export function ErrorsList({ errors }: { errors: any }) {
  if (!Array.isArray(errors) || errors.length === 0) {
    return null;
  }

  return (
    <List>
      {errors.map((e, i) => (
        <ListItem key={i}>{DisplayError(e)}</ListItem>
      ))}
    </List>
  );
}

function DisplayError(error: string | ErrorItem) {
  if (typeof error === 'object') {
    return error.message;
  }

  return error;
}
