// @ngInject
const uploadPreventDrop = () => ({
  scope: false,

  link: function linkFn(scope, element) {
    element.bind('drop dragover', (event) => {
      event.preventDefault();
    });
  },
});

angular
  .module('uploadPreventDrop', [])
  .directive('uploadPreventDrop', uploadPreventDrop);
