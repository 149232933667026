const link = (scope, element, attrs, mdAutocompleteCtrl) => {
  if (!attrs.mdSelectedItem) {
    return;
  }

  const originalBlurFn = mdAutocompleteCtrl.blur;
  mdAutocompleteCtrl.blur = () => {
    originalBlurFn();
    if (!mdAutocompleteCtrl.scope.selectedItem && mdAutocompleteCtrl.hidden) {
      mdAutocompleteCtrl.scope.searchText = null;
    }
  };
};

const clearSearch = () => ({
  restrict: 'A',
  scope: false,
  require: 'mdAutocomplete',
  link,
});

angular
  .module('directives.clearSearch', ['material.components.autocomplete'])
  .directive('clearSearch', clearSearch);
