// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.deliveryMethods', {
    url: '/deliverymethods',
    controller: 'DeliveryMethodsConfigurationController as vm',
    templateUrl: 'main/configuration/deliveryMethods/deliveryMethods.tpl.html',
    data: { pageTitle: 'Delivery terms' },
  });
};

class DeliveryMethodsConfigurationController {
  constructor(
    $scope,
    DS,
    configurationOptions,
    loadSpinnerService,
    DeliveryMethodsApiService,
    ConfigItemDialogService,
    currentOrganization,
  ) {
    'ngInject';

    this._ConfigItemDialogService = ConfigItemDialogService;
    this._configurationOptions = configurationOptions;
    this._loadSpinnerService = loadSpinnerService;
    this._DeliveryMethodsApiService = DeliveryMethodsApiService;
    this.selectedBranch = {};
    this.branches = currentOrganization.branches;
    this.deliveryMethods = [];
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    this.onSelectBranch(configurationOptions.selectedBranch);

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('deliveryMethod');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.deliveryMethods = DS.filter('deliveryMethod', {
            where: {
              branchId: {
                '==': this.selectedBranch.id,
              },
            },
          });
        }
      },
    );
  }

  onSelectBranch(branch) {
    this._configurationOptions.selectedBranch = branch || this.branches[0];
    this.selectedBranch = branch || this.branches[0];
    this.loadDeliveryMethods();
  }

  loadDeliveryMethods() {
    this._loadSpinnerService.start('mainSpinner');
    this._DeliveryMethodsApiService
      .getAllByBranchId(this.selectedBranch.id)
      .then((deliveryMethods) => {
        this.deliveryMethods = deliveryMethods;
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }

  openDeliveryMethod(deliveryMethod) {
    this._ConfigItemDialogService.showDialog({
      configItem: deliveryMethod,
      displayOrder: true,
      identifier: true,
      onSave: (deliveryMethod) =>
        this._DeliveryMethodsApiService.updateDeliveryMethod(
          _.pick(deliveryMethod, [
            'name',
            'identifier',
            'displayOrder',
            'archived',
          ]),
          deliveryMethod.id,
        ),
    });
  }

  newDeliveryMethod() {
    this._ConfigItemDialogService.showDialog({
      displayOrder: true,
      identifier: true,
      onSave: (deliveryMethod) =>
        this._DeliveryMethodsApiService.newDeliveryMethod(
          deliveryMethod,
          this.selectedBranch.id,
        ),
    });
  }
}

angular
  .module('configuration.deliveryMethods', [])
  .config(config)
  .controller(
    'DeliveryMethodsConfigurationController',
    DeliveryMethodsConfigurationController,
  );
