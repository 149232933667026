import { Box, Grid, Tooltip, useColorMode } from '@chakra-ui/react';
import { ArticleProcess } from '@texas/api/endpoints/searchApi';
import { getEnumValues } from '@texas/utils/helpers/enumHelpers';

const articleProcesses = getEnumValues(ArticleProcess);

export function StepsIndicator({
  currentStep,
  height,
}: {
  currentStep: ArticleProcess;
  height?: number;
}) {
  const { colorMode } = useColorMode();
  const intermediateSteps = articleProcesses.slice(0, -1);
  const stepColor =
    colorMode === 'dark' ? 'texas.sand.100' : 'texas.burntSienna.500';
  const currentStepColor = colorMode === 'dark' ? 'gray.200' : 'gray.300';
  const bgColor = colorMode === 'dark' ? 'gray.500' : 'gray.100';

  return (
    <Tooltip label={ArticleProcess[currentStep]}>
      <Grid
        gridTemplateColumns={`repeat(${intermediateSteps.length}, 1fr)`}
        gridColumnGap="1px"
        borderRadius={4}
        height={height ?? 4}
        overflow="hidden"
      >
        {intermediateSteps.map((v) => {
          return (
            <Box
              key={v}
              backgroundColor={
                v === currentStep
                  ? currentStepColor
                  : v < currentStep
                  ? stepColor
                  : bgColor
              }
              border="1px solid"
              borderColor={v === currentStep ? currentStepColor : 'transparent'}
            />
          );
        })}
      </Grid>
    </Tooltip>
  );
}
