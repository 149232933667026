const disableWhileEditing = () => ({
  restrict: 'A',
  scope: false,
  controller: 'DisableWhileEditingController',
});

class DisableWhileEditingController {
  constructor($scope, $element, $compile, SavingService) {
    'ngInject';
    this.SavingService = SavingService;
    this.$element = $element;
    this.$scope = $scope;

    let overlay = angular.element(
      "<div class='disabling-overlay'><md-tooltip>Disabled while editing</md-tooltip></div>",
    );
    overlay = $compile(overlay)($scope);
    $element.append(overlay);
    $element.css('position', 'relative');
    $element.on('click', (event) => this.onClick(event));

    $scope.$watch(
      () => {
        return this.SavingService.hasChanges();
      },
      (newValue) => {
        if (newValue) {
          overlay.css('display', 'block');
          $element.css('opacity', '.5');
        } else {
          $element.css('opacity', '1');
          overlay.css('display', 'none');
        }
      },
    );
  }

  onClick(event) {
    if (this.SavingService.hasChanges()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.SavingService.notifyUser();
    }
  }
}

angular
  .module('directives.disableWhileEditing', [])
  .controller('DisableWhileEditingController', DisableWhileEditingController)
  .directive('disableWhileEditing', disableWhileEditing);
