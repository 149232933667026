import {
  Text,
  Flex,
  GridItem,
  Grid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { useEffect, useRef, useState } from 'react';
import { MatrixView } from './matrix/MatrixView';
import {
  formatNodeGroup,
  nodeGroupIsPopulated,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { useTranslation } from 'react-i18next';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import {
  compositionGroupApi,
  CompositionGroupOverview,
} from '@texas/api/endpoints/compositionGroupApi';
import { OptionsOverview } from './optionOverview/OptionsOverview';
import { VersionsGridOverview } from './versionOverview/VersionsGridOverview';
import React from 'react';
import { compositionGroupEvents } from './shared/events';
import { DragAndDrop } from '@texas/components/shared/dragAndDrop/DragAndDrop';

interface CompositionsProps {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}

const optionsRowSize = '280px';
const rowOffset = 2;

export function Compositions({
  variantId,
  articleNodeGroup,
}: CompositionsProps) {
  return (
    <BodyOverride>
      <Box pb={32}>
        {!nodeGroupIsPopulated(articleNodeGroup) ? (
          <StandardComposition variantId={variantId} />
        ) : (
          <MatrixComposition
            variantId={variantId}
            articleNodeGroup={articleNodeGroup}
          />
        )}
      </Box>
    </BodyOverride>
  );
}

function StandardComposition({ variantId }: { variantId: number }) {
  const { t } = useTranslation();
  const {
    data: suppliers,
    refetch: refetchSuppliers,
    loading,
  } = useApiResource(variantsApi.getVariantBranchSuppliers);

  const [options, setOptions] = useState<CompositionGroupOverview[]>([]);

  useEffect(() => {
    refetchSuppliers(variantId, false);
  }, [refetchSuppliers, variantId]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <DragAndDrop dndProps={{ autoScroll: false }}>
      <Tabs variant="line" isLazy={true}>
        <Grid
          gridTemplateRows="auto 1fr"
          gridTemplateColumns={`${optionsRowSize} 1fr`}
          columnGap={8}
        >
          <GridItem gridRow={1} gridColumn={2}>
            <TabList>
              {suppliers?.branches.map((x) => {
                return (
                  <Tab key={x.id}>
                    <Flex flexDir="column" align="start">
                      <Text variant="tab-header">{x.identifier}</Text>
                      <Text variant="sub">{x.name}</Text>
                    </Flex>
                  </Tab>
                );
              })}
            </TabList>
          </GridItem>
          <GridItem gridRow={2} gridColumn={1} padding={4}>
            <OptionsOverview
              variantId={variantId}
              onLoaded={(options) => setOptions(options)}
            />
          </GridItem>
          <GridItem gridRow={2} gridColumn={2} overflow="auto">
            <TabPanels>
              {suppliers?.branches.map((x) => {
                const nrOfSuppliers = suppliers.suppliers.filter(
                  (s) => s.branchId === x.id,
                ).length;
                return (
                  <TabPanel key={x.id}>
                    <Grid
                      pos="relative"
                      ref={ref}
                      rowGap={6}
                      columnGap={2}
                      gridTemplateRows={`repeat(${
                        rowOffset + options.length
                      }, auto)`}
                      gridTemplateColumns={`30px repeat(${nrOfSuppliers}, 300px)`}
                    >
                      {options.map((o, i) => {
                        const row = rowOffset + i;

                        return (
                          <GridItem
                            as={Flex}
                            key={o.id}
                            colStart={1}
                            colEnd={nrOfSuppliers + rowOffset}
                            rowStart={row}
                            alignItems="center"
                          >
                            <Text
                              variant="sub"
                              sx={{ writingMode: 'vertical-lr' }}
                            >
                              {t('composition.optionNr', { nr: o.version })}
                            </Text>
                            <Flex
                              alignItems="center"
                              padding={4}
                              bg="texas.bg.950"
                              _light={{ bg: 'gray.80' }}
                              h="full"
                              flexGrow={1}
                            >
                              {nrOfSuppliers === 0 && !loading && (
                                <Text variant="sub">
                                  {t('suppliers.noSuppliers')}
                                </Text>
                              )}
                            </Flex>
                          </GridItem>
                        );
                      })}

                      {suppliers.suppliers
                        .filter((s) => s.branchId === x.id)
                        .map((s, i) => {
                          const col = i + rowOffset;
                          return (
                            <React.Fragment key={s.supplierId}>
                              <VersionsGridOverview
                                supplierName={s.supplierName}
                                gridRef={ref}
                                options={options}
                                variantId={variantId}
                                supplierId={s.supplierId}
                                branchId={s.branchId}
                                col={col}
                              />
                            </React.Fragment>
                          );
                        })}
                    </Grid>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </GridItem>
        </Grid>
      </Tabs>
    </DragAndDrop>
  );
}

function MatrixComposition({
  variantId,
  articleNodeGroup,
}: {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  const { data: suppliers, refetch: refetchSuppliers } = useApiResource(
    variantsApi.getVariantBranchSuppliers,
  );

  useEffect(() => {
    refetchSuppliers(variantId, false);
  }, [refetchSuppliers, variantId]);
  const [selectedCell, setSelectedCell] = useState<
    [number, number, number, number] | null
  >(null);
  const { t } = useTranslation();
  const { data, refetch, loading } = useApiResource(
    compositionGroupApi.getAllCellGroups,
  );
  const ref = useRef<HTMLDivElement>(null);

  const [options, setOptions] = useState<CompositionGroupOverview[]>([]);
  const [cellOptions, setCellOptions] = useState<CompositionGroupOverview[]>(
    [],
  );

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  useEffect(() => {
    return compositionGroupEvents.compositionGroupsChanged.sub(() => {
      refetch(variantId);
    });
  }, [refetch, variantId]);

  useEffect(() => {
    if (!selectedCell) return;
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedCell]);

  return (
    <DragAndDrop dndProps={{ autoScroll: false }}>
      <>
        <Box bg="texas.bg.980" _light={{ bg: 'gray.50' }} mb={6}>
          <MatrixView
            cells={data}
            refetchCells={() => refetch(variantId)}
            cellsLoading={loading}
            selectedCell={selectedCell}
            onCellSelected={(cell) => {
              setSelectedCell(cell);
            }}
            articleNodeGroup={articleNodeGroup}
            variantId={variantId}
          />
        </Box>

        <Tabs variant="line" isLazy={true} ref={ref}>
          <Grid
            gridTemplateRows="auto 1fr"
            gridTemplateColumns={`${optionsRowSize} 1fr`}
            columnGap={8}
          >
            <GridItem gridRow={1} gridColumn={2}>
              <TabList>
                {suppliers?.branches.map((x) => {
                  return (
                    <Tab key={x.id}>
                      <Flex flexDir="column" align="start">
                        <Text variant="tab-header">{x.identifier}</Text>
                        <Text variant="sub">{x.name}</Text>
                      </Flex>
                    </Tab>
                  );
                })}
              </TabList>
            </GridItem>
            <GridItem gridRow={2} gridColumn={1} padding={4}>
              <Flex flexDir="column" gap={4}>
                <OptionsOverview
                  variantId={variantId}
                  onLoaded={(options) => setOptions(options)}
                />
                {selectedCell && (
                  <OptionsOverview
                    label={formatNodeGroup(
                      articleNodeGroup.nodeXValues[selectedCell[2]],
                      articleNodeGroup.nodeYValues[selectedCell[3]],
                      true,
                    )}
                    variantId={variantId}
                    cellX={selectedCell[0]}
                    cellY={selectedCell[1]}
                    baseOptions={options}
                    onLoaded={(options) => setCellOptions(options)}
                  />
                )}
              </Flex>
            </GridItem>
            <GridItem gridRow={2} gridColumn={2} overflow="auto">
              <TabPanels>
                {suppliers?.branches.map((x) => {
                  const nrOfSuppliers = suppliers.suppliers.filter(
                    (s) => s.branchId === x.id,
                  ).length;
                  return (
                    <TabPanel key={x.id}>
                      <Grid
                        pos="relative"
                        ref={ref}
                        rowGap={6}
                        columnGap={2}
                        gridTemplateRows={`repeat(${
                          rowOffset + cellOptions.length
                        }, auto)`}
                        gridTemplateColumns={`30px repeat(${nrOfSuppliers}, 300px)`}
                      >
                        {cellOptions.map((o, i) => {
                          const row = rowOffset + i;

                          return (
                            <GridItem
                              as={Flex}
                              key={o.id}
                              colStart={1}
                              colEnd={nrOfSuppliers + rowOffset}
                              rowStart={row}
                              alignItems="center"
                            >
                              <Text
                                variant="sub"
                                sx={{ writingMode: 'vertical-lr' }}
                              >
                                {t('composition.cellOptionNr', {
                                  nr: o.version,
                                })}
                              </Text>
                              <Box
                                bg="texas.bg.950"
                                _light={{ bg: 'gray.80' }}
                                h="full"
                                flexGrow={1}
                              />
                            </GridItem>
                          );
                        })}
                        {selectedCell && (
                          <>
                            {suppliers.suppliers
                              .filter((s) => s.branchId === x.id)
                              .map((s, i) => {
                                const col = i + 2;
                                return (
                                  <React.Fragment key={s.supplierId}>
                                    <VersionsGridOverview
                                      supplierName={s.supplierName}
                                      gridRef={ref}
                                      options={cellOptions}
                                      variantId={variantId}
                                      supplierId={s.supplierId}
                                      cellX={selectedCell[0]}
                                      cellY={selectedCell[1]}
                                      branchId={s.branchId}
                                      col={col}
                                    />
                                  </React.Fragment>
                                );
                              })}
                          </>
                        )}
                      </Grid>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </GridItem>
          </Grid>
        </Tabs>
      </>
    </DragAndDrop>
  );
}
