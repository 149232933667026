import { ReactSelectOption } from '@texas/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export enum Character {
  None = 0,
  CentreFold = 1,
  EndFold = 2,
  LoopFold = 3,
  ManhattanFold = 4,
  MitreFold = 5,
  StraightCut = 6,
  DieCut = 7,
}

export function GetCharacterOptions(): ReactSelectOption[] {
  return [
    { label: 'None', value: 0 },
    { label: 'Centre fold', value: 1 },
    { label: 'End fold', value: 2 },
    { label: 'Loop fold', value: 3 },
    { label: 'Manhattan fold', value: 4 },
    { label: 'Mitre fold', value: 5 },
    { label: 'Straight cut', value: 6 },
    { label: 'Die cut', value: 7 },
  ];
}

export function getCharacterLabel(character: Character) {
  return GetCharacterOptions().find(
    (x) => convertToEnum(Character, x.value) === character,
  )?.label;
}

export function GetCharacterOption(character: Character): ReactSelectOption {
  if ((Character[character] as any) === Character.CentreFold)
    return { label: 'Centre fold', value: 1 };
  if ((Character[character] as any) === Character.EndFold)
    return { label: 'End fold', value: 2 };
  if ((Character[character] as any) === Character.LoopFold)
    return { label: 'Loop fold', value: 3 };
  if ((Character[character] as any) === Character.ManhattanFold)
    return { label: 'Manhattan fold', value: 4 };
  if ((Character[character] as any) === Character.MitreFold)
    return { label: 'Mitre fold', value: 5 };
  if ((Character[character] as any) === Character.StraightCut)
    return { label: 'Straight cut', value: 6 };
  if ((Character[character] as any) === Character.DieCut)
    return { label: 'Die cut', value: 7 };

  return { label: 'None', value: 0 };
}
