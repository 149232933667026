class LoginDialogService {
  constructor($mdDialog) {
    'ngInject';
    this.$mdDialog = $mdDialog;
  }

  showDialog(options) {
    const user = options.user;

    return this.$mdDialog.show({
      skipHide: true,
      escapeToClose: false,
      customSpinnerName: 'loginDialogSpinner',
      controller: 'LoginDialogController as vm',
      templateUrl: 'services/dialogs/loginDialog/loginDialog.tpl.html',
      locals: { user },
    });
  }
}

class LoginDialogController {
  constructor($mdDialog, user, AuthApiService, AuthService) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.AuthApiService = AuthApiService;
    this.AuthService = AuthService;
    this.lockedEmail = user || false;
    this.user = user || {};
    this.validationErrors = [];
  }

  login() {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner('loginDialogSpinner');

    this.AuthApiService.login(this.user.email, this.user.password).then(
      () => {
        this.$mdDialog.hide();
      },
      (error) => {
        this.$mdDialog.stopLoadingSpinner('loginDialogSpinner');
        this.validationErrors.push(error);
      },
    );
  }

  changeUser() {
    this.AuthService.logout();
  }
}

angular
  .module('services.dialogs.loginDialog', ['autofill-directive'])
  .service('LoginDialogService', LoginDialogService)
  .controller('LoginDialogController', LoginDialogController);
