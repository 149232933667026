import { RefObject } from 'react';

export function menuButtonProps(
  ref: RefObject<HTMLDivElement>,
  supplierId: number,
  isDark: boolean,
) {
  return {
    onMouseEnter: () => {
      ref.current?.style.setProperty(
        `--supplier-${supplierId}-bg`,
        isDark ? 'white' : 'black',
      );
      ref.current?.style.setProperty(
        `--supplier-${supplierId}-border`,
        isDark ? '1px solid white' : '1px solid black',
      );
    },
    onMouseLeave: () => {
      ref.current?.style.removeProperty(`--supplier-${supplierId}-bg`);
      ref.current?.style.removeProperty(`--supplier-${supplierId}-border`);
    },
  };
}
