import { FileLink } from '@texas/api/endpoints/filesApi';
import { SignalDispatcher } from 'ste-signals';
import { SimpleEventDispatcher } from 'ste-simple-events';

export interface UpdateValue {
  id: number;
  value: string;
}

export const fileEvents = {
  folderNameChanged: new SimpleEventDispatcher<UpdateValue>(),
  fileUploadComplete: new SimpleEventDispatcher<FileLink>(),
  fileChanged: new SimpleEventDispatcher<FileLink>(),
  fileDeleted: new SignalDispatcher(),
};
