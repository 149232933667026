// @ngInject
const uploadButton = (UploaderService, $document, FilesApiService) => ({
  restrict: 'EA',
  scope: false,
  require: '^uploadInstance',

  link: function linkFn(scope, element, attrs, uploadInstanceCtrl) {
    const instance = uploadInstanceCtrl.instance;
    const input = angular.element('<input>');

    input.attr('type', 'file');

    if (attrs.uploadButton === 'folder') {
      input.attr('webkitdirectory', 'webkitdirectory');
    }

    if (!instance.single) {
      input.attr('multiple', 'multiple');
    }

    // display:none - not working in opera 12
    input.css('display', 'none');
    input.css('visibility', 'hidden');
    input.css('position', 'absolute');

    input.bind('click', (e) => {
      e.stopPropagation();
    });

    input.bind('change', (e) => {
      uploadFiles(e);
    });

    async function uploadFiles(event) {
      if (attrs.uploadButton === 'folder') {
        const folders = {
          parentFolder: JSON.parse(attrs.parentFolder),
          childFolders: [],
        };
        const files = [...event.target.files];
        _.each(
          files,
          (file) => (file.path = _.split(file.webkitRelativePath, '/')),
        );
        const totalDepth = _.max(_.map(files, (f) => f.path.length));

        for (let i = 1; i < totalDepth; i++) {
          const currentDepthFiles = _.filter(
            files,
            (f) => f.path.length === i + 1,
          );
          const currentDepthFolderNames = _.uniq(
            _.map(currentDepthFiles, (f) => f.path[i - 1]),
          );

          for (const folderName of currentDepthFolderNames) {
            const folderFiles = _.filter(
              currentDepthFiles,
              (f) => f.path[i - 1] === folderName,
            );
            const currentfolder = { name: folderName };
            const currentParentFolder =
              i === 1
                ? folders.parentFolder
                : _.find(
                    folders.childFolders,
                    (f) => f.name === folderFiles[0].path[i - 2],
                  );

            if (currentParentFolder) {
              await FilesApiService.newFolder(
                currentfolder,
                currentParentFolder,
              ).then(
                (newFolder) => {
                  instance.finalizeUrl = () => `folders/${newFolder.id}/files`;
                  UploaderService.addFiles(folderFiles, {
                    instance: instance.name,
                  });
                  folders.childFolders.push(newFolder);
                },
                (error) => {
                  this.validationErrors = error.errors;
                },
              );
            }
          }
        }
      } else {
        UploaderService.addFiles(event.target.files, {
          instance: instance.name,
        });
      }
      event.target.value = '';
    }

    $document.find('body').eq(0).append(input);

    element.bind('click', () => {
      input[0].click();
    });

    scope.$watch(
      () => {
        return instance.disabled;
      },
      (value) => {
        if (value) {
          element.attr('disabled', 'disabled');
        } else {
          element.removeAttr('disabled');
        }
      },
    );

    element.on('$destroy', () => {
      input.remove();
    });
  },
});

angular.module('uploadButton', []).directive('uploadButton', uploadButton);
