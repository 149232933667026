// @ngInject
const assignedUserReminder = () => ({
  templateUrl: 'directives/assignedUserReminder/assignedUserReminder.tpl.html',
  restrict: 'E',
  replace: true,
  scope: {},

  bindToController: {
    taskId: '=',
    assignedUser: '=',
    currentUser: '=',
  },

  controller: 'AssignedUserReminderController',
  controllerAs: 'vm',
});

class AssignedUserReminderController {
  constructor(loadSpinnerService, NotificationsApiService, toasterService) {
    'ngInject';
    this._NotificationsApiService = NotificationsApiService;
    this._loadSpinnerService = loadSpinnerService;
    this._toasterService = toasterService;
  }

  sendReminder() {
    this._loadSpinnerService.start('mainSpinner');
    this._NotificationsApiService
      .notifyAssignedUser(
        this.taskId,
        this.currentUser.id,
        this.assignedUser.userId,
      )
      .then(() => {
        this._toasterService.success();
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }
}

angular
  .module('directives.assignedUserReminder', [])
  .controller('AssignedUserReminderController', AssignedUserReminderController)
  .directive('assignedUserReminder', assignedUserReminder);
