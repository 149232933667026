// @ngInject
const TasksApiService = ($q, DS, DSHttpAdapter, ResourcesService) => ({
  getById: (taskId, options) =>
    ResourcesService.getById('task', taskId, options),

  getAllByUserId: (
    userId,
    {
      declined = false,
      page = 1,
      limit = 20,
      articleId = 0,
      completed = false,
    } = {
      declined: false,
      page: 1,
      limit: 20,
      articleId: 0,
      completed: false,
    },
  ) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}users/${userId}/tasks` +
        `${declined ? '/declined' : ''}` +
        `?page=${page}` +
        `&limit=${limit}` +
        `&articleId=${articleId}` +
        `&completed=${completed}`,
    ).then(
      ({ data }) => ({
        ...data,
        items: DS.inject('task', data.items),
      }),
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getCreatedByUserId: (
    userId,
    { page = 1, limit = 20, articleId = 0, assignedUserId = 0 } = {
      page: 1,
      limit: 20,
      articleId: 0,
      assignedUserId: 0,
    },
  ) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}users/${userId}/tasks/created` +
        `?page=${page}` +
        `&limit=${limit}` +
        `&articleId=${articleId}` +
        `&assignedUserId=${assignedUserId}`,
    ).then(
      ({ data }) => ({
        ...data,
        items: DS.inject('task', data.items),
      }),
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getAllByArticleId: (articleId, options = {}) =>
    ResourcesService.getAll(
      'task',
      Object.assign({}, options, {
        endpoint: `/articles/${articleId}/tasks${
          options.completed ? '/completed' : ''
        }`,
      }),
    ),

  getAllByConceptId: (conceptId, options = {}) =>
    ResourcesService.getAll(
      'task',
      Object.assign({}, options, {
        endpoint: `/concepts/${conceptId}/tasks${
          options.completed ? '/completed' : ''
        }`,
      }),
    ),

  getAllByCustomerId: (customerId, options = {}) =>
    ResourcesService.getAll(
      'task',
      Object.assign({}, options, {
        endpoint: `/customers/${customerId}/tasks${
          options.completed ? '/completed' : ''
        }`,
      }),
    ),

  newTaskWithArticleId: (task, articleId, options = {}) =>
    ResourcesService.create(
      'task',
      task,
      Object.assign({}, options, {
        endpoint: `/articles/${articleId}/tasks`,
      }),
    ),

  newTaskWithConceptId: (task, conceptId, options = {}) =>
    ResourcesService.create(
      'task',
      task,
      Object.assign({}, options, {
        endpoint: `/concepts/${conceptId}/tasks`,
      }),
    ),

  newTaskWithCustomerId: (task, customerId, options = {}) =>
    ResourcesService.create(
      'task',
      task,
      Object.assign({}, options, {
        endpoint: `/customers/${customerId}/tasks`,
      }),
    ),

  newTaskWithUserId: (task, userId, options = {}) =>
    ResourcesService.create(
      'task',
      task,
      Object.assign({}, options, {
        endpoint: `/users/${userId}/tasks`,
      }),
    ),

  updateTask: (taskId, data, options = {}) => {
    const content = _.extend({}, data);

    if (data.assignedUsers) {
      content.assignedUsers = _.map(
        data.assignedUsers,
        _.partialRight(_.pick, 'userId'),
      );
    }

    return ResourcesService.update(
      'task',
      taskId,
      content,
      Object.assign({}, options, {
        updatedFields: [
          'assignedUsers',
          'description',
          'doneDate',
          'dueDate',
          'isDone',
          'title',
        ],
      }),
    );
  },

  updateAssignedUser: (taskId, userId, data) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}tasks/${taskId}/assignedUsers/${userId}`,
      data,
    ).then(
      (res) => {
        const task = DS.get('task', taskId);
        const user = _.find(task.assignedUsers, {
          userId,
        });
        if (user) {
          user.confirmation = res.data.confirmation;
          user.confirmationDate = res.data.confirmationDate;
          user.confirmationMessage = res.data.confirmationMessage;
          user.updated = res.data.updated;
        }
        // Trigger change
        task.set('assignedUsers', task.assignedUsers);
        return res;
      },
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  deleteTask: (task, options) =>
    ResourcesService.destroy('task', task, options),

  getUnconfirmedCountByUserId: (userId) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}users/${userId}/unconfirmedtaskcount`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),
});

angular
  .module('resources.tasks', [])
  .factory('TasksApiService', TasksApiService);
