// @ngInject
const ExcelApiService = ($q, DS, ResourcesService, DSHttpAdapter) => ({
  generateExcel: (data, excelType) =>
    DSHttpAdapter.POST(`${DS.defaults.basePath}excel/${excelType}`, data).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getExcelUrl: (excelId) => {
    return `${DS.defaults.basePath}excel/${excelId}`;
  },
});

angular
  .module('resources.excel', [])
  .factory('ExcelApiService', ExcelApiService);
