angular.module('app.vendor', [
  'ngCookies',
  'ngSanitize',
  'ngAria',
  'ngAnimate',
  'ngMessages',
  'ui.router',
  'material.core',
  'material.components.backdrop',
  'material.components.virtualRepeat',
  'material.components.checkbox',
  'material.components.progressCircular',
  'material.components.progressLinear',
  'material.components.tooltip',
  'material.components.button',
  'material.components.menu',
  'material.components.input',
  'material.components.select',
  'material.components.icon',
  'material.components.autocomplete',
  'material.components.chips',
  'material.components.dialog',
  'material.components.content',
  'material.components.toolbar',
  'material.components.sidenav',
  'material.components.datepicker',
  'material.components.radioButton',
  'ks.ngScrollRepeat',
  'js-data',
  'monospaced.elastic',
  'ui.grid',
  'ui.grid.edit',
  'ui.grid.cellNav',
  'ui.grid.pinning',
  'md.data.table',
  'toastr',
]);
