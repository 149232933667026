import { Tabs, TabList, TabPanels } from '@chakra-ui/react';
import { StepTab } from './StepTab';
import { StepTabPanel } from './StepTabPanel';
import { Step, StepState } from './types';

export function WizardForm({
  steps,
  stepStates,
  setStepIsActive,
}: {
  steps: Step[];
  stepStates: StepState[];
  setStepIsActive: (index: string) => void;
}) {
  return (
    <Tabs
      index={stepStates.findIndex((s) => s.isActive)}
      onChange={(index) => {
        setStepIsActive(stepStates[index]!.id);
      }}
      isManual={true}
      orientation="horizontal"
      variant="step"
      pt={1}
      display="flex"
      flexDir="column"
      gap={2}
      w="full"
    >
      <TabList>
        {steps.map((step, i) => (
          <StepTab key={step.config.id} step={step} zIndex={steps.length - i} />
        ))}
      </TabList>
      <TabPanels>
        {steps.map((step) => (
          <StepTabPanel key={step.id} step={step} />
        ))}
      </TabPanels>
    </Tabs>
  );
}
