import { httpClient } from '../httpClient/httpClient';

export interface Currency {
  id: number;
  branchId: number;
  code: string;
  name: string;
  sellingRate: number;
  buyingRate: number;
}

export interface CustomerCurrency {
  id: number;
  branchId: number;
  code: string;
  name: string;
  sellingRate: number;
  buyingRate: number;
  garpSellingRate: number;
  sellingRateModified: boolean;
}

export const currenciesApi = {
  getAllCurrencies: () => httpClient.get<Currency[]>(`currencies/all`),
  getAllCurrenciesMergedWithCustomerCurrencies: (customerId: number) =>
    httpClient.get<CustomerCurrency[]>(`currencies/all/merged/${customerId}`),
};
