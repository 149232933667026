import { useLocalStorage } from '@texas/hooks/useLocalStorage';
import { WidgetLayout, WidgetOption } from './useWidgetLayout';
import { randomSeed } from '@texas/resources/constants';
import { useCallback } from 'react';
import {
  widgetSizeConstraints,
  defaultWidgetLayouts,
} from './defaultWidgetLayouts';
import { WidgetType } from './shared/types';

interface Props {
  defaultLayout: WidgetLayout;
  localStorageKey?: string;
  widgetOptions: WidgetOption;
}

export function useWidgetFactory({ defaultLayout, localStorageKey }: Props) {
  const [layout, setLayout] = useLocalStorage(localStorageKey, defaultLayout);

  const addNewWidget = useCallback(
    (widget: WidgetType, name: string, color: string | null) => {
      const widgetId = Math.floor(Math.random() * randomSeed).toString();
      const constraints = widgetSizeConstraints[widget];

      setLayout((l) => ({
        layout: {
          ['lg']: [
            ...l.layout['lg'],
            { ...defaultWidgetLayouts[widget].lg, ...constraints, i: widgetId },
          ],
          ['md']: [
            ...l.layout['md'],
            { ...defaultWidgetLayouts[widget].lg, ...constraints, i: widgetId },
          ],
        },
        widgets: [
          ...l.widgets,
          {
            id: widgetId,
            type: widget,
            layoutIndex: widgetId,
            name: name,
            categoryColor: color,
          },
        ],
        currentLayout: l.currentLayout,
      }));
    },
    [setLayout],
  );

  function removeWidget(id: string) {
    setLayout((l) => ({
      ...l,
      widgets: [...l.widgets.filter((x) => x.id !== id)],
    }));
  }

  function updateWidget(id: string, name: string, color: string | null) {
    setLayout((l) => ({
      ...l,
      widgets: [
        ...l.widgets.map((x) => {
          if (x.id === id) {
            return { ...x, name, categoryColor: color };
          }

          return x;
        }),
      ],
    }));
  }

  return { addNewWidget, removeWidget, updateWidget, layout, setLayout };
}
