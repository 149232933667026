import {
  Tooltip,
  Tag,
  TagLabel,
  Flex,
  Spinner,
  TagCloseButton,
} from '@chakra-ui/react';
import { FileUpload } from '@texas/types';
import { Icons } from '../Icons';

export function FileUploads({
  fileUploads,
  abortFileUpload,
}: {
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
}) {
  return (
    <>
      {fileUploads.map((upload) => {
        if (upload.type === 'failed')
          return (
            <Tooltip label={upload.error?.message}>
              <Tag
                key={upload.uuid}
                variant="lighter"
                w="fit-content"
                py={2}
                height={8}
              >
                <TagLabel mr={2}>{upload.fileName}</TagLabel>
                <Icons.Close
                  boxSize={4}
                  color="red.300"
                  _light={{ color: 'red.500' }}
                />
              </Tag>
            </Tooltip>
          );
        return (
          <Tag
            key={upload.uuid}
            variant="lighter"
            h="fit-content"
            w="fit-content"
            py={2}
            height={8}
          >
            <TagLabel mr={2}>{upload.fileName}</TagLabel>
            {upload.type === 'uploading' && (
              <Flex alignItems="center">
                <Spinner size="xs" />
                <TagCloseButton onClick={() => abortFileUpload(upload)} />
              </Flex>
            )}
            {upload.type === 'uploaded' && (
              <Icons.Checkmark
                boxSize={4}
                color="green.300"
                _light={{ color: 'green.500' }}
              />
            )}
          </Tag>
        );
      })}
    </>
  );
}
