const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

// @ngInject
const bytes = () => (bytes, precision) => {
  if (typeof bytes !== 'number') {
    bytes = parseFloat(bytes);
  }

  if (bytes === 0) {
    return '0 B';
  } else if (isNaN(bytes) || !isFinite(bytes)) {
    return '-';
  }

  const isNegative = bytes < 0;
  let prefix = '';
  if (isNegative) {
    bytes = -bytes;
    prefix = '-';
  }

  if (typeof precision !== 'number') {
    precision = parseFloat(precision);
  }

  if (isNaN(precision) || !isFinite(precision)) {
    precision = 1;
  }

  const exponent = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    units.length - 1,
  );

  const number = (bytes / Math.pow(1024, Math.floor(exponent))).toFixed(
    precision,
  );

  return `${prefix}${number} ${units[exponent]}`;
};

angular.module('bytesFilter', []).filter('bytes', bytes);
