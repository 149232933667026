import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { convertToOption, findOptionFromProfiles } from '../shared';
import {
  Project,
  CreateProjectRequest,
  projectApi,
  ErpProject,
} from '@texas/api/endpoints/projectsApi';
import {
  userProfilesApi,
  UserProfileType,
} from '@texas/api/endpoints/userProfilesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { HttpStatus } from '@texas/api/httpClient/types';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { AlertDetails } from '@texas/components/shared/alert/AlertDetails';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ServerError, ReactSelectOption } from '@texas/types';
import { TFunction } from 'i18next';
import { request } from '@texas/utils/helpers/httpHelpers';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

interface CreateProjectFormProps {
  userId: number;
  brandName: string;
  brandId: number;
  identifier?: string;
  onProjectCreated: (project: Project) => void;
}

export function CreateProjectForm(props: CreateProjectFormProps) {
  const { data: keyAccountManagers, refetch } = useApiResource(
    userProfilesApi.getUserProfilesWithType,
  );

  const {
    data: garpProject,
    refetch: validateIdentifierRefetch,
    loading: identifierLoading,
    error: identifierError,
  } = useApiResource(projectApi.validateProject);

  const toast = useToast();
  const { t } = useTranslation();
  const { request: createProjectRequest, error: createError } = useApiRequest(
    projectApi.createProject,
  );

  const onSubmit = async (data: CreateProjectRequest) =>
    await request(
      createProjectRequest,
      [data],
      (data: Project) => {
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        props.onProjectCreated(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateProjectRequest>({
    defaultValues: {
      customerId: props.brandId,
      name: props.brandName,
      userProfileId: undefined,
      identifier: props.identifier,
    },
  });

  useEffect(() => {
    refetch(UserProfileType.KeyAccountManager, false);
    if (props.identifier) {
      validateIdentifierRefetch(props.identifier);
    }
  }, [props.identifier, refetch, validateIdentifierRefetch]);

  return (
    <Box>
      {props.identifier &&
        identifierAlert(identifierLoading, identifierError, garpProject, t)}
      <VStack>
        {createError && <ErrorDetails error={createError} />}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Flex direction="column" gap={4} align="start">
            <FormControl>
              <TexasFormLabel>{t('project.area')}</TexasFormLabel>
              <Controller
                name="userProfileId"
                control={control}
                render={({ field }) => (
                  <TexasSelect
                    {...field}
                    value={findOptionFromProfiles(
                      field.value,
                      keyAccountManagers ?? [],
                    )}
                    onChange={(value) => field.onChange(value?.value ?? null)}
                    isClearable={true}
                    options={
                      keyAccountManagers?.map<ReactSelectOption>((u) =>
                        convertToOption(u),
                      ) ?? []
                    }
                  />
                )}
              />
            </FormControl>
            <Box>
              {keyAccountManagers
                ?.filter((s) => s.userId === props.userId)
                .map((u) => (
                  <Button
                    mr={2}
                    mb={2}
                    display="inline-block"
                    variant="texas-solid"
                    size="xs"
                    key={u.id}
                    onClick={() => setValue('userProfileId', u.id)}
                  >
                    {u.name}
                  </Button>
                ))}
            </Box>

            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>{t('project.projectName')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 25,
                    message: t('errors.maxLength', { count: 25 }),
                  },
                })}
                placeholder="Name"
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <SubmitButton disabled={identifierLoading} loading={isSubmitting}>
              {t('general.create')}
            </SubmitButton>
          </Flex>
        </form>
      </VStack>
    </Box>
  );
}

function identifierAlert(
  isLoading: boolean,
  error: ServerError | null,
  garpProject: ErpProject | null,
  t: TFunction,
) {
  if (isLoading) {
    return (
      <AlertDetails
        type="loading"
        title={t('general.verifying')}
        description={t('project.identifierVerifying')}
      />
    );
  }

  if (error) {
    if (error.status === HttpStatus.NotFound) {
      return (
        <AlertDetails
          type="info"
          title={t('project.identifierMissing')}
          description={t('project.newProjectNumberGenerated')}
        />
      );
    }
    return (
      <>
        <AlertDetails
          type="error"
          title={t('general.verificationFailed')}
          description={error.message}
        />
        <AlertDetails
          type="info"
          description={t('project.newProjectNumberGenerated')}
        />
      </>
    );
  }

  if (garpProject) {
    <AlertDetails
      type="success"
      title={t('project.identifierValid')}
      description={t('project.reuseIdentifier', {
        projectNumber: garpProject.number,
      })}
    />;
  }
}
