import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { SimpleEventDispatcher } from 'ste-simple-events';
import { addSpaceBeforeUppercaseLetter } from '../../../utils/helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
import { AutoUpdateFieldType } from '@texas/api/endpoints/autoUpdateTypes';

export const autoUpdateEvents = {
  formNotFound: new SimpleEventDispatcher<AutoUpdateFieldType[]>(),
};

export interface AutoUpdateEventsProps {
  children: React.ReactNode;
}

export function AutoUpdateEvents(props: AutoUpdateEventsProps) {
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    return autoUpdateEvents.formNotFound.subscribe((row) => {
      if (row.filter((r) => !r.hasError).length > 0) {
        toast({
          title: `${t('autoUpdate.autosaveSuccess')}`,
          description:
            `${t('autoUpdate.updatedFields')} ` +
            row
              .filter((r) => !r.hasError)
              .map((r) => addSpaceBeforeUppercaseLetter(r.fieldName))
              .join(', '),
          position: 'bottom-left',
          status: 'success',
          isClosable: true,
        });
      }

      if (row.filter((r) => r.hasError).length > 0) {
        toast({
          title: `${t('autoUpdate.autosaveFailed')}`,
          description:
            `${t('autoUpdate.failedFields')} ` +
            row
              .filter((r) => !r.hasError)
              .map((r) => addSpaceBeforeUppercaseLetter(r.fieldName))
              .join(', '),
          position: 'bottom-left',
          status: 'error',
          isClosable: true,
        });
      }
    });
  });

  return <>{props.children}</>;
}
