// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.productGroups', {
    url: '/product-groups',
    templateUrl: 'main/configuration/productGroups/productGroups.tpl.html',
    data: { pageTitle: 'Product groups' },
  });
};

angular.module('configuration.productGroups', []).config(config);
