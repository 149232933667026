import { Global } from '@emotion/react';

export const CustomFonts = () => (
  <Global
    styles={`		
	  @font-face {
        font-family: 'Barlow';
        src: url('/assets/fonts/Barlow-Medium.woff2') format('woff2');
        font-style: normal;
        font-weight: 500;
      }

      @font-face {
        font-family: 'Barlow';
        src: url('/assets/fonts/Barlow-SemiBold.woff2') format('woff2');
        font-style: normal;
        font-weight: 600;
      }

      @font-face {
        font-family: 'Instrument Sans';
        src: url('/assets/fonts/InstrumentSans-Regular.woff2') format('woff2');
        font-style: normal;
        font-weight: 400;
      }

      @font-face {
        font-family: 'Instrument Sans';
        src: url('/assets/fonts/InstrumentSans-Medium.woff2') format('woff2');
        font-style: normal;
        font-weight: 500;
      }

      @font-face {
        font-family: 'Instrument Sans';
        src: url('/assets/fonts/InstrumentSans-SemiBold.woff2') format('woff2');
        font-style: normal;
        font-weight: 600;
      }

      @font-face {
        font-family: 'Instrument Sans';
        src: url('/assets/fonts/InstrumentSans-Bold.woff2') format('woff2');
        font-style: normal;
        font-weight: 700;
      }
`}
  />
);
