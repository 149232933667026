// @ngInject
const fileDownload = ($timeout) => ({
  restrict: 'EA',
  link(scope, element, attrs) {
    const delay = 500;
    let clicks = 0;
    let timer = null;

    if (attrs.fileDownload) {
      const onClick = () => {
        clicks += 1;
        // Prevent double clicks
        if (clicks === 1) {
          let iFrame = element.find('iframe');
          if (!(iFrame && iFrame.length > 0)) {
            iFrame = angular.element(
              "<iframe style='position:fixed;display:none;top:-1px;left:-1px;'></iframe>",
            );
            element.append(iFrame);
          }
          iFrame.attr('src', attrs.fileDownload);
          timer = $timeout(() => {
            clicks = 0;
          }, delay);
        } else {
          $timeout.cancel(timer);
          clicks = 0;
        }
      };

      element.bind('click', onClick);
    }
  },
});

angular
  .module('directives.fileDownload', [])
  .directive('fileDownload', fileDownload);
