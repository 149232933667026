import {
  GridItem,
  Card,
  CardBody,
  Flex,
  Image,
  Tag,
  Box,
  Text,
  Tooltip,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { clientEndpoints } from '@texas/clientEndpoints';
import { Locale } from '@texas/i18n/types';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import {
  isImage,
  getExtension,
  formatBytes,
} from '@texas/utils/helpers/filesHelper';
import { Icons } from '../Icons';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { useTranslation } from 'react-i18next';
import placeholderImg from '@texas/assets/placeholder-250.png';
import { FileBrowserMode } from './FileBrowserModal';

export function FolderFiles({
  files,
  mode,
  onSelect,
  onEditFileOpen,
  onArchiveOpen,
  onRestoreOpen,
}: {
  files?: FileLink[];
  mode: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  onEditFileOpen?: (image: FileLink) => void;
  onArchiveOpen?: (image: FileLink) => void;
  onRestoreOpen?: (image: FileLink) => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      {files?.length === 0 && (
        <Text variant="sub" p={4}>
          {t('fileBrowser.noFiles')}
        </Text>
      )}
      <Flex
        maxW="100%"
        overflowX="auto"
        overflowY="auto"
        pb={5}
        __css={{ container: 'filesContainer / inline-size' }}
      >
        <Box
          gap={6}
          __css={{
            display: 'grid',
            '@container filesContainer (min-width: 0px)': {
              gridTemplateColumns: 'repeat(1, 1fr)',
            },
            '@container filesContainer (min-width: 680px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@container filesContainer (min-width: 980px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
            '@container filesContainer (min-width: 1280px)': {
              gridTemplateColumns: 'repeat(4, 1fr)',
            },
            '@container filesContainer (min-width: 1800px)': {
              gridTemplateColumns: 'repeat(6, minmax(200px, auto))',
            },
          }}
        >
          {files?.map((file) => (
            <GridItem key={file.id} role="group">
              <Card
                animation={fadeInScaleAnimation()}
                height="100%"
                variant="hover-shadow"
                cursor={mode === 'Edit' ? 'default' : 'pointer'}
                onClick={() => {
                  if (onSelect) onSelect(file);
                }}
              >
                <CardBody role="group" p={3}>
                  <Flex gap={2}>
                    {isImage(file.extension) ? (
                      <Image
                        src={
                          file.identifier
                            ? clientEndpoints.previewImage(file.identifier, 128)
                            : undefined
                        }
                        objectFit="contain"
                        borderRadius={2}
                        maxW="128px"
                        maxH="150px"
                        loading="lazy"
                        onError={(e) => {
                          e.currentTarget.src = placeholderImg;
                        }}
                      />
                    ) : (
                      <Icons.FileOutline
                        boxSize={16}
                        color="gray.200"
                        _light={{ color: 'gray.200' }}
                      />
                    )}
                    <Flex gap={1} direction="column" flexGrow={1}>
                      <Text fontWeight="medium">{file.name}</Text>
                      <Flex gap={2} alignItems="center">
                        <Tag
                          size="sm"
                          textTransform="uppercase"
                          h="fit-content"
                          w="fit-content"
                          variant="lighter"
                        >
                          {getExtension(file.extension)}
                        </Tag>
                        <Popover trigger="hover">
                          <PopoverTrigger>
                            <Icons.InformationOutline
                              boxSize={5}
                              color="gray.300"
                              _light={{ color: 'gray.500' }}
                            />
                          </PopoverTrigger>
                          <Portal>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverBody>
                                <Flex gap={1} alignItems="center">
                                  <Text variant="sub">Size:</Text>
                                  <Text>{formatBytes(file.size)}</Text>
                                </Flex>
                                <Flex gap={1} alignItems="center">
                                  <Text variant="sub">Uploaded:</Text>
                                  <Text>
                                    {formatDate(Locale.En, file.created)}
                                  </Text>
                                </Flex>
                                <Flex gap={1} alignItems="center">
                                  <Text variant="sub">Last edited:</Text>
                                  <Text>
                                    {formatDate(Locale.En, file.updated)}
                                  </Text>
                                </Flex>
                                {file.note && (
                                  <Flex gap={1} alignItems="baseline">
                                    <Text variant="sub">Note:</Text>
                                    <Text>{file.note}</Text>
                                  </Flex>
                                )}
                              </PopoverBody>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      </Flex>
                      <Text variant="sub">{file.creatorName}</Text>
                    </Flex>
                    {mode === 'Edit' && (
                      <Flex
                        direction="column"
                        opacity={0}
                        _groupHover={{ opacity: 1 }}
                      >
                        {!file.archived && onEditFileOpen && (
                          <Tooltip label={t('general.edit')}>
                            <IconButton
                              icon={<Icons.Pencil boxSize={5} />}
                              aria-label={t('general.edit')}
                              size="sm"
                              variant="no-bg"
                              onClick={() => onEditFileOpen(file)}
                            />
                          </Tooltip>
                        )}
                        <Tooltip label={t('fileBrowser.downloadFile')}>
                          <IconButton
                            icon={<Icons.Download boxSize={5} />}
                            aria-label={t('fileBrowser.downloadFile')}
                            size="sm"
                            variant="no-bg"
                            as="a"
                            href={clientEndpoints.downloadFile(file.identifier)}
                            target="_blank"
                            download={true}
                          />
                        </Tooltip>
                        {!file.archived && onArchiveOpen && (
                          <Tooltip label={t('general.archive')}>
                            <IconButton
                              icon={<Icons.Archive boxSize={5} />}
                              aria-label={t('general.archive')}
                              size="sm"
                              variant="no-bg"
                              onClick={() => onArchiveOpen(file)}
                            />
                          </Tooltip>
                        )}
                        {file.archived && onRestoreOpen && (
                          <Tooltip label={t('general.restore')}>
                            <IconButton
                              icon={<Icons.ArchiveRemove boxSize={5} />}
                              aria-label={t('general.restore')}
                              size="sm"
                              variant="no-bg"
                              onClick={() => onRestoreOpen(file)}
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </Box>
      </Flex>
    </>
  );
}
