import { Badge, Text, IconButton, Input } from '@chakra-ui/react';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { UpdateHistory } from '@texas/components/shared/autoUpdate/history/UpdateHistory';
import { AutoUpdateResponseCallback } from '@texas/components/shared/autoUpdate/types';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { SalesContext } from '../Sales';
import { setValueAsDecimalExtender } from '../shared';
import { articleHistoryApi } from '@texas/api/endpoints/articleHistoryApi';
import {
  SalesCurrency,
  AutoUpdateSalesCurrencyRequest,
  salesCurrenciesApi,
} from '@texas/api/endpoints/salesCurrenciesApi';
import { Icons } from '@texas/components/shared/Icons';
import { VerifyPopover } from '@texas/components/shared/popover/VerifyPopover';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';

export interface SalesCurrencyFormProps {
  salesCurrency: SalesCurrency;
  isBranchDefaultCurrency: boolean;
  disabled: boolean;
  branchId: number;
  variantId: number;
}

const schema = object({
  fixedExchangeRate: number()
    .positive('Can not be negative')
    .typeError('Not a valid number'),
}).required();

export function SalesCurrencyForm(props: SalesCurrencyFormProps) {
  const salesContext = useActiveContext(SalesContext);
  const { t } = useTranslation();
  const onResponse = (
    data: AutoUpdateResponseCallback<
      SalesCurrency,
      AutoUpdateSalesCurrencyRequest
    >,
  ) => {
    const toSet =
      salesContext.salesInformation.data?.map((s) => {
        s.currencies = s.currencies.map((c) => {
          if (c.id === props.salesCurrency.id) {
            return { ...c, ...data.mappedServerData };
          }

          return c;
        });

        return s;
      }) ?? [];

    salesContext.salesInformation.set(toSet);
  };

  const {
    registerAutoUpdate,
    setValue,
    triggerSubmit,
    form,
    register,
    formState: { errors },
  } = useAutoUpdateForm<AutoUpdateSalesCurrencyRequest, SalesCurrency>({
    defaultValue: {
      fixedExchangeRate: props.salesCurrency.fixedExchangeRate,
    },
    valueId: props.salesCurrency.id,
    namePrefix: 'salesCurrency',
    autoUpdateRequest: salesCurrenciesApi.autoUpdateSalesCurrency,
    onResponse,
    resolver: yupResolver(schema),
  });

  const garpRate = salesContext.currencies.data?.find(
    (c) => c.id === props.salesCurrency.currencyId,
  )?.sellingRate;

  return (
    <form {...form}>
      <fieldset>
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="fixedExchangeRate"
        >
          <TexasInputWrapper
            formControlProps={{ width: 'fit-content' }}
            bottomLabel={
              props.isBranchDefaultCurrency
                ? undefined
                : `${t('variant.sales.garpRate')}: ${garpRate}`
            }
            label="Exchange rate"
            error={errors.fixedExchangeRate}
            utilButtons={
              <>
                {props.salesCurrency.fixedExchangeRate !== garpRate && (
                  <RefreshGarpRate
                    salesCurrency={props.salesCurrency}
                    garpRate={garpRate ?? 0}
                    onRefresh={(value) => {
                      setValue('fixedExchangeRate', value);
                      triggerSubmit();
                    }}
                    disabled={props.disabled}
                  />
                )}
                <UpdateHistory
                  header={t('history.fixedExchangeRateHistory')}
                  historyRequestWrapper={() =>
                    articleHistoryApi.salesCurrency(
                      salesContext.articleId,
                      props.variantId,
                      props.salesCurrency.currencyId,
                      props.branchId,
                    )
                  }
                />
              </>
            }
          >
            <Input
              {...register('fixedExchangeRate', {
                setValueAs: (value) => setValueAsDecimalExtender(value, false),
                onBlur: triggerSubmit,
              })}
              disabled={props.isBranchDefaultCurrency || props.disabled}
            />
          </TexasInputWrapper>
        </AutoUpdateWrapper>
      </fieldset>
    </form>
  );
}

function RefreshGarpRate({
  salesCurrency,
  garpRate,
  disabled,
  onRefresh,
}: {
  salesCurrency: SalesCurrency;
  garpRate: number;
  disabled: boolean;
  onRefresh: (value: number) => void;
}) {
  const { t } = useTranslation();
  const { value, isOpen, onOpen, onClose } =
    useValueDisclosure<SalesCurrency>();

  return (
    <VerifyPopover
      trigger={
        <IconButton
          variant="ghost"
          size="xs"
          aria-label={`${t('variant.sales.refreshRate')}`}
          onClick={() => {
            onOpen(salesCurrency);
          }}
          icon={<Icons.Refresh />}
          disabled={disabled}
        />
      }
      headerTitle={t('variant.sales.refreshRate')}
      secondaryButtonTitle="Cancel"
      primaryButtonTitle="Refresh"
      onPrimaryClick={() => {
        onRefresh(garpRate);
        onClose();
      }}
      onSuccessTitle="Rate refreshed"
      onFailureTitle="Failed to refresh rate"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text>
        {t('variant.sales.rateConfirm')}
        <br />
        <Badge>{value?.fixedExchangeRate}</Badge>
        <Icons.ArrowRightThick />
        <Badge colorScheme="orange">{garpRate}</Badge>
      </Text>
    </VerifyPopover>
  );
}
