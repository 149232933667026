// @ngInject
const uploadImagePaste = (UploaderService, $timeout, toasterService) => ({
  restrict: 'EA',
  scope: false,
  require: '^uploadInstance',
  link: (scope, element, attrs, uploadInstanceCtrl) => {
    const instance = uploadInstanceCtrl.instance;
    const fileName = attrs.uploadImagePaste || 'pasted_image';
    const clipboardInput = angular.element(
      "<div class='hidden-input' contenteditable='true'></div>",
    );

    const getImagesFromContainer = (cb) => {
      $timeout(() => {
        const images = clipboardInput.find('img');
        _.forEach(images, (img) => {
          cb(img.src);
        });
      }, 1);
    };

    // Set a file name and add to upload queue
    const uploadBlob = (blob) => {
      blob.fileName = `${fileName}.png`;
      UploaderService.addFiles([blob], { instance: instance.name });
    };

    const uploadImage = (dataURL) => {
      const loader = new Image();
      loader.src = dataURL;

      // Redraw the image (any image type) in a virtual canvas and get it as an png
      loader.onload = () => {
        const canvas = angular.element('<canvas></canvas>')[0];
        canvas.width = loader.width;
        canvas.height = loader.height;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.drawImage(loader, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(uploadBlob, 'image/png');
      };
    };

    element.append(clipboardInput);
    element.addClass('paste-area');

    element.on('mouseenter', () => {
      clipboardInput.html('');
      clipboardInput.css('display', 'block');
      clipboardInput[0].focus();
    });

    element.on('mouseleave', () => {
      clipboardInput.css('display', 'none');
      clipboardInput.html('');
      clipboardInput[0].blur();
      window.getSelection().removeAllRanges(); // Workaround for webkit blur bug
    });

    element.on('click', () => {
      clipboardInput[0].focus();
    });

    element.on('mousedown', (event) => {
      event.preventDefault();
    });

    element.on('mousemove', () => {
      clipboardInput[0].focus();
    });

    clipboardInput.on('focus', () => {
      element.toggleClass('hover', true);
    });

    clipboardInput.on('blur', () => {
      element.toggleClass('hover', false);
    });

    clipboardInput.on('paste', (event) => {
      const clipboardData =
        event.clipboardData || event.originalEvent.clipboardData;

      if (clipboardData && clipboardData.items) {
        // Chrome
        _.forEach(clipboardData.items, (item) => {
          if (item.type.match(/^image\//)) {
            const reader = new FileReader();
            reader.onload = (event) => {
              uploadImage(event.target.result);
            };

            const blob = item.getAsFile();
            if (blob) {
              reader.readAsDataURL(blob);
            } else {
              toasterService.error('Image could not be pasted');
            }
          }
        });
      } else {
        // Firefox & IE 11
        getImagesFromContainer(uploadImage);
      }

      clipboardInput.html('');
    });
  },
});

angular
  .module('uploadImagePaste', [])
  .directive('uploadImagePaste', uploadImagePaste);
