import {
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  suppliersApi,
  AddSupplierRequest,
} from '@texas/api/endpoints/suppliersApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { reactEvents } from '@bridge/reactEvents';

export function NewSupplier() {
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    request: createRequest,
    error,
    loading,
  } = useApiRequest(suppliersApi.createSupplier);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddSupplierRequest>({ defaultValues: { name: '' } });

  const onSubmit = async (data: AddSupplierRequest) =>
    await request(
      createRequest,
      [data],
      () => {
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        reset();
        onClose();
        reactEvents.supplierCreated.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      <Button variant="texas-success-solid" onClick={onOpen}>
        {t('suppliers.newSupplier')}
      </Button>
      <Modal
        onClose={() => {
          reset();
          onClose();
        }}
        isOpen={isOpen}
        variant="above-angular"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontWeight="bold">
            {t('suppliers.addSupplier')}
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={4}>
              {error && <ErrorDetails error={error} />}
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <VStack>
                  <FormControl isRequired={true} isInvalid={!!errors.name}>
                    <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                    <Input
                      variant="outline"
                      {...register('name', {
                        required: true,
                        maxLength: {
                          value: 100,
                          message: t('errors.maxLength', { count: 100 }),
                        },
                      })}
                      placeholder={t('general.name')}
                    />
                    <ErrorLabel text={errors.name?.message} />
                  </FormControl>
                  <SubmitButton loading={loading}>
                    {t('general.create')}
                  </SubmitButton>
                </VStack>
              </form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
