export enum AutoUpdateFieldState {
  None = 0,
  Successful = 1,
  Failed = 2,
  NewValueSameAsDatabase = 3,
}

export enum AutoUpdateErrorType {
  ValidationError = 1,
  OldValueMismatch = 2,
  InternalServerError = 3,
}

export interface BaseAutoUpdateField {
  loading: boolean;
  iteration: number;
  fieldName: string;
  fieldValue: any;
  fieldState: AutoUpdateFieldState;
}

export interface AutoUpdateFieldSuccess extends BaseAutoUpdateField {
  hasError: false;
}

export interface AutoUpdateError {
  message: string;
  type: AutoUpdateErrorType;
}

export interface AutoUpdateFieldError extends BaseAutoUpdateField {
  hasError: true;
  error: AutoUpdateError;
}

export type AutoUpdateFieldType = AutoUpdateFieldSuccess | AutoUpdateFieldError;

export interface AutoUpdateResponse<T> {
  value: T;
  unmodifiedValue: T;
  fields: AutoUpdateFieldType[];
}

export interface AutoUpdateRequest<T> {
  forceUpdate: boolean;
  oldData: T;
  newData: T;
  changedFields: string[];
}

export interface AutoUpdateRequestWithData<T, TData>
  extends AutoUpdateRequest<T> {
  additionalData?: TData;
}
