import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(tagAnatomy.keys);

const variants = {
  'locked-message': {
    container: {
      alignItems: 'center',
      bg: 'gray.500',
      _light: { bg: 'gray.100' },
    },
    label: {
      textTransform: 'uppercase',
      color: 'white',
      _light: 'texas.bg.900',
    },
  },
  colored: {
    container: {
      color: 'texas.sand.50',
      bg: 'whiteAlpha.200',
      _light: {
        bg: 'blackAlpha.100',
        color: 'texas.burntSienna.500',
      },
    },
  },
  lighter: {
    container: {
      bg: 'whiteAlpha.200',
      color: 'gray.200',
      _light: { bg: 'blackAlpha.100', color: 'gray.600' },
    },
  },
};

export default config.defineMultiStyleConfig({ variants });
