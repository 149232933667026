import { GeneratedProductApproval } from '../../../types';

export type PaInvalidReason = 'contact_missing' | 'composition_missing' | null;

export function validProductApproval(
  pa: GeneratedProductApproval,
): PaInvalidReason {
  if (pa.compositionGroup === null) return 'composition_missing';
  else if (pa.customerContact === null || pa.supplierContact === null)
    return 'contact_missing';

  return null;
}
