import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  PlacementWithLogical,
} from '@chakra-ui/react';
import { Project } from '@texas/api/endpoints/projectsApi';
import { Icons } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';
import { CreateProjectForm } from './forms/CreateProjectForm';

interface NoProjectComponentProps {
  keyAccountManagerId: number;
  brandName: string;
  brandId: number;
  customerArchived: boolean;
  popoverPlacement?: PlacementWithLogical;
  identifier?: string;
  onProjectCreated: (project: Project) => void;
}

export function NoProjectComponent(props: NoProjectComponentProps) {
  const { t } = useTranslation();
  return (
    <Popover isLazy={true} placement={props.popoverPlacement ?? 'bottom'}>
      <PopoverTrigger>
        <Button
          disabled={props.customerArchived}
          w="100%"
          p={6}
          justifyContent="center"
          border="1px dashed"
          borderRadius={8}
          borderColor="gray.100"
          color="gray.300"
          fontWeight="medium"
          fontSize={14}
          _hover={{
            borderColor: 'gray.200',
            color: 'gray.500',
          }}
          _active={{
            bg: 'gray.100',
          }}
          role="group"
        >
          {props.customerArchived ? (
            <>
              <Text>{t('project.canNotCreateCauseArchivedCustomer')}</Text>
            </>
          ) : (
            <>
              {t('project.noProject')}
              <Text color="texas.sand.100" fontWeight="bold">
                , {t('project.createSuggestion').toLowerCase()}
              </Text>
            </>
          )}
          <Icons.AlphaPBox
            color="texas.sand.100"
            boxSize={6}
            transition="all 200ms ease"
            maxWidth={0}
            _groupHover={{ maxWidth: '32px', marginLeft: '0.6em' }}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent minW={{ base: '30em' }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{t('project.createNew')}</PopoverHeader>

        <PopoverBody>
          <CreateProjectForm
            userId={props.keyAccountManagerId}
            brandName={props.brandName}
            brandId={props.brandId}
            identifier={props.identifier}
            onProjectCreated={props.onProjectCreated}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
