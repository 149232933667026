import { VariantsSearchQuery } from '@texas/api/endpoints/searchApi';
import { FilterOptionType } from '../filter/types';
import { defaultEmptyFilters } from '../searchArticles/useSearchArticles';
import { User } from '@texas/api/endpoints/userApi';
import { isOrganizationOrSystemAdmin } from '@texas/utils/helpers/claimHelpers';

export const pageSize = 12;
const defaultPage = 1;
const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: '',
  sortDesc: false,
  page: defaultPage,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: true,
};
export const defaultArticlesWidgetOptions = (user: User) => {
  return {
    defaultSearchPage: {
      filters: [FilterOptionType.ShowMyBrands],
      searchParams: defaultSearchParams,
    },
    filterOptions: {
      [FilterOptionType.ShowMyBrands]: {
        static: true,
        disabled: !isOrganizationOrSystemAdmin(user),
      },
    },
  };
};
