// @ngInject
const DeliveryMethodsApiService = (ResourcesService) => ({
  getAllByBranchId: (branchId) =>
    ResourcesService.getAll('deliveryMethod', {
      params: { branchId, organizationId: false },
    }),

  getById: (deliveryMethodId) =>
    ResourcesService.getById('deliveryMethod', deliveryMethodId, {
      params: { branchId: false },
    }),

  newDeliveryMethod: (deliveryMethod, branchId) =>
    ResourcesService.create('deliveryMethod', deliveryMethod, {
      params: { branchId, organizationId: false },
    }),

  updateDeliveryMethod: (data, deliveryMethodId) =>
    ResourcesService.update('deliveryMethod', deliveryMethodId, data, {
      params: { branchId: false },
      updatedFields: ['name', 'identifier', 'displayOrder', 'archived'],
    }),
});

angular
  .module('resources.deliveryMethods', [])
  .factory('DeliveryMethodsApiService', DeliveryMethodsApiService);
