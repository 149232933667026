import { Box, PlacementWithLogical } from '@chakra-ui/react';
import { projectApi } from '@texas/api/endpoints/projectsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { NoProjectComponent } from './NoProjectComponent';
import { ProjectComponent } from './ProjectComponent';

interface ProjectContainerProps {
  customerId: number;
  brandName: string;
  customerArchived: boolean;
  keyAccountManagerId: number;
  popoverPlacement?: PlacementWithLogical;
  identifier?: string;
  onProjectCreated?: () => void;
  finalizeContainer: boolean;
}

export function ProjectContainer(props: ProjectContainerProps) {
  const {
    data: projectData,
    refetch: projectRefetch,
    set: setProject,
    loading: projectLoading,
  } = useApiResource(projectApi.getProjectFromBrand);

  useEffect(() => {
    projectRefetch(props.customerId);
  }, [projectRefetch, props.customerId]);

  return (
    <Box>
      {projectData || projectLoading ? (
        <ProjectComponent
          customerArchived={props.customerArchived}
          finalizeContainer={props.finalizeContainer}
          keyAccountManagerId={props.keyAccountManagerId}
          project={projectData}
          loading={projectLoading}
          onArchivedStateChanged={(project) => setProject(project)}
          onProjectUpdated={(project) => {
            setProject(project);
          }}
        />
      ) : (
        <NoProjectComponent
          customerArchived={props.customerArchived}
          keyAccountManagerId={props.keyAccountManagerId}
          brandName={props.brandName}
          brandId={props.customerId}
          identifier={props.identifier}
          popoverPlacement={props.popoverPlacement}
          onProjectCreated={(project) => {
            setProject(project);
            if (props.onProjectCreated) {
              props.onProjectCreated();
            }
          }}
        />
      )}
    </Box>
  );
}
