import { Button, VStack, Text } from '@chakra-ui/react';

interface CopyValuePreviewProps {
  value: any;
  buttonText: string;
  onClick: (value: any) => void;
}

export function CopyValuePreview(props: CopyValuePreviewProps) {
  return (
    <VStack>
      <Text width="100%" whiteSpace="break-spaces">
        {props.value}
      </Text>
      <Button size="xs" width="100%" onClick={() => props.onClick(props.value)}>
        {props.buttonText}
      </Button>
    </VStack>
  );
}
