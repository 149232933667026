import i18n from 'i18next';
import translationEN from './en/translation.json';
import { initReactI18next } from 'react-i18next';
import { Locale } from './types';

export const resources = {
  en: {
    translation: translationEN,
  },
} as const;

const langKey = 'i18n_lang';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem(langKey) ?? Locale.En,
  fallbackLng: [Locale.En],
  resources,
});

i18n.on('languageChanged', (lang) => {
  localStorage.setItem(langKey, lang);
});
