// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('concept.management', {
    url: '/management',
    templateUrl: 'main/concept/management/management.tpl.html',
    controller: 'ConceptManagementController as vm',
  });
};

class ConceptManagementController {
  constructor(
    $scope,
    $q,
    HelperService,
    ConceptsApiService,
    loadSpinnerService,
    AuthService,
    toasterService,
    SavingService,
    concept,
  ) {
    'ngInject';
    const getChangedData = () =>
      HelperService.getChangedData(concept, this.concept, ['name', 'archived']);

    const saveObj = SavingService.registerSave({
      onSave: () => this.save(getChangedData()),
      onDiscard: () => {
        this.validationErrors = [];
        this.setConceptCopy();
      },
      onValidate: () => {
        if (!this.conceptForm.$valid) {
          this.validationErrors = [{ message: 'Form is not valid' }];
          return false;
        }
      },
    });

    this._ConceptsApiService = ConceptsApiService;
    this._loadSpinnerService = loadSpinnerService;
    this._concept = concept;
    this._toasterService = toasterService;
    this.validationErrors = [];
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);
    this.setConceptCopy();

    $scope.$watch(
      () => {
        return !_.isEmpty(getChangedData());
      },
      (hasChanges) => {
        if (!hasChanges) {
          this.conceptForm.$setPristine();
        }
        saveObj.hasChanges = hasChanges;
      },
    );

    $scope.$on('$destroy', () => {
      SavingService.deregisterSave(saveObj);
    });
  }

  setConceptCopy() {
    this.concept = _.extend({}, this._concept);
  }

  save(data) {
    this.validationErrors = [];
    this._loadSpinnerService.start('mainSpinner');
    this._ConceptsApiService
      .updateConceptInfo(data, this._concept.id)
      .then(
        () => {
          this.setConceptCopy();
          this._toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }

  archive() {
    this.save({ archived: !this._concept.archived });
  }
}

angular
  .module('main.concept.management', [])
  .config(config)
  .controller('ConceptManagementController', ConceptManagementController);
