import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useStep } from './hooks/useStep';
import { Icons } from '@texas/components/shared/Icons';
import { useProdutApprovalWizardState } from '../context';

export function StepContent({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const { state, config, nextStep, lastStep } = useStep();
  const { submit, isSubmitting } = useProdutApprovalWizardState();

  if (!state.isActive) {
    return null;
  }

  return (
    <>
      <Text
        color="white"
        _light={{ color: 'texas.bg.900' }}
        fontSize="x-large"
        fontWeight="bold"
        mb={6}
        px={4}
      >
        {config.label}
      </Text>
      {children}
      <Box px={4} mt="auto">
        <Flex direction="row" justifyContent="flex-end" pt={6} pb={4}>
          {lastStep ? (
            <Button
              variant="texas-solid"
              fontSize="18px"
              onClick={() => submit()}
              minW="36"
              isDisabled={!state.isValid || isSubmitting}
            >
              {t('wizard.create')} <Icons.Plus ml={2} boxSize={6} />
            </Button>
          ) : (
            <Flex flexDir="column" gap={1} alignItems="flex-end">
              <Button
                variant="texas-solid"
                fontSize="18px"
                onClick={nextStep}
                minW="36"
                width="fit-content"
                isDisabled={!state.isValid}
              >
                {t('wizard.continue')} <Icons.ArrowRight ml={2} boxSize={6} />
              </Button>
              {!state.isValid && config.invalidContent}
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
}
