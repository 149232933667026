import { httpClient } from '../httpClient/httpClient';

export enum UserProfileType {
  KeyAccountManager = 1,
}

export interface UserProfile {
  name: string;
  id: number;
  type: UserProfileType;
  userId: number;
  archived?: Date;
}

export interface CreateUserProfileRequest {
  name: string;
  userId: number;
  type: UserProfileType;
}
export interface UpdateUserProfileRequest {
  name: string;
  userId: number;
  type: UserProfileType;
}

export const userProfilesApi = {
  createUserProfile: (request: CreateUserProfileRequest) =>
    httpClient.post<UserProfile>('userprofiles', { data: request }),
  updateUserProfile: (profileId: number, request: UpdateUserProfileRequest) =>
    httpClient.put<UserProfile>(`userprofiles/${profileId}`, {
      data: request,
    }),
  archiveUserProfile: (profileId: number) =>
    httpClient.put<UserProfile>(`userprofiles/${profileId}/archive`),
  restoreUserProfile: (profileId: number) =>
    httpClient.put<UserProfile>(`userprofiles/${profileId}/restore`),

  getUserProfilesFromUser: (userId: number, includeArchived: boolean) =>
    httpClient.get<UserProfile[]>(`userprofiles/${userId}`, {
      params: { includeArchived },
    }),
  getUserProfilesWithType: (type: UserProfileType, includeArchived: boolean) =>
    httpClient.get<UserProfile[]>(`userprofiles/type/${type}`, {
      params: { includeArchived },
    }),
};
