export const paperTpg = {
  colors: [
    {
      code: '17-3938 TPG',
      name: 'Very Peri',
      hex: '696AAD',
      rgb: {
        r: 105,
        g: 106,
        b: 173,
      },
      lab: {
        l: 47.09,
        a: 12.01,
        b: -36.01,
      },
      cmyk: null,
    },
    {
      code: '11-4001 TPG',
      name: 'Brilliant White',
      hex: 'F0F0F1',
      rgb: {
        r: 240,
        g: 240,
        b: 241,
      },
      lab: {
        l: 94.8,
        a: 0.01,
        b: -0.65,
      },
      cmyk: null,
    },
    {
      code: '11-0601 TPG',
      name: 'Bright White',
      hex: 'F5F7F6',
      rgb: {
        r: 245,
        g: 247,
        b: 246,
      },
      lab: {
        l: 97.08,
        a: -0.63,
        b: 0.24,
      },
      cmyk: null,
    },
    {
      code: '11-0700 TPG',
      name: 'Lucent White',
      hex: 'F3F4F4',
      rgb: {
        r: 243,
        g: 244,
        b: 244,
      },
      lab: {
        l: 96.11,
        a: -0.21,
        b: -0.15,
      },
      cmyk: null,
    },
    {
      code: '11-4800 TPG',
      name: 'Blanc de Blanc',
      hex: 'E9E9E7',
      rgb: {
        r: 233,
        g: 233,
        b: 231,
      },
      lab: {
        l: 92.28,
        a: 0.12,
        b: 0.89,
      },
      cmyk: null,
    },
    {
      code: '11-4201 TPG',
      name: 'Cloud Dancer',
      hex: 'F1F0EC',
      rgb: {
        r: 241,
        g: 240,
        b: 236,
      },
      lab: {
        l: 94.74,
        a: 0.27,
        b: 1.84,
      },
      cmyk: null,
    },
    {
      code: '11-0608 TPG',
      name: 'Coconut Milk',
      hex: 'F0EDE5',
      rgb: {
        r: 240,
        g: 237,
        b: 229,
      },
      lab: {
        l: 93.87,
        a: -0.1,
        b: 4.4,
      },
      cmyk: null,
    },
    {
      code: '11-4801 TPG',
      name: 'Tofu',
      hex: 'EAE6D9',
      rgb: {
        r: 234,
        g: 230,
        b: 217,
      },
      lab: {
        l: 91.24,
        a: -0.07,
        b: 6.4,
      },
      cmyk: null,
    },
    {
      code: '11-0607 TPG',
      name: 'Sugar Swizzle',
      hex: 'F3EFE7',
      rgb: {
        r: 243,
        g: 239,
        b: 231,
      },
      lab: {
        l: 94.6,
        a: 0.56,
        b: 4.29,
      },
      cmyk: null,
    },
    {
      code: '11-1001 TPG',
      name: 'White Alyssum',
      hex: 'ECE8E3',
      rgb: {
        r: 236,
        g: 232,
        b: 227,
      },
      lab: {
        l: 92.16,
        a: 0.86,
        b: 2.57,
      },
      cmyk: null,
    },
    {
      code: '11-0602 TPG',
      name: 'Snow White',
      hex: 'EFEDE6',
      rgb: {
        r: 239,
        g: 237,
        b: 230,
      },
      lab: {
        l: 93.82,
        a: -0.04,
        b: 3.63,
      },
      cmyk: null,
    },
    {
      code: '11-4202 TPG',
      name: 'Star White',
      hex: 'ECECE6',
      rgb: {
        r: 236,
        g: 236,
        b: 230,
      },
      lab: {
        l: 93.24,
        a: -0.42,
        b: 2.54,
      },
      cmyk: null,
    },
    {
      code: '11-4300 TPG',
      name: 'Marshmallow',
      hex: 'EFEDE2',
      rgb: {
        r: 239,
        g: 237,
        b: 226,
      },
      lab: {
        l: 93.68,
        a: -0.49,
        b: 5.2,
      },
      cmyk: null,
    },
    {
      code: '11-4302 TPG',
      name: 'Cannoli Cream',
      hex: 'F2F0E3',
      rgb: {
        r: 242,
        g: 240,
        b: 227,
      },
      lab: {
        l: 94.72,
        a: -1.08,
        b: 6.64,
      },
      cmyk: null,
    },
    {
      code: '11-0103 TPG',
      name: 'Egret',
      hex: 'EFECE1',
      rgb: {
        r: 239,
        g: 236,
        b: 225,
      },
      lab: {
        l: 93.51,
        a: -0.41,
        b: 5.85,
      },
      cmyk: null,
    },
    {
      code: '11-0104 TPG',
      name: 'Vanilla Ice',
      hex: 'F1ECDC',
      rgb: {
        r: 241,
        g: 236,
        b: 220,
      },
      lab: {
        l: 93.42,
        a: 0.16,
        b: 8,
      },
      cmyk: null,
    },
    {
      code: '11-0606 TPG',
      name: 'Pristine',
      hex: 'EEEADB',
      rgb: {
        r: 238,
        g: 234,
        b: 219,
      },
      lab: {
        l: 92.72,
        a: -0.28,
        b: 7.77,
      },
      cmyk: null,
    },
    {
      code: '11-0701 TPG',
      name: 'Whisper White',
      hex: 'EEE8DD',
      rgb: {
        r: 238,
        g: 232,
        b: 221,
      },
      lab: {
        l: 92.37,
        a: 1.4,
        b: 6.01,
      },
      cmyk: null,
    },
    {
      code: '12-0601 TPG',
      name: 'Eggnog',
      hex: 'EAE0D2',
      rgb: {
        r: 234,
        g: 224,
        b: 210,
      },
      lab: {
        l: 89.88,
        a: 1.75,
        b: 8.5,
      },
      cmyk: null,
    },
    {
      code: '11-0604 TPG',
      name: 'Gardenia',
      hex: 'F0EAE0',
      rgb: {
        r: 240,
        g: 234,
        b: 224,
      },
      lab: {
        l: 93.09,
        a: 0.73,
        b: 5.72,
      },
      cmyk: null,
    },
    {
      code: '11-0605 TPG',
      name: 'Jet Stream',
      hex: 'EAE3DB',
      rgb: {
        r: 234,
        g: 227,
        b: 219,
      },
      lab: {
        l: 90.71,
        a: 1.61,
        b: 4.83,
      },
      cmyk: null,
    },
    {
      code: '13-0905 TPG',
      name: 'Birch',
      hex: 'DED5C5',
      rgb: {
        r: 222,
        g: 213,
        b: 197,
      },
      lab: {
        l: 85.71,
        a: 1.44,
        b: 8.77,
      },
      cmyk: null,
    },
    {
      code: '11-0105 TPG',
      name: 'Antique White',
      hex: 'EEE7D3',
      rgb: {
        r: 238,
        g: 231,
        b: 211,
      },
      lab: {
        l: 91.93,
        a: -0.08,
        b: 10.5,
      },
      cmyk: null,
    },
    {
      code: '12-0703 TPG',
      name: 'Seedpearl',
      hex: 'E3DAC2',
      rgb: {
        r: 227,
        g: 218,
        b: 194,
      },
      lab: {
        l: 87.43,
        a: -0.01,
        b: 13.12,
      },
      cmyk: null,
    },
    {
      code: '12-0000 TPG',
      name: 'White Swan',
      hex: 'E2D7C6',
      rgb: {
        r: 226,
        g: 215,
        b: 198,
      },
      lab: {
        l: 86.75,
        a: 1.75,
        b: 10.35,
      },
      cmyk: null,
    },
    {
      code: '12-0304 TPG',
      name: 'Whitecap Gray',
      hex: 'E1D7C6',
      rgb: {
        r: 225,
        g: 215,
        b: 198,
      },
      lab: {
        l: 86.51,
        a: 1.44,
        b: 9.76,
      },
      cmyk: null,
    },
    {
      code: '13-0908 TPG',
      name: 'Parchment',
      hex: 'DED1BC',
      rgb: {
        r: 222,
        g: 209,
        b: 188,
      },
      lab: {
        l: 84.62,
        a: 2.09,
        b: 12.41,
      },
      cmyk: null,
    },
    {
      code: '13-1006 TPG',
      name: 'Crème Brûlée',
      hex: 'DACCB2',
      rgb: {
        r: 218,
        g: 204,
        b: 178,
      },
      lab: {
        l: 82.66,
        a: 2.22,
        b: 14.95,
      },
      cmyk: null,
    },
    {
      code: '12-0709 TPG',
      name: 'Macadamia',
      hex: 'E7D2B9',
      rgb: {
        r: 231,
        g: 210,
        b: 185,
      },
      lab: {
        l: 85.64,
        a: 5.36,
        b: 15.13,
      },
      cmyk: null,
    },
    {
      code: '11-0110 TPG',
      name: 'Buttercream',
      hex: 'F0E3CE',
      rgb: {
        r: 240,
        g: 227,
        b: 206,
      },
      lab: {
        l: 91,
        a: 2.33,
        b: 12.14,
      },
      cmyk: null,
    },
    {
      code: '11-0907 TPG',
      name: 'Pearled Ivory',
      hex: 'EFE1CE',
      rgb: {
        r: 239,
        g: 225,
        b: 206,
      },
      lab: {
        l: 90.46,
        a: 2.59,
        b: 11.39,
      },
      cmyk: null,
    },
    {
      code: '12-0704 TPG',
      name: 'White Smoke',
      hex: 'ECDDC8',
      rgb: {
        r: 236,
        g: 221,
        b: 200,
      },
      lab: {
        l: 89.08,
        a: 3.05,
        b: 12.29,
      },
      cmyk: null,
    },
    {
      code: '11-0809 TPG',
      name: 'Ecru',
      hex: 'F2E1CD',
      rgb: {
        r: 242,
        g: 225,
        b: 205,
      },
      lab: {
        l: 90.66,
        a: 3.67,
        b: 12.36,
      },
      cmyk: null,
    },
    {
      code: '12-0710 TPG',
      name: 'Shortbread',
      hex: 'EFDEC3',
      rgb: {
        r: 239,
        g: 222,
        b: 195,
      },
      lab: {
        l: 89.52,
        a: 2.96,
        b: 15.77,
      },
      cmyk: null,
    },
    {
      code: '12-0605 TPG',
      name: 'Angora',
      hex: 'DFD3BB',
      rgb: {
        r: 223,
        g: 211,
        b: 187,
      },
      lab: {
        l: 85.12,
        a: 1.21,
        b: 13.53,
      },
      cmyk: null,
    },
    {
      code: '13-1008 TPG',
      name: 'Bleached Sand',
      hex: 'E0D0B7',
      rgb: {
        r: 224,
        g: 208,
        b: 183,
      },
      lab: {
        l: 84.48,
        a: 3.04,
        b: 14.54,
      },
      cmyk: null,
    },
    {
      code: '13-1104 TPG',
      name: 'Sheer Bliss',
      hex: 'DFCDBC',
      rgb: {
        r: 223,
        g: 205,
        b: 188,
      },
      lab: {
        l: 83.65,
        a: 4.7,
        b: 11.04,
      },
      cmyk: null,
    },
    {
      code: '12-1404 TPG',
      name: 'Pink Tint',
      hex: 'DACABC',
      rgb: {
        r: 218,
        g: 202,
        b: 188,
      },
      lab: {
        l: 82.59,
        a: 4.1,
        b: 9.51,
      },
      cmyk: null,
    },
    {
      code: '13-1107 TPG',
      name: 'Whisper Pink',
      hex: 'DECDBF',
      rgb: {
        r: 222,
        g: 205,
        b: 191,
      },
      lab: {
        l: 83.55,
        a: 4.53,
        b: 9.41,
      },
      cmyk: null,
    },
    {
      code: '13-1106 TPG',
      name: 'Sand Dollar',
      hex: 'DECEBB',
      rgb: {
        r: 222,
        g: 206,
        b: 187,
      },
      lab: {
        l: 83.84,
        a: 3.51,
        b: 11.73,
      },
      cmyk: null,
    },
    {
      code: '13-1308 TPG',
      name: 'Brazilian Sand',
      hex: 'DDCCB7',
      rgb: {
        r: 221,
        g: 204,
        b: 183,
      },
      lab: {
        l: 83.11,
        a: 4.12,
        b: 13.02,
      },
      cmyk: null,
    },
    {
      code: '12-1403 TPG',
      name: 'Tapioca',
      hex: 'DDCEBA',
      rgb: {
        r: 221,
        g: 206,
        b: 186,
      },
      lab: {
        l: 83.59,
        a: 3.37,
        b: 12.08,
      },
      cmyk: null,
    },
    {
      code: '13-0907 TPG',
      name: 'Sandshell',
      hex: 'DBCEBA',
      rgb: {
        r: 219,
        g: 206,
        b: 186,
      },
      lab: {
        l: 83.58,
        a: 1.92,
        b: 11.77,
      },
      cmyk: null,
    },
    {
      code: '13-0404 TPG',
      name: 'Ancient Scroll',
      hex: 'D1C7B8',
      rgb: {
        r: 209,
        g: 199,
        b: 184,
      },
      lab: {
        l: 80.72,
        a: 2.12,
        b: 8.64,
      },
      cmyk: null,
    },
    {
      code: '13-0401 TPG',
      name: 'Oatmeal',
      hex: 'CCC4B4',
      rgb: {
        r: 204,
        g: 196,
        b: 180,
      },
      lab: {
        l: 79.57,
        a: 0.68,
        b: 9.36,
      },
      cmyk: null,
    },
    {
      code: '13-0003 TPG',
      name: 'Oat Milk',
      hex: 'D5CCC1',
      rgb: {
        r: 213,
        g: 204,
        b: 193,
      },
      lab: {
        l: 82.64,
        a: 2.54,
        b: 6.55,
      },
      cmyk: null,
    },
    {
      code: '13-0400 TPG',
      name: 'French Oak',
      hex: 'CCC1B3',
      rgb: {
        r: 204,
        g: 193,
        b: 179,
      },
      lab: {
        l: 78.75,
        a: 2.77,
        b: 8.36,
      },
      cmyk: null,
    },
    {
      code: '14-0002 TPG',
      name: 'Pumice Stone',
      hex: 'C4BEB3',
      rgb: {
        r: 196,
        g: 190,
        b: 179,
      },
      lab: {
        l: 77.18,
        a: 1.08,
        b: 6.02,
      },
      cmyk: null,
    },
    {
      code: '13-5304 TPG',
      name: 'Rainy Day',
      hex: 'D4CBBE',
      rgb: {
        r: 212,
        g: 203,
        b: 190,
      },
      lab: {
        l: 82.15,
        a: 2.27,
        b: 7.45,
      },
      cmyk: null,
    },
    {
      code: '13-0000 TPG',
      name: 'Moonbeam',
      hex: 'D6CFC7',
      rgb: {
        r: 214,
        g: 207,
        b: 199,
      },
      lab: {
        l: 83.67,
        a: 1.39,
        b: 5.35,
      },
      cmyk: null,
    },
    {
      code: '12-0105 TPG',
      name: 'Bone White',
      hex: 'DED7C3',
      rgb: {
        r: 222,
        g: 215,
        b: 195,
      },
      lab: {
        l: 86.05,
        a: 0.66,
        b: 10.38,
      },
      cmyk: null,
    },
    {
      code: '13-0607 TPG',
      name: 'Fog',
      hex: 'D5C9B6',
      rgb: {
        r: 213,
        g: 201,
        b: 182,
      },
      lab: {
        l: 81.72,
        a: 1.63,
        b: 11.36,
      },
      cmyk: null,
    },
    {
      code: '14-1107 TPG',
      name: 'Oyster Gray',
      hex: 'CFC3B1',
      rgb: {
        r: 207,
        g: 195,
        b: 177,
      },
      lab: {
        l: 79.52,
        a: 2.36,
        b: 10.67,
      },
      cmyk: null,
    },
    {
      code: '14-1106 TPG',
      name: 'Peyote',
      hex: 'C4BCAD',
      rgb: {
        r: 196,
        g: 188,
        b: 173,
      },
      lab: {
        l: 76.75,
        a: 0.95,
        b: 8.84,
      },
      cmyk: null,
    },
    {
      code: '15-1305 TPG',
      name: 'Feather Gray',
      hex: 'C0B3A2',
      rgb: {
        r: 192,
        g: 179,
        b: 162,
      },
      lab: {
        l: 73.67,
        a: 3.43,
        b: 9.98,
      },
      cmyk: null,
    },
    {
      code: '15-1306 TPG',
      name: 'Oxford Tan',
      hex: 'BCAD9E',
      rgb: {
        r: 188,
        g: 173,
        b: 158,
      },
      lab: {
        l: 71.86,
        a: 4.49,
        b: 9.84,
      },
      cmyk: null,
    },
    {
      code: '15-1304 TPG',
      name: 'Humus',
      hex: 'B7A897',
      rgb: {
        r: 183,
        g: 168,
        b: 151,
      },
      lab: {
        l: 69.99,
        a: 3.51,
        b: 11.25,
      },
      cmyk: null,
    },
    {
      code: '12-4302 TPG',
      name: 'Vaporous Gray',
      hex: 'E1E1DB',
      rgb: {
        r: 225,
        g: 225,
        b: 219,
      },
      lab: {
        l: 89.55,
        a: -0.8,
        b: 3,
      },
      cmyk: null,
    },
    {
      code: '12-4300 TPG',
      name: 'White Onyx',
      hex: 'D9D8D0',
      rgb: {
        r: 217,
        g: 216,
        b: 208,
      },
      lab: {
        l: 86.14,
        a: -0.11,
        b: 3.57,
      },
      cmyk: null,
    },
    {
      code: '13-4403 TPG',
      name: 'Silver Birch',
      hex: 'CECDC1',
      rgb: {
        r: 206,
        g: 205,
        b: 193,
      },
      lab: {
        l: 82.17,
        a: -0.93,
        b: 6.03,
      },
      cmyk: null,
    },
    {
      code: '12-4301 TPG',
      name: 'Almond Milk',
      hex: 'D9D0BE',
      rgb: {
        r: 217,
        g: 208,
        b: 190,
      },
      lab: {
        l: 83.89,
        a: 1.24,
        b: 10.23,
      },
      cmyk: null,
    },
    {
      code: '14-0105 TPG',
      name: 'Overcast',
      hex: 'C4C0AA',
      rgb: {
        r: 196,
        g: 192,
        b: 170,
      },
      lab: {
        l: 77.59,
        a: -1.47,
        b: 11.78,
      },
      cmyk: null,
    },
    {
      code: '14-6305 TPG',
      name: 'Pelican',
      hex: 'C6C0B1',
      rgb: {
        r: 198,
        g: 192,
        b: 177,
      },
      lab: {
        l: 77.77,
        a: 0.93,
        b: 8.19,
      },
      cmyk: null,
    },
    {
      code: '16-1105 TPG',
      name: 'Plaza Taupe',
      hex: 'B3A898',
      rgb: {
        r: 179,
        g: 168,
        b: 152,
      },
      lab: {
        l: 69.42,
        a: 2.87,
        b: 9.62,
      },
      cmyk: null,
    },
    {
      code: '12-5201 TPG',
      name: 'Icicle',
      hex: 'DFE1D3',
      rgb: {
        r: 223,
        g: 225,
        b: 211,
      },
      lab: {
        l: 89.05,
        a: -1.97,
        b: 6.25,
      },
      cmyk: null,
    },
    {
      code: '12-0404 TPG',
      name: 'Light Gray',
      hex: 'DAD6CA',
      rgb: {
        r: 218,
        g: 214,
        b: 202,
      },
      lab: {
        l: 85.76,
        a: 0.3,
        b: 6.09,
      },
      cmyk: null,
    },
    {
      code: '12-6204 TPG',
      name: 'Silver Green',
      hex: 'D6D5C6',
      rgb: {
        r: 214,
        g: 213,
        b: 198,
      },
      lab: {
        l: 84.98,
        a: -0.92,
        b: 7.51,
      },
      cmyk: null,
    },
    {
      code: '14-6308 TPG',
      name: 'Alfalfa',
      hex: 'B9B9A4',
      rgb: {
        r: 185,
        g: 185,
        b: 164,
      },
      lab: {
        l: 74.63,
        a: -2.09,
        b: 10.15,
      },
      cmyk: null,
    },
    {
      code: '15-6410 TPG',
      name: 'Moss Gray',
      hex: 'AAA994',
      rgb: {
        r: 170,
        g: 169,
        b: 148,
      },
      lab: {
        l: 68.89,
        a: -2.56,
        b: 11.08,
      },
      cmyk: null,
    },
    {
      code: '15-6307 TPG',
      name: 'Agate Gray',
      hex: 'B4B4A5',
      rgb: {
        r: 180,
        g: 180,
        b: 165,
      },
      lab: {
        l: 72.84,
        a: -1.42,
        b: 7.27,
      },
      cmyk: null,
    },
    {
      code: '14-6408 TPG',
      name: 'Abbey Stone',
      hex: 'A5A696',
      rgb: {
        r: 165,
        g: 166,
        b: 150,
      },
      lab: {
        l: 67.7,
        a: -2.43,
        b: 8.09,
      },
      cmyk: null,
    },
    {
      code: '12-5202 TPG',
      name: 'Turtledove',
      hex: 'DED7C7',
      rgb: {
        r: 222,
        g: 215,
        b: 199,
      },
      lab: {
        l: 86.35,
        a: 0.56,
        b: 9.06,
      },
      cmyk: null,
    },
    {
      code: '12-0104 TPG',
      name: 'White Asparagus',
      hex: 'E3DECA',
      rgb: {
        r: 227,
        g: 222,
        b: 202,
      },
      lab: {
        l: 88.56,
        a: -0.74,
        b: 10.72,
      },
      cmyk: null,
    },
    {
      code: '12-0110 TPG',
      name: 'Pistachio Shell',
      hex: 'DED5BE',
      rgb: {
        r: 222,
        g: 213,
        b: 190,
      },
      lab: {
        l: 85.68,
        a: 0.59,
        b: 12.61,
      },
      cmyk: null,
    },
    {
      code: '14-0108 TPG',
      name: 'Castle Wall',
      hex: 'CEC4AD',
      rgb: {
        r: 206,
        g: 196,
        b: 173,
      },
      lab: {
        l: 79.43,
        a: 1.55,
        b: 12.49,
      },
      cmyk: null,
    },
    {
      code: '14-0210 TPG',
      name: 'Tidal Foam',
      hex: 'C3BCA4',
      rgb: {
        r: 195,
        g: 188,
        b: 164,
      },
      lab: {
        l: 76.32,
        a: 0.23,
        b: 13.23,
      },
      cmyk: null,
    },
    {
      code: '15-0513 TPG',
      name: 'Eucalyptus',
      hex: 'ADA890',
      rgb: {
        r: 173,
        g: 168,
        b: 144,
      },
      lab: {
        l: 68.85,
        a: -1.13,
        b: 13.3,
      },
      cmyk: null,
    },
    {
      code: '15-0309 TPG',
      name: 'Spray Green',
      hex: 'B2AA97',
      rgb: {
        r: 178,
        g: 170,
        b: 151,
      },
      lab: {
        l: 69.91,
        a: 1.02,
        b: 10.86,
      },
      cmyk: null,
    },
    {
      code: '12-0311 TPG',
      name: 'Asparagus Green',
      hex: 'D7D4B8',
      rgb: {
        r: 215,
        g: 212,
        b: 184,
      },
      lab: {
        l: 84.67,
        a: -1.99,
        b: 13.84,
      },
      cmyk: null,
    },
    {
      code: '13-1007 TPG',
      name: 'Oyster White',
      hex: 'D6CDB1',
      rgb: {
        r: 214,
        g: 205,
        b: 177,
      },
      lab: {
        l: 82.74,
        a: -0.13,
        b: 15.17,
      },
      cmyk: null,
    },
    {
      code: '13-0611 TPG',
      name: 'Moth',
      hex: 'D3CEB0',
      rgb: {
        r: 211,
        g: 206,
        b: 176,
      },
      lab: {
        l: 82.68,
        a: -1.71,
        b: 15.62,
      },
      cmyk: null,
    },
    {
      code: '14-0615 TPG',
      name: 'Green Haze',
      hex: 'CCC8A5',
      rgb: {
        r: 204,
        g: 200,
        b: 165,
      },
      lab: {
        l: 80.31,
        a: -2.38,
        b: 17.75,
      },
      cmyk: null,
    },
    {
      code: '15-1115 TPG',
      name: 'Fields of Rye',
      hex: 'B8AC94',
      rgb: {
        r: 184,
        g: 172,
        b: 148,
      },
      lab: {
        l: 70.86,
        a: 2.69,
        b: 13.7,
      },
      cmyk: null,
    },
    {
      code: '17-1107 TPG',
      name: 'Seneca Rock',
      hex: '958E7C',
      rgb: {
        r: 149,
        g: 142,
        b: 124,
      },
      lab: {
        l: 59.34,
        a: -0.05,
        b: 10.74,
      },
      cmyk: null,
    },
    {
      code: '17-0510 TPG',
      name: 'Silver Sage',
      hex: '979280',
      rgb: {
        r: 151,
        g: 146,
        b: 128,
      },
      lab: {
        l: 60.69,
        a: -0.65,
        b: 10.14,
      },
      cmyk: null,
    },
    {
      code: '13-0513 TPG',
      name: 'Frozen Dew',
      hex: 'DCD4B8',
      rgb: {
        r: 220,
        g: 212,
        b: 184,
      },
      lab: {
        l: 84.88,
        a: -0.01,
        b: 14.46,
      },
      cmyk: null,
    },
    {
      code: '13-0711 TPG',
      name: 'Putty',
      hex: 'D4CDB0',
      rgb: {
        r: 212,
        g: 205,
        b: 176,
      },
      lab: {
        l: 82.41,
        a: -0.87,
        b: 15.42,
      },
      cmyk: null,
    },
    {
      code: '13-1105 TPG',
      name: 'Brown Rice',
      hex: 'C9BCA4',
      rgb: {
        r: 201,
        g: 188,
        b: 164,
      },
      lab: {
        l: 76.77,
        a: 2.32,
        b: 13.82,
      },
      cmyk: null,
    },
    {
      code: '16-1119 TPG',
      name: 'Lemon Pepper',
      hex: 'A29780',
      rgb: {
        r: 162,
        g: 151,
        b: 128,
      },
      lab: {
        l: 63.01,
        a: 1.77,
        b: 13.36,
      },
      cmyk: null,
    },
    {
      code: '17-1108 TPG',
      name: 'Winter Twig',
      hex: '998F7F',
      rgb: {
        r: 153,
        g: 143,
        b: 127,
      },
      lab: {
        l: 59.91,
        a: 2.67,
        b: 9.82,
      },
      cmyk: null,
    },
    {
      code: '17-1113 TPG',
      name: 'Coriander',
      hex: '998D7A',
      rgb: {
        r: 153,
        g: 141,
        b: 122,
      },
      lab: {
        l: 59.37,
        a: 2.64,
        b: 12.03,
      },
      cmyk: null,
    },
    {
      code: '17-1118 TPG',
      name: 'Lead Gray',
      hex: '857861',
      rgb: {
        r: 133,
        g: 120,
        b: 97,
      },
      lab: {
        l: 51.32,
        a: 2.51,
        b: 15.08,
      },
      cmyk: null,
    },
    {
      code: '15-1116 TPG',
      name: 'Safari',
      hex: 'C0AD94',
      rgb: {
        r: 192,
        g: 173,
        b: 148,
      },
      lab: {
        l: 72.07,
        a: 4.95,
        b: 15.42,
      },
      cmyk: null,
    },
    {
      code: '16-1010 TPG',
      name: 'Incense',
      hex: 'AB997C',
      rgb: {
        r: 171,
        g: 153,
        b: 124,
      },
      lab: {
        l: 64.34,
        a: 3.66,
        b: 18.32,
      },
      cmyk: null,
    },
    {
      code: '15-1114 TPG',
      name: 'Travertine',
      hex: 'B09D83',
      rgb: {
        r: 176,
        g: 157,
        b: 131,
      },
      lab: {
        l: 65.84,
        a: 5.2,
        b: 16.05,
      },
      cmyk: null,
    },
    {
      code: '16-1315 TPG',
      name: 'Cornstalk',
      hex: 'AC987D',
      rgb: {
        r: 172,
        g: 152,
        b: 125,
      },
      lab: {
        l: 64.15,
        a: 6.01,
        b: 16.84,
      },
      cmyk: null,
    },
    {
      code: '17-1022 TPG',
      name: 'Kelp',
      hex: '998C6E',
      rgb: {
        r: 153,
        g: 140,
        b: 110,
      },
      lab: {
        l: 58.92,
        a: 1.43,
        b: 18.17,
      },
      cmyk: null,
    },
    {
      code: '17-1115 TPG',
      name: 'Petrified Oak',
      hex: '8F7C64',
      rgb: {
        r: 143,
        g: 124,
        b: 100,
      },
      lab: {
        l: 53.57,
        a: 5.22,
        b: 16.23,
      },
      cmyk: null,
    },
    {
      code: '17-1314 TPG',
      name: 'Sepia Tint',
      hex: '8E7A66',
      rgb: {
        r: 142,
        g: 122,
        b: 102,
      },
      lab: {
        l: 52.9,
        a: 5.98,
        b: 14.05,
      },
      cmyk: null,
    },
    {
      code: '14-1108 TPG',
      name: 'Wood Ash',
      hex: 'DDCDB1',
      rgb: {
        r: 221,
        g: 205,
        b: 177,
      },
      lab: {
        l: 83.2,
        a: 3.56,
        b: 15.82,
      },
      cmyk: null,
    },
    {
      code: '15-1218 TPG',
      name: 'Semolina',
      hex: 'D1BC9E',
      rgb: {
        r: 209,
        g: 188,
        b: 158,
      },
      lab: {
        l: 77.5,
        a: 5,
        b: 18.29,
      },
      cmyk: null,
    },
    {
      code: '14-1112 TPG',
      name: 'Pebble',
      hex: 'D2BC9C',
      rgb: {
        r: 210,
        g: 188,
        b: 156,
      },
      lab: {
        l: 77.5,
        a: 5.35,
        b: 18.8,
      },
      cmyk: null,
    },
    {
      code: '14-1208 TPG',
      name: 'Irish Cream',
      hex: 'C2AD92',
      rgb: {
        r: 194,
        g: 173,
        b: 146,
      },
      lab: {
        l: 72.17,
        a: 5.18,
        b: 16.79,
      },
      cmyk: null,
    },
    {
      code: '16-1213 TPG',
      name: 'Savannah Tan',
      hex: 'A89583',
      rgb: {
        r: 168,
        g: 149,
        b: 131,
      },
      lab: {
        l: 63.11,
        a: 5.85,
        b: 12.06,
      },
      cmyk: null,
    },
    {
      code: '17-1112 TPG',
      name: 'Weathered Teak',
      hex: '9F9283',
      rgb: {
        r: 159,
        g: 146,
        b: 131,
      },
      lab: {
        l: 61.37,
        a: 3.86,
        b: 9.67,
      },
      cmyk: null,
    },
    {
      code: '17-1312 TPG',
      name: 'Silver Mink',
      hex: 'A49282',
      rgb: {
        r: 164,
        g: 146,
        b: 130,
      },
      lab: {
        l: 62.08,
        a: 5.52,
        b: 11.43,
      },
      cmyk: null,
    },
    {
      code: '14-1014 TPG',
      name: 'Gravel',
      hex: 'CFC2A5',
      rgb: {
        r: 207,
        g: 194,
        b: 165,
      },
      lab: {
        l: 79.01,
        a: 2.06,
        b: 16.29,
      },
      cmyk: null,
    },
    {
      code: '15-1217 TPG',
      name: 'Mojave Desert',
      hex: 'C7B795',
      rgb: {
        r: 199,
        g: 183,
        b: 149,
      },
      lab: {
        l: 75.25,
        a: 1.92,
        b: 19.85,
      },
      cmyk: null,
    },
    {
      code: '15-1216 TPG',
      name: 'Pale Khaki',
      hex: 'C3B296',
      rgb: {
        r: 195,
        g: 178,
        b: 150,
      },
      lab: {
        l: 73.72,
        a: 3.84,
        b: 16.83,
      },
      cmyk: null,
    },
    {
      code: '15-1307 TPG',
      name: 'White Pepper',
      hex: 'BAAA95',
      rgb: {
        r: 186,
        g: 170,
        b: 149,
      },
      lab: {
        l: 70.67,
        a: 4.32,
        b: 13.15,
      },
      cmyk: null,
    },
    {
      code: '16-1101 TPG',
      name: 'Trench Coat',
      hex: 'AC9F8B',
      rgb: {
        r: 172,
        g: 159,
        b: 139,
      },
      lab: {
        l: 66.23,
        a: 2.58,
        b: 12.28,
      },
      cmyk: null,
    },
    {
      code: '16-1104 TPG',
      name: 'Crockery',
      hex: 'AB9D8D',
      rgb: {
        r: 171,
        g: 157,
        b: 141,
      },
      lab: {
        l: 65.81,
        a: 3.86,
        b: 10.24,
      },
      cmyk: null,
    },
    {
      code: '17-1109 TPG',
      name: 'Chinchilla',
      hex: '9B927B',
      rgb: {
        r: 155,
        g: 146,
        b: 123,
      },
      lab: {
        l: 61,
        a: 0.68,
        b: 13.55,
      },
      cmyk: null,
    },
    {
      code: '14-1110 TPG',
      name: 'Boulder',
      hex: 'D1BE9A',
      rgb: {
        r: 209,
        g: 190,
        b: 154,
      },
      lab: {
        l: 78.11,
        a: 2.58,
        b: 20.96,
      },
      cmyk: null,
    },
    {
      code: '14-1116 TPG',
      name: 'Almond Buff',
      hex: 'D1B894',
      rgb: {
        r: 209,
        g: 184,
        b: 148,
      },
      lab: {
        l: 76.53,
        a: 5.12,
        b: 22.08,
      },
      cmyk: null,
    },
    {
      code: '15-1119 TPG',
      name: 'Taos Taupe',
      hex: 'BFAB83',
      rgb: {
        r: 191,
        g: 171,
        b: 131,
      },
      lab: {
        l: 71.04,
        a: 3.06,
        b: 23.61,
      },
      cmyk: null,
    },
    {
      code: '16-0924 TPG',
      name: 'Croissant',
      hex: 'C9AD89',
      rgb: {
        r: 201,
        g: 173,
        b: 137,
      },
      lab: {
        l: 72.72,
        a: 7.8,
        b: 22.54,
      },
      cmyk: null,
    },
    {
      code: '16-0920 TPG',
      name: 'Curds & Whey',
      hex: 'C0A686',
      rgb: {
        r: 192,
        g: 166,
        b: 134,
      },
      lab: {
        l: 70.09,
        a: 7.09,
        b: 20.64,
      },
      cmyk: null,
    },
    {
      code: '16-1120 TPG',
      name: 'Starfish',
      hex: 'B49D7B',
      rgb: {
        r: 180,
        g: 157,
        b: 123,
      },
      lab: {
        l: 66.26,
        a: 5.57,
        b: 21,
      },
      cmyk: null,
    },
    {
      code: '16-1324 TPG',
      name: 'Lark',
      hex: 'BE9F77',
      rgb: {
        r: 190,
        g: 159,
        b: 119,
      },
      lab: {
        l: 67.87,
        a: 8.32,
        b: 25.85,
      },
      cmyk: null,
    },
    {
      code: '13-1009 TPG',
      name: 'Biscotti',
      hex: 'DCCAA9',
      rgb: {
        r: 220,
        g: 202,
        b: 169,
      },
      lab: {
        l: 82.36,
        a: 2.52,
        b: 19.05,
      },
      cmyk: null,
    },
    {
      code: '13-1016 TPG',
      name: 'Wheat',
      hex: 'E2C6A5',
      rgb: {
        r: 226,
        g: 198,
        b: 165,
      },
      lab: {
        l: 81.81,
        a: 6.68,
        b: 20.89,
      },
      cmyk: null,
    },
    {
      code: '13-1011 TPG',
      name: 'Ivory Cream',
      hex: 'DFC5AC',
      rgb: {
        r: 223,
        g: 197,
        b: 172,
      },
      lab: {
        l: 81.46,
        a: 6.51,
        b: 16.49,
      },
      cmyk: null,
    },
    {
      code: '14-1118 TPG',
      name: 'Beige',
      hex: 'D4B996',
      rgb: {
        r: 212,
        g: 185,
        b: 150,
      },
      lab: {
        l: 77,
        a: 6.42,
        b: 21.84,
      },
      cmyk: null,
    },
    {
      code: '15-1214 TPG',
      name: 'Warm Sand',
      hex: 'CBB395',
      rgb: {
        r: 203,
        g: 179,
        b: 149,
      },
      lab: {
        l: 74.48,
        a: 6.48,
        b: 18.32,
      },
      cmyk: null,
    },
    {
      code: '15-1020 TPG',
      name: 'Ginger Root',
      hex: 'C1A68B',
      rgb: {
        r: 193,
        g: 166,
        b: 139,
      },
      lab: {
        l: 70.14,
        a: 8.07,
        b: 17.71,
      },
      cmyk: null,
    },
    {
      code: '17-1320 TPG',
      name: 'Tannin',
      hex: 'A68B6F',
      rgb: {
        r: 166,
        g: 139,
        b: 111,
      },
      lab: {
        l: 60.17,
        a: 7.52,
        b: 19.41,
      },
      cmyk: null,
    },
    {
      code: '12-0811 TPG',
      name: 'Dawn',
      hex: 'F1D7BA',
      rgb: {
        r: 241,
        g: 215,
        b: 186,
      },
      lab: {
        l: 87.86,
        a: 6.07,
        b: 18.3,
      },
      cmyk: null,
    },
    {
      code: '13-1010 TPG',
      name: 'Gray Sand',
      hex: 'ECD4B6',
      rgb: {
        r: 236,
        g: 212,
        b: 182,
      },
      lab: {
        l: 86.51,
        a: 5.06,
        b: 18.42,
      },
      cmyk: null,
    },
    {
      code: '14-1120 TPG',
      name: 'Apricot Illusion',
      hex: 'E9C7A4',
      rgb: {
        r: 233,
        g: 199,
        b: 164,
      },
      lab: {
        l: 82.64,
        a: 8.9,
        b: 22.52,
      },
      cmyk: null,
    },
    {
      code: '13-1013 TPG',
      name: 'Appleblossom',
      hex: 'E3C3A5',
      rgb: {
        r: 227,
        g: 195,
        b: 165,
      },
      lab: {
        l: 81.18,
        a: 8.79,
        b: 20.01,
      },
      cmyk: null,
    },
    {
      code: '14-1119 TPG',
      name: 'Winter Wheat',
      hex: 'E4C49D',
      rgb: {
        r: 228,
        g: 196,
        b: 157,
      },
      lab: {
        l: 81.39,
        a: 7.81,
        b: 24.32,
      },
      cmyk: null,
    },
    {
      code: '13-1015 TPG',
      name: 'Honey Peach',
      hex: 'DEC19C',
      rgb: {
        r: 222,
        g: 193,
        b: 156,
      },
      lab: {
        l: 80,
        a: 6.83,
        b: 22.77,
      },
      cmyk: null,
    },
    {
      code: '13-1014 TPG',
      name: 'Mellow Buff',
      hex: 'E0C2A0',
      rgb: {
        r: 224,
        g: 194,
        b: 160,
      },
      lab: {
        l: 80.66,
        a: 7.25,
        b: 21.99,
      },
      cmyk: null,
    },
    {
      code: '12-1009 TPG',
      name: 'Vanilla Cream',
      hex: 'F9DDCA',
      rgb: {
        r: 249,
        g: 221,
        b: 202,
      },
      lab: {
        l: 90.18,
        a: 7.9,
        b: 13.5,
      },
      cmyk: null,
    },
    {
      code: '12-1006 TPG',
      name: 'Mother of Pearl',
      hex: 'EBD9C6',
      rgb: {
        r: 235,
        g: 217,
        b: 198,
      },
      lab: {
        l: 87.95,
        a: 4.31,
        b: 12.24,
      },
      cmyk: null,
    },
    {
      code: '12-1008 TPG',
      name: 'Linen',
      hex: 'EFD6C0',
      rgb: {
        r: 239,
        g: 214,
        b: 192,
      },
      lab: {
        l: 87.46,
        a: 6.69,
        b: 14.5,
      },
      cmyk: null,
    },
    {
      code: '12-0912 TPG',
      name: 'Tender Peach',
      hex: 'F7D4B4',
      rgb: {
        r: 247,
        g: 212,
        b: 180,
      },
      lab: {
        l: 87.5,
        a: 9.72,
        b: 20.86,
      },
      cmyk: null,
    },
    {
      code: '12-0911 TPG',
      name: 'Peach Taffy',
      hex: 'F3D3B7',
      rgb: {
        r: 243,
        g: 211,
        b: 183,
      },
      lab: {
        l: 86.9,
        a: 8.63,
        b: 18.43,
      },
      cmyk: null,
    },
    {
      code: '12-1011 TPG',
      name: 'Peach Purée',
      hex: 'F3D0B6',
      rgb: {
        r: 243,
        g: 208,
        b: 182,
      },
      lab: {
        l: 86.33,
        a: 9.95,
        b: 18.13,
      },
      cmyk: null,
    },
    {
      code: '12-0913 TPG',
      name: 'Alesan',
      hex: 'F4CFB2',
      rgb: {
        r: 244,
        g: 207,
        b: 178,
      },
      lab: {
        l: 85.93,
        a: 10.46,
        b: 19.8,
      },
      cmyk: null,
    },
    {
      code: '12-1007 TPG',
      name: 'Pastel Rose Tan',
      hex: 'E8D0BB',
      rgb: {
        r: 232,
        g: 208,
        b: 187,
      },
      lab: {
        l: 85.24,
        a: 6.27,
        b: 13.99,
      },
      cmyk: null,
    },
    {
      code: '12-0807 TPG',
      name: 'Sun Kiss',
      hex: 'ECD3BC',
      rgb: {
        r: 236,
        g: 211,
        b: 188,
      },
      lab: {
        l: 86.41,
        a: 6.65,
        b: 15.07,
      },
      cmyk: null,
    },
    {
      code: '12-1005 TPG',
      name: 'Novelle Peach',
      hex: 'E7D2BB',
      rgb: {
        r: 231,
        g: 210,
        b: 187,
      },
      lab: {
        l: 85.77,
        a: 4.95,
        b: 14.51,
      },
      cmyk: null,
    },
    {
      code: '14-1210 TPG',
      name: 'Shifting Sand',
      hex: 'D8C4AC',
      rgb: {
        r: 216,
        g: 196,
        b: 172,
      },
      lab: {
        l: 80.5,
        a: 4.71,
        b: 15.05,
      },
      cmyk: null,
    },
    {
      code: '14-1217 TPG',
      name: 'Amberlight',
      hex: 'DEB99C',
      rgb: {
        r: 222,
        g: 185,
        b: 156,
      },
      lab: {
        l: 78.05,
        a: 11.04,
        b: 20.26,
      },
      cmyk: null,
    },
    {
      code: '14-1213 TPG',
      name: 'Toasted Almond',
      hex: 'D0B098',
      rgb: {
        r: 208,
        g: 176,
        b: 152,
      },
      lab: {
        l: 74.47,
        a: 9.92,
        b: 17.23,
      },
      cmyk: null,
    },
    {
      code: '14-1315 TPG',
      name: 'Hazelnut',
      hex: 'D3B296',
      rgb: {
        r: 211,
        g: 178,
        b: 150,
      },
      lab: {
        l: 75.14,
        a: 9.82,
        b: 19.35,
      },
      cmyk: null,
    },
    {
      code: '14-1209 TPG',
      name: 'Smoke Gray',
      hex: 'CBB7A2',
      rgb: {
        r: 203,
        g: 183,
        b: 162,
      },
      lab: {
        l: 75.72,
        a: 5.02,
        b: 13.57,
      },
      cmyk: null,
    },
    {
      code: '14-0708 TPG',
      name: 'Cement',
      hex: 'C7B8A7',
      rgb: {
        r: 199,
        g: 184,
        b: 167,
      },
      lab: {
        l: 75.81,
        a: 4.36,
        b: 10.59,
      },
      cmyk: null,
    },
    {
      code: '14-1212 TPG',
      name: 'Frappé',
      hex: 'D2B8A1',
      rgb: {
        r: 210,
        g: 184,
        b: 161,
      },
      lab: {
        l: 76.71,
        a: 7.31,
        b: 15.34,
      },
      cmyk: null,
    },
    {
      code: '15-1215 TPG',
      name: 'Sesame',
      hex: 'BFA68F',
      rgb: {
        r: 191,
        g: 166,
        b: 143,
      },
      lab: {
        l: 69.95,
        a: 7.68,
        b: 15.22,
      },
      cmyk: null,
    },
    {
      code: '16-1212 TPG',
      name: 'Nomad',
      hex: 'BAA48F',
      rgb: {
        r: 186,
        g: 164,
        b: 143,
      },
      lab: {
        l: 68.95,
        a: 6.71,
        b: 13.72,
      },
      cmyk: null,
    },
    {
      code: '15-1314 TPG',
      name: 'Cuban Sand',
      hex: 'C3A990',
      rgb: {
        r: 195,
        g: 169,
        b: 144,
      },
      lab: {
        l: 71.3,
        a: 7.15,
        b: 16.92,
      },
      cmyk: null,
    },
    {
      code: '15-1213 TPG',
      name: 'Candied Ginger',
      hex: 'C8AA8F',
      rgb: {
        r: 200,
        g: 170,
        b: 143,
      },
      lab: {
        l: 71.82,
        a: 9.8,
        b: 17.93,
      },
      cmyk: null,
    },
    {
      code: '15-1309 TPG',
      name: 'Moonlight',
      hex: 'C2AE9D',
      rgb: {
        r: 194,
        g: 174,
        b: 157,
      },
      lab: {
        l: 72.67,
        a: 5.33,
        b: 11.73,
      },
      cmyk: null,
    },
    {
      code: '16-1210 TPG',
      name: 'Light Taupe',
      hex: 'B6A293',
      rgb: {
        r: 182,
        g: 162,
        b: 147,
      },
      lab: {
        l: 68.11,
        a: 7.29,
        b: 10.34,
      },
      cmyk: null,
    },
    {
      code: '15-1308 TPG',
      name: 'Doeskin',
      hex: 'BEAD9A',
      rgb: {
        r: 190,
        g: 173,
        b: 154,
      },
      lab: {
        l: 72,
        a: 3.76,
        b: 12.27,
      },
      cmyk: null,
    },
    {
      code: '16-1310 TPG',
      name: 'Natural',
      hex: 'AD957F',
      rgb: {
        r: 173,
        g: 149,
        b: 127,
      },
      lab: {
        l: 63.57,
        a: 7.08,
        b: 15.45,
      },
      cmyk: null,
    },
    {
      code: '16-1318 TPG',
      name: 'Warm Taupe',
      hex: 'B19986',
      rgb: {
        r: 177,
        g: 153,
        b: 134,
      },
      lab: {
        l: 65.06,
        a: 7.3,
        b: 13.55,
      },
      cmyk: null,
    },
    {
      code: '17-1319 TPG',
      name: 'Amphora',
      hex: '9B8571',
      rgb: {
        r: 155,
        g: 133,
        b: 113,
      },
      lab: {
        l: 57.4,
        a: 6.61,
        b: 14.42,
      },
      cmyk: null,
    },
    {
      code: '17-1316 TPG',
      name: 'Portabella',
      hex: '9A8577',
      rgb: {
        r: 154,
        g: 133,
        b: 119,
      },
      lab: {
        l: 57.19,
        a: 7.22,
        b: 10.81,
      },
      cmyk: null,
    },
    {
      code: '16-1412 TPG',
      name: 'Stucco',
      hex: 'A99185',
      rgb: {
        r: 169,
        g: 145,
        b: 133,
      },
      lab: {
        l: 62.34,
        a: 8.63,
        b: 10.28,
      },
      cmyk: null,
    },
    {
      code: '16-1415 TPG',
      name: 'Almondine',
      hex: 'AB8F7F',
      rgb: {
        r: 171,
        g: 143,
        b: 127,
      },
      lab: {
        l: 61.98,
        a: 9.72,
        b: 12.86,
      },
      cmyk: null,
    },
    {
      code: '16-1414 TPG',
      name: 'Chanterelle',
      hex: 'A38C79',
      rgb: {
        r: 163,
        g: 140,
        b: 121,
      },
      lab: {
        l: 60.2,
        a: 6.86,
        b: 13.78,
      },
      cmyk: null,
    },
    {
      code: '17-1418 TPG',
      name: 'Ginger Snap',
      hex: '968172',
      rgb: {
        r: 150,
        g: 129,
        b: 114,
      },
      lab: {
        l: 55.7,
        a: 6.74,
        b: 11.28,
      },
      cmyk: null,
    },
    {
      code: '17-1321 TPG',
      name: 'Woodsmoke',
      hex: '927A68',
      rgb: {
        r: 146,
        g: 122,
        b: 104,
      },
      lab: {
        l: 53.18,
        a: 7.73,
        b: 13.79,
      },
      cmyk: null,
    },
    {
      code: '17-1417 TPG',
      name: 'Beaver Fur',
      hex: '957867',
      rgb: {
        r: 149,
        g: 120,
        b: 103,
      },
      lab: {
        l: 53.19,
        a: 9.8,
        b: 14.21,
      },
      cmyk: null,
    },
    {
      code: '18-1321 TPG',
      name: 'Brownie',
      hex: '8F7467',
      rgb: {
        r: 143,
        g: 116,
        b: 103,
      },
      lab: {
        l: 51.47,
        a: 9.65,
        b: 12.07,
      },
      cmyk: null,
    },
    {
      code: '13-0403 TPG',
      name: 'Gray Morn',
      hex: 'CFC2B8',
      rgb: {
        r: 207,
        g: 194,
        b: 184,
      },
      lab: {
        l: 79.3,
        a: 4.36,
        b: 6.33,
      },
      cmyk: null,
    },
    {
      code: '14-1305 TPG',
      name: 'Mushroom',
      hex: 'BBAA9F',
      rgb: {
        r: 187,
        g: 170,
        b: 159,
      },
      lab: {
        l: 70.8,
        a: 5.44,
        b: 7.94,
      },
      cmyk: null,
    },
    {
      code: '16-0806 TPG',
      name: 'Goat',
      hex: 'AC9E95',
      rgb: {
        r: 172,
        g: 158,
        b: 149,
      },
      lab: {
        l: 66.13,
        a: 5.66,
        b: 6.23,
      },
      cmyk: null,
    },
    {
      code: '15-1506 TPG',
      name: 'Etherea',
      hex: 'A89791',
      rgb: {
        r: 168,
        g: 151,
        b: 145,
      },
      lab: {
        l: 63.97,
        a: 6.48,
        b: 5.57,
      },
      cmyk: null,
    },
    {
      code: '17-0807 TPG',
      name: 'Mountain Trail',
      hex: '8D7C70',
      rgb: {
        r: 141,
        g: 124,
        b: 112,
      },
      lab: {
        l: 53.45,
        a: 6.07,
        b: 8.85,
      },
      cmyk: null,
    },
    {
      code: '17-1412 TPG',
      name: 'Leafless Tree',
      hex: '87766E',
      rgb: {
        r: 135,
        g: 118,
        b: 110,
      },
      lab: {
        l: 51.29,
        a: 6.4,
        b: 7.18,
      },
      cmyk: null,
    },
    {
      code: '18-1312 TPG',
      name: 'Deep Taupe',
      hex: '73615C',
      rgb: {
        r: 115,
        g: 97,
        b: 92,
      },
      lab: {
        l: 42.95,
        a: 7.11,
        b: 6.24,
      },
      cmyk: null,
    },
    {
      code: '12-1406 TPG',
      name: 'Almond Peach',
      hex: 'DBCABE',
      rgb: {
        r: 219,
        g: 202,
        b: 190,
      },
      lab: {
        l: 82.61,
        a: 5.26,
        b: 8.01,
      },
      cmyk: null,
    },
    {
      code: '13-3801 TPG',
      name: 'Crystal Gray',
      hex: 'DACDC5',
      rgb: {
        r: 218,
        g: 205,
        b: 197,
      },
      lab: {
        l: 83.46,
        a: 3.74,
        b: 6.11,
      },
      cmyk: null,
    },
    {
      code: '16-1211 TPG',
      name: 'Mocha Meringue',
      hex: '9F8E84',
      rgb: {
        r: 159,
        g: 142,
        b: 132,
      },
      lab: {
        l: 60.57,
        a: 5.92,
        b: 7.69,
      },
      cmyk: null,
    },
    {
      code: '17-0808 TPG',
      name: 'Taupe Gray',
      hex: '938176',
      rgb: {
        r: 147,
        g: 129,
        b: 118,
      },
      lab: {
        l: 55.52,
        a: 6.29,
        b: 8.48,
      },
      cmyk: null,
    },
    {
      code: '17-1506 TPG',
      name: 'Cinder',
      hex: '8D837D',
      rgb: {
        r: 141,
        g: 131,
        b: 125,
      },
      lab: {
        l: 55.52,
        a: 4.12,
        b: 4.52,
      },
      cmyk: null,
    },
    {
      code: '17-1212 TPG',
      name: 'Fungi',
      hex: '8D8177',
      rgb: {
        r: 141,
        g: 129,
        b: 119,
      },
      lab: {
        l: 54.97,
        a: 3.36,
        b: 7.33,
      },
      cmyk: null,
    },
    {
      code: '17-1409 TPG',
      name: 'Brown Lentil',
      hex: '816F66',
      rgb: {
        r: 129,
        g: 111,
        b: 102,
      },
      lab: {
        l: 48.44,
        a: 6.73,
        b: 7.98,
      },
      cmyk: null,
    },
    {
      code: '15-4504 TPG',
      name: 'Nacreous Clouds',
      hex: 'BAB1AA',
      rgb: {
        r: 186,
        g: 177,
        b: 170,
      },
      lab: {
        l: 72.95,
        a: 3.01,
        b: 4.68,
      },
      cmyk: null,
    },
    {
      code: '16-1406 TPG',
      name: 'Atmosphere',
      hex: 'AA9E98',
      rgb: {
        r: 170,
        g: 158,
        b: 152,
      },
      lab: {
        l: 66.24,
        a: 4.3,
        b: 4.9,
      },
      cmyk: null,
    },
    {
      code: '16-3800 TPG',
      name: 'Satellite',
      hex: '99908D',
      rgb: {
        r: 153,
        g: 144,
        b: 141,
      },
      lab: {
        l: 60.55,
        a: 3.47,
        b: 2.84,
      },
      cmyk: null,
    },
    {
      code: '17-1210 TPG',
      name: 'Moon Rock',
      hex: '968F85',
      rgb: {
        r: 150,
        g: 143,
        b: 133,
      },
      lab: {
        l: 59.83,
        a: 1.7,
        b: 6.38,
      },
      cmyk: null,
    },
    {
      code: '18-1210 TPG',
      name: 'Driftwood',
      hex: '877F7B',
      rgb: {
        r: 135,
        g: 127,
        b: 123,
      },
      lab: {
        l: 53.91,
        a: 3.06,
        b: 3.63,
      },
      cmyk: null,
    },
    {
      code: '17-1410 TPG',
      name: 'Pine Bark',
      hex: '86766A',
      rgb: {
        r: 134,
        g: 118,
        b: 106,
      },
      lab: {
        l: 50.97,
        a: 4.54,
        b: 9.51,
      },
      cmyk: null,
    },
    {
      code: '18-1306 TPG',
      name: 'Iron',
      hex: '736763',
      rgb: {
        r: 115,
        g: 103,
        b: 99,
      },
      lab: {
        l: 44.73,
        a: 4.49,
        b: 4.11,
      },
      cmyk: null,
    },
    {
      code: '16-1102 TPG',
      name: 'Island Fossil',
      hex: 'B3AB9E',
      rgb: {
        r: 179,
        g: 171,
        b: 158,
      },
      lab: {
        l: 70.42,
        a: 1.58,
        b: 7.57,
      },
      cmyk: null,
    },
    {
      code: '16-1106 TPG',
      name: 'Tuffet',
      hex: 'A99A8A',
      rgb: {
        r: 169,
        g: 154,
        b: 138,
      },
      lab: {
        l: 64.67,
        a: 4.89,
        b: 10.05,
      },
      cmyk: null,
    },
    {
      code: '17-1009 TPG',
      name: 'Dune',
      hex: '9D8D7C',
      rgb: {
        r: 157,
        g: 141,
        b: 124,
      },
      lab: {
        l: 59.9,
        a: 5.13,
        b: 11.15,
      },
      cmyk: null,
    },
    {
      code: '17-1311 TPG',
      name: 'Desert Taupe',
      hex: '918679',
      rgb: {
        r: 145,
        g: 134,
        b: 121,
      },
      lab: {
        l: 56.68,
        a: 3.6,
        b: 8.23,
      },
      cmyk: null,
    },
    {
      code: '18-1017 TPG',
      name: 'Caribou',
      hex: '887569',
      rgb: {
        r: 136,
        g: 117,
        b: 105,
      },
      lab: {
        l: 50.99,
        a: 6.46,
        b: 9.87,
      },
      cmyk: null,
    },
    {
      code: '18-1015 TPG',
      name: 'Shitake',
      hex: '756759',
      rgb: {
        r: 117,
        g: 103,
        b: 89,
      },
      lab: {
        l: 44.7,
        a: 3.69,
        b: 10.35,
      },
      cmyk: null,
    },
    {
      code: '19-0916 TPG',
      name: 'Rain Drum',
      hex: '64554C',
      rgb: {
        r: 100,
        g: 85,
        b: 76,
      },
      lab: {
        l: 37.46,
        a: 5.61,
        b: 7.66,
      },
      cmyk: null,
    },
    {
      code: '16-1407 TPG',
      name: 'Cobblestone',
      hex: 'AC9F93',
      rgb: {
        r: 172,
        g: 159,
        b: 147,
      },
      lab: {
        l: 66.4,
        a: 4.28,
        b: 7.76,
      },
      cmyk: null,
    },
    {
      code: '16-1109 TPG',
      name: 'Greige',
      hex: '97897A',
      rgb: {
        r: 151,
        g: 137,
        b: 122,
      },
      lab: {
        l: 58,
        a: 3.9,
        b: 10,
      },
      cmyk: null,
    },
    {
      code: '17-0909 TPG',
      name: 'Fossil',
      hex: '807466',
      rgb: {
        r: 128,
        g: 116,
        b: 102,
      },
      lab: {
        l: 49.65,
        a: 3.28,
        b: 9.7,
      },
      cmyk: null,
    },
    {
      code: '18-1016 TPG',
      name: 'Cub',
      hex: '6C6152',
      rgb: {
        r: 108,
        g: 97,
        b: 82,
      },
      lab: {
        l: 41.82,
        a: 2.64,
        b: 10.58,
      },
      cmyk: null,
    },
    {
      code: '19-0809 TPG',
      name: 'Chocolate Chip',
      hex: '6A6054',
      rgb: {
        r: 106,
        g: 96,
        b: 84,
      },
      lab: {
        l: 41.46,
        a: 2.79,
        b: 8.45,
      },
      cmyk: null,
    },
    {
      code: '19-0808 TPG',
      name: 'Morel',
      hex: '70675F',
      rgb: {
        r: 112,
        g: 103,
        b: 95,
      },
      lab: {
        l: 44.18,
        a: 3.25,
        b: 5.64,
      },
      cmyk: null,
    },
    {
      code: '18-1304 TPG',
      name: 'Falcon',
      hex: '6F6560',
      rgb: {
        r: 111,
        g: 101,
        b: 96,
      },
      lab: {
        l: 43.74,
        a: 4.17,
        b: 4.1,
      },
      cmyk: null,
    },
    {
      code: '16-0906 TPG',
      name: 'Simply Taupe',
      hex: 'ACA093',
      rgb: {
        r: 172,
        g: 160,
        b: 147,
      },
      lab: {
        l: 66.61,
        a: 3.04,
        b: 8.44,
      },
      cmyk: null,
    },
    {
      code: '17-1105 TPG',
      name: 'Roasted Cashew',
      hex: '978B7F',
      rgb: {
        r: 151,
        g: 139,
        b: 127,
      },
      lab: {
        l: 58.86,
        a: 3.35,
        b: 8.27,
      },
      cmyk: null,
    },
    {
      code: '17-1310 TPG',
      name: 'Timber Wolf',
      hex: '8B8170',
      rgb: {
        r: 139,
        g: 129,
        b: 112,
      },
      lab: {
        l: 54.52,
        a: 1.62,
        b: 10.76,
      },
      cmyk: null,
    },
    {
      code: '18-0513 TPG',
      name: 'Bungee Cord',
      hex: '716A61',
      rgb: {
        r: 113,
        g: 106,
        b: 97,
      },
      lab: {
        l: 45.26,
        a: 2.33,
        b: 6.08,
      },
      cmyk: null,
    },
    {
      code: '19-0822 TPG',
      name: 'Tarmac',
      hex: '5A564E',
      rgb: {
        r: 90,
        g: 86,
        b: 78,
      },
      lab: {
        l: 36.9,
        a: 0.24,
        b: 5.65,
      },
      cmyk: null,
    },
    {
      code: '19-0810 TPG',
      name: 'Major Brown',
      hex: '615B52',
      rgb: {
        r: 97,
        g: 91,
        b: 82,
      },
      lab: {
        l: 38.99,
        a: 1.04,
        b: 5.9,
      },
      cmyk: null,
    },
    {
      code: '19-0823 TPG',
      name: 'Crocodile',
      hex: '645A51',
      rgb: {
        r: 100,
        g: 90,
        b: 81,
      },
      lab: {
        l: 39.08,
        a: 3.66,
        b: 6.79,
      },
      cmyk: null,
    },
    {
      code: '16-1103 TPG',
      name: 'Pure Cashmere',
      hex: 'B1A699',
      rgb: {
        r: 177,
        g: 166,
        b: 153,
      },
      lab: {
        l: 68.89,
        a: 2.49,
        b: 8.24,
      },
      cmyk: null,
    },
    {
      code: '16-0205 TPG',
      name: 'Vintage Khaki',
      hex: '9B9387',
      rgb: {
        r: 155,
        g: 147,
        b: 135,
      },
      lab: {
        l: 61.36,
        a: 1.88,
        b: 7.34,
      },
      cmyk: null,
    },
    {
      code: '18-1110 TPG',
      name: 'Brindle',
      hex: '887E74',
      rgb: {
        r: 136,
        g: 126,
        b: 116,
      },
      lab: {
        l: 53.62,
        a: 3.03,
        b: 7.02,
      },
      cmyk: null,
    },
    {
      code: '18-1108 TPG',
      name: 'Fallen Rock',
      hex: '867E74',
      rgb: {
        r: 134,
        g: 126,
        b: 116,
      },
      lab: {
        l: 53.49,
        a: 2.1,
        b: 6.74,
      },
      cmyk: null,
    },
    {
      code: '19-0820 TPG',
      name: 'Canteen',
      hex: '62594F',
      rgb: {
        r: 98,
        g: 89,
        b: 79,
      },
      lab: {
        l: 38.56,
        a: 1.89,
        b: 7.71,
      },
      cmyk: null,
    },
    {
      code: '18-1112 TPG',
      name: 'Walnut',
      hex: '786E61',
      rgb: {
        r: 120,
        g: 110,
        b: 97,
      },
      lab: {
        l: 47.05,
        a: 2.89,
        b: 9.02,
      },
      cmyk: null,
    },
    {
      code: '18-0615 TPG',
      name: 'Stone Gray',
      hex: '72695C',
      rgb: {
        r: 114,
        g: 105,
        b: 92,
      },
      lab: {
        l: 45.02,
        a: 2.51,
        b: 8.58,
      },
      cmyk: null,
    },
    {
      code: '14-4500 TPG',
      name: 'Moonstruck',
      hex: 'C4C1B9',
      rgb: {
        r: 196,
        g: 193,
        b: 185,
      },
      lab: {
        l: 78.21,
        a: -0.15,
        b: 4.49,
      },
      cmyk: null,
    },
    {
      code: '15-4502 TPG',
      name: 'Silver Cloud',
      hex: 'C5BCB5',
      rgb: {
        r: 197,
        g: 188,
        b: 181,
      },
      lab: {
        l: 76.99,
        a: 2.67,
        b: 4.87,
      },
      cmyk: null,
    },
    {
      code: '14-4501 TPG',
      name: 'Silver Lining',
      hex: 'C0BAAD',
      rgb: {
        r: 192,
        g: 186,
        b: 173,
      },
      lab: {
        l: 75.65,
        a: 0.74,
        b: 7.07,
      },
      cmyk: null,
    },
    {
      code: '14-0000 TPG',
      name: 'Silver Gray',
      hex: 'C5BEB6',
      rgb: {
        r: 197,
        g: 190,
        b: 182,
      },
      lab: {
        l: 77.44,
        a: 1.69,
        b: 4.91,
      },
      cmyk: null,
    },
    {
      code: '15-4503 TPG',
      name: 'Chateau Gray',
      hex: 'BDB2AA',
      rgb: {
        r: 189,
        g: 178,
        b: 170,
      },
      lab: {
        l: 73.46,
        a: 3.22,
        b: 5.4,
      },
      cmyk: null,
    },
    {
      code: '16-1305 TPG',
      name: 'String',
      hex: 'ACA49B',
      rgb: {
        r: 172,
        g: 164,
        b: 155,
      },
      lab: {
        l: 67.9,
        a: 1.79,
        b: 5.95,
      },
      cmyk: null,
    },
    {
      code: '16-1107 TPG',
      name: 'Aluminum',
      hex: 'A39A8C',
      rgb: {
        r: 163,
        g: 154,
        b: 140,
      },
      lab: {
        l: 64.14,
        a: 2.35,
        b: 8.68,
      },
      cmyk: null,
    },
    {
      code: '13-0002 TPG',
      name: 'White Sand',
      hex: 'DCD6D1',
      rgb: {
        r: 220,
        g: 214,
        b: 209,
      },
      lab: {
        l: 85.98,
        a: 1.97,
        b: 3.33,
      },
      cmyk: null,
    },
    {
      code: '14-4002 TPG',
      name: 'Wind Chime',
      hex: 'CBC6C4',
      rgb: {
        r: 203,
        g: 198,
        b: 196,
      },
      lab: {
        l: 80.18,
        a: 1.73,
        b: 1.39,
      },
      cmyk: null,
    },
    {
      code: '15-0703 TPG',
      name: 'Ashes of Roses',
      hex: 'B2ABAC',
      rgb: {
        r: 178,
        g: 171,
        b: 172,
      },
      lab: {
        l: 70.6,
        a: 3.24,
        b: 0.43,
      },
      cmyk: null,
    },
    {
      code: '15-3800 TPG',
      name: 'Porpoise',
      hex: 'ACA4A2',
      rgb: {
        r: 172,
        g: 164,
        b: 162,
      },
      lab: {
        l: 68.2,
        a: 3.22,
        b: 2.25,
      },
      cmyk: null,
    },
    {
      code: '16-3801 TPG',
      name: 'Opal Gray',
      hex: 'A39D9D',
      rgb: {
        r: 163,
        g: 157,
        b: 157,
      },
      lab: {
        l: 65.3,
        a: 2.79,
        b: 0.71,
      },
      cmyk: null,
    },
    {
      code: '16-3802 TPG',
      name: 'Ash',
      hex: 'A19B9A',
      rgb: {
        r: 161,
        g: 155,
        b: 154,
      },
      lab: {
        l: 64.62,
        a: 2.36,
        b: 1.3,
      },
      cmyk: null,
    },
    {
      code: '17-1508 TPG',
      name: 'Silent Storm',
      hex: '7F7C7A',
      rgb: {
        r: 127,
        g: 124,
        b: 122,
      },
      lab: {
        l: 52.22,
        a: 1.18,
        b: 1.73,
      },
      cmyk: null,
    },
    {
      code: '15-0000 TPG',
      name: 'Dove',
      hex: 'B6B0AA',
      rgb: {
        r: 182,
        g: 176,
        b: 170,
      },
      lab: {
        l: 72.37,
        a: 1.85,
        b: 3.98,
      },
      cmyk: null,
    },
    {
      code: '16-5803 TPG',
      name: 'Flint Gray',
      hex: '9E9D98',
      rgb: {
        r: 158,
        g: 157,
        b: 152,
      },
      lab: {
        l: 64.61,
        a: -0.07,
        b: 2.27,
      },
      cmyk: null,
    },
    {
      code: '17-0000 TPG',
      name: 'Frost Gray',
      hex: '848587',
      rgb: {
        r: 132,
        g: 133,
        b: 135,
      },
      lab: {
        l: 55.55,
        a: -0.01,
        b: -1.26,
      },
      cmyk: null,
    },
    {
      code: '17-1502 TPG',
      name: 'Cloudburst',
      hex: '868486',
      rgb: {
        r: 134,
        g: 132,
        b: 134,
      },
      lab: {
        l: 55.46,
        a: 0.88,
        b: -0.42,
      },
      cmyk: null,
    },
    {
      code: '17-1500 TPG',
      name: 'Steeple Gray',
      hex: '83807D',
      rgb: {
        r: 131,
        g: 128,
        b: 125,
      },
      lab: {
        l: 53.75,
        a: 0.88,
        b: 1.69,
      },
      cmyk: null,
    },
    {
      code: '18-0403 TPG',
      name: 'Dark Gull Gray',
      hex: '62605F',
      rgb: {
        r: 98,
        g: 96,
        b: 95,
      },
      lab: {
        l: 40.95,
        a: 0.64,
        b: 1.29,
      },
      cmyk: null,
    },
    {
      code: '18-0601 TPG',
      name: 'Charcoal Gray',
      hex: '6E6E6D',
      rgb: {
        r: 110,
        g: 110,
        b: 109,
      },
      lab: {
        l: 46.44,
        a: -0.13,
        b: 0.25,
      },
      cmyk: null,
    },
    {
      code: '14-4203 TPG',
      name: 'Vapor Blue',
      hex: 'C5C3C3',
      rgb: {
        r: 197,
        g: 195,
        b: 195,
      },
      lab: {
        l: 79.1,
        a: 0.87,
        b: 0.64,
      },
      cmyk: null,
    },
    {
      code: '16-4703 TPG',
      name: 'Ghost Gray',
      hex: '999A98',
      rgb: {
        r: 153,
        g: 154,
        b: 152,
      },
      lab: {
        l: 63.53,
        a: -0.48,
        b: 0.77,
      },
      cmyk: null,
    },
    {
      code: '16-4402 TPG',
      name: 'Drizzle',
      hex: 'A4A29E',
      rgb: {
        r: 164,
        g: 162,
        b: 158,
      },
      lab: {
        l: 66.76,
        a: 0.7,
        b: 1.96,
      },
      cmyk: null,
    },
    {
      code: '16-4400 TPG',
      name: 'Mourning Dove',
      hex: '9B9591',
      rgb: {
        r: 155,
        g: 149,
        b: 145,
      },
      lab: {
        l: 62.15,
        a: 2.11,
        b: 2.83,
      },
      cmyk: null,
    },
    {
      code: '17-0207 TPG',
      name: 'Rock Ridge',
      hex: '928D87',
      rgb: {
        r: 146,
        g: 141,
        b: 135,
      },
      lab: {
        l: 59.08,
        a: 1.86,
        b: 3.91,
      },
      cmyk: null,
    },
    {
      code: '17-0205 TPG',
      name: 'Elephant Skin',
      hex: '8F8D88',
      rgb: {
        r: 143,
        g: 141,
        b: 136,
      },
      lab: {
        l: 58.57,
        a: 0.51,
        b: 2.87,
      },
      cmyk: null,
    },
    {
      code: '17-5102 TPG',
      name: 'Griffin',
      hex: '8C9092',
      rgb: {
        r: 140,
        g: 144,
        b: 146,
      },
      lab: {
        l: 59.57,
        a: -1.63,
        b: -1.48,
      },
      cmyk: null,
    },
    {
      code: '12-4306 TPG',
      name: 'Barely Blue',
      hex: 'DCE0DC',
      rgb: {
        r: 220,
        g: 224,
        b: 220,
      },
      lab: {
        l: 88.7,
        a: -1.61,
        b: 1.33,
      },
      cmyk: null,
    },
    {
      code: '13-4303 TPG',
      name: 'Dawn Blue',
      hex: 'C9CCCA',
      rgb: {
        r: 201,
        g: 204,
        b: 202,
      },
      lab: {
        l: 81.63,
        a: -1.41,
        b: 0.42,
      },
      cmyk: null,
    },
    {
      code: '15-4703 TPG',
      name: 'Mirage Gray',
      hex: 'AEB3B1',
      rgb: {
        r: 174,
        g: 179,
        b: 177,
      },
      lab: {
        l: 72.34,
        a: -1.74,
        b: 0.27,
      },
      cmyk: null,
    },
    {
      code: '16-4702 TPG',
      name: 'Limestone',
      hex: '9B9E9C',
      rgb: {
        r: 155,
        g: 158,
        b: 156,
      },
      lab: {
        l: 64.94,
        a: -1.05,
        b: 0.5,
      },
      cmyk: null,
    },
    {
      code: '18-4105 TPG',
      name: 'Moon Mist',
      hex: '858682',
      rgb: {
        r: 133,
        g: 134,
        b: 130,
      },
      lab: {
        l: 55.65,
        a: -0.11,
        b: 1.65,
      },
      cmyk: null,
    },
    {
      code: '17-1501 TPG',
      name: 'Wild Dove',
      hex: '8B8E8D',
      rgb: {
        r: 139,
        g: 142,
        b: 141,
      },
      lab: {
        l: 58.58,
        a: -0.93,
        b: -0.45,
      },
      cmyk: null,
    },
    {
      code: '18-0510 TPG',
      name: 'Castor Gray',
      hex: '6A6F6A',
      rgb: {
        r: 106,
        g: 111,
        b: 106,
      },
      lab: {
        l: 46.28,
        a: -3.09,
        b: 1.86,
      },
      cmyk: null,
    },
    {
      code: '12-4705 TPG',
      name: 'Blue Blush',
      hex: 'D6DED9',
      rgb: {
        r: 214,
        g: 222,
        b: 217,
      },
      lab: {
        l: 87.69,
        a: -3.81,
        b: 1.38,
      },
      cmyk: null,
    },
    {
      code: '13-4305 TPG',
      name: 'Foggy Dew',
      hex: 'D1D4CD',
      rgb: {
        r: 209,
        g: 212,
        b: 205,
      },
      lab: {
        l: 84.42,
        a: -1.98,
        b: 2.88,
      },
      cmyk: null,
    },
    {
      code: '14-4804 TPG',
      name: 'Blue Fox',
      hex: 'B5BEB8',
      rgb: {
        r: 181,
        g: 190,
        b: 184,
      },
      lab: {
        l: 76.17,
        a: -4.06,
        b: 1.84,
      },
      cmyk: null,
    },
    {
      code: '15-4003 TPG',
      name: 'Storm Gray',
      hex: 'B0B8B4',
      rgb: {
        r: 176,
        g: 184,
        b: 180,
      },
      lab: {
        l: 74.05,
        a: -3.64,
        b: 1.01,
      },
      cmyk: null,
    },
    {
      code: '17-4402 TPG',
      name: 'Neutral Gray',
      hex: '8A8F8E',
      rgb: {
        r: 138,
        g: 143,
        b: 142,
      },
      lab: {
        l: 58.89,
        a: -2.3,
        b: -0.05,
      },
      cmyk: null,
    },
    {
      code: '18-5105 TPG',
      name: 'Sedona Sage',
      hex: '697171',
      rgb: {
        r: 105,
        g: 113,
        b: 113,
      },
      lab: {
        l: 47.03,
        a: -3.39,
        b: -1.32,
      },
      cmyk: null,
    },
    {
      code: '18-0306 TPG',
      name: 'Gunmetal',
      hex: '606361',
      rgb: {
        r: 96,
        g: 99,
        b: 97,
      },
      lab: {
        l: 41.7,
        a: -1.47,
        b: 0.96,
      },
      cmyk: null,
    },
    {
      code: '11-4601 TPG',
      name: 'Bit of Blue',
      hex: 'E5E9E8',
      rgb: {
        r: 229,
        g: 233,
        b: 232,
      },
      lab: {
        l: 92.02,
        a: -1.66,
        b: -0.34,
      },
      cmyk: null,
    },
    {
      code: '13-4201 TPG',
      name: 'Oyster Mushroom',
      hex: 'C8CACA',
      rgb: {
        r: 200,
        g: 202,
        b: 202,
      },
      lab: {
        l: 81.02,
        a: -0.25,
        b: -0.75,
      },
      cmyk: null,
    },
    {
      code: '15-4101 TPG',
      name: 'High-rise',
      hex: 'AAB1B3',
      rgb: {
        r: 170,
        g: 177,
        b: 179,
      },
      lab: {
        l: 71.58,
        a: -2.47,
        b: -2.09,
      },
      cmyk: null,
    },
    {
      code: '15-4305 TPG',
      name: 'Quarry',
      hex: '9AA4A9',
      rgb: {
        r: 154,
        g: 164,
        b: 169,
      },
      lab: {
        l: 66.5,
        a: -2.97,
        b: -4.21,
      },
      cmyk: null,
    },
    {
      code: '17-4405 TPG',
      name: 'Monument',
      hex: '7E878A',
      rgb: {
        r: 126,
        g: 135,
        b: 138,
      },
      lab: {
        l: 55.46,
        a: -3.06,
        b: -2.89,
      },
      cmyk: null,
    },
    {
      code: '17-3914 TPG',
      name: 'Sharkskin',
      hex: '8A8A8C',
      rgb: {
        r: 138,
        g: 138,
        b: 140,
      },
      lab: {
        l: 57.6,
        a: 0.82,
        b: -1.28,
      },
      cmyk: null,
    },
    {
      code: '18-0202 TPG',
      name: 'Lava Smoke',
      hex: '64676A',
      rgb: {
        r: 100,
        g: 103,
        b: 106,
      },
      lab: {
        l: 43.32,
        a: 0.18,
        b: -2.31,
      },
      cmyk: null,
    },
    {
      code: '13-4108 TPG',
      name: 'Nimbus Cloud',
      hex: 'DAD9DB',
      rgb: {
        r: 218,
        g: 217,
        b: 219,
      },
      lab: {
        l: 86.7,
        a: 0.73,
        b: -0.87,
      },
      cmyk: null,
    },
    {
      code: '14-4104 TPG',
      name: 'Northern Droplet',
      hex: 'BDC0BF',
      rgb: {
        r: 189,
        g: 192,
        b: 191,
      },
      lab: {
        l: 77.57,
        a: -1.01,
        b: -0.03,
      },
      cmyk: null,
    },
    {
      code: '17-5104 TPG',
      name: 'Ultimate Gray',
      hex: '96999B',
      rgb: {
        r: 150,
        g: 153,
        b: 155,
      },
      lab: {
        l: 62.9,
        a: -0.23,
        b: -1.88,
      },
      cmyk: null,
    },
    {
      code: '16-3917 TPG',
      name: 'Chiseled Stone',
      hex: '8F8F93',
      rgb: {
        r: 143,
        g: 143,
        b: 147,
      },
      lab: {
        l: 59.55,
        a: 0.75,
        b: -2.02,
      },
      cmyk: null,
    },
    {
      code: '15-4304 TPG',
      name: 'Weathervane',
      hex: '8E939A',
      rgb: {
        r: 142,
        g: 147,
        b: 154,
      },
      lab: {
        l: 60.57,
        a: -0.24,
        b: -5,
      },
      cmyk: null,
    },
    {
      code: '18-4017 TPG',
      name: 'Night Owl',
      hex: '7E7E83',
      rgb: {
        r: 126,
        g: 126,
        b: 131,
      },
      lab: {
        l: 52.87,
        a: 0.83,
        b: -2.76,
      },
      cmyk: null,
    },
    {
      code: '18-4004 TPG',
      name: 'Poppy Seed',
      hex: '66686C',
      rgb: {
        r: 102,
        g: 104,
        b: 108,
      },
      lab: {
        l: 43.95,
        a: 0.58,
        b: -2.74,
      },
      cmyk: null,
    },
    {
      code: '14-4201 TPG',
      name: 'Lunar Rock',
      hex: 'C7C8CA',
      rgb: {
        r: 199,
        g: 200,
        b: 202,
      },
      lab: {
        l: 80.59,
        a: -0.37,
        b: -0.95,
      },
      cmyk: null,
    },
    {
      code: '14-4102 TPG',
      name: 'Glacier Gray',
      hex: 'C5C9CA',
      rgb: {
        r: 197,
        g: 201,
        b: 202,
      },
      lab: {
        l: 80.56,
        a: -1.46,
        b: -1.01,
      },
      cmyk: null,
    },
    {
      code: '13-4104 TPG',
      name: 'Antarctica',
      hex: 'C9C9C8',
      rgb: {
        r: 201,
        g: 201,
        b: 200,
      },
      lab: {
        l: 80.89,
        a: 0.22,
        b: 0.34,
      },
      cmyk: null,
    },
    {
      code: '14-4103 TPG',
      name: 'Gray Violet',
      hex: 'BEC2C0',
      rgb: {
        r: 190,
        g: 194,
        b: 192,
      },
      lab: {
        l: 77.98,
        a: -1.53,
        b: 0.45,
      },
      cmyk: null,
    },
    {
      code: '14-4107 TPG',
      name: 'Quiet Gray',
      hex: 'BCBCBE',
      rgb: {
        r: 188,
        g: 188,
        b: 190,
      },
      lab: {
        l: 76.26,
        a: 0.32,
        b: -0.96,
      },
      cmyk: null,
    },
    {
      code: '14-4105 TPG',
      name: 'Micro Chip',
      hex: 'BBBEC3',
      rgb: {
        r: 187,
        g: 190,
        b: 195,
      },
      lab: {
        l: 76.94,
        a: 0.29,
        b: -3.04,
      },
      cmyk: null,
    },
    {
      code: '14-4202 TPG',
      name: 'Harbor Mist',
      hex: 'B4B7B9',
      rgb: {
        r: 180,
        g: 183,
        b: 185,
      },
      lab: {
        l: 74.08,
        a: -0.46,
        b: -1.85,
      },
      cmyk: null,
    },
    {
      code: '16-3850 TPG',
      name: 'Silver Sconce',
      hex: '9F9EA3',
      rgb: {
        r: 159,
        g: 158,
        b: 163,
      },
      lab: {
        l: 65.24,
        a: 1.32,
        b: -2.51,
      },
      cmyk: null,
    },
    {
      code: '16-5102 TPG',
      name: 'Formal Gray',
      hex: '9C9B9C',
      rgb: {
        r: 156,
        g: 155,
        b: 156,
      },
      lab: {
        l: 64.14,
        a: 1.1,
        b: -0.79,
      },
      cmyk: null,
    },
    {
      code: '16-3915 TPG',
      name: 'Alloy',
      hex: '96989B',
      rgb: {
        r: 150,
        g: 152,
        b: 155,
      },
      lab: {
        l: 62.79,
        a: -0.3,
        b: -1.91,
      },
      cmyk: null,
    },
    {
      code: '17-3913 TPG',
      name: 'Skyrocket',
      hex: '87888E',
      rgb: {
        r: 135,
        g: 136,
        b: 142,
      },
      lab: {
        l: 56.71,
        a: 1.34,
        b: -3.92,
      },
      cmyk: null,
    },
    {
      code: '17-3911 TPG',
      name: 'Silver Filigree',
      hex: '868387',
      rgb: {
        r: 134,
        g: 131,
        b: 135,
      },
      lab: {
        l: 55.11,
        a: 2.27,
        b: -1.77,
      },
      cmyk: null,
    },
    {
      code: '18-4006 TPG',
      name: 'Quiet Shade',
      hex: '6D6E73',
      rgb: {
        r: 109,
        g: 110,
        b: 115,
      },
      lab: {
        l: 46.49,
        a: 0.61,
        b: -2.79,
      },
      cmyk: null,
    },
    {
      code: '18-3907 TPG',
      name: 'Tornado',
      hex: '68656A',
      rgb: {
        r: 104,
        g: 101,
        b: 106,
      },
      lab: {
        l: 43.1,
        a: 2.61,
        b: -2.56,
      },
      cmyk: null,
    },
    {
      code: '16-5101 TPG',
      name: 'Wet Weather',
      hex: '969393',
      rgb: {
        r: 150,
        g: 147,
        b: 147,
      },
      lab: {
        l: 61.3,
        a: 1.48,
        b: 0.52,
      },
      cmyk: null,
    },
    {
      code: '17-4014 TPG',
      name: 'Titanium',
      hex: '868587',
      rgb: {
        r: 134,
        g: 133,
        b: 135,
      },
      lab: {
        l: 55.64,
        a: 1.1,
        b: -1.06,
      },
      cmyk: null,
    },
    {
      code: '18-5102 TPG',
      name: 'Brushed Nickel',
      hex: '7C7877',
      rgb: {
        r: 124,
        g: 120,
        b: 119,
      },
      lab: {
        l: 50.69,
        a: 2.17,
        b: 0.7,
      },
      cmyk: null,
    },
    {
      code: '17-4013 TPG',
      name: 'Gray Quill',
      hex: '7E7C7D',
      rgb: {
        r: 126,
        g: 124,
        b: 125,
      },
      lab: {
        l: 52.26,
        a: 1.58,
        b: -0.49,
      },
      cmyk: null,
    },
    {
      code: '18-4005 TPG',
      name: 'Steel Gray',
      hex: '6E7074',
      rgb: {
        r: 110,
        g: 112,
        b: 116,
      },
      lab: {
        l: 47.2,
        a: -0.66,
        b: -2.23,
      },
      cmyk: null,
    },
    {
      code: '18-4016 TPG',
      name: 'December Sky',
      hex: '7F7C7C',
      rgb: {
        r: 127,
        g: 124,
        b: 124,
      },
      lab: {
        l: 52.29,
        a: 0.98,
        b: 0.69,
      },
      cmyk: null,
    },
    {
      code: '18-4010 TPG',
      name: 'City Skyline',
      hex: '747376',
      rgb: {
        r: 116,
        g: 115,
        b: 118,
      },
      lab: {
        l: 48.52,
        a: 1.7,
        b: -1.77,
      },
      cmyk: null,
    },
    {
      code: '16-3803 TPG',
      name: 'Gull Gray',
      hex: 'A6A0A6',
      rgb: {
        r: 166,
        g: 160,
        b: 166,
      },
      lab: {
        l: 66.67,
        a: 3.14,
        b: -2.25,
      },
      cmyk: null,
    },
    {
      code: '17-2601 TPG',
      name: 'Zinc',
      hex: '91898D',
      rgb: {
        r: 145,
        g: 137,
        b: 141,
      },
      lab: {
        l: 57.95,
        a: 4.05,
        b: -0.81,
      },
      cmyk: null,
    },
    {
      code: '17-3802 TPG',
      name: 'Gull',
      hex: '908D92',
      rgb: {
        r: 144,
        g: 141,
        b: 146,
      },
      lab: {
        l: 58.98,
        a: 2.39,
        b: -2.53,
      },
      cmyk: null,
    },
    {
      code: '17-1503 TPG',
      name: 'Storm Front',
      hex: '7D787C',
      rgb: {
        r: 125,
        g: 120,
        b: 124,
      },
      lab: {
        l: 50.97,
        a: 2.95,
        b: -1.63,
      },
      cmyk: null,
    },
    {
      code: '18-3908 TPG',
      name: 'Volcanic Glass',
      hex: '686368',
      rgb: {
        r: 104,
        g: 99,
        b: 104,
      },
      lab: {
        l: 42.57,
        a: 3.49,
        b: -2.13,
      },
      cmyk: null,
    },
    {
      code: '19-3803 TPG',
      name: 'Plum Kitten',
      hex: '615E61',
      rgb: {
        r: 97,
        g: 94,
        b: 97,
      },
      lab: {
        l: 40.35,
        a: 1.29,
        b: -0.87,
      },
      cmyk: null,
    },
    {
      code: '18-3906 TPG',
      name: 'Boulevard',
      hex: '5D595F',
      rgb: {
        r: 93,
        g: 89,
        b: 95,
      },
      lab: {
        l: 38.21,
        a: 3.54,
        b: -3.37,
      },
      cmyk: null,
    },
    {
      code: '16-0000 TPG',
      name: 'Paloma',
      hex: 'A4A09D',
      rgb: {
        r: 164,
        g: 160,
        b: 157,
      },
      lab: {
        l: 66.07,
        a: 1.45,
        b: 1.96,
      },
      cmyk: null,
    },
    {
      code: '17-4016 TPG',
      name: 'Gray Flannel',
      hex: '898585',
      rgb: {
        r: 137,
        g: 133,
        b: 133,
      },
      lab: {
        l: 56.01,
        a: 1.88,
        b: 0.5,
      },
      cmyk: null,
    },
    {
      code: '18-4015 TPG',
      name: 'Mockingbird',
      hex: '7E7D80',
      rgb: {
        r: 126,
        g: 125,
        b: 128,
      },
      lab: {
        l: 52.64,
        a: 1.67,
        b: -1.76,
      },
      cmyk: null,
    },
    {
      code: '18-0503 TPG',
      name: 'Gargoyle',
      hex: '6B6C6B',
      rgb: {
        r: 107,
        g: 108,
        b: 107,
      },
      lab: {
        l: 45.38,
        a: -0.51,
        b: 0.19,
      },
      cmyk: null,
    },
    {
      code: '18-5203 TPG',
      name: 'Pewter',
      hex: '656768',
      rgb: {
        r: 101,
        g: 103,
        b: 104,
      },
      lab: {
        l: 43.5,
        a: -0.56,
        b: -0.71,
      },
      cmyk: null,
    },
    {
      code: '18-5206 TPG',
      name: 'Thunderstorm',
      hex: '605F60',
      rgb: {
        r: 96,
        g: 95,
        b: 96,
      },
      lab: {
        l: 40.29,
        a: 1.31,
        b: -1.1,
      },
      cmyk: null,
    },
    {
      code: '19-3906 TPG',
      name: 'Dark Shadow',
      hex: '505557',
      rgb: {
        r: 80,
        g: 85,
        b: 87,
      },
      lab: {
        l: 35.65,
        a: -1.89,
        b: -1.96,
      },
      cmyk: null,
    },
    {
      code: '19-4405 TPG',
      name: 'Forest River',
      hex: '3B4142',
      rgb: {
        r: 59,
        g: 65,
        b: 66,
      },
      lab: {
        l: 26.78,
        a: -2.22,
        b: -2.16,
      },
      cmyk: null,
    },
    {
      code: '19-5002 TPG',
      name: 'Woodland Gray',
      hex: '44484B',
      rgb: {
        r: 68,
        g: 72,
        b: 75,
      },
      lab: {
        l: 30.25,
        a: -1.48,
        b: -2.36,
      },
      cmyk: null,
    },
    {
      code: '19-5005 TPG',
      name: 'Unexplored',
      hex: '484A49',
      rgb: {
        r: 72,
        g: 74,
        b: 73,
      },
      lab: {
        l: 31.33,
        a: -0.92,
        b: 0.3,
      },
      cmyk: null,
    },
    {
      code: '19-4406 TPG',
      name: 'Black Sand',
      hex: '48494C',
      rgb: {
        r: 72,
        g: 73,
        b: 76,
      },
      lab: {
        l: 30.95,
        a: 0.43,
        b: -2.09,
      },
      cmyk: null,
    },
    {
      code: '19-1100 TPG',
      name: 'Bristol Black',
      hex: '474445',
      rgb: {
        r: 71,
        g: 68,
        b: 69,
      },
      lab: {
        l: 29.11,
        a: 1.9,
        b: -0.39,
      },
      cmyk: null,
    },
    {
      code: '19-3912 TPG',
      name: 'Volcanic Ash',
      hex: '4F5151',
      rgb: {
        r: 79,
        g: 81,
        b: 81,
      },
      lab: {
        l: 34.43,
        a: -0.93,
        b: -0.28,
      },
      cmyk: null,
    },
    {
      code: '19-0205 TPG',
      name: 'Black Oyster',
      hex: '4A4A4D',
      rgb: {
        r: 74,
        g: 74,
        b: 77,
      },
      lab: {
        l: 31.55,
        a: 0.51,
        b: -1.9,
      },
      cmyk: null,
    },
    {
      code: '18-0000 TPG',
      name: 'Smoked Pearl',
      hex: '6B6C6E',
      rgb: {
        r: 107,
        g: 108,
        b: 110,
      },
      lab: {
        l: 45.61,
        a: -0.33,
        b: -1.41,
      },
      cmyk: null,
    },
    {
      code: '18-0201 TPG',
      name: 'Castlerock',
      hex: '5F6367',
      rgb: {
        r: 95,
        g: 99,
        b: 103,
      },
      lab: {
        l: 41.79,
        a: -1.23,
        b: -2.62,
      },
      cmyk: null,
    },
    {
      code: '18-5204 TPG',
      name: 'Granite Gray',
      hex: '696667',
      rgb: {
        r: 105,
        g: 102,
        b: 103,
      },
      lab: {
        l: 43.52,
        a: 1.72,
        b: 0.01,
      },
      cmyk: null,
    },
    {
      code: '18-5210 TPG',
      name: 'Eiffel Tower',
      hex: '666062',
      rgb: {
        r: 102,
        g: 96,
        b: 98,
      },
      lab: {
        l: 41.45,
        a: 2.96,
        b: -0.05,
      },
      cmyk: null,
    },
    {
      code: '19-3910 TPG',
      name: 'Iron Gate',
      hex: '575B5F',
      rgb: {
        r: 87,
        g: 91,
        b: 95,
      },
      lab: {
        l: 38.35,
        a: -1.1,
        b: -3.13,
      },
      cmyk: null,
    },
    {
      code: '19-0201 TPG',
      name: 'Asphalt',
      hex: '525257',
      rgb: {
        r: 82,
        g: 82,
        b: 87,
      },
      lab: {
        l: 35.12,
        a: 0.64,
        b: -2.59,
      },
      cmyk: null,
    },
    {
      code: '19-3901 TPG',
      name: 'Magnet',
      hex: '545358',
      rgb: {
        r: 84,
        g: 83,
        b: 88,
      },
      lab: {
        l: 35.53,
        a: 1.65,
        b: -2.93,
      },
      cmyk: null,
    },
    {
      code: '19-3905 TPG',
      name: 'Rabbit',
      hex: '5E5A5E',
      rgb: {
        r: 94,
        g: 90,
        b: 94,
      },
      lab: {
        l: 38.87,
        a: 2.33,
        b: -1.67,
      },
      cmyk: null,
    },
    {
      code: '18-3905 TPG',
      name: 'Excalibur',
      hex: '66636B',
      rgb: {
        r: 102,
        g: 99,
        b: 107,
      },
      lab: {
        l: 42.5,
        a: 2.38,
        b: -3.94,
      },
      cmyk: null,
    },
    {
      code: '19-3903 TPG',
      name: 'Shale',
      hex: '4D484B',
      rgb: {
        r: 77,
        g: 72,
        b: 75,
      },
      lab: {
        l: 31.1,
        a: 2.72,
        b: -1.13,
      },
      cmyk: null,
    },
    {
      code: '19-3900 TPG',
      name: 'Pavement',
      hex: '5A5659',
      rgb: {
        r: 90,
        g: 86,
        b: 89,
      },
      lab: {
        l: 37.07,
        a: 2.94,
        b: -1.19,
      },
      cmyk: null,
    },
    {
      code: '19-3908 TPG',
      name: 'Nine Iron',
      hex: '4A484F',
      rgb: {
        r: 74,
        g: 72,
        b: 79,
      },
      lab: {
        l: 31.11,
        a: 1.56,
        b: -3.55,
      },
      cmyk: null,
    },
    {
      code: '19-4104 TPG',
      name: 'Ebony',
      hex: '494D53',
      rgb: {
        r: 73,
        g: 77,
        b: 83,
      },
      lab: {
        l: 32.46,
        a: -0.8,
        b: -4.03,
      },
      cmyk: null,
    },
    {
      code: '19-3918 TPG',
      name: 'Periscope',
      hex: '53525B',
      rgb: {
        r: 83,
        g: 82,
        b: 91,
      },
      lab: {
        l: 35.38,
        a: 1.61,
        b: -4.5,
      },
      cmyk: null,
    },
    {
      code: '19-0203 TPG',
      name: 'Gray Pinstripe',
      hex: '555559',
      rgb: {
        r: 85,
        g: 85,
        b: 89,
      },
      lab: {
        l: 36.11,
        a: 1.26,
        b: -2.47,
      },
      cmyk: null,
    },
    {
      code: '19-3907 TPG',
      name: 'Forged Iron',
      hex: '545356',
      rgb: {
        r: 84,
        g: 83,
        b: 86,
      },
      lab: {
        l: 35.3,
        a: 1.05,
        b: -1.53,
      },
      cmyk: null,
    },
    {
      code: '19-3917 TPG',
      name: 'Blackened Pearl',
      hex: '58565B',
      rgb: {
        r: 88,
        g: 86,
        b: 91,
      },
      lab: {
        l: 37.02,
        a: 1.44,
        b: -2.54,
      },
      cmyk: null,
    },
    {
      code: '19-3902 TPG',
      name: 'Obsidian',
      hex: '4B484D',
      rgb: {
        r: 75,
        g: 72,
        b: 77,
      },
      lab: {
        l: 31.02,
        a: 1.89,
        b: -2.29,
      },
      cmyk: null,
    },
    {
      code: '19-4205 TPG',
      name: 'Phantom',
      hex: '404145',
      rgb: {
        r: 64,
        g: 65,
        b: 69,
      },
      lab: {
        l: 27.64,
        a: 0.06,
        b: -2.37,
      },
      cmyk: null,
    },
    {
      code: '19-4007 TPG',
      name: 'Anthracite',
      hex: '3D3F43',
      rgb: {
        r: 61,
        g: 63,
        b: 67,
      },
      lab: {
        l: 26.44,
        a: -0.26,
        b: -2.79,
      },
      cmyk: null,
    },
    {
      code: '19-0303 TPG',
      name: 'Jet Black',
      hex: '3D4043',
      rgb: {
        r: 61,
        g: 64,
        b: 67,
      },
      lab: {
        l: 26.83,
        a: -0.43,
        b: -2.51,
      },
      cmyk: null,
    },
    {
      code: '19-3911 TPG',
      name: 'Black Beauty',
      hex: '3B3C40',
      rgb: {
        r: 59,
        g: 60,
        b: 64,
      },
      lab: {
        l: 25.27,
        a: 0.07,
        b: -2.61,
      },
      cmyk: null,
    },
    {
      code: '19-4003 TPG',
      name: 'Black Onyx',
      hex: '3F3D41',
      rgb: {
        r: 63,
        g: 61,
        b: 65,
      },
      lab: {
        l: 25.98,
        a: 1.83,
        b: -2.45,
      },
      cmyk: null,
    },
    {
      code: '19-4004 TPG',
      name: 'Tap Shoe',
      hex: '3D3C3F',
      rgb: {
        r: 61,
        g: 60,
        b: 63,
      },
      lab: {
        l: 25.6,
        a: 0.4,
        b: -1.24,
      },
      cmyk: null,
    },
    {
      code: '19-4005 TPG',
      name: 'Stretch Limo',
      hex: '3B3F42',
      rgb: {
        r: 59,
        g: 63,
        b: 66,
      },
      lab: {
        l: 26.43,
        a: -1.25,
        b: -2.29,
      },
      cmyk: null,
    },
    {
      code: '19-4006 TPG',
      name: 'Caviar',
      hex: '3B3D3F',
      rgb: {
        r: 59,
        g: 61,
        b: 63,
      },
      lab: {
        l: 25.61,
        a: -0.33,
        b: -1.71,
      },
      cmyk: null,
    },
    {
      code: '19-4008 TPG',
      name: 'Meteorite',
      hex: '413E40',
      rgb: {
        r: 65,
        g: 62,
        b: 64,
      },
      lab: {
        l: 26.43,
        a: 1.74,
        b: -0.73,
      },
      cmyk: null,
    },
    {
      code: '19-4203 TPG',
      name: 'Moonless Night',
      hex: '3D3E41',
      rgb: {
        r: 61,
        g: 62,
        b: 65,
      },
      lab: {
        l: 26.25,
        a: -0.31,
        b: -1.91,
      },
      cmyk: null,
    },
    {
      code: '19-0405 TPG',
      name: 'Beluga',
      hex: '4D4C4A',
      rgb: {
        r: 77,
        g: 76,
        b: 74,
      },
      lab: {
        l: 32.4,
        a: -0.44,
        b: 1.79,
      },
      cmyk: null,
    },
    {
      code: '19-0608 TPG',
      name: 'Black Olive',
      hex: '4D4B46',
      rgb: {
        r: 77,
        g: 75,
        b: 70,
      },
      lab: {
        l: 31.93,
        a: 0.29,
        b: 3.16,
      },
      cmyk: null,
    },
    {
      code: '19-0000 TPG',
      name: 'Raven',
      hex: '454646',
      rgb: {
        r: 69,
        g: 70,
        b: 70,
      },
      lab: {
        l: 29.49,
        a: -0.19,
        b: -0.32,
      },
      cmyk: null,
    },
    {
      code: '19-0506 TPG',
      name: 'Black Ink',
      hex: '494B47',
      rgb: {
        r: 73,
        g: 75,
        b: 71,
      },
      lab: {
        l: 31.53,
        a: -1.35,
        b: 2.02,
      },
      cmyk: null,
    },
    {
      code: '19-0508 TPG',
      name: 'Peat',
      hex: '4A4A48',
      rgb: {
        r: 74,
        g: 74,
        b: 72,
      },
      lab: {
        l: 31.28,
        a: -0.34,
        b: 0.83,
      },
      cmyk: null,
    },
    {
      code: '19-4305 TPG',
      name: 'Pirate Black',
      hex: '3D4140',
      rgb: {
        r: 61,
        g: 65,
        b: 64,
      },
      lab: {
        l: 27.02,
        a: -1.44,
        b: -0.46,
      },
      cmyk: null,
    },
    {
      code: '19-5708 TPG',
      name: 'Jet Set',
      hex: '3D4243',
      rgb: {
        r: 61,
        g: 66,
        b: 67,
      },
      lab: {
        l: 27.6,
        a: -2.18,
        b: -1.42,
      },
      cmyk: null,
    },
    {
      code: '19-0812 TPG',
      name: 'Turkish Coffee',
      hex: '4D4743',
      rgb: {
        r: 77,
        g: 71,
        b: 67,
      },
      lab: {
        l: 30.79,
        a: 1.66,
        b: 3.37,
      },
      cmyk: null,
    },
    {
      code: '19-0814 TPG',
      name: 'Slate Black',
      hex: '524841',
      rgb: {
        r: 82,
        g: 72,
        b: 65,
      },
      lab: {
        l: 31.65,
        a: 3.37,
        b: 6.25,
      },
      cmyk: null,
    },
    {
      code: '19-0614 TPG',
      name: 'Wren',
      hex: '4F4841',
      rgb: {
        r: 79,
        g: 72,
        b: 65,
      },
      lab: {
        l: 31.07,
        a: 1.97,
        b: 5.41,
      },
      cmyk: null,
    },
    {
      code: '19-0712 TPG',
      name: 'Demitasse',
      hex: '4D4540',
      rgb: {
        r: 77,
        g: 69,
        b: 64,
      },
      lab: {
        l: 29.94,
        a: 2.93,
        b: 4.23,
      },
      cmyk: null,
    },
    {
      code: '19-0910 TPG',
      name: 'Mulch',
      hex: '504846',
      rgb: {
        r: 80,
        g: 72,
        b: 70,
      },
      lab: {
        l: 31.38,
        a: 3.38,
        b: 2.47,
      },
      cmyk: null,
    },
    {
      code: '19-1102 TPG',
      name: 'Licorice',
      hex: '413F3F',
      rgb: {
        r: 65,
        g: 63,
        b: 63,
      },
      lab: {
        l: 26.71,
        a: 1.12,
        b: 0.07,
      },
      cmyk: null,
    },
    {
      code: '19-1103 TPG',
      name: 'Espresso',
      hex: '443E41',
      rgb: {
        r: 68,
        g: 62,
        b: 65,
      },
      lab: {
        l: 27.03,
        a: 3.32,
        b: -0.48,
      },
      cmyk: null,
    },
    {
      code: '19-1015 TPG',
      name: 'Bracken',
      hex: '554B47',
      rgb: {
        r: 85,
        g: 75,
        b: 71,
      },
      lab: {
        l: 32.89,
        a: 3.87,
        b: 4.23,
      },
      cmyk: null,
    },
    {
      code: '19-0912 TPG',
      name: 'Chocolate Brown',
      hex: '4E443E',
      rgb: {
        r: 78,
        g: 68,
        b: 62,
      },
      lab: {
        l: 29.63,
        a: 3.71,
        b: 4.93,
      },
      cmyk: null,
    },
    {
      code: '19-0840 TPG',
      name: 'Delicioso',
      hex: '4C4441',
      rgb: {
        r: 76,
        g: 68,
        b: 65,
      },
      lab: {
        l: 29.68,
        a: 3.08,
        b: 3.28,
      },
      cmyk: null,
    },
    {
      code: '19-1016 TPG',
      name: 'Java',
      hex: '4C413E',
      rgb: {
        r: 76,
        g: 65,
        b: 62,
      },
      lab: {
        l: 28.73,
        a: 4.08,
        b: 3.96,
      },
      cmyk: null,
    },
    {
      code: '19-0915 TPG',
      name: 'Coffee Bean',
      hex: '473F3E',
      rgb: {
        r: 71,
        g: 63,
        b: 62,
      },
      lab: {
        l: 27.62,
        a: 3.27,
        b: 2.23,
      },
      cmyk: null,
    },
    {
      code: '19-1106 TPG',
      name: 'Molé',
      hex: '483D3C',
      rgb: {
        r: 72,
        g: 61,
        b: 60,
      },
      lab: {
        l: 27,
        a: 5.38,
        b: 2.67,
      },
      cmyk: null,
    },
    {
      code: '19-1101 TPG',
      name: 'After Dark',
      hex: '433F3F',
      rgb: {
        r: 67,
        g: 63,
        b: 63,
      },
      lab: {
        l: 26.98,
        a: 1.77,
        b: 0.63,
      },
      cmyk: null,
    },
    {
      code: '19-1213 TPG',
      name: 'Shopping Bag',
      hex: '5B4B48',
      rgb: {
        r: 91,
        g: 75,
        b: 72,
      },
      lab: {
        l: 33.58,
        a: 6.29,
        b: 4.93,
      },
      cmyk: null,
    },
    {
      code: '19-1012 TPG',
      name: 'French Roast',
      hex: '5F4E4B',
      rgb: {
        r: 95,
        g: 78,
        b: 75,
      },
      lab: {
        l: 35.04,
        a: 7.06,
        b: 4.82,
      },
      cmyk: null,
    },
    {
      code: '19-1314 TPG',
      name: 'Seal Brown',
      hex: '4C4140',
      rgb: {
        r: 76,
        g: 65,
        b: 64,
      },
      lab: {
        l: 28.79,
        a: 4.59,
        b: 2.38,
      },
      cmyk: null,
    },
    {
      code: '19-1109 TPG',
      name: 'Chocolate Torte',
      hex: '473E3E',
      rgb: {
        r: 71,
        g: 62,
        b: 62,
      },
      lab: {
        l: 27.33,
        a: 3.83,
        b: 1.67,
      },
      cmyk: null,
    },
    {
      code: '19-1111 TPG',
      name: 'Black Coffee',
      hex: '483F3F',
      rgb: {
        r: 72,
        g: 63,
        b: 63,
      },
      lab: {
        l: 27.72,
        a: 3.68,
        b: 1.76,
      },
      cmyk: null,
    },
    {
      code: '19-1018 TPG',
      name: 'Ganache',
      hex: '463E3E',
      rgb: {
        r: 70,
        g: 62,
        b: 62,
      },
      lab: {
        l: 27.02,
        a: 4.13,
        b: 1.38,
      },
      cmyk: null,
    },
    {
      code: '19-3909 TPG',
      name: 'Black Bean',
      hex: '443D42',
      rgb: {
        r: 68,
        g: 61,
        b: 66,
      },
      lab: {
        l: 26.74,
        a: 3.9,
        b: -1.64,
      },
      cmyk: null,
    },
    {
      code: '19-1118 TPG',
      name: 'Chestnut',
      hex: '5C4D46',
      rgb: {
        r: 92,
        g: 77,
        b: 70,
      },
      lab: {
        l: 34.08,
        a: 6.01,
        b: 6.56,
      },
      cmyk: null,
    },
    {
      code: '19-1234 TPG',
      name: 'Rocky Road',
      hex: '634A44',
      rgb: {
        r: 99,
        g: 74,
        b: 68,
      },
      lab: {
        l: 34.25,
        a: 10,
        b: 8.32,
      },
      cmyk: null,
    },
    {
      code: '19-1420 TPG',
      name: 'Deep Mahogany',
      hex: '5D4A48',
      rgb: {
        r: 93,
        g: 74,
        b: 72,
      },
      lab: {
        l: 33.56,
        a: 8.42,
        b: 4.86,
      },
      cmyk: null,
    },
    {
      code: '19-1518 TPG',
      name: 'Puce',
      hex: '584747',
      rgb: {
        r: 88,
        g: 71,
        b: 71,
      },
      lab: {
        l: 32.13,
        a: 8,
        b: 3.07,
      },
      cmyk: null,
    },
    {
      code: '19-1215 TPG',
      name: 'Shaved Chocolate',
      hex: '5D4944',
      rgb: {
        r: 93,
        g: 73,
        b: 68,
      },
      lab: {
        l: 33.2,
        a: 8.42,
        b: 6.64,
      },
      cmyk: null,
    },
    {
      code: '19-1419 TPG',
      name: 'Chicory Coffee',
      hex: '574542',
      rgb: {
        r: 87,
        g: 69,
        b: 66,
      },
      lab: {
        l: 31.26,
        a: 7.57,
        b: 5.38,
      },
      cmyk: null,
    },
    {
      code: '19-1110 TPG',
      name: 'Chocolate Plum',
      hex: '4B3F40',
      rgb: {
        r: 75,
        g: 63,
        b: 64,
      },
      lab: {
        l: 28.06,
        a: 5.82,
        b: 1.85,
      },
      cmyk: null,
    },
    {
      code: '19-1224 TPG',
      name: 'Fondue Fudge',
      hex: '665249',
      rgb: {
        r: 102,
        g: 82,
        b: 73,
      },
      lab: {
        l: 36.77,
        a: 8.06,
        b: 8.73,
      },
      cmyk: null,
    },
    {
      code: '19-1233 TPG',
      name: 'Tiramisu',
      hex: '6C5046',
      rgb: {
        r: 108,
        g: 80,
        b: 70,
      },
      lab: {
        l: 37.05,
        a: 11.7,
        b: 11.03,
      },
      cmyk: null,
    },
    {
      code: '19-1214 TPG',
      name: 'Chocolate Lab',
      hex: '644B44',
      rgb: {
        r: 100,
        g: 75,
        b: 68,
      },
      lab: {
        l: 34.57,
        a: 10.44,
        b: 8.53,
      },
      cmyk: null,
    },
    {
      code: '19-1235 TPG',
      name: 'Brunette',
      hex: '684D46',
      rgb: {
        r: 104,
        g: 77,
        b: 70,
      },
      lab: {
        l: 35.67,
        a: 11.49,
        b: 8.89,
      },
      cmyk: null,
    },
    {
      code: '19-1218 TPG',
      name: 'Potting Soil',
      hex: '604E47',
      rgb: {
        r: 96,
        g: 78,
        b: 71,
      },
      lab: {
        l: 34.92,
        a: 7.17,
        b: 7.34,
      },
      cmyk: null,
    },
    {
      code: '19-1432 TPG',
      name: 'Chocolate Fondant',
      hex: '604944',
      rgb: {
        r: 96,
        g: 73,
        b: 68,
      },
      lab: {
        l: 33.62,
        a: 10.16,
        b: 7.46,
      },
      cmyk: null,
    },
    {
      code: '19-1322 TPG',
      name: 'Brown Stone',
      hex: '604B4B',
      rgb: {
        r: 96,
        g: 75,
        b: 75,
      },
      lab: {
        l: 34.3,
        a: 9.64,
        b: 4.02,
      },
      cmyk: null,
    },
    {
      code: '19-1217 TPG',
      name: 'Mustang',
      hex: '6F574E',
      rgb: {
        r: 111,
        g: 87,
        b: 78,
      },
      lab: {
        l: 39.41,
        a: 9.23,
        b: 9.79,
      },
      cmyk: null,
    },
    {
      code: '19-1430 TPG',
      name: 'Mink',
      hex: '75544D',
      rgb: {
        r: 117,
        g: 84,
        b: 77,
      },
      lab: {
        l: 39.44,
        a: 14.26,
        b: 10.2,
      },
      cmyk: null,
    },
    {
      code: '19-1228 TPG',
      name: 'Root Beer',
      hex: '76554E',
      rgb: {
        r: 118,
        g: 85,
        b: 78,
      },
      lab: {
        l: 39.78,
        a: 14.11,
        b: 10.32,
      },
      cmyk: null,
    },
    {
      code: '19-1220 TPG',
      name: 'Cappuccino',
      hex: '6B4E47',
      rgb: {
        r: 107,
        g: 78,
        b: 71,
      },
      lab: {
        l: 36.4,
        a: 12.35,
        b: 9.81,
      },
      cmyk: null,
    },
    {
      code: '19-1431 TPG',
      name: 'Fudgesickle',
      hex: '6A4C48',
      rgb: {
        r: 106,
        g: 76,
        b: 72,
      },
      lab: {
        l: 35.83,
        a: 13.29,
        b: 8.05,
      },
      cmyk: null,
    },
    {
      code: '19-1321 TPG',
      name: 'Rum Raisin',
      hex: '5F4241',
      rgb: {
        r: 95,
        g: 66,
        b: 65,
      },
      lab: {
        l: 31.37,
        a: 13.33,
        b: 5.94,
      },
      cmyk: null,
    },
    {
      code: '19-1317 TPG',
      name: 'Bitter Chocolate',
      hex: '5A4344',
      rgb: {
        r: 90,
        g: 67,
        b: 68,
      },
      lab: {
        l: 31,
        a: 10.68,
        b: 3.85,
      },
      cmyk: null,
    },
    {
      code: '19-1436 TPG',
      name: 'Cinnamon',
      hex: '704D47',
      rgb: {
        r: 112,
        g: 77,
        b: 71,
      },
      lab: {
        l: 36.8,
        a: 15.35,
        b: 10.17,
      },
      cmyk: null,
    },
    {
      code: '19-1320 TPG',
      name: 'Sable',
      hex: '764E4B',
      rgb: {
        r: 118,
        g: 78,
        b: 75,
      },
      lab: {
        l: 37.9,
        a: 18.07,
        b: 9.47,
      },
      cmyk: null,
    },
    {
      code: '19-1334 TPG',
      name: 'Henna',
      hex: '835049',
      rgb: {
        r: 131,
        g: 80,
        b: 73,
      },
      lab: {
        l: 40.24,
        a: 22.79,
        b: 13.97,
      },
      cmyk: null,
    },
    {
      code: '19-1429 TPG',
      name: 'Smoked Paprika',
      hex: '72423B',
      rgb: {
        r: 114,
        g: 66,
        b: 59,
      },
      lab: {
        l: 34.14,
        a: 21.56,
        b: 14.33,
      },
      cmyk: null,
    },
    {
      code: '19-1337 TPG',
      name: 'Fired Brick',
      hex: '753D3C',
      rgb: {
        r: 117,
        g: 61,
        b: 60,
      },
      lab: {
        l: 33.61,
        a: 26.24,
        b: 13.05,
      },
      cmyk: null,
    },
    {
      code: '19-1331 TPG',
      name: 'Madder Brown',
      hex: '714445',
      rgb: {
        r: 113,
        g: 68,
        b: 69,
      },
      lab: {
        l: 34.63,
        a: 20.97,
        b: 8.8,
      },
      cmyk: null,
    },
    {
      code: '19-1325 TPG',
      name: 'Hot Chocolate',
      hex: '704A49',
      rgb: {
        r: 112,
        g: 74,
        b: 73,
      },
      lab: {
        l: 36.2,
        a: 17.24,
        b: 8.22,
      },
      cmyk: null,
    },
    {
      code: '18-1336 TPG',
      name: 'Copper Brown',
      hex: '976459',
      rgb: {
        r: 151,
        g: 100,
        b: 89,
      },
      lab: {
        l: 48.15,
        a: 21.16,
        b: 15.93,
      },
      cmyk: null,
    },
    {
      code: '18-1433 TPG',
      name: 'Chutney',
      hex: '965F53',
      rgb: {
        r: 150,
        g: 95,
        b: 83,
      },
      lab: {
        l: 46.58,
        a: 23.03,
        b: 17.29,
      },
      cmyk: null,
    },
    {
      code: '18-1425 TPG',
      name: 'Mahogany',
      hex: '865451',
      rgb: {
        r: 134,
        g: 84,
        b: 81,
      },
      lab: {
        l: 41.85,
        a: 21.84,
        b: 11.64,
      },
      cmyk: null,
    },
    {
      code: '18-1541 TPG',
      name: 'Brandy Brown',
      hex: '78463E',
      rgb: {
        r: 120,
        g: 70,
        b: 62,
      },
      lab: {
        l: 35.93,
        a: 22.4,
        b: 14.91,
      },
      cmyk: null,
    },
    {
      code: '19-1338 TPG',
      name: 'Russet Brown',
      hex: '7C4748',
      rgb: {
        r: 124,
        g: 71,
        b: 72,
      },
      lab: {
        l: 37.11,
        a: 24.13,
        b: 10.42,
      },
      cmyk: null,
    },
    {
      code: '18-1325 TPG',
      name: 'Spiced Apple',
      hex: '7E4847',
      rgb: {
        r: 126,
        g: 72,
        b: 71,
      },
      lab: {
        l: 37.58,
        a: 24.5,
        b: 11.74,
      },
      cmyk: null,
    },
    {
      code: '19-1435 TPG',
      name: 'Cherry Mahogany',
      hex: '714640',
      rgb: {
        r: 113,
        g: 70,
        b: 64,
      },
      lab: {
        l: 35.22,
        a: 19.28,
        b: 12.39,
      },
      cmyk: null,
    },
    {
      code: '18-1432 TPG',
      name: 'Redwood Burl',
      hex: '9F5E55',
      rgb: {
        r: 159,
        g: 94,
        b: 85,
      },
      lab: {
        l: 47.97,
        a: 27.53,
        b: 18.11,
      },
      cmyk: null,
    },
    {
      code: '18-1243 TPG',
      name: 'Imperial Topaz',
      hex: '8C5A4B',
      rgb: {
        r: 140,
        g: 90,
        b: 75,
      },
      lab: {
        l: 43.99,
        a: 20.71,
        b: 18.17,
      },
      cmyk: null,
    },
    {
      code: '18-1341 TPG',
      name: 'Maple Syrup',
      hex: '8D574B',
      rgb: {
        r: 141,
        g: 87,
        b: 75,
      },
      lab: {
        l: 43.35,
        a: 22.76,
        b: 17.37,
      },
      cmyk: null,
    },
    {
      code: '18-1323 TPG',
      name: 'Brandy Snifter',
      hex: '874C4A',
      rgb: {
        r: 135,
        g: 76,
        b: 74,
      },
      lab: {
        l: 39.9,
        a: 26.34,
        b: 13.37,
      },
      cmyk: null,
    },
    {
      code: '19-1541 TPG',
      name: 'Roasted Russet',
      hex: '834A42',
      rgb: {
        r: 131,
        g: 74,
        b: 66,
      },
      lab: {
        l: 38.66,
        a: 25.23,
        b: 16.47,
      },
      cmyk: null,
    },
    {
      code: '19-1428 TPG',
      name: 'Brown Out',
      hex: '784F45',
      rgb: {
        r: 120,
        g: 79,
        b: 69,
      },
      lab: {
        l: 38.42,
        a: 17.54,
        b: 14.03,
      },
      cmyk: null,
    },
    {
      code: '19-1242 TPG',
      name: 'Cambridge Brown',
      hex: '774F40',
      rgb: {
        r: 119,
        g: 79,
        b: 64,
      },
      lab: {
        l: 38.15,
        a: 16.65,
        b: 16.74,
      },
      cmyk: null,
    },
    {
      code: '18-1346 TPG',
      name: 'Bruschetta',
      hex: 'AF5F55',
      rgb: {
        r: 175,
        g: 95,
        b: 85,
      },
      lab: {
        l: 50.45,
        a: 34.05,
        b: 22.13,
      },
      cmyk: null,
    },
    {
      code: '18-1350 TPG',
      name: 'Burnt Brick',
      hex: 'A3584D',
      rgb: {
        r: 163,
        g: 88,
        b: 77,
      },
      lab: {
        l: 46.93,
        a: 32.16,
        b: 21.55,
      },
      cmyk: null,
    },
    {
      code: '18-1438 TPG',
      name: 'Marsala',
      hex: '975A59',
      rgb: {
        r: 151,
        g: 90,
        b: 89,
      },
      lab: {
        l: 45.78,
        a: 27.29,
        b: 12.71,
      },
      cmyk: null,
    },
    {
      code: '18-1531 TPG',
      name: 'Barn Red',
      hex: '904F4C',
      rgb: {
        r: 144,
        g: 79,
        b: 76,
      },
      lab: {
        l: 42.05,
        a: 29,
        b: 15.03,
      },
      cmyk: null,
    },
    {
      code: '18-1442 TPG',
      name: 'Red Ochre',
      hex: '924C48',
      rgb: {
        r: 146,
        g: 76,
        b: 72,
      },
      lab: {
        l: 41.66,
        a: 31.21,
        b: 16.98,
      },
      cmyk: null,
    },
    {
      code: '18-1440 TPG',
      name: 'Chili Oil',
      hex: '944743',
      rgb: {
        r: 148,
        g: 71,
        b: 67,
      },
      lab: {
        l: 40.92,
        a: 33.73,
        b: 19.37,
      },
      cmyk: null,
    },
    {
      code: '19-1540 TPG',
      name: 'Burnt Henna',
      hex: '834A45',
      rgb: {
        r: 131,
        g: 74,
        b: 69,
      },
      lab: {
        l: 38.65,
        a: 25.47,
        b: 14.68,
      },
      cmyk: null,
    },
    {
      code: '18-1443 TPG',
      name: 'Redwood',
      hex: 'A46057',
      rgb: {
        r: 164,
        g: 96,
        b: 87,
      },
      lab: {
        l: 48.97,
        a: 29.31,
        b: 18.49,
      },
      cmyk: null,
    },
    {
      code: '18-1434 TPG',
      name: 'Etruscan Red',
      hex: 'A25F57',
      rgb: {
        r: 162,
        g: 95,
        b: 87,
      },
      lab: {
        l: 48.41,
        a: 28.92,
        b: 17.39,
      },
      cmyk: null,
    },
    {
      code: '18-1441 TPG',
      name: 'Baked Clay',
      hex: 'A15E4F',
      rgb: {
        r: 161,
        g: 94,
        b: 79,
      },
      lab: {
        l: 48.13,
        a: 28.09,
        b: 21.74,
      },
      cmyk: null,
    },
    {
      code: '18-1343 TPG',
      name: 'Auburn',
      hex: '9E6154',
      rgb: {
        r: 158,
        g: 97,
        b: 84,
      },
      lab: {
        l: 48.25,
        a: 25.38,
        b: 18.82,
      },
      cmyk: null,
    },
    {
      code: '19-1245 TPG',
      name: 'Arabian Spice',
      hex: '8C5248',
      rgb: {
        r: 140,
        g: 82,
        b: 72,
      },
      lab: {
        l: 42.02,
        a: 25.54,
        b: 17.71,
      },
      cmyk: null,
    },
    {
      code: '19-1333 TPG',
      name: 'Sequoia',
      hex: '835347',
      rgb: {
        r: 131,
        g: 83,
        b: 71,
      },
      lab: {
        l: 40.95,
        a: 20.34,
        b: 16.41,
      },
      cmyk: null,
    },
    {
      code: '19-1230 TPG',
      name: 'Friar Brown',
      hex: '725246',
      rgb: {
        r: 114,
        g: 82,
        b: 70,
      },
      lab: {
        l: 38.43,
        a: 13.13,
        b: 12.97,
      },
      cmyk: null,
    },
    {
      code: '18-1239 TPG',
      name: 'Sierra',
      hex: '9E664F',
      rgb: {
        r: 158,
        g: 102,
        b: 79,
      },
      lab: {
        l: 49.28,
        a: 22.6,
        b: 23.34,
      },
      cmyk: null,
    },
    {
      code: '18-1140 TPG',
      name: 'Mocha Bisque',
      hex: '8E5E4A',
      rgb: {
        r: 142,
        g: 94,
        b: 74,
      },
      lab: {
        l: 45.1,
        a: 19.41,
        b: 20.11,
      },
      cmyk: null,
    },
    {
      code: '17-1142 TPG',
      name: 'Argan Oil',
      hex: '91634D',
      rgb: {
        r: 145,
        g: 99,
        b: 77,
      },
      lab: {
        l: 46.79,
        a: 18.69,
        b: 20.51,
      },
      cmyk: null,
    },
    {
      code: '18-1242 TPG',
      name: 'Brown Patina',
      hex: '8A5B4C',
      rgb: {
        r: 138,
        g: 91,
        b: 76,
      },
      lab: {
        l: 44.08,
        a: 19.32,
        b: 17.81,
      },
      cmyk: null,
    },
    {
      code: '18-1230 TPG',
      name: 'Coconut Shell',
      hex: '875546',
      rgb: {
        r: 135,
        g: 85,
        b: 70,
      },
      lab: {
        l: 41.97,
        a: 20.86,
        b: 18.65,
      },
      cmyk: null,
    },
    {
      code: '18-1238 TPG',
      name: 'Rustic Brown',
      hex: '895B4E',
      rgb: {
        r: 137,
        g: 91,
        b: 78,
      },
      lab: {
        l: 43.73,
        a: 19.34,
        b: 16.26,
      },
      cmyk: null,
    },
    {
      code: '19-1241 TPG',
      name: 'Tortoise Shell',
      hex: '7D5446',
      rgb: {
        r: 125,
        g: 84,
        b: 70,
      },
      lab: {
        l: 40.42,
        a: 17,
        b: 15.91,
      },
      cmyk: null,
    },
    {
      code: '17-1422 TPG',
      name: 'Raw Umber',
      hex: '917364',
      rgb: {
        r: 145,
        g: 115,
        b: 100,
      },
      lab: {
        l: 51.22,
        a: 10.93,
        b: 13.57,
      },
      cmyk: null,
    },
    {
      code: '18-1320 TPG',
      name: 'Clove',
      hex: '866459',
      rgb: {
        r: 134,
        g: 100,
        b: 89,
      },
      lab: {
        l: 46.04,
        a: 13.64,
        b: 12.29,
      },
      cmyk: null,
    },
    {
      code: '18-1229 TPG',
      name: 'Carob Brown',
      hex: '876456',
      rgb: {
        r: 135,
        g: 100,
        b: 86,
      },
      lab: {
        l: 46,
        a: 13.61,
        b: 14.34,
      },
      cmyk: null,
    },
    {
      code: '18-1235 TPG',
      name: 'Russet',
      hex: '8F6457',
      rgb: {
        r: 143,
        g: 100,
        b: 87,
      },
      lab: {
        l: 47.13,
        a: 17.16,
        b: 15.12,
      },
      cmyk: null,
    },
    {
      code: '18-1421 TPG',
      name: 'Baltic Amber',
      hex: '89675F',
      rgb: {
        r: 137,
        g: 103,
        b: 95,
      },
      lab: {
        l: 47.16,
        a: 13.64,
        b: 10.71,
      },
      cmyk: null,
    },
    {
      code: '18-1314 TPG',
      name: 'Acorn',
      hex: '80675C',
      rgb: {
        r: 128,
        g: 103,
        b: 92,
      },
      lab: {
        l: 45.88,
        a: 9.4,
        b: 10.49,
      },
      cmyk: null,
    },
    {
      code: '18-1326 TPG',
      name: 'Nutmeg',
      hex: '7F625A',
      rgb: {
        r: 127,
        g: 98,
        b: 90,
      },
      lab: {
        l: 44.58,
        a: 11.93,
        b: 9.5,
      },
      cmyk: null,
    },
    {
      code: '18-1124 TPG',
      name: 'Partridge',
      hex: '745B4C',
      rgb: {
        r: 116,
        g: 91,
        b: 76,
      },
      lab: {
        l: 41.11,
        a: 9.16,
        b: 12.94,
      },
      cmyk: null,
    },
    {
      code: '18-1033 TPG',
      name: 'Dachshund',
      hex: '795C46',
      rgb: {
        r: 121,
        g: 92,
        b: 70,
      },
      lab: {
        l: 42.02,
        a: 10.01,
        b: 17.97,
      },
      cmyk: null,
    },
    {
      code: '18-1031 TPG',
      name: 'Toffee',
      hex: '765841',
      rgb: {
        r: 118,
        g: 88,
        b: 65,
      },
      lab: {
        l: 40.31,
        a: 10.44,
        b: 18.44,
      },
      cmyk: null,
    },
    {
      code: '18-1027 TPG',
      name: 'Bison',
      hex: '6B5545',
      rgb: {
        r: 107,
        g: 85,
        b: 69,
      },
      lab: {
        l: 38.2,
        a: 8.08,
        b: 13.07,
      },
      cmyk: null,
    },
    {
      code: '18-1222 TPG',
      name: 'Cocoa Brown',
      hex: '70594D',
      rgb: {
        r: 112,
        g: 89,
        b: 77,
      },
      lab: {
        l: 40.14,
        a: 8.77,
        b: 11,
      },
      cmyk: null,
    },
    {
      code: '19-1121 TPG',
      name: 'Pinecone',
      hex: '655249',
      rgb: {
        r: 101,
        g: 82,
        b: 73,
      },
      lab: {
        l: 36.77,
        a: 7.23,
        b: 8.92,
      },
      cmyk: null,
    },
    {
      code: '19-1116 TPG',
      name: 'Carafe',
      hex: '5C4D44',
      rgb: {
        r: 92,
        g: 77,
        b: 68,
      },
      lab: {
        l: 34.27,
        a: 5.42,
        b: 8.45,
      },
      cmyk: null,
    },
    {
      code: '18-1019 TPG',
      name: 'Coca Mocha',
      hex: '8C725F',
      rgb: {
        r: 140,
        g: 114,
        b: 95,
      },
      lab: {
        l: 50.23,
        a: 9.74,
        b: 14.44,
      },
      cmyk: null,
    },
    {
      code: '18-1028 TPG',
      name: 'Emperador',
      hex: '6D4F3D',
      rgb: {
        r: 109,
        g: 79,
        b: 61,
      },
      lab: {
        l: 36.82,
        a: 11.21,
        b: 16.43,
      },
      cmyk: null,
    },
    {
      code: '18-1307 TPG',
      name: 'Coffee Quartz',
      hex: '6D5D58',
      rgb: {
        r: 109,
        g: 93,
        b: 88,
      },
      lab: {
        l: 41.08,
        a: 6.36,
        b: 5.57,
      },
      cmyk: null,
    },
    {
      code: '19-1119 TPG',
      name: 'Cocoa',
      hex: '614F43',
      rgb: {
        r: 97,
        g: 79,
        b: 67,
      },
      lab: {
        l: 35.36,
        a: 7.19,
        b: 10.19,
      },
      cmyk: null,
    },
    {
      code: '19-1223 TPG',
      name: 'Downtown Brown',
      hex: '684B41',
      rgb: {
        r: 104,
        g: 75,
        b: 65,
      },
      lab: {
        l: 35.07,
        a: 13.14,
        b: 11.47,
      },
      cmyk: null,
    },
    {
      code: '19-1216 TPG',
      name: 'Chocolate Martini',
      hex: '5D4E4B',
      rgb: {
        r: 93,
        g: 78,
        b: 75,
      },
      lab: {
        l: 34.73,
        a: 7.32,
        b: 4.52,
      },
      cmyk: null,
    },
    {
      code: '19-0913 TPG',
      name: 'Hot Fudge',
      hex: '544743',
      rgb: {
        r: 84,
        g: 71,
        b: 67,
      },
      lab: {
        l: 31.41,
        a: 5.8,
        b: 4.35,
      },
      cmyk: null,
    },
    {
      code: '17-1322 TPG',
      name: 'Burro',
      hex: 'A4846C',
      rgb: {
        r: 164,
        g: 132,
        b: 108,
      },
      lab: {
        l: 57.95,
        a: 11.2,
        b: 17.76,
      },
      cmyk: null,
    },
    {
      code: '17-1044 TPG',
      name: 'Chipmunk',
      hex: '977252',
      rgb: {
        r: 151,
        g: 114,
        b: 82,
      },
      lab: {
        l: 51.39,
        a: 12.17,
        b: 24.04,
      },
      cmyk: null,
    },
    {
      code: '17-1327 TPG',
      name: 'Tobacco Brown',
      hex: '96775A',
      rgb: {
        r: 150,
        g: 119,
        b: 90,
      },
      lab: {
        l: 52.74,
        a: 9.78,
        b: 21.43,
      },
      cmyk: null,
    },
    {
      code: '18-1030 TPG',
      name: 'Thrush',
      hex: '91705A',
      rgb: {
        r: 145,
        g: 112,
        b: 90,
      },
      lab: {
        l: 50.36,
        a: 11.53,
        b: 17.78,
      },
      cmyk: null,
    },
    {
      code: '18-1024 TPG',
      name: 'Nuthatch',
      hex: '88644B',
      rgb: {
        r: 136,
        g: 100,
        b: 75,
      },
      lab: {
        l: 46.04,
        a: 12.61,
        b: 20.61,
      },
      cmyk: null,
    },
    {
      code: '18-1137 TPG',
      name: 'Rawhide',
      hex: '866351',
      rgb: {
        r: 134,
        g: 99,
        b: 81,
      },
      lab: {
        l: 45.65,
        a: 13.35,
        b: 16.48,
      },
      cmyk: null,
    },
    {
      code: '19-1227 TPG',
      name: 'Sorrel Horse',
      hex: '704D3E',
      rgb: {
        r: 112,
        g: 77,
        b: 62,
      },
      lab: {
        l: 36.7,
        a: 14.29,
        b: 15.51,
      },
      cmyk: null,
    },
    {
      code: '17-1324 TPG',
      name: 'Cinnamon Swirl',
      hex: 'A18068',
      rgb: {
        r: 161,
        g: 128,
        b: 104,
      },
      lab: {
        l: 56.39,
        a: 11.69,
        b: 18.04,
      },
      cmyk: null,
    },
    {
      code: '17-1318 TPG',
      name: 'Affogat',
      hex: 'A78C79',
      rgb: {
        r: 167,
        g: 140,
        b: 121,
      },
      lab: {
        l: 60.59,
        a: 9.69,
        b: 14.11,
      },
      cmyk: null,
    },
    {
      code: '17-1317 TPG',
      name: 'Sinopia Fresco',
      hex: '96796C',
      rgb: {
        r: 150,
        g: 121,
        b: 108,
      },
      lab: {
        l: 53.63,
        a: 10.94,
        b: 11.61,
      },
      cmyk: null,
    },
    {
      code: '18-1324 TPG',
      name: 'Chocolate Malt',
      hex: '927567',
      rgb: {
        r: 146,
        g: 117,
        b: 103,
      },
      lab: {
        l: 51.92,
        a: 11.09,
        b: 12.01,
      },
      cmyk: null,
    },
    {
      code: '18-1125 TPG',
      name: 'Pecan Pie',
      hex: '7F6050',
      rgb: {
        r: 127,
        g: 96,
        b: 80,
      },
      lab: {
        l: 43.74,
        a: 12.28,
        b: 14.27,
      },
      cmyk: null,
    },
    {
      code: '18-1130 TPG',
      name: 'Cacao Nibs',
      hex: '795C4F',
      rgb: {
        r: 121,
        g: 92,
        b: 79,
      },
      lab: {
        l: 42,
        a: 11.3,
        b: 12.43,
      },
      cmyk: null,
    },
    {
      code: '18-1232 TPG',
      name: 'Soft Silt',
      hex: '6F5446',
      rgb: {
        r: 111,
        g: 84,
        b: 70,
      },
      lab: {
        l: 38.41,
        a: 11.25,
        b: 12.89,
      },
      cmyk: null,
    },
    {
      code: '17-1223 TPG',
      name: 'Praline',
      hex: 'AA8B75',
      rgb: {
        r: 170,
        g: 139,
        b: 117,
      },
      lab: {
        l: 60.52,
        a: 9.73,
        b: 17.1,
      },
      cmyk: null,
    },
    {
      code: '16-1323 TPG',
      name: 'Macaroon',
      hex: 'B18B73',
      rgb: {
        r: 177,
        g: 139,
        b: 115,
      },
      lab: {
        l: 61.21,
        a: 13.22,
        b: 18.8,
      },
      cmyk: null,
    },
    {
      code: '17-1225 TPG',
      name: 'Tawny Birch',
      hex: 'AF8971',
      rgb: {
        r: 175,
        g: 137,
        b: 113,
      },
      lab: {
        l: 60.63,
        a: 13.18,
        b: 19.31,
      },
      cmyk: null,
    },
    {
      code: '17-1226 TPG',
      name: 'Tawny Brown',
      hex: 'A8856E',
      rgb: {
        r: 168,
        g: 133,
        b: 110,
      },
      lab: {
        l: 58.76,
        a: 12.19,
        b: 18.27,
      },
      cmyk: null,
    },
    {
      code: '17-1328 TPG',
      name: 'Tanzine',
      hex: 'AE8866',
      rgb: {
        r: 174,
        g: 136,
        b: 102,
      },
      lab: {
        l: 59.94,
        a: 11.87,
        b: 24.72,
      },
      cmyk: null,
    },
    {
      code: '17-1221 TPG',
      name: 'Macchiato',
      hex: 'AF876D',
      rgb: {
        r: 175,
        g: 135,
        b: 109,
      },
      lab: {
        l: 59.89,
        a: 14.52,
        b: 20.15,
      },
      cmyk: null,
    },
    {
      code: '17-1330 TPG',
      name: 'Lion',
      hex: 'A1785A',
      rgb: {
        r: 161,
        g: 120,
        b: 90,
      },
      lab: {
        l: 54.36,
        a: 13.68,
        b: 23.8,
      },
      cmyk: null,
    },
    {
      code: '16-1317 TPG',
      name: 'Brush',
      hex: 'B89B87',
      rgb: {
        r: 184,
        g: 155,
        b: 135,
      },
      lab: {
        l: 66.44,
        a: 8.73,
        b: 15.13,
      },
      cmyk: null,
    },
    {
      code: '16-1320 TPG',
      name: 'Nougat',
      hex: 'BC9D89',
      rgb: {
        r: 188,
        g: 157,
        b: 137,
      },
      lab: {
        l: 67.17,
        a: 11.13,
        b: 14.87,
      },
      cmyk: null,
    },
    {
      code: '16-1221 TPG',
      name: 'Roebuck',
      hex: 'B49581',
      rgb: {
        r: 180,
        g: 149,
        b: 129,
      },
      lab: {
        l: 64.38,
        a: 9.77,
        b: 15.67,
      },
      cmyk: null,
    },
    {
      code: '17-1224 TPG',
      name: 'Camel',
      hex: 'AD846B',
      rgb: {
        r: 173,
        g: 132,
        b: 107,
      },
      lab: {
        l: 58.98,
        a: 14.69,
        b: 20.53,
      },
      cmyk: null,
    },
    {
      code: '17-1227 TPG',
      name: 'Café au Lait',
      hex: 'A9836F',
      rgb: {
        r: 169,
        g: 131,
        b: 111,
      },
      lab: {
        l: 58.47,
        a: 12.84,
        b: 17.68,
      },
      cmyk: null,
    },
    {
      code: '17-1230 TPG',
      name: 'Mocha Mousse',
      hex: 'A47B67',
      rgb: {
        r: 164,
        g: 123,
        b: 103,
      },
      lab: {
        l: 55.66,
        a: 14.52,
        b: 17.92,
      },
      cmyk: null,
    },
    {
      code: '18-1422 TPG',
      name: 'Myristica',
      hex: '89685F',
      rgb: {
        r: 137,
        g: 104,
        b: 95,
      },
      lab: {
        l: 47.52,
        a: 13.41,
        b: 10.65,
      },
      cmyk: null,
    },
    {
      code: '13-1108 TPG',
      name: 'Cream Tan',
      hex: 'E3C6B4',
      rgb: {
        r: 227,
        g: 198,
        b: 180,
      },
      lab: {
        l: 82.19,
        a: 8.4,
        b: 13.44,
      },
      cmyk: null,
    },
    {
      code: '14-1314 TPG',
      name: 'Spanish Villa',
      hex: 'E4BDAA',
      rgb: {
        r: 228,
        g: 189,
        b: 170,
      },
      lab: {
        l: 79.95,
        a: 12.27,
        b: 15.67,
      },
      cmyk: null,
    },
    {
      code: '15-1317 TPG',
      name: 'Sirocco',
      hex: 'C19E87',
      rgb: {
        r: 193,
        g: 158,
        b: 135,
      },
      lab: {
        l: 68.19,
        a: 10.99,
        b: 17.93,
      },
      cmyk: null,
    },
    {
      code: '15-1316 TPG',
      name: 'Maple Sugar',
      hex: 'CAA690',
      rgb: {
        r: 202,
        g: 166,
        b: 144,
      },
      lab: {
        l: 71.1,
        a: 11.68,
        b: 16.96,
      },
      cmyk: null,
    },
    {
      code: '16-1219 TPG',
      name: 'Tuscany',
      hex: 'C09B88',
      rgb: {
        r: 192,
        g: 155,
        b: 136,
      },
      lab: {
        l: 67.23,
        a: 12.58,
        b: 16.14,
      },
      cmyk: null,
    },
    {
      code: '16-1220 TPG',
      name: 'Café Crème',
      hex: 'C49883',
      rgb: {
        r: 196,
        g: 152,
        b: 131,
      },
      lab: {
        l: 66.87,
        a: 15.26,
        b: 18.33,
      },
      cmyk: null,
    },
    {
      code: '16-1422 TPG',
      name: 'Cork',
      hex: 'B88A76',
      rgb: {
        r: 184,
        g: 138,
        b: 118,
      },
      lab: {
        l: 61.82,
        a: 16.22,
        b: 18.62,
      },
      cmyk: null,
    },
    {
      code: '13-1405 TPG',
      name: 'Shell',
      hex: 'E7D5C8',
      rgb: {
        r: 231,
        g: 213,
        b: 200,
      },
      lab: {
        l: 86.45,
        a: 5.06,
        b: 8.64,
      },
      cmyk: null,
    },
    {
      code: '12-1206 TPG',
      name: 'Silver Peony',
      hex: 'EAD2C6',
      rgb: {
        r: 234,
        g: 210,
        b: 198,
      },
      lab: {
        l: 86.14,
        a: 7.09,
        b: 9.26,
      },
      cmyk: null,
    },
    {
      code: '14-1309 TPG',
      name: 'Peach Whip',
      hex: 'DDBFB9',
      rgb: {
        r: 221,
        g: 191,
        b: 185,
      },
      lab: {
        l: 80.08,
        a: 10.63,
        b: 7.82,
      },
      cmyk: null,
    },
    {
      code: '14-1310 TPG',
      name: 'Cameo Rose',
      hex: 'D7B6A9',
      rgb: {
        r: 215,
        g: 182,
        b: 169,
      },
      lab: {
        l: 76.91,
        a: 10.57,
        b: 11.92,
      },
      cmyk: null,
    },
    {
      code: '14-1307 TPG',
      name: 'Rose Dust',
      hex: 'CFB3A6',
      rgb: {
        r: 207,
        g: 179,
        b: 166,
      },
      lab: {
        l: 75.38,
        a: 8.66,
        b: 10.99,
      },
      cmyk: null,
    },
    {
      code: '15-1315 TPG',
      name: 'Rugby Tan',
      hex: 'C6A894',
      rgb: {
        r: 198,
        g: 168,
        b: 148,
      },
      lab: {
        l: 71.5,
        a: 9,
        b: 15.51,
      },
      cmyk: null,
    },
    {
      code: '15-1511 TPG',
      name: 'Mahogany Rose',
      hex: 'C3A292',
      rgb: {
        r: 195,
        g: 162,
        b: 146,
      },
      lab: {
        l: 69.58,
        a: 10.77,
        b: 13.42,
      },
      cmyk: null,
    },
    {
      code: '11-1006 TPG',
      name: 'Slightly Pink',
      hex: 'EDE6E2',
      rgb: {
        r: 237,
        g: 230,
        b: 226,
      },
      lab: {
        l: 91.81,
        a: 2.34,
        b: 2.73,
      },
      cmyk: null,
    },
    {
      code: '11-1007 TPG',
      name: 'Coconut Cream',
      hex: 'EFE3DE',
      rgb: {
        r: 239,
        g: 227,
        b: 222,
      },
      lab: {
        l: 91.02,
        a: 4.32,
        b: 3.5,
      },
      cmyk: null,
    },
    {
      code: '11-2309 TPG',
      name: 'Petal Pink',
      hex: 'F4E6E2',
      rgb: {
        r: 244,
        g: 230,
        b: 226,
      },
      lab: {
        l: 92.38,
        a: 4.87,
        b: 3.84,
      },
      cmyk: null,
    },
    {
      code: '12-2103 TPG',
      name: 'Almost Mauve',
      hex: 'EADEDB',
      rgb: {
        r: 234,
        g: 222,
        b: 219,
      },
      lab: {
        l: 89.52,
        a: 4.06,
        b: 3.04,
      },
      cmyk: null,
    },
    {
      code: '11-0603 TPG',
      name: 'Pastel Parchment',
      hex: 'E5DCD5',
      rgb: {
        r: 229,
        g: 220,
        b: 213,
      },
      lab: {
        l: 88.31,
        a: 2.53,
        b: 4.57,
      },
      cmyk: null,
    },
    {
      code: '12-1105 TPG',
      name: 'Salsa Rosa',
      hex: 'F1DDD3',
      rgb: {
        r: 241,
        g: 221,
        b: 211,
      },
      lab: {
        l: 89.61,
        a: 6.72,
        b: 7.53,
      },
      cmyk: null,
    },
    {
      code: '11-1305 TPG',
      name: 'Angel Wing',
      hex: 'F3E1D7',
      rgb: {
        r: 243,
        g: 225,
        b: 215,
      },
      lab: {
        l: 90.81,
        a: 5.65,
        b: 6.96,
      },
      cmyk: null,
    },
    {
      code: '11-1302 TPG',
      name: 'Sea Salt',
      hex: 'F1E9E0',
      rgb: {
        r: 241,
        g: 233,
        b: 224,
      },
      lab: {
        l: 92.71,
        a: 1.94,
        b: 5.24,
      },
      cmyk: null,
    },
    {
      code: '12-1106 TPG',
      name: 'Sheer Pink',
      hex: 'F2E7DB',
      rgb: {
        r: 242,
        g: 231,
        b: 219,
      },
      lab: {
        l: 92.42,
        a: 2.64,
        b: 7.29,
      },
      cmyk: null,
    },
    {
      code: '11-1306 TPG',
      name: 'Cream Pink',
      hex: 'F2E2D8',
      rgb: {
        r: 242,
        g: 226,
        b: 216,
      },
      lab: {
        l: 90.92,
        a: 4.79,
        b: 6.83,
      },
      cmyk: null,
    },
    {
      code: '11-1005 TPG',
      name: 'Bridal Blush',
      hex: 'EDE4DE',
      rgb: {
        r: 237,
        g: 228,
        b: 222,
      },
      lab: {
        l: 91.37,
        a: 2.44,
        b: 4.2,
      },
      cmyk: null,
    },
    {
      code: '12-1108 TPG',
      name: 'Dew',
      hex: 'EFE2D4',
      rgb: {
        r: 239,
        g: 226,
        b: 212,
      },
      lab: {
        l: 90.7,
        a: 3.22,
        b: 8.63,
      },
      cmyk: null,
    },
    {
      code: '12-2901 TPG',
      name: 'Morganite',
      hex: 'E6D2C9',
      rgb: {
        r: 230,
        g: 210,
        b: 201,
      },
      lab: {
        l: 85.83,
        a: 6.64,
        b: 7.23,
      },
      cmyk: null,
    },
    {
      code: '13-1504 TPG',
      name: 'Peach Blush',
      hex: 'E6CDC5',
      rgb: {
        r: 230,
        g: 205,
        b: 197,
      },
      lab: {
        l: 84.56,
        a: 8.63,
        b: 7.42,
      },
      cmyk: null,
    },
    {
      code: '11-1404 TPG',
      name: 'Powder Puff',
      hex: 'F3E6DC',
      rgb: {
        r: 243,
        g: 230,
        b: 220,
      },
      lab: {
        l: 92.26,
        a: 3.32,
        b: 6.71,
      },
      cmyk: null,
    },
    {
      code: '12-1107 TPG',
      name: 'Peach Dust',
      hex: 'F2DBCC',
      rgb: {
        r: 242,
        g: 219,
        b: 204,
      },
      lab: {
        l: 89.09,
        a: 6.61,
        b: 10.47,
      },
      cmyk: null,
    },
    {
      code: '12-1209 TPG',
      name: 'Soft Pink',
      hex: 'F2D9CC',
      rgb: {
        r: 242,
        g: 217,
        b: 204,
      },
      lab: {
        l: 88.75,
        a: 7.11,
        b: 10.06,
      },
      cmyk: null,
    },
    {
      code: '12-1110 TPG',
      name: 'Crème de Pêche',
      hex: 'F8DBC9',
      rgb: {
        r: 248,
        g: 219,
        b: 201,
      },
      lab: {
        l: 89.7,
        a: 8.76,
        b: 12.97,
      },
      cmyk: null,
    },
    {
      code: '12-1010 TPG',
      name: 'Scallop Shell',
      hex: 'FAD6C6',
      rgb: {
        r: 250,
        g: 214,
        b: 198,
      },
      lab: {
        l: 88.52,
        a: 11.26,
        b: 13.21,
      },
      cmyk: null,
    },
    {
      code: '13-1404 TPG',
      name: 'Pale Dogwood',
      hex: 'F0CFC3',
      rgb: {
        r: 240,
        g: 207,
        b: 195,
      },
      lab: {
        l: 85.9,
        a: 10.45,
        b: 10.89,
      },
      cmyk: null,
    },
    {
      code: '12-1207 TPG',
      name: 'Pearl Blush',
      hex: 'F2CEC5',
      rgb: {
        r: 242,
        g: 206,
        b: 197,
      },
      lab: {
        l: 85.97,
        a: 12.41,
        b: 9.96,
      },
      cmyk: null,
    },
    {
      code: '13-1406 TPG',
      name: 'Cloud Pink',
      hex: 'EED1C6',
      rgb: {
        r: 238,
        g: 209,
        b: 198,
      },
      lab: {
        l: 86.22,
        a: 10.03,
        b: 9.38,
      },
      cmyk: null,
    },
    {
      code: '13-1407 TPG',
      name: 'Creole Pink',
      hex: 'F5D5CD',
      rgb: {
        r: 245,
        g: 213,
        b: 205,
      },
      lab: {
        l: 87.89,
        a: 11.29,
        b: 8.59,
      },
      cmyk: null,
    },
    {
      code: '13-1208 TPG',
      name: 'Peachy Keen',
      hex: 'E7BFB4',
      rgb: {
        r: 231,
        g: 191,
        b: 180,
      },
      lab: {
        l: 80.91,
        a: 14.32,
        b: 11.49,
      },
      cmyk: null,
    },
    {
      code: '14-1312 TPG',
      name: 'Peach Powder',
      hex: 'E5BDB0',
      rgb: {
        r: 229,
        g: 189,
        b: 176,
      },
      lab: {
        l: 80.09,
        a: 13.61,
        b: 12.56,
      },
      cmyk: null,
    },
    {
      code: '14-1311 TPG',
      name: 'Evening Sand',
      hex: 'DFB7AA',
      rgb: {
        r: 223,
        g: 183,
        b: 170,
      },
      lab: {
        l: 78.04,
        a: 13.42,
        b: 12.6,
      },
      cmyk: null,
    },
    {
      code: '14-1313 TPG',
      name: 'Rose Cloud',
      hex: 'DEB3A3',
      rgb: {
        r: 222,
        g: 179,
        b: 163,
      },
      lab: {
        l: 76.86,
        a: 14.2,
        b: 15.2,
      },
      cmyk: null,
    },
    {
      code: '14-1316 TPG',
      name: 'Dusty Pink',
      hex: 'E0AE9E',
      rgb: {
        r: 224,
        g: 174,
        b: 158,
      },
      lab: {
        l: 75.68,
        a: 18.64,
        b: 16.04,
      },
      cmyk: null,
    },
    {
      code: '13-1109 TPG',
      name: 'Bisque',
      hex: 'EBC6B0',
      rgb: {
        r: 235,
        g: 198,
        b: 176,
      },
      lab: {
        l: 82.93,
        a: 11.27,
        b: 16.77,
      },
      cmyk: null,
    },
    {
      code: '13-1114 TPG',
      name: 'Bellini',
      hex: 'F4C7AB',
      rgb: {
        r: 244,
        g: 199,
        b: 171,
      },
      lab: {
        l: 84.08,
        a: 13.93,
        b: 20.94,
      },
      cmyk: null,
    },
    {
      code: '15-1319 TPG',
      name: 'Almost Apricot',
      hex: 'E6B49B',
      rgb: {
        r: 230,
        g: 180,
        b: 155,
      },
      lab: {
        l: 77.84,
        a: 16.3,
        b: 20.83,
      },
      cmyk: null,
    },
    {
      code: '14-1220 TPG',
      name: 'Peach Nougat',
      hex: 'E7AF90',
      rgb: {
        r: 231,
        g: 175,
        b: 144,
      },
      lab: {
        l: 76.62,
        a: 18.32,
        b: 25.33,
      },
      cmyk: null,
    },
    {
      code: '15-1318 TPG',
      name: 'Pink Sand',
      hex: 'DEAD95',
      rgb: {
        r: 222,
        g: 173,
        b: 149,
      },
      lab: {
        l: 75.22,
        a: 15.94,
        b: 20.4,
      },
      cmyk: null,
    },
    {
      code: '15-1322 TPG',
      name: 'Dusty Coral',
      hex: 'D09D83',
      rgb: {
        r: 208,
        g: 157,
        b: 131,
      },
      lab: {
        l: 69.52,
        a: 17.44,
        b: 21.83,
      },
      cmyk: null,
    },
    {
      code: '16-1330 TPG',
      name: 'Muted Clay',
      hex: 'D09786',
      rgb: {
        r: 208,
        g: 151,
        b: 134,
      },
      lab: {
        l: 68.1,
        a: 21.78,
        b: 18.17,
      },
      cmyk: null,
    },
    {
      code: '12-0813 TPG',
      name: 'Autumn Blonde',
      hex: 'F2D6B3',
      rgb: {
        r: 242,
        g: 214,
        b: 179,
      },
      lab: {
        l: 87.49,
        a: 6.6,
        b: 21.26,
      },
      cmyk: null,
    },
    {
      code: '13-1024 TPG',
      name: 'Buff',
      hex: 'EBC594',
      rgb: {
        r: 235,
        g: 197,
        b: 148,
      },
      lab: {
        l: 82.14,
        a: 9.47,
        b: 30.19,
      },
      cmyk: null,
    },
    {
      code: '13-1018 TPG',
      name: 'Desert Dust',
      hex: 'E3BD8F',
      rgb: {
        r: 227,
        g: 189,
        b: 143,
      },
      lab: {
        l: 79.36,
        a: 9.64,
        b: 28.92,
      },
      cmyk: null,
    },
    {
      code: '12-0921 TPG',
      name: 'Golden Straw',
      hex: 'E5BD8E',
      rgb: {
        r: 229,
        g: 189,
        b: 142,
      },
      lab: {
        l: 79.54,
        a: 10.74,
        b: 29.69,
      },
      cmyk: null,
    },
    {
      code: '14-1127 TPG',
      name: 'Desert Mist',
      hex: 'E0B589',
      rgb: {
        r: 224,
        g: 181,
        b: 137,
      },
      lab: {
        l: 77.09,
        a: 12.02,
        b: 29.38,
      },
      cmyk: null,
    },
    {
      code: '14-1122 TPG',
      name: 'Sheepskin',
      hex: 'DBB790',
      rgb: {
        r: 219,
        g: 183,
        b: 144,
      },
      lab: {
        l: 77.05,
        a: 9.67,
        b: 25.23,
      },
      cmyk: null,
    },
    {
      code: '16-1333 TPG',
      name: 'Doe',
      hex: 'B9936C',
      rgb: {
        r: 185,
        g: 147,
        b: 108,
      },
      lab: {
        l: 64.03,
        a: 11.51,
        b: 26.89,
      },
      cmyk: null,
    },
    {
      code: '15-1125 TPG',
      name: 'Porcini',
      hex: 'D0A783',
      rgb: {
        r: 208,
        g: 167,
        b: 131,
      },
      lab: {
        l: 71.96,
        a: 12.51,
        b: 24.76,
      },
      cmyk: null,
    },
    {
      code: '15-1220 TPG',
      name: 'Lattè',
      hex: 'CBAA88',
      rgb: {
        r: 203,
        g: 170,
        b: 136,
      },
      lab: {
        l: 72.12,
        a: 9.93,
        b: 22.22,
      },
      cmyk: null,
    },
    {
      code: '15-1231 TPG',
      name: 'Clay',
      hex: 'D5A576',
      rgb: {
        r: 213,
        g: 165,
        b: 118,
      },
      lab: {
        l: 71.74,
        a: 14.51,
        b: 32.05,
      },
      cmyk: null,
    },
    {
      code: '16-1341 TPG',
      name: 'Butterum',
      hex: 'C3916A',
      rgb: {
        r: 195,
        g: 145,
        b: 106,
      },
      lab: {
        l: 64.77,
        a: 16.3,
        b: 29.44,
      },
      cmyk: null,
    },
    {
      code: '15-1425 TPG',
      name: 'Fenugreek',
      hex: 'C4936F',
      rgb: {
        r: 196,
        g: 147,
        b: 111,
      },
      lab: {
        l: 65.5,
        a: 16.44,
        b: 27.28,
      },
      cmyk: null,
    },
    {
      code: '15-1430 TPG',
      name: 'Pastry Shell',
      hex: 'C08E68',
      rgb: {
        r: 192,
        g: 142,
        b: 104,
      },
      lab: {
        l: 63.66,
        a: 16.9,
        b: 28.73,
      },
      cmyk: null,
    },
    {
      code: '16-1438 TPG',
      name: 'Meerkat',
      hex: 'A9754F',
      rgb: {
        r: 169,
        g: 117,
        b: 79,
      },
      lab: {
        l: 54.43,
        a: 19.21,
        b: 29.65,
      },
      cmyk: null,
    },
    {
      code: '15-1225 TPG',
      name: 'Sand',
      hex: 'CDAB82',
      rgb: {
        r: 205,
        g: 171,
        b: 130,
      },
      lab: {
        l: 72.46,
        a: 8.63,
        b: 26.59,
      },
      cmyk: null,
    },
    {
      code: '16-1331 TPG',
      name: 'Toast',
      hex: 'CB9C7A',
      rgb: {
        r: 203,
        g: 156,
        b: 122,
      },
      lab: {
        l: 68.41,
        a: 15.75,
        b: 25.57,
      },
      cmyk: null,
    },
    {
      code: '16-1336 TPG',
      name: 'Biscuit',
      hex: 'B58760',
      rgb: {
        r: 181,
        g: 135,
        b: 96,
      },
      lab: {
        l: 60.51,
        a: 14.67,
        b: 28.97,
      },
      cmyk: null,
    },
    {
      code: '16-1235 TPG',
      name: 'Sandstorm',
      hex: 'BB8C69',
      rgb: {
        r: 187,
        g: 140,
        b: 105,
      },
      lab: {
        l: 62.65,
        a: 15.6,
        b: 26.52,
      },
      cmyk: null,
    },
    {
      code: '16-1432 TPG',
      name: 'Almond',
      hex: 'A97A55',
      rgb: {
        r: 169,
        g: 122,
        b: 85,
      },
      lab: {
        l: 55.8,
        a: 15.96,
        b: 28.79,
      },
      cmyk: null,
    },
    {
      code: '17-1134 TPG',
      name: 'Brown Sugar',
      hex: 'A17956',
      rgb: {
        r: 161,
        g: 121,
        b: 86,
      },
      lab: {
        l: 54.32,
        a: 13.01,
        b: 25.97,
      },
      cmyk: null,
    },
    {
      code: '17-1137 TPG',
      name: 'Cashew',
      hex: 'A67651',
      rgb: {
        r: 166,
        g: 118,
        b: 81,
      },
      lab: {
        l: 54.46,
        a: 16.27,
        b: 29.29,
      },
      cmyk: null,
    },
    {
      code: '17-1336 TPG',
      name: 'Bran',
      hex: 'A87452',
      rgb: {
        r: 168,
        g: 116,
        b: 82,
      },
      lab: {
        l: 54.14,
        a: 18.68,
        b: 28.21,
      },
      cmyk: null,
    },
    {
      code: '17-1340 TPG',
      name: 'Adobe',
      hex: 'A66948',
      rgb: {
        r: 166,
        g: 105,
        b: 72,
      },
      lab: {
        l: 51.13,
        a: 23.17,
        b: 30.03,
      },
      cmyk: null,
    },
    {
      code: '18-1154 TPG',
      name: 'Glazed Ginger',
      hex: '94603E',
      rgb: {
        r: 148,
        g: 96,
        b: 62,
      },
      lab: {
        l: 46.25,
        a: 19.7,
        b: 29.14,
      },
      cmyk: null,
    },
    {
      code: '17-1052 TPG',
      name: 'Roasted Pecan',
      hex: '99623A',
      rgb: {
        r: 153,
        g: 98,
        b: 58,
      },
      lab: {
        l: 47.51,
        a: 21.03,
        b: 32.97,
      },
      cmyk: null,
    },
    {
      code: '18-1155 TPG',
      name: 'Sugar Almond',
      hex: '945D3B',
      rgb: {
        r: 148,
        g: 93,
        b: 59,
      },
      lab: {
        l: 45.55,
        a: 20.74,
        b: 29.79,
      },
      cmyk: null,
    },
    {
      code: '18-1142 TPG',
      name: 'Leather Brown',
      hex: '975E3F',
      rgb: {
        r: 151,
        g: 94,
        b: 63,
      },
      lab: {
        l: 46.34,
        a: 21.82,
        b: 28.88,
      },
      cmyk: null,
    },
    {
      code: '18-1148 TPG',
      name: 'Caramel Café',
      hex: '8B5A3E',
      rgb: {
        r: 139,
        g: 90,
        b: 62,
      },
      lab: {
        l: 43.7,
        a: 18.87,
        b: 25.66,
      },
      cmyk: null,
    },
    {
      code: '15-1327 TPG',
      name: 'Peach Bloom',
      hex: 'DC9D7C',
      rgb: {
        r: 220,
        g: 157,
        b: 124,
      },
      lab: {
        l: 70.72,
        a: 21.98,
        b: 27.75,
      },
      cmyk: null,
    },
    {
      code: '16-1327 TPG',
      name: 'Toasted Nut',
      hex: 'C48B6C',
      rgb: {
        r: 196,
        g: 139,
        b: 108,
      },
      lab: {
        l: 63.42,
        a: 20.55,
        b: 26.28,
      },
      cmyk: null,
    },
    {
      code: '16-1332 TPG',
      name: 'Pheasant',
      hex: 'C68667',
      rgb: {
        r: 198,
        g: 134,
        b: 103,
      },
      lab: {
        l: 62.57,
        a: 23.9,
        b: 27.93,
      },
      cmyk: null,
    },
    {
      code: '17-1430 TPG',
      name: 'Pecan Brown',
      hex: 'A6755A',
      rgb: {
        r: 166,
        g: 117,
        b: 90,
      },
      lab: {
        l: 54.19,
        a: 17.83,
        b: 23.79,
      },
      cmyk: null,
    },
    {
      code: '16-1429 TPG',
      name: 'Sunburn',
      hex: 'B37459',
      rgb: {
        r: 179,
        g: 116,
        b: 89,
      },
      lab: {
        l: 55.53,
        a: 24.71,
        b: 26.12,
      },
      cmyk: null,
    },
    {
      code: '17-1147 TPG',
      name: 'Amber Brown',
      hex: 'A76D54',
      rgb: {
        r: 167,
        g: 109,
        b: 84,
      },
      lab: {
        l: 52.33,
        a: 22.61,
        b: 24.55,
      },
      cmyk: null,
    },
    {
      code: '17-1143 TPG',
      name: 'Hazel',
      hex: 'AF7757',
      rgb: {
        r: 175,
        g: 119,
        b: 87,
      },
      lab: {
        l: 55.79,
        a: 19.88,
        b: 27.66,
      },
      cmyk: null,
    },
    {
      code: '16-1328 TPG',
      name: 'Sandstone',
      hex: 'C88E70',
      rgb: {
        r: 200,
        g: 142,
        b: 112,
      },
      lab: {
        l: 64.78,
        a: 20.84,
        b: 26.02,
      },
      cmyk: null,
    },
    {
      code: '16-1439 TPG',
      name: 'Caramel',
      hex: 'C9815A',
      rgb: {
        r: 201,
        g: 129,
        b: 90,
      },
      lab: {
        l: 61.51,
        a: 27.08,
        b: 33.59,
      },
      cmyk: null,
    },
    {
      code: '17-1436 TPG',
      name: 'Raw Sienna',
      hex: 'BA745A',
      rgb: {
        r: 186,
        g: 116,
        b: 90,
      },
      lab: {
        l: 56.6,
        a: 27.34,
        b: 27.11,
      },
      cmyk: null,
    },
    {
      code: '17-1347 TPG',
      name: 'Autumn Leaf',
      hex: 'B26B54',
      rgb: {
        r: 178,
        g: 107,
        b: 84,
      },
      lab: {
        l: 53.35,
        a: 28.84,
        b: 26.21,
      },
      cmyk: null,
    },
    {
      code: '18-1250 TPG',
      name: 'Bombay Brown',
      hex: 'A25A40',
      rgb: {
        r: 162,
        g: 90,
        b: 64,
      },
      lab: {
        l: 47.08,
        a: 29.96,
        b: 29.26,
      },
      cmyk: null,
    },
    {
      code: '17-1342 TPG',
      name: 'Autumnal',
      hex: 'A55C3B',
      rgb: {
        r: 165,
        g: 92,
        b: 59,
      },
      lab: {
        l: 47.89,
        a: 29.27,
        b: 33.25,
      },
      cmyk: null,
    },
    {
      code: '18-1246 TPG',
      name: 'Umber',
      hex: '9C583A',
      rgb: {
        r: 156,
        g: 88,
        b: 58,
      },
      lab: {
        l: 45.7,
        a: 27.12,
        b: 30.86,
      },
      cmyk: null,
    },
    {
      code: '18-1450 TPG',
      name: 'Mecca Orange',
      hex: 'BC6350',
      rgb: {
        r: 188,
        g: 99,
        b: 80,
      },
      lab: {
        l: 53.14,
        a: 37.03,
        b: 28.83,
      },
      cmyk: null,
    },
    {
      code: '18-1451 TPG',
      name: 'Autumn Glaze',
      hex: 'B26150',
      rgb: {
        r: 178,
        g: 97,
        b: 80,
      },
      lab: {
        l: 51.18,
        a: 33.92,
        b: 25.62,
      },
      cmyk: null,
    },
    {
      code: '18-1340 TPG',
      name: "Potter's Clay",
      hex: 'A6533A',
      rgb: {
        r: 166,
        g: 83,
        b: 58,
      },
      lab: {
        l: 46.16,
        a: 34.52,
        b: 31.8,
      },
      cmyk: null,
    },
    {
      code: '18-1345 TPG',
      name: 'Cinnamon Stick',
      hex: 'A2553A',
      rgb: {
        r: 162,
        g: 85,
        b: 58,
      },
      lab: {
        l: 46.07,
        a: 31.77,
        b: 31.26,
      },
      cmyk: null,
    },
    {
      code: '18-1355 TPG',
      name: 'Rooibos Tea',
      hex: 'AB4D3A',
      rgb: {
        r: 171,
        g: 77,
        b: 58,
      },
      lab: {
        l: 45.78,
        a: 40.63,
        b: 31.65,
      },
      cmyk: null,
    },
    {
      code: '19-1250 TPG',
      name: 'Picante',
      hex: '924A3D',
      rgb: {
        r: 146,
        g: 74,
        b: 61,
      },
      lab: {
        l: 41.09,
        a: 31.19,
        b: 23.04,
      },
      cmyk: null,
    },
    {
      code: '18-1244 TPG',
      name: 'Ginger Bread',
      hex: '8C5641',
      rgb: {
        r: 140,
        g: 86,
        b: 65,
      },
      lab: {
        l: 42.96,
        a: 22.32,
        b: 22.74,
      },
      cmyk: null,
    },
    {
      code: '17-1540 TPG',
      name: 'Apricot Brandy',
      hex: 'C16D5E',
      rgb: {
        r: 193,
        g: 109,
        b: 94,
      },
      lab: {
        l: 56.28,
        a: 35,
        b: 24.81,
      },
      cmyk: null,
    },
    {
      code: '17-1532 TPG',
      name: 'Aragon',
      hex: 'AE6C60',
      rgb: {
        r: 174,
        g: 108,
        b: 96,
      },
      lab: {
        l: 53.34,
        a: 27.37,
        b: 19.33,
      },
      cmyk: null,
    },
    {
      code: '18-1535 TPG',
      name: 'Ginger Spice',
      hex: 'B96758',
      rgb: {
        r: 185,
        g: 103,
        b: 88,
      },
      lab: {
        l: 53.69,
        a: 33.87,
        b: 24.45,
      },
      cmyk: null,
    },
    {
      code: '18-1444 TPG',
      name: 'Tandoori Spice',
      hex: '9F534F',
      rgb: {
        r: 159,
        g: 83,
        b: 79,
      },
      lab: {
        l: 45.4,
        a: 33.39,
        b: 18.27,
      },
      cmyk: null,
    },
    {
      code: '18-1540 TPG',
      name: 'Cinnabar',
      hex: '9D514C',
      rgb: {
        r: 157,
        g: 81,
        b: 76,
      },
      lab: {
        l: 44.47,
        a: 33.66,
        b: 18.64,
      },
      cmyk: null,
    },
    {
      code: '18-1547 TPG',
      name: 'Bossa Nova',
      hex: '99403B',
      rgb: {
        r: 153,
        g: 64,
        b: 59,
      },
      lab: {
        l: 40.28,
        a: 39.43,
        b: 23.88,
      },
      cmyk: null,
    },
    {
      code: '18-1449 TPG',
      name: 'Ketchup',
      hex: '984A46',
      rgb: {
        r: 152,
        g: 74,
        b: 70,
      },
      lab: {
        l: 42.33,
        a: 34.92,
        b: 19.6,
      },
      cmyk: null,
    },
    {
      code: '16-1520 TPG',
      name: 'Lobster Bisque',
      hex: 'DE948F',
      rgb: {
        r: 222,
        g: 148,
        b: 143,
      },
      lab: {
        l: 69.29,
        a: 29.83,
        b: 15.45,
      },
      cmyk: null,
    },
    {
      code: '16-1329 TPG',
      name: 'Coral Haze',
      hex: 'E39282',
      rgb: {
        r: 227,
        g: 146,
        b: 130,
      },
      lab: {
        l: 69.43,
        a: 30.64,
        b: 23.13,
      },
      cmyk: null,
    },
    {
      code: '16-1431 TPG',
      name: 'Canyon Clay',
      hex: 'CD8578',
      rgb: {
        r: 205,
        g: 133,
        b: 120,
      },
      lab: {
        l: 63.41,
        a: 29.15,
        b: 19.67,
      },
      cmyk: null,
    },
    {
      code: '16-1624 TPG',
      name: 'Lantana',
      hex: 'D87E7B',
      rgb: {
        r: 216,
        g: 126,
        b: 123,
      },
      lab: {
        l: 63.46,
        a: 37.45,
        b: 18.55,
      },
      cmyk: null,
    },
    {
      code: '18-1629 TPG',
      name: 'Faded Rose',
      hex: 'BB6869',
      rgb: {
        r: 187,
        g: 104,
        b: 105,
      },
      lab: {
        l: 54.52,
        a: 35.96,
        b: 15.6,
      },
      cmyk: null,
    },
    {
      code: '18-1630 TPG',
      name: 'Dusty Cedar',
      hex: 'AD6464',
      rgb: {
        r: 173,
        g: 100,
        b: 100,
      },
      lab: {
        l: 51.37,
        a: 31.91,
        b: 14.01,
      },
      cmyk: null,
    },
    {
      code: '18-1648 TPG',
      name: 'Baked Apple',
      hex: 'B14E52',
      rgb: {
        r: 177,
        g: 78,
        b: 82,
      },
      lab: {
        l: 47.25,
        a: 43.06,
        b: 19.7,
      },
      cmyk: null,
    },
    {
      code: '14-1318 TPG',
      name: 'Coral Pink',
      hex: 'EAAD9D',
      rgb: {
        r: 234,
        g: 173,
        b: 157,
      },
      lab: {
        l: 76.54,
        a: 22.92,
        b: 17.89,
      },
      cmyk: null,
    },
    {
      code: '15-1523 TPG',
      name: 'Shrimp',
      hex: 'E09C8B',
      rgb: {
        r: 224,
        g: 156,
        b: 139,
      },
      lab: {
        l: 71.21,
        a: 25.9,
        b: 20.21,
      },
      cmyk: null,
    },
    {
      code: '17-1341 TPG',
      name: 'Tawny Orange',
      hex: 'D98575',
      rgb: {
        r: 217,
        g: 133,
        b: 117,
      },
      lab: {
        l: 64.89,
        a: 33.81,
        b: 23.56,
      },
      cmyk: null,
    },
    {
      code: '16-1532 TPG',
      name: 'Crabapple',
      hex: 'D57C6F',
      rgb: {
        r: 213,
        g: 124,
        b: 111,
      },
      lab: {
        l: 62.47,
        a: 35.33,
        b: 23.88,
      },
      cmyk: null,
    },
    {
      code: '16-1526 TPG',
      name: 'Terra Cotta',
      hex: 'CE837A',
      rgb: {
        r: 206,
        g: 131,
        b: 122,
      },
      lab: {
        l: 63.1,
        a: 30.72,
        b: 18.43,
      },
      cmyk: null,
    },
    {
      code: '17-1544 TPG',
      name: 'Burnt Sienna',
      hex: 'C46157',
      rgb: {
        r: 196,
        g: 97,
        b: 87,
      },
      lab: {
        l: 54.17,
        a: 40.85,
        b: 26.19,
      },
      cmyk: null,
    },
    {
      code: '18-1536 TPG',
      name: 'Hot Sauce',
      hex: 'AD5C54',
      rgb: {
        r: 173,
        g: 92,
        b: 84,
      },
      lab: {
        l: 49.41,
        a: 35.32,
        b: 20.99,
      },
      cmyk: null,
    },
    {
      code: '15-1333 TPG',
      name: 'Canyon Sunset',
      hex: 'E2957A',
      rgb: {
        r: 226,
        g: 149,
        b: 122,
      },
      lab: {
        l: 69.7,
        a: 29.36,
        b: 27.35,
      },
      cmyk: null,
    },
    {
      code: '16-1340 TPG',
      name: 'Brandied Melon',
      hex: 'D18365',
      rgb: {
        r: 209,
        g: 131,
        b: 101,
      },
      lab: {
        l: 63.29,
        a: 29.68,
        b: 30.48,
      },
      cmyk: null,
    },
    {
      code: '16-1435 TPG',
      name: 'Carnelian',
      hex: 'D17C62',
      rgb: {
        r: 209,
        g: 124,
        b: 98,
      },
      lab: {
        l: 61.75,
        a: 33.41,
        b: 29.91,
      },
      cmyk: null,
    },
    {
      code: '15-1429 TPG',
      name: 'Dusted Clay',
      hex: 'D07B61',
      rgb: {
        r: 208,
        g: 123,
        b: 97,
      },
      lab: {
        l: 61.31,
        a: 33.66,
        b: 30.2,
      },
      cmyk: null,
    },
    {
      code: '16-1441 TPG',
      name: 'Arabesque',
      hex: 'D07158',
      rgb: {
        r: 208,
        g: 113,
        b: 88,
      },
      lab: {
        l: 59.14,
        a: 38.58,
        b: 32.69,
      },
      cmyk: null,
    },
    {
      code: '16-1440 TPG',
      name: 'Langoustino',
      hex: 'C76C5A',
      rgb: {
        r: 199,
        g: 108,
        b: 90,
      },
      lab: {
        l: 56.77,
        a: 37.12,
        b: 27.97,
      },
      cmyk: null,
    },
    {
      code: '17-1446 TPG',
      name: 'Mango',
      hex: 'B76452',
      rgb: {
        r: 183,
        g: 100,
        b: 82,
      },
      lab: {
        l: 52.65,
        a: 34.66,
        b: 26.6,
      },
      cmyk: null,
    },
    {
      code: '15-1520 TPG',
      name: 'Blooming Dahlia',
      hex: 'EC9787',
      rgb: {
        r: 236,
        g: 151,
        b: 135,
      },
      lab: {
        l: 71.56,
        a: 33.05,
        b: 23.18,
      },
      cmyk: null,
    },
    {
      code: '16-1529 TPG',
      name: 'Burnt Coral',
      hex: 'EC8B83',
      rgb: {
        r: 236,
        g: 139,
        b: 131,
      },
      lab: {
        l: 68.99,
        a: 39.38,
        b: 22.26,
      },
      cmyk: null,
    },
    {
      code: '16-1345 TPG',
      name: 'Sun Baked',
      hex: 'D6866A',
      rgb: {
        r: 214,
        g: 134,
        b: 106,
      },
      lab: {
        l: 64.65,
        a: 30.73,
        b: 29.49,
      },
      cmyk: null,
    },
    {
      code: '17-1345 TPG',
      name: 'Spice Route',
      hex: 'BF624C',
      rgb: {
        r: 191,
        g: 98,
        b: 76,
      },
      lab: {
        l: 53.42,
        a: 37.93,
        b: 31.54,
      },
      cmyk: null,
    },
    {
      code: '17-1444 TPG',
      name: 'Ginger',
      hex: 'C86656',
      rgb: {
        r: 200,
        g: 102,
        b: 86,
      },
      lab: {
        l: 55.73,
        a: 40.47,
        b: 29.07,
      },
      cmyk: null,
    },
    {
      code: '16-1450 TPG',
      name: 'Flamingo',
      hex: 'DF7258',
      rgb: {
        r: 223,
        g: 114,
        b: 88,
      },
      lab: {
        l: 61.66,
        a: 43.96,
        b: 35.86,
      },
      cmyk: null,
    },
    {
      code: '18-1354 TPG',
      name: 'Burnt Ochre',
      hex: 'B65848',
      rgb: {
        r: 182,
        g: 88,
        b: 72,
      },
      lab: {
        l: 49.89,
        a: 39.87,
        b: 29.11,
      },
      cmyk: null,
    },
    {
      code: '15-1433 TPG',
      name: 'Papaya Punch',
      hex: 'FEA088',
      rgb: {
        r: 254,
        g: 160,
        b: 136,
      },
      lab: {
        l: 75.86,
        a: 34.87,
        b: 29.22,
      },
      cmyk: null,
    },
    {
      code: '14-1228 TPG',
      name: 'Peach Nectar',
      hex: 'FFBBA0',
      rgb: {
        r: 255,
        g: 187,
        b: 160,
      },
      lab: {
        l: 82.94,
        a: 26.08,
        b: 25.51,
      },
      cmyk: null,
    },
    {
      code: '15-1331 TPG',
      name: 'Coral Reef',
      hex: 'F9A482',
      rgb: {
        r: 249,
        g: 164,
        b: 130,
      },
      lab: {
        l: 76.02,
        a: 31.02,
        b: 32.32,
      },
      cmyk: null,
    },
    {
      code: '15-1530 TPG',
      name: 'Peach Pink',
      hex: 'F89686',
      rgb: {
        r: 248,
        g: 150,
        b: 134,
      },
      lab: {
        l: 73.03,
        a: 37.8,
        b: 26.36,
      },
      cmyk: null,
    },
    {
      code: '15-1340 TPG',
      name: 'Cadmium Orange',
      hex: 'F39470',
      rgb: {
        r: 243,
        g: 148,
        b: 112,
      },
      lab: {
        l: 71.62,
        a: 36.02,
        b: 36.05,
      },
      cmyk: null,
    },
    {
      code: '15-1239 TPG',
      name: 'Cantaloupe',
      hex: 'FFA47F',
      rgb: {
        r: 255,
        g: 164,
        b: 127,
      },
      lab: {
        l: 77.59,
        a: 34.88,
        b: 36.12,
      },
      cmyk: null,
    },
    {
      code: '16-1442 TPG',
      name: 'Melon',
      hex: 'F68A6A',
      rgb: {
        r: 246,
        g: 138,
        b: 106,
      },
      lab: {
        l: 69.79,
        a: 41.61,
        b: 36.86,
      },
      cmyk: null,
    },
    {
      code: '12-0915 TPG',
      name: 'Pale Peach',
      hex: 'FBD0B8',
      rgb: {
        r: 251,
        g: 208,
        b: 184,
      },
      lab: {
        l: 87.08,
        a: 13.48,
        b: 18.25,
      },
      cmyk: null,
    },
    {
      code: '12-0917 TPG',
      name: 'Bleached Apricot',
      hex: 'FCCFAE',
      rgb: {
        r: 252,
        g: 207,
        b: 174,
      },
      lab: {
        l: 86.8,
        a: 12.98,
        b: 23.55,
      },
      cmyk: null,
    },
    {
      code: '14-1219 TPG',
      name: 'Peach Parfait',
      hex: 'F9BFAA',
      rgb: {
        r: 249,
        g: 191,
        b: 170,
      },
      lab: {
        l: 82.74,
        a: 20.2,
        b: 19.78,
      },
      cmyk: null,
    },
    {
      code: '13-1021 TPG',
      name: 'Prairie Sunset',
      hex: 'FCC0A3',
      rgb: {
        r: 252,
        g: 192,
        b: 163,
      },
      lab: {
        l: 83.06,
        a: 20,
        b: 24.12,
      },
      cmyk: null,
    },
    {
      code: '14-1323 TPG',
      name: 'Salmon',
      hex: 'F5AD99',
      rgb: {
        r: 245,
        g: 173,
        b: 153,
      },
      lab: {
        l: 77.9,
        a: 26.74,
        b: 22.19,
      },
      cmyk: null,
    },
    {
      code: '14-1227 TPG',
      name: 'Peach',
      hex: 'F1AD88',
      rgb: {
        r: 241,
        g: 173,
        b: 136,
      },
      lab: {
        l: 76.98,
        a: 23.76,
        b: 30.08,
      },
      cmyk: null,
    },
    {
      code: '15-1334 TPG',
      name: 'Shell Coral',
      hex: 'EC9A79',
      rgb: {
        r: 236,
        g: 154,
        b: 121,
      },
      lab: {
        l: 72.07,
        a: 30.2,
        b: 31.44,
      },
      cmyk: null,
    },
    {
      code: '13-1023 TPG',
      name: 'Peach Fuzz',
      hex: 'FFC197',
      rgb: {
        r: 255,
        g: 193,
        b: 151,
      },
      lab: {
        l: 83.87,
        a: 20.23,
        b: 31.8,
      },
      cmyk: null,
    },
    {
      code: '14-1225 TPG',
      name: 'Beach Sand',
      hex: 'F8BF9A',
      rgb: {
        r: 248,
        g: 191,
        b: 154,
      },
      lab: {
        l: 82.35,
        a: 17.6,
        b: 27.89,
      },
      cmyk: null,
    },
    {
      code: '14-1230 TPG',
      name: 'Apricot Wash',
      hex: 'F6B088',
      rgb: {
        r: 246,
        g: 176,
        b: 136,
      },
      lab: {
        l: 78.48,
        a: 24.17,
        b: 32.04,
      },
      cmyk: null,
    },
    {
      code: '14-1231 TPG',
      name: 'Peach Cobbler',
      hex: 'FFB582',
      rgb: {
        r: 255,
        g: 181,
        b: 130,
      },
      lab: {
        l: 80.95,
        a: 25.97,
        b: 38.76,
      },
      cmyk: null,
    },
    {
      code: '14-1241 TPG',
      name: 'Orange Chiffon',
      hex: 'FAAC7E',
      rgb: {
        r: 250,
        g: 172,
        b: 126,
      },
      lab: {
        l: 77.81,
        a: 26.95,
        b: 36.39,
      },
      cmyk: null,
    },
    {
      code: '14-1135 TPG',
      name: 'Salmon Buff',
      hex: 'FFB17F',
      rgb: {
        r: 255,
        g: 177,
        b: 127,
      },
      lab: {
        l: 79.69,
        a: 26.15,
        b: 38.59,
      },
      cmyk: null,
    },
    {
      code: '14-1139 TPG',
      name: 'Pumpkin',
      hex: 'F9A76D',
      rgb: {
        r: 249,
        g: 167,
        b: 109,
      },
      lab: {
        l: 76.44,
        a: 27.03,
        b: 43.96,
      },
      cmyk: null,
    },
    {
      code: '13-1026 TPG',
      name: 'Creampuff',
      hex: 'FFD4B1',
      rgb: {
        r: 255,
        g: 212,
        b: 177,
      },
      lab: {
        l: 88.64,
        a: 13.7,
        b: 24.37,
      },
      cmyk: null,
    },
    {
      code: '13-1020 TPG',
      name: 'Apricot Ice',
      hex: 'FCC19A',
      rgb: {
        r: 252,
        g: 193,
        b: 154,
      },
      lab: {
        l: 83.12,
        a: 18.41,
        b: 28.88,
      },
      cmyk: null,
    },
    {
      code: '13-1022 TPG',
      name: 'Caramel Cream',
      hex: 'F3C29A',
      rgb: {
        r: 243,
        g: 194,
        b: 154,
      },
      lab: {
        l: 82.51,
        a: 14.57,
        b: 27.6,
      },
      cmyk: null,
    },
    {
      code: '13-1125 TPG',
      name: 'Peach Quartz',
      hex: 'F6B995',
      rgb: {
        r: 246,
        g: 185,
        b: 149,
      },
      lab: {
        l: 80.55,
        a: 20.35,
        b: 27.79,
      },
      cmyk: null,
    },
    {
      code: '14-1224 TPG',
      name: 'Coral Sands',
      hex: 'EEAB8A',
      rgb: {
        r: 238,
        g: 171,
        b: 138,
      },
      lab: {
        l: 76.44,
        a: 23.62,
        b: 28.19,
      },
      cmyk: null,
    },
    {
      code: '16-1338 TPG',
      name: 'Copper Tan',
      hex: 'E39165',
      rgb: {
        r: 227,
        g: 145,
        b: 101,
      },
      lab: {
        l: 68.65,
        a: 29.55,
        b: 37.64,
      },
      cmyk: null,
    },
    {
      code: '16-1337 TPG',
      name: 'Coral Gold',
      hex: 'D9875D',
      rgb: {
        r: 217,
        g: 135,
        b: 93,
      },
      lab: {
        l: 65.12,
        a: 29.72,
        b: 37.13,
      },
      cmyk: null,
    },
    {
      code: '13-1017 TPG',
      name: 'Almond Cream',
      hex: 'F4CAA8',
      rgb: {
        r: 244,
        g: 202,
        b: 168,
      },
      lab: {
        l: 84.66,
        a: 11.84,
        b: 23.26,
      },
      cmyk: null,
    },
    {
      code: '13-1019 TPG',
      name: 'Cream Blush',
      hex: 'FAC69D',
      rgb: {
        r: 250,
        g: 198,
        b: 157,
      },
      lab: {
        l: 84.28,
        a: 15.27,
        b: 28.81,
      },
      cmyk: null,
    },
    {
      code: '14-1133 TPG',
      name: 'Apricot Nectar',
      hex: 'EEAA75',
      rgb: {
        r: 238,
        g: 170,
        b: 117,
      },
      lab: {
        l: 75.73,
        a: 21.92,
        b: 38.5,
      },
      cmyk: null,
    },
    {
      code: '15-1234 TPG',
      name: 'Gold Earth',
      hex: 'DE9D6A',
      rgb: {
        r: 222,
        g: 157,
        b: 106,
      },
      lab: {
        l: 70.77,
        a: 21.39,
        b: 37.77,
      },
      cmyk: null,
    },
    {
      code: '15-1237 TPG',
      name: 'Apricot Tan',
      hex: 'DD9760',
      rgb: {
        r: 221,
        g: 151,
        b: 96,
      },
      lab: {
        l: 69.2,
        a: 23.15,
        b: 40.79,
      },
      cmyk: null,
    },
    {
      code: '16-1346 TPG',
      name: 'Golden Ochre',
      hex: 'CA7E4A',
      rgb: {
        r: 202,
        g: 126,
        b: 74,
      },
      lab: {
        l: 60.92,
        a: 27.26,
        b: 42,
      },
      cmyk: null,
    },
    {
      code: '16-1443 TPG',
      name: 'Apricot Buff',
      hex: 'D0804F',
      rgb: {
        r: 208,
        g: 128,
        b: 79,
      },
      lab: {
        l: 62.23,
        a: 29.1,
        b: 40.89,
      },
      cmyk: null,
    },
    {
      code: '16-1347 TPG',
      name: 'Peach Caramel',
      hex: 'C77748',
      rgb: {
        r: 199,
        g: 119,
        b: 72,
      },
      lab: {
        l: 58.99,
        a: 30.34,
        b: 40.52,
      },
      cmyk: null,
    },
    {
      code: '16-1348 TPG',
      name: 'Tomato Cream',
      hex: 'C6794C',
      rgb: {
        r: 198,
        g: 121,
        b: 76,
      },
      lab: {
        l: 59.18,
        a: 29.57,
        b: 38.44,
      },
      cmyk: null,
    },
    {
      code: '18-1248 TPG',
      name: 'Rust',
      hex: 'B35F44',
      rgb: {
        r: 179,
        g: 95,
        b: 68,
      },
      lab: {
        l: 50.79,
        a: 34.45,
        b: 32.48,
      },
      cmyk: null,
    },
    {
      code: '16-1448 TPG',
      name: 'Burnt Orange',
      hex: 'C96939',
      rgb: {
        r: 201,
        g: 105,
        b: 57,
      },
      lab: {
        l: 56.19,
        a: 37.12,
        b: 45.48,
      },
      cmyk: null,
    },
    {
      code: '16-1449 TPG',
      name: 'Gold Flame',
      hex: 'BD603A',
      rgb: {
        r: 189,
        g: 96,
        b: 58,
      },
      lab: {
        l: 52.47,
        a: 36.47,
        b: 40.24,
      },
      cmyk: null,
    },
    {
      code: '17-1353 TPG',
      name: 'Apricot Orange',
      hex: 'C46C46',
      rgb: {
        r: 196,
        g: 108,
        b: 70,
      },
      lab: {
        l: 56,
        a: 34.07,
        b: 38.36,
      },
      cmyk: null,
    },
    {
      code: '16-1260 TPG',
      name: 'Harvest Pumpkin',
      hex: 'D36A3E',
      rgb: {
        r: 211,
        g: 106,
        b: 62,
      },
      lab: {
        l: 57.92,
        a: 40.44,
        b: 45.08,
      },
      cmyk: null,
    },
    {
      code: '15-1242 TPG',
      name: 'Muskmelon',
      hex: 'F29864',
      rgb: {
        r: 242,
        g: 152,
        b: 100,
      },
      lab: {
        l: 72.14,
        a: 31.69,
        b: 43.28,
      },
      cmyk: null,
    },
    {
      code: '16-1350 TPG',
      name: 'Amberglow',
      hex: 'E17F47',
      rgb: {
        r: 225,
        g: 127,
        b: 71,
      },
      lab: {
        l: 64.25,
        a: 35.81,
        b: 48.08,
      },
      cmyk: null,
    },
    {
      code: '16-1253 TPG',
      name: 'Orange Ochre',
      hex: 'DF8045',
      rgb: {
        r: 223,
        g: 128,
        b: 69,
      },
      lab: {
        l: 64.15,
        a: 34.8,
        b: 49.1,
      },
      cmyk: null,
    },
    {
      code: '16-1454 TPG',
      name: 'Jaffa Orange',
      hex: 'DB7344',
      rgb: {
        r: 219,
        g: 115,
        b: 68,
      },
      lab: {
        l: 61.04,
        a: 39.86,
        b: 45.89,
      },
      cmyk: null,
    },
    {
      code: '17-1145 TPG',
      name: 'Autumn Maple',
      hex: 'C96C32',
      rgb: {
        r: 201,
        g: 108,
        b: 50,
      },
      lab: {
        l: 56.57,
        a: 35.43,
        b: 49.37,
      },
      cmyk: null,
    },
    {
      code: '17-1140 TPG',
      name: 'Marmalade',
      hex: 'C66E33',
      rgb: {
        r: 198,
        g: 110,
        b: 51,
      },
      lab: {
        l: 56.75,
        a: 33.09,
        b: 48.49,
      },
      cmyk: null,
    },
    {
      code: '18-1249 TPG',
      name: 'Hawaiian Sunset',
      hex: 'BD6537',
      rgb: {
        r: 189,
        g: 101,
        b: 55,
      },
      lab: {
        l: 53.44,
        a: 33.99,
        b: 42.69,
      },
      cmyk: null,
    },
    {
      code: '15-1245 TPG',
      name: 'Mock Orange',
      hex: 'FFA76C',
      rgb: {
        r: 255,
        g: 167,
        b: 108,
      },
      lab: {
        l: 78.05,
        a: 32.07,
        b: 46.8,
      },
      cmyk: null,
    },
    {
      code: '15-1243 TPG',
      name: 'Papaya',
      hex: 'FFA269',
      rgb: {
        r: 255,
        g: 162,
        b: 105,
      },
      lab: {
        l: 77.19,
        a: 35.62,
        b: 46.88,
      },
      cmyk: null,
    },
    {
      code: '15-1247 TPG',
      name: 'Tangerine',
      hex: 'F7955D',
      rgb: {
        r: 247,
        g: 149,
        b: 93,
      },
      lab: {
        l: 72.2,
        a: 34.99,
        b: 47.02,
      },
      cmyk: null,
    },
    {
      code: '16-1257 TPG',
      name: 'Sun Orange',
      hex: 'F18643',
      rgb: {
        r: 241,
        g: 134,
        b: 67,
      },
      lab: {
        l: 68.1,
        a: 38.61,
        b: 54.88,
      },
      cmyk: null,
    },
    {
      code: '15-1150 TPG',
      name: 'Dark Cheddar',
      hex: 'E4882E',
      rgb: {
        r: 228,
        g: 136,
        b: 46,
      },
      lab: {
        l: 66.51,
        a: 31.47,
        b: 61.46,
      },
      cmyk: null,
    },
    {
      code: '16-1164 TPG',
      name: 'Orange Pepper',
      hex: 'E57E2E',
      rgb: {
        r: 229,
        g: 126,
        b: 46,
      },
      lab: {
        l: 64.58,
        a: 36.95,
        b: 60.07,
      },
      cmyk: null,
    },
    {
      code: '16-1255 TPG',
      name: 'Russet Orange',
      hex: 'E47A2F',
      rgb: {
        r: 228,
        g: 122,
        b: 47,
      },
      lab: {
        l: 63.65,
        a: 39.24,
        b: 59,
      },
      cmyk: null,
    },
    {
      code: '15-1160 TPG',
      name: 'Blazing Orange',
      hex: 'FFA351',
      rgb: {
        r: 255,
        g: 163,
        b: 81,
      },
      lab: {
        l: 77.55,
        a: 34.46,
        b: 59.21,
      },
      cmyk: null,
    },
    {
      code: '15-1153 TPG',
      name: 'Apricot',
      hex: 'F0973D',
      rgb: {
        r: 240,
        g: 151,
        b: 61,
      },
      lab: {
        l: 71.36,
        a: 29.65,
        b: 60.73,
      },
      cmyk: null,
    },
    {
      code: '15-1151 TPG',
      name: 'Iceland Poppy',
      hex: 'F69D4D',
      rgb: {
        r: 246,
        g: 157,
        b: 77,
      },
      lab: {
        l: 73.45,
        a: 29.37,
        b: 56.23,
      },
      cmyk: null,
    },
    {
      code: '15-1157 TPG',
      name: 'Flame Orange',
      hex: 'F98E30',
      rgb: {
        r: 249,
        g: 142,
        b: 48,
      },
      lab: {
        l: 70.76,
        a: 37.37,
        b: 65.99,
      },
      cmyk: null,
    },
    {
      code: '15-1164 TPG',
      name: 'Bright Marigold',
      hex: 'FF911F',
      rgb: {
        r: 255,
        g: 145,
        b: 31,
      },
      lab: {
        l: 73.55,
        a: 41.91,
        b: 74.89,
      },
      cmyk: null,
    },
    {
      code: '15-1262 TPG',
      name: 'Carrot Curl',
      hex: 'FF8B27',
      rgb: {
        r: 255,
        g: 139,
        b: 39,
      },
      lab: {
        l: 71.92,
        a: 43.42,
        b: 70.97,
      },
      cmyk: null,
    },
    {
      code: '15-1263 TPG',
      name: 'Autumn Glory',
      hex: 'FF8927',
      rgb: {
        r: 255,
        g: 137,
        b: 39,
      },
      lab: {
        l: 71.76,
        a: 45.59,
        b: 71.02,
      },
      cmyk: null,
    },
    {
      code: '15-1264 TPG',
      name: 'Turmeric',
      hex: 'FF842A',
      rgb: {
        r: 255,
        g: 132,
        b: 42,
      },
      lab: {
        l: 70.24,
        a: 46.21,
        b: 68.56,
      },
      cmyk: null,
    },
    {
      code: '15-1335 TPG',
      name: 'Tangelo',
      hex: 'FF8028',
      rgb: {
        r: 255,
        g: 128,
        b: 40,
      },
      lab: {
        l: 69.3,
        a: 47.99,
        b: 68.6,
      },
      cmyk: null,
    },
    {
      code: '17-1350 TPG',
      name: 'Oriole',
      hex: 'FF7B25',
      rgb: {
        r: 255,
        g: 123,
        b: 37,
      },
      lab: {
        l: 68.79,
        a: 50.68,
        b: 69.16,
      },
      cmyk: null,
    },
    {
      code: '16-1364 TPG',
      name: 'Vibrant Orange',
      hex: 'FF7338',
      rgb: {
        r: 255,
        g: 115,
        b: 56,
      },
      lab: {
        l: 66.63,
        a: 53.25,
        b: 59.63,
      },
      cmyk: null,
    },
    {
      code: '16-1356 TPG',
      name: 'Persimmon Orange',
      hex: 'F57B36',
      rgb: {
        r: 245,
        g: 123,
        b: 54,
      },
      lab: {
        l: 66.47,
        a: 45.32,
        b: 59.75,
      },
      cmyk: null,
    },
    {
      code: '16-1358 TPG',
      name: 'Orange Tiger',
      hex: 'FE7133',
      rgb: {
        r: 254,
        g: 113,
        b: 51,
      },
      lab: {
        l: 65.92,
        a: 53.53,
        b: 60.98,
      },
      cmyk: null,
    },
    {
      code: '17-1349 TPG',
      name: 'Exuberance',
      hex: 'E87233',
      rgb: {
        r: 232,
        g: 114,
        b: 51,
      },
      lab: {
        l: 62.6,
        a: 44.91,
        b: 56.39,
      },
      cmyk: null,
    },
    {
      code: '16-1343 TPG',
      name: 'Autumn Sunset',
      hex: 'F58C58',
      rgb: {
        r: 245,
        g: 140,
        b: 88,
      },
      lab: {
        l: 70.06,
        a: 38.07,
        b: 47.12,
      },
      cmyk: null,
    },
    {
      code: '16-1344 TPG',
      name: 'Dusty Orange',
      hex: 'E6815A',
      rgb: {
        r: 230,
        g: 129,
        b: 90,
      },
      lab: {
        l: 65.54,
        a: 38.86,
        b: 40.03,
      },
      cmyk: null,
    },
    {
      code: '16-1359 TPG',
      name: 'Orange Peel',
      hex: 'FD823E',
      rgb: {
        r: 253,
        g: 130,
        b: 62,
      },
      lab: {
        l: 69.03,
        a: 45.2,
        b: 58.71,
      },
      cmyk: null,
    },
    {
      code: '17-1360 TPG',
      name: 'Celosia Orange',
      hex: 'E97544',
      rgb: {
        r: 233,
        g: 117,
        b: 68,
      },
      lab: {
        l: 63.45,
        a: 44.26,
        b: 49.19,
      },
      cmyk: null,
    },
    {
      code: '16-1459 TPG',
      name: 'Mandarin Orange',
      hex: 'EA6F46',
      rgb: {
        r: 234,
        g: 111,
        b: 70,
      },
      lab: {
        l: 62.63,
        a: 47.44,
        b: 47.39,
      },
      cmyk: null,
    },
    {
      code: '16-1462 TPG',
      name: 'Golden Poppy',
      hex: 'F36E3C',
      rgb: {
        r: 243,
        g: 110,
        b: 60,
      },
      lab: {
        l: 63.77,
        a: 51.12,
        b: 54.1,
      },
      cmyk: null,
    },
    {
      code: '16-1453 TPG',
      name: 'Exotic Orange',
      hex: 'FC6E45',
      rgb: {
        r: 252,
        g: 110,
        b: 69,
      },
      lab: {
        l: 65.31,
        a: 54.92,
        b: 51.97,
      },
      cmyk: null,
    },
    {
      code: '16-1357 TPG',
      name: 'Bird of Paradise',
      hex: 'FF8D54',
      rgb: {
        r: 255,
        g: 141,
        b: 84,
      },
      lab: {
        l: 72.72,
        a: 45.12,
        b: 52.79,
      },
      cmyk: null,
    },
    {
      code: '16-1360 TPG',
      name: 'Nectarine',
      hex: 'FF895A',
      rgb: {
        r: 255,
        g: 137,
        b: 90,
      },
      lab: {
        l: 72.2,
        a: 47.13,
        b: 49.37,
      },
      cmyk: null,
    },
    {
      code: '16-1349 TPG',
      name: 'Coral Rose',
      hex: 'F3794E',
      rgb: {
        r: 243,
        g: 121,
        b: 78,
      },
      lab: {
        l: 65.9,
        a: 46.25,
        b: 47.18,
      },
      cmyk: null,
    },
    {
      code: '16-1361 TPG',
      name: 'Carrot',
      hex: 'F77042',
      rgb: {
        r: 247,
        g: 112,
        b: 66,
      },
      lab: {
        l: 64.89,
        a: 51.97,
        b: 52.44,
      },
      cmyk: null,
    },
    {
      code: '17-1361 TPG',
      name: 'Scarlet Ibis',
      hex: 'F86139',
      rgb: {
        r: 248,
        g: 97,
        b: 57,
      },
      lab: {
        l: 62.44,
        a: 58.76,
        b: 54.38,
      },
      cmyk: null,
    },
    {
      code: '16-1363 TPG',
      name: "Puffin's Bill",
      hex: 'E66132',
      rgb: {
        r: 230,
        g: 97,
        b: 50,
      },
      lab: {
        l: 59.3,
        a: 52.15,
        b: 53.9,
      },
      cmyk: null,
    },
    {
      code: '16-1460 TPG',
      name: 'Dragon Fire',
      hex: 'FC6D44',
      rgb: {
        r: 252,
        g: 109,
        b: 68,
      },
      lab: {
        l: 65.16,
        a: 54.95,
        b: 52.35,
      },
      cmyk: null,
    },
    {
      code: '16-1362 TPG',
      name: 'Vermillion Orange',
      hex: 'FC693F',
      rgb: {
        r: 252,
        g: 105,
        b: 63,
      },
      lab: {
        l: 64.48,
        a: 57.11,
        b: 53.74,
      },
      cmyk: null,
    },
    {
      code: '16-1452 TPG',
      name: 'Firecracker',
      hex: 'F2714E',
      rgb: {
        r: 242,
        g: 113,
        b: 78,
      },
      lab: {
        l: 64.28,
        a: 49.68,
        b: 45.51,
      },
      cmyk: null,
    },
    {
      code: '17-1452 TPG',
      name: 'Koi',
      hex: 'D05F44',
      rgb: {
        r: 208,
        g: 95,
        b: 68,
      },
      lab: {
        l: 55.42,
        a: 45.61,
        b: 38.98,
      },
      cmyk: null,
    },
    {
      code: '17-1462 TPG',
      name: 'Flame',
      hex: 'F85C3B',
      rgb: {
        r: 248,
        g: 92,
        b: 59,
      },
      lab: {
        l: 61.76,
        a: 61.31,
        b: 52.89,
      },
      cmyk: null,
    },
    {
      code: '17-1461 TPG',
      name: 'Orangeade',
      hex: 'E55D3B',
      rgb: {
        r: 229,
        g: 93,
        b: 59,
      },
      lab: {
        l: 58.58,
        a: 54.2,
        b: 48.34,
      },
      cmyk: null,
    },
    {
      code: '17-1464 TPG',
      name: 'Red Orange',
      hex: 'F25E35',
      rgb: {
        r: 242,
        g: 94,
        b: 53,
      },
      lab: {
        l: 60.87,
        a: 58.22,
        b: 54.76,
      },
      cmyk: null,
    },
    {
      code: '17-1463 TPG',
      name: 'Tangerine Tango',
      hex: 'E44F33',
      rgb: {
        r: 228,
        g: 79,
        b: 51,
      },
      lab: {
        l: 56.33,
        a: 59.46,
        b: 50.3,
      },
      cmyk: null,
    },
    {
      code: '17-1456 TPG',
      name: 'Tigerlily',
      hex: 'E2654D',
      rgb: {
        r: 226,
        g: 101,
        b: 77,
      },
      lab: {
        l: 59.7,
        a: 50.36,
        b: 39.77,
      },
      cmyk: null,
    },
    {
      code: '17-1450 TPG',
      name: 'Summer Fig',
      hex: 'C35448',
      rgb: {
        r: 195,
        g: 84,
        b: 72,
      },
      lab: {
        l: 51.38,
        a: 46.55,
        b: 31.75,
      },
      cmyk: null,
    },
    {
      code: '18-1448 TPG',
      name: 'Chili',
      hex: 'BF5B4E',
      rgb: {
        r: 191,
        g: 91,
        b: 78,
      },
      lab: {
        l: 52.02,
        a: 42,
        b: 28.62,
      },
      cmyk: null,
    },
    {
      code: '18-1447 TPG',
      name: 'Orange Rust',
      hex: 'C3634F',
      rgb: {
        r: 195,
        g: 99,
        b: 79,
      },
      lab: {
        l: 54.18,
        a: 39.57,
        b: 30.76,
      },
      cmyk: null,
    },
    {
      code: '17-1553 TPG',
      name: 'Paprika',
      hex: 'CC5953',
      rgb: {
        r: 204,
        g: 89,
        b: 83,
      },
      lab: {
        l: 53.86,
        a: 48.58,
        b: 28.44,
      },
      cmyk: null,
    },
    {
      code: '18-1454 TPG',
      name: 'Red Clay',
      hex: 'C15044',
      rgb: {
        r: 193,
        g: 80,
        b: 68,
      },
      lab: {
        l: 50.38,
        a: 47.2,
        b: 32.61,
      },
      cmyk: null,
    },
    {
      code: '17-1449 TPG',
      name: 'Pureed Pumpkin',
      hex: 'C14F39',
      rgb: {
        r: 193,
        g: 79,
        b: 57,
      },
      lab: {
        l: 49.85,
        a: 47.18,
        b: 38.2,
      },
      cmyk: null,
    },
    {
      code: '18-1445 TPG',
      name: 'Spicy Orange',
      hex: 'DD523F',
      rgb: {
        r: 221,
        g: 82,
        b: 63,
      },
      lab: {
        l: 55.54,
        a: 56.25,
        b: 42.61,
      },
      cmyk: null,
    },
    {
      code: '17-1558 TPG',
      name: 'Grenadine',
      hex: 'DD4D47',
      rgb: {
        r: 221,
        g: 77,
        b: 71,
      },
      lab: {
        l: 54.69,
        a: 59.31,
        b: 37.52,
      },
      cmyk: null,
    },
    {
      code: '18-1561 TPG',
      name: 'Orange.com',
      hex: 'D84539',
      rgb: {
        r: 216,
        g: 69,
        b: 57,
      },
      lab: {
        l: 52.73,
        a: 60.15,
        b: 42.74,
      },
      cmyk: null,
    },
    {
      code: '17-1563 TPG',
      name: 'Cherry Tomato',
      hex: 'E94B3C',
      rgb: {
        r: 233,
        g: 75,
        b: 60,
      },
      lab: {
        l: 56.68,
        a: 63.46,
        b: 46.27,
      },
      cmyk: null,
    },
    {
      code: '17-1564 TPG',
      name: 'Fiesta',
      hex: 'DE4D44',
      rgb: {
        r: 222,
        g: 77,
        b: 68,
      },
      lab: {
        l: 55.02,
        a: 59.27,
        b: 39.5,
      },
      cmyk: null,
    },
    {
      code: '18-1564 TPG',
      name: 'Poinciana',
      hex: 'C94235',
      rgb: {
        r: 201,
        g: 66,
        b: 53,
      },
      lab: {
        l: 49.41,
        a: 55.51,
        b: 40.28,
      },
      cmyk: null,
    },
    {
      code: '18-1550 TPG',
      name: 'Aurora Red',
      hex: 'BD4848',
      rgb: {
        r: 189,
        g: 72,
        b: 72,
      },
      lab: {
        l: 48.34,
        a: 50.14,
        b: 27.59,
      },
      cmyk: null,
    },
    {
      code: '16-1544 TPG',
      name: 'Persimmon',
      hex: 'F57B6E',
      rgb: {
        r: 245,
        g: 123,
        b: 110,
      },
      lab: {
        l: 66.83,
        a: 48.76,
        b: 31.21,
      },
      cmyk: null,
    },
    {
      code: '16-1541 TPG',
      name: 'Camellia',
      hex: 'F87766',
      rgb: {
        r: 248,
        g: 119,
        b: 102,
      },
      lab: {
        l: 66.43,
        a: 51.02,
        b: 35.52,
      },
      cmyk: null,
    },
    {
      code: '17-1547 TPG',
      name: 'Emberglow',
      hex: 'E66C63',
      rgb: {
        r: 230,
        g: 108,
        b: 99,
      },
      lab: {
        l: 61.77,
        a: 50.08,
        b: 30.66,
      },
      cmyk: null,
    },
    {
      code: '16-1451 TPG',
      name: 'Nasturtium',
      hex: 'F96A51',
      rgb: {
        r: 249,
        g: 106,
        b: 81,
      },
      lab: {
        l: 64.3,
        a: 56.32,
        b: 44.18,
      },
      cmyk: null,
    },
    {
      code: '17-1562 TPG',
      name: 'Mandarin Red',
      hex: 'EA5743',
      rgb: {
        r: 234,
        g: 87,
        b: 67,
      },
      lab: {
        l: 58.51,
        a: 59.23,
        b: 44.32,
      },
      cmyk: null,
    },
    {
      code: '18-1664 TPG',
      name: 'Fiery Red',
      hex: 'D2393E',
      rgb: {
        r: 210,
        g: 57,
        b: 62,
      },
      lab: {
        l: 50.08,
        a: 62.97,
        b: 36.64,
      },
      cmyk: null,
    },
    {
      code: '18-1549 TPG',
      name: 'Valiant Poppy',
      hex: 'BD3D3A',
      rgb: {
        r: 189,
        g: 61,
        b: 58,
      },
      lab: {
        l: 46.53,
        a: 54.88,
        b: 33.45,
      },
      cmyk: null,
    },
    {
      code: '16-1543 TPG',
      name: 'Fusion Coral',
      hex: 'FF8573',
      rgb: {
        r: 255,
        g: 133,
        b: 115,
      },
      lab: {
        l: 71.13,
        a: 49.2,
        b: 34.55,
      },
      cmyk: null,
    },
    {
      code: '16-1548 TPG',
      name: 'Peach Echo',
      hex: 'F77D73',
      rgb: {
        r: 247,
        g: 125,
        b: 115,
      },
      lab: {
        l: 67.71,
        a: 48.29,
        b: 29.95,
      },
      cmyk: null,
    },
    {
      code: '16-1539 TPG',
      name: 'Coral',
      hex: 'EC776B',
      rgb: {
        r: 236,
        g: 119,
        b: 107,
      },
      lab: {
        l: 64.72,
        a: 47.61,
        b: 29.86,
      },
      cmyk: null,
    },
    {
      code: '16-1542 TPG',
      name: 'Fresh Salmon',
      hex: 'FF8674',
      rgb: {
        r: 255,
        g: 134,
        b: 116,
      },
      lab: {
        l: 71.39,
        a: 48.43,
        b: 34.54,
      },
      cmyk: null,
    },
    {
      code: '16-1545 TPG',
      name: 'Coral Quartz',
      hex: 'F87A6D',
      rgb: {
        r: 248,
        g: 122,
        b: 109,
      },
      lab: {
        l: 67.08,
        a: 49.88,
        b: 32.36,
      },
      cmyk: null,
    },
    {
      code: '16-1546 TPG',
      name: 'Living Coral',
      hex: 'FC776A',
      rgb: {
        r: 252,
        g: 119,
        b: 106,
      },
      lab: {
        l: 67.21,
        a: 52.88,
        b: 34.13,
      },
      cmyk: null,
    },
    {
      code: '17-1656 TPG',
      name: 'Hot Coral',
      hex: 'F0605C',
      rgb: {
        r: 240,
        g: 96,
        b: 92,
      },
      lab: {
        l: 61.25,
        a: 58.16,
        b: 34.13,
      },
      cmyk: null,
    },
    {
      code: '15-1435 TPG',
      name: 'Desert Flower',
      hex: 'FF9A8D',
      rgb: {
        r: 255,
        g: 154,
        b: 141,
      },
      lab: {
        l: 75.97,
        a: 41.41,
        b: 26.63,
      },
      cmyk: null,
    },
    {
      code: '16-1641 TPG',
      name: 'Georgia Peach',
      hex: 'F37778',
      rgb: {
        r: 243,
        g: 119,
        b: 120,
      },
      lab: {
        l: 65.97,
        a: 50.47,
        b: 24.43,
      },
      cmyk: null,
    },
    {
      code: '16-1640 TPG',
      name: 'Sugar Coral',
      hex: 'F86E76',
      rgb: {
        r: 248,
        g: 110,
        b: 118,
      },
      lab: {
        l: 65.28,
        a: 54.71,
        b: 25.67,
      },
      cmyk: null,
    },
    {
      code: '17-1647 TPG',
      name: 'Dubarry',
      hex: 'ED696F',
      rgb: {
        r: 237,
        g: 105,
        b: 111,
      },
      lab: {
        l: 62.5,
        a: 54.09,
        b: 24.88,
      },
      cmyk: null,
    },
    {
      code: '17-1643 TPG',
      name: 'Porcelain Rose',
      hex: 'E57073',
      rgb: {
        r: 229,
        g: 112,
        b: 115,
      },
      lab: {
        l: 62.61,
        a: 48.63,
        b: 22.73,
      },
      cmyk: null,
    },
    {
      code: '17-1644 TPG',
      name: 'Spiced Coral',
      hex: 'D86A6B',
      rgb: {
        r: 216,
        g: 106,
        b: 107,
      },
      lab: {
        l: 59.1,
        a: 46.39,
        b: 21.66,
      },
      cmyk: null,
    },
    {
      code: '17-1545 TPG',
      name: 'Cranberry',
      hex: 'B85357',
      rgb: {
        r: 184,
        g: 83,
        b: 87,
      },
      lab: {
        l: 49.52,
        a: 43.86,
        b: 19.73,
      },
      cmyk: null,
    },
    {
      code: '16-1632 TPG',
      name: 'Shell Pink',
      hex: 'F48385',
      rgb: {
        r: 244,
        g: 131,
        b: 133,
      },
      lab: {
        l: 68.55,
        a: 45.84,
        b: 20.78,
      },
      cmyk: null,
    },
    {
      code: '18-1649 TPG',
      name: 'Deep Sea Coral',
      hex: 'DB6266',
      rgb: {
        r: 219,
        g: 98,
        b: 102,
      },
      lab: {
        l: 58.24,
        a: 50.74,
        b: 24.02,
      },
      cmyk: null,
    },
    {
      code: '17-1635 TPG',
      name: 'Rose of Sharon',
      hex: 'D85F68',
      rgb: {
        r: 216,
        g: 95,
        b: 104,
      },
      lab: {
        l: 57.24,
        a: 51.49,
        b: 21.49,
      },
      cmyk: null,
    },
    {
      code: '18-1651 TPG',
      name: 'Cayenne',
      hex: 'DB555B',
      rgb: {
        r: 219,
        g: 85,
        b: 91,
      },
      lab: {
        l: 55.98,
        a: 56.2,
        b: 27.43,
      },
      cmyk: null,
    },
    {
      code: '17-1654 TPG',
      name: 'Poinsettia',
      hex: 'CD4351',
      rgb: {
        r: 205,
        g: 67,
        b: 81,
      },
      lab: {
        l: 50.7,
        a: 58.12,
        b: 26.13,
      },
      cmyk: null,
    },
    {
      code: '17-1641 TPG',
      name: 'Chrysanthemum',
      hex: 'BB525B',
      rgb: {
        r: 187,
        g: 82,
        b: 91,
      },
      lab: {
        l: 49.8,
        a: 45.72,
        b: 17.94,
      },
      cmyk: null,
    },
    {
      code: '18-1643 TPG',
      name: 'Cardinal',
      hex: 'A74B54',
      rgb: {
        r: 167,
        g: 75,
        b: 84,
      },
      lab: {
        l: 45.21,
        a: 41.19,
        b: 15.51,
      },
      cmyk: null,
    },
    {
      code: '18-1661 TPG',
      name: 'Tomato Puree',
      hex: 'C24654',
      rgb: {
        r: 194,
        g: 70,
        b: 84,
      },
      lab: {
        l: 49.15,
        a: 53.55,
        b: 21.52,
      },
      cmyk: null,
    },
    {
      code: '17-1663 TPG',
      name: 'Bittersweet',
      hex: 'D84653',
      rgb: {
        r: 216,
        g: 70,
        b: 83,
      },
      lab: {
        l: 53.14,
        a: 60.66,
        b: 28.52,
      },
      cmyk: null,
    },
    {
      code: '18-1652 TPG',
      name: 'Rococco Red',
      hex: 'BF4550',
      rgb: {
        r: 191,
        g: 69,
        b: 80,
      },
      lab: {
        l: 48.23,
        a: 52.86,
        b: 22.5,
      },
      cmyk: null,
    },
    {
      code: '18-1660 TPG',
      name: 'Tomato',
      hex: 'CE3846',
      rgb: {
        r: 206,
        g: 56,
        b: 70,
      },
      lab: {
        l: 49.28,
        a: 62.05,
        b: 30.91,
      },
      cmyk: null,
    },
    {
      code: '18-1762 TPG',
      name: 'Hibiscus',
      hex: 'D84552',
      rgb: {
        r: 216,
        g: 69,
        b: 82,
      },
      lab: {
        l: 53.03,
        a: 61.18,
        b: 28.89,
      },
      cmyk: null,
    },
    {
      code: '19-1760 TPG',
      name: 'Scarlet',
      hex: 'B43B4C',
      rgb: {
        r: 180,
        g: 59,
        b: 76,
      },
      lab: {
        l: 44.64,
        a: 52.77,
        b: 20.2,
      },
      cmyk: null,
    },
    {
      code: '18-1764 TPG',
      name: 'Lollipop',
      hex: 'C63A4F',
      rgb: {
        r: 198,
        g: 58,
        b: 79,
      },
      lab: {
        l: 48.2,
        a: 59.58,
        b: 23.68,
      },
      cmyk: null,
    },
    {
      code: '18-1763 TPG',
      name: 'High Risk Red',
      hex: 'CC333D',
      rgb: {
        r: 204,
        g: 51,
        b: 61,
      },
      lab: {
        l: 48.13,
        a: 63.01,
        b: 34.6,
      },
      cmyk: null,
    },
    {
      code: '18-1555 TPG',
      name: 'Molten Lava',
      hex: 'BD3D3A',
      rgb: {
        r: 189,
        g: 61,
        b: 58,
      },
      lab: {
        l: 46.45,
        a: 54.07,
        b: 33.35,
      },
      cmyk: null,
    },
    {
      code: '18-1657 TPG',
      name: 'Salsa',
      hex: 'AC303E',
      rgb: {
        r: 172,
        g: 48,
        b: 62,
      },
      lab: {
        l: 41.46,
        a: 53.99,
        b: 24.37,
      },
      cmyk: null,
    },
    {
      code: '18-1551 TPG',
      name: 'Aura Orange',
      hex: 'B8363B',
      rgb: {
        r: 184,
        g: 54,
        b: 59,
      },
      lab: {
        l: 44.72,
        a: 55.31,
        b: 30.74,
      },
      cmyk: null,
    },
    {
      code: '18-1659 TPG',
      name: 'Goji Berry',
      hex: 'B8333E',
      rgb: {
        r: 184,
        g: 51,
        b: 62,
      },
      lab: {
        l: 44.28,
        a: 56.92,
        b: 28.24,
      },
      cmyk: null,
    },
    {
      code: '18-1658 TPG',
      name: 'Pompeian Red',
      hex: 'A74246',
      rgb: {
        r: 167,
        g: 66,
        b: 70,
      },
      lab: {
        l: 43.3,
        a: 44.74,
        b: 21.64,
      },
      cmyk: null,
    },
    {
      code: '19-1758 TPG',
      name: 'Haute Red',
      hex: 'A42F3B',
      rgb: {
        r: 164,
        g: 47,
        b: 59,
      },
      lab: {
        l: 39.72,
        a: 51.87,
        b: 23.65,
      },
      cmyk: null,
    },
    {
      code: '18-1559 TPG',
      name: 'Red Alert',
      hex: 'D44038',
      rgb: {
        r: 212,
        g: 64,
        b: 56,
      },
      lab: {
        l: 51.23,
        a: 61.13,
        b: 41.51,
      },
      cmyk: null,
    },
    {
      code: '18-1563 TPG',
      name: 'Rubicondo',
      hex: 'C33D41',
      rgb: {
        r: 195,
        g: 61,
        b: 65,
      },
      lab: {
        l: 47.65,
        a: 56.57,
        b: 30.98,
      },
      cmyk: null,
    },
    {
      code: '18-1453 TPG',
      name: 'Fire Whirl',
      hex: 'AD433D',
      rgb: {
        r: 173,
        g: 67,
        b: 61,
      },
      lab: {
        l: 44.44,
        a: 45.8,
        b: 28.34,
      },
      cmyk: null,
    },
    {
      code: '18-1553 TPG',
      name: 'Adrenaline Rush',
      hex: 'B73438',
      rgb: {
        r: 183,
        g: 52,
        b: 56,
      },
      lab: {
        l: 44.07,
        a: 56.02,
        b: 31.72,
      },
      cmyk: null,
    },
    {
      code: '19-1756 TPG',
      name: 'Urban Red',
      hex: 'B63743',
      rgb: {
        r: 182,
        g: 55,
        b: 67,
      },
      lab: {
        l: 44.38,
        a: 54.84,
        b: 25.11,
      },
      cmyk: null,
    },
    {
      code: '18-1552 TPG',
      name: 'Lava Falls',
      hex: '9F383A',
      rgb: {
        r: 159,
        g: 56,
        b: 58,
      },
      lab: {
        l: 40.14,
        a: 45.81,
        b: 24.14,
      },
      cmyk: null,
    },
    {
      code: '19-1755 TPG',
      name: 'Equestrian Red',
      hex: 'A2333C',
      rgb: {
        r: 162,
        g: 51,
        b: 60,
      },
      lab: {
        l: 39.82,
        a: 49.48,
        b: 23.04,
      },
      cmyk: null,
    },
    {
      code: '18-1662 TPG',
      name: 'Flame Scarlet',
      hex: 'D13B40',
      rgb: {
        r: 209,
        g: 59,
        b: 64,
      },
      lab: {
        l: 50.24,
        a: 61.9,
        b: 35.45,
      },
      cmyk: null,
    },
    {
      code: '17-1664 TPG',
      name: 'Poppy Red',
      hex: 'DF4950',
      rgb: {
        r: 223,
        g: 73,
        b: 80,
      },
      lab: {
        l: 54.63,
        a: 61.73,
        b: 32.35,
      },
      cmyk: null,
    },
    {
      code: '19-1763 TPG',
      name: 'Racing Red',
      hex: 'BC2F3F',
      rgb: {
        r: 188,
        g: 47,
        b: 63,
      },
      lab: {
        l: 44.53,
        a: 59.68,
        b: 28.46,
      },
      cmyk: null,
    },
    {
      code: '18-1663 TPG',
      name: 'Chinese Red',
      hex: 'C03444',
      rgb: {
        r: 192,
        g: 52,
        b: 68,
      },
      lab: {
        l: 45.99,
        a: 59.14,
        b: 27.44,
      },
      cmyk: null,
    },
    {
      code: '19-1664 TPG',
      name: 'True Red',
      hex: 'BE3645',
      rgb: {
        r: 190,
        g: 54,
        b: 69,
      },
      lab: {
        l: 45.83,
        a: 57.71,
        b: 26.29,
      },
      cmyk: null,
    },
    {
      code: '19-1663 TPG',
      name: 'Ribbon Red',
      hex: 'B93745',
      rgb: {
        r: 185,
        g: 55,
        b: 69,
      },
      lab: {
        l: 45.09,
        a: 55.81,
        b: 25.29,
      },
      cmyk: null,
    },
    {
      code: '19-1662 TPG',
      name: 'Samba',
      hex: 'AA363E',
      rgb: {
        r: 170,
        g: 54,
        b: 62,
      },
      lab: {
        l: 41.91,
        a: 50.88,
        b: 24.64,
      },
      cmyk: null,
    },
    {
      code: '18-1653 TPG',
      name: 'Toreador',
      hex: 'BA2B42',
      rgb: {
        r: 186,
        g: 43,
        b: 66,
      },
      lab: {
        l: 43.75,
        a: 60.55,
        b: 25.37,
      },
      cmyk: null,
    },
    {
      code: '18-1761 TPG',
      name: 'Ski Patrol',
      hex: 'B8324B',
      rgb: {
        r: 184,
        g: 50,
        b: 75,
      },
      lab: {
        l: 44.2,
        a: 58.1,
        b: 20.06,
      },
      cmyk: null,
    },
    {
      code: '19-1764 TPG',
      name: 'Lipstick Red',
      hex: 'B2364A',
      rgb: {
        r: 178,
        g: 54,
        b: 74,
      },
      lab: {
        l: 43.76,
        a: 54.31,
        b: 20.37,
      },
      cmyk: null,
    },
    {
      code: '18-1654 TPG',
      name: 'Lychee',
      hex: 'BB2F43',
      rgb: {
        r: 187,
        g: 47,
        b: 67,
      },
      lab: {
        l: 44.44,
        a: 59.54,
        b: 25.83,
      },
      cmyk: null,
    },
    {
      code: '19-1762 TPG',
      name: 'Crimson',
      hex: 'B0354D',
      rgb: {
        r: 176,
        g: 53,
        b: 77,
      },
      lab: {
        l: 43.2,
        a: 54.32,
        b: 17.24,
      },
      cmyk: null,
    },
    {
      code: '18-1759 TPG',
      name: 'Jalapeño Red',
      hex: 'B1334A',
      rgb: {
        r: 177,
        g: 51,
        b: 74,
      },
      lab: {
        l: 43.18,
        a: 54.85,
        b: 19.29,
      },
      cmyk: null,
    },
    {
      code: '19-1862 TPG',
      name: 'Jester Red',
      hex: '9E3745',
      rgb: {
        r: 158,
        g: 55,
        b: 69,
      },
      lab: {
        l: 39.94,
        a: 46.52,
        b: 17.63,
      },
      cmyk: null,
    },
    {
      code: '18-1655 TPG',
      name: 'Mars Red',
      hex: 'BE3A46',
      rgb: {
        r: 190,
        g: 58,
        b: 70,
      },
      lab: {
        l: 46.5,
        a: 56.62,
        b: 26.64,
      },
      cmyk: null,
    },
    {
      code: '19-1757 TPG',
      name: 'Barbados Cherry',
      hex: 'B02B3B',
      rgb: {
        r: 176,
        g: 43,
        b: 59,
      },
      lab: {
        l: 41.73,
        a: 57,
        b: 26.72,
      },
      cmyk: null,
    },
    {
      code: '19-1559 TPG',
      name: 'Scarlet Sage',
      hex: 'A13942',
      rgb: {
        r: 161,
        g: 57,
        b: 66,
      },
      lab: {
        l: 40.65,
        a: 46.11,
        b: 20.52,
      },
      cmyk: null,
    },
    {
      code: '19-1761 TPG',
      name: 'Tango Red',
      hex: 'B02E41',
      rgb: {
        r: 176,
        g: 46,
        b: 65,
      },
      lab: {
        l: 42.09,
        a: 56.59,
        b: 23.14,
      },
      cmyk: null,
    },
    {
      code: '19-1759 TPG',
      name: 'American Beauty',
      hex: 'A74551',
      rgb: {
        r: 167,
        g: 69,
        b: 81,
      },
      lab: {
        l: 43.95,
        a: 43.66,
        b: 15.71,
      },
      cmyk: null,
    },
    {
      code: '19-1863 TPG',
      name: 'Scooter',
      hex: '993242',
      rgb: {
        r: 153,
        g: 50,
        b: 66,
      },
      lab: {
        l: 38.02,
        a: 47.05,
        b: 16.66,
      },
      cmyk: null,
    },
    {
      code: '19-1557 TPG',
      name: 'Chili Pepper',
      hex: '9D373F',
      rgb: {
        r: 157,
        g: 55,
        b: 63,
      },
      lab: {
        l: 39.55,
        a: 46.13,
        b: 20.43,
      },
      cmyk: null,
    },
    {
      code: '19-1554 TPG',
      name: 'Savvy Red',
      hex: '9C313C',
      rgb: {
        r: 156,
        g: 49,
        b: 60,
      },
      lab: {
        l: 38.57,
        a: 48.47,
        b: 21.34,
      },
      cmyk: null,
    },
    {
      code: '19-1657 TPG',
      name: 'Karanda Red',
      hex: '923842',
      rgb: {
        r: 146,
        g: 56,
        b: 66,
      },
      lab: {
        l: 37.73,
        a: 41.55,
        b: 15.92,
      },
      cmyk: null,
    },
    {
      code: '19-1875 TPG',
      name: 'Emboldened',
      hex: '96353D',
      rgb: {
        r: 150,
        g: 53,
        b: 61,
      },
      lab: {
        l: 37.85,
        a: 44.5,
        b: 19.26,
      },
      cmyk: null,
    },
    {
      code: '19-1558 TPG',
      name: 'Scarlet Smile',
      hex: 'A33745',
      rgb: {
        r: 163,
        g: 55,
        b: 69,
      },
      lab: {
        l: 40.88,
        a: 48.66,
        b: 19.09,
      },
      cmyk: null,
    },
    {
      code: '19-1537 TPG',
      name: 'Winery',
      hex: '833C3F',
      rgb: {
        r: 131,
        g: 60,
        b: 63,
      },
      lab: {
        l: 35.74,
        a: 33.54,
        b: 14.25,
      },
      cmyk: null,
    },
    {
      code: '19-1653 TPG',
      name: 'Rhythmic Red',
      hex: '93363D',
      rgb: {
        r: 147,
        g: 54,
        b: 61,
      },
      lab: {
        l: 37.41,
        a: 42.73,
        b: 18.82,
      },
      cmyk: null,
    },
    {
      code: '19-1527 TPG',
      name: 'New Maroon',
      hex: '714145',
      rgb: {
        r: 113,
        g: 65,
        b: 69,
      },
      lab: {
        l: 34.05,
        a: 22.79,
        b: 7.88,
      },
      cmyk: null,
    },
    {
      code: '19-1650 TPG',
      name: 'Biking Red',
      hex: '803D45',
      rgb: {
        r: 128,
        g: 61,
        b: 69,
      },
      lab: {
        l: 35.6,
        a: 31.64,
        b: 10.37,
      },
      cmyk: null,
    },
    {
      code: '19-1652 TPG',
      name: 'Rhubarb',
      hex: '7A3640',
      rgb: {
        r: 122,
        g: 54,
        b: 64,
      },
      lab: {
        l: 33.04,
        a: 33.23,
        b: 10.02,
      },
      cmyk: null,
    },
    {
      code: '19-1534 TPG',
      name: 'Merlot',
      hex: '79393E',
      rgb: {
        r: 121,
        g: 57,
        b: 62,
      },
      lab: {
        l: 33.52,
        a: 30.82,
        b: 11.46,
      },
      cmyk: null,
    },
    {
      code: '19-1555 TPG',
      name: 'Red Dahlia',
      hex: '863439',
      rgb: {
        r: 134,
        g: 52,
        b: 57,
      },
      lab: {
        l: 34.69,
        a: 38.8,
        b: 16.76,
      },
      cmyk: null,
    },
    {
      code: '19-1531 TPG',
      name: 'Sun-Dried Tomato',
      hex: '803A40',
      rgb: {
        r: 128,
        g: 58,
        b: 64,
      },
      lab: {
        l: 34.82,
        a: 32.95,
        b: 12.29,
      },
      cmyk: null,
    },
    {
      code: '19-1535 TPG',
      name: 'Syrah',
      hex: '75373A',
      rgb: {
        r: 117,
        g: 55,
        b: 58,
      },
      lab: {
        l: 32.15,
        a: 29.63,
        b: 12.34,
      },
      cmyk: null,
    },
    {
      code: '19-1327 TPG',
      name: 'Andorra',
      hex: '694546',
      rgb: {
        r: 105,
        g: 69,
        b: 70,
      },
      lab: {
        l: 33.81,
        a: 17.1,
        b: 6.55,
      },
      cmyk: null,
    },
    {
      code: '19-1629 TPG',
      name: 'Ruby Wine',
      hex: '794045',
      rgb: {
        r: 121,
        g: 64,
        b: 69,
      },
      lab: {
        l: 35.12,
        a: 26.61,
        b: 9.27,
      },
      cmyk: null,
    },
    {
      code: '19-1536 TPG',
      name: 'Red Pear',
      hex: '804145',
      rgb: {
        r: 128,
        g: 65,
        b: 69,
      },
      lab: {
        l: 36.38,
        a: 29.47,
        b: 11.39,
      },
      cmyk: null,
    },
    {
      code: '19-1934 TPG',
      name: 'Tibetan Red',
      hex: '81404A',
      rgb: {
        r: 129,
        g: 64,
        b: 74,
      },
      lab: {
        l: 36.44,
        a: 30.54,
        b: 8.33,
      },
      cmyk: null,
    },
    {
      code: '19-1726 TPG',
      name: 'Cordovan',
      hex: '6F4048',
      rgb: {
        r: 111,
        g: 64,
        b: 72,
      },
      lab: {
        l: 33.5,
        a: 23.02,
        b: 4.92,
      },
      cmyk: null,
    },
    {
      code: '19-1724 TPG',
      name: 'Cabernet',
      hex: '723B44',
      rgb: {
        r: 114,
        g: 59,
        b: 68,
      },
      lab: {
        l: 32.77,
        a: 26.69,
        b: 7,
      },
      cmyk: null,
    },
    {
      code: '19-1525 TPG',
      name: 'Port',
      hex: '6D4549',
      rgb: {
        r: 109,
        g: 69,
        b: 73,
      },
      lab: {
        l: 34.57,
        a: 18.89,
        b: 5.97,
      },
      cmyk: null,
    },
    {
      code: '19-1521 TPG',
      name: 'Red Mahogany',
      hex: '674B4E',
      rgb: {
        r: 103,
        g: 75,
        b: 78,
      },
      lab: {
        l: 35.17,
        a: 13.59,
        b: 3.18,
      },
      cmyk: null,
    },
    {
      code: '19-1533 TPG',
      name: 'Cowhide',
      hex: '8B5253',
      rgb: {
        r: 139,
        g: 82,
        b: 83,
      },
      lab: {
        l: 42.02,
        a: 25.56,
        b: 10.95,
      },
      cmyk: null,
    },
    {
      code: '19-1530 TPG',
      name: 'Burnt Russet',
      hex: '884850',
      rgb: {
        r: 136,
        g: 72,
        b: 80,
      },
      lab: {
        l: 39.41,
        a: 29.35,
        b: 8.76,
      },
      cmyk: null,
    },
    {
      code: '19-1524 TPG',
      name: 'Oxblood Red',
      hex: '754A4E',
      rgb: {
        r: 117,
        g: 74,
        b: 78,
      },
      lab: {
        l: 36.98,
        a: 19.99,
        b: 6.38,
      },
      cmyk: null,
    },
    {
      code: '19-1930 TPG',
      name: 'Pomegranate',
      hex: '703841',
      rgb: {
        r: 112,
        g: 56,
        b: 65,
      },
      lab: {
        l: 31.84,
        a: 27.76,
        b: 7.03,
      },
      cmyk: null,
    },
    {
      code: '19-1522 TPG',
      name: 'Zinfandel',
      hex: '663F45',
      rgb: {
        r: 102,
        g: 63,
        b: 69,
      },
      lab: {
        l: 31.9,
        a: 18.96,
        b: 4.31,
      },
      cmyk: null,
    },
    {
      code: '19-1725 TPG',
      name: 'Tawny Port',
      hex: '6B3B44',
      rgb: {
        r: 107,
        g: 59,
        b: 68,
      },
      lab: {
        l: 31.68,
        a: 23.64,
        b: 5.06,
      },
      cmyk: null,
    },
    {
      code: '19-1526 TPG',
      name: 'Chocolate Truffle',
      hex: '674248',
      rgb: {
        r: 103,
        g: 66,
        b: 72,
      },
      lab: {
        l: 32.85,
        a: 17.47,
        b: 4.08,
      },
      cmyk: null,
    },
    {
      code: '18-1631 TPG',
      name: 'Earth Red',
      hex: '9C505D',
      rgb: {
        r: 156,
        g: 80,
        b: 93,
      },
      lab: {
        l: 44.38,
        a: 34.76,
        b: 8.41,
      },
      cmyk: null,
    },
    {
      code: '19-1543 TPG',
      name: 'Brick Red',
      hex: '91484D',
      rgb: {
        r: 145,
        g: 72,
        b: 77,
      },
      lab: {
        l: 40.68,
        a: 33.04,
        b: 12.92,
      },
      cmyk: null,
    },
    {
      code: '19-1532 TPG',
      name: 'Rosewood',
      hex: '874748',
      rgb: {
        r: 135,
        g: 71,
        b: 72,
      },
      lab: {
        l: 38.73,
        a: 29.46,
        b: 12.89,
      },
      cmyk: null,
    },
    {
      code: '19-1656 TPG',
      name: 'Rio Red',
      hex: '8E3841',
      rgb: {
        r: 142,
        g: 56,
        b: 65,
      },
      lab: {
        l: 36.96,
        a: 40.11,
        b: 15.02,
      },
      cmyk: null,
    },
    {
      code: '19-1655 TPG',
      name: 'Garnet',
      hex: '964049',
      rgb: {
        r: 150,
        g: 64,
        b: 73,
      },
      lab: {
        l: 40.11,
        a: 39.1,
        b: 14.49,
      },
      cmyk: null,
    },
    {
      code: '19-1840 TPG',
      name: 'Deep Claret',
      hex: '9C4150',
      rgb: {
        r: 156,
        g: 65,
        b: 80,
      },
      lab: {
        l: 41.36,
        a: 41.12,
        b: 12.22,
      },
      cmyk: null,
    },
    {
      code: '19-1940 TPG',
      name: 'Rumba Red',
      hex: '823B47',
      rgb: {
        r: 130,
        g: 59,
        b: 71,
      },
      lab: {
        l: 35.56,
        a: 33.91,
        b: 9.03,
      },
      cmyk: null,
    },
    {
      code: '16-1617 TPG',
      name: 'Mauveglow',
      hex: 'D1888E',
      rgb: {
        r: 209,
        g: 136,
        b: 142,
      },
      lab: {
        l: 64.87,
        a: 30.26,
        b: 9.48,
      },
      cmyk: null,
    },
    {
      code: '17-1927 TPG',
      name: 'Desert Rose',
      hex: 'D16E7C',
      rgb: {
        r: 209,
        g: 110,
        b: 124,
      },
      lab: {
        l: 59.2,
        a: 42.6,
        b: 12.16,
      },
      cmyk: null,
    },
    {
      code: '17-1929 TPG',
      name: 'Rapture Rose',
      hex: 'CE6981',
      rgb: {
        r: 206,
        g: 105,
        b: 129,
      },
      lab: {
        l: 57.87,
        a: 44.92,
        b: 7.12,
      },
      cmyk: null,
    },
    {
      code: '18-1634 TPG',
      name: 'Baroque Rose',
      hex: 'B15A68',
      rgb: {
        r: 177,
        g: 90,
        b: 104,
      },
      lab: {
        l: 50.07,
        a: 38.19,
        b: 10.43,
      },
      cmyk: null,
    },
    {
      code: '18-1635 TPG',
      name: 'Slate Rose',
      hex: 'B65E6C',
      rgb: {
        r: 182,
        g: 94,
        b: 108,
      },
      lab: {
        l: 51.7,
        a: 38.56,
        b: 10.53,
      },
      cmyk: null,
    },
    {
      code: '17-1633 TPG',
      name: 'Holly Berry',
      hex: 'B55464',
      rgb: {
        r: 181,
        g: 84,
        b: 100,
      },
      lab: {
        l: 49.48,
        a: 42.92,
        b: 12.06,
      },
      cmyk: null,
    },
    {
      code: '18-1633 TPG',
      name: 'Garnet Rose',
      hex: 'B15662',
      rgb: {
        r: 177,
        g: 86,
        b: 98,
      },
      lab: {
        l: 49.12,
        a: 40.33,
        b: 12.4,
      },
      cmyk: null,
    },
    {
      code: '14-1911 TPG',
      name: 'Candy Pink',
      hex: 'F4B2C0',
      rgb: {
        r: 244,
        g: 178,
        b: 192,
      },
      lab: {
        l: 79.62,
        a: 27.31,
        b: 3.66,
      },
      cmyk: null,
    },
    {
      code: '15-1816 TPG',
      name: 'Peony',
      hex: 'EC9EAC',
      rgb: {
        r: 236,
        g: 158,
        b: 172,
      },
      lab: {
        l: 73.9,
        a: 31.94,
        b: 6.48,
      },
      cmyk: null,
    },
    {
      code: '14-1714 TPG',
      name: 'Quartz Pink',
      hex: 'F0ABAE',
      rgb: {
        r: 240,
        g: 171,
        b: 174,
      },
      lab: {
        l: 77.21,
        a: 27.05,
        b: 9.89,
      },
      cmyk: null,
    },
    {
      code: '15-1717 TPG',
      name: 'Pink Icing',
      hex: 'ECA1A6',
      rgb: {
        r: 236,
        g: 161,
        b: 166,
      },
      lab: {
        l: 74.43,
        a: 30.2,
        b: 10.15,
      },
      cmyk: null,
    },
    {
      code: '15-1821 TPG',
      name: 'Flamingo Pink',
      hex: 'F499A0',
      rgb: {
        r: 244,
        g: 153,
        b: 160,
      },
      lab: {
        l: 73.46,
        a: 36.83,
        b: 12.34,
      },
      cmyk: null,
    },
    {
      code: '15-1624 TPG',
      name: 'Conch Shell',
      hex: 'F88E97',
      rgb: {
        r: 248,
        g: 142,
        b: 151,
      },
      lab: {
        l: 71.59,
        a: 42.96,
        b: 14.94,
      },
      cmyk: null,
    },
    {
      code: '15-1626 TPG',
      name: 'Salmon Rose',
      hex: 'FC8B93',
      rgb: {
        r: 252,
        g: 139,
        b: 147,
      },
      lab: {
        l: 71.65,
        a: 45.02,
        b: 17.47,
      },
      cmyk: null,
    },
    {
      code: '15-1611 TPG',
      name: 'Bridal Rose',
      hex: 'D7A3A4',
      rgb: {
        r: 215,
        g: 163,
        b: 164,
      },
      lab: {
        l: 72.31,
        a: 20.37,
        b: 7.86,
      },
      cmyk: null,
    },
    {
      code: '15-1614 TPG',
      name: 'Rose Elegance',
      hex: 'D0969E',
      rgb: {
        r: 208,
        g: 150,
        b: 158,
      },
      lab: {
        l: 68.35,
        a: 23.7,
        b: 5.5,
      },
      cmyk: null,
    },
    {
      code: '16-1610 TPG',
      name: 'Brandied Apricot',
      hex: 'CC8A92',
      rgb: {
        r: 204,
        g: 138,
        b: 146,
      },
      lab: {
        l: 64.97,
        a: 27.67,
        b: 7.64,
      },
      cmyk: null,
    },
    {
      code: '16-1720 TPG',
      name: 'Strawberry Ice',
      hex: 'E58990',
      rgb: {
        r: 229,
        g: 137,
        b: 144,
      },
      lab: {
        l: 67.85,
        a: 37.7,
        b: 13.13,
      },
      cmyk: null,
    },
    {
      code: '16-1626 TPG',
      name: 'Peach Blossom',
      hex: 'E28388',
      rgb: {
        r: 226,
        g: 131,
        b: 136,
      },
      lab: {
        l: 66.12,
        a: 39.05,
        b: 15.16,
      },
      cmyk: null,
    },
    {
      code: '16-1620 TPG',
      name: 'Tea Rose',
      hex: 'DD727D',
      rgb: {
        r: 221,
        g: 114,
        b: 125,
      },
      lab: {
        l: 61.85,
        a: 45.17,
        b: 15.39,
      },
      cmyk: null,
    },
    {
      code: '17-1537 TPG',
      name: 'Mineral Red',
      hex: 'AF5B60',
      rgb: {
        r: 175,
        g: 91,
        b: 96,
      },
      lab: {
        l: 49.82,
        a: 36.58,
        b: 14.93,
      },
      cmyk: null,
    },
    {
      code: '15-1415 TPG',
      name: 'Coral Cloud',
      hex: 'E3ACA4',
      rgb: {
        r: 227,
        g: 172,
        b: 164,
      },
      lab: {
        l: 75.69,
        a: 21.1,
        b: 13,
      },
      cmyk: null,
    },
    {
      code: '16-1511 TPG',
      name: 'Rose Tan',
      hex: 'CE9794',
      rgb: {
        r: 206,
        g: 151,
        b: 148,
      },
      lab: {
        l: 68.04,
        a: 22.46,
        b: 10.39,
      },
      cmyk: null,
    },
    {
      code: '17-1718 TPG',
      name: 'Dusty Rose',
      hex: 'B87D84',
      rgb: {
        r: 184,
        g: 125,
        b: 132,
      },
      lab: {
        l: 59.1,
        a: 24.9,
        b: 6.97,
      },
      cmyk: null,
    },
    {
      code: '17-1514 TPG',
      name: 'Ash Rose',
      hex: 'B28282',
      rgb: {
        r: 178,
        g: 130,
        b: 130,
      },
      lab: {
        l: 59.46,
        a: 20.22,
        b: 8.07,
      },
      cmyk: null,
    },
    {
      code: '17-1424 TPG',
      name: 'Brick Dust',
      hex: 'B3746E',
      rgb: {
        r: 179,
        g: 116,
        b: 110,
      },
      lab: {
        l: 55.92,
        a: 26.52,
        b: 14.92,
      },
      cmyk: null,
    },
    {
      code: '17-1518 TPG',
      name: 'Old Rose',
      hex: 'B17D7B',
      rgb: {
        r: 177,
        g: 125,
        b: 123,
      },
      lab: {
        l: 58.12,
        a: 22.04,
        b: 10.05,
      },
      cmyk: null,
    },
    {
      code: '17-1520 TPG',
      name: 'Canyon Rose',
      hex: 'B17370',
      rgb: {
        r: 177,
        g: 115,
        b: 112,
      },
      lab: {
        l: 55.63,
        a: 26.09,
        b: 12.79,
      },
      cmyk: null,
    },
    {
      code: '14-1506 TPG',
      name: 'Rose Smoke',
      hex: 'D6B9B2',
      rgb: {
        r: 214,
        g: 185,
        b: 178,
      },
      lab: {
        l: 77.84,
        a: 10.42,
        b: 8.31,
      },
      cmyk: null,
    },
    {
      code: '14-1803 TPG',
      name: 'Sepia Rose',
      hex: 'DABEBA',
      rgb: {
        r: 218,
        g: 190,
        b: 186,
      },
      lab: {
        l: 79.4,
        a: 10.37,
        b: 6.2,
      },
      cmyk: null,
    },
    {
      code: '15-1512 TPG',
      name: 'Misty Rose',
      hex: 'CDA8A0',
      rgb: {
        r: 205,
        g: 168,
        b: 160,
      },
      lab: {
        l: 72.16,
        a: 14.01,
        b: 9.64,
      },
      cmyk: null,
    },
    {
      code: '15-1516 TPG',
      name: 'Peach Beige',
      hex: 'D4A299',
      rgb: {
        r: 212,
        g: 162,
        b: 153,
      },
      lab: {
        l: 71.48,
        a: 19.04,
        b: 12.56,
      },
      cmyk: null,
    },
    {
      code: '16-1516 TPG',
      name: 'Cameo Brown',
      hex: 'BF8B84',
      rgb: {
        r: 191,
        g: 139,
        b: 132,
      },
      lab: {
        l: 63.19,
        a: 20.87,
        b: 12.48,
      },
      cmyk: null,
    },
    {
      code: '18-1435 TPG',
      name: 'Withered Rose',
      hex: 'A36E6D',
      rgb: {
        r: 163,
        g: 110,
        b: 109,
      },
      lab: {
        l: 52.52,
        a: 22.38,
        b: 10.29,
      },
      cmyk: null,
    },
    {
      code: '18-1426 TPG',
      name: 'Apple Butter',
      hex: '885558',
      rgb: {
        r: 136,
        g: 85,
        b: 88,
      },
      lab: {
        l: 42.46,
        a: 23.25,
        b: 8.29,
      },
      cmyk: null,
    },
    {
      code: '15-1515 TPG',
      name: 'Mellow Rose',
      hex: 'DDAAA2',
      rgb: {
        r: 221,
        g: 170,
        b: 162,
      },
      lab: {
        l: 74.43,
        a: 19.53,
        b: 11.87,
      },
      cmyk: null,
    },
    {
      code: '16-1518 TPG',
      name: 'Rosette',
      hex: 'CB8A86',
      rgb: {
        r: 203,
        g: 138,
        b: 134,
      },
      lab: {
        l: 64.5,
        a: 25.91,
        b: 13.53,
      },
      cmyk: null,
    },
    {
      code: '16-1522 TPG',
      name: 'Rose Dawn',
      hex: 'C2877D',
      rgb: {
        r: 194,
        g: 135,
        b: 125,
      },
      lab: {
        l: 62.61,
        a: 23.89,
        b: 15.39,
      },
      cmyk: null,
    },
    {
      code: '17-1524 TPG',
      name: 'Desert Sand',
      hex: 'BA7D77',
      rgb: {
        r: 186,
        g: 125,
        b: 119,
      },
      lab: {
        l: 59.14,
        a: 25.42,
        b: 14.31,
      },
      cmyk: null,
    },
    {
      code: '18-1436 TPG',
      name: 'Light Mahogany',
      hex: 'B0746E',
      rgb: {
        r: 176,
        g: 116,
        b: 110,
      },
      lab: {
        l: 55.52,
        a: 25.26,
        b: 14.21,
      },
      cmyk: null,
    },
    {
      code: '17-1525 TPG',
      name: 'Cedar Wood',
      hex: 'A26E64',
      rgb: {
        r: 162,
        g: 110,
        b: 100,
      },
      lab: {
        l: 52.19,
        a: 21.47,
        b: 14.79,
      },
      cmyk: null,
    },
    {
      code: '17-1516 TPG',
      name: 'Burlwood',
      hex: '9A7773',
      rgb: {
        r: 154,
        g: 119,
        b: 115,
      },
      lab: {
        l: 53.6,
        a: 14.76,
        b: 8.07,
      },
      cmyk: null,
    },
    {
      code: '14-1418 TPG',
      name: 'Peach Melba',
      hex: 'F9BDB0',
      rgb: {
        r: 249,
        g: 189,
        b: 176,
      },
      lab: {
        l: 82.3,
        a: 21.99,
        b: 16,
      },
      cmyk: null,
    },
    {
      code: '13-1318 TPG',
      name: 'Tropical Peach',
      hex: 'FFC5B2',
      rgb: {
        r: 255,
        g: 197,
        b: 178,
      },
      lab: {
        l: 84.82,
        a: 20.36,
        b: 18.37,
      },
      cmyk: null,
    },
    {
      code: '14-1419 TPG',
      name: 'Peach Pearl',
      hex: 'F9B6A9',
      rgb: {
        r: 249,
        g: 182,
        b: 169,
      },
      lab: {
        l: 80.62,
        a: 24.39,
        b: 17.53,
      },
      cmyk: null,
    },
    {
      code: '14-1420 TPG',
      name: 'Apricot Blush',
      hex: 'FBB2A9',
      rgb: {
        r: 251,
        g: 178,
        b: 169,
      },
      lab: {
        l: 79.86,
        a: 27.32,
        b: 16.43,
      },
      cmyk: null,
    },
    {
      code: '14-1324 TPG',
      name: 'Peach Bud',
      hex: 'FBB2A7',
      rgb: {
        r: 251,
        g: 178,
        b: 167,
      },
      lab: {
        l: 79.99,
        a: 27.19,
        b: 17.57,
      },
      cmyk: null,
    },
    {
      code: '15-1423 TPG',
      name: 'Peach Amber',
      hex: 'FAA094',
      rgb: {
        r: 250,
        g: 160,
        b: 148,
      },
      lab: {
        l: 75.44,
        a: 34.65,
        b: 21.92,
      },
      cmyk: null,
    },
    {
      code: '15-1621 TPG',
      name: 'Candlelight Peach',
      hex: 'F4A19F',
      rgb: {
        r: 244,
        g: 161,
        b: 159,
      },
      lab: {
        l: 75.19,
        a: 33.29,
        b: 15.1,
      },
      cmyk: null,
    },
    {
      code: '13-1513 TPG',
      name: 'Gossamer Pink',
      hex: 'F9C5C2',
      rgb: {
        r: 249,
        g: 197,
        b: 194,
      },
      lab: {
        l: 84.53,
        a: 19.4,
        b: 9.53,
      },
      cmyk: null,
    },
    {
      code: '13-1310 TPG',
      name: 'English Rose',
      hex: 'F4C3C0',
      rgb: {
        r: 244,
        g: 195,
        b: 192,
      },
      lab: {
        l: 83.46,
        a: 18.49,
        b: 9.12,
      },
      cmyk: null,
    },
    {
      code: '13-1510 TPG',
      name: 'Impatiens Pink',
      hex: 'FDC3BA',
      rgb: {
        r: 253,
        g: 195,
        b: 186,
      },
      lab: {
        l: 84.18,
        a: 21.25,
        b: 13.19,
      },
      cmyk: null,
    },
    {
      code: '14-1511 TPG',
      name: 'Powder Pink',
      hex: 'ECB7B7',
      rgb: {
        r: 236,
        g: 183,
        b: 183,
      },
      lab: {
        l: 79.6,
        a: 20.3,
        b: 8.15,
      },
      cmyk: null,
    },
    {
      code: '14-1513 TPG',
      name: 'Blossom',
      hex: 'F2B4B0',
      rgb: {
        r: 242,
        g: 180,
        b: 176,
      },
      lab: {
        l: 79.59,
        a: 23.79,
        b: 12.21,
      },
      cmyk: null,
    },
    {
      code: '14-1521 TPG',
      name: "Peaches N' Cream",
      hex: 'F4A7A2',
      rgb: {
        r: 244,
        g: 167,
        b: 162,
      },
      lab: {
        l: 76.57,
        a: 29.57,
        b: 15.44,
      },
      cmyk: null,
    },
    {
      code: '16-1434 TPG',
      name: 'Coral Almond',
      hex: 'E39A94',
      rgb: {
        r: 227,
        g: 154,
        b: 148,
      },
      lab: {
        l: 71.33,
        a: 28.82,
        b: 15.82,
      },
      cmyk: null,
    },
    {
      code: '13-2005 TPG',
      name: 'Strawberry Cream',
      hex: 'F4C3C4',
      rgb: {
        r: 244,
        g: 195,
        b: 196,
      },
      lab: {
        l: 83.55,
        a: 18.42,
        b: 7.17,
      },
      cmyk: null,
    },
    {
      code: '13-2003 TPG',
      name: 'Blushing Rose',
      hex: 'F5C2C2',
      rgb: {
        r: 245,
        g: 194,
        b: 194,
      },
      lab: {
        l: 83.35,
        a: 19.55,
        b: 8.09,
      },
      cmyk: null,
    },
    {
      code: '12-1708 TPG',
      name: 'Crystal Rose',
      hex: 'F8C5C8',
      rgb: {
        r: 248,
        g: 197,
        b: 200,
      },
      lab: {
        l: 84.61,
        a: 19.62,
        b: 6.75,
      },
      cmyk: null,
    },
    {
      code: '14-1910 TPG',
      name: 'Tickled Pink',
      hex: 'F6BCC6',
      rgb: {
        r: 246,
        g: 188,
        b: 198,
      },
      lab: {
        l: 82.17,
        a: 23.5,
        b: 4.29,
      },
      cmyk: null,
    },
    {
      code: '13-1716 TPG',
      name: 'Pale Rosette',
      hex: 'FFBBB9',
      rgb: {
        r: 255,
        g: 187,
        b: 185,
      },
      lab: {
        l: 83.03,
        a: 26.71,
        b: 12.7,
      },
      cmyk: null,
    },
    {
      code: '16-1615 TPG',
      name: 'Flamingo Plume',
      hex: 'E28690',
      rgb: {
        r: 226,
        g: 134,
        b: 144,
      },
      lab: {
        l: 66.95,
        a: 38.73,
        b: 11.82,
      },
      cmyk: null,
    },
    {
      code: '15-1919 TPG',
      name: 'Dianthus',
      hex: 'F898A6',
      rgb: {
        r: 248,
        g: 152,
        b: 166,
      },
      lab: {
        l: 74.01,
        a: 39.26,
        b: 10.3,
      },
      cmyk: null,
    },
    {
      code: '13-1501 TPG',
      name: 'Mangano Calcite',
      hex: 'F3D6D0',
      rgb: {
        r: 243,
        g: 214,
        b: 208,
      },
      lab: {
        l: 88.08,
        a: 10.79,
        b: 6.85,
      },
      cmyk: null,
    },
    {
      code: '12-1212 TPG',
      name: 'Veiled Rose',
      hex: 'F5CEC8',
      rgb: {
        r: 245,
        g: 206,
        b: 200,
      },
      lab: {
        l: 86.26,
        a: 14.48,
        b: 8.63,
      },
      cmyk: null,
    },
    {
      code: '13-1408 TPG',
      name: 'Chintz Rose',
      hex: 'EFC5BE',
      rgb: {
        r: 239,
        g: 197,
        b: 190,
      },
      lab: {
        l: 83.35,
        a: 15.64,
        b: 9.84,
      },
      cmyk: null,
    },
    {
      code: '13-1409 TPG',
      name: 'Seashell Pink',
      hex: 'F6C9C3',
      rgb: {
        r: 246,
        g: 201,
        b: 195,
      },
      lab: {
        l: 84.95,
        a: 16.75,
        b: 9.59,
      },
      cmyk: null,
    },
    {
      code: '13-1511 TPG',
      name: 'Pink Salt',
      hex: 'FAD0C9',
      rgb: {
        r: 250,
        g: 208,
        b: 201,
      },
      lab: {
        l: 87.31,
        a: 14.92,
        b: 9.72,
      },
      cmyk: null,
    },
    {
      code: '13-1520 TPG',
      name: 'Rose Quartz',
      hex: 'F8CDCC',
      rgb: {
        r: 248,
        g: 205,
        b: 204,
      },
      lab: {
        l: 86.36,
        a: 16.03,
        b: 6.71,
      },
      cmyk: null,
    },
    {
      code: '15-1712 TPG',
      name: 'Murex Shell',
      hex: 'FAA3A5',
      rgb: {
        r: 250,
        g: 163,
        b: 165,
      },
      lab: {
        l: 76.56,
        a: 34.18,
        b: 14.22,
      },
      cmyk: null,
    },
    {
      code: '12-1303 TPG',
      name: 'Carinaria',
      hex: 'F1DEE0',
      rgb: {
        r: 241,
        g: 222,
        b: 224,
      },
      lab: {
        l: 90.26,
        a: 7.2,
        b: 1.51,
      },
      cmyk: null,
    },
    {
      code: '12-1304 TPG',
      name: 'Pearl',
      hex: 'F3DAD5',
      rgb: {
        r: 243,
        g: 218,
        b: 213,
      },
      lab: {
        l: 89.32,
        a: 8.44,
        b: 6.01,
      },
      cmyk: null,
    },
    {
      code: '12-1706 TPG',
      name: 'Pink Dogwood',
      hex: 'F5D3D4',
      rgb: {
        r: 245,
        g: 211,
        b: 212,
      },
      lab: {
        l: 87.67,
        a: 12.75,
        b: 4.61,
      },
      cmyk: null,
    },
    {
      code: '13-2004 TPG',
      name: 'Potpourri',
      hex: 'E9C8CA',
      rgb: {
        r: 233,
        g: 200,
        b: 202,
      },
      lab: {
        l: 83.72,
        a: 12.38,
        b: 4.05,
      },
      cmyk: null,
    },
    {
      code: '14-1907 TPG',
      name: 'Peachskin',
      hex: 'E1B8B9',
      rgb: {
        r: 225,
        g: 184,
        b: 185,
      },
      lab: {
        l: 78.75,
        a: 16.36,
        b: 5.57,
      },
      cmyk: null,
    },
    {
      code: '14-1508 TPG',
      name: 'Silver Pink',
      hex: 'DCB5B3',
      rgb: {
        r: 220,
        g: 181,
        b: 179,
      },
      lab: {
        l: 77.31,
        a: 14.87,
        b: 7.12,
      },
      cmyk: null,
    },
    {
      code: '14-1904 TPG',
      name: 'Pink Dolphin',
      hex: 'EBB0B8',
      rgb: {
        r: 235,
        g: 176,
        b: 184,
      },
      lab: {
        l: 78,
        a: 24.01,
        b: 5.22,
      },
      cmyk: null,
    },
    {
      code: '11-2409 TPG',
      name: 'Delicacy',
      hex: 'F0E3E0',
      rgb: {
        r: 240,
        g: 227,
        b: 224,
      },
      lab: {
        l: 91.29,
        a: 4.55,
        b: 3.19,
      },
      cmyk: null,
    },
    {
      code: '12-1305 TPG',
      name: 'Heavenly Pink',
      hex: 'F3DEDF',
      rgb: {
        r: 243,
        g: 222,
        b: 223,
      },
      lab: {
        l: 90.32,
        a: 7.8,
        b: 2.43,
      },
      cmyk: null,
    },
    {
      code: '11-1408 TPG',
      name: 'Rosewater',
      hex: 'F2DBD7',
      rgb: {
        r: 242,
        g: 219,
        b: 215,
      },
      lab: {
        l: 89.28,
        a: 8.43,
        b: 5.04,
      },
      cmyk: null,
    },
    {
      code: '12-2102 TPG',
      name: 'Mauve Morn',
      hex: 'EAD9D8',
      rgb: {
        r: 234,
        g: 217,
        b: 216,
      },
      lab: {
        l: 88.31,
        a: 5.89,
        b: 2.86,
      },
      cmyk: null,
    },
    {
      code: '12-1605 TPG',
      name: 'Crystal Pink',
      hex: 'EDD4D1',
      rgb: {
        r: 237,
        g: 212,
        b: 209,
      },
      lab: {
        l: 87.11,
        a: 8.99,
        b: 5.19,
      },
      cmyk: null,
    },
    {
      code: '14-1905 TPG',
      name: 'Lotus',
      hex: 'E3C1C1',
      rgb: {
        r: 227,
        g: 193,
        b: 193,
      },
      lab: {
        l: 81.31,
        a: 12.92,
        b: 5.35,
      },
      cmyk: null,
    },
    {
      code: '13-2007 TPG',
      name: 'Veiled Pink',
      hex: 'E7C6C8',
      rgb: {
        r: 231,
        g: 198,
        b: 200,
      },
      lab: {
        l: 82.94,
        a: 13.26,
        b: 3.72,
      },
      cmyk: null,
    },
    {
      code: '12-1310 TPG',
      name: 'Icy Pink',
      hex: 'F5D3DB',
      rgb: {
        r: 245,
        g: 211,
        b: 219,
      },
      lab: {
        l: 87.74,
        a: 13.83,
        b: 1.01,
      },
      cmyk: null,
    },
    {
      code: '12-1813 TPG',
      name: "Mary's Rose",
      hex: 'F8D3D5',
      rgb: {
        r: 248,
        g: 211,
        b: 213,
      },
      lab: {
        l: 88.12,
        a: 13.97,
        b: 4.59,
      },
      cmyk: null,
    },
    {
      code: '12-2902 TPG',
      name: 'Mauve Chalk',
      hex: 'E3D2CF',
      rgb: {
        r: 227,
        g: 210,
        b: 207,
      },
      lab: {
        l: 85.76,
        a: 5.81,
        b: 3.85,
      },
      cmyk: null,
    },
    {
      code: '12-2904 TPG',
      name: 'Primrose Pink',
      hex: 'EED7D9',
      rgb: {
        r: 238,
        g: 215,
        b: 217,
      },
      lab: {
        l: 88,
        a: 8.84,
        b: 2.11,
      },
      cmyk: null,
    },
    {
      code: '13-2803 TPG',
      name: 'Pale Lilac',
      hex: 'E3C9CE',
      rgb: {
        r: 227,
        g: 201,
        b: 206,
      },
      lab: {
        l: 83.47,
        a: 9.94,
        b: 1.39,
      },
      cmyk: null,
    },
    {
      code: '13-1904 TPG',
      name: 'Chalk Pink',
      hex: 'E8C7CE',
      rgb: {
        r: 232,
        g: 199,
        b: 206,
      },
      lab: {
        l: 83.5,
        a: 12.91,
        b: 1.59,
      },
      cmyk: null,
    },
    {
      code: '12-1808 TPG',
      name: 'Pinkesque',
      hex: 'F5CBD1',
      rgb: {
        r: 245,
        g: 203,
        b: 209,
      },
      lab: {
        l: 85.87,
        a: 16.49,
        b: 3.53,
      },
      cmyk: null,
    },
    {
      code: '12-2807 TPG',
      name: 'Tender Touch',
      hex: 'F3D6E0',
      rgb: {
        r: 243,
        g: 214,
        b: 224,
      },
      lab: {
        l: 88.32,
        a: 12.27,
        b: -1.22,
      },
      cmyk: null,
    },
    {
      code: '13-2808 TPG',
      name: 'Ballet Slipper',
      hex: 'F0D1D8',
      rgb: {
        r: 240,
        g: 209,
        b: 216,
      },
      lab: {
        l: 86.87,
        a: 12.16,
        b: 1.23,
      },
      cmyk: null,
    },
    {
      code: '13-2802 TPG',
      name: 'Fairy Tale',
      hex: 'EFC2D1',
      rgb: {
        r: 239,
        g: 194,
        b: 209,
      },
      lab: {
        l: 82.93,
        a: 18.96,
        b: -0.86,
      },
      cmyk: null,
    },
    {
      code: '13-1906 TPG',
      name: 'Rose Shadow',
      hex: 'F7C2D0',
      rgb: {
        r: 247,
        g: 194,
        b: 208,
      },
      lab: {
        l: 83.9,
        a: 21.5,
        b: 1.47,
      },
      cmyk: null,
    },
    {
      code: '13-2006 TPG',
      name: 'Almond Blossom',
      hex: 'F5BDC8',
      rgb: {
        r: 245,
        g: 189,
        b: 200,
      },
      lab: {
        l: 82.46,
        a: 22.73,
        b: 3.34,
      },
      cmyk: null,
    },
    {
      code: '13-2010 TPG',
      name: 'Orchid Pink',
      hex: 'F1BCCA',
      rgb: {
        r: 241,
        g: 188,
        b: 202,
      },
      lab: {
        l: 81.79,
        a: 21.58,
        b: 1.22,
      },
      cmyk: null,
    },
    {
      code: '14-1909 TPG',
      name: 'Coral Blush',
      hex: 'E7B6BE',
      rgb: {
        r: 231,
        g: 182,
        b: 190,
      },
      lab: {
        l: 78.99,
        a: 19.95,
        b: 3.62,
      },
      cmyk: null,
    },
    {
      code: '11-2511 TPG',
      name: 'Shrinking Violet',
      hex: 'F0DDE1',
      rgb: {
        r: 240,
        g: 221,
        b: 225,
      },
      lab: {
        l: 89.91,
        a: 7.42,
        b: 0.85,
      },
      cmyk: null,
    },
    {
      code: '12-2906 TPG',
      name: 'Barely Pink',
      hex: 'F1D7DC',
      rgb: {
        r: 241,
        g: 215,
        b: 220,
      },
      lab: {
        l: 88.43,
        a: 10.27,
        b: 1.07,
      },
      cmyk: null,
    },
    {
      code: '13-2807 TPG',
      name: 'Ballerina',
      hex: 'ECCFDB',
      rgb: {
        r: 236,
        g: 207,
        b: 219,
      },
      lab: {
        l: 85.97,
        a: 12.26,
        b: -1.52,
      },
      cmyk: null,
    },
    {
      code: '13-2801 TPG',
      name: 'Pink-a-boo',
      hex: 'F6CFDD',
      rgb: {
        r: 246,
        g: 207,
        b: 221,
      },
      lab: {
        l: 87.07,
        a: 16.21,
        b: -1.07,
      },
      cmyk: null,
    },
    {
      code: '14-2304 TPG',
      name: 'Bleached Mauve',
      hex: 'D9AFB9',
      rgb: {
        r: 217,
        g: 175,
        b: 185,
      },
      lab: {
        l: 75.84,
        a: 17.47,
        b: 1.45,
      },
      cmyk: null,
    },
    {
      code: '13-2804 TPG',
      name: 'Parfait Pink',
      hex: 'EAC4CF',
      rgb: {
        r: 234,
        g: 196,
        b: 207,
      },
      lab: {
        l: 82.93,
        a: 16.1,
        b: 0.36,
      },
      cmyk: null,
    },
    {
      code: '13-2008 TPG',
      name: 'Romance Rose',
      hex: 'EAB4C3',
      rgb: {
        r: 234,
        g: 180,
        b: 195,
      },
      lab: {
        l: 79.01,
        a: 22.11,
        b: 1.03,
      },
      cmyk: null,
    },
    {
      code: '12-2907 TPG',
      name: 'Pink Marshmallow',
      hex: 'F8E3E8',
      rgb: {
        r: 248,
        g: 227,
        b: 232,
      },
      lab: {
        l: 92.03,
        a: 8.32,
        b: 0.36,
      },
      cmyk: null,
    },
    {
      code: '11-2509 TPG',
      name: 'Calcite',
      hex: 'EADEE3',
      rgb: {
        r: 234,
        g: 222,
        b: 227,
      },
      lab: {
        l: 89.58,
        a: 5.46,
        b: -1.03,
      },
      cmyk: null,
    },
    {
      code: '12-2905 TPG',
      name: 'Cradle Pink',
      hex: 'E7CDD9',
      rgb: {
        r: 231,
        g: 205,
        b: 217,
      },
      lab: {
        l: 85.11,
        a: 11.2,
        b: -1.99,
      },
      cmyk: null,
    },
    {
      code: '13-3406 TPG',
      name: 'Orchid Ice',
      hex: 'DCCED9',
      rgb: {
        r: 220,
        g: 206,
        b: 217,
      },
      lab: {
        l: 84.27,
        a: 6.37,
        b: -3.32,
      },
      cmyk: null,
    },
    {
      code: '12-2903 TPG',
      name: 'Light Lilac',
      hex: 'E0C7D2',
      rgb: {
        r: 224,
        g: 199,
        b: 210,
      },
      lab: {
        l: 82.62,
        a: 10.9,
        b: -2.08,
      },
      cmyk: null,
    },
    {
      code: '13-3405 TPG',
      name: 'Lilac Snow',
      hex: 'E1C7D9',
      rgb: {
        r: 225,
        g: 199,
        b: 217,
      },
      lab: {
        l: 83.06,
        a: 12.34,
        b: -4.9,
      },
      cmyk: null,
    },
    {
      code: '14-3203 TPG',
      name: 'Corsage',
      hex: 'DDB5C8',
      rgb: {
        r: 221,
        g: 181,
        b: 200,
      },
      lab: {
        l: 77.88,
        a: 18.06,
        b: -3.45,
      },
      cmyk: null,
    },
    {
      code: '12-2908 TPG',
      name: 'Festival Bloom',
      hex: 'F4D9E9',
      rgb: {
        r: 244,
        g: 217,
        b: 233,
      },
      lab: {
        l: 89.51,
        a: 11.98,
        b: -3.83,
      },
      cmyk: null,
    },
    {
      code: '13-2806 TPG',
      name: 'Pink Lady',
      hex: 'EDC2D7',
      rgb: {
        r: 237,
        g: 194,
        b: 215,
      },
      lab: {
        l: 82.92,
        a: 19.33,
        b: -4.31,
      },
      cmyk: null,
    },
    {
      code: '13-2805 TPG',
      name: 'Roseate Spoonbill',
      hex: 'EAC0D3',
      rgb: {
        r: 234,
        g: 192,
        b: 211,
      },
      lab: {
        l: 82.08,
        a: 18.36,
        b: -3.16,
      },
      cmyk: null,
    },
    {
      code: '13-2809 TPG',
      name: 'Sweet Dreams',
      hex: 'F1C1D7',
      rgb: {
        r: 241,
        g: 193,
        b: 215,
      },
      lab: {
        l: 83.19,
        a: 20.99,
        b: -3.67,
      },
      cmyk: null,
    },
    {
      code: '14-2710 TPG',
      name: 'Lilac Sachet',
      hex: 'E9AECA',
      rgb: {
        r: 233,
        g: 174,
        b: 202,
      },
      lab: {
        l: 77.51,
        a: 26.47,
        b: -4.69,
      },
      cmyk: null,
    },
    {
      code: '14-2808 TPG',
      name: 'Sweet Lilac',
      hex: 'E7B7CF',
      rgb: {
        r: 231,
        g: 183,
        b: 207,
      },
      lab: {
        l: 79.61,
        a: 21.64,
        b: -4.69,
      },
      cmyk: null,
    },
    {
      code: '14-3205 TPG',
      name: 'Pirouette',
      hex: 'EFC2E0',
      rgb: {
        r: 239,
        g: 194,
        b: 224,
      },
      lab: {
        l: 83.35,
        a: 20.79,
        b: -8.34,
      },
      cmyk: null,
    },
    {
      code: '13-2800 TPG',
      name: 'Pink Tulle',
      hex: 'F0CCE5',
      rgb: {
        r: 240,
        g: 204,
        b: 229,
      },
      lab: {
        l: 86.01,
        a: 16.48,
        b: -6.94,
      },
      cmyk: null,
    },
    {
      code: '13-3207 TPG',
      name: 'Cherry Blossom',
      hex: 'F4CFE0',
      rgb: {
        r: 244,
        g: 207,
        b: 224,
      },
      lab: {
        l: 87.03,
        a: 15.91,
        b: -2.69,
      },
      cmyk: null,
    },
    {
      code: '14-2806 TPG',
      name: 'Nosegay',
      hex: 'F0C0D8',
      rgb: {
        r: 240,
        g: 192,
        b: 216,
      },
      lab: {
        l: 82.87,
        a: 21.26,
        b: -4.33,
      },
      cmyk: null,
    },
    {
      code: '15-2218 TPG',
      name: 'Pink Frosting',
      hex: 'F1ABCE',
      rgb: {
        r: 241,
        g: 171,
        b: 206,
      },
      lab: {
        l: 78.03,
        a: 30.87,
        b: -6.23,
      },
      cmyk: null,
    },
    {
      code: '15-2213 TPG',
      name: 'Bonbon',
      hex: 'F1A9CF',
      rgb: {
        r: 241,
        g: 169,
        b: 207,
      },
      lab: {
        l: 77.53,
        a: 31.97,
        b: -7.27,
      },
      cmyk: null,
    },
    {
      code: '16-2118 TPG',
      name: 'Pink Power',
      hex: 'D66D97',
      rgb: {
        r: 214,
        g: 109,
        b: 151,
      },
      lab: {
        l: 60.47,
        a: 47.13,
        b: -1.46,
      },
      cmyk: null,
    },
    {
      code: '17-2519 TPG',
      name: 'Gin Fizz',
      hex: 'CF608F',
      rgb: {
        r: 207,
        g: 96,
        b: 143,
      },
      lab: {
        l: 56.79,
        a: 49.88,
        b: -1.98,
      },
      cmyk: null,
    },
    {
      code: '15-1915 TPG',
      name: 'Flowering Ginger',
      hex: 'E87D98',
      rgb: {
        r: 232,
        g: 125,
        b: 152,
      },
      lab: {
        l: 66.15,
        a: 45.66,
        b: 6.58,
      },
      cmyk: null,
    },
    {
      code: '15-1922 TPG',
      name: 'Geranium Pink',
      hex: 'F492A2',
      rgb: {
        r: 244,
        g: 146,
        b: 162,
      },
      lab: {
        l: 72.13,
        a: 40.26,
        b: 9.6,
      },
      cmyk: null,
    },
    {
      code: '16-1731 TPG',
      name: 'Strawberry Pink',
      hex: 'F88192',
      rgb: {
        r: 248,
        g: 129,
        b: 146,
      },
      lab: {
        l: 69.02,
        a: 48.39,
        b: 14.35,
      },
      cmyk: null,
    },
    {
      code: '17-1928 TPG',
      name: 'Bubblegum',
      hex: 'E67592',
      rgb: {
        r: 230,
        g: 117,
        b: 146,
      },
      lab: {
        l: 64.31,
        a: 48.54,
        b: 7.58,
      },
      cmyk: null,
    },
    {
      code: '17-1930 TPG',
      name: 'Camellia Rose',
      hex: 'F16987',
      rgb: {
        r: 241,
        g: 105,
        b: 135,
      },
      lab: {
        l: 63.53,
        a: 56.15,
        b: 13.64,
      },
      cmyk: null,
    },
    {
      code: '17-1926 TPG',
      name: 'Fruit Dove',
      hex: 'D26480',
      rgb: {
        r: 210,
        g: 100,
        b: 128,
      },
      lab: {
        l: 57.72,
        a: 47.63,
        b: 8.22,
      },
      cmyk: null,
    },
    {
      code: '17-1739 TPG',
      name: 'Rethink Pink',
      hex: 'CA4564',
      rgb: {
        r: 202,
        g: 69,
        b: 100,
      },
      lab: {
        l: 50.74,
        a: 57.45,
        b: 14.49,
      },
      cmyk: null,
    },
    {
      code: '16-1723 TPG',
      name: 'Confetti',
      hex: 'E87A90',
      rgb: {
        r: 232,
        g: 122,
        b: 144,
      },
      lab: {
        l: 65.47,
        a: 46.16,
        b: 10.17,
      },
      cmyk: null,
    },
    {
      code: '16-1735 TPG',
      name: 'Pink Lemonade',
      hex: 'FA7B95',
      rgb: {
        r: 250,
        g: 123,
        b: 149,
      },
      lab: {
        l: 68.25,
        a: 51.68,
        b: 12.27,
      },
      cmyk: null,
    },
    {
      code: '17-1741 TPG',
      name: 'Party Punch',
      hex: 'C5516C',
      rgb: {
        r: 197,
        g: 81,
        b: 108,
      },
      lab: {
        l: 51.74,
        a: 49.93,
        b: 11.29,
      },
      cmyk: null,
    },
    {
      code: '17-1744 TPG',
      name: 'Calypso Coral',
      hex: 'EE6779',
      rgb: {
        r: 238,
        g: 103,
        b: 121,
      },
      lab: {
        l: 62.5,
        a: 55.46,
        b: 19.35,
      },
      cmyk: null,
    },
    {
      code: '17-1640 TPG',
      name: 'Winterberry',
      hex: 'BE4253',
      rgb: {
        r: 190,
        g: 66,
        b: 83,
      },
      lab: {
        l: 47.78,
        a: 53.65,
        b: 20.14,
      },
      cmyk: null,
    },
    {
      code: '18-1665 TPG',
      name: 'Watermelon',
      hex: 'CC4358',
      rgb: {
        r: 204,
        g: 67,
        b: 88,
      },
      lab: {
        l: 50.53,
        a: 57.82,
        b: 21.94,
      },
      cmyk: null,
    },
    {
      code: '18-1750 TPG',
      name: 'Viva Magenta',
      hex: 'BE3455',
      rgb: {
        r: 190,
        g: 52,
        b: 85,
      },
      lab: {
        l: 46.05,
        a: 58.95,
        b: 17.38,
      },
      cmyk: null,
    },
    {
      code: '15-1822 TPG',
      name: 'Plumeria',
      hex: 'F891A3',
      rgb: {
        r: 248,
        g: 145,
        b: 163,
      },
      lab: {
        l: 72.52,
        a: 42.14,
        b: 9.86,
      },
      cmyk: null,
    },
    {
      code: '17-1746 TPG',
      name: 'Coral Paradise',
      hex: 'EE6075',
      rgb: {
        r: 238,
        g: 96,
        b: 117,
      },
      lab: {
        l: 61.33,
        a: 57.7,
        b: 20.59,
      },
      cmyk: null,
    },
    {
      code: '17-1736 TPG',
      name: 'Sun Kissed Coral',
      hex: 'E4707F',
      rgb: {
        r: 228,
        g: 112,
        b: 127,
      },
      lab: {
        l: 62.6,
        a: 49.05,
        b: 15.44,
      },
      cmyk: null,
    },
    {
      code: '17-1755 TPG',
      name: 'Paradise Pink',
      hex: 'E64D67',
      rgb: {
        r: 230,
        g: 77,
        b: 103,
      },
      lab: {
        l: 56.9,
        a: 62.37,
        b: 22.67,
      },
      cmyk: null,
    },
    {
      code: '17-1753 TPG',
      name: 'Geranium',
      hex: 'D74B61',
      rgb: {
        r: 215,
        g: 75,
        b: 97,
      },
      lab: {
        l: 53.83,
        a: 59.15,
        b: 20.86,
      },
      cmyk: null,
    },
    {
      code: '18-1755 TPG',
      name: 'Rouge Red',
      hex: 'E4506B',
      rgb: {
        r: 228,
        g: 80,
        b: 107,
      },
      lab: {
        l: 57,
        a: 61.21,
        b: 20.05,
      },
      cmyk: null,
    },
    {
      code: '18-1756 TPG',
      name: 'Teaberry',
      hex: 'DC455F',
      rgb: {
        r: 220,
        g: 69,
        b: 95,
      },
      lab: {
        l: 53.96,
        a: 62.05,
        b: 22.94,
      },
      cmyk: null,
    },
    {
      code: '18-2120 TPG',
      name: 'Honeysuckle',
      hex: 'D85A7B',
      rgb: {
        r: 216,
        g: 90,
        b: 123,
      },
      lab: {
        l: 56.87,
        a: 54.5,
        b: 9.79,
      },
      cmyk: null,
    },
    {
      code: '17-1740 TPG',
      name: 'Claret Red',
      hex: 'C35369',
      rgb: {
        r: 195,
        g: 83,
        b: 105,
      },
      lab: {
        l: 51.67,
        a: 49.57,
        b: 12.39,
      },
      cmyk: null,
    },
    {
      code: '18-1741 TPG',
      name: 'Raspberry Wine',
      hex: 'B73E5C',
      rgb: {
        r: 183,
        g: 62,
        b: 92,
      },
      lab: {
        l: 46,
        a: 53.35,
        b: 12.4,
      },
      cmyk: null,
    },
    {
      code: '18-1852 TPG',
      name: 'Rose Red',
      hex: 'C4375C',
      rgb: {
        r: 196,
        g: 55,
        b: 92,
      },
      lab: {
        l: 47.55,
        a: 59.98,
        b: 15.05,
      },
      cmyk: null,
    },
    {
      code: '18-1760 TPG',
      name: 'Barberry',
      hex: 'B4334E',
      rgb: {
        r: 180,
        g: 51,
        b: 78,
      },
      lab: {
        l: 43.73,
        a: 55.63,
        b: 18.18,
      },
      cmyk: null,
    },
    {
      code: '17-1842 TPG',
      name: 'Azalea',
      hex: 'D64565',
      rgb: {
        r: 214,
        g: 69,
        b: 101,
      },
      lab: {
        l: 52.97,
        a: 60.82,
        b: 17.65,
      },
      cmyk: null,
    },
    {
      code: '18-1856 TPG',
      name: 'Virtual Pink',
      hex: 'CA335C',
      rgb: {
        r: 202,
        g: 51,
        b: 92,
      },
      lab: {
        l: 48.36,
        a: 62.79,
        b: 16.52,
      },
      cmyk: null,
    },
    {
      code: '18-1754 TPG',
      name: 'Raspberry',
      hex: 'D64162',
      rgb: {
        r: 214,
        g: 65,
        b: 98,
      },
      lab: {
        l: 52.32,
        a: 61.86,
        b: 19.14,
      },
      cmyk: null,
    },
    {
      code: '18-1945 TPG',
      name: 'Bright Rose',
      hex: 'C03360',
      rgb: {
        r: 192,
        g: 51,
        b: 96,
      },
      lab: {
        l: 46.48,
        a: 60.21,
        b: 11.16,
      },
      cmyk: null,
    },
    {
      code: '18-1951 TPG',
      name: 'Love Potion',
      hex: 'BE3360',
      rgb: {
        r: 190,
        g: 51,
        b: 96,
      },
      lab: {
        l: 46.14,
        a: 59.58,
        b: 10.91,
      },
      cmyk: null,
    },
    {
      code: '18-1950 TPG',
      name: 'Jazzy',
      hex: 'B53359',
      rgb: {
        r: 181,
        g: 51,
        b: 89,
      },
      lab: {
        l: 44.1,
        a: 56.61,
        b: 11.72,
      },
      cmyk: null,
    },
    {
      code: '19-1955 TPG',
      name: 'Cerise',
      hex: 'A03553',
      rgb: {
        r: 160,
        g: 53,
        b: 83,
      },
      lab: {
        l: 40.17,
        a: 48.98,
        b: 9.18,
      },
      cmyk: null,
    },
    {
      code: '19-1860 TPG',
      name: 'Persian Red',
      hex: 'A23550',
      rgb: {
        r: 162,
        g: 53,
        b: 80,
      },
      lab: {
        l: 40.68,
        a: 49.68,
        b: 11.75,
      },
      cmyk: null,
    },
    {
      code: '19-1850 TPG',
      name: 'Red Bud',
      hex: '9B3E56',
      rgb: {
        r: 155,
        g: 62,
        b: 86,
      },
      lab: {
        l: 40.64,
        a: 43.18,
        b: 7.94,
      },
      cmyk: null,
    },
    {
      code: '17-1937 TPG',
      name: 'Hot Pink',
      hex: 'E86288',
      rgb: {
        r: 232,
        g: 98,
        b: 136,
      },
      lab: {
        l: 61.04,
        a: 56.29,
        b: 8.85,
      },
      cmyk: null,
    },
    {
      code: '18-2043 TPG',
      name: 'Raspberry Sorbet',
      hex: 'CD4A77',
      rgb: {
        r: 205,
        g: 74,
        b: 119,
      },
      lab: {
        l: 52.43,
        a: 57.07,
        b: 6.23,
      },
      cmyk: null,
    },
    {
      code: '19-2039 TPG',
      name: 'Granita',
      hex: 'A03A59',
      rgb: {
        r: 160,
        g: 58,
        b: 89,
      },
      lab: {
        l: 41.22,
        a: 47.08,
        b: 6.78,
      },
      cmyk: null,
    },
    {
      code: '19-2041 TPG',
      name: 'Cherries Jubilee',
      hex: '9A3456',
      rgb: {
        r: 154,
        g: 52,
        b: 86,
      },
      lab: {
        l: 39.09,
        a: 47.38,
        b: 5.77,
      },
      cmyk: null,
    },
    {
      code: '19-2045 TPG',
      name: 'Vivacious',
      hex: 'A93661',
      rgb: {
        r: 169,
        g: 54,
        b: 97,
      },
      lab: {
        l: 42.52,
        a: 52.11,
        b: 4.4,
      },
      cmyk: null,
    },
    {
      code: '19-2047 TPG',
      name: 'Sangria',
      hex: 'A03E5D',
      rgb: {
        r: 160,
        g: 62,
        b: 93,
      },
      lab: {
        l: 41.74,
        a: 45.35,
        b: 5.14,
      },
      cmyk: null,
    },
    {
      code: '19-2033 TPG',
      name: 'Anemone',
      hex: '893B53',
      rgb: {
        r: 137,
        g: 59,
        b: 83,
      },
      lab: {
        l: 36.99,
        a: 37.95,
        b: 3.71,
      },
      cmyk: null,
    },
    {
      code: '15-1920 TPG',
      name: 'Morning Glory',
      hex: 'E982A0',
      rgb: {
        r: 233,
        g: 130,
        b: 160,
      },
      lab: {
        l: 67.41,
        a: 44.36,
        b: 3.7,
      },
      cmyk: null,
    },
    {
      code: '16-2126 TPG',
      name: 'Azalea Pink',
      hex: 'E46D9A',
      rgb: {
        r: 228,
        g: 109,
        b: 154,
      },
      lab: {
        l: 62.6,
        a: 51.58,
        b: 0.95,
      },
      cmyk: null,
    },
    {
      code: '17-2127 TPG',
      name: 'Shocking Pink',
      hex: 'D6638D',
      rgb: {
        r: 214,
        g: 99,
        b: 141,
      },
      lab: {
        l: 58.47,
        a: 50.91,
        b: 1.41,
      },
      cmyk: null,
    },
    {
      code: '17-2230 TPG',
      name: 'Carmine Rose',
      hex: 'DB6393',
      rgb: {
        r: 219,
        g: 99,
        b: 147,
      },
      lab: {
        l: 59.33,
        a: 52.78,
        b: 0.08,
      },
      cmyk: null,
    },
    {
      code: '17-2033 TPG',
      name: 'Fandango Pink',
      hex: 'DD5B86',
      rgb: {
        r: 221,
        g: 91,
        b: 134,
      },
      lab: {
        l: 58.03,
        a: 55.69,
        b: 5.67,
      },
      cmyk: null,
    },
    {
      code: '18-2133 TPG',
      name: 'Pink Flambé',
      hex: 'CD577E',
      rgb: {
        r: 205,
        g: 87,
        b: 126,
      },
      lab: {
        l: 54.54,
        a: 52.88,
        b: 4.22,
      },
      cmyk: null,
    },
    {
      code: '17-1831 TPG',
      name: 'Carmine',
      hex: 'BB5072',
      rgb: {
        r: 187,
        g: 80,
        b: 114,
      },
      lab: {
        l: 50.02,
        a: 48.62,
        b: 4.71,
      },
      cmyk: null,
    },
    {
      code: '14-2311 TPG',
      name: 'Prism Pink',
      hex: 'EEA5C5',
      rgb: {
        r: 238,
        g: 165,
        b: 197,
      },
      lab: {
        l: 76.09,
        a: 32.36,
        b: -4.07,
      },
      cmyk: null,
    },
    {
      code: '15-2216 TPG',
      name: 'Sachet Pink',
      hex: 'F38CB1',
      rgb: {
        r: 243,
        g: 140,
        b: 177,
      },
      lab: {
        l: 71.08,
        a: 43.66,
        b: 0.37,
      },
      cmyk: null,
    },
    {
      code: '15-2217 TPG',
      name: 'Aurora Pink',
      hex: 'E683A9',
      rgb: {
        r: 230,
        g: 131,
        b: 169,
      },
      lab: {
        l: 67.41,
        a: 43.49,
        b: -1.42,
      },
      cmyk: null,
    },
    {
      code: '16-2124 TPG',
      name: 'Pink Carnation',
      hex: 'EA7EA4',
      rgb: {
        r: 234,
        g: 126,
        b: 164,
      },
      lab: {
        l: 66.87,
        a: 46.73,
        b: 1.11,
      },
      cmyk: null,
    },
    {
      code: '16-2120 TPG',
      name: 'Wild Orchid',
      hex: 'D674A0',
      rgb: {
        r: 214,
        g: 116,
        b: 160,
      },
      lab: {
        l: 61.9,
        a: 44.72,
        b: -4.7,
      },
      cmyk: null,
    },
    {
      code: '17-2120 TPG',
      name: 'Chateau Rose',
      hex: 'D57898',
      rgb: {
        r: 213,
        g: 120,
        b: 152,
      },
      lab: {
        l: 62.4,
        a: 41.28,
        b: 0.68,
      },
      cmyk: null,
    },
    {
      code: '17-2520 TPG',
      name: 'Ibis Rose',
      hex: 'C76491',
      rgb: {
        r: 199,
        g: 100,
        b: 145,
      },
      lab: {
        l: 56.32,
        a: 45.28,
        b: -4.16,
      },
      cmyk: null,
    },
    {
      code: '15-2215 TPG',
      name: 'Begonia Pink',
      hex: 'E89ABC',
      rgb: {
        r: 232,
        g: 154,
        b: 188,
      },
      lab: {
        l: 72.91,
        a: 34.6,
        b: -3.72,
      },
      cmyk: null,
    },
    {
      code: '15-2214 TPG',
      name: 'Rosebloom',
      hex: 'E18FB4',
      rgb: {
        r: 225,
        g: 143,
        b: 180,
      },
      lab: {
        l: 69.54,
        a: 36.96,
        b: -4.4,
      },
      cmyk: null,
    },
    {
      code: '15-2718 TPG',
      name: 'Fuchsia Pink',
      hex: 'E08CB9',
      rgb: {
        r: 224,
        g: 140,
        b: 185,
      },
      lab: {
        l: 68.88,
        a: 38.48,
        b: -8.02,
      },
      cmyk: null,
    },
    {
      code: '17-2623 TPG',
      name: 'Fiji Flower',
      hex: 'CF6AA2',
      rgb: {
        r: 207,
        g: 106,
        b: 162,
      },
      lab: {
        l: 59.05,
        a: 47.07,
        b: -9.98,
      },
      cmyk: null,
    },
    {
      code: '17-2227 TPG',
      name: 'Lilac Rose',
      hex: 'BE4C7D',
      rgb: {
        r: 190,
        g: 76,
        b: 125,
      },
      lab: {
        l: 50.29,
        a: 52.07,
        b: -1.08,
      },
      cmyk: null,
    },
    {
      code: '18-2333 TPG',
      name: 'Raspberry Rose',
      hex: 'C7508C',
      rgb: {
        r: 199,
        g: 80,
        b: 140,
      },
      lab: {
        l: 52.85,
        a: 54.19,
        b: -5.79,
      },
      cmyk: null,
    },
    {
      code: '17-2031 TPG',
      name: 'Fuchsia Rose',
      hex: 'C34E7C',
      rgb: {
        r: 195,
        g: 78,
        b: 124,
      },
      lab: {
        l: 51.42,
        a: 52.92,
        b: 1.04,
      },
      cmyk: null,
    },
    {
      code: '16-2122 TPG',
      name: 'Pink Cosmos',
      hex: 'ED7FA8',
      rgb: {
        r: 237,
        g: 127,
        b: 168,
      },
      lab: {
        l: 67.52,
        a: 47.58,
        b: -0.09,
      },
      cmyk: null,
    },
    {
      code: '17-2235 TPG',
      name: 'Sangria Sunset',
      hex: 'E66693',
      rgb: {
        r: 230,
        g: 102,
        b: 147,
      },
      lab: {
        l: 61.57,
        a: 54.81,
        b: 2.99,
      },
      cmyk: null,
    },
    {
      code: '18-2330 TPG',
      name: 'Fuchsia Fedora',
      hex: 'CC4C8B',
      rgb: {
        r: 204,
        g: 76,
        b: 139,
      },
      lab: {
        l: 53.1,
        a: 57.44,
        b: -4.95,
      },
      cmyk: null,
    },
    {
      code: '17-2034 TPG',
      name: 'Pink Yarrow',
      hex: 'CE4A7E',
      rgb: {
        r: 206,
        g: 74,
        b: 126,
      },
      lab: {
        l: 52.65,
        a: 57.48,
        b: 2.43,
      },
      cmyk: null,
    },
    {
      code: '17-2036 TPG',
      name: 'Magenta',
      hex: 'CF4F80',
      rgb: {
        r: 207,
        g: 79,
        b: 128,
      },
      lab: {
        l: 53.71,
        a: 55.74,
        b: 2.47,
      },
      cmyk: null,
    },
    {
      code: '18-2143 TPG',
      name: 'Beetroot Purple',
      hex: 'CA4273',
      rgb: {
        r: 202,
        g: 66,
        b: 115,
      },
      lab: {
        l: 50.62,
        a: 59.19,
        b: 5.92,
      },
      cmyk: null,
    },
    {
      code: '18-2042 TPG',
      name: 'Innuendo',
      hex: 'C43F66',
      rgb: {
        r: 196,
        g: 63,
        b: 102,
      },
      lab: {
        l: 48.76,
        a: 57.27,
        b: 10.68,
      },
      cmyk: null,
    },
    {
      code: '17-2619 TPG',
      name: 'Strawberry Moon',
      hex: 'DD72A7',
      rgb: {
        r: 221,
        g: 114,
        b: 167,
      },
      lab: {
        l: 62.88,
        a: 48.29,
        b: -6.84,
      },
      cmyk: null,
    },
    {
      code: '17-2625 TPG',
      name: 'Super Pink',
      hex: 'CF6FA7',
      rgb: {
        r: 207,
        g: 111,
        b: 167,
      },
      lab: {
        l: 60.15,
        a: 44.96,
        b: -11.28,
      },
      cmyk: null,
    },
    {
      code: '17-2521 TPG',
      name: 'Cone Flower',
      hex: 'C15B89',
      rgb: {
        r: 193,
        g: 91,
        b: 137,
      },
      lab: {
        l: 53.54,
        a: 46,
        b: -3.42,
      },
      cmyk: null,
    },
    {
      code: '18-2436 TPG',
      name: 'Fuchsia Purple',
      hex: 'CD497D',
      rgb: {
        r: 205,
        g: 73,
        b: 125,
      },
      lab: {
        l: 52.28,
        a: 57.56,
        b: 2.15,
      },
      cmyk: null,
    },
    {
      code: '18-2140 TPG',
      name: 'Cabaret',
      hex: 'CB3F77',
      rgb: {
        r: 203,
        g: 63,
        b: 119,
      },
      lab: {
        l: 50.33,
        a: 60.67,
        b: 3.11,
      },
      cmyk: null,
    },
    {
      code: '18-2045 TPG',
      name: 'Pink Peacock',
      hex: 'C83E74',
      rgb: {
        r: 200,
        g: 62,
        b: 116,
      },
      lab: {
        l: 49.79,
        a: 59.9,
        b: 3.92,
      },
      cmyk: null,
    },
    {
      code: '18-2336 TPG',
      name: 'Very Berry',
      hex: 'BB467D',
      rgb: {
        r: 187,
        g: 70,
        b: 125,
      },
      lab: {
        l: 48.72,
        a: 53.67,
        b: -3.66,
      },
      cmyk: null,
    },
    {
      code: '17-2627 TPG',
      name: 'Phlox Pink',
      hex: 'D368A2',
      rgb: {
        r: 211,
        g: 104,
        b: 162,
      },
      lab: {
        l: 59.45,
        a: 49.51,
        b: -9.3,
      },
      cmyk: null,
    },
    {
      code: '17-2624 TPG',
      name: 'Rose Violet',
      hex: 'C15391',
      rgb: {
        r: 193,
        g: 83,
        b: 145,
      },
      lab: {
        l: 52.54,
        a: 51.38,
        b: -9.91,
      },
      cmyk: null,
    },
    {
      code: '18-2615 TPG',
      name: 'Rosé Sorbet',
      hex: 'A65382',
      rgb: {
        r: 166,
        g: 83,
        b: 130,
      },
      lab: {
        l: 47.69,
        a: 40.11,
        b: -8.7,
      },
      cmyk: null,
    },
    {
      code: '18-2328 TPG',
      name: 'Fuchsia Red',
      hex: 'B44580',
      rgb: {
        r: 180,
        g: 69,
        b: 128,
      },
      lab: {
        l: 47.48,
        a: 51.88,
        b: -7.29,
      },
      cmyk: null,
    },
    {
      code: '18-2326 TPG',
      name: 'Cactus Flower',
      hex: 'AC4977',
      rgb: {
        r: 172,
        g: 73,
        b: 119,
      },
      lab: {
        l: 46.45,
        a: 46.41,
        b: -3.63,
      },
      cmyk: null,
    },
    {
      code: '19-2434 TPG',
      name: 'Festival Fuchsia',
      hex: 'A14375',
      rgb: {
        r: 161,
        g: 67,
        b: 117,
      },
      lab: {
        l: 43.45,
        a: 45.22,
        b: -7.06,
      },
      cmyk: null,
    },
    {
      code: '18-1722 TPG',
      name: 'Raspberry Coulis',
      hex: '8A4563',
      rgb: {
        r: 138,
        g: 69,
        b: 99,
      },
      lab: {
        l: 39.62,
        a: 33.85,
        b: -2.53,
      },
      cmyk: null,
    },
    {
      code: '18-2525 TPG',
      name: 'Magenta Haze',
      hex: 'A04E78',
      rgb: {
        r: 160,
        g: 78,
        b: 120,
      },
      lab: {
        l: 45.42,
        a: 39.85,
        b: -6.36,
      },
      cmyk: null,
    },
    {
      code: '18-2527 TPG',
      name: 'Baton Rouge',
      hex: '9A3F70',
      rgb: {
        r: 154,
        g: 63,
        b: 112,
      },
      lab: {
        l: 41.42,
        a: 43.96,
        b: -6.93,
      },
      cmyk: null,
    },
    {
      code: '19-2431 TPG',
      name: 'Boysenberry',
      hex: '893E62',
      rgb: {
        r: 137,
        g: 62,
        b: 98,
      },
      lab: {
        l: 37.9,
        a: 37.33,
        b: -4.24,
      },
      cmyk: null,
    },
    {
      code: '19-2432 TPG',
      name: 'Raspberry Radiance',
      hex: '864061',
      rgb: {
        r: 134,
        g: 64,
        b: 97,
      },
      lab: {
        l: 37.98,
        a: 34.65,
        b: -3.32,
      },
      cmyk: null,
    },
    {
      code: '19-2430 TPG',
      name: 'Purple Potion',
      hex: '723F54',
      rgb: {
        r: 114,
        g: 63,
        b: 84,
      },
      lab: {
        l: 33.92,
        a: 26.09,
        b: -1.71,
      },
      cmyk: null,
    },
    {
      code: '19-2428 TPG',
      name: 'Magenta Purple',
      hex: '753D5B',
      rgb: {
        r: 117,
        g: 61,
        b: 91,
      },
      lab: {
        l: 34.28,
        a: 29.32,
        b: -5.21,
      },
      cmyk: null,
    },
    {
      code: '19-2429 TPG',
      name: 'Plum Caspia',
      hex: '6E3D5B',
      rgb: {
        r: 110,
        g: 61,
        b: 91,
      },
      lab: {
        l: 33.17,
        a: 25.86,
        b: -7.31,
      },
      cmyk: null,
    },
    {
      code: '17-2617 TPG',
      name: 'Dahlia Mauve',
      hex: 'A95988',
      rgb: {
        r: 169,
        g: 89,
        b: 136,
      },
      lab: {
        l: 49.52,
        a: 38.82,
        b: -9.47,
      },
      cmyk: null,
    },
    {
      code: '18-3027 TPG',
      name: 'Purple Orchid',
      hex: 'AD5691',
      rgb: {
        r: 173,
        g: 86,
        b: 145,
      },
      lab: {
        l: 49.76,
        a: 42.61,
        b: -14.54,
      },
      cmyk: null,
    },
    {
      code: '18-3339 TPG',
      name: 'Vivid Viola',
      hex: '9F4D84',
      rgb: {
        r: 159,
        g: 77,
        b: 132,
      },
      lab: {
        l: 45.41,
        a: 41.29,
        b: -13.28,
      },
      cmyk: null,
    },
    {
      code: '19-2630 TPG',
      name: 'Wild Aster',
      hex: '964678',
      rgb: {
        r: 150,
        g: 70,
        b: 120,
      },
      lab: {
        l: 42.34,
        a: 40.25,
        b: -10.54,
      },
      cmyk: null,
    },
    {
      code: '18-3022 TPG',
      name: 'Deep Orchid',
      hex: '964B7C',
      rgb: {
        r: 150,
        g: 75,
        b: 124,
      },
      lab: {
        l: 43.35,
        a: 37.87,
        b: -11.93,
      },
      cmyk: null,
    },
    {
      code: '18-2320 TPG',
      name: 'Clover',
      hex: '944478',
      rgb: {
        r: 148,
        g: 68,
        b: 120,
      },
      lab: {
        l: 41.74,
        a: 40.33,
        b: -11.48,
      },
      cmyk: null,
    },
    {
      code: '18-2929 TPG',
      name: 'Purple Wine',
      hex: '924678',
      rgb: {
        r: 146,
        g: 70,
        b: 120,
      },
      lab: {
        l: 41.75,
        a: 38.68,
        b: -11.62,
      },
      cmyk: null,
    },
    {
      code: '18-3224 TPG',
      name: 'Radiant Orchid',
      hex: 'B067A1',
      rgb: {
        r: 176,
        g: 103,
        b: 161,
      },
      lab: {
        l: 54.12,
        a: 36.82,
        b: -17.04,
      },
      cmyk: null,
    },
    {
      code: '18-3331 TPG',
      name: 'Hyacinth Violet',
      hex: '975591',
      rgb: {
        r: 151,
        g: 85,
        b: 145,
      },
      lab: {
        l: 46.23,
        a: 35.23,
        b: -20.31,
      },
      cmyk: null,
    },
    {
      code: '18-3324 TPG',
      name: 'Dahlia',
      hex: '8A4E85',
      rgb: {
        r: 138,
        g: 78,
        b: 133,
      },
      lab: {
        l: 42.53,
        a: 32.8,
        b: -18.68,
      },
      cmyk: null,
    },
    {
      code: '19-2924 TPG',
      name: 'Hollyhock',
      hex: '894778',
      rgb: {
        r: 137,
        g: 71,
        b: 120,
      },
      lab: {
        l: 40.41,
        a: 34.42,
        b: -13.79,
      },
      cmyk: null,
    },
    {
      code: '19-3138 TPG',
      name: 'Byzantium',
      hex: '8C4D83',
      rgb: {
        r: 140,
        g: 77,
        b: 131,
      },
      lab: {
        l: 42.49,
        a: 34.07,
        b: -17.37,
      },
      cmyk: null,
    },
    {
      code: '19-2820 TPG',
      name: 'Phlox',
      hex: '75446A',
      rgb: {
        r: 117,
        g: 68,
        b: 106,
      },
      lab: {
        l: 36.21,
        a: 26.84,
        b: -12.14,
      },
      cmyk: null,
    },
    {
      code: '19-3022 TPG',
      name: 'Gloxinia',
      hex: '684363',
      rgb: {
        r: 104,
        g: 67,
        b: 99,
      },
      lab: {
        l: 34.08,
        a: 20.65,
        b: -10.97,
      },
      cmyk: null,
    },
    {
      code: '17-3014 TPG',
      name: 'Mulberry',
      hex: 'A9739E',
      rgb: {
        r: 169,
        g: 115,
        b: 158,
      },
      lab: {
        l: 55.48,
        a: 27.76,
        b: -13.38,
      },
      cmyk: null,
    },
    {
      code: '17-3323 TPG',
      name: 'Iris Orchid',
      hex: 'A96BA6',
      rgb: {
        r: 169,
        g: 107,
        b: 166,
      },
      lab: {
        l: 54.05,
        a: 32.87,
        b: -20.52,
      },
      cmyk: null,
    },
    {
      code: '18-3120 TPG',
      name: 'Willowherb',
      hex: '925188',
      rgb: {
        r: 146,
        g: 81,
        b: 136,
      },
      lab: {
        l: 44.48,
        a: 34.09,
        b: -17.23,
      },
      cmyk: null,
    },
    {
      code: '18-3025 TPG',
      name: 'Striking Purple',
      hex: '99598F',
      rgb: {
        r: 153,
        g: 89,
        b: 143,
      },
      lab: {
        l: 47.26,
        a: 33.64,
        b: -17.1,
      },
      cmyk: null,
    },
    {
      code: '19-3336 TPG',
      name: 'Sparkling Grape',
      hex: '814A7E',
      rgb: {
        r: 129,
        g: 74,
        b: 126,
      },
      lab: {
        l: 40.19,
        a: 30.78,
        b: -18.23,
      },
      cmyk: null,
    },
    {
      code: '19-3230 TPG',
      name: 'Grape Juice',
      hex: '724166',
      rgb: {
        r: 114,
        g: 65,
        b: 102,
      },
      lab: {
        l: 34.94,
        a: 26.98,
        b: -11.29,
      },
      cmyk: null,
    },
    {
      code: '18-3340 TPG',
      name: 'Charisma',
      hex: '6A4069',
      rgb: {
        r: 106,
        g: 64,
        b: 105,
      },
      lab: {
        l: 33.95,
        a: 24.41,
        b: -15.15,
      },
      cmyk: null,
    },
    {
      code: '16-3115 TPG',
      name: 'Crocus',
      hex: 'C383B0',
      rgb: {
        r: 195,
        g: 131,
        b: 176,
      },
      lab: {
        l: 62.94,
        a: 31.3,
        b: -12.23,
      },
      cmyk: null,
    },
    {
      code: '17-3020 TPG',
      name: 'Spring Crocus',
      hex: 'BC70A5',
      rgb: {
        r: 188,
        g: 112,
        b: 165,
      },
      lab: {
        l: 57.55,
        a: 37.24,
        b: -13.93,
      },
      cmyk: null,
    },
    {
      code: '17-3240 TPG',
      name: 'Bodacious',
      hex: 'B66CA2',
      rgb: {
        r: 182,
        g: 108,
        b: 162,
      },
      lab: {
        l: 55.93,
        a: 36.79,
        b: -14.79,
      },
      cmyk: null,
    },
    {
      code: '17-3023 TPG',
      name: 'Rosebud',
      hex: 'B5639F',
      rgb: {
        r: 181,
        g: 99,
        b: 159,
      },
      lab: {
        l: 53.91,
        a: 40.52,
        b: -16.23,
      },
      cmyk: null,
    },
    {
      code: '18-3230 TPG',
      name: 'Meadow Mauve',
      hex: 'A55E90',
      rgb: {
        r: 165,
        g: 94,
        b: 144,
      },
      lab: {
        l: 49.99,
        a: 35.65,
        b: -13.89,
      },
      cmyk: null,
    },
    {
      code: '18-3015 TPG',
      name: 'Amethyst',
      hex: '84557A',
      rgb: {
        r: 132,
        g: 85,
        b: 122,
      },
      lab: {
        l: 42.74,
        a: 25.22,
        b: -12.22,
      },
      cmyk: null,
    },
    {
      code: '18-3014 TPG',
      name: 'Grape Kiss',
      hex: '805170',
      rgb: {
        r: 128,
        g: 81,
        b: 112,
      },
      lab: {
        l: 41.16,
        a: 24.48,
        b: -8.23,
      },
      cmyk: null,
    },
    {
      code: '15-3216 TPG',
      name: 'Phalaenopsis',
      hex: 'D4A0CA',
      rgb: {
        r: 212,
        g: 160,
        b: 202,
      },
      lab: {
        l: 72.24,
        a: 25.41,
        b: -12.85,
      },
      cmyk: null,
    },
    {
      code: '16-3320 TPG',
      name: 'Violet',
      hex: 'BE81B6',
      rgb: {
        r: 190,
        g: 129,
        b: 182,
      },
      lab: {
        l: 62.05,
        a: 31.09,
        b: -17.02,
      },
      cmyk: null,
    },
    {
      code: '16-3321 TPG',
      name: 'First Bloom',
      hex: 'BF7DB6',
      rgb: {
        r: 191,
        g: 125,
        b: 182,
      },
      lab: {
        l: 61.44,
        a: 33.04,
        b: -17.7,
      },
      cmyk: null,
    },
    {
      code: '17-3310 TPG',
      name: 'Pale Pansy',
      hex: 'B581AC',
      rgb: {
        r: 181,
        g: 129,
        b: 172,
      },
      lab: {
        l: 60.78,
        a: 26.54,
        b: -13.66,
      },
      cmyk: null,
    },
    {
      code: '17-3320 TPG',
      name: 'Liatris',
      hex: 'AD69A2',
      rgb: {
        r: 173,
        g: 105,
        b: 162,
      },
      lab: {
        l: 54.07,
        a: 34.31,
        b: -17.61,
      },
      cmyk: null,
    },
    {
      code: '18-3223 TPG',
      name: 'Cattleya Orchid',
      hex: 'A15491',
      rgb: {
        r: 161,
        g: 84,
        b: 145,
      },
      lab: {
        l: 47.64,
        a: 39.23,
        b: -17.75,
      },
      cmyk: null,
    },
    {
      code: '18-3024 TPG',
      name: 'Beautyberry',
      hex: '8C4B81',
      rgb: {
        r: 140,
        g: 75,
        b: 129,
      },
      lab: {
        l: 42.1,
        a: 34.36,
        b: -16.65,
      },
      cmyk: null,
    },
    {
      code: '15-3412 TPG',
      name: 'Orchid Bouquet',
      hex: 'D0ACD0',
      rgb: {
        r: 208,
        g: 172,
        b: 208,
      },
      lab: {
        l: 74.67,
        a: 18.85,
        b: -12.66,
      },
      cmyk: null,
    },
    {
      code: '16-3416 TPG',
      name: 'Violet Tulle',
      hex: 'C493C3',
      rgb: {
        r: 196,
        g: 147,
        b: 195,
      },
      lab: {
        l: 67.3,
        a: 25.39,
        b: -16.83,
      },
      cmyk: null,
    },
    {
      code: '15-3214 TPG',
      name: 'Orchid',
      hex: 'D29AC7',
      rgb: {
        r: 210,
        g: 154,
        b: 199,
      },
      lab: {
        l: 70.4,
        a: 27.5,
        b: -13.8,
      },
      cmyk: null,
    },
    {
      code: '14-3209 TPG',
      name: 'Pastel Lavender',
      hex: 'DAA4C9',
      rgb: {
        r: 218,
        g: 164,
        b: 201,
      },
      lab: {
        l: 73.64,
        a: 25.66,
        b: -10.04,
      },
      cmyk: null,
    },
    {
      code: '15-2913 TPG',
      name: 'Lilac Chiffon',
      hex: 'DD9CC7',
      rgb: {
        r: 221,
        g: 156,
        b: 199,
      },
      lab: {
        l: 72.34,
        a: 30.58,
        b: -11.04,
      },
      cmyk: null,
    },
    {
      code: '16-3118 TPG',
      name: 'Cyclamen',
      hex: 'D88ABE',
      rgb: {
        r: 216,
        g: 138,
        b: 190,
      },
      lab: {
        l: 67.54,
        a: 37.32,
        b: -13.31,
      },
      cmyk: null,
    },
    {
      code: '16-3116 TPG',
      name: 'Opera Mauve',
      hex: 'CB83B5',
      rgb: {
        r: 203,
        g: 131,
        b: 181,
      },
      lab: {
        l: 64.14,
        a: 34.98,
        b: -13.05,
      },
      cmyk: null,
    },
    {
      code: '14-3207 TPG',
      name: 'Pink Lavender',
      hex: 'DBB1CD',
      rgb: {
        r: 219,
        g: 177,
        b: 205,
      },
      lab: {
        l: 76.91,
        a: 19.85,
        b: -7.83,
      },
      cmyk: null,
    },
    {
      code: '16-2111 TPG',
      name: 'Mauve Orchid',
      hex: 'B3819A',
      rgb: {
        r: 179,
        g: 129,
        b: 154,
      },
      lab: {
        l: 60,
        a: 23.56,
        b: -4.69,
      },
      cmyk: null,
    },
    {
      code: '16-2614 TPG',
      name: 'Moonlite Mauve',
      hex: 'D091B2',
      rgb: {
        r: 208,
        g: 145,
        b: 178,
      },
      lab: {
        l: 67.64,
        a: 29.34,
        b: -6.61,
      },
      cmyk: null,
    },
    {
      code: '15-2210 TPG',
      name: 'Orchid Smoke',
      hex: 'D295AD',
      rgb: {
        r: 210,
        g: 149,
        b: 173,
      },
      lab: {
        l: 68.73,
        a: 26.78,
        b: -1.88,
      },
      cmyk: null,
    },
    {
      code: '16-2215 TPG',
      name: 'Cashmere Rose',
      hex: 'CF87A1',
      rgb: {
        r: 207,
        g: 135,
        b: 161,
      },
      lab: {
        l: 65.02,
        a: 31.77,
        b: -0.97,
      },
      cmyk: null,
    },
    {
      code: '15-1912 TPG',
      name: 'Sea Pink',
      hex: 'E198AD',
      rgb: {
        r: 225,
        g: 152,
        b: 173,
      },
      lab: {
        l: 71.18,
        a: 30.94,
        b: 1.91,
      },
      cmyk: null,
    },
    {
      code: '16-1715 TPG',
      name: 'Wild Rose',
      hex: 'D0879D',
      rgb: {
        r: 208,
        g: 135,
        b: 157,
      },
      lab: {
        l: 65.02,
        a: 32.1,
        b: 1.66,
      },
      cmyk: null,
    },
    {
      code: '14-2305 TPG',
      name: 'Pink Nectar',
      hex: 'D7A8B4',
      rgb: {
        r: 215,
        g: 168,
        b: 180,
      },
      lab: {
        l: 73.66,
        a: 19.73,
        b: 0.98,
      },
      cmyk: null,
    },
    {
      code: '14-2307 TPG',
      name: 'Cameo Pink',
      hex: 'DCABBA',
      rgb: {
        r: 220,
        g: 171,
        b: 186,
      },
      lab: {
        l: 75.18,
        a: 20.8,
        b: 0.04,
      },
      cmyk: null,
    },
    {
      code: '15-1906 TPG',
      name: 'Zephyr',
      hex: 'CAA2AA',
      rgb: {
        r: 202,
        g: 162,
        b: 170,
      },
      lab: {
        l: 70.7,
        a: 17.31,
        b: 1.92,
      },
      cmyk: null,
    },
    {
      code: '16-1712 TPG',
      name: 'Polignac',
      hex: 'C18A9D',
      rgb: {
        r: 193,
        g: 138,
        b: 157,
      },
      lab: {
        l: 63.75,
        a: 24.54,
        b: -0.48,
      },
      cmyk: null,
    },
    {
      code: '16-1710 TPG',
      name: 'Foxglove',
      hex: 'BD8893',
      rgb: {
        r: 189,
        g: 136,
        b: 147,
      },
      lab: {
        l: 62.53,
        a: 23.58,
        b: 2.81,
      },
      cmyk: null,
    },
    {
      code: '16-1708 TPG',
      name: 'Lilas',
      hex: 'BA8E9C',
      rgb: {
        r: 186,
        g: 142,
        b: 156,
      },
      lab: {
        l: 63.82,
        a: 19.18,
        b: -0.21,
      },
      cmyk: null,
    },
    {
      code: '17-1610 TPG',
      name: 'Dusky Orchid',
      hex: '9C7989',
      rgb: {
        r: 156,
        g: 121,
        b: 137,
      },
      lab: {
        l: 54.88,
        a: 16.6,
        b: -2.52,
      },
      cmyk: null,
    },
    {
      code: '17-1609 TPG',
      name: 'Mesa Rose',
      hex: 'AA737E',
      rgb: {
        r: 170,
        g: 115,
        b: 126,
      },
      lab: {
        l: 54.92,
        a: 24.72,
        b: 4.09,
      },
      cmyk: null,
    },
    {
      code: '17-1614 TPG',
      name: 'Deco Rose',
      hex: '98606B',
      rgb: {
        r: 152,
        g: 96,
        b: 107,
      },
      lab: {
        l: 47.77,
        a: 25.44,
        b: 4.46,
      },
      cmyk: null,
    },
    {
      code: '17-1522 TPG',
      name: 'Mauvewood',
      hex: 'AD6472',
      rgb: {
        r: 173,
        g: 100,
        b: 114,
      },
      lab: {
        l: 51.63,
        a: 32.96,
        b: 6.29,
      },
      cmyk: null,
    },
    {
      code: '18-1718 TPG',
      name: 'Hawthorn Rose',
      hex: '875666',
      rgb: {
        r: 135,
        g: 86,
        b: 102,
      },
      lab: {
        l: 42.94,
        a: 23.26,
        b: 0.21,
      },
      cmyk: null,
    },
    {
      code: '18-1725 TPG',
      name: 'Dry Rose',
      hex: '925366',
      rgb: {
        r: 146,
        g: 83,
        b: 102,
      },
      lab: {
        l: 43.72,
        a: 30.09,
        b: 1.84,
      },
      cmyk: null,
    },
    {
      code: '18-1619 TPG',
      name: 'Maroon',
      hex: '885561',
      rgb: {
        r: 136,
        g: 85,
        b: 97,
      },
      lab: {
        l: 42.57,
        a: 24.22,
        b: 3.09,
      },
      cmyk: null,
    },
    {
      code: '18-1420 TPG',
      name: 'Wild Ginger',
      hex: '81575D',
      rgb: {
        r: 129,
        g: 87,
        b: 93,
      },
      lab: {
        l: 42.02,
        a: 19.91,
        b: 4.21,
      },
      cmyk: null,
    },
    {
      code: '17-1623 TPG',
      name: 'Rose Wine',
      hex: 'A76277',
      rgb: {
        r: 167,
        g: 98,
        b: 119,
      },
      lab: {
        l: 50.46,
        a: 32.18,
        b: 1.7,
      },
      cmyk: null,
    },
    {
      code: '17-1608 TPG',
      name: 'Heather Rose',
      hex: 'AC7284',
      rgb: {
        r: 172,
        g: 114,
        b: 132,
      },
      lab: {
        l: 54.94,
        a: 26.32,
        b: 0.43,
      },
      cmyk: null,
    },
    {
      code: '17-1723 TPG',
      name: 'Malaga',
      hex: 'A45872',
      rgb: {
        r: 164,
        g: 88,
        b: 114,
      },
      lab: {
        l: 48.02,
        a: 35.78,
        b: 0.83,
      },
      cmyk: null,
    },
    {
      code: '18-2027 TPG',
      name: 'Beaujolais',
      hex: '863D55',
      rgb: {
        r: 134,
        g: 61,
        b: 85,
      },
      lab: {
        l: 36.98,
        a: 35.21,
        b: 2.54,
      },
      cmyk: null,
    },
    {
      code: '19-2030 TPG',
      name: 'Beet Red',
      hex: '83394F',
      rgb: {
        r: 131,
        g: 57,
        b: 79,
      },
      lab: {
        l: 35.63,
        a: 35.94,
        b: 4.51,
      },
      cmyk: null,
    },
    {
      code: '19-2025 TPG',
      name: 'Red Plum',
      hex: '814054',
      rgb: {
        r: 129,
        g: 64,
        b: 84,
      },
      lab: {
        l: 36.66,
        a: 31.97,
        b: 2.33,
      },
      cmyk: null,
    },
    {
      code: '19-2024 TPG',
      name: 'Rhododendron',
      hex: '7A404F',
      rgb: {
        r: 122,
        g: 64,
        b: 79,
      },
      lab: {
        l: 35.26,
        a: 28.34,
        b: 3.64,
      },
      cmyk: null,
    },
    {
      code: '15-1607 TPG',
      name: 'Pale Mauve',
      hex: 'C4A4A3',
      rgb: {
        r: 196,
        g: 164,
        b: 163,
      },
      lab: {
        l: 70.44,
        a: 12.67,
        b: 5.45,
      },
      cmyk: null,
    },
    {
      code: '17-1512 TPG',
      name: 'Nostalgia Rose',
      hex: 'A57D85',
      rgb: {
        r: 165,
        g: 125,
        b: 133,
      },
      lab: {
        l: 56.92,
        a: 17.48,
        b: 2.64,
      },
      cmyk: null,
    },
    {
      code: '17-1511 TPG',
      name: 'Wistful Mauve',
      hex: '97737E',
      rgb: {
        r: 151,
        g: 115,
        b: 126,
      },
      lab: {
        l: 52.54,
        a: 16.4,
        b: 0.19,
      },
      cmyk: null,
    },
    {
      code: '18-1613 TPG',
      name: 'Renaissance Rose',
      hex: '875762',
      rgb: {
        r: 135,
        g: 87,
        b: 98,
      },
      lab: {
        l: 43.01,
        a: 21.98,
        b: 2.75,
      },
      cmyk: null,
    },
    {
      code: '18-1418 TPG',
      name: 'Crushed Berry',
      hex: '815561',
      rgb: {
        r: 129,
        g: 85,
        b: 97,
      },
      lab: {
        l: 41.73,
        a: 20.52,
        b: 1.81,
      },
      cmyk: null,
    },
    {
      code: '18-1616 TPG',
      name: 'Roan Rouge',
      hex: '8A595F',
      rgb: {
        r: 138,
        g: 89,
        b: 95,
      },
      lab: {
        l: 43.85,
        a: 22.02,
        b: 5.84,
      },
      cmyk: null,
    },
    {
      code: '18-1614 TPG',
      name: 'Nocturne',
      hex: '7F5660',
      rgb: {
        r: 127,
        g: 86,
        b: 96,
      },
      lab: {
        l: 41.75,
        a: 19.23,
        b: 2.07,
      },
      cmyk: null,
    },
    {
      code: '15-1905 TPG',
      name: 'Burnished Lilac',
      hex: 'C3AEB3',
      rgb: {
        r: 195,
        g: 174,
        b: 179,
      },
      lab: {
        l: 73.07,
        a: 9.21,
        b: 0.47,
      },
      cmyk: null,
    },
    {
      code: '15-2705 TPG',
      name: 'Keepsake Lilac',
      hex: 'C2A9B3',
      rgb: {
        r: 194,
        g: 169,
        b: 179,
      },
      lab: {
        l: 71.71,
        a: 11.16,
        b: -1.45,
      },
      cmyk: null,
    },
    {
      code: '15-2205 TPG',
      name: 'Dawn Pink',
      hex: 'C1A4B2',
      rgb: {
        r: 193,
        g: 164,
        b: 178,
      },
      lab: {
        l: 70.35,
        a: 13.41,
        b: -3.18,
      },
      cmyk: null,
    },
    {
      code: '16-3205 TPG',
      name: 'Mauve Shadows',
      hex: 'B59BA6',
      rgb: {
        r: 181,
        g: 155,
        b: 166,
      },
      lab: {
        l: 66.81,
        a: 12.05,
        b: -1.77,
      },
      cmyk: null,
    },
    {
      code: '17-1605 TPG',
      name: 'Elderberry',
      hex: '9C8690',
      rgb: {
        r: 156,
        g: 134,
        b: 144,
      },
      lab: {
        l: 58.41,
        a: 10,
        b: -1.99,
      },
      cmyk: null,
    },
    {
      code: '17-2411 TPG',
      name: 'Toadstool',
      hex: '998189',
      rgb: {
        r: 153,
        g: 129,
        b: 137,
      },
      lab: {
        l: 56.61,
        a: 10.73,
        b: -0.25,
      },
      cmyk: null,
    },
    {
      code: '18-2109 TPG',
      name: 'Grape Shake',
      hex: '8A6F79',
      rgb: {
        r: 138,
        g: 111,
        b: 121,
      },
      lab: {
        l: 49.96,
        a: 12.81,
        b: -1.04,
      },
      cmyk: null,
    },
    {
      code: '13-3804 TPG',
      name: 'Gray Lilac',
      hex: 'D8CCCF',
      rgb: {
        r: 216,
        g: 204,
        b: 207,
      },
      lab: {
        l: 83.29,
        a: 4.84,
        b: 0.28,
      },
      cmyk: null,
    },
    {
      code: '15-2706 TPG',
      name: 'Violet Ice',
      hex: 'C1AFB2',
      rgb: {
        r: 193,
        g: 175,
        b: 178,
      },
      lab: {
        l: 73.25,
        a: 7.46,
        b: 1.04,
      },
      cmyk: null,
    },
    {
      code: '16-1707 TPG',
      name: 'Deauville Mauve',
      hex: 'AD9294',
      rgb: {
        r: 173,
        g: 146,
        b: 148,
      },
      lab: {
        l: 63.2,
        a: 11.14,
        b: 2.98,
      },
      cmyk: null,
    },
    {
      code: '16-1806 TPG',
      name: 'Woodrose',
      hex: 'AE9092',
      rgb: {
        r: 174,
        g: 144,
        b: 146,
      },
      lab: {
        l: 62.83,
        a: 12.24,
        b: 3.62,
      },
      cmyk: null,
    },
    {
      code: '18-1807 TPG',
      name: 'Twilight Mauve',
      hex: '8B7377',
      rgb: {
        r: 139,
        g: 115,
        b: 119,
      },
      lab: {
        l: 51.08,
        a: 10.19,
        b: 2.02,
      },
      cmyk: null,
    },
    {
      code: '18-1612 TPG',
      name: 'Rose Taupe',
      hex: '826567',
      rgb: {
        r: 130,
        g: 101,
        b: 103,
      },
      lab: {
        l: 45.93,
        a: 12.76,
        b: 3.98,
      },
      cmyk: null,
    },
    {
      code: '18-1512 TPG',
      name: 'Rose Brown',
      hex: '835F63',
      rgb: {
        r: 131,
        g: 95,
        b: 99,
      },
      lab: {
        l: 44.37,
        a: 16.38,
        b: 4.21,
      },
      cmyk: null,
    },
    {
      code: '14-3803 TPG',
      name: 'Hushed Violet',
      hex: 'D5C3C2',
      rgb: {
        r: 213,
        g: 195,
        b: 194,
      },
      lab: {
        l: 80.41,
        a: 7.08,
        b: 3.03,
      },
      cmyk: null,
    },
    {
      code: '16-1509 TPG',
      name: 'Shadow Gray',
      hex: 'B9A59F',
      rgb: {
        r: 185,
        g: 165,
        b: 159,
      },
      lab: {
        l: 69.45,
        a: 7.19,
        b: 5.91,
      },
      cmyk: null,
    },
    {
      code: '16-1508 TPG',
      name: 'Adobe Rose',
      hex: 'B89C96',
      rgb: {
        r: 184,
        g: 156,
        b: 150,
      },
      lab: {
        l: 67.04,
        a: 10.31,
        b: 7.72,
      },
      cmyk: null,
    },
    {
      code: '16-1510 TPG',
      name: 'Fawn',
      hex: 'B09894',
      rgb: {
        r: 176,
        g: 152,
        b: 148,
      },
      lab: {
        l: 65.05,
        a: 9.01,
        b: 5.99,
      },
      cmyk: null,
    },
    {
      code: '17-1510 TPG',
      name: 'Antler',
      hex: '927A78',
      rgb: {
        r: 146,
        g: 122,
        b: 120,
      },
      lab: {
        l: 53.7,
        a: 9.53,
        b: 5.34,
      },
      cmyk: null,
    },
    {
      code: '18-1415 TPG',
      name: 'Marron',
      hex: '745957',
      rgb: {
        r: 116,
        g: 89,
        b: 87,
      },
      lab: {
        l: 40.77,
        a: 11.61,
        b: 5.7,
      },
      cmyk: null,
    },
    {
      code: '18-1409 TPG',
      name: 'Peppercorn',
      hex: '6F5E5F',
      rgb: {
        r: 111,
        g: 94,
        b: 95,
      },
      lab: {
        l: 41.67,
        a: 7.51,
        b: 2.12,
      },
      cmyk: null,
    },
    {
      code: '13-3803 TPG',
      name: 'Lilac Ash',
      hex: 'D5CCCC',
      rgb: {
        r: 213,
        g: 204,
        b: 204,
      },
      lab: {
        l: 82.82,
        a: 3.21,
        b: 1.05,
      },
      cmyk: null,
    },
    {
      code: '16-1703 TPG',
      name: 'Sphinx',
      hex: 'AB9995',
      rgb: {
        r: 171,
        g: 153,
        b: 149,
      },
      lab: {
        l: 64.82,
        a: 6.75,
        b: 4.67,
      },
      cmyk: null,
    },
    {
      code: '16-1506 TPG',
      name: 'Bark',
      hex: 'A79592',
      rgb: {
        r: 167,
        g: 149,
        b: 146,
      },
      lab: {
        l: 63.29,
        a: 6.85,
        b: 4.37,
      },
      cmyk: null,
    },
    {
      code: '19-1620 TPG',
      name: 'Huckleberry',
      hex: '5F4B51',
      rgb: {
        r: 95,
        g: 75,
        b: 81,
      },
      lab: {
        l: 34.3,
        a: 9.8,
        b: -0.05,
      },
      cmyk: null,
    },
    {
      code: '18-1404 TPG',
      name: 'Sparrow',
      hex: '6E6267',
      rgb: {
        r: 110,
        g: 98,
        b: 103,
      },
      lab: {
        l: 42.82,
        a: 6.25,
        b: -1.14,
      },
      cmyk: null,
    },
    {
      code: '18-1506 TPG',
      name: 'Plum Truffle',
      hex: '6E5F61',
      rgb: {
        r: 110,
        g: 95,
        b: 97,
      },
      lab: {
        l: 42.12,
        a: 7.12,
        b: 1.23,
      },
      cmyk: null,
    },
    {
      code: '19-1606 TPG',
      name: 'Raisin',
      hex: '5D4B52',
      rgb: {
        r: 93,
        g: 75,
        b: 82,
      },
      lab: {
        l: 34.14,
        a: 9.08,
        b: -0.45,
      },
      cmyk: null,
    },
    {
      code: '16-1606 TPG',
      name: 'Purple Dove',
      hex: '96868B',
      rgb: {
        r: 150,
        g: 134,
        b: 139,
      },
      lab: {
        l: 57.68,
        a: 7.28,
        b: 0.15,
      },
      cmyk: null,
    },
    {
      code: '17-1708 TPG',
      name: 'Moonscape',
      hex: '776570',
      rgb: {
        r: 119,
        g: 101,
        b: 112,
      },
      lab: {
        l: 44.86,
        a: 9.98,
        b: -3.49,
      },
      cmyk: null,
    },
    {
      code: '18-1705 TPG',
      name: 'Arctic Dusk',
      hex: '796472',
      rgb: {
        r: 121,
        g: 100,
        b: 114,
      },
      lab: {
        l: 44.84,
        a: 11.18,
        b: -4.58,
      },
      cmyk: null,
    },
    {
      code: '18-1405 TPG',
      name: 'Flint',
      hex: '736069',
      rgb: {
        r: 115,
        g: 96,
        b: 105,
      },
      lab: {
        l: 42.82,
        a: 9.43,
        b: -1.9,
      },
      cmyk: null,
    },
    {
      code: '18-1708 TPG',
      name: 'Ephemera',
      hex: '77636E',
      rgb: {
        r: 119,
        g: 99,
        b: 110,
      },
      lab: {
        l: 44.38,
        a: 10.27,
        b: -2.75,
      },
      cmyk: null,
    },
    {
      code: '18-1706 TPG',
      name: 'Black Plum',
      hex: '6E5F6C',
      rgb: {
        r: 110,
        g: 95,
        b: 108,
      },
      lab: {
        l: 42.1,
        a: 8.59,
        b: -4.91,
      },
      cmyk: null,
    },
    {
      code: '19-3316 TPG',
      name: 'Plum Perfect',
      hex: '524651',
      rgb: {
        r: 82,
        g: 70,
        b: 81,
      },
      lab: {
        l: 31.24,
        a: 7.28,
        b: -4.26,
      },
      cmyk: null,
    },
    {
      code: '18-1411 TPG',
      name: 'Plum Wine',
      hex: '715361',
      rgb: {
        r: 113,
        g: 83,
        b: 97,
      },
      lab: {
        l: 39.17,
        a: 14.78,
        b: -2.21,
      },
      cmyk: null,
    },
    {
      code: '19-2311 TPG',
      name: 'Eggplant',
      hex: '654D57',
      rgb: {
        r: 101,
        g: 77,
        b: 87,
      },
      lab: {
        l: 35.84,
        a: 11.73,
        b: -1.43,
      },
      cmyk: null,
    },
    {
      code: '19-1621 TPG',
      name: 'Catawba Grape',
      hex: '614B51',
      rgb: {
        r: 97,
        g: 75,
        b: 81,
      },
      lab: {
        l: 34.64,
        a: 10.32,
        b: 0.96,
      },
      cmyk: null,
    },
    {
      code: '19-1624 TPG',
      name: 'Sassafras',
      hex: '5C4A50',
      rgb: {
        r: 92,
        g: 74,
        b: 80,
      },
      lab: {
        l: 33.61,
        a: 9.28,
        b: -0.24,
      },
      cmyk: null,
    },
    {
      code: '19-1625 TPG',
      name: 'Decadent Chocolate',
      hex: '5F4649',
      rgb: {
        r: 95,
        g: 70,
        b: 73,
      },
      lab: {
        l: 32.64,
        a: 11.78,
        b: 3.01,
      },
      cmyk: null,
    },
    {
      code: '19-1623 TPG',
      name: 'Vineyard Wine',
      hex: '60474D',
      rgb: {
        r: 96,
        g: 71,
        b: 77,
      },
      lab: {
        l: 33.4,
        a: 12.04,
        b: 1.48,
      },
      cmyk: null,
    },
    {
      code: '19-1619 TPG',
      name: 'Fudge',
      hex: '534549',
      rgb: {
        r: 83,
        g: 69,
        b: 73,
      },
      lab: {
        l: 31.05,
        a: 6.47,
        b: 0.45,
      },
      cmyk: null,
    },
    {
      code: '19-1716 TPG',
      name: 'Mauve Wine',
      hex: '624350',
      rgb: {
        r: 98,
        g: 67,
        b: 80,
      },
      lab: {
        l: 32.56,
        a: 16.1,
        b: -1.69,
      },
      cmyk: null,
    },
    {
      code: '19-1718 TPG',
      name: 'Fig',
      hex: '58424B',
      rgb: {
        r: 88,
        g: 66,
        b: 75,
      },
      lab: {
        l: 30.7,
        a: 11.74,
        b: -1.38,
      },
      cmyk: null,
    },
    {
      code: '19-1627 TPG',
      name: 'Port Royale',
      hex: '5E3E45',
      rgb: {
        r: 94,
        g: 62,
        b: 69,
      },
      lab: {
        l: 30.51,
        a: 15.96,
        b: 2.24,
      },
      cmyk: null,
    },
    {
      code: '19-1617 TPG',
      name: 'Burgundy',
      hex: '6B4650',
      rgb: {
        r: 107,
        g: 70,
        b: 80,
      },
      lab: {
        l: 34.46,
        a: 18.03,
        b: 1.46,
      },
      cmyk: null,
    },
    {
      code: '19-1528 TPG',
      name: 'Windsor Wine',
      hex: '643E46',
      rgb: {
        r: 100,
        g: 62,
        b: 70,
      },
      lab: {
        l: 31.36,
        a: 18.58,
        b: 3.14,
      },
      cmyk: null,
    },
    {
      code: '19-1529 TPG',
      name: 'Maroon Banner',
      hex: '643E47',
      rgb: {
        r: 100,
        g: 62,
        b: 71,
      },
      lab: {
        l: 31.29,
        a: 19.27,
        b: 2.15,
      },
      cmyk: null,
    },
    {
      code: '19-2118 TPG',
      name: 'Winetasting',
      hex: '544249',
      rgb: {
        r: 84,
        g: 66,
        b: 73,
      },
      lab: {
        l: 30.31,
        a: 8.76,
        b: -0.89,
      },
      cmyk: null,
    },
    {
      code: '18-1709 TPG',
      name: 'Tulipwood',
      hex: '865F71',
      rgb: {
        r: 134,
        g: 95,
        b: 113,
      },
      lab: {
        l: 45.18,
        a: 19.24,
        b: -2.91,
      },
      cmyk: null,
    },
    {
      code: '18-1710 TPG',
      name: 'Grape Nectar',
      hex: '8E6679',
      rgb: {
        r: 142,
        g: 102,
        b: 121,
      },
      lab: {
        l: 48.05,
        a: 19.56,
        b: -3.24,
      },
      cmyk: null,
    },
    {
      code: '19-2014 TPG',
      name: 'Prune',
      hex: '654856',
      rgb: {
        r: 101,
        g: 72,
        b: 86,
      },
      lab: {
        l: 34.57,
        a: 14.99,
        b: -2.74,
      },
      cmyk: null,
    },
    {
      code: '19-2315 TPG',
      name: 'Grape Wine',
      hex: '604151',
      rgb: {
        r: 96,
        g: 65,
        b: 81,
      },
      lab: {
        l: 31.89,
        a: 16.1,
        b: -3.28,
      },
      cmyk: null,
    },
    {
      code: '19-1608 TPG',
      name: 'Prune Purple',
      hex: '654D5C',
      rgb: {
        r: 101,
        g: 77,
        b: 92,
      },
      lab: {
        l: 35.81,
        a: 12.68,
        b: -4.42,
      },
      cmyk: null,
    },
    {
      code: '19-2514 TPG',
      name: 'Italian Plum',
      hex: '5A4252',
      rgb: {
        r: 90,
        g: 66,
        b: 82,
      },
      lab: {
        l: 31.31,
        a: 13.17,
        b: -4.77,
      },
      cmyk: null,
    },
    {
      code: '19-2520 TPG',
      name: 'Potent Purple',
      hex: '583C4B',
      rgb: {
        r: 88,
        g: 60,
        b: 75,
      },
      lab: {
        l: 29.27,
        a: 14.96,
        b: -3.17,
      },
      cmyk: null,
    },
    {
      code: '17-1710 TPG',
      name: 'Bordeaux',
      hex: '9B6C86',
      rgb: {
        r: 155,
        g: 108,
        b: 134,
      },
      lab: {
        l: 51.55,
        a: 22.91,
        b: -5.6,
      },
      cmyk: null,
    },
    {
      code: '17-1612 TPG',
      name: 'Mellow Mauve',
      hex: '99687E',
      rgb: {
        r: 153,
        g: 104,
        b: 126,
      },
      lab: {
        l: 50.14,
        a: 23.65,
        b: -2.97,
      },
      cmyk: null,
    },
    {
      code: '17-1818 TPG',
      name: 'Red Violet',
      hex: 'A8627F',
      rgb: {
        r: 168,
        g: 98,
        b: 127,
      },
      lab: {
        l: 50.93,
        a: 32.74,
        b: -2.48,
      },
      cmyk: null,
    },
    {
      code: '18-1720 TPG',
      name: 'Violet Quartz',
      hex: '8D5269',
      rgb: {
        r: 141,
        g: 82,
        b: 105,
      },
      lab: {
        l: 43.01,
        a: 28.5,
        b: -1.37,
      },
      cmyk: null,
    },
    {
      code: '18-1716 TPG',
      name: 'Damson',
      hex: '825568',
      rgb: {
        r: 130,
        g: 85,
        b: 104,
      },
      lab: {
        l: 41.93,
        a: 21.95,
        b: -2.44,
      },
      cmyk: null,
    },
    {
      code: '19-2410 TPG',
      name: 'Amaranth',
      hex: '704158',
      rgb: {
        r: 112,
        g: 65,
        b: 88,
      },
      lab: {
        l: 34.22,
        a: 24.57,
        b: -4.08,
      },
      cmyk: null,
    },
    {
      code: '19-2312 TPG',
      name: 'Crushed Violets',
      hex: '694955',
      rgb: {
        r: 105,
        g: 73,
        b: 85,
      },
      lab: {
        l: 35.09,
        a: 15.97,
        b: -1.31,
      },
      cmyk: null,
    },
    {
      code: '14-3204 TPG',
      name: 'Fragrant Lilac',
      hex: 'CFAEBF',
      rgb: {
        r: 207,
        g: 174,
        b: 191,
      },
      lab: {
        l: 74.59,
        a: 15.12,
        b: -3.84,
      },
      cmyk: null,
    },
    {
      code: '15-3207 TPG',
      name: 'Mauve Mist',
      hex: 'C49EB7',
      rgb: {
        r: 196,
        g: 158,
        b: 183,
      },
      lab: {
        l: 69.48,
        a: 18.24,
        b: -6.99,
      },
      cmyk: null,
    },
    {
      code: '16-2107 TPG',
      name: 'Orchid Haze',
      hex: 'B2889F',
      rgb: {
        r: 178,
        g: 136,
        b: 159,
      },
      lab: {
        l: 61.63,
        a: 19.88,
        b: -4.91,
      },
      cmyk: null,
    },
    {
      code: '18-3011 TPG',
      name: 'Argyle Purple',
      hex: '856079',
      rgb: {
        r: 133,
        g: 96,
        b: 121,
      },
      lab: {
        l: 45.5,
        a: 19.23,
        b: -7.32,
      },
      cmyk: null,
    },
    {
      code: '18-3211 TPG',
      name: 'Grapeade',
      hex: '846B81',
      rgb: {
        r: 132,
        g: 107,
        b: 129,
      },
      lab: {
        l: 48.52,
        a: 13.75,
        b: -7.98,
      },
      cmyk: null,
    },
    {
      code: '18-3012 TPG',
      name: 'Purple Gumdrop',
      hex: '7D5E77',
      rgb: {
        r: 125,
        g: 94,
        b: 119,
      },
      lab: {
        l: 44.14,
        a: 16.48,
        b: -8.2,
      },
      cmyk: null,
    },
    {
      code: '18-3013 TPG',
      name: 'Berry Conserve',
      hex: '75536A',
      rgb: {
        r: 117,
        g: 83,
        b: 106,
      },
      lab: {
        l: 39.83,
        a: 17.96,
        b: -6.81,
      },
      cmyk: null,
    },
    {
      code: '16-3307 TPG',
      name: 'Lavender Mist',
      hex: 'AF91A9',
      rgb: {
        r: 175,
        g: 145,
        b: 169,
      },
      lab: {
        l: 63.83,
        a: 15.25,
        b: -7.76,
      },
      cmyk: null,
    },
    {
      code: '17-3410 TPG',
      name: 'Valerian',
      hex: '9D7994',
      rgb: {
        r: 157,
        g: 121,
        b: 148,
      },
      lab: {
        l: 55.25,
        a: 18.87,
        b: -8.56,
      },
      cmyk: null,
    },
    {
      code: '18-3220 TPG',
      name: 'Very Grape',
      hex: '91738B',
      rgb: {
        r: 145,
        g: 115,
        b: 139,
      },
      lab: {
        l: 52.12,
        a: 15.85,
        b: -8.22,
      },
      cmyk: null,
    },
    {
      code: '18-3418 TPG',
      name: 'Chinese Violet',
      hex: '88678A',
      rgb: {
        r: 136,
        g: 103,
        b: 138,
      },
      lab: {
        l: 48.02,
        a: 18.77,
        b: -13.96,
      },
      cmyk: null,
    },
    {
      code: '18-3218 TPG',
      name: 'Concord Grape',
      hex: '815C83',
      rgb: {
        r: 129,
        g: 92,
        b: 131,
      },
      lab: {
        l: 44.37,
        a: 21.27,
        b: -15.14,
      },
      cmyk: null,
    },
    {
      code: '19-3424 TPG',
      name: 'Sunset Purple',
      hex: '765278',
      rgb: {
        r: 118,
        g: 82,
        b: 120,
      },
      lab: {
        l: 40.13,
        a: 20.85,
        b: -15.17,
      },
      cmyk: null,
    },
    {
      code: '18-3415 TPG',
      name: 'Grape Jam',
      hex: '77617B',
      rgb: {
        r: 119,
        g: 97,
        b: 123,
      },
      lab: {
        l: 44.18,
        a: 13.33,
        b: -10.65,
      },
      cmyk: null,
    },
    {
      code: '18-3522 TPG',
      name: 'Crushed Grape',
      hex: '7E5D86',
      rgb: {
        r: 126,
        g: 93,
        b: 134,
      },
      lab: {
        l: 44.48,
        a: 20,
        b: -17.01,
      },
      cmyk: null,
    },
    {
      code: '19-3325 TPG',
      name: 'Wood Violet',
      hex: '7A5076',
      rgb: {
        r: 122,
        g: 80,
        b: 118,
      },
      lab: {
        l: 40.27,
        a: 23.12,
        b: -13.44,
      },
      cmyk: null,
    },
    {
      code: '19-3223 TPG',
      name: 'Purple Passion',
      hex: '724F6E',
      rgb: {
        r: 114,
        g: 79,
        b: 110,
      },
      lab: {
        l: 38.59,
        a: 19.73,
        b: -11.3,
      },
      cmyk: null,
    },
    {
      code: '19-2524 TPG',
      name: 'Dark Purple',
      hex: '6C3E5B',
      rgb: {
        r: 108,
        g: 62,
        b: 91,
      },
      lab: {
        l: 33.27,
        a: 24.65,
        b: -7.33,
      },
      cmyk: null,
    },
    {
      code: '19-2420 TPG',
      name: 'Pickled Beet',
      hex: '58394B',
      rgb: {
        r: 88,
        g: 57,
        b: 75,
      },
      lab: {
        l: 28.47,
        a: 16.85,
        b: -4.48,
      },
      cmyk: null,
    },
    {
      code: '19-2620 TPG',
      name: 'Winter Bloom',
      hex: '533A4B',
      rgb: {
        r: 83,
        g: 58,
        b: 75,
      },
      lab: {
        l: 28.2,
        a: 14.12,
        b: -5.27,
      },
      cmyk: null,
    },
    {
      code: '19-2816 TPG',
      name: 'Blackberry Wine',
      hex: '574454',
      rgb: {
        r: 87,
        g: 68,
        b: 84,
      },
      lab: {
        l: 31.42,
        a: 11.45,
        b: -6.02,
      },
      cmyk: null,
    },
    {
      code: '14-3206 TPG',
      name: 'Winsome Orchid',
      hex: 'D1B6CC',
      rgb: {
        r: 209,
        g: 182,
        b: 204,
      },
      lab: {
        l: 77.15,
        a: 13.07,
        b: -6.73,
      },
      cmyk: null,
    },
    {
      code: '16-3110 TPG',
      name: 'Smoky Grape',
      hex: 'B78CB0',
      rgb: {
        r: 183,
        g: 140,
        b: 176,
      },
      lab: {
        l: 63.48,
        a: 22.16,
        b: -11.78,
      },
      cmyk: null,
    },
    {
      code: '17-3313 TPG',
      name: 'Dusty Lavender',
      hex: '9D789A',
      rgb: {
        r: 157,
        g: 120,
        b: 154,
      },
      lab: {
        l: 55.13,
        a: 19.86,
        b: -12.44,
      },
      cmyk: null,
    },
    {
      code: '16-3310 TPG',
      name: 'Lavender Herb',
      hex: 'B490AF',
      rgb: {
        r: 180,
        g: 144,
        b: 175,
      },
      lab: {
        l: 64.12,
        a: 18.67,
        b: -10.71,
      },
      cmyk: null,
    },
    {
      code: '17-3520 TPG',
      name: 'Diffused Orchid',
      hex: '9E7FA6',
      rgb: {
        r: 158,
        g: 127,
        b: 166,
      },
      lab: {
        l: 57.29,
        a: 17.95,
        b: -15.74,
      },
      cmyk: null,
    },
    {
      code: '17-3612 TPG',
      name: 'Orchid Mist',
      hex: '917A9A',
      rgb: {
        r: 145,
        g: 122,
        b: 154,
      },
      lab: {
        l: 54.38,
        a: 13.94,
        b: -13.23,
      },
      cmyk: null,
    },
    {
      code: '18-3530 TPG',
      name: 'Lavender Crystal',
      hex: '966E9C',
      rgb: {
        r: 150,
        g: 110,
        b: 156,
      },
      lab: {
        l: 52.19,
        a: 22.46,
        b: -17.49,
      },
      cmyk: null,
    },
    {
      code: '15-3520 TPG',
      name: 'Crocus Petal',
      hex: 'BE9EC8',
      rgb: {
        r: 190,
        g: 158,
        b: 200,
      },
      lab: {
        l: 69.15,
        a: 18.1,
        b: -16.87,
      },
      cmyk: null,
    },
    {
      code: '16-3521 TPG',
      name: 'Lupine',
      hex: 'BEA0C4',
      rgb: {
        r: 190,
        g: 160,
        b: 196,
      },
      lab: {
        l: 69.58,
        a: 16.57,
        b: -13.85,
      },
      cmyk: null,
    },
    {
      code: '16-3617 TPG',
      name: 'Sheer Lilac',
      hex: 'BB97C6',
      rgb: {
        r: 187,
        g: 151,
        b: 198,
      },
      lab: {
        l: 67.23,
        a: 20.58,
        b: -18.61,
      },
      cmyk: null,
    },
    {
      code: '16-3520 TPG',
      name: 'African Violet',
      hex: 'B185BA',
      rgb: {
        r: 177,
        g: 133,
        b: 186,
      },
      lab: {
        l: 61.63,
        a: 24.28,
        b: -20.28,
      },
      cmyk: null,
    },
    {
      code: '17-3617 TPG',
      name: 'English Lavender',
      hex: '9D7EB1',
      rgb: {
        r: 157,
        g: 126,
        b: 177,
      },
      lab: {
        l: 57.41,
        a: 19.63,
        b: -22,
      },
      cmyk: null,
    },
    {
      code: '17-3640 TPG',
      name: 'Fairy Wren',
      hex: '987DB4',
      rgb: {
        r: 152,
        g: 125,
        b: 180,
      },
      lab: {
        l: 56.85,
        a: 18.33,
        b: -24.17,
      },
      cmyk: null,
    },
    {
      code: '18-3628 TPG',
      name: 'Bellflower',
      hex: '966DA5',
      rgb: {
        r: 150,
        g: 109,
        b: 165,
      },
      lab: {
        l: 52.26,
        a: 24.33,
        b: -22.63,
      },
      cmyk: null,
    },
    {
      code: '13-3802 TPG',
      name: 'Orchid Tint',
      hex: 'DCD4DC',
      rgb: {
        r: 220,
        g: 212,
        b: 220,
      },
      lab: {
        l: 85.74,
        a: 4.14,
        b: -2.93,
      },
      cmyk: null,
    },
    {
      code: '13-3820 TPG',
      name: 'Lavender Fog',
      hex: 'D4C7D9',
      rgb: {
        r: 212,
        g: 199,
        b: 217,
      },
      lab: {
        l: 81.75,
        a: 7.37,
        b: -6.99,
      },
      cmyk: null,
    },
    {
      code: '13-3805 TPG',
      name: 'Orchid Hush',
      hex: 'CDC3D3',
      rgb: {
        r: 205,
        g: 195,
        b: 211,
      },
      lab: {
        l: 79.94,
        a: 5.92,
        b: -6.81,
      },
      cmyk: null,
    },
    {
      code: '15-3507 TPG',
      name: 'Lavender Frost',
      hex: 'B9A8BE',
      rgb: {
        r: 185,
        g: 168,
        b: 190,
      },
      lab: {
        l: 70.9,
        a: 10.01,
        b: -8.96,
      },
      cmyk: null,
    },
    {
      code: '15-3508 TPG',
      name: 'Fair Orchid',
      hex: 'C0ABC2',
      rgb: {
        r: 192,
        g: 171,
        b: 194,
      },
      lab: {
        l: 72.54,
        a: 10.93,
        b: -8.5,
      },
      cmyk: null,
    },
    {
      code: '16-3525 TPG',
      name: 'Regal Orchid',
      hex: 'AB8CB0',
      rgb: {
        r: 171,
        g: 140,
        b: 176,
      },
      lab: {
        l: 62.31,
        a: 16.89,
        b: -13.72,
      },
      cmyk: null,
    },
    {
      code: '16-3817 TPG',
      name: 'Rhapsody',
      hex: 'A28AAC',
      rgb: {
        r: 162,
        g: 138,
        b: 172,
      },
      lab: {
        l: 60.78,
        a: 14.32,
        b: -14.18,
      },
      cmyk: null,
    },
    {
      code: '14-3612 TPG',
      name: 'Orchid Bloom',
      hex: 'C4AED0',
      rgb: {
        r: 196,
        g: 174,
        b: 208,
      },
      lab: {
        l: 73.97,
        a: 13.3,
        b: -14.02,
      },
      cmyk: null,
    },
    {
      code: '15-3620 TPG',
      name: 'Lavendula',
      hex: 'BCA1CB',
      rgb: {
        r: 188,
        g: 161,
        b: 203,
      },
      lab: {
        l: 69.78,
        a: 16.62,
        b: -17.75,
      },
      cmyk: null,
    },
    {
      code: '15-3720 TPG',
      name: 'Lilac Breeze',
      hex: 'B6A1CC',
      rgb: {
        r: 182,
        g: 161,
        b: 204,
      },
      lab: {
        l: 69.3,
        a: 14.1,
        b: -19.06,
      },
      cmyk: null,
    },
    {
      code: '15-3716 TPG',
      name: 'Purple Rose',
      hex: 'B2A1CD',
      rgb: {
        r: 178,
        g: 161,
        b: 205,
      },
      lab: {
        l: 69.13,
        a: 12.31,
        b: -19.42,
      },
      cmyk: null,
    },
    {
      code: '16-3815 TPG',
      name: 'Viola',
      hex: 'A894BD',
      rgb: {
        r: 168,
        g: 148,
        b: 189,
      },
      lab: {
        l: 64.22,
        a: 13.99,
        b: -18.31,
      },
      cmyk: null,
    },
    {
      code: '16-3720 TPG',
      name: 'Sand Verbena',
      hex: 'A290C0',
      rgb: {
        r: 162,
        g: 144,
        b: 192,
      },
      lab: {
        l: 62.85,
        a: 14.06,
        b: -22.31,
      },
      cmyk: null,
    },
    {
      code: '17-3735 TPG',
      name: 'Sunlit Allium',
      hex: '9C8BBC',
      rgb: {
        r: 156,
        g: 139,
        b: 188,
      },
      lab: {
        l: 60.78,
        a: 13.63,
        b: -22.68,
      },
      cmyk: null,
    },
    {
      code: '17-3619 TPG',
      name: 'Hyacinth',
      hex: '916EA4',
      rgb: {
        r: 145,
        g: 110,
        b: 164,
      },
      lab: {
        l: 51.66,
        a: 22.18,
        b: -23.2,
      },
      cmyk: null,
    },
    {
      code: '17-3628 TPG',
      name: 'Amethyst Orchid',
      hex: '9369A8',
      rgb: {
        r: 147,
        g: 105,
        b: 168,
      },
      lab: {
        l: 51.07,
        a: 25.82,
        b: -26.6,
      },
      cmyk: null,
    },
    {
      code: '18-3533 TPG',
      name: 'Dewberry',
      hex: '8D5B99',
      rgb: {
        r: 141,
        g: 91,
        b: 153,
      },
      lab: {
        l: 46.62,
        a: 29.32,
        b: -24.64,
      },
      cmyk: null,
    },
    {
      code: '19-3438 TPG',
      name: 'Bright Violet',
      hex: '7E5186',
      rgb: {
        r: 126,
        g: 81,
        b: 134,
      },
      lab: {
        l: 41.65,
        a: 26.43,
        b: -20.97,
      },
      cmyk: null,
    },
    {
      code: '18-3634 TPG',
      name: 'Chive Blossom',
      hex: '7F619B',
      rgb: {
        r: 127,
        g: 97,
        b: 155,
      },
      lab: {
        l: 46.25,
        a: 20.81,
        b: -26.52,
      },
      cmyk: null,
    },
    {
      code: '19-3526 TPG',
      name: 'Meadow Violet',
      hex: '765686',
      rgb: {
        r: 118,
        g: 86,
        b: 134,
      },
      lab: {
        l: 41.68,
        a: 20.98,
        b: -21.29,
      },
      cmyk: null,
    },
    {
      code: '19-3425 TPG',
      name: 'Spiced Plum',
      hex: '735079',
      rgb: {
        r: 115,
        g: 80,
        b: 121,
      },
      lab: {
        l: 39.45,
        a: 20.88,
        b: -16.43,
      },
      cmyk: null,
    },
    {
      code: '18-3633 TPG',
      name: 'Deep Lavender',
      hex: '7A5999',
      rgb: {
        r: 122,
        g: 89,
        b: 153,
      },
      lab: {
        l: 43.67,
        a: 23.34,
        b: -29.43,
      },
      cmyk: null,
    },
    {
      code: '18-3531 TPG',
      name: 'Royal Lilac',
      hex: '7A5591',
      rgb: {
        r: 122,
        g: 85,
        b: 145,
      },
      lab: {
        l: 42.28,
        a: 24.56,
        b: -26.36,
      },
      cmyk: null,
    },
    {
      code: '19-3542 TPG',
      name: 'Pansy',
      hex: '6E4C82',
      rgb: {
        r: 110,
        g: 76,
        b: 130,
      },
      lab: {
        l: 38.16,
        a: 22.13,
        b: -23.73,
      },
      cmyk: null,
    },
    {
      code: '19-3536 TPG',
      name: 'Amaranth Purple',
      hex: '764C83',
      rgb: {
        r: 118,
        g: 76,
        b: 131,
      },
      lab: {
        l: 39.28,
        a: 25.5,
        b: -22.52,
      },
      cmyk: null,
    },
    {
      code: '19-3540 TPG',
      name: 'Purple Magic',
      hex: '6F4774',
      rgb: {
        r: 111,
        g: 71,
        b: 116,
      },
      lab: {
        l: 36.59,
        a: 23.38,
        b: -17.66,
      },
      cmyk: null,
    },
    {
      code: '19-3528 TPG',
      name: 'Imperial Purple',
      hex: '614468',
      rgb: {
        r: 97,
        g: 68,
        b: 104,
      },
      lab: {
        l: 33.51,
        a: 17.91,
        b: -15.38,
      },
      cmyk: null,
    },
    {
      code: '19-3220 TPG',
      name: 'Plum',
      hex: '644564',
      rgb: {
        r: 100,
        g: 69,
        b: 100,
      },
      lab: {
        l: 33.86,
        a: 18.18,
        b: -12.43,
      },
      cmyk: null,
    },
    {
      code: '18-3635 TPG',
      name: 'Picasso Lily',
      hex: '6D5380',
      rgb: {
        r: 109,
        g: 83,
        b: 128,
      },
      lab: {
        l: 39.81,
        a: 17.91,
        b: -20.31,
      },
      cmyk: null,
    },
    {
      code: '18-3520 TPG',
      name: 'Purple Heart',
      hex: '775B8C',
      rgb: {
        r: 119,
        g: 91,
        b: 140,
      },
      lab: {
        l: 43.41,
        a: 19.1,
        b: -21.93,
      },
      cmyk: null,
    },
    {
      code: '18-3615 TPG',
      name: 'Imperial Palace',
      hex: '604E79',
      rgb: {
        r: 96,
        g: 78,
        b: 121,
      },
      lab: {
        l: 36.71,
        a: 14.64,
        b: -21.18,
      },
      cmyk: null,
    },
    {
      code: '18-3518 TPG',
      name: 'Patrician Purple',
      hex: '6F577E',
      rgb: {
        r: 111,
        g: 87,
        b: 126,
      },
      lab: {
        l: 40.87,
        a: 16.32,
        b: -17.88,
      },
      cmyk: null,
    },
    {
      code: '18-3521 TPG',
      name: 'Plum Jam',
      hex: '6B4C7D',
      rgb: {
        r: 107,
        g: 76,
        b: 125,
      },
      lab: {
        l: 37.64,
        a: 20.63,
        b: -21.78,
      },
      cmyk: null,
    },
    {
      code: '18-3540 TPG',
      name: 'Purple Sapphire',
      hex: '76528B',
      rgb: {
        r: 118,
        g: 82,
        b: 139,
      },
      lab: {
        l: 40.94,
        a: 23.76,
        b: -25.13,
      },
      cmyk: null,
    },
    {
      code: '19-3514 TPG',
      name: 'Majesty',
      hex: '614266',
      rgb: {
        r: 97,
        g: 66,
        b: 102,
      },
      lab: {
        l: 32.95,
        a: 19.01,
        b: -14.79,
      },
      cmyk: null,
    },
    {
      code: '19-3518 TPG',
      name: 'Grape Royale',
      hex: '5C4161',
      rgb: {
        r: 92,
        g: 65,
        b: 97,
      },
      lab: {
        l: 31.84,
        a: 16.46,
        b: -13.67,
      },
      cmyk: null,
    },
    {
      code: '19-3632 TPG',
      name: 'Petunia',
      hex: '5B446D',
      rgb: {
        r: 91,
        g: 68,
        b: 109,
      },
      lab: {
        l: 32.9,
        a: 16.01,
        b: -19.34,
      },
      cmyk: null,
    },
    {
      code: '19-3628 TPG',
      name: 'Acai',
      hex: '563C66',
      rgb: {
        r: 86,
        g: 60,
        b: 102,
      },
      lab: {
        l: 30,
        a: 17.92,
        b: -19.23,
      },
      cmyk: null,
    },
    {
      code: '19-3640 TPG',
      name: 'Crown Jewel',
      hex: '543F5C',
      rgb: {
        r: 84,
        g: 63,
        b: 92,
      },
      lab: {
        l: 30.13,
        a: 13.77,
        b: -13.17,
      },
      cmyk: null,
    },
    {
      code: '19-3218 TPG',
      name: 'Plum Purple',
      hex: '594158',
      rgb: {
        r: 89,
        g: 65,
        b: 88,
      },
      lab: {
        l: 31.16,
        a: 13.9,
        b: -8.79,
      },
      cmyk: null,
    },
    {
      code: '18-3410 TPG',
      name: 'Vintage Violet',
      hex: '625162',
      rgb: {
        r: 98,
        g: 81,
        b: 98,
      },
      lab: {
        l: 36.83,
        a: 9.67,
        b: -6.77,
      },
      cmyk: null,
    },
    {
      code: '19-3217 TPG',
      name: 'Shadow Purple',
      hex: '583E54',
      rgb: {
        r: 88,
        g: 62,
        b: 84,
      },
      lab: {
        l: 29.95,
        a: 15.16,
        b: -8.09,
      },
      cmyk: null,
    },
    {
      code: '18-3513 TPG',
      name: 'Grape Compote',
      hex: '72627D',
      rgb: {
        r: 114,
        g: 98,
        b: 125,
      },
      lab: {
        l: 43.91,
        a: 11.12,
        b: -12.31,
      },
      cmyk: null,
    },
    {
      code: '19-2814 TPG',
      name: 'Wineberry',
      hex: '654F6A',
      rgb: {
        r: 101,
        g: 79,
        b: 106,
      },
      lab: {
        l: 36.92,
        a: 13.75,
        b: -11.66,
      },
      cmyk: null,
    },
    {
      code: '19-3323 TPG',
      name: 'Deep Purple',
      hex: '5E475C',
      rgb: {
        r: 94,
        g: 71,
        b: 92,
      },
      lab: {
        l: 33.32,
        a: 13.35,
        b: -7.97,
      },
      cmyk: null,
    },
    {
      code: '19-2009 TPG',
      name: 'Hortensia',
      hex: '604C5E',
      rgb: {
        r: 96,
        g: 76,
        b: 94,
      },
      lab: {
        l: 34.96,
        a: 11.72,
        b: -7.2,
      },
      cmyk: null,
    },
    {
      code: '19-3714 TPG',
      name: 'Navy Cosmos',
      hex: '584A5D',
      rgb: {
        r: 88,
        g: 74,
        b: 93,
      },
      lab: {
        l: 33.52,
        a: 9.59,
        b: -8.94,
      },
      cmyk: null,
    },
    {
      code: '19-3519 TPG',
      name: 'Purple Pennant',
      hex: '524255',
      rgb: {
        r: 82,
        g: 66,
        b: 85,
      },
      lab: {
        l: 30.43,
        a: 9.74,
        b: -8.37,
      },
      cmyk: null,
    },
    {
      code: '19-3520 TPG',
      name: 'Blackberry Cordial',
      hex: '4E3E53',
      rgb: {
        r: 78,
        g: 62,
        b: 83,
      },
      lab: {
        l: 28.67,
        a: 10.97,
        b: -9.68,
      },
      cmyk: null,
    },
    {
      code: '18-3714 TPG',
      name: 'Mulled Grape',
      hex: '625871',
      rgb: {
        r: 98,
        g: 88,
        b: 113,
      },
      lab: {
        l: 39.29,
        a: 8.21,
        b: -12.23,
      },
      cmyk: null,
    },
    {
      code: '18-3715 TPG',
      name: 'Montana Grape',
      hex: '6E6377',
      rgb: {
        r: 110,
        g: 99,
        b: 119,
      },
      lab: {
        l: 43.54,
        a: 7.99,
        b: -9.26,
      },
      cmyk: null,
    },
    {
      code: '18-3620 TPG',
      name: 'Mystical',
      hex: '67577A',
      rgb: {
        r: 103,
        g: 87,
        b: 122,
      },
      lab: {
        l: 39.74,
        a: 13.04,
        b: -17.06,
      },
      cmyk: null,
    },
    {
      code: '19-3622 TPG',
      name: 'Loganberry',
      hex: '61526E',
      rgb: {
        r: 97,
        g: 82,
        b: 110,
      },
      lab: {
        l: 37.29,
        a: 10.97,
        b: -13.71,
      },
      cmyk: null,
    },
    {
      code: '19-3620 TPG',
      name: 'Purple Reign',
      hex: '5C4E71',
      rgb: {
        r: 92,
        g: 78,
        b: 113,
      },
      lab: {
        l: 35.91,
        a: 11.62,
        b: -17.35,
      },
      cmyk: null,
    },
    {
      code: '19-3215 TPG',
      name: 'Indigo',
      hex: '584962',
      rgb: {
        r: 88,
        g: 73,
        b: 98,
      },
      lab: {
        l: 33.54,
        a: 10.4,
        b: -11.54,
      },
      cmyk: null,
    },
    {
      code: '19-3619 TPG',
      name: 'Sweet Grape',
      hex: '564C5C',
      rgb: {
        r: 86,
        g: 76,
        b: 92,
      },
      lab: {
        l: 33.79,
        a: 7.16,
        b: -7.86,
      },
      cmyk: null,
    },
    {
      code: '19-3722 TPG',
      name: 'Mulberry Purple',
      hex: '554A6D',
      rgb: {
        r: 85,
        g: 74,
        b: 109,
      },
      lab: {
        l: 33.85,
        a: 10.07,
        b: -18.04,
      },
      cmyk: null,
    },
    {
      code: '19-3716 TPG',
      name: 'Purple Plumeria',
      hex: '54495F',
      rgb: {
        r: 84,
        g: 73,
        b: 95,
      },
      lab: {
        l: 32.83,
        a: 8.36,
        b: -11.08,
      },
      cmyk: null,
    },
    {
      code: '19-3720 TPG',
      name: 'Gothic Grape',
      hex: '4C3E55',
      rgb: {
        r: 76,
        g: 62,
        b: 85,
      },
      lab: {
        l: 28.66,
        a: 9.58,
        b: -11.12,
      },
      cmyk: null,
    },
    {
      code: '19-3617 TPG',
      name: 'Mysterioso',
      hex: '493F51',
      rgb: {
        r: 73,
        g: 63,
        b: 81,
      },
      lab: {
        l: 28.41,
        a: 7.28,
        b: -8.61,
      },
      cmyk: null,
    },
    {
      code: '19-3712 TPG',
      name: 'Nightshade',
      hex: '4C4551',
      rgb: {
        r: 76,
        g: 69,
        b: 81,
      },
      lab: {
        l: 30.48,
        a: 4.79,
        b: -5.99,
      },
      cmyk: null,
    },
    {
      code: '19-3725 TPG',
      name: 'Purple Velvet',
      hex: '50485B',
      rgb: {
        r: 80,
        g: 72,
        b: 91,
      },
      lab: {
        l: 31.97,
        a: 6.6,
        b: -9.98,
      },
      cmyk: null,
    },
    {
      code: '19-3728 TPG',
      name: 'Grape',
      hex: '554863',
      rgb: {
        r: 85,
        g: 72,
        b: 99,
      },
      lab: {
        l: 32.83,
        a: 10.1,
        b: -13.77,
      },
      cmyk: null,
    },
    {
      code: '18-3834 TPG',
      name: 'Veronica',
      hex: '736C99',
      rgb: {
        r: 115,
        g: 108,
        b: 153,
      },
      lab: {
        l: 47.68,
        a: 10.07,
        b: -23.25,
      },
      cmyk: null,
    },
    {
      code: '18-3840 TPG',
      name: 'Purple Opulence',
      hex: '695FA2',
      rgb: {
        r: 105,
        g: 95,
        b: 162,
      },
      lab: {
        l: 43.74,
        a: 15.68,
        b: -34.51,
      },
      cmyk: null,
    },
    {
      code: '19-3850 TPG',
      name: 'Liberty',
      hex: '57508C',
      rgb: {
        r: 87,
        g: 80,
        b: 140,
      },
      lab: {
        l: 36.96,
        a: 13.55,
        b: -32.28,
      },
      cmyk: null,
    },
    {
      code: '19-3847 TPG',
      name: 'Deep Blue',
      hex: '544B82',
      rgb: {
        r: 84,
        g: 75,
        b: 130,
      },
      lab: {
        l: 34.95,
        a: 13.37,
        b: -29.45,
      },
      cmyk: null,
    },
    {
      code: '19-3737 TPG',
      name: 'Heliotrope',
      hex: '5B4778',
      rgb: {
        r: 91,
        g: 71,
        b: 120,
      },
      lab: {
        l: 33.99,
        a: 16.6,
        b: -24.51,
      },
      cmyk: null,
    },
    {
      code: '19-3750 TPG',
      name: 'Violet Indigo',
      hex: '503F69',
      rgb: {
        r: 80,
        g: 63,
        b: 105,
      },
      lab: {
        l: 30.01,
        a: 14.08,
        b: -21.42,
      },
      cmyk: null,
    },
    {
      code: '19-3731 TPG',
      name: 'Parachute Purple',
      hex: '504363',
      rgb: {
        r: 80,
        g: 67,
        b: 99,
      },
      lab: {
        l: 31.05,
        a: 10.75,
        b: -16.32,
      },
      cmyk: null,
    },
    {
      code: '17-3834 TPG',
      name: 'Dahlia Purple',
      hex: '8272AD',
      rgb: {
        r: 130,
        g: 114,
        b: 173,
      },
      lab: {
        l: 51.49,
        a: 15.72,
        b: -29.03,
      },
      cmyk: null,
    },
    {
      code: '18-3737 TPG',
      name: 'Passion Flower',
      hex: '715C99',
      rgb: {
        r: 113,
        g: 92,
        b: 153,
      },
      lab: {
        l: 43.4,
        a: 18.62,
        b: -29.82,
      },
      cmyk: null,
    },
    {
      code: '18-3839 TPG',
      name: 'Purple Corallites',
      hex: '625593',
      rgb: {
        r: 98,
        g: 85,
        b: 147,
      },
      lab: {
        l: 39.68,
        a: 16.19,
        b: -32.2,
      },
      cmyk: null,
    },
    {
      code: '18-3838 TPG',
      name: 'Ultra Violet',
      hex: '6B5B95',
      rgb: {
        r: 107,
        g: 91,
        b: 149,
      },
      lab: {
        l: 42.34,
        a: 16.14,
        b: -29.2,
      },
      cmyk: null,
    },
    {
      code: '19-3748 TPG',
      name: 'Prism Violet',
      hex: '615089',
      rgb: {
        r: 97,
        g: 80,
        b: 137,
      },
      lab: {
        l: 37.84,
        a: 16.46,
        b: -29.02,
      },
      cmyk: null,
    },
    {
      code: '19-3642 TPG',
      name: 'Royal Purple',
      hex: '634F82',
      rgb: {
        r: 99,
        g: 79,
        b: 130,
      },
      lab: {
        l: 37.55,
        a: 16.66,
        b: -25,
      },
      cmyk: null,
    },
    {
      code: '19-3638 TPG',
      name: 'Tillandsia Purple',
      hex: '64437E',
      rgb: {
        r: 100,
        g: 67,
        b: 126,
      },
      lab: {
        l: 34.58,
        a: 23.07,
        b: -27.16,
      },
      cmyk: null,
    },
    {
      code: '14-3812 TPG',
      name: 'Pastel Lilac',
      hex: 'BEB1D2',
      rgb: {
        r: 190,
        g: 177,
        b: 210,
      },
      lab: {
        l: 74.35,
        a: 9.59,
        b: -14.52,
      },
      cmyk: null,
    },
    {
      code: '15-3817 TPG',
      name: 'Lavender',
      hex: 'B2A4D0',
      rgb: {
        r: 178,
        g: 164,
        b: 208,
      },
      lab: {
        l: 69.74,
        a: 12.01,
        b: -20.29,
      },
      cmyk: null,
    },
    {
      code: '16-3823 TPG',
      name: 'Violet Tulip',
      hex: '9C91C4',
      rgb: {
        r: 156,
        g: 145,
        b: 196,
      },
      lab: {
        l: 62.55,
        a: 12.47,
        b: -25.12,
      },
      cmyk: null,
    },
    {
      code: '17-3725 TPG',
      name: 'Bougainvillea',
      hex: '9D86BD',
      rgb: {
        r: 157,
        g: 134,
        b: 189,
      },
      lab: {
        l: 59.83,
        a: 17.05,
        b: -24.98,
      },
      cmyk: null,
    },
    {
      code: '17-3730 TPG',
      name: 'Paisley Purple',
      hex: '8B7AB0',
      rgb: {
        r: 139,
        g: 122,
        b: 176,
      },
      lab: {
        l: 54.47,
        a: 15.26,
        b: -26.1,
      },
      cmyk: null,
    },
    {
      code: '17-3826 TPG',
      name: 'Aster Purple',
      hex: '8278A9',
      rgb: {
        r: 130,
        g: 120,
        b: 169,
      },
      lab: {
        l: 52.84,
        a: 11.76,
        b: -24.36,
      },
      cmyk: null,
    },
    {
      code: '19-3730 TPG',
      name: 'Gentian Violet',
      hex: '605282',
      rgb: {
        r: 96,
        g: 82,
        b: 130,
      },
      lab: {
        l: 38.02,
        a: 14.1,
        b: -24.76,
      },
      cmyk: null,
    },
    {
      code: '14-3710 TPG',
      name: 'Orchid Petal',
      hex: 'BDB1CB',
      rgb: {
        r: 189,
        g: 177,
        b: 203,
      },
      lab: {
        l: 74.01,
        a: 8.41,
        b: -11.56,
      },
      cmyk: null,
    },
    {
      code: '16-3810 TPG',
      name: 'Wisteria',
      hex: 'A19AB3',
      rgb: {
        r: 161,
        g: 154,
        b: 179,
      },
      lab: {
        l: 64.84,
        a: 6.49,
        b: -11.96,
      },
      cmyk: null,
    },
    {
      code: '16-3812 TPG',
      name: 'Heirloom Lilac',
      hex: '9993B1',
      rgb: {
        r: 153,
        g: 147,
        b: 177,
      },
      lab: {
        l: 62.39,
        a: 6.92,
        b: -14.68,
      },
      cmyk: null,
    },
    {
      code: '17-3817 TPG',
      name: 'Daybreak',
      hex: '8A83A3',
      rgb: {
        r: 138,
        g: 131,
        b: 163,
      },
      lab: {
        l: 56.27,
        a: 7.9,
        b: -16.16,
      },
      cmyk: null,
    },
    {
      code: '17-3812 TPG',
      name: 'Dusk',
      hex: '8B839D',
      rgb: {
        r: 139,
        g: 131,
        b: 157,
      },
      lab: {
        l: 56.19,
        a: 7.01,
        b: -12.45,
      },
      cmyk: null,
    },
    {
      code: '17-3615 TPG',
      name: 'Chalk Violet',
      hex: '8E7CA5',
      rgb: {
        r: 142,
        g: 124,
        b: 165,
      },
      lab: {
        l: 54.87,
        a: 13.31,
        b: -19.17,
      },
      cmyk: null,
    },
    {
      code: '18-3718 TPG',
      name: 'Purple Haze',
      hex: '807398',
      rgb: {
        r: 128,
        g: 115,
        b: 152,
      },
      lab: {
        l: 50.74,
        a: 10.81,
        b: -18,
      },
      cmyk: null,
    },
    {
      code: '15-3807 TPG',
      name: 'Misty Lilac',
      hex: 'BBB6C6',
      rgb: {
        r: 187,
        g: 182,
        b: 198,
      },
      lab: {
        l: 74.92,
        a: 3.89,
        b: -7.2,
      },
      cmyk: null,
    },
    {
      code: '14-3907 TPG',
      name: 'Thistle',
      hex: 'BCB7CA',
      rgb: {
        r: 188,
        g: 183,
        b: 202,
      },
      lab: {
        l: 75.41,
        a: 4.29,
        b: -8.64,
      },
      cmyk: null,
    },
    {
      code: '14-3904 TPG',
      name: 'Evening Haze',
      hex: 'BCB8C8',
      rgb: {
        r: 188,
        g: 184,
        b: 200,
      },
      lab: {
        l: 75.62,
        a: 3.65,
        b: -7.44,
      },
      cmyk: null,
    },
    {
      code: '17-3910 TPG',
      name: 'Lavender Gray',
      hex: '9690A3',
      rgb: {
        r: 150,
        g: 144,
        b: 163,
      },
      lab: {
        l: 60.83,
        a: 4.89,
        b: -8.95,
      },
      cmyk: null,
    },
    {
      code: '17-3810 TPG',
      name: 'Purple Ash',
      hex: '8E8698',
      rgb: {
        r: 142,
        g: 134,
        b: 152,
      },
      lab: {
        l: 56.99,
        a: 5.87,
        b: -8.61,
      },
      cmyk: null,
    },
    {
      code: '18-3710 TPG',
      name: 'Gray Ridge',
      hex: '807785',
      rgb: {
        r: 128,
        g: 119,
        b: 133,
      },
      lab: {
        l: 51.18,
        a: 5.6,
        b: -6.3,
      },
      cmyk: null,
    },
    {
      code: '18-3712 TPG',
      name: 'Purple Sage',
      hex: '756C7F',
      rgb: {
        r: 117,
        g: 108,
        b: 127,
      },
      lab: {
        l: 47.01,
        a: 6.69,
        b: -9.26,
      },
      cmyk: null,
    },
    {
      code: '14-3903 TPG',
      name: 'Lilac Marble',
      hex: 'C0B9C2',
      rgb: {
        r: 192,
        g: 185,
        b: 194,
      },
      lab: {
        l: 75.96,
        a: 3.74,
        b: -3.48,
      },
      cmyk: null,
    },
    {
      code: '15-3802 TPG',
      name: 'Cloud Gray',
      hex: 'B5A8AC',
      rgb: {
        r: 181,
        g: 168,
        b: 172,
      },
      lab: {
        l: 70.17,
        a: 5.35,
        b: -0.34,
      },
      cmyk: null,
    },
    {
      code: '14-3906 TPG',
      name: 'Raindrops',
      hex: 'B4ACB3',
      rgb: {
        r: 180,
        g: 172,
        b: 179,
      },
      lab: {
        l: 71.37,
        a: 3.89,
        b: -2.25,
      },
      cmyk: null,
    },
    {
      code: '14-3805 TPG',
      name: 'Iris',
      hex: 'BCB2BE',
      rgb: {
        r: 188,
        g: 178,
        b: 190,
      },
      lab: {
        l: 73.62,
        a: 5.74,
        b: -4.94,
      },
      cmyk: null,
    },
    {
      code: '17-3808 TPG',
      name: 'Nirvana',
      hex: '9C8D9A',
      rgb: {
        r: 156,
        g: 141,
        b: 154,
      },
      lab: {
        l: 60.47,
        a: 7.4,
        b: -4.43,
      },
      cmyk: null,
    },
    {
      code: '16-3304 TPG',
      name: 'Sea Fog',
      hex: 'A994A0',
      rgb: {
        r: 169,
        g: 148,
        b: 160,
      },
      lab: {
        l: 63.73,
        a: 9.88,
        b: -2.85,
      },
      cmyk: null,
    },
    {
      code: '17-1505 TPG',
      name: 'Quail',
      hex: '98878F',
      rgb: {
        r: 152,
        g: 135,
        b: 143,
      },
      lab: {
        l: 58.23,
        a: 7.75,
        b: -1.28,
      },
      cmyk: null,
    },
    {
      code: '13-4105 TPG',
      name: 'Lilac Hint',
      hex: 'D1D0D8',
      rgb: {
        r: 209,
        g: 208,
        b: 216,
      },
      lab: {
        l: 83.8,
        a: 1.41,
        b: -3.77,
      },
      cmyk: null,
    },
    {
      code: '14-3905 TPG',
      name: 'Lavender Blue',
      hex: 'C0BDD1',
      rgb: {
        r: 192,
        g: 189,
        b: 209,
      },
      lab: {
        l: 77.41,
        a: 3.65,
        b: -9.35,
      },
      cmyk: null,
    },
    {
      code: '16-3911 TPG',
      name: 'Lavender Aura',
      hex: '9F99AC',
      rgb: {
        r: 159,
        g: 153,
        b: 172,
      },
      lab: {
        l: 64.22,
        a: 4.86,
        b: -8.82,
      },
      cmyk: null,
    },
    {
      code: '16-3905 TPG',
      name: 'Lilac Gray',
      hex: '9E9CA9',
      rgb: {
        r: 158,
        g: 156,
        b: 169,
      },
      lab: {
        l: 64.93,
        a: 2.67,
        b: -6.46,
      },
      cmyk: null,
    },
    {
      code: '16-3907 TPG',
      name: 'Dapple Gray',
      hex: '9C9CA9',
      rgb: {
        r: 156,
        g: 156,
        b: 169,
      },
      lab: {
        l: 64.67,
        a: 2.04,
        b: -6.57,
      },
      cmyk: null,
    },
    {
      code: '17-3906 TPG',
      name: 'Minimal Gray',
      hex: '928E9B',
      rgb: {
        r: 146,
        g: 142,
        b: 155,
      },
      lab: {
        l: 59.59,
        a: 3.39,
        b: -6.37,
      },
      cmyk: null,
    },
    {
      code: '18-1703 TPG',
      name: 'Shark',
      hex: '6D6771',
      rgb: {
        r: 109,
        g: 103,
        b: 113,
      },
      lab: {
        l: 44.48,
        a: 3.95,
        b: -4.4,
      },
      cmyk: null,
    },
    {
      code: '15-3910 TPG',
      name: 'Languid Lavender',
      hex: 'A3A3BC',
      rgb: {
        r: 163,
        g: 163,
        b: 188,
      },
      lab: {
        l: 67.59,
        a: 3.59,
        b: -12.9,
      },
      cmyk: null,
    },
    {
      code: '15-3908 TPG',
      name: 'Icelandic Blue',
      hex: 'B0B3C7',
      rgb: {
        r: 176,
        g: 179,
        b: 199,
      },
      lab: {
        l: 73.24,
        a: 1.76,
        b: -10.25,
      },
      cmyk: null,
    },
    {
      code: '15-3912 TPG',
      name: 'Aleutian',
      hex: '9CA0B5',
      rgb: {
        r: 156,
        g: 160,
        b: 181,
      },
      lab: {
        l: 65.91,
        a: 2.13,
        b: -11.62,
      },
      cmyk: null,
    },
    {
      code: '17-3933 TPG',
      name: 'Silver Bullet',
      hex: '86879E',
      rgb: {
        r: 134,
        g: 135,
        b: 158,
      },
      lab: {
        l: 56.89,
        a: 3.89,
        b: -12.51,
      },
      cmyk: null,
    },
    {
      code: '17-3907 TPG',
      name: 'Quicksilver',
      hex: '83838F',
      rgb: {
        r: 131,
        g: 131,
        b: 143,
      },
      lab: {
        l: 55.08,
        a: 2.19,
        b: -6.64,
      },
      cmyk: null,
    },
    {
      code: '18-3933 TPG',
      name: 'Blue Granite',
      hex: '77798E',
      rgb: {
        r: 119,
        g: 121,
        b: 142,
      },
      lab: {
        l: 51.3,
        a: 2.97,
        b: -11.49,
      },
      cmyk: null,
    },
    {
      code: '18-3812 TPG',
      name: 'Cadet',
      hex: '6C677D',
      rgb: {
        r: 108,
        g: 103,
        b: 125,
      },
      lab: {
        l: 44.63,
        a: 5.88,
        b: -11.44,
      },
      cmyk: null,
    },
    {
      code: '14-3911 TPG',
      name: 'Purple Heather',
      hex: 'B9B7D4',
      rgb: {
        r: 185,
        g: 183,
        b: 212,
      },
      lab: {
        l: 75.32,
        a: 5.01,
        b: -14.13,
      },
      cmyk: null,
    },
    {
      code: '15-3909 TPG',
      name: 'Cosmic Sky',
      hex: 'A5A8C4',
      rgb: {
        r: 165,
        g: 168,
        b: 196,
      },
      lab: {
        l: 69.43,
        a: 2.99,
        b: -14.46,
      },
      cmyk: null,
    },
    {
      code: '16-3930 TPG',
      name: 'Thistle Down',
      hex: '959ABF',
      rgb: {
        r: 149,
        g: 154,
        b: 191,
      },
      lab: {
        l: 64.18,
        a: 3.98,
        b: -20.1,
      },
      cmyk: null,
    },
    {
      code: '17-3925 TPG',
      name: 'Persian Violet',
      hex: '8B8DB2',
      rgb: {
        r: 139,
        g: 141,
        b: 178,
      },
      lab: {
        l: 59.52,
        a: 5.15,
        b: -19.78,
      },
      cmyk: null,
    },
    {
      code: '18-3820 TPG',
      name: 'Twilight Purple',
      hex: '6A698F',
      rgb: {
        r: 106,
        g: 105,
        b: 143,
      },
      lab: {
        l: 45.64,
        a: 6.68,
        b: -20.76,
      },
      cmyk: null,
    },
    {
      code: '18-3817 TPG',
      name: 'Heron',
      hex: '686885',
      rgb: {
        r: 104,
        g: 104,
        b: 133,
      },
      lab: {
        l: 44.96,
        a: 4.47,
        b: -15.57,
      },
      cmyk: null,
    },
    {
      code: '19-3842 TPG',
      name: 'Deep Wisteria',
      hex: '53507A',
      rgb: {
        r: 83,
        g: 80,
        b: 122,
      },
      lab: {
        l: 35.65,
        a: 9.12,
        b: -23.56,
      },
      cmyk: null,
    },
    {
      code: '16-3931 TPG',
      name: 'Sweet Lavender',
      hex: '9C9CC4',
      rgb: {
        r: 156,
        g: 156,
        b: 196,
      },
      lab: {
        l: 65.43,
        a: 6.21,
        b: -20.67,
      },
      cmyk: null,
    },
    {
      code: '17-3924 TPG',
      name: 'Lavender Violet',
      hex: '7880A9',
      rgb: {
        r: 120,
        g: 128,
        b: 169,
      },
      lab: {
        l: 54.21,
        a: 3.87,
        b: -22.78,
      },
      cmyk: null,
    },
    {
      code: '18-3930 TPG',
      name: 'Bleached Denim',
      hex: '6D76A1',
      rgb: {
        r: 109,
        g: 118,
        b: 161,
      },
      lab: {
        l: 50.21,
        a: 3.67,
        b: -24.28,
      },
      cmyk: null,
    },
    {
      code: '18-3833 TPG',
      name: 'Dusted Peri',
      hex: '696A9E',
      rgb: {
        r: 105,
        g: 106,
        b: 158,
      },
      lab: {
        l: 46.35,
        a: 8.73,
        b: -28.58,
      },
      cmyk: null,
    },
    {
      code: '18-3828 TPG',
      name: 'Corsican Blue',
      hex: '696797',
      rgb: {
        r: 105,
        g: 103,
        b: 151,
      },
      lab: {
        l: 45.23,
        a: 9.41,
        b: -26.15,
      },
      cmyk: null,
    },
    {
      code: '19-3947 TPG',
      name: 'Orient Blue',
      hex: '555282',
      rgb: {
        r: 85,
        g: 82,
        b: 130,
      },
      lab: {
        l: 36.95,
        a: 9.59,
        b: -26.25,
      },
      cmyk: null,
    },
    {
      code: '18-3963 TPG',
      name: 'Spectrum Blue',
      hex: '494B84',
      rgb: {
        r: 73,
        g: 75,
        b: 132,
      },
      lab: {
        l: 33.88,
        a: 10.14,
        b: -32.01,
      },
      cmyk: null,
    },
    {
      code: '17-3929 TPG',
      name: 'Pale Iris',
      hex: '8B96C8',
      rgb: {
        r: 139,
        g: 150,
        b: 200,
      },
      lab: {
        l: 62.69,
        a: 4.46,
        b: -27.41,
      },
      cmyk: null,
    },
    {
      code: '17-3932 TPG',
      name: 'Deep Periwinkle',
      hex: '7D84BD',
      rgb: {
        r: 125,
        g: 132,
        b: 189,
      },
      lab: {
        l: 56.32,
        a: 6.8,
        b: -30.56,
      },
      cmyk: null,
    },
    {
      code: '17-3940 TPG',
      name: 'Iolite',
      hex: '757EB9',
      rgb: {
        r: 117,
        g: 126,
        b: 185,
      },
      lab: {
        l: 53.7,
        a: 7.28,
        b: -32.35,
      },
      cmyk: null,
    },
    {
      code: '18-3944 TPG',
      name: 'Violet Storm',
      hex: '5F65A2',
      rgb: {
        r: 95,
        g: 101,
        b: 162,
      },
      lab: {
        l: 44.44,
        a: 8.83,
        b: -34.04,
      },
      cmyk: null,
    },
    {
      code: '18-3950 TPG',
      name: 'Iris Bloom',
      hex: '6266A3',
      rgb: {
        r: 98,
        g: 102,
        b: 163,
      },
      lab: {
        l: 44.92,
        a: 9.75,
        b: -33.43,
      },
      cmyk: null,
    },
    {
      code: '18-3943 TPG',
      name: 'Blue Iris',
      hex: '6364A5',
      rgb: {
        r: 99,
        g: 100,
        b: 165,
      },
      lab: {
        l: 44.53,
        a: 11.87,
        b: -35.58,
      },
      cmyk: null,
    },
    {
      code: '19-3832 TPG',
      name: 'Navy Blue',
      hex: '4B4C76',
      rgb: {
        r: 75,
        g: 76,
        b: 118,
      },
      lab: {
        l: 33.77,
        a: 7.27,
        b: -23.96,
      },
      cmyk: null,
    },
    {
      code: '16-3925 TPG',
      name: 'Easter Egg',
      hex: '9199CC',
      rgb: {
        r: 145,
        g: 153,
        b: 204,
      },
      lab: {
        l: 64.01,
        a: 5.11,
        b: -27.13,
      },
      cmyk: null,
    },
    {
      code: '17-3930 TPG',
      name: 'Jacaranda',
      hex: '888FC7',
      rgb: {
        r: 136,
        g: 143,
        b: 199,
      },
      lab: {
        l: 60.5,
        a: 6.49,
        b: -29.71,
      },
      cmyk: null,
    },
    {
      code: '17-3934 TPG',
      name: 'Persian Jewel',
      hex: '6C80BE',
      rgb: {
        r: 108,
        g: 128,
        b: 190,
      },
      lab: {
        l: 53.88,
        a: 4.3,
        b: -35.23,
      },
      cmyk: null,
    },
    {
      code: '18-3935 TPG',
      name: 'Wedgewood',
      hex: '6A7CB5',
      rgb: {
        r: 106,
        g: 124,
        b: 181,
      },
      lab: {
        l: 52.33,
        a: 3.62,
        b: -32.78,
      },
      cmyk: null,
    },
    {
      code: '18-3946 TPG',
      name: 'Baja Blue',
      hex: '5A69AF',
      rgb: {
        r: 90,
        g: 105,
        b: 175,
      },
      lab: {
        l: 45.6,
        a: 8.01,
        b: -39.52,
      },
      cmyk: null,
    },
    {
      code: '19-3955 TPG',
      name: 'Royal Blue',
      hex: '4D4E8D',
      rgb: {
        r: 77,
        g: 78,
        b: 141,
      },
      lab: {
        l: 35.48,
        a: 11.96,
        b: -35.66,
      },
      cmyk: null,
    },
    {
      code: '19-3951 TPG',
      name: 'Clematis Blue',
      hex: '474981',
      rgb: {
        r: 71,
        g: 73,
        b: 129,
      },
      lab: {
        l: 32.94,
        a: 10.28,
        b: -32.01,
      },
      cmyk: null,
    },
    {
      code: '17-4037 TPG',
      name: 'Ultramarine',
      hex: '5F7DBE',
      rgb: {
        r: 95,
        g: 125,
        b: 190,
      },
      lab: {
        l: 52.23,
        a: 2.01,
        b: -37.88,
      },
      cmyk: null,
    },
    {
      code: '18-3945 TPG',
      name: 'Amparo Blue',
      hex: '5065AC',
      rgb: {
        r: 80,
        g: 101,
        b: 172,
      },
      lab: {
        l: 43.54,
        a: 7.15,
        b: -41.27,
      },
      cmyk: null,
    },
    {
      code: '18-3949 TPG',
      name: 'Dazzling Blue',
      hex: '3E55AB',
      rgb: {
        r: 62,
        g: 85,
        b: 171,
      },
      lab: {
        l: 37.89,
        a: 11.49,
        b: -49.45,
      },
      cmyk: null,
    },
    {
      code: '19-3950 TPG',
      name: 'Deep Ultramarine',
      hex: '485489',
      rgb: {
        r: 72,
        g: 84,
        b: 137,
      },
      lab: {
        l: 36.44,
        a: 5.96,
        b: -31.48,
      },
      cmyk: null,
    },
    {
      code: '19-3954 TPG',
      name: 'Bluing',
      hex: '3F488E',
      rgb: {
        r: 63,
        g: 72,
        b: 142,
      },
      lab: {
        l: 32.64,
        a: 11.58,
        b: -40.75,
      },
      cmyk: null,
    },
    {
      code: '19-3952 TPG',
      name: 'Surf the Web',
      hex: '3E4989',
      rgb: {
        r: 62,
        g: 73,
        b: 137,
      },
      lab: {
        l: 32.6,
        a: 9.73,
        b: -37.88,
      },
      cmyk: null,
    },
    {
      code: '19-3864 TPG',
      name: 'Mazarine Blue',
      hex: '414A7E',
      rgb: {
        r: 65,
        g: 74,
        b: 126,
      },
      lab: {
        l: 32.64,
        a: 6.94,
        b: -30.83,
      },
      cmyk: null,
    },
    {
      code: '19-3964 TPG',
      name: 'Blue Quartz',
      hex: '3A5081',
      rgb: {
        r: 58,
        g: 80,
        b: 129,
      },
      lab: {
        l: 33.94,
        a: 2.6,
        b: -30.76,
      },
      cmyk: null,
    },
    {
      code: '19-4055 TPG',
      name: 'Galaxy Blue',
      hex: '415786',
      rgb: {
        r: 65,
        g: 87,
        b: 134,
      },
      lab: {
        l: 36.65,
        a: 1.78,
        b: -29.58,
      },
      cmyk: null,
    },
    {
      code: '19-4041 TPG',
      name: 'Déja Vu Blue',
      hex: '3C5B8A',
      rgb: {
        r: 60,
        g: 91,
        b: 138,
      },
      lab: {
        l: 37.59,
        a: -1,
        b: -30.37,
      },
      cmyk: null,
    },
    {
      code: '19-4057 TPG',
      name: 'True Blue',
      hex: '395181',
      rgb: {
        r: 57,
        g: 81,
        b: 129,
      },
      lab: {
        l: 34.07,
        a: 1.8,
        b: -30.65,
      },
      cmyk: null,
    },
    {
      code: '19-4044 TPG',
      name: 'Limoges',
      hex: '3D4E7A',
      rgb: {
        r: 61,
        g: 78,
        b: 122,
      },
      lab: {
        l: 33.09,
        a: 2.76,
        b: -27.76,
      },
      cmyk: null,
    },
    {
      code: '19-4029 TPG',
      name: 'Navy Peony',
      hex: '394867',
      rgb: {
        r: 57,
        g: 72,
        b: 103,
      },
      lab: {
        l: 30.22,
        a: 0.66,
        b: -20.72,
      },
      cmyk: null,
    },
    {
      code: '19-3953 TPG',
      name: 'Sodalite Blue',
      hex: '384478',
      rgb: {
        r: 56,
        g: 68,
        b: 120,
      },
      lab: {
        l: 29.81,
        a: 6.69,
        b: -31.72,
      },
      cmyk: null,
    },
    {
      code: '19-4045 TPG',
      name: 'Lapis Blue',
      hex: '2D5593',
      rgb: {
        r: 45,
        g: 85,
        b: 147,
      },
      lab: {
        l: 35.48,
        a: 2.03,
        b: -39.79,
      },
      cmyk: null,
    },
    {
      code: '19-4150 TPG',
      name: 'Princess Blue',
      hex: '2E5D9F',
      rgb: {
        r: 46,
        g: 93,
        b: 159,
      },
      lab: {
        l: 38.57,
        a: 0.66,
        b: -41.7,
      },
      cmyk: null,
    },
    {
      code: '18-4148 TPG',
      name: 'Victoria Blue',
      hex: '3263A4',
      rgb: {
        r: 50,
        g: 99,
        b: 164,
      },
      lab: {
        l: 40.92,
        a: -0.75,
        b: -40.98,
      },
      cmyk: null,
    },
    {
      code: '19-4049 TPG',
      name: 'Snorkel Blue',
      hex: '1A548F',
      rgb: {
        r: 26,
        g: 84,
        b: 143,
      },
      lab: {
        l: 34.1,
        a: -1.11,
        b: -39.62,
      },
      cmyk: null,
    },
    {
      code: '19-4050 TPG',
      name: 'Nautical Blue',
      hex: '315592',
      rgb: {
        r: 49,
        g: 85,
        b: 146,
      },
      lab: {
        l: 35.63,
        a: 2.74,
        b: -39.06,
      },
      cmyk: null,
    },
    {
      code: '19-4053 TPG',
      name: 'Turkish Sea',
      hex: '375998',
      rgb: {
        r: 55,
        g: 89,
        b: 152,
      },
      lab: {
        l: 37.62,
        a: 2.33,
        b: -38.95,
      },
      cmyk: null,
    },
    {
      code: '19-4056 TPG',
      name: 'Blue Lolite',
      hex: '3D5C98',
      rgb: {
        r: 61,
        g: 92,
        b: 152,
      },
      lab: {
        l: 38.78,
        a: 2.29,
        b: -36.87,
      },
      cmyk: null,
    },
    {
      code: '18-4039 TPG',
      name: 'Regatta',
      hex: '547EB9',
      rgb: {
        r: 84,
        g: 126,
        b: 185,
      },
      lab: {
        l: 51.35,
        a: -2.35,
        b: -36.57,
      },
      cmyk: null,
    },
    {
      code: '18-4043 TPG',
      name: 'Palace Blue',
      hex: '4871B3',
      rgb: {
        r: 72,
        g: 113,
        b: 179,
      },
      lab: {
        l: 46.84,
        a: 0.06,
        b: -40.03,
      },
      cmyk: null,
    },
    {
      code: '18-4143 TPG',
      name: 'Super Sonic',
      hex: '4178BC',
      rgb: {
        r: 65,
        g: 120,
        b: 188,
      },
      lab: {
        l: 48.84,
        a: -2.51,
        b: -42.27,
      },
      cmyk: null,
    },
    {
      code: '18-4051 TPG',
      name: 'Strong Blue',
      hex: '3B64A7',
      rgb: {
        r: 59,
        g: 100,
        b: 167,
      },
      lab: {
        l: 41.81,
        a: 1.15,
        b: -41.11,
      },
      cmyk: null,
    },
    {
      code: '18-4048 TPG',
      name: 'Nebulas Blue',
      hex: '3F69AA',
      rgb: {
        r: 63,
        g: 105,
        b: 170,
      },
      lab: {
        l: 43.51,
        a: 0.63,
        b: -40.6,
      },
      cmyk: null,
    },
    {
      code: '19-4058 TPG',
      name: 'Beaucoup Blue',
      hex: '415997',
      rgb: {
        r: 65,
        g: 89,
        b: 151,
      },
      lab: {
        l: 38.07,
        a: 5.16,
        b: -37.69,
      },
      cmyk: null,
    },
    {
      code: '19-4054 TPG',
      name: 'Nouvean Navy',
      hex: '485F91',
      rgb: {
        r: 72,
        g: 95,
        b: 145,
      },
      lab: {
        l: 40.06,
        a: 2.36,
        b: -31.23,
      },
      cmyk: null,
    },
    {
      code: '18-4141 TPG',
      name: 'Campanula',
      hex: '4176B0',
      rgb: {
        r: 65,
        g: 118,
        b: 176,
      },
      lab: {
        l: 47.69,
        a: -4.88,
        b: -37.3,
      },
      cmyk: null,
    },
    {
      code: '18-4250 TPG',
      name: 'Indigo Bunting',
      hex: '006FAF',
      rgb: {
        r: 0,
        g: 111,
        b: 175,
      },
      lab: {
        l: 43.55,
        a: -9.02,
        b: -43.69,
      },
      cmyk: null,
    },
    {
      code: '18-4244 TPG',
      name: 'Directoire Blue',
      hex: '036AAA',
      rgb: {
        r: 3,
        g: 106,
        b: 170,
      },
      lab: {
        l: 42.02,
        a: -6.94,
        b: -43.38,
      },
      cmyk: null,
    },
    {
      code: '18-4045 TPG',
      name: 'Daphne',
      hex: '31679F',
      rgb: {
        r: 49,
        g: 103,
        b: 159,
      },
      lab: {
        l: 41.82,
        a: -4.4,
        b: -36.9,
      },
      cmyk: null,
    },
    {
      code: '19-4151 TPG',
      name: 'Skydiver',
      hex: '1E609E',
      rgb: {
        r: 30,
        g: 96,
        b: 158,
      },
      lab: {
        l: 38.83,
        a: -3.29,
        b: -41.18,
      },
      cmyk: null,
    },
    {
      code: '19-4048 TPG',
      name: 'Baleine Blue',
      hex: '30598C',
      rgb: {
        r: 48,
        g: 89,
        b: 140,
      },
      lab: {
        l: 36.52,
        a: -1.86,
        b: -33.55,
      },
      cmyk: null,
    },
    {
      code: '19-4052 TPG',
      name: 'Classic Blue',
      hex: '34568B',
      rgb: {
        r: 52,
        g: 86,
        b: 139,
      },
      lab: {
        l: 35.69,
        a: 0.44,
        b: -34.37,
      },
      cmyk: null,
    },
    {
      code: '16-4132 TPG',
      name: 'Little Boy Blue',
      hex: '6FA0D8',
      rgb: {
        r: 111,
        g: 160,
        b: 216,
      },
      lab: {
        l: 63.76,
        a: -5.73,
        b: -34.78,
      },
      cmyk: null,
    },
    {
      code: '17-4139 TPG',
      name: 'Azure Blue',
      hex: '508BC3',
      rgb: {
        r: 80,
        g: 139,
        b: 195,
      },
      lab: {
        l: 55.41,
        a: -7.79,
        b: -36.25,
      },
      cmyk: null,
    },
    {
      code: '17-4245 TPG',
      name: 'Ibiza Blue',
      hex: '277FBB',
      rgb: {
        r: 39,
        g: 127,
        b: 187,
      },
      lab: {
        l: 50.09,
        a: -11.05,
        b: -39.94,
      },
      cmyk: null,
    },
    {
      code: '18-4252 TPG',
      name: 'Blue Aster',
      hex: '007BBA',
      rgb: {
        r: 0,
        g: 123,
        b: 186,
      },
      lab: {
        l: 47.9,
        a: -12.48,
        b: -43.51,
      },
      cmyk: null,
    },
    {
      code: '18-4247 TPG',
      name: 'Brilliant Blue',
      hex: '0078BA',
      rgb: {
        r: 0,
        g: 120,
        b: 186,
      },
      lab: {
        l: 47.09,
        a: -10.25,
        b: -44.37,
      },
      cmyk: null,
    },
    {
      code: '18-4140 TPG',
      name: 'French Blue',
      hex: '3078B4',
      rgb: {
        r: 48,
        g: 120,
        b: 180,
      },
      lab: {
        l: 47.77,
        a: -7.68,
        b: -39.87,
      },
      cmyk: null,
    },
    {
      code: '19-4245 TPG',
      name: 'Imperial Blue',
      hex: '195E9A',
      rgb: {
        r: 25,
        g: 94,
        b: 154,
      },
      lab: {
        l: 37.77,
        a: -3.99,
        b: -40.13,
      },
      cmyk: null,
    },
    {
      code: '14-4320 TPG',
      name: 'Baltic Sea',
      hex: '7AB7DC',
      rgb: {
        r: 122,
        g: 183,
        b: 220,
      },
      lab: {
        l: 70.95,
        a: -13.49,
        b: -25.95,
      },
      cmyk: null,
    },
    {
      code: '15-4323 TPG',
      name: 'Ethereal Blue',
      hex: '61A9D2',
      rgb: {
        r: 97,
        g: 169,
        b: 210,
      },
      lab: {
        l: 65.37,
        a: -15.15,
        b: -28.96,
      },
      cmyk: null,
    },
    {
      code: '16-4134 TPG',
      name: 'Bonnie Blue',
      hex: '59A0D1',
      rgb: {
        r: 89,
        g: 160,
        b: 209,
      },
      lab: {
        l: 62.54,
        a: -13.16,
        b: -33.12,
      },
      cmyk: null,
    },
    {
      code: '17-4435 TPG',
      name: 'Malibu Blue',
      hex: '2D91C6',
      rgb: {
        r: 45,
        g: 145,
        b: 198,
      },
      lab: {
        l: 55.92,
        a: -15.67,
        b: -37.6,
      },
      cmyk: null,
    },
    {
      code: '17-4129 TPG',
      name: 'Wave Ride',
      hex: '3985AC',
      rgb: {
        r: 57,
        g: 133,
        b: 172,
      },
      lab: {
        l: 51.71,
        a: -15.49,
        b: -28.68,
      },
      cmyk: null,
    },
    {
      code: '18-4032 TPG',
      name: 'Deep Water',
      hex: '3B6B90',
      rgb: {
        r: 59,
        g: 107,
        b: 144,
      },
      lab: {
        l: 42.73,
        a: -8.25,
        b: -26.53,
      },
      cmyk: null,
    },
    {
      code: '18-4037 TPG',
      name: 'Sea of Belize',
      hex: '35709C',
      rgb: {
        r: 53,
        g: 112,
        b: 156,
      },
      lab: {
        l: 44.63,
        a: -9.27,
        b: -30.35,
      },
      cmyk: null,
    },
    {
      code: '14-4122 TPG',
      name: 'Airy Blue',
      hex: '95B8D8',
      rgb: {
        r: 149,
        g: 184,
        b: 216,
      },
      lab: {
        l: 72.94,
        a: -6.82,
        b: -20.73,
      },
      cmyk: null,
    },
    {
      code: '15-4225 TPG',
      name: 'Alaskan Blue',
      hex: '6FAAD3',
      rgb: {
        r: 111,
        g: 170,
        b: 211,
      },
      lab: {
        l: 66.39,
        a: -11.97,
        b: -27.76,
      },
      cmyk: null,
    },
    {
      code: '17-4032 TPG',
      name: 'Lichen Blue',
      hex: '638DB7',
      rgb: {
        r: 99,
        g: 141,
        b: 183,
      },
      lab: {
        l: 56.6,
        a: -6.28,
        b: -27.5,
      },
      cmyk: null,
    },
    {
      code: '18-4036 TPG',
      name: 'Parisian Blue',
      hex: '5581AB',
      rgb: {
        r: 85,
        g: 129,
        b: 171,
      },
      lab: {
        l: 51.98,
        a: -7.24,
        b: -27.44,
      },
      cmyk: null,
    },
    {
      code: '17-4033 TPG',
      name: 'Pacific Coast',
      hex: '5B84B1',
      rgb: {
        r: 91,
        g: 132,
        b: 177,
      },
      lab: {
        l: 53.24,
        a: -4.98,
        b: -29.04,
      },
      cmyk: null,
    },
    {
      code: '17-4029 TPG',
      name: 'Quiet Harbor',
      hex: '617DA0',
      rgb: {
        r: 97,
        g: 125,
        b: 160,
      },
      lab: {
        l: 51.17,
        a: -4.42,
        b: -22.45,
      },
      cmyk: null,
    },
    {
      code: '18-4029 TPG',
      name: 'Federal Blue',
      hex: '44638C',
      rgb: {
        r: 68,
        g: 99,
        b: 140,
      },
      lab: {
        l: 40.71,
        a: -2.71,
        b: -26.94,
      },
      cmyk: null,
    },
    {
      code: '15-4020 TPG',
      name: 'Cerulean',
      hex: '98B2D1',
      rgb: {
        r: 152,
        g: 178,
        b: 209,
      },
      lab: {
        l: 71.32,
        a: -4.24,
        b: -19.16,
      },
      cmyk: null,
    },
    {
      code: '16-4121 TPG',
      name: 'Blissful Blue ',
      hex: '7296BA',
      rgb: {
        r: 114,
        g: 150,
        b: 186,
      },
      lab: {
        l: 60.25,
        a: -5.82,
        b: -23.83,
      },
      cmyk: null,
    },
    {
      code: '16-4021 TPG',
      name: 'Allure',
      hex: '718DB1',
      rgb: {
        r: 113,
        g: 141,
        b: 177,
      },
      lab: {
        l: 57.39,
        a: -3.88,
        b: -22.62,
      },
      cmyk: null,
    },
    {
      code: '18-4035 TPG',
      name: 'Cyaneus',
      hex: '5C84A7',
      rgb: {
        r: 92,
        g: 132,
        b: 167,
      },
      lab: {
        l: 53,
        a: -7.31,
        b: -23.83,
      },
      cmyk: null,
    },
    {
      code: '18-4027 TPG',
      name: 'Moonlight Blue',
      hex: '586E8B',
      rgb: {
        r: 88,
        g: 110,
        b: 139,
      },
      lab: {
        l: 45.51,
        a: -3.06,
        b: -18.87,
      },
      cmyk: null,
    },
    {
      code: '18-3929 TPG',
      name: 'Blue Horizon',
      hex: '586C8A',
      rgb: {
        r: 88,
        g: 108,
        b: 138,
      },
      lab: {
        l: 44.9,
        a: -2.66,
        b: -19.02,
      },
      cmyk: null,
    },
    {
      code: '19-4026 TPG',
      name: 'Ensign Blue',
      hex: '45576F',
      rgb: {
        r: 69,
        g: 87,
        b: 111,
      },
      lab: {
        l: 36.01,
        a: -2.28,
        b: -16.54,
      },
      cmyk: null,
    },
    {
      code: '16-4018 TPG',
      name: 'Ashleigh Blue',
      hex: '6F8AAD',
      rgb: {
        r: 111,
        g: 138,
        b: 173,
      },
      lab: {
        l: 56.21,
        a: -3.76,
        b: -21.8,
      },
      cmyk: null,
    },
    {
      code: '17-4027 TPG',
      name: 'Riviera',
      hex: '5D7AA5',
      rgb: {
        r: 93,
        g: 122,
        b: 165,
      },
      lab: {
        l: 50.11,
        a: -2.34,
        b: -27.15,
      },
      cmyk: null,
    },
    {
      code: '17-4028 TPG',
      name: 'Riverside ',
      hex: '577199',
      rgb: {
        r: 87,
        g: 113,
        b: 153,
      },
      lab: {
        l: 46.86,
        a: -1.64,
        b: -25.22,
      },
      cmyk: null,
    },
    {
      code: '18-4034 TPG',
      name: 'Vallarta Blue',
      hex: '3F6B93',
      rgb: {
        r: 63,
        g: 107,
        b: 147,
      },
      lab: {
        l: 43.01,
        a: -7.16,
        b: -27.27,
      },
      cmyk: null,
    },
    {
      code: '18-4041 TPG',
      name: 'Star Sapphire',
      hex: '45689A',
      rgb: {
        r: 69,
        g: 104,
        b: 154,
      },
      lab: {
        l: 42.92,
        a: -2.03,
        b: -31.59,
      },
      cmyk: null,
    },
    {
      code: '19-4039 TPG',
      name: 'Delft',
      hex: '46618E',
      rgb: {
        r: 70,
        g: 97,
        b: 142,
      },
      lab: {
        l: 40.45,
        a: -0.61,
        b: -28.69,
      },
      cmyk: null,
    },
    {
      code: '19-4037 TPG',
      name: 'Bright Cobalt',
      hex: '416392',
      rgb: {
        r: 65,
        g: 99,
        b: 146,
      },
      lab: {
        l: 40.85,
        a: -2.28,
        b: -30.21,
      },
      cmyk: null,
    },
    {
      code: '15-3932 TPG',
      name: 'Bel Air Blue',
      hex: '859DC5',
      rgb: {
        r: 133,
        g: 157,
        b: 197,
      },
      lab: {
        l: 63.8,
        a: -1.93,
        b: -23.73,
      },
      cmyk: null,
    },
    {
      code: '18-4033 TPG',
      name: 'Blue Jasper',
      hex: '5B86B2',
      rgb: {
        r: 91,
        g: 134,
        b: 178,
      },
      lab: {
        l: 53.94,
        a: -5.72,
        b: -28.79,
      },
      cmyk: null,
    },
    {
      code: '18-4022 TPG',
      name: 'High Tide',
      hex: '3E6490',
      rgb: {
        r: 62,
        g: 100,
        b: 144,
      },
      lab: {
        l: 40.84,
        a: -3.57,
        b: -29.17,
      },
      cmyk: null,
    },
    {
      code: '19-4042 TPG',
      name: 'Set Sail',
      hex: '365176',
      rgb: {
        r: 54,
        g: 81,
        b: 118,
      },
      lab: {
        l: 33.46,
        a: -2.1,
        b: -24.66,
      },
      cmyk: null,
    },
    {
      code: '19-4035 TPG',
      name: 'Dark Blue',
      hex: '405A7A',
      rgb: {
        r: 64,
        g: 90,
        b: 122,
      },
      lab: {
        l: 37.23,
        a: -3.63,
        b: -21.22,
      },
      cmyk: null,
    },
    {
      code: '19-4033 TPG',
      name: 'Poseidon',
      hex: '304661',
      rgb: {
        r: 48,
        g: 70,
        b: 97,
      },
      lab: {
        l: 28.54,
        a: -1.97,
        b: -19.58,
      },
      cmyk: null,
    },
    {
      code: '19-4117 TPG',
      name: 'Big Dipper',
      hex: '404B5B',
      rgb: {
        r: 64,
        g: 75,
        b: 91,
      },
      lab: {
        l: 31.3,
        a: -1.59,
        b: -11.35,
      },
      cmyk: null,
    },
    {
      code: '16-4031 TPG',
      name: 'Cornflower Blue',
      hex: '7592CA',
      rgb: {
        r: 117,
        g: 146,
        b: 202,
      },
      lab: {
        l: 59.88,
        a: 0.04,
        b: -32.74,
      },
      cmyk: null,
    },
    {
      code: '16-4032 TPG',
      name: 'Provence',
      hex: '6A8DC3',
      rgb: {
        r: 106,
        g: 141,
        b: 195,
      },
      lab: {
        l: 57.62,
        a: -2.3,
        b: -32.42,
      },
      cmyk: null,
    },
    {
      code: '17-4041 TPG',
      name: 'Marina',
      hex: '5B89CB',
      rgb: {
        r: 91,
        g: 137,
        b: 203,
      },
      lab: {
        l: 55.72,
        a: -2.08,
        b: -39.9,
      },
      cmyk: null,
    },
    {
      code: '16-4033 TPG',
      name: 'Granada Sky',
      hex: '6585C0',
      rgb: {
        r: 101,
        g: 133,
        b: 192,
      },
      lab: {
        l: 54.77,
        a: 0.32,
        b: -35.34,
      },
      cmyk: null,
    },
    {
      code: '17-3936 TPG',
      name: 'Blue Bonnet',
      hex: '6987BC',
      rgb: {
        r: 105,
        g: 135,
        b: 188,
      },
      lab: {
        l: 55.39,
        a: -0.83,
        b: -31.62,
      },
      cmyk: null,
    },
    {
      code: '18-3937 TPG',
      name: 'Blue Yonder',
      hex: '5876A7',
      rgb: {
        r: 88,
        g: 118,
        b: 167,
      },
      lab: {
        l: 48.67,
        a: -1.29,
        b: -30.15,
      },
      cmyk: null,
    },
    {
      code: '18-3928 TPG',
      name: 'Dutch Blue',
      hex: '526892',
      rgb: {
        r: 82,
        g: 104,
        b: 146,
      },
      lab: {
        l: 43.54,
        a: -0.33,
        b: -25.68,
      },
      cmyk: null,
    },
    {
      code: '16-3923 TPG',
      name: 'Baby Lavender',
      hex: 'A4ABD5',
      rgb: {
        r: 164,
        g: 171,
        b: 213,
      },
      lab: {
        l: 70.52,
        a: 3.97,
        b: -22.19,
      },
      cmyk: null,
    },
    {
      code: '16-3921 TPG',
      name: 'Blue Heron',
      hex: '9BA7C9',
      rgb: {
        r: 155,
        g: 167,
        b: 201,
      },
      lab: {
        l: 68.28,
        a: 1.39,
        b: -19.19,
      },
      cmyk: null,
    },
    {
      code: '15-3919 TPG',
      name: 'Serenity',
      hex: '95AAD3',
      rgb: {
        r: 149,
        g: 170,
        b: 211,
      },
      lab: {
        l: 68.95,
        a: -0.91,
        b: -23.79,
      },
      cmyk: null,
    },
    {
      code: '15-3930 TPG',
      name: 'Vista Blue',
      hex: '7E9CD3',
      rgb: {
        r: 126,
        g: 156,
        b: 211,
      },
      lab: {
        l: 63.65,
        a: -1.11,
        b: -32.09,
      },
      cmyk: null,
    },
    {
      code: '16-3920 TPG',
      name: 'Lavender Lustre',
      hex: '8B9CC0',
      rgb: {
        r: 139,
        g: 156,
        b: 192,
      },
      lab: {
        l: 63.89,
        a: -0.12,
        b: -21.01,
      },
      cmyk: null,
    },
    {
      code: '16-4030 TPG',
      name: 'Hydrangea',
      hex: '8A9FCC',
      rgb: {
        r: 138,
        g: 159,
        b: 204,
      },
      lab: {
        l: 64.96,
        a: 0.02,
        b: -25.67,
      },
      cmyk: null,
    },
    {
      code: '16-3929 TPG',
      name: 'Grapemist',
      hex: '8397C9',
      rgb: {
        r: 131,
        g: 151,
        b: 201,
      },
      lab: {
        l: 62.16,
        a: 1.72,
        b: -28.71,
      },
      cmyk: null,
    },
    {
      code: '15-4031 TPG',
      name: 'Windsurfer',
      hex: 'A6BEE5',
      rgb: {
        r: 166,
        g: 190,
        b: 229,
      },
      lab: {
        l: 76,
        a: -1.78,
        b: -22.64,
      },
      cmyk: null,
    },
    {
      code: '15-3917 TPG',
      name: 'Frozen Fjord',
      hex: '8BA7CE',
      rgb: {
        r: 139,
        g: 167,
        b: 206,
      },
      lab: {
        l: 67.31,
        a: -3.38,
        b: -23.46,
      },
      cmyk: null,
    },
    {
      code: '15-3920 TPG',
      name: 'Placid Blue',
      hex: '8DACD2',
      rgb: {
        r: 141,
        g: 172,
        b: 210,
      },
      lab: {
        l: 68.79,
        a: -4.42,
        b: -23.77,
      },
      cmyk: null,
    },
    {
      code: '15-3922 TPG',
      name: 'Open Air',
      hex: '97B4E1',
      rgb: {
        r: 151,
        g: 180,
        b: 225,
      },
      lab: {
        l: 72.18,
        a: -2.3,
        b: -26.72,
      },
      cmyk: null,
    },
    {
      code: '16-4020 TPG',
      name: 'Della Robbia Blue',
      hex: '7899C8',
      rgb: {
        r: 120,
        g: 153,
        b: 200,
      },
      lab: {
        l: 61.83,
        a: -3.2,
        b: -28.87,
      },
      cmyk: null,
    },
    {
      code: '16-4029 TPG',
      name: 'Azurine',
      hex: '7591C2',
      rgb: {
        r: 117,
        g: 145,
        b: 194,
      },
      lab: {
        l: 59.4,
        a: -1.3,
        b: -29.03,
      },
      cmyk: null,
    },
    {
      code: '18-3936 TPG',
      name: 'Ebb and Flow',
      hex: '647EAC',
      rgb: {
        r: 100,
        g: 126,
        b: 172,
      },
      lab: {
        l: 51.89,
        a: -0.38,
        b: -28.25,
      },
      cmyk: null,
    },
    {
      code: '13-4202 TPG',
      name: 'Ice Water',
      hex: 'C1D6EA',
      rgb: {
        r: 193,
        g: 214,
        b: 234,
      },
      lab: {
        l: 84.33,
        a: -4.15,
        b: -12.77,
      },
      cmyk: null,
    },
    {
      code: '14-4005 TPG',
      name: 'Nantucket Breeze',
      hex: 'BCD5EA',
      rgb: {
        r: 188,
        g: 213,
        b: 234,
      },
      lab: {
        l: 83.78,
        a: -5.31,
        b: -13.66,
      },
      cmyk: null,
    },
    {
      code: '14-4124 TPG',
      name: 'Dutch Canal',
      hex: '99BDE4',
      rgb: {
        r: 153,
        g: 189,
        b: 228,
      },
      lab: {
        l: 74.72,
        a: -5.55,
        b: -24.34,
      },
      cmyk: null,
    },
    {
      code: '14-4214 TPG',
      name: 'Powder Blue',
      hex: '95B1CE',
      rgb: {
        r: 149,
        g: 177,
        b: 206,
      },
      lab: {
        l: 70.73,
        a: -5.16,
        b: -18.44,
      },
      cmyk: null,
    },
    {
      code: '14-4121 TPG',
      name: 'Blue Bell',
      hex: '93B2D5',
      rgb: {
        r: 147,
        g: 178,
        b: 213,
      },
      lab: {
        l: 71.1,
        a: -5.56,
        b: -21.62,
      },
      cmyk: null,
    },
    {
      code: '17-4140 TPG',
      name: 'All Aboard',
      hex: '5C97CD',
      rgb: {
        r: 92,
        g: 151,
        b: 205,
      },
      lab: {
        l: 59.95,
        a: -8.6,
        b: -34.73,
      },
      cmyk: null,
    },
    {
      code: '17-4030 TPG',
      name: 'Silver Lake Blue',
      hex: '6489B8',
      rgb: {
        r: 100,
        g: 137,
        b: 184,
      },
      lab: {
        l: 55.68,
        a: -4.54,
        b: -28.97,
      },
      cmyk: null,
    },
    {
      code: '13-4306 TPG',
      name: 'Ice Melt',
      hex: 'D4E4EF',
      rgb: {
        r: 212,
        g: 228,
        b: 239,
      },
      lab: {
        l: 89.6,
        a: -3.72,
        b: -7.67,
      },
      cmyk: null,
    },
    {
      code: '13-4302 TPG',
      name: 'Skywriting',
      hex: 'C8D3DF',
      rgb: {
        r: 200,
        g: 211,
        b: 223,
      },
      lab: {
        l: 83.87,
        a: -1.68,
        b: -7.56,
      },
      cmyk: null,
    },
    {
      code: '14-4112 TPG',
      name: 'Skyway',
      hex: 'AABDD4',
      rgb: {
        r: 170,
        g: 189,
        b: 212,
      },
      lab: {
        l: 75.55,
        a: -3.06,
        b: -14.27,
      },
      cmyk: null,
    },
    {
      code: '14-4115 TPG',
      name: 'Cashmere Blue',
      hex: 'A1B5D0',
      rgb: {
        r: 161,
        g: 181,
        b: 208,
      },
      lab: {
        l: 72.77,
        a: -3.08,
        b: -16.15,
      },
      cmyk: null,
    },
    {
      code: '15-4030 TPG',
      name: 'Chambray Blue',
      hex: '9EB4D4',
      rgb: {
        r: 158,
        g: 180,
        b: 212,
      },
      lab: {
        l: 72.34,
        a: -2.51,
        b: -19.07,
      },
      cmyk: null,
    },
    {
      code: '16-3922 TPG',
      name: 'Brunnera Blue',
      hex: '9AA8C9',
      rgb: {
        r: 154,
        g: 168,
        b: 201,
      },
      lab: {
        l: 68.63,
        a: -0.12,
        b: -18.82,
      },
      cmyk: null,
    },
    {
      code: '16-4022 TPG',
      name: 'Endless Sky',
      hex: '8097B6',
      rgb: {
        r: 128,
        g: 151,
        b: 182,
      },
      lab: {
        l: 61.41,
        a: -3.23,
        b: -19.02,
      },
      cmyk: null,
    },
    {
      code: '13-3920 TPG',
      name: 'Halogen Blue',
      hex: 'BDC4D6',
      rgb: {
        r: 189,
        g: 196,
        b: 214,
      },
      lab: {
        l: 79.03,
        a: 0.01,
        b: -9.85,
      },
      cmyk: null,
    },
    {
      code: '14-4110 TPG',
      name: 'Heather',
      hex: 'B6C0D6',
      rgb: {
        r: 182,
        g: 192,
        b: 214,
      },
      lab: {
        l: 77.29,
        a: -0.28,
        b: -12.67,
      },
      cmyk: null,
    },
    {
      code: '14-3949 TPG',
      name: 'Xenon Blue',
      hex: 'BBC3D6',
      rgb: {
        r: 187,
        g: 195,
        b: 214,
      },
      lab: {
        l: 78.54,
        a: -0.07,
        b: -10.84,
      },
      cmyk: null,
    },
    {
      code: '15-3915 TPG',
      name: 'Kentucky Blue',
      hex: 'A0B0D1',
      rgb: {
        r: 160,
        g: 176,
        b: 209,
      },
      lab: {
        l: 71.43,
        a: -0.9,
        b: -18.83,
      },
      cmyk: null,
    },
    {
      code: '14-3912 TPG',
      name: 'Zen Blue',
      hex: 'A6AFC2',
      rgb: {
        r: 166,
        g: 175,
        b: 194,
      },
      lab: {
        l: 71.02,
        a: -0.27,
        b: -11.28,
      },
      cmyk: null,
    },
    {
      code: '16-4016 TPG',
      name: 'Soft Chambray',
      hex: '92A6BE',
      rgb: {
        r: 146,
        g: 166,
        b: 190,
      },
      lab: {
        l: 67.1,
        a: -3.31,
        b: -14.83,
      },
      cmyk: null,
    },
    {
      code: '15-4009 TPG',
      name: 'Blue Blizzard',
      hex: '95A1B6',
      rgb: {
        r: 149,
        g: 161,
        b: 182,
      },
      lab: {
        l: 65.75,
        a: -0.85,
        b: -12.81,
      },
      cmyk: null,
    },
    {
      code: '12-4108 TPG',
      name: 'Ancient Water',
      hex: 'D1DAE7',
      rgb: {
        r: 209,
        g: 218,
        b: 231,
      },
      lab: {
        l: 86.5,
        a: -0.94,
        b: -7.92,
      },
      cmyk: null,
    },
    {
      code: '13-4110 TPG',
      name: 'Arctic Ice',
      hex: 'C2C9D5',
      rgb: {
        r: 194,
        g: 201,
        b: 213,
      },
      lab: {
        l: 80.77,
        a: -0.97,
        b: -7.01,
      },
      cmyk: null,
    },
    {
      code: '14-4211 TPG',
      name: 'Niagara Mist',
      hex: 'ADBCCB',
      rgb: {
        r: 173,
        g: 188,
        b: 203,
      },
      lab: {
        l: 75.49,
        a: -3.13,
        b: -9.5,
      },
      cmyk: null,
    },
    {
      code: '16-4023 TPG',
      name: 'Rain Washed',
      hex: '8294AC',
      rgb: {
        r: 130,
        g: 148,
        b: 172,
      },
      lab: {
        l: 60.29,
        a: -2.71,
        b: -15.28,
      },
      cmyk: null,
    },
    {
      code: '17-3912 TPG',
      name: 'Wild Wind',
      hex: '6C7690',
      rgb: {
        r: 108,
        g: 118,
        b: 144,
      },
      lab: {
        l: 49.58,
        a: 0.43,
        b: -15.63,
      },
      cmyk: null,
    },
    {
      code: '17-3926 TPG',
      name: 'Troposphere',
      hex: '79879F',
      rgb: {
        r: 121,
        g: 135,
        b: 159,
      },
      lab: {
        l: 55.67,
        a: -1.02,
        b: -14.67,
      },
      cmyk: null,
    },
    {
      code: '18-3919 TPG',
      name: 'Bluefin',
      hex: '576A7E',
      rgb: {
        r: 87,
        g: 106,
        b: 126,
      },
      lab: {
        l: 43.84,
        a: -3.91,
        b: -13.78,
      },
      cmyk: null,
    },
    {
      code: '16-3919 TPG',
      name: 'Eventide',
      hex: '909BB5',
      rgb: {
        r: 144,
        g: 155,
        b: 181,
      },
      lab: {
        l: 63.76,
        a: -0.07,
        b: -15,
      },
      cmyk: null,
    },
    {
      code: '16-4019 TPG',
      name: 'Forever Blue',
      hex: '839ABB',
      rgb: {
        r: 131,
        g: 154,
        b: 187,
      },
      lab: {
        l: 62.49,
        a: -2.77,
        b: -20.3,
      },
      cmyk: null,
    },
    {
      code: '17-4015 TPG',
      name: 'Infinity',
      hex: '6B7D98',
      rgb: {
        r: 107,
        g: 125,
        b: 152,
      },
      lab: {
        l: 51.63,
        a: -2.09,
        b: -16.98,
      },
      cmyk: null,
    },
    {
      code: '17-3915 TPG',
      name: 'Tempest',
      hex: '76829B',
      rgb: {
        r: 118,
        g: 130,
        b: 155,
      },
      lab: {
        l: 53.86,
        a: -0.38,
        b: -15.35,
      },
      cmyk: null,
    },
    {
      code: '17-3917 TPG',
      name: 'Stonewash',
      hex: '77839E',
      rgb: {
        r: 119,
        g: 131,
        b: 158,
      },
      lab: {
        l: 54.42,
        a: -0.07,
        b: -15.91,
      },
      cmyk: null,
    },
    {
      code: '17-3918 TPG',
      name: 'Country Blue',
      hex: '707F9B',
      rgb: {
        r: 112,
        g: 127,
        b: 155,
      },
      lab: {
        l: 52.5,
        a: -0.88,
        b: -17.37,
      },
      cmyk: null,
    },
    {
      code: '19-3929 TPG',
      name: 'Vintage Indigo',
      hex: '535E71',
      rgb: {
        r: 83,
        g: 94,
        b: 113,
      },
      lab: {
        l: 39.33,
        a: -0.74,
        b: -12.55,
      },
      cmyk: null,
    },
    {
      code: '18-3916 TPG',
      name: 'Flint Stone',
      hex: '687585',
      rgb: {
        r: 104,
        g: 117,
        b: 133,
      },
      lab: {
        l: 48.59,
        a: -2.34,
        b: -10.75,
      },
      cmyk: null,
    },
    {
      code: '18-3910 TPG',
      name: 'Folkstone Gray',
      hex: '61697A',
      rgb: {
        r: 97,
        g: 105,
        b: 122,
      },
      lab: {
        l: 44.22,
        a: -0.43,
        b: -10.35,
      },
      cmyk: null,
    },
    {
      code: '18-3912 TPG',
      name: 'Grisaille',
      hex: '626677',
      rgb: {
        r: 98,
        g: 102,
        b: 119,
      },
      lab: {
        l: 43.28,
        a: 1.49,
        b: -10.28,
      },
      cmyk: null,
    },
    {
      code: '19-4121 TPG',
      name: 'Blue Wing Teal',
      hex: '384B61',
      rgb: {
        r: 56,
        g: 75,
        b: 97,
      },
      lab: {
        l: 31.03,
        a: -3.26,
        b: -15.14,
      },
      cmyk: null,
    },
    {
      code: '19-4028 TPG',
      name: 'Insignia Blue',
      hex: '414C60',
      rgb: {
        r: 65,
        g: 76,
        b: 96,
      },
      lab: {
        l: 31.95,
        a: -0.6,
        b: -13.59,
      },
      cmyk: null,
    },
    {
      code: '19-4118 TPG',
      name: 'Dark Denim',
      hex: '435267',
      rgb: {
        r: 67,
        g: 82,
        b: 103,
      },
      lab: {
        l: 34.18,
        a: -2,
        b: -14.58,
      },
      cmyk: null,
    },
    {
      code: '19-4122 TPG',
      name: 'Moonlit Ocean',
      hex: '354558',
      rgb: {
        r: 53,
        g: 69,
        b: 88,
      },
      lab: {
        l: 28.57,
        a: -2.61,
        b: -13.45,
      },
      cmyk: null,
    },
    {
      code: '17-3919 TPG',
      name: 'Purple Impression',
      hex: '8791B5',
      rgb: {
        r: 135,
        g: 145,
        b: 181,
      },
      lab: {
        l: 60.29,
        a: 2.06,
        b: -20.12,
      },
      cmyk: null,
    },
    {
      code: '17-3923 TPG',
      name: 'Colony Blue',
      hex: '67789D',
      rgb: {
        r: 103,
        g: 120,
        b: 157,
      },
      lab: {
        l: 50.12,
        a: -0.37,
        b: -21.91,
      },
      cmyk: null,
    },
    {
      code: '18-3921 TPG',
      name: 'Bijou Blue',
      hex: '4D6081',
      rgb: {
        r: 77,
        g: 96,
        b: 129,
      },
      lab: {
        l: 40.03,
        a: -1.13,
        b: -20.98,
      },
      cmyk: null,
    },
    {
      code: '19-4030 TPG',
      name: 'True Navy',
      hex: '4C5B7D',
      rgb: {
        r: 76,
        g: 91,
        b: 125,
      },
      lab: {
        l: 38.4,
        a: 0.79,
        b: -21.19,
      },
      cmyk: null,
    },
    {
      code: '19-3926 TPG',
      name: 'Crown Blue',
      hex: '52566D',
      rgb: {
        r: 82,
        g: 86,
        b: 109,
      },
      lab: {
        l: 36.95,
        a: 2.07,
        b: -13.33,
      },
      cmyk: null,
    },
    {
      code: '19-4031 TPG',
      name: 'Sargasso Sea',
      hex: '485167',
      rgb: {
        r: 72,
        g: 81,
        b: 103,
      },
      lab: {
        l: 34.38,
        a: 0.14,
        b: -14.16,
      },
      cmyk: null,
    },
    {
      code: '19-4024 TPG',
      name: 'Dress Blues',
      hex: '363D4E',
      rgb: {
        r: 54,
        g: 61,
        b: 78,
      },
      lab: {
        l: 25.79,
        a: 0.42,
        b: -11.36,
      },
      cmyk: null,
    },
    {
      code: '17-3920 TPG',
      name: 'English Manor',
      hex: '7181A3',
      rgb: {
        r: 113,
        g: 129,
        b: 163,
      },
      lab: {
        l: 53.69,
        a: -0.41,
        b: -20.34,
      },
      cmyk: null,
    },
    {
      code: '18-3920 TPG',
      name: 'Coastal Fjord',
      hex: '566284',
      rgb: {
        r: 86,
        g: 98,
        b: 132,
      },
      lab: {
        l: 41.58,
        a: 1.3,
        b: -20.8,
      },
      cmyk: null,
    },
    {
      code: '18-3917 TPG',
      name: 'Gray Blue',
      hex: '565F7E',
      rgb: {
        r: 86,
        g: 95,
        b: 126,
      },
      lab: {
        l: 40.35,
        a: 2.43,
        b: -19.03,
      },
      cmyk: null,
    },
    {
      code: '19-3928 TPG',
      name: 'Blue Indigo',
      hex: '515A75',
      rgb: {
        r: 81,
        g: 90,
        b: 117,
      },
      lab: {
        l: 38.31,
        a: 0.73,
        b: -16.3,
      },
      cmyk: null,
    },
    {
      code: '19-3919 TPG',
      name: 'Nightshadow Blue',
      hex: '595F74',
      rgb: {
        r: 89,
        g: 95,
        b: 116,
      },
      lab: {
        l: 40.31,
        a: 1.33,
        b: -12.52,
      },
      cmyk: null,
    },
    {
      code: '19-3915 TPG',
      name: 'Graystone',
      hex: '4D4E5F',
      rgb: {
        r: 77,
        g: 78,
        b: 95,
      },
      lab: {
        l: 33.52,
        a: 2.62,
        b: -10.01,
      },
      cmyk: null,
    },
    {
      code: '19-3815 TPG',
      name: 'Evening Blue',
      hex: '3A384D',
      rgb: {
        r: 58,
        g: 56,
        b: 77,
      },
      lab: {
        l: 24.57,
        a: 4.72,
        b: -12.33,
      },
      cmyk: null,
    },
    {
      code: '17-3922 TPG',
      name: 'Blue Ice',
      hex: '747EA0',
      rgb: {
        r: 116,
        g: 126,
        b: 160,
      },
      lab: {
        l: 53,
        a: 1.82,
        b: -19.62,
      },
      cmyk: null,
    },
    {
      code: '18-3927 TPG',
      name: 'Velvet Morning',
      hex: '666E91',
      rgb: {
        r: 102,
        g: 110,
        b: 145,
      },
      lab: {
        l: 46.8,
        a: 2.6,
        b: -20,
      },
      cmyk: null,
    },
    {
      code: '18-3932 TPG',
      name: 'Marlin',
      hex: '5B6487',
      rgb: {
        r: 91,
        g: 100,
        b: 135,
      },
      lab: {
        l: 42.65,
        a: 2.86,
        b: -20.53,
      },
      cmyk: null,
    },
    {
      code: '19-3936 TPG',
      name: 'Skipper Blue',
      hex: '55567B',
      rgb: {
        r: 85,
        g: 86,
        b: 123,
      },
      lab: {
        l: 37.73,
        a: 6.32,
        b: -21.26,
      },
      cmyk: null,
    },
    {
      code: '19-3839 TPG',
      name: 'Blue Ribbon',
      hex: '4A4B69',
      rgb: {
        r: 74,
        g: 75,
        b: 105,
      },
      lab: {
        l: 32.66,
        a: 5.06,
        b: -17.59,
      },
      cmyk: null,
    },
    {
      code: '19-3830 TPG',
      name: 'Astral Aura',
      hex: '3F3C57',
      rgb: {
        r: 63,
        g: 60,
        b: 87,
      },
      lab: {
        l: 26.66,
        a: 5.9,
        b: -15.45,
      },
      cmyk: null,
    },
    {
      code: '19-3810 TPG',
      name: 'Eclipse',
      hex: '3B3A50',
      rgb: {
        r: 59,
        g: 58,
        b: 80,
      },
      lab: {
        l: 25.4,
        a: 4.39,
        b: -13.19,
      },
      cmyk: null,
    },
    {
      code: '19-3934 TPG',
      name: 'Oceana',
      hex: '434D68',
      rgb: {
        r: 67,
        g: 77,
        b: 104,
      },
      lab: {
        l: 32.75,
        a: 1.47,
        b: -16.97,
      },
      cmyk: null,
    },
    {
      code: '19-3932 TPG',
      name: 'Naval Academy',
      hex: '3E4053',
      rgb: {
        r: 62,
        g: 64,
        b: 83,
      },
      lab: {
        l: 27.55,
        a: 2.3,
        b: -11.86,
      },
      cmyk: null,
    },
    {
      code: '19-3943 TPG',
      name: 'Bellwether Blue',
      hex: '383F65',
      rgb: {
        r: 56,
        g: 63,
        b: 101,
      },
      lab: {
        l: 27.47,
        a: 4.89,
        b: -23.58,
      },
      cmyk: null,
    },
    {
      code: '19-3851 TPG',
      name: 'Midnight Sail',
      hex: '3F425D',
      rgb: {
        r: 63,
        g: 66,
        b: 93,
      },
      lab: {
        l: 28.5,
        a: 3.88,
        b: -16.8,
      },
      cmyk: null,
    },
    {
      code: '19-3942 TPG',
      name: 'Beacon Blue',
      hex: '3D3D5E',
      rgb: {
        r: 61,
        g: 61,
        b: 94,
      },
      lab: {
        l: 26.88,
        a: 6.35,
        b: -19.72,
      },
      cmyk: null,
    },
    {
      code: '19-3937 TPG',
      name: 'Ocean Cavern',
      hex: '3A3F59',
      rgb: {
        r: 58,
        g: 63,
        b: 89,
      },
      lab: {
        l: 27.14,
        a: 2.92,
        b: -16.33,
      },
      cmyk: null,
    },
    {
      code: '19-3838 TPG',
      name: 'Rhodonite',
      hex: '3E3B5A',
      rgb: {
        r: 62,
        g: 59,
        b: 90,
      },
      lab: {
        l: 26.35,
        a: 6.46,
        b: -17.72,
      },
      cmyk: null,
    },
    {
      code: '19-3935 TPG',
      name: 'Deep Cobalt',
      hex: '4B4F6B',
      rgb: {
        r: 75,
        g: 79,
        b: 107,
      },
      lab: {
        l: 34.09,
        a: 3.64,
        b: -16.76,
      },
      cmyk: null,
    },
    {
      code: '19-4027 TPG',
      name: 'Estate Blue',
      hex: '3A4462',
      rgb: {
        r: 58,
        g: 68,
        b: 98,
      },
      lab: {
        l: 28.89,
        a: 2.19,
        b: -19.45,
      },
      cmyk: null,
    },
    {
      code: '19-3938 TPG',
      name: 'Twilight Blue',
      hex: '424C6D',
      rgb: {
        r: 66,
        g: 76,
        b: 109,
      },
      lab: {
        l: 32.45,
        a: 2.51,
        b: -20.71,
      },
      cmyk: null,
    },
    {
      code: '19-3939 TPG',
      name: 'Blueprint',
      hex: '3C4167',
      rgb: {
        r: 60,
        g: 65,
        b: 103,
      },
      lab: {
        l: 28.3,
        a: 5.28,
        b: -23.06,
      },
      cmyk: null,
    },
    {
      code: '19-3940 TPG',
      name: 'Blue Depths',
      hex: '3D4162',
      rgb: {
        r: 61,
        g: 65,
        b: 98,
      },
      lab: {
        l: 28.29,
        a: 4.5,
        b: -19.95,
      },
      cmyk: null,
    },
    {
      code: '19-3933 TPG',
      name: 'Medieval Blue',
      hex: '3F425C',
      rgb: {
        r: 63,
        g: 66,
        b: 92,
      },
      lab: {
        l: 28.6,
        a: 3.3,
        b: -15.64,
      },
      cmyk: null,
    },
    {
      code: '19-3925 TPG',
      name: 'Patriot Blue',
      hex: '42465D',
      rgb: {
        r: 66,
        g: 70,
        b: 93,
      },
      lab: {
        l: 29.98,
        a: 2.81,
        b: -14.47,
      },
      cmyk: null,
    },
    {
      code: '19-4111 TPG',
      name: 'Pageant Blue',
      hex: '353E52',
      rgb: {
        r: 53,
        g: 62,
        b: 82,
      },
      lab: {
        l: 26.06,
        a: 0.19,
        b: -13.45,
      },
      cmyk: null,
    },
    {
      code: '19-4109 TPG',
      name: 'After Midnight',
      hex: '3E424B',
      rgb: {
        r: 62,
        g: 66,
        b: 75,
      },
      lab: {
        l: 27.74,
        a: -0.05,
        b: -6.37,
      },
      cmyk: null,
    },
    {
      code: '19-4018 TPG',
      name: 'Seaborne',
      hex: '41434F',
      rgb: {
        r: 65,
        g: 67,
        b: 79,
      },
      lab: {
        l: 28.43,
        a: 1.56,
        b: -7.38,
      },
      cmyk: null,
    },
    {
      code: '19-3812 TPG',
      name: 'Baritone Blue',
      hex: '3A3A46',
      rgb: {
        r: 58,
        g: 58,
        b: 70,
      },
      lab: {
        l: 24.83,
        a: 2.11,
        b: -6.96,
      },
      cmyk: null,
    },
    {
      code: '19-4017 TPG',
      name: 'Spellbound',
      hex: '444B5A',
      rgb: {
        r: 68,
        g: 75,
        b: 90,
      },
      lab: {
        l: 31.62,
        a: 0.06,
        b: -10.19,
      },
      cmyk: null,
    },
    {
      code: '19-3811 TPG',
      name: 'Inkling',
      hex: '3F3C49',
      rgb: {
        r: 63,
        g: 60,
        b: 73,
      },
      lab: {
        l: 25.98,
        a: 3.51,
        b: -7.6,
      },
      cmyk: null,
    },
    {
      code: '19-4105 TPG',
      name: 'Polar Night',
      hex: '434550',
      rgb: {
        r: 67,
        g: 69,
        b: 80,
      },
      lab: {
        l: 29.31,
        a: 1.06,
        b: -6.73,
      },
      cmyk: null,
    },
    {
      code: '19-3921 TPG',
      name: 'Black Iris',
      hex: '383E4E',
      rgb: {
        r: 56,
        g: 62,
        b: 78,
      },
      lab: {
        l: 26.04,
        a: 0.76,
        b: -10.81,
      },
      cmyk: null,
    },
    {
      code: '19-3831 TPG',
      name: 'Maritime Blue',
      hex: '3E3D4C',
      rgb: {
        r: 62,
        g: 61,
        b: 76,
      },
      lab: {
        l: 26.28,
        a: 3,
        b: -8.82,
      },
      cmyk: null,
    },
    {
      code: '19-4025 TPG',
      name: 'Mood Indigo',
      hex: '434B59',
      rgb: {
        r: 67,
        g: 75,
        b: 89,
      },
      lab: {
        l: 31.41,
        a: -0.55,
        b: -9.58,
      },
      cmyk: null,
    },
    {
      code: '19-3920 TPG',
      name: 'Peacoat',
      hex: '3F3F50',
      rgb: {
        r: 63,
        g: 63,
        b: 80,
      },
      lab: {
        l: 27.2,
        a: 2.83,
        b: -10.1,
      },
      cmyk: null,
    },
    {
      code: '19-4010 TPG',
      name: 'Total Eclipse',
      hex: '3B4049',
      rgb: {
        r: 59,
        g: 64,
        b: 73,
      },
      lab: {
        l: 26.83,
        a: -0.3,
        b: -6.41,
      },
      cmyk: null,
    },
    {
      code: '19-4022 TPG',
      name: 'Parisian Night',
      hex: '40434F',
      rgb: {
        r: 64,
        g: 67,
        b: 79,
      },
      lab: {
        l: 28.5,
        a: 0.99,
        b: -7.88,
      },
      cmyk: null,
    },
    {
      code: '19-4020 TPG',
      name: 'Dark Sapphire',
      hex: '393D49',
      rgb: {
        r: 57,
        g: 61,
        b: 73,
      },
      lab: {
        l: 25.72,
        a: 0.84,
        b: -8.21,
      },
      cmyk: null,
    },
    {
      code: '19-3930 TPG',
      name: 'Odyssey Gray',
      hex: '4F4F5C',
      rgb: {
        r: 79,
        g: 79,
        b: 92,
      },
      lab: {
        l: 34.05,
        a: 2.07,
        b: -7.43,
      },
      cmyk: null,
    },
    {
      code: '19-4009 TPG',
      name: 'Outer Space',
      hex: '3B414F',
      rgb: {
        r: 59,
        g: 65,
        b: 79,
      },
      lab: {
        l: 27.53,
        a: 0.11,
        b: -9.54,
      },
      cmyk: null,
    },
    {
      code: '19-3927 TPG',
      name: 'Graphite',
      hex: '484754',
      rgb: {
        r: 72,
        g: 71,
        b: 84,
      },
      lab: {
        l: 30.61,
        a: 2.78,
        b: -7.61,
      },
      cmyk: null,
    },
    {
      code: '19-4023 TPG',
      name: 'Blue Nights',
      hex: '434852',
      rgb: {
        r: 67,
        g: 72,
        b: 82,
      },
      lab: {
        l: 30.24,
        a: -0.34,
        b: -6.9,
      },
      cmyk: null,
    },
    {
      code: '19-3923 TPG',
      name: 'Navy Blazer',
      hex: '3B3E4B',
      rgb: {
        r: 59,
        g: 62,
        b: 75,
      },
      lab: {
        l: 26.13,
        a: 1.38,
        b: -9.05,
      },
      cmyk: null,
    },
    {
      code: '19-3922 TPG',
      name: 'Sky Captain',
      hex: '393C48',
      rgb: {
        r: 57,
        g: 60,
        b: 72,
      },
      lab: {
        l: 25.23,
        a: 1.04,
        b: -7.95,
      },
      cmyk: null,
    },
    {
      code: '19-3713 TPG',
      name: 'Deep Well',
      hex: '3F3C48',
      rgb: {
        r: 63,
        g: 60,
        b: 72,
      },
      lab: {
        l: 26.05,
        a: 3.08,
        b: -6.63,
      },
      cmyk: null,
    },
    {
      code: '19-4014 TPG',
      name: 'Ombre Blue',
      hex: '4C525C',
      rgb: {
        r: 76,
        g: 82,
        b: 92,
      },
      lab: {
        l: 34.57,
        a: -0.68,
        b: -7.11,
      },
      cmyk: null,
    },
    {
      code: '19-4019 TPG',
      name: 'India Ink',
      hex: '4A4F59',
      rgb: {
        r: 74,
        g: 79,
        b: 89,
      },
      lab: {
        l: 33.37,
        a: -0.77,
        b: -6.5,
      },
      cmyk: null,
    },
    {
      code: '19-4013 TPG',
      name: 'Dark Navy',
      hex: '414349',
      rgb: {
        r: 65,
        g: 67,
        b: 73,
      },
      lab: {
        l: 28.4,
        a: -0.23,
        b: -3.83,
      },
      cmyk: null,
    },
    {
      code: '19-4015 TPG',
      name: 'Blue Graphite',
      hex: '404347',
      rgb: {
        r: 64,
        g: 67,
        b: 71,
      },
      lab: {
        l: 28.12,
        a: -0.41,
        b: -2.92,
      },
      cmyk: null,
    },
    {
      code: '19-4011 TPG',
      name: 'Salute',
      hex: '3B3F4A',
      rgb: {
        r: 59,
        g: 63,
        b: 74,
      },
      lab: {
        l: 26.48,
        a: 0.52,
        b: -7.37,
      },
      cmyk: null,
    },
    {
      code: '19-4218 TPG',
      name: 'Vulcan',
      hex: '3F4147',
      rgb: {
        r: 63,
        g: 65,
        b: 71,
      },
      lab: {
        l: 27.62,
        a: 0.24,
        b: -3.94,
      },
      cmyk: null,
    },
    {
      code: '19-3924 TPG',
      name: 'Night Sky',
      hex: '3B3C49',
      rgb: {
        r: 59,
        g: 60,
        b: 73,
      },
      lab: {
        l: 25.46,
        a: 2.03,
        b: -8,
      },
      cmyk: null,
    },
    {
      code: '19-4908 TPG',
      name: 'Magical Forest',
      hex: '394448',
      rgb: {
        r: 57,
        g: 68,
        b: 72,
      },
      lab: {
        l: 28.04,
        a: -3.98,
        b: -4.28,
      },
      cmyk: null,
    },
    {
      code: '19-4112 TPG',
      name: 'Stratified Sea',
      hex: '3B4853',
      rgb: {
        r: 59,
        g: 72,
        b: 83,
      },
      lab: {
        l: 29.75,
        a: -3.21,
        b: -8.58,
      },
      cmyk: null,
    },
    {
      code: '19-4108 TPG',
      name: 'Sea Storm',
      hex: '414A53',
      rgb: {
        r: 65,
        g: 74,
        b: 83,
      },
      lab: {
        l: 30.81,
        a: -1.66,
        b: -7.01,
      },
      cmyk: null,
    },
    {
      code: '19-3944 TPG',
      name: 'Overture',
      hex: '3F4251',
      rgb: {
        r: 63,
        g: 66,
        b: 81,
      },
      lab: {
        l: 28.12,
        a: 1.69,
        b: -9.61,
      },
      cmyk: null,
    },
    {
      code: '19-4016 TPG',
      name: 'Inkwell',
      hex: '434852',
      rgb: {
        r: 67,
        g: 72,
        b: 82,
      },
      lab: {
        l: 30.26,
        a: -0.01,
        b: -6.92,
      },
      cmyk: null,
    },
    {
      code: '19-4051 TPG',
      name: 'Collegiate Blue ',
      hex: '3F454D',
      rgb: {
        r: 63,
        g: 69,
        b: 77,
      },
      lab: {
        l: 28.98,
        a: -1.33,
        b: -5.39,
      },
      cmyk: null,
    },
    {
      code: '19-3916 TPG',
      name: 'Charcoal Art',
      hex: '414047',
      rgb: {
        r: 65,
        g: 64,
        b: 71,
      },
      lab: {
        l: 27.5,
        a: 1.81,
        b: -4.06,
      },
      cmyk: null,
    },
    {
      code: '16-3916 TPG',
      name: 'Sleet',
      hex: '9D9EA4',
      rgb: {
        r: 157,
        g: 158,
        b: 164,
      },
      lab: {
        l: 65.09,
        a: 0.85,
        b: -3.59,
      },
      cmyk: null,
    },
    {
      code: '15-4307 TPG',
      name: 'Tradewinds',
      hex: '878F99',
      rgb: {
        r: 135,
        g: 143,
        b: 153,
      },
      lab: {
        l: 58.83,
        a: -1.41,
        b: -6.47,
      },
      cmyk: null,
    },
    {
      code: '18-4214 TPG',
      name: 'Stormy Weather',
      hex: '626E76',
      rgb: {
        r: 98,
        g: 110,
        b: 118,
      },
      lab: {
        l: 45.51,
        a: -3.3,
        b: -6.47,
      },
      cmyk: null,
    },
    {
      code: '19-4215 TPG',
      name: 'Turbulence',
      hex: '595F66',
      rgb: {
        r: 89,
        g: 95,
        b: 102,
      },
      lab: {
        l: 39.94,
        a: -1.02,
        b: -4.86,
      },
      cmyk: null,
    },
    {
      code: '19-4110 TPG',
      name: 'Midnight Navy',
      hex: '444E5A',
      rgb: {
        r: 68,
        g: 78,
        b: 90,
      },
      lab: {
        l: 32.59,
        a: -1.99,
        b: -8.59,
      },
      cmyk: null,
    },
    {
      code: '19-4021 TPG',
      name: 'Blueberry',
      hex: '3A414A',
      rgb: {
        r: 58,
        g: 65,
        b: 74,
      },
      lab: {
        l: 27.02,
        a: -1.32,
        b: -7,
      },
      cmyk: null,
    },
    {
      code: '19-4012 TPG',
      name: 'Carbon',
      hex: '3C3F48',
      rgb: {
        r: 60,
        g: 63,
        b: 72,
      },
      lab: {
        l: 26.73,
        a: 0.01,
        b: -5.97,
      },
      cmyk: null,
    },
    {
      code: '17-4020 TPG',
      name: 'Blue Shadow',
      hex: '6C88A1',
      rgb: {
        r: 108,
        g: 136,
        b: 161,
      },
      lab: {
        l: 55.15,
        a: -6.31,
        b: -16.74,
      },
      cmyk: null,
    },
    {
      code: '19-4227 TPG',
      name: 'Indian Teal',
      hex: '485F6F',
      rgb: {
        r: 72,
        g: 95,
        b: 111,
      },
      lab: {
        l: 38.79,
        a: -5.62,
        b: -12.57,
      },
      cmyk: null,
    },
    {
      code: '19-4318 TPG',
      name: 'Mallard Blue',
      hex: '476577',
      rgb: {
        r: 71,
        g: 101,
        b: 119,
      },
      lab: {
        l: 40.76,
        a: -7.73,
        b: -14.2,
      },
      cmyk: null,
    },
    {
      code: '18-3918 TPG',
      name: 'China Blue',
      hex: '576B7E',
      rgb: {
        r: 87,
        g: 107,
        b: 126,
      },
      lab: {
        l: 43.92,
        a: -4.11,
        b: -13.24,
      },
      cmyk: null,
    },
    {
      code: '19-4229 TPG',
      name: 'Orion Blue',
      hex: '4D5B67',
      rgb: {
        r: 77,
        g: 91,
        b: 103,
      },
      lab: {
        l: 37.74,
        a: -3.99,
        b: -8.68,
      },
      cmyk: null,
    },
    {
      code: '19-4220 TPG',
      name: 'Dark Slate',
      hex: '4E5A61',
      rgb: {
        r: 78,
        g: 90,
        b: 97,
      },
      lab: {
        l: 37.3,
        a: -3.42,
        b: -6.14,
      },
      cmyk: null,
    },
    {
      code: '19-4316 TPG',
      name: 'Stargazer',
      hex: '445A65',
      rgb: {
        r: 68,
        g: 90,
        b: 101,
      },
      lab: {
        l: 36.54,
        a: -6.6,
        b: -9.43,
      },
      cmyk: null,
    },
    {
      code: '17-4019 TPG',
      name: 'Mountain Spring',
      hex: '7C95A8',
      rgb: {
        r: 124,
        g: 149,
        b: 168,
      },
      lab: {
        l: 60.14,
        a: -6.01,
        b: -13.51,
      },
      cmyk: null,
    },
    {
      code: '17-4018 TPG',
      name: 'Windward Blue',
      hex: '738799',
      rgb: {
        r: 115,
        g: 135,
        b: 153,
      },
      lab: {
        l: 55.1,
        a: -4.16,
        b: -12.53,
      },
      cmyk: null,
    },
    {
      code: '18-4221 TPG',
      name: 'Spring Lake',
      hex: '698295',
      rgb: {
        r: 105,
        g: 130,
        b: 149,
      },
      lab: {
        l: 52.68,
        a: -5.96,
        b: -13.71,
      },
      cmyk: null,
    },
    {
      code: '18-4218 TPG',
      name: 'Blue Fusion',
      hex: '53687C',
      rgb: {
        r: 83,
        g: 104,
        b: 124,
      },
      lab: {
        l: 42.77,
        a: -3.74,
        b: -14.27,
      },
      cmyk: null,
    },
    {
      code: '18-3923 TPG',
      name: 'Oceanview',
      hex: '596F80',
      rgb: {
        r: 89,
        g: 111,
        b: 128,
      },
      lab: {
        l: 45.46,
        a: -5.23,
        b: -12.39,
      },
      cmyk: null,
    },
    {
      code: '19-4129 TPG',
      name: 'Key Largo',
      hex: '3F5165',
      rgb: {
        r: 63,
        g: 81,
        b: 101,
      },
      lab: {
        l: 33.36,
        a: -3.18,
        b: -14.32,
      },
      cmyk: null,
    },
    {
      code: '19-4128 TPG',
      name: 'Titan',
      hex: '35465A',
      rgb: {
        r: 53,
        g: 70,
        b: 90,
      },
      lab: {
        l: 28.71,
        a: -2.22,
        b: -14.62,
      },
      cmyk: null,
    },
    {
      code: '18-4220 TPG',
      name: 'Provincial Blue',
      hex: '5F7E94',
      rgb: {
        r: 95,
        g: 126,
        b: 148,
      },
      lab: {
        l: 50.78,
        a: -7.57,
        b: -16.01,
      },
      cmyk: null,
    },
    {
      code: '18-4217 TPG',
      name: 'Bluestone',
      hex: '587587',
      rgb: {
        r: 88,
        g: 117,
        b: 135,
      },
      lab: {
        l: 47.25,
        a: -7.43,
        b: -13.74,
      },
      cmyk: null,
    },
    {
      code: '18-4320 TPG',
      name: 'Aegean Blue',
      hex: '567789',
      rgb: {
        r: 86,
        g: 119,
        b: 137,
      },
      lab: {
        l: 47.78,
        a: -8.92,
        b: -14.29,
      },
      cmyk: null,
    },
    {
      code: '18-3922 TPG',
      name: 'Coronet Blue',
      hex: '5A7490',
      rgb: {
        r: 90,
        g: 116,
        b: 144,
      },
      lab: {
        l: 47.62,
        a: -4.67,
        b: -18.77,
      },
      cmyk: null,
    },
    {
      code: '18-4020 TPG',
      name: "Captain's Blue",
      hex: '5B7691',
      rgb: {
        r: 91,
        g: 118,
        b: 145,
      },
      lab: {
        l: 48.18,
        a: -5.49,
        b: -18.14,
      },
      cmyk: null,
    },
    {
      code: '18-4025 TPG',
      name: 'Copen Blue',
      hex: '587289',
      rgb: {
        r: 88,
        g: 114,
        b: 137,
      },
      lab: {
        l: 46.38,
        a: -5.5,
        b: -16.28,
      },
      cmyk: null,
    },
    {
      code: '18-4026 TPG',
      name: 'Stellar',
      hex: '46647D',
      rgb: {
        r: 70,
        g: 100,
        b: 125,
      },
      lab: {
        l: 40.56,
        a: -6.24,
        b: -18.19,
      },
      cmyk: null,
    },
    {
      code: '18-4215 TPG',
      name: 'Blue Mirage',
      hex: '5C707E',
      rgb: {
        r: 92,
        g: 112,
        b: 126,
      },
      lab: {
        l: 45.78,
        a: -5.51,
        b: -10.5,
      },
      cmyk: null,
    },
    {
      code: '18-4028 TPG',
      name: 'Bering Sea',
      hex: '546375',
      rgb: {
        r: 84,
        g: 99,
        b: 117,
      },
      lab: {
        l: 41.03,
        a: -2.7,
        b: -12.42,
      },
      cmyk: null,
    },
    {
      code: '19-4126 TPG',
      name: 'Deep Dive',
      hex: '385265',
      rgb: {
        r: 56,
        g: 82,
        b: 101,
      },
      lab: {
        l: 33.25,
        a: -6.24,
        b: -14.67,
      },
      cmyk: null,
    },
    {
      code: '19-4038 TPG',
      name: 'Gibraltar Sea',
      hex: '31475E',
      rgb: {
        r: 49,
        g: 71,
        b: 94,
      },
      lab: {
        l: 28.85,
        a: -3.33,
        b: -17.31,
      },
      cmyk: null,
    },
    {
      code: '19-4120 TPG',
      name: 'Blue Opal',
      hex: '2E4762',
      rgb: {
        r: 46,
        g: 71,
        b: 98,
      },
      lab: {
        l: 28.88,
        a: -3.04,
        b: -19.6,
      },
      cmyk: null,
    },
    {
      code: '19-4034 TPG',
      name: 'Sailor Blue ',
      hex: '2F4A62',
      rgb: {
        r: 47,
        g: 74,
        b: 98,
      },
      lab: {
        l: 29.96,
        a: -5.09,
        b: -17.72,
      },
      cmyk: null,
    },
    {
      code: '19-4125 TPG',
      name: 'Majolica Blue',
      hex: '3E4E5F',
      rgb: {
        r: 62,
        g: 78,
        b: 95,
      },
      lab: {
        l: 32.17,
        a: -3.02,
        b: -12.58,
      },
      cmyk: null,
    },
    {
      code: '17-4023 TPG',
      name: 'Blue Heaven',
      hex: '5D7F98',
      rgb: {
        r: 93,
        g: 127,
        b: 152,
      },
      lab: {
        l: 51.02,
        a: -7.61,
        b: -18.01,
      },
      cmyk: null,
    },
    {
      code: '18-4222 TPG',
      name: 'Bluesteel',
      hex: '486C85',
      rgb: {
        r: 72,
        g: 108,
        b: 133,
      },
      lab: {
        l: 43.5,
        a: -8.72,
        b: -18.22,
      },
      cmyk: null,
    },
    {
      code: '18-4018 TPG',
      name: 'Real Teal',
      hex: '4B6479',
      rgb: {
        r: 75,
        g: 100,
        b: 121,
      },
      lab: {
        l: 40.76,
        a: -5.55,
        b: -15.19,
      },
      cmyk: null,
    },
    {
      code: '19-4127 TPG',
      name: 'Midnight',
      hex: '38637B',
      rgb: {
        r: 56,
        g: 99,
        b: 123,
      },
      lab: {
        l: 39.4,
        a: -10.07,
        b: -19.2,
      },
      cmyk: null,
    },
    {
      code: '18-4023 TPG',
      name: 'Blue Ashes',
      hex: '46657D',
      rgb: {
        r: 70,
        g: 101,
        b: 125,
      },
      lab: {
        l: 40.87,
        a: -6.58,
        b: -17.88,
      },
      cmyk: null,
    },
    {
      code: '19-4234 TPG',
      name: 'Ink Blue',
      hex: '2E5C74',
      rgb: {
        r: 46,
        g: 92,
        b: 116,
      },
      lab: {
        l: 36.39,
        a: -10.66,
        b: -19.19,
      },
      cmyk: null,
    },
    {
      code: '19-4241 TPG',
      name: 'Moroccan Blue',
      hex: '275870',
      rgb: {
        r: 39,
        g: 88,
        b: 112,
      },
      lab: {
        l: 34.53,
        a: -10.9,
        b: -19.74,
      },
      cmyk: null,
    },
    {
      code: '14-4106 TPG',
      name: 'Gray Dawn',
      hex: 'B7BECB',
      rgb: {
        r: 183,
        g: 190,
        b: 203,
      },
      lab: {
        l: 76.77,
        a: -0.81,
        b: -7.15,
      },
      cmyk: null,
    },
    {
      code: '14-4206 TPG',
      name: 'Pearl Blue',
      hex: 'ACB9BF',
      rgb: {
        r: 172,
        g: 185,
        b: 191,
      },
      lab: {
        l: 74.3,
        a: -3.93,
        b: -4.64,
      },
      cmyk: null,
    },
    {
      code: '14-4210 TPG',
      name: 'Celestial Blue',
      hex: 'A4B6C5',
      rgb: {
        r: 164,
        g: 182,
        b: 197,
      },
      lab: {
        l: 72.78,
        a: -4.07,
        b: -10.39,
      },
      cmyk: null,
    },
    {
      code: '15-4008 TPG',
      name: 'Blue Fog',
      hex: '98ABBD',
      rgb: {
        r: 152,
        g: 171,
        b: 189,
      },
      lab: {
        l: 68.85,
        a: -4.53,
        b: -11.59,
      },
      cmyk: null,
    },
    {
      code: '16-4010 TPG',
      name: 'Dusty Blue',
      hex: '889BAE',
      rgb: {
        r: 136,
        g: 155,
        b: 174,
      },
      lab: {
        l: 62.86,
        a: -3.82,
        b: -12.51,
      },
      cmyk: null,
    },
    {
      code: '16-4013 TPG',
      name: 'Ashley Blue',
      hex: '879AAC',
      rgb: {
        r: 135,
        g: 154,
        b: 172,
      },
      lab: {
        l: 62.6,
        a: -4.41,
        b: -11.5,
      },
      cmyk: null,
    },
    {
      code: '17-4021 TPG',
      name: 'Faded Denim',
      hex: '798FA8',
      rgb: {
        r: 121,
        g: 143,
        b: 168,
      },
      lab: {
        l: 58.35,
        a: -4.22,
        b: -15.94,
      },
      cmyk: null,
    },
    {
      code: '12-4303 TPG',
      name: 'Country Air',
      hex: 'D1DEE9',
      rgb: {
        r: 209,
        g: 222,
        b: 233,
      },
      lab: {
        l: 87.75,
        a: -2.79,
        b: -7.34,
      },
      cmyk: null,
    },
    {
      code: '12-4202 TPG',
      name: 'Delicate Blue',
      hex: 'C7DFEC',
      rgb: {
        r: 199,
        g: 223,
        b: 236,
      },
      lab: {
        l: 87.07,
        a: -6.14,
        b: -9.81,
      },
      cmyk: null,
    },
    {
      code: '14-4215 TPG',
      name: 'Skyride',
      hex: 'B3C7D7',
      rgb: {
        r: 179,
        g: 199,
        b: 215,
      },
      lab: {
        l: 78.96,
        a: -4.27,
        b: -10.75,
      },
      cmyk: null,
    },
    {
      code: '14-4316 TPG',
      name: 'Summer Song',
      hex: 'A3C6DD',
      rgb: {
        r: 163,
        g: 198,
        b: 221,
      },
      lab: {
        l: 77.62,
        a: -8.19,
        b: -16.22,
      },
      cmyk: null,
    },
    {
      code: '14-4123 TPG',
      name: 'Clear Sky',
      hex: 'A0C0DD',
      rgb: {
        r: 160,
        g: 192,
        b: 221,
      },
      lab: {
        l: 75.77,
        a: -6.02,
        b: -18.77,
      },
      cmyk: null,
    },
    {
      code: '14-4209 TPG',
      name: 'Subdued Blue',
      hex: 'A7B5C8',
      rgb: {
        r: 167,
        g: 181,
        b: 200,
      },
      lab: {
        l: 73.04,
        a: -2.31,
        b: -11.28,
      },
      cmyk: null,
    },
    {
      code: '16-4118 TPG',
      name: 'Glacier Lake',
      hex: '84A2BB',
      rgb: {
        r: 132,
        g: 162,
        b: 187,
      },
      lab: {
        l: 64.96,
        a: -6.62,
        b: -16.82,
      },
      cmyk: null,
    },
    {
      code: '13-4111 TPG',
      name: 'Plein Air',
      hex: 'C4CED6',
      rgb: {
        r: 196,
        g: 206,
        b: 214,
      },
      lab: {
        l: 82.02,
        a: -1.95,
        b: -5.59,
      },
      cmyk: null,
    },
    {
      code: '13-4200 TPG',
      name: 'Omphalodes',
      hex: 'B3CBD9',
      rgb: {
        r: 179,
        g: 203,
        b: 217,
      },
      lab: {
        l: 80.04,
        a: -6.3,
        b: -10.16,
      },
      cmyk: null,
    },
    {
      code: '14-4317 TPG',
      name: 'Cool Blue',
      hex: 'A5C7DB',
      rgb: {
        r: 165,
        g: 199,
        b: 219,
      },
      lab: {
        l: 77.97,
        a: -8.8,
        b: -14.22,
      },
      cmyk: null,
    },
    {
      code: '14-4313 TPG',
      name: 'Aquamarine',
      hex: '9CC4D5',
      rgb: {
        r: 156,
        g: 196,
        b: 213,
      },
      lab: {
        l: 76.31,
        a: -11.27,
        b: -13.56,
      },
      cmyk: null,
    },
    {
      code: '15-4005 TPG',
      name: 'Dream Blue',
      hex: '9EBDD0',
      rgb: {
        r: 158,
        g: 189,
        b: 208,
      },
      lab: {
        l: 74.57,
        a: -7.82,
        b: -13.73,
      },
      cmyk: null,
    },
    {
      code: '14-4318 TPG',
      name: 'Sky Blue',
      hex: '8DBCD3',
      rgb: {
        r: 141,
        g: 188,
        b: 211,
      },
      lab: {
        l: 73.31,
        a: -12.44,
        b: -17.61,
      },
      cmyk: null,
    },
    {
      code: '15-4319 TPG',
      name: 'Air Blue',
      hex: '7CAFCA',
      rgb: {
        r: 124,
        g: 175,
        b: 202,
      },
      lab: {
        l: 68.23,
        a: -12.67,
        b: -20.05,
      },
      cmyk: null,
    },
    {
      code: '15-4105 TPG',
      name: 'Angel Falls',
      hex: '9CBAD4',
      rgb: {
        r: 156,
        g: 186,
        b: 212,
      },
      lab: {
        l: 73.8,
        a: -6.74,
        b: -16.91,
      },
      cmyk: null,
    },
    {
      code: '16-4120 TPG',
      name: 'Dusk Blue',
      hex: '7DA0BD',
      rgb: {
        r: 125,
        g: 160,
        b: 189,
      },
      lab: {
        l: 63.94,
        a: -7.54,
        b: -19.27,
      },
      cmyk: null,
    },
    {
      code: '16-4127 TPG',
      name: 'Heritage Blue',
      hex: '679BBE',
      rgb: {
        r: 103,
        g: 155,
        b: 190,
      },
      lab: {
        l: 60.98,
        a: -11.23,
        b: -24.58,
      },
      cmyk: null,
    },
    {
      code: '17-4123 TPG',
      name: 'Niagara',
      hex: '5D8DAA',
      rgb: {
        r: 93,
        g: 141,
        b: 170,
      },
      lab: {
        l: 55.83,
        a: -11.19,
        b: -21.27,
      },
      cmyk: null,
    },
    {
      code: '17-4131 TPG',
      name: 'Cendre Blue',
      hex: '4882AA',
      rgb: {
        r: 72,
        g: 130,
        b: 170,
      },
      lab: {
        l: 51.53,
        a: -11.24,
        b: -28.07,
      },
      cmyk: null,
    },
    {
      code: '18-4232 TPG',
      name: 'Faience',
      hex: '3A6F90',
      rgb: {
        r: 58,
        g: 111,
        b: 144,
      },
      lab: {
        l: 44.04,
        a: -11.13,
        b: -24.56,
      },
      cmyk: null,
    },
    {
      code: '18-4231 TPG',
      name: 'Blue Sapphire',
      hex: '2E6784',
      rgb: {
        r: 46,
        g: 103,
        b: 132,
      },
      lab: {
        l: 40.42,
        a: -11.83,
        b: -23.09,
      },
      cmyk: null,
    },
    {
      code: '17-4336 TPG',
      name: 'Blithe',
      hex: '2888BE',
      rgb: {
        r: 40,
        g: 136,
        b: 190,
      },
      lab: {
        l: 52.93,
        a: -14.54,
        b: -37.51,
      },
      cmyk: null,
    },
    {
      code: '17-4433 TPG',
      name: 'Dresden Blue',
      hex: '008AC0',
      rgb: {
        r: 0,
        g: 138,
        b: 192,
      },
      lab: {
        l: 52.39,
        a: -19.31,
        b: -39.53,
      },
      cmyk: null,
    },
    {
      code: '18-4334 TPG',
      name: 'Mediterranian Blue',
      hex: '2E7BA8',
      rgb: {
        r: 46,
        g: 123,
        b: 168,
      },
      lab: {
        l: 48.18,
        a: -13.31,
        b: -31.97,
      },
      cmyk: null,
    },
    {
      code: '18-4330 TPG',
      name: 'Swedish Blue',
      hex: '2982B1',
      rgb: {
        r: 41,
        g: 130,
        b: 177,
      },
      lab: {
        l: 50.41,
        a: -14.89,
        b: -33.73,
      },
      cmyk: null,
    },
    {
      code: '18-4440 TPG',
      name: 'Cloisonné',
      hex: '0081B8',
      rgb: {
        r: 0,
        g: 129,
        b: 184,
      },
      lab: {
        l: 49.34,
        a: -16.6,
        b: -40.11,
      },
      cmyk: null,
    },
    {
      code: '17-4247 TPG',
      name: 'Diva Blue',
      hex: '0084C0',
      rgb: {
        r: 0,
        g: 132,
        b: 192,
      },
      lab: {
        l: 50.63,
        a: -16.84,
        b: -42.54,
      },
      cmyk: null,
    },
    {
      code: '18-4434 TPG',
      name: 'Mykonos Blue',
      hex: '035F88',
      rgb: {
        r: 3,
        g: 95,
        b: 136,
      },
      lab: {
        l: 36.79,
        a: -11.3,
        b: -31.31,
      },
      cmyk: null,
    },
    {
      code: '15-4427 TPG',
      name: 'Norse Blue',
      hex: '56A8CB',
      rgb: {
        r: 86,
        g: 168,
        b: 203,
      },
      lab: {
        l: 64.41,
        a: -18.93,
        b: -26.76,
      },
      cmyk: null,
    },
    {
      code: '17-4540 TPG',
      name: 'Hawaiian Ocean',
      hex: '0099C9',
      rgb: {
        r: 0,
        g: 153,
        b: 201,
      },
      lab: {
        l: 56.18,
        a: -31.04,
        b: -39.29,
      },
      cmyk: null,
    },
    {
      code: '17-4432 TPG',
      name: 'Vivid Blue',
      hex: '008CB5',
      rgb: {
        r: 0,
        g: 140,
        b: 181,
      },
      lab: {
        l: 52.74,
        a: -22.55,
        b: -32.98,
      },
      cmyk: null,
    },
    {
      code: '17-4440 TPG',
      name: 'Blue Danube',
      hex: '008AB6',
      rgb: {
        r: 0,
        g: 138,
        b: 182,
      },
      lab: {
        l: 51.95,
        a: -23.29,
        b: -35.21,
      },
      cmyk: null,
    },
    {
      code: '18-4535 TPG',
      name: 'Blue Jewel',
      hex: '0086B6',
      rgb: {
        r: 0,
        g: 134,
        b: 182,
      },
      lab: {
        l: 49.43,
        a: -27.3,
        b: -39.07,
      },
      cmyk: null,
    },
    {
      code: '18-4538 TPG',
      name: 'Hawaiian Surf',
      hex: '007FAF',
      rgb: {
        r: 0,
        g: 127,
        b: 175,
      },
      lab: {
        l: 47.19,
        a: -23.11,
        b: -38.51,
      },
      cmyk: null,
    },
    {
      code: '18-4537 TPG',
      name: 'Methyl Blue',
      hex: '007DAD',
      rgb: {
        r: 0,
        g: 125,
        b: 173,
      },
      lab: {
        l: 47.3,
        a: -19.06,
        b: -37.05,
      },
      cmyk: null,
    },
    {
      code: '14-4514 TPG',
      name: 'Atomizer',
      hex: '9AD7EB',
      rgb: {
        r: 154,
        g: 215,
        b: 235,
      },
      lab: {
        l: 81.95,
        a: -16.72,
        b: -17.52,
      },
      cmyk: null,
    },
    {
      code: '16-4520 TPG',
      name: 'Splish Splash',
      hex: '67BEDF',
      rgb: {
        r: 103,
        g: 190,
        b: 223,
      },
      lab: {
        l: 72.09,
        a: -20.62,
        b: -26.37,
      },
      cmyk: null,
    },
    {
      code: '15-4421 TPG',
      name: 'Blue Grotto',
      hex: '64AED0',
      rgb: {
        r: 100,
        g: 174,
        b: 208,
      },
      lab: {
        l: 66.93,
        a: -17.49,
        b: -25.87,
      },
      cmyk: null,
    },
    {
      code: '15-4428 TPG',
      name: 'Crystal Seas',
      hex: '60B1D2',
      rgb: {
        r: 96,
        g: 177,
        b: 210,
      },
      lab: {
        l: 67.78,
        a: -19.12,
        b: -25.94,
      },
      cmyk: null,
    },
    {
      code: '16-4526 TPG',
      name: 'Swim Cap',
      hex: '41A9D4',
      rgb: {
        r: 65,
        g: 169,
        b: 212,
      },
      lab: {
        l: 64.05,
        a: -20.77,
        b: -32.5,
      },
      cmyk: null,
    },
    {
      code: '16-4530 TPG',
      name: 'Aquarius',
      hex: '42AED5',
      rgb: {
        r: 66,
        g: 174,
        b: 213,
      },
      lab: {
        l: 65.57,
        a: -22.77,
        b: -30.6,
      },
      cmyk: null,
    },
    {
      code: '16-4529 TPG',
      name: 'Cyan Blue',
      hex: '23A3C7',
      rgb: {
        r: 35,
        g: 163,
        b: 199,
      },
      lab: {
        l: 61.21,
        a: -25.07,
        b: -30.05,
      },
      cmyk: null,
    },
    {
      code: '13-4307 TPG',
      name: 'Tropical Breeze',
      hex: '88CFE7',
      rgb: {
        r: 136,
        g: 207,
        b: 231,
      },
      lab: {
        l: 78.61,
        a: -18.62,
        b: -20.34,
      },
      cmyk: null,
    },
    {
      code: '14-4310 TPG',
      name: 'Blue Topaz',
      hex: '7BBED4',
      rgb: {
        r: 123,
        g: 190,
        b: 212,
      },
      lab: {
        l: 72.74,
        a: -18.21,
        b: -19.15,
      },
      cmyk: null,
    },
    {
      code: '14-4522 TPG',
      name: 'Bachelor Button',
      hex: '4DB9D6',
      rgb: {
        r: 77,
        g: 185,
        b: 214,
      },
      lab: {
        l: 69.39,
        a: -25.91,
        b: -25.56,
      },
      cmyk: null,
    },
    {
      code: '15-4720 TPG',
      name: 'River Blue',
      hex: '44B0CD',
      rgb: {
        r: 68,
        g: 176,
        b: 205,
      },
      lab: {
        l: 66.25,
        a: -25.45,
        b: -25.35,
      },
      cmyk: null,
    },
    {
      code: '16-4535 TPG',
      name: 'Blue Atoll',
      hex: '00B6D7',
      rgb: {
        r: 0,
        g: 182,
        b: 215,
      },
      lab: {
        l: 66.62,
        a: -33.33,
        b: -30.85,
      },
      cmyk: null,
    },
    {
      code: '16-4427 TPG',
      name: 'Horizon Blue',
      hex: '37A1C2',
      rgb: {
        r: 55,
        g: 161,
        b: 194,
      },
      lab: {
        l: 60.87,
        a: -23.34,
        b: -27.4,
      },
      cmyk: null,
    },
    {
      code: '17-4427 TPG',
      name: 'Bluejay',
      hex: '3085A8',
      rgb: {
        r: 48,
        g: 133,
        b: 168,
      },
      lab: {
        l: 51.35,
        a: -17.9,
        b: -27.21,
      },
      cmyk: null,
    },
    {
      code: '13-4410 TPG',
      name: 'Cooling Spray',
      hex: 'C7E3EE',
      rgb: {
        r: 199,
        g: 227,
        b: 238,
      },
      lab: {
        l: 88.2,
        a: -7.94,
        b: -9.05,
      },
      cmyk: null,
    },
    {
      code: '13-4401 TPG',
      name: 'Quiet Tide',
      hex: 'C3E5F0',
      rgb: {
        r: 195,
        g: 229,
        b: 240,
      },
      lab: {
        l: 88.53,
        a: -10.06,
        b: -9.96,
      },
      cmyk: null,
    },
    {
      code: '12-4401 TPG',
      name: 'Spun Sugar',
      hex: 'B8DEEC',
      rgb: {
        r: 184,
        g: 222,
        b: 236,
      },
      lab: {
        l: 85.92,
        a: -11.17,
        b: -11.63,
      },
      cmyk: null,
    },
    {
      code: '14-4311 TPG',
      name: 'Corydalis Blue',
      hex: 'ADCFDF',
      rgb: {
        r: 173,
        g: 207,
        b: 223,
      },
      lab: {
        l: 80.8,
        a: -9.46,
        b: -12.19,
      },
      cmyk: null,
    },
    {
      code: '13-4411 TPG',
      name: 'Crystal Blue',
      hex: 'A0C9DA',
      rgb: {
        r: 160,
        g: 201,
        b: 218,
      },
      lab: {
        l: 78.29,
        a: -11.63,
        b: -13.84,
      },
      cmyk: null,
    },
    {
      code: '14-4516 TPG',
      name: 'Petit four',
      hex: '86C3D5',
      rgb: {
        r: 134,
        g: 195,
        b: 213,
      },
      lab: {
        l: 74.88,
        a: -17.52,
        b: -16.31,
      },
      cmyk: null,
    },
    {
      code: '16-4421 TPG',
      name: 'Blue Mist',
      hex: '5DADC7',
      rgb: {
        r: 93,
        g: 173,
        b: 199,
      },
      lab: {
        l: 66.13,
        a: -20.71,
        b: -22.09,
      },
      cmyk: null,
    },
    {
      code: '18-4430 TPG',
      name: 'Fjord Blue',
      hex: '007493',
      rgb: {
        r: 0,
        g: 116,
        b: 147,
      },
      lab: {
        l: 43.97,
        a: -20.12,
        b: -26.87,
      },
      cmyk: null,
    },
    {
      code: '18-4528 TPG',
      name: 'Mosaic Blue',
      hex: '0E7893',
      rgb: {
        r: 14,
        g: 120,
        b: 147,
      },
      lab: {
        l: 45.73,
        a: -20.87,
        b: -24.12,
      },
      cmyk: null,
    },
    {
      code: '18-4432 TPG',
      name: 'Turkish Tile',
      hex: '056E8F',
      rgb: {
        r: 5,
        g: 110,
        b: 143,
      },
      lab: {
        l: 42.11,
        a: -17.35,
        b: -27.28,
      },
      cmyk: null,
    },
    {
      code: '18-4530 TPG',
      name: 'Celestial',
      hex: '2B6F8D',
      rgb: {
        r: 43,
        g: 111,
        b: 141,
      },
      lab: {
        l: 43.1,
        a: -14.24,
        b: -24.27,
      },
      cmyk: null,
    },
    {
      code: '18-4225 TPG',
      name: 'Saxony Blue',
      hex: '3C6B82',
      rgb: {
        r: 60,
        g: 107,
        b: 130,
      },
      lab: {
        l: 42.3,
        a: -11.68,
        b: -18.57,
      },
      cmyk: null,
    },
    {
      code: '19-4342 TPG',
      name: 'Seaport',
      hex: '136383',
      rgb: {
        r: 19,
        g: 99,
        b: 131,
      },
      lab: {
        l: 38.33,
        a: -14.53,
        b: -25.52,
      },
      cmyk: null,
    },
    {
      code: '19-4329 TPG',
      name: 'Corsair',
      hex: '2D5F74',
      rgb: {
        r: 45,
        g: 95,
        b: 116,
      },
      lab: {
        l: 37.13,
        a: -12.31,
        b: -18.07,
      },
      cmyk: null,
    },
    {
      code: '17-4328 TPG',
      name: 'Blue Moon',
      hex: '4088A6',
      rgb: {
        r: 64,
        g: 136,
        b: 166,
      },
      lab: {
        l: 52.59,
        a: -16.74,
        b: -24.21,
      },
      cmyk: null,
    },
    {
      code: '17-4429 TPG',
      name: 'Navagio Bay',
      hex: '3F86A3',
      rgb: {
        r: 63,
        g: 134,
        b: 163,
      },
      lab: {
        l: 51.95,
        a: -16.71,
        b: -23.59,
      },
      cmyk: null,
    },
    {
      code: '18-4630 TPG',
      name: 'Tahitian Tide',
      hex: '007286',
      rgb: {
        r: 0,
        g: 114,
        b: 134,
      },
      lab: {
        l: 43.02,
        a: -22.15,
        b: -20.78,
      },
      cmyk: null,
    },
    {
      code: '19-4536 TPG',
      name: 'Crystal Teal',
      hex: '006883',
      rgb: {
        r: 0,
        g: 104,
        b: 131,
      },
      lab: {
        l: 39.32,
        a: -20.27,
        b: -24.61,
      },
      cmyk: null,
    },
    {
      code: '19-4540 TPG',
      name: 'Deep Lagoon',
      hex: '185E6F',
      rgb: {
        r: 24,
        g: 94,
        b: 111,
      },
      lab: {
        l: 35.94,
        a: -16.69,
        b: -17.12,
      },
      cmyk: null,
    },
    {
      code: '19-4535 TPG',
      name: 'Ocean Depths',
      hex: '066A7D',
      rgb: {
        r: 6,
        g: 106,
        b: 125,
      },
      lab: {
        l: 40.15,
        a: -20.3,
        b: -19.74,
      },
      cmyk: null,
    },
    {
      code: '19-4340 TPG',
      name: 'Lyons Blue',
      hex: '28637C',
      rgb: {
        r: 40,
        g: 99,
        b: 124,
      },
      lab: {
        l: 38.53,
        a: -12.89,
        b: -21.33,
      },
      cmyk: null,
    },
    {
      code: '16-4525 TPG',
      name: 'Maui Blue',
      hex: '55A4B9',
      rgb: {
        r: 85,
        g: 164,
        b: 185,
      },
      lab: {
        l: 62.54,
        a: -21.03,
        b: -19.7,
      },
      cmyk: null,
    },
    {
      code: '17-4728 TPG',
      name: 'Algiers Blue',
      hex: '00879F',
      rgb: {
        r: 0,
        g: 135,
        b: 159,
      },
      lab: {
        l: 50.32,
        a: -26.46,
        b: -24.01,
      },
      cmyk: null,
    },
    {
      code: '17-4530 TPG',
      name: 'Barrier Reef',
      hex: '0088A5',
      rgb: {
        r: 0,
        g: 136,
        b: 165,
      },
      lab: {
        l: 51.11,
        a: -24.91,
        b: -26.42,
      },
      cmyk: null,
    },
    {
      code: '17-4730 TPG',
      name: 'Caneel Bay',
      hex: '0086A3',
      rgb: {
        r: 0,
        g: 134,
        b: 163,
      },
      lab: {
        l: 50.22,
        a: -26.15,
        b: -26.63,
      },
      cmyk: null,
    },
    {
      code: '18-4525 TPG',
      name: 'Caribbean Sea',
      hex: '0085A3',
      rgb: {
        r: 0,
        g: 133,
        b: 163,
      },
      lab: {
        l: 49.38,
        a: -27.97,
        b: -27.91,
      },
      cmyk: null,
    },
    {
      code: '17-4735 TPG',
      name: 'Capri Breeze',
      hex: '008BA0',
      rgb: {
        r: 0,
        g: 139,
        b: 160,
      },
      lab: {
        l: 50.87,
        a: -31.76,
        b: -24.53,
      },
      cmyk: null,
    },
    {
      code: '18-4733 TPG',
      name: 'Enamel Blue',
      hex: '007F95',
      rgb: {
        r: 0,
        g: 127,
        b: 149,
      },
      lab: {
        l: 46.87,
        a: -28.83,
        b: -23.77,
      },
      cmyk: null,
    },
    {
      code: '12-4608 TPG',
      name: 'Clearwater',
      hex: 'ABD6DF',
      rgb: {
        r: 171,
        g: 214,
        b: 223,
      },
      lab: {
        l: 82.6,
        a: -13.43,
        b: -9.93,
      },
      cmyk: null,
    },
    {
      code: '14-4816 TPG',
      name: 'Blue Radiance',
      hex: '64C8D2',
      rgb: {
        r: 100,
        g: 200,
        b: 210,
      },
      lab: {
        l: 74.36,
        a: -28.46,
        b: -16.22,
      },
      cmyk: null,
    },
    {
      code: '15-4722 TPG',
      name: 'Capri',
      hex: '49BBCA',
      rgb: {
        r: 73,
        g: 187,
        b: 202,
      },
      lab: {
        l: 69.38,
        a: -30.06,
        b: -19.13,
      },
      cmyk: null,
    },
    {
      code: '15-4825 TPG',
      name: 'Blue Curacao',
      hex: '36BAC6',
      rgb: {
        r: 54,
        g: 186,
        b: 198,
      },
      lab: {
        l: 68.5,
        a: -33.28,
        b: -18.58,
      },
      cmyk: null,
    },
    {
      code: '16-4725 TPG',
      name: 'Scuba Blue',
      hex: '00AFC6',
      rgb: {
        r: 0,
        g: 175,
        b: 198,
      },
      lab: {
        l: 63.57,
        a: -36.43,
        b: -26.16,
      },
      cmyk: null,
    },
    {
      code: '16-4834 TPG',
      name: 'Bluebird',
      hex: '00A1B4',
      rgb: {
        r: 0,
        g: 161,
        b: 180,
      },
      lab: {
        l: 58.66,
        a: -37.24,
        b: -23.78,
      },
      cmyk: null,
    },
    {
      code: '16-4728 TPG',
      name: 'Peacock Blue',
      hex: '00A4B7',
      rgb: {
        r: 0,
        g: 164,
        b: 183,
      },
      lab: {
        l: 60.16,
        a: -33.75,
        b: -22.78,
      },
      cmyk: null,
    },
    {
      code: '13-4409 TPG',
      name: 'Blue Glow',
      hex: 'ABD2DC',
      rgb: {
        r: 171,
        g: 210,
        b: 220,
      },
      lab: {
        l: 81.42,
        a: -11.96,
        b: -10.03,
      },
      cmyk: null,
    },
    {
      code: '14-4510 TPG',
      name: 'Aquatic',
      hex: '97C0CC',
      rgb: {
        r: 151,
        g: 192,
        b: 204,
      },
      lab: {
        l: 74.92,
        a: -12.68,
        b: -11.1,
      },
      cmyk: null,
    },
    {
      code: '14-4315 TPG',
      name: 'Sea Angel',
      hex: '9AC1CD',
      rgb: {
        r: 154,
        g: 193,
        b: 205,
      },
      lab: {
        l: 75.22,
        a: -11.82,
        b: -11.04,
      },
      cmyk: null,
    },
    {
      code: '13-5410 TPG',
      name: 'Iced Aqua',
      hex: 'AED6DF',
      rgb: {
        r: 174,
        g: 214,
        b: 223,
      },
      lab: {
        l: 82.8,
        a: -12.21,
        b: -9.81,
      },
      cmyk: null,
    },
    {
      code: '14-4508 TPG',
      name: 'Stratosphere',
      hex: '9ABFCA',
      rgb: {
        r: 154,
        g: 191,
        b: 202,
      },
      lab: {
        l: 74.62,
        a: -10.9,
        b: -10.72,
      },
      cmyk: null,
    },
    {
      code: '14-4512 TPG',
      name: 'Porcelain Blue',
      hex: '90C0CC',
      rgb: {
        r: 144,
        g: 192,
        b: 204,
      },
      lab: {
        l: 74.21,
        a: -14.53,
        b: -12.12,
      },
      cmyk: null,
    },
    {
      code: '15-4415 TPG',
      name: 'Milky Blue',
      hex: '74A9BD',
      rgb: {
        r: 116,
        g: 169,
        b: 189,
      },
      lab: {
        l: 65.86,
        a: -15,
        b: -16.65,
      },
      cmyk: null,
    },
    {
      code: '13-4304 TPG',
      name: 'Ballad Blue',
      hex: 'BCCCDA',
      rgb: {
        r: 188,
        g: 204,
        b: 218,
      },
      lab: {
        l: 81.15,
        a: -3.82,
        b: -8.95,
      },
      cmyk: null,
    },
    {
      code: '13-4308 TPG',
      name: 'Baby Blue',
      hex: 'B3C7D5',
      rgb: {
        r: 179,
        g: 199,
        b: 213,
      },
      lab: {
        l: 79.04,
        a: -5.14,
        b: -9.68,
      },
      cmyk: null,
    },
    {
      code: '15-4312 TPG',
      name: 'Forget-Me-Not',
      hex: '8EAEBF',
      rgb: {
        r: 142,
        g: 174,
        b: 191,
      },
      lab: {
        l: 68.84,
        a: -8.41,
        b: -13.21,
      },
      cmyk: null,
    },
    {
      code: '16-4519 TPG',
      name: 'Delphinium Blue',
      hex: '669DB2',
      rgb: {
        r: 102,
        g: 157,
        b: 178,
      },
      lab: {
        l: 61.34,
        a: -15.32,
        b: -17.67,
      },
      cmyk: null,
    },
    {
      code: '17-4320 TPG',
      name: 'Adriatic Blue',
      hex: '638D9F',
      rgb: {
        r: 99,
        g: 141,
        b: 159,
      },
      lab: {
        l: 55.78,
        a: -11.94,
        b: -14.79,
      },
      cmyk: null,
    },
    {
      code: '17-4421 TPG',
      name: 'Larkspur',
      hex: '427C93',
      rgb: {
        r: 66,
        g: 124,
        b: 147,
      },
      lab: {
        l: 48.57,
        a: -14.19,
        b: -19.47,
      },
      cmyk: null,
    },
    {
      code: '17-4716 TPG',
      name: 'Storm Blue',
      hex: '4C7D8F',
      rgb: {
        r: 76,
        g: 125,
        b: 143,
      },
      lab: {
        l: 49.3,
        a: -13.99,
        b: -15.43,
      },
      cmyk: null,
    },
    {
      code: '16-4109 TPG',
      name: 'Arona',
      hex: '859BA6',
      rgb: {
        r: 133,
        g: 155,
        b: 166,
      },
      lab: {
        l: 62.36,
        a: -5.89,
        b: -9.14,
      },
      cmyk: null,
    },
    {
      code: '17-4412 TPG',
      name: 'Smoke Blue',
      hex: '6A8B95',
      rgb: {
        r: 106,
        g: 139,
        b: 149,
      },
      lab: {
        l: 55.57,
        a: -10.63,
        b: -9.3,
      },
      cmyk: null,
    },
    {
      code: '17-4111 TPG',
      name: 'Citadel',
      hex: '758B98',
      rgb: {
        r: 117,
        g: 139,
        b: 152,
      },
      lab: {
        l: 56.23,
        a: -5.4,
        b: -10.34,
      },
      cmyk: null,
    },
    {
      code: '18-4417 TPG',
      name: 'Tapestry',
      hex: '4A6A78',
      rgb: {
        r: 74,
        g: 106,
        b: 120,
      },
      lab: {
        l: 42.51,
        a: -9.13,
        b: -12.69,
      },
      cmyk: null,
    },
    {
      code: '19-4526 TPG',
      name: 'Blue Coral',
      hex: '2D5C6C',
      rgb: {
        r: 45,
        g: 92,
        b: 108,
      },
      lab: {
        l: 35.94,
        a: -12.38,
        b: -15.48,
      },
      cmyk: null,
    },
    {
      code: '19-4324 TPG',
      name: 'Legion Blue',
      hex: '385466',
      rgb: {
        r: 56,
        g: 84,
        b: 102,
      },
      lab: {
        l: 34,
        a: -7.15,
        b: -14.17,
      },
      cmyk: null,
    },
    {
      code: '19-4326 TPG',
      name: 'Reflecting Pond',
      hex: '354957',
      rgb: {
        r: 53,
        g: 73,
        b: 87,
      },
      lab: {
        l: 29.51,
        a: -4.76,
        b: -11.48,
      },
      cmyk: null,
    },
    {
      code: '13-4404 TPG',
      name: 'Ice Flow',
      hex: 'C7D3D4',
      rgb: {
        r: 199,
        g: 211,
        b: 212,
      },
      lab: {
        l: 83.54,
        a: -4.02,
        b: -2.29,
      },
      cmyk: null,
    },
    {
      code: '13-4103 TPG',
      name: 'Illusion Blue',
      hex: 'C9D6DD',
      rgb: {
        r: 201,
        g: 214,
        b: 221,
      },
      lab: {
        l: 84.77,
        a: -3.77,
        b: -5.03,
      },
      cmyk: null,
    },
    {
      code: '14-4306 TPG',
      name: 'Cloud Blue',
      hex: '9DB5B9',
      rgb: {
        r: 157,
        g: 181,
        b: 185,
      },
      lab: {
        l: 71.94,
        a: -8.28,
        b: -5.13,
      },
      cmyk: null,
    },
    {
      code: '14-4506 TPG',
      name: 'Ether',
      hex: 'A2B9BC',
      rgb: {
        r: 162,
        g: 185,
        b: 188,
      },
      lab: {
        l: 73.2,
        a: -7.8,
        b: -4.89,
      },
      cmyk: null,
    },
    {
      code: '16-4411 TPG',
      name: 'Tourmaline',
      hex: '86A3AC',
      rgb: {
        r: 134,
        g: 163,
        b: 172,
      },
      lab: {
        l: 64.9,
        a: -9.02,
        b: -8.68,
      },
      cmyk: null,
    },
    {
      code: '16-4114 TPG',
      name: 'Stone Blue',
      hex: '809CA7',
      rgb: {
        r: 128,
        g: 156,
        b: 167,
      },
      lab: {
        l: 62.4,
        a: -8.63,
        b: -9.58,
      },
      cmyk: null,
    },
    {
      code: '16-4414 TPG',
      name: 'Cameo Blue',
      hex: '78A0AB',
      rgb: {
        r: 120,
        g: 160,
        b: 171,
      },
      lab: {
        l: 62.99,
        a: -12.41,
        b: -10.68,
      },
      cmyk: null,
    },
    {
      code: '14-4307 TPG',
      name: 'Winter Sky',
      hex: 'A5BDCA',
      rgb: {
        r: 165,
        g: 189,
        b: 202,
      },
      lab: {
        l: 74.9,
        a: -6.35,
        b: -10.07,
      },
      cmyk: null,
    },
    {
      code: '15-4309 TPG',
      name: 'Sterling Blue',
      hex: '9DB9C3',
      rgb: {
        r: 157,
        g: 185,
        b: 195,
      },
      lab: {
        l: 73.14,
        a: -8.07,
        b: -8.82,
      },
      cmyk: null,
    },
    {
      code: '16-4612 TPG',
      name: 'Reef Waters',
      hex: '6C9EAA',
      rgb: {
        r: 108,
        g: 158,
        b: 170,
      },
      lab: {
        l: 61.7,
        a: -15.63,
        b: -12.42,
      },
      cmyk: null,
    },
    {
      code: '16-4610 TPG',
      name: 'Stillwater',
      hex: '6EA2AF',
      rgb: {
        r: 110,
        g: 162,
        b: 175,
      },
      lab: {
        l: 63.06,
        a: -16.07,
        b: -13.14,
      },
      cmyk: null,
    },
    {
      code: '15-4717 TPG',
      name: 'Aqua',
      hex: '69A1B0',
      rgb: {
        r: 105,
        g: 161,
        b: 176,
      },
      lab: {
        l: 62.61,
        a: -16.84,
        b: -14.31,
      },
      cmyk: null,
    },
    {
      code: '16-5121 TPG',
      name: 'Meadowbrook',
      hex: '5A9AA1',
      rgb: {
        r: 90,
        g: 154,
        b: 161,
      },
      lab: {
        l: 59.31,
        a: -20.25,
        b: -11.19,
      },
      cmyk: null,
    },
    {
      code: '16-4719 TPG',
      name: 'Porcelain',
      hex: '5A9BA3',
      rgb: {
        r: 90,
        g: 155,
        b: 163,
      },
      lab: {
        l: 59.52,
        a: -20.31,
        b: -12.09,
      },
      cmyk: null,
    },
    {
      code: '12-4609 TPG',
      name: 'Starlight Blue',
      hex: 'B4CFD9',
      rgb: {
        r: 180,
        g: 207,
        b: 217,
      },
      lab: {
        l: 81.14,
        a: -7.99,
        b: -8.67,
      },
      cmyk: null,
    },
    {
      code: '13-4809 TPG',
      name: 'Plume',
      hex: 'A4D1D9',
      rgb: {
        r: 164,
        g: 209,
        b: 217,
      },
      lab: {
        l: 80.6,
        a: -14.24,
        b: -9.59,
      },
      cmyk: null,
    },
    {
      code: '15-4712 TPG',
      name: 'Marine Blue',
      hex: '6FADB6',
      rgb: {
        r: 111,
        g: 173,
        b: 182,
      },
      lab: {
        l: 66.3,
        a: -19.15,
        b: -12.21,
      },
      cmyk: null,
    },
    {
      code: '15-4714 TPG',
      name: 'Aquarelle',
      hex: '67ADB5',
      rgb: {
        r: 103,
        g: 173,
        b: 181,
      },
      lab: {
        l: 65.84,
        a: -21.57,
        b: -12.73,
      },
      cmyk: null,
    },
    {
      code: '15-4715 TPG',
      name: 'Aqua Sea',
      hex: '6CAEB4',
      rgb: {
        r: 108,
        g: 174,
        b: 180,
      },
      lab: {
        l: 66.43,
        a: -20.7,
        b: -10.91,
      },
      cmyk: null,
    },
    {
      code: '15-5209 TPG',
      name: 'Aqua Haze',
      hex: '84B7BB',
      rgb: {
        r: 132,
        g: 183,
        b: 187,
      },
      lab: {
        l: 70.68,
        a: -17.06,
        b: -8.28,
      },
      cmyk: null,
    },
    {
      code: '15-5210 TPG',
      name: 'Nile Blue',
      hex: '7AA7AC',
      rgb: {
        r: 122,
        g: 167,
        b: 172,
      },
      lab: {
        l: 65.23,
        a: -15,
        b: -8.44,
      },
      cmyk: null,
    },
    {
      code: '12-4611 TPG',
      name: 'Saltwater Slide',
      hex: 'CEE7EF',
      rgb: {
        r: 206,
        g: 231,
        b: 239,
      },
      lab: {
        l: 89.91,
        a: -7.62,
        b: -7.23,
      },
      cmyk: null,
    },
    {
      code: '13-5411 TPG',
      name: 'Aqua-esque',
      hex: 'A6CFD8',
      rgb: {
        r: 166,
        g: 207,
        b: 216,
      },
      lab: {
        l: 80.21,
        a: -12.92,
        b: -9.76,
      },
      cmyk: null,
    },
    {
      code: '14-4515 TPG',
      name: 'Blue Elixir',
      hex: '8BCDDF',
      rgb: {
        r: 139,
        g: 205,
        b: 223,
      },
      lab: {
        l: 78.14,
        a: -18.54,
        b: -16.81,
      },
      cmyk: null,
    },
    {
      code: '14-4513 TPG',
      name: 'Cabana Blue',
      hex: '7BB8CA',
      rgb: {
        r: 123,
        g: 184,
        b: 202,
      },
      lab: {
        l: 70.74,
        a: -17.23,
        b: -16.78,
      },
      cmyk: null,
    },
    {
      code: '14-4511 TPG',
      name: 'Gulf Stream',
      hex: '8DC6D4',
      rgb: {
        r: 141,
        g: 198,
        b: 212,
      },
      lab: {
        l: 75.96,
        a: -16.92,
        b: -14.2,
      },
      cmyk: null,
    },
    {
      code: '15-4710 TPG',
      name: 'Barbados Beach',
      hex: '64B6C3',
      rgb: {
        r: 100,
        g: 182,
        b: 195,
      },
      lab: {
        l: 68.69,
        a: -23.7,
        b: -16.1,
      },
      cmyk: null,
    },
    {
      code: '15-4716 TPG',
      name: 'Ipanema',
      hex: '67BCC5',
      rgb: {
        r: 103,
        g: 188,
        b: 197,
      },
      lab: {
        l: 70.8,
        a: -25.57,
        b: -14.05,
      },
      cmyk: null,
    },
    {
      code: '14-4620 TPG',
      name: 'Island Paradise',
      hex: '95DEE1',
      rgb: {
        r: 149,
        g: 222,
        b: 225,
      },
      lab: {
        l: 83.31,
        a: -23.19,
        b: -10.54,
      },
      cmyk: null,
    },
    {
      code: '13-4720 TPG',
      name: 'Tanager Turquoise',
      hex: '95DBE5',
      rgb: {
        r: 149,
        g: 219,
        b: 229,
      },
      lab: {
        l: 82.78,
        a: -21.4,
        b: -13,
      },
      cmyk: null,
    },
    {
      code: '14-4812 TPG',
      name: 'Aqua Splash',
      hex: '85D0D1',
      rgb: {
        r: 133,
        g: 208,
        b: 209,
      },
      lab: {
        l: 78.31,
        a: -24.35,
        b: -9.38,
      },
      cmyk: null,
    },
    {
      code: '13-4810 TPG',
      name: 'Limpet Shell',
      hex: '99DEDF',
      rgb: {
        r: 153,
        g: 222,
        b: 223,
      },
      lab: {
        l: 83.66,
        a: -22.42,
        b: -8.8,
      },
      cmyk: null,
    },
    {
      code: '14-4710 TPG',
      name: 'Tibetan Stone',
      hex: '83C5CA',
      rgb: {
        r: 131,
        g: 197,
        b: 202,
      },
      lab: {
        l: 74.93,
        a: -20.9,
        b: -10.4,
      },
      cmyk: null,
    },
    {
      code: '14-4814 TPG',
      name: 'Angel Blue',
      hex: '83C6CF',
      rgb: {
        r: 131,
        g: 198,
        b: 207,
      },
      lab: {
        l: 75.31,
        a: -20.95,
        b: -12.49,
      },
      cmyk: null,
    },
    {
      code: '14-4615 TPG',
      name: 'Antigua Sand',
      hex: '83C4CF',
      rgb: {
        r: 131,
        g: 196,
        b: 207,
      },
      lab: {
        l: 74.79,
        a: -19.74,
        b: -13.24,
      },
      cmyk: null,
    },
    {
      code: '14-4618 TPG',
      name: 'Waterspout',
      hex: '93DBE0',
      rgb: {
        r: 147,
        g: 219,
        b: 224,
      },
      lab: {
        l: 82.46,
        a: -22.54,
        b: -11.12,
      },
      cmyk: null,
    },
    {
      code: '14-4818 TPG',
      name: 'Amazonite',
      hex: '78BABE',
      rgb: {
        r: 120,
        g: 186,
        b: 190,
      },
      lab: {
        l: 70.92,
        a: -21.64,
        b: -9.87,
      },
      cmyk: null,
    },
    {
      code: '14-4815 TPG',
      name: 'Leisure Time',
      hex: '80BFC5',
      rgb: {
        r: 128,
        g: 191,
        b: 197,
      },
      lab: {
        l: 73.02,
        a: -20.56,
        b: -10.49,
      },
      cmyk: null,
    },
    {
      code: '15-4711 TPG',
      name: 'Coastal Shade',
      hex: '6CA9B0',
      rgb: {
        r: 108,
        g: 169,
        b: 176,
      },
      lab: {
        l: 64.93,
        a: -19.51,
        b: -11.24,
      },
      cmyk: null,
    },
    {
      code: '15-4713 TPG',
      name: 'Sea Jet',
      hex: '56AFBB',
      rgb: {
        r: 86,
        g: 175,
        b: 187,
      },
      lab: {
        l: 66.03,
        a: -25.7,
        b: -15.91,
      },
      cmyk: null,
    },
    {
      code: '17-4725 TPG',
      name: 'Tahitian Teal',
      hex: '00899C',
      rgb: {
        r: 0,
        g: 137,
        b: 156,
      },
      lab: {
        l: 50.86,
        a: -28.89,
        b: -21.49,
      },
      cmyk: null,
    },
    {
      code: '18-4631 TPG',
      name: 'Exotic Plume',
      hex: '007384',
      rgb: {
        r: 0,
        g: 115,
        b: 132,
      },
      lab: {
        l: 43.11,
        a: -25.06,
        b: -19.24,
      },
      cmyk: null,
    },
    {
      code: '17-4928 TPG',
      name: 'Lake Blue',
      hex: '008E9A',
      rgb: {
        r: 0,
        g: 142,
        b: 154,
      },
      lab: {
        l: 52.2,
        a: -32.35,
        b: -18.65,
      },
      cmyk: null,
    },
    {
      code: '18-4735 TPG',
      name: 'Tile Blue',
      hex: '008D9B',
      rgb: {
        r: 0,
        g: 141,
        b: 155,
      },
      lab: {
        l: 51.51,
        a: -34.53,
        b: -20.45,
      },
      cmyk: null,
    },
    {
      code: '18-4726 TPG',
      name: 'Biscay Bay',
      hex: '257A88',
      rgb: {
        r: 37,
        g: 122,
        b: 136,
      },
      lab: {
        l: 46.49,
        a: -22.28,
        b: -16.54,
      },
      cmyk: null,
    },
    {
      code: '17-4724 TPG',
      name: 'Pagoda Blue',
      hex: '2F8393',
      rgb: {
        r: 47,
        g: 131,
        b: 147,
      },
      lab: {
        l: 49.76,
        a: -21.98,
        b: -17.72,
      },
      cmyk: null,
    },
    {
      code: '18-4728 TPG',
      name: 'Harbor Blue',
      hex: '16737F',
      rgb: {
        r: 22,
        g: 115,
        b: 127,
      },
      lab: {
        l: 43.38,
        a: -23.19,
        b: -15.56,
      },
      cmyk: null,
    },
    {
      code: '19-4524 TPG',
      name: 'Shaded Spruce',
      hex: '286168',
      rgb: {
        r: 40,
        g: 97,
        b: 104,
      },
      lab: {
        l: 37.21,
        a: -17.21,
        b: -11.16,
      },
      cmyk: null,
    },
    {
      code: '19-4914 TPG',
      name: 'Deep Teal',
      hex: '30535A',
      rgb: {
        r: 48,
        g: 83,
        b: 90,
      },
      lab: {
        l: 32.47,
        a: -11.36,
        b: -9.01,
      },
      cmyk: null,
    },
    {
      code: '18-5610 TPG',
      name: 'Brittany Blue',
      hex: '55828A',
      rgb: {
        r: 85,
        g: 130,
        b: 138,
      },
      lab: {
        l: 51.06,
        a: -14.74,
        b: -10.19,
      },
      cmyk: null,
    },
    {
      code: '18-4718 TPG',
      name: 'Hydro',
      hex: '496C77',
      rgb: {
        r: 73,
        g: 108,
        b: 119,
      },
      lab: {
        l: 43.14,
        a: -11.22,
        b: -10.81,
      },
      cmyk: null,
    },
    {
      code: '19-4826 TPG',
      name: 'Dragonfly',
      hex: '376470',
      rgb: {
        r: 55,
        g: 100,
        b: 112,
      },
      lab: {
        l: 39.14,
        a: -13.06,
        b: -12.79,
      },
      cmyk: null,
    },
    {
      code: '18-4522 TPG',
      name: 'Colonial Blue',
      hex: '3A6A78',
      rgb: {
        r: 58,
        g: 106,
        b: 120,
      },
      lab: {
        l: 41.59,
        a: -14.49,
        b: -13.54,
      },
      cmyk: null,
    },
    {
      code: '19-4820 TPG',
      name: 'Balsam',
      hex: '436066',
      rgb: {
        r: 67,
        g: 96,
        b: 102,
      },
      lab: {
        l: 38.41,
        a: -9.87,
        b: -7.72,
      },
      cmyk: null,
    },
    {
      code: '19-4517 TPG',
      name: 'Mediterranea',
      hex: '3A5D63',
      rgb: {
        r: 58,
        g: 93,
        b: 99,
      },
      lab: {
        l: 36.87,
        a: -11.45,
        b: -8.21,
      },
      cmyk: null,
    },
    {
      code: '19-4726 TPG',
      name: 'Atlantic Deep',
      hex: '32575C',
      rgb: {
        r: 50,
        g: 87,
        b: 92,
      },
      lab: {
        l: 34.24,
        a: -12.53,
        b: -8.08,
      },
      cmyk: null,
    },
    {
      code: '12-4604 TPG',
      name: 'Skylight',
      hex: 'CEE5E3',
      rgb: {
        r: 206,
        g: 229,
        b: 227,
      },
      lab: {
        l: 89.03,
        a: -7.98,
        b: -2.03,
      },
      cmyk: null,
    },
    {
      code: '12-4607 TPG',
      name: 'Pastel Blue',
      hex: 'BDD6D9',
      rgb: {
        r: 189,
        g: 214,
        b: 217,
      },
      lab: {
        l: 83.76,
        a: -8.29,
        b: -4.53,
      },
      cmyk: null,
    },
    {
      code: '14-4810 TPG',
      name: 'Canal Blue',
      hex: '9AC1C7',
      rgb: {
        r: 154,
        g: 193,
        b: 199,
      },
      lab: {
        l: 75.07,
        a: -12.58,
        b: -8.4,
      },
      cmyk: null,
    },
    {
      code: '16-5114 TPG',
      name: 'Dusty Turquoise',
      hex: '67A0A3',
      rgb: {
        r: 103,
        g: 160,
        b: 163,
      },
      lab: {
        l: 61.86,
        a: -19.16,
        b: -8.19,
      },
      cmyk: null,
    },
    {
      code: '17-4818 TPG',
      name: 'Bristol Blue',
      hex: '569195',
      rgb: {
        r: 86,
        g: 145,
        b: 149,
      },
      lab: {
        l: 55.89,
        a: -19.3,
        b: -9.17,
      },
      cmyk: null,
    },
    {
      code: '17-5117 TPG',
      name: 'Green-Blue Slate',
      hex: '408388',
      rgb: {
        r: 64,
        g: 131,
        b: 136,
      },
      lab: {
        l: 50.3,
        a: -21.57,
        b: -10.24,
      },
      cmyk: null,
    },
    {
      code: '17-4919 TPG',
      name: 'Teal',
      hex: '4B878B',
      rgb: {
        r: 75,
        g: 135,
        b: 139,
      },
      lab: {
        l: 52.02,
        a: -19.3,
        b: -9.77,
      },
      cmyk: null,
    },
    {
      code: '13-5306 TPG',
      name: 'Icy Morn',
      hex: 'ADD2D0',
      rgb: {
        r: 173,
        g: 210,
        b: 208,
      },
      lab: {
        l: 81.28,
        a: -12.91,
        b: -3.89,
      },
      cmyk: null,
    },
    {
      code: '13-4909 TPG',
      name: 'Blue Light',
      hex: 'A7DDDB',
      rgb: {
        r: 167,
        g: 221,
        b: 219,
      },
      lab: {
        l: 84,
        a: -18.78,
        b: -5.7,
      },
      cmyk: null,
    },
    {
      code: '13-4910 TPG',
      name: 'Blue Tint',
      hex: '9EDADA',
      rgb: {
        r: 158,
        g: 218,
        b: 218,
      },
      lab: {
        l: 82.74,
        a: -20.12,
        b: -7.24,
      },
      cmyk: null,
    },
    {
      code: '13-5313 TPG',
      name: 'Aruba Blue',
      hex: '85D7D4',
      rgb: {
        r: 133,
        g: 215,
        b: 212,
      },
      lab: {
        l: 80.15,
        a: -26.97,
        b: -8.19,
      },
      cmyk: null,
    },
    {
      code: '14-4811 TPG',
      name: 'Aqua Sky',
      hex: '7FCACB',
      rgb: {
        r: 127,
        g: 202,
        b: 203,
      },
      lab: {
        l: 75.95,
        a: -24.29,
        b: -9.37,
      },
      cmyk: null,
    },
    {
      code: '15-5217 TPG',
      name: 'Blue Turquoise',
      hex: '5BB3B0',
      rgb: {
        r: 91,
        g: 179,
        b: 176,
      },
      lab: {
        l: 67.08,
        a: -28.49,
        b: -8.48,
      },
      cmyk: null,
    },
    {
      code: '16-5123 TPG',
      name: 'Baltic',
      hex: '339B9F',
      rgb: {
        r: 51,
        g: 155,
        b: 159,
      },
      lab: {
        l: 57.71,
        a: -29.38,
        b: -12.84,
      },
      cmyk: null,
    },
    {
      code: '11-4607 TPG',
      name: 'Diaphonous',
      hex: 'E7EFEE',
      rgb: {
        r: 231,
        g: 239,
        b: 238,
      },
      lab: {
        l: 93.91,
        a: -2.85,
        b: -0.68,
      },
      cmyk: null,
    },
    {
      code: '11-4606 TPG',
      name: 'Ice Castle',
      hex: 'DBEEEF',
      rgb: {
        r: 219,
        g: 238,
        b: 239,
      },
      lab: {
        l: 92.53,
        a: -6.4,
        b: -3.1,
      },
      cmyk: null,
    },
    {
      code: '12-5208 TPG',
      name: 'Mint Julep',
      hex: 'D3EEED',
      rgb: {
        r: 211,
        g: 238,
        b: 237,
      },
      lab: {
        l: 91.95,
        a: -9.42,
        b: -3.21,
      },
      cmyk: null,
    },
    {
      code: '12-5207 TPG',
      name: 'Salt Air',
      hex: 'CCECEE',
      rgb: {
        r: 204,
        g: 236,
        b: 238,
      },
      lab: {
        l: 91.04,
        a: -10.85,
        b: -5.1,
      },
      cmyk: null,
    },
    {
      code: '13-4908 TPG',
      name: 'Spa Retreat',
      hex: 'B5E6EB',
      rgb: {
        r: 181,
        g: 230,
        b: 235,
      },
      lab: {
        l: 87.86,
        a: -15.79,
        b: -8.69,
      },
      cmyk: null,
    },
    {
      code: '14-4715 TPG',
      name: 'Water Ballet',
      hex: '9DDDE2',
      rgb: {
        r: 157,
        g: 221,
        b: 226,
      },
      lab: {
        l: 83.7,
        a: -20.24,
        b: -10.37,
      },
      cmyk: null,
    },
    {
      code: '12-5302 TPG',
      name: 'Cooling Oasis',
      hex: 'BCDBDC',
      rgb: {
        r: 188,
        g: 219,
        b: 220,
      },
      lab: {
        l: 84.92,
        a: -10.11,
        b: -4.62,
      },
      cmyk: null,
    },
    {
      code: '12-5206 TPG',
      name: 'Blue Glass',
      hex: 'C5E1E0',
      rgb: {
        r: 197,
        g: 225,
        b: 224,
      },
      lab: {
        l: 87.16,
        a: -9.59,
        b: -3.56,
      },
      cmyk: null,
    },
    {
      code: '12-5209 TPG',
      name: 'Soothing Sea',
      hex: 'C7E9E2',
      rgb: {
        r: 199,
        g: 233,
        b: 226,
      },
      lab: {
        l: 89.44,
        a: -12.62,
        b: -1.4,
      },
      cmyk: null,
    },
    {
      code: '12-5808 TPG',
      name: 'Honeydew',
      hex: 'B8E3D2',
      rgb: {
        r: 184,
        g: 227,
        b: 210,
      },
      lab: {
        l: 86.5,
        a: -17.44,
        b: 2.56,
      },
      cmyk: null,
    },
    {
      code: '12-5409 TPG',
      name: 'Fair Aqua',
      hex: 'BCE5DF',
      rgb: {
        r: 188,
        g: 229,
        b: 223,
      },
      lab: {
        l: 87.79,
        a: -15.21,
        b: -2.07,
      },
      cmyk: null,
    },
    {
      code: '12-5410 TPG',
      name: 'Bleached Aqua',
      hex: 'B2E0DC',
      rgb: {
        r: 178,
        g: 224,
        b: 220,
      },
      lab: {
        l: 85.57,
        a: -16.14,
        b: -4.12,
      },
      cmyk: null,
    },
    {
      code: '14-4809 TPG',
      name: 'Eggshell Blue',
      hex: '9CC8C6',
      rgb: {
        r: 156,
        g: 200,
        b: 198,
      },
      lab: {
        l: 77.3,
        a: -15.57,
        b: -4.34,
      },
      cmyk: null,
    },
    {
      code: '13-5309 TPG',
      name: 'Pastel Turquoise',
      hex: '95C5C6',
      rgb: {
        r: 149,
        g: 197,
        b: 198,
      },
      lab: {
        l: 75.89,
        a: -16.13,
        b: -6.44,
      },
      cmyk: null,
    },
    {
      code: '11-4604 TPG',
      name: 'Billowing Sail',
      hex: 'DFE9E6',
      rgb: {
        r: 223,
        g: 233,
        b: 230,
      },
      lab: {
        l: 91.5,
        a: -4.17,
        b: -0.01,
      },
      cmyk: null,
    },
    {
      code: '12-4305 TPG',
      name: 'Spa Blue',
      hex: 'D5E1DF',
      rgb: {
        r: 213,
        g: 225,
        b: 223,
      },
      lab: {
        l: 88.56,
        a: -4.61,
        b: -0.66,
      },
      cmyk: null,
    },
    {
      code: '12-4805 TPG',
      name: 'Wan Blue',
      hex: 'CBDDDE',
      rgb: {
        r: 203,
        g: 221,
        b: 222,
      },
      lab: {
        l: 86.58,
        a: -6.08,
        b: -2.98,
      },
      cmyk: null,
    },
    {
      code: '12-4610 TPG',
      name: 'Whispering Blue',
      hex: 'C8DDDD',
      rgb: {
        r: 200,
        g: 221,
        b: 221,
      },
      lab: {
        l: 86.32,
        a: -7.34,
        b: -2.91,
      },
      cmyk: null,
    },
    {
      code: '13-4804 TPG',
      name: 'Pale Blue',
      hex: 'C6D8D7',
      rgb: {
        r: 198,
        g: 216,
        b: 215,
      },
      lab: {
        l: 84.78,
        a: -6.48,
        b: -1.99,
      },
      cmyk: null,
    },
    {
      code: '13-4405 TPG',
      name: 'Misty Blue',
      hex: 'BDCFCE',
      rgb: {
        r: 189,
        g: 207,
        b: 206,
      },
      lab: {
        l: 81.71,
        a: -6.73,
        b: -1.97,
      },
      cmyk: null,
    },
    {
      code: '14-4504 TPG',
      name: 'Sky Gray',
      hex: 'B7C7C5',
      rgb: {
        r: 183,
        g: 199,
        b: 197,
      },
      lab: {
        l: 78.94,
        a: -5.92,
        b: -1.3,
      },
      cmyk: null,
    },
    {
      code: '11-4805 TPG',
      name: 'Hint of Mint',
      hex: 'DCEBE4',
      rgb: {
        r: 220,
        g: 235,
        b: 228,
      },
      lab: {
        l: 91.57,
        a: -6.33,
        b: 1.31,
      },
      cmyk: null,
    },
    {
      code: '12-5508 TPG',
      name: 'Hushed Green',
      hex: 'D9E9E3',
      rgb: {
        r: 217,
        g: 233,
        b: 227,
      },
      lab: {
        l: 90.97,
        a: -6.76,
        b: 0.66,
      },
      cmyk: null,
    },
    {
      code: '12-5204 TPG',
      name: 'Morning Mist',
      hex: 'CADDD9',
      rgb: {
        r: 202,
        g: 221,
        b: 217,
      },
      lab: {
        l: 86.34,
        a: -7.1,
        b: -0.94,
      },
      cmyk: null,
    },
    {
      code: '12-5303 TPG',
      name: 'Sprout Green',
      hex: 'CAD9D3',
      rgb: {
        r: 202,
        g: 217,
        b: 211,
      },
      lab: {
        l: 85.24,
        a: -6.26,
        b: 0.63,
      },
      cmyk: null,
    },
    {
      code: '12-4806 TPG',
      name: 'Chalk Blue',
      hex: 'C8D8D7',
      rgb: {
        r: 200,
        g: 216,
        b: 215,
      },
      lab: {
        l: 85.07,
        a: -5.95,
        b: -1.42,
      },
      cmyk: null,
    },
    {
      code: '14-4807 TPG',
      name: 'Surf Spray',
      hex: 'ABC4C0',
      rgb: {
        r: 171,
        g: 196,
        b: 192,
      },
      lab: {
        l: 77.19,
        a: -9.41,
        b: -1.39,
      },
      cmyk: null,
    },
    {
      code: '14-4908 TPG',
      name: 'Harbor Gray',
      hex: 'A6C2BB',
      rgb: {
        r: 166,
        g: 194,
        b: 187,
      },
      lab: {
        l: 75.89,
        a: -10.89,
        b: -0.29,
      },
      cmyk: null,
    },
    {
      code: '15-4707 TPG',
      name: 'Blue Haze',
      hex: '9DB8B8',
      rgb: {
        r: 157,
        g: 184,
        b: 184,
      },
      lab: {
        l: 72.64,
        a: -9.62,
        b: -3.34,
      },
      cmyk: null,
    },
    {
      code: '15-4706 TPG',
      name: 'Gray Mist',
      hex: '98ADAE',
      rgb: {
        r: 152,
        g: 173,
        b: 174,
      },
      lab: {
        l: 68.97,
        a: -7.58,
        b: -3.49,
      },
      cmyk: null,
    },
    {
      code: '16-5106 TPG',
      name: 'Blue Surf',
      hex: '8FA9A5',
      rgb: {
        r: 143,
        g: 169,
        b: 165,
      },
      lab: {
        l: 66.91,
        a: -10.43,
        b: -1.69,
      },
      cmyk: null,
    },
    {
      code: '15-5207 TPG',
      name: 'Aquifer',
      hex: '83ABAC',
      rgb: {
        r: 131,
        g: 171,
        b: 172,
      },
      lab: {
        l: 66.87,
        a: -13.87,
        b: -5.97,
      },
      cmyk: null,
    },
    {
      code: '16-4712 TPG',
      name: 'Mineral Blue',
      hex: '6F9293',
      rgb: {
        r: 111,
        g: 146,
        b: 147,
      },
      lab: {
        l: 57.76,
        a: -12.42,
        b: -5.1,
      },
      cmyk: null,
    },
    {
      code: '17-5110 TPG',
      name: 'Trellis',
      hex: '6E8F8D',
      rgb: {
        r: 110,
        g: 143,
        b: 141,
      },
      lab: {
        l: 56.53,
        a: -11.75,
        b: -3.92,
      },
      cmyk: null,
    },
    {
      code: '17-4911 TPG',
      name: 'Arctic',
      hex: '67898F',
      rgb: {
        r: 103,
        g: 137,
        b: 143,
      },
      lab: {
        l: 54.35,
        a: -11.41,
        b: -8.18,
      },
      cmyk: null,
    },
    {
      code: '16-4408 TPG',
      name: 'Slate',
      hex: '8B9FA1',
      rgb: {
        r: 139,
        g: 159,
        b: 161,
      },
      lab: {
        l: 63.72,
        a: -6.93,
        b: -4.03,
      },
      cmyk: null,
    },
    {
      code: '16-4706 TPG',
      name: 'Silver Blue',
      hex: '89999B',
      rgb: {
        r: 137,
        g: 153,
        b: 155,
      },
      lab: {
        l: 61.94,
        a: -5.99,
        b: -3.08,
      },
      cmyk: null,
    },
    {
      code: '16-4404 TPG',
      name: 'Abyss',
      hex: '8B9D9E',
      rgb: {
        r: 139,
        g: 157,
        b: 158,
      },
      lab: {
        l: 63.13,
        a: -6.39,
        b: -3.36,
      },
      cmyk: null,
    },
    {
      code: '17-4408 TPG',
      name: 'Lead',
      hex: '77898F',
      rgb: {
        r: 119,
        g: 137,
        b: 143,
      },
      lab: {
        l: 55.75,
        a: -6.16,
        b: -5.86,
      },
      cmyk: null,
    },
    {
      code: '18-4711 TPG',
      name: 'Stormy Sea',
      hex: '718383',
      rgb: {
        r: 113,
        g: 131,
        b: 131,
      },
      lab: {
        l: 53.16,
        a: -6.75,
        b: -2.83,
      },
      cmyk: null,
    },
    {
      code: '18-4510 TPG',
      name: 'Trooper',
      hex: '687B7E',
      rgb: {
        r: 104,
        g: 123,
        b: 126,
      },
      lab: {
        l: 49.96,
        a: -6.81,
        b: -4.61,
      },
      cmyk: null,
    },
    {
      code: '18-4011 TPG',
      name: 'Goblin Blue',
      hex: '607278',
      rgb: {
        r: 96,
        g: 114,
        b: 120,
      },
      lab: {
        l: 46.63,
        a: -6.21,
        b: -6.15,
      },
      cmyk: null,
    },
    {
      code: '16-5304 TPG',
      name: 'Jadeite',
      hex: '8DA19B',
      rgb: {
        r: 141,
        g: 161,
        b: 155,
      },
      lab: {
        l: 64.38,
        a: -8.26,
        b: 0.34,
      },
      cmyk: null,
    },
    {
      code: '16-5804 TPG',
      name: 'Slate Gray',
      hex: '8A9892',
      rgb: {
        r: 138,
        g: 152,
        b: 146,
      },
      lab: {
        l: 61.34,
        a: -6.09,
        b: 0.82,
      },
      cmyk: null,
    },
    {
      code: '17-5107 TPG',
      name: 'Chinois Green',
      hex: '778A86',
      rgb: {
        r: 119,
        g: 138,
        b: 134,
      },
      lab: {
        l: 55.69,
        a: -7.75,
        b: -0.64,
      },
      cmyk: null,
    },
    {
      code: '16-5806 TPG',
      name: 'Green Milieu',
      hex: '859690',
      rgb: {
        r: 133,
        g: 150,
        b: 144,
      },
      lab: {
        l: 60.37,
        a: -7.3,
        b: 0.48,
      },
      cmyk: null,
    },
    {
      code: '18-5606 TPG',
      name: 'Balsam Green',
      hex: '5B686A',
      rgb: {
        r: 91,
        g: 104,
        b: 106,
      },
      lab: {
        l: 42.86,
        a: -4.83,
        b: -3.53,
      },
      cmyk: null,
    },
    {
      code: '18-4612 TPG',
      name: 'North Atlantic',
      hex: '577477',
      rgb: {
        r: 87,
        g: 116,
        b: 119,
      },
      lab: {
        l: 46.66,
        a: -10.78,
        b: -5.47,
      },
      cmyk: null,
    },
    {
      code: '18-5112 TPG',
      name: 'Sea Pine',
      hex: '516F71',
      rgb: {
        r: 81,
        g: 111,
        b: 113,
      },
      lab: {
        l: 44.38,
        a: -11,
        b: -5.47,
      },
      cmyk: null,
    },
    {
      code: '18-5612 TPG',
      name: 'Sagebrush Green',
      hex: '587876',
      rgb: {
        r: 88,
        g: 120,
        b: 118,
      },
      lab: {
        l: 47.73,
        a: -11.99,
        b: -3.62,
      },
      cmyk: null,
    },
    {
      code: '18-5410 TPG',
      name: 'Silver Pine',
      hex: '5A716E',
      rgb: {
        r: 90,
        g: 113,
        b: 110,
      },
      lab: {
        l: 45.45,
        a: -9.07,
        b: -2.05,
      },
      cmyk: null,
    },
    {
      code: '18-5308 TPG',
      name: 'Blue Spruce',
      hex: '51716E',
      rgb: {
        r: 81,
        g: 113,
        b: 110,
      },
      lab: {
        l: 44.89,
        a: -12.34,
        b: -2.97,
      },
      cmyk: null,
    },
    {
      code: '19-4818 TPG',
      name: 'Mallard Green',
      hex: '4D6769',
      rgb: {
        r: 77,
        g: 103,
        b: 105,
      },
      lab: {
        l: 41.38,
        a: -9.71,
        b: -4.85,
      },
      cmyk: null,
    },
    {
      code: '19-5408 TPG',
      name: 'Bistro Green',
      hex: '3B5855',
      rgb: {
        r: 59,
        g: 88,
        b: 85,
      },
      lab: {
        l: 34.73,
        a: -11.71,
        b: -2.41,
      },
      cmyk: null,
    },
    {
      code: '19-5030 TPG',
      name: 'Sea Moss',
      hex: '395052',
      rgb: {
        r: 57,
        g: 80,
        b: 82,
      },
      lab: {
        l: 31.93,
        a: -8.37,
        b: -4.91,
      },
      cmyk: null,
    },
    {
      code: '19-5320 TPG',
      name: 'Ponderosa Pine',
      hex: '324D4E',
      rgb: {
        r: 50,
        g: 77,
        b: 78,
      },
      lab: {
        l: 30.56,
        a: -10.29,
        b: -4.3,
      },
      cmyk: null,
    },
    {
      code: '19-5226 TPG',
      name: 'Everglade',
      hex: '1F6769',
      rgb: {
        r: 31,
        g: 103,
        b: 105,
      },
      lab: {
        l: 38.89,
        a: -21.9,
        b: -9,
      },
      cmyk: null,
    },
    {
      code: '19-4922 TPG',
      name: 'Teal Green',
      hex: '276B6C',
      rgb: {
        r: 39,
        g: 107,
        b: 108,
      },
      lab: {
        l: 40.62,
        a: -21.47,
        b: -8.66,
      },
      cmyk: null,
    },
    {
      code: '19-4916 TPG',
      name: 'Pacific',
      hex: '386268',
      rgb: {
        r: 56,
        g: 98,
        b: 104,
      },
      lab: {
        l: 38.45,
        a: -13.37,
        b: -9.47,
      },
      cmyk: null,
    },
    {
      code: '19-5413 TPG',
      name: 'Jasper',
      hex: '3F6364',
      rgb: {
        r: 63,
        g: 99,
        b: 100,
      },
      lab: {
        l: 39.03,
        a: -13.22,
        b: -5.6,
      },
      cmyk: null,
    },
    {
      code: '19-5217 TPG',
      name: 'Storm',
      hex: '2E6464',
      rgb: {
        r: 46,
        g: 100,
        b: 100,
      },
      lab: {
        l: 38.57,
        a: -18.9,
        b: -6.65,
      },
      cmyk: null,
    },
    {
      code: '18-5315 TPG',
      name: 'Bayberry',
      hex: '346260',
      rgb: {
        r: 52,
        g: 98,
        b: 96,
      },
      lab: {
        l: 37.8,
        a: -15.98,
        b: -4.97,
      },
      cmyk: null,
    },
    {
      code: '19-5414 TPG',
      name: 'June Bug',
      hex: '2F5050',
      rgb: {
        r: 47,
        g: 80,
        b: 80,
      },
      lab: {
        l: 31.19,
        a: -11.88,
        b: -5.16,
      },
      cmyk: null,
    },
    {
      code: '18-4835 TPG',
      name: 'Alexandrite',
      hex: '166D72',
      rgb: {
        r: 22,
        g: 109,
        b: 114,
      },
      lab: {
        l: 41.24,
        a: -23.83,
        b: -11.42,
      },
      cmyk: null,
    },
    {
      code: '18-4833 TPG',
      name: 'Kayaking',
      hex: '006C72',
      rgb: {
        r: 0,
        g: 108,
        b: 114,
      },
      lab: {
        l: 40.03,
        a: -27.23,
        b: -13.41,
      },
      cmyk: null,
    },
    {
      code: '19-4918 TPG',
      name: 'Spruced-up',
      hex: '25585F',
      rgb: {
        r: 37,
        g: 88,
        b: 95,
      },
      lab: {
        l: 34.06,
        a: -15.66,
        b: -10.14,
      },
      cmyk: null,
    },
    {
      code: '19-4534 TPG',
      name: 'Chesapeake Bay',
      hex: '2A5B64',
      rgb: {
        r: 42,
        g: 91,
        b: 100,
      },
      lab: {
        l: 35.33,
        a: -14.79,
        b: -11.26,
      },
      cmyk: null,
    },
    {
      code: '19-4727 TPG',
      name: 'Green Heron',
      hex: '325B63',
      rgb: {
        r: 50,
        g: 91,
        b: 99,
      },
      lab: {
        l: 35.52,
        a: -12.89,
        b: -10.13,
      },
      cmyk: null,
    },
    {
      code: '19-4523 TPG',
      name: 'Gulf Coast',
      hex: '255663',
      rgb: {
        r: 37,
        g: 86,
        b: 99,
      },
      lab: {
        l: 33.35,
        a: -13.44,
        b: -13.79,
      },
      cmyk: null,
    },
    {
      code: '19-5025 TPG',
      name: 'Dark Sea',
      hex: '2F4E53',
      rgb: {
        r: 47,
        g: 78,
        b: 83,
      },
      lab: {
        l: 30.6,
        a: -10.26,
        b: -7.49,
      },
      cmyk: null,
    },
    {
      code: '16-5112 TPG',
      name: 'Canton',
      hex: '73A2A1',
      rgb: {
        r: 115,
        g: 162,
        b: 161,
      },
      lab: {
        l: 63.03,
        a: -15.53,
        b: -5.88,
      },
      cmyk: null,
    },
    {
      code: '16-5412 TPG',
      name: 'Agate Green',
      hex: '63A19C',
      rgb: {
        r: 99,
        g: 161,
        b: 156,
      },
      lab: {
        l: 61.58,
        a: -22.23,
        b: -5.21,
      },
      cmyk: null,
    },
    {
      code: '16-5119 TPG',
      name: 'Sea Blue',
      hex: '589D98',
      rgb: {
        r: 88,
        g: 157,
        b: 152,
      },
      lab: {
        l: 59.78,
        a: -24.25,
        b: -5.79,
      },
      cmyk: null,
    },
    {
      code: '17-5122 TPG',
      name: 'Latigo Bay',
      hex: '429394',
      rgb: {
        r: 66,
        g: 147,
        b: 148,
      },
      lab: {
        l: 55.68,
        a: -25.71,
        b: -9.7,
      },
      cmyk: null,
    },
    {
      code: '17-5421 TPG',
      name: 'Porcelain Green',
      hex: '328E87',
      rgb: {
        r: 50,
        g: 142,
        b: 135,
      },
      lab: {
        l: 53.04,
        a: -29.38,
        b: -6.33,
      },
      cmyk: null,
    },
    {
      code: '18-5121 TPG',
      name: 'Bayou',
      hex: '3A7779',
      rgb: {
        r: 58,
        g: 119,
        b: 121,
      },
      lab: {
        l: 45.66,
        a: -20.44,
        b: -8.32,
      },
      cmyk: null,
    },
    {
      code: '18-5115 TPG',
      name: 'North Sea',
      hex: '37716E',
      rgb: {
        r: 55,
        g: 113,
        b: 110,
      },
      lab: {
        l: 43.32,
        a: -20.56,
        b: -5.56,
      },
      cmyk: null,
    },
    {
      code: '16-5127 TPG',
      name: 'Ceramic',
      hex: '00ADAD',
      rgb: {
        r: 0,
        g: 173,
        b: 173,
      },
      lab: {
        l: 62.72,
        a: -39.65,
        b: -13.9,
      },
      cmyk: null,
    },
    {
      code: '17-5126 TPG',
      name: 'Viridian Green',
      hex: '00979C',
      rgb: {
        r: 0,
        g: 151,
        b: 156,
      },
      lab: {
        l: 55.29,
        a: -35.17,
        b: -15.36,
      },
      cmyk: null,
    },
    {
      code: '18-4930 TPG',
      name: 'Tropical Green',
      hex: '008B8C',
      rgb: {
        r: 0,
        g: 139,
        b: 140,
      },
      lab: {
        l: 50.44,
        a: -38.49,
        b: -13.77,
      },
      cmyk: null,
    },
    {
      code: '17-5025 TPG',
      name: 'Navigate',
      hex: '00888A',
      rgb: {
        r: 0,
        g: 136,
        b: 138,
      },
      lab: {
        l: 49.61,
        a: -34.61,
        b: -13.56,
      },
      cmyk: null,
    },
    {
      code: '17-5029 TPG',
      name: 'Deep Peacock Blue',
      hex: '008889',
      rgb: {
        r: 0,
        g: 136,
        b: 137,
      },
      lab: {
        l: 49.19,
        a: -37.58,
        b: -13.47,
      },
      cmyk: null,
    },
    {
      code: '17-5034 TPG',
      name: 'Lapis',
      hex: '008D8F',
      rgb: {
        r: 0,
        g: 141,
        b: 143,
      },
      lab: {
        l: 51.04,
        a: -38.76,
        b: -14.45,
      },
      cmyk: null,
    },
    {
      code: '18-4834 TPG',
      name: 'Deep Lake',
      hex: '106972',
      rgb: {
        r: 16,
        g: 105,
        b: 114,
      },
      lab: {
        l: 39.7,
        a: -22.48,
        b: -13.47,
      },
      cmyk: null,
    },
    {
      code: '15-5218 TPG',
      name: 'Pool Blue',
      hex: '6EBEBA',
      rgb: {
        r: 110,
        g: 190,
        b: 186,
      },
      lab: {
        l: 71.36,
        a: -26.71,
        b: -7.32,
      },
      cmyk: null,
    },
    {
      code: '15-5519 TPG',
      name: 'Turquoise',
      hex: '4DB6AD',
      rgb: {
        r: 77,
        g: 182,
        b: 173,
      },
      lab: {
        l: 67.35,
        a: -33.36,
        b: -6.74,
      },
      cmyk: null,
    },
    {
      code: '15-5516 TPG',
      name: 'Waterfall',
      hex: '49B2A7',
      rgb: {
        r: 73,
        g: 178,
        b: 167,
      },
      lab: {
        l: 65.86,
        a: -33.86,
        b: -5.38,
      },
      cmyk: null,
    },
    {
      code: '16-5418 TPG',
      name: 'Lagoon',
      hex: '53A09C',
      rgb: {
        r: 83,
        g: 160,
        b: 156,
      },
      lab: {
        l: 60.5,
        a: -26.09,
        b: -6.91,
      },
      cmyk: null,
    },
    {
      code: '16-5422 TPG',
      name: 'Bright Aqua',
      hex: '3AA6A1',
      rgb: {
        r: 58,
        g: 166,
        b: 161,
      },
      lab: {
        l: 61.41,
        a: -32.85,
        b: -8.46,
      },
      cmyk: null,
    },
    {
      code: '18-5128 TPG',
      name: 'Blue Grass',
      hex: '008384',
      rgb: {
        r: 0,
        g: 131,
        b: 132,
      },
      lab: {
        l: 47.54,
        a: -36.64,
        b: -12.95,
      },
      cmyk: null,
    },
    {
      code: '18-4936 TPG',
      name: 'Fanfare',
      hex: '00757A',
      rgb: {
        r: 0,
        g: 117,
        b: 122,
      },
      lab: {
        l: 43.18,
        a: -29.59,
        b: -13.32,
      },
      cmyk: null,
    },
    {
      code: '15-5425 TPG',
      name: 'Atlantis',
      hex: '00B3A3',
      rgb: {
        r: 0,
        g: 179,
        b: 163,
      },
      lab: {
        l: 64.63,
        a: -43.08,
        b: -5.67,
      },
      cmyk: null,
    },
    {
      code: '16-5425 TPG',
      name: 'Pool Green',
      hex: '00B19E',
      rgb: {
        r: 0,
        g: 177,
        b: 158,
      },
      lab: {
        l: 63.68,
        a: -44.91,
        b: -4.24,
      },
      cmyk: null,
    },
    {
      code: '17-5335 TPG',
      name: 'Spectra Green',
      hex: '009E92',
      rgb: {
        r: 0,
        g: 158,
        b: 146,
      },
      lab: {
        l: 56.61,
        a: -45.67,
        b: -8.14,
      },
      cmyk: null,
    },
    {
      code: '17-5330 TPG',
      name: 'Dynasty Green',
      hex: '009689',
      rgb: {
        r: 0,
        g: 150,
        b: 137,
      },
      lab: {
        l: 53.72,
        a: -43.29,
        b: -7.22,
      },
      cmyk: null,
    },
    {
      code: '17-5130 TPG',
      name: 'Columbia',
      hex: '00948D',
      rgb: {
        r: 0,
        g: 148,
        b: 141,
      },
      lab: {
        l: 53.25,
        a: -42.33,
        b: -9.84,
      },
      cmyk: null,
    },
    {
      code: '17-5024 TPG',
      name: 'Teal Blue',
      hex: '088282',
      rgb: {
        r: 8,
        g: 130,
        b: 130,
      },
      lab: {
        l: 48.3,
        a: -30,
        b: -10.62,
      },
      cmyk: null,
    },
    {
      code: '18-5025 TPG',
      name: 'Quetzal Green',
      hex: '006E6D',
      rgb: {
        r: 0,
        g: 110,
        b: 109,
      },
      lab: {
        l: 40.72,
        a: -26.8,
        b: -9.54,
      },
      cmyk: null,
    },
    {
      code: '14-5416 TPG',
      name: 'Bermuda',
      hex: '5BC8B3',
      rgb: {
        r: 91,
        g: 200,
        b: 179,
      },
      lab: {
        l: 73.35,
        a: -37.04,
        b: -1.24,
      },
      cmyk: null,
    },
    {
      code: '14-5721 TPG',
      name: 'Electric Green',
      hex: '42C0A8',
      rgb: {
        r: 66,
        g: 192,
        b: 168,
      },
      lab: {
        l: 70,
        a: -40.8,
        b: -0.17,
      },
      cmyk: null,
    },
    {
      code: '15-5421 TPG',
      name: 'Aqua Green',
      hex: '1DBAA4',
      rgb: {
        r: 29,
        g: 186,
        b: 164,
      },
      lab: {
        l: 67.18,
        a: -43.74,
        b: -2.17,
      },
      cmyk: null,
    },
    {
      code: '16-5427 TPG',
      name: 'Billiard',
      hex: '00AE99',
      rgb: {
        r: 0,
        g: 174,
        b: 153,
      },
      lab: {
        l: 62.78,
        a: -44.42,
        b: -2.72,
      },
      cmyk: null,
    },
    {
      code: '16-5431 TPG',
      name: 'Peacock Green',
      hex: '00A88E',
      rgb: {
        r: 0,
        g: 168,
        b: 142,
      },
      lab: {
        l: 60.27,
        a: -47.69,
        b: -0.14,
      },
      cmyk: null,
    },
    {
      code: '16-5533 TPG',
      name: 'Arcadia',
      hex: '00A591',
      rgb: {
        r: 0,
        g: 165,
        b: 145,
      },
      lab: {
        l: 59.5,
        a: -44.72,
        b: -3.24,
      },
      cmyk: null,
    },
    {
      code: '18-5020 TPG',
      name: 'Parasailing',
      hex: '007773',
      rgb: {
        r: 0,
        g: 119,
        b: 115,
      },
      lab: {
        l: 43.91,
        a: -31.09,
        b: -8.39,
      },
      cmyk: null,
    },
    {
      code: '13-6009 TPG',
      name: 'Brook Green',
      hex: 'A9DECC',
      rgb: {
        r: 169,
        g: 222,
        b: 204,
      },
      lab: {
        l: 84.09,
        a: -21.17,
        b: 2.08,
      },
      cmyk: null,
    },
    {
      code: '13-5714 TPG',
      name: 'Cabbage',
      hex: '88DCBE',
      rgb: {
        r: 136,
        g: 220,
        b: 190,
      },
      lab: {
        l: 81.27,
        a: -32.2,
        b: 4.9,
      },
      cmyk: null,
    },
    {
      code: '14-5714 TPG',
      name: 'Beveled Glass',
      hex: '7DCDB6',
      rgb: {
        r: 125,
        g: 205,
        b: 182,
      },
      lab: {
        l: 76.39,
        a: -30.21,
        b: 1.9,
      },
      cmyk: null,
    },
    {
      code: '14-5718 TPG',
      name: 'Opal',
      hex: '71CEB4',
      rgb: {
        r: 113,
        g: 206,
        b: 180,
      },
      lab: {
        l: 75.89,
        a: -34.23,
        b: 2.29,
      },
      cmyk: null,
    },
    {
      code: '15-5819 TPG',
      name: 'Spearmint',
      hex: '66C0A4',
      rgb: {
        r: 102,
        g: 192,
        b: 164,
      },
      lab: {
        l: 71.23,
        a: -34.32,
        b: 4.29,
      },
      cmyk: null,
    },
    {
      code: '15-5718 TPG',
      name: 'Biscay Green',
      hex: '4FC5A5',
      rgb: {
        r: 79,
        g: 197,
        b: 165,
      },
      lab: {
        l: 71.54,
        a: -41.05,
        b: 4.04,
      },
      cmyk: null,
    },
    {
      code: '15-5728 TPG',
      name: 'Mint Leaf',
      hex: '0DB695',
      rgb: {
        r: 13,
        g: 182,
        b: 149,
      },
      lab: {
        l: 65.35,
        a: -46.09,
        b: 3.28,
      },
      cmyk: null,
    },
    {
      code: '12-5407 TPG',
      name: 'Aqua Glass',
      hex: 'D3E8DD',
      rgb: {
        r: 211,
        g: 232,
        b: 221,
      },
      lab: {
        l: 89.99,
        a: -8.36,
        b: 2.19,
      },
      cmyk: null,
    },
    {
      code: '12-5507 TPG',
      name: 'Bay',
      hex: 'BDE7D8',
      rgb: {
        r: 189,
        g: 231,
        b: 216,
      },
      lab: {
        l: 88.09,
        a: -16.78,
        b: 1.92,
      },
      cmyk: null,
    },
    {
      code: '13-5409 TPG',
      name: 'Yucca',
      hex: '9ED9CC',
      rgb: {
        r: 158,
        g: 217,
        b: 204,
      },
      lab: {
        l: 82.13,
        a: -22.21,
        b: -0.8,
      },
      cmyk: null,
    },
    {
      code: '13-5412 TPG',
      name: 'Beach Glass',
      hex: '97DFCE',
      rgb: {
        r: 151,
        g: 223,
        b: 206,
      },
      lab: {
        l: 83.44,
        a: -26.8,
        b: -0.07,
      },
      cmyk: null,
    },
    {
      code: '13-5414 TPG',
      name: 'Ice Green',
      hex: '7FD6C1',
      rgb: {
        r: 127,
        g: 214,
        b: 193,
      },
      lab: {
        l: 79.2,
        a: -31.68,
        b: 0.18,
      },
      cmyk: null,
    },
    {
      code: '14-5420 TPG',
      name: 'Cockatoo',
      hex: '5ECCB9',
      rgb: {
        r: 94,
        g: 204,
        b: 185,
      },
      lab: {
        l: 74.83,
        a: -36.96,
        b: -2.3,
      },
      cmyk: null,
    },
    {
      code: '15-5416 TPG',
      name: 'Florida Keys',
      hex: '50C0AC',
      rgb: {
        r: 80,
        g: 192,
        b: 172,
      },
      lab: {
        l: 70.24,
        a: -37.17,
        b: -1.75,
      },
      cmyk: null,
    },
    {
      code: '12-5406 TPG',
      name: 'Opal Blue',
      hex: 'C4DED7',
      rgb: {
        r: 196,
        g: 222,
        b: 215,
      },
      lab: {
        l: 86.09,
        a: -9.88,
        b: -0.11,
      },
      cmyk: null,
    },
    {
      code: '12-5408 TPG',
      name: 'Moonlight Jade',
      hex: 'C2E5DB',
      rgb: {
        r: 194,
        g: 229,
        b: 219,
      },
      lab: {
        l: 87.96,
        a: -13.59,
        b: 0.12,
      },
      cmyk: null,
    },
    {
      code: '12-5505 TPG',
      name: 'Glacier',
      hex: 'C1DAD4',
      rgb: {
        r: 193,
        g: 218,
        b: 212,
      },
      lab: {
        l: 85.05,
        a: -9.96,
        b: -0.31,
      },
      cmyk: null,
    },
    {
      code: '14-5711 TPG',
      name: 'Ocean Wave',
      hex: '8EC5B7',
      rgb: {
        r: 142,
        g: 197,
        b: 183,
      },
      lab: {
        l: 75.18,
        a: -20.87,
        b: 0.38,
      },
      cmyk: null,
    },
    {
      code: '14-5413 TPG',
      name: 'Holiday',
      hex: '7BC2B3',
      rgb: {
        r: 123,
        g: 194,
        b: 179,
      },
      lab: {
        l: 72.93,
        a: -25.25,
        b: -1.36,
      },
      cmyk: null,
    },
    {
      code: '14-5713 TPG',
      name: 'Cascade',
      hex: '77BEB1',
      rgb: {
        r: 119,
        g: 190,
        b: 177,
      },
      lab: {
        l: 71.51,
        a: -25.27,
        b: -2.08,
      },
      cmyk: null,
    },
    {
      code: '15-5711 TPG',
      name: 'Dusty Jade Green',
      hex: '7CB7A3',
      rgb: {
        r: 124,
        g: 183,
        b: 163,
      },
      lab: {
        l: 69.7,
        a: -22.88,
        b: 2.65,
      },
      cmyk: null,
    },
    {
      code: '16-5109 TPG',
      name: 'Wasabi',
      hex: '74A59E',
      rgb: {
        r: 116,
        g: 165,
        b: 158,
      },
      lab: {
        l: 63.8,
        a: -18.57,
        b: -2.62,
      },
      cmyk: null,
    },
    {
      code: '16-5515 TPG',
      name: 'Beryl Green',
      hex: '68938A',
      rgb: {
        r: 104,
        g: 147,
        b: 138,
      },
      lab: {
        l: 57.52,
        a: -17.48,
        b: -0.84,
      },
      cmyk: null,
    },
    {
      code: '17-5111 TPG',
      name: 'Oil Blue',
      hex: '678E8D',
      rgb: {
        r: 103,
        g: 142,
        b: 141,
      },
      lab: {
        l: 55.93,
        a: -14.54,
        b: -4.54,
      },
      cmyk: null,
    },
    {
      code: '17-5513 TPG',
      name: 'Deep Sea',
      hex: '53817C',
      rgb: {
        r: 83,
        g: 129,
        b: 124,
      },
      lab: {
        l: 50.34,
        a: -17.96,
        b: -2.86,
      },
      cmyk: null,
    },
    {
      code: '18-5618 TPG',
      name: 'Deep Jungle',
      hex: '457876',
      rgb: {
        r: 69,
        g: 120,
        b: 118,
      },
      lab: {
        l: 46.69,
        a: -18.9,
        b: -5.18,
      },
      cmyk: null,
    },
    {
      code: '18-5619 TPG',
      name: 'Tidepool',
      hex: '27706B',
      rgb: {
        r: 39,
        g: 112,
        b: 107,
      },
      lab: {
        l: 42.25,
        a: -24.02,
        b: -5.74,
      },
      cmyk: null,
    },
    {
      code: '18-5620 TPG',
      name: 'Ivy',
      hex: '397873',
      rgb: {
        r: 57,
        g: 120,
        b: 115,
      },
      lab: {
        l: 45.83,
        a: -22.34,
        b: -4.75,
      },
      cmyk: null,
    },
    {
      code: '16-5919 TPG',
      name: 'Crème de Menthe',
      hex: '73A18D',
      rgb: {
        r: 115,
        g: 161,
        b: 141,
      },
      lab: {
        l: 62.22,
        a: -20.04,
        b: 4.36,
      },
      cmyk: null,
    },
    {
      code: '16-5815 TPG',
      name: 'Feldspar',
      hex: '749D8C',
      rgb: {
        r: 116,
        g: 157,
        b: 140,
      },
      lab: {
        l: 61.04,
        a: -17.7,
        b: 3.11,
      },
      cmyk: null,
    },
    {
      code: '18-5622 TPG',
      name: 'Frosty Spruce',
      hex: '578373',
      rgb: {
        r: 87,
        g: 131,
        b: 115,
      },
      lab: {
        l: 50.78,
        a: -18.68,
        b: 2.5,
      },
      cmyk: null,
    },
    {
      code: '17-5722 TPG',
      name: 'Bottle Green',
      hex: '4A8376',
      rgb: {
        r: 74,
        g: 131,
        b: 118,
      },
      lab: {
        l: 50.2,
        a: -22.41,
        b: -0.22,
      },
      cmyk: null,
    },
    {
      code: '18-5418 TPG',
      name: 'Antique Green',
      hex: '3B6D69',
      rgb: {
        r: 59,
        g: 109,
        b: 105,
      },
      lab: {
        l: 42.03,
        a: -17.54,
        b: -4.55,
      },
      cmyk: null,
    },
    {
      code: '18-5725 TPG',
      name: 'Galapagos Green',
      hex: '31675D',
      rgb: {
        r: 49,
        g: 103,
        b: 93,
      },
      lab: {
        l: 39.23,
        a: -20.69,
        b: -1.43,
      },
      cmyk: null,
    },
    {
      code: '19-5230 TPG',
      name: 'Forest Biome',
      hex: '345654',
      rgb: {
        r: 52,
        g: 86,
        b: 84,
      },
      lab: {
        l: 33.81,
        a: -13.19,
        b: -3.64,
      },
      cmyk: null,
    },
    {
      code: '16-5917 TPG',
      name: 'Malachite Green',
      hex: '6C9987',
      rgb: {
        r: 108,
        g: 153,
        b: 135,
      },
      lab: {
        l: 59.23,
        a: -19.22,
        b: 3.19,
      },
      cmyk: null,
    },
    {
      code: '18-5621 TPG',
      name: 'Fir',
      hex: '467768',
      rgb: {
        r: 70,
        g: 119,
        b: 104,
      },
      lab: {
        l: 45.84,
        a: -18.59,
        b: 1.19,
      },
      cmyk: null,
    },
    {
      code: '19-5420 TPG',
      name: 'Evergreen',
      hex: '31655A',
      rgb: {
        r: 49,
        g: 101,
        b: 90,
      },
      lab: {
        l: 38.73,
        a: -20.6,
        b: -0.24,
      },
      cmyk: null,
    },
    {
      code: '18-5616 TPG',
      name: 'Posy Green',
      hex: '3E625B',
      rgb: {
        r: 62,
        g: 98,
        b: 91,
      },
      lab: {
        l: 38.36,
        a: -14.28,
        b: -1.32,
      },
      cmyk: null,
    },
    {
      code: '19-5511 TPG',
      name: 'Hunter Green',
      hex: '435E55',
      rgb: {
        r: 67,
        g: 94,
        b: 85,
      },
      lab: {
        l: 37.49,
        a: -12.6,
        b: 1.42,
      },
      cmyk: null,
    },
    {
      code: '18-5718 TPG',
      name: 'Smoke Pine',
      hex: '4E6C64',
      rgb: {
        r: 78,
        g: 108,
        b: 100,
      },
      lab: {
        l: 42.86,
        a: -12.95,
        b: -0.06,
      },
      cmyk: null,
    },
    {
      code: '19-5232 TPG',
      name: 'Rain Forest',
      hex: '375550',
      rgb: {
        r: 55,
        g: 85,
        b: 80,
      },
      lab: {
        l: 33.34,
        a: -12.23,
        b: -1.84,
      },
      cmyk: null,
    },
    {
      code: '16-5721 TPG',
      name: 'Marine Green',
      hex: '499F8C',
      rgb: {
        r: 73,
        g: 159,
        b: 140,
      },
      lab: {
        l: 59.26,
        a: -31.28,
        b: 0.25,
      },
      cmyk: null,
    },
    {
      code: '17-5430 TPG',
      name: 'Alhambra',
      hex: '288A7C',
      rgb: {
        r: 40,
        g: 138,
        b: 124,
      },
      lab: {
        l: 51.22,
        a: -31.97,
        b: -2.57,
      },
      cmyk: null,
    },
    {
      code: '17-5734 TPG',
      name: 'Viridis',
      hex: '1A8872',
      rgb: {
        r: 26,
        g: 136,
        b: 114,
      },
      lab: {
        l: 50.12,
        a: -35.02,
        b: 1.44,
      },
      cmyk: null,
    },
    {
      code: '17-5528 TPG',
      name: 'Greenlake',
      hex: '158272',
      rgb: {
        r: 21,
        g: 130,
        b: 114,
      },
      lab: {
        l: 47.87,
        a: -32.81,
        b: -1.86,
      },
      cmyk: null,
    },
    {
      code: '18-5624 TPG',
      name: 'Shady Glade',
      hex: '297969',
      rgb: {
        r: 41,
        g: 121,
        b: 105,
      },
      lab: {
        l: 45.44,
        a: -28.78,
        b: 0.43,
      },
      cmyk: null,
    },
    {
      code: '18-5424 TPG',
      name: 'Cadmium Green',
      hex: '13756A',
      rgb: {
        r: 19,
        g: 117,
        b: 106,
      },
      lab: {
        l: 43.54,
        a: -29.58,
        b: -3.46,
      },
      cmyk: null,
    },
    {
      code: '18-5322 TPG',
      name: 'Alpine Green',
      hex: '236761',
      rgb: {
        r: 35,
        g: 103,
        b: 97,
      },
      lab: {
        l: 38.75,
        a: -22.7,
        b: -4.52,
      },
      cmyk: null,
    },
    {
      code: '16-5426 TPG',
      name: 'Water Garden',
      hex: '29A793',
      rgb: {
        r: 41,
        g: 167,
        b: 147,
      },
      lab: {
        l: 61.04,
        a: -38.74,
        b: -1.61,
      },
      cmyk: null,
    },
    {
      code: '17-5440 TPG',
      name: 'Ocean Floor',
      hex: '00887C',
      rgb: {
        r: 0,
        g: 136,
        b: 124,
      },
      lab: {
        l: 49.76,
        a: -35.49,
        b: -5.18,
      },
      cmyk: null,
    },
    {
      code: '17-5730 TPG',
      name: 'Slushy',
      hex: '008D7D',
      rgb: {
        r: 0,
        g: 141,
        b: 125,
      },
      lab: {
        l: 51.26,
        a: -39.21,
        b: -3.53,
      },
      cmyk: null,
    },
    {
      code: '17-5527 TPG',
      name: 'Sporting Green',
      hex: '007C6D',
      rgb: {
        r: 0,
        g: 124,
        b: 109,
      },
      lab: {
        l: 44.8,
        a: -37.85,
        b: -3.98,
      },
      cmyk: null,
    },
    {
      code: '18-5016 TPG',
      name: 'Proud Peacock',
      hex: '00746D',
      rgb: {
        r: 0,
        g: 116,
        b: 109,
      },
      lab: {
        l: 42.27,
        a: -33.81,
        b: -7.4,
      },
      cmyk: null,
    },
    {
      code: '18-5425 TPG',
      name: 'Bear Grass',
      hex: '007265',
      rgb: {
        r: 0,
        g: 114,
        b: 101,
      },
      lab: {
        l: 41.98,
        a: -31.04,
        b: -2.45,
      },
      cmyk: null,
    },
    {
      code: '19-5421 TPG',
      name: 'Aventurine',
      hex: '0D5E54',
      rgb: {
        r: 13,
        g: 94,
        b: 84,
      },
      lab: {
        l: 34.96,
        a: -24.72,
        b: -2.7,
      },
      cmyk: null,
    },
    {
      code: '16-5421 TPG',
      name: 'Sea Green',
      hex: '2BA08B',
      rgb: {
        r: 43,
        g: 160,
        b: 139,
      },
      lab: {
        l: 58.73,
        a: -37.31,
        b: -0.47,
      },
      cmyk: null,
    },
    {
      code: '17-5633 TPG',
      name: 'Deep Green',
      hex: '00957D',
      rgb: {
        r: 0,
        g: 149,
        b: 125,
      },
      lab: {
        l: 53.28,
        a: -45.11,
        b: -0.6,
      },
      cmyk: null,
    },
    {
      code: '17-5638 TPG',
      name: 'Vivid Green',
      hex: '00A386',
      rgb: {
        r: 0,
        g: 163,
        b: 134,
      },
      lab: {
        l: 58.44,
        a: -48.04,
        b: 1.57,
      },
      cmyk: null,
    },
    {
      code: '17-5641 TPG',
      name: 'Emerald',
      hex: '00997B',
      rgb: {
        r: 0,
        g: 153,
        b: 123,
      },
      lab: {
        l: 54.94,
        a: -46.53,
        b: 2.58,
      },
      cmyk: null,
    },
    {
      code: '18-5841 TPG',
      name: 'Pepper Green',
      hex: '00856A',
      rgb: {
        r: 0,
        g: 133,
        b: 106,
      },
      lab: {
        l: 47.7,
        a: -43.98,
        b: 2.08,
      },
      cmyk: null,
    },
    {
      code: '17-5735 TPG',
      name: 'Parakeet',
      hex: '009270',
      rgb: {
        r: 0,
        g: 146,
        b: 112,
      },
      lab: {
        l: 52.19,
        a: -47.46,
        b: 4.91,
      },
      cmyk: null,
    },
    {
      code: '18-5642 TPG',
      name: 'Golf Green',
      hex: '009170',
      rgb: {
        r: 0,
        g: 145,
        b: 112,
      },
      lab: {
        l: 51.53,
        a: -49.29,
        b: 4.09,
      },
      cmyk: null,
    },
    {
      code: '16-5932 TPG',
      name: 'Holly Green',
      hex: '15A07D',
      rgb: {
        r: 21,
        g: 160,
        b: 125,
      },
      lab: {
        l: 58.07,
        a: -42.73,
        b: 6.57,
      },
      cmyk: null,
    },
    {
      code: '17-5936 TPG',
      name: 'Simply Green',
      hex: '139C7A',
      rgb: {
        r: 19,
        g: 156,
        b: 122,
      },
      lab: {
        l: 56.62,
        a: -41.13,
        b: 5.74,
      },
      cmyk: null,
    },
    {
      code: '16-5938 TPG',
      name: 'Mint',
      hex: '00A779',
      rgb: {
        r: 0,
        g: 167,
        b: 121,
      },
      lab: {
        l: 59.67,
        a: -50.36,
        b: 10.7,
      },
      cmyk: null,
    },
    {
      code: '17-5937 TPG',
      name: 'Deep Mint',
      hex: '00A377',
      rgb: {
        r: 0,
        g: 163,
        b: 119,
      },
      lab: {
        l: 58.64,
        a: -47.18,
        b: 10.46,
      },
      cmyk: null,
    },
    {
      code: '18-5633 TPG',
      name: 'Bosphorus',
      hex: '0A8066',
      rgb: {
        r: 10,
        g: 128,
        b: 102,
      },
      lab: {
        l: 47.02,
        a: -35.68,
        b: 3.76,
      },
      cmyk: null,
    },
    {
      code: '18-5845 TPG',
      name: 'Lush Meadow',
      hex: '00755D',
      rgb: {
        r: 0,
        g: 117,
        b: 93,
      },
      lab: {
        l: 42.46,
        a: -36.48,
        b: 2.56,
      },
      cmyk: null,
    },
    {
      code: '18-5338 TPG',
      name: 'Ultramarine Green',
      hex: '007762',
      rgb: {
        r: 0,
        g: 119,
        b: 98,
      },
      lab: {
        l: 43.19,
        a: -36.64,
        b: 0.61,
      },
      cmyk: null,
    },
    {
      code: '14-6017 TPG',
      name: 'Neptune Green',
      hex: '81BBA1',
      rgb: {
        r: 129,
        g: 187,
        b: 161,
      },
      lab: {
        l: 71.1,
        a: -24.69,
        b: 6.11,
      },
      cmyk: null,
    },
    {
      code: '16-6030 TPG',
      name: 'Katydid',
      hex: '69B794',
      rgb: {
        r: 105,
        g: 183,
        b: 148,
      },
      lab: {
        l: 68.36,
        a: -31.23,
        b: 8.54,
      },
      cmyk: null,
    },
    {
      code: '14-6330 TPG',
      name: 'Spring Bud',
      hex: '71D4A5',
      rgb: {
        r: 113,
        g: 212,
        b: 165,
      },
      lab: {
        l: 77.37,
        a: -39.16,
        b: 12.36,
      },
      cmyk: null,
    },
    {
      code: '15-6123 TPG',
      name: 'Jade Cream',
      hex: '65BA96',
      rgb: {
        r: 101,
        g: 186,
        b: 150,
      },
      lab: {
        l: 68.86,
        a: -33.67,
        b: 8.5,
      },
      cmyk: null,
    },
    {
      code: '16-5930 TPG',
      name: 'Ming Green',
      hex: '40A37B',
      rgb: {
        r: 64,
        g: 163,
        b: 123,
      },
      lab: {
        l: 60.06,
        a: -37.77,
        b: 10.57,
      },
      cmyk: null,
    },
    {
      code: '16-5942 TPG',
      name: 'Blarney',
      hex: '00A87C',
      rgb: {
        r: 0,
        g: 168,
        b: 124,
      },
      lab: {
        l: 60.15,
        a: -49.79,
        b: 9.3,
      },
      cmyk: null,
    },
    {
      code: '16-5825 TPG',
      name: 'Gumdrop Green',
      hex: '41A98D',
      rgb: {
        r: 65,
        g: 169,
        b: 141,
      },
      lab: {
        l: 62.32,
        a: -37.15,
        b: 3.8,
      },
      cmyk: null,
    },
    {
      code: '15-5812 TPG',
      name: 'Lichen',
      hex: '99C2B0',
      rgb: {
        r: 153,
        g: 194,
        b: 176,
      },
      lab: {
        l: 74.72,
        a: -16.52,
        b: 3.64,
      },
      cmyk: null,
    },
    {
      code: '13-5907 TPG',
      name: 'Gossamer Green',
      hex: 'AECEBD',
      rgb: {
        r: 174,
        g: 206,
        b: 189,
      },
      lab: {
        l: 79.94,
        a: -13.59,
        b: 3.95,
      },
      cmyk: null,
    },
    {
      code: '13-5911 TPG',
      name: "Bird's Egg Green",
      hex: 'A8D0BD',
      rgb: {
        r: 168,
        g: 208,
        b: 189,
      },
      lab: {
        l: 80.01,
        a: -16.94,
        b: 4.38,
      },
      cmyk: null,
    },
    {
      code: '13-6110 TPG',
      name: 'Mist Green',
      hex: 'A7CEB9',
      rgb: {
        r: 167,
        g: 206,
        b: 185,
      },
      lab: {
        l: 79.39,
        a: -17.14,
        b: 5.52,
      },
      cmyk: null,
    },
    {
      code: '14-6011 TPG',
      name: 'Grayed Jade',
      hex: '9AC0AC',
      rgb: {
        r: 154,
        g: 192,
        b: 172,
      },
      lab: {
        l: 74.18,
        a: -15.9,
        b: 4.95,
      },
      cmyk: null,
    },
    {
      code: '16-5820 TPG',
      name: 'Green Spruce',
      hex: '5C9F85',
      rgb: {
        r: 92,
        g: 159,
        b: 133,
      },
      lab: {
        l: 60.21,
        a: -26.14,
        b: 5.17,
      },
      cmyk: null,
    },
    {
      code: '16-5924 TPG',
      name: 'Winter Green',
      hex: '59A085',
      rgb: {
        r: 89,
        g: 160,
        b: 133,
      },
      lab: {
        l: 60.35,
        a: -29.47,
        b: 6.03,
      },
      cmyk: null,
    },
    {
      code: '11-0304 TPG',
      name: 'Water Lily',
      hex: 'DEE3D7',
      rgb: {
        r: 222,
        g: 227,
        b: 215,
      },
      lab: {
        l: 89.52,
        a: -3.24,
        b: 5.08,
      },
      cmyk: null,
    },
    {
      code: '12-6208 TPG',
      name: 'Phantom Green',
      hex: 'DBE2D6',
      rgb: {
        r: 219,
        g: 226,
        b: 214,
      },
      lab: {
        l: 89.04,
        a: -3.64,
        b: 4.71,
      },
      cmyk: null,
    },
    {
      code: '12-6206 TPG',
      name: 'Fairest Jade',
      hex: 'DBE2D5',
      rgb: {
        r: 219,
        g: 226,
        b: 213,
      },
      lab: {
        l: 89.15,
        a: -4.33,
        b: 5.2,
      },
      cmyk: null,
    },
    {
      code: '12-5504 TPG',
      name: 'Clearly Aqua',
      hex: 'CFE4D7',
      rgb: {
        r: 207,
        g: 228,
        b: 215,
      },
      lab: {
        l: 88.5,
        a: -8.62,
        b: 3.03,
      },
      cmyk: null,
    },
    {
      code: '13-6008 TPG',
      name: 'Misty Jade',
      hex: 'BDDAC9',
      rgb: {
        r: 189,
        g: 218,
        b: 201,
      },
      lab: {
        l: 84.48,
        a: -12.25,
        b: 4.27,
      },
      cmyk: null,
    },
    {
      code: '12-5506 TPG',
      name: 'Dusty Aqua',
      hex: 'C2DCD1',
      rgb: {
        r: 194,
        g: 220,
        b: 209,
      },
      lab: {
        l: 85.38,
        a: -10.32,
        b: 1.51,
      },
      cmyk: null,
    },
    {
      code: '14-6312 TPG',
      name: 'Cameo Green',
      hex: 'A6C2AE',
      rgb: {
        r: 166,
        g: 194,
        b: 174,
      },
      lab: {
        l: 75.65,
        a: -12.91,
        b: 6.09,
      },
      cmyk: null,
    },
    {
      code: '11-4303 TPG',
      name: 'Mystic Blue',
      hex: 'E5E8E0',
      rgb: {
        r: 229,
        g: 232,
        b: 224,
      },
      lab: {
        l: 91.47,
        a: -1.83,
        b: 3.12,
      },
      cmyk: null,
    },
    {
      code: '12-4304 TPG',
      name: 'Bluewash',
      hex: 'E0E5E0',
      rgb: {
        r: 224,
        g: 229,
        b: 224,
      },
      lab: {
        l: 90.46,
        a: -2.69,
        b: 1.75,
      },
      cmyk: null,
    },
    {
      code: '11-4301 TPG',
      name: 'Lily White',
      hex: 'E4E3DC',
      rgb: {
        r: 228,
        g: 227,
        b: 220,
      },
      lab: {
        l: 90.18,
        a: -0.5,
        b: 3.29,
      },
      cmyk: null,
    },
    {
      code: '11-4802 TPG',
      name: 'Summer Shower',
      hex: 'E4E9E1',
      rgb: {
        r: 228,
        g: 233,
        b: 225,
      },
      lab: {
        l: 91.64,
        a: -2.57,
        b: 2.81,
      },
      cmyk: null,
    },
    {
      code: '11-4804 TPG',
      name: 'Lightest Sky',
      hex: 'E7EBE0',
      rgb: {
        r: 231,
        g: 235,
        b: 224,
      },
      lab: {
        l: 92.57,
        a: -2.9,
        b: 4.71,
      },
      cmyk: null,
    },
    {
      code: '12-5203 TPG',
      name: 'Murmur',
      hex: 'D3DCD6',
      rgb: {
        r: 211,
        g: 220,
        b: 214,
      },
      lab: {
        l: 86.92,
        a: -3.93,
        b: 1.49,
      },
      cmyk: null,
    },
    {
      code: '12-5603 TPG',
      name: 'Zephyr Blue',
      hex: 'D6DDD4',
      rgb: {
        r: 214,
        g: 221,
        b: 212,
      },
      lab: {
        l: 87.31,
        a: -3.9,
        b: 3.29,
      },
      cmyk: null,
    },
    {
      code: '12-5404 TPG',
      name: 'Whisper Green',
      hex: 'E2E6D9',
      rgb: {
        r: 226,
        g: 230,
        b: 217,
      },
      lab: {
        l: 90.59,
        a: -2.71,
        b: 5.56,
      },
      cmyk: null,
    },
    {
      code: '12-6205 TPG',
      name: 'Milky Green',
      hex: 'CFDAD0',
      rgb: {
        r: 207,
        g: 218,
        b: 208,
      },
      lab: {
        l: 85.87,
        a: -5.42,
        b: 3.24,
      },
      cmyk: null,
    },
    {
      code: '13-0107 TPG',
      name: 'Dewkist',
      hex: 'C1D0BE',
      rgb: {
        r: 193,
        g: 208,
        b: 190,
      },
      lab: {
        l: 81.86,
        a: -7.79,
        b: 6.52,
      },
      cmyk: null,
    },
    {
      code: '13-6106 TPG',
      name: 'Green Tint',
      hex: 'C6CEC0',
      rgb: {
        r: 198,
        g: 206,
        b: 192,
      },
      lab: {
        l: 81.62,
        a: -4.57,
        b: 5.63,
      },
      cmyk: null,
    },
    {
      code: '13-6107 TPG',
      name: 'Green Lily',
      hex: 'BDCEBD',
      rgb: {
        r: 189,
        g: 206,
        b: 189,
      },
      lab: {
        l: 80.93,
        a: -8.34,
        b: 5.64,
      },
      cmyk: null,
    },
    {
      code: '13-6108 TPG',
      name: 'Celadon',
      hex: 'B4CAB7',
      rgb: {
        r: 180,
        g: 202,
        b: 183,
      },
      lab: {
        l: 79.06,
        a: -10.21,
        b: 6.15,
      },
      cmyk: null,
    },
    {
      code: '14-6008 TPG',
      name: 'Subtle Green',
      hex: 'AFC9BA',
      rgb: {
        r: 175,
        g: 201,
        b: 186,
      },
      lab: {
        l: 78.49,
        a: -11.23,
        b: 3.76,
      },
      cmyk: null,
    },
    {
      code: '14-4505 TPG',
      name: 'Smoke',
      hex: 'BCC6C2',
      rgb: {
        r: 188,
        g: 198,
        b: 194,
      },
      lab: {
        l: 78.92,
        a: -4.1,
        b: 0.58,
      },
      cmyk: null,
    },
    {
      code: '13-5305 TPG',
      name: 'Pale Aqua',
      hex: 'C0CDC4',
      rgb: {
        r: 192,
        g: 205,
        b: 196,
      },
      lab: {
        l: 81.06,
        a: -5.45,
        b: 2.51,
      },
      cmyk: null,
    },
    {
      code: '14-5707 TPG',
      name: 'Aqua Foam',
      hex: 'ABC4B6',
      rgb: {
        r: 171,
        g: 196,
        b: 182,
      },
      lab: {
        l: 76.79,
        a: -10.86,
        b: 3.48,
      },
      cmyk: null,
    },
    {
      code: '14-5706 TPG',
      name: 'Silt Green',
      hex: 'ABC0B5',
      rgb: {
        r: 171,
        g: 192,
        b: 181,
      },
      lab: {
        l: 75.77,
        a: -9.53,
        b: 2.8,
      },
      cmyk: null,
    },
    {
      code: '15-5706 TPG',
      name: 'Frosty Green',
      hex: 'A1B5A7',
      rgb: {
        r: 161,
        g: 181,
        b: 167,
      },
      lab: {
        l: 71.56,
        a: -9.09,
        b: 3.91,
      },
      cmyk: null,
    },
    {
      code: '16-5907 TPG',
      name: 'Granite Green',
      hex: '85A295',
      rgb: {
        r: 133,
        g: 162,
        b: 149,
      },
      lab: {
        l: 63.96,
        a: -13.09,
        b: 2.91,
      },
      cmyk: null,
    },
    {
      code: '16-5810 TPG',
      name: 'Green Bay',
      hex: '7A8E81',
      rgb: {
        r: 122,
        g: 142,
        b: 129,
      },
      lab: {
        l: 57.09,
        a: -9.83,
        b: 3.85,
      },
      cmyk: null,
    },
    {
      code: '12-5403 TPG',
      name: 'Blue Flower',
      hex: 'D1DCD7',
      rgb: {
        r: 209,
        g: 220,
        b: 215,
      },
      lab: {
        l: 86.75,
        a: -4.59,
        b: 1.13,
      },
      cmyk: null,
    },
    {
      code: '14-4502 TPG',
      name: 'Mercury',
      hex: 'BBC3BE',
      rgb: {
        r: 187,
        g: 195,
        b: 190,
      },
      lab: {
        l: 77.96,
        a: -3.51,
        b: 1.42,
      },
      cmyk: null,
    },
    {
      code: '14-4503 TPG',
      name: 'Metal',
      hex: 'B2BCB9',
      rgb: {
        r: 178,
        g: 188,
        b: 185,
      },
      lab: {
        l: 75.48,
        a: -4.4,
        b: 0.45,
      },
      cmyk: null,
    },
    {
      code: '15-4702 TPG',
      name: 'Puritan Gray',
      hex: 'A0ADAA',
      rgb: {
        r: 160,
        g: 173,
        b: 170,
      },
      lab: {
        l: 69.56,
        a: -5.52,
        b: -0.18,
      },
      cmyk: null,
    },
    {
      code: '15-4704 TPG',
      name: 'Pigeon',
      hex: 'A6B0AB',
      rgb: {
        r: 166,
        g: 176,
        b: 171,
      },
      lab: {
        l: 70.91,
        a: -4.55,
        b: 1,
      },
      cmyk: null,
    },
    {
      code: '15-5205 TPG',
      name: 'Aqua Gray',
      hex: 'A2AFA8',
      rgb: {
        r: 162,
        g: 175,
        b: 168,
      },
      lab: {
        l: 70.03,
        a: -5.61,
        b: 1.48,
      },
      cmyk: null,
    },
    {
      code: '16-5808 TPG',
      name: 'Iceberg Green',
      hex: '899C92',
      rgb: {
        r: 137,
        g: 156,
        b: 146,
      },
      lab: {
        l: 62.64,
        a: -8.88,
        b: 2.61,
      },
      cmyk: null,
    },
    {
      code: '16-5807 TPG',
      name: 'Lily Pad',
      hex: '7E8F84',
      rgb: {
        r: 126,
        g: 143,
        b: 132,
      },
      lab: {
        l: 57.7,
        a: -7.99,
        b: 3.19,
      },
      cmyk: null,
    },
    {
      code: '17-6009 TPG',
      name: 'Laurel Wreath',
      hex: '627269',
      rgb: {
        r: 98,
        g: 114,
        b: 105,
      },
      lab: {
        l: 46.28,
        a: -7.39,
        b: 2.24,
      },
      cmyk: null,
    },
    {
      code: '18-5611 TPG',
      name: 'Dark Forest',
      hex: '586864',
      rgb: {
        r: 88,
        g: 104,
        b: 100,
      },
      lab: {
        l: 42.54,
        a: -7.06,
        b: -0.05,
      },
      cmyk: null,
    },
    {
      code: '18-6011 TPG',
      name: 'Duck Green',
      hex: '5A6F65',
      rgb: {
        r: 90,
        g: 111,
        b: 101,
      },
      lab: {
        l: 44.77,
        a: -9.99,
        b: 2.34,
      },
      cmyk: null,
    },
    {
      code: '18-5913 TPG',
      name: 'Garden Topiary',
      hex: '425750',
      rgb: {
        r: 66,
        g: 87,
        b: 80,
      },
      lab: {
        l: 34.91,
        a: -9.74,
        b: 0.58,
      },
      cmyk: null,
    },
    {
      code: '19-5411 TPG',
      name: 'Trekking Green',
      hex: '465B57',
      rgb: {
        r: 70,
        g: 91,
        b: 87,
      },
      lab: {
        l: 36.52,
        a: -9.11,
        b: -1.02,
      },
      cmyk: null,
    },
    {
      code: '19-5220 TPG',
      name: 'Botanical Garden',
      hex: '30524E',
      rgb: {
        r: 48,
        g: 82,
        b: 78,
      },
      lab: {
        l: 31.84,
        a: -13.43,
        b: -2.7,
      },
      cmyk: null,
    },
    {
      code: '19-5004 TPG',
      name: 'Urban Chic',
      hex: '525A5B',
      rgb: {
        r: 82,
        g: 90,
        b: 91,
      },
      lab: {
        l: 37.58,
        a: -3.46,
        b: -1.81,
      },
      cmyk: null,
    },
    {
      code: '19-4906 TPG',
      name: 'Green Gables',
      hex: '445252',
      rgb: {
        r: 68,
        g: 82,
        b: 82,
      },
      lab: {
        l: 33.51,
        a: -5.93,
        b: -2.31,
      },
      cmyk: null,
    },
    {
      code: '19-5212 TPG',
      name: 'Darkest Spruce',
      hex: '424F50',
      rgb: {
        r: 66,
        g: 79,
        b: 80,
      },
      lab: {
        l: 32.55,
        a: -5.31,
        b: -2.65,
      },
      cmyk: null,
    },
    {
      code: '19-5406 TPG',
      name: 'Pine Grove',
      hex: '384A46',
      rgb: {
        r: 56,
        g: 74,
        b: 70,
      },
      lab: {
        l: 29.85,
        a: -7.98,
        b: -0.49,
      },
      cmyk: null,
    },
    {
      code: '19-5918 TPG',
      name: 'Mountain View',
      hex: '3B4A40',
      rgb: {
        r: 59,
        g: 74,
        b: 64,
      },
      lab: {
        l: 29.75,
        a: -8.2,
        b: 3.7,
      },
      cmyk: null,
    },
    {
      code: '19-6110 TPG',
      name: 'Deep Forest',
      hex: '444C48',
      rgb: {
        r: 68,
        g: 76,
        b: 72,
      },
      lab: {
        l: 31.36,
        a: -4.18,
        b: 1.21,
      },
      cmyk: null,
    },
    {
      code: '19-5350 TPG',
      name: 'Scarab',
      hex: '3B4443',
      rgb: {
        r: 59,
        g: 68,
        b: 67,
      },
      lab: {
        l: 27.94,
        a: -4.19,
        b: -0.67,
      },
      cmyk: null,
    },
    {
      code: '17-6212 TPG',
      name: 'Sea Spray',
      hex: '6E7F71',
      rgb: {
        r: 110,
        g: 127,
        b: 113,
      },
      lab: {
        l: 51.39,
        a: -8.7,
        b: 5,
      },
      cmyk: null,
    },
    {
      code: '18-5806 TPG',
      name: 'Agave Green',
      hex: '6E746E',
      rgb: {
        r: 110,
        g: 116,
        b: 110,
      },
      lab: {
        l: 48.14,
        a: -3.84,
        b: 2.62,
      },
      cmyk: null,
    },
    {
      code: '19-0309 TPG',
      name: 'Thyme',
      hex: '596258',
      rgb: {
        r: 89,
        g: 98,
        b: 88,
      },
      lab: {
        l: 40.4,
        a: -5.38,
        b: 4.07,
      },
      cmyk: null,
    },
    {
      code: '19-5621 TPG',
      name: 'Cilantro',
      hex: '4F5D56',
      rgb: {
        r: 79,
        g: 93,
        b: 86,
      },
      lab: {
        l: 37.97,
        a: -7.09,
        b: 1.87,
      },
      cmyk: null,
    },
    {
      code: '19-5914 TPG',
      name: 'Jungle Green',
      hex: '4B5B58',
      rgb: {
        r: 75,
        g: 91,
        b: 88,
      },
      lab: {
        l: 37.16,
        a: -6.94,
        b: -0.55,
      },
      cmyk: null,
    },
    {
      code: '19-5920 TPG',
      name: 'Pineneedle',
      hex: '455952',
      rgb: {
        r: 69,
        g: 89,
        b: 82,
      },
      lab: {
        l: 35.71,
        a: -9.24,
        b: 1.16,
      },
      cmyk: null,
    },
    {
      code: '19-5917 TPG',
      name: 'Sycamore',
      hex: '3B4E47',
      rgb: {
        r: 59,
        g: 78,
        b: 71,
      },
      lab: {
        l: 31.12,
        a: -8.84,
        b: 1.21,
      },
      cmyk: null,
    },
    {
      code: '18-0515 TPG',
      name: 'Dusty Olive',
      hex: '6C6B61',
      rgb: {
        r: 108,
        g: 107,
        b: 97,
      },
      lab: {
        l: 45.04,
        a: -0.48,
        b: 5.79,
      },
      cmyk: null,
    },
    {
      code: '19-0312 TPG',
      name: 'Beetle',
      hex: '5F645A',
      rgb: {
        r: 95,
        g: 100,
        b: 90,
      },
      lab: {
        l: 41.69,
        a: -3.48,
        b: 5.05,
      },
      cmyk: null,
    },
    {
      code: '19-0307 TPG',
      name: 'Climbing Ivy',
      hex: '50544E',
      rgb: {
        r: 80,
        g: 84,
        b: 78,
      },
      lab: {
        l: 35.32,
        a: -2.96,
        b: 3.43,
      },
      cmyk: null,
    },
    {
      code: '19-0417 TPG',
      name: 'Kombu Green',
      hex: '434D40',
      rgb: {
        r: 67,
        g: 77,
        b: 64,
      },
      lab: {
        l: 31.45,
        a: -6.7,
        b: 6.14,
      },
      cmyk: null,
    },
    {
      code: '19-0315 TPG',
      name: 'Black Forest',
      hex: '4B5B4C',
      rgb: {
        r: 75,
        g: 91,
        b: 76,
      },
      lab: {
        l: 36.7,
        a: -8.94,
        b: 6.01,
      },
      cmyk: null,
    },
    {
      code: '19-0415 TPG',
      name: 'Duffel Bag',
      hex: '464C46',
      rgb: {
        r: 70,
        g: 76,
        b: 70,
      },
      lab: {
        l: 31.74,
        a: -3.64,
        b: 2.94,
      },
      cmyk: null,
    },
    {
      code: '19-0509 TPG',
      name: 'Rosin',
      hex: '444841',
      rgb: {
        r: 68,
        g: 72,
        b: 65,
      },
      lab: {
        l: 29.97,
        a: -2.93,
        b: 3.26,
      },
      cmyk: null,
    },
    {
      code: '17-0610 TPG',
      name: 'Laurel Oak',
      hex: '8C8B7C',
      rgb: {
        r: 140,
        g: 139,
        b: 124,
      },
      lab: {
        l: 57.64,
        a: -1.96,
        b: 8.53,
      },
      cmyk: null,
    },
    {
      code: '17-0613 TPG',
      name: 'Vetiver',
      hex: '808174',
      rgb: {
        r: 128,
        g: 129,
        b: 116,
      },
      lab: {
        l: 53.51,
        a: -2.37,
        b: 6.98,
      },
      cmyk: null,
    },
    {
      code: '18-0312 TPG',
      name: 'Deep Lichen Green',
      hex: '6C7160',
      rgb: {
        r: 108,
        g: 113,
        b: 96,
      },
      lab: {
        l: 46.66,
        a: -4.63,
        b: 8.79,
      },
      cmyk: null,
    },
    {
      code: '18-0517 TPG',
      name: 'Tea Leaf',
      hex: '6A6960',
      rgb: {
        r: 106,
        g: 105,
        b: 96,
      },
      lab: {
        l: 44.27,
        a: -0.66,
        b: 5.32,
      },
      cmyk: null,
    },
    {
      code: '18-0514 TPG',
      name: 'Mulled Basil',
      hex: '6D6F67',
      rgb: {
        r: 109,
        g: 111,
        b: 103,
      },
      lab: {
        l: 46.42,
        a: -1.73,
        b: 4.09,
      },
      cmyk: null,
    },
    {
      code: '19-0404 TPG',
      name: 'Kambaba',
      hex: '54574F',
      rgb: {
        r: 84,
        g: 87,
        b: 79,
      },
      lab: {
        l: 36.41,
        a: -2.53,
        b: 3.93,
      },
      cmyk: null,
    },
    {
      code: '19-0406 TPG',
      name: 'Chimera',
      hex: '555551',
      rgb: {
        r: 85,
        g: 85,
        b: 81,
      },
      lab: {
        l: 36.14,
        a: -1.04,
        b: 2.28,
      },
      cmyk: null,
    },
    {
      code: '15-6304 TPG',
      name: 'Pussywillow Gray',
      hex: 'AEAFA4',
      rgb: {
        r: 174,
        g: 175,
        b: 164,
      },
      lab: {
        l: 71.08,
        a: -1.8,
        b: 5.44,
      },
      cmyk: null,
    },
    {
      code: '16-0207 TPG',
      name: 'London Fog',
      hex: 'A09F92',
      rgb: {
        r: 160,
        g: 159,
        b: 146,
      },
      lab: {
        l: 65.28,
        a: -1.35,
        b: 6.96,
      },
      cmyk: null,
    },
    {
      code: '15-4306 TPG',
      name: 'Belgian Block',
      hex: 'A6ABA6',
      rgb: {
        r: 166,
        g: 171,
        b: 166,
      },
      lab: {
        l: 69.45,
        a: -2.19,
        b: 1.45,
      },
      cmyk: null,
    },
    {
      code: '16-5904 TPG',
      name: 'Wrought Iron',
      hex: '94A09B',
      rgb: {
        r: 148,
        g: 160,
        b: 155,
      },
      lab: {
        l: 64.65,
        a: -5.27,
        b: 1.04,
      },
      cmyk: null,
    },
    {
      code: '17-0209 TPG',
      name: 'Forest Fog',
      hex: '91938D',
      rgb: {
        r: 145,
        g: 147,
        b: 141,
      },
      lab: {
        l: 60.59,
        a: -1.68,
        b: 3.05,
      },
      cmyk: null,
    },
    {
      code: '17-0208 TPG',
      name: 'Dried Sage',
      hex: '8D8E84',
      rgb: {
        r: 141,
        g: 142,
        b: 132,
      },
      lab: {
        l: 58.81,
        a: -1.57,
        b: 5.35,
      },
      cmyk: null,
    },
    {
      code: '18-0516 TPG',
      name: 'Smokey Olive',
      hex: '6E6D63',
      rgb: {
        r: 110,
        g: 109,
        b: 99,
      },
      lab: {
        l: 45.88,
        a: -0.58,
        b: 5.64,
      },
      cmyk: null,
    },
    {
      code: '12-5703 TPG',
      name: 'Frosted Mint',
      hex: 'D1D8CE',
      rgb: {
        r: 209,
        g: 216,
        b: 206,
      },
      lab: {
        l: 85.47,
        a: -3.92,
        b: 4.13,
      },
      cmyk: null,
    },
    {
      code: '13-6105 TPG',
      name: 'Celadon Tint',
      hex: 'CDD0C0',
      rgb: {
        r: 205,
        g: 208,
        b: 192,
      },
      lab: {
        l: 82.89,
        a: -2.99,
        b: 7.18,
      },
      cmyk: null,
    },
    {
      code: '15-5704 TPG',
      name: 'Mineral Gray',
      hex: 'B0B6AE',
      rgb: {
        r: 176,
        g: 182,
        b: 174,
      },
      lab: {
        l: 73.26,
        a: -3.02,
        b: 2.96,
      },
      cmyk: null,
    },
    {
      code: '16-0110 TPG',
      name: 'Desert Sage',
      hex: 'A3AC9B',
      rgb: {
        r: 163,
        g: 172,
        b: 155,
      },
      lab: {
        l: 69.12,
        a: -5.2,
        b: 7.04,
      },
      cmyk: null,
    },
    {
      code: '16-6008 TPG',
      name: 'Seagrass',
      hex: '92998C',
      rgb: {
        r: 146,
        g: 153,
        b: 140,
      },
      lab: {
        l: 62.2,
        a: -4.26,
        b: 5.75,
      },
      cmyk: null,
    },
    {
      code: '16-0213 TPG',
      name: 'Tea',
      hex: '999E88',
      rgb: {
        r: 153,
        g: 158,
        b: 136,
      },
      lab: {
        l: 64.27,
        a: -4.87,
        b: 11.13,
      },
      cmyk: null,
    },
    {
      code: '17-6206 TPG',
      name: 'Shadow',
      hex: '899187',
      rgb: {
        r: 137,
        g: 145,
        b: 135,
      },
      lab: {
        l: 59.09,
        a: -4.24,
        b: 4.04,
      },
      cmyk: null,
    },
    {
      code: '13-0210 TPG',
      name: 'Fog Green',
      hex: 'BFCAB3',
      rgb: {
        r: 191,
        g: 202,
        b: 179,
      },
      lab: {
        l: 80.01,
        a: -6.65,
        b: 9.65,
      },
      cmyk: null,
    },
    {
      code: '13-0212 TPG',
      name: 'Tender Greens',
      hex: 'C5CFB3',
      rgb: {
        r: 197,
        g: 207,
        b: 179,
      },
      lab: {
        l: 81.73,
        a: -6.88,
        b: 12.13,
      },
      cmyk: null,
    },
    {
      code: '14-0114 TPG',
      name: 'Celadon Green',
      hex: 'B1C1A2',
      rgb: {
        r: 177,
        g: 193,
        b: 162,
      },
      lab: {
        l: 76.05,
        a: -9.2,
        b: 12.89,
      },
      cmyk: null,
    },
    {
      code: '15-6313 TPG',
      name: 'Laurel Green',
      hex: 'A8B89E',
      rgb: {
        r: 168,
        g: 184,
        b: 158,
      },
      lab: {
        l: 72.99,
        a: -9.07,
        b: 10.81,
      },
      cmyk: null,
    },
    {
      code: '15-6310 TPG',
      name: 'Swamp',
      hex: 'ABB49C',
      rgb: {
        r: 171,
        g: 180,
        b: 156,
      },
      lab: {
        l: 72.03,
        a: -6.32,
        b: 10.84,
      },
      cmyk: null,
    },
    {
      code: '15-6414 TPG',
      name: 'Reseda',
      hex: 'A1B194',
      rgb: {
        r: 161,
        g: 177,
        b: 148,
      },
      lab: {
        l: 70.15,
        a: -9.43,
        b: 12.16,
      },
      cmyk: null,
    },
    {
      code: '17-6323 TPG',
      name: 'Hedge Green',
      hex: '718875',
      rgb: {
        r: 113,
        g: 136,
        b: 117,
      },
      lab: {
        l: 54.15,
        a: -11.2,
        b: 6.69,
      },
      cmyk: null,
    },
    {
      code: '14-6007 TPG',
      name: 'Sea Foam',
      hex: 'B5C1B3',
      rgb: {
        r: 181,
        g: 193,
        b: 179,
      },
      lab: {
        l: 76.82,
        a: -6.01,
        b: 5.09,
      },
      cmyk: null,
    },
    {
      code: '15-6315 TPG',
      name: 'Smoke Green',
      hex: 'A3BCA6',
      rgb: {
        r: 163,
        g: 188,
        b: 166,
      },
      lab: {
        l: 73.84,
        a: -12.3,
        b: 7.95,
      },
      cmyk: null,
    },
    {
      code: '16-0220 TPG',
      name: 'Mistletoe',
      hex: '859E7F',
      rgb: {
        r: 133,
        g: 158,
        b: 127,
      },
      lab: {
        l: 62.43,
        a: -13.18,
        b: 12.37,
      },
      cmyk: null,
    },
    {
      code: '16-0224 TPG',
      name: 'Green Eyes',
      hex: '7C9670',
      rgb: {
        r: 124,
        g: 150,
        b: 112,
      },
      lab: {
        l: 59.22,
        a: -14.6,
        b: 16.15,
      },
      cmyk: null,
    },
    {
      code: '17-0119 TPG',
      name: 'Turf Green',
      hex: '6E8E6E',
      rgb: {
        r: 110,
        g: 142,
        b: 110,
      },
      lab: {
        l: 55.78,
        a: -16.31,
        b: 12.43,
      },
      cmyk: null,
    },
    {
      code: '17-0220 TPG',
      name: 'Watercress',
      hex: '728D6C',
      rgb: {
        r: 114,
        g: 141,
        b: 108,
      },
      lab: {
        l: 55.62,
        a: -14.44,
        b: 13.3,
      },
      cmyk: null,
    },
    {
      code: '18-0121 TPG',
      name: 'Elm Green',
      hex: '5C795E',
      rgb: {
        r: 92,
        g: 121,
        b: 94,
      },
      lab: {
        l: 47.72,
        a: -14.73,
        b: 10.39,
      },
      cmyk: null,
    },
    {
      code: '13-6006 TPG',
      name: 'Almost Aqua',
      hex: 'CFD6C5',
      rgb: {
        r: 207,
        g: 214,
        b: 197,
      },
      lab: {
        l: 84.77,
        a: -4.61,
        b: 7.23,
      },
      cmyk: null,
    },
    {
      code: '13-6208 TPG',
      name: 'Bok Choy',
      hex: 'C2CFB5',
      rgb: {
        r: 194,
        g: 207,
        b: 181,
      },
      lab: {
        l: 81.57,
        a: -7.85,
        b: 10.95,
      },
      cmyk: null,
    },
    {
      code: '16-6318 TPG',
      name: 'Mineral Green',
      hex: '7B9F7F',
      rgb: {
        r: 123,
        g: 159,
        b: 127,
      },
      lab: {
        l: 62.03,
        a: -17.59,
        b: 12,
      },
      cmyk: null,
    },
    {
      code: '18-6216 TPG',
      name: 'Comfrey',
      hex: '617E6B',
      rgb: {
        r: 97,
        g: 126,
        b: 107,
      },
      lab: {
        l: 50.05,
        a: -14.04,
        b: 6.16,
      },
      cmyk: null,
    },
    {
      code: '17-5912 TPG',
      name: 'Dark Ivy',
      hex: '5F7C6B',
      rgb: {
        r: 95,
        g: 124,
        b: 107,
      },
      lab: {
        l: 49.19,
        a: -13.54,
        b: 5.12,
      },
      cmyk: null,
    },
    {
      code: '18-6114 TPG',
      name: 'Myrtle',
      hex: '577263',
      rgb: {
        r: 87,
        g: 114,
        b: 99,
      },
      lab: {
        l: 45.35,
        a: -13.08,
        b: 4.52,
      },
      cmyk: null,
    },
    {
      code: '18-6018 TPG',
      name: 'Foliage Green',
      hex: '457462',
      rgb: {
        r: 69,
        g: 116,
        b: 98,
      },
      lab: {
        l: 44.86,
        a: -19.94,
        b: 3.51,
      },
      cmyk: null,
    },
    {
      code: '13-6007 TPG',
      name: 'Spray',
      hex: 'BDD5BC',
      rgb: {
        r: 189,
        g: 213,
        b: 188,
      },
      lab: {
        l: 82.82,
        a: -11.67,
        b: 8.87,
      },
      cmyk: null,
    },
    {
      code: '13-0116 TPG',
      name: 'Pastel Green',
      hex: 'B2D4B2',
      rgb: {
        r: 178,
        g: 212,
        b: 178,
      },
      lab: {
        l: 81.57,
        a: -16.86,
        b: 12.44,
      },
      cmyk: null,
    },
    {
      code: '14-6316 TPG',
      name: 'Sprucestone',
      hex: '9CBF9D',
      rgb: {
        r: 156,
        g: 191,
        b: 157,
      },
      lab: {
        l: 73.86,
        a: -16.89,
        b: 12.55,
      },
      cmyk: null,
    },
    {
      code: '14-6324 TPG',
      name: 'Peapod',
      hex: '7FB185',
      rgb: {
        r: 127,
        g: 177,
        b: 133,
      },
      lab: {
        l: 67.5,
        a: -24.96,
        b: 16.61,
      },
      cmyk: null,
    },
    {
      code: '14-6327 TPG',
      name: 'Zephyr Green',
      hex: '7DB285',
      rgb: {
        r: 125,
        g: 178,
        b: 133,
      },
      lab: {
        l: 67.68,
        a: -26.65,
        b: 16.94,
      },
      cmyk: null,
    },
    {
      code: '16-6324 TPG',
      name: 'Jadesheen',
      hex: '73A078',
      rgb: {
        r: 115,
        g: 160,
        b: 120,
      },
      lab: {
        l: 61.5,
        a: -21.77,
        b: 14.67,
      },
      cmyk: null,
    },
    {
      code: '15-6432 TPG',
      name: 'Shamrock',
      hex: '77A670',
      rgb: {
        r: 119,
        g: 166,
        b: 112,
      },
      lab: {
        l: 63.39,
        a: -25.83,
        b: 22.36,
      },
      cmyk: null,
    },
    {
      code: '15-6114 TPG',
      name: 'Hemlock',
      hex: '96C1A3',
      rgb: {
        r: 150,
        g: 193,
        b: 163,
      },
      lab: {
        l: 73.99,
        a: -18.9,
        b: 8.95,
      },
      cmyk: null,
    },
    {
      code: '14-6319 TPG',
      name: 'Meadow',
      hex: '8DBB96',
      rgb: {
        r: 141,
        g: 187,
        b: 150,
      },
      lab: {
        l: 71.64,
        a: -21.06,
        b: 12.64,
      },
      cmyk: null,
    },
    {
      code: '15-6120 TPG',
      name: 'Ming',
      hex: '80B58F',
      rgb: {
        r: 128,
        g: 181,
        b: 143,
      },
      lab: {
        l: 68.78,
        a: -25.06,
        b: 12.59,
      },
      cmyk: null,
    },
    {
      code: '15-6322 TPG',
      name: 'Light Grass Green',
      hex: '7DB289',
      rgb: {
        r: 125,
        g: 178,
        b: 137,
      },
      lab: {
        l: 67.76,
        a: -25.7,
        b: 14.8,
      },
      cmyk: null,
    },
    {
      code: '14-6329 TPG',
      name: 'Absinthe Green',
      hex: '73B584',
      rgb: {
        r: 115,
        g: 181,
        b: 132,
      },
      lab: {
        l: 68.04,
        a: -31.68,
        b: 17.75,
      },
      cmyk: null,
    },
    {
      code: '16-6329 TPG',
      name: 'Peppermint',
      hex: '719F72',
      rgb: {
        r: 113,
        g: 159,
        b: 114,
      },
      lab: {
        l: 60.99,
        a: -24.24,
        b: 17.9,
      },
      cmyk: null,
    },
    {
      code: '18-0110 TPG',
      name: 'English Ivy',
      hex: '688863',
      rgb: {
        r: 104,
        g: 136,
        b: 99,
      },
      lab: {
        l: 53.32,
        a: -16.5,
        b: 14.89,
      },
      cmyk: null,
    },
    {
      code: '14-0127 TPG',
      name: 'Greengage',
      hex: '89BF8D',
      rgb: {
        r: 137,
        g: 191,
        b: 141,
      },
      lab: {
        l: 72.23,
        a: -25.8,
        b: 18.43,
      },
      cmyk: null,
    },
    {
      code: '16-6127 TPG',
      name: 'Greenbriar',
      hex: '4F9D73',
      rgb: {
        r: 79,
        g: 157,
        b: 115,
      },
      lab: {
        l: 58.39,
        a: -32.85,
        b: 12.65,
      },
      cmyk: null,
    },
    {
      code: '18-6022 TPG',
      name: 'Leprechaun',
      hex: '438A69',
      rgb: {
        r: 67,
        g: 138,
        b: 105,
      },
      lab: {
        l: 51.8,
        a: -29.98,
        b: 9.59,
      },
      cmyk: null,
    },
    {
      code: '17-6030 TPG',
      name: 'Jelly Bean',
      hex: '008A59',
      rgb: {
        r: 0,
        g: 138,
        b: 89,
      },
      lab: {
        l: 49.8,
        a: -43.56,
        b: 14.94,
      },
      cmyk: null,
    },
    {
      code: '18-6030 TPG',
      name: 'Jolly Green',
      hex: '00804F',
      rgb: {
        r: 0,
        g: 128,
        b: 79,
      },
      lab: {
        l: 45.95,
        a: -44.06,
        b: 15.23,
      },
      cmyk: null,
    },
    {
      code: '19-6026 TPG',
      name: 'Verdant Green',
      hex: '296F53',
      rgb: {
        r: 41,
        g: 111,
        b: 83,
      },
      lab: {
        l: 41.46,
        a: -28.14,
        b: 7.51,
      },
      cmyk: null,
    },
    {
      code: '18-6024 TPG',
      name: 'Amazon',
      hex: '347857',
      rgb: {
        r: 52,
        g: 120,
        b: 87,
      },
      lab: {
        l: 44.87,
        a: -28.69,
        b: 10.18,
      },
      cmyk: null,
    },
    {
      code: '17-6154 TPG',
      name: 'Green Bee',
      hex: '199255',
      rgb: {
        r: 25,
        g: 146,
        b: 85,
      },
      lab: {
        l: 52.72,
        a: -43.89,
        b: 20.96,
      },
      cmyk: null,
    },
    {
      code: '18-6028 TPG',
      name: 'Rolling Hills',
      hex: '328051',
      rgb: {
        r: 50,
        g: 128,
        b: 81,
      },
      lab: {
        l: 47.33,
        a: -33.66,
        b: 16.78,
      },
      cmyk: null,
    },
    {
      code: '18-6031 TPG',
      name: 'First Tee',
      hex: '007C4C',
      rgb: {
        r: 0,
        g: 124,
        b: 76,
      },
      lab: {
        l: 44.68,
        a: -41.22,
        b: 15.36,
      },
      cmyk: null,
    },
    {
      code: '18-6033 TPG',
      name: 'Green Tambourine',
      hex: '007E57',
      rgb: {
        r: 0,
        g: 126,
        b: 87,
      },
      lab: {
        l: 45.26,
        a: -42.37,
        b: 10.17,
      },
      cmyk: null,
    },
    {
      code: '18-6026 TPG',
      name: 'Abundant Green',
      hex: '236E48',
      rgb: {
        r: 35,
        g: 110,
        b: 72,
      },
      lab: {
        l: 40.89,
        a: -32.06,
        b: 13.47,
      },
      cmyk: null,
    },
    {
      code: '19-6350 TPG',
      name: 'Formal Garden',
      hex: '355B3E',
      rgb: {
        r: 53,
        g: 91,
        b: 62,
      },
      lab: {
        l: 34.96,
        a: -20.31,
        b: 12.05,
      },
      cmyk: null,
    },
    {
      code: '19-6027 TPG',
      name: 'Green Jacket',
      hex: '20664E',
      rgb: {
        r: 32,
        g: 102,
        b: 78,
      },
      lab: {
        l: 38.13,
        a: -27.51,
        b: 6.11,
      },
      cmyk: null,
    },
    {
      code: '14-0121 TPG',
      name: 'Nile Green',
      hex: 'A8C798',
      rgb: {
        r: 168,
        g: 199,
        b: 152,
      },
      lab: {
        l: 76.84,
        a: -16.69,
        b: 19.09,
      },
      cmyk: null,
    },
    {
      code: '13-0221 TPG',
      name: 'Pistachio Green',
      hex: 'ABD69E',
      rgb: {
        r: 171,
        g: 214,
        b: 158,
      },
      lab: {
        l: 81.32,
        a: -22.7,
        b: 22.12,
      },
      cmyk: null,
    },
    {
      code: '13-0117 TPG',
      name: 'Green Ash',
      hex: '9FDBAD',
      rgb: {
        r: 159,
        g: 219,
        b: 173,
      },
      lab: {
        l: 82.1,
        a: -26.97,
        b: 14.98,
      },
      cmyk: null,
    },
    {
      code: '14-6340 TPG',
      name: 'Spring Bouquet',
      hex: '6FCF90',
      rgb: {
        r: 111,
        g: 207,
        b: 144,
      },
      lab: {
        l: 75.59,
        a: -40.83,
        b: 20.9,
      },
      cmyk: null,
    },
    {
      code: '15-6340 TPG',
      name: 'Irish Green',
      hex: '4CC37C',
      rgb: {
        r: 76,
        g: 195,
        b: 124,
      },
      lab: {
        l: 70.19,
        a: -47.57,
        b: 23.26,
      },
      cmyk: null,
    },
    {
      code: '16-6240 TPG',
      name: 'Island Green',
      hex: '34B46E',
      rgb: {
        r: 52,
        g: 180,
        b: 110,
      },
      lab: {
        l: 64.6,
        a: -48.5,
        b: 23.1,
      },
      cmyk: null,
    },
    {
      code: '15-5534 TPG',
      name: 'Bright Green',
      hex: '00A466',
      rgb: {
        r: 0,
        g: 164,
        b: 102,
      },
      lab: {
        l: 58.57,
        a: -49.96,
        b: 19.47,
      },
      cmyk: null,
    },
    {
      code: '12-0225 TPG',
      name: 'Patina Green',
      hex: 'B0E7AF',
      rgb: {
        r: 176,
        g: 231,
        b: 175,
      },
      lab: {
        l: 86.35,
        a: -26.45,
        b: 20.31,
      },
      cmyk: null,
    },
    {
      code: '14-0156 TPG',
      name: 'Summer Green',
      hex: '79D381',
      rgb: {
        r: 121,
        g: 211,
        b: 129,
      },
      lab: {
        l: 76.84,
        a: -41.42,
        b: 30.24,
      },
      cmyk: null,
    },
    {
      code: '16-6444 TPG',
      name: 'Poison Green',
      hex: '53B86A',
      rgb: {
        r: 83,
        g: 184,
        b: 106,
      },
      lab: {
        l: 66.83,
        a: -43.46,
        b: 28.34,
      },
      cmyk: null,
    },
    {
      code: '16-6340 TPG',
      name: 'Classic Green',
      hex: '3DB24E',
      rgb: {
        r: 61,
        g: 178,
        b: 78,
      },
      lab: {
        l: 63.9,
        a: -50.8,
        b: 38.72,
      },
      cmyk: null,
    },
    {
      code: '16-6339 TPG',
      name: 'Vibrant Green',
      hex: '5DAB63',
      rgb: {
        r: 93,
        g: 171,
        b: 99,
      },
      lab: {
        l: 63.21,
        a: -37.09,
        b: 27.61,
      },
      cmyk: null,
    },
    {
      code: '16-6138 TPG',
      name: 'Kelly Green',
      hex: '339E66',
      rgb: {
        r: 51,
        g: 158,
        b: 102,
      },
      lab: {
        l: 57.68,
        a: -42.23,
        b: 18.43,
      },
      cmyk: null,
    },
    {
      code: '17-6153 TPG',
      name: 'Fern Green',
      hex: '009353',
      rgb: {
        r: 0,
        g: 147,
        b: 83,
      },
      lab: {
        l: 52.73,
        a: -47.35,
        b: 22.3,
      },
      cmyk: null,
    },
    {
      code: '13-0220 TPG',
      name: 'Paradise Green',
      hex: 'B4E7A0',
      rgb: {
        r: 180,
        g: 231,
        b: 160,
      },
      lab: {
        l: 86.39,
        a: -26.86,
        b: 28.13,
      },
      cmyk: null,
    },
    {
      code: '15-0146 TPG',
      name: 'Green Flash',
      hex: '75CA5D',
      rgb: {
        r: 117,
        g: 202,
        b: 93,
      },
      lab: {
        l: 73.84,
        a: -42.96,
        b: 43.87,
      },
      cmyk: null,
    },
    {
      code: '15-0545 TPG',
      name: 'Jasmine Green',
      hex: '83CB53',
      rgb: {
        r: 131,
        g: 203,
        b: 83,
      },
      lab: {
        l: 74.64,
        a: -39.55,
        b: 49.66,
      },
      cmyk: null,
    },
    {
      code: '16-0237 TPG',
      name: 'Foliage',
      hex: '78A059',
      rgb: {
        r: 120,
        g: 160,
        b: 89,
      },
      lab: {
        l: 61.27,
        a: -22.56,
        b: 30.73,
      },
      cmyk: null,
    },
    {
      code: '17-0145 TPG',
      name: 'Online Lime',
      hex: '548A4D',
      rgb: {
        r: 84,
        g: 138,
        b: 77,
      },
      lab: {
        l: 52.35,
        a: -29.25,
        b: 25.98,
      },
      cmyk: null,
    },
    {
      code: '18-0135 TPG',
      name: 'Treetop',
      hex: '547144',
      rgb: {
        r: 84,
        g: 113,
        b: 68,
      },
      lab: {
        l: 44.24,
        a: -18.58,
        b: 21.21,
      },
      cmyk: null,
    },
    {
      code: '19-0230 TPG',
      name: 'Garden Green',
      hex: '526447',
      rgb: {
        r: 82,
        g: 100,
        b: 71,
      },
      lab: {
        l: 40.26,
        a: -12.31,
        b: 14.03,
      },
      cmyk: null,
    },
    {
      code: '14-0232 TPG',
      name: 'Jade Lime',
      hex: 'A3C77C',
      rgb: {
        r: 163,
        g: 199,
        b: 124,
      },
      lab: {
        l: 76.16,
        a: -21.61,
        b: 32.34,
      },
      cmyk: null,
    },
    {
      code: '14-0244 TPG',
      name: 'Bright Lime Green',
      hex: 'A2C66C',
      rgb: {
        r: 162,
        g: 198,
        b: 108,
      },
      lab: {
        l: 75.53,
        a: -23.43,
        b: 39.88,
      },
      cmyk: null,
    },
    {
      code: '15-0343 TPG',
      name: 'Greenery',
      hex: '92B558',
      rgb: {
        r: 146,
        g: 181,
        b: 88,
      },
      lab: {
        l: 69.27,
        a: -23.37,
        b: 41.75,
      },
      cmyk: null,
    },
    {
      code: '17-0235 TPG',
      name: 'Piquant Green',
      hex: '73945B',
      rgb: {
        r: 115,
        g: 148,
        b: 91,
      },
      lab: {
        l: 57.52,
        a: -19.63,
        b: 24.98,
      },
      cmyk: null,
    },
    {
      code: '18-0119 TPG',
      name: 'Willow Bough',
      hex: '59744D',
      rgb: {
        r: 89,
        g: 116,
        b: 77,
      },
      lab: {
        l: 45.81,
        a: -17.1,
        b: 18.2,
      },
      cmyk: null,
    },
    {
      code: '18-0107 TPG',
      name: 'Kale',
      hex: '61774F',
      rgb: {
        r: 97,
        g: 119,
        b: 79,
      },
      lab: {
        l: 47.19,
        a: -13.92,
        b: 18.41,
      },
      cmyk: null,
    },
    {
      code: '18-0330 TPG',
      name: 'Twist of Lime',
      hex: '5C6C44',
      rgb: {
        r: 92,
        g: 108,
        b: 68,
      },
      lab: {
        l: 43.36,
        a: -11.54,
        b: 19.99,
      },
      cmyk: null,
    },
    {
      code: '17-0232 TPG',
      name: 'Salted Lime',
      hex: '658946',
      rgb: {
        r: 101,
        g: 137,
        b: 70,
      },
      lab: {
        l: 53.04,
        a: -23.21,
        b: 31.23,
      },
      cmyk: null,
    },
    {
      code: '17-0340 TPG',
      name: 'Kelp Forest',
      hex: '758C3E',
      rgb: {
        r: 117,
        g: 140,
        b: 62,
      },
      lab: {
        l: 55.04,
        a: -17.7,
        b: 37.65,
      },
      cmyk: null,
    },
    {
      code: '17-0345 TPG',
      name: 'Minced Herb',
      hex: '6B8745',
      rgb: {
        r: 107,
        g: 135,
        b: 69,
      },
      lab: {
        l: 52.9,
        a: -18.36,
        b: 31.07,
      },
      cmyk: null,
    },
    {
      code: '18-0230 TPG',
      name: 'Banana Palm',
      hex: '577340',
      rgb: {
        r: 87,
        g: 115,
        b: 64,
      },
      lab: {
        l: 45.16,
        a: -18.44,
        b: 24.35,
      },
      cmyk: null,
    },
    {
      code: '18-0226 TPG',
      name: 'Courtyard',
      hex: '4A673E',
      rgb: {
        r: 74,
        g: 103,
        b: 62,
      },
      lab: {
        l: 40.4,
        a: -18.81,
        b: 19.4,
      },
      cmyk: null,
    },
    {
      code: '19-0231 TPG',
      name: 'Forest Elf',
      hex: '48603F',
      rgb: {
        r: 72,
        g: 96,
        b: 63,
      },
      lab: {
        l: 37.92,
        a: -15.57,
        b: 15.83,
      },
      cmyk: null,
    },
    {
      code: '19-0220 TPG',
      name: 'Douglas Fir',
      hex: '445540',
      rgb: {
        r: 68,
        g: 85,
        b: 64,
      },
      lab: {
        l: 34.16,
        a: -10.61,
        b: 9.62,
      },
      cmyk: null,
    },
    {
      code: '13-0331 TPG',
      name: 'Sap Green',
      hex: 'B1CE86',
      rgb: {
        r: 177,
        g: 206,
        b: 134,
      },
      lab: {
        l: 79.19,
        a: -18.59,
        b: 31.68,
      },
      cmyk: null,
    },
    {
      code: '15-0341 TPG',
      name: 'Parrot Green',
      hex: '90B258',
      rgb: {
        r: 144,
        g: 178,
        b: 88,
      },
      lab: {
        l: 68.27,
        a: -22.65,
        b: 40.5,
      },
      cmyk: null,
    },
    {
      code: '16-0235 TPG',
      name: 'Kiwi',
      hex: '7CAC5F',
      rgb: {
        r: 124,
        g: 172,
        b: 95,
      },
      lab: {
        l: 65.26,
        a: -26.95,
        b: 32.95,
      },
      cmyk: null,
    },
    {
      code: '17-0133 TPG',
      name: 'Fluorite Green',
      hex: '689058',
      rgb: {
        r: 104,
        g: 144,
        b: 88,
      },
      lab: {
        l: 55.36,
        a: -22.22,
        b: 24.02,
      },
      cmyk: null,
    },
    {
      code: '17-0230 TPG',
      name: 'Forest Green',
      hex: '6A8E58',
      rgb: {
        r: 106,
        g: 142,
        b: 88,
      },
      lab: {
        l: 55.11,
        a: -20.72,
        b: 23.67,
      },
      cmyk: null,
    },
    {
      code: '18-0130 TPG',
      name: 'Cactus',
      hex: '5D7547',
      rgb: {
        r: 93,
        g: 117,
        b: 71,
      },
      lab: {
        l: 46.09,
        a: -14.75,
        b: 21.5,
      },
      cmyk: null,
    },
    {
      code: '18-0125 TPG',
      name: 'Artichoke Green',
      hex: '567451',
      rgb: {
        r: 86,
        g: 116,
        b: 81,
      },
      lab: {
        l: 45.44,
        a: -16.08,
        b: 14.53,
      },
      cmyk: null,
    },
    {
      code: '14-0123 TPG',
      name: 'Arcadian Green',
      hex: '9DC68F',
      rgb: {
        r: 157,
        g: 198,
        b: 143,
      },
      lab: {
        l: 75.7,
        a: -22.03,
        b: 21.92,
      },
      cmyk: null,
    },
    {
      code: '15-6437 TPG',
      name: 'Grass Green',
      hex: '7DB46C',
      rgb: {
        r: 125,
        g: 180,
        b: 108,
      },
      lab: {
        l: 67.87,
        a: -29.46,
        b: 29.34,
      },
      cmyk: null,
    },
    {
      code: '15-6442 TPG',
      name: 'Bud Green',
      hex: '7CB469',
      rgb: {
        r: 124,
        g: 180,
        b: 105,
      },
      lab: {
        l: 67.82,
        a: -30.32,
        b: 31.08,
      },
      cmyk: null,
    },
    {
      code: '15-6428 TPG',
      name: 'Green Tea',
      hex: '81A76E',
      rgb: {
        r: 129,
        g: 167,
        b: 110,
      },
      lab: {
        l: 64.36,
        a: -21.81,
        b: 24.42,
      },
      cmyk: null,
    },
    {
      code: '16-0123 TPG',
      name: 'Tendril',
      hex: '8BA475',
      rgb: {
        r: 139,
        g: 164,
        b: 117,
      },
      lab: {
        l: 64.33,
        a: -15.3,
        b: 20.65,
      },
      cmyk: null,
    },
    {
      code: '17-0215 TPG',
      name: 'Aspen Green',
      hex: '7F9B7A',
      rgb: {
        r: 127,
        g: 155,
        b: 122,
      },
      lab: {
        l: 60.95,
        a: -14.99,
        b: 13.11,
      },
      cmyk: null,
    },
    {
      code: '16-0228 TPG',
      name: 'Jade Green',
      hex: '779768',
      rgb: {
        r: 119,
        g: 151,
        b: 104,
      },
      lab: {
        l: 58.93,
        a: -18.03,
        b: 20.03,
      },
      cmyk: null,
    },
    {
      code: '13-0324 TPG',
      name: 'Lettuce Green',
      hex: 'BED390',
      rgb: {
        r: 190,
        g: 211,
        b: 144,
      },
      lab: {
        l: 81.54,
        a: -14.68,
        b: 29.51,
      },
      cmyk: null,
    },
    {
      code: '14-0226 TPG',
      name: 'Opaline Green',
      hex: 'A2C583',
      rgb: {
        r: 162,
        g: 197,
        b: 131,
      },
      lab: {
        l: 75.46,
        a: -20.08,
        b: 27.82,
      },
      cmyk: null,
    },
    {
      code: '16-0233 TPG',
      name: 'Meadow Green',
      hex: '72975E',
      rgb: {
        r: 114,
        g: 151,
        b: 94,
      },
      lab: {
        l: 58.29,
        a: -20.56,
        b: 24.53,
      },
      cmyk: null,
    },
    {
      code: '17-6229 TPG',
      name: 'Medium Green',
      hex: '55875F',
      rgb: {
        r: 85,
        g: 135,
        b: 95,
      },
      lab: {
        l: 51.66,
        a: -23.34,
        b: 14.55,
      },
      cmyk: null,
    },
    {
      code: '18-6330 TPG',
      name: 'Juniper',
      hex: '4D7653',
      rgb: {
        r: 77,
        g: 118,
        b: 83,
      },
      lab: {
        l: 45.67,
        a: -21.07,
        b: 13.94,
      },
      cmyk: null,
    },
    {
      code: '18-6320 TPG',
      name: 'Fairway',
      hex: '4E7459',
      rgb: {
        r: 78,
        g: 116,
        b: 89,
      },
      lab: {
        l: 45.11,
        a: -18.49,
        b: 9.78,
      },
      cmyk: null,
    },
    {
      code: '17-6333 TPG',
      name: 'Mint Green',
      hex: '598459',
      rgb: {
        r: 89,
        g: 132,
        b: 89,
      },
      lab: {
        l: 50.9,
        a: -23.71,
        b: 18.16,
      },
      cmyk: null,
    },
    {
      code: '13-0215 TPG',
      name: 'Reed',
      hex: 'C0D3A5',
      rgb: {
        r: 192,
        g: 211,
        b: 165,
      },
      lab: {
        l: 82.16,
        a: -12.05,
        b: 19.93,
      },
      cmyk: null,
    },
    {
      code: '12-0317 TPG',
      name: 'Gleam',
      hex: 'C1D2B1',
      rgb: {
        r: 193,
        g: 210,
        b: 177,
      },
      lab: {
        l: 82.12,
        a: -10.23,
        b: 13.7,
      },
      cmyk: null,
    },
    {
      code: '14-0115 TPG',
      name: 'Foam Green',
      hex: 'B1C5A0',
      rgb: {
        r: 177,
        g: 197,
        b: 160,
      },
      lab: {
        l: 77.22,
        a: -11.75,
        b: 15.88,
      },
      cmyk: null,
    },
    {
      code: '15-6423 TPG',
      name: 'Forest Shade',
      hex: '8FAC7F',
      rgb: {
        r: 143,
        g: 172,
        b: 127,
      },
      lab: {
        l: 66.99,
        a: -16.14,
        b: 18.71,
      },
      cmyk: null,
    },
    {
      code: '16-6116 TPG',
      name: 'Shale Green',
      hex: '739379',
      rgb: {
        r: 115,
        g: 147,
        b: 121,
      },
      lab: {
        l: 57.6,
        a: -15.09,
        b: 8.99,
      },
      cmyk: null,
    },
    {
      code: '17-6319 TPG',
      name: 'Kashmir',
      hex: '6D8D71',
      rgb: {
        r: 109,
        g: 141,
        b: 113,
      },
      lab: {
        l: 55.4,
        a: -15.78,
        b: 10.45,
      },
      cmyk: null,
    },
    {
      code: '17-6219 TPG',
      name: 'Deep Grass Green',
      hex: '5A876E',
      rgb: {
        r: 90,
        g: 135,
        b: 110,
      },
      lab: {
        l: 52.24,
        a: -20.01,
        b: 7.17,
      },
      cmyk: null,
    },
    {
      code: '16-6216 TPG',
      name: 'Basil',
      hex: '829E86',
      rgb: {
        r: 130,
        g: 158,
        b: 134,
      },
      lab: {
        l: 62.36,
        a: -13.61,
        b: 8.85,
      },
      cmyk: null,
    },
    {
      code: '17-0210 TPG',
      name: 'Loden Frost',
      hex: '758E77',
      rgb: {
        r: 117,
        g: 142,
        b: 119,
      },
      lab: {
        l: 56.36,
        a: -12.35,
        b: 8.33,
      },
      cmyk: null,
    },
    {
      code: '18-0108 TPG',
      name: 'Dill',
      hex: '5D7050',
      rgb: {
        r: 93,
        g: 112,
        b: 80,
      },
      lab: {
        l: 45.01,
        a: -12.76,
        b: 15.18,
      },
      cmyk: null,
    },
    {
      code: '18-0117 TPG',
      name: 'Vineyard Green',
      hex: '5E7558',
      rgb: {
        r: 94,
        g: 117,
        b: 88,
      },
      lab: {
        l: 46.62,
        a: -13.03,
        b: 12.37,
      },
      cmyk: null,
    },
    {
      code: '19-6311 TPG',
      name: 'Greener Pastures',
      hex: '3C5542',
      rgb: {
        r: 60,
        g: 85,
        b: 66,
      },
      lab: {
        l: 33.6,
        a: -13.7,
        b: 7.88,
      },
      cmyk: null,
    },
    {
      code: '19-6050 TPG',
      name: 'Eden',
      hex: '355743',
      rgb: {
        r: 53,
        g: 87,
        b: 67,
      },
      lab: {
        l: 33.63,
        a: -16.79,
        b: 6.8,
      },
      cmyk: null,
    },
    {
      code: '19-5513 TPG',
      name: 'Dark Green',
      hex: '425A51',
      rgb: {
        r: 66,
        g: 90,
        b: 81,
      },
      lab: {
        l: 35.75,
        a: -11.2,
        b: 1.51,
      },
      cmyk: null,
    },
    {
      code: '12-0106 TPG',
      name: 'Meadow Mist',
      hex: 'D4DDC4',
      rgb: {
        r: 212,
        g: 221,
        b: 196,
      },
      lab: {
        l: 86.84,
        a: -5.96,
        b: 11,
      },
      cmyk: null,
    },
    {
      code: '12-0109 TPG',
      name: 'Ambrosia',
      hex: 'D7E7CA',
      rgb: {
        r: 215,
        g: 231,
        b: 202,
      },
      lab: {
        l: 89.6,
        a: -9.21,
        b: 11.38,
      },
      cmyk: null,
    },
    {
      code: '13-0111 TPG',
      name: 'Seacrest',
      hex: 'BFCFB1',
      rgb: {
        r: 191,
        g: 207,
        b: 177,
      },
      lab: {
        l: 81.17,
        a: -9.28,
        b: 12.72,
      },
      cmyk: null,
    },
    {
      code: '15-6317 TPG',
      name: 'Quiet Green',
      hex: 'A0C09A',
      rgb: {
        r: 160,
        g: 192,
        b: 154,
      },
      lab: {
        l: 74.26,
        a: -16.22,
        b: 14.38,
      },
      cmyk: null,
    },
    {
      code: '15-6316 TPG',
      name: 'Fair Green',
      hex: '8FAF8A',
      rgb: {
        r: 143,
        g: 175,
        b: 138,
      },
      lab: {
        l: 68.2,
        a: -16.25,
        b: 14.54,
      },
      cmyk: null,
    },
    {
      code: '17-0123 TPG',
      name: 'Stone Green',
      hex: '68926D',
      rgb: {
        r: 104,
        g: 146,
        b: 109,
      },
      lab: {
        l: 56.55,
        a: -20.62,
        b: 13.77,
      },
      cmyk: null,
    },
    {
      code: '17-5923 TPG',
      name: 'Pine Green',
      hex: '4D826C',
      rgb: {
        r: 77,
        g: 130,
        b: 108,
      },
      lab: {
        l: 49.78,
        a: -22.68,
        b: 4.96,
      },
      cmyk: null,
    },
    {
      code: '12-0313 TPG',
      name: 'Seafoam Green',
      hex: 'C9D9B3',
      rgb: {
        r: 201,
        g: 217,
        b: 179,
      },
      lab: {
        l: 84.59,
        a: -9.97,
        b: 16.32,
      },
      cmyk: null,
    },
    {
      code: '12-0312 TPG',
      name: 'Lime Cream',
      hex: 'DCE9BE',
      rgb: {
        r: 220,
        g: 233,
        b: 190,
      },
      lab: {
        l: 90.36,
        a: -9.3,
        b: 18.6,
      },
      cmyk: null,
    },
    {
      code: '13-0319 TPG',
      name: 'Shadow Lime',
      hex: 'D3E09C',
      rgb: {
        r: 211,
        g: 224,
        b: 156,
      },
      lab: {
        l: 86.76,
        a: -11.81,
        b: 30.92,
      },
      cmyk: null,
    },
    {
      code: '12-0322 TPG',
      name: 'Butterfly',
      hex: 'C9DDA2',
      rgb: {
        r: 201,
        g: 221,
        b: 162,
      },
      lab: {
        l: 85.47,
        a: -13.7,
        b: 26.13,
      },
      cmyk: null,
    },
    {
      code: '13-0317 TPG',
      name: 'Lily Green',
      hex: 'C4D19A',
      rgb: {
        r: 196,
        g: 209,
        b: 154,
      },
      lab: {
        l: 81.72,
        a: -10.94,
        b: 25.3,
      },
      cmyk: null,
    },
    {
      code: '14-0223 TPG',
      name: 'Nile',
      hex: 'B2BB88',
      rgb: {
        r: 178,
        g: 187,
        b: 136,
      },
      lab: {
        l: 74.19,
        a: -9.11,
        b: 24.14,
      },
      cmyk: null,
    },
    {
      code: '15-0531 TPG',
      name: 'Sweet Pea',
      hex: 'A5AD70',
      rgb: {
        r: 165,
        g: 173,
        b: 112,
      },
      lab: {
        l: 68.83,
        a: -9.35,
        b: 29.73,
      },
      cmyk: null,
    },
    {
      code: '12-6207 TPG',
      name: 'Frost',
      hex: 'DDE5D9',
      rgb: {
        r: 221,
        g: 229,
        b: 217,
      },
      lab: {
        l: 89.97,
        a: -4.03,
        b: 4.57,
      },
      cmyk: null,
    },
    {
      code: '12-0315 TPG',
      name: 'White Jade',
      hex: 'D4DBB3',
      rgb: {
        r: 212,
        g: 219,
        b: 179,
      },
      lab: {
        l: 86.07,
        a: -7.17,
        b: 18.47,
      },
      cmyk: null,
    },
    {
      code: '14-0217 TPG',
      name: 'Seedling',
      hex: 'BDC9A1',
      rgb: {
        r: 189,
        g: 201,
        b: 161,
      },
      lab: {
        l: 79.04,
        a: -8.9,
        b: 18.07,
      },
      cmyk: null,
    },
    {
      code: '14-0116 TPG',
      name: 'Margarita',
      hex: 'B7C791',
      rgb: {
        r: 183,
        g: 199,
        b: 145,
      },
      lab: {
        l: 77.92,
        a: -12.78,
        b: 25.02,
      },
      cmyk: null,
    },
    {
      code: '15-0628 TPG',
      name: 'Leek Green',
      hex: 'BEB87E',
      rgb: {
        r: 190,
        g: 184,
        b: 126,
      },
      lab: {
        l: 74.28,
        a: -3.03,
        b: 29.92,
      },
      cmyk: null,
    },
    {
      code: '15-0525 TPG',
      name: 'Weeping Willow',
      hex: 'BAB984',
      rgb: {
        r: 186,
        g: 185,
        b: 132,
      },
      lab: {
        l: 74.14,
        a: -4.4,
        b: 26.35,
      },
      cmyk: null,
    },
    {
      code: '16-0430 TPG',
      name: 'Fern',
      hex: '989E68',
      rgb: {
        r: 152,
        g: 158,
        b: 104,
      },
      lab: {
        l: 63.63,
        a: -8.79,
        b: 27.2,
      },
      cmyk: null,
    },
    {
      code: '12-0435 TPG',
      name: 'Daiquiri Green',
      hex: 'CDD77F',
      rgb: {
        r: 205,
        g: 215,
        b: 127,
      },
      lab: {
        l: 83.73,
        a: -12.78,
        b: 41.29,
      },
      cmyk: null,
    },
    {
      code: '13-0442 TPG',
      name: 'Green Glow',
      hex: 'B3CD69',
      rgb: {
        r: 179,
        g: 205,
        b: 105,
      },
      lab: {
        l: 78.71,
        a: -20.31,
        b: 45.04,
      },
      cmyk: null,
    },
    {
      code: '14-0445 TPG',
      name: 'Bright Chartreuse',
      hex: 'B1BC55',
      rgb: {
        r: 177,
        g: 188,
        b: 85,
      },
      lab: {
        l: 73.62,
        a: -13.93,
        b: 48.65,
      },
      cmyk: null,
    },
    {
      code: '16-0435 TPG',
      name: 'Dark Citron',
      hex: '9CA954',
      rgb: {
        r: 156,
        g: 169,
        b: 84,
      },
      lab: {
        l: 66.77,
        a: -13.95,
        b: 40.78,
      },
      cmyk: null,
    },
    {
      code: '15-0332 TPG',
      name: 'Leaf Green',
      hex: 'A6B172',
      rgb: {
        r: 166,
        g: 177,
        b: 114,
      },
      lab: {
        l: 70.06,
        a: -10.89,
        b: 30.01,
      },
      cmyk: null,
    },
    {
      code: '15-0336 TPG',
      name: 'Herbal Garden',
      hex: 'A0B167',
      rgb: {
        r: 160,
        g: 177,
        b: 103,
      },
      lab: {
        l: 69.53,
        a: -14.81,
        b: 35.11,
      },
      cmyk: null,
    },
    {
      code: '16-0439 TPG',
      name: 'Spinach Green',
      hex: '909E56',
      rgb: {
        r: 144,
        g: 158,
        b: 86,
      },
      lab: {
        l: 62.76,
        a: -12.82,
        b: 34.64,
      },
      cmyk: null,
    },
    {
      code: '13-0535 TPG',
      name: 'Sharp Green',
      hex: 'C9E881',
      rgb: {
        r: 201,
        g: 232,
        b: 129,
      },
      lab: {
        l: 87.96,
        a: -22.24,
        b: 45.49,
      },
      cmyk: null,
    },
    {
      code: '13-0443 TPG',
      name: 'Love Bird',
      hex: 'C5DE48',
      rgb: {
        r: 197,
        g: 222,
        b: 72,
      },
      lab: {
        l: 84.41,
        a: -22.42,
        b: 65.55,
      },
      cmyk: null,
    },
    {
      code: '14-0340 TPG',
      name: 'Acid Lime',
      hex: 'BEE040',
      rgb: {
        r: 190,
        g: 224,
        b: 64,
      },
      lab: {
        l: 84.37,
        a: -27.18,
        b: 68.14,
      },
      cmyk: null,
    },
    {
      code: '13-0550 TPG',
      name: 'Lime Punch',
      hex: 'BED542',
      rgb: {
        r: 190,
        g: 213,
        b: 66,
      },
      lab: {
        l: 81.55,
        a: -22.33,
        b: 65.53,
      },
      cmyk: null,
    },
    {
      code: '14-0446 TPG',
      name: 'Tender Shoots',
      hex: 'BBCF50',
      rgb: {
        r: 187,
        g: 207,
        b: 80,
      },
      lab: {
        l: 79.6,
        a: -18.66,
        b: 57.27,
      },
      cmyk: null,
    },
    {
      code: '16-0230 TPG',
      name: 'Macaw Green',
      hex: '9EB749',
      rgb: {
        r: 158,
        g: 183,
        b: 73,
      },
      lab: {
        l: 70.69,
        a: -20.27,
        b: 50.41,
      },
      cmyk: null,
    },
    {
      code: '17-0336 TPG',
      name: 'Peridot',
      hex: '7D944C',
      rgb: {
        r: 125,
        g: 148,
        b: 76,
      },
      lab: {
        l: 58.25,
        a: -17.3,
        b: 34.81,
      },
      cmyk: null,
    },
    {
      code: '13-0643 TPG',
      name: 'Yellow Plum',
      hex: 'D7DA58',
      rgb: {
        r: 215,
        g: 218,
        b: 88,
      },
      lab: {
        l: 84.87,
        a: -12.93,
        b: 60.7,
      },
      cmyk: null,
    },
    {
      code: '15-0549 TPG',
      name: 'Fragile Sprout',
      hex: 'BEC338',
      rgb: {
        r: 190,
        g: 195,
        b: 56,
      },
      lab: {
        l: 76.46,
        a: -14.06,
        b: 64.1,
      },
      cmyk: null,
    },
    {
      code: '14-0443 TPG',
      name: 'Kiwi Colada',
      hex: 'C1D235',
      rgb: {
        r: 193,
        g: 210,
        b: 53,
      },
      lab: {
        l: 80.68,
        a: -20.18,
        b: 68.95,
      },
      cmyk: null,
    },
    {
      code: '14-0442 TPG',
      name: 'White Grape',
      hex: 'ACC352',
      rgb: {
        r: 172,
        g: 195,
        b: 82,
      },
      lab: {
        l: 75.25,
        a: -20.96,
        b: 51.68,
      },
      cmyk: null,
    },
    {
      code: '16-0229 TPG',
      name: 'Titanite',
      hex: '90AD3B',
      rgb: {
        r: 144,
        g: 173,
        b: 59,
      },
      lab: {
        l: 66.75,
        a: -22.2,
        b: 52.09,
      },
      cmyk: null,
    },
    {
      code: '17-0332 TPG',
      name: 'Spindle Tree',
      hex: '6D9140',
      rgb: {
        r: 109,
        g: 145,
        b: 64,
      },
      lab: {
        l: 55.85,
        a: -24.67,
        b: 37.6,
      },
      cmyk: null,
    },
    {
      code: '18-0323 TPG',
      name: 'Campsite',
      hex: '627B4F',
      rgb: {
        r: 98,
        g: 123,
        b: 79,
      },
      lab: {
        l: 48.7,
        a: -15.8,
        b: 20.35,
      },
      cmyk: null,
    },
    {
      code: '12-0741 TPG',
      name: 'Sunny Lime',
      hex: 'DEE784',
      rgb: {
        r: 222,
        g: 231,
        b: 132,
      },
      lab: {
        l: 89.38,
        a: -13.44,
        b: 46.26,
      },
      cmyk: null,
    },
    {
      code: '12-0530 TPG',
      name: 'Charlock',
      hex: 'E4E58F',
      rgb: {
        r: 228,
        g: 229,
        b: 143,
      },
      lab: {
        l: 89.42,
        a: -8.62,
        b: 41.04,
      },
      cmyk: null,
    },
    {
      code: '13-0645 TPG',
      name: 'Limeade',
      hex: 'D2D864',
      rgb: {
        r: 210,
        g: 216,
        b: 100,
      },
      lab: {
        l: 84.06,
        a: -12.74,
        b: 54.75,
      },
      cmyk: null,
    },
    {
      code: '13-0650 TPG',
      name: 'Sulphur Spring',
      hex: 'D2D431',
      rgb: {
        r: 210,
        g: 212,
        b: 49,
      },
      lab: {
        l: 82.88,
        a: -13.65,
        b: 72.71,
      },
      cmyk: null,
    },
    {
      code: '13-0651 TPG',
      name: 'Evening Primrose',
      hex: 'D3E031',
      rgb: {
        r: 211,
        g: 224,
        b: 49,
      },
      lab: {
        l: 86.09,
        a: -19.01,
        b: 75.07,
      },
      cmyk: null,
    },
    {
      code: '13-0540 TPG',
      name: 'Wild Lime',
      hex: 'CAD66A',
      rgb: {
        r: 202,
        g: 214,
        b: 106,
      },
      lab: {
        l: 82.81,
        a: -14.41,
        b: 50.05,
      },
      cmyk: null,
    },
    {
      code: '14-0452 TPG',
      name: 'Lime Green',
      hex: 'A0C23D',
      rgb: {
        r: 160,
        g: 194,
        b: 61,
      },
      lab: {
        l: 73.9,
        a: -26.05,
        b: 58.56,
      },
      cmyk: null,
    },
    {
      code: '11-0710 TPG',
      name: 'Tender Yellow',
      hex: 'EDEBB2',
      rgb: {
        r: 237,
        g: 235,
        b: 178,
      },
      lab: {
        l: 92.19,
        a: -5.41,
        b: 27.82,
      },
      cmyk: null,
    },
    {
      code: '11-0618 TPG',
      name: 'Wax Yellow',
      hex: 'EEEDB0',
      rgb: {
        r: 238,
        g: 237,
        b: 176,
      },
      lab: {
        l: 92.63,
        a: -5.79,
        b: 29.13,
      },
      cmyk: null,
    },
    {
      code: '12-0426 TPG',
      name: 'Mellow Green',
      hex: 'D2D591',
      rgb: {
        r: 210,
        g: 213,
        b: 145,
      },
      lab: {
        l: 83.89,
        a: -8.15,
        b: 32.96,
      },
      cmyk: null,
    },
    {
      code: '12-0525 TPG',
      name: 'Luminary Green',
      hex: 'E2E9A6',
      rgb: {
        r: 226,
        g: 233,
        b: 166,
      },
      lab: {
        l: 90.58,
        a: -9.52,
        b: 31.46,
      },
      cmyk: null,
    },
    {
      code: '12-0520 TPG',
      name: 'Pale Lime Yellow',
      hex: 'E7E9A5',
      rgb: {
        r: 231,
        g: 233,
        b: 165,
      },
      lab: {
        l: 90.95,
        a: -7.71,
        b: 32.16,
      },
      cmyk: null,
    },
    {
      code: '13-0530 TPG',
      name: 'Lime Sherbet',
      hex: 'CED48C',
      rgb: {
        r: 206,
        g: 212,
        b: 140,
      },
      lab: {
        l: 83.16,
        a: -9.69,
        b: 34.53,
      },
      cmyk: null,
    },
    {
      code: '13-0532 TPG',
      name: 'Celery Green',
      hex: 'C5CA7A',
      rgb: {
        r: 197,
        g: 202,
        b: 122,
      },
      lab: {
        l: 79.67,
        a: -9.94,
        b: 38.85,
      },
      cmyk: null,
    },
    {
      code: '11-4803 TPG',
      name: 'Ice',
      hex: 'E3E8DE',
      rgb: {
        r: 227,
        g: 232,
        b: 222,
      },
      lab: {
        l: 91.25,
        a: -2.96,
        b: 4.12,
      },
      cmyk: null,
    },
    {
      code: '12-0108 TPG',
      name: 'Canary Green',
      hex: 'D8E0CC',
      rgb: {
        r: 216,
        g: 224,
        b: 204,
      },
      lab: {
        l: 88.01,
        a: -4.92,
        b: 8.53,
      },
      cmyk: null,
    },
    {
      code: '11-0410 TPG',
      name: 'Sylvan Green',
      hex: 'E6E7CA',
      rgb: {
        r: 230,
        g: 231,
        b: 202,
      },
      lab: {
        l: 91,
        a: -3.65,
        b: 13.55,
      },
      cmyk: null,
    },
    {
      code: '12-0607 TPG',
      name: 'Green Essence',
      hex: 'E6E6C7',
      rgb: {
        r: 230,
        g: 230,
        b: 199,
      },
      lab: {
        l: 90.75,
        a: -3.71,
        b: 15.08,
      },
      cmyk: null,
    },
    {
      code: '12-0521 TPG',
      name: 'Young Wheat',
      hex: 'E0E0A7',
      rgb: {
        r: 224,
        g: 224,
        b: 167,
      },
      lab: {
        l: 88.02,
        a: -6,
        b: 27.5,
      },
      cmyk: null,
    },
    {
      code: '12-0524 TPG',
      name: 'Citron',
      hex: 'DEDE9D',
      rgb: {
        r: 222,
        g: 222,
        b: 157,
      },
      lab: {
        l: 87.3,
        a: -6.68,
        b: 31.54,
      },
      cmyk: null,
    },
    {
      code: '11-0623 TPG',
      name: 'Yellow Pear',
      hex: 'EFEB9B',
      rgb: {
        r: 239,
        g: 235,
        b: 155,
      },
      lab: {
        l: 92.13,
        a: -6.38,
        b: 38.64,
      },
      cmyk: null,
    },
    {
      code: '11-0205 TPG',
      name: 'Glass Green',
      hex: 'EEEDD1',
      rgb: {
        r: 238,
        g: 237,
        b: 209,
      },
      lab: {
        l: 93.17,
        a: -2.77,
        b: 13.2,
      },
      cmyk: null,
    },
    {
      code: '13-0614 TPG',
      name: 'Garden Glade',
      hex: 'DFD8A8',
      rgb: {
        r: 223,
        g: 216,
        b: 168,
      },
      lab: {
        l: 86.13,
        a: -3.18,
        b: 24.84,
      },
      cmyk: null,
    },
    {
      code: '12-0418 TPG',
      name: 'Hay',
      hex: 'D6CEA5',
      rgb: {
        r: 214,
        g: 206,
        b: 165,
      },
      lab: {
        l: 82.57,
        a: -1.7,
        b: 21.38,
      },
      cmyk: null,
    },
    {
      code: '13-0720 TPG',
      name: 'Custard',
      hex: 'E8D88D',
      rgb: {
        r: 232,
        g: 216,
        b: 141,
      },
      lab: {
        l: 86.51,
        a: -1.44,
        b: 38.86,
      },
      cmyk: null,
    },
    {
      code: '13-0333 TPG',
      name: 'Lima Bean',
      hex: 'DDD38E',
      rgb: {
        r: 221,
        g: 211,
        b: 142,
      },
      lab: {
        l: 84.18,
        a: -3.44,
        b: 35.34,
      },
      cmyk: null,
    },
    {
      code: '14-0827 TPG',
      name: 'Dusky Citron',
      hex: 'E4CD82',
      rgb: {
        r: 228,
        g: 205,
        b: 130,
      },
      lab: {
        l: 83.23,
        a: 0.88,
        b: 40.67,
      },
      cmyk: null,
    },
    {
      code: '13-0739 TPG',
      name: 'Cream Gold',
      hex: 'E0C568',
      rgb: {
        r: 224,
        g: 197,
        b: 104,
      },
      lab: {
        l: 80.51,
        a: 1.73,
        b: 49.64,
      },
      cmyk: null,
    },
    {
      code: '11-0609 TPG',
      name: 'Ethereal Green',
      hex: 'EDECCC',
      rgb: {
        r: 237,
        g: 236,
        b: 204,
      },
      lab: {
        l: 92.78,
        a: -3.38,
        b: 15.46,
      },
      cmyk: null,
    },
    {
      code: '11-0617 TPG',
      name: 'Transparent Yellow',
      hex: 'F6EDC3',
      rgb: {
        r: 246,
        g: 237,
        b: 195,
      },
      lab: {
        l: 93.72,
        a: -0.96,
        b: 21.17,
      },
      cmyk: null,
    },
    {
      code: '13-0624 TPG',
      name: 'Golden Mist',
      hex: 'D6CF92',
      rgb: {
        r: 214,
        g: 207,
        b: 146,
      },
      lab: {
        l: 82.76,
        a: -4.05,
        b: 31.26,
      },
      cmyk: null,
    },
    {
      code: '12-0619 TPG',
      name: 'Dusty Yellow',
      hex: 'D5D09E',
      rgb: {
        r: 213,
        g: 208,
        b: 158,
      },
      lab: {
        l: 83.01,
        a: -3.14,
        b: 25.19,
      },
      cmyk: null,
    },
    {
      code: '14-0627 TPG',
      name: 'Shadow Green',
      hex: 'CFC386',
      rgb: {
        r: 207,
        g: 195,
        b: 134,
      },
      lab: {
        l: 78.87,
        a: -2.27,
        b: 32.59,
      },
      cmyk: null,
    },
    {
      code: '14-0826 TPG',
      name: 'Pampas',
      hex: 'D1BD81',
      rgb: {
        r: 209,
        g: 189,
        b: 129,
      },
      lab: {
        l: 77.43,
        a: 1.34,
        b: 33.31,
      },
      cmyk: null,
    },
    {
      code: '16-0737 TPG',
      name: 'Burnished Gold',
      hex: 'A99B59',
      rgb: {
        r: 169,
        g: 155,
        b: 89,
      },
      lab: {
        l: 64.2,
        a: -0.9,
        b: 35.55,
      },
      cmyk: null,
    },
    {
      code: '13-0633 TPG',
      name: 'Chardonnay',
      hex: 'EBE39C',
      rgb: {
        r: 235,
        g: 227,
        b: 156,
      },
      lab: {
        l: 89.66,
        a: -4.4,
        b: 35.69,
      },
      cmyk: null,
    },
    {
      code: '14-0636 TPG',
      name: 'Muted Lime',
      hex: 'D5CD7E',
      rgb: {
        r: 213,
        g: 205,
        b: 126,
      },
      lab: {
        l: 81.74,
        a: -4.74,
        b: 39.85,
      },
      cmyk: null,
    },
    {
      code: '13-0632 TPG',
      name: 'Endive',
      hex: 'D7D182',
      rgb: {
        r: 215,
        g: 209,
        b: 130,
      },
      lab: {
        l: 83.09,
        a: -5.52,
        b: 39.64,
      },
      cmyk: null,
    },
    {
      code: '15-0636 TPG',
      name: 'Golden Green',
      hex: 'BBB167',
      rgb: {
        r: 187,
        g: 177,
        b: 103,
      },
      lab: {
        l: 71.76,
        a: -2.95,
        b: 38.41,
      },
      cmyk: null,
    },
    {
      code: '16-0632 TPG',
      name: 'Willow',
      hex: '9D915A',
      rgb: {
        r: 157,
        g: 145,
        b: 90,
      },
      lab: {
        l: 60.22,
        a: -0.06,
        b: 30.45,
      },
      cmyk: null,
    },
    {
      code: '17-0636 TPG',
      name: 'Green Moss',
      hex: '8A7F53',
      rgb: {
        r: 138,
        g: 127,
        b: 83,
      },
      lab: {
        l: 53.57,
        a: -0.51,
        b: 26.03,
      },
      cmyk: null,
    },
    {
      code: '18-0627 TPG',
      name: 'Fir Green',
      hex: '6C5F3E',
      rgb: {
        r: 108,
        g: 95,
        b: 62,
      },
      lab: {
        l: 41.13,
        a: 1.04,
        b: 21.3,
      },
      cmyk: null,
    },
    {
      code: '12-0626 TPG',
      name: 'Lemon Grass',
      hex: 'DAD391',
      rgb: {
        r: 218,
        g: 211,
        b: 145,
      },
      lab: {
        l: 84.08,
        a: -4.61,
        b: 33.4,
      },
      cmyk: null,
    },
    {
      code: '12-0633 TPG',
      name: 'Canary Yellow',
      hex: 'DEDC80',
      rgb: {
        r: 222,
        g: 220,
        b: 128,
      },
      lab: {
        l: 86.39,
        a: -8.01,
        b: 44.64,
      },
      cmyk: null,
    },
    {
      code: '14-0647 TPG',
      name: 'Celery',
      hex: 'D0C456',
      rgb: {
        r: 208,
        g: 196,
        b: 86,
      },
      lab: {
        l: 78.75,
        a: -5.16,
        b: 54.83,
      },
      cmyk: null,
    },
    {
      code: '15-0646 TPG',
      name: 'Warm Olive',
      hex: 'C8B643',
      rgb: {
        r: 200,
        g: 182,
        b: 67,
      },
      lab: {
        l: 74.19,
        a: -2.93,
        b: 58.33,
      },
      cmyk: null,
    },
    {
      code: '15-0643 TPG',
      name: 'Cress Green',
      hex: 'BCAD48',
      rgb: {
        r: 188,
        g: 173,
        b: 72,
      },
      lab: {
        l: 70.69,
        a: -3.11,
        b: 52.19,
      },
      cmyk: null,
    },
    {
      code: '16-0840 TPG',
      name: 'Antique Moss',
      hex: 'BEA437',
      rgb: {
        r: 190,
        g: 164,
        b: 55,
      },
      lab: {
        l: 68.48,
        a: 1.32,
        b: 57.5,
      },
      cmyk: null,
    },
    {
      code: '16-0639 TPG',
      name: 'Golden Olive',
      hex: 'AD994C',
      rgb: {
        r: 173,
        g: 153,
        b: 76,
      },
      lab: {
        l: 63.93,
        a: 0.28,
        b: 42.65,
      },
      cmyk: null,
    },
    {
      code: '15-0533 TPG',
      name: 'Linden Green',
      hex: 'C5BF73',
      rgb: {
        r: 197,
        g: 191,
        b: 115,
      },
      lab: {
        l: 76.63,
        a: -5.03,
        b: 38.55,
      },
      cmyk: null,
    },
    {
      code: '15-0543 TPG',
      name: 'Apple Green',
      hex: 'BDBD49',
      rgb: {
        r: 189,
        g: 189,
        b: 73,
      },
      lab: {
        l: 74.98,
        a: -10.09,
        b: 55.85,
      },
      cmyk: null,
    },
    {
      code: '15-0544 TPG',
      name: 'Grenoble Green',
      hex: 'A4A628',
      rgb: {
        r: 164,
        g: 166,
        b: 40,
      },
      lab: {
        l: 66.42,
        a: -12.59,
        b: 60.07,
      },
      cmyk: null,
    },
    {
      code: '16-0540 TPG',
      name: 'Oasis',
      hex: 'A5A451',
      rgb: {
        r: 165,
        g: 164,
        b: 81,
      },
      lab: {
        l: 66.15,
        a: -7.87,
        b: 42.09,
      },
      cmyk: null,
    },
    {
      code: '16-0543 TPG',
      name: 'Golden Lime',
      hex: '9C9A40',
      rgb: {
        r: 156,
        g: 154,
        b: 64,
      },
      lab: {
        l: 62.31,
        a: -7.7,
        b: 45.8,
      },
      cmyk: null,
    },
    {
      code: '16-0545 TPG',
      name: 'Split Pea',
      hex: 'A29F4B',
      rgb: {
        r: 162,
        g: 159,
        b: 75,
      },
      lab: {
        l: 64.44,
        a: -7.14,
        b: 42.86,
      },
      cmyk: null,
    },
    {
      code: '16-0550 TPG',
      name: 'Lentil Sprout',
      hex: 'AFA84E',
      rgb: {
        r: 175,
        g: 168,
        b: 78,
      },
      lab: {
        l: 68.07,
        a: -6.11,
        b: 46.34,
      },
      cmyk: null,
    },
    {
      code: '15-0548 TPG',
      name: 'Citronelle',
      hex: 'B6AD38',
      rgb: {
        r: 182,
        g: 173,
        b: 56,
      },
      lab: {
        l: 69.9,
        a: -6.27,
        b: 58.05,
      },
      cmyk: null,
    },
    {
      code: '15-0542 TPG',
      name: 'Pear Liqueur',
      hex: 'A4A02A',
      rgb: {
        r: 164,
        g: 160,
        b: 42,
      },
      lab: {
        l: 64.63,
        a: -8.88,
        b: 57.64,
      },
      cmyk: null,
    },
    {
      code: '16-0541 TPG',
      name: 'Green Envy',
      hex: 'A4963A',
      rgb: {
        r: 164,
        g: 150,
        b: 58,
      },
      lab: {
        l: 62.1,
        a: -2.51,
        b: 48.73,
      },
      cmyk: null,
    },
    {
      code: '16-0640 TPG',
      name: 'Avocado Oil',
      hex: 'A09040',
      rgb: {
        r: 160,
        g: 144,
        b: 64,
      },
      lab: {
        l: 60.03,
        a: -0.92,
        b: 43.83,
      },
      cmyk: null,
    },
    {
      code: '16-0544 TPG',
      name: 'Camping Gear',
      hex: '9B933C',
      rgb: {
        r: 155,
        g: 147,
        b: 60,
      },
      lab: {
        l: 60.38,
        a: -5.62,
        b: 45.87,
      },
      cmyk: null,
    },
    {
      code: '16-0634 TPG',
      name: 'Evergreen Sprig',
      hex: '8F894C',
      rgb: {
        r: 143,
        g: 137,
        b: 76,
      },
      lab: {
        l: 56.54,
        a: -4.76,
        b: 33.52,
      },
      cmyk: null,
    },
    {
      code: '18-0537 TPG',
      name: 'Golden Cypress',
      hex: '82853A',
      rgb: {
        r: 130,
        g: 133,
        b: 58,
      },
      lab: {
        l: 53.99,
        a: -10.02,
        b: 39.16,
      },
      cmyk: null,
    },
    {
      code: '15-0538 TPG',
      name: 'Green Oasis',
      hex: 'B5BB5C',
      rgb: {
        r: 181,
        g: 187,
        b: 92,
      },
      lab: {
        l: 73.79,
        a: -10.99,
        b: 45.58,
      },
      cmyk: null,
    },
    {
      code: '16-0436 TPG',
      name: 'Pickled Pepper',
      hex: 'A0A653',
      rgb: {
        r: 160,
        g: 166,
        b: 83,
      },
      lab: {
        l: 66.2,
        a: -9.65,
        b: 40.49,
      },
      cmyk: null,
    },
    {
      code: '16-0532 TPG',
      name: 'Moss',
      hex: '9B9B5B',
      rgb: {
        r: 155,
        g: 155,
        b: 91,
      },
      lab: {
        l: 62.83,
        a: -6.78,
        b: 32.66,
      },
      cmyk: null,
    },
    {
      code: '18-0540 TPG',
      name: 'Lima Bean Green',
      hex: '8A913C',
      rgb: {
        r: 138,
        g: 145,
        b: 60,
      },
      lab: {
        l: 58.3,
        a: -11.55,
        b: 42.96,
      },
      cmyk: null,
    },
    {
      code: '18-0530 TPG',
      name: 'Going Green',
      hex: '7E8545',
      rgb: {
        r: 126,
        g: 133,
        b: 69,
      },
      lab: {
        l: 53.82,
        a: -9.26,
        b: 33.12,
      },
      cmyk: null,
    },
    {
      code: '17-0530 TPG',
      name: 'Guacamole',
      hex: '7D7F44',
      rgb: {
        r: 125,
        g: 127,
        b: 68,
      },
      lab: {
        l: 51.9,
        a: -7.52,
        b: 31.59,
      },
      cmyk: null,
    },
    {
      code: '18-0538 TPG',
      name: 'Woodbine',
      hex: '82853E',
      rgb: {
        r: 130,
        g: 133,
        b: 62,
      },
      lab: {
        l: 54.24,
        a: -8.62,
        b: 37.14,
      },
      cmyk: null,
    },
    {
      code: '13-0522 TPG',
      name: 'Pale Green',
      hex: 'D0CF96',
      rgb: {
        r: 208,
        g: 207,
        b: 150,
      },
      lab: {
        l: 82.26,
        a: -5.18,
        b: 28.06,
      },
      cmyk: null,
    },
    {
      code: '14-0434 TPG',
      name: 'Green Banana',
      hex: 'B6BB71',
      rgb: {
        r: 182,
        g: 187,
        b: 113,
      },
      lab: {
        l: 74.01,
        a: -9.15,
        b: 35.7,
      },
      cmyk: null,
    },
    {
      code: '15-0535 TPG',
      name: 'Palm',
      hex: 'B1B160',
      rgb: {
        r: 177,
        g: 177,
        b: 96,
      },
      lab: {
        l: 70.88,
        a: -7.92,
        b: 40.27,
      },
      cmyk: null,
    },
    {
      code: '16-0531 TPG',
      name: 'Perfect Pear',
      hex: '949049',
      rgb: {
        r: 148,
        g: 144,
        b: 73,
      },
      lab: {
        l: 58.9,
        a: -5.31,
        b: 37.47,
      },
      cmyk: null,
    },
    {
      code: '17-0529 TPG',
      name: 'Cardamom Seed',
      hex: '7A7A3F',
      rgb: {
        r: 122,
        g: 122,
        b: 63,
      },
      lab: {
        l: 50.13,
        a: -6.52,
        b: 31.41,
      },
      cmyk: null,
    },
    {
      code: '18-0428 TPG',
      name: 'Peat Moss',
      hex: '707244',
      rgb: {
        r: 112,
        g: 114,
        b: 68,
      },
      lab: {
        l: 46.92,
        a: -6.11,
        b: 25.21,
      },
      cmyk: null,
    },
    {
      code: '18-0529 TPG',
      name: 'Sphagnum',
      hex: '6C6F4E',
      rgb: {
        r: 108,
        g: 111,
        b: 78,
      },
      lab: {
        l: 45.77,
        a: -4.36,
        b: 17.47,
      },
      cmyk: null,
    },
    {
      code: '14-0425 TPG',
      name: 'Beechnut',
      hex: 'BDBE88',
      rgb: {
        r: 189,
        g: 190,
        b: 136,
      },
      lab: {
        l: 75.85,
        a: -6.29,
        b: 26.92,
      },
      cmyk: null,
    },
    {
      code: '15-0326 TPG',
      name: 'Tarragon',
      hex: 'A3B07D',
      rgb: {
        r: 163,
        g: 176,
        b: 125,
      },
      lab: {
        l: 69.82,
        a: -10.68,
        b: 24.13,
      },
      cmyk: null,
    },
    {
      code: '17-0324 TPG',
      name: 'Epsom',
      hex: '829368',
      rgb: {
        r: 130,
        g: 147,
        b: 104,
      },
      lab: {
        l: 58.49,
        a: -11.52,
        b: 20.02,
      },
      cmyk: null,
    },
    {
      code: '17-0330 TPG',
      name: 'Turtle Green',
      hex: '808A56',
      rgb: {
        r: 128,
        g: 138,
        b: 86,
      },
      lab: {
        l: 55.47,
        a: -9.94,
        b: 26.36,
      },
      cmyk: null,
    },
    {
      code: '18-0324 TPG',
      name: 'Calliste Green',
      hex: '7B8059',
      rgb: {
        r: 123,
        g: 128,
        b: 89,
      },
      lab: {
        l: 52.35,
        a: -6.88,
        b: 20.39,
      },
      cmyk: null,
    },
    {
      code: '18-0332 TPG',
      name: 'Grasshopper',
      hex: '7C8851',
      rgb: {
        r: 124,
        g: 136,
        b: 81,
      },
      lab: {
        l: 54.68,
        a: -12.19,
        b: 27.88,
      },
      cmyk: null,
    },
    {
      code: '18-0435 TPG',
      name: 'Calla Green',
      hex: '707647',
      rgb: {
        r: 112,
        g: 118,
        b: 71,
      },
      lab: {
        l: 48.14,
        a: -8.32,
        b: 25.22,
      },
      cmyk: null,
    },
    {
      code: '13-0608 TPG',
      name: 'Aloe Wash',
      hex: 'CED1B2',
      rgb: {
        r: 206,
        g: 209,
        b: 178,
      },
      lab: {
        l: 83.13,
        a: -4.98,
        b: 15.01,
      },
      cmyk: null,
    },
    {
      code: '14-0216 TPG',
      name: 'Lint',
      hex: 'B7BE9A',
      rgb: {
        r: 183,
        g: 190,
        b: 154,
      },
      lab: {
        l: 75.6,
        a: -6.42,
        b: 16.58,
      },
      cmyk: null,
    },
    {
      code: '16-0421 TPG',
      name: 'Sage',
      hex: '8F9370',
      rgb: {
        r: 143,
        g: 147,
        b: 112,
      },
      lab: {
        l: 59.96,
        a: -6.21,
        b: 18.57,
      },
      cmyk: null,
    },
    {
      code: '17-0525 TPG',
      name: 'Mosstone',
      hex: '8B8D68',
      rgb: {
        r: 139,
        g: 141,
        b: 104,
      },
      lab: {
        l: 57.67,
        a: -4.47,
        b: 18.69,
      },
      cmyk: null,
    },
    {
      code: '18-0525 TPG',
      name: 'Iguana',
      hex: '80865E',
      rgb: {
        r: 128,
        g: 134,
        b: 94,
      },
      lab: {
        l: 54.6,
        a: -7.62,
        b: 21.13,
      },
      cmyk: null,
    },
    {
      code: '18-0228 TPG',
      name: 'Pesto',
      hex: '5F6946',
      rgb: {
        r: 95,
        g: 105,
        b: 70,
      },
      lab: {
        l: 42.7,
        a: -9.31,
        b: 18.59,
      },
      cmyk: null,
    },
    {
      code: '18-0328 TPG',
      name: 'Cedar Green',
      hex: '677249',
      rgb: {
        r: 103,
        g: 114,
        b: 73,
      },
      lab: {
        l: 46.36,
        a: -10.35,
        b: 21.33,
      },
      cmyk: null,
    },
    {
      code: '15-0523 TPG',
      name: 'Winter Pear',
      hex: 'AEB385',
      rgb: {
        r: 174,
        g: 179,
        b: 133,
      },
      lab: {
        l: 71.68,
        a: -7.42,
        b: 22.78,
      },
      cmyk: null,
    },
    {
      code: '16-0526 TPG',
      name: 'Cedar',
      hex: '979569',
      rgb: {
        r: 151,
        g: 149,
        b: 105,
      },
      lab: {
        l: 60.91,
        a: -3.02,
        b: 22.68,
      },
      cmyk: null,
    },
    {
      code: '17-0535 TPG',
      name: 'Green Olive',
      hex: '8E8B5C',
      rgb: {
        r: 142,
        g: 139,
        b: 92,
      },
      lab: {
        l: 57.31,
        a: -4.42,
        b: 26.06,
      },
      cmyk: null,
    },
    {
      code: '18-0430 TPG',
      name: 'Avocado',
      hex: '6B6947',
      rgb: {
        r: 107,
        g: 105,
        b: 71,
      },
      lab: {
        l: 44.01,
        a: -3.83,
        b: 19.76,
      },
      cmyk: null,
    },
    {
      code: '18-0426 TPG',
      name: 'Capulet Olive',
      hex: '6D7057',
      rgb: {
        r: 109,
        g: 112,
        b: 87,
      },
      lab: {
        l: 46.35,
        a: -4.55,
        b: 13.55,
      },
      cmyk: null,
    },
    {
      code: '18-0220 TPG',
      name: 'Mayfly',
      hex: '6B6D50',
      rgb: {
        r: 107,
        g: 109,
        b: 80,
      },
      lab: {
        l: 45.3,
        a: -4.51,
        b: 15.8,
      },
      cmyk: null,
    },
    {
      code: '18-0527 TPG',
      name: 'Olive Branch',
      hex: '73714E',
      rgb: {
        r: 115,
        g: 113,
        b: 78,
      },
      lab: {
        l: 47.13,
        a: -1.96,
        b: 19.17,
      },
      cmyk: null,
    },
    {
      code: '17-0115 TPG',
      name: 'Oil Green',
      hex: '808A73',
      rgb: {
        r: 128,
        g: 138,
        b: 115,
      },
      lab: {
        l: 56.13,
        a: -7.19,
        b: 10.92,
      },
      cmyk: null,
    },
    {
      code: '18-0316 TPG',
      name: 'Olivine',
      hex: '69725F',
      rgb: {
        r: 105,
        g: 114,
        b: 95,
      },
      lab: {
        l: 46.84,
        a: -6.34,
        b: 9.26,
      },
      cmyk: null,
    },
    {
      code: '18-0317 TPG',
      name: 'Bronze Green',
      hex: '58624E',
      rgb: {
        r: 88,
        g: 98,
        b: 78,
      },
      lab: {
        l: 40.18,
        a: -5.42,
        b: 9.45,
      },
      cmyk: null,
    },
    {
      code: '19-0323 TPG',
      name: 'Chive',
      hex: '565D47',
      rgb: {
        r: 86,
        g: 93,
        b: 71,
      },
      lab: {
        l: 38.25,
        a: -5.43,
        b: 11.4,
      },
      cmyk: null,
    },
    {
      code: '18-0322 TPG',
      name: 'Cypress',
      hex: '5B624C',
      rgb: {
        r: 91,
        g: 98,
        b: 76,
      },
      lab: {
        l: 40.33,
        a: -5.56,
        b: 11.65,
      },
      cmyk: null,
    },
    {
      code: '18-0523 TPG',
      name: 'Winter Moss',
      hex: '60614C',
      rgb: {
        r: 96,
        g: 97,
        b: 76,
      },
      lab: {
        l: 40.47,
        a: -3.66,
        b: 11.75,
      },
      cmyk: null,
    },
    {
      code: '19-0419 TPG',
      name: 'Rifle Green',
      hex: '515748',
      rgb: {
        r: 81,
        g: 87,
        b: 72,
      },
      lab: {
        l: 36.19,
        a: -5.35,
        b: 7.82,
      },
      cmyk: null,
    },
    {
      code: '18-0422 TPG',
      name: 'Loden Green',
      hex: '70775D',
      rgb: {
        r: 112,
        g: 119,
        b: 93,
      },
      lab: {
        l: 48.97,
        a: -6.58,
        b: 13.56,
      },
      cmyk: null,
    },
    {
      code: '18-0420 TPG',
      name: 'Four Leaf Clover',
      hex: '626959',
      rgb: {
        r: 98,
        g: 105,
        b: 89,
      },
      lab: {
        l: 43.49,
        a: -5.78,
        b: 8.17,
      },
      cmyk: null,
    },
    {
      code: '18-0521 TPG',
      name: 'Burnt Olive',
      hex: '706E5C',
      rgb: {
        r: 112,
        g: 110,
        b: 92,
      },
      lab: {
        l: 46.21,
        a: -1.19,
        b: 10.51,
      },
      cmyk: null,
    },
    {
      code: '19-0512 TPG',
      name: 'Ivy Green',
      hex: '605F51',
      rgb: {
        r: 96,
        g: 95,
        b: 81,
      },
      lab: {
        l: 40.01,
        a: -0.7,
        b: 7.68,
      },
      cmyk: null,
    },
    {
      code: '19-0510 TPG',
      name: 'Kalamata',
      hex: '67645A',
      rgb: {
        r: 103,
        g: 100,
        b: 90,
      },
      lab: {
        l: 42.58,
        a: 0.16,
        b: 6.47,
      },
      cmyk: null,
    },
    {
      code: '19-0515 TPG',
      name: 'Olive Night',
      hex: '5F5C51',
      rgb: {
        r: 95,
        g: 92,
        b: 81,
      },
      lab: {
        l: 39.09,
        a: 0.01,
        b: 6.97,
      },
      cmyk: null,
    },
    {
      code: '19-0413 TPG',
      name: 'Deep Depths',
      hex: '53554C',
      rgb: {
        r: 83,
        g: 85,
        b: 76,
      },
      lab: {
        l: 35.78,
        a: -1.54,
        b: 4.95,
      },
      cmyk: null,
    },
    {
      code: '18-0617 TPG',
      name: 'Covert Green',
      hex: '7F7865',
      rgb: {
        r: 127,
        g: 120,
        b: 101,
      },
      lab: {
        l: 50.72,
        a: 0.45,
        b: 11.56,
      },
      cmyk: null,
    },
    {
      code: '17-0517 TPG',
      name: 'Dusky Green',
      hex: '7D7465',
      rgb: {
        r: 125,
        g: 116,
        b: 101,
      },
      lab: {
        l: 49.39,
        a: 2.36,
        b: 9.65,
      },
      cmyk: null,
    },
    {
      code: '19-0622 TPG',
      name: 'Military Olive',
      hex: '69614C',
      rgb: {
        r: 105,
        g: 97,
        b: 76,
      },
      lab: {
        l: 41.42,
        a: 0.39,
        b: 13.5,
      },
      cmyk: null,
    },
    {
      code: '19-0516 TPG',
      name: 'Dark Olive',
      hex: '605A49',
      rgb: {
        r: 96,
        g: 90,
        b: 73,
      },
      lab: {
        l: 38.56,
        a: -0.23,
        b: 11.14,
      },
      cmyk: null,
    },
    {
      code: '19-0403 TPG',
      name: 'Sea Turtle',
      hex: '655F55',
      rgb: {
        r: 101,
        g: 95,
        b: 85,
      },
      lab: {
        l: 40.66,
        a: 1.51,
        b: 6.62,
      },
      cmyk: null,
    },
    {
      code: '19-0511 TPG',
      name: 'Grape Leaf',
      hex: '5A5B4F',
      rgb: {
        r: 90,
        g: 91,
        b: 79,
      },
      lab: {
        l: 38.31,
        a: -2.49,
        b: 6.93,
      },
      cmyk: null,
    },
    {
      code: '19-0414 TPG',
      name: 'Forest Night',
      hex: '4C4F47',
      rgb: {
        r: 76,
        g: 79,
        b: 71,
      },
      lab: {
        l: 32.99,
        a: -2.35,
        b: 4.02,
      },
      cmyk: null,
    },
    {
      code: '16-0518 TPG',
      name: 'Gray Green',
      hex: 'A29D7D',
      rgb: {
        r: 162,
        g: 157,
        b: 125,
      },
      lab: {
        l: 64.58,
        a: -2.04,
        b: 17.8,
      },
      cmyk: null,
    },
    {
      code: '17-0625 TPG',
      name: 'Boa',
      hex: '948B6A',
      rgb: {
        r: 148,
        g: 139,
        b: 106,
      },
      lab: {
        l: 58.04,
        a: -0.17,
        b: 18.95,
      },
      cmyk: null,
    },
    {
      code: '17-0627 TPG',
      name: 'Dried Herb',
      hex: '8A8061',
      rgb: {
        r: 138,
        g: 128,
        b: 97,
      },
      lab: {
        l: 54.01,
        a: 0.71,
        b: 18.6,
      },
      cmyk: null,
    },
    {
      code: '18-0622 TPG',
      name: 'Olive Drab',
      hex: '777352',
      rgb: {
        r: 119,
        g: 115,
        b: 82,
      },
      lab: {
        l: 48.22,
        a: -2.84,
        b: 19.26,
      },
      cmyk: null,
    },
    {
      code: '18-0825 TPG',
      name: 'Nutria',
      hex: '756A4C',
      rgb: {
        r: 117,
        g: 106,
        b: 76,
      },
      lab: {
        l: 45.21,
        a: 1,
        b: 18.33,
      },
      cmyk: null,
    },
    {
      code: '18-0629 TPG',
      name: 'Lizard',
      hex: '7C7354',
      rgb: {
        r: 124,
        g: 115,
        b: 84,
      },
      lab: {
        l: 48.71,
        a: -0.04,
        b: 18.9,
      },
      cmyk: null,
    },
    {
      code: '18-0625 TPG',
      name: 'Martini Olive',
      hex: '776F57',
      rgb: {
        r: 119,
        g: 111,
        b: 87,
      },
      lab: {
        l: 47.14,
        a: 0.43,
        b: 14.68,
      },
      cmyk: null,
    },
    {
      code: '14-0418 TPG',
      name: 'Bog',
      hex: 'B7B996',
      rgb: {
        r: 183,
        g: 185,
        b: 150,
      },
      lab: {
        l: 74.41,
        a: -5.3,
        b: 17.7,
      },
      cmyk: null,
    },
    {
      code: '15-0318 TPG',
      name: 'Sage Green',
      hex: 'AFAC87',
      rgb: {
        r: 175,
        g: 172,
        b: 135,
      },
      lab: {
        l: 69.86,
        a: -3.44,
        b: 19.52,
      },
      cmyk: null,
    },
    {
      code: '16-0713 TPG',
      name: 'Slate Green',
      hex: 'A1977D',
      rgb: {
        r: 161,
        g: 151,
        b: 125,
      },
      lab: {
        l: 62.91,
        a: 1.19,
        b: 15.21,
      },
      cmyk: null,
    },
    {
      code: '17-0619 TPG',
      name: 'Overland Trek',
      hex: '8C8575',
      rgb: {
        r: 140,
        g: 133,
        b: 117,
      },
      lab: {
        l: 55.76,
        a: 1.28,
        b: 9.45,
      },
      cmyk: null,
    },
    {
      code: '16-0613 TPG',
      name: 'Elm',
      hex: 'A5A188',
      rgb: {
        r: 165,
        g: 161,
        b: 136,
      },
      lab: {
        l: 65.98,
        a: -0.97,
        b: 13.08,
      },
      cmyk: null,
    },
    {
      code: '17-0620 TPG',
      name: 'Aloe',
      hex: '867D65',
      rgb: {
        r: 134,
        g: 125,
        b: 101,
      },
      lab: {
        l: 52.79,
        a: 0.52,
        b: 14.33,
      },
      cmyk: null,
    },
    {
      code: '17-0618 TPG',
      name: 'Mermaid',
      hex: '817E6A',
      rgb: {
        r: 129,
        g: 126,
        b: 106,
      },
      lab: {
        l: 52.58,
        a: -1.41,
        b: 11.28,
      },
      cmyk: null,
    },
    {
      code: '15-0522 TPG',
      name: 'Pale Olive Green',
      hex: 'B7AC8A',
      rgb: {
        r: 183,
        g: 172,
        b: 138,
      },
      lab: {
        l: 70.55,
        a: 0.8,
        b: 18.6,
      },
      cmyk: null,
    },
    {
      code: '16-1110 TPG',
      name: 'Olive Gray',
      hex: 'A49A7A',
      rgb: {
        r: 164,
        g: 154,
        b: 122,
      },
      lab: {
        l: 63.92,
        a: -0.02,
        b: 18.27,
      },
      cmyk: null,
    },
    {
      code: '16-1108 TPG',
      name: 'Twill',
      hex: 'A89F84',
      rgb: {
        r: 168,
        g: 159,
        b: 132,
      },
      lab: {
        l: 65.79,
        a: -0.15,
        b: 15.66,
      },
      cmyk: null,
    },
    {
      code: '16-1118 TPG',
      name: 'Sponge',
      hex: 'A79C7D',
      rgb: {
        r: 167,
        g: 156,
        b: 125,
      },
      lab: {
        l: 64.82,
        a: 1.16,
        b: 17.74,
      },
      cmyk: null,
    },
    {
      code: '17-0630 TPG',
      name: 'Tree House',
      hex: '9B8F77',
      rgb: {
        r: 155,
        g: 143,
        b: 119,
      },
      lab: {
        l: 60.1,
        a: 2.2,
        b: 14.46,
      },
      cmyk: null,
    },
    {
      code: '17-1019 TPG',
      name: 'Elmwood',
      hex: '928167',
      rgb: {
        r: 146,
        g: 129,
        b: 103,
      },
      lab: {
        l: 54.97,
        a: 4.38,
        b: 16.29,
      },
      cmyk: null,
    },
    {
      code: '18-0724 TPG',
      name: 'Gothic Olive',
      hex: '81765B',
      rgb: {
        r: 129,
        g: 118,
        b: 91,
      },
      lab: {
        l: 50.02,
        a: 1.16,
        b: 16.61,
      },
      cmyk: null,
    },
    {
      code: '13-0715 TPG',
      name: 'Sea Mist',
      hex: 'DACCA7',
      rgb: {
        r: 218,
        g: 204,
        b: 167,
      },
      lab: {
        l: 82.57,
        a: 0.9,
        b: 20.46,
      },
      cmyk: null,
    },
    {
      code: '13-0613 TPG',
      name: 'Chino Green',
      hex: 'DCD0AC',
      rgb: {
        r: 220,
        g: 208,
        b: 172,
      },
      lab: {
        l: 83.84,
        a: 0.04,
        b: 19.93,
      },
      cmyk: null,
    },
    {
      code: '13-0919 TPG',
      name: 'Soybean',
      hex: 'D7C49E',
      rgb: {
        r: 215,
        g: 196,
        b: 158,
      },
      lab: {
        l: 80.25,
        a: 3.13,
        b: 21.93,
      },
      cmyk: null,
    },
    {
      code: '13-0915 TPG',
      name: 'Reed Yellow',
      hex: 'DCCCA3',
      rgb: {
        r: 220,
        g: 204,
        b: 163,
      },
      lab: {
        l: 82.83,
        a: 1.09,
        b: 23,
      },
      cmyk: null,
    },
    {
      code: '14-1113 TPG',
      name: 'Marzipan',
      hex: 'DAC29B',
      rgb: {
        r: 218,
        g: 194,
        b: 155,
      },
      lab: {
        l: 79.81,
        a: 4.68,
        b: 23.02,
      },
      cmyk: null,
    },
    {
      code: '14-0925 TPG',
      name: 'Parsnip',
      hex: 'D6C694',
      rgb: {
        r: 214,
        g: 198,
        b: 148,
      },
      lab: {
        l: 80.46,
        a: 0.46,
        b: 27.44,
      },
      cmyk: null,
    },
    {
      code: '14-1026 TPG',
      name: 'Moonstone',
      hex: 'CFB686',
      rgb: {
        r: 207,
        g: 182,
        b: 134,
      },
      lab: {
        l: 75.61,
        a: 4.61,
        b: 28.22,
      },
      cmyk: null,
    },
    {
      code: '14-0626 TPG',
      name: 'Dried Moss',
      hex: 'CBBB80',
      rgb: {
        r: 203,
        g: 187,
        b: 128,
      },
      lab: {
        l: 76.33,
        a: -0.65,
        b: 32.23,
      },
      cmyk: null,
    },
    {
      code: '14-1025 TPG',
      name: 'Cocoon',
      hex: 'CDB97F',
      rgb: {
        r: 205,
        g: 185,
        b: 127,
      },
      lab: {
        l: 75.84,
        a: 1.55,
        b: 32.24,
      },
      cmyk: null,
    },
    {
      code: '14-0721 TPG',
      name: 'Hemp',
      hex: 'C5B482',
      rgb: {
        r: 197,
        g: 180,
        b: 130,
      },
      lab: {
        l: 73.95,
        a: 0.75,
        b: 28.07,
      },
      cmyk: null,
    },
    {
      code: '15-0719 TPG',
      name: 'Silver Fern',
      hex: 'BBAD82',
      rgb: {
        r: 187,
        g: 173,
        b: 130,
      },
      lab: {
        l: 71.11,
        a: 0.77,
        b: 24.25,
      },
      cmyk: null,
    },
    {
      code: '16-0726 TPG',
      name: 'Khaki',
      hex: 'A09165',
      rgb: {
        r: 160,
        g: 145,
        b: 101,
      },
      lab: {
        l: 60.9,
        a: 0.79,
        b: 26.07,
      },
      cmyk: null,
    },
    {
      code: '17-0929 TPG',
      name: 'Fennel Seed',
      hex: '9B855B',
      rgb: {
        r: 155,
        g: 133,
        b: 91,
      },
      lab: {
        l: 56.92,
        a: 4.92,
        b: 25.95,
      },
      cmyk: null,
    },
    {
      code: '18-0820 TPG',
      name: 'Capers',
      hex: '6B644F',
      rgb: {
        r: 107,
        g: 100,
        b: 79,
      },
      lab: {
        l: 42.77,
        a: -0.13,
        b: 13.45,
      },
      cmyk: null,
    },
    {
      code: '16-1326 TPG',
      name: 'Prairie Sand',
      hex: 'B49A68',
      rgb: {
        r: 180,
        g: 154,
        b: 104,
      },
      lab: {
        l: 65.27,
        a: 4.84,
        b: 30.4,
      },
      cmyk: null,
    },
    {
      code: '16-1126 TPG',
      name: 'Antelope',
      hex: 'A99260',
      rgb: {
        r: 169,
        g: 146,
        b: 96,
      },
      lab: {
        l: 61.93,
        a: 3.87,
        b: 30.1,
      },
      cmyk: null,
    },
    {
      code: '16-1133 TPG',
      name: 'Mustard Gold',
      hex: 'B19358',
      rgb: {
        r: 177,
        g: 147,
        b: 88,
      },
      lab: {
        l: 62.96,
        a: 6.26,
        b: 35.75,
      },
      cmyk: null,
    },
    {
      code: '17-1028 TPG',
      name: 'Antique Bronze',
      hex: '948260',
      rgb: {
        r: 148,
        g: 130,
        b: 96,
      },
      lab: {
        l: 55.61,
        a: 2.94,
        b: 21.71,
      },
      cmyk: null,
    },
    {
      code: '17-0935 TPG',
      name: 'Dull Gold',
      hex: '86724A',
      rgb: {
        r: 134,
        g: 114,
        b: 74,
      },
      lab: {
        l: 49.44,
        a: 3.8,
        b: 25.44,
      },
      cmyk: null,
    },
    {
      code: '18-0830 TPG',
      name: 'Butternut',
      hex: '7D6C4E',
      rgb: {
        r: 125,
        g: 108,
        b: 78,
      },
      lab: {
        l: 46.7,
        a: 3.62,
        b: 19.38,
      },
      cmyk: null,
    },
    {
      code: '19-0617 TPG',
      name: 'Teak',
      hex: '65594A',
      rgb: {
        r: 101,
        g: 89,
        b: 74,
      },
      lab: {
        l: 38.63,
        a: 3.02,
        b: 10.63,
      },
      cmyk: null,
    },
    {
      code: '16-0730 TPG',
      name: 'Antique Gold',
      hex: 'B6A166',
      rgb: {
        r: 182,
        g: 161,
        b: 102,
      },
      lab: {
        l: 67.12,
        a: 2.07,
        b: 33.71,
      },
      cmyk: null,
    },
    {
      code: '17-0836 TPG',
      name: 'Ecru Olive',
      hex: '98824C',
      rgb: {
        r: 152,
        g: 130,
        b: 76,
      },
      lab: {
        l: 55.53,
        a: 3.84,
        b: 32.54,
      },
      cmyk: null,
    },
    {
      code: '17-0840 TPG',
      name: 'Amber Green',
      hex: '9D884A',
      rgb: {
        r: 157,
        g: 136,
        b: 74,
      },
      lab: {
        l: 57.88,
        a: 2.15,
        b: 36.75,
      },
      cmyk: null,
    },
    {
      code: '17-0843 TPG',
      name: 'Bronze Mist',
      hex: 'A0824D',
      rgb: {
        r: 160,
        g: 130,
        b: 77,
      },
      lab: {
        l: 56.63,
        a: 7.6,
        b: 33.31,
      },
      cmyk: null,
    },
    {
      code: '19-1034 TPG',
      name: 'Breen',
      hex: '776144',
      rgb: {
        r: 119,
        g: 97,
        b: 68,
      },
      lab: {
        l: 42.98,
        a: 6.25,
        b: 20.33,
      },
      cmyk: null,
    },
    {
      code: '18-0920 TPG',
      name: 'Kangaroo',
      hex: '74654F',
      rgb: {
        r: 116,
        g: 101,
        b: 79,
      },
      lab: {
        l: 43.85,
        a: 3.7,
        b: 14.84,
      },
      cmyk: null,
    },
    {
      code: '19-0618 TPG',
      name: 'Beech',
      hex: '5C5440',
      rgb: {
        r: 92,
        g: 84,
        b: 64,
      },
      lab: {
        l: 36.28,
        a: 0.57,
        b: 13.51,
      },
      cmyk: null,
    },
    {
      code: '15-0730 TPG',
      name: 'Southern Moss',
      hex: 'BEAB6E',
      rgb: {
        r: 190,
        g: 171,
        b: 110,
      },
      lab: {
        l: 70.67,
        a: 1.43,
        b: 33.72,
      },
      cmyk: null,
    },
    {
      code: '15-0732 TPG',
      name: 'Olivenite',
      hex: 'C2AD69',
      rgb: {
        r: 194,
        g: 173,
        b: 105,
      },
      lab: {
        l: 71.63,
        a: 1.13,
        b: 38.31,
      },
      cmyk: null,
    },
    {
      code: '16-0742 TPG',
      name: 'Green Sulphur',
      hex: 'B0913F',
      rgb: {
        r: 176,
        g: 145,
        b: 63,
      },
      lab: {
        l: 62.16,
        a: 4.74,
        b: 47.14,
      },
      cmyk: null,
    },
    {
      code: '17-0839 TPG',
      name: 'Golden Palm',
      hex: 'AF9131',
      rgb: {
        r: 175,
        g: 145,
        b: 49,
      },
      lab: {
        l: 62.01,
        a: 3.71,
        b: 53.52,
      },
      cmyk: null,
    },
    {
      code: '16-0847 TPG',
      name: 'Olive Oil',
      hex: 'AC9041',
      rgb: {
        r: 172,
        g: 144,
        b: 65,
      },
      lab: {
        l: 61.48,
        a: 4.09,
        b: 45.71,
      },
      cmyk: null,
    },
    {
      code: '18-0835 TPG',
      name: 'Dried Tobacco',
      hex: '997E43',
      rgb: {
        r: 153,
        g: 126,
        b: 67,
      },
      lab: {
        l: 54.66,
        a: 5.06,
        b: 36.42,
      },
      cmyk: null,
    },
    {
      code: '18-0832 TPG',
      name: 'Plantation',
      hex: '7D6A45',
      rgb: {
        r: 125,
        g: 106,
        b: 69,
      },
      lab: {
        l: 46.14,
        a: 4.04,
        b: 23.74,
      },
      cmyk: null,
    },
    {
      code: '13-0725 TPG',
      name: 'Raffia',
      hex: 'DBC886',
      rgb: {
        r: 219,
        g: 200,
        b: 134,
      },
      lab: {
        l: 81.34,
        a: -0.05,
        b: 35.85,
      },
      cmyk: null,
    },
    {
      code: '15-0743 TPG',
      name: 'Oil Yellow',
      hex: 'C4A850',
      rgb: {
        r: 196,
        g: 168,
        b: 80,
      },
      lab: {
        l: 70.18,
        a: 3.06,
        b: 48.77,
      },
      cmyk: null,
    },
    {
      code: '14-0740 TPG',
      name: 'Bamboo',
      hex: 'D3B558',
      rgb: {
        r: 211,
        g: 181,
        b: 88,
      },
      lab: {
        l: 75.04,
        a: 3.23,
        b: 50.91,
      },
      cmyk: null,
    },
    {
      code: '15-0850 TPG',
      name: 'Ceylon Yellow',
      hex: 'D5AE41',
      rgb: {
        r: 213,
        g: 174,
        b: 65,
      },
      lab: {
        l: 73.48,
        a: 6.52,
        b: 59.62,
      },
      cmyk: null,
    },
    {
      code: '15-0751 TPG',
      name: 'Lemon Curry',
      hex: 'CEA433',
      rgb: {
        r: 206,
        g: 164,
        b: 51,
      },
      lab: {
        l: 70.14,
        a: 7.62,
        b: 61.88,
      },
      cmyk: null,
    },
    {
      code: '16-0946 TPG',
      name: 'Honey',
      hex: 'BB9646',
      rgb: {
        r: 187,
        g: 150,
        b: 70,
      },
      lab: {
        l: 64.68,
        a: 7.93,
        b: 47.49,
      },
      cmyk: null,
    },
    {
      code: '18-0840 TPG',
      name: 'Tapenade',
      hex: '83683E',
      rgb: {
        r: 131,
        g: 104,
        b: 62,
      },
      lab: {
        l: 46.22,
        a: 7.02,
        b: 28.16,
      },
      cmyk: null,
    },
    {
      code: '14-0837 TPG',
      name: 'Misted Yellow',
      hex: 'D9B965',
      rgb: {
        r: 217,
        g: 185,
        b: 101,
      },
      lab: {
        l: 76.78,
        a: 4.22,
        b: 46.79,
      },
      cmyk: null,
    },
    {
      code: '14-0952 TPG',
      name: 'Spicy Mustard',
      hex: 'DBAE49',
      rgb: {
        r: 219,
        g: 174,
        b: 73,
      },
      lab: {
        l: 74.15,
        a: 9.71,
        b: 56.69,
      },
      cmyk: null,
    },
    {
      code: '16-0954 TPG',
      name: 'Arrowwood',
      hex: 'BE8F32',
      rgb: {
        r: 190,
        g: 143,
        b: 50,
      },
      lab: {
        l: 63.18,
        a: 11.47,
        b: 54.95,
      },
      cmyk: null,
    },
    {
      code: '16-0952 TPG',
      name: 'Nugget Gold',
      hex: 'CB9D31',
      rgb: {
        r: 203,
        g: 157,
        b: 49,
      },
      lab: {
        l: 67.97,
        a: 10.37,
        b: 60.33,
      },
      cmyk: null,
    },
    {
      code: '15-0948 TPG',
      name: 'Golden Spice',
      hex: 'C59941',
      rgb: {
        r: 197,
        g: 153,
        b: 65,
      },
      lab: {
        l: 66.56,
        a: 10.37,
        b: 51.96,
      },
      cmyk: null,
    },
    {
      code: '16-0953 TPG',
      name: 'Tawny Olive',
      hex: 'C59633',
      rgb: {
        r: 197,
        g: 150,
        b: 51,
      },
      lab: {
        l: 65.63,
        a: 11.17,
        b: 57.26,
      },
      cmyk: null,
    },
    {
      code: '17-0949 TPG',
      name: 'Chai Tea',
      hex: 'B8893D',
      rgb: {
        r: 184,
        g: 137,
        b: 61,
      },
      lab: {
        l: 61.08,
        a: 13.04,
        b: 47.69,
      },
      cmyk: null,
    },
    {
      code: '14-1031 TPG',
      name: 'Rattan',
      hex: 'D4B373',
      rgb: {
        r: 212,
        g: 179,
        b: 115,
      },
      lab: {
        l: 74.97,
        a: 6.63,
        b: 37.37,
      },
      cmyk: null,
    },
    {
      code: '14-1036 TPG',
      name: 'Ochre',
      hex: 'D9AE60',
      rgb: {
        r: 217,
        g: 174,
        b: 96,
      },
      lab: {
        l: 74.19,
        a: 9.43,
        b: 46.59,
      },
      cmyk: null,
    },
    {
      code: '15-1132 TPG',
      name: 'Fall Leaf',
      hex: 'C8AA6E',
      rgb: {
        r: 200,
        g: 170,
        b: 110,
      },
      lab: {
        l: 71.46,
        a: 5.55,
        b: 35.65,
      },
      cmyk: null,
    },
    {
      code: '15-0942 TPG',
      name: 'Sauterne',
      hex: 'C2A156',
      rgb: {
        r: 194,
        g: 161,
        b: 86,
      },
      lab: {
        l: 68.38,
        a: 5.77,
        b: 43.95,
      },
      cmyk: null,
    },
    {
      code: '16-0945 TPG',
      name: 'Tinsel',
      hex: 'C49C52',
      rgb: {
        r: 196,
        g: 156,
        b: 82,
      },
      lab: {
        l: 67.16,
        a: 9.15,
        b: 44.34,
      },
      cmyk: null,
    },
    {
      code: '16-0948 TPG',
      name: 'Harvest Gold',
      hex: 'B38B41',
      rgb: {
        r: 179,
        g: 139,
        b: 65,
      },
      lab: {
        l: 61.08,
        a: 9.73,
        b: 45.44,
      },
      cmyk: null,
    },
    {
      code: '18-0939 TPG',
      name: 'Cumin',
      hex: '917345',
      rgb: {
        r: 145,
        g: 115,
        b: 69,
      },
      lab: {
        l: 50.91,
        a: 7.57,
        b: 30.62,
      },
      cmyk: null,
    },
    {
      code: '15-1142 TPG',
      name: 'Honey Gold',
      hex: 'D5A351',
      rgb: {
        r: 213,
        g: 163,
        b: 81,
      },
      lab: {
        l: 70.97,
        a: 12.61,
        b: 49.79,
      },
      cmyk: null,
    },
    {
      code: '16-0947 TPG',
      name: 'Bright Gold',
      hex: 'D1A451',
      rgb: {
        r: 209,
        g: 164,
        b: 81,
      },
      lab: {
        l: 70.72,
        a: 10.61,
        b: 49.35,
      },
      cmyk: null,
    },
    {
      code: '16-0950 TPG',
      name: 'Narcissus',
      hex: 'C6964A',
      rgb: {
        r: 198,
        g: 150,
        b: 74,
      },
      lab: {
        l: 65.93,
        a: 13.12,
        b: 47.15,
      },
      cmyk: null,
    },
    {
      code: '16-1139 TPG',
      name: 'Amber Gold',
      hex: 'BC9251',
      rgb: {
        r: 188,
        g: 146,
        b: 81,
      },
      lab: {
        l: 63.77,
        a: 11.04,
        b: 40.81,
      },
      cmyk: null,
    },
    {
      code: '15-1046 TPG',
      name: 'Mineral Yellow',
      hex: 'D7A247',
      rgb: {
        r: 215,
        g: 162,
        b: 71,
      },
      lab: {
        l: 70.91,
        a: 13.6,
        b: 54.86,
      },
      cmyk: null,
    },
    {
      code: '15-0960 TPG',
      name: 'Mango Mojito',
      hex: 'DAA03D',
      rgb: {
        r: 218,
        g: 160,
        b: 61,
      },
      lab: {
        l: 70.49,
        a: 16.14,
        b: 58.43,
      },
      cmyk: null,
    },
    {
      code: '15-0953 TPG',
      name: 'Golden Yellow',
      hex: 'CF942E',
      rgb: {
        r: 207,
        g: 148,
        b: 46,
      },
      lab: {
        l: 66.35,
        a: 16.35,
        b: 60.36,
      },
      cmyk: null,
    },
    {
      code: '16-0940 TPG',
      name: 'Taffy',
      hex: 'C49D69',
      rgb: {
        r: 196,
        g: 157,
        b: 105,
      },
      lab: {
        l: 67.73,
        a: 10.28,
        b: 33.47,
      },
      cmyk: null,
    },
    {
      code: '17-1047 TPG',
      name: 'Honey Mustard',
      hex: 'B79154',
      rgb: {
        r: 183,
        g: 145,
        b: 84,
      },
      lab: {
        l: 63.23,
        a: 9.33,
        b: 38.51,
      },
      cmyk: null,
    },
    {
      code: '17-1129 TPG',
      name: 'Wood Thrush',
      hex: 'A68146',
      rgb: {
        r: 166,
        g: 129,
        b: 70,
      },
      lab: {
        l: 56.97,
        a: 9.71,
        b: 37.73,
      },
      cmyk: null,
    },
    {
      code: '18-0940 TPG',
      name: 'Golden Brown',
      hex: '916C3A',
      rgb: {
        r: 145,
        g: 108,
        b: 58,
      },
      lab: {
        l: 48.97,
        a: 11.16,
        b: 34.27,
      },
      cmyk: null,
    },
    {
      code: '18-0937 TPG',
      name: 'Bronze Brown',
      hex: '82643E',
      rgb: {
        r: 130,
        g: 100,
        b: 62,
      },
      lab: {
        l: 45.01,
        a: 9.02,
        b: 26.85,
      },
      cmyk: null,
    },
    {
      code: '18-0933 TPG',
      name: 'Rubber',
      hex: '85684D',
      rgb: {
        r: 133,
        g: 104,
        b: 77,
      },
      lab: {
        l: 46.54,
        a: 9.54,
        b: 20.29,
      },
      cmyk: null,
    },
    {
      code: '18-1048 TPG',
      name: "Monk's Robe",
      hex: '77563E',
      rgb: {
        r: 119,
        g: 86,
        b: 62,
      },
      lab: {
        l: 39.8,
        a: 12.29,
        b: 20.11,
      },
      cmyk: null,
    },
    {
      code: '17-1036 TPG',
      name: 'Bistre',
      hex: '927449',
      rgb: {
        r: 146,
        g: 116,
        b: 73,
      },
      lab: {
        l: 51.42,
        a: 7.85,
        b: 29.22,
      },
      cmyk: null,
    },
    {
      code: '18-1022 TPG',
      name: 'Ermine',
      hex: '826D52',
      rgb: {
        r: 130,
        g: 109,
        b: 82,
      },
      lab: {
        l: 47.55,
        a: 5.75,
        b: 18.74,
      },
      cmyk: null,
    },
    {
      code: '18-1018 TPG',
      name: 'Otter',
      hex: '7F6A55',
      rgb: {
        r: 127,
        g: 106,
        b: 85,
      },
      lab: {
        l: 46.69,
        a: 6.27,
        b: 15.73,
      },
      cmyk: null,
    },
    {
      code: '18-0930 TPG',
      name: 'Coffee Liqueúr',
      hex: '6E5B4A',
      rgb: {
        r: 110,
        g: 91,
        b: 74,
      },
      lab: {
        l: 40.34,
        a: 6.23,
        b: 12.98,
      },
      cmyk: null,
    },
    {
      code: '18-0928 TPG',
      name: 'Sepia',
      hex: '705E4A',
      rgb: {
        r: 112,
        g: 94,
        b: 74,
      },
      lab: {
        l: 41.45,
        a: 5.26,
        b: 14.43,
      },
      cmyk: null,
    },
    {
      code: '19-0815 TPG',
      name: 'Desert Palm',
      hex: '5E4E3E',
      rgb: {
        r: 94,
        g: 78,
        b: 62,
      },
      lab: {
        l: 34.54,
        a: 5.02,
        b: 12.03,
      },
      cmyk: null,
    },
    {
      code: '19-1020 TPG',
      name: 'Dark Earth',
      hex: '5F4E41',
      rgb: {
        r: 95,
        g: 78,
        b: 65,
      },
      lab: {
        l: 34.7,
        a: 6.45,
        b: 10.8,
      },
      cmyk: null,
    },
    {
      code: '16-1334 TPG',
      name: 'Tan',
      hex: 'BA9C76',
      rgb: {
        r: 186,
        g: 156,
        b: 118,
      },
      lab: {
        l: 66.57,
        a: 7.79,
        b: 24.59,
      },
      cmyk: null,
    },
    {
      code: '17-1325 TPG',
      name: 'Cartouche',
      hex: 'AC8E6F',
      rgb: {
        r: 172,
        g: 142,
        b: 111,
      },
      lab: {
        l: 61.34,
        a: 9.07,
        b: 21.03,
      },
      cmyk: null,
    },
    {
      code: '17-1038 TPG',
      name: "Tiger's Eye",
      hex: '9E8168',
      rgb: {
        r: 158,
        g: 129,
        b: 104,
      },
      lab: {
        l: 56.43,
        a: 9.24,
        b: 17.78,
      },
      cmyk: null,
    },
    {
      code: '18-1014 TPG',
      name: 'Malt Ball',
      hex: '856C58',
      rgb: {
        r: 133,
        g: 108,
        b: 88,
      },
      lab: {
        l: 47.88,
        a: 8.6,
        b: 15.33,
      },
      cmyk: null,
    },
    {
      code: '18-1020 TPG',
      name: 'Cocoa Crème',
      hex: '8B7360',
      rgb: {
        r: 139,
        g: 115,
        b: 96,
      },
      lab: {
        l: 50.47,
        a: 8.04,
        b: 14.28,
      },
      cmyk: null,
    },
    {
      code: '18-1029 TPG',
      name: 'Toasted Coconut',
      hex: '8F725D',
      rgb: {
        r: 143,
        g: 114,
        b: 93,
      },
      lab: {
        l: 50.85,
        a: 10.01,
        b: 16.68,
      },
      cmyk: null,
    },
    {
      code: '18-1025 TPG',
      name: 'Foxtrot',
      hex: '8F6C50',
      rgb: {
        r: 143,
        g: 108,
        b: 80,
      },
      lab: {
        l: 48.83,
        a: 12.7,
        b: 21.54,
      },
      cmyk: null,
    },
    {
      code: '14-1038 TPG',
      name: 'New Wheat',
      hex: 'D9B780',
      rgb: {
        r: 217,
        g: 183,
        b: 128,
      },
      lab: {
        l: 76.77,
        a: 6.91,
        b: 33.09,
      },
      cmyk: null,
    },
    {
      code: '16-0928 TPG',
      name: 'Curry',
      hex: 'BC9E6E',
      rgb: {
        r: 188,
        g: 158,
        b: 110,
      },
      lab: {
        l: 67.12,
        a: 7.05,
        b: 29.82,
      },
      cmyk: null,
    },
    {
      code: '15-1040 TPG',
      name: 'Iced Coffee',
      hex: 'B69573',
      rgb: {
        r: 182,
        g: 149,
        b: 115,
      },
      lab: {
        l: 64.41,
        a: 9.79,
        b: 23.35,
      },
      cmyk: null,
    },
    {
      code: '17-1045 TPG',
      name: 'Apple Cinnamon',
      hex: 'AE895D',
      rgb: {
        r: 174,
        g: 137,
        b: 93,
      },
      lab: {
        l: 60.31,
        a: 10.34,
        b: 30.09,
      },
      cmyk: null,
    },
    {
      code: '17-1128 TPG',
      name: 'Bone Brown',
      hex: '9D794C',
      rgb: {
        r: 157,
        g: 121,
        b: 76,
      },
      lab: {
        l: 53.88,
        a: 10.74,
        b: 30.63,
      },
      cmyk: null,
    },
    {
      code: '17-1125 TPG',
      name: 'Dijon',
      hex: '9A7D52',
      rgb: {
        r: 154,
        g: 125,
        b: 82,
      },
      lab: {
        l: 54.52,
        a: 7.53,
        b: 27.93,
      },
      cmyk: null,
    },
    {
      code: '17-0942 TPG',
      name: 'Medal Bronze',
      hex: '97784D',
      rgb: {
        r: 151,
        g: 120,
        b: 77,
      },
      lab: {
        l: 53.03,
        a: 8.17,
        b: 28.99,
      },
      cmyk: null,
    },
    {
      code: '13-1031 TPG',
      name: 'Apricot Sherbet',
      hex: 'FDD19C',
      rgb: {
        r: 253,
        g: 209,
        b: 156,
      },
      lab: {
        l: 87.15,
        a: 10.93,
        b: 33.09,
      },
      cmyk: null,
    },
    {
      code: '13-1030 TPG',
      name: 'Sunburst',
      hex: 'FAC58D',
      rgb: {
        r: 250,
        g: 197,
        b: 141,
      },
      lab: {
        l: 83.74,
        a: 14.78,
        b: 36.49,
      },
      cmyk: null,
    },
    {
      code: '16-1144 TPG',
      name: 'Oak Buff',
      hex: 'D09F64',
      rgb: {
        r: 208,
        g: 159,
        b: 100,
      },
      lab: {
        l: 69.65,
        a: 13.49,
        b: 38.84,
      },
      cmyk: null,
    },
    {
      code: '16-1143 TPG',
      name: 'Honey Yellow',
      hex: 'CB9658',
      rgb: {
        r: 203,
        g: 150,
        b: 88,
      },
      lab: {
        l: 66.67,
        a: 15.87,
        b: 41.34,
      },
      cmyk: null,
    },
    {
      code: '17-1040 TPG',
      name: 'Spruce Yellow',
      hex: 'BF8D4E',
      rgb: {
        r: 191,
        g: 141,
        b: 78,
      },
      lab: {
        l: 62.98,
        a: 14.35,
        b: 41.87,
      },
      cmyk: null,
    },
    {
      code: '17-1048 TPG',
      name: 'Inca Gold',
      hex: 'BD8038',
      rgb: {
        r: 189,
        g: 128,
        b: 56,
      },
      lab: {
        l: 59.4,
        a: 19.76,
        b: 48.58,
      },
      cmyk: null,
    },
    {
      code: '18-0935 TPG',
      name: 'Buckthorn Brown',
      hex: 'AC7835',
      rgb: {
        r: 172,
        g: 120,
        b: 53,
      },
      lab: {
        l: 55.28,
        a: 16.13,
        b: 44.67,
      },
      cmyk: null,
    },
    {
      code: '13-1027 TPG',
      name: 'Apricot Cream',
      hex: 'F4BF86',
      rgb: {
        r: 244,
        g: 191,
        b: 134,
      },
      lab: {
        l: 81.63,
        a: 14.64,
        b: 37.14,
      },
      cmyk: null,
    },
    {
      code: '14-1128 TPG',
      name: 'Buff Orange',
      hex: 'FFBE7B',
      rgb: {
        r: 255,
        g: 190,
        b: 123,
      },
      lab: {
        l: 82.81,
        a: 20.23,
        b: 44.77,
      },
      cmyk: null,
    },
    {
      code: '15-1145 TPG',
      name: 'Chamois',
      hex: 'F6B066',
      rgb: {
        r: 246,
        g: 176,
        b: 102,
      },
      lab: {
        l: 78.02,
        a: 20.97,
        b: 49.01,
      },
      cmyk: null,
    },
    {
      code: '14-1051 TPG',
      name: 'Warm Apricot',
      hex: 'FFB762',
      rgb: {
        r: 255,
        g: 183,
        b: 98,
      },
      lab: {
        l: 80.93,
        a: 21.72,
        b: 54.57,
      },
      cmyk: null,
    },
    {
      code: '16-1142 TPG',
      name: 'Golden Nugget',
      hex: 'E19E5A',
      rgb: {
        r: 225,
        g: 158,
        b: 90,
      },
      lab: {
        l: 71.33,
        a: 20.86,
        b: 46.57,
      },
      cmyk: null,
    },
    {
      code: '16-1342 TPG',
      name: 'Buckskin',
      hex: 'D29156',
      rgb: {
        r: 210,
        g: 145,
        b: 86,
      },
      lab: {
        l: 66.26,
        a: 21.48,
        b: 41.92,
      },
      cmyk: null,
    },
    {
      code: '16-1148 TPG',
      name: 'Nugget',
      hex: 'CE8C4E',
      rgb: {
        r: 206,
        g: 140,
        b: 78,
      },
      lab: {
        l: 64.68,
        a: 21.21,
        b: 44.38,
      },
      cmyk: null,
    },
    {
      code: '16-1149 TPG',
      name: 'Desert Sun',
      hex: 'CA7C3D',
      rgb: {
        r: 202,
        g: 124,
        b: 61,
      },
      lab: {
        l: 60.19,
        a: 28.4,
        b: 47.41,
      },
      cmyk: null,
    },
    {
      code: '17-1046 TPG',
      name: 'Golden Oak',
      hex: 'C37A3B',
      rgb: {
        r: 195,
        g: 122,
        b: 59,
      },
      lab: {
        l: 58.95,
        a: 25.82,
        b: 46.96,
      },
      cmyk: null,
    },
    {
      code: '18-1160 TPG',
      name: 'Sudan Brown',
      hex: 'AA7241',
      rgb: {
        r: 170,
        g: 114,
        b: 65,
      },
      lab: {
        l: 53.67,
        a: 19.12,
        b: 37.03,
      },
      cmyk: null,
    },
    {
      code: '18-1050 TPG',
      name: 'Honey Ginger',
      hex: 'B46F35',
      rgb: {
        r: 180,
        g: 111,
        b: 53,
      },
      lab: {
        l: 54.18,
        a: 25.23,
        b: 43.89,
      },
      cmyk: null,
    },
    {
      code: '18-1049 TPG',
      name: 'Thai Curry',
      hex: 'AF6F34',
      rgb: {
        r: 175,
        g: 111,
        b: 52,
      },
      lab: {
        l: 53.7,
        a: 22.11,
        b: 43.81,
      },
      cmyk: null,
    },
    {
      code: '18-1163 TPG',
      name: 'Pumpkin Spice',
      hex: 'AA6938',
      rgb: {
        r: 170,
        g: 105,
        b: 56,
      },
      lab: {
        l: 51.49,
        a: 23.88,
        b: 39.01,
      },
      cmyk: null,
    },
    {
      code: '18-0950 TPG',
      name: 'Cathay Spice',
      hex: '9B6C3F',
      rgb: {
        r: 155,
        g: 108,
        b: 63,
      },
      lab: {
        l: 50.12,
        a: 15.89,
        b: 33.3,
      },
      cmyk: null,
    },
    {
      code: '13-0945 TPG',
      name: 'Pale Marigold',
      hex: 'FFC867',
      rgb: {
        r: 255,
        g: 200,
        b: 103,
      },
      lab: {
        l: 85.46,
        a: 16.24,
        b: 57.7,
      },
      cmyk: null,
    },
    {
      code: '14-1050 TPG',
      name: 'Marigold',
      hex: 'FEAE51',
      rgb: {
        r: 254,
        g: 174,
        b: 81,
      },
      lab: {
        l: 78.25,
        a: 24.32,
        b: 59.52,
      },
      cmyk: null,
    },
    {
      code: '15-1058 TPG',
      name: 'Radiant Yellow',
      hex: 'FAA12F',
      rgb: {
        r: 250,
        g: 161,
        b: 47,
      },
      lab: {
        l: 74.8,
        a: 27.79,
        b: 69.88,
      },
      cmyk: null,
    },
    {
      code: '15-1147 TPG',
      name: 'Butterscotch',
      hex: 'DD974A',
      rgb: {
        r: 221,
        g: 151,
        b: 74,
      },
      lab: {
        l: 69.04,
        a: 22.09,
        b: 51.46,
      },
      cmyk: null,
    },
    {
      code: '15-1054 TPG',
      name: 'Cadmium Yellow',
      hex: 'EE9E32',
      rgb: {
        r: 238,
        g: 158,
        b: 50,
      },
      lab: {
        l: 72.54,
        a: 25.15,
        b: 65.82,
      },
      cmyk: null,
    },
    {
      code: '16-1140 TPG',
      name: 'Yam',
      hex: 'D28E46',
      rgb: {
        r: 210,
        g: 142,
        b: 70,
      },
      lab: {
        l: 65.37,
        a: 22,
        b: 48.89,
      },
      cmyk: null,
    },
    {
      code: '16-1150 TPG',
      name: 'Topaz',
      hex: 'D28648',
      rgb: {
        r: 210,
        g: 134,
        b: 72,
      },
      lab: {
        l: 63.64,
        a: 26.01,
        b: 46.4,
      },
      cmyk: null,
    },
    {
      code: '13-0942 TPG',
      name: 'Amber Yellow',
      hex: 'F9B95C',
      rgb: {
        r: 249,
        g: 185,
        b: 92,
      },
      lab: {
        l: 80.3,
        a: 17.56,
        b: 56.34,
      },
      cmyk: null,
    },
    {
      code: '13-0947 TPG',
      name: 'Banana',
      hex: 'FDBE56',
      rgb: {
        r: 253,
        g: 190,
        b: 86,
      },
      lab: {
        l: 81.86,
        a: 15.73,
        b: 60.94,
      },
      cmyk: null,
    },
    {
      code: '15-0955 TPG',
      name: 'Old Gold',
      hex: 'EEB127',
      rgb: {
        r: 238,
        g: 177,
        b: 39,
      },
      lab: {
        l: 76.79,
        a: 14.46,
        b: 72.93,
      },
      cmyk: null,
    },
    {
      code: '14-0955 TPG',
      name: 'Citrus',
      hex: 'FEB237',
      rgb: {
        r: 254,
        g: 178,
        b: 55,
      },
      lab: {
        l: 79.17,
        a: 21.16,
        b: 70.94,
      },
      cmyk: null,
    },
    {
      code: '15-1062 TPG',
      name: 'Gold Fusion',
      hex: 'FFB100',
      rgb: {
        r: 255,
        g: 177,
        b: 0,
      },
      lab: {
        l: 79.87,
        a: 24.13,
        b: 85.23,
      },
      cmyk: null,
    },
    {
      code: '14-1064 TPG',
      name: 'Saffron',
      hex: 'FFAF12',
      rgb: {
        r: 255,
        g: 175,
        b: 18,
      },
      lab: {
        l: 79.66,
        a: 26.26,
        b: 81.33,
      },
      cmyk: null,
    },
    {
      code: '14-1159 TPG',
      name: 'Zinnia',
      hex: 'FFA638',
      rgb: {
        r: 255,
        g: 166,
        b: 56,
      },
      lab: {
        l: 78.04,
        a: 32.75,
        b: 70.58,
      },
      cmyk: null,
    },
    {
      code: '14-0936 TPG',
      name: 'Sahara Sun',
      hex: 'E1C289',
      rgb: {
        r: 225,
        g: 194,
        b: 137,
      },
      lab: {
        l: 80.26,
        a: 5.62,
        b: 33.29,
      },
      cmyk: null,
    },
    {
      code: '13-1025 TPG',
      name: 'Impala',
      hex: 'FDD498',
      rgb: {
        r: 253,
        g: 212,
        b: 152,
      },
      lab: {
        l: 87.75,
        a: 9.16,
        b: 36.08,
      },
      cmyk: null,
    },
    {
      code: '13-0935 TPG',
      name: 'Flax',
      hex: 'FFC97E',
      rgb: {
        r: 255,
        g: 201,
        b: 126,
      },
      lab: {
        l: 85.21,
        a: 13.97,
        b: 45.72,
      },
      cmyk: null,
    },
    {
      code: '14-1045 TPG',
      name: 'Amber',
      hex: 'F0B058',
      rgb: {
        r: 240,
        g: 176,
        b: 88,
      },
      lab: {
        l: 77.12,
        a: 17.83,
        b: 54.4,
      },
      cmyk: null,
    },
    {
      code: '15-1049 TPG',
      name: "Artisan's Gold",
      hex: 'E8A546',
      rgb: {
        r: 232,
        g: 165,
        b: 70,
      },
      lab: {
        l: 73.58,
        a: 19.38,
        b: 58.67,
      },
      cmyk: null,
    },
    {
      code: '14-0941 TPG',
      name: 'Beeswax',
      hex: 'EFAC51',
      rgb: {
        r: 239,
        g: 172,
        b: 81,
      },
      lab: {
        l: 76.05,
        a: 19.39,
        b: 56.36,
      },
      cmyk: null,
    },
    {
      code: '15-1045 TPG',
      name: 'Autumn Blaze',
      hex: 'DB9332',
      rgb: {
        r: 219,
        g: 147,
        b: 50,
      },
      lab: {
        l: 67.63,
        a: 23.27,
        b: 60.29,
      },
      cmyk: null,
    },
    {
      code: '13-0939 TPG',
      name: 'Golden Cream',
      hex: 'F1BC69',
      rgb: {
        r: 241,
        g: 188,
        b: 105,
      },
      lab: {
        l: 80.22,
        a: 12.95,
        b: 49.7,
      },
      cmyk: null,
    },
    {
      code: '13-0946 TPG',
      name: 'Jurassic Gold',
      hex: 'E9AA54',
      rgb: {
        r: 233,
        g: 170,
        b: 84,
      },
      lab: {
        l: 74.96,
        a: 18.3,
        b: 53.54,
      },
      cmyk: null,
    },
    {
      code: '14-1052 TPG',
      name: 'Kumquat',
      hex: 'FCB05A',
      rgb: {
        r: 252,
        g: 176,
        b: 90,
      },
      lab: {
        l: 78.46,
        a: 23.35,
        b: 55.34,
      },
      cmyk: null,
    },
    {
      code: '16-1054 TPG',
      name: 'Sunflower',
      hex: 'D49538',
      rgb: {
        r: 212,
        g: 149,
        b: 56,
      },
      lab: {
        l: 67.28,
        a: 18.74,
        b: 57.59,
      },
      cmyk: null,
    },
    {
      code: '15-1050 TPG',
      name: 'Golden Glow',
      hex: 'DDA142',
      rgb: {
        r: 221,
        g: 161,
        b: 66,
      },
      lab: {
        l: 71.26,
        a: 16.77,
        b: 57.37,
      },
      cmyk: null,
    },
    {
      code: '14-1041 TPG',
      name: 'Golden Apricot',
      hex: 'DFAD61',
      rgb: {
        r: 223,
        g: 173,
        b: 97,
      },
      lab: {
        l: 74.48,
        a: 13.02,
        b: 46.51,
      },
      cmyk: null,
    },
    {
      code: '15-1051 TPG',
      name: 'Golden Orange',
      hex: 'D79232',
      rgb: {
        r: 215,
        g: 146,
        b: 50,
      },
      lab: {
        l: 67.1,
        a: 21.9,
        b: 59.38,
      },
      cmyk: null,
    },
    {
      code: '13-0941 TPG',
      name: 'Banana Cream',
      hex: 'FFD175',
      rgb: {
        r: 255,
        g: 209,
        b: 117,
      },
      lab: {
        l: 86.94,
        a: 10.43,
        b: 51.97,
      },
      cmyk: null,
    },
    {
      code: '13-0932 TPG',
      name: 'Cornsilk',
      hex: 'ECC478',
      rgb: {
        r: 236,
        g: 196,
        b: 120,
      },
      lab: {
        l: 81.84,
        a: 8.14,
        b: 44.04,
      },
      cmyk: null,
    },
    {
      code: '13-0940 TPG',
      name: 'Sunset Gold',
      hex: 'F9C66E',
      rgb: {
        r: 249,
        g: 198,
        b: 110,
      },
      lab: {
        l: 83.56,
        a: 11.37,
        b: 51.63,
      },
      cmyk: null,
    },
    {
      code: '14-0851 TPG',
      name: 'Samoan Sun',
      hex: 'F5CC72',
      rgb: {
        r: 245,
        g: 204,
        b: 114,
      },
      lab: {
        l: 84.56,
        a: 8.37,
        b: 50.02,
      },
      cmyk: null,
    },
    {
      code: '14-0847 TPG',
      name: 'Buff Yellow',
      hex: 'F1C472',
      rgb: {
        r: 241,
        g: 196,
        b: 114,
      },
      lab: {
        l: 82.33,
        a: 9.66,
        b: 48.12,
      },
      cmyk: null,
    },
    {
      code: '14-0850 TPG',
      name: 'Daffodil',
      hex: 'FFC752',
      rgb: {
        r: 255,
        g: 199,
        b: 82,
      },
      lab: {
        l: 84.61,
        a: 13.53,
        b: 65.72,
      },
      cmyk: null,
    },
    {
      code: '14-0846 TPG',
      name: 'Yolk Yellow',
      hex: 'E3B254',
      rgb: {
        r: 227,
        g: 178,
        b: 84,
      },
      lab: {
        l: 76.08,
        a: 11.06,
        b: 54.98,
      },
      cmyk: null,
    },
    {
      code: '12-0826 TPG',
      name: 'Golden Haze',
      hex: 'FADA95',
      rgb: {
        r: 250,
        g: 218,
        b: 149,
      },
      lab: {
        l: 88.82,
        a: 4.89,
        b: 38.64,
      },
      cmyk: null,
    },
    {
      code: '12-0758 TPG',
      name: 'Yarrow',
      hex: 'F0CB76',
      rgb: {
        r: 240,
        g: 203,
        b: 118,
      },
      lab: {
        l: 83.87,
        a: 6.87,
        b: 47.16,
      },
      cmyk: null,
    },
    {
      code: '14-0848 TPG',
      name: 'Mimosa',
      hex: 'EBBF57',
      rgb: {
        r: 235,
        g: 191,
        b: 87,
      },
      lab: {
        l: 80.22,
        a: 8.09,
        b: 57.54,
      },
      cmyk: null,
    },
    {
      code: '13-0859 TPG',
      name: 'Lemon Chrome',
      hex: 'FFC600',
      rgb: {
        r: 255,
        g: 198,
        b: 0,
      },
      lab: {
        l: 84.48,
        a: 13.6,
        b: 87.25,
      },
      cmyk: null,
    },
    {
      code: '14-0957 TPG',
      name: 'Spectra Yellow',
      hex: 'FFC429',
      rgb: {
        r: 255,
        g: 196,
        b: 41,
      },
      lab: {
        l: 83.37,
        a: 13.54,
        b: 78.13,
      },
      cmyk: null,
    },
    {
      code: '13-0759 TPG',
      name: 'Solar Power',
      hex: 'F2C44D',
      rgb: {
        r: 242,
        g: 196,
        b: 77,
      },
      lab: {
        l: 81.94,
        a: 9.44,
        b: 63.23,
      },
      cmyk: null,
    },
    {
      code: '14-0951 TPG',
      name: 'Golden Rod',
      hex: 'E8AC32',
      rgb: {
        r: 232,
        g: 172,
        b: 50,
      },
      lab: {
        l: 74.96,
        a: 14.99,
        b: 67.77,
      },
      cmyk: null,
    },
    {
      code: '13-0840 TPG',
      name: 'Snapdragon',
      hex: 'FFD573',
      rgb: {
        r: 255,
        g: 213,
        b: 115,
      },
      lab: {
        l: 87.77,
        a: 7.24,
        b: 53.59,
      },
      cmyk: null,
    },
    {
      code: '13-0851 TPG',
      name: 'Minion YellowTM',
      hex: 'FFD662',
      rgb: {
        r: 255,
        g: 214,
        b: 98,
      },
      lab: {
        l: 88.15,
        a: 6.68,
        b: 61.59,
      },
      cmyk: null,
    },
    {
      code: '13-0850 TPG',
      name: 'Aspen Gold',
      hex: 'FED65E',
      rgb: {
        r: 254,
        g: 214,
        b: 94,
      },
      lab: {
        l: 87.75,
        a: 5.26,
        b: 62.8,
      },
      cmyk: null,
    },
    {
      code: '13-0849 TPG',
      name: 'Habañero Gold',
      hex: 'FFD653',
      rgb: {
        r: 255,
        g: 214,
        b: 83,
      },
      lab: {
        l: 87.86,
        a: 5.92,
        b: 67.6,
      },
      cmyk: null,
    },
    {
      code: '13-0758 TPG',
      name: 'Dandelion',
      hex: 'FFD31B',
      rgb: {
        r: 255,
        g: 211,
        b: 27,
      },
      lab: {
        l: 87.51,
        a: 7.41,
        b: 84.75,
      },
      cmyk: null,
    },
    {
      code: '13-0752 TPG',
      name: 'Lemon',
      hex: 'F0C62D',
      rgb: {
        r: 240,
        g: 198,
        b: 45,
      },
      lab: {
        l: 82.14,
        a: 5.71,
        b: 75.33,
      },
      cmyk: null,
    },
    {
      code: '14-0852 TPG',
      name: 'Freesia',
      hex: 'F3C337',
      rgb: {
        r: 243,
        g: 195,
        b: 55,
      },
      lab: {
        l: 81.75,
        a: 8.93,
        b: 71.93,
      },
      cmyk: null,
    },
    {
      code: '13-0754 TPG',
      name: 'Sunstruck',
      hex: 'F5CF51',
      rgb: {
        r: 245,
        g: 207,
        b: 81,
      },
      lab: {
        l: 85.08,
        a: 4.73,
        b: 65.04,
      },
      cmyk: null,
    },
    {
      code: '14-0849 TPG',
      name: 'Beach Ball',
      hex: 'F4C863',
      rgb: {
        r: 244,
        g: 200,
        b: 99,
      },
      lab: {
        l: 83.3,
        a: 8.79,
        b: 55.9,
      },
      cmyk: null,
    },
    {
      code: '14-0751 TPG',
      name: 'Daisy Daze',
      hex: 'EFC326',
      rgb: {
        r: 239,
        g: 195,
        b: 38,
      },
      lab: {
        l: 81.22,
        a: 7.16,
        b: 76.09,
      },
      cmyk: null,
    },
    {
      code: '13-0757 TPG',
      name: 'Calendula',
      hex: 'DFB13A',
      rgb: {
        r: 223,
        g: 177,
        b: 58,
      },
      lab: {
        l: 75.33,
        a: 9.72,
        b: 64.26,
      },
      cmyk: null,
    },
    {
      code: '14-0958 TPG',
      name: 'Bumblebee',
      hex: 'F0B902',
      rgb: {
        r: 240,
        g: 185,
        b: 2,
      },
      lab: {
        l: 78.97,
        a: 11.63,
        b: 80.69,
      },
      cmyk: null,
    },
    {
      code: '15-0956 TPG',
      name: 'Daylily',
      hex: 'EEAF0C',
      rgb: {
        r: 238,
        g: 175,
        b: 12,
      },
      lab: {
        l: 76.32,
        a: 16.25,
        b: 77.81,
      },
      cmyk: null,
    },
    {
      code: '15-0954 TPG',
      name: 'Symphonic Sunset',
      hex: 'DFA41E',
      rgb: {
        r: 223,
        g: 164,
        b: 30,
      },
      lab: {
        l: 72.07,
        a: 14.83,
        b: 70.66,
      },
      cmyk: null,
    },
    {
      code: '12-0737 TPG',
      name: 'Goldfinch',
      hex: 'F3DB74',
      rgb: {
        r: 243,
        g: 219,
        b: 116,
      },
      lab: {
        l: 87.99,
        a: 0.45,
        b: 52.7,
      },
      cmyk: null,
    },
    {
      code: '12-0736 TPG',
      name: 'Lemon Drop',
      hex: 'F5D777',
      rgb: {
        r: 245,
        g: 215,
        b: 119,
      },
      lab: {
        l: 87.24,
        a: 2.16,
        b: 51.19,
      },
      cmyk: null,
    },
    {
      code: '13-0755 TPG',
      name: 'Primrose Yellow',
      hex: 'F0CD5C',
      rgb: {
        r: 240,
        g: 205,
        b: 92,
      },
      lab: {
        l: 84.15,
        a: 3.36,
        b: 59.98,
      },
      cmyk: null,
    },
    {
      code: '13-0756 TPG',
      name: 'Lemon Zest',
      hex: 'F1D762',
      rgb: {
        r: 241,
        g: 215,
        b: 98,
      },
      lab: {
        l: 86.66,
        a: 0.37,
        b: 59.18,
      },
      cmyk: null,
    },
    {
      code: '14-0754 TPG',
      name: 'Super Lemon',
      hex: 'E5C049',
      rgb: {
        r: 229,
        g: 192,
        b: 73,
      },
      lab: {
        l: 79.69,
        a: 4.59,
        b: 63.1,
      },
      cmyk: null,
    },
    {
      code: '13-0746 TPG',
      name: 'Maize',
      hex: 'F0D14A',
      rgb: {
        r: 240,
        g: 209,
        b: 74,
      },
      lab: {
        l: 84.88,
        a: 1.53,
        b: 67.72,
      },
      cmyk: null,
    },
    {
      code: '14-0755 TPG',
      name: 'Sulphur',
      hex: 'E1BA36',
      rgb: {
        r: 225,
        g: 186,
        b: 54,
      },
      lab: {
        l: 77.7,
        a: 5.39,
        b: 67.89,
      },
      cmyk: null,
    },
    {
      code: '13-0760 TPG',
      name: 'Yellow Balloon',
      hex: 'F4D04F',
      rgb: {
        r: 244,
        g: 208,
        b: 79,
      },
      lab: {
        l: 85.07,
        a: 4.14,
        b: 65.96,
      },
      cmyk: null,
    },
    {
      code: '13-0749 TPG',
      name: 'Slicker',
      hex: 'F3D143',
      rgb: {
        r: 243,
        g: 209,
        b: 67,
      },
      lab: {
        l: 85.23,
        a: 2.84,
        b: 70.56,
      },
      cmyk: null,
    },
    {
      code: '13-0751 TPG',
      name: 'High Visibility',
      hex: 'F5CB00',
      rgb: {
        r: 245,
        g: 203,
        b: 0,
      },
      lab: {
        l: 83.93,
        a: 4.68,
        b: 84.38,
      },
      cmyk: null,
    },
    {
      code: '14-0750 TPG',
      name: 'Passion Fruit',
      hex: 'E5C633',
      rgb: {
        r: 229,
        g: 198,
        b: 51,
      },
      lab: {
        l: 81.07,
        a: 2.12,
        b: 71.37,
      },
      cmyk: null,
    },
    {
      code: '14-0757 TPG',
      name: 'Misted Marigold',
      hex: 'E8C338',
      rgb: {
        r: 232,
        g: 195,
        b: 56,
      },
      lab: {
        l: 80.62,
        a: 4.77,
        b: 69.88,
      },
      cmyk: null,
    },
    {
      code: '14-0758 TPG',
      name: 'Hot Spot',
      hex: 'D9B02A',
      rgb: {
        r: 217,
        g: 176,
        b: 42,
      },
      lab: {
        l: 74.27,
        a: 6.97,
        b: 68.41,
      },
      cmyk: null,
    },
    {
      code: '16-0748 TPG',
      name: 'Gold Flake',
      hex: 'C29927',
      rgb: {
        r: 194,
        g: 153,
        b: 39,
      },
      lab: {
        l: 66,
        a: 8.03,
        b: 61.8,
      },
      cmyk: null,
    },
    {
      code: '12-0738 TPG',
      name: 'Yellow Cream',
      hex: 'EED972',
      rgb: {
        r: 238,
        g: 217,
        b: 114,
      },
      lab: {
        l: 86.93,
        a: -1.4,
        b: 52.76,
      },
      cmyk: null,
    },
    {
      code: '13-0644 TPG',
      name: 'Golden Kiwi',
      hex: 'F7E14A',
      rgb: {
        r: 247,
        g: 225,
        b: 74,
      },
      lab: {
        l: 89.49,
        a: -2.89,
        b: 71.8,
      },
      cmyk: null,
    },
    {
      code: '12-0752 TPG',
      name: 'Buttercup',
      hex: 'F9E244',
      rgb: {
        r: 249,
        g: 226,
        b: 68,
      },
      lab: {
        l: 89.99,
        a: -3.06,
        b: 74.67,
      },
      cmyk: null,
    },
    {
      code: '12-0643 TPG',
      name: 'Blazing Yellow',
      hex: 'F6E63E',
      rgb: {
        r: 246,
        g: 230,
        b: 62,
      },
      lab: {
        l: 90.54,
        a: -6.08,
        b: 76.91,
      },
      cmyk: null,
    },
    {
      code: '14-0756 TPG',
      name: 'Empire Yellow',
      hex: 'F3D321',
      rgb: {
        r: 243,
        g: 211,
        b: 33,
      },
      lab: {
        l: 85.56,
        a: 0.67,
        b: 80.56,
      },
      cmyk: null,
    },
    {
      code: '14-0760 TPG',
      name: 'Cyber Yellow',
      hex: 'FDD20E',
      rgb: {
        r: 253,
        g: 210,
        b: 14,
      },
      lab: {
        l: 86.33,
        a: 4.82,
        b: 84.8,
      },
      cmyk: null,
    },
    {
      code: '13-0858 TPG',
      name: 'Vibrant Yellow',
      hex: 'FDDB27',
      rgb: {
        r: 253,
        g: 219,
        b: 39,
      },
      lab: {
        l: 88.59,
        a: 1.02,
        b: 82.04,
      },
      cmyk: null,
    },
    {
      code: '12-0735 TPG',
      name: 'Yellowtail',
      hex: 'EDDB6B',
      rgb: {
        r: 237,
        g: 219,
        b: 107,
      },
      lab: {
        l: 87.46,
        a: -3.04,
        b: 56.21,
      },
      cmyk: null,
    },
    {
      code: '13-0753 TPG',
      name: 'Quince',
      hex: 'EDD632',
      rgb: {
        r: 237,
        g: 214,
        b: 50,
      },
      lab: {
        l: 85.83,
        a: -3.07,
        b: 76.07,
      },
      cmyk: null,
    },
    {
      code: '13-0647 TPG',
      name: 'Illuminating',
      hex: 'F3DF4D',
      rgb: {
        r: 243,
        g: 223,
        b: 77,
      },
      lab: {
        l: 88.79,
        a: -3.64,
        b: 69.77,
      },
      cmyk: null,
    },
    {
      code: '14-0745 TPG',
      name: 'Incaberry',
      hex: 'E6C723',
      rgb: {
        r: 230,
        g: 199,
        b: 35,
      },
      lab: {
        l: 81.4,
        a: 0.51,
        b: 76.28,
      },
      cmyk: null,
    },
    {
      code: '15-0635 TPG',
      name: 'Snake Eye',
      hex: 'CEBC38',
      rgb: {
        r: 206,
        g: 188,
        b: 56,
      },
      lab: {
        l: 76.17,
        a: -3.42,
        b: 64.82,
      },
      cmyk: null,
    },
    {
      code: '15-0647 TPG',
      name: 'Yellow Jasmine',
      hex: 'D2B425',
      rgb: {
        r: 210,
        g: 180,
        b: 37,
      },
      lab: {
        l: 74.54,
        a: 1.27,
        b: 69.62,
      },
      cmyk: null,
    },
    {
      code: '16-0838 TPG',
      name: 'Mango Mint',
      hex: 'CFB42B',
      rgb: {
        r: 207,
        g: 180,
        b: 43,
      },
      lab: {
        l: 74.26,
        a: -0.29,
        b: 68.06,
      },
      cmyk: null,
    },
    {
      code: '12-0740 TPG',
      name: 'Limelight',
      hex: 'F1EA7F',
      rgb: {
        r: 241,
        g: 234,
        b: 127,
      },
      lab: {
        l: 91.7,
        a: -7.31,
        b: 51.62,
      },
      cmyk: null,
    },
    {
      code: '12-0742 TPG',
      name: 'Lemon Verbena',
      hex: 'F6EA7B',
      rgb: {
        r: 246,
        g: 234,
        b: 123,
      },
      lab: {
        l: 91.91,
        a: -5.2,
        b: 54,
      },
      cmyk: null,
    },
    {
      code: '12-0646 TPG',
      name: 'Celandine',
      hex: 'F0E366',
      rgb: {
        r: 240,
        g: 227,
        b: 102,
      },
      lab: {
        l: 89.5,
        a: -5.67,
        b: 60.43,
      },
      cmyk: null,
    },
    {
      code: '12-0642 TPG',
      name: 'Aurora',
      hex: 'F1DD61',
      rgb: {
        r: 241,
        g: 221,
        b: 97,
      },
      lab: {
        l: 88.14,
        a: -2.99,
        b: 61.67,
      },
      cmyk: null,
    },
    {
      code: '13-0640 TPG',
      name: 'Acacia',
      hex: 'DCD067',
      rgb: {
        r: 220,
        g: 208,
        b: 103,
      },
      lab: {
        l: 83.07,
        a: -4.66,
        b: 52.17,
      },
      cmyk: null,
    },
    {
      code: '13-0648 TPG',
      name: 'Green Sheen',
      hex: 'DDD257',
      rgb: {
        r: 221,
        g: 210,
        b: 87,
      },
      lab: {
        l: 83.29,
        a: -6.03,
        b: 59.62,
      },
      cmyk: null,
    },
    {
      code: '13-0646 TPG',
      name: 'Meadowlark',
      hex: 'ECDB53',
      rgb: {
        r: 236,
        g: 219,
        b: 83,
      },
      lab: {
        l: 87.15,
        a: -4.35,
        b: 65.5,
      },
      cmyk: null,
    },
    {
      code: '11-0615 TPG',
      name: 'Pear Sorbet',
      hex: 'EEE9C6',
      rgb: {
        r: 238,
        g: 233,
        b: 198,
      },
      lab: {
        l: 92.13,
        a: -1.89,
        b: 17.22,
      },
      cmyk: null,
    },
    {
      code: '11-0616 TPG',
      name: 'Pastel Yellow',
      hex: 'F1E3AD',
      rgb: {
        r: 241,
        g: 227,
        b: 173,
      },
      lab: {
        l: 90.5,
        a: -0.68,
        b: 28.37,
      },
      cmyk: null,
    },
    {
      code: '12-0715 TPG',
      name: 'Double Cream',
      hex: 'F7E4AC',
      rgb: {
        r: 247,
        g: 228,
        b: 172,
      },
      lab: {
        l: 91.15,
        a: 1.36,
        b: 29.75,
      },
      cmyk: null,
    },
    {
      code: '12-0722 TPG',
      name: 'French Vanilla',
      hex: 'EDDFA3',
      rgb: {
        r: 237,
        g: 223,
        b: 163,
      },
      lab: {
        l: 88.94,
        a: -0.97,
        b: 31.01,
      },
      cmyk: null,
    },
    {
      code: '12-0721 TPG',
      name: 'Lemonade',
      hex: 'F0E59A',
      rgb: {
        r: 240,
        g: 229,
        b: 154,
      },
      lab: {
        l: 90.56,
        a: -3.63,
        b: 37.46,
      },
      cmyk: null,
    },
    {
      code: '11-0622 TPG',
      name: 'Yellow Iris',
      hex: 'EEE892',
      rgb: {
        r: 238,
        g: 232,
        b: 146,
      },
      lab: {
        l: 91.03,
        a: -5.84,
        b: 41.8,
      },
      cmyk: null,
    },
    {
      code: '11-0620 TPG',
      name: 'Elfin Yellow',
      hex: 'EFEB9A',
      rgb: {
        r: 239,
        g: 235,
        b: 154,
      },
      lab: {
        l: 91.94,
        a: -6.57,
        b: 39.15,
      },
      cmyk: null,
    },
    {
      code: '12-0720 TPG',
      name: 'Mellow Yellow',
      hex: 'EEDC9C',
      rgb: {
        r: 238,
        g: 220,
        b: 156,
      },
      lab: {
        l: 88.24,
        a: -0.18,
        b: 34.11,
      },
      cmyk: null,
    },
    {
      code: '12-0824 TPG',
      name: 'Pale Banana',
      hex: 'F5E096',
      rgb: {
        r: 245,
        g: 224,
        b: 150,
      },
      lab: {
        l: 89.95,
        a: 0.03,
        b: 39.16,
      },
      cmyk: null,
    },
    {
      code: '12-0711 TPG',
      name: 'Lemon Meringue',
      hex: 'EEE29C',
      rgb: {
        r: 238,
        g: 226,
        b: 156,
      },
      lab: {
        l: 89.73,
        a: -2.24,
        b: 35.2,
      },
      cmyk: null,
    },
    {
      code: '12-0825 TPG',
      name: 'Popcorn',
      hex: 'F7E08F',
      rgb: {
        r: 247,
        g: 224,
        b: 143,
      },
      lab: {
        l: 89.91,
        a: 0.26,
        b: 42.97,
      },
      cmyk: null,
    },
    {
      code: '12-0718 TPG',
      name: 'Pineapple Slice',
      hex: 'E6D594',
      rgb: {
        r: 230,
        g: 213,
        b: 148,
      },
      lab: {
        l: 85.54,
        a: 0.33,
        b: 33.69,
      },
      cmyk: null,
    },
    {
      code: '12-0729 TPG',
      name: 'Sundress',
      hex: 'E7CF8F',
      rgb: {
        r: 231,
        g: 207,
        b: 143,
      },
      lab: {
        l: 84.21,
        a: 3.32,
        b: 34.73,
      },
      cmyk: null,
    },
    {
      code: '12-0727 TPG',
      name: 'Sunshine',
      hex: 'F9DF83',
      rgb: {
        r: 249,
        g: 223,
        b: 131,
      },
      lab: {
        l: 89.61,
        a: 0.92,
        b: 48.35,
      },
      cmyk: null,
    },
    {
      code: '11-0510 TPG',
      name: 'Afterglow',
      hex: 'F5EBD0',
      rgb: {
        r: 245,
        g: 235,
        b: 208,
      },
      lab: {
        l: 93.42,
        a: 0.38,
        b: 14.3,
      },
      cmyk: null,
    },
    {
      code: '11-0619 TPG',
      name: 'Flan',
      hex: 'EAE0B7',
      rgb: {
        r: 234,
        g: 224,
        b: 183,
      },
      lab: {
        l: 89.23,
        a: -0.44,
        b: 21.03,
      },
      cmyk: null,
    },
    {
      code: '12-0717 TPG',
      name: 'Anise Flower',
      hex: 'ECE3BA',
      rgb: {
        r: 236,
        g: 227,
        b: 186,
      },
      lab: {
        l: 90.17,
        a: -1.04,
        b: 20.75,
      },
      cmyk: null,
    },
    {
      code: '13-0822 TPG',
      name: 'Sunlight',
      hex: 'F0DAA4',
      rgb: {
        r: 240,
        g: 218,
        b: 164,
      },
      lab: {
        l: 88.03,
        a: 2.38,
        b: 29.38,
      },
      cmyk: null,
    },
    {
      code: '13-0917 TPG',
      name: 'Italian Straw',
      hex: 'EAD4A2',
      rgb: {
        r: 234,
        g: 212,
        b: 162,
      },
      lab: {
        l: 85.9,
        a: 2.93,
        b: 27.79,
      },
      cmyk: null,
    },
    {
      code: '13-0922 TPG',
      name: 'Straw',
      hex: 'E3CA92',
      rgb: {
        r: 227,
        g: 202,
        b: 146,
      },
      lab: {
        l: 82.74,
        a: 3.25,
        b: 31.45,
      },
      cmyk: null,
    },
    {
      code: '14-0935 TPG',
      name: 'Jojoba',
      hex: 'DEC283',
      rgb: {
        r: 222,
        g: 194,
        b: 131,
      },
      lab: {
        l: 80,
        a: 4.07,
        b: 35.46,
      },
      cmyk: null,
    },
    {
      code: '12-0812 TPG',
      name: 'Alabaster Gleam',
      hex: 'EFDFBC',
      rgb: {
        r: 239,
        g: 223,
        b: 188,
      },
      lab: {
        l: 89.51,
        a: 1.9,
        b: 19.22,
      },
      cmyk: null,
    },
    {
      code: '12-0806 TPG',
      name: 'Rutabaga',
      hex: 'ECE0BD',
      rgb: {
        r: 236,
        g: 224,
        b: 189,
      },
      lab: {
        l: 89.62,
        a: 0.12,
        b: 18.76,
      },
      cmyk: null,
    },
    {
      code: '13-0815 TPG',
      name: 'Banana Crepe',
      hex: 'E6D3A6',
      rgb: {
        r: 230,
        g: 211,
        b: 166,
      },
      lab: {
        l: 85.34,
        a: 2.23,
        b: 25.03,
      },
      cmyk: null,
    },
    {
      code: '12-0910 TPG',
      name: "Lamb's Wool",
      hex: 'EAD4B3',
      rgb: {
        r: 234,
        g: 212,
        b: 179,
      },
      lab: {
        l: 86.14,
        a: 4.93,
        b: 19.13,
      },
      cmyk: null,
    },
    {
      code: '13-0814 TPG',
      name: 'Summer Melon',
      hex: 'EAD6AF',
      rgb: {
        r: 234,
        g: 214,
        b: 175,
      },
      lab: {
        l: 86.77,
        a: 2.92,
        b: 22.24,
      },
      cmyk: null,
    },
    {
      code: '12-0714 TPG',
      name: 'Cornhusk',
      hex: 'F4DAB2',
      rgb: {
        r: 244,
        g: 218,
        b: 178,
      },
      lab: {
        l: 88.7,
        a: 4.66,
        b: 23.83,
      },
      cmyk: null,
    },
    {
      code: '12-0822 TPG',
      name: 'Golden Fleece',
      hex: 'F6D09D',
      rgb: {
        r: 246,
        g: 208,
        b: 157,
      },
      lab: {
        l: 86.17,
        a: 8.72,
        b: 31.17,
      },
      cmyk: null,
    },
    {
      code: '11-0515 TPG',
      name: 'Lemon Icing',
      hex: 'F4EBC8',
      rgb: {
        r: 244,
        g: 235,
        b: 200,
      },
      lab: {
        l: 93.25,
        a: -0.7,
        b: 18.36,
      },
      cmyk: null,
    },
    {
      code: '12-0804 TPG',
      name: 'Cloud Cream',
      hex: 'E7DDC3',
      rgb: {
        r: 231,
        g: 221,
        b: 195,
      },
      lab: {
        l: 88.53,
        a: 0.23,
        b: 14.04,
      },
      cmyk: null,
    },
    {
      code: '12-0713 TPG',
      name: 'Almond Oil',
      hex: 'F1E5BE',
      rgb: {
        r: 241,
        g: 229,
        b: 190,
      },
      lab: {
        l: 91.16,
        a: 0.48,
        b: 20.24,
      },
      cmyk: null,
    },
    {
      code: '12-0815 TPG',
      name: 'Vanilla Custard',
      hex: 'F0E1B9',
      rgb: {
        r: 240,
        g: 225,
        b: 185,
      },
      lab: {
        l: 90.18,
        a: 0.67,
        b: 21.68,
      },
      cmyk: null,
    },
    {
      code: '12-0712 TPG',
      name: 'Vanilla',
      hex: 'F4E4C4',
      rgb: {
        r: 244,
        g: 228,
        b: 196,
      },
      lab: {
        l: 91.44,
        a: 1.74,
        b: 18.07,
      },
      cmyk: null,
    },
    {
      code: '13-0916 TPG',
      name: 'Chamomile',
      hex: 'EAD4A6',
      rgb: {
        r: 234,
        g: 212,
        b: 166,
      },
      lab: {
        l: 86,
        a: 2.99,
        b: 26.09,
      },
      cmyk: null,
    },
    {
      code: '12-0817 TPG',
      name: 'Apricot Gelato',
      hex: 'F0D4A4',
      rgb: {
        r: 240,
        g: 212,
        b: 164,
      },
      lab: {
        l: 86.63,
        a: 5.05,
        b: 28.19,
      },
      cmyk: null,
    },
    {
      code: '11-0108 TPG',
      name: 'Solitary Star',
      hex: 'F0EBDA',
      rgb: {
        r: 240,
        g: 235,
        b: 218,
      },
      lab: {
        l: 93.17,
        a: -0.2,
        b: 8.67,
      },
      cmyk: null,
    },
    {
      code: '11-0202 TPG',
      name: "Baby's Breath",
      hex: 'EBE6D5',
      rgb: {
        r: 235,
        g: 230,
        b: 213,
      },
      lab: {
        l: 91.22,
        a: -0.01,
        b: 8.76,
      },
      cmyk: null,
    },
    {
      code: '11-0507 TPG',
      name: 'Winter White',
      hex: 'F1EAD4',
      rgb: {
        r: 241,
        g: 234,
        b: 212,
      },
      lab: {
        l: 92.9,
        a: -0.23,
        b: 11.69,
      },
      cmyk: null,
    },
    {
      code: '11-0107 TPG',
      name: 'Papyrus',
      hex: 'F1ECD7',
      rgb: {
        r: 241,
        g: 236,
        b: 215,
      },
      lab: {
        l: 93.44,
        a: -0.7,
        b: 10.7,
      },
      cmyk: null,
    },
    {
      code: '11-0106 TPG',
      name: 'Sweet Corn',
      hex: 'F2EDD7',
      rgb: {
        r: 242,
        g: 237,
        b: 215,
      },
      lab: {
        l: 93.79,
        a: -0.83,
        b: 11.25,
      },
      cmyk: null,
    },
    {
      code: '12-0602 TPG',
      name: 'Arctic Wolf',
      hex: 'E6DFD1',
      rgb: {
        r: 230,
        g: 223,
        b: 209,
      },
      lab: {
        l: 89.25,
        a: 0.96,
        b: 7.84,
      },
      cmyk: null,
    },
    {
      code: '12-0908 TPG',
      name: 'Summer Sand',
      hex: 'D9CEBA',
      rgb: {
        r: 217,
        g: 206,
        b: 186,
      },
      lab: {
        l: 83.22,
        a: 2.08,
        b: 11.12,
      },
      cmyk: null,
    },
    {
      code: '13-1012 TPG',
      name: 'Frosted Almond',
      hex: 'D9C4AD',
      rgb: {
        r: 217,
        g: 196,
        b: 173,
      },
      lab: {
        l: 80.48,
        a: 5.46,
        b: 14.45,
      },
      cmyk: null,
    },
    {
      code: '14-1012 TPG',
      name: 'Gilded Beige',
      hex: 'C3A98D',
      rgb: {
        r: 195,
        g: 169,
        b: 141,
      },
      lab: {
        l: 71.19,
        a: 6.88,
        b: 18.37,
      },
      cmyk: null,
    },
    {
      code: '15-0927 TPG',
      name: 'Pale Gold',
      hex: 'C79B69',
      rgb: {
        r: 199,
        g: 155,
        b: 105,
      },
      lab: {
        l: 67.69,
        a: 13.2,
        b: 32.74,
      },
      cmyk: null,
    },
    {
      code: '16-0836 TPG',
      name: 'Rich Gold',
      hex: 'BCA360',
      rgb: {
        r: 188,
        g: 163,
        b: 96,
      },
      lab: {
        l: 68.36,
        a: 3.51,
        b: 38.1,
      },
      cmyk: null,
    },
    {
      code: '16-1325 TPG',
      name: 'Copper',
      hex: 'BF7D5C',
      rgb: {
        r: 191,
        g: 125,
        b: 92,
      },
      lab: {
        l: 59.5,
        a: 24.85,
        b: 29.89,
      },
      cmyk: null,
    },
    {
      code: '18-1537 TPG',
      name: 'Copper Coin',
      hex: 'B1634E',
      rgb: {
        r: 177,
        g: 99,
        b: 78,
      },
      lab: {
        l: 51.3,
        a: 31.75,
        b: 27.21,
      },
      cmyk: null,
    },
    {
      code: '14-5002 TPG',
      name: 'Silver',
      hex: 'AAABA8',
      rgb: {
        r: 170,
        g: 171,
        b: 168,
      },
      lab: {
        l: 69.79,
        a: -0.55,
        b: 1.02,
      },
      cmyk: null,
    },
  ],
};
