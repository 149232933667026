import { httpClient } from '../httpClient/httpClient';
import { AutoUpdateRequest, AutoUpdateResponse } from './autoUpdateTypes';
import { SalesCurrency } from './salesCurrenciesApi';

export interface AutoUpdateSalesInformationRequest {
  notes: string;
  isReady?: boolean;
  syncPriceLists?: boolean;
}

export interface SalesInformation {
  id: number;
  variantId: number;
  branchId: number;
  vatCodeId: number;
  moq: number;
  notes: string;
  currencies: SalesCurrency[];
  isReady: boolean;
  syncedOn?: Date;
  syncPriceLists: boolean;
  priceListsSyncedOn?: Date;
  isEdited: boolean;
}

export const salesInformationApi = {
  getSalesInformations: (variantId: number) =>
    httpClient.get<SalesInformation[]>(
      `salesinformations/variant/${variantId}`,
    ),

  autoUpdateSalesInformations: (
    salesInformationId: number,
    request: AutoUpdateRequest<AutoUpdateSalesInformationRequest>,
  ) =>
    httpClient.post<AutoUpdateResponse<SalesInformation>>(
      `salesinformations/auto/${salesInformationId}`,
      { data: request },
    ),
};
