// @ngInject
const orderEmpty = () => (array, key, type) => {
  if (!angular.isArray(array)) {
    return;
  }

  const present = array.filter((item) => item[key]);
  const empty = array.filter((item) => !item[key]);

  switch (type) {
    case 'toBottom':
      return present.concat(empty);
    case 'toTop':
      return empty.concat(present);
    default:
      return array;
  }
};

angular.module('orderEmpty', []).filter('orderEmpty', orderEmpty);
