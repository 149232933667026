import type {
  IAugmentedJQuery,
  IComponentOptions,
  IController,
  IOnChangesObject,
} from 'angular';
import React from 'react';
import {
  addElement,
  ReactElementWithContainer,
  removeElement,
} from './bridgeManager';

export function bridgeConverter<TProps extends Record<any, any>>(
  reactComponent: React.ComponentType<TProps>,
  propNames: (keyof TProps)[],
): IComponentOptions {
  const bindings = Object.fromEntries(propNames.map((prop) => [prop, '<']));

  return {
    bindings,
    controller: [
      '$element',
      class implements IController {
        private container?: HTMLElement;
        private reactElement?: ReactElementWithContainer;
        private reactElementRef?: React.RefObject<ComponentWrapperRef> =
          React.createRef();
        private bindings?: TProps;

        constructor($element: IAugmentedJQuery) {
          this.container = $element[0];
        }

        public $onChanges(onChangesObj: IOnChangesObject) {
          const currentBindings = (this.bindings ?? {}) as TProps;

          const newBindings = Object.fromEntries(
            Object.entries(onChangesObj).map(
              ([bindingName, { currentValue }]) => [bindingName, currentValue],
            ),
          ) as Partial<TProps>;

          this.bindings = {
            ...currentBindings,
            ...newBindings,
          };

          this.updateProps();
        }

        public $onInit() {
          this.reactElement = {
            container: this.container!,
            element: React.createElement(ComponentWrapper, {
              component: reactComponent,
              initialProps: this.bindings!,
              ref: this.reactElementRef,
            }),
          };
          addElement(this.reactElement);
        }

        public $onDestroy() {
          if (this.reactElement) {
            removeElement(this.reactElement);
          }
          this.reactElement = undefined;
          this.reactElementRef = undefined;
          this.container = undefined;
        }

        private updateProps() {
          this.reactElementRef?.current?.updateProps(this.bindings);
        }
      },
    ],
  };
}

interface ComponentWrapperRef {
  updateProps(newProps: any): void;
}

interface ComponentWrapperProps {
  component: React.ComponentType<any>;
  initialProps: Record<any, any>;
}

const ComponentWrapper = React.forwardRef(function ComponentWrapper(
  { component: Component, initialProps }: ComponentWrapperProps,
  ref: React.Ref<ComponentWrapperRef>,
) {
  const [props, setProps] = React.useState<Record<any, any>>(initialProps);

  React.useImperativeHandle(ref, () => ({
    updateProps(newProps: Record<any, any>) {
      setProps(newProps);
    },
  }));

  return <Component {...props} />;
});
