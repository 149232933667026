// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('tasks', {
    parent: 'main',
    url: '/tasks',
    views: {
      'main-content': {
        controller: 'TasksController as vm',
        templateUrl: 'main/tasks/tasks.tpl.html',
      },
    },
    data: { pageTitle: 'Tasks' },
  });
};

class TasksController {
  constructor($scope, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;

    this.contentTabs = [
      {
        active: $state.is('tasks'),
        heading: 'Assigned',
        route: 'tasks.assigned',
      },
      {
        heading: 'Declined',
        route: 'tasks.declined',
      },
      {
        heading: 'Delegated',
        route: 'tasks.delegated',
      },
    ];

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('tasks')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.contentTabs, { active: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module('main.tasks', [
    'main.tasks.assigned',
    'main.tasks.declined',
    'main.tasks.delegated',
  ])
  .config(config)
  .controller('TasksController', TasksController);
