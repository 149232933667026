// @ngInject
const customerCurrencies = () => ({
  templateUrl: 'directives/customerCurrencies/customerCurrencies.tpl.html',
  restrict: 'EA',
  scope: {},
  bindToController: {
    currencies: '=',
    defaultCurrency: '=',
    useDefaultCurrency: '=',
    defaultCurrencyIsMain: '=',
    currentBranchData: '=',
    setMainCurrency: '&',
    setUseCustomSellingRate: '&',
    removeCurrency: '&',
    setDefaultCurrencyAsMain: '&',
    removeDefaultCurrency: '&',
    addDefaultCurrency: '&',
  },
  controller: 'CustomerCurrenciesController',
  controllerAs: 'vm',
});

class CustomerCurrenciesController {}

angular
  .module('directives.customerCurrencies', [])
  .controller('CustomerCurrenciesController', CustomerCurrenciesController)
  .directive('customerCurrencies', customerCurrencies);
