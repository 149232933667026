// @ngInject
const branchSelector = () => ({
  templateUrl: 'directives/branchSelector/branchSelector.tpl.html',
  restrict: 'E',

  scope: {
    selectedBranch: '=',
    onSelectBranch: '&',
    isReady: '&?',
    branches: '=',
    alwaysVisible: '=',
  },
});

angular
  .module('directives.branchSelector', [])
  .directive('branchSelector', branchSelector);
