// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('tasks.delegated', {
    url: '/delegated',
    templateUrl: 'main/tasks/delegated/delegated.tpl.html',
    controller: 'DelegatedTasksController as vm',
    data: { pageTitle: 'Tasks' },
  });
};

class DelegatedTasksController {
  constructor(
    $scope,
    $filter,
    DS,
    toasterService,
    loadSpinnerService,
    UsersApiService,
    TasksApiService,
    HelperService,
    currentUser,
  ) {
    'ngInject';

    this.$filter = $filter;
    this.DS = DS;
    this.toasterService = toasterService;
    this.loadSpinnerService = loadSpinnerService;
    this.TasksApiService = TasksApiService;
    this.UsersApiService = UsersApiService;
    this.HelperService = HelperService;
    this.currentUser = currentUser;

    this.selectedTask = {};
    this.tasks = [];
    this.articles = [];
    this.assignedUsers = [];
    this.selectedArticle = null;
    this.selectedAssignedUser = null;
    this.tasksCount = 0;
    this.page = 1;
    this.pageSizes = [10, 15, 50, 100];
    this.pageSize = 50;
    this.onPaginate = (page, pageSize) => {
      this.page = page;
      this.pageSize = pageSize;
      this.selectTask(null);
      this.loadTasks();
    };

    this.loadTasks();
  }

  loadTasks() {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.getCreatedByUserId(this.currentUser.id, {
      page: this.page,
      limit: this.pageSize,
      articleId: this.selectedArticle ? this.selectedArticle.id : 0,
      assignedUserId: this.selectedAssignedUser
        ? this.selectedAssignedUser.id
        : 0,
    })
      .then(({ items, totalItems, filters: { articles, assignedUsers } }) => {
        this.tasks = items;
        this.tasksCount = totalItems;
        this.articles = articles;
        this.assignedUsers = assignedUsers;
      })
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  filterArticles(query) {
    return _.orderBy(
      _.filter(this.articles, (a) =>
        this.HelperService.containsText(a, { name: query, itemCode: query }),
      ),
      'itemCode',
    );
  }

  assignedUserSearch(query) {
    return _.orderBy(
      _.filter(this.assignedUsers, (u) => {
        if (_.isEmpty(query)) {
          return true;
        }
        return _.includes(
          _.toLower(`${u.firstName} ${u.lastName}`),
          _.toLower(query),
        );
      }),
      'firstName',
    );
  }

  selectTask(task) {
    if (!task) {
      this.selectedTask = {};
      return;
    }
    if (task.id === this.selectedTask.id) {
      return;
    }

    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.getById(task.id, {
      includeRelations: ['creator', '_taskUsers.user'],
    })
      .then((task) => (this.selectedTask = task))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  deleteTask() {
    this.selectTask(null);
  }

  toggleDoneStatus(task) {
    this.loadSpinnerService.start('mainSpinner');
    this.TasksApiService.updateTask(task.id, { isDone: task.isDone })
      .then(() => this.toasterService.success())
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  selectedArticleChanged() {
    this.selectTask(null);
    this.loadTasks();
  }

  selectedAssignedUserChanged() {
    this.selectTask(null);
    this.loadTasks();
  }
}

angular
  .module('main.tasks.delegated', [])
  .config(config)
  .controller('DelegatedTasksController', DelegatedTasksController);
