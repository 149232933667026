import { Box } from '@chakra-ui/react';
import { WizardSummary } from '../summary/WizardSummary';
import { useEffect } from 'react';
import { GeneratedProductApprovalState } from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';

export function Confirm({ page }: { page: string }) {
  const { data, setStepIsValid } = useProdutApprovalWizardState();

  useEffect(() => {
    if (
      data.generatedProductApprovalKeys.length > 0 &&
      data.generatedProductApprovalKeys.filter(
        (key) =>
          data.generatedProductApprovals[key].state ===
          GeneratedProductApprovalState.Unhandled,
      ).length === 0 &&
      data.generatedProductApprovalKeys.filter(
        (key) =>
          data.generatedProductApprovals[key].state ===
          GeneratedProductApprovalState.Accepted,
      ).length > 0
    ) {
      setStepIsValid(page, true);
      return;
    }

    setStepIsValid(page, false);
  }, [
    data.generatedProductApprovalKeys,
    data.generatedProductApprovals,
    setStepIsValid,
    page,
  ]);

  return (
    <Box px={4}>
      <WizardSummary />
    </Box>
  );
}
