import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
} from '@chakra-ui/react';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';

export function OptionMenu({
  fileId,
  onOpen,
  openSelectFile,
  onEditGroupOpen,
  onFileBrowserOpen,
  onRemoveFileOpen,
}: {
  fileId: number | undefined;
  onOpen: () => void;
  openSelectFile: () => void;
  onEditGroupOpen: () => void;
  onFileBrowserOpen: () => void;
  onRemoveFileOpen: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Menu placement="right">
      <MenuButton
        as={IconButton}
        size="sm"
        variant="ghost"
        onClick={(e) => e.stopPropagation()}
        icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
      />
      <MenuList color="white">
        <MenuItem
          icon={<Icons.NoteEdit boxSize={defaultIconSize} />}
          onClick={(e) => {
            e.stopPropagation();
            onEditGroupOpen();
          }}
        >
          {t('composition.editNote')}
        </MenuItem>
        <MenuItem
          icon={<Icons.ContentCopy boxSize={defaultIconSize} />}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          {t('composition.copyIntoNewOption')}
        </MenuItem>
        <MenuDivider />
        <MenuGroup title={t('composition.files.layout')}>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              openSelectFile();
            }}
            icon={
              fileId ? (
                <Icons.FileReplaceOutline boxSize={defaultIconSize} />
              ) : (
                <Icons.Upload boxSize={defaultIconSize} />
              )
            }
          >
            {fileId
              ? t('fileBrowser.uploadAndReplaceLayout')
              : t('fileBrowser.uploadLayout')}
          </MenuItem>
          <MenuItem
            icon={<Icons.OpenInNew boxSize={defaultIconSize} />}
            onClick={(e) => {
              e.stopPropagation();
              onFileBrowserOpen();
            }}
          >
            {t('fileBrowser.chooseFromLibrary')}
          </MenuItem>
          {fileId && (
            <MenuItem
              icon={<Icons.TrashOutline boxSize={defaultIconSize} />}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveFileOpen();
              }}
            >
              {t('fileBrowser.removeLayout')}
            </MenuItem>
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
