import { reactEvents } from '../../bridge/reactEvents';

// @ngInject
const setupBridgeEvents = (
  AuthService,
  ExportArticleExcelDialogService,
  UserDialogService,
  $state,
) => {
  // Show login modal when we receive unauthorized response
  reactEvents.unauthorized.subscribe(() => AuthService.showLogin());

  reactEvents.excelExportClicked.subscribe((exportArticleEvent) => {
    AuthService.getUser().then((u) => {
      ExportArticleExcelDialogService.showDialog({
        selectedArticles: exportArticleEvent.articles,
        branchIds: exportArticleEvent.branchIds,
        organization: { id: u.organizationId },
        hideBranchIds: exportArticleEvent.hideBranchIds,
        selectedTemplate: exportArticleEvent.template,
      });
    });
  });

  reactEvents.routingChanged.subscribe((path) => {
    $state.go(path.to, path.navObj);
  });

  reactEvents.accountConfigurationClicked.subscribe(() => {
    AuthService.getUser().then((u) => {
      UserDialogService.showDialog({
        user: u,
        isEditingSelf: true,
      });
    });
  });
  reactEvents.signedOut.subscribe(() => {
    AuthService.logout();
  });
};

angular.module('app.bridgeEvents', []).run(setupBridgeEvents);
