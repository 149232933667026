import {
  ArticleProcess,
  VariantsSearchQuery,
} from '@texas/api/endpoints/searchApi';
import { FilterOptionType } from '../../filter/types';
import { ArticleSearchProps, FilterOptions } from '../../searchArticles/types';
import { defaultEmptyFilters } from '../../searchArticles/useSearchArticles';
import { defaultPage } from './defaultWidgetsOptions';

const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: '',
  sortDesc: false,
  page: defaultPage,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: false,
};

export const defaultKickOffWidgetOptions = (
  customerId: number | undefined,
  conceptId: number | undefined,
) => {
  return widgetKickOffOptions(customerId, conceptId);
};

function widgetKickOffOptions(
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  if (customerId) {
    return brandsKickOffOptions(customerId);
  } else if (conceptId) {
    return conceptKickOffOptions(conceptId);
  }

  throw Error('Customer or concept properties not declared');
}

const brandsKickOffOptions = (customerId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Customers, FilterOptionType.Processes],
    searchParams: {
      ...defaultSearchParams,
      customerIds: [customerId],
      processes: [ArticleProcess.KickOff],
    },
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Processes]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};

const conceptKickOffOptions = (conceptId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Concepts, FilterOptionType.Processes],
    searchParams: {
      ...defaultSearchParams,
      conceptIds: [conceptId],
      processes: [ArticleProcess.KickOff],
    },
    view: 'table',
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Concepts]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Processes]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};
