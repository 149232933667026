// @ngInject
const PollerService = ($interval, AuthService) => {
  const pollers = [];

  class Poller {
    constructor(target, onSuccess, onError) {
      this.$interval = $interval;
      this.AuthService = AuthService;
      this.target = target;
      this.onError = onError || _.noop;
      this.onSuccess = onSuccess || _.noop;
      this.isWaiting = false;
      this.delay = 45000;
    }

    start() {
      this.poll();
      this.$interval(() => this.poll(), this.delay);
    }

    poll() {
      if (this.isWaiting || !this.AuthService.isAuthenticated()) {
        return;
      }
      this.isWaiting = true;
      this.target()
        .then(
          (data) => this.onSuccess(data),
          (error) => this.onError(error),
        )
        .finally(() => {
          this.isWaiting = false;
        });
    }
  }

  return {
    get: (target, onSuccess, onError) => {
      let poller = _.find(pollers, { target });

      if (!poller) {
        poller = new Poller(target, onSuccess, onError);
        pollers.push(poller);
        poller.start();
      }

      return poller;
    },
  };
};

angular.module('services.poller', []).factory('PollerService', PollerService);
