// @ngInject
const technicalFieldsResolve = (variant, ArticlesApiService) =>
  ArticlesApiService.getVariantTechnicalFields(variant.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.technical', {
    url: '/technical',
    templateUrl: 'main/article/variant/technical/technical.tpl.html',
    controller: 'VariantTechnicalController as vm',
    resolve: {
      technicalFields: technicalFieldsResolve,
    },
  });
};

class VariantTechnicalController {
  constructor(
    $scope,
    variant,
    article,
    technicalFields,
    HelperService,
    AuthService,
    ArticlesApiService,
    loadSpinnerService,
    toasterService,
    SavingService,
    GarpApiService,
  ) {
    'ngInject';

    this.HelperService = HelperService;
    this.ArticlesApiService = ArticlesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
    this.GarpApiService = GarpApiService;

    this.variant = variant;
    this.article = article;
    this.filteredFields = _.filter(technicalFields, { archived: false });
    this.validationErrors = [];
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
      'agent',
    ]);
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(),
      onDiscard: () => this.discard(),
      onValidate: () => this.validate(),
    });
    this.setTechnicalFieldsClone();

    $scope.$watch(
      () => !_.isEmpty(this.getChangedData()),
      (hasChanges) => {
        if (!hasChanges) {
          this.variantTechnicalForm.$setPristine(true);
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    $scope.$on('$destroy', () => {
      SavingService.deregisterSave(this.saveObj);
    });
  }

  setTechnicalFieldsClone() {
    this.technicalFields = _.map(this.filteredFields, _.clone);
  }

  validate() {
    if (!this.variantTechnicalForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  getChangedData() {
    const changedData = [];

    _.forEach(this.filteredFields, (field) => {
      const localField = _.find(this.technicalFields, { id: field.id });
      const changedField = this.HelperService.getChangedData(
        field,
        localField,
        ['checked', 'note'],
      );

      if (!_.isEmpty(changedField)) {
        changedField.id = field.id;
        changedData.push(changedField);
      }
    });

    return changedData;
  }

  save(cb) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateTechnicalInfo(
      this.getChangedData(),
      this.variant.id,
    )
      .then(
        () => {
          this.filteredFields = this.technicalFields;
          this.setTechnicalFieldsClone();
          if (cb) {
            cb();
          } else {
            this.toasterService.success();
          }
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  discard() {
    this.validationErrors = [];
    this.setTechnicalFieldsClone();
  }
}

angular
  .module('main.article.variant.technical', [])
  .config(config)
  .controller('VariantTechnicalController', VariantTechnicalController);
