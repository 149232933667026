class PurchaseInfoDialogService {
  constructor($mdDialog) {
    'ngInject';

    this.$mdDialog = $mdDialog;
  }

  showDialog(data) {
    data = data || {};
    data.variantId = data.variantId || null;
    data.branchId = data.branchId || null;
    data.suppliers = data.suppliers || [];

    const options = {
      controller: 'PurchaseInfoDialogController as vm',
      templateUrl:
        'services/dialogs/purchaseInfoDialog/purchaseInfoDialog.tpl.html',
      locals: {
        variantId: data.variantId,
        branchId: data.branchId,
        suppliers: data.suppliers,
      },
    };

    return this.$mdDialog.show(options);
  }
}

class PurchaseInfoDialogController {
  constructor(
    $q,
    $mdDialog,
    toasterService,
    ArticlesApiService,
    HelperService,
    variantId,
    branchId,
    suppliers,
  ) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.toasterService = toasterService;
    this.variantId = variantId;
    this.branchId = branchId;
    this.suppliers = suppliers;
    this.ArticlesApiService = ArticlesApiService;
    this.HelperService = HelperService;

    this.validationErrors = [];
  }

  save() {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();
    if (!this.supplier) {
      this.validationErrors.push({ message: 'Please select a supplier' });
      this.$mdDialog.stopLoadingSpinner();
      return;
    }
    this.ArticlesApiService.newVariantPurchaseInfo(
      { branchId: this.branchId, supplierId: this.supplier.id },
      this.variantId,
    )
      .then(
        (newPurchase) => {
          this.toasterService.success();
          this.$mdDialog.hide(newPurchase);
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  filterSuppliers(query) {
    return _.orderBy(
      _.filter(this.suppliers, (s) => {
        const supplierData = {
          name: s.name,
          number: this.getCurrentSupplierNumber(s),
        };
        return this.HelperService.containsText(supplierData, {
          name: query,
          number: query,
        });
      }),
      'name',
    );
  }

  getCurrentSupplierNumber(supplier) {
    if (!supplier) {
      return '';
    }
    const branchData = _.find(supplier.branchData, { branchId: this.branchId });
    return branchData ? branchData.supplierNumber : '';
  }
}

angular
  .module('services.dialogs.purchaseInfoDialog', [])
  .service('PurchaseInfoDialogService', PurchaseInfoDialogService)
  .controller('PurchaseInfoDialogController', PurchaseInfoDialogController);
