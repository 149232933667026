export const maxSize = 20000000;

export function sizeValidator(file: File) {
  if (file.size > maxSize) {
    return {
      code: 'file-too-large',
      message: `File size is too large (limit: 20mb)`,
    };
  }

  return null;
}

export function notImageValidator(file: File) {
  if (file.type.includes('image/')) {
    return {
      code: 'wrong-file-type',
      message: `Can not be file type image.`,
    };
  }

  return null;
}
