import { useOutsideClick } from '@chakra-ui/react';
import { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';
export enum ModalState {
  Open,
  Closed,
  Peek,
}

export function reverseModalState(state: ModalState) {
  switch (state) {
    case ModalState.Closed:
      return ModalState.Open;
    case ModalState.Open:
      return ModalState.Closed;
    case ModalState.Peek:
      return ModalState.Open;
  }
}

export interface Modal<T> {
  ref: RefObject<T>;
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

export default function useChakraOutsideClick<T extends HTMLElement>(
  initialState: ModalState,
  peekClose?: boolean,
): Modal<T> {
  const ref = useRef<T>(null);
  const [modalState, setModalState] = useState(initialState);
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (modalState === ModalState.Closed) return;

      setModalState(peekClose ? ModalState.Peek : ModalState.Closed);
    },
  });

  return { ref, modalState, setModalState };
}
