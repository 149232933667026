import { Box } from '@chakra-ui/react';

interface ModalBadgeProps {
  children: React.ReactNode;
}

export function ModalBadge(props: ModalBadgeProps) {
  return (
    <Box
      pos="absolute"
      left="50%"
      bottom="100%"
      backgroundColor="texas.sand.100"
      _light={{ backgroundColor: 'texas.burntSienna.500' }}
      color="white"
      borderRadius="20em"
      transform="translate(-50%, 30%)"
      p={3}
      boxShadow="base"
    >
      {props.children}
    </Box>
  );
}
