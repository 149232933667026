import { useActiveSession } from '@texas/hooks/useSession';
import { useWidgetLayout } from '../widgets/useWidgetLayout';
import { TableColumns } from './TableColumns';
import { defaultCustomerWidgetsLayout } from './defaultCustomerWidgetsLayout';
import { defaultKickOffWidgetOptions } from './widgetOptions/defaultKickOffWidgetOptions';
import {
  defaultArticlesWidgetOptions,
  pageSize,
} from './widgetOptions/defaultWidgetsOptions';
import { useTranslation } from 'react-i18next';
import { BodyOverride } from '../shared/BodyOverride';
import { WidgetType } from '../widgets/shared/types';
import { defaultOnHoldWidgetsOptions } from './widgetOptions/defaultOnHoldWidgetOptions';

const localStorageCustomerSuffix = 'c';
const localStorageConceptSuffix = 'concept';

export function Customer({
  customerId,
  conceptId,
}: {
  customerId: number | undefined;
  conceptId: number | undefined;
}) {
  const { t } = useTranslation();
  const session = useActiveSession();
  const { widgetComponents } = useWidgetLayout({
    gridProps: {
      isDraggable: false,
      isResizable: false,
    },
    defaultLayout: defaultCustomerWidgetsLayout,
    widgetOptions: {
      [WidgetType.Articles]: {
        widgetType: WidgetType.Articles,
        options: {
          ...defaultArticlesWidgetOptions(customerId, conceptId),
          localStorageKey: localStorageKey(
            session.currentUser.id,
            customerId,
            conceptId,
          ),
          columns: TableColumns,
          customWidgetTitle: t('general.brandArticles'),
          pageSize: pageSize,
          optOutDefaultOrdering: true,
          componentSettings: {
            editWidget: 'hidden',
            notDraggable: true,
          },
        },
      },
      [WidgetType.ArticlesCount]: {
        widgetType: WidgetType.ArticlesCount,
        options: {
          preConfiguredWidget: {
            ['countWidget1']: {
              ...defaultKickOffWidgetOptions(customerId, conceptId),
              localStorageKey: undefined,
              columns: TableColumns,
              widgetTitle: t('customer.widgets.kickOffTitle'),
              optOutDefaultOrdering: true,
              componentSettings: {
                editWidget: 'hidden',
                editFilters: 'hidden',
                notDraggable: true,
              },
            },
            ['countWidget2']: {
              ...defaultOnHoldWidgetsOptions(customerId, conceptId),
              localStorageKey: undefined,
              columns: TableColumns,
              widgetTitle: t('customer.widgets.onHoldTitle'),
              optOutDefaultOrdering: true,
              componentSettings: {
                editWidget: 'hidden',
                editFilters: 'hidden',
                notDraggable: true,
              },
            },
          },
          ...defaultArticlesWidgetOptions(customerId, conceptId),
          localStorageKey: undefined,
          columns: TableColumns,
          customWidgetTitle: t('general.brandArticles'),
          optOutDefaultOrdering: true,
          componentSettings: {
            editWidget: 'hidden',
            notDraggable: true,
          },
        },
      },
    },
  });

  return <BodyOverride>{widgetComponents}</BodyOverride>;
}

function localStorageKey(
  userId: number,
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  return customerId
    ? `${userId}_${customerId}_${localStorageCustomerSuffix}`
    : conceptId
    ? `${userId}_${conceptId}_${localStorageConceptSuffix}`
    : undefined;
}
