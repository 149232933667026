// @ngInject
const technicalOptionsResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadTechnicalOptions(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.technicalOptions', {
    url: '/technicaloptions',
    controller: 'TechnicalOptionsConfigurationController as vm',
    templateUrl:
      'main/configuration/technicalOptions/technicalOptions.tpl.html',
    data: { pageTitle: 'Article templates' },
    resolve: {
      technicalOptions: technicalOptionsResolve,
    },
  });
};

class TechnicalOptionsConfigurationController {
  constructor(
    $scope,
    DS,
    TechnicalOptionsApiService,
    ConfigItemDialogService,
    currentUser,
    technicalOptions,
  ) {
    'ngInject';

    this._ConfigItemDialogService = ConfigItemDialogService;
    this._TechnicalOptionsApiService = TechnicalOptionsApiService;
    this._currentUser = currentUser;
    this.technicalOptions = technicalOptions;
    this.noop = _.noop;

    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('technicalOption');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.technicalOptions = _.map(
            currentUser.organization.technicalOptions,
            _.clone,
          );
        }
      },
    );
  }

  openTechnicalOption(technicalOption) {
    this._ConfigItemDialogService.showDialog({
      configItem: technicalOption,
      displayOrder: true,
      checkedByDefault: true,
      onSave: (technicalOption) =>
        this._TechnicalOptionsApiService.updateTechnicalOption(
          _.pick(technicalOption, [
            'name',
            'displayOrder',
            'checkedByDefault',
            'archived',
          ]),
          technicalOption.id,
        ),
    });
  }

  newTechnicalOption() {
    this._ConfigItemDialogService.showDialog({
      displayOrder: true,
      checkedByDefault: true,
      onSave: (technicalOption) =>
        this._TechnicalOptionsApiService.newTechnicalOption(
          technicalOption,
          this._currentUser.organization.id,
        ),
    });
  }
}

angular
  .module('configuration.technicalOptions', [])
  .config(config)
  .controller(
    'TechnicalOptionsConfigurationController',
    TechnicalOptionsConfigurationController,
  );
