// @ngInject
const UploaderFileClass = (
  UploaderChunk,
  FilesApiService,
  FileReplaceDialogService,
) => {
  return class UploaderFile {
    constructor(uploaderService, instance, finalizeUrl, file) {
      this.uploaderService = uploaderService;
      this.instance = instance;
      this.finalizeUrl = finalizeUrl;
      this.file = file;
      this.name = file.fileName || file.name;
      this.size = file.size;
      this.uniqueIdentifier = '';
      this.offset = 0;
      this.chunks = [];
      this.uploadingChunk = false;
      this.completedChunks = 0;
      this.completedBytes = 0;
      this.uploadComplete = false;
      this.uploading = false;
      this.done = false;
      this.progress = 0;
      this.error = false;
      this.averageSpeed = 0;
      this.currentSpeed = 0;
      this._lastProgressCallback = Date.now();
      this._prevTransferredSize = 0; // For speed measurement
    }

    measureSpeed() {
      const timeSpan = Date.now() - this._lastProgressCallback;
      const smoothingFactor = this.uploaderService.opts.speedSmoothingFactor;
      if (!timeSpan) {
        return;
      }
      // Prevent negative upload speed after file upload resume
      this.currentSpeed = Math.max(
        ((this.completedBytes - this._prevTransferredSize) / timeSpan) * 1000,
        0,
      );
      this.averageSpeed =
        smoothingFactor * this.currentSpeed +
        (1 - smoothingFactor) * this.averageSpeed;
      this.uploaderService.averageSpeed = this.averageSpeed;
      this._prevTransferredSize = this.completedBytes;
    }

    chunkEvent(event, serverReply) {
      switch (event) {
        case 'progress':
          if (
            Date.now() - this._lastProgressCallback <
            this.uploaderService.opts.progressCallbacksInterval
          ) {
            break;
          }
          this.reportProgress();
          break;
        case 'error':
          this.error = true;
          this.done = true;
          this.uploaderService.removeCompletedBytes(this.completedBytes);
          this.completedBytes = 0;
          this.reset();
          this.uploaderService.onFileError(this, serverReply);
          break;
        case 'success':
          this.error = false;
          this.removeUploadingChunk(this.uploadingChunk); //free-up memory
          if (this.completedChunks === this.chunks.length) {
            //All chunks completed
            this.reportProgress();
            this.uploading = false;
            this.uploadComplete = true;
            this.currentSpeed = 0;
            this.averageSpeed = 0;
            this.file = null;
            const finalizeOptions = {
              keepBoth: this.instance.keepBoth,
              replace: this.instance.replace,
            };

            FilesApiService.postFinalizeRequest(
              this.uniqueIdentifier,
              this.finalizeUrl,
              finalizeOptions,
            ).then(
              (file) => {
                this.done = true;
                this.uploaderService.onFileComplete(this, file);
              },
              (error) => {
                if (error.status === 409) {
                  this.openFileReplaceDialog();
                } else {
                  this.chunkEvent('error');
                }
              },
            );
          } else {
            this.uploadNextChunk();
          }
          break;
        case 'retry':
        default:
          this.uploaderService.fire('fileRetry', this);
          break;
      }
    }

    reportProgress() {
      this.measureProgress();
      this.measureSpeed();
      this.uploaderService.fire('fileProgress', this);
      this._lastProgressCallback = Date.now();
    }

    uploadNextChunk() {
      _.forEach(this.chunks, (chunk) => {
        if (chunk && chunk.status() === 'pending') {
          chunk.send();
          return false;
        }
      });
    }

    reset() {
      this.uploading = false;
      this.currentSpeed = 0;
      this.averageSpeed = 0;
      this.offset = 0;
      this.uploadingChunk = false;
      this.completedChunks = 0;
      this.chunks = [];
    }

    start() {
      if (this.uploadComplete) {
        return false;
      }
      this.reset();
      this.uploading = true;
      FilesApiService.getFileIdentifier(
        this.name,
        this.file.size,
        this.uploaderService.opts.chunkSize,
      ).then(
        (identifier) => {
          this._lastProgressCallback = Date.now();
          this.uniqueIdentifier = identifier;
          this.prepareChunks();
          this.uploadNextChunk();
        },
        () => {
          this.uploading = false;
          this.done = true;
          this.error = true;
          this.reset();
          this.uploaderService.onFileError(this);
        },
      );
    }

    prepareChunks() {
      let chunkCount = 0;
      if (this.uploaderService.opts.chunkSize > 0) {
        chunkCount = Math.max(
          Math.ceil(
            (this.file.size - this.offset) /
              this.uploaderService.opts.chunkSize,
          ),
          1,
        );
      } else {
        chunkCount = 1;
      }
      for (let offset = 0; offset < chunkCount; offset += 1) {
        this.chunks.push(new UploaderChunk(this.uploaderService, this, offset));
      }
    }

    removeUploadingChunk() {
      const chunkIndex = _.indexOf(this.chunks, this.uploadingChunk);
      this.chunks[chunkIndex] = null;
      this.uploadingChunk = null;
    }

    measureProgress() {
      if (this.error) {
        this.progress = 0;
      } else {
        if (this.uploadComplete) {
          this.progress = 1;
        } else {
          if (this.size === 0 && this.completedBytes === 0) {
            this.progress = 1;
          } else {
            this.progress = this.completedBytes / this.size;
          }
        }
      }
    }

    timeRemaining() {
      if (this.error || this.complete) {
        return 0;
      }
      const delta = this.size - this.completedBytes;
      if (delta && !this.averageSpeed) {
        return Number.POSITIVE_INFINITY;
      }
      if (!delta && !this.averageSpeed) {
        return 0;
      }
      return Math.floor(delta / this.averageSpeed);
    }

    remove() {
      this.uploaderService.removeFile(this);
    }

    openFileReplaceDialog() {
      FileReplaceDialogService.showDialog({ file: this }).then(
        (file) => {
          this.done = true;
          this.uploaderService.onFileComplete(this, file);
        },
        () => {
          this.chunkEvent('error');
        },
      );
    }
  };
};

angular
  .module('services.uploader.uploaderFile', ['services.uploader.uploaderChunk'])
  .factory('UploaderFile', UploaderFileClass);
