// @ngInject
const archiveConfig = ($stateProvider) => {
  $stateProvider.state('archive', {
    parent: 'main',
    url: '/archive',
    views: {
      'main-content': {
        controller: 'ArchiveController as vm',
        templateUrl: 'main/archive/archive.tpl.html',
      },
    },
    data: { pageTitle: 'Archive' },
  });
};

const TABS = [
  { heading: 'Article templates', resource: 'articleTemplate' },
  { heading: 'Articles', resource: 'article', identifier: 'Itemcode' },
  { heading: 'Concepts', resource: 'concept' },
  {
    heading: 'Countries',
    resource: 'country',
    url: 'countries',
    identifier: 'Code',
  },
  {
    heading: 'Currencies',
    resource: 'currency',
    url: 'currencies',
    identifier: 'Code',
  },
  { heading: 'Customers', resource: 'customer' },
  { heading: 'Delivery methods', resource: 'deliveryMethod', identifier: true },
  { heading: 'Delivery terms', resource: 'deliveryTerm', identifier: true },
  { heading: 'Files', resource: 'file' },
  { heading: 'Folders', resource: 'folder' },
  { heading: 'Freight costs', resource: 'freightCost' },
  { heading: 'Handling fees', resource: 'handlingFee' },
  { heading: 'Matrix nodes', resource: 'matrixNode' },
  {
    heading: 'Matrix node values',
    resource: 'matrixNodeValue',
    identifier: true,
  },
  { heading: 'Options', resource: 'variant' },
  { heading: 'Payment terms', resource: 'paymentTerm', identifier: true },
  { heading: 'Suppliers', resource: 'supplier' },
  { heading: 'Technical options', resource: 'technicalOption' },
  { heading: 'Vat codes', resource: 'vatCode', identifier: true },
  { heading: 'Users', resource: 'user', identifier: 'Email' },
];

class ArchiveController {
  constructor($scope, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;

    this.tabData = _.map(TABS, (tab) => ({
      active: tab.resource === 'articleTemplate',
      heading: tab.heading,
      route: `archive.${tab.resource}`,
    }));

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('archive')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.tabData, { active: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module(
    'main.archive',
    _.map(TABS, (tab) => `archive.${tab.resource}`),
  )
  .config(archiveConfig)
  .controller('ArchiveController', ArchiveController);

class ArchivedEntitiesController {
  constructor(
    $scope,
    ResourcesService,
    loadSpinnerService,
    currentOrganization,
    resource,
    heading,
    identifier,
    entities,
    HelperService,
    AuthService,
  ) {
    'ngInject';

    this.HelperService = HelperService;
    this.ResourcesService = ResourcesService;
    this.loadSpinnerService = loadSpinnerService;

    this.allowedToRestore = AuthService.hasAnyClaim([
      'system_administrator',
      'organization_administrator',
    ]);

    this.resource = resource;
    this.heading = heading;
    this.entities = entities;
    this.identifierTitle =
      !!identifier && angular.isString(identifier) ? identifier : 'Identifier';
    this.nameTitle = 'Name';
    this.hasIdentifier = !!identifier;
    this.hasParent = _.some(entities, (e) => !!e.parent);

    this.noop = _.noop;
    this.query = {
      filter: '',
      order: '-archived',
      limit: 50,
      page: 1,
    };
    this.validationErrors = [];

    $scope.$watch('vm.query.filter', () => (this.query.page = 1));
  }

  restore(entity) {
    if (!entity) {
      return;
    }

    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');

    this.ResourcesService.restore(this.resource, entity.id)
      .then(
        () => this.entities.splice(this.entities.indexOf(entity), 1),
        (error) => {
          if (error.errors && error.errors.length) {
            this.validationErrors = error.errors;
          } else {
            this.validationErrors = [{ message: error.message }];
          }
        },
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  filterEntities(query) {
    const queryObj = {
      name: query,
    };
    if (this.hasIdentifier) {
      queryObj.identifier = query;
    }
    if (this.hasParent) {
      queryObj.parent = query;
    }

    return _.filter(this.entities, (h) =>
      this.HelperService.containsText(h, queryObj),
    );
  }
}

TABS.forEach((tab) => {
  const route = `archive.${tab.resource}`;

  // @ngInject
  const entitiesResolve = (currentUser, ResourcesService) =>
    ResourcesService.getArchived(tab.resource, currentUser.organization.id);

  // @ngInject
  const entityConfig = ($stateProvider) => {
    $stateProvider.state(route, {
      url: `/${(tab.url || `${tab.resource}s`).toLowerCase()}`,
      controller: 'ArchivedEntitiesController as vm',
      templateUrl: 'main/archive/entities.tpl.html',
      data: { pageTitle: `Archived ${tab.heading.toLowerCase()}` },
      resolve: {
        resource: () => tab.resource,
        heading: () => tab.heading,
        identifier: () => tab.identifier,
        entities: entitiesResolve,
      },
    });
  };

  angular
    .module(route, [])
    .config(entityConfig)
    .controller('ArchivedEntitiesController', ArchivedEntitiesController);
});
