import { Box, BoxProps, Flex, TableContainer } from '@chakra-ui/react';
import { DataTable, DataTableProps } from './DataTable';
import { Pagination } from './pagination/Pagination';
import { ServerError } from '@texas/types';
import { ErrorDetails } from '../alert/ErrorDetails';

export interface DataTableContainerProps<TData extends object>
  extends BoxProps {
  error: ServerError | null;
  datatable: DataTableProps<TData>;
  pagination?: DataTablePaginationProps;
  footer?: React.ReactNode;
}

interface DataTablePaginationProps {
  pageSize: number;
  totalItems: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export function DataTableContainer<TData extends object>(
  props: DataTableContainerProps<TData>,
) {
  return (
    <TableContainer {...props}>
      <ErrorDetails error={props.error} />
      <DataTable {...props.datatable} />
      <Flex justifyContent="space-between" mt={2}>
        {props.footer && <>{props.footer}</>}
        {props.pagination && (
          <Box ml="auto">
            <Pagination
              totalItems={props.pagination.totalItems}
              pageSize={props.pagination.pageSize}
              currentPage={props.pagination.currentPage}
              onPageChange={props.pagination.onPageChange}
            />
          </Box>
        )}
      </Flex>
    </TableContainer>
  );
}
