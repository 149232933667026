import { HTMLAttributes, useCallback, useRef, useState } from 'react';
import { FieldValues, Path, PathValue, useForm } from 'react-hook-form';
import { Command } from '../../../commandHandler/commandHandler';
import {
  BaseAutoUpdateProps,
  AutoUpdateWrapperProps,
  UseAutoUpdateFormProps,
  UseAutoUpdateFormWithDataProps,
} from './types';
import {
  AutoUpdateCommand,
  CommandCallbackArgs,
} from '../../../commandHandler/autoUpdate/types';
import { autoUpdateBus } from '../../../commandHandler/autoUpdate/autoUpdateCommandHandler';
import { commandTypes } from '../../../commandHandler/commandTypes';
import { AutoUpdateFieldType } from '@texas/api/endpoints/autoUpdateTypes';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { randomSeed } from '@texas/resources/constants';
import { AutoUpdate } from './AutoUpdate';

export interface AutoUpdateFormState<T> {
  values: T;
  unmodifiedServerValues: T;
}

export function useAutoUpdateForm<
  TRequest extends FieldValues,
  TDto extends TRequest,
  TData = any,
>(
  props:
    | UseAutoUpdateFormProps<TRequest, TDto>
    | UseAutoUpdateFormWithDataProps<TRequest, TDto, TData>,
) {
  const [autoUpdateRows, setAutoUpdateRows] = useState<AutoUpdateFieldType[]>(
    [],
  );

  const [formValues, setFormValues] = useState<AutoUpdateFormState<TRequest>>({
    values: props.defaultValue as TRequest,
    unmodifiedServerValues: props.defaultValue as TRequest,
  });
  const [valueId, setValueId] = useState<number>(props.valueId);
  const formId = useRef(
    `${Math.random() * randomSeed}-${props.namePrefix}-${valueId}`,
  );
  const apiRequest = useApiRequest(props.autoUpdateRequest);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState,
    control,
  } = useForm<TRequest>({
    defaultValues: props.defaultValue,
    resolver: props.resolver,
  });

  function sendToAutoUpdateCommandHandler(
    values: TRequest,
    forceUpdate: boolean,
  ) {
    const command: Command<AutoUpdateCommand<TRequest, TDto, TData>> = {
      payload: {
        id: valueId,
        initialFormValues: formValues.values,
        unmodifiedServerValues: formValues.unmodifiedServerValues,
        formValues: values,
        forceUpdate: forceUpdate,
        apiRequest: apiRequest,
        stateUpdate: stateUpdate,
        formId: formId.current,
        additionalData: hasAdditionalData(props)
          ? props.additionalData
          : undefined,
      },
      type: commandTypes.autoUpdate,
    };

    if (!hasData(formValues.unmodifiedServerValues, values)) return;

    autoUpdateBus.execute(command);
  }

  function stateUpdate(args: CommandCallbackArgs<TDto>) {
    setAutoUpdateRows(args.rows ?? []);
    if (args.response) {
      // Map correct TRequest values here
      const mappedRequest: AutoUpdateFormState<TRequest> = {
        values: mapTo(
          args.response.values,
          props.defaultValue ?? props.fallbackMapObject,
        ),
        unmodifiedServerValues: mapTo(
          args.response.unmodifiedServerValues,
          props.defaultValue ?? props.fallbackMapObject,
        ),
      };
      setFormValues(mappedRequest);

      if (props.onResponse) {
        props.onResponse({
          unmodifiedServerData: args.response.unmodifiedServerValues,
          mappedServerData: mappedRequest.values,
        });
      }
    }
  }

  const resetValue = useCallback(
    (name: Path<TRequest>, value: PathValue<TRequest, Path<TRequest>>) => {
      setValue(name, value);
      setFormValues((s) => ({
        unmodifiedServerValues: {
          ...s.unmodifiedServerValues,
          [name as keyof TRequest]: value,
        },
        values: { ...s.values, [name as keyof TRequest]: value },
      }));
    },
    [setValue, setFormValues],
  );

  const submit = () => {
    handleSubmit((data) => {
      sendToAutoUpdateCommandHandler(data, false);
    })();
  };

  const registerAutoUpdate: BaseAutoUpdateProps<TRequest> = {
    states: autoUpdateRows,
    setValue: setValue,
    getValues: getValues,
    onValueOptionClick: (force: boolean) => {
      handleSubmit((data) => sendToAutoUpdateCommandHandler(data, force))();
    },
  };

  const form: HTMLAttributes<HTMLFormElement> = {
    id: formId.current,
    onSubmit: (e) => e.preventDefault(),
  };

  return {
    register,
    registerAutoUpdate,
    watch,
    formState,
    setDefaultValues: setFormValues,
    getValues,
    resetValue,
    setValueId,
    setValue,
    triggerSubmit: submit,
    form,
    control,
  };
}

// Validate if the server has a valid entity, and if it doesnt
// we dont want to send only undefined data to the server
function hasData(serverData: any, formData: any) {
  if (Object.entries(formData).length === 0) return false;
  if (serverData) return true;

  return Object.entries(formData).some(([_, value]) => {
    if (value !== undefined) return true;
  });
}

function mapTo<TFrom extends object, TTo extends object>(
  fromObject: TFrom,
  toModel: any,
): TTo {
  const temp = structuredClone(toModel);
  Object.entries(fromObject).map(([key, value]) => {
    if (Object.hasOwn(temp, key)) {
      temp[key] = value;
    }
  });

  return temp;
}

function hasAdditionalData<TRequest extends FieldValues, TDto, TData>(
  props:
    | UseAutoUpdateFormProps<TRequest, TDto>
    | UseAutoUpdateFormWithDataProps<TRequest, TDto, TData>,
): props is UseAutoUpdateFormWithDataProps<TRequest, TDto, TData> {
  return (
    (props as UseAutoUpdateFormWithDataProps<TRequest, TDto, TData>)
      .additionalData !== undefined
  );
}

export function AutoUpdateWrapper<T extends FieldValues>(
  autoUpdateWrapperTemplateProps: AutoUpdateWrapperProps<T>,
) {
  return (
    <AutoUpdate<T>
      {...autoUpdateWrapperTemplateProps.autoUpdateProps}
      path={autoUpdateWrapperTemplateProps.path}
      position={autoUpdateWrapperTemplateProps.position}
      boxProps={autoUpdateWrapperTemplateProps.boxProps}
    >
      {autoUpdateWrapperTemplateProps.children}
    </AutoUpdate>
  );
}
