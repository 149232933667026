// @ngInject
const ProductGroupsApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('productGroup', {
      params: { organizationId },
    }),
});

angular
  .module('resources.productGroups', [])
  .factory('ProductGroupsApiService', ProductGroupsApiService);
