// @ngInject
const assignedUserChip = () => ({
  templateUrl: 'directives/assignedUserChip/assignedUserChip.tpl.html',
  restrict: 'E',
  replace: true,
  scope: {},

  bindToController: {
    taskId: '=',
    assignedUser: '=',
    removeAssignedUser: '&',
    currentUser: '=',
    onChange: '&',
  },

  controller: 'AssignedUserChipController',
  controllerAs: 'vm',
});

class AssignedUserChipController {
  constructor($filter, AuthService, UserDialogService) {
    'ngInject';
    this._currentUser = {};
    this._UserDialogService = UserDialogService;
    this.$filter = $filter;

    AuthService.getUser().then((user) => {
      this._currentUser = user;
    });
  }

  showAssignUserConfirmation() {
    return (
      this._currentUser && this.assignedUser.userId === this._currentUser.id
    );
  }

  openUser() {
    this._UserDialogService.showDialog({
      user: this.assignedUser.user,
      viewMode: true,
    });
  }

  currentStatus() {
    if (this.assignedUser.confirmation === 'None') {
      return 'Waiting for confirmation';
    } else {
      const state =
        this.assignedUser.confirmation === 'Accepted' ? 'Accepted' : 'Declined';
      const confirmationDate = this.$filter('date')(
        this.assignedUser.confirmationDate,
        'yyyy-MM-dd',
      );
      return confirmationDate ? `${state}: ${confirmationDate}` : state;
    }
  }
}

angular
  .module('directives.assignedUserChip', [])
  .controller('AssignedUserChipController', AssignedUserChipController)
  .directive('assignedUserChip', assignedUserChip);
