// @ngInject
const InformationHistoryDialogService = ($mdDialog, ArticlesApiService) => ({
  showDialog(
    type,
    name,
    variantId,
    supplierId,
    currencyId,
    currentOrganization,
  ) {
    const options = {
      clickOutsideToClose: true,
      templateUrl:
        'services/dialogs/informationHistoryDialog/informationHistoryDialog.tpl.html',
      controller: 'InformationHistoryDialogController as vm',
      locals: {
        keyName: name,
        organization: currentOrganization,
      },
    };
    if (type === 'purchase') {
      options.resolve = {
        data: () => {
          return ArticlesApiService.getPurchaseInfoHistory(variantId).then(
            (data) =>
              _.filter(data, {
                supplierId,
                currencyId,
              }),
          );
        },
      };
    }
    return $mdDialog.show(options);
  },
});

class InformationHistoryDialogController {
  constructor($mdDialog, keyName, organization, data) {
    'ngInject';

    this._$mdDialog = $mdDialog;
    this.keyName = keyName;
    this.organization = organization;
    this.data = _.filter(
      data,
      this.keyName,
      'user',
      'updatedOn',
      'freightCostId',
      'handlingFeeId',
    );

    this.limitOptions = [20, 40, 60];

    this.options = {
      boundaryLinks: true,
      limitSelect: true,
      pageSelect: true,
    };

    this.query = {
      order: 'date',
      limit: 20,
      page: 1,
    };
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.informationHistoryDialog', [])
  .service('InformationHistoryDialogService', InformationHistoryDialogService)
  .controller(
    'InformationHistoryDialogController',
    InformationHistoryDialogController,
  );
