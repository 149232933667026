// @ngInject
const ArticleTemplatesApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('articleTemplate', {
      params: { organizationId },
    }),

  getById: (articleTemplateId) =>
    ResourcesService.getById('articleTemplate', articleTemplateId, {
      params: { organizationId: false },
    }),

  newArticleTemplate: (articleTemplate, organizationId) =>
    ResourcesService.create('articleTemplate', articleTemplate, {
      params: { organizationId },
    }),

  updateArticleTemplate: (data, articleTemplateId) =>
    ResourcesService.update('articleTemplate', articleTemplateId, data, {
      params: { organizationId: false },
      updatedFields: [
        'matrixNodeXId',
        'matrixNodeYId',
        'name',
        'itemCodeMinLength',
        'itemCodeMaxLength',
        'displayOrder',
        'archived',
      ],
    }),
});

angular
  .module('resources.articleTemplates', [])
  .factory('ArticleTemplatesApiService', ArticleTemplatesApiService);
