// @ngInject
const loadSpinner = () => ({
  template:
    '<md-progress-circular class="spinner md-accent" md-mode="indeterminate"></md-progress-circular>',
  restrict: 'E',
  scope: {},
  bindToController: {
    spinnerName: '@',
  },
  controller: 'LoadSpinnerController',
  controllerAs: 'vm',
});

class LoadSpinnerController {
  constructor($scope, $rootScope, $timeout, $element) {
    'ngInject';
    this.$timeout = $timeout;
    this.$element = $element;

    this.loadDelay = 180;
    this.isLoading = false;
    this.loaders = 0;
    const startEvent = $rootScope.$on(
      `${this.spinnerName}-start`,
      (event, data) => this.onStartEvent(event, data),
    );
    const stopEvent = $rootScope.$on(
      `${this.spinnerName}-stop`,
      (event, data) => this.onStopEvent(event, data),
    );

    $scope.$on('$destroy', () => {
      startEvent();
      stopEvent();
    });
  }

  onStartEvent(event, data) {
    this.loaders = this.loaders + 1;
    if (data.force) {
      this.startSpinner();
    } else {
      this.$timeout(() => {
        this.startSpinner();
      }, this.loadDelay);
    }
  }

  onStopEvent(event, data) {
    this.loaders = this.loaders > 0 ? this.loaders - 1 : this.loaders;
    if (this.loaders === 0 || data.force) {
      this.stopSpinner();
    }
  }

  startSpinner() {
    if (this.loaders > 0 && !this.isLoading) {
      this.isLoading = true;
      this.$element.toggleClass('loading', this.isLoading);
    }
  }

  stopSpinner() {
    this.loaders = 0;
    this.isLoading = false;
    this.$element.toggleClass('loading', this.isLoading);
  }
}

angular
  .module('directives.loadSpinner', [])
  .directive('loadSpinner', loadSpinner)
  .controller('LoadSpinnerController', LoadSpinnerController);
