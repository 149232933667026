import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CompositionGroupView } from './CompositionGroupView';
import { useEffect } from 'react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { CompositionLocationData } from '../hooks/useCompositionGroup';
import { compositionGroupEvents } from '../events';

interface Props extends SharedDisclosureProps {
  locationData: CompositionLocationData;
}

export function PreviousCompositionGroupsModal({
  locationData,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const { data, refetch, loading } = useApiResource(
    compositionGroupApi.getAllPreviousGroupIds,
  );

  useEffect(() => {
    refetch(locationData);
  }, [refetch, locationData]);

  useEffect(() => {
    return compositionGroupEvents.optionArchivedOrRestored.sub(() => {
      refetch(locationData);
    });
  }, [locationData, refetch]);

  useEffect(() => {
    return compositionGroupEvents.versionArchivedOrRestored.sub(() => {
      refetch(locationData);
    });
  }, [locationData, refetch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {locationData.supplierId
            ? t('composition.archivedVersions')
            : t('composition.archivedOptions')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody _dark={{ bg: 'texas.bg.dark' }}>
          {loading && (
            <Flex>
              <Text mr={2}>{t('general.loading')}</Text>
              <Spinner />
            </Flex>
          )}
          {data?.length == 0 && (
            <Text py={6}>{t('composition.noArchivedVersions')}</Text>
          )}
          <Flex overflowX="auto" gap={2}>
            {data?.map((x) => (
              <CompositionGroupView
                key={x.id}
                variantId={x.variantId}
                compositionGroupId={x.id}
                componentSettings={{
                  compositionArchiveRestore: 'hidden',
                  compositionEdit: 'hidden',
                }}
              />
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{t('general.close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
