import { SelectBranches } from './branches/SelectBranches';
import { StepConfig } from '../types';
import { SelectSuppliers } from './suppliers/SelectSuppliers';
import { SelectProductApprovals } from './selectProductApprovals/SelectProductApprovals';
import { SelectCells } from './matrix/SelectCells';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { Confirm } from './confirm/Confirm';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

export function useCreateProductApprovalsSteps(
  articleNodeGroups?: ArticleNodeGroup,
): StepConfig[] {
  const { t } = useTranslation();
  return [
    {
      id: 'branches',
      content: <SelectBranches page="branches" />,
      label: <>{t('wizard.productApproval.selectBranches')}</>,
      invalidContent: (
        <Text as="i" variant="sub">
          {t('productApproval.selectBranch')}
        </Text>
      ),
    },
    {
      id: 'suppliers',
      content: <SelectSuppliers page="suppliers" />,
      label: <>{t('wizard.productApproval.selectSuppliers')}</>,
      invalidContent: (
        <Text as="i" variant="sub">
          {t('productApproval.selectSupplier')}
        </Text>
      ),
    },
    ...(nodeGroupIsPopulated(articleNodeGroups)
      ? [
          {
            id: 'matrix',
            content: (
              <SelectCells
                page="matrix"
                articleNodeGroup={articleNodeGroups!}
              />
            ),
            label: <>{t('wizard.productApproval.selectCells')}</>,
            invalidContent: (
              <Text as="i" variant="sub">
                {t('productApproval.selectCell')}
              </Text>
            ),
          },
        ]
      : []),
    {
      id: 'productApprovals',
      content: <SelectProductApprovals page="productApprovals" />,
      label: <>{t('wizard.productApproval.productApprovals')}</>,
      invalidContent: (
        <Text as="i" variant="sub">
          {t('productApproval.unhandledProductApprovals')}
        </Text>
      ),
    },
    {
      id: 'confirm',
      content: <Confirm page="confirm" />,
      label: <>{t('general.confirm')}</>,
      customSummary: <></>,
    },
  ];
}
