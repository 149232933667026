import { ArticleBranchErpSyncChanges } from '@texas/api/endpoints/variantsApi';
import FinalizeError from '../../../api/dto/variant/finalizeErrorTypes';

export function branchCanFinalize(
  branchIdsToSync: number[],
  validations: FinalizeError[] | null,
) {
  return (
    branchIdsToSync.length > 0 &&
    !branchIdsToSync.some((b) => branchHasErrors(b, validations)) &&
    validations?.filter((v) => !v.branchId).length === 0
  );
}

export function branchIsModifiedSinceSync(
  branchId: number,
  syncChanges: ArticleBranchErpSyncChanges[] | null,
) {
  if (!syncChanges) return false;
  return (
    syncChanges.find(
      (s) =>
        s.branchId === branchId &&
        s.modifications.some((m) => m.modifiedFields.length > 0),
    ) !== undefined
  );
}

export function branchHasErrors(
  branchId: number,
  validations: FinalizeError[] | null,
) {
  return (validations?.filter((v) => v.branchId === branchId) ?? []).length > 0;
}
