import { useProdutApprovalWizardState } from '../../context';

export function useStep() {
  const { steps, nextStep, stepId } = useProdutApprovalWizardState();

  const step = steps.find((stepState) => stepState.id === stepId);
  if (!step) {
    throw new Error(`StepState not found for id: ${stepId}`);
  }

  return {
    nextStep,
    config: step.config,
    state: step.state,
    lastStep:
      steps.findIndex((stepState) => stepState.id === stepId) ===
      steps.length - 1,
  };
}
