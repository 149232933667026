// @ngInject
const TechniquesApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('technique', {
      params: { organizationId },
    }),

  getById: (techniqueId) =>
    ResourcesService.getById('technique', techniqueId, {
      params: { organizationId: false },
    }),

  newTechnique: (technique, organizationId) =>
    ResourcesService.create('technique', technique, {
      params: { organizationId },
    }),

  updateTechnique: (data, techniqueId) =>
    ResourcesService.update('technique', techniqueId, data, {
      params: { organizationId: false },
      updatedFields: [
        'code',
        'value',
        'displayOrder',
        'archived',
        'description',
      ],
    }),
});

angular
  .module('resources.techniques', [])
  .factory('TechniquesApiService', TechniquesApiService);
