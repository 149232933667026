import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  Input,
  Select,
  Flex,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { request } from '../../../utils/helpers/httpHelpers';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '../../../utils/helpers/enumHelpers';
import { addSpaceBeforeUppercaseLetter } from '../../../utils/helpers/commonHelpers';
import {
  UserProfile,
  UpdateUserProfileRequest,
  userProfilesApi,
  UserProfileType,
} from '@texas/api/endpoints/userProfilesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';

export interface EditUserProfileModalProps extends SharedDisclosureProps {
  onProfileUpdated: (userProfile: UserProfile) => void;
  userProfile: UserProfile | null;
}

export function EditUserProfileModal(props: EditUserProfileModalProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: updateUserProfileRequest, error } = useApiRequest(
    userProfilesApi.updateUserProfile,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateUserProfileRequest>();

  useEffect(() => {
    reset({
      name: props.userProfile?.name,
      userId: props.userProfile?.userId,
      type: convertToEnum(UserProfileType, props.userProfile?.type),
    });
  }, [props.userProfile, reset]);

  const onSubmit = async (data: UpdateUserProfileRequest) =>
    await request(
      updateUserProfileRequest,
      [props.userProfile!.id, data],
      (data: UserProfile) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        props.onProfileUpdated(data);
        props.onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('userProfile.editUserProfile')}</ModalHeader>
        {error && <ErrorDetails error={error} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Flex direction="column" gap={4}>
              <FormControl isInvalid={!!errors.type}>
                <TexasFormLabel>
                  {t('userProfile.userProfileType')}
                </TexasFormLabel>
                <Select {...register('type', { required: true })}>
                  {getEnumNamesAndValues(UserProfileType).map((p) => {
                    return (
                      <option key={p.value} value={p.value}>
                        {addSpaceBeforeUppercaseLetter(p.name)}
                      </option>
                    );
                  })}
                </Select>
                <ErrorLabel text={errors.type?.message} />
              </FormControl>
              <FormControl isRequired={true} isInvalid={!!errors.name}>
                <TexasFormLabel>
                  {t('userProfile.userProfileName')}
                </TexasFormLabel>
                <Input
                  variant="outline"
                  {...register('name', {
                    required: true,
                    maxLength: {
                      value: 25,
                      message: t('errors.maxLength', { count: 25 }),
                    },
                  })}
                  placeholder="Name"
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <SubmitButton loading={isSubmitting}>
              {t('general.update')}
            </SubmitButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
