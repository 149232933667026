// @ngInject
const ngEnter = () => ({
  restrict: 'A',
  scope: false,

  link: function linkFn(scope, element, attrs) {
    const withShift = attrs.enterWithShift || false;
    element.bind('keydown keypress', (event) => {
      if (event.which === 13) {
        if (withShift && !event.shiftKey) {
          return;
        }
        scope.$applyAsync(() => {
          scope.$eval(attrs.ngEnter);
        });

        event.preventDefault();
      }
    });
  },
});

angular.module('directives.ngEnter', []).directive('ngEnter', ngEnter);
