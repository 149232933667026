// @ngInject
const FolderDialogService = ($mdDialog) => ({
  showDialog(data) {
    data = data || {};
    data.folder = data.folder || null;
    data.parent = data.parent || null;
    data.files = data.files || null;

    return $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'FolderEditDialogController as vm',
      templateUrl: 'services/dialogs/folderDialog/folderDialog.tpl.html',
      locals: {
        folder: data.folder,
        parent: data.parent,
      },
    });
  },
});

class FolderEditDialogController {
  constructor(
    $mdDialog,
    HelperService,
    FilesApiService,
    toasterService,
    folder,
    parent,
  ) {
    'ngInject';

    this.validationErrors = [];
    this.editMode = folder !== null;
    this.folder = angular.copy(folder);

    if (!this.editMode) {
      folder = {};
    }
    this._$mdDialog = $mdDialog;
    this._FilesApiService = FilesApiService;
    this._HelperService = HelperService;
    this._folder = folder;
    this._parent = parent;
    this._toasterService = toasterService;
  }

  save() {
    this.validationErrors = [];

    if (this.editMode) {
      // Get any changes
      const changedFolderInfo = this._HelperService.getChangedData(
        this._folder,
        this.folder,
        ['name'],
      );

      // If changes are found, update them
      if (!_.isEmpty(changedFolderInfo)) {
        this._$mdDialog.startLoadingSpinner();
        this._FilesApiService
          .updateFolderInfo(changedFolderInfo, this._folder.id)
          .then(
            (updatedFolder) => {
              this._toasterService.success();
              this._$mdDialog.hide(updatedFolder);
            },
            (error) => {
              this.validationErrors = error.errors;
            },
          )
          .finally(() => {
            this._$mdDialog.stopLoadingSpinner();
          });
      } else {
        this._$mdDialog.hide();
      }
    } else {
      this._$mdDialog.startLoadingSpinner();
      this._FilesApiService
        .newFolder(this.folder, this._parent)
        .then(
          (newFolder) => {
            this._toasterService.success();
            this._$mdDialog.hide(newFolder);
          },
          (error) => {
            this.validationErrors = error.errors;
          },
        )
        .finally(() => {
          this._$mdDialog.stopLoadingSpinner();
        });
    }
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.folderDialog', [])
  .service('FolderDialogService', FolderDialogService)
  .controller('FolderEditDialogController', FolderEditDialogController);
