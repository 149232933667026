// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.organization', {
    url: '/organization',
    controller: 'OrganizationConfigurationController as vm',
    templateUrl: 'main/configuration/organization/organization.tpl.html',
    data: { pageTitle: 'Organization' },
  });
};

class OrganizationConfigurationController {
  constructor(
    $scope,
    loadSpinnerService,
    OrganizationsApiService,
    HelperService,
    toasterService,
    AuthService,
    UploaderService,
    FilesApiService,
    currentOrganization,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.loadSpinnerService = loadSpinnerService;
    this.OrganizationsApiService = OrganizationsApiService;
    this.HelperService = HelperService;
    this.toasterService = toasterService;
    this.AuthService = AuthService;
    this.UploaderService = UploaderService;
    this.FilesApiService = FilesApiService;

    this.config = _.extend({}, currentOrganization.config);
    this.original = currentOrganization.config;
    this.validationErrors = [];
    this.organization = currentOrganization;
    this.logoImageInstance = {};

    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);
    const logoImageOpts = {
      finalizeUrl: `organizations/${this.organization.id}/logoimage`,
      onDone: (success, file) => this.logoImageUploadDone(success, file),
      keepBoth: true,
      single: true,
      disabled: !this.currentUserCanEdit,
      validate: (file) => this.validateImage(file),
    };

    this.logoImageInstance = UploaderService.instance(
      `logoImageInstance${this.organization.id}`,
      logoImageOpts,
    );
  }

  getPreviewUrl(file) {
    return this.FilesApiService.getFilePreviewUrl(file.identifier, 250);
  }

  logoImageUploadDone(success, file) {
    if (success) {
      this.organization.logoImageId = file.id;
      this.organization.logoImage = file;
    }
  }

  validateImage(file) {
    this.validationErrors = [];
    const mimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file.size > 20000000) {
      this.validationErrors.push({
        message: 'File size is too large (limit: 20mb)',
      });
      return false;
    }

    if (mimeTypes.indexOf(file.type) === -1) {
      this.validationErrors.push({
        message: 'File must be of type jpg, png or gif',
      });
      return false;
    }
  }

  deleteFile(type) {
    this.OrganizationsApiService.deleteOrganizationFile(
      this.organization,
      type,
    );
  }
}

angular
  .module('configuration.organization', [])
  .config(config)
  .controller(
    'OrganizationConfigurationController',
    OrganizationConfigurationController,
  );
