// @ngInject
const texasErrors = () => ({
  templateUrl: 'directives/texasErrors/texasErrors.tpl.html',
  restrict: 'E',
  replace: true,

  scope: {
    form: '=',
    key: '@',
  },
});

angular
  .module('directives.texasErrors', [])
  .directive('texasErrors', texasErrors);
