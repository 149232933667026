import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { GroupContext } from './CompositionGroupView';
import { CompositionListModal } from '../../CompositionListModal';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { compositionGroupEvents } from '../events';

export function CompositionGroupMenu() {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useDisclosure();

  const {
    group,
    archiveGroupRequest,
    addCompositionToGroupRequest,
    refetchGroup,
    restoreGroupRequest,
    anyProductApproval,
  } = useContext(GroupContext)!;

  useEffect(() => {
    return compositionGroupEvents.compositionGroupsChanged.sub(() => {
      refetchGroup();
    });
  }, [refetchGroup]);

  return (
    <>
      <Menu>
        <MenuButton
          ml="auto"
          as={IconButton}
          variant="ghost"
          icon={<Icons.DotsHorizontal />}
        />
        <MenuList color="white">
          <MenuItem
            isDisabled={anyProductApproval}
            icon={<Icons.Plus boxSize={defaultIconSize} />}
            onClick={() => {
              addCompositionToGroupRequest();
            }}
          >
            {t('composition.addComposition')}
          </MenuItem>
          <MenuItem
            icon={<Icons.Archive boxSize={defaultIconSize} />}
            isDisabled={!group?.hasArchivedCompositions}
            onClick={onOpen}
          >
            {t('composition.showArchivedCompositions')}
          </MenuItem>
          <MenuDivider />
          {!group?.archived ? (
            <MenuItem
              color="texas.sand.50"
              icon={<Icons.Archive boxSize={defaultIconSize} />}
              onClick={onArchiveOpen}
            >
              {group?.supplierId
                ? t('composition.archiveVersion')
                : t('composition.archiveOption')}
            </MenuItem>
          ) : (
            <MenuItem
              color="texas.sand.50"
              icon={<Icons.ArchiveRemove boxSize={defaultIconSize} />}
              onClick={async () => {
                await restoreGroupRequest();
              }}
            >
              {group.supplierId
                ? t('composition.restoreVersion')
                : t('composition.restoreOption')}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <CompositionListModal
        isOpen={isOpen}
        onClose={onClose}
        compositionGroupId={group!.id}
      />
      <VerifyDialogWithFunction
        headerTitle={
          group?.supplierId
            ? t('composition.archiveVersion')
            : t('composition.archiveOption')
        }
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={async () => {
          await archiveGroupRequest();
          onArchiveClose();
        }}
        isOpen={isArchiveOpen}
        onClose={onArchiveClose}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
    </>
  );
}
