// @ngInject
const variantBranchDataResolve = (variant, ArticlesApiService) =>
  ArticlesApiService.getVariantBranchData(variant.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.matrix', {
    url: '/matrix',
    templateUrl: 'main/article/variant/matrix/matrix.tpl.html',
    controller: 'VariantMatrixController as vm',
    resolve: {
      variantBranchData: variantBranchDataResolve,
    },
  });
};

class VariantMatrixController {
  constructor(
    $scope,
    priceMatrixService,
    UploaderService,
    ArticlesApiService,
    MatrixCellDialogService,
    loadSpinnerService,
    toasterService,
    AuthService,
    article,
    variant,
    FilesApiService,
    SavingService,
    HelperService,
    variantBranchData,
  ) {
    'ngInject';

    this.validationErrors = [];
    this.photoInstances = [];
    this.article = article;
    this.variant = variant;
    this.UploaderService = UploaderService;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
    this.priceMatrixService = priceMatrixService;
    this.ArticlesApiService = ArticlesApiService;
    this.SavingService = SavingService;
    this.HelperService = HelperService;
    this.variantBranchData = {};
    this.variantBranchDataCopy = {};
    this.branches = article._branches;
    this.sharedData = {};
    this.sharedDataCopy = {};
    this.matrixValues = [];
    this.selectedBranch = null;
    this.headerFieldData = {};
    this.rowHeader = [];
    this.colHeader = [];
    this.$scope = $scope;
    this.tableHeight = 'height: 350px';

    this.sharedCellFields = [
      'supplierColorCode',
      'supplierSize',
      'techniqueId',
      'materialId',
      'weight',
      'blockedFromSync',
      'isLocked',
    ];

    this.setVariantBranchData(variantBranchData);
    this.setSharedData();

    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
      'agent',
    ]);
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(),
      onDiscard: () => this.discard(),
    });
    this.gridOptions = {
      enableSorting: false,
      enableColumnMenus: false,
      enableCellEditOnFocus: false,
      flatEntityAccess: true,
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
      },
      getPreviewUrl: (photo) =>
        photo ? FilesApiService.getFilePreviewUrl(photo.identifier, 250) : '',
      deletePhoto: (colField) =>
        ArticlesApiService.updateVariantMatrixValue(variant.id, {
          matrixNodeValueXId: colField.matrixValue.matrixNodeValueXId,
          matrixNodeValueYId: colField.matrixValue.matrixNodeValueYId,
          photoId: 0,
        }).then(() => {
          colField.matrixValue.photoId = 0;
          colField.matrixValue.photo = null;
          colField.isEdited = false;
        }),
      openCellEditDialog: (colField) =>
        MatrixCellDialogService.showDialog({
          matrixValue: colField.matrixValue,
          label: colField.label,
          cellInformation: colField.cellInformation,
          branchId: colField.branchId,
          sharedToBranches: () => this.sharedToBranches(),
          textDiffers: (i, x, y) => this.textDiffers(i, x, y),
          clearField: (i, x, y) => this.clearField(i, x, y),
          variant,
          article,
        }).finally(() => {
          colField.isEdited =
            !!colField.matrixValue && !!colField.matrixValue.photo;
        }),
      openHeaderEditDialog: (colField, colOrRow, displaySustainabilityForm) =>
        MatrixCellDialogService.showHeaderDialog({
          displaySustainabilityForm,
          matrixValue: true,
          label: colOrRow.rowName || colOrRow.displayName,
          fieldInformation: colOrRow.fieldInformation,
          branchId: colOrRow.fieldInformation.branchId,
          setRowOrCol: (fieldInformation) => this.setRowOrCol(fieldInformation),
          nodeDiffers: (i, fieldInformation) =>
            this.nodeDiffers(i, fieldInformation),
          clearNode: (i, fieldInformation) =>
            this.clearNode(i, fieldInformation),
          variant,
          article,
        }).finally(() => {
          colField.isEdited =
            !!colField.matrixValue && !!colField.matrixValue.photo;
        }),
    };

    this.loadMatrixData();
    this.setupWatchers();
  }

  loadMatrixData() {
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.getVariantMatrixValues(this.variant.id)
      .then((matrixValues) => {
        this.matrixValues = matrixValues;
        this.selectShared();
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  save(cb) {
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateBranchData(
      this.variant.id,
      this.getChangedData().branchData,
    )
      .then(
        (updatedBranchData) => {
          this.setVariantBranchData(updatedBranchData.data);
          this.setSharedData();
          if (this.selectedBranch !== null) {
            this.selectBranch(this.selectedBranch);
          } else {
            this.selectShared();
            this.loadMatrixData();
          }

          this.setHeaderInformation();
          if (cb) {
            cb();
          } else {
            this.toasterService.success();
          }
        },
        (error) => (this.validationErrors = error.errors),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  discard() {
    this.validationErrors = [];
    this.setVariantBranchData(this.variantBranchData);
    this.setSharedData();
    this.setupMatrix();
  }

  getChangedData() {
    const changedData = {};
    const changedBranchData = this.getBranchDataChanges();
    if (changedBranchData.length) {
      changedData.branchData = changedBranchData;
    }
    return changedData;
  }

  getBranchDataChanges() {
    const dataChanges = [];
    const branchDataFields = ['cellInformation'];
    const ignoredFields = ['material', 'technique'];

    _.forEach(this.variantBranchDataCopy, (branchData) => {
      const existingBranchData = _.find(this.variantBranchData, {
        branchId: branchData.branchId,
      });
      const changedData = this.HelperService.getChangedData(
        existingBranchData,
        branchData,
        branchDataFields,
        ignoredFields,
      );
      if (!_.isEmpty(changedData)) {
        dataChanges.push(
          angular.extend(
            {},
            {
              branchId: branchData.branchId,
            },
            changedData,
          ),
        );
      }
    });

    return dataChanges;
  }

  setupMatrix() {
    const maxTextWidth = _.max(
      _.map(this.article.nodeYValues, (y) =>
        y.name ? y.name.length : y.matrixNodeValue.name.length,
      ),
    );

    const columnDefs = this.priceMatrixService.getColumns({
      matrixLabel: `${this.article.matrixNodeY.name} / ${this.article.matrixNodeX.name}`,
      nodeXValues: this.article.nodeXValues,
      cellTemplate: 'directives/matrixCell/extendedMatrixCell.tpl.html',
      width: maxTextWidth * 10 + 175,
    });

    columnDefs[0].cellTemplate = 'directives/matrixCell/rowHeader.tpl.html';
    _.forEach(columnDefs, (col) => {
      if (['matrixNodeValueId'] in col) {
        const minWidth = col.displayName.length * 16;
        col.minWidth = minWidth > 150 ? minWidth : 150;
        col.fieldInformation = _.find(this.colHeader, {
          matrixNodeValueXId: col.matrixNodeValueId,
        });
        col.headerCellTemplate = 'directives/matrixCell/colHeader.tpl.html';
      }
    });

    this.gridOptions = {
      data: this.priceMatrixService.getRows(this.article.nodeYValues),
      columnDefs,
    };

    const self = this;
    self.tableHeight = `height: ${self.gridOptions.data.length * 30 + 56}px`;
    // The window needs 1 frame for the table height to be refreshed
    setTimeout(() => {
      self.$scope.gridApi.grid.handleWindowResize();
      self.$scope.gridApi.core.refresh();
    }, 1);

    let currentMatrixValues = null;
    let currentMatrixInformation = null;

    if (this.selectedBranch !== null) {
      currentMatrixValues = null;
      currentMatrixInformation = _.find(this.variantBranchDataCopy, {
        branchId: this.selectedBranch.id,
      });
    } else {
      currentMatrixValues = this.matrixValues;
      currentMatrixInformation = this.sharedDataCopy;
    }

    const finalizeUrlBase = `variants/${this.variant.id}/photo`;

    _.forEach(this.gridOptions.data, (row) => {
      row.fieldInformation = _.find(this.rowHeader, {
        matrixNodeValueYId: row.matrixNodeValueId,
      });
      _.forEach(this.article.nodeXValues, (xNode) => {
        const x = xNode.id;
        const y = row.matrixNodeValueId;
        const branchId = currentMatrixInformation.branchId;
        const matrixValue = _.find(currentMatrixValues, {
          matrixNodeValueXId: x,
          matrixNodeValueYId: y,
        });
        const cellInformation = _.find(
          currentMatrixInformation.cellInformation,
          {
            matrixNodeValueXId: x,
            matrixNodeValueYId: y,
          },
        );
        const colValue = {
          branchId,
          matrixNodeValueXId: x,
          matrixNodeValueYId: y,
          isEdited: !!matrixValue && !!matrixValue.photo,
          label: `${row.rowName}-${xNode.name || xNode.matrixNodeValue.name}`,
          matrixValue: matrixValue || {
            matrixNodeValueXId: x,
            matrixNodeValueYId: y,
          },
          cellInformation: cellInformation || {
            matrixNodeValueXId: x,
            matrixNodeValueYId: y,
          },
        };
        row[xNode.id] = colValue;

        const finalizeUrl = `${finalizeUrlBase}?matrixNodeValueXId=${x}&matrixNodeValueYId=${y}`;

        this.photoInstances.push(
          this.UploaderService.instance(`photoInstance-${x}-${y}`, {
            finalizeUrl,
            onDone: (success, file) =>
              this.photoUploadDone(success, file, colValue),
            keepBoth: true,
            single: true,
            disabled: !this.currentUserCanEdit,
            validate: (file) => this.validateImage(file),
          }),
        );
      });
    });
  }

  photoUploadDone(success, file, colValue) {
    if (success) {
      colValue.matrixValue.photoId = file.id;
      colValue.matrixValue.photo = file;
      colValue.isEdited = true;
    }
  }

  validateImage(file) {
    this.validationErrors = [];
    const mimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file.size > 20000000) {
      this.validationErrors.push({
        message: 'File size is too large (limit: 20mb)',
      });
      return false;
    }

    if (mimeTypes.indexOf(file.type) === -1) {
      this.validationErrors.push({
        message: 'File must be of type jpg, png or gif',
      });
      return false;
    }
  }

  setupWatchers() {
    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
    });
  }

  setVariantBranchData(variantBranchData) {
    this.variantBranchData = variantBranchData;

    const fullVarianBranchData = [];
    _.forEach(variantBranchData, (branch) => {
      const cellInfo = [];
      _.forEach(this.article.nodeXValues, (x) => {
        _.forEach(this.article.nodeYValues, (y) => {
          const existingCellInfo = _.find(branch.cellInformation, {
            matrixNodeValueXId: x.id,
            matrixNodeValueYId: y.id,
          });
          if (existingCellInfo !== undefined) {
            cellInfo.push(existingCellInfo);
          } else {
            cellInfo.push({
              matrixNodeValueXId: x.id,
              matrixNodeValueYId: y.id,
            });
          }
        });
      });
      fullVarianBranchData.push({
        branchId: branch.branchId,
        cellInformation: cellInfo,
      });
    });

    this.variantBranchData = fullVarianBranchData;
    this.variantBranchDataCopy = angular.copy(this.variantBranchData);
  }

  setSharedData() {
    const allCellInformation = _.flatMap(
      this.variantBranchData,
      (branchData) => {
        return branchData.cellInformation;
      },
    );

    const groupedCellInformation = _.reduce(
      allCellInformation,
      (groupedData, cellInfo) => {
        const existingGroup = _.find(groupedData, {
          matrixNodeValueXId: cellInfo.matrixNodeValueXId,
          matrixNodeValueYId: cellInfo.matrixNodeValueYId,
        });
        if (existingGroup) {
          existingGroup.values.push(cellInfo);
        } else {
          const group = {
            matrixNodeValueXId: cellInfo.matrixNodeValueXId,
            matrixNodeValueYId: cellInfo.matrixNodeValueYId,
            values: [cellInfo],
          };
          groupedData.push(group);
        }
        return groupedData;
      },
      [],
    );

    const branchCount = this.branches.length;
    const sharedDataGroupedByCell = _.reduce(
      groupedCellInformation,
      (sharedData, group) => {
        const data = _.reduce(
          this.sharedCellFields,
          (data, field) => {
            const valuesWithFieldData = _.filter(group.values, (v) =>
              this.fieldHasValue(v[field]),
            );

            if (valuesWithFieldData.length < branchCount) {
              return data;
            }

            const uniqFields = _.uniqBy(group.values, field);
            if (uniqFields.length === 1) {
              data[field] = group.values[0][field];
            }
            data.matrixNodeValueXId = group.matrixNodeValueXId;
            data.matrixNodeValueYId = group.matrixNodeValueYId;
            return data;
          },
          {},
        );
        if (_.isEmpty(data)) {
          return sharedData;
        }
        const dataGroup = data;
        sharedData.push(dataGroup);

        return sharedData;
      },
      [],
    );

    const sharedInformation = { cellInformation: sharedDataGroupedByCell };

    const fullVarianSharedData = [];
    const tmpCellInformation = [];

    _.forEach(this.article.nodeXValues, (x) => {
      _.forEach(this.article.nodeYValues, (y) => {
        const existingCellInformation = _.find(
          sharedInformation.cellInformation,
          {
            matrixNodeValueXId: x.id,
            matrixNodeValueYId: y.id,
          },
        );
        if (existingCellInformation !== undefined) {
          tmpCellInformation.push(existingCellInformation);
        } else {
          tmpCellInformation.push({
            matrixNodeValueXId: x.id,
            matrixNodeValueYId: y.id,
          });
        }
      });
    });
    fullVarianSharedData.push({ cellInformation: tmpCellInformation });

    this.sharedData = { cellInformation: tmpCellInformation };
    this.sharedDataCopy = angular.copy(this.sharedData);
  }

  selectShared() {
    this.selectedBranch = null;
    this.selectedBranchData = null;
    this.setHeaderInformation();
    this.setupMatrix();
  }

  selectBranch(branch) {
    this.selectedBranch = branch;
    this.selectedBranchData = _.find(this.variantBranchDataCopy, {
      branchId: branch.id,
    });
    this.setHeaderInformation();
    this.setupMatrix();
  }

  setHeaderInformation() {
    let branchId = null;
    let currentMatrixData = null;
    if (this.selectedBranch !== null) {
      branchId = this.selectedBranch.id;
      currentMatrixData = _.find(this.variantBranchDataCopy, { branchId });
    } else {
      branchId = null;
      currentMatrixData = this.sharedDataCopy;
    }

    this.colHeader = [];
    _.forEach(this.article.nodeXValues, (x) => {
      const xCells = _.filter(
        currentMatrixData.cellInformation,
        (cell) => cell.matrixNodeValueXId === x.id,
      );
      const xHeaderCell = { branchId, matrixNodeValueXId: x.id };
      _.forEach(this.sharedCellFields, (field) => {
        if (_.every(xCells, [field, xCells[0][field]])) {
          xHeaderCell[field] = xCells[0][field];
        }
      });
      this.colHeader.push(xHeaderCell);
    });

    this.rowHeader = [];
    _.forEach(this.article.nodeYValues, (y) => {
      const yCells = _.filter(
        currentMatrixData.cellInformation,
        (cell) => cell.matrixNodeValueYId === y.id,
      );
      const yHeaderCell = { branchId, matrixNodeValueYId: y.id };
      _.forEach(this.sharedCellFields, (field) => {
        if (_.every(yCells, [field, yCells[0][field]])) {
          yHeaderCell[field] = yCells[0][field];
        }
      });
      this.rowHeader.push(yHeaderCell);
    });
  }

  setRowOrCol(fieldInformation) {
    let currentMatrixData = {};
    if (this.selectedBranchData !== null) {
      currentMatrixData = _.find(this.variantBranchDataCopy, {
        branchId: this.selectedBranchData.branchId,
      });
    } else {
      currentMatrixData = this.sharedDataCopy;
    }
    const currentBranchRowCell = _.filter(
      currentMatrixData.cellInformation,
      (cell) =>
        cell.matrixNodeValueYId === fieldInformation.matrixNodeValueYId ||
        cell.matrixNodeValueXId === fieldInformation.matrixNodeValueXId,
    );
    _.forEach(this.sharedCellFields, (field) => {
      if (field in fieldInformation) {
        _.forEach(currentBranchRowCell, (cell) => {
          cell[field] = fieldInformation[field];
        });
      }
    });
    if (this.selectedBranchData === null) {
      this.sharedToBranches();
    }
  }

  sharedToBranches() {
    _.forEach(this.sharedDataCopy.cellInformation, (sharedCell) => {
      if (_.size(sharedCell) > 2) {
        _.forEach(this.variantBranchDataCopy, (branchData) => {
          const existingBranchCell = _.find(branchData.cellInformation, {
            matrixNodeValueXId: sharedCell.matrixNodeValueXId,
            matrixNodeValueYId: sharedCell.matrixNodeValueYId,
          });
          _.forEach(this.sharedCellFields, (field) => {
            if (sharedCell[field] !== undefined) {
              existingBranchCell[field] = sharedCell[field];
            }
          });
        });
      }
    });
  }

  textDiffers(i, x, y) {
    if (this.selectedBranch !== null) {
      return false;
    }
    const field = this.sharedCellFields[i];
    const allCells = _.flatMap(this.variantBranchDataCopy, (branchData) => {
      return branchData.cellInformation;
    });
    const cellFieldMatch = _.filter(
      allCells,
      (c) =>
        this.fieldHasValue(c[field]) &&
        c.matrixNodeValueXId === x &&
        c.matrixNodeValueYId === y,
    );
    const sharedCell = _.find(this.sharedDataCopy.cellInformation, {
      matrixNodeValueXId: x,
      matrixNodeValueYId: y,
    });
    if (sharedCell[field] === undefined && cellFieldMatch.length > 0) {
      return true;
    }
    return false;
  }

  nodeDiffers(i, fieldInformation) {
    const field = this.sharedCellFields[i];
    let currentMatrixData = {};
    if (this.selectedBranch !== null) {
      currentMatrixData = _.find(this.variantBranchDataCopy, {
        branchId: this.selectedBranch.id,
      });
    } else {
      currentMatrixData.cellInformation = _.flatMap(
        this.variantBranchDataCopy,
        (branchData) => {
          return branchData.cellInformation;
        },
      );
    }
    const nodeCellMatch = _.filter(
      currentMatrixData.cellInformation,
      (c) =>
        this.fieldHasValue(c[field]) &&
        (c.matrixNodeValueXId === fieldInformation.matrixNodeValueXId ||
          c.matrixNodeValueYId === fieldInformation.matrixNodeValueYId),
    );
    if (!fieldInformation[field] && nodeCellMatch.length > 0) {
      return true;
    }
    return false;
  }

  clearField(i, x, y) {
    const field = this.sharedCellFields[i];
    _.forEach(this.variantBranchDataCopy, (branchData) => {
      const cellInformation = _.find(branchData.cellInformation, (c) => {
        return (
          this.fieldHasValue(c[field]) &&
          (c.matrixNodeValueXId === x || c.matrixNodeValueYId === y)
        );
      });
      if (cellInformation) {
        cellInformation[field] = null;
      }
    });
  }

  clearNode(i, fieldInformation) {
    const field = this.sharedCellFields[i];
    let currentMatrixData = {};
    if (this.selectedBranch !== null) {
      currentMatrixData = _.find(this.variantBranchDataCopy, {
        branchId: this.selectedBranch.id,
      });
    } else {
      currentMatrixData.cellInformation = _.flatMap(
        this.variantBranchDataCopy,
        (branchData) => {
          return branchData.cellInformation;
        },
      );
      currentMatrixData.cellInformation =
        currentMatrixData.cellInformation.concat(
          this.sharedDataCopy.cellInformation,
        );
    }
    const nodeCellMatch = _.filter(
      currentMatrixData.cellInformation,
      (c) =>
        this.fieldHasValue(c[field]) &&
        (c.matrixNodeValueXId === fieldInformation.matrixNodeValueXId ||
          c.matrixNodeValueYId === fieldInformation.matrixNodeValueYId),
    );
    _.forEach(nodeCellMatch, (c) => {
      if (this.fieldHasValue(c[field])) {
        c[field] = null;
      }
    });
  }

  fieldHasValue(value) {
    return (
      _.isString(value) ||
      _.isNumber(value) ||
      (_.isBoolean(value) && value === true)
    );
  }
}

angular
  .module('main.article.variant.matrix', [])
  .config(config)
  .controller('VariantMatrixController', VariantMatrixController);
