// @ngInject
const ArticleDialogService = (
  $mdDialog,
  $rootScope,
  $q,
  OrganizationsApiService,
  CustomersApiService,
  ArticleTagsApiService,
  GarpApiService,
  ProductGroupsApiService,
) => ({
  showDialog: ({
    customerId = null,
    conceptId = null,
    organization = $rootScope.currentUser.organization || null,
    article = null,
    isCopy = false,
    includePurchasePrices = true,
    includeSalesPrices = false,
    includeVariants = false,
  } = {}) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ArticleDialogController as vm',
      templateUrl: 'services/dialogs/articleDialog/articleDialog.tpl.html',
      locals: {
        customerId,
        conceptId,
        article,
        organization,
        isCopy,
        includePurchasePrices,
        includeSalesPrices,
        includeVariants,
      },
      resolve: {
        articleTemplates: () =>
          article
            ? $q
                .all([
                  OrganizationsApiService.loadMatrixNodes(organization),
                  OrganizationsApiService.loadMatrixGroups(organization),
                ])
                .then(
                  () => [],
                  (err) => $q.reject(err),
                )
            : $q
                .all([
                  OrganizationsApiService.loadMatrixNodes(organization),
                  OrganizationsApiService.loadMatrixGroups(organization),
                  OrganizationsApiService.loadArticleTemplates(organization),
                ])
                .then(
                  (res) => res[2],
                  (err) => $q.reject(err),
                ),
        customers: () =>
          article
            ? $q.resolve([])
            : CustomersApiService.getAllByOrganizationId(organization.id, true),
        tags: () =>
          article
            ? $q.resolve([])
            : ArticleTagsApiService.getAllByOrganizationId(organization.id),
        garpData: () =>
          article
            ? GarpApiService.getGarpData(article.mainVariantId)
            : $q.resolve([]),
        productGroups: () =>
          ProductGroupsApiService.getAllByOrganizationId(organization.id),
      },
    }),
});

class ArticleDialogController {
  constructor(
    $filter,
    $mdDialog,
    ArticlesApiService,
    ConceptsApiService,
    matrixNodeService,
    CustomersApiService,
    GarpApiService,
    toasterService,
    HelperService,
    articleTemplates,
    organization,
    customerId,
    conceptId,
    article,
    customers,
    productGroups,
    tags,
    garpData,
    isCopy,
    includePurchasePrices,
    includeSalesPrices,
    includeVariants,
  ) {
    'ngInject';

    this.$filter = $filter;
    this.$mdDialog = $mdDialog;
    this.ArticlesApiService = ArticlesApiService;
    this.ConceptsApiService = ConceptsApiService;
    this.GarpApiService = GarpApiService;
    this.toasterService = toasterService;
    this.HelperService = HelperService;
    this.matrixNodeService = matrixNodeService;
    this.matrixGroups = organization.matrixGroups;
    this.originalArticle = article;
    this.customers = customers;
    this.tags = tags;
    this.garpData = garpData;
    this.productGroups = productGroups;
    this.selectedTags = [];

    this.validationErrors = [];
    this.article = {};
    this.isCopy = isCopy;
    this.includePurchasePrices = includePurchasePrices;
    this.includeSalesPrices = includeSalesPrices;
    this.includeVariants = includeVariants;
    this.selectedCustomer = null;
    this.selectedCopyCustomer = null;
    this.selectedCopyArticle = null;
    this.selectedConcept = null;
    this.selectedMasterBranch = null;
    this.selectedProductGroup = null;
    this.articleTemplates = [];
    this.articles = [];
    this.branches = [];
    this.concepts = [];
    this.articleTemplate = {};
    this.customXValueNames = {};
    this.customYValueNames = {};
    this.nodeXValues = [];
    this.nodeYValues = [];
    this.displayTextField = 'itemCode';
    this.maximumYNodeValues = 62;
    this.maximumXNodeValues = 30;
    this.canAddNodeXValues = true;
    this.canAddNodeYValues = true;

    if (article) {
      this.article.id = article.id;
      this.article.name = article.name;
      this.article.articleTags = article.articleTags;
      if (article.matrixNodeX && article.matrixNodeY) {
        this.articleTemplate = {
          matrixNodeX: article.matrixNodeX,
          matrixNodeXId: article.matrixNodeX.id,
          matrixNodeY: article.matrixNodeY,
          matrixNodeYId: article.matrixNodeY.id,
        };
      }
      this.newArticle = false;
    } else {
      this.newArticle = true;
      this.articleTemplates = _.filter(articleTemplates, { archived: false });
      if (this.articleTemplates.length > 0) {
        this.articleTemplate = this.articleTemplates[0];
        this.article.articleTemplateId = this.articleTemplate.id;
      }
      if (customerId) {
        this.article.customerId = customerId;
        $mdDialog.startLoadingSpinner();
        CustomersApiService.getById(customerId, true)
          .then((response) => {
            this.selectedCustomer = response;
            this.loadConcepts(response.id);
          })
          .finally(() => $mdDialog.stopLoadingSpinner());
      }
      if (conceptId) {
        this.article.conceptId = conceptId;
        $mdDialog.startLoadingSpinner();
        ConceptsApiService.getById(conceptId, true)
          .then((response) => (this.selectedConcept = response))
          .finally(() => $mdDialog.stopLoadingSpinner());
      }
      this.article.articleTags = [];
    }
    this.branches = _.clone(organization.branches);

    this.resetNodeValues();
    if (article) {
      const mapArticleValue = (value) => ({
        id: value.id,
        identifier: value.matrixNodeValue.identifier,
        name: value.name || value.matrixNodeValue.name,
      });
      this.article.nodeXValues = _.map(
        article.nodeXValues || [],
        mapArticleValue,
      );
      this.article.nodeYValues = _.map(
        article.nodeYValues || [],
        mapArticleValue,
      );
      this.article.branches = _.filter(
        this.branches,
        (branch) => !!_.find(article.branches, { id: branch.id }),
      );
      _.each(this.article.branches, (b) => {
        const branchData = _.find(
          this.garpData.branchData,
          (bd) => bd.branchId === b.id,
        );
        if (branchData) {
          b.blockedFromSync = branchData.blockedFromSync;
        }
      });
    } else {
      this.article.branches = _.clone(this.branches);
    }

    this.customerId = customerId;
    this.conceptId = conceptId;
    this.article.variable = false;
  }

  setProductGroup(productGroup) {
    this.article.productGroupId = productGroup ? productGroup.id : 0;
    this.article.productGroup = productGroup;
  }

  getCurrentProductGroups() {
    const productGroups = _.filter(this.productGroups, { archived: false });
    return productGroups;
  }

  filterProductGroups(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentProductGroups(), { value: query }),
      ['value'],
    );
  }

  onItemCodeChange() {
    if (this.showImport) {
      this.showImport = false;
    }
  }

  onCustomerSelect(customer) {
    this.concepts = [];
    if (customer) {
      this.loadConcepts(customer.id);
    }
    this.selectedConcept = null;
    this.conceptSearchText = '';
  }

  onCopyCustomerSelect(customer) {
    this.articles = [];
    this.selectedCopyArticle = null;
    if (customer) {
      this.loadArticles(customer.id);
    }
  }

  onArticleSelect(article) {
    this.validationErrors = [];
    this.invalidCopyArticle = false;

    if (article) {
      this.ArticlesApiService.getById(article.id).then((article) => {
        if (article) {
          this.article.basedOnArticleId = article.id;
          this.article.name = article.name;
          this.article.itemCodeMinLength = article.itemCodeMinLength;
          this.article.itemCodeMaxLength = article.itemCodeMaxLength;
          this.article.articleTags = article.articleTags;
          this.articleTemplate = _.find(
            this.articleTemplates,
            (t) =>
              t.matrixNodeXId === article.matrixNodeXId &&
              t.matrixNodeYId === article.matrixNodeYId,
          );
          if (this.articleTemplate) {
            this.resetNodeValues();
          } else {
            this.invalidCopyArticle = true;
            this.validationErrors.push({
              message:
                'Cannot copy from this article because it contains archived matrix nodes',
            });
          }
          const mapArticleValue = (value) => ({
            id: value.id,
            identifier: value.matrixNodeValue.identifier,
            name: value.name || value.matrixNodeValue.name,
          });
          this.article.nodeXValues = _.map(
            article.nodeXValues || [],
            mapArticleValue,
          );
          this.article.nodeYValues = _.map(
            article.nodeYValues || [],
            mapArticleValue,
          );
          this.article.branches = _.filter(
            this.branches,
            (branch) => !!_.find(article.branches, { id: branch.id }),
          );
        }
      });
    }
  }

  onConceptSelect(concept) {
    if (concept) {
      this.article.conceptId = concept.id;
    } else if (!this.newArticle) {
      this.article.conceptId = 0;
    } else {
      delete this.article.conceptId;
    }
  }

  loadConcepts(customerId) {
    this.$mdDialog.startLoadingSpinner();
    this.ConceptsApiService.getAllByCustomerId(customerId)
      .then((response) => (this.concepts = response))
      .finally(() => this.$mdDialog.stopLoadingSpinner());
  }

  loadArticles(customerId) {
    this.$mdDialog.startLoadingSpinner();
    this.ArticlesApiService.getAllByCustomerId(customerId)
      .then((response) => (this.articles = response))
      .finally(() => this.$mdDialog.stopLoadingSpinner());
  }

  filterEntities(entities, query) {
    return this.$filter('filter')(entities, {
      name: query,
      archived: false,
    });
  }

  filterArticles(query) {
    return _.orderBy(
      _.filter(this.articles, (a) =>
        this.HelperService.containsText(a, { name: query, itemCode: query }),
      ),
      'itemCode',
    );
  }

  filterTemplates(query) {
    return _.orderBy(
      this.$filter('filter')(this.articleTemplates, { name: query }),
      'name',
    );
  }

  filterTags(query) {
    return _.chain(this.$filter('filter')(this.tags, { name: query }))
      .filter((tag) => !_.find(this.article.articleTags, { id: tag.id }))
      .value();
  }

  filterAvailableBranches(query) {
    return this.$filter('filter')(this.availableBranches, {
      name: query,
    });
  }

  loadValues(query, nodeValues, articleValues) {
    query = (query || '').toLowerCase();
    return this.$filter('filter')(nodeValues, (value) => {
      if (value.group) {
        return value.name.toLowerCase().indexOf(query) !== -1;
      }
      return (
        (value.name.toLowerCase().indexOf(query) !== -1 ||
          value.identifier.toLowerCase().indexOf(query) !== -1) &&
        !_.find(articleValues, { id: value.id })
      );
    });
  }

  loadXValues(query) {
    return this.loadValues(query, this.nodeXValues, this.article.nodeXValues);
  }

  loadYValues(query) {
    return this.loadValues(query, this.nodeYValues, this.article.nodeYValues);
  }

  xSelected(value) {
    this.selectedXValue = null;
    this.xValueSearchText = null;

    if (this.article.nodeXValues.length >= this.maximumXNodeValues) {
      this.canAddNodeXValues = false;
    }

    if (!this.canAddNodeXValues) {
      return;
    }

    this.addNodeValue(this.nodeXValues, this.article.nodeXValues, value);
  }

  ySelected(value) {
    this.selectedYValue = null;
    this.yValueSearchText = null;

    if (this.article.nodeYValues.length >= this.maximumYNodeValues) {
      this.canAddNodeYValues = false;
    }

    if (!this.canAddNodeYValues) {
      return;
    }

    this.addNodeValue(this.nodeYValues, this.article.nodeYValues, value);
  }

  addNodeValue(nodeValues, articleNodeValues, value) {
    if (!value) {
      return;
    }
    if (value.group) {
      for (let i = 0; i < value.matrixNodeValues.length; i++) {
        const groupValue = value.matrixNodeValues[i];
        const existing = _.find(nodeValues, {
          group: false,
          id: groupValue.id,
        });
        if (existing && !_.find(articleNodeValues, { id: existing.id })) {
          const newValue = _.extend({}, existing);
          if (groupValue.name) {
            newValue.name = groupValue.name;
          }
          articleNodeValues.push(newValue);
        }
      }
    } else if (!_.find(articleNodeValues, { id: value.id })) {
      articleNodeValues.push(_.extend({}, value));
    }
  }

  removeXValue(value) {
    _.remove(this.article.nodeXValues, value);
    this.canAddNodeXValues = true;
  }

  removeYValue(value) {
    _.remove(this.article.nodeYValues, value);
    this.canAddNodeYValues = true;
  }

  changeTemplate(template) {
    if (!this.newArticle) {
      return;
    }

    if (template) {
      this.article.articleTemplateId = this.articleTemplate.id;
      this.resetNodeValues();

      if (
        !this.article.itemCode ||
        this.article.itemCode.length > this.articleTemplate.itemCodeMaxLength
      ) {
        this.article.itemCode = '';
      }
    } else {
      this.article.articleTemplateId = 0;
      this.article.articleTemplate = null;
    }
  }

  resetNodeValues() {
    const mapGroup = (group) => ({
      group: true,
      name: group.name,
      matrixNodeValues: group.matrixNodeValues,
    });

    const mapValue = (value) => ({
      group: false,
      id: value.id,
      identifier: value.identifier,
      name: value.name,
    });

    const xGroups = this.articleTemplate.matrixNodeXId
      ? _.map(
          _.filter(this.matrixGroups, {
            matrixNodeId: this.articleTemplate.matrixNodeXId,
          }),
          mapGroup,
        )
      : [];
    const yGroups = this.articleTemplate.matrixNodeYId
      ? _.map(
          _.filter(this.matrixGroups, {
            matrixNodeId: this.articleTemplate.matrixNodeYId,
          }),
          mapGroup,
        )
      : [];

    this.nodeXValues = xGroups.concat(
      _.map(
        _.filter(
          _.get(this.articleTemplate, 'matrixNodeX.matrixNodeValues', []),
          { archived: false },
        ),
        mapValue,
      ),
    );
    this.nodeYValues = yGroups.concat(
      _.map(
        _.filter(
          _.get(this.articleTemplate, 'matrixNodeY.matrixNodeValues', []),
          { archived: false },
        ),
        mapValue,
      ),
    );
    this.article.nodeXValues = [];
    this.article.nodeYValues = [];
  }

  validateInput(nodeValues, value) {
    this.matrixNodeService.validateInput(nodeValues, value, true);
  }

  keyDown($event, nodeValues, value) {
    this.matrixNodeService.keyDown($event, nodeValues, value);
  }

  save(force) {
    this.validationErrors = [];
    this.$mdDialog.startLoadingSpinner();
    const articleCopy = _.extend({}, this.article);
    articleCopy.variable = this.article.variable;
    if (force) {
      articleCopy.force = true;
    }
    if (
      !!_.get(this.articleTemplate, 'matrixNodeX.matrixNodeValues') &&
      !!_.get(this.articleTemplate, 'matrixNodeY.matrixNodeValues')
    ) {
      articleCopy.nodeXValues = _.map(this.article.nodeXValues, (value) => {
        const node = { id: value.id };
        const originalValue = _.find(this.nodeXValues, {
          group: false,
          id: node.id,
        });
        if (originalValue && value.name !== originalValue.name) {
          node.name = value.name;
        }
        return node;
      });
      articleCopy.nodeYValues = _.map(this.article.nodeYValues, (value) => {
        const node = { id: value.id };
        const originalValue = _.find(this.nodeYValues, {
          group: false,
          id: node.id,
        });
        if (originalValue && value.name !== originalValue.name) {
          node.name = value.name;
        }
        return node;
      });
    } else {
      delete articleCopy.nodeXValues;
      delete articleCopy.nodeYValues;
    }
    if (this.newArticle) {
      if (this.isCopy) {
        articleCopy.includePurchasePrices = this.includePurchasePrices;
        articleCopy.includeSalesPrices = this.includeSalesPrices;
        articleCopy.includeVariants = this.includeVariants;
      }
      if (!this.selectedCustomer) {
        this.validationErrors.push({ message: 'Please select a customer' });
        this.$mdDialog.stopLoadingSpinner();
        return;
      }
      if (!this.article.articleTags.length > 0) {
        this.validationErrors.push({
          message: 'Please choose at least one tag',
        });
        this.$mdDialog.stopLoadingSpinner();
        return;
      }
      if (this.selectedMasterBranch) {
        articleCopy.MasterBranchId = this.selectedMasterBranch.id;
      }
      if (this.selectedProductGroup) {
        articleCopy.productGroupId = this.selectedProductGroup.id;
      }
      this.ArticlesApiService.newArticle(articleCopy, this.selectedCustomer.id)
        .then(
          (article) => {
            this.toasterService.success();
            this.$mdDialog.hide(article);
          },
          (error) => {
            this.validationErrors = error.errors;
            if (error.status === 409) {
              this.showImport = true;
              return this.GarpApiService.getArticleBranches(
                this.article.itemCode,
              ).then((result) => {
                this.availableBranches = result;
              });
            }
          },
        )
        .finally(() => this.$mdDialog.stopLoadingSpinner());
    } else {
      const data = {};
      if (articleCopy.nodeXValues && articleCopy.nodeYValues) {
        data.nodeXValues = articleCopy.nodeXValues;
        data.nodeYValues = articleCopy.nodeYValues;
      }
      if (
        this.HelperService.isArrayModified(
          _.map(this.originalArticle.branches, 'id'),
          _.map(articleCopy.branches, 'id'),
        ) ||
        this.HelperService.isArrayModified(
          _.map(this.garpData.branchData, 'blockedFromSync'),
          _.map(articleCopy.branches, 'blockedFromSync'),
        )
      ) {
        data.branches = articleCopy.branches;
      }

      if (_.isEmpty(data)) {
        this.$mdDialog.stopLoadingSpinner();
        this.$mdDialog.hide(this.originalArticle);
        return;
      }

      this.ArticlesApiService.updateArticle(data, this.article.id)
        .then(
          (article) => {
            this.toasterService.success();
            this.$mdDialog.hide(article);
          },
          (error) => (this.validationErrors = error.errors),
        )
        .finally(() => this.$mdDialog.stopLoadingSpinner());
    }
  }

  addTag($chip) {
    if (_.isString($chip)) {
      $chip = { name: $chip };
    }

    if (
      _.some(
        this.article.articleTags,
        ({ name }) => name.toLowerCase() === $chip.name.toLowerCase(),
      )
    ) {
      return null;
    } else {
      return { name: $chip.name, id: $chip.id };
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  shouldFocus(field) {
    switch (field) {
      case 'copy':
        return this.isCopy;
      case 'customer':
        return !this.customerId && !this.isCopy;
      case 'concept':
        return this.customerId && !this.conceptId && !this.isCopy;
      case 'itemcode':
      default:
        return this.customerId && this.conceptId && !this.isCopy;
    }
  }

  isBranchInactive(branch, articleBranches) {
    if (!_.some(articleBranches, (ab) => ab.id === branch.id)) {
      branch.blockedFromSync = false;
      return true;
    }
    return false;
  }
}

angular
  .module('services.dialogs.articleDialog', [])
  .service('ArticleDialogService', ArticleDialogService)
  .controller('ArticleDialogController', ArticleDialogController);
