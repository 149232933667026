// @ngInject
const assignedUserConfirmation = () => ({
  templateUrl:
    'directives/assignedUserConfirmation/assignedUserConfirmation.tpl.html',
  restrict: 'E',
  replace: true,
  scope: {},

  bindToController: {
    taskId: '=',
    assignedUser: '=',
    onChange: '&',
  },

  controller: 'AssignedUserConfirmationController',
  controllerAs: 'vm',
});

class AssignedUserConfirmationController {
  constructor(
    loadSpinnerService,
    TasksApiService,
    DeclineConfirmationDialogService,
    toasterService,
  ) {
    'ngInject';
    this._DeclineConfirmationDialogService = DeclineConfirmationDialogService;
    this._TasksApiService = TasksApiService;
    this._loadSpinnerService = loadSpinnerService;
    this._toasterService = toasterService;
  }

  confirm(confirmation, message) {
    this._loadSpinnerService.start('mainSpinner');
    this._TasksApiService
      .updateAssignedUser(this.taskId, this.assignedUser.userId, {
        confirmation,
        confirmationMessage: message,
      })
      .then(() => {
        this._toasterService.success();
        if (this.onChange) {
          this.onChange();
        }
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }

  decline() {
    this._DeclineConfirmationDialogService.showDialog({
      confirm: (confirmation, message) => {
        this.confirm(confirmation, message);
      },
    });
  }
}

angular
  .module('directives.assignedUserConfirmation', [])
  .controller(
    'AssignedUserConfirmationController',
    AssignedUserConfirmationController,
  )
  .directive('assignedUserConfirmation', assignedUserConfirmation);
