import { Checkbox, Spinner, Text, Button, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SupplierVariant, variantsApi } from '@texas/api/endpoints/variantsApi';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useProdutApprovalWizardState } from '../../../context';

export function SelectSuppliers({ page }: { page: string }) {
  const { t } = useTranslation();

  const {
    data: suppliers,
    refetch,
    loading,
  } = useApiResource(variantsApi.getSuppliers);

  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();
  const [filteredSuppliers, setFilteredSuppliers] = useState<SupplierVariant[]>(
    [],
  );

  useEffect(() => {
    if (data.selectedSuppliers.length > 0) {
      setStepIsValid(page, true);
      return;
    }

    setStepIsValid(page, false);
  }, [data.selectedSuppliers.length, setStepIsValid, page]);

  useEffect(() => {
    refetch(data.variantId);
  }, [refetch, data.variantId]);

  useEffect(() => {
    if (suppliers != null)
      setFilteredSuppliers(
        suppliers.filter((s) =>
          data.selectedBranches.map((b) => b.id).includes(s.branchId),
        ),
      );
  }, [data.selectedBranches, setFilteredSuppliers, suppliers]);

  return (
    <>
      {loading && (
        <Flex gap={2} alignItems="center">
          <Text>{t('general.loading')}</Text>
          <Spinner size="sm" />
        </Flex>
      )}
      {suppliers && filteredSuppliers.length > 0 && (
        <Button
          mx={4}
          width="fit-content"
          size="sm"
          variant="texas-outline-light"
          isLoading={loading}
          mb={2}
          onClick={() =>
            setData((d) => ({
              ...d,
              selectedSuppliers: filteredSuppliers.map((s) => {
                const branch = data.selectedBranches.find(
                  (b2) => b2.id === s.branchId,
                );

                return {
                  id: s.id,
                  name: s.name,
                  defaultContact: null,
                  branch: {
                    id: branch!.id,
                    identifier: branch!.identifier,
                    name: branch!.name,
                  },
                };
              }),
            }))
          }
        >
          {t('general.selectAll')}
        </Button>
      )}

      {data.selectedBranches.map((b) => {
        const branchSuppliers = suppliers?.filter((s) => s.branchId === b.id);

        if (branchSuppliers?.length === 0) {
          return (
            <>
              <Text
                key={b.id}
                fontWeight="bold"
                borderBottom="1px solid gray"
                px={4}
                pt={3}
                pb={1}
              >
                {b.name}
              </Text>
              <Text variant="sub" px={4} py={1}>
                {t('suppliers.noSuppliers')}
              </Text>
            </>
          );
        }

        return (
          <React.Fragment key={b.id}>
            <Text
              fontWeight="bold"
              borderBottom="1px solid gray"
              px={4}
              pt={3}
              pb={1}
            >
              {b.name}
            </Text>

            {branchSuppliers?.map((x) => {
              const toggled = data.selectedSuppliers.some(
                (s) => s.id === x.id && s.branch.id === x.branchId,
              );
              return (
                <Flex
                  key={x.id}
                  px={4}
                  py={1}
                  cursor="pointer"
                  backgroundColor={toggled ? 'gray.600' : 'transparent'}
                  _hover={{
                    backgroundColor: 'gray.600',
                    opacity: toggled ? 1 : 0.7,
                  }}
                  _light={{
                    backgroundColor: toggled ? 'gray.100' : 'transparent',
                    _hover: {
                      backgroundColor: 'gray.100',
                      opacity: toggled ? 1 : 0.7,
                    },
                  }}
                  transition="all 200ms ease"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!toggled) {
                      setData((d) => ({
                        ...d,
                        selectedSuppliers: [
                          ...d.selectedSuppliers,
                          {
                            id: x.id,
                            name: x.name,
                            defaultContact: null,
                            branch: {
                              id: b.id,
                              name: b.name,
                              identifier: b.identifier,
                            },
                          },
                        ],
                      }));
                      return;
                    }

                    setData((d) => ({
                      ...d,
                      selectedSuppliers: d.selectedSuppliers.filter(
                        (b2) =>
                          !(b2.id === x.id && b2.branch.id === x.branchId),
                      ),
                    }));
                  }}
                  opacity={!toggled ? 0.4 : 1}
                >
                  <Text fontWeight="medium">{x.name}</Text>
                  <Checkbox
                    marginInlineStart="auto !important"
                    isChecked={toggled}
                  />
                </Flex>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
}
