import {
  Button,
  Flex,
  useDisclosure,
  Text,
  Box,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  Composition,
  CompositionMaterial,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { CreateCompositionMaterial } from '../composition/CreateCompositionMaterial';
import { UpdateCompositionMaterial } from '../composition/UpdateCompositionMaterial';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { GetColorFromCode } from '../../pantonePicker/PantonePicker';
import useFormat from '@texas/hooks/useFormat';
import { CompositionForm } from './CompositionForm';
import { fadeInRightAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { ColorDot } from '../ColorDot';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';

interface CompositionOverviewProps {
  composition: Composition;
  productGroupId: number | null;
  onMaterialChanged: () => void;
  onClose: () => void;
}

export function CompositionOverview({
  composition,
  productGroupId,
  onMaterialChanged,
}: CompositionOverviewProps) {
  const {
    isOpen: editIsOpen,
    value,
    onClose: editOnClose,
    onOpen: editOnOpen,
  } = useValueDisclosure<CompositionMaterial>();
  const { t } = useTranslation();

  return (
    <>
      {value && (
        <UpdateCompositionMaterial
          onUpdate={() => {
            onMaterialChanged();
          }}
          compositionMaterialId={value.id}
          productGroupId={productGroupId}
          isOpen={editIsOpen}
          onClose={editOnClose}
        />
      )}
      <Flex flexDir="column" gap={2} animation={fadeInRightAnimation()}>
        <Accordion allowMultiple={true}>
          <AccordionItem borderTop="none">
            <AccordionButton as={Flex} justifyContent="space-between">
              <Text fontWeight="bold">{t('composition.characteristics')}</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <CompositionForm composition={composition} />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom="none">
            <AccordionButton as={Flex} justifyContent="space-between">
              <Text fontWeight="bold">
                {t('composition.materialsCounts', {
                  count: composition.materials.length,
                })}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Grid rowGap={2} gridAutoRows="auto" gridAutoColumns="100%">
                {composition.materials.map((x) => {
                  return (
                    <GridItem key={x.id}>
                      <CompositionMaterialView
                        onClick={() => editOnOpen(x)}
                        material={x}
                        onRemoved={() => onMaterialChanged()}
                      />
                    </GridItem>
                  );
                })}
                <GridItem>
                  <AddMaterial
                    compositionId={composition.id}
                    productGroupId={productGroupId}
                    onCreate={() => {
                      onMaterialChanged();
                    }}
                  />
                </GridItem>
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  );
}

function CompositionMaterialView({
  material,
  onClick,
  onRemoved,
}: {
  material: CompositionMaterial;
  onClick: () => void;
  onRemoved: () => void;
}) {
  const { formatWeight } = useFormat();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      bg="texas.bg.700"
      _light={{ bg: 'gray.10' }}
      p={2}
      h="auto"
      role="group"
      borderRadius="md"
    >
      <Button
        boxSize="full"
        onClick={() => onClick()}
        variant="unstyled"
        _hover={{ opacity: 0.7 }}
      >
        <Flex flexDir="column" gap={2} alignItems="center">
          <Box>
            <Text whiteSpace="normal">{material.material.text}</Text>
            <Text
              overflowWrap="anywhere"
              noOfLines={3}
              fontSize="sm"
              fontWeight="normal"
              color="gray.300"
              css={{ textWrap: 'wrap' }}
            >
              {material.note}
            </Text>
          </Box>

          <Flex
            fontSize="sm"
            fontWeight="medium"
            gap={2}
            flexWrap="wrap"
            justifyContent="center"
          >
            <DottedArray
              values={[
                {
                  amount: material.qualities.length,
                  label: t('configuration.qualities'),
                },
                {
                  amount: material.treatments.length,
                  label: t('configuration.treatments'),
                },
                {
                  amount: material.techniques.length,
                  label: t('general.techniques'),
                },
              ]}
            />
            <Text fontSize="sm" fontWeight="medium" variant="sub">
              {formatWeight(material.weight)}
            </Text>
          </Flex>
          <Flex gap={2}>
            {material.colors.map((x) => {
              const color = GetColorFromCode(x.pantoneCode);

              if (!color) return null;
              return <ColorDot key={color.name} hex={color.hex} />;
            })}
          </Flex>
        </Flex>
      </Button>
      <Button
        variant="link"
        size="sm"
        colorScheme="red"
        _groupHover={{ opacity: 1 }}
        opacity={0}
        onClick={() => onOpen()}
      >
        {t('general.remove')}
      </Button>
      <VerifyDialogWithRequest
        headerTitle={t('composition.remove')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionApi.removeCompositionMaterial}
        args={[material.id]}
        isOpen={isOpen}
        onClose={onClose}
        onPerformed={onRemoved}
        onSuccessTitle={t('general.successfullyRemoved')}
        onFailureTitle={t('general.removeFailed')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
    </Flex>
  );
}

function DottedArray({
  values,
}: {
  values: { amount: number; label: string }[];
}) {
  return (
    <>
      {values.map((x) => {
        if (x.amount === 0) return null;
        return (
          <Text key={x.label} fontSize="sm" fontWeight="medium" variant="sub">
            {x.amount} {x.label}
          </Text>
        );
      })}
    </>
  );
}

function AddMaterial({
  compositionId,
  productGroupId,
  onCreate,
}: {
  compositionId: number;
  productGroupId: number | null;
  onCreate: (data: CompositionMaterial) => void;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <CreateCompositionMaterial
        onCreate={onCreate}
        compositionId={compositionId}
        productGroupId={productGroupId}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Button
        minH="20"
        boxSize="full"
        variant="texas-outline-light"
        borderStyle="dashed"
        onClick={() => onOpen()}
      >
        {t('composition.addMaterial')}
      </Button>
    </>
  );
}
