// @ngInject
const PaymentTermsApiService = (ResourcesService) => ({
  getAllByBranchId: (branchId) =>
    ResourcesService.getAll('paymentTerm', {
      params: { branchId, organizationId: false },
    }),

  getById: (paymentTermId) =>
    ResourcesService.getById('paymentTerm', paymentTermId, {
      params: { branchId: false },
    }),

  newPaymentTerm: (paymentTerm, branchId) =>
    ResourcesService.create('paymentTerm', paymentTerm, {
      params: { branchId, organizationId: false },
    }),

  updatePaymentTerm: (data, paymentTermId) =>
    ResourcesService.update('paymentTerm', paymentTermId, data, {
      params: { branchId: false },
      updatedFields: ['name', 'identifier', 'displayOrder', 'archived'],
    }),
});

angular
  .module('resources.paymentTerms', [])
  .factory('PaymentTermsApiService', PaymentTermsApiService);
