// @ngInject
const ConceptDialogService = ($rootScope, $mdDialog, CustomersApiService) => ({
  showDialog(data) {
    data = data || {};
    data.customer = data.customer || null;
    data.organization =
      data.organization || $rootScope.currentUser.organization || null;

    return $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ConceptDialogController as vm',
      templateUrl: 'services/dialogs/conceptDialog/conceptDialog.tpl.html',
      locals: { customer: data.customer },
      resolve: {
        customers: () =>
          CustomersApiService.getAllByOrganizationId(
            data.organization.id,
            true,
          ),
      },
    });
  },
});

class ConceptDialogController {
  constructor(
    $mdDialog,
    $filter,
    ConceptsApiService,
    CustomersApiService,
    customer,
    customers,
    toasterService,
  ) {
    'ngInject';

    this._$filter = $filter;
    this._$mdDialog = $mdDialog;
    this._ConceptsApiService = ConceptsApiService;
    this._toasterService = toasterService;
    this.validationErrors = [];
    this.customers = customers;
    this.concept = {};
    this.selectedCustomer = null;

    if (customer) {
      $mdDialog.startLoadingSpinner();
      CustomersApiService.getById(customer.id, true)
        .then((response) => {
          this.selectedCustomer = response;
        })
        .finally(() => {
          $mdDialog.stopLoadingSpinner();
        });
    }
  }

  save() {
    this.validationErrors = [];
    if (!this.selectedCustomer) {
      this.validationErrors.push({ message: 'Please select a customer' });
      return;
    }
    this._$mdDialog.startLoadingSpinner();
    this._ConceptsApiService
      .newConcept(this.concept, this.selectedCustomer.id)
      .then(
        (newConcept) => {
          this._toasterService.success();
          this._$mdDialog.hide(newConcept);
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this._$mdDialog.cancel();
  }

  filterCustomers(query) {
    return this._$filter('filter')(this.customers, { name: query });
  }

  shouldFocus(field) {
    switch (field) {
      case 'customer':
        return !this.selectedCustomer;
      case 'concept':
      default:
        return !!this.selectedCustomer;
    }
  }
}

angular
  .module('services.dialogs.conceptDialog', [])
  .service('ConceptDialogService', ConceptDialogService)
  .controller('ConceptDialogController', ConceptDialogController);
