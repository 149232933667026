import { useTranslation } from 'react-i18next';
import {
  FilterOptionType,
  FilterType,
  FilterTypeEnum,
} from '../../filter/types';

export interface GroupedOption {
  label: string;
  options: SelectFilterOption[] | ToggleFilterOption[];
}

export interface SelectFilterOption {
  label: string;
  value: FilterOptionType;
}
export interface ToggleFilterOption {
  label: string;
  value: FilterOptionType;
}

export function GetFilterGroupedOptions(
  availableFilters: FilterType[],
): GroupedOption[] {
  const { t } = useTranslation();
  return [
    {
      label: t('filter.dropdown'),
      options: availableFilters
        .filter((f) => {
          return f.type === FilterTypeEnum.Select;
        })
        .map<SelectFilterOption>((f) => {
          return { label: f.getName(t), value: f.optionType };
        }),
    },
    {
      label: t('filter.toggle'),
      options: availableFilters
        .filter((f) => {
          return f.type === FilterTypeEnum.Checkbox;
        })
        .map<ToggleFilterOption>((f) => {
          return { label: f.getName(t), value: f.optionType };
        }),
    },
  ];
}
