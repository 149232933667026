import { Locale } from '../../i18n/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export enum DateFormat {
  Date = 'YYYY-MM-DD',
  Datetime = 'YYYY-MM-DD HH:mm UTC',
  DateWeekday = 'ddd DD',
}

export function formatDate(
  locale: Locale,
  dateString?: string,
  dateFormat: DateFormat = DateFormat.Date,
): string {
  if (!dateString) return '';
  return dayjs(dateString).utc().locale(locale).format(dateFormat);
}

export function dateFromNow(locale: Locale, dateString: string) {
  return dayjs(dateString).utc().locale(locale).fromNow();
}
