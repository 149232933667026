// @ngInject
const DeclineConfirmationDialogService = ($mdDialog) => ({
  showDialog(data) {
    data = data || {};
    data.confirm = data.confirm || null;

    return $mdDialog.show({
      controller: 'DeclineConfirmationDialog as vm',
      templateUrl:
        'services/dialogs/declineConfirmationDialog/declineConfirmationDialog.tpl.html',
      locals: { confirm: data.confirm },
    });
  },
});

class DeclineConfirmationDialog {
  constructor($mdDialog, confirm) {
    'ngInject';
    this.message = null;
    this._$mdDialog = $mdDialog;
    this._confirm = confirm;
  }

  decline() {
    this._confirm(2, this.message);
    this._$mdDialog.hide();
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.declineConfirmationDialog', [])
  .service('DeclineConfirmationDialogService', DeclineConfirmationDialogService)
  .controller('DeclineConfirmationDialog', DeclineConfirmationDialog);
