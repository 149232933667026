angular
  .module('directives.branchDisabledHint', [])
  .directive('branchDisabledHint', () => ({
    template:
      '<div class="disabled-hint">' +
      '<div layout="row" ' +
      'ng-show="!!showHint">' +
      '<span>Branch data differs and cannot be changed globally</span>' +
      '<md-button type="button" ' +
      'ng-if="!!onClearField && !readOnly" ' +
      'class="md-raised md-mini md-dynamic" ' +
      'aria-label="Clear fields" ' +
      'confirm-dialog="onClearField()" ' +
      'confirm-title="\'Clear branch data\'" ' +
      'confirm-text="\'Are you sure you want to clear this field on this branch?\'">' +
      '<md-tooltip md-direction="bottom">' +
      'Clear branch data' +
      '</md-tooltip>' +
      '<i class="icon icon-delete"></i>' +
      '</md-button>' +
      '</div>' +
      '</div>',
    restrict: 'E',
    replace: true,
    scope: {
      showHint: '=',
      onClearField: '&',
      readOnly: '=',
    },
  }));
