// @ngInject
const DeliveryTermsApiService = (ResourcesService) => ({
  getAllByBranchId: (branchId) =>
    ResourcesService.getAll('deliveryTerm', {
      params: { branchId, organizationId: false },
    }),

  getById: (deliveryTermId) =>
    ResourcesService.getById('deliveryTerm', deliveryTermId, {
      params: { branchId: false },
    }),

  newDeliveryTerm: (deliveryTerm, branchId) =>
    ResourcesService.create('deliveryTerm', deliveryTerm, {
      params: { branchId, organizationId: false },
    }),

  updateDeliveryTerm: (data, deliveryTermId) =>
    ResourcesService.update('deliveryTerm', deliveryTermId, data, {
      params: { branchId: false },
      updatedFields: ['name', 'identifier', 'displayOrder', 'archived'],
    }),
});

angular
  .module('resources.deliveryTerms', [])
  .factory('DeliveryTermsApiService', DeliveryTermsApiService);
