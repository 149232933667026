import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const underline = defineStyle({
  textDecoration: 'underline !important',
});

const navigationBar = defineStyle({
  fontFamily: 'Barlow',
  fontWeight: 'medium',
  textDecoration: 'none',
  color: 'whiteAlpha.900',
  opacity: '0.5',
  _activeLink: {
    fontWeight: 'bold',
    opacity: 1,
  },
  _hover: {
    opacity: 1,
  },
});

export default defineStyleConfig({
  defaultProps: { variant: 'underline' },
  variants: { navigationBar, underline },
});
