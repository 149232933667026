// @ngInject
const freightCostsResolve = (currentOrganization, OrganizationsApiService) =>
  OrganizationsApiService.loadFreightCosts(currentOrganization);

// @ngInject
const handlingFeesResolve = (currentOrganization, OrganizationsApiService) =>
  OrganizationsApiService.loadHandlingFees(currentOrganization);

// @ngInject
const variantResolve = ($stateParams, ArticlesApiService) =>
  ArticlesApiService.getVariantById(
    $stateParams.articleId,
    $stateParams.variantId,
  );

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant', {
    parent: 'article',
    url: '/variants/{variantId:[0-9]{1,11}}',
    templateUrl: 'main/article/variant/variant.tpl.html',
    resolve: {
      freightCosts: freightCostsResolve,
      handlingFees: handlingFeesResolve,
      variant: variantResolve,
    },
  });
};

angular
  .module('main.article.variant', [
    'main.article.variant.technical',
    'main.article.variant.matrix',
    'main.article.variant.general',
    'main.article.variant.purchase',
    'main.article.variant.sales',
    'main.article.variant.suppliers',
    'main.article.variant.compositions',
  ])
  .config(config);
