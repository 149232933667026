import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {
  FileLink,
  filesApi,
  UpdateFileRequest,
  UpdateFileResponse,
} from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface EditFileProps extends SharedDisclosureProps {
  file: FileLink;
  onUpdated: (f: UpdateFileResponse) => void;
}
export function EditFileModal({
  file,
  onUpdated,
  onClose,
  isOpen,
}: EditFileProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request: updateRequest, error } = useApiRequest(filesApi.updateFile);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateFileRequest>();

  useEffect(() => {
    reset({
      name: file.name,
      note: file.note,
    });
  }, [reset, file]);

  const onSubmit = async (data: UpdateFileRequest) =>
    await request(
      updateRequest,
      [file.identifier, data],
      (f) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(f);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">{t('fileBrowser.editFile')}</ModalHeader>
        <ModalBody>
          {error && <ErrorDetails error={error} />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column">
              <FormControl isRequired={true}>
                <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                <Input
                  {...register('name', {
                    required: true,
                    maxLength: {
                      value: 300,
                      message: t('errors.maxLength', { count: 300 }),
                    },
                  })}
                  variant="outline"
                  placeholder={t('general.name')}
                  autoFocus={true}
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
              <FormControl>
                <TexasFormLabel>{t('general.note')}</TexasFormLabel>
                <Input
                  {...register('note', {
                    required: false,
                  })}
                  variant="outline"
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
              <SubmitButton loading={isSubmitting}>
                {t('general.update')}
              </SubmitButton>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
