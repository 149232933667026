import { createIcon } from '@chakra-ui/react';
import {
  mdiCheck,
  mdiCheckDecagram,
  mdiCloseCircle,
  mdiClose,
  mdiHandBackLeft,
  mdiMagnify,
  mdiPauseCircle,
  mdiSortAscending,
  mdiSortDescending,
  mdiTrashCan,
  mdiTrashCanOutline,
  mdiAccountCircle,
  mdiDotsHorizontal,
  mdiCalendarCheck,
  mdiCog,
  mdiArchive,
  mdiArchiveOutline,
  mdiLogoutVariant,
  mdiFlag,
  mdiHistory,
  mdiArrowRight,
  mdiArrowRightThick,
  mdiArrowRightThin,
  mdiArrowLeft,
  mdiArrowLeftThick,
  mdiInformationOutline,
  mdiArrowDownRight,
  mdiPencil,
  mdiCircleMultiple,
  mdiAlphaPBox,
  mdiCheckAll,
  mdiAlertCircle,
  mdiCheckboxOutline,
  mdiGrid,
  mdiGridOff,
  mdiHelpCircleOutline,
  mdiRefresh,
  mdiCancel,
  mdiNote,
  mdiNoteAlert,
  mdiNoteEditOutline,
  mdiPercentCircle,
  mdiMargin,
  mdiMultiplicationBox,
  mdiPlus,
  mdiFileExport,
  mdiResizeBottomRight,
  mdiCalculator,
  mdiCircleMedium,
  mdiDownload,
  mdiUpload,
  mdiUploadBoxOutline,
  mdiArrowRightTopBold,
  mdiCircle,
  mdiCircleOutline,
  mdiArrowExpand,
  mdiArrowCollapse,
  mdiChevronDown,
  mdiChevronUp,
  mdiUndoVariant,
  mdiEyeOffOutline,
  mdiCheckCircle,
  mdiOpenInNew,
  mdiClock,
  mdiFilterVariant,
  mdiContentCopy,
  mdiArrowUpLeft,
  mdiViewGrid,
  mdiViewList,
  mdiDrag,
  mdiPackageVariant,
  mdiCube,
  mdiImageOutline,
  mdiFolderOutline,
  mdiFilePlusOutline,
  mdiFileOutline,
  mdiFileReplaceOutline,
  mdiPencilRuler,
  mdiLock,
} from '@mdi/js';

// Browse the full list of available icons here:
// https://materialdesignicons.com

const enlargedIconProps = {
  defaultProps: { boxSize: '2em' },
};

export const defaultIconSize = 5;

export const Icons = {
  Search: createIcon({ d: mdiMagnify }),
  Trash: createIcon({ d: mdiTrashCan }),
  TrashOutline: createIcon({ d: mdiTrashCanOutline }),
  Checkmark: createIcon({ d: mdiCheck }),
  SortAscending: createIcon({ d: mdiSortAscending }),
  SortDescending: createIcon({ d: mdiSortDescending }),
  CheckmarkDecagram: createIcon({
    d: mdiCheckDecagram,
    ...enlargedIconProps,
  }),
  CloseCircle: createIcon({ d: mdiCloseCircle, ...enlargedIconProps }),
  Close: createIcon({ d: mdiClose }),
  HandBackLeft: createIcon({ d: mdiHandBackLeft, ...enlargedIconProps }),
  PauseCircle: createIcon({ d: mdiPauseCircle, ...enlargedIconProps }),
  AccountCircle: createIcon({ d: mdiAccountCircle }),
  DotsHorizontal: createIcon({
    d: mdiDotsHorizontal,
  }),
  CalendarCheck: createIcon({ d: mdiCalendarCheck }),
  Cog: createIcon({ d: mdiCog }),
  Lock: createIcon({ d: mdiLock }),
  Archive: createIcon({ d: mdiArchive }),
  ArchiveOutline: createIcon({ d: mdiArchiveOutline }),
  Logout: createIcon({ d: mdiLogoutVariant }),
  Flag: createIcon({ d: mdiFlag }),
  History: createIcon({ d: mdiHistory }),
  ArrowRight: createIcon({ d: mdiArrowRight }),
  ArrowRightThick: createIcon({ d: mdiArrowRightThick }),
  ArrowRightThin: createIcon({ d: mdiArrowRightThin }),
  ArrowLeft: createIcon({ d: mdiArrowLeft }),
  ArrowLeftThick: createIcon({ d: mdiArrowLeftThick }),
  InformationOutline: createIcon({ d: mdiInformationOutline }),
  ArrowDownRight: createIcon({ d: mdiArrowDownRight }),
  Pencil: createIcon({ d: mdiPencil }),
  CircleMultiple: createIcon({ d: mdiCircleMultiple }),
  AlphaPBox: createIcon({ d: mdiAlphaPBox }),
  ArchiveRemove: createIcon({ d: mdiUndoVariant }),
  CheckAll: createIcon({ d: mdiCheckAll }),
  AlertCircle: createIcon({ d: mdiAlertCircle }),
  CheckboxOutline: createIcon({ d: mdiCheckboxOutline }),
  Grid: createIcon({ d: mdiGrid }),
  GridOff: createIcon({ d: mdiGridOff }),
  HelpCircleOutline: createIcon({ d: mdiHelpCircleOutline }),
  Refresh: createIcon({ d: mdiRefresh }),
  Cancel: createIcon({ d: mdiCancel }),
  Note: createIcon({ d: mdiNote }),
  NoteAlert: createIcon({ d: mdiNoteAlert }),
  NoteEdit: createIcon({ d: mdiNoteEditOutline }),
  PercentCircle: createIcon({ d: mdiPercentCircle }),
  Margin: createIcon({ d: mdiMargin }),
  MultiplicationBox: createIcon({ d: mdiMultiplicationBox }),
  Plus: createIcon({ d: mdiPlus }),
  FileExport: createIcon({ d: mdiFileExport }),
  ResizeBottomRight: createIcon({ d: mdiResizeBottomRight }),
  Calculator: createIcon({ d: mdiCalculator }),
  CircleMedium: createIcon({ d: mdiCircleMedium }),
  Download: createIcon({ d: mdiDownload }),
  Upload: createIcon({ d: mdiUpload }),
  UploadBoxOutline: createIcon({ d: mdiUploadBoxOutline }),
  ArrowRightTopBold: createIcon({ d: mdiArrowRightTopBold }),
  Circle: createIcon({ d: mdiCircle }),
  CircleOutline: createIcon({ d: mdiCircleOutline }),
  ArrowExpand: createIcon({ d: mdiArrowExpand }),
  ArrowCollapse: createIcon({ d: mdiArrowCollapse }),
  ChevronUp: createIcon({ d: mdiChevronUp }),
  ChevronDown: createIcon({ d: mdiChevronDown }),
  EyeOff: createIcon({ d: mdiEyeOffOutline }),
  CheckCircle: createIcon({ d: mdiCheckCircle }),
  OpenInNew: createIcon({ d: mdiOpenInNew }),
  Clock: createIcon({ d: mdiClock }),
  FilterVariant: createIcon({ d: mdiFilterVariant }),
  ContentCopy: createIcon({ d: mdiContentCopy }),
  ArrowUpLeft: createIcon({ d: mdiArrowUpLeft }),
  viewTable: createIcon({ d: mdiViewList }),
  viewCard: createIcon({ d: mdiViewGrid }),
  Drag: createIcon({ d: mdiDrag }),
  PackageVariant: createIcon({ d: mdiPackageVariant }),
  Cube: createIcon({ d: mdiCube }),
  ImageOutline: createIcon({ d: mdiImageOutline }),
  FolderOutline: createIcon({ d: mdiFolderOutline }),
  FilePlusOutline: createIcon({ d: mdiFilePlusOutline }),
  FileOutline: createIcon({ d: mdiFileOutline }),
  FileReplaceOutline: createIcon({ d: mdiFileReplaceOutline }),
  PencilRuler: createIcon({ d: mdiPencilRuler }),
};
