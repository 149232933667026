// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('searcharticles', {
    parent: 'main',
    url: '/searcharticles',
    views: {
      'main-content': {
        template: '<search-articles></search-articles>',
      },
    },
    data: { pageTitle: 'Articles' },
  });
};

angular.module('main.searcharticles', []).config(config);
