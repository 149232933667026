import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  addSpaceBeforeUppercaseLetter,
  formatValue,
} from '@texas/utils/helpers/commonHelpers';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import {
  ArticleBranch,
  ArticleBranchErpSyncChanges,
  FieldValueType,
} from '@texas/api/endpoints/variantsApi';
import { Icons } from '@texas/components/shared/Icons';

interface SyncChangesProps {
  branch: ArticleBranch;
  syncChanges?: ArticleBranchErpSyncChanges;
}

export function SyncChanges(props: SyncChangesProps) {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>{t('variant.sync.field')}</Th>
            <Th>{t('variant.sync.from')}</Th>
            <Th />
            <Th>{t('variant.sync.new')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.syncChanges?.modifications
            .filter((m) => m.modifiedFields.length > 0)
            .map((mod) => {
              return (
                <React.Fragment key={mod.label}>
                  <Tr>
                    <Td colSpan={4}>
                      <Text variant="subtitle" textTransform="uppercase">
                        {mod.label}
                      </Text>
                    </Td>
                  </Tr>
                  {mod.modifiedFields.map((m) => {
                    const convertedType = convertToEnum(FieldValueType, m.type);
                    return (
                      <Tr
                        key={m.name}
                        css={{
                          '> td': {
                            whiteSpace: 'normal',
                            maxWidth: '20em',
                            paddingTop: '1px !important',
                            paddingBottom: '1px !important',
                          },
                        }}
                      >
                        <Td
                          fontWeight="bold"
                          fontSize={12}
                          textTransform="capitalize"
                        >
                          {addSpaceBeforeUppercaseLetter(m.name)}
                        </Td>
                        <Td fontSize={12}>
                          <Text variant="sub">
                            {formatValue(m.syncedValue, convertedType)}
                          </Text>
                        </Td>
                        <Td>
                          <Icons.ArrowRightThin
                            boxSize={5}
                            color={syncColor(m.syncedValue, m.value)}
                          />
                        </Td>
                        <Td fontSize={12} fontWeight="bold">
                          {formatValue(m.value, convertedType)}
                        </Td>
                      </Tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function syncColor(syncValue: any, value: any) {
  if (!syncValue && value) {
    return 'texas.green.100';
  } else {
    return 'white';
  }
}
