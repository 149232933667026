import {
  Flex,
  useToast,
  Tr,
  Td,
  Tooltip,
  IconButton,
  Button,
  Text,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { roundedPurchasePriceWithFees } from './shared';
import { SalesPriceForm } from './forms/SalesPriceForm';
import { Multiplied } from './common/Multiplied';
import { Margin } from './common/Margin';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import { TierPrice, tierPriceApi } from '@texas/api/endpoints/tierPriceApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Icons } from '@texas/components/shared/Icons';
import useFormat from '@texas/hooks/useFormat';
import { useActiveSession } from '@texas/hooks/useSession';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { DeleteTierPriceDialog } from './DeleteTierDialog';
import { MatrixView } from './MatrixView';
import { SalesContext } from './Sales';
import { SalesBranchOverview } from './SalesBranchOverview';

const paddingSize = '4px !important';

interface SalesTierRowProps {
  tierPrice: TierPrice;
  purchaseInformation: PurchaseInformation;
  nodeGroups: ArticleNodeGroup;
  salesCurrency: SalesCurrency;
  selectedCurrency: CustomerCurrency;
  sellingExchangeRate: number;
  readyForSync: boolean;
}

export function SalesTierRow(props: SalesTierRowProps) {
  const { t } = useTranslation();
  const basePrice = props.tierPrice.basePrices.find(
    (b) => b.supplierId === props.purchaseInformation.supplierId,
  );
  const salesPrice = props.tierPrice.salesPrices.find(
    (s) => s.salesCurrencyId === props.salesCurrency.id,
  );
  const [localSalesPrice, setLocalSalesPrice] = useState<number>(
    salesPrice?.price ?? 0,
  );
  const salesContext = useContext(SalesContext);
  const session = useActiveSession();
  const toast = useToast();
  const { numberFormat } = useFormat();

  const [expandMatrix, setExpandMatrix] = useState(false);
  const { request: setMainRequest, loading: setMainLoading } = useApiRequest(
    tierPriceApi.setAsMain,
  );

  const {
    value: deleteValue,
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useValueDisclosure<TierPrice>();

  return (
    <>
      {deleteValue && (
        <DeleteTierPriceDialog
          tierPrice={deleteValue}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          onDelete={(tierPriceId) => {
            const toSet =
              salesContext?.tierPrices.data?.filter(
                (t) => t.id !== tierPriceId,
              ) ?? [];

            salesContext?.tierPrices.set(toSet);
          }}
        />
      )}
      <Tr role="group">
        <Td>
          {props.tierPrice.isMain && (
            <Tooltip label={t('variant.isMain')}>
              <Icons.Flag
                color="texas.sand.100"
                _light={{ color: 'texas.burntSienna.500' }}
              />
            </Tooltip>
          )}
        </Td>

        <Td>
          <Text fontWeight="bold">
            {numberFormat(props.tierPrice.quantity)}
          </Text>
        </Td>
        {nodeGroupIsPopulated(props.nodeGroups) ? (
          <>
            <Td>
              <Button
                width="100%"
                variant={expandMatrix ? 'solid' : 'ghost'}
                aria-label="expand"
                onClick={() => setExpandMatrix((v) => !v)}
                justifyContent="start"
              >
                <Flex alignItems="center" gap={1}>
                  {expandMatrix ? <Icons.GridOff /> : <Icons.Grid />}
                  <Text>
                    {expandMatrix
                      ? t('variant.hideMatrix')
                      : t('variant.expandMatrix')}
                  </Text>
                </Flex>
              </Button>
            </Td>
          </>
        ) : (
          <>
            <Td paddingRight={paddingSize}>
              <Text>{`${numberFormat(
                roundedPurchasePriceWithFees(
                  basePrice?.price ?? 0,
                  props.sellingExchangeRate,
                  props.purchaseInformation,
                ),
              )} ${props.selectedCurrency.code}`}</Text>
            </Td>
            <Td>
              <SalesPriceForm
                tierPrice={props.tierPrice}
                salesCurrency={props.salesCurrency}
                purchaseInformation={props.purchaseInformation}
                currencyBuyingRate={props.sellingExchangeRate}
                isDisabled={props.readyForSync}
                onPriceChanged={(price) => setLocalSalesPrice(price)}
                selectedCurrencyCode={props.selectedCurrency.code}
              />
            </Td>
            <Td>
              <Margin
                salesCurrency={props.salesCurrency}
                sellingExchangeRate={props.sellingExchangeRate}
                price={localSalesPrice}
                purchasePrice={basePrice?.price ?? 0}
                purchaseInformation={props.purchaseInformation}
              />
            </Td>
            <Td>
              <Multiplied
                salesCurrency={props.salesCurrency}
                sellingExchangeRate={props.sellingExchangeRate}
                price={localSalesPrice}
                purchasePrice={basePrice?.price ?? 0}
                purchaseInformation={props.purchaseInformation}
              />
            </Td>
          </>
        )}
        <Td>
          <Flex alignItems="center" gap={2} justifyContent="flex-end">
            {!props.tierPrice.isMain && (
              <>
                <IconButton
                  size="xs"
                  icon={<Icons.Trash />}
                  aria-label="delete"
                  onClick={() => onDeleteOpen(props.tierPrice)}
                />
                <IconButton
                  isLoading={setMainLoading}
                  size="xs"
                  icon={<Icons.Flag />}
                  aria-label="main"
                  onClick={async () =>
                    await performSetMainRequest(
                      props.tierPrice.id,
                      props.tierPrice.branchId,
                    )
                  }
                />
              </>
            )}
            {!nodeGroupIsPopulated(props.nodeGroups) && (
              <>
                <Tooltip label="Branches overview">
                  <SalesBranchOverview
                    currentTier={props.tierPrice}
                    branchTierPrices={
                      salesContext?.purchaseInformation.data
                        ?.filter((p) => p.isMain)
                        .map((p) => {
                          return {
                            branch: session.branches.find(
                              (b) => b.id === p.branchId,
                            ),
                            salesInformation:
                              salesContext.salesInformation.data?.find(
                                (s) => s.branchId === p.branchId,
                              ),
                            purchaseInformation: p,
                            tierPrice: salesContext.tierPrices.data?.find(
                              (t) =>
                                t.branchId === p.branchId &&
                                t.quantity === props.tierPrice.quantity,
                            ),
                            sellingExchangeRate: props.sellingExchangeRate,
                            selectedCurrencyCode: props.selectedCurrency.code,
                          };
                        }) ?? []
                    }
                  >
                    <Button size="xs">{t('variant.tierOverview')}</Button>
                  </SalesBranchOverview>
                </Tooltip>
              </>
            )}
          </Flex>
        </Td>
      </Tr>
      {expandMatrix && (
        <Tr _hover={{ background: 'transparent !important' }}>
          <Td colSpan={7}>
            <MatrixView
              purchaseInformation={props.purchaseInformation}
              salesCurrency={props.salesCurrency}
              tierPriceId={props.tierPrice.id}
              nodeGroup={props.nodeGroups}
              salesPrice={props.tierPrice.salesPrices.find(
                (s) => s.salesCurrencyId === props.salesCurrency.id,
              )}
              selectedCurrency={props.selectedCurrency}
              sellingExchangeRate={props.sellingExchangeRate}
              readyForSync={props.readyForSync}
              purchasePrice={props.tierPrice.basePrices.find(
                (b) => b.supplierId === props.purchaseInformation.supplierId,
              )}
            />
          </Td>
        </Tr>
      )}
    </>
  );

  async function performSetMainRequest(tierPriceId: number, branchId: number) {
    await request(
      setMainRequest,
      [tierPriceId],
      () => {
        const toSet =
          salesContext?.tierPrices.data?.map((t) => {
            if (t.branchId === branchId) {
              if (t.id === tierPriceId) {
                return { ...t, isMain: true };
              } else {
                return { ...t, isMain: false };
              }
            }

            return t;
          }) ?? [];

        salesContext?.tierPrices.set(toSet);
      },
      (error: ServerError) => {
        toast({
          title: `${t('variant.setMainFailed')}`,
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}
