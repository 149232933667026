import { useCallback } from 'react';
import { DeepPartial, FieldValues, useForm } from 'react-hook-form';

interface Props<T> {
  submit: (data: T) => Promise<void>;
  defaultValues?: DeepPartial<T>;
}

export function useFormSubmitStore<T extends FieldValues>(props: Props<T>) {
  const form = useForm<T>({
    defaultValues: props.defaultValues,
  });

  const performSubmit = useCallback(() => {
    form.handleSubmit(props.submit)();
  }, [form, props.submit]);

  return { form, performSubmit };
}
