// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.deliveryTerms', {
    url: '/deliveryterms',
    controller: 'DeliveryTermsConfigurationController as vm',
    templateUrl: 'main/configuration/deliveryTerms/deliveryTerms.tpl.html',
    data: { pageTitle: 'Delivery terms' },
  });
};

class DeliveryTermsConfigurationController {
  constructor(
    $scope,
    DS,
    configurationOptions,
    loadSpinnerService,
    DeliveryTermsApiService,
    ConfigItemDialogService,
    currentOrganization,
  ) {
    'ngInject';

    this._ConfigItemDialogService = ConfigItemDialogService;
    this._configurationOptions = configurationOptions;
    this._loadSpinnerService = loadSpinnerService;
    this._DeliveryTermsApiService = DeliveryTermsApiService;
    this.selectedBranch = {};
    this.branches = currentOrganization.branches;
    this.deliveryTerms = [];
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    this.onSelectBranch(configurationOptions.selectedBranch);

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('deliveryTerm');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.deliveryTerms = DS.filter('deliveryTerm', {
            where: {
              branchId: {
                '==': this.selectedBranch.id,
              },
            },
          });
        }
      },
    );
  }

  onSelectBranch(branch) {
    this._configurationOptions.selectedBranch = branch || this.branches[0];
    this.selectedBranch = branch || this.branches[0];
    this.loadDeliveryTerms();
  }

  loadDeliveryTerms() {
    this._loadSpinnerService.start('mainSpinner');
    this._DeliveryTermsApiService
      .getAllByBranchId(this.selectedBranch.id)
      .then((deliveryTerms) => {
        this.deliveryTerms = deliveryTerms;
      })
      .finally(() => {
        this._loadSpinnerService.stop('mainSpinner');
      });
  }

  openDeliveryTerm(deliveryTerm) {
    this._ConfigItemDialogService.showDialog({
      configItem: deliveryTerm,
      displayOrder: true,
      identifier: true,
      onSave: (deliveryTerm) =>
        this._DeliveryTermsApiService.updateDeliveryTerm(
          _.pick(deliveryTerm, [
            'name',
            'identifier',
            'displayOrder',
            'archived',
          ]),
          deliveryTerm.id,
        ),
    });
  }

  newDeliveryTerm() {
    this._ConfigItemDialogService.showDialog({
      displayOrder: true,
      identifier: true,
      onSave: (deliveryTerm) =>
        this._DeliveryTermsApiService.newDeliveryTerm(
          deliveryTerm,
          this.selectedBranch.id,
        ),
    });
  }
}

angular
  .module('configuration.deliveryTerms', [])
  .config(config)
  .controller(
    'DeliveryTermsConfigurationController',
    DeliveryTermsConfigurationController,
  );
