import {
  AxiosResponseHeaders,
  GenericAbortSignal,
  RawAxiosResponseHeaders,
} from 'axios';
import { ServerError } from '../../types';
import { createHttpClient } from './httpClient';

export type HttpClient = ReturnType<typeof createHttpClient>;

export type Method = 'GET' | 'DELETE' | 'POST' | 'PUT';

export enum HttpStatus {
  Unauthorized = 401,
  NotFound = 404,
}

export interface RequestOptions {
  headers?: Record<string, string>;
  params?: Record<string, any>;
  signal?: GenericAbortSignal;
}
export type RequestOptionsWithBody = RequestOptions & {
  data?: FormData | object;
};

export interface OkResponse<T> {
  hasError: false;
  status: number;
  data: T;
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

export interface ErrorResponse {
  hasError: true;
  error: ServerError;
}

export type HttpClientResponse<T> = OkResponse<T> | ErrorResponse;
