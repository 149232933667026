// @ngInject
const initializeErrorLogger = (ENV, ErrorLoggerService) => {
  if (ENV.name === 'production') {
    ErrorLoggerService.init({ apiKey: ENV.raygunApiKey, version: ENV.version });
  }
};

// @ngInject
const changeDragoverBehaviour = () => {
  angular
    .element(document)
    .on('dragover', (e) => {
      e.originalEvent.dataTransfer.dropEffect = 'copy';
      e.preventDefault();
    })
    .on('drop', () => {});
};

// @ngInject
const setRootScopeVariables = (
  $rootScope,
  $state,
  $stateParams,
  $location,
  AuthService,
  UserDialogService,
  UsersApiService,
) => {
  $rootScope.hasAnyClaim = AuthService.hasAnyClaim;
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;
  $rootScope.go = (path) => {
    $location.url(path);
  };
  $rootScope.pagingLimits = [
    {
      label: 'All',
      value: null,
    },
    10,
    15,
    50,
    100,
  ];
  $rootScope.openUser = (userId) => {
    UsersApiService.getById(userId).then((user) => {
      const modalOptions = {
        user,
        viewMode: true,
      };
      UserDialogService.showDialog(modalOptions);
    });
  };
};

// @ngInject
const eventSubscribers = (
  $q,
  $rootScope,
  $state,
  loadSpinnerService,
  $injector,
) => {
  $rootScope.$on('$stateChangeStart', (e, toState, toParams) => {
    loadSpinnerService.start('mainSpinner');
    $rootScope.toState = toState;
    $rootScope.toParams = toParams;
  });
  $rootScope.$on('$stateChangeSuccess', (event, toState) => {
    loadSpinnerService.stop('mainSpinner', { force: true });
    if (angular.isDefined(toState.data.pageTitle)) {
      $rootScope.pageTitle = toState.data.pageTitle;
    }
    $rootScope.toState = null;
    $rootScope.toParams = null;

    const redirect = toState.redirectTo;
    if (redirect) {
      const newState = $injector.invoke(
        redirect,
        null,
        $state.$current.locals.globals,
      );
      if (newState) {
        $q.when(newState).then((result) => {
          if (angular.isString(result)) {
            $state.go(result, null, { location: 'replace' });
          } else if (result.state) {
            $state.go(result.state, result.params, { location: 'replace' });
          }
        });
      }
    }
  });
  $rootScope.$on('$stateChangeError', () => {
    loadSpinnerService.stop('mainSpinner', { force: true });
  });
};

const appSettings = {
  token: null,
  dateFormat: 'yyyy-MM-dd',
  dateTimeFormat: 'yyyy-MM-dd HH:mm',
};

const customerTypes = [
  { value: 'Brand', label: 'Brand' },
  { value: 'Vendor', label: 'Vendor' },
];

angular
  .module('core.init', ['init.angularData'])
  .run(setRootScopeVariables)
  .run(eventSubscribers)
  .run(initializeErrorLogger)
  .run(changeDragoverBehaviour)
  .value('appSettings', appSettings)
  .value('customerTypes', customerTypes);
