// @ngInject
const CountryDialogService = ($mdDialog) => ({
  showDialog(data) {
    data = data || {};
    data.branch = data.branch || null;
    data.country = data.country || null;

    return $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'CountryDialogController as vm',
      templateUrl: 'services/dialogs/countryDialog/countryDialog.tpl.html',
      locals: {
        branch: data.branch,
        country: data.country,
      },
    });
  },
});

class CountryDialogController {
  constructor(
    $q,
    $mdDialog,
    HelperService,
    CountriesApiService,
    toasterService,
    country,
    branch,
  ) {
    'ngInject';

    this.validationErrors = [];
    this.editMode = country !== null;

    if (!this.editMode) {
      country = {};
    }

    this.country = _.extend({}, country);
    this._$mdDialog = $mdDialog;
    this._$q = $q;
    this._CountriesApiService = CountriesApiService;
    this._HelperService = HelperService;
    this._branch = branch;
    this._country = country;
    this._toasterService = toasterService;
  }

  save() {
    const promises = [];
    this.validationErrors = [];
    this._$mdDialog.startLoadingSpinner();

    if (this.editMode) {
      const changedCountryData = this._HelperService.getChangedData(
        this._country,
        this.country,
        ['name', 'code', 'archived'],
      );

      if (!_.isEmpty(changedCountryData)) {
        promises.push(
          this._CountriesApiService.updateCountry(
            changedCountryData,
            this._country.id,
          ),
        );
      }
    } else {
      promises.push(
        this._CountriesApiService.newCountry(this.country, this._branch.id),
      );
    }

    this._$q
      .all(promises)
      .then(
        () => {
          this._toasterService.success();
          this._$mdDialog.hide();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  archive() {
    this.country.archived = !this.country.archived;
    this.save();
  }

  cancel() {
    this._$mdDialog.cancel();
  }
}

angular
  .module('services.dialogs.countryDialog', [])
  .service('CountryDialogService', CountryDialogService)
  .controller('CountryDialogController', CountryDialogController);
