const link = (scope, element, attrs, mdAutocompleteCtrl) => {
  if (!attrs.mdSelectedItem || attrs.disabled) {
    return;
  }

  scope.$watch(attrs.mdSelectedItem, (newVal, oldVal) => {
    if (oldVal === newVal || newVal !== null || attrs.disabled) {
      return;
    }
    mdAutocompleteCtrl.focus();
  });
};

const fixFocus = () => ({
  restrict: 'A',
  scope: false,
  require: 'mdAutocomplete',
  link,
});

angular
  .module('directives.fixFocus', ['material.components.autocomplete'])
  .directive('fixFocus', fixFocus);
