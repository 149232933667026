// @ngInject
const VatCodesApiService = (DS, $q, ResourcesService) => ({
  getAllByBranchId(branchId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.organizationId = false;
    options.params.branchId = branchId;
    return ResourcesService.getAll('vatCode', options);
  },

  getById(vatCodeId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.branchId = false;
    return ResourcesService.getById('vatCode', vatCodeId, options);
  },

  newVatCode(vatCode, branchId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.organizationId = false;
    options.params.branchId = branchId;
    return ResourcesService.create('vatCode', vatCode, options);
  },

  updateVatCode(data, vatCodeId, options) {
    options = options || {};
    options.params = options.params || {};
    options.params.branchId = false;
    options.updatedFields = [
      'name',
      'identifier',
      'value',
      'displayOrder',
      'archived',
    ];
    return ResourcesService.update('vatCode', vatCodeId, data, options);
  },
});

angular
  .module('resources.vatCodes', [])
  .factory('VatCodesApiService', VatCodesApiService);
