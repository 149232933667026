import { httpClient } from '../httpClient/httpClient';
import { Composition } from './compositionApi';
import { FileLink } from './filesApi';
import { ProductApprovalState } from './productApprovalsApi';

interface CreateCompositionGroupRequest {
  variantId: number;
  basedOnId?: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

interface CopyCompositionGroupRequest extends CreateCompositionGroupRequest {
  replaceCompositionGroupId: number | undefined;
}

export interface UpdateCompositionGroupRequest {
  note: string;
}

interface CompositionGroupPa {
  productApprovalId: number;
  state: ProductApprovalState;
}

export interface CompositionGroupOverview {
  articleId: number;
  id: number;
  variantId: number;
  cellX: number | null;
  cellY: number | null;
  supplierId: number | null;
  branchId: number | null;
  note: string | null;
  version: number;
  isTemplate: boolean;
  productApprovals: CompositionGroupPa[] | null;
  archived: string | null;
  hasArchivedCompositions: boolean;
  basedOnGroupId: number | null;
  compositions: Composition[];
  fileId?: number;
  folderId: number;
}

export interface CompositionGroup {
  articleId: number;
  id: number;
  variantId: number;
  cellX: number | null;
  cellY: number | null;
  supplierId: number | null;
  branchId: number | null;
  note: string;
  version: number;
  isTemplate: boolean;
  exists: boolean;
  compositions: Composition[];
  productApprovals: CompositionGroupPa[] | null;
  archived: string | null;
  fileId?: number;
  file?: FileLink;
  hasArchivedCompositions: boolean;
  basedOnGroupId: number | null;
  articleProductGroupId: number | null;
}

export interface CompositionGroupId {
  id: number;
  variantId: number;
}

interface LocateCompositionGroupParams {
  variantId: number;
  cellX?: number;
  cellY?: number;
  supplierId?: number;
  branchId?: number;
}

interface SearchCompositionGroupsParams {
  variantId: number;
  branchIds: number[];
  supplierIds: number[];
}

export interface CompositionCell {
  x: number;
  y: number;
  colorCodes: string[];
  versionsCount: number;
  optionsCount: number;
}

interface GetCompositionGroupParams {
  includeArchived: boolean;
}

export const compositionGroupApi = {
  addGroup: (data: CreateCompositionGroupRequest) =>
    httpClient.post<CompositionGroup>(`/compositiongroups`, {
      data,
    }),
  copyGroup: (from: number, data: CopyCompositionGroupRequest) =>
    httpClient.put<CompositionGroup>(`/compositiongroups/${from}/copy`, {
      data,
    }),
  setBasedOn: (id: number, basedOnId: number) =>
    httpClient.put<CompositionGroup>(
      `/compositiongroups/${id}/based-on/${basedOnId}`,
    ),
  updateGroup: (id: number, data: UpdateCompositionGroupRequest) =>
    httpClient.put<CompositionGroup>(`/compositiongroups/${id}`, { data }),
  updateFile: (id: number, fileId: number) =>
    httpClient.put(`/compositiongroups/${id}/file/${fileId}`),
  deleteFile: (id: number) =>
    httpClient.delete(`/compositiongroups/${id}/file`),
  getGroup: (id: number, params?: GetCompositionGroupParams) =>
    httpClient.get<CompositionGroup>(`/compositiongroups/${id}`, { params }),
  getAllCellGroups: (variantId: number) =>
    httpClient.get<CompositionCell[]>(`compositiongroups/${variantId}/cells`),
  getAllPreviousGroupIds: (params: LocateCompositionGroupParams) =>
    httpClient.get<CompositionGroupId[]>(`compositiongroups/previous`, {
      params,
    }),
  searchGroup: (params: SearchCompositionGroupsParams) =>
    httpClient.get<CompositionGroup[]>(`/compositiongroups/search`, {
      params,
    }),
  locateGroup: (params: LocateCompositionGroupParams) =>
    httpClient.get<CompositionGroup>(`/compositiongroups/locate`, {
      params,
    }),
  overview: (params: LocateCompositionGroupParams) =>
    httpClient.get<CompositionGroupOverview[]>(`/compositiongroups/overview`, {
      params,
    }),
  archive: (id: number) =>
    httpClient.put<CompositionGroup>(`compositiongroups/${id}/archive`),
  restore: (id: number) =>
    httpClient.put<CompositionGroup>(`compositiongroups/${id}/restore`),
};
