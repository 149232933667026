import { keyframes } from '@emotion/react';

export const drawerAnimations = ({
  openRightOffset,
  width,
  duration,
}: {
  openRightOffset: string;
  width: string;
  duration: number;
}) => {
  const animationOpenKeyframes = keyframes`
  0% { right: -${width} }
  50% { right: -${openRightOffset}; width: calc(${width} + 2em); }
  100% { right: -${openRightOffset}; width: ${width} }`;

  const animationCloseKeyframes = keyframes`
  0% { right: 0px }
  100% { right: -${width} }`;

  const openAnimation = `${animationOpenKeyframes} ${duration}ms ease`;
  const closeAnimation = `${animationCloseKeyframes} ${duration}ms ease`;

  return { openAnimation, closeAnimation };
};
