// @ngInject
const usersResolve = (customer, UsersApiService) =>
  UsersApiService.getAllByOrganizationId(customer.organizationId);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('customer.management', {
    url: '/management',
    templateUrl: 'main/customer/management/management.tpl.html',
    controller: 'CustomerManagementController as vm',
    resolve: {
      users: usersResolve,
    },
  });
};

class CustomerManagementController {
  constructor(
    $scope,
    $filter,
    $q,
    currentOrganization,
    DeliveryTermsApiService,
    PaymentTermsApiService,
    ResourcesService,
    customer,
    loadSpinnerService,
    CustomersApiService,
    CurrenciesApiService,
    HelperService,
    AuthService,
    customerTypes,
    toasterService,
    SavingService,
    users,
  ) {
    'ngInject';

    const saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validateForm(),
    });

    this.$filter = $filter;
    this.$q = $q;
    this.DeliveryTermsApiService = DeliveryTermsApiService;
    this.PaymentTermsApiService = PaymentTermsApiService;
    this.ResourcesService = ResourcesService;
    this.loadSpinnerService = loadSpinnerService;
    this.CustomersApiService = CustomersApiService;
    this.CurrenciesApiService = CurrenciesApiService;
    this.HelperService = HelperService;
    this.toasterService = toasterService;
    this.customerTypes = customerTypes;
    this.customer = customer;
    this.selectedBranch = null;
    this.selectedBranchCurrency = null;
    this.branches = currentOrganization.branches;
    this.currentBranchData = {};
    this.deliveryTerms = [];
    this.paymentTerms = [];
    this.originalCustomer = customer;
    this.customer = {};
    this.uploadInstances = {};
    this.validationErrors = [];
    this.filterSelected = true;
    this.currencies = [];
    this.sellingRate = null;
    this.users = users;

    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
      'agent',
    ]);
    this.currentUserCanEditBrandOwner = AuthService.hasAnyClaim([
      'system_administrator',
      'company_administrator',
    ]);

    this.extendCustomer();
    this.selectBranch(this.branches[0]);

    $scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        if (!hasChanges) {
          this.customerForm.$setPristine();
        }
        saveObj.hasChanges = hasChanges;
      },
    );

    $scope.$on('$destroy', () => {
      SavingService.deregisterSave(saveObj);
    });
  }

  extendCustomer() {
    const customerClone = _.extend({}, this.originalCustomer);
    customerClone.brandOwner = customerClone.brandOwner
      ? _.find(this.users, { id: this.originalCustomer.brandOwner })
      : null;
    this.customer = customerClone;
    if (this.selectedBranch) {
      this.setCurrentBranchDataClone();
    }
  }

  getChangedData() {
    const changedInfo = this.HelperService.getChangedData(
      this.originalCustomer,
      this.customer,
      [
        'name',
        'officeAddress',
        'website',
        'email',
        'phone',
        'notes',
        'customerType',
        'archived',
      ],
    );

    const changedBranchData = this.getBranchDataChanges();
    if (changedBranchData.length) {
      changedInfo.branchData = changedBranchData;
    }

    if (!this.originalCustomer.brandOwner && this.customer.brandOwner) {
      changedInfo.brandOwner = this.customer.brandOwner.id;
    } else if (
      this.customer.brandOwner &&
      this.originalCustomer.brandOwner !== this.customer.brandOwner.id
    ) {
      changedInfo.brandOwner = this.customer.brandOwner.id;
    } else if (!this.customer.brandOwner && this.originalCustomer.brandOwner) {
      changedInfo.brandOwner = 0;
    }

    return changedInfo;
  }

  getBranchDataChanges() {
    const dataChanges = [];
    const fields = [
      'paymentTermId',
      'deliveryTermId',
      'useDefaultCurrency',
      'defaultCurrencyIsMain',
    ];

    const originalBranchData = this.getOriginalBranchData();
    const changedData = this.HelperService.getChangedData(
      originalBranchData,
      this.currentBranchData,
      fields,
    );
    if (this.currentBranchData.currencies !== null) {
      const hasChangedCurrencies = this.HelperService.isArrayModified(
        originalBranchData.currencies,
        this.currentBranchData.currencies,
        ['currencyId', 'isMain', 'sellingRate', 'useCustomSellingRate'],
        ['currencyId'],
      );
      if (hasChangedCurrencies) {
        changedData.currencies = this.currentBranchData.currencies;
      }
    }
    if (!_.isEmpty(changedData)) {
      dataChanges.push(angular.extend({}, originalBranchData, changedData));
    }

    return dataChanges;
  }

  validateForm() {
    if (!this.customerForm.$valid) {
      this.customerForm.$setSubmitted();
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  save(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.CustomersApiService.updateCustomerInfo(data, this.originalCustomer.id)
      .then(
        () => {
          this.extendCustomer();
          this.setCurrentBranchData();
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  archive() {
    this.save({ archived: !this.originalCustomer.archived });
  }

  discard() {
    this.validationErrors = [];
    this.extendCustomer();
    this.setCurrentBranchData();
  }

  currencySelected(currency) {
    this.selectedCurrency = null;
    this.currencySearchText = null;

    this.addCurrency(currency);
  }

  addCurrency(currency) {
    this.currentBranchData.currencies.push({
      currencyCode: currency.code,
      currencyId: currency.id,
      isMain: false,
      sellingRate: currency.sellingRate,
      garpSellingRate: currency.sellingRate,
      useCustomSellingRate: false,
    });
  }

  removeCurrency(currency) {
    this.currentBranchData.currencies.splice(
      this.currentBranchData.currencies.indexOf(currency),
      1,
    );
  }

  setMainCurrency(currency) {
    if (this.currentBranchData.currencies.indexOf(currency) !== -1) {
      _.forEach(this.currentBranchData.currencies, (c) => {
        c.isMain = false;
      });
      currency.isMain = true;
      this.currentBranchData.defaultCurrencyIsMain = false;
    }
  }

  setUseCustomSellingRate(currency) {
    if (this.currentBranchData.currencies.indexOf(currency) !== -1) {
      currency.useCustomSellingRate = !currency.useCustomSellingRate;
    }
  }

  removeDefaultCurrency() {
    if (
      !this.currentBranchData.defaultCurrencyIsMain &&
      !!this.currentBranchData.currencies.length
    ) {
      this.currentBranchData.useDefaultCurrency = false;
      this.currentBranchData.defaultCurrencyIsMain = false;
    }
  }

  addDefaultCurrency() {
    this.currentBranchData.useDefaultCurrency = true;
  }

  setDefaultCurrencyAsMain() {
    _.forEach(this.currentBranchData.currencies, (c) => {
      c.isMain = false;
    });
    this.currentBranchData.useDefaultCurrency = true;
    this.currentBranchData.defaultCurrencyIsMain = true;
  }

  filterCurrencies(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentCurrencies(), {
        code: query || '',
      }),
      ['code'],
    );
  }

  filterDeliveryTerms(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentDeliveryTerms(), { name: query }),
      ['displayOrder', 'name'],
    );
  }

  filterPaymentTerms(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentPaymentTerms(), { name: query }),
      ['displayOrder', 'name'],
    );
  }

  setCurrentBranchDataClone() {
    this.currentBranchData = _.extend({}, this.getOriginalBranchData());
    this.currentBranchData.currencies = _.map(
      this.currentBranchData.currencies,
      _.cloneDeep,
    );
  }

  setCurrentBranchData() {
    this.setBranchDataDeliveryTerm(this.currentBranchData.deliveryTermId);
    this.setBranchDataPaymentTerm(this.currentBranchData.paymentTermId);
    this.setBranchDataSellingRate();
  }

  setBranchDataDeliveryTerm(deliveryTermId) {
    if (deliveryTermId) {
      this.currentBranchData.deliveryTermId = deliveryTermId;
      this.currentBranchData.deliveryTerm = _.find(
        this.getCurrentDeliveryTerms(),
        { id: deliveryTermId },
      );
    } else {
      if (this.getOriginalBranchData().deliveryTermId) {
        this.currentBranchData.deliveryTermId = 0;
      }
      this.currentBranchData.deliveryTerm = null;
    }
  }

  setBranchDataPaymentTerm(paymentTermId) {
    if (paymentTermId) {
      this.currentBranchData.paymentTermId = paymentTermId;
      this.currentBranchData.paymentTerm = _.find(
        this.getCurrentPaymentTerms(),
        { id: paymentTermId },
      );
    } else {
      if (this.getOriginalBranchData().paymentTermId) {
        this.currentBranchData.paymentTermId = 0;
      }
      this.currentBranchData.paymentTerm = null;
    }
  }

  setBranchDataSellingRate() {
    const branchData = _.find(this.originalCustomer.branchData, {
      branchId: this.selectedBranch.id,
    });
    if (branchData && branchData.currencies && branchData.currencies.length) {
      return (this.sellingRate = branchData.currencies[0].sellingRate);
    }
  }

  getCurrentDeliveryTerms() {
    const deliveryTerms = _.filter(this.deliveryTerms, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.deliveryTerm &&
      !_.some(this.deliveryTerms, { id: originalBranchData.deliveryTerm.id })
    ) {
      deliveryTerms.push(originalBranchData.deliveryTerm);
    }
    return deliveryTerms;
  }

  getCurrentPaymentTerms() {
    const paymentTerms = _.filter(this.paymentTerms, { archived: false });
    const originalBranchData = this.getOriginalBranchData();
    if (
      originalBranchData.paymentTerm &&
      !_.some(this.paymentTerms, { id: originalBranchData.paymentTerm.id })
    ) {
      paymentTerms.push(originalBranchData.paymentTerm);
    }
    return paymentTerms;
  }

  getCurrentCurrencies() {
    const unarchivedCurrencies = _.filter(this.currencies, { archived: false });

    return _.filter(
      unarchivedCurrencies,
      (c) =>
        (!this.selectedBranch ||
          c.id !== this.selectedBranch.defaultCurrencyId) &&
        !_.some(this.currentBranchData.currencies, { currencyId: c.id }),
    );
  }

  setSelectedBranchCurrency() {
    if (this.selectedBranch) {
      this.selectedBranchCurrency = _.find(this.currencies, {
        id: this.selectedBranch.defaultCurrencyId,
      });
    } else {
      this.selectedBranchCurrency = null;
    }
  }

  selectBranch(branch) {
    if (!branch) {
      return;
    }

    this.selectedBranch = branch;
    this.setSelectedBranchCurrency();
    this.setCurrentBranchDataClone();
    this.loadBranchData(this.getOriginalBranchData()).then(() => {
      this.setCurrentBranchData();
    });
  }

  setOwner(owner) {
    this.customer.brandOwner = owner;
  }

  loadBranchData(branchData) {
    this.loadSpinnerService.start('mainSpinner');
    return this.$q
      .all([
        this.DeliveryTermsApiService.getAllByBranchId(branchData.branchId),
        this.PaymentTermsApiService.getAllByBranchId(branchData.branchId),
        this.CurrenciesApiService.getAllByBranchId(branchData.branchId),
      ])
      .then((data) => {
        this.deliveryTerms = data[0];
        this.paymentTerms = data[1];
        this.currencies = data[2];
        if (!this.selectedBranchCurrency) {
          this.setSelectedBranchCurrency();
        }
        return this.ResourcesService.loadRelations(
          'customerBranchData',
          branchData,
          ['deliveryTerm', 'paymentTerm'],
        );
      })
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  getOriginalBranchData() {
    if (
      !_.some(this.originalCustomer._branchData, {
        branchId: this.selectedBranch.id,
      })
    ) {
      this.originalCustomer.branchData.push({
        branchId: this.selectedBranch.id,
        customerId: this.originalCustomer.id,
        useDefaultCurrency: true,
        defaultCurrencyIsMain: true,
      });
    }
    return _.find(this.originalCustomer._branchData, {
      branchId: this.selectedBranch.id,
    });
  }

  filterUsers(query) {
    return this.$filter('filter')(this.users, { uniqueName: query });
  }
}

angular
  .module('main.customer.management', [])
  .config(config)
  .controller('CustomerManagementController', CustomerManagementController);
