// @ngInject
const scrollTop = ($window) => {
  $window.scrollTo(0, 0);
};

// @ngInject
const rootFolderIdResolve = (concept) => concept.folderId;

// @ngInject
const conceptResolve = ($stateParams, ConceptsApiService) =>
  ConceptsApiService.getById($stateParams.conceptId);

// @ngInject
const conceptArticlesResolve = (concept, ArticlesApiService) =>
  ArticlesApiService.getAllByConceptId(concept.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('concept', {
    parent: 'main',
    url: '/concepts/{conceptId}',
    views: {
      'main-content': {
        controller: 'ConceptController as vm',
        templateUrl: 'main/concept/concept.tpl.html',
      },
    },
    data: { pageTitle: 'Concept' },
    resolve: {
      concept: conceptResolve,
    },
    onEnter: scrollTop,
  });
  // Articles tab
  $stateProvider.state('concept.articles', {
    url: '/articles',
    templateUrl: 'tabs/articlesTab/articlesTab.tpl.html',
    controller: 'ArticlesTabController as vm',
    resolve: {
      customer: () => null,
      conceptArticles: conceptArticlesResolve,
    },
  });
  // Files tab
  $stateProvider.state('concept.files', {
    url: '/files',
    abstract: true,
    templateUrl: 'tabs/filesTab/filesTab.tpl.html',
    controller: 'FilesTabsController as vm',
    resolve: {
      rootFolderId: rootFolderIdResolve,
    },
  });
  $stateProvider.state('concept.files.id', {
    url: '/{folderPath:nonURIEncoded}',
    params: {
      folderPath: { value: null, squash: true },
    },
  });
  // Tasks tab
  $stateProvider.state('concept.tasks', {
    url: '/tasks',
    templateUrl: 'tabs/tasksTab/tasksTab.tpl.html',
    controller: 'TasksTabsController as vm',
    resolve: {
      article: () => null,
      customer: () => null,
      tasksTabSettings: () => null,
    },
  });
};

class ConceptController {
  constructor($scope, $state, concept) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.concept = concept;

    this.entityTabs = [
      {
        heading: '<i class="icon icon-settings"></i>',
        tooltip: 'Management',
        route: 'concept.management',
      },
      {
        heading: '<i class="icon icon-folder"></i>',
        tooltip: 'Files',
        route: 'concept.files.id',
      },
      {
        heading: '<i class="icon icon-assignment"></i>',
        tooltip: 'Tasks',
        route: 'concept.tasks',
      },
    ];

    this.contentTabs = [
      {
        active: $state.is('concept'),
        isDefault: true,
        heading: 'Articles',
        route: 'concept.articles',
      },
    ];

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('concept')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.contentTabs, { isDefault: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module('main.concept', ['main.concept.management'])
  .config(config)
  .controller('ConceptController', ConceptController);
