import { httpClient } from '../httpClient/httpClient';
import { AdditionalCharge } from './additionalChargeApi';
import { AutoUpdateRequest, AutoUpdateResponse } from './autoUpdateTypes';

export interface SalesCurrency {
  id: number;
  salesInformationId: number;
  currencyId: number;
  currencyCode: string;
  fixedExchangeRate: number;
  additionalCharge: AdditionalCharge;
  isMain: boolean;
}

export interface AutoUpdateSalesCurrencyRequest {
  fixedExchangeRate?: number;
}

export interface CreateSalesCurrencyRequest {
  currencyId: number;
}

export const salesCurrenciesApi = {
  createSalesCurrency: (
    salesInformationId: number,
    request: CreateSalesCurrencyRequest,
  ) =>
    httpClient.post<SalesCurrency>(
      `salescurrencies/${salesInformationId}/add`,
      {
        data: request,
      },
    ),
  autoUpdateSalesCurrency: (
    salesCurrencyId: number,
    request: AutoUpdateRequest<AutoUpdateSalesCurrencyRequest>,
  ) =>
    httpClient.post<AutoUpdateResponse<SalesCurrency>>(
      `salescurrencies/${salesCurrencyId}/auto`,
      {
        data: request,
      },
    ),
  setSalesCurrencyAsMain: (salesCurrencyId: number) =>
    httpClient.post<SalesCurrency[]>(`salescurrencies/${salesCurrencyId}/main`),
  deleteSalesCurrency: (salesCurrencyId: number) =>
    httpClient.delete<boolean>(`salescurrencies/${salesCurrencyId}`),
  refreshSalesCurrencyRate: (salesCurrencyId: number) =>
    httpClient.post<SalesCurrency>(
      `salescurrencies/${salesCurrencyId}/refresh/rate`,
    ),
};
