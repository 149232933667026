// @ngInject
const CustomerDialogService = ($mdDialog, AuthService, UsersApiService) => ({
  showDialog(data) {
    data = data || {};
    data.organization = data.organization || null;
    data.defaultType = data.defaultType || null;

    return $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'CustomerDialogController as vm',
      templateUrl: 'services/dialogs/customerDialog/customerDialog.tpl.html',
      locals: {
        organization: data.organization,
        defaultType: data.defaultType,
      },
      resolve: {
        currentUser: () => AuthService.getUser(),
        users: () =>
          UsersApiService.getAllByOrganizationId(data.organization.id),
      },
    });
  },
});

class CustomerDialogController {
  constructor(
    $mdDialog,
    $filter,
    CustomersApiService,
    organization,
    defaultType,
    toasterService,
    customerTypes,
    users,
    HelperService,
  ) {
    'ngInject';
    this.$filter = $filter;
    this.customerTypes = customerTypes;
    this.customerType = defaultType;
    this.users = users;
    this.validationErrors = [];
    this.customer = {};
    this.brandOwnerId = 0;
    this.customer.customerType = this.customerType.value;
    this._$mdDialog = $mdDialog;
    this._CustomersApiService = CustomersApiService;
    this._HelperService = HelperService;
    this._organization = organization;
    this._toasterService = toasterService;
  }

  save() {
    this.validationErrors = [];
    this._$mdDialog.startLoadingSpinner();
    this.customer.brandOwnerId = this.brandOwnerId;
    this._CustomersApiService
      .newCustomer(this.customer, this._organization.id)
      .then(
        (newCustomer) => {
          this._toasterService.success();
          this._$mdDialog.hide(newCustomer);
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this._$mdDialog.stopLoadingSpinner();
      });
  }

  cancel() {
    this._$mdDialog.cancel();
  }

  setBrandOwner(brandOwner) {
    this.brandOwnerId = brandOwner ? brandOwner.id : 0;
    this.brandOwner = brandOwner;
  }

  filterUsers(query) {
    return this.$filter('filter')(this.users, { uniqueName: query });
  }
}

angular
  .module('services.dialogs.customerDialog', [])
  .service('CustomerDialogService', CustomerDialogService)
  .controller('CustomerDialogController', CustomerDialogController);
