// @ngInject
const priceMatrixService = (HelperService) => {
  const updateCellPrice = (updatedCellValues, xId, yId, price) => {
    const updatedCellValue = _.find(updatedCellValues, {
      matrixNodeValueXId: xId,
      matrixNodeValueYId: yId,
    });
    if (updatedCellValue) {
      updatedCellValue.price = price;
    } else {
      updatedCellValues.push({
        matrixNodeValueXId: xId,
        matrixNodeValueYId: yId,
        _price: price,
        get price() {
          return this._price;
        },
        set price(value) {
          this._price = value;
        },
      });
    }
  };

  const resetMatrixCellPrice = (cell, price, cellValues, newCellValues) => {
    cell.isDirty = _.some(cellValues, {
      matrixNodeValueXId: cell.matrixNodeValueXId,
      matrixNodeValueYId: cell.matrixNodeValueYId,
    });
    cell.price = null;
    cell.isEdited = false;
    cell.hasChanged = false;

    if (cell.isDirty) {
      updateCellPrice(
        newCellValues,
        cell.matrixNodeValueXId,
        cell.matrixNodeValueYId,
        null,
      );
    } else {
      _.remove(newCellValues, {
        matrixNodeValueXId: cell.matrixNodeValueXId,
        matrixNodeValueYId: cell.matrixNodeValueYId,
      });
    }
  };

  return {
    getColumns: ({
      matrixLabel = '',
      nodeXValues = [],
      enableCellEdit = false,
      cellTemplate = 'directives/matrixCell/matrixCell.tpl.html',
      editableCellTemplate = 'directives/matrixCell/matrixCellInput.tpl.html',
      width = '*',
    } = {}) => [
      // Add static label column
      {
        name: 'rowName',
        cellClass: 'row-header',
        enableCellEdit: false,
        allowCellFocus: false,
        displayName: matrixLabel,
        pinnedLeft: true,
        minWidth: 200,
        width,
      },
      // Add dynamic columns
      ..._.chain(nodeXValues)
        .filter({ matrixNodeValue: { archived: false } })
        .sortBy((value) => value.matrixNodeValue.displayOrder)
        .map((node) => ({
          name: node.id.toString(),
          matrixNodeValueId: node.id,
          enableCellEdit,
          cellTemplate,
          editableCellTemplate,
          displayName: `${node.matrixNodeValue.identifier}: ${
            node.name || node.matrixNodeValue.name
          }`,
          type: 'object',
        }))
        .value(),
    ],

    getRows: (nodeValuesY) =>
      _.chain(nodeValuesY)
        .filter({ matrixNodeValue: { archived: false } })
        .sortBy((value) => value.matrixNodeValue.displayOrder)
        .map((node) => ({
          rowName: `${node.matrixNodeValue.identifier}: ${
            node.name || node.matrixNodeValue.name
          }`,
          matrixNodeValueId: node.id,
        }))
        .value(),

    addRowCellValues(row, nodeValuesX, getBasePrice, cellValues, keepDirty) {
      _.forEach(nodeValuesX, (xNode) => {
        let dirtyPrice;
        const isDirty = keepDirty && row[xNode.id] && row[xNode.id].isDirty;

        const cellValue = _.find(cellValues, {
          matrixNodeValueXId: xNode.id,
          matrixNodeValueYId: row.matrixNodeValueId,
        });
        const getPrice = () =>
          cellValue && row[xNode.id]._price !== null
            ? cellValue.price
            : getBasePrice(xNode.id, row.matrixNodeValueId);

        // Keep unsaved prices or replace them
        // with price from api/selectedTierPrice
        if (keepDirty && row[xNode.id]) {
          dirtyPrice = row[xNode.id]._price;
        }

        row[xNode.id] = {
          _price: dirtyPrice,
          matrixNodeValueXId: xNode.id,
          matrixNodeValueYId: row.matrixNodeValueId,
          isDirty,
          isEdited: (!!cellValue && !isDirty) || !!dirtyPrice,
          getPrice,
          get price() {
            if (_.isNumber(this._price)) {
              return this._price;
            } else {
              return typeof this._price === 'string' ? null : this.getPrice();
            }
          },
          set price(value) {
            this._price = value;
          },
        };
      });
    },

    updateCellPrice,

    resetMatrixCellPrice,

    onMatrixCellChange(cell, price, cellValues, newCellValues) {
      if (!HelperService.isValidDecimal(cell.price)) {
        resetMatrixCellPrice(cell, price, cellValues, newCellValues);
        return;
      }

      if (cell.hasChanged) {
        cell.isEdited = true;
        cell.isDirty = true;
        updateCellPrice(
          newCellValues,
          cell.matrixNodeValueXId,
          cell.matrixNodeValueYId,
          cell.price,
        );
      }
    },
  };
};

angular
  .module('services.priceMatrix', [])
  .factory('priceMatrixService', priceMatrixService);
