import { httpClient } from '../httpClient/httpClient';
import { AutoUpdateRequest, AutoUpdateResponse } from './autoUpdateTypes';

export interface AutoUpdateAdditionalCharge {
  surcharge: number;
  mouldCharge: number;
  sampleCharge: number;
}

export interface AdditionalCharge {
  salesCurrencyId: number;
  surcharge: number;
  mouldCharge: number;
  sampleCharge: number;
}

export const additionalChargeApi = {
  autoUpdateAdditionalCharge: (
    salesCurrencyId: number,
    additionalCharge: AutoUpdateRequest<AutoUpdateAdditionalCharge>,
  ) => {
    return httpClient.post<AutoUpdateResponse<AdditionalCharge>>(
      `additionalcharge/auto/${salesCurrencyId}`,
      {
        data: additionalCharge,
      },
    );
  },
};
