// @ngInject
const ArticleTemplateDialogService = (
  $mdDialog,
  $rootScope,
  OrganizationsApiService,
) => ({
  showDialog(data) {
    data = data || {};
    data.organization =
      data.organization || $rootScope.currentUser.organization || null;
    data.articleTemplate = data.articleTemplate || null;

    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'ArticleTemplateDialogController as vm',
      templateUrl:
        'services/dialogs/articleTemplateDialog/articleTemplateDialog.tpl.html',
      locals: {
        organization: data.organization,
        articleTemplate: data.articleTemplate,
        allowedToEdit: data.allowedToEdit,
      },
      resolve: {
        matrixNodes: () =>
          OrganizationsApiService.loadMatrixNodes(data.organization),
      },
    });
  },
});

class ArticleTemplateDialogController {
  constructor(
    $q,
    $mdDialog,
    HelperService,
    ArticleTemplatesApiService,
    toasterService,
    articleTemplate,
    organization,
    matrixNodes,
    allowedToEdit,
  ) {
    'ngInject';

    this.allowedToEdit = allowedToEdit;
    this.matrixNodesForX = _.filter(matrixNodes, (node) => {
      return node.allowedAxis === 'X' && !node.archived;
    }).map((node) => {
      return _.extend({}, node);
    });
    this.matrixNodesForY = _.filter(matrixNodes, (node) => {
      return node.allowedAxis === 'Y' && !node.archived;
    }).map((node) => {
      return _.extend({}, node);
    });
    this.validationErrors = [];
    this.editMode = articleTemplate !== null;

    if (!this.editMode) {
      articleTemplate = {};
    }

    this.articleTemplate = _.extend({}, articleTemplate);
    this._$mdDialog = $mdDialog;
    this._$q = $q;
    this._ArticleTemplatesApiService = ArticleTemplatesApiService;
    this._HelperService = HelperService;
    this._articleTemplate = articleTemplate;
    this._organization = organization;
    this._toasterService = toasterService;
  }

  save() {
    const promises = [];
    this.validationErrors = [];
    this._$mdDialog.startLoadingSpinner();

    if (this.editMode) {
      // Get any changes in article template info
      const fields = [
        'name',
        'matrixNodeXId',
        'matrixNodeYId',
        'itemCodeMinLength',
        'itemCodeMaxLength',
        'displayOrder',
        'archived',
      ];
      const changedArticleTemplateData = this._HelperService.getChangedData(
        this._articleTemplate,
        this.articleTemplate,
        fields,
      );

      // If changes are found, update them
      if (!_.isEmpty(changedArticleTemplateData)) {
        const articleTemplateInfoDfd = this._$q.defer();

        if (
          changedArticleTemplateData.itemCodeMinLength === '' ||
          changedArticleTemplateData.itemCodeMinLength === null
        ) {
          changedArticleTemplateData.hasItemCodeMinLength = false;
          delete changedArticleTemplateData.itemCodeMinLength;
        }

        if (
          changedArticleTemplateData.itemCodeMaxLength === '' ||
          changedArticleTemplateData.itemCodeMaxLength === null
        ) {
          changedArticleTemplateData.hasItemCodeMaxLength = false;
          delete changedArticleTemplateData.itemCodeMaxLength;
        }

        if (changedArticleTemplateData.matrixNodeXId === null) {
          changedArticleTemplateData.matrixNodeXId = 0;
        }

        if (changedArticleTemplateData.matrixNodeYId === null) {
          changedArticleTemplateData.matrixNodeYId = 0;
        }

        this._ArticleTemplatesApiService
          .updateArticleTemplate(
            changedArticleTemplateData,
            this._articleTemplate.id,
          )
          .then(
            () => {
              articleTemplateInfoDfd.resolve();
            },
            (error) => {
              articleTemplateInfoDfd.reject(error);
            },
          );

        promises.push(articleTemplateInfoDfd.promise);
      }

      this._$q
        .all(promises)
        .then(
          () => {
            this._toasterService.success();
            this._$mdDialog.hide();
          },
          (error) => {
            this.validationErrors = error.errors;
          },
        )
        .finally(() => {
          this._$mdDialog.stopLoadingSpinner();
        });
    } else {
      this._ArticleTemplatesApiService
        .newArticleTemplate(this.articleTemplate, this._organization.id)
        .then(
          (newTemplate) => {
            this._toasterService.success();
            this._$mdDialog.hide(newTemplate);
          },
          (error) => {
            this.validationErrors = error.errors;
          },
        )
        .finally(() => {
          this._$mdDialog.stopLoadingSpinner();
        });
    }
  }

  cancel() {
    this._$mdDialog.cancel();
  }

  archive() {
    this.articleTemplate.archived = !this.articleTemplate.archived;
    this.save();
  }
}

angular
  .module('services.dialogs.articleTemplateDialog', [])
  .service('ArticleTemplateDialogService', ArticleTemplateDialogService)
  .controller(
    'ArticleTemplateDialogController',
    ArticleTemplateDialogController,
  );
