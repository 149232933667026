import { UserProfile } from '@texas/api/endpoints/userProfilesApi';

export function findOptionFromProfiles(id: any, profiles: UserProfile[]) {
  const profile = profiles.find((k) => k.id === id);
  if (!profile) return undefined;
  return convertToOption(profile);
}

export function convertToOption(profile: UserProfile) {
  return {
    value: profile.id,
    label: profile.name,
  };
}
