import { HStack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { convertToEnum } from '../../../utils/helpers/enumHelpers';
import {
  AutoUpdateErrorType,
  AutoUpdateFieldError,
} from '@texas/api/endpoints/autoUpdateTypes';

export function AutoUpdateErrorFooter({
  fieldValue,
  formValue,
  onValueOptionClick,
  fieldError,
}: {
  fieldValue: any;
  formValue: any;
  onValueOptionClick: (value: any, force: boolean) => void;
  fieldError: AutoUpdateFieldError;
}) {
  const { t } = useTranslation();
  switch (convertToEnum(AutoUpdateErrorType, fieldError.error.type)) {
    case AutoUpdateErrorType.InternalServerError:
      return (
        <HStack justifyContent="space-between" w="100%">
          <Button
            size="sm"
            onClick={() => onValueOptionClick(formValue, false)}
          >
            {t('autoUpdate.tryAgain')}
          </Button>
        </HStack>
      );
    case AutoUpdateErrorType.OldValueMismatch:
      return (
        <HStack justifyContent="space-between" w="100%">
          <Button size="sm" onClick={() => onValueOptionClick(formValue, true)}>
            {t('autoUpdate.overwrite')}
          </Button>
          <Button
            size="sm"
            onClick={() => onValueOptionClick(fieldValue, false)}
          >
            {t('autoUpdate.reset')}
          </Button>
        </HStack>
      );
    case AutoUpdateErrorType.ValidationError:
    default:
      return null;
  }
}
