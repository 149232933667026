// @ngInject
const OrganizationsApiService = ($q, DS, ResourcesService, DSHttpAdapter) => {
  const loadRelations = (organization, relation, transform) =>
    DS.loadRelations('organization', organization, [relation], {
      bypassCache: true,
    }).then(transform || ((r) => r[relation]), ResourcesService.handleError);

  return {
    getAll: () => ResourcesService.getAll('organization'),

    getById: (organizationId) =>
      ResourcesService.getById('organization', organizationId),

    loadHandlingFees: (organization) =>
      loadRelations(organization, 'handlingFees'),

    loadMaterials: (organization) => loadRelations(organization, 'materials'),

    loadProductGroups: (organization) =>
      loadRelations(organization, 'productGroups'),

    loadTechniques: (organization) => loadRelations(organization, 'techniques'),

    loadFreightCosts: (organization) =>
      loadRelations(organization, 'freightCosts'),

    loadTechnicalOptions: (organization) =>
      loadRelations(organization, 'technicalOptions'),

    loadMatrixGroups: (organization) =>
      loadRelations(organization, 'matrixGroups'),

    loadMatrixNodes: (organization) =>
      loadRelations(organization, 'matrixNodes', (res) =>
        _.map(res.matrixNodes, (matrixNode) => {
          matrixNode.hasValueMinLength = matrixNode.valueMinLength !== null;
          matrixNode.hasValueMaxLength = matrixNode.valueMaxLength !== null;
          return matrixNode;
        }),
      ),

    loadArticleTemplates: (organization) =>
      loadRelations(organization, 'articleTemplates', (res) =>
        _.map(res.articleTemplates, (template) => {
          template.hasItemCodeMinLength = template.itemCodeMinLength !== null;
          template.hasItemCodeMaxLength = template.itemCodeMaxLength !== null;
          return template;
        }),
      ),

    deleteOrganizationFile: (organization, type) =>
      DSHttpAdapter.DEL(
        `${DS.defaults.basePath}organizations/${organization.id}/${type}`,
      ).then(
        (response) => {
          organization[`${type}Id`] = 0;
          organization[type] = null;
          return response;
        },
        (error) => $q.reject(ResourcesService.handleError(error)),
      ),
  };
};

angular
  .module('resources.organizations', [])
  .factory('OrganizationsApiService', OrganizationsApiService);
